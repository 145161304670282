import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { ReferralService } from 'utils/ReferralService';
import { FAILTOSAVERECOED } from 'utils/Constants';

export default function RefaralAllFilter(props) {
    const location = useLocation();
    const [currentRouteUrl, setCurrentRouteUrl] = useState("");
    const { setQuery, insSelected } = props;

    // const [selectedStatusFilters, setSelectedStatusFilters] = useState([]);
    const [selectedStatusFilters, setSelectedStatusFilters] = useState(['PENDING', 'ACCEPTED', 'REJECTED']);
    const [sentReferralsCount, setSentReferralsCount] = useState([]);
    const [receivedReferralsCount, setReceivedReferralsCount] = useState([]);
    const [filterDrops, setFilterDrops] = useState(false);

    useEffect(() => {
        getReferralsCount(props.calledBy === "ReceivedReferrals" ? "RECEIVED" : "SENT");
    }, [props.calledBy, insSelected]);

    useEffect(() => {
        setCurrentRouteUrl(location.pathname);
        setQuery("");
    }, [location]);

    useEffect(() => {
        createQuery(props.calledBy === "ReceivedReferrals" ? "RECEIVED" : "SENT");
    }, [selectedStatusFilters]);

    const getReferralsCount = (type) => {
        let instIds = insSelected.map(item => item.value);
        const commaSeparatedString = instIds.join(',');

        ReferralService.getReferralsCount(type, commaSeparatedString)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    if (type === "SENT") {
                        setSentReferralsCount(data);
                    } else {
                        setReceivedReferralsCount(data);
                    }
                } else {
                    console.log(FAILTOSAVERECOED);
                }
            });
    };

    const createQuery = (type) => {
        let query = "";
        if (selectedStatusFilters.includes("PENDING")) {
            query += "st=='SUBMITTED',st=='IN_REVIEW'";
            if (type === "SENT") query += ",st=='DRAFT'";
        }
        if (selectedStatusFilters.includes("ACCEPTED")) {
            if (query) query += ",";
            query += "st=='ACCEPTED',st=='IN_PROGRESS',st=='CLOSED'";
        }
        if (selectedStatusFilters.includes("REJECTED")) {
            if (query) query += ",";
            query += "st=='REJECTED',st=='CANCELED'";
        }
        setQuery(query);
    };

    const handleStatusFilterChange = (status) => {
        if (selectedStatusFilters.includes(status)) {
            setSelectedStatusFilters(selectedStatusFilters.filter(s => s !== status));
        } else {
            setSelectedStatusFilters([...selectedStatusFilters, status]);
        }
    };

    return (
        <div className="nex-filter-drops d-flex justify-content-end pb-3">
            <span className='nex-label cursor-pointer' onClick={() => setFilterDrops(!filterDrops)}>All Filters <span className='icon'><svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.3594 6.5625H1.64062C1.37955 6.5625 1.12917 6.46373 0.944567 6.28791C0.759961 6.1121 0.65625 5.87364 0.65625 5.625C0.65625 5.37636 0.759961 5.1379 0.944567 4.96209C1.12917 4.78627 1.37955 4.6875 1.64062 4.6875H19.3594C19.6204 4.6875 19.8708 4.78627 20.0554 4.96209C20.24 5.1379 20.3438 5.37636 20.3438 5.625C20.3438 5.87364 20.24 6.1121 20.0554 6.28791C19.8708 6.46373 19.6204 6.5625 19.3594 6.5625ZM16.0781 10.9375H4.92188C4.6608 10.9375 4.41042 10.8387 4.22582 10.6629C4.04121 10.4871 3.9375 10.2486 3.9375 10C3.9375 9.75136 4.04121 9.5129 4.22582 9.33709C4.41042 9.16127 4.6608 9.0625 4.92188 9.0625H16.0781C16.3392 9.0625 16.5896 9.16127 16.7742 9.33709C16.9588 9.5129 17.0625 9.75136 17.0625 10C17.0625 10.2486 16.9588 10.4871 16.7742 10.6629C16.5896 10.8387 16.3392 10.9375 16.0781 10.9375ZM12.1406 15.3125H8.85938C8.5983 15.3125 8.34792 15.2137 8.16332 15.0379C7.97871 14.8621 7.875 14.6236 7.875 14.375C7.875 14.1264 7.97871 13.8879 8.16332 13.7121C8.34792 13.5363 8.5983 13.4375 8.85938 13.4375H12.1406C12.4017 13.4375 12.6521 13.5363 12.8367 13.7121C13.0213 13.8879 13.125 14.1264 13.125 14.375C13.125 14.6236 13.0213 14.8621 12.8367 15.0379C12.6521 15.2137 12.4017 15.3125 12.1406 15.3125Z" fill="#009FD7" />
            </svg>
            </span></span>
            <ul className={`nex-filter-drops-lists nexogic-custom-scroll ${filterDrops === true ? 'open' : ''}`}>
                <li>
                    <label>
                        <input
                            type="checkbox"
                            checked={selectedStatusFilters.length === 3}
                            onChange={() =>
                                setSelectedStatusFilters(
                                    selectedStatusFilters.length === 3 ? [] : ['PENDING', 'ACCEPTED', 'REJECTED']
                                )
                            }
                        />
                        <span>All</span>
                        {props.calledBy === "ReceivedReferrals" ?
                            <span>{(receivedReferralsCount?.SUBMITTED ?? 0)
                                + (receivedReferralsCount?.CANCELED ?? 0)
                                + (receivedReferralsCount?.IN_REVIEW ?? 0)
                                + (receivedReferralsCount?.ACCEPTED ?? 0)
                                + (receivedReferralsCount?.REJECTED ?? 0)
                                + (receivedReferralsCount?.IN_PROGRESS ?? 0)
                                + (receivedReferralsCount?.CLOSED ?? 0)}</span>
                            :
                            <span>{(sentReferralsCount?.DRAFT ?? 0)
                                + (sentReferralsCount?.SUBMITTED ?? 0)
                                + (sentReferralsCount?.CANCELED ?? 0)
                                + (sentReferralsCount?.IN_REVIEW ?? 0)
                                + (sentReferralsCount?.ACCEPTED ?? 0)
                                + (sentReferralsCount?.REJECTED ?? 0)
                                + (sentReferralsCount?.IN_PROGRESS ?? 0)
                                + (sentReferralsCount?.CLOSED ?? 0)}</span>
                        }
                    </label>
                </li>
                {['PENDING', 'ACCEPTED', 'REJECTED'].map((status) => (
                    <li key={status}>
                        <label>
                            <input
                                type="checkbox"
                                checked={selectedStatusFilters.includes(status)}
                                onChange={() => handleStatusFilterChange(status)}
                            />
                            <span>{status}</span>
                            {(props.calledBy === "ReceivedReferrals") ?
                                status === "PENDING" ? <span>{(receivedReferralsCount?.SUBMITTED ?? 0) + (receivedReferralsCount?.IN_REVIEW ?? 0)}</span>
                                    : status === "ACCEPTED" ? <span>{(receivedReferralsCount?.ACCEPTED ?? 0) + (receivedReferralsCount?.IN_PROGRESS ?? 0) + (receivedReferralsCount?.CLOSED ?? 0)}</span>
                                        : status === "REJECTED" ? <span>{(receivedReferralsCount?.REJECTED ?? 0)}</span> : ''
                                : ''}
                            {(props.calledBy === "SentReferrals") ?
                                status === "PENDING" ? <span>{(sentReferralsCount?.SUBMITTED ?? 0) + (sentReferralsCount?.IN_REVIEW ?? 0) + (sentReferralsCount?.DRAFT ?? 0)}</span>
                                    : status === "ACCEPTED" ? <span>{(sentReferralsCount?.ACCEPTED ?? 0) + (sentReferralsCount?.IN_PROGRESS ?? 0) + (sentReferralsCount?.CLOSED ?? 0)}</span>
                                        : status === "REJECTED" ? <span>{(sentReferralsCount?.REJECTED ?? 0)}</span> : ''
                                : ''}
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    );
}

