import React from 'react';
import ImgSec from './images/img1.jpg'
import './css/style.scss'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

const Section1 = () => {
  return (
    <>
    <Card className='micro-section-card-v2 bg-color'>
        <CardHeader className='section-header'>Section Two Heading</CardHeader>
        
        <CardBody>
        <Row className="row">
                <Col lg={6} className="card-graphic">
                    <img src={ImgSec} alt="" width="100%" className="card-img" />
                </Col>
                <Col lg={6} className="card-content">
                    <h2 className="card-title">Lorem ipsum dolor sit amet.</h2>
                    {/* <h4 className="h4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Temporibus, obcaecati.</h4> */}
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate facere omnis nisi excepturi, enim expedita est, quisquam repudiandae aspernatur , incidunt to molestiae nisi voluptas amet, omnis eligendi, aliquid quaerat?</p>
                </Col>
            </Row>            
        </CardBody>
    </Card>
    {/* <div className="micro-section-card-v1 card border border-primary">
            <div className='card-header main-header'>Sections</div>
            <div className="row">
                <div className="col-lg-6 card-graphic">
                    <img src={ImgSec} alt="" width="100%" className="card-img" />
                </div>
                <div className="col-lg-6 card-content">
                    <h2 className="card-title">Lorem ipsum dolor sit amet.</h2>
                    <h4 className="h4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Temporibus, obcaecati.</h4>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate facere omnis nisi excepturi, enim expedita est, quisquam repudiandae aspernatur ipsam eius harum ex explicabo iusto, mollitia necessitatibus? Dolore maxime, incidunt totam laborum rerum explicabo ipsum delectus aspernatur molestiae nisi voluptas amet, omnis eligendi, aliquid quaerat?</p>
                </div>
            </div>
        </div> */}
    </>

        

  )
}

export default Section1