import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { Field } from "formik";
import React from "react";
import { Button, Col, Input, Label, Row } from "reactstrap";

const renderType = [
  {
    label: "Number",
    value: "NUMBER",
  },
  {
    label: "Select",
    value: "SELECT",
  },
  {
    label: "Text",
    value: "TEXT",
  }
];

export const ScreeningQuestion = (props) => {
  const { index, values, remove, setFieldValue, removeQuestion } = props;

  return (
    <div className="screening-question">
      <div className="screen-qhead">
        <div className="q-txt"> {values?.screeningQuestions[index].question} <span className="recomm">Recommended</span></div>
        <Button className="close-icon p-0" color="link" type="button"  onClick={()=>removeQuestion(index,values.screeningQuestions[index].question,remove)}>
          <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="#047883" className="bi bi-x-lg" viewBox="0 0 16 16" >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </Button>
      </div>
      <div className="screen-body">
        <Row>
        <Col md="3" className="d-flex align-items-start">
        <Label>Answer Type :</Label>
        {/* <Field
          id={`screeningQuestions[${index}].type`}
          name={`screeningQuestions[${index}].type`}
          type="text"
          component={FormikSelectField}
          value={props.values?.screeningQuestions[index]?.renderType}
          inputprops={{
            name: `screeningQuestions[${index}].type`,
            options: renderType,
            keys: {
              id: "value",
              label: "label",
            },
          }}
        /> */}
      </Col>
      <Col md={4} className="d-flex align-items-start">
      {values?.screeningQuestions[index]?.renderType==='SELECT'?
      <p>Yes/No </p> : <p>{values?.screeningQuestions[index]?.renderType}</p>
      }
        {/* <Field
          id={`screeningQuestions[${index}].options`}
          name={`screeningQuestions[${index}].options`}
          type="text"
          component={FormikInputField}
          placeholder="Enter Options"
          value={values?.screeningQuestions[index].options}
        /> */}
      </Col>      
    </Row>
    </div>
    </div>
  );
};
