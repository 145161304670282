import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import prizeIcon from "assets/images/svgs/prize_icon.svg";
import AlertDialog from "common/AlertDialog";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { InstitutionServices } from "utils/InstitutionServices";
import { renderDateRange } from "utils/Utils";
import { RenderInstituteAwardModal } from "./components/RenderInstituteAwardModal";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import del_red_icon from "assets/images/del-red-icon.png";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import awards_icon from "assets/images/awords.png";
import plusIcon from "assets/images/plus-icon-lg.png";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const RenderAwards = (props) => {

  const { awards, handleEditItem, handleRemoveItem, isEditable, isAdmin } =
    props;
  if (awards && awards.length > 0) {
    return awards.map((item, index) => {
      const { issuedYear, issuedMonth } = item;
      const timeDuration =
        issuedMonth && issuedYear
          ? renderDateRange(issuedMonth + "-" + issuedYear, "", false, "", true)
          : "";
      return (
        <li className="nex-icon-list-item"key={`award_${index}`} >

          <div className="card-graphic">
            <img src={awards_icon} width="40" alt="#" />
          </div>
          <div className="nex-info">
            <div className="card-title">{item.awardName}</div>
            <p className="card-text nex-text-blue-light">{item.issuingAuthority ? item.issuingAuthority : null}</p>
            <p className="card-text">
              {item.description ? item.description : null}
            </p>
            <p className="card-text text-black">
              Issued On: {timeDuration}
            </p>

            <div className="nex-action">
              <div className="delete-action">
                {(isEditable || isAdmin) && (
                  <div className="px-0">
                    <Button
                      color="link"
                      className="visible-hover py-0 nex-ar-awards-edit-btn"
                      onClick={() => {
                        const {
                          issuedMonth,
                          issuedYear,
                          description,
                          awardName,
                          issuingAuthority,
                          guid,
                        } = item;
                        handleEditItem({
                          guid: guid ?? "",
                          awardName: awardName ?? "",
                          issuingAuthority: issuingAuthority ?? "",
                          description: description ?? "",
                          issuedMonthYear: issuedMonth + "-" + issuedYear ?? "",
                        });
                      }}
                    >
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="15.9062" cy="16" r="15.375" fill="white" stroke="#DBF4FB" stroke-width="0.75" />
                        <path d="M19.3612 9.53125L17.8557 11.0368L20.8667 14.0478L22.3722 12.5423L19.3612 9.53125ZM16.3502 12.5423L10.3281 18.5643V21.5754H13.3392L19.3612 15.5533L16.3502 12.5423Z" fill="#46ABC9" />
                      </svg>
                    </Button>
                    <Button
                      color="link-danger"
                      className="visible-hover delete py-0 nex-ar-awards-del-btn"
                      onClick={() => handleRemoveItem(item)}
                    >
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16.2266" cy="16" r="15.75" fill="#FFEAEF" />
                        <path d="M11.3984 13.7734L12.2586 20.1323C12.426 21.3702 12.5097 21.9892 12.9337 22.3597C13.3577 22.7302 13.9823 22.7302 15.2315 22.7302H18.4692C19.7184 22.7302 20.343 22.7302 20.767 22.3597C21.191 21.9892 21.2747 21.3702 21.4421 20.1323L22.3023 13.7734" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14.5156 11.0475V11.0475C14.5156 10.2672 14.5156 9.87708 14.6572 9.57473C14.8061 9.25693 15.0616 9.00138 15.3794 8.85254C15.6818 8.71094 16.0719 8.71094 16.8522 8.71094V8.71094C17.6324 8.71094 18.0226 8.71094 18.3249 8.85254C18.6427 9.00138 18.8983 9.25693 19.0471 9.57473C19.1887 9.87708 19.1887 10.2672 19.1887 11.0475V11.0475" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M22.3023 11.0547L11.3984 11.0547" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14.8906 14.1719L15.28 20.0132" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.7957 14.1719L18.4062 20.0132" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/** 
            <div className="award flex-grow-1">
              <div className="d-flex">
                <div className="prize-icon">
                  <img src={prizeIcon} width="20" alt="#" />
                </div>
                <div className="ml-2">
                  <div className="font-weight-500 f-16 line-h-16 black-color-accent m-0">
                    {item.awardName}
                  </div>
                  <div className="section-item-shortdes mt-2">
                    {item.issuingAuthority ? item.issuingAuthority : null}
                  </div>
                  <div className="section-item-shortdes mt-2">
                    {item.description ? item.description : null}
                  </div>
                  {timeDuration && (
                    <div className="section-item-shortdes mt-2">
                      Issued On: {timeDuration}
                    </div>
                  )}
                </div>
              </div>
            </div>
            */}

        </li>
      );
    });
  }
  return "";
};

const InstitutionsAwards = (props) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { alert, isEditable, isAdmin, institution, getInstitutionProfile, setShowAwardsOnTabScroll } =
    props;
  const [awards, setAwards] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);
  const [isOpenAlert, setAlertModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [pannel_on, setPannel_on] = useState(true);

  const getlistOfawards = async () => {
    setLoading(true);
    const response = await InstitutionServices.awardsInstitution(
      institution?.guid
    );
    if (response.status === 200) {
      setAwards(response.data);
    }
    setLoading(false);
  };

  const toggleAlert = () => {
    setLoading(false);
    setAlertModal(!isOpenAlert);
  };
  const initialValue = {
    awardName: "",
    issuingAuthority: "",
    description: "",
    issuedMonthYear: "",
    guid: "",
  };

  const handleEditItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    toggle();
  };

  const handleRemoveItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    toggleAlert();
  };

  const handleClickOnYes = async () => {
    setLoading(true);
    try {
      const request = InstitutionServices.deleteAwardInstitution(
        institution.guid,
        selectedItem.guid
      );
      request.then(
        async (response) => {
          const { data, status } = response;
          if (status === 204) {
            getInstitutionProfile(institution.guid);
            setLoading(false);
            toggleAlert();
            showSuccessMessage("Award deleted successfully");
          } else {
            showErrorMessage(data.status?.message);
            setLoading(false);
            toggleAlert();
          }
        },
        (e) => {
          setLoading(false);
          let errmsg = e.message;
          if (e.response) {
            errmsg =
              e.response.data && !!e.response.data?.message
                ? e.response.data?.message
                : errmsg;
          }
          showErrorMessage(errmsg);
        }
      );
    } catch (e) {
      showErrorMessage(e.message);
      setLoading(false);
      toggleAlert();
    }
  };

  useEffect(() => {
    if (institution?.guid) {
      getlistOfawards();
    }
  }, [getInstitutionProfile, institution]);

  useEffect(() => {
    if (((awards?.length > 0 && !isAdmin) || isAdmin)) {
      setShowAwardsOnTabScroll(true);
    } else {
      setShowAwardsOnTabScroll(false);
    }
  }, [awards])

  return (
    <>
      {((awards?.length > 0 && !isAdmin) || isAdmin) && (
        <Card className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`}>
          <div className="card-header"><h2>Awards</h2>
            <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
              <svg width={17} height={10} viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path
                  d="M2.25 1.625L8.625 8L15 1.625"
                  stroke="black"
                  strokeWidth={3}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            {(isEditable || isAdmin) && (
              <Button
                id="nex-ip-awwords-plus-btn"
                color="link"
                className="add-button ml-auto"
                onClick={() => handleEditItem(initialValue)}
              >
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9.75" cy="9" r="8.4375" fill="white" stroke="#46ABC9" strokeWidth="1.125" />
                  <path d="M9.625 5.25L9.625 12.5039" stroke="#46ABC9" strokeWidth="1.125" strokeLinecap="round" />
                  <path d="M13.25 8.875L5.99609 8.875" stroke="#46ABC9" strokeWidth="1.125" strokeLinecap="round" />
                </svg> Add More
              </Button>
            )}
          </div>
          <div className={`collapse-div`}>
            <CardBody>

              {awards && awards?.length
                ? ""
                : (isEditable || isAdmin) && (
                  <p className="card-text">
                    <Button
                      id="ia_add_awords"
                      className="readmore"
                      color="link"
                      onClick={() => handleEditItem(initialValue)}
                    >
                      {"Add your awards"}
                    </Button>
                  </p>
                )}
              <ul className="nex-icon-list-within-card">
                <RenderAwards
                  awards={awards}
                  handleEditItem={handleEditItem}
                  handleRemoveItem={handleRemoveItem}
                  isEditable={isEditable}
                  isAdmin={isAdmin}
                />
              </ul>
              <RenderInstituteAwardModal
                selectedItem={selectedItem}
                toggle={toggle}
                isOpen={isOpen}
                getInstitutionProfile={getInstitutionProfile}
                isAdmin={isAdmin}
                institution={institution}
                practitionerId={currentUser?.guid}
                institutionId={institution?.guid}
              />
              <AlertDialog
                toggle={toggleAlert}
                isOpen={isOpenAlert}
                isLoading={isLoading}
                handleClickOnYes={handleClickOnYes}
                yesLabel={"Yes"}
                noLabel={"No"}
              />
            </CardBody>
          </div>
        </Card>
      )}
    </>
  );
};

export default withAlert()(InstitutionsAwards);
