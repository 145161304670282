import {
    GET_AFFILIATIONS,
    GET_USER_AFFILIATIONS
} from "./Actions";

let initialState = {
    affiliations : [],
    userAffiliations : [],
};
  
function ProfileReducer(state = initialState, action) {
  
    switch (action.type) {
        case GET_AFFILIATIONS:
            return {
            ...state,
            affiliations: action.payload,
            };
        case GET_USER_AFFILIATIONS:
            return {
            ...state,
            userAffiliations: action.payload,
            };    
        
        default:
            return state;
    }
}
  
export default ProfileReducer;  