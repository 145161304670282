import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import AmentitesForm from "./AmentitesForm";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import blue_bullets from "assets/images/blue-bullets.png";
import plusIcon from "assets/images/plus-icon-lg.png";
import AmentitesFormBulkEdit from "./AmentitesFormBulkEdit";

export const AmenityUI = (props) => {
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();
  const { institution, getInstitutionProfile, themeDetails, previewFlag, alert, isEditable, isAdmin } = props;
  const { id } = props.institution;

  const [count, setCount] = useState(12);
  const [counta, setCounta] = useState(12);
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amenities, setAmenities] = useState([]);
  const toggle = () => setIsOpen(!isOpen);
  const modalToggle = () => setIsModalOpen(!isModalOpen);
  const [pannel_on, setPannel_on] = useState(true);
  const [pannel_on_2, setPannel_on_2] = useState(true);
  const [addInBulk, setAddInBulk] = useState(false);
  const handleClick = () => {
    setAddInBulk(!addInBulk);
  };

  const sectionColors = (sThemeColor) => {
    document.documentElement.style.setProperty('--section-bg-color', sThemeColor[3]);
    document.documentElement.style.setProperty('--section-headerbg-color', sThemeColor[4]);
    document.documentElement.style.setProperty('--section-headerfont-color', sThemeColor[5]);
    document.documentElement.style.setProperty('--section-font-color', sThemeColor[2]);
    document.documentElement.style.setProperty('--section-heading-color', sThemeColor[1]);
  }
  useEffect(() => {
    if (themeDetails.length > 0) {
      sectionColors(themeDetails)
    }
  }, [themeDetails])
  useEffect(() => {
    if (institution?.guid) {
      getInstitutionServices();
    }
  }, [institution]);


  const getInstitutionServices = () => {
    // setServices(institution?.services);
    setAmenities(institution?.amenities);
  };

  const RenderAmentities = (props) => {
    const { amenities, count } = props;
    if (amenities && amenities.length) {
      return amenities?.slice(0, count)?.map((ament, index) => {
        if (ament.length > 0) {
          return (
            <li className="list-group-item nex-pill-list" key={`${ament}_${index}`}>
              <div className="row g-0">
                <div className="col"><h3 className="mb-0 fw-semibold">{ament}</h3></div>
              </div>
            </li>
          );
        }
      });
    }
    return "";
  };

  const ShowMoreServices = (props) => {
    const { services, count, setCount } = props;
    if (services && services.length > count) {
      const remainingCount = services.length - count;
      return (
        <Button
          id="nex-ip-service-more-btn"
          color="link"
          onClick={() => setCount(services.length)}
          className="d-inline-block f-14"
        >
          {`+ ${remainingCount} more`}
        </Button>
      );
    }
    return null;
  };

  return (
    <Row>
      <Col lg={12} className="text-center px-3">
        <div id="section-amenities" className="m-v1">
          {((amenities?.length > 0 && !isAdmin) || isAdmin) && (
            <Card className={`card nexogic-card-color-header ${pannel_on_2 ? 'expanded' : ''} micro-section-card-vs1`}>
              <div className="card-header section-header"><h2>Amenities</h2>
                <button className="collapse-btn" type="button" onClick={() => setPannel_on_2(!pannel_on_2)}>
                  <svg width={17} height={10} viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path
                      d="M2.25 1.625L8.625 8L15 1.625"
                      stroke="black"
                      strokeWidth={3}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                {isEditable &&
                  isAdmin && (
                    <Button
                      id="nex-ip-service-add-btn2"
                      color="link"
                      className="plus-btn-round"
                      onClick={modalToggle}
                    >
                      <img src={plusIcon} alt="" />
                    </Button>

                  )}
              </div>
              <div className={`collapse-div`}>
                <CardBody className="bg-color">
                  {amenities && amenities?.length
                    ? ""
                    : (isEditable || isAdmin) && (
                      <p className="card-text">
                        <Button
                          id="nex-ip-service-add-btn3"
                          className="readmore"
                          color="link"
                          onClick={modalToggle}
                        >
                          {"Add information"}
                        </Button>
                      </p>
                    )}
                  <div className="nexogic-card-list">
                    <ul className="nex-pill-list-w pt-3">
                      <RenderAmentities amenities={amenities} count={counta} />
                    </ul>
                    <ShowMoreServices
                      services={amenities}
                      count={counta}
                      setCount={setCounta}
                    />
                  </div>

                  {!isEditable && isEmpty(currentUser) && (
                    <Row className="py-5 my-4">
                      <Col xs="12">
                        <p className="text-primary font-weight-bold font-italic">
                          {" "}
                          Please Sign In to access the full profile.
                        </p>
                        <Button
                          id="nex-ip-service-login-btn2"
                          color="primary"
                          onClick={() => history.push("/login")}
                        >
                          {"Login"}
                        </Button>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </div>
              <Modal
                isOpen={isModalOpen}
                toggle={modalToggle}
                id="profile-modal"
                centered={true}
                scrollable={true}
                backdrop="static"
                className="skills-modal skills-expertise-modal"
              >
                <ModalHeader toggle={toggle} className="nex-institutions-modal-header">
                  <div className='modal-filter-header nex-text-xl'>
                    {`Amenities`}
                    <span className="ml-auto">
                      <label className='mb-0 mr-2'>Add in Bulk</label>
                      <input className="add_in_bulk mr-3" id="nex-amenities-add-in-bulk" type="checkbox" checked={addInBulk} onClick={() => handleClick()} />
                    </span>
                  </div>
                </ModalHeader>
                <ModalBody bg-white>
                  {/* <ModalHeader toggle={modalToggle}>
                  <div className='modal-filter-header'>{`Amenities`}
                    <span className='ml-auto'>
                      <label className='mb-0 mr-2'>Add in Bulk</label>
                      <input id="add_in_bulk" type="checkbox" checked={addInBulk} onClick={() => handleClick()} />
                    </span>
                  </div>
                </ModalHeader>
                <ModalBody> */}
                  {addInBulk
                    ?
                    <AmentitesFormBulkEdit
                      amenities={amenities}
                      toggle={modalToggle}
                      isAdmin={isAdmin}
                      institution={institution}
                      practitionerId={currentUser?.guid}
                      institutionId={institution?.guid}
                      getInstitutionProfile={getInstitutionProfile}
                    />
                    :
                    <AmentitesForm
                      amenities={amenities}
                      toggle={modalToggle}
                      isAdmin={isAdmin}
                      institution={institution}
                      practitionerId={currentUser?.guid}
                      institutionId={institution?.guid}
                      getInstitutionProfile={getInstitutionProfile}
                    />
                  }
                </ModalBody>
              </Modal>
            </Card>
          )}
        </div>
      </Col>
    </Row>
  );
};
