import React,{useRef} from "react";
import {
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const AlertDialog = (props) => {
  const { isLoading, isOpen, toggle, yesLabel, noLabel, handleClickOnYes } =props;
    const modalRef = useRef();
  const noBtnProps = props.noProperties || {};
  const yesBtnProps = props.yesProperties || {};
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} className="modal-sm nex-alert-modal"  innerRef={modalRef}>
        {props.headerTitle && (
          <ModalHeader toggle={toggle}>{props.headerTitle}</ModalHeader>
        )}
        <ModalBody>
          <h5 className="nex-alert-modal-content">{props.bodyText || "Are you sure, you want to remove this?"}</h5>
          <div className="action-group">
          <Button
            outline
            className="nex-btn-transparent nex-btn-link"
            color="primary"
            onClick={toggle}
            {...noBtnProps}
          >
            {noLabel}
          </Button>{" "}
          {isLoading ? (
            <div className="profile-loader">
              <Spinner color="success" />
            </div>
          ) : (
            <Button
              className="nex-btn-primary "
              color="info"
              onClick={handleClickOnYes}
              {...yesBtnProps}
            >
              {yesLabel}
            </Button>
          )}
          </div>
        </ModalBody>
        
      </Modal>
    </div>
  );
};

export default AlertDialog;
