import React from 'react'
import './css/v1.scss'
import applo_logo_img from 'assets/images/logo-top-icon.png'

function AdsSeven(props) {
  return (
    <div className='nex-ads-wrapper v7'>
        <div className="nex-ads-brand">
            <a href="#"><img src={props.logo_img} alt="" /></a>
        </div>
        <div className="nex-ads-graphic">
            <img src={props.bg} alt="" />
        </div>
        <div className="nex-ads-graphic-back">
            <img src={props.bg_back} alt="" style={{opacity: 0}} />
        </div>
        <div className="nex-ads-main-contain">
            <div className="nex-ads-content-wrap">
                <h2 className='nex-ads-headings' dangerouslySetInnerHTML={{__html: props.title}}></h2>
                <div className="nex-ads-doc-info">
                    <h3 className='nex-title'>{props.doctor_name}</h3>
                    <p className='card-text'>{props.doctor_deg}</p>
                    <p className='card-text'>{props.doctor_desc}</p>
                </div>
            </div>
        </div>
    </div>
  )
}


/* default props */
AdsSeven.defaultProps = {
    bg:'https://source.unsplash.com/vt7iAyiwpf0',
    bg_back:'https://source.unsplash.com/vt7iAyiwpf0',
    logo_img:applo_logo_img,
    logo_url:"#",
    logo_target:"_blank",
    title:"Find Medical Services",
    short_desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    button_name: "Lorem ipsum",
    button_url: "#",
    website:"www.nexogic.com",
    phone:"+0123456789",
    doctor_name: "DR. Amit Majhi",
    doctor_deg:"MBBS",
    doctor_desc:"Lorem ipsum dolor sit amet.",


  }
  
  export default AdsSeven;