import CollapseablePannel from 'common/CollapseablePannel'
import React from 'react'
import { useEffect } from 'react';
import { IdentityService } from 'utils/IdentityService';
import { useState } from 'react';
import { FAILED_TO_FETCH_DATA } from 'utils/Constants';
import { useSelector } from 'react-redux';
import { createImageFromInitials, getMeaningFullErrorMessage, getRandomColor } from 'utils/Utils';
import swal from "sweetalert";
import { withAlert } from 'react-alert';
import UserAddButton from './UserAddButton';
import CommonLoader from 'common/CommonLoader';
import { PractitionerService } from 'utils/PractitionerService';
import LoadMoreButton from 'common/LoadMoreButton/LoadMoreButton';
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import hospital_icon from "assets/images/hospital-icon-2.png";
import no_data_question_icon from "assets/images/no-data-question.png";


export default withAlert()(function AdminUsers({ institution, alert }) {
    const [isLoading, setIsLoading] = useState(true);
    const currentUser = useSelector((state) => state.auth.currentUser);
    const [institutionAdmins, setInstitutionAdmins] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const handleUserRoleDelete = (userId) => {
        swal("Are you sure you want to remove user as Institution Admin?", {
            buttons: ["cancel", "yes"],
        })
            .then((yesRemove) => {
                if (yesRemove)
                    IdentityService.DeleteUsersRole(userId, "INSTITUTION_ADMIN", institution?.guid)
                        .then((response) => {
                            const { status, data } = response;
                            if (status === 204) {
                                showSuccessMessage("User removed as an Institution Admin successfully.");
                                setIsLoading(true);
                                getAllInstitutionAdmin(0, institution?.guid, []);
                            }
                            else {
                                showErrorMessage(getMeaningFullErrorMessage(response.message));
                            }
                        })
            })
    }

    const getAllInstitutionAdmin = async (pageNo, instId, prevData) => {
        IdentityService.GetInstitutionUsers(instId, "INSTITUTION_ADMIN", pageNo)
            .then(async (response) => {
                const { status, data } = response;
                if (data?.currentPage < (data?.totalPages - 1))
                    setHasMore(true);
                else
                    setHasMore(false);
                setIsLoadingMore(false);
                if (status === 200) {
                    setCurrentPage(data.currentPage);
                    setTotalPages(data.totalPages);
                    const institutionAdminsWithDetails = await Promise.all(data.contents.map(async (item) => {
                        let info = null;
                        if (item?.profileGuid) {
                            info = await getMoreUserDetails(item?.profileGuid);
                        }
                        if (item?.profileGuid)
                            return {
                                ...item,
                                firstName: info?.firstName,
                                lastName: info?.lastName,
                                profileId: info?.profileId,
                                primarySpeciality: info?.primarySpeciality,
                                superSpeciality: info?.superSpeciality,
                                avatarId: info?.avatarId
                            };
                        else
                            return {
                                ...item,
                                profileId: info?.profileId,
                                primarySpeciality: info?.primarySpeciality,
                                superSpeciality: info?.superSpeciality,
                                avatarId: info?.avatarId
                            };
                    }));

                    if (pageNo === 0)
                        setInstitutionAdmins(institutionAdminsWithDetails);
                    else
                        setInstitutionAdmins([...institutionAdmins, ...institutionAdminsWithDetails]);
                    setIsLoading(false);
                } else {
                    showErrorMessage(FAILED_TO_FETCH_DATA);
                    setIsLoading(false);
                    setIsLoadingMore(false);
                }
            });
    };

    const getMoreUserDetails = async (id) => {
        return PractitionerService.gePractitionersDetails(id)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    return data;
                } else {
                    return null;
                }
            });
    }

    const fetchData = () => {
        setIsLoadingMore(true);
        getAllInstitutionAdmin(currentPage + 1, institution.guid, institutionAdmins);
    };

    useEffect(() => {
        if (institution && institution.guid) {
            getAllInstitutionAdmin(0, institution.guid, []);
        }
    }, [institution]);

    return (
        <div className='admin-users nex-main-panel'>
            <div className="nex-panel-head">
                <h1 className='nex-text-xl'><span className="icon"><img src={hospital_icon} alt="" width={30} /> </span> Institution Admin
                <span className="nex-panel-action">
                {!isLoading && <UserAddButton institution={institution} getAllInstitutionAdmin={getAllInstitutionAdmin} setIsLoading={setIsLoading} />}
                </span>
                </h1>
            </div>
            <div className="nex-panel-body">
            <CollapseablePannel className="nex-small-gap" title="" noHeader={true} headActions={!isLoading && <UserAddButton institution={institution} getAllInstitutionAdmin={getAllInstitutionAdmin} setIsLoading={setIsLoading} />}>
                <div className="nexogic-user-lists">
                    <div className="header-labels">
                        <div className="col">User Info</div>
                        <div className="col">Role</div>
                        <div className="col">Action</div>
                    </div>
                    {isLoading ?
                        <div className="text-center">
                            <CommonLoader />
                        </div>
                        :
                        <div>
                            {institutionAdmins && institutionAdmins.length > 0 ?
                                institutionAdmins.map((institutionAdmin, index) => (
                                    <div className="nexogic-user-item card" key={index}>
                                        <div className="u-info">
                                            <div className="graphic">
                                                {institutionAdmin?.avatarId ? (
                                                    <img width={72}
                                                        className="card-img card-img-md"
                                                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${institutionAdmin?.profileGuid}/${institutionAdmin?.avatarId}`}
                                                        alt="#"
                                                    />
                                                ) : (
                                                    <img
                                                        className="card-img"
                                                        src={createImageFromInitials(
                                                            64,
                                                            (institutionAdmin?.firstName + " " + institutionAdmin?.lastName),
                                                            getRandomColor(null)
                                                        )}
                                                        alt="profilePic"
                                                        width={64}
                                                    />
                                                )}
                                            </div>
                                            <div className="info">
                                                <h2 className="card-title"><a href={institutionAdmin?.profileId ? `/profile/${institutionAdmin?.profileId}` : null}> {institutionAdmin?.firstName}{" "}{institutionAdmin?.lastName}</a>
                                                    {/* {currentUser?.guid === institutionAdmin?.profileGuid &&
                                                        <span className='light'>(You)</span>
                                                    } */}
                                                </h2>
                                                <div className="card-meta">
                                                    {institutionAdmin?.primarySpeciality &&
                                                        <>
                                                            <div className="card-text">{institutionAdmin?.primarySpeciality} </div>
                                                            {institutionAdmin?.superSpeciality &&
                                                                <div className="card-text">{institutionAdmin?.superSpeciality}</div>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="role">
                                            <div className="card-text">{"Admin"}</div>
                                        </div>
                                        <div className="card-action">
                                            {institutionAdmin?.profileGuid && currentUser?.guid !== institutionAdmin?.profileGuid &&
                                                <a className='btn'><svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg"
                                                    onClick={() => handleUserRoleDelete(institutionAdmin?.id)}
                                                >
                                                    <path d="M1 5H3M3 5H19M3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V5M6 5V3C6 2.46957 6.21071 1.96086 6.58579 1.58579C6.96086 1.21071 7.46957 1 8 1H12C12.5304 1 13.0391 1.21071 13.4142 1.58579C13.7893 1.96086 14 2.46957 14 3V5M8 10V16M12 10V16" stroke="#FF6060" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                </a>
                                            }
                                        </div>
                                    </div>
                                )) :
                                <div>
                                    <div className='nexogic-nodata-dotted gap-y-xl text-center'>
                                        <img src={no_data_question_icon} alt='' width={69} />
                                        <div className='no-suggestion-text text-center'>No Institution User</div>
                                    </div>
                                </div>
                            }
                            {hasMore &&
                                <div className="text-center mt-3">
                                    {isLoadingMore
                                        ?
                                        <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                                        :
                                        <LoadMoreButton loadMore={fetchData} />
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
            </CollapseablePannel>
            </div>
            
            
        </div>
    )
})
