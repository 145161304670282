import { ErrorMessage, useFormikContext } from 'formik';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { MetadataService } from 'utils/MetadataService';

const JobSkillsEditModal = (props) => {
    const { modal, setModal } = props;
    const skillsRef = useRef(null);
    const [allSkillsList, setAllSkillsList] = useState([]);
    const { setFieldValue, values } = useFormikContext();
    const [skills, setSkills] = useState(values?.skills);
    const [popularSkills, setPopularSkills] = useState([]);
    const [inputValue, setInputValue] = useState([]);
    const [popularSkillsToShow, setPopularSkillsToShow] = useState([]);
    const [isSkillSelected, setIsSkillSelected] = useState(false);

    const getPopularSkillsSet = () => {
        MetadataService.getAllSkillSet()
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    const skillsWithDisabledFlag = response.data.map(skill => ({ name: skill, disabled: false }));
                    setPopularSkills(skillsWithDisabledFlag);
                    setPopularSkillsToShow(skillsWithDisabledFlag.slice(0, 10));
                }
            })
            .catch((error) => {
                console.log("MetadataService.setPopularSkills error", error);
            });
    }

    useEffect(() => {
        const getSkillSet = (primarySpecialityItem) => {
            MetadataService.getSpecialitySkillSet(encodeURIComponent(primarySpecialityItem))
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        setAllSkillsList(response.data);
                    }
                })
                .catch((error) => {
                    console.log("MetadataService.getSpecialitySkillSet error", error);
                });
        };

        if (values?.primarySpeciality) {
            getSkillSet(values?.primarySpeciality);
        }
        getPopularSkillsSet();
    }, [values?.primarySpeciality]);

    const removeSkill = (skillToRemove) => {
        const updatedSkills = skills.filter(skill => skill !== skillToRemove);
        setSkills(updatedSkills);
        setFieldValue('skills', updatedSkills);
    };

    const onChangeHandler = (selected) => {
        // Extract the names of the selected items
        const selectedSkills = selected.map(item => item.customOption ? item.name : item);

        // Create a set of existing skills to ensure no duplicates
        const existingSkillsSet = new Set(skills);

        // Merge selected skills with existing skills
        const updatedSkills = [...existingSkillsSet, ...selectedSkills].filter(skill => skill);

        // Update the state and form field value
        setSkills(updatedSkills);
        setFieldValue('skills', updatedSkills);
    };


    const handleSubmit = () => {
        setModal(false);
    }

    const handleLoadMore = (len) => {
        let temp = popularSkills.slice(0, len).map(skill =>
            skills.includes(skill.name) ? { ...skill, disabled: true } : skill
        );
        setPopularSkillsToShow(temp);
    }

    const handleAddSkill = (skill) => {
        if (!skills.includes(skill.name)) {
            const updatedSkills = [...skills, skill.name];
            setSkills(updatedSkills);
            setFieldValue('skills', updatedSkills);
            setPopularSkillsToShow(prevSkills => prevSkills.map(item =>
                item.name === skill.name ? { ...item, disabled: true } : item
            ));
        }
    };


    return (
        <Modal isOpen={modal} toggle={() => setModal(!modal)} className="nex-model-v2 modal-dialog-scrollable modal-dialog-centered">
            <ModalHeader toggle={() => setModal(!modal)}>Edit Apply mode</ModalHeader>
            <ModalBody>
                <div className="nex-job-form-right-sec">
                    <h2 className='nex-text-lg text-black'>Required Skills</h2>
                    <div className="form-group gap-md">
                        <Typeahead
                            ref={skillsRef}
                            allowNew
                            clearButton
                            newSelectionPrefix="Add Skill: "
                            selected={inputValue}
                            id="skills"
                            multiple
                            options={allSkillsList}
                            labelKey={"name"}
                            placeholder="Add your skills ex. Cardiology"
                            onBlur={(e) => {
                                if (skills.length > 0) {
                                    setIsSkillSelected(true);
                                }
                                return skillsRef !== null
                                    ? skillsRef.current.hideMenu()
                                    : null;
                            }}
                            onChange={onChangeHandler}
                        />
                        <ErrorMessage name="skills" render={msg => <span className="text-danger skill-err f-12 d-inline-block mt-1 line-height-error">{msg}</span>} />
                    </div>
                    <>
                        {(skills && skills.length) ? <h2 className='nex-text-md'>Selected Skills</h2> : ""}
                        <div className="form-group gap-md">
                            <div className="nex-pill-group nex-selected-pill-group">
                                {(skills && skills.length > 0)
                                    && skills.map((e, index) => (
                                        <span key={index} className='nex-pill nex-pill-primary'>{e}
                                            <span
                                                className="times"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => removeSkill(e)}
                                            >
                                                &times;
                                            </span>
                                        </span>
                                    ))
                                }
                            </div>
                        </div>
                    </>
                    <div className="form-group gap-md">
                        <h2 className='nex-text-md'>Popular Skills</h2>
                        <div className="nex-pill-group nexogic-custom-scroll">
                            {popularSkillsToShow.length > 0 &&
                                <>
                                    {popularSkillsToShow.map((item, index) => (
                                        <span
                                            key={index}
                                            className={`nex-pill nex-pill-primary ${item.disabled ? 'disabled' : ''}`}
                                            onClick={() => !item.disabled && handleAddSkill(item)}
                                            style={{ cursor: item.disabled ? 'not-allowed' : 'pointer' }}
                                        >
                                            {item.name} +
                                        </span>
                                    ))}
                                    {popularSkills.length > popularSkillsToShow.length && (
                                        <button type="button" className="btn btn-link" onClick={() => handleLoadMore(popularSkillsToShow.length + 10)}>Load More</button>
                                    )}
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="form-group nex-action-flex">
                <button className="btn nex-btn-primary ml-auto" onClick={handleSubmit}>Save</button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default JobSkillsEditModal;