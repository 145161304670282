import React, { useState, useEffect } from "react";
import { Col, Row, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight,faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";
import WeekSchedule from "./WeekSchedule";
import moment from "moment";

import Locker from "assets/images/svgs/Locker.svg"

const createDayTimeIntervals = () => {
  const timeIntervals = [];
  const intervalMinutes = 60; // Per time-slot to show
  const timeToday = new Date();

  timeToday.setHours(0); // Shift Start - (24 hours fomrat)
  timeToday.setMinutes(0);
  let currentInterval = timeToday.setSeconds(0);

  const shiftEnd = new Date();
  shiftEnd.setHours(24); // Shift End - 24 hours fomrat
  shiftEnd.setMinutes(0);
  const closingTime = shiftEnd.setSeconds(0);

  // create intervals of 1hr each
  while (currentInterval < closingTime) {
    timeIntervals.push({
      label:
        ("0" + timeToday.getHours()).slice(-2) +
        ":" +
        ("0" + timeToday.getMinutes()).slice(-2) +
        " " +
        (timeToday.getHours() < 12 ? "AM" : "PM"),
      hours: timeToday.getHours(),
      minutes: timeToday.getMinutes(),
    });
    currentInterval = timeToday.setMinutes(
      timeToday.getMinutes() + intervalMinutes
    );
  }
  return timeIntervals
}

const WeekView = (props) => {
  const daytimeIntervals = createDayTimeIntervals();
 
  const slideDate = (direction)=>{   
    var container = document.getElementById('weekCalendarContainer');
    let scrollCompleted = 0;
    var slideVar = setInterval(function(){
        if(direction === 'left'){
            container.scrollLeft -= 100;
        } else {
            container.scrollLeft += 100;
        }
        scrollCompleted += 100;
        if(scrollCompleted >= 100){
            window.clearInterval(slideVar);
        }
    }, 50);
  };

  return (
    <div id={'weekCalendarContainer'} className="week-view">
      <div className="left-nav-arrow text-center">
        <span className="fa-layers fa-fw" onClick={()=>{slideDate("left")}}>     
          <FontAwesomeIcon size="sm" style={{fontSize:"24px",marginRight : "42px"}} icon={faCircle} color="rgba(227, 242, 248,0.3)"/>
          <FontAwesomeIcon size="sm" className="faicon mr-5" icon={faChevronLeft} transform="shrink-6"/>
        </span>
      </div>      
      <div className="right-nav-arrow">
        <span className="fa-layers fa-fw" onClick={()=>{slideDate("right")}}>     
          <FontAwesomeIcon size="sm" style={{fontSize:"24px",marginLeft : "44px"}} icon={faCircle} color="rgba(227, 242, 248,0.3)"/>
          <FontAwesomeIcon size="sm" className=" ml-5 faicon" icon={faChevronRight} transform="shrink-6"/>
        </span>
      </div>

      <Row className="px-3 m-0 week-view__header">
        <Col sm={1}></Col>
        {props.days.map((day, index) => (
          <Col key={index} className="time-col py-2" style={{width:"140px"}}>
            {day && (
              <div className="font-weight-bold">
                {day.date} ({day.day}) 
                {(day.totalAppointments>0)?
                  <span className="spancntr">{day.totalAppointments}</span>
                  :
                  day.isLocked?
                    <span className="mx-2 pl-1"><img style={{verticalAlign:"text-top"}} src={Locker} alt="Lock" /></span>
                    : <span className="spancntr">{day.totalAppointments}</span>
                }
              </div>
            )}
          </Col>
        ))}
      </Row>
      
      {daytimeIntervals.map((timeInterval, index) => {
        let timeFormat_12 = moment(timeInterval.label, "HH:mm:ss A").format("hh:mm A");
        return (
      <Row key={`row-ti-${index}`} className="m-0 week-view__appt-time-row">
        <Col sm={1} className="week-view__appt-time-row__time-cell">
          <div className="pl-2 day-time">{timeFormat_12}</div>
        </Col>
        {props.days.map((day, index) => (
        <Col key={index} className="week-view__appt-time-row__slot-cell px-0" style={{width:"140px"}}>
          {day && (
            <WeekSchedule timeInterval={timeInterval} selectedDay={day} getAppts={props.getAppts} />
          )}
        </Col>
        ))}
      </Row>
      )})}
    </div>
  )
};

export default WeekView;
