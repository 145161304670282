import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const DropDown = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Public");
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const selectedPrivacy = props.selected_privacy;
  const handleClick = (e) => {
    setSelectedOption(e.currentTarget.textContent);
    selectedPrivacy(e.currentTarget.textContent.toUpperCase());
  };
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret>{selectedOption}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClick}>Public</DropdownItem>
        <DropdownItem onClick={handleClick}>Connections</DropdownItem>
        <DropdownItem onClick={handleClick}>Private</DropdownItem>
        {/* <DropdownItem onClick={handleClick}>Group</DropdownItem> */}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropDown;
