import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Button, Card, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import itemType from '../Themes/ThemeContents/utils';
import { withAlert } from 'react-alert';
import { PractitionerService } from "utils/PractitionerService";
import ThemeCustomTemplate from '../Themes/Components/ThemeOne/ThemeCustomTemplate';
import PreviewWindow from './PreviewWindow';
import { FAILTOSAVERECOED } from 'utils/Constants';
import { InstitutionServices } from 'utils/InstitutionServices';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import secureLocalStorage from 'react-secure-storage';
import { TouchBackend } from 'react-dnd-touch-backend';
import pannel_arrow from "assets/svgIcon/down-arrow-icon.svg";
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisH, faPlus } from '@fortawesome/free-solid-svg-icons';
import Ads from './CustomForms/Ads';
import Search from './CustomForms/Search';
import CustomSection from './CustomForms/CustomSection';
import ManageWidgets from './CustomForms/ManageWidgets';
import ThemeOneTemplate from '../Themes/Components/ThemeOne/ThemeOneTemplate';

const backendForDND = 'ontouchstart' in window ? TouchBackend : HTML5Backend;
const Draggable = ({ id, text, content, type, onDragEnd }) => {
    const [{ isDragging }, drag] = useDrag({
        // type: 'draggable',
        type: itemType.CARD,
        item: { id, text, content },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        // canDrag: () => {
        //     return !droppedItem || droppedItem.id === item.id;
        //   },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
                onDragEnd(item.id);
            }
        },
    });

    return (
        <div ref={drag} className="file-item" style={{ opacity: isDragging ? 0.5 : 1 }}>
            <Card className='content-card text-center'>{text}</Card>
        </div>
    );
}

const pageJson = {
    inst: '',
    theme: 'default',
    themeColor: '',
    themeContainerType: '',
    sectionContent: '',
}

const pageComponents = [
    { id: '1', text: 'About', content: ["AboutUI"] },
    { id: '2', text: 'Gallery', content: ["GalleryUI"] },
    { id: '3', text: 'Business Hours', content: ["BusinessHrUI"] },
    { id: '4', text: 'Specialities', content: ["SpecialitiesUI"] },
    { id: '5', text: 'Services', content: ["ServicesUI"] },
    { id: '6', text: 'Amenities', content: ["AmenityUI"] },
    { id: '7', text: 'Other Locations', content: ["BranchesUI"] },
    { id: '8', text: 'Awards', content: ["AwardUI"] },
    { id: '9', text: 'All Practitioners', content: ["DoctorsUI"] },
    // { id: '12', text: 'Custom Components', content: ["CustomComponentUI"] },
    // { id: '8', text: 'Other BranchesSSS', content:["list",['Delhi Asthama And Diabetes Centre Child Care Clinic']]},
]

const CustomThemeDesign = (props) => {
    const pageData = JSON.parse(secureLocalStorage.getItem("nexogic.temp_admin_inst_data"));
    const { state } = useLocation();
    const history = useHistory();
    const currentUser = useSelector((state) => state.auth.currentUser);
    const [themePColors, setThemePColors] = useState(pageData.themecolors);
    const [otherBranches, setOtherBranches] = useState(pageData.otherBranches);
    const [oBranchFlag, setOBranchFlag] = useState(false);
    const [instituteDetails, setInstituteDetails] = useState(pageData.institutedetails);
    const [eThemeDetails, setEThemeDetails] = useState(pageData.eThemeDetails);
    const layouttype = pageData.layouttype;
    const [modal, setModal] = useState(false);
    const currentUserId = currentUser.guid;
    const [pageContent, setPageContent] = useState(null);
    const [practitioners, setPractitioners] = useState(pageData.practitioners);
    const [awards, setAwards] = useState(pageData.awards);
    const [Loader, setLoader] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [pannel_on, setPannel_on] = useState(true);
    const [pannel_ons, setPannel_ons] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [modalIndex, setModalIndex] = useState(1);
    const [modalType, setModalType] = useState(null);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const toggleWindow = () => {
        setModal(!modal);
        if(refreshFlag)
        window.location.reload();
        setModalType(null);
    };
    const [isEditable, setIsEditable] = useState(true);
    const [isAdmin, setIsAdmin] = useState(true);
    const [allDraggable, setAllDraggable] = useState(0);



    const [draggableItems, setDraggableItems] = useState(pageComponents);

    const [draggableWidgets, setDraggableWidgets] = useState([
        // { id: '10', text: 'AdvertisementS', content: ["AdsUI"] },
        // { id: '11', text: 'Search', content: ["SearchUI"] },
        // { id: '12', text: 'Custom Sections', content: ["CustomSectionUI"] }
        // { id: '1', text: 'Search', content: ["search", "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer."] },
        // { id: '2', text: 'Jobs', content:["job",['Ablation For Treatment Of Atrial Fibrillation', 'Abdominal Hysterectomy', 'Achilles Tendon Rupture Repair', 'asdadddd', 'Acl Anterior Cruciate Ligament Surgery']] },
        // { id: '3', text: 'Features', content:["feature","when an unknown printer took a galley of type and scrambled it to make a type specimen book when an unknown printer took a galley of type and scrambled it to make a type specimen book when an unknown printer took a galley of type and scrambled it to make a type specimen book"]},
    ]);
    const [droppedItemIds, setDroppedItemIds] = useState([]);

    const handleDragEnd = (id) => {
        setDraggableItems(items => items.filter(item => item.id !== id));
        setDraggableWidgets(items => items.filter(item => item.id !== id));
    };

    const handleDroppedItems = (dropElement) => {
        //setDroppedItemIds(dropElement);
    };


    const getInstituteThemeDetails = async (instguid) => {
        await InstitutionServices.getDisplayPreferences(instguid)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    const draggableWidgetswoId = data?.draggables ?? [];
                    const dragIndex = pageComponents.length + 1;
                    const totalDraggableItems = draggableWidgetswoId?.length + dragIndex;//ads draggables length+1(Jobs Widget) + draggableItems index                                                            =)
                    const jobsWidget = { id: (dragIndex).toString(), text: 'Jobs', content: ["JobsUI", []] }
                    // const SearchWidget = {id:(dragIndex+1).toString(), text: 'Search', content: ["SearchUI",[]]}
                    let draggableWidgetsListpre, draggableWidgetsList;
                    if (draggableWidgetswoId) {
                        draggableWidgetsListpre = draggableWidgetswoId.map((eachDraggable, index) => ({
                            ...eachDraggable,
                            id: (dragIndex + index + 1).toString(),
                        })).map((widgetList) => ({
                            id: widgetList.id,
                            text: widgetList.name,
                            content: ['AdsUI', widgetList],
                        }));

                        draggableWidgetsList = [jobsWidget, ...draggableWidgetsListpre];
                    } else {
                        draggableWidgetsList = [jobsWidget];
                    }
                    //   const draggableWidgetsList =[jobsWidget,draggableWidgetsListpre.map((widgetList) => ({
                    //     id:widgetList.id,
                    //     text: widgetList.name,
                    //     content: ['AdsUI',widgetList],
                    //   }))];

                    // console.log("getInstituteThemeDetails---------2-------------",jobsWidget,draggableWidgetsList,totalDraggableItems,draggableItems.length);
                    setPageContent(data);
                    setDraggableWidgets(draggableWidgetsList);
                    setAllDraggable(totalDraggableItems);
                }
                else {
                    setPageContent({});
                }
            })
    }

    // const getCreatedUser = () => {
    //     if (parseInt(currentUserId) === parseInt(instituteDetails?.createdBy)) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   };   

    useEffect(() => {
        if (draggableItems.length > 1) {
            draggableItems.sort((a, b) => a.id - b.id);
        }
    }, [draggableItems.length])

    useEffect(() => {
        if (draggableWidgets.length > 1) {
            draggableWidgets.sort((a, b) => a.id - b.id);
        }
    }, [draggableWidgets.length])

    useEffect(() => {
        setDraggableItems(() => pageComponents);
        // setDraggableItems(items => [...items, { id: menuItem[0], text: menuItem[1], content: menuItem[2] }]);
        getInstituteThemeDetails(instituteDetails?.guid);
    }, [modalIndex])

    const openForm = (pValue) => {
        setModal(true);
        setModalType(pValue);
    };

    const openModalForm = () => {
        switch (modalType) {
            case 'preview':
                return <PreviewWindow themeDetails={themePColors} content={pageContent} institution={instituteDetails} otherBranches={otherBranches} practitioners={practitioners} awards={awards} previewFlag={true} />;
            case 'ads':
                return <Ads {...props} eThemeDetails={pageContent} institution={instituteDetails} setModalIndex={setModalIndex} toggle={toggleWindow} />;
            case 'mwidgets':
                return <ManageWidgets {...props} eThemeDetails={pageContent} setEThemeDetails={setEThemeDetails} institution={instituteDetails} toggle={toggleWindow} setModalIndex={setModalIndex} setRefreshFlag={setRefreshFlag} />
            default:
                return null;
        }
    }

    const onPublish = async () => {
        // console.log("onPublish---------------------------onPublish-----------------------onPublish-----------------",pageContent);
        const content = (
            <div className='d-flex flex-column'>
                <h2 className='text-dark'>Are you sure to publish your Microsite ?</h2>
                <p>Published Microsite will be available for Public View</p>
            </div>
        );
        const { isConfirmed } = await Swal.fire({
            title: ' ',
            html: ReactDOMServer.renderToString(content),
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes',
        });

        if (isConfirmed) {
            setLoader(true);
            InstitutionServices.updateDisplayPreferences(pageContent?.inst, pageContent)
                .then((response) => {
                    const { status, data } = response;
                    if (status === 200) {
                        props.alert.success("Preferences updated successfully");
                    }
                    else {
                        props.alert.error("Something went wrong, Please try again later");
                    }
                })
            setLoader(false);
            secureLocalStorage.removeItem("nexogic.temp_admin_inst_data");
            history.push(`/u/institution/${instituteDetails.profileId}/admin`);
        }
    }
    const [mobFilterOn, setMobFilterOn] = useState(false);
    return (
        Loader ? (
            <div className="d-flex justify-content-center mt-3">
                <div className="loading-spiner">
                    <Spinner style={{ width: "2rem", height: "2rem" }} />
                </div>
            </div>
        ) : (
            <> 
            <div className="nex-micro-theme-builder">
                {/** mobile filter only */}
                <div className="nex-micro-mob-filter">
                        <button className="btn btn-filter nex-micro-filter-btn" onClick={(e)=>setMobFilterOn(!mobFilterOn)}>
                            {mobFilterOn == true &&
                                (<FontAwesomeIcon icon={faChevronCircleDown} className='btn-filter'/>)
                            }
                            {mobFilterOn == false &&
                                (<FontAwesomeIcon icon={faChevronCircleLeft} className='btn-filter'/>)
                            }
                            
                        </button>
                </div>
                <div className={`container-fluid microsite-side-main ${mobFilterOn ? 'filter-on' : ''}`} style={{}}>
                    <main>
                        <DndProvider backend={backendForDND}>
                            <Row>
                                <Col auto className='col-2 nex-left-options-w'>
                                    <Row>
                                        <Col className="card-fixed fixed-top nex-left-options">
                                            <Card className='mb-2 text-center'>
                                                <div className='main-header'>Microsite Contents</div>
                                            </Card>
                                            <Row className="">
                                                <Col md={12} lg={12} className='h-100'>
                                                    <Card className={`nexogic-card-color-header mb-0 ${pannel_on ? 'expanded' : ''}`} id="mcontent">
                                                        <button className={`collapse-btn cst-btn ${pannel_on ? 'flip-180' : ''}`} type="button" onClick={() => setPannel_on(!pannel_on)}>
                                                            <img src={pannel_arrow} alt="pannel_arrow" />
                                                        </button>
                                                        {/* <Card className='vheight'> */}
                                                        <div className={`collapse-div`}>
                                                            {draggableItems.length > 0 ?
                                                                draggableItems.map((item, index) => (
                                                                    <Draggable key={index} id={item.id} text={item.text} type='contents' content={item.content} onDragEnd={handleDragEnd} />
                                                                ))
                                                                : <p className='text-center text-muted py-2'>No Contents</p>}
                                                        </div>
                                                    </Card>
                                                    <Card className='my-2 text-center'>
                                                        <div className='main-header'>Widgets</div>
                                                    </Card>
                                                    <div className={`nexogic-card-color-header mb-0 ${pannel_ons ? 'expanded' : ''}`} id="widgetcontent">
                                                        <Card>
                                                            <button className={`collapse-btn cst-btn ${pannel_ons ? 'flip-180' : ''}`} type="button" onClick={() => setPannel_ons(!pannel_ons)}>
                                                                <img src={pannel_arrow} alt="pannel_arrow" />
                                                            </button>
                                                            <div className={`collapse-div`}>
                                                                {draggableWidgets.length > 0 ?
                                                                    draggableWidgets.map((item, index) => (
                                                                        <Draggable key={index} id={item.id} text={item.text} content={item.content} type='ads' onDragEnd={handleDragEnd} />
                                                                    ))
                                                                    : <p className='text-center text-muted py-2'>No Widgets</p>}
                                                            </div>
                                                        </Card>
                                                        <div className='custom-add-btn'>
                                                            <Dropdown isOpen={isOpen} toggle={toggleMenu}>
                                                                <DropdownToggle caret className='w-100' color="info">
                                                                    Add a Widget
                                                                </DropdownToggle>
                                                                <DropdownMenu className='w-100'>
                                                                    <DropdownItem className='border-top text-center' onClick={() => openForm('ads')}>Advertisements</DropdownItem>
                                                                    {/* <DropdownItem className='border-top text-center' onClick={toggleWindow}>Advertisements</DropdownItem> */}
                                                                    <DropdownItem className='border-top border-bottom text-center' onClick={() => openForm('mwidgets')}>Manage Widgets</DropdownItem>
                                                                </DropdownMenu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="nex-right-content-w col-10">
                                    <Row>
                                        <Col className="pl-0 ml-3 nex-right-content">
                                            <Card className='p-0 border-0'>
                                                <ThemeCustomTemplate themeDetails={themePColors} institution={instituteDetails} setInstituteDetails={setInstituteDetails} pageContent={pageContent} setPageContent={setPageContent} setThemePColors={setThemePColors} setDraggableItems={setDraggableItems} draggableItems={draggableItems} setDraggableWidgets={setDraggableWidgets} allDraggable={allDraggable} droppedItem={(elem) => handleDroppedItems(elem)} isEditable={isEditable} isAdmin={isAdmin} oBranchFlag={oBranchFlag} otherBranches={otherBranches} practitioners={practitioners} awards={awards} layouttype={layouttype} eThemeDetails={eThemeDetails} draggableWidgets={draggableWidgets} modalIndex={modalIndex} />
                                                {/* } */}
                                            </Card>
                                            <div className='pt-3 text-right action micro_actions'>
                                                <Button id="micro_preview_reset" color="primary" className="nexogic_primary_button_outline small micro_preview mr-2" type="button" onClick={() => window.location.reload()}>Reset</Button>
                                                <Button id="micro_preview_cancel" color="warning" className="nexogic_warning_button small micro_preview mr-2" type="button" onClick={() => history.push(`/institution/${instituteDetails.profileId}`)}>Cancel</Button>
                                                <Button id="micro_preview_back" color="primary" className="nexogic_primary_button small micro_preview mr-2" type="button" onClick={() => history.goBack()}>Back</Button>
                                                <Button id="micro_preview" color="success" className="nexogic_success_button small micro_preview mr-2" type="button" onClick={() => openForm('preview')}>Preview</Button>
                                                <Button id="micro_preview_publish" color="primary" className="nexogic_primary_button small micro_preview" type="button" onClick={onPublish}>Publish</Button></div>
                                            {/* <Modal
                                                isOpen={modal}
                                                // toggle={toggleWindow}
                                                backdrop="static"
                                                scrollable
                                                centered
                                                style={{ maxWidth: modalWidth === 'preview' ? '1200px' : '700px' }}
                                            >
                                                <ModalHeader toggle={toggleWindow}>
                                                    <span style={{ color: "gray" }}>
                                                        {modalWidth === 'preview' ? 'Preview'
                                                            : modalWidth === 'ads' ? 'Add an Advertisement'
                                                                : modalWidth === 'search' ? 'Add Serach':''}
                                                                    //: modalWidth === 'csection' ? 'Add a Custom Section' : ''} 
                                                    </span> </ModalHeader>
                                                <ModalBody style={{ backgroundColor: modalWidth === 'preview' ? themePColors[0] : '#ffffff' }}>
                                                <Ads {...props} eThemeDetails={eThemeDetails} institution={instituteDetails} setDraggableWidgets={setDraggableWidgets} toggle={toggleWindow}/>
                                                //    {modalContent}
                                                //    <PreviewWindow themeDetails={themePColors} content={pageContent} institution={instituteDetails} otherBranches={otherBranches} practitioners={practitioners} awards={awards} previewFlag={true} />
                                                </ModalBody>
                                            </Modal> */}
                                            <Modal
                                                isOpen={modal}
                                                toggle={toggleWindow}
                                                backdrop="static"
                                                scrollable
                                                centered
                                                style={{ maxWidth: modalType === 'preview' ? '1200px' : '700px' }}
                                                className='nex-apply-jobs-modal'
                                            >
                                                <ModalHeader toggle={toggleWindow} className='nex-institutions-modal-header'>
                                                <span className="nex-text-xl">
                                                        {modalType === 'preview' ? 'Preview'
                                                            : modalType === 'ads' ? 'Add an Advertisement'
                                                                : modalType === 'mwidgets' ? 'Manage Widgets' : ''}
                                                    </span> 
                                                </ModalHeader>
                                                <ModalBody style={{ backgroundColor: modalType === 'preview' ? themePColors[0] : '#ffffff' }}>
                                                    {openModalForm()}
                                                </ModalBody>
                                            </Modal>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </DndProvider>
                    </main>
                </div >
            </div>
            
            </>
        )
    );
}
export default withAlert()(CustomThemeDesign);
