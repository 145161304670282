import API_Factory from "./API_Factory";
import { LookupTypes } from "./Constants";

const getCountry = () =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_METADATA_SERVICE}/lookups/${LookupTypes.COUNTRY}`,
    ignoreToken: true,
  });

const getStates = (countryId) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_METADATA_SERVICE}/lookups/${countryId}/${LookupTypes.STATE}`,
    ignoreToken: true,
  });

const getCities = (stateId) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_METADATA_SERVICE}/lookups/${stateId}/${LookupTypes.CITY}`,
    ignoreToken: true,
  });

const getLocations = (cityId) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_METADATA_SERVICE}/lookups/${cityId}/${LookupTypes.LOCATION}`,
    ignoreToken: true,
  });

const getPrimarySpecialities = () =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_METADATA_SERVICE}/lookups/${LookupTypes.SPECIALITY}`,
    ignoreToken: true,
  });

const getDegree = () =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_METADATA_SERVICE}/lookups/${LookupTypes.DEGREE}`,
    ignoreToken: true,
  });

const getSpecialitySkillSet = (typeID) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_METADATA_SERVICE}/lookups/${typeID}/${LookupTypes.SPECIALITY_SKILL_SET}`,
    ignoreToken: true,
  });

const getCourses = (degree) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_METADATA_SERVICE}/lookups/${degree}/${LookupTypes.DEGREE_COURSE}`,
    ignoreToken: true,
  });

const getAllSkillSet = () =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_METADATA_SERVICE}/lookups/${LookupTypes.SKILL_SET}`,
    ignoreToken: true,
  });

const getLanguages = () =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_METADATA_SERVICE}/lookups/${LookupTypes.LANGUAGE}`,
    ignoreToken: true,
  });

const getServices = () =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_METADATA_SERVICE}/lookups/${LookupTypes.SERVICE_OFFERED}`,
    ignoreToken: true,
  });

const getAmenity = () =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_METADATA_SERVICE}/lookups/${LookupTypes.AMENITY}`,
    ignoreToken: true,
  });

const getCitiesWithoutStateId = (query) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_METADATA_SERVICE}/city/autocomplete?sq=${query}&cn=India`,
    ignoreToken: true,
  });

export const MetadataService = {
  getCountry,
  getStates,
  getCities,
  getLocations,
  getPrimarySpecialities,
  getDegree,
  getSpecialitySkillSet,
  getAllSkillSet,
  getCourses,
  getLanguages,
  getServices,
  getCitiesWithoutStateId,
  getAmenity,
};
