import React, { useState } from "react";
import connectionslgIcon from "assets/images/svgs/connections_lg_icon.svg";

const NoConnection = ({ content }) => {
  return (
    <div className="nexogic-not-found-text v2 bg-white">
      <div className="no-suggestion-text text-center">
        <div><img src={connectionslgIcon} className="icon" width={35} /></div>
        {content}
      </div>
    </div>
  );
};

export default NoConnection;
