import Header from "common/Header";
import VerifyEmail from "components/OTPFlow/VerifyEmail";
import React, { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useHistory, useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import { IdentityService } from "utils/IdentityService";

const ClaimProfileEmailVerification = () => {
  const history = useHistory();
  const { state } = useLocation();
  const [phone, setPhone] = useState();
  const [userType, setUserType] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [userProfileId, setUserProfileId] = useState("");
  // const location = useLocation();

  useEffect(() => {
    if(state.data){
      const { profileId } = state.data;
      setPhone(state.data.phone);
      setUserType(state.data.userType);
      // setPhone(state.data.phone);
      if (profileId) {
        setUserProfileId(profileId);
      }
    }
  }, [state]);

  // useEffect(() => {
  //   const { state } = location;
  //   const { data } = state;
  //   const { profileId,phone,userType } = data;
  //   if (profileId) {
  //     setUserProfileId(profileId);
  //   }
  // }, [location,state]);

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
  }, [errorMessage]);

  const onSubmit = (values, { setSubmitting }) => {
    values.userType=userType;
    const payload = {
      phoneNumber: phone,
      emailAddress: values.email,
      // type: "practitioner",
      type: userType,
      rtType: userProfileId ? "CLAIM" : "REGISTRATION",
      profileId: userProfileId,
    };
    // console.log("ClaimProfileEmailVerification..............",payload);
    IdentityService.CreateRegistration(payload, "EMAIL", payload.rtType)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          history.push(`/register/otp-verification/email`, {
            data: {
              ...values,
              guid: response.data.id,
              profileId: userProfileId,
            },
          });
        } else {
          if (response.validationErrors) {
            response.validationErrors.map((err) =>
              setErrorMessage(`${err.field}: ${err.message}`)
            );
          } else if (response.message) {
            setErrorMessage(response.message);
          }
        }
      })
      .catch((error) => {
        console.log("IdentityService.RegisterNewUser error", error);
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  
// console.log("Email Verification----------------",state.data);
  return (
    <section id="claimProfile">
      <div className="nexogic-bg-light">
        <Header />
        <div className="theme-body-bg nexogic-dashboard-card">
          <Container className="nexogic-container-gap">
            <VerifyEmail handleSubmit={onSubmit} error={errorMessage} />
          </Container>
        </div>
      </div>
    </section>
  );
};

export default withAlert()(ClaimProfileEmailVerification);
