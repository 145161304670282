import {
  GET_ALL_FEEDS,
  GET_SUGGESTED_CONNECTION,
  GET_SHARE_FEED,
  HIDE_POST,
  RESET_ALL_FEEDS,
  GET_ALL,
} from "./Actions";
import API_New from "../utils/API_New";
import axios from "axios";

export const reset_feed_data = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_ALL_FEEDS,
    });
  };
};
export const getFeedCreator = (id, pageNum) => {
  return (dispatch) => {
    // debugger;
    // console.log(id, 2525);
    API_New.get(
      `practitioner-service/practitioners/${id}/feeds/all?pageNum=${pageNum}&pageSize=10`
    ).then((response) => {
      dispatch({
        type: GET_ALL_FEEDS,
        payload: response.data,
      });
    });
  };
};

export const get_latest_feed = (id) => {
  return (dispatch) => {
    // debugger;
    // console.log(id, 2525);
    API_New.get(
      `practitioner-service/practitioners/${id}/feeds/all?pageNum=0&pageSize=1`
    ).then((response) => {
      dispatch({
        type: GET_SHARE_FEED,
        payload: response.data,
      });
    });
  };
};

export const hide_post = (id) => {
  return (dispatch) => {
    dispatch({
      type: HIDE_POST,
      id: id,
    });
  };
};

export const feedLiked = (action, feedId, practitionerId, callback) => {
  return (dispatch) => {
    API_New.post(`practitioner-service/practitioners/reaction/${action}`, {
      react: "like",
      reactOn: feedId,
      type: "FEED",
      user: {
        practitionerId: practitionerId,
      },
    }).then((response) => {
      callback(response.data.status);
    });
  };
};
export const SugesstedConn = (id) => {
  return (dispatch) => {
    axios
      .get(`http://qa-api.nexogic.com/practitioners/857/suggested/`)
      .then((response) => {
        dispatch({
          type: GET_SUGGESTED_CONNECTION,
          payload: response.data.data.practitioners,
        });
      });
  };
};

export const getFeed = (id, pageNum) => {
  return (dispatch) => {
    API_New.get(
      `practitioner-service/practitioners/${id}/feeds/all?pageNum=${pageNum}&pageSize=10`
    ).then((response) => {
      dispatch({
        type: GET_ALL,
        payload: response.data,
      });
    });
  };
};
