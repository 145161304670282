import React, { useState } from 'react'
import { Button } from 'reactstrap';

const LongText = ({ content, limit }) => {
    const [showAll, setShowAll] = useState(false);

    const showMore = () => setShowAll(true);
    const showLess = () => setShowAll(false);

    if (content?.length <= limit) {
        return <span className='text-content'>{content}</span>
    }
    if (showAll) {
        return <span className='text-content'>
            {content}
            <Button color='link' onClick={showLess}>Read less</Button>
        </span>
    }
    const toShow = content?.substring(0, limit) + "...";
    return <span className='text-content'>
        {toShow}
        <Button color='link' onClick={showMore}>Read more</Button>
    </span>
}

export default LongText;