import React from "react";
import { useState } from "react";
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, FormGroup, Input, ListGroup, ListGroupItem, Row } from "reactstrap";
import edit_icon from 'assets/images/svgs/small-edit-btn.svg';
import symtoms_icon from 'assets/images/pregnancy.png'
import allergies_icon from 'assets/images/allergy.png'
import surgeries_icon from 'assets/images/surgery-room.png'
import tick_check from 'assets/images/nex-completed-tick.png'
import { withAlert } from "react-alert";
import SymptomsForm from "./subForms/SymptomsForm";
import AllergiesForm from "./subForms/AllergiesForm";
import SurgeriesForm from "./subForms/SurgeriesForm";

const MedicalHistoryForm1 = ({ currentStep, setCurrentStep, isSelf, setIsSelf, values }) => {
    const [symptomsModal, setSymptomsModal] = useState(false);
    const [allergiesModal, setAllergiesModal] = useState(false);
    const [surgeriesModal, setSurgeriesModal] = useState(false);

    const [isModalshow, setIsModalshow] = useState(false);
    const [symptoms_selected, setSymptoms_selected] = useState([]);
    const [allergies_selected, setAllergies_selected] = useState([]);
    const [surgeries_selected, setSurgeries_selected] = useState([]);

    return (
        <>
            <CardBody className={"nex-form-step active"}>
                <div className="nex-form-field-container">
                    <div className="nex-form-group">
                        <div className="nex-form-group-c">

                            {!isModalshow &&
                                <label htmlFor="" className="nex-text-xxl">Please specify your medical Symptoms, Allergies, Surgeries which are applicable? </label>
                            }
                            <div className="nex-symptoms-icon-box">
                                {!isModalshow &&
                                    <ButtonGroup className="nex-radio-card">
                                        <div className="nex-radio-item">
                                            <Button
                                                onClick={() => { setSymptomsModal(!symptomsModal); setIsModalshow(!isModalshow); }}
                                                color="primary"
                                                outline
                                                className={`nex-radio-card-item-v2 ${values?.symptoms.length > 0 ? 'active' : ''}`}
                                            >
                                                <div className="graphic">
                                                    <img src={symtoms_icon} alt="" />
                                                </div>
                                                <div className="label">Symptoms</div>
                                                <div className="check">
                                                    <img src={tick_check} alt="" width={10} />
                                                </div>
                                            </Button>
                                            <div className="action nex-flex">
                                                <span className="nex-text label text-truncate" title={values?.symptoms.join(', ') || '' }>
                                                    {values?.symptoms &&
                                                        values?.symptoms.map((e, i) => (
                                                            <span> {`${i != 0 ? ", " : ""}`} {e}</span>
                                                        ))
                                                    }
                                                </span>
                                                {values?.symptoms.length > 0 &&
                                                    <span className="btn">
                                                        <img src={edit_icon}
                                                            onClick={() => { setSymptomsModal(!symptomsModal); setIsModalshow(!isModalshow); }}
                                                        />
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        <div className="nex-radio-item">
                                            <Button
                                                color="primary"
                                                outline
                                                className={`nex-radio-card-item-v2 ${values?.allergies.length > 0 ? 'active' : ''}`}
                                                onClick={() => { setAllergiesModal(!allergiesModal); setIsModalshow(!isModalshow); }}
                                            >
                                                <div className="graphic"><img src={allergies_icon} alt="" /></div>
                                                <div className="label">Allergies</div>
                                                <div className="check">
                                                    <img src={tick_check} alt="" width={10} />
                                                </div>
                                            </Button>
                                            <div className="action nex-flex">
                                                <span className="nex-text label text-truncate" title={values?.allergies.join(', ') || '' }>
                                                    {values?.allergies &&
                                                        values?.allergies.map((e, i) => (
                                                            <span> {`${i != 0 ? ", " : ""}`} {e}</span>
                                                        ))
                                                    }
                                                </span>
                                                {values?.allergies.length > 0 &&
                                                    <span className="btn">
                                                        <img src={edit_icon}
                                                            onClick={() => { setAllergiesModal(!allergiesModal); setIsModalshow(!isModalshow); }}
                                                        />
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        <div className="nex-radio-item">
                                            <Button
                                                color="primary"
                                                outline
                                                className={`nex-radio-card-item-v2 ${values?.surgeries.length > 0 ? 'active' : ''}`}
                                                onClick={() => { setSurgeriesModal(!surgeriesModal); setIsModalshow(!isModalshow); }}
                                            >
                                                <div className="graphic"><img src={surgeries_icon} alt="" /></div>
                                                <div className="label">Surgeries</div>
                                                <div className="check">
                                                    <img src={tick_check} alt="" width={10} />
                                                </div>
                                            </Button>
                                            <div className="action nex-flex">
                                            <span className="nex-text label text-truncate" title={values?.surgeries.join(', ') || '' }>
                                                {values?.surgeries &&
                                                    values?.surgeries.map((e, i) => (
                                                        <span> {`${i != 0 ? ", " : ""}`} {e}</span>
                                                    ))
                                                }
                                            </span>
                                            {values?.surgeries.length > 0 &&
                                                <span className="btn">
                                                    <img src={edit_icon}
                                                        onClick={() => { setSurgeriesModal(!surgeriesModal); setIsModalshow(!isModalshow); }}
                                                    />
                                                </span>
                                            }
                                            </div>
                                        </div>

                                    </ButtonGroup>
                                }
                                {/* modals */}

                                <SymptomsForm
                                    modal={symptomsModal}
                                    toggle={() => { setSymptomsModal(!symptomsModal); setIsModalshow(!isModalshow); }}
                                    symptoms_selected={symptoms_selected}
                                    setSymptoms_selected={setSymptoms_selected}
                                />
                                <AllergiesForm
                                    modal={allergiesModal}
                                    toggle={() => { setAllergiesModal(!allergiesModal); setIsModalshow(!isModalshow); }}
                                    allergies_selected={allergies_selected}
                                    setAllergies_selected={setAllergies_selected}
                                />
                                <SurgeriesForm
                                    modal={surgeriesModal}
                                    toggle={() => { setSurgeriesModal(!surgeriesModal); setIsModalshow(!isModalshow); }}
                                    surgeries_selected={surgeries_selected}
                                    setSurgeries_selected={setSurgeries_selected}
                                />
                                {!isModalshow &&
                                    <div className="nex-modal-actions mb-2 d-flex justify-content-end mt-10">
                                        <div className="nex-tips-box-card">

                                            <div className="title">Tips</div>
                                            <div className="info">Select the above  options.  Multi select Options to select symptoms and
                                                other options from the list.</div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {/** select list pages */}

                </div>
            </CardBody>
        </>
    )
}
export default withAlert()(MedicalHistoryForm1);