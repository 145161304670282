import { Button, Card, Col, Nav, NavItem } from 'reactstrap';
import SoComment from './SoComment';
import SoDocument from './SoDocument';
import { Link } from "react-router-dom";
import { FAILED_TO_FETCH_DATA } from 'utils/Constants';
import { OpinionService } from 'utils/OpinionService';
import { useEffect, useState } from 'react';
import { createImageFromInitials, dateFormat, getAge, getGenderDetails, getRandomColor } from 'utils/Utils';
import moment from 'moment';
import { encrypt } from 'utils/EncryptDecrypt';
import { PatientService } from 'utils/PatientService';
import OpinionDocument from './OpinionDocument';

const MyOpinionInfo = ({ opinion }) => {
    const [patientInfo, setPatientInfo] = useState({});

    const getPatientInfo = () => {
        if (opinion?.requestedBy === opinion?.requestedFor) {
            PatientService.getPatientById(opinion?.requestedBy)
                .then((response) => {
                    const { status, data } = response;
                    if (status === 200) {
                        setPatientInfo(data);
                        // console.log(data);
                    } else {
                        console.log(FAILED_TO_FETCH_DATA);
                    }
                })
        } else {
            PatientService.getRelatedPersonById(opinion?.requestedBy, opinion?.requestedFor)
                .then((response) => {
                    const { status, data } = response;
                    if (status === 200) {
                        setPatientInfo(data);
                        // console.log(data);
                    } else {
                        console.log(FAILED_TO_FETCH_DATA);
                    }
                })
        }
    }

    useEffect(() => {
        getPatientInfo();
    }, [opinion?.id])

    return (
        <Card className="nex-list-card-v2 nexogic-so-list-card" >
            <div className="card-body">
                <div className="nex-stack nex-so-stack">
                    <div className="nex-avatar-info nex-flex md">
                        <div className="graphic">
                            <img src={createImageFromInitials(100, patientInfo?.firstName + " " + patientInfo?.lastName, getRandomColor(encrypt(21).toString()))} className="nex-avatar lg" width="70" height="70" />
                        </div>
                        <div className="info w-full">
                        
                                {patientInfo?.id ?
                                    <h2 className="nex-text-xl stack-title text-truncate"><Link className="text-black" to={`/patient-profile/${patientInfo?.id}`}>{patientInfo?.firstName}{" "}{patientInfo?.lastName}</Link></h2>
                                    :
                                    <h2 className="nex-text-xl stack-title text-truncate"><span>{patientInfo?.firstName}{" "}{patientInfo?.lastName}</span></h2>
                                }
                                {opinion?.speciality ? <p className="nex-text-lg bold nex-text-blue text-truncate">{opinion?.speciality}</p> : null}
                                <p className="nex-text text-black">Phone : {patientInfo?.cellularPhoneNumber || 'N/A'}</p>
                        </div>
                    </div>
                    <div className="info md">
                        <p className="nex-text text-black">Age  :  {getAge(patientInfo?.dateOfBirth) || 'N/A'}</p>
                        <p className="nex-text text-black">Gender  : {getGenderDetails(patientInfo?.gender) || 'N/A'}</p>
                        <p className="nex-pill sm primary bold">{opinion?.status}</p>
                    </div>
                    <div className="info md">
                        <p className='nex-text text-black'>Date  : {`${moment(opinion?.createdOn).format(
                                "Do MMM, YYYY"
                            )}`}</p>
                         <div className="nex-text text-black"><OpinionDocument id={'so_document_1'} opinionId={opinion.id} /></div>
                    </div>
                    <div className="action ml-auto my-auto">
                        <Link to={`/u/medicalcases/my-opinions/${opinion?.id}`} className="btn nex-btn-primary ">More Details</Link>
                    </div>
                    

                </div>
                {opinion?.description && 
                    <div className="nex-description">
                        <h3 className='nex-text-lg bold text-black'>Description</h3>
                        <div className='nex-description-content' dangerouslySetInnerHTML={{ __html: opinion?.description }}></div>
                    </div>
                }
                {/** old code */}
                <div className="row need-margin reason-sec d-none">
                    <div className="col-lg-12">
                        <hr />
                        <h3 className="card-title"> Description:</h3>
                        <div className="card-text">
                            <p style={{ overflow: "hidden" }}>
                                <div dangerouslySetInnerHTML={{ __html: opinion?.description }}></div>
                            </p>
                        </div>
                        <div className="card-text d-flex"><div className="mr-auto"><Link to={`/u/medicalcases/my-opinions/${opinion?.id}`} className="nexogic_primary_button_outline ">More Details</Link></div></div>
                    </div>
                </div>

                <div className="nexogic-meta-description d-none">
                    <div className="row need-margin">
                        <div className="col"><p className="card-text"><span className="icon"><svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 8V17C1 19.2091 2.79086 21 5 21H15C17.2091 21 19 19.2091 19 17V8M1 8V6.5C1 4.29086 2.79086 2.5 5 2.5H15C17.2091 2.5 19 4.29086 19 6.5V8M1 8H19M14 1V4M6 1V4" stroke="#5EA9C6" strokeWidth="1.5" strokeLinecap="round"></path></svg></span>  <b>Date  </b>
                            {`${moment(opinion?.createdOn).format(
                                "Do MMM, YYYY"
                            )}`}</p></div>
                        {/* <div className="col"><SoComment id={'so_connemt_1'} /></div> */}
                        <div className="col-auto"><OpinionDocument id={'so_document_1'} opinionId={opinion.id} /></div>
                    </div>
                </div>
                {/** old code end here */}
            </div>
        </Card>
    )
}

export default MyOpinionInfo;