import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap';
import blue_bullets from "assets/images/blue-bullets.png";

 function List(props) {
  return (
    <Card>
        <CardBody>
            <ul className={`list-group list-group-flush nex-custom-lists${props.column ? ' column-'+props.column : ''}`}>
                {props.list.length > 0 &&  props.list.map(item=>{
                    return (
                        <li className='list-group-item'>
                            <Row className="g-0">
                                <Col xs="auto" className={`icon${props.icon ? 'custom-icon' :'dot-icon'}`}>
                                    <img src={props.icon ? props.icon : blue_bullets} alt="" />
                                </Col>
                                <Col>
                                    
                                        <div className="card-title fw-semibold mb-0">
                                            {item}
                                        </div>
                                    
                                </Col>
                            </Row>
                        </li>
                    
                    )
                })}
                
            </ul>
        </CardBody>
    </Card>
  )
}
List.defaultProps = {
    list: [],
    icon:null,
    column:null
}

export default List;
