import API from "./API_Factory";
import API_Factory from "./API_Factory";

// User registration services
const CreateRegistration = (data, mode, rtType = "REGISTRATION") =>
  API({
    method: "POST",
    url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/register?ut=${data.type}&rt=${rtType}&vm=${mode}`,
    data,
    ignoreToken: true,
  });

const VerifyRegistrationOTP = (guid, type, data) =>
  API({
    method: "PUT",
    url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/register/${guid}/verify`,
    data,
    ignoreToken: true,
  });

const ResendRegistrationOTP = (guid, type, data) =>
  API({
    method: "PUT",
    url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/register/${guid}/resend`,
    data,
    ignoreToken: true,
  });

// const CreateRegistration = (data) =>
//     API({
//         method: 'POST',
//         url: `/api/register`,
//         data,
//         ignoreToken: true
//     })

const AddPersonalDetails = (data, guid) =>
  API({
    method: "PUT",
    url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/register/${guid}/personal_details?registration_id=${guid}`,
    data,
    ignoreToken: true,
  });

const GetVerificationMode = (guid) =>
  API({
    method: "GET",
    url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/register/${guid}`,
    ignoreToken: true,
  });

const MedicalRegistration = (data, guid) =>
  API({
    method: "PUT",
    url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/register/${guid}/personal_details?registration_id=${guid}`,
    data,
    ignoreToken: true,
  });

const CreateRegistrationWithEmail = (guid, data) =>
  API({
    method: "POST",
    url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/api/register/${guid}/email`,
    data,
    ignoreToken: true,
  });

// const VerifyRegistrationOTP = (guid, type, data) =>
//     API({
//         method: 'PUT',
//         url: `/api/register/${guid}/${type}/otp`,
//         data,
//         ignoreToken: true
//     })

// const ResendRegistrationOTP = (guid, type, data) =>
//     API({
//         method: 'POST',
//         url: `/api/register/${guid}/${type}/otp`,
//         data,
//         ignoreToken: true
//     })

const CompleteRegistration = (guid, data) =>
  API({
    method: "PUT",
    url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/api/register/${guid}`,
    data,
    ignoreToken: true,
  });

// user login services
const Authentication = (data) =>
  API({
    method: "POST",
    url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/auth/sign-in`,
    data,
    ignoreToken: true,
  });

// user password services

const ForgotPassword = (data) =>
  API({
    method: "POST",
    url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/auth/forgot-password`,
    data,
    ignoreToken: true,
  });

const VerifyForgotPasswordToken = (token) =>
  API({
    method: "GET",
    url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/auth/reset-password/${token}`,
    ignoreToken: true,
  });

const CompleteForgotPassword = (token, data) =>
  API({
    method: "POST",
    url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/auth/reset-password/${token}`,
    data,
    ignoreToken: true,
  });

const ResetPassword = (data) =>
  API({
    method: "PUT",
    url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/users/reset-password`,
    data,
  });

const GetUserDetails = (accessToken, guid) =>
  API({
    method: "GET",
    url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/users/${guid}`,
    ignoreToken: true,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

const UserLogout = () =>
  API({
    method: "POST",
    url: `/users/logout`,
  });

const GetUserDetailsByGuid = (guid) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/users/${guid}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const GetInstitutionUsers = (instGuid, role, pageNo = 0) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/users/by-tenant?tenant=${instGuid}&scope=${role}&cp=${pageNo}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const AddUsersRole = (userGuid, payload) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/users/${userGuid}/groups`,
        method: "POST",
        responseType: "application/json",
        data: payload,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const DeleteUsersRole = (userGuid, role, instId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/users/${userGuid}/roles/${role}?authorityFor=${instId}`,
        method: "DELETE",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

//New APIs for Java 17
const RefreshUserToken = (data) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_GOOGLE_SEC_TOKEN_URL,
        url: `/v1/token?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
        method: "POST",
        ignoreToken: true,
        responseType: "application/json",
        data,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const UserAuthentication = (accessToken) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/users/me`,
        method: "GET",
        responseType: "application/json",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const AddUserDetails = (accessToken, userdata) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/users`,
        method: "POST",
        responseType: "application/json",
        data: userdata,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const RefreshImpersonateUserToken = (data) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_GOOGLE_IDENTITY_URL,
        url: `/v1/accounts:signInWithCustomToken?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
        method: "POST",
        ignoreToken: true,
        responseType: "application/json",
        data,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

export const IdentityService = {
  CreateRegistration,
  CreateRegistrationWithEmail,
  VerifyRegistrationOTP,
  ResendRegistrationOTP,
  AddPersonalDetails,
  GetVerificationMode,
  MedicalRegistration,
  CompleteRegistration,
  Authentication,
  ForgotPassword,
  VerifyForgotPasswordToken,
  CompleteForgotPassword,
  ResetPassword,
  GetUserDetails,
  UserLogout,
  GetUserDetailsByGuid,
  GetInstitutionUsers,
  AddUsersRole,
  DeleteUsersRole,
  UserAuthentication,
  RefreshUserToken,
  AddUserDetails,
  RefreshImpersonateUserToken,
};
