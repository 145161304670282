import React from 'react';
import {  Modal, ModalHeader, ModalBody } from 'reactstrap';

import RenderAboutForm from "./RenderAboutForm"

export const AboutModal = (props) => {
  const {
    isOpen,
    toggle,
  } = props;
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} id="profile-modal" className="modal-dialog-scrollable modal-dialog-centered" backdrop="static">
        <ModalHeader toggle={toggle}>About</ModalHeader>
        <ModalBody>
          <RenderAboutForm {...props}/>
        </ModalBody>
      </Modal>
    </div>
  );
}

