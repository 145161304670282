import React, { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import { Row, Col, Button, Spinner, FormGroup, Progress } from "reactstrap";
import { withAlert } from "react-alert";
import * as Yup from "yup";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import moment from "moment";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { listOfDocuments } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import CommonLoader from "common/CommonLoader";
import Compressor from "compressorjs";
import { PDFDocument } from 'pdf-lib';
import JSZip from 'jszip';
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const RenderForm = (props) => {
  const [uploadedFile, setUploadedFile] = useState();
  const [addAction, setAddAction] = useState(true);
  const [errorMessageForFile, setErrorMessageForFile] = useState('');
  const [isCompressing, setisCompressing] = useState(false);

  const {
    setErrorMessage,
    updateDocument,
    setSpinner,
    values,
    toggle,
    isValid,
    dirty,
    handleSubmit,
    getSignUrl,
    setFieldValue,
  } = props;

  const handleImageUpload = async (event) => {
    let file = event.target.files[0];
    const unixTime = moment().unix();
    let fileName = file.name;

    // Check if the file size exceeds 10 MB
    if (file.size > 10 * 1024 * 1024) { // 10 MB in bytes
      setErrorMessageForFile('File size exceeds 10 MB.');
      setUploadedFile(null);
      return;
    } else {
      setErrorMessageForFile('');
    }

    // Check if it's a PDF file and size is greater than 3 MB
    if (
      file.type === 'application/pdf' &&
      file.size > 3 * 1024 * 1024 // 3 MB in bytes
    ) {
      // Compress the PDF file
      setisCompressing(true);
      console.log(file.size);
      const compressedPdf = await compressPdfFile(file);
      console.log(compressedPdf.size);
      setisCompressing(false);
      file = new File([compressedPdf], fileName, { type: 'application/pdf' });
    } else if (
      file.size > 3 * 1024 * 1024 && // 3 MB in bytes
      (file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png")
    ) {
      // Compress the image file if its size exceeds 3 MB
      setisCompressing(true);
      console.log(file.size);
      const compressedFile = await compressImageFile(file, fileName);
      console.log(compressedFile.size);
      setisCompressing(false);
      file = new File([compressedFile], fileName, { type: compressedFile.type });
    }

    // Set the uploaded file
    setUploadedFile(file);
    setFieldValue("documentName", file.name, true);
    setFieldValue("uploadDocument", file, true);
  };

  const compressPdfFile = async (file) => {
    try {
      // Read the PDF file
      const fileData = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(fileData);

      // Compress the PDF
      const pdfBytes = await pdfDoc.save();

      // Create a ZIP archive to store the compressed PDF
      const zip = new JSZip();
      zip.file(file.name, pdfBytes);

      // Generate the compressed PDF file
      const zipBlob = await zip.generateAsync({ type: 'blob' });

      // Return the compressed PDF file
      return zipBlob;
    } catch (error) {
      console.error('Error compressing PDF:', error);
      throw error;
    }
  };

  const compressImageFile = (file, fileName) => {
    const targetSizeInBytes = 3 * 1024 * 1024; // 3 MB in bytes
    return new Promise((resolve, reject) => {
      // Function to recursively adjust quality until target size is met
      const compressWithQuality = (quality) => {
        new Compressor(file, {
          quality: quality / 100, // Set quality as a percentage
          success(result) {
            // Check if the compressed file size is less than or equal to the target size
            if (result.size <= targetSizeInBytes) {
              // Resolve the Promise with the compressed file
              resolve(result);
            } else {
              // If compressed size is still greater than target size, decrease quality and retry
              const newQuality = quality - 10; // Adjust the decrement value as needed
              if (newQuality >= 10) {
                // Recursively call compressWithQuality with the new quality value
                compressWithQuality(newQuality);
              } else {
                // If quality drops too low and target size is still not met, reject the Promise
                reject(new Error("Unable to compress file to target size"));
              }
            }
          },
          error(err) {
            // If any error occurs during compression, reject the Promise with the error
            reject(err);
          },
        });
      };

      // Start compression with an initial quality setting
      compressWithQuality(90); // Adjust initial quality as needed
    });
  };


  useEffect(() => {
    setFieldValue("documentCategory", "ID_PROOF", true);
  }, []);

  const renderButton = () => {

    if (values.id) {
      return (
        <Button
          id="nex-rd-document-modal-update-btn"
          className="nexogic_primary_button"
          disabled={!isValid || !dirty}
          color="primary"
          onClick={() => getSignUrl(uploadedFile, values, "update")}
        >
          Update
        </Button>
      );
    } else {
      return (
        <Button
          id="nex-rd-document-modal-add-btn"
          className="nexogic_primary_button"
          color="primary"
          disabled={!isValid || !dirty || !addAction || errorMessageForFile}
          onClick={() => {
            getSignUrl(uploadedFile, values, "add");
            setAddAction(false);
          }}
        >
          Add
        </Button>
      );
    }
  };

  return (
    <form className="" onSubmit={handleSubmit}>
      <div>
        <Row form>
          <Col lg={6}>
            <Field
              id={`documentCategory`}
              name={`documentCategory`}
              type="text"
              label="Document Type"
              component={FormikSelectField}
              inputprops={{
                name: `documentCategory`,
                options: listOfDocuments,
              }}
            />
          </Col>
          <Col lg={6}>
            <Field
              id={`fileName`}
              name={`fileName`}
              type="text"
              label="Document Name"
              component={FormikInputField}
              value={values.fileName}
              placeholder="Enter"
            />
          </Col>
          <Col md={12}>
            <FormGroup className="d-flex align-items-center">
              {!isCompressing
                ?
                <Button Button color="primary" outline className="nexogic-file-upload-btn nexogic_primary_button_outline">
                  Select File
                  <input
                    name="uploadDocument"
                    type="file"
                    onChange={(event) => handleImageUpload(event)}
                    id="uploadDocument"
                    accept="image/jpeg,image/jpg,image/png,application/pdf"
                    title="Upload Document"
                    className="nexogic-file-upload-input"
                  />
                </Button>
                :
                <div className="text-center">
                  <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                </div>
              }
              {errorMessageForFile && <div style={{ color: 'red' }}>{errorMessageForFile}</div>}
              {values.documentName ? (
                <span className="ml-2 f-14 text-muted flex-grow-1 text-truncate">
                  {values.documentName}
                </span>
              ) : null}
            </FormGroup>
          </Col>
        </Row>

        <Row className="justify-content-end  modal-footer mx-0" id="profile-modal">
          <Col xs="auto action-group">
            {renderButton()}
            <Button id="duf_btn_cancel" className="nexogic_primary_button_outline" onClick={toggle}>
              Cancel
            </Button>{" "}

          </Col>

        </Row>
      </div>
    </form >
  );
};

const DocumentUploaForm = (props) => {
  const [existingDocs, setExistingDocs] = useState([]);

  const validationSchema = Yup.object({
    fileName: Yup.string().required("This field is required"),
    documentCategory: Yup.string().required("This field is required"),
    uploadDocument: Yup.string().required("This field is required"),

  }).test('unique', 'A document with this file name and category already exists', function (value) {
    const { fileName, documentCategory } = value;
    let isDuplicate = false;


    existingDocs.map((doc) => {

      if (doc?.documentCategory === documentCategory && doc?.description === fileName) {
        isDuplicate = true;
        return;
      }
    })
    if (isDuplicate) {
      return this.createError({
        message: 'A document with this file name and category already exists',
        path: 'fileName',
      });
    }
    return true;
  });

  const [showSpinner, setSpinner] = useState(false);
  const [uProgress, setUProgress] = useState(0);
  const { selectedItem, practitionerId, getData, toggle, alert } = props;
  const [errorMsg, setErrorMessage] = useState("");

  const getExistingDocs = () => {
    PractitionerService.getDocumentsProfile(practitionerId)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          setExistingDocs(data);
        }
        else {
          console.log("Something Went Wrong");
        }

      })
  }

  useEffect(() => {
    getExistingDocs();
  }, [practitionerId])

  const getSignUrl = async (file, values, action) => {
    setSpinner(true);
    const options = (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      if (percent <= 100) {
        setUProgress(percent);
      }
    };
    const payload = [];
    payload.push({
      documentName: values.fileName,
      documentType: values.documentCategory,
      files: [file],
    });

    PractitionerService.registerDocumentsDetails(
      payload,
      practitionerId,
      options
    )
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          showSuccessMessage("Document uploaded successfully");
          if (uProgress <= 100) {
            setTimeout(() => {
              setUProgress(0);
            }, 1000);
          }
          getData();
          toggle();
          setSpinner(false);
        } else {
          if (response.validationErrors) {
            response.validationErrors.map((err) => {
              showErrorMessage(`${err.field}: ${err.message}`);
            });
          } else if (response.message) {
            showErrorMessage(response.message);
          }
        }
      })
      .catch((error) => {
        console.log(
          "PractitionerService.registerDocumentsDetails error",
          error
        );
        showErrorMessage("server facing problem while Adding Documents");
      });
  };
  /*  const uploadFileURL = (signedUrl, file, title) => {
         const xhr = new XMLHttpRequest();
         xhr.open("PUT", signedUrl, true);
         xhr.onload = () => {
             const status = xhr.status;
             if (status === 200) {
                 generateImageUrl(file, title)
             } else {
                 setErrorMessage("Something went wrong ")
                 setSpinner(false)
             }
         };
 
         xhr.setRequestHeader('Content-Type', file.type);
         xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
         xhr.setRequestHeader('Access-Control-Allow-Headers', 'Origin');
         xhr.setRequestHeader('Access-Control-Allow-Headers', 'Content-Type');
         xhr.setRequestHeader('Access-Control-Allow-Headers', 'X-Auth-Token');
         xhr.setRequestHeader('Access-Control-Allow-Methods', 'PUT');
         xhr.send(file);
     }
 
     const generateImageUrl = async (file, title) => {
         try {
             const response = await API.get("/signed-url?uploadPath=doc/" + file.name + "&action=download");
             if (response && response.data) {
                 const { signedUrl } = response.data.data
                 //const documentUrl = signedUrl.split("?")[0]
                 const payload = {
                     "documentTitle": title,
                     "documentUrl": signedUrl,
                     "documentName": file.name,
                     "documentType": file.type
                 }
                 if (selectedItem.id) {
                     updateDocument(payload)
                 }
                 else {
                     addDocument(payload)
                 }
 
             }
         }
         catch (e) {
             //showErrorMessage("Something went wrong ")
             setErrorMessage("Something went wrong ")
             setSpinner(false)
         }
     }
 
     const addDocument = async (payload) => {
         try {
             const response = await API.post("/practitioners/" + practitionerId + "/documents/", payload);
             if (response && response.data) {
                 showSuccessMessage(response.data.message)
                 getData()
                 toggle()
                 setSpinner(false)
             }
         }
         catch (e) {
             setErrorMessage(e.message)
             setSpinner(false)
         }
     } */

  const updateDocument = async (payload) => {
    /*  const payload = [];
        Object.values(values.documentName).forEach((data, index) => {
             payload.push({
                 documentName: values.documentName[index],
                 documentType: values.documentType[index],
                 files: values.files[index],
             });
         });
         if (
             payload.some((person) => person.documentType === "ID_PROOF") &&
             payload.some((person) => person.documentType === "REGISTRATION_DOCUMENT")
         ) {
             PractitionerService.registerDocumentsDetails(payload, userUUID)
                 .then((response) => {
                     if (response.status === 200 || response.status === 201) {
                         getData()
                         toggle()
                         setSpinner(false)
                     } else {
                         if (response.validationErrors) {
                             response.validationErrors.map((err) => {
                                 showErrorMessage(`${err.field}: ${err.message}`);
                             });
                         } else if (response.message) {
                             showErrorMessage(response.message);
                         }
                     }
                 })
                 .catch((error) => {
                     console.log(
                         "PractitionerService.registerDocumentsDetails error",
                         error
                     );
                     showErrorMessage("server facing problem while Adding Documents");
                 });
         } else {
             showErrorMessage(
                 "Please upload Medical Registration document and Government Issued Photo ID."
             );
         } */
    /*    const { id } = selectedItem
           try {
               const response = await API.put("/practitioners/" + practitionerId + "/documents/" + id, payload);
               if (response && response.data) {
                   showSuccessMessage(response.data.message)
                   getData()
                   toggle()
                   setSpinner(false)
               }
           }
           catch (e) {
               setErrorMessage(e.message)
               setSpinner(false)
           } */
  };



  return (
    <>
      {uProgress > 0 ? (
        <div className="">
          <Progress value={uProgress} max="100">
            {uProgress}
          </Progress>
        </div>
      ) : (
        ""
      )}
      {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={selectedItem}
        children={(props) => {
          return (
            <RenderForm
              setSpinner={setSpinner}
              updateDocument={updateDocument}
              toggle={toggle}
              getSignUrl={getSignUrl}
              setErrorMessage={setErrorMessage}
              {...props}
            />
          );
        }}
      />
    </>
  );
};
export default withAlert()(DocumentUploaForm);
