import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { withAlert } from "react-alert";
import { Formik } from "formik";
import * as Yup from "yup";

import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import CreateEventForm from "./CreateEventForm";

const validationSchema = Yup.object({
  apptFor: Yup.string().required("This field is required"),
  patientName: Yup.string().min(3).max(200).required("This field is required"),
  apptType: Yup.string().required("This field is required"),
  apptDate: Yup.string().required("This field is required"),
  apptPurpose: Yup.string().required("This field is required"),
  apptTimeFrom: Yup.string().required("This field is required"),
  apptTimeTo: Yup.string().required("This field is required"),
});
const validationSchemaColleague = Yup.object({
  apptFor: Yup.string().required("This field is required"),
  patientName: Yup.string().min(3).max(200).required("This field is required"),
  //   apptType: Yup.string().required("This field is required"),
  apptDate: Yup.string().required("This field is required"),
  //   apptPurpose: Yup.string().required("This field is required"),
  apptTimeFrom: Yup.string().required("This field is required"),
  apptTimeTo: Yup.string().required("This field is required"),
});

const CreateEvent = (props) => {
  const [isLoading, setLoading] = useState(false);
  const { isOpen, toggle, practitionerId } = props;
  const [errorMsg, setErrorMessage] = useState("");
  let initialValues = {
    apptFor: "patient",
    apptPurpose: "",
    patientName: "",
    apptType: "office",
    apptDate: "",
    apptTimeFrom: "",
    apptTimeTo: "",
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        id="create-event-modal"
        className="modal-lg modal-dialog-scrollable modal-dialog-centered"
      >
        <ModalHeader toggle={toggle}>Create Event</ModalHeader>
        <ModalBody>
          {/* {isLoading && (
            <div className="loading-spiner text-center">
              <Spinner style={{ width: "2rem", height: "2rem" }} />
            </div>
          )} */}
          {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
          <Formik
            enableReinitialize={true}
            validationSchema={() =>
              Yup.lazy((values) => {
                return values.apptFor === "patient" ? validationSchema:validationSchemaColleague;
              })
            }
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              if (values.apptFor !== "patient") {
                values.apptPurpose = "office"; // Set Default
                values.apptType = "";
              }
              setLoading(true);
              console.log(values);
              setSubmitting(true);
              setTimeout(() => {
                setLoading(false);
                // alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
              }, 2000);
            }}
            children={(props) => {
              return (
                <CreateEventForm
                  toggle={toggle}
                  {...props}
                  practitionerId={practitionerId}
                  isLoading={isLoading}
                />
              );
            }}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default withAlert()(CreateEvent);
