import { useState } from "react";
import { PractitionerService } from "utils/PractitionerService";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FAILED_TO_DELETE_DATA } from "utils/Constants";
import CommonLoader from "common/CommonLoader";
import { Fade, Modal, ModalBody, ModalHeader, Toast, ToastBody, ToastHeader } from "reactstrap";
import { withAlert } from "react-alert";
import { useEffect } from "react";
import wordFileIcon from "assets/images/svgs/word-file-icon.svg";

import pdfFileIcon from "assets/images/pdf-icon-new.png";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const ResumeInfo = (props) => {
    const { docId, doc, practitionerGuid, getProfileResumes } = props;
    const [isFileDownloading, setIsFileDownloading] = useState(false);
    const [viewDoc, setViewDoc] = useState("");

    const [isOpenResume, setisOpenResume] = useState(false);

    function ResumeFunc() {
        setisOpenResume(!isOpenResume);
    }

    const deleteResume = (id) => {
        swal("Are you sure you want to delete this Resume?", {
            buttons: ["cancel", "yes"],
        })
            .then((yesRemove) => {
                if (yesRemove) {
                    PractitionerService.deletePractitionerResume(practitionerGuid, id)
                        .then((response) => {
                            const { status } = response;
                            if (status === 204) {
                                showSuccessMessage("Resume deleted Succesdfully!");
                                getProfileResumes(practitionerGuid);
                            } else {
                                showErrorMessage(FAILED_TO_DELETE_DATA);
                            }
                        })
                }
            })
    }

    const downloadResume = async (docId) => {
        const viewDocument = `${process.env.REACT_APP_API_BASE_URL}practitioner-service/practitioners/${practitionerGuid}/resumes/${docId}`;
        setViewDoc(viewDocument);
    };

    useEffect(() => {
        downloadResume(docId);
    }, [docId])


    return (
        <>

            <li key={docId} >
                <div className="nexogic-document-item">
                    <img src={doc?.mimeType === 'application/pdf' ? pdfFileIcon : wordFileIcon} alt="" style={{ cursor: "pointer", height: '25px', width: '25px' }} onClick={() => ResumeFunc()} />
                    <div className="card-text" style={{ cursor: "pointer" }} onClick={() => ResumeFunc()}>{doc?.fileName}</div>

                    <div className="nex-action-btn" style={{ cursor: "pointer" }}>

                    <svg width="28" height="28" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => deleteResume(doc?.id)}>
<circle cx="9.75" cy="10.5" r="9.75" fill="#FFEAEF"/>
<path d="M6.75 9.14062L7.1486 12.0874C7.31605 13.3253 7.39977 13.9443 7.82375 14.3148C8.24772 14.6853 8.87348 14.6853 10.125 14.6853V14.6853C11.3765 14.6853 12.0023 14.6853 12.4262 14.3148C12.8502 13.9443 12.9339 13.3253 13.1014 12.0874L13.5 9.14062" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.67188 7.44643V7.44643C8.67188 6.64759 9.31946 6 10.1183 6V6C10.9171 6 11.5647 6.64759 11.5647 7.44643V7.44643" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.5 7.45312L6.75 7.45312" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.90625 9.375L9.14732 12.9911" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.327 9.375L11.0859 12.9911" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                
                    </div>
                </div>
            </li>
            {
                isFileDownloading ?
                    <div className="" style={{ zIndex: "111", position: "absolute", top: "0", left: "0", backgroundColor: "#edeff0b0", width: "100%", height: "100%" }}>
                        <div className="component-wrapper"><CommonLoader /></div>
                    </div>
                    :
                    <div className={`cov-letter-preview ${isOpenResume ? 'show' : ''}`}>
                        <Fade className="">
                            <Toast isOpen={isOpenResume}>
                                <ToastHeader toggle={ResumeFunc}>View Resume</ToastHeader>
                                <ToastBody style={{ height: "1000px" }}>
                                    <embed
                                        src={`https://docs.google.com/viewer?url=${viewDoc}&embedded=true`}
                                        style={{ width: "100%", height: "100%" }}
                                    />
                                </ToastBody>
                            </Toast>
                        </Fade>
                    </div>
            }
        </>
    );
}

export default withAlert()(ResumeInfo);