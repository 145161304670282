import {
    APPLY_FILTER,
    HANDLE_REMOVE_FILTER
} from "./Actions";
  
let initialState={
    location: "",
    country: "",
    hospital: "",
    specialty: "",
    university: "",
    field_of_study: ""
}

function ConnectionsFilterReducer(state = initialState, action){
    switch(action.type) {
        case APPLY_FILTER:
            return {
                ...state,
                ...action.payload
            };
        case HANDLE_REMOVE_FILTER:
            return {
                ...state,
                ...action.payload
            };    
        
        default:
            return state;
    }
}

export default ConnectionsFilterReducer;