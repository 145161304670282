import React, { useRef, useState } from 'react';
import { Card, CardBody, CardTitle, ButtonGroup, Button, Table, CardText } from 'reactstrap';
import AppointmentPopup from '../components/AppointmentPopup'
import { useEffect } from 'react';
import moment from "moment";
import CalenderNav from '../components/Calender/CalenderNav';
import WeekTimeCol from '../components/Calender/WeekTimeCol';
import CalenderWeekDays from '../components/Calender/CalenderWeekDays';




export default function WeekCalender() {





    /* calender data  */
    const [calenderData, setCalenderData] =  useState({
        timeSlotPreriod : '15',
        startDate: '2023-06-05T00:00:00',
        endDate: '2023-06-05T17:00:00',
        appointments : [
            {name: "Anima jana", status: 'accepted', startTime:'06 23 2023, 09:00 AM',  endTime:"06 23 2023, 09:30 AM"},
            {name: "Anima jana 2", status: 'pending', startTime:'06 23 2023, 11:15 AM',  endTime:"06 23 2023, 11:30 AM"},
            {name: "Anima jana 2", status: 'pending', startTime:'06 16 2023, 10:15 AM',  endTime:"06 16 2023, 10:30 AM"},
            {name: "Priti Das", status: 'reject', startTime:'06 12 2023, 10:15 AM',  endTime:"06 12 2023, 10:30 AM"},
        ],
        inactiveTimes : [
            {startTime:'06 23 2023, 10:00 AM',  endTime:"06 23 2023, 11:00 AM", desc:""},
            {startTime:'06 17 2023, 09:15 AM',  endTime:"06 17 2023, 09:30 AM", desc:""},
        ] 
    });




    const [popvisible, setPopVisible] = useState(false);
    const [mousePosition, setMousePosition] = useState({
        x: 0,
        y: 0,
    });

    const  handleAppointmentPops = (e) => {
        e.preventDefault();
        const  cur_elm = e.target.getBoundingClientRect();
        const screen_width = window.innerWidth;
        const popup_size = document.querySelectorAll('.nexogic-appointment-popup')[0].clientWidth;
        setPopVisible(true);

       if(screen_width < 992){
        setMousePosition({
        x: (screen_width / 2)  - (popup_size / 2),
        y: cur_elm.top,
        });
       }else{
        setMousePosition({
            //screen_width < (cur_elm.right - rel_pos.x) + popup_size ? cur_elm.left - rel_pos.x : cur_elm.right - rel_pos.x
            x: screen_width < (cur_elm.right + popup_size) ? (cur_elm.left -  popup_size) - 20 : cur_elm.right + 20,
            y: cur_elm.top,
        });
       }
        
    };

 



    return (
        <>
            <div className='row nexogic-week-cal-w'>
                <div className="col-lg-3">
                    <div className="card nexogic-widget-sidebar-card nexogic-widget-patient">
                        <div className="card-body">
                            <h5 className="card-title">Appointment for Patient</h5>
                            <div className="nexogic-quick-patient-details">
                                <div className="graphic nexogic-avatar">
                                    <img src="https://images-dev.nexogic.com/53efb6fd-1ceb-412a-b19d-b65032bde722/a8dd998a-8e20-4ac4-971f-bf91154aa6fc.png" className="" alt="Patient's photo" />
                                </div>
                                <div className="info">
                                    <p className="card-text"><span className='key'>Name</span> John Doe</p>
                                    <p className="card-text"><span className='key'>Age</span> 35</p>
                                    <p className="card-text"><span className='key'>Gender</span> Male</p>

                                </div>
                            </div>
                            <h5 className="card-title">Appointment Type : <span className='light'>Vartial</span></h5>
                        </div>
                    </div>

                    {/* filter  */}
                    <div className="card nexogic-filter-list nexogic-filter-mob-bot type-bullets">
                        <div className="card-body p-0">
                            <ul className="list-group list-group-flush nexogic-aside-lists">
                                <li className="list-group-item">
                                    <a href="#"><span className='nexogic-color-bullet lightgreen'></span> Pending (15)</a>
                                </li>
                                <li className="list-group-item">
                                    <a href="#"><span className='nexogic-color-bullet green'></span> Accept (20)</a>
                                </li>
                                <li className="list-group-item">
                                    <a href="#"><span className='nexogic-color-bullet red'></span> Reject (5)</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div className="col-lg-9 position-relative">
                    <CalenderNav title="Today" intervalNum={7} />
                    <Card className='nexogic-calender-week-card'>
                        <CardBody>
                            <WeekTimeCol />
                            <CalenderWeekDays calenderData={calenderData} popupPos={(e)=>handleAppointmentPops(e)} />
                        </CardBody>
                    </Card>

                   <AppointmentPopup popvisible={popvisible}  mousePosition={mousePosition} closePopup={()=>setPopVisible(false)} />

                </div>



            </div>

        </>
    )
}
