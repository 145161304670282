import React, {useState, useEffect, useMemo} from "react"
import { Container, Row, Col, Input, Button } from 'reactstrap'
import { useHistory, useLocation } from "react-router-dom" 
import { useFormik } from 'formik';
import { withAlert } from "react-alert"
import * as Yup from 'yup';
import { Formik } from 'formik';

import Header from "common/Header"
import Footer from "common/Footer"

import API from "utils/API"
import { encrypt } from "utils/EncryptDecrypt"
import { UserService } from "utils/UserService"
import OtpForm from '../ClaimForms/OtpForm'

const validationSchema = Yup.object({
  verifyNumber: Yup.string().required("This field is required")
})

const initialValues = {
  verifyNumber: ''
}

const ClaimOtpVarification = (props) => {
  const {state} = useLocation()
  const  history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [isOTPExpired, setOTPExpired] = useState(false);

  React.useEffect(() => {
    // protect direct route access
    if(!(state && state.id && (state.phoneNumber || state.email))){
      history.replace("/")
    }
    if (UserService.verifyToken()) {
      // already logged In user cannot claim profile
      history.push('/home')
    }
  }, [])

  const isEmailVerification = useMemo(() => {
    return !!state.email
  })

  const onSubmit = async (values) => {   
    const {phoneNumber, id, email} = state

    if(!isEmailVerification) {
      const payload = {
        mobileNumber: phoneNumber,
        otp: values.verifyNumber,
        practitionerId: id
      }
      
      // For testing routes
      // setTimeout(() => {
      //   history.replace({
      //     pathname: '/claim-profile-signup',
      //     state: { phoneNumber: phoneNumber, id}
      //   })
      // }, 2000);
      // return

      setLoading(true)
      setOTPExpired(true)
      UserService.verifyOTP(payload)
      .then(response => {
          // console.log("verify otp response", response)
          if(response.data.status==="SUCCESS"){
              props.alert.success(response.data.message)
              history.replace({
                pathname: '/claim-profile-signup',
                state: { phoneNumber, id}
              })
          }
          else {
              props.alert.error(response.data.message)
          }
          setLoading(false)
        },
        e => {
            setLoading(false)
            console.log("error", e.message)
            let errmsg = e.message
            if(e.response){
                errmsg = (e.response.data && !!e.response.data.message)? e.response.data.message : errmsg
            }
            props.alert.error(errmsg)
        })
    } else {
      const payload = {
        claimedProfileEmail: email,
        otpCode: values.verifyNumber,
        practitionerId: id
      }
      setLoading(true)
      UserService.verifyOTPEmail(payload)
      .then(response => {
        if(response.data.status==="SUCCESS"){
          props.alert.success(response.data.message)
          history.replace({
            pathname: '/claim-profile-signup',
            state: { email, id}
          })
        }
        else {
          props.alert.error(response.data.message)
        }
        setLoading(false)
      },
      e => {
        setLoading(false)
        console.log("error", e.message)
        let errmsg = e.message
        if(e.response){
            errmsg = (e.response.data && !!e.response.data.message)? e.response.data.message : errmsg
        }
        props.alert.error(errmsg)
      })
    }
  }
  
  const ResendOtp = async () => {  
    const {phoneNumber, email} = state
    if(!isEmailVerification) {
      try {
        UserService.sendOTP(phoneNumber)
        .then(response => {
          const { data } = response;
          if (data.status === "SUCCESS") {
            props.alert.success(data.message) 
            setOTPExpired(false)
          }
          else {
            props.alert.error(data.message)
          }
        },
        e => {
            // console.log("error", e.message)
            let errmsg = e.message
            if(e.response){
                errmsg = (e.response.data && !!e.response.data.message)? e.response.data.message : errmsg
            }
            props.alert.error(errmsg)
        })
      }
      catch (e) {
        props.alert.error(e.message)
      }
    } else {
      try {
        // const response = await API.post("/practitioners/claim-profile/"+email);
        UserService.sendOTPEmail(email)
        .then(response => {
          const { data } = response;
          if (data.status === "SUCCESS") {
            props.alert.success("OTP has been sent successfully in your email") 
          }
          else {
            props.alert.error(data.status.message)
          }
        },
        e => {
          console.log("error", e.message)
          let errmsg = e.message
          if(e.response){
              errmsg = (e.response.data && !!e.response.data.message)? e.response.data.message : errmsg
          }
          props.alert.error(errmsg)
        })
      }
      catch (e) {
        props.alert.error(e.message)
      }
    }
  }

  const tryAnotherWay = () => {
    let eid = encrypt(state.id)
    if(state.phoneNumber)
      props.history.replace(`/claim-profile/${state.userName}/${eid}/?email`)
    else
      props.history.replace(`/claim-profile/${state.userName}/${eid}`)
  }

  const onOTPTimeExpire = () => {
    alert("OPT expired. Please resend OTP.")
    setOTPExpired(true)
  }

  return (
      <section id="claimProfilePage">
        <div className="bg-light">
          <Header />
            <div className="bg-white border-top py-5 content">
              <Container>
                <Row className="pt-3 pb-5 justify-content-center text-center">
                  <Col md={5} sm={8} xs={10}>
                    <h3><strong> Claim Profile Assistance </strong></h3>
                    <p className="mb-4 pb-2">
                      {isEmailVerification? 
                        "You will receive an email with OTP. Enter OTP and get verified."
                        :
                        "You will receive an text message with OTP. Enter OTP and get verified."
                      }
                    </p>

                    <Formik
                      initialValues={initialValues}
                      onSubmit={onSubmit}
                      validationSchema={validationSchema}
                      children={props => {
                        return <OtpForm {...props} />
                      }}
                    />
                    {(isOTPExpired || !!state.email) ?
                      null
                    :
                      <div className="otp-countdown">
                        <CountDown time={60} onComplete={onOTPTimeExpire} />
                      </div>
                    }
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col md={5} sm={8} xs={10} className="d-flex justify-content-between">
                    <div>
                      {(isOTPExpired || isEmailVerification) &&
                        <Button color="link" size="sm" inblock className="rounded-sm text-left" onClick={() => tryAnotherWay()}>Try another way</Button>
                      }
                    </div>
                    <div className="text-right">
                      <Button color="link" size="sm" inblock className="rounded-sm text-right pull-right" onClick={() => ResendOtp()}>Resend OTP</Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          <Footer />
        </div>
      </section> 
    )
}


export default withAlert()(ClaimOtpVarification); 

const CountDown = (props) => {
  const {time, onComplete} = props
  const [t, setTime] = useState(time)

  useEffect(() => {
    if(t>0) {
      setTimeout(()=>{
        setTime(t=>--t)
      }, 1000)
    } else {
      onComplete()
    }
  },[t])

  return (
    <div>
      <span>{`${t} sec(s) left`}</span>
    </div>
  )
}