import API_Factory from "./API_Factory";

const addResponse = (referralId, payload) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/referral-service/${referralId}/responses`,
                method: "POST",
                responseType: "application/json",
                data: payload,
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const addPatientStatus = (referralId, responseId, payload) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/referral-service/${referralId}/responses/${responseId}/patient-status`,
                method: "POST",
                responseType: "application/json",
                data: payload,
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const updatePatientStatus = (referralId, responseId, statusId, payload) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/referral-service/${referralId}/responses/${responseId}/patient-status/${statusId}`,
                method: "PUT",
                responseType: "application/json",
                data: payload,
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const addTreatment = (referralId, responseId, payload) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/referral-service/${referralId}/responses/${responseId}/treatments`,
                method: "POST",
                responseType: "application/json",
                data: payload,
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const getReferralResponse = (id, referralsType) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/referral-service/${id}/responses?referralsType=${referralsType}`,
                method: "GET",
                responseType: "application/json",
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const getMyReferralResponse = (id) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/referral-service/my-referrals/${id}/responses`,
                method: "GET",
                responseType: "application/json",
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const getReferralResponseTreatment = (referralId, responseId, treatmentId) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/referral-service/${referralId}/responses/${responseId}/treatments/${treatmentId}`,
                method: "GET",
                responseType: "application/json",
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

export const ReferralResponseService = {
    addResponse,
    getReferralResponse,
    getMyReferralResponse,
    addPatientStatus,
    addTreatment,
    updatePatientStatus,
    getReferralResponseTreatment
};