import CityAutocompleteTypeaheadField from "components/forms/formikFields/CityAutocompleteTypeaheadField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import PrimarySpecialityTypeaheadField from "components/forms/formikFields/PrimarySpecialityTypeaheadField";
import {
  getJobData,
  updateFilterData,
  useJobFilterDispatch,
  useJobFilterState,
} from "context/JobFilterContext";
import { Field, Form, Formik } from "formik";
import { memo, useEffect, useState } from "react";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";
import { Opportunity_Type } from "utils/Constants";
import { MetadataService } from "utils/MetadataService";
import * as Yup from "yup";
import filter_icon from "../../../../../assets/images/svgs/filter-blue-icon.svg";

const initValues = {
  opportunity: "",
  primarySpeciality: [],
  city: [],
};

const validationSchema = Yup.object().shape({
  opportunity: Yup.string(),
  primarySpeciality: Yup.array().nullable(),
  city: Yup.array().nullable(),
});

const JobFilter = (props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  const [selectedFrom, setSelectedFrom] = useState(initValues);
  const { setPrimarySpeciality, setCity, setOpportunityType, getData } = props;
  const [primarySpecialityList, setPrimarySpecialityList] = useState([]);


  function makeQuery(sqOpportunity, sqCity, sqPrimary) {
    let query = "";
    if (sqOpportunity !== "") {
      query = `(op=="${encodeURIComponent(sqOpportunity)}")`;
    }

    if (sqCity !== "") {
      if (query === "")
        query = `(cy=="${encodeURIComponent(sqCity)}")`;
      else
        query += `;(cy=="${encodeURIComponent(sqCity)}")`;
    }

    if (sqPrimary !== "") {
      if (query === "")
        query = `(ps=="${encodeURIComponent(sqPrimary)}")`;
      else
        query += `;(ps=="${encodeURIComponent(sqPrimary)}")`;
    }

    return query;
  }


  const getprimarySpeciality = () => {
    MetadataService.getPrimarySpecialities()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setPrimarySpecialityList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getprimarySpeciality error", error);
      });
  };

  const handleFilter = (values) => {
    setOpportunityType(values.opportunity);
    setCity(((values.city)[0]?.value)?.split(",")[0])
    setPrimarySpeciality((values.primarySpeciality)[0]);
    getData(0, [], makeQuery(values?.opportunity ?? "", ((values?.city)[0]?.value)?.split(",")[0] ?? "", (values?.primarySpeciality)[0] ?? ""));

    setSelectedFrom({
      opportunity: values?.opportunity || "",
      primarySpeciality:
        values?.primarySpeciality && values?.primarySpeciality.length > 0
          ? values?.primarySpeciality
          : [],
      city: values?.city && values?.city.length > 0 ? values?.city : [],
    });

    toggle();

  };

  useEffect(() => {
    getprimarySpeciality();
  }, []);

  return (
    <>{props.tabularView ? (<>
    <Formik
            initialValues={selectedFrom}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleFilter}
            children={(props) => {
              const { values, handleSubmit } = props;
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="filter-item-check">
                    <div className="form-group" id="nex-jf-primary-speciality">
                      <Field
                        id="primarySpeciality"
                        name="primarySpeciality"
                        autoComplete="primarySpeciality"
                        label="Primary Speciality"
                        className="primary-specialities"
                        type="text"
                        component={PrimarySpecialityTypeaheadField}
                        placeholder="Select Speciality"
                        inputprops={{
                          autoComplete: "off",
                        }}
                        value={values.primarySpeciality}
                        spellCheck={false}
                        specialit={primarySpecialityList}
                      />
                    </div>
                    <div className="form-group" id="nex-jf-city">
                      <Field
                        name="city"
                        id="city"
                        autocomplete="city"
                        type="text"
                        label="City"
                        component={CityAutocompleteTypeaheadField}
                        placeholder="City"
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        id="job_filter_opportunity"
                        name="opportunity"
                        type="text"
                        label="Opportunity Type"
                        component={FormikSelectField}
                        inputprops={{
                          name: `opportunity`,
                          options: Opportunity_Type,
                          defaultOption: "Select",
                          keys: {
                            id: "value",
                            label: "label",
                          },
                        }}
                      />
                    </div>
                    <div className="text-center">
                      <Button id="job_filter_apply" color="info" type="submit" block className="nex-btn-primary round-lg">
                        Apply
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          />
    </>) : (<>
      <Button
        id="PopoverFocus"
        type="button"
        className="filter-btn-wrp"
        onClick={toggle}
      >
        Filters <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.3594 6.5625H1.64062C1.37955 6.5625 1.12917 6.46373 0.944567 6.28791C0.759961 6.1121 0.65625 5.87364 0.65625 5.625C0.65625 5.37636 0.759961 5.1379 0.944567 4.96209C1.12917 4.78627 1.37955 4.6875 1.64062 4.6875H19.3594C19.6204 4.6875 19.8708 4.78627 20.0554 4.96209C20.24 5.1379 20.3438 5.37636 20.3438 5.625C20.3438 5.87364 20.24 6.1121 20.0554 6.28791C19.8708 6.46373 19.6204 6.5625 19.3594 6.5625ZM16.0781 10.9375H4.92188C4.6608 10.9375 4.41042 10.8387 4.22582 10.6629C4.04121 10.4871 3.9375 10.2486 3.9375 10C3.9375 9.75136 4.04121 9.5129 4.22582 9.33709C4.41042 9.16127 4.6608 9.0625 4.92188 9.0625H16.0781C16.3392 9.0625 16.5896 9.16127 16.7742 9.33709C16.9588 9.5129 17.0625 9.75136 17.0625 10C17.0625 10.2486 16.9588 10.4871 16.7742 10.6629C16.5896 10.8387 16.3392 10.9375 16.0781 10.9375ZM12.1406 15.3125H8.85938C8.5983 15.3125 8.34792 15.2137 8.16332 15.0379C7.97871 14.8621 7.875 14.6236 7.875 14.375C7.875 14.1264 7.97871 13.8879 8.16332 13.7121C8.34792 13.5363 8.5983 13.4375 8.85938 13.4375H12.1406C12.4017 13.4375 12.6521 13.5363 12.8367 13.7121C13.0213 13.8879 13.125 14.1264 13.125 14.375C13.125 14.6236 13.0213 14.8621 12.8367 15.0379C12.6521 15.2137 12.4017 15.3125 12.1406 15.3125Z" fill="#009FD7"/>
</svg>
      </Button>
      <UncontrolledPopover
        placement="bottom"
        target="PopoverFocus"
        trigger="legacy"
        isOpen={popoverOpen}
        toggle={toggle}
      >
        <PopoverBody>
          <Formik
            initialValues={selectedFrom}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleFilter}
            children={(props) => {
              const { values, handleSubmit } = props;
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="filter-item-check">
                    <div className="form-group" id="nex-jf-primary-speciality">
                      <Field
                        id="primarySpeciality"
                        name="primarySpeciality"
                        autoComplete="primarySpeciality"
                        label="Primary Speciality"
                        className="primary-specialities"
                        type="text"
                        component={PrimarySpecialityTypeaheadField}
                        placeholder="Select Speciality"
                        inputprops={{
                          autoComplete: "off",
                        }}
                        value={values.primarySpeciality}
                        spellCheck={false}
                        specialit={primarySpecialityList}
                      />
                    </div>
                    <div className="form-group" id="nex-jf-city">
                      <Field
                        name="city"
                        id="city"
                        autocomplete="city"
                        type="text"
                        label="City"
                        component={CityAutocompleteTypeaheadField}
                        placeholder="City"
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        id="job_filter_opportunity"
                        name="opportunity"
                        type="text"
                        label="Opportunity Type"
                        component={FormikSelectField}
                        inputprops={{
                          name: `opportunity`,
                          options: Opportunity_Type,
                          defaultOption: "Select",
                          keys: {
                            id: "value",
                            label: "label",
                          },
                        }}
                      />
                    </div>
                    <div className="text-center">
                      <Button id="job_filter_apply" color="info" type="submit" block className="nex-btn-primary round-lg">
                        Apply
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          />
        </PopoverBody>
      </UncontrolledPopover>
    </>)}
  
      
    </>
  );
};

export default memo(JobFilter);
