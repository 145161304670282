import React,{useEffect,useState} from 'react';
import '../css/style.css'
import { Card, CardBody, CardHeader, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft,faListDropdown } from '@fortawesome/free-solid-svg-icons'; 

import SectionOne from 'assets/images/ui-icons/section-default.jpg'
import SectionTwo from 'assets/images/ui-icons/section-two.jpg'
import SectionThree from 'assets/images/ui-icons/section-three.jpg'
import SectionFour from 'assets/images/ui-icons/section-four.jpg'
// import {SectionOne,SectionTwo,SectionThree,SectionFour} from 'assets/images/ui-icons'

const Section01 = (props) => {
  const {fieldDetails,themeDetails} = props;
  const {id, scontent, slabel} = fieldDetails;
  // const [dropdownOpen, setOpen] = React.useState(false);
  // const [sectionType,setSectionType] = useState('s0');

  const sectionColors = (sThemeColor) => {
    // console.log("sThemeColor----------Section01------------------",sThemeColor);
    document.documentElement.style.setProperty('--section-bg-color', sThemeColor[3]);
    document.documentElement.style.setProperty('--section-headerbg-color', sThemeColor[4]);
    document.documentElement.style.setProperty('--section-headerfont-color', sThemeColor[5]);
    document.documentElement.style.setProperty('--section-font-color', sThemeColor[2]);
    document.documentElement.style.setProperty('--section-heading-color', sThemeColor[1]);
}

// const handleSections = (that)=>{
//   console.log("-------------------",that);
//   if(that.target.id==='s0'){
//     console.log("==============fullwidth",that.target.id);
//     setSectionType(that.target.id);
// }else if(that.target.id==='s1'){
//     console.log("==============rounded",that.target.id);
//     setSectionType(that.target.id);
// }else if(that.target.id==='s2'){            
//     console.log("==============square",that.target.id);
//     setSectionType(that.target.id);
// }else if(that.target.id==='s3'){            
//   console.log("==============square",that.target.id);
//   setSectionType(that.target.id);
// }
// }

useEffect(()=>{
  if(themeDetails.length >0){
    sectionColors(themeDetails)
  }
},[themeDetails[0],themeDetails[1],themeDetails[2],themeDetails[3],themeDetails[4],themeDetails[5]])

  return (
    <>
    <Card className='micro-section-card-vs1'>
      {/* <div className='dropdown-caret'>
      <Dropdown toggle={() => { setOpen(!dropdownOpen) }} isOpen={dropdownOpen} direction='left'>
                {/* <DropdownToggle caret> </DropdownToggle> */}
            {/* <DropdownToggle data-toggle="dropdown" tag="span"><FontAwesomeIcon icon={faCaretLeft} /></DropdownToggle>
                <DropdownMenu onClick={(e)=> handleSections(e)}>
                    <DropdownItem style={{backgroundColor:'gray'}} ><img id='s0' src={SectionOne} width="150" style={{border:'1px solid blue'}}/></DropdownItem>
                    <DropdownItem style={{backgroundColor:'gray'}} ><img id='s1' src={SectionTwo} width="150" style={{border:'1px solid blue'}}/></DropdownItem>
                    <DropdownItem style={{backgroundColor:'gray'}} ><img id='s2' src={SectionThree} width="150" style={{border:'1px solid blue'}}/></DropdownItem>
                    <DropdownItem style={{backgroundColor:'gray'}} ><img id='s3' src={SectionFour} width="150" style={{border:'1px solid blue'}}/></DropdownItem>
                </DropdownMenu>
            </Dropdown> 
      </div> */}
        <CardHeader className='main-header section-header'>{fieldDetails.slabel}</CardHeader>
        <CardBody className=' bg-color'>
        <Row className="row">
                <Col lg={12} className="card-content">{fieldDetails.scontent}</Col>
            </Row>            
        </CardBody>
    </Card>
    </>
  )
}

export default Section01