import React, { useState, useEffect } from "react";
import ProfileIcon from "../../images/profilepic.png";
import PostImage from "../../images/post-image.png";
import PostInputOption from "./PostInputOption";
import ReviewIcon from "../../images/review.png";
import ShareIcon from "../../images/share.png";
import CommentIcon from "../../images/comment.png";
import Dots from "../../images/ellipsis-h-solid.svg";
import IconOpen from "../../images/Home page/calender/Vector.png";
import BlueThumb from "../../images/thum-blue.png";
import { useDispatch } from "react-redux";
import Comment from "../Comments/Comment";
import CommentActive from "../../images/VectorComment.png";
import { feedLiked } from "redux-helpers/FeedActionCreator";
import { useSelector } from "react-redux";
import { get_media_url_s3, Post_actions } from "utils/TalentService";
import {
  getFeedCreator,
  hide_post,
} from "../../../../redux-helpers/FeedActionCreator";
import { fetch_Comment } from "../../../../utils/CommentServie";
import Article_image from "../../images/article_default.png";
import Default_img from "../../../../assets/images/defaultPic.png";
import { ButtonDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import "./Post.css";

const Post = (props) => {
  const {
    user,
    mediaList,
    createdOn,
    text,
    location,
    totalShare,
    totalComments,
    totalLikes,
    likedStatus,
    id,
    comments,
  } = props.feed;

  const practitionerId = useSelector(
    (state) => state.auth.currentUser.practitionerId
  );

  let dispatch = useDispatch();

  const [reportBox, setResportBox] = useState(false);

  const [showComment, setShowComment] = useState(false);

  const showReportBox = () => setResportBox(!reportBox);

  const name =
    (user.title != null ? user.title : "") +
    " " +
    (user.firstName != null ? user.firstName : "") +
    " " +
    (user.middleName != null ? user.middleName : "") +
    " " +
    (user.lastName != null ? user.lastName : "");

  const [noOfComments, setNoOfComments] = useState(0);

  useEffect(() => {
    setNoOfComments(totalComments);
  }, [totalComments]);

  const createdDate = createdOn.split("T")[0];

  const increaseComment = () => {
    setNoOfComments(noOfComments + 1);
  };

  const createdTime = createdOn
    .split("T")[1]
    .split(".")[0]
    .split(":")
    .slice(0, 2)
    .join(":");

  const onLiked = (action, feedId, practionerId, callback) => {
    dispatch(feedLiked(action, feedId, practionerId, callback));
  };

  const showCommentSection = () => {
    setShowComment(!showComment);
  };

  //hide and report post functionality
  let allFeeds = useSelector(
    (state) => state && state.feed && state.feed.allFeeds
  );

  // hide post comment for hide post option
  const hide_post_action = () => {
    let data = {
      feedId: id,
      isHidden: true,
      isReported: false,
      practitionerId: practitionerId,
    };
    Post_actions(data)
      .then((res) => {
        if (res.data.status == "SUCCESS") {
          showSuccessMessage(res.data.message, {
            autoClose: 2000,
          });
          setTimeout(() => {
            dispatch(hide_post(data.feedId));
            setResportBox(false);
          }, 2000);
        } else {
          showErrorMessage(
            res.data.message ? res.data.message : "Something Went Wrong"
          );
        }
      })
      .catch((err) => err);
  };

  //report post api
  const report_post_action = () => {
    let data = {
      feedId: id,
      isHidden: true,
      isReported: true,
      practitionerId: practitionerId,
    };
    Post_actions(data)
      .then((res) => {
        if (res.data.status == "SUCCESS") {
          showSuccessMessage(
            res.data.message,

            {
              autoClose: 2000,
            }
          );
          setTimeout(() => {
            dispatch(hide_post(data.feedId));
            setResportBox(false);
          }, 2000);
        } else {
          showErrorMessage(
            res.data.message ? res.data.message : "Something Went Wrong"
          );
        }
      })
      .catch((err) => showErrorMessage(err.message));
  };

  let date_num = new Date(createdDate);
  let day_from_date = date_num.getDate();
  let year_from_date = date_num.getFullYear();
  let month_from_date = date_num.getMonth();

  let month = [
    "Jan",
    "Feb",
    "March",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let month_in_format = month.filter(
    (item, index) => index === month_from_date
  );

  const shared_createdTime = props.feed?.sharedFeed?.createdOn
    .split("T")[1]
    .split(".")[0]
    .split(":")
    .slice(0, 2)
    .join(":");
  //date for shared feed
  let shared_date = props.feed?.sharedFeed?.createdOn;
  let shared_date_num = new Date(shared_date);
  let shared_day_from_date = date_num.getDate();
  let shared_year_from_date = date_num.getFullYear();
  let shared_month_from_date = date_num.getMonth();

  let shared_month_in_format = month.filter(
    (item, index) => index === shared_month_from_date
  );
  // variable holding the shared feeds
  let Shared_feeds = (
    <div className="container post">
      <div className="row">
        <div className="col-sm-12">
          <div className="post__header">
            <div>
              <img
                className="user-pic"
                src={
                  user.profilePicUrl
                    ? user.firstName == "lokesh"
                      ? user.profilePicUrl
                      : user.profilePicUrl.includes("http")
                      ? Default_img
                      : `${process.env.REACT_APP_API_ENDPOINT}getobject/${user.profilePicUrl}`
                    : Default_img
                }
                alt=""
              />
              <span className="prof_name">{name}</span>
              <span className="date_info">
                {month_in_format.map((month) => month)} {day_from_date},{" "}
                {year_from_date} {createdTime}, {location && location.state}
              </span>
            </div>
            <ButtonDropdown
              className="ml-3 user-profile-menu position-initial"
              isOpen={reportBox}
              toggle={showReportBox}
            >
              <DropdownToggle
                color="link"
                className="min-w-0 p-0 post_dropdown_menu"
              >
                <img src={Dots} alt="" style={{ width: "14px" }} />
              </DropdownToggle>
              <DropdownMenu className="p-0 post_dropdown_menu_box">
                {reportBox && (
                  <div className="report__cls">
                    <div className="post_action_css" onClick={hide_post_action}>
                      Hide post
                    </div>
                    <hr />
                    <div
                      className="post_action_css"
                      onClick={report_post_action}
                    >
                      Report post
                    </div>
                  </div>
                )}
              </DropdownMenu>
            </ButtonDropdown>
          </div>
          <p className="desp">
            {mediaList.length > 0 && mediaList[0].caption === "Article"
              ? text
              : text}
          </p>

          {/* ternerary statements for the data to render from get api call*/}

          <div className="post___body shared_feed_homepage_css">
            <div className="post__header">
              <div>
                <img
                  className="user-pic"
                  src={
                    user.profilePicUrl
                      ? props.feed.sharedFeed?.user?.firstName == "lokesh"
                        ? user.profilePicUrl
                        : user.profilePicUrl.includes("http")
                        ? Default_img
                        : `${process.env.REACT_APP_API_ENDPOINT}getobject/${user.profilePicUrl}`
                      : Default_img
                  }
                  alt=""
                />
                <span className="prof_name">
                  {(props.feed.sharedFeed?.user?.firstName
                    ? props.feed.sharedFeed.user.firstName
                    : "") +
                    " " +
                    (props.feed.sharedFeed?.user?.middleName
                      ? props.feed.sharedFeed.user.middleName
                      : "") +
                    " " +
                    (props.feed.sharedFeed?.user?.lastName
                      ? props.feed.sharedFeed.user.lastName
                      : "")}
                </span>
                <span className="date_info">
                  {shared_month_in_format.map((month) => month)}{" "}
                  {shared_day_from_date}, {shared_year_from_date}{" "}
                  {shared_createdTime},{" "}
                  {props.feed?.sharedFeed && location.state}
                </span>
              </div>
            </div>

            <div className="mb-2" style={{ wordBreak: "break-all" }}>
              {props.feed?.sharedFeed?.text}
            </div>
            {(mediaList.length > 0 &&
              mediaList[0].contentType === "image/jpeg") ||
            (mediaList.length > 0 &&
              mediaList[0].contentType === "image/png") ? (
              <img
                src={
                  mediaList[0].mediaUrl
                    ? `${process.env.REACT_APP_API_ENDPOINT}getobject/${mediaList[0].mediaUrl}`
                    : null
                }
                alt=""
              />
            ) : mediaList.length > 0 &&
              mediaList[0].contentType === "video/mp4" ? (
              <video width="100%" controls loop autoPlay muted>
                <source
                  src={
                    mediaList[0].mediaUrl
                      ? `${process.env.REACT_APP_API_ENDPOINT}getobject/${mediaList[0].mediaUrl}`
                      : null
                  }
                  type="video/mp4"
                />
              </video>
            ) : mediaList.length > 0 &&
              mediaList[0].caption === "Article" &&
              mediaList[0].contentType === "video/mp4" ? (
              <video width="100%" controls loop autoPlay muted>
                <source
                  src={
                    mediaList[0].mediaUrl
                      ? `${process.env.REACT_APP_API_ENDPOINT}getobject/${mediaList[0].mediaUrl}`
                      : null
                  }
                  type="video/mp4"
                />
              </video>
            ) : mediaList.length > 0 &&
              mediaList[0].caption === "Article" &&
              (mediaList[0].contentType == "image/jpeg" ||
                mediaList[0].contentType == "image/png") ? (
              <img
                src={
                  mediaList[0].mediaUrl
                    ? `${process.env.REACT_APP_API_ENDPOINT}getobject/${mediaList[0].mediaUrl}`
                    : null
                }
                alt=""
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-sm-4">
          <PostInputOption
            Icon={ReviewIcon}
            title="Like"
            number={totalLikes}
            likedStatus={likedStatus}
            handleClick={onLiked}
            feedId={id}
            IconOpen={BlueThumb}
          />
        </div>
        <div className="col-sm-4">
          <PostInputOption
            Icon={CommentIcon}
            title="Comment"
            totalComment={noOfComments}
            toggleComment={showCommentSection}
            IconOpen={CommentActive}
            showComment={showComment}
          />
        </div>
        <div className="col-sm-4">
          <PostInputOption
            Icon={ShareIcon}
            title="Share"
            number={totalShare}
            IconOpen={IconOpen}
            user={user}
            mediaList={mediaList.length > 0 ? mediaList : null}
            createdOn={createdOn}
            id={id}
            location={location}
            text={text}
          />
        </div>
      </div>

      {showComment && (
        <Comment
          latestComment={comments}
          id={id}
          increaseComment={increaseComment}
          number={noOfComments}
        />
      )}
    </div>
  );

  return (
    <>
      {props.feed.sharedFeedId !== 0 ? (
        Shared_feeds
      ) : (
        <div className="container post">
          <div className="row">
            <div className="col-sm-12">
              <div className="post__header">
                <div>
                  <img
                    className="user-pic"
                    // src={
                    //   user.profilePicUrl
                    //     ? `${process.env.REACT_APP_API_ENDPOINT}getobject/${user.profilePicUrl}`
                    //     : Default_img
                    // }
                    src={
                      user.profilePicUrl
                        ? user.firstName == "lokesh"
                          ? user.profilePicUrl
                          : user.profilePicUrl.includes("http")
                          ? Default_img
                          : `${process.env.REACT_APP_API_ENDPOINT}getobject/${user.profilePicUrl}`
                        : Default_img
                    }
                    alt=""
                  />
                  <span className="prof_name">{name}</span>
                  <span className="date_info">
                    {month_in_format.map((month) => month)} {day_from_date},{" "}
                    {year_from_date} {createdTime}, {location && location.state}
                  </span>
                </div>

                <ButtonDropdown
                  className="ml-3 user-profile-menu position-initial"
                  isOpen={reportBox}
                  toggle={showReportBox}
                >
                  <DropdownToggle
                    color="link"
                    className="min-w-0 p-0 post_dropdown_menu"
                  >
                    <img src={Dots} alt="" style={{ width: "14px" }} />
                  </DropdownToggle>
                  <DropdownMenu className="p-0 post_dropdown_menu_box">
                    {reportBox && (
                      <div className="report__cls">
                        <div
                          className="post_action_css"
                          onClick={hide_post_action}
                        >
                          Hide post
                        </div>
                        <hr />
                        <div
                          className="post_action_css"
                          onClick={report_post_action}
                        >
                          Report post
                        </div>
                      </div>
                    )}
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
              <p className="desp">
                {mediaList.length > 0 && mediaList[0].caption === "Article"
                  ? text
                  : text}
              </p>

              {/* ternerary statements for the data to render from get api call*/}

              <div className="post___body">
                {(mediaList.length > 0 &&
                  mediaList[0].contentType === "image/jpeg") ||
                (mediaList.length > 0 &&
                  mediaList[0].contentType === "image/png") ? (
                  <img
                    src={
                      mediaList[0].mediaUrl
                        ? `${process.env.REACT_APP_API_ENDPOINT}getobject/${mediaList[0].mediaUrl}`
                        : null
                    }
                    alt=""
                  />
                ) : mediaList.length > 0 &&
                  mediaList[0].contentType === "video/mp4" ? (
                  <video width="100%" controls loop autoPlay muted>
                    <source
                      // src={mediaList[0].mediaUrl ? mediaList[0].mediaUrl : null}
                      src={
                        mediaList[0].mediaUrl
                          ? `${process.env.REACT_APP_API_ENDPOINT}getobject/${mediaList[0].mediaUrl}`
                          : null
                      }
                      type="video/mp4"
                    />
                  </video>
                ) : mediaList.length > 0 &&
                  mediaList[0].caption === "Article" &&
                  mediaList[0].contentType === "video/mp4" ? (
                  <video width="100%" controls loop autoPlay muted>
                    <source
                      src={
                        mediaList[0].mediaUrl
                          ? `${process.env.REACT_APP_API_ENDPOINT}getobject/${mediaList[0].mediaUrl}`
                          : null
                      }
                      type="video/mp4"
                    />
                  </video>
                ) : mediaList.length > 0 &&
                  mediaList[0].caption === "Article" &&
                  (mediaList[0].contentType == "image/jpeg" ||
                    mediaList[0].contentType == "image/png") ? (
                  <img
                    src={
                      mediaList[0].mediaUrl
                        ? `${process.env.REACT_APP_API_ENDPOINT}getobject/${mediaList[0].mediaUrl}`
                        : null
                    }
                    alt=""
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-4">
              <PostInputOption
                Icon={ReviewIcon}
                title="Like"
                number={totalLikes}
                likedStatus={likedStatus}
                handleClick={onLiked}
                feedId={id}
                IconOpen={BlueThumb}
              />
            </div>
            <div className="col-sm-4">
              <PostInputOption
                Icon={CommentIcon}
                title="Comment"
                totalComment={noOfComments && noOfComments}
                toggleComment={showCommentSection}
                IconOpen={CommentActive}
                showComment={showComment}
              />
            </div>
            <div className="col-sm-4">
              <PostInputOption
                Icon={ShareIcon}
                title="Share"
                number={totalShare}
                IconOpen={IconOpen}
                user={user}
                mediaList={mediaList.length > 0 ? mediaList : null}
                createdOn={createdOn}
                id={id}
                location={location}
                text={text}
              />
            </div>
          </div>

          {showComment && (
            <Comment
              latestComment={comments}
              id={id}
              increaseComment={increaseComment}
              number={noOfComments}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Post;
