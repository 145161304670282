import React, {useEffect, useState, useMemo} from 'react'
import Header from "common/Header"
import Footer from "common/Footer"
import { useHistory, useLocation } from "react-router-dom" 
import { Container, Row, Col, Label, Input, InputGroup, Button, FormGroup, FormText, FormFeedback } from 'reactstrap'
import { UserService } from 'utils/UserService'
import * as Yup from 'yup';
import { Formik } from 'formik';
import { withAlert } from "react-alert"

import PhoneNumberForm from './components/ClaimForms/PhoneNumberForm'
import EmailForm from './components/ClaimForms/EmailForm'
import { plainPhoneNumber } from "utils/Utils"
import {  decrypt } from "utils/EncryptDecrypt"
import { emailNonNumericRegEx } from "utils/Constants"

const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{5})(( x| ext)\d{1,5}){0,1}$/;
const validationSchema = Yup.object({
  phoneNumber: Yup.string().matches(phoneRegExp, "Please enter valid number").required("This field is required")
  // phoneNumber: Yup.string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, "Please enter valid number").required("This field is required")
})
const emailValidationSchema = Yup.object({
  email: Yup.string().max(100).matches(emailNonNumericRegEx, 'Please Enter an valid Email').required("This field is required")
})

const initialValues = {
  phoneNumber: '',
  userType: 'practitioner'
}

const ClaimProfile = (props) => {
  const { alert, match: { params: { id, userName } } } = props
  const history = useHistory()
  const [isLoading, setLoading] = useState(false)
  const { search } = useLocation()

  useEffect(() => {
    if (UserService.verifyToken()) {
      // already logged In user cannot claim profile
      history.push('/home')
    }
  }, [])

  const userId = useMemo(() => {
    return id ? decrypt(id) : null
  })

  const isEmailVerification = useMemo(() => {
    return search === "?email"
  })

  const onSubmit = (values, {setSubmitting}) => {
    setLoading(true)

    let phoneNumber = plainPhoneNumber(values.phoneNumber)

    // Testing
    // setTimeout(() => {
    //   history.push({
    //       pathname: '/claimOtp-verification',
    //       state: { phoneNumber: phoneNumber, id: userId, userName}
    //   })
    // }, 2000)
    // return

    UserService.sendOTP(phoneNumber)
    .then(response => {
      const { data } = response;
      if (data.status === "SUCCESS") {
        alert.success(data.message)    
        setLoading(false)

        setTimeout(() => {
          history.push({
              pathname: '/claimOtp-verification',
              state: { phoneNumber: phoneNumber, id: userId, userName}
          })
        }, 2000)
      
      }
      else {
          alert.error(response.data.message)
          setLoading(false)
      }
      setSubmitting(false)
    }, e => {
      setLoading(false)
      console.log("error", e.message)
      let errmsg = e.message
      if(e.response){
        errmsg = (e.response.data && !!e.response.data.message)? e.response.data.message : errmsg
      }
      alert.error(errmsg)
      setSubmitting(false)
    })
  }

  
  const onEmailSubmit = async (values, action) => { 
    // For testing routes
    // setTimeout(() => {
    //     history.push({
    //         pathname: '/claimOtp-verification',
    //         state: { email: values.email, id, userName}
    //       })
    // }, 2000)
    // return

    setLoading(true)
    try {
        // const response = await API.post("/practitioners/claim-profile/"+values.email); 
        UserService.sendOTPEmail(values.email)
        .then(response => {
            // console.log("claim-profile response", response)
            if (response.data.status === "SUCCESS") {
                alert.success(response.data.message)
                action.resetForm() 
                setTimeout(() => {
                    history.push({
                        pathname: '/claimOtp-verification',
                        state: { email: values.email, id: userId, userName}
                    })
                }, 2000)
            }
            else {
                props.alert.error(response.data.message)
            }
            setLoading(false)
        },
        e => {
            setLoading(false)
            console.log("error", e.message)
            let errmsg = e.message
            if(e.response){
                errmsg = (e.response.data && !!e.response.data.message)? e.response.data.message : errmsg
            }
            props.alert.error(errmsg)
        })
    }
    catch (e) {
        props.alert.error(e.message)
        setLoading(false)
    }
  }
    return (
      <section id="claimProfilePage">
        <div className="bg-light">
          <Header />
          <div className="bg-white border-top py-5 content">
            <Container>
              <Row className="py-5 justify-content-center text-center">
                <Col md={5} sm={8} xs={10}>
                  <h3><strong> Claim Profile Assistance </strong></h3>
                  {isEmailVerification? 
                    <p className="mb-4 pb-2">Enter your email address to claim your profile. You will receive an email with OTP.</p>
                  :
                    <p className="mb-4 pb-2">Enter your phone number to claim your profile. You will receive an sms with OTP.</p>
                  }
                  {isEmailVerification ?
                    <Formik
                      initialValues={{
                        email: '',
                        userType: 'practitioner'
                    }}
                      onSubmit={onEmailSubmit}
                      validationSchema={emailValidationSchema}
                      children={props => {
                        return <EmailForm {...props} />
                      }}
                    />
                  :
                    <Formik
                      initialValues={initialValues}
                      onSubmit={onSubmit}
                      validationSchema={validationSchema}
                      children={props => {
                        return <PhoneNumberForm {...props} />
                      }}
                    />
                  }
                </Col>
              </Row>
            </Container>
          </div>

          <Footer />
        </div>
      </section>
    )
}

export default withAlert()(ClaimProfile)