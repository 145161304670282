import React,{ useState, useEffect } from 'react';
import { Col } from 'reactstrap';

const Section002 = () => {
    const [sectionBG, setSectionBG] = useState('#f6f7fd');
    const [sectionFont, setSectionFont] = useState('');
    const [sectionHeaderBg, setSectionHeaderBg] = useState('#02827a');
    const [sectionHeaderFontColor, setSectionHeaderFontColor] = useState('#ffffff');
    const [sHeaderFontSize, setSHeaderFontSize] = useState('1rem');
    const [sHeaderFontWeight, setSHeaderFontWeight] = useState('bold');
    const [sHeader, setSHeader] = useState(''); //Header or none for No Header 
    const theme = {
        sectionbg: {
            padding: "0",
            color: `${sectionFont ? sectionFont : ''}`,
            backgroundColor: `${sectionBG}`
        },
        theader: {
            backgroundColor: `${sectionHeaderBg}`,
            fontSize: `${sHeaderFontSize}`,
            color: `${sectionHeaderFontColor}`,
            fontWeight: `${sHeaderFontWeight}`
        },
    };

    return (
        <>
        <Col lg={12} className="h-lg d-flex" style={theme.sectionbg}>
                <div className={`w-100 ${sHeader === 'none' ? 'd-none' : ''}`} id="skillSection">
                    <div className="card-header" style={theme.theader}>
                        Contact Info
                    </div>
                </div>
            </Col>
        {/* // <div className="micro-content-flex row mx-0 ">
             <div className="micro-sidebar h-lg d-flex" style={theme.sectionbg}>
                 <div className={`w-100 ${sHeader === 'none' ? 'd-none' : ''}`} id="skillSection">
                     <div className="card-header" style={theme.theader}>
                         Contact Info
                     </div>
                 </div>
             </div>
             <div className="micro-main-content col h-lg" style={theme.sectionbg}></div>
         </div> */}
        </>
    )
}

export default Section002