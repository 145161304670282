import { faUniversity } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getFullAddress } from "utils/Utils";
import hospital_icon_blue from "assets/images/hospital-icon-blue.png";

const RenderRecentExperience = (props) => {
  const { institutionDetail } = props;

  if (institutionDetail === null) {
    return null;
  }

  const institutionName = getFullAddress({
    name: institutionDetail?.name ?? "",
    abbreviation: institutionDetail?.abbreviation ?? "",
    location: institutionDetail?.address?.location ?? "",
    city: institutionDetail?.address?.city ?? "",
    state: institutionDetail?.address?.state ?? "",
  });
  const profileIdLink = institutionDetail?.profileId
    ? institutionDetail?.profileId
    : institutionDetail?.guid;

  return (
    <>
    {institutionDetail &&  institutionName ? (
    <div className="nexogic-profile-banner-links">
      <div className="item">
        <span className="icon"><svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.15" d="M5.46875 11.4375L13.125 3.78125L20.7812 11.4375L20.7813 22.375H5.46875L5.46875 11.4375Z" fill="#001A72"/>
<path d="M13.125 12.5312V19.0938M9.84375 15.8125H16.4062M13.125 3.78125L5.46875 11.4375L5.46875 22.375H20.7813L20.7812 11.4375L13.125 3.78125Z" stroke="#001A72" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</span>
        <p className="card-text"><Link
            to={`/institution/${profileIdLink}`}
            className=""
          >{institutionName}</Link></p>
      </div>
      <div className="row">
        <div className="exp-blk-logo col-auto">
          
          {/** <FontAwesomeIcon icon={faUniversity} className="fa-fw" size="2x" />*/}
        </div>

      </div>
    </div>
  ): null
  /*(
    <div className="line-progress">
    <div className="row">
        <div className="col">          
            <p>No Institution Details</p>
        </div>
      </div>
      </div>
      )*/}
  </>
  );
};

const ContainerRecentExperience = () => {
  const recentExperienceList = useSelector(
    (state) => state.RecentExperienceReducer.recentExperienceList
  );
  const recentEducationList = useSelector(
    (state) => state.RecentExperienceReducer.recentEducationList
  );
  const allRecentExperience = [...recentExperienceList, ...recentEducationList];

  if (allRecentExperience.length === 0) {
    return null;
  }
  return allRecentExperience.map((node, index) => (
    <RenderRecentExperience
      institutionDetail={node?.institutionDetail ?? null}
      key={index.toString()}
    />
  ));
};

export default memo(ContainerRecentExperience);
