import { Button, Card, CardBody } from "reactstrap";
import job_icon from "assets/svgIcon/briefcase-job-img.svg";
import { useEffect, useRef, useState } from "react";
import { TalentService } from "utils/TalentService";
import {
  createImageFromInitials,
  getFullAddress,
  getRandomColor,
} from "utils/Utils";
import { JOB_PositionTypesList } from "utils/Constants";
import { Link } from "react-router-dom";
import NoJobs from "pages/Jobs/NoJobs";
import { encrypt } from "utils/EncryptDecrypt";
import CommonLoader from "common/CommonLoader";

const JobWidgetUI = (props) => {
  const {
    institution,
    widgetTitle,
    primarySpeciality = "",
    jobId,
    alert,
    themeDetails
  } = props;

  const [jobListInfo, setJobListInfo] = useState([]);
  const [hasMore, sethasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [checkTotalPages, setCheckTotalPages] = useState();
  const [checkCurrentPage, setCheckCurrentPage] = useState();

  const currentPage = useRef(0);

  const fetchData = () => {
    setIsLoadingMore(true);
    try {
      const pageNo = currentPage.current + 1;
      currentPage.current = pageNo;
      let params = "";
      if (widgetTitle !== "Jobs") {
        params = {
          cp: pageNo,
          nr: 5,
          sq: `ps=="${primarySpeciality}"`,
        };
      } else {
        params = {
          cp: pageNo,
          nr: 5,
          sq: `ii=="${institution?.guid}"`,
        };
      }

      TalentService.listJob(params)
        .then((response) => {
          if (response.status === 200) {
            const { currentPage, totalPages, contents } = response.data;
            setCheckCurrentPage(currentPage);
            setCheckTotalPages(totalPages);
            setJobListInfo((preState) => {
              const newItem = contents.map((item) => {
                item.positionTitle =
                  JOB_PositionTypesList.find(
                    (node) => node?.key === item.position
                  ).label ?? "";
                item.institutionFullNameAddress = getFullAddress({
                  name: " ",
                  abbreviation: "",
                  location: "",
                  city: item?.institutionDetails?.address?.city ?? "",
                  state: item?.institutionDetails?.address?.state ?? "",
                }).replace("-", "");
                return {
                  ...item,
                };
              });
              return [...preState, ...newItem];
            });

            sethasMore(totalPages - currentPage !== 1);
            setIsLoadingMore(false);
          }
        })
        .catch((err) => {
          console.log(err.message);
          setIsLoadingMore(false);
        });
    } catch (e) {
      if (alert) alert.error(e.message);
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    if (institution?.guid || primarySpeciality) {
      try {
        let params = "";
        if (widgetTitle !== "Jobs") {
          params = {
            cp: 0,
            nr: 5,
            sq: `ps=="${primarySpeciality}"`,
          };
        } else {
          params = {
            cp: 0,
            nr: 5,
            sq: `ii=="${institution?.guid}"`,
          };
        }
        TalentService.listJob(params)
          .then((response) => {
            if (response.status === 200) {
              const { currentPage, totalPages } = response.data;
              setCheckCurrentPage(currentPage);
              setCheckTotalPages(totalPages);
              setJobListInfo(
                [...response.data.contents].map((item) => {
                  item.positionTitle =
                    JOB_PositionTypesList.find(
                      (node) => node?.key === item.position
                    ).label ?? "";
                  item.institutionFullNameAddress = getFullAddress({
                    name: " ",
                    abbreviation: "",
                    location: "",
                    city: item?.institutionDetails?.address?.city ?? "",
                    state: item?.institutionDetails?.address?.state ?? "",
                  }).replace("-", "");
                  return {
                    ...item,
                  };
                })
              );
              sethasMore(totalPages - currentPage !== 1);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log(err.message);
            setIsLoading(false);
          });
      } catch (e) {
        if (alert) alert.error(e.message);
        setIsLoading(false);
      }
    }
  }, [institution, alert]);

  const sectionColors = (sThemeColor) => {
    document.documentElement.style.setProperty('--section-bg-color', sThemeColor[3]);
    document.documentElement.style.setProperty('--section-headerbg-color', sThemeColor[4]);
    document.documentElement.style.setProperty('--section-headerfont-color', sThemeColor[5]);
    document.documentElement.style.setProperty('--section-font-color', sThemeColor[2]);
    document.documentElement.style.setProperty('--section-heading-color', sThemeColor[1]);
  }
  useEffect(()=>{
    if(themeDetails?.length >0){
      sectionColors(themeDetails)
    }
  },[themeDetails])

  return (
    <Card className="card nexogic-card-color-header micro-section-card-vs1 bg-color">
      <div className="card-header section-header"><h2>{widgetTitle}</h2></div>
      {isLoading &&
        <div className="text-center bg-transparent">
          <CommonLoader />
        </div>
      }

      <CardBody className="bg-color p-2">
        <div className={`nexogic-custom-scroll mt-1 position-relative`}>
          <div className="card-group nexogic-card-group-list ">
            {jobListInfo.map(
              (item, index) =>
                item.id !== jobId && (
                  <div className="card p-2 bg-transparent mb-0" key={`job_list${index}`}>
                    <div className="row no-gutters align-items-start border-bottom pb-3">
                      {widgetTitle !== "Jobs" && (
                        <div className="col-auto jobs-icon">
                          <Link
                            to={`/institution/${item?.institutionDetails?.profileId}`}
                          >
                            {item.institutionDetails &&
                              item.institutionDetails.avatarId ? (
                              <img
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${item?.institutionDetails.guid}/${item?.institutionDetails.avatarId}`}
                                className="card-img"
                                alt="#"
                              />
                            ) : (
                              <>

                                <img
                                  src={createImageFromInitials(
                                    50,
                                    `${item?.institutionDetails?.name}`,
                                    getRandomColor(
                                      encrypt(
                                        item?.institutionDetails?.guid
                                      ).toString()
                                    )
                                  )}
                                  className="card-img"
                                  alt="#"
                                />
                              </>
                            )}
                          </Link>
                        </div>
                      )}
                      {
                        widgetTitle === "Jobs" && (
                          <div className="col-auto jobs-icon"><img src={job_icon} className="img-fluid" alt="#" /></div>
                        )
                      }
                      <div className="col px-2">
                        {item?.title && (
                          <Link to={`/jobs/${item?.profileId}`} onClick={() => window.scrollTo(0, 0)} className="text-decoration-none"><h3 className="text-left mb-0">{item?.title}</h3></Link>
                        )}
                        {widgetTitle === "Jobs" && (
                          <p className="text-left mb-0">
                            {item?.primarySpeciality && (
                              <small>
                                {item?.primarySpeciality}
                              </small>
                            )}
                          </p>
                        )}
                        <p className="card-text text-left mb-0">
                          {" "}
                          <small>{item?.institutionFullNameAddress}</small>
                        </p>
                        <p className="card-text text-left mb-0">
                          {item?.positionTitle && (
                            <small className="text-muted">
                              {item?.positionTitle}
                            </small>
                          )}
                        </p>
                      </div>                    
                    <hr />
                    </div>
                  </div>
                )
            )}
          </div>

          {isLoadingMore &&
            <div className="text-center nexogic-loader-center bg-transparent">
              <CommonLoader />
            </div>
          }

        </div>

        {checkCurrentPage + 1 < checkTotalPages && (
          <div className="mt-3">
            <Button color="link" className="readmore" onClick={fetchData}>
              View More
            </Button>
          </div>
        )}
        {!isLoading &&
        (jobListInfo.length === 0 ||
          (jobListInfo.length === 1 && widgetTitle === "Similar Jobs")) && (
            isLoading===false ? <NoJobs content={"No Jobs"} />:'Loading...'
          )}
      </CardBody>

    </Card>
  );
};

export default JobWidgetUI;
