import { GET_COONETIONS_INFO, SET_COONETIONS_INFO } from "./Actions";

let initialState = {
    connections:{
        count : {
            "all": 0,
            "alumni": 0,
            "pages": 0,
            "colleagues": 0,
            "patients": 0,
            "peopleIFollow": 0,
            "groups": 0
        }
    }
};

function ConnectionsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COONETIONS_INFO:
      return {
        ...state,
        connections: action.payload,
      };
    case SET_COONETIONS_INFO:
      return {
        ...state,
        connections: action.payload,
      };
    default:
      return state;
  }
}

export default ConnectionsReducer;
