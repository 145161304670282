import { initializeApp } from "firebase/app";
import { browserLocalPersistence, getAuth, setPersistence } from "firebase/auth";

let firebaseConfig;

if (process.env.REACT_APP_ENV === 'development') {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "nexogic-dev-3cbd4.firebaseapp.com",
    projectId: "nexogic-dev-3cbd4",
    storageBucket: "nexogic-dev-3cbd4.appspot.com",
    messagingSenderId: "740991375020",
    appId: "1:740991375020:web:2bf3751a1191a49bfcf109",
    measurementId: "G-QSCSE457ZN"
  };
} else if (process.env.REACT_APP_ENV === 'production') {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "nexogic-f7ce5.firebaseapp.com",
    projectId: "nexogic-f7ce5",
    storageBucket: "nexogic-f7ce5.appspot.com",
    messagingSenderId: "268784001550",
    appId: "1:268784001550:web:ead9e1599d6d7e540ed25f",
    measurementId: "G-Y0EJ43H3V0"
  };
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);

export { auth };