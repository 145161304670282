import CommonLoader from "common/CommonLoader";
import { useEffect } from "react";
import { UserService } from "utils/UserService";

const DummyLoginScreen = () => {

    useEffect(() => {
        UserService.logout()
    }, []);

    return (
        <h1>
            <div className="text-center mt-3">
                <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                <h2 className="m-3">Logging in, please wait...</h2>
            </div>
        </h1>
    )
}

export default DummyLoginScreen;