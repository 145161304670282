import React, { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { FormGroup, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import ToolTip from 'common/ToolTip';
import FormikInputField from 'components/forms/formikFields/FormikInputField';
import FormikSelectField from 'components/forms/formikFields/FormikSelectField';
import { APPLY_MODE_LIST } from 'utils/Constants';

const JobApplyModeAndLinkEditModal = (props) => {
    const { modal, setModal } = props;
    const [applyMode, setApplyMode] = useState("EMAIL"); 
    const { setFieldValue } = useFormikContext();

    const handleApplyModeChange = (event) => {
        setApplyMode(event.target.value);
        setFieldValue("applyMode", event.target.value);
    };

    const handleSubmit = () => {
        setModal(false); // Close the modal
    };

    return (
        <Modal isOpen={modal} toggle={() => setModal(!modal)} className="nex-model-v2 modal-dialog-scrollable modal-dialog-centered">
            <ModalHeader toggle={() => setModal(!modal)}>Edit Apply mode</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col">
                        <div className="form-group mb-4">
                            <FormGroup>
                                <Label for="applyMode">Apply Mode <ToolTip id="apply_mode_field" place="right">Please Enter Apply Mode</ToolTip></Label>
                                <Field
                                    id="applyMode"
                                    name="applyMode"
                                    type="text"
                                    component={FormikSelectField}
                                    onChange={handleApplyModeChange}
                                    inputprops={{
                                        name: `applyMode`,
                                        options: APPLY_MODE_LIST,
                                        defaultOption: "Select",
                                        keys: {
                                            id: "value",
                                            label: "label",
                                        },
                                    }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="linkToApply">{applyMode === "EMAIL" ? "Email" : applyMode === "LINK" ? "External Link" : "Link to apply"} <ToolTip id="link_to_apply_field" place="right">Please Enter {applyMode === "EMAIL" ? "Email" : applyMode === "LINK" ? "External Link" : "Link to apply"}</ToolTip></Label>
                                <Field
                                    id="linkToApply"
                                    name="linkToApply"
                                    type="text"
                                    component={FormikInputField}
                                    placeholder={
                                        applyMode === "EMAIL"
                                            ? "Email"
                                            : applyMode === "LINK"
                                                ? "External Link"
                                                : "Link to apply"
                                    }
                                />
                            </FormGroup>
                        </div>
                    </div>
                </div>
                <div className="form-group nex-action-flex">
                <button className="btn nex-btn-primary ml-auto" onClick={handleSubmit}>Save</button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default JobApplyModeAndLinkEditModal;
