import React, { useState } from "react";
import { Row, Col, Button, FormGroup } from "reactstrap";
import { Field, Form } from "formik";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpandAlt, faCompressAlt } from '@fortawesome/free-solid-svg-icons'
import { withAlert } from "react-alert"

import "../style.scss";
import { ProfileStatuses } from "utils/Constants"

import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import FormikCheckboxField from "components/forms/formikFields/FormikCheckboxField";
import SkiilsTypeaheadField from "components/forms/formikFields/SkiilsTypeaheadField";
import InstitutesTypeaheadField from "components/forms/formikFields/InstitutesTypeaheadField"
import UserCityAutocompleteField from "components/forms/formikFields/UserCityAutocompleteField";
import UserLocationAutocompleteField from "components/forms/formikFields/UserLocationAutocompleteField";

const SearchCriteriaForm = (props) => {
  const [collapse, setCollapse] = useState(true);

  const toggleCollapse = () => {
    setCollapse((collapse) => !collapse);
  };

  return (
    <Form>
      <Row className="pt-3 search-form-filter">
        <Col xs="4">
            <Field
              id="name"
              name="name"
              type="text"
              label="Name"
              component={FormikInputField}
              placeholder="Enter practitioner's name"
            />
        </Col>
        <Col xs="4">
            <Field
              id="scf_speciality"
              className="speciality"
              name="speciality"
              type="text"
              label="Select Speciality"
              placeholder={'Select Speciality'}
              component={SkiilsTypeaheadField}
            />
        </Col>
        <Col xs="auto" className="form-action-btn">
          <Button
            id="scf_search"
            size="sm"
            color="primary"
            type="submit"
          >
            Search
          </Button>
        </Col>
        <Col xs="auto" className="form-action-btn">
          <Button id="scf_advance"  color={"link"} size="sm" onClick={toggleCollapse}>
            {collapse?
            <FontAwesomeIcon icon={faExpandAlt} />
            :
            <FontAwesomeIcon icon={faCompressAlt} />
            }
            <span className="pl-1">Advance</span>
          </Button>
        </Col>
      </Row>
      
      <Row className={`advanced-search${collapse? ``: ` expand` }`}>
        <Col>
          <Row>
            <Col className="d-flex align-items-center">
              <Field
                id="scf_status"
                name="status"
                type="text"
                label="Select Status"
                className="custom-select"
                component={FormikSelectField}
                inputprops={{
                    options: ProfileStatuses,
                    defaultOption: "Select status"
                }}
                placeholder="Status"
              />
              {/* <FormGroup className="checkbox-wrapper"> 
                <Field
                  id="profileVerified"
                  name="profileVerified"
                  type="checkbox"
                  label="Profiles Verified"
                  component={FormikCheckboxField}
                />
              </FormGroup> */}
            </Col>
            {/* <Col className="d-flex align-items-center">
              <FormGroup className="checkbox-wrapper">
                <Field
                  id="profileClaimed"
                  name="profileClaimed"
                  type="checkbox"
                  label="Profiles Claimed"
                  component={FormikCheckboxField}
                />
              </FormGroup>
            </Col> */}
            <Col></Col>
            <Col></Col>
          </Row>
          <Row>
            <Col className="d-flex align-items-center">
              <Field
                className="rounded-0 border-top-0 border-right-0 border-left-0 bg-none"
                id="city"
                name="city"
                type="text"
                label="City"
                component={UserCityAutocompleteField}
                placeholder="City"
              />
            </Col>
            <Col className="d-flex align-items-center">
              <Field
                className="rounded-0 border-top-0 border-right-0 border-left-0 bg-none"
                id="location"
                name="location"
                type="text"
                label="Location"
                component={UserLocationAutocompleteField}
                placeholder="Location"
              />
            </Col>
            <Col className="d-flex align-items-center facilityName">
              <Field
                className="rounded-0 border-top-0 border-right-0 border-left-0 bg-none"
                id="scf_facility"
                name="facility"
                type="text"
                label="Institution"
                placeholder="Select Institution"
                component={InstitutesTypeaheadField}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default withAlert()(SearchCriteriaForm);
