import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import SecondOpinionImg from "assets/images/second-opinion.png";

export default function AppointmentDetails() {
    return (
        <div className='row'>
            <div className="col-lg-3">
                <div className="card nexogic-filter-list nexogic-filter-mob-bot type-bullets">
                    
                    <div className="card-body p-0">
                        <ul className="list-group list-group-flush nexogic-aside-lists">
                            <li className="list-group-item">
                                <a href=""> <span className='nexogic-color-bullet lightgreen'></span>Upcoming</a>
                            </li>
                            <li className="list-group-item">
                                <a href=""> <span className='nexogic-color-bullet green'></span> Previous</a>
                            </li>
                            <li className="list-group-item">
                                <a href=""> <span className='nexogic-color-bullet red'></span> Canceled</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg-9">

                <Card className='nexogic-doctor-appoint-card'>
                    <CardBody>
                        <Row className='need-margin'>
                            <Col xl={12} lg={12} className="">
                                <p className="card-title bold">Doctor Info</p>
                                <div className="nexogic-appoint-doctor-info">


                                    <div className="nexogic-avatar size-lg">
                                        <img src="https://images-dev.nexogic.com/53efb6fd-1ceb-412a-b19d-b65032bde722/a8dd998a-8e20-4ac4-971f-bf91154aa6fc.png" alt="" className='' />
                                    </div>
                                    <div className="details">
                                        <h3 className="card-title">Dr. Anna Sotelo <span className='nexogic-green-tick'></span></h3>
                                        <p className="card-text">MBBS, MS - Orthopedic Surgeon (Orthopedist)</p>
                                        <p className="card-text">12 years as a specialist</p>
                                        <p className="card-text"><span className='icon'><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.99988 0.320068C5.17738 0.320068 2.87988 2.61757 2.87988 5.44007C2.87988 9.95632 7.56113 15.3426 7.75988 15.5701C7.82113 15.6401 7.90738 15.6801 7.99988 15.6801C8.09863 15.6738 8.17863 15.6401 8.23988 15.5701C8.43863 15.3388 13.1199 9.86007 13.1199 5.44007C13.1199 2.61757 10.8224 0.320068 7.99988 0.320068ZM7.99988 3.84007C9.05989 3.84007 9.91989 4.70007 9.91989 5.76007C9.91989 6.82007 9.05989 7.68007 7.99988 7.68007C6.93988 7.68007 6.07988 6.82007 6.07988 5.76007C6.07988 4.70007 6.93988 3.84007 7.99988 3.84007Z" fill="#001244" fillOpacity="0.6" />
                                        </svg>
                                        </span> California, CA, 18974</p>
                                        
                                    </div>
                                </div>
                            </Col>
                            
                        </Row>
                        
                    </CardBody>
                </Card>

                <Card className='nexogic-doctor-appoint-card'>
                    <CardBody>
                        <Row className='need-margin'>
                            <Col xl={4} lg={12} className="">
                                <p className="card-title bold">Appointment Details</p>
                                <div className="nexogic-key-val-lists nexogic-key-val-full nexogic-appointment-date-slot-w">
                                    <div className="item">
                                        <span className='key'>Appointment Date</span>
                                        <span className='val'><span className='icon'><svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.3889 1V4M4.61111 1V4M1 7H14M2.44444 2.5H12.5556C13.3533 2.5 14 3.17157 14 4V14.5C14 15.3284 13.3533 16 12.5556 16H2.44444C1.6467 16 1 15.3284 1 14.5V4C1 3.17157 1.6467 2.5 2.44444 2.5Z" stroke="#2D4552" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        </span> Monday, May 23  -  Monday, May 23</span>
                                    </div>
                                    <div className="item">
                                        <span className='key'>Select Time Slot :</span>
                                        <span className='val'><span className='icon'><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 4.6V10L13.6 11.8M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#2D4552" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        </span> 9:00AM - 10:00AM</span>
                                    </div>

                                </div>
                            </Col>
                            <Col xl={4} lg={12} className="">
                                <p className="card-title bold">&nbsp;</p>
                                <div className="nexogic-key-val-lists ">
                                    <div className="item align-items-center">
                                        <span className='key'>Status :</span>
                                        <span className='val'><span className='pill green'>Accept</span></span>
                                    </div>
                                    <div className="item">
                                        <span className='key'>Payment :</span>
                                        <span className='val'>Successfull</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                       
                    </CardBody>
                </Card>

             


            </div>

        </div>
    )
}
