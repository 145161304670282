import React, { useState, useRef,useEffect } from 'react'
import Slider from "react-slick";
import { Card, CardBody, CardHeader, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons'; 
import FullWidth from '../Sliders/Components/FullWidth';
import RoundedSlider from '../Sliders/Components/RoundedSlider';
import SquareSlider from '../Sliders/Components/SquareSlider';
import './style.scss';

const FullWidthSlider = ({fieldDetails,themeDetails}) => {
    const [cardWidth, setCardWidth] = useState(0);
    const refWidth = useRef(null);
    const [sliderType,setSliderType] = useState('fullwidth');
  const [dropdownOpen, setOpen] = React.useState(false);
    const thumbs = require.context('./assets', true);
    const thumbList = thumbs.keys().map((thumbName, index) => (
        // <div className={`${cardWidth > 300 ? "thumb":"thumb-sm"}`} key={index}>
        <div key={index}>
        <img src={thumbs(thumbName).default} alt={thumbName} id={index} className="preview" onClick={(e)=>changeSlider(e)} />
        </div>
      ));
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const changeSlider = (det) =>{
        // console.log("====changeSlider=======================",det.target.id);
        if(det.target.id==='0'){
            // console.log("==============fullwidth",det.target.id);
            setSliderType('fullwidth');
        }else if(det.target.id==='1'){
            // console.log("==============rounded",det.target.id);
            setSliderType('rounded');
        }else if(det.target.id==='2'){            
            // console.log("==============square",det.target.id);
            setSliderType('square');
        }
    }

    useEffect(() => {
        if (refWidth.current) {
          const cWidth = refWidth.current.getBoundingClientRect().width;
          setCardWidth(cWidth);
          console.log(cWidth); // outputs the width of the col in pixels
        }
      }, [cardWidth]);
    // console.log("FWS-------------", cardWidth,thumbList);

    return (
        <>
        <div className='thumb-area'>
            {/* <div className={`${cardWidth > 300 ? "thumbs":"thumbs-sm"}`}>{thumbList}</div> */}


            <Dropdown toggle={() => { setOpen(!dropdownOpen) }} isOpen={dropdownOpen} direction='left'>
            <DropdownToggle data-toggle="dropdown" tag="span"><FontAwesomeIcon icon={faCaretLeft} /></DropdownToggle>
                <DropdownMenu>
                    <DropdownItem style={{backgroundColor:'gray'}} >{thumbList[0]}</DropdownItem>
                    <DropdownItem style={{backgroundColor:'gray'}} >{thumbList[1]}</DropdownItem>
                    <DropdownItem style={{backgroundColor:'gray'}} >{thumbList[2]}</DropdownItem>
                </DropdownMenu>
            </Dropdown>



        </div>
            <Card className='micro-section-card-vs1'>
                <CardHeader className='main-header section-header'>{fieldDetails.slabel}</CardHeader>
                <CardBody className='bg-color p-0 m-0'>
                    <div ref={refWidth}>
                    {/* {console.log("-----------",refWidth.current.getBoundingClientRect().width)} */}
                {sliderType==='fullwidth' ? <FullWidth /> :sliderType==='rounded' ? <RoundedSlider /> : sliderType==='square' ? <SquareSlider />: ''}
                    {/* <div className='micro-slider-v1'>
                        <Slider {...settings}>
                            <div className="slider-item">
                                <img src="https://picsum.photos/id/202/600/200" alt="" />
                            </div>
                            <div className="slider-item">
                                <img src="https://picsum.photos/id/203/600/200" alt="" />
                            </div>
                            <div className="slider-item">
                                <img src="https://picsum.photos/id/204/600/200" alt="" />
                            </div>
                        </Slider>
                    </div> */}
                    </div>
                </CardBody>
            </Card>
        </>
    )
}
export default FullWidthSlider
