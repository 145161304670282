import AlertDialog from "common/AlertDialog";
import { first, groupBy } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import {
  profileAddEvent,
  set_Recent_Experience_user,
  updateProfileCompletness,
} from "redux-helpers/ActionCreator";
import { UserStatusConstants } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import {
  convertToMMMYYYYFormat,
  dateDifferenceInYearsAndMonths,
  getEmptySectionShowHide,
  getFullAddress,
  makeFullNameWithAddressWhenAddressIsNested,
} from "utils/Utils";
import { RenderExperienceModal } from "./components/RenderExperienceModal";
import "./style.scss";
import collage_icon from "assets/images/work-experince.png";
import small_marker from "assets/svgIcon/marker-small.svg";

import NoData from "../NoData";

const initialValue = {
  id: "",
  institutionProfile: {
    institutionName: "",
    guid: "",
    onSelectNewInstitutionCollege: 0,
  },
  position: "",
  description: "",
  fromDate: "",
  toDate: "",
  expires: false,
  mainItemName: "",
  city: "",
  state: "",
  country: "",
  location: "",
  phone: "",
  postalCode: "",
  addressLine1: "",
  websiteAddress: "",
  category: "",
};

const RenderExpCard = (props) => {
  const {
    sortedExperience,
    keyValue,
    isAdmin,
    handleEditItem,
    handleRemoveItem,
    isLoggedinUser,
  } = props;
  const isMultipleExp = sortedExperience[keyValue].length > 1 ? true : false;
  const currentUser = useSelector((state) => state.auth.currentUser);
  return sortedExperience[keyValue].map((item, index) => {
    const location = item.institutionProfile?.address?.city || item.location;
    return (
      <Row className={`experience-position-item ${isMultipleExp ? 'multiple-items' : 'single-item'}`} key={`exp_posi_${index}`}>
        <Col xs="auto" className="experience-position-icon auto">
          {isMultipleExp ? (
            item.expires ? (
              <>
                <span className="bullet-icon active"></span>
              </>
            ) : (
              <>
                <span className="bullet-icon"></span>
              </>
            )
          ) : null}
        </Col>
        <Col>
          <h3 className="card-title"><Link
            to={`/institution/${item?.institutionDetail?.profileId}`}
            className=""
          >{item?.positionName}</Link></h3>
          <p className="card-text">{makeFullNameWithAddressWhenAddressIsNested(item?.institutionDetail)}</p>
          {item?.startYear &&
            <p className="card-text">
              {convertToMMMYYYYFormat(item?.startYear)} {" - "}
              {item?.presentlyWorking
                ? <span><span>{"Present | "}</span><span>{dateDifferenceInYearsAndMonths(item?.startYear)}</span></span>
                : <span>{convertToMMMYYYYFormat(item?.endYear)}</span>}
            </p>
          }
          <div className="nex-action">
          </div>
          {location &&
            <p className="card-text">
              <small className="text-muted">
                <span className="icon">
                  <img src={small_marker} alt="" />
                </span>{location}
              </small>
            </p>}
        </Col>
        <div className="nex-action">
          <div className="delete-action">
            {(isAdmin ||
              (isLoggedinUser &&
                currentUser.status === UserStatusConstants.APPROVED)) && (
                <>
                  <span href="" className="btn" onClick={() => handleEditItem(item)}><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="15.9062" cy="16" r="15.375" fill="white" stroke="#DBF4FB" strokeWidth="0.75" />
                    <path d="M19.3612 9.53125L17.8557 11.0368L20.8667 14.0478L22.3722 12.5423L19.3612 9.53125ZM16.3502 12.5423L10.3281 18.5643V21.5754H13.3392L19.3612 15.5533L16.3502 12.5423Z" fill="#46ABC9" />
                  </svg>
                  </span>
                  <span href="" className="btn" onClick={() => handleRemoveItem(item)}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="16.2266" cy="16" r="15.75" fill="#FFEAEF" />
                      <path d="M11.3984 13.7734L12.2586 20.1323C12.426 21.3702 12.5097 21.9892 12.9337 22.3597C13.3577 22.7302 13.9823 22.7302 15.2315 22.7302H18.4692C19.7184 22.7302 20.343 22.7302 20.767 22.3597C21.191 21.9892 21.2747 21.3702 21.4421 20.1323L22.3023 13.7734" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" stroke-linejoin="round" />
                      <path d="M14.5156 11.0475V11.0475C14.5156 10.2672 14.5156 9.87708 14.6572 9.57473C14.8061 9.25693 15.0616 9.00138 15.3794 8.85254C15.6818 8.71094 16.0719 8.71094 16.8522 8.71094V8.71094C17.6324 8.71094 18.0226 8.71094 18.3249 8.85254C18.6427 9.00138 18.8983 9.25693 19.0471 9.57473C19.1887 9.87708 19.1887 10.2672 19.1887 11.0475V11.0475" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" stroke-linejoin="round" />
                      <path d="M22.3023 11.0547L11.3984 11.0547" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" stroke-linejoin="round" />
                      <path d="M14.8906 14.1719L15.28 20.0132" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" stroke-linejoin="round" />
                      <path d="M18.7957 14.1719L18.4062 20.0132" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                </>
              )}
          </div>
        </div>
      </Row>
    );
  });
};

const RenderExperience = (props) => {
  const { experience, isLoggedinUser, isAdmin, handleEditItem } = props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  if (experience && experience.length) {
    let sortedExperience = experience.sort((a, b) => {
      return b.fromYear - a.fromYear;
    });

    sortedExperience = experience.sort((a, b) => {
      return b.expires - a.expires;
    });

    sortedExperience = sortedExperience.map((exp) => {
      if (exp?.institutionDetail?.guid) exp.name = exp?.institutionDetail?.name;
      return exp;
    });
    sortedExperience = groupBy(sortedExperience, "name");
    return Object.keys(sortedExperience).map((key, index) => {
      const profileIdLink = sortedExperience[key][0]?.institutionDetail
        ?.profileId
        ? sortedExperience[key][0]?.institutionDetail?.profileId
        : sortedExperience[key][0]?.institutionDetail?.guid;
      const infoInsObj = {
        name: key ?? "",
        abbreviation:
          sortedExperience[key][0]?.institutionDetail?.abbreviation ?? "",
        location:
          sortedExperience[key][0]?.institutionDetail?.address?.location ?? "",
        city: sortedExperience[key][0]?.institutionDetail?.address?.city ?? "",
        state:
          sortedExperience[key][0]?.institutionDetail?.address?.state ?? "",
      };
      const institutionName = getFullAddress(infoInsObj);
      const avatarId = sortedExperience[key][0]?.institutionDetail?.avatarId ?? null;
      const instGuid = sortedExperience[key][0]?.institutionDetail?.guid ?? null;
      return (
        <li
          className="nex-icon-list-item"
          key={`keyName_${index}`}
        >
          {avatarId ?
            <div className="card-graphic">
              <img
                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${instGuid}/${avatarId}`}
                alt=""
                style={{ MaxHeight: "45px" }}
              />
            </div>
            :
            <div className="card-graphic">
              <img src={collage_icon} alt="" className="contain-bg" />
            </div>
          }
          <div className="nex-info">
            <RenderExpCard
              keyValue={key}
              sortedExperience={sortedExperience}
              {...props}
            />
          </div>
        </li>
      );
    });
  }

  return (
    <div className="py-2">
      <Row className="align-items-center">
        <Col>
          {(isLoggedinUser || isAdmin) &&
            currentUser.status === UserStatusConstants.APPROVED && (
              <Button
                id="nex-e-exp-add-btn"
                className="add-infomation p-0"
                color="link"
                onClick={() => handleEditItem(initialValue, "add")}
              >
                {"Add experience"}
              </Button>
            )}
        </Col>
      </Row>
    </div>
  );
};

const Experience = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const eventName = useSelector((state) => state.profileComplete.eventName);
  const dispatch = useDispatch();
  const [experience, setExperience] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const { guid } = props.practitioner;
  const userGUID = props.practitioner.guid;
  const { alert, isLoggedinUser, isAdmin, setShowExperienceOnTabScroll } = props;
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );
  const [isOpenAlert, setAlertModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const toggleAlert = () => {
    setLoading(false);
    setAlertModal(!isOpenAlert);
  };

  useEffect(() => {
    if (userGUID) {
      getData();
    }
  }, [userGUID]);

  useEffect(() => {
    dispatch(updateProfileCompletness(0, !!experience.length));
    if (experience.length > 0 || isLoggedinUser || isAdmin) {
      setShowExperienceOnTabScroll(true);
    } else {
      setShowExperienceOnTabScroll(false);
    }
  }, [experience]);

  useEffect(() => {
    if (eventName === "ADD_EXPERIENCE") {
      handleEditItem(initialValue);
      dispatch(profileAddEvent(null));
    }
  }, [eventName]);

  const handleEditItem = (selectedItem) => {
    const formData = {
      institutionProfile: {
        institutionName: selectedItem?.institutionDetail ? makeFullNameWithAddressWhenAddressIsNested(selectedItem?.institutionDetail) : "",
        guid: selectedItem?.institutionDetail?.guid ?? "",
        onSelectNewInstitutionCollege: selectedItem?.institutionDetail?.guid
          ? 1
          : 0,
      },
      position: selectedItem?.positionName ?? "",
      description: selectedItem?.description ?? "",
      fromDate: selectedItem?.startYear ?? "",
      toDate: selectedItem?.endYear ?? "",
      expires: selectedItem?.expires ?? false,
      mainItemName: selectedItem?.institutionDetail?.name ?? "",
      id: selectedItem?.guid ?? "",
      city: "",
      state: "",
      country: "",
      location: "",
      phone: "",
      postalCode: "",
      addressLine1: "",
      websiteAddress: "",
      category: "",
    };
    setSelectedItem(formData);
    toggle();
  };

  const handleRemoveItem = (selectedItem) => {
    const formData = {
      institutionProfile: {
        institutionName: selectedItem?.institutionDetail?.name ?? "",
        guid: selectedItem?.institutionDetail?.guid ?? "",
        onSelectNewInstitutionCollege: selectedItem?.institutionDetail?.guid
          ? 1
          : 0,
      },
      position: selectedItem?.positionName ?? "",
      description: selectedItem?.description ?? "",
      fromDate: selectedItem?.startYear ?? "",
      toDate: selectedItem?.endYear ?? "",
      expires: selectedItem?.expires ?? false,
      mainItemName: selectedItem?.institutionDetail?.name ?? "",
      id: selectedItem?.guid ?? "",
      city: "",
      state: "",
      country: "",
      location: "",
      phone: "",
      postalCode: "",
      addressLine1: "",
      websiteAddress: "",
      category: "",
    };
    setSelectedItem(formData);
    toggleAlert();
  };

  const handleClickOnYes = async () => {
    const userGUID = globalProfileInfo.guid;
    setLoading(true);
    try {
      const request = PractitionerService.deleteExperiences(
        userGUID,
        selectedItem?.id
      );
      request
        .then(async (response) => {
          const { status } = response;
          if (status === 204) {
            await getData();
          } else {
            setLoading(false);
            let errmsg =
              response.data && !!response.data.message
                ? response.data.message
                : "Failed to save. Please try again";
            showErrorMessage(errmsg);
          }
        })
        .then(() => {
          setLoading(false);
          toggleAlert();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getData = async () => {

    const request = PractitionerService.listExperiences(userGUID);
    request
      .then((response) => {
        let { data } = response;
        if (data && data.length > 0) {
          const list_of_data = data.map((node) => {
            const [fromMonth, fromYear] = node?.startYear
              ? node?.startYear.split("-")
              : "";
            const [toMonth, toYear] = node?.endYear
              ? node?.endYear.split("-")
              : "";
            return {
              ...node,
              id: node?.guid ?? "",
              expires: node.presentlyWorking,
              fromMonth:
                fromMonth && fromYear
                  ? moment(fromMonth + "/01/" + fromYear).format("MMMM")
                  : "",
              fromYear:
                fromMonth && fromYear
                  ? moment(fromMonth + "/01/" + fromYear).format("YYYY")
                  : "",
              toMonth:
                toMonth && toYear
                  ? moment(toMonth + "/01/" + toYear).format("MMMM")
                  : "",
              toYear:
                toMonth && toYear
                  ? moment(toMonth + "/01/" + toYear).format("YYYY")
                  : "",
              toDate: node?.endYear,
            };
          });
          setExperience(list_of_data);
          const incoming_data = first(list_of_data)
            ? [first(list_of_data)]
            : [];
          dispatch(set_Recent_Experience_user(incoming_data));
        } else {
          setExperience([]);
          dispatch(set_Recent_Experience_user([]));
        }
      })
      .catch((e) => {
        console.log("PractitionerService.listExperiences", e);
      });
  };

  if (
    getEmptySectionShowHide(
      experience ?? [],
      isAdmin,
      currentUser,
      globalProfileInfo,
      guid
    )
  )
    return null;

  return (
    <>
      <Card
        className={`nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`}
        id="experienceSection"
      >
        <div className="card-header"><h2>Experience</h2>
          {(isAdmin ||
            (isLoggedinUser &&
              currentUser.status === UserStatusConstants.APPROVED)) && (

              <Button
                id="nex-es-experience-add-btn"
                color="link"
                className="add-button ml-auto"
                onClick={() => handleEditItem(initialValue)}
              >
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9.75" cy="9" r="8.4375" fill="white" stroke="#46ABC9" strokeWidth="1.125" />
                  <path d="M9.625 5.25L9.625 12.5039" stroke="#46ABC9" strokeWidth="1.125" strokeLinecap="round" />
                  <path d="M13.2656 8.88281L6.01172 8.88281" stroke="#46ABC9" strokeWidth="1.125" strokeLinecap="round" />
                </svg>  Add More Experience

              </Button>

            )}

          <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
            <svg width={17} height={10} viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg" >
              <path
                d="M2.25 1.625L8.625 8L15 1.625"
                stroke="black"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>

        </div>
        <div className={`collapse-div`}>
          <CardBody>
            <ul className="nex-icon-list-within-card" id="nex-es-experience-lists">
              <RenderExperience
                experience={experience}
                handleEditItem={handleEditItem}
                handleRemoveItem={handleRemoveItem}
                isLoggedinUser={isLoggedinUser}
                isAdmin={isAdmin}
              />
            </ul>
            {experience.length < 1 &&
              <NoData>No Data</NoData>
            }
          </CardBody>
        </div>
      </Card>
      <RenderExperienceModal
        selectedItem={selectedItem}
        toggle={toggle}
        isOpen={isOpen}
        guid={guid}
        getData={getData}
        isAdmin={isAdmin}
      />
      <AlertDialog
        toggle={toggleAlert}
        isOpen={isOpenAlert}
        isLoading={isLoading}
        handleClickOnYes={handleClickOnYes}
        yesLabel={"Yes"}
        noLabel={"No"}
      />
    </>
  );
};

export default withAlert()(Experience);
