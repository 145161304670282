import { useRef } from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";
import job_description from 'assets/images/job-description-1.png';
import FormikInputField from "components/forms/formikFields/FormikInputField";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'; // Import styles for ReactQuill

function JobCreateFormFive() {
    const textInput = useRef();
    const { values, setFieldValue } = useFormikContext();

    return (
        <>
            <div className="nex-job-form-left-sec">
                <div className="nex-job-info">
                    <img src={job_description} alt="" className="graphic" />
                    <div className="conts">
                        <p><span className='light'>4/7</span> Job Post</p>
                        <h2 className='title'>Describe the Job Details that
                            you wanna update..</h2>
                        <p className='lead'>Fill the forms with job role and description.</p>
                    </div>
                </div>
            </div>
            <div className="nex-job-form-right-sec">
                <h2 className='nex-text-lg text-black'>What is the job Role</h2>
                <div className="form-group">
                    <Field
                        className='form-control'    
                        title="role"
                        id="role"
                        name="role"
                        component={FormikInputField}
                        placeholder='Role'
                        type="text"
                    />
                </div>
                <h2 className='nex-text-md text-black'>Example Roles</h2>
                <ul className='mb-3 nex-ul-info'>
                    <li>Physicians</li>
                    <li>Nurses</li>
                    <li>Physical Therapists</li>
                </ul>
                <h2 className='nex-text-lg text-black'>Job Descriptions</h2>
                <div className="form-group">
                    <ErrorMessage name="description" component="div" className="error-message"
                        style={{ color: 'red', fontSize: '0.875rem', marginTop: '0.25rem' }}
                    />
                    <ReactQuill
                        theme="snow"
                        value={values.description}
                        name={"description"}
                        onChange={(content) => setFieldValue('description', content)}
                    />
                </div>
            </div>
        </>
    );
}

export default JobCreateFormFive;
