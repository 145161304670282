import React from "react"
import { Container } from 'reactstrap'
import Header from "../../common/Header"
import { withRouter, useParams } from 'react-router-dom'
import ResetPasswordForm from "./components/ResetPasswordForm";

const ResetPassword = (props) => {  
  const { token } = useParams()
  return (
    <section>
      <div className="nexogic-bg-light">
        <Header />
        <div className="theme-body-bg nexogic-dashboard-card">
          <Container className="nexogic-container-gap">
            <ResetPasswordForm token={token}/>
          </Container>
        </div>
        <Container className="fixed-bottom fixed-footer-full bg-light">
        </Container>
      </div>
    </section>

  )
}

export default withRouter(ResetPassword);
