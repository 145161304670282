
import React from 'react'
import { useState } from 'react';
import { Card, CardBody, Collapse } from 'reactstrap';
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import { useEffect } from 'react';

function CollapseableItem(props) {
    const [pannel_on, setPannel_on] = useState(false);
    useEffect(()=>{
      setPannel_on(props.isPannelOn);
    }, [props.isPannelOn])
  return (
    <Card className={`nexogic-card-collapsable-item ${pannel_on ? 'expanded' : ''}`} id={props.id} >
        <div className="card-header"><h2>{props.title}</h2>
                            {props.headAction}
                            <button id="agd_btn_desc" className="collapse-btn" type="button" onClick={()=>setPannel_on(!pannel_on)}>
                            <svg width="30" height="18" viewBox="0 0 33 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 1L16.5 17L32.5 1" stroke="black"/>
                            </svg>

                          </button>
        </div>
        <Collapse isOpen={pannel_on} >
            <CardBody>
                {props.children}
            </CardBody>
        </Collapse>
    </Card>
  )
}

CollapseableItem.defaultProps = {
  isPannelOn: false,
};

export default CollapseableItem;