import React, { useState } from "react";
import { Button, CardBody, FormGroup } from "reactstrap";
import ReactQuill from "react-quill";
import { withAlert } from "react-alert";
import { useFormikContext } from "formik";

const MedicalHistoryForm3 = ({ currentStep, setCurrentStep, isSelf, setIsSelf }) => {
    const { values, setFieldValue } = useFormikContext();

    return (
        <CardBody className={`nex-form-step active`}>
            <div className="nex-form-field-container">
                <div className="nex-form-group">
                    <div className="nex-form-group-c mt-0">
                        <label htmlFor="" className="nex-text-lg bold mb-3">Any past hospitalizations ?</label>
                        <FormGroup>
                            <ReactQuill
                                id="pastHospitalization"
                                theme="snow"
                                value={values.pastHospitalization}
                                name={"pastHospitalization"}
                                onChange={(content) => setFieldValue('pastHospitalization', content)}
                                placeholder="Enter Your past hospitalizations"
                                bounds={".app"}
                            />
                        </FormGroup>

                        <label htmlFor="" className="nex-text-lg bold mb-3">Please brief your Current Medications ?</label>
                        <FormGroup>
                            <ReactQuill
                                id="currentMedications"
                                theme="snow"
                                value={values.currentMedications}
                                name={"currentMedications"}
                                onChange={(content) => setFieldValue('currentMedications', content)}
                                placeholder="Enter Your Current Medications"
                                bounds={".app"}
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
        </CardBody>
    )
}

export default withAlert()(MedicalHistoryForm3);
