import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { withAlert } from "react-alert"
import ServicesForm from "./ServicesForm"
import ServicesFormBulkEdit from './ServicesFormBulkEdit';

const RenderServicesOfferedForm = (props) => {
  const { services, isOpen, toggle, setPractitioner, practitioner, isAdmin } = props;
  const [addInBulk, setAddInBulk] = useState(false);
  const handleClick = () => {
    setAddInBulk(!addInBulk);
  };
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} id="profile-modal" className="skills-modal modal-dialog-scrollable modal-dialog-centered skills-expertise-modal" backdrop="static">
        <ModalHeader toggle={toggle}>
        <div className='modal-filter-header'>{`Services`}
          <span className='ml-auto'>
            <label className=' mb-0 mr-2'>Add in Bulk</label>
            <input id='add_in_bulk' name='add_in_bulk' type="checkbox" checked={addInBulk} onClick={() => handleClick()} />
          </span>
          </div>
        </ModalHeader>
        <ModalBody>
          {addInBulk
            ?
            <ServicesFormBulkEdit
              services={services}
              toggle={toggle}
              setPractitioner={setPractitioner}
              practitioner={practitioner}
              isAdmin={isAdmin}
            />
            :
            <ServicesForm
              services={services}
              toggle={toggle}
              setPractitioner={setPractitioner}
              practitioner={practitioner}
              isAdmin={isAdmin}
            />
          }
        </ModalBody>
      </Modal>
    </div>
  );
}


export default withAlert()(RenderServicesOfferedForm)



