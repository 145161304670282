import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import LinesEllipsis from 'react-lines-ellipsis';
import { TalentService } from "utils/TalentService";
import { ShowExperience, getPosition } from 'utils/Utils';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { isInstitutionAdmin, isPractitioner } from "utils/UserHelper";
import Apply from 'pages/FeedHomePage/components/ApplyJobs/Apply';
import { FAILED_TO_FETCH_DATA } from 'utils/Constants';
import moment from 'moment';
import JobFilter from 'pages/FeedHomePage/components/CreatePosthome/JobFilter';
import { showErrorMessage } from 'common/AlertContainer';

const JobTabularView = ({ JobDetails, index, currentUser, alert }) => {
    const [applicantStatus, setApplicantStatus] = useState(null);
    const [modal, setModal] = useState(false);
    const toggleForm = () => setModal(!modal);
    const [jobFullDetails, setJobFullDetails] = useState({});

    const getJobDetails = (id) => {
        TalentService.getJob(id)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    // console.log(data);
                    setJobFullDetails(data);
                } else {
                    alert.error(FAILED_TO_FETCH_DATA);
                }
            })
    }

    const getApplicantStatus = (jobId) => {
        TalentService.getJobApplicantStatus(jobId)
            .then((response) => {
                let { data } = response;
                // console.log("applicantStatus-------------------------------------------response-----------", jobId, response);
                if (response.status === 200 || response.status === 201) {
                    setApplicantStatus(data?.status);
                } else {
                    showErrorMessage(FAILED_TO_FETCH_DATA);
                }
            })
            .catch((error) => {
                console.log("Error while getting connection status: ", error);
            });

    };

    const getJobApplyAction = (applicantStatus) => {
        let possibleApplicationStatus = ["APPLIED", "SHORTLISTED", "INTERVIEW", "OFFERED", "ACCEPTED", "ON_HOLD", "HIRED", "REJECTED", "CLOSED"];

        if (isPractitioner()) {
            if (JobDetails?.creatorProfile === null) {
                return (
                    <>
                        {
                            possibleApplicationStatus.includes(applicantStatus)
                                ?
                                <div className="action job-apply-btns">
                                    <span className="btn nex-btn-applied small">
                                        {"Applied"}</span>
                                </div>
                                :
                                <div className="action job-apply-btns">
                                    <span className="btn nex-btn-primary small nex-apply-btn"
                                        onClick={() => {
                                            if (jobFullDetails?.applyMode === 'EMAIL') {
                                                toggleForm();
                                            } else {
                                                window.open(jobFullDetails?.linkToApply, "_blank");
                                            }
                                        }}
                                    >Apply</span>
                                </div>
                        }
                    </>
                );
            }

            else {
                if (JobDetails?.creatorProfile?.profileGuid !== currentUser?.guid) {
                    return (
                        <>
                            {
                                possibleApplicationStatus.includes(applicantStatus)
                                    ?
                                    <div className="action job-apply-btns">
                                        <span className="btn nex-btn-applied small">
                                            {"Applied"}</span>
                                    </div>
                                    :
                                    <div className="action job-apply-btns">
                                        <span className="btn nex-btn-primary small nex-apply-btn"
                                            onClick={() => {
                                                if (jobFullDetails?.applyMode === 'EMAIL') {
                                                    toggleForm();
                                                } else {
                                                    window.open(jobFullDetails?.linkToApply, "_blank");
                                                }
                                            }}
                                        >Apply</span>
                                    </div>
                            }
                        </>
                    );
                }
            }
        }
    }

    useEffect(() => {
        if (JobDetails?.id) {
            getJobDetails(JobDetails?.id);
            getApplicantStatus(JobDetails?.id);
        }
    }, [JobDetails?.id])

    return (
        <>
            <tr key={index}>
                <td>
                    <h4 className="link-text">
                        <Link
                            to={`/jobs/${encodeURIComponent(JobDetails?.profileId)}`}
                            onClick={() => window.scrollTo(0, 0)}
                            className="title-txt"
                        >
                            {JobDetails?.title}

                        </Link>
                    </h4>
                </td>
                <td>
                    <LinesEllipsis
                        className="nex-text"
                        text={JobDetails?.primarySpeciality}
                        maxLine="1"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                    />
                </td>
                <td>
                    <LinesEllipsis
                        className="nex-text"
                        text={JobDetails?.institutionDetails?.name}
                        maxLine="1"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                    />
                </td>
                <td>
                    <LinesEllipsis
                        className="nex-text"
                        text={<ShowExperience min={JobDetails?.minExperience} max={JobDetails?.maxExperience} />}
                        maxLine="1"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                    />
                </td>
                <td>
                    <LinesEllipsis
                        className="nex-text"
                        text={JobDetails?.city}
                        maxLine="1"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                    />
                </td>
                <td>
                    <LinesEllipsis
                        className="nex-text"
                        text={getPosition(JobDetails?.position)}
                        maxLine="1"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                    />
                </td>
                <td>
                    {jobFullDetails.createdOn && moment(jobFullDetails.createdOn).fromNow()}
                </td>
                {!isInstitutionAdmin() &&
                    <td>
                        {/*jobFullDetails.id && <div className='btn nex-btn-primary'>Apply Now</div>*/}
                        {jobFullDetails.id && getJobApplyAction(applicantStatus)}
                    </td>
                }
            </tr>

            <Modal
                isOpen={modal}
                toggle={toggleForm}
                backdrop="static"
                scrollable
                centered

                className='nex-apply-jobs-modal'
            >
                <ModalHeader toggle={toggleForm} className='nex-apply-jobs-modal-header'>
                    <div className="nex-flex">
                        <div className="nex-avatar sm">
                            <img src='https://images-dev.nexogic.com/78c57ab1-9bc0-4095-bb3b-54283acb88c7/030da8a0-f027-4bc8-a1a1-3c65ef0cafde.jpg' alt="" />
                        </div>
                        <div className="info">
                            <span className='card-title-sub'>Apply to</span> <span className='card-title' >{JobDetails?.title} , {JobDetails?.institutionDetails?.name}</span>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Apply practitioner={currentUser}
                        jobDet={jobFullDetails}
                        getJob={getApplicantStatus}
                        toggle={toggleForm}
                        getApplicantStatus={getApplicantStatus}
                    />
                </ModalBody>
            </Modal>
        </>
    )

}


const AllJobsTabularView = (props) => {
    const currentUser = useSelector((state) => state.auth.currentUser);
    const { theader, tdata, getData, setPrimarySpeciality, setCity, setOpportunityType } = props;

    return (
        <>
            <div className="nex-tabular-job-filter">
                <div className="nex-filter-icon"><svg width="29" height="27" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M26.7344 8.85937H2.26562C1.9051 8.85937 1.55933 8.72603 1.3044 8.48868C1.04947 8.25133 0.90625 7.92941 0.90625 7.59375C0.90625 7.25809 1.04947 6.93617 1.3044 6.69882C1.55933 6.46147 1.9051 6.32813 2.26562 6.32812H26.7344C27.0949 6.32812 27.4407 6.46147 27.6956 6.69882C27.9505 6.93617 28.0938 7.25809 28.0938 7.59375C28.0938 7.92941 27.9505 8.25133 27.6956 8.48868C27.4407 8.72603 27.0949 8.85937 26.7344 8.85937ZM22.2031 14.7656H6.79688C6.43635 14.7656 6.09058 14.6323 5.83565 14.3949C5.58072 14.1576 5.4375 13.8357 5.4375 13.5C5.4375 13.1643 5.58072 12.8424 5.83565 12.6051C6.09058 12.3677 6.43635 12.2344 6.79688 12.2344H22.2031C22.5637 12.2344 22.9094 12.3677 23.1643 12.6051C23.4193 12.8424 23.5625 13.1643 23.5625 13.5C23.5625 13.8357 23.4193 14.1576 23.1643 14.3949C22.9094 14.6323 22.5637 14.7656 22.2031 14.7656ZM16.7656 20.6719H12.2344C11.8738 20.6719 11.5281 20.5385 11.2732 20.3012C11.0182 20.0638 10.875 19.7419 10.875 19.4062C10.875 19.0706 11.0182 18.7487 11.2732 18.5113C11.5281 18.274 11.8738 18.1406 12.2344 18.1406H16.7656C17.1262 18.1406 17.4719 18.274 17.7268 18.5113C17.9818 18.7487 18.125 19.0706 18.125 19.4062C18.125 19.7419 17.9818 20.0638 17.7268 20.3012C17.4719 20.5385 17.1262 20.6719 16.7656 20.6719Z" fill="#395F7A" />
                </svg>
                </div>
                <JobFilter setPrimarySpeciality={setPrimarySpeciality} setCity={setCity} setOpportunityType={setOpportunityType} getData={getData} tabularView={true} />
            </div>
            <div className="jobs-table">
                <table className="table border" >
                    <thead>
                        <tr>
                            {theader.map((columns, index) => (
                                <th className="theader" key={index}>{columns}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tdata.map((JobDetails, index) => (
                            <JobTabularView JobDetails={JobDetails} index={JobDetails.id} currentUser={currentUser} />
                        ))
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default AllJobsTabularView