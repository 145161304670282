import React from "react";
import { Link, useHistory } from "react-router-dom";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { useState } from "react";
import secondOpinionright from "assets/images/second-opinion.png";
import profile_sample from "assets/images/user-profile-sample.png";
import { useEffect } from "react";
import AssignModal from "../SubElements/Modals/AssignModal";
import { OpinionService } from "utils/OpinionService";
import { FAILED_TO_FETCH_DATA, FAILTOSAVERECOED } from "utils/Constants";
import { MedialService } from "utils/MediaService";
import { isAdmin, isPatient } from "utils/UserHelper";
import CommonLoader from "common/CommonLoader";
import { createImageFromInitials, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { PatientService } from "utils/PatientService";
import { PractitionerService } from "utils/PractitionerService";
import { useRef } from "react";
import { withAlert } from "react-alert";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default withAlert()(function ReceivedOpinionDetailsPage(props) {
  const { opinionId } = props.match.params;
  const [opinionDetails, setOpinionDetails] = useState({});
  const [docs, setDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [documentType, setDocumentType] = useState(null);
  const [documentData, setDocumentData] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [patientInfo, setPatientInfo] = useState({});
  const [practitionerInfo, setPractitionerInfo] = useState({});
  const [isFileUploaing, setIsFileUploaing] = useState(false);
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Perform any necessary validation or processing of the selected file here
      setIsFileUploaing(true);
      // Create a FormData object to send the file data
      const formData = new FormData();
      formData.append('files', selectedFile);

      // Submit the file to the API using your preferred method (e.g., fetch, axios)
      MedialService.uploadMedia("SECOND_OPINION", opinionId, formData)
        .then((response) => {
          const { status } = response;
          if (status === 200) {
            showSuccessMessage("Document uploaded Successfully");
            getAllDocs(opinionId);
            setIsFileUploaing(false);
          } else if (status === 409) {
            toast.info("Can't upload the document with the same name again");
            setIsFileUploaing(false);
          }
          else {
            showErrorMessage(FAILTOSAVERECOED);
            setIsFileUploaing(false);
          }
        })
    }
  };

  const getPatientInfo = (opinion) => {
    if (opinion?.requestedBy === opinion?.requestedFor) {
      PatientService.getPatientById(opinion?.requestedBy)
        .then((response) => {
          const { status, data } = response;
          if (status === 200) {
            setPatientInfo(data);
          } else {
            console.error(FAILED_TO_FETCH_DATA);
          }
        })
    } else {
      PatientService.getRelatedPersonById(opinion?.requestedBy, opinion?.requestedFor)
        .then((response) => {
          const { status, data } = response;
          if (status === 200) {
            setPatientInfo(data);
          } else {
            console.error(FAILED_TO_FETCH_DATA);
          }
        })
    }
  }

  const getPractitionerInfo = (practitionerAssignedTo) => {
    PractitionerService.getPractitionerProfileById(practitionerAssignedTo)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          setPractitionerInfo(data);
        } else {
          console.error(FAILED_TO_FETCH_DATA);
        }
      })
  }

  const getAllDocs = (opinionId) => {
    MedialService.getMedias("SECOND_OPINION", opinionId)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          setDocs(data);
        }
        else {
          console.error(FAILED_TO_FETCH_DATA);
        }
      })
  }

  const history = useHistory();
  const handleBack = () => {
    history.goBack();
  };
  const [pannel_one_on, setPannel_one_on] = useState(true);
  const [pannel_two_on, setPannel_two_on] = useState(true);


  const [reqAssign, setReqAssign] = useState(false);
  const [isAssignModal, setIsAssignModal] = useState(false);

  function openAssignModal(e) {
    e.preventDefault();
    setIsAssignModal(!isAssignModal);
  }

  function haldleAssign(data) {
    if (data === true) {
      setIsAssignModal(false);
      setReqAssign(data);
    }
  }

  function haldleUnassign(e) {
    e.preventDefault();
    setReqAssign(false);
  }

  useEffect(() => {
    document.body.classList.add("nexogic-so-page");
    return () => document.body.classList.remove("nexogic-so-page");
  }, []);

  const getOpinionDetails = (id) => {
    OpinionService.getOpinion(opinionId)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          setOpinionDetails(data);
      
          getPatientInfo(data);
          if (data?.practitionerAssignedTo)
            getPractitionerInfo(data?.practitionerAssignedTo)
          setIsLoading(false);
        } else {
          history.push("/404");
          console.error(FAILED_TO_FETCH_DATA);
          setIsLoading(false);
        }
      })
  }

  useEffect(() => {
    getOpinionDetails(opinionId);
    getAllDocs(opinionId);
  }, [opinionId])

  const getReferralDoc = async (id) => {
    try {
      setDataLoading(true);
      const { data, headers } = await MedialService.getMediaFile(id);
      const createContentType = headers["content-type"] === "application/octet-stream"
        ? "application/pdf"
        : headers["content-type"];
      let generateURL = `data:${createContentType};base64,${Buffer.from(data).toString("base64")}`;
      setDocumentData(generateURL);
      let docArray = [data, createContentType];
      setDocumentType(docArray);
      if (createContentType === "application/pdf") {
        setDataLoading(false);
        onOpenBase64(generateURL);
      }
      else {
        setDataLoading(false);
        toggleForm();
      }
    } catch (error) {
      console.error("Error retrieving referral document:", error);
    }
  };

  const onOpenBase64 = (base64URL) => {
    // const onOpenBase64 = () => {
    try {
      var win = window.open();
      win.document.write(`
        <iframe class="nexogic-iframe" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" 
          src="${base64URL}" frameborder="0" allowfullscreen></iframe>
      `);
    } catch (error) {
      console.error("Error opening base64 URL:", error);
      // Handle error, show error message, etc.
    }
  };

  const toggleForm = () => setModal(!modal);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = documentData;
    let ext = documentType[1].split('/').pop();
    link.download = 'reports.' + ext;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.click();
  };

  if (isLoading) {
    return (
      <div className="loading-spiner text-center">
        <CommonLoader />
      </div>
    )
  }

  return (
    <div className="nexogic-second-view-details">
      <Card className="nexogic-form-text-display-w border-0 bg-transparent">
        <CardBody className="">
          <Row className="need-margin">
            <Col>
              <div className="position-relative mb-3">
                <div className="nex-heading-sec">
                  <h1 className="nex-text-xxl">Patient Information</h1>
                </div>

                <Card
                  className={`nex-list-card-v2 nexogic-so-list-card`}
                >
                  <CardBody>
                    <div className="nex-stack wrap nex-so-stack ">
                      <div className="nex-avatar-info nex-flex md">
                        <div className="graphic">
                          <img src={createImageFromInitials(100, patientInfo?.firstName + " " + patientInfo?.lastName, getRandomColor(encrypt(21).toString()))} alt="" className="nex-avatar lg" />
                        </div>
                        <div className="info">
                          <h2 className='nex-text stack-title'>{(patientInfo?.id && opinionDetails?.requestedBy === opinionDetails?.requestedFor) ?
                            <Link to={`/patient-profile/${patientInfo?.id}`}>{patientInfo?.firstName}{" "}{patientInfo?.lastName}</Link>
                            :
                            <span>{patientInfo?.firstName}{" "}{patientInfo?.lastName}</span>
                          }</h2>
                          <p className='nex-text text-black'>Phone : {patientInfo?.cellularPhoneNumber || 'N/A'}</p>
                          <p className='nex-text text-black'> Blood group : {patientInfo?.bloodGroup || 'N/A'}</p>
                        </div>

                      </div>
                      <div className="info ">
                        <p className='nex-text text-black'>Speciality :  {opinionDetails?.speciality || 'N/A'}</p>
                        <p className='nex-text text-black'>Email : {patientInfo?.emailAddress || 'N/A'}</p>
                        <div className='nex-text'><StatusButton opinionDetails={opinionDetails} getOpinionDetails={getOpinionDetails} /></div>
                      </div>
                      <div className="info">
                        <p className='nex-text text-black'>Weight :  {patientInfo?.weight || 'N/A'}</p>
                        <p className='nex-text text-black'>DOB : {patientInfo?.dateOfBirth || 'N/A'}</p>
                      </div>
                      <div className="info">
                        {/*<p className='nex-text text-black'>Marital Status : Married</p>*/}
                        <p className='nex-text text-black'>Height : {patientInfo?.height || 'N/A'}</p>
                      </div>
                    </div>
                    <div className="nex-stack wrap nex-so-stack mt-3 align-items">
                      <div className="info w-full">
                        <p className="nex-text text-black bold mb-0">Address:</p>
                      </div>
                      <div className="info sm">
                        <p className="nex-text text-black">City : {patientInfo?.address?.city}</p>
                      </div>
                      <div className="info sm">
                        <p className="nex-text text-black">State : {patientInfo?.address?.state}</p>
                      </div>
                      <div className="info sm">
                        <p className="nex-text text-black">Zip Code : {patientInfo?.address?.zipCode}</p>
                      </div>
                      <div className="action ml-auto md">
                        <Link to={`/u/medicalcases/received-opinions/${opinionId}/response`} className="btn nex-btn-primary">Provide/View Response</Link>
                      </div>
                    </div>
                    {/** old code start here  */}
                    <div className="row d-none">
                      <div className="col-md-4 col-sm-6 nexogic-flex-col">
                        <div className="graphic nexogic-avatar size-m">
                          <div className="graphic">
                            <div className="card-graphic"><img src={createImageFromInitials(100, patientInfo?.firstName + " " + patientInfo?.lastName, getRandomColor(encrypt(21).toString()))} className="rounded-circle" width="70" height="70" /></div>
                          </div>
                        </div>
                        <div className="info">
                          <p className="card-text">

                            <b>Name</b> :
                            {patientInfo?.id ?
                              <Link to={`/patient-profile/${patientInfo?.id}`}>{patientInfo?.firstName}{" "}{patientInfo?.lastName}</Link>
                              :
                              <span>{patientInfo?.firstName}{" "}{patientInfo?.lastName}</span>
                            }
                          </p>
                          <p className="card-text">
                            <b>Phone</b> : {patientInfo?.cellularPhoneNumber}
                          </p>
                          <p className="card-text">
                            <b>Blood group</b> : {patientInfo?.bloodGroup}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6 nexogic-flex-col">
                        <div className="info">
                          <p className="card-text">
                            <b>Specialty</b> : {opinionDetails?.speciality}
                          </p>
                          <p className="card-text">
                            <b>E-mail</b> : {patientInfo?.emailAddress}
                          </p>
                          <p className="card-text">
                            <b>Height</b> : {patientInfo?.height}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 nexogic-flex-col">
                        <div className="info">
                          <p className="card-text">
                            <b>Weight</b> : {patientInfo?.weight}
                          </p>
                          <p className="card-text">
                            <b>Date of birth</b> : {patientInfo?.dateOfBirth}
                          </p>
                          <p className="card-text">
                            <b>Marital status</b> : Married
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3 d-none">
                      <h3 className="card-title">Address</h3>
                      <div className="col-lg-12 nexogic-bg-light address-sec">
                        <div className="row">

                          <div className="col-md-4 nexogic-flex-col">
                            <div className="info">
                              <p className="card-text">
                                <b>City</b> : {patientInfo?.address?.city}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-4 nexogic-flex-col">
                            <div className="info">
                              <p className="card-text">
                                <b>State</b> : {patientInfo?.address?.state}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-4 nexogic-flex-col">
                            <div className="info">
                              <p className="card-text">
                                <b>Zip code</b> : {patientInfo?.address?.zipCode}
                              </p>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    {/*** old code end here */}
                  </CardBody>
                </Card>

                <Card
                  className={`nexogic-card-color-header ${pannel_two_on ? "expanded" : ""
                    }`}
                >
                  <div className="card-header">
                    <h2>Medical History</h2>
                    <button
                      className="collapse-btn"
                      type="button"
                      onClick={() => setPannel_two_on(!pannel_two_on)}
                    >
                      <svg width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2 10.5L10.5 2L19 10.5" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" /></svg>
                    </button>
                  </div>
                  <div className="collapse-div">
                    <CardBody>
                      <ul className="nex-question-lists">
                        <li>
                          <p className="nex-text-lg text-black bold">
                            What are your current concerns: Are you presently
                            suffering from any acute illness?
                          </p>
                          <p className="nex-text">{opinionDetails?.title ? opinionDetails?.title : "N/A"}</p>
                        </li>
                        <li>
                          <p className="nex-text-lg text-black bold">Symptoms</p>
                          <p className="nex-text">{opinionDetails?.symptoms ? opinionDetails?.symptoms : "N/A"}</p>
                        </li>
                        <li>
                          <p className="nex-text-lg text-black bold">Allergies</p>
                          <p className="nex-text">{opinionDetails?.allergies ? opinionDetails?.allergies : "N/A"}</p>
                        </li>
                        <li>
                          <p className="nex-text-lg text-black bold">Surgeries</p>
                          <p className="nex-text">{opinionDetails?.surgeries ? opinionDetails?.surgeries : "N/A"}</p>
                        </li>
                        <li>
                          <p className="nex-text-lg text-black bold">
                            Do you have a strong family history of any serious
                            illnesses? (cancer, heart disease etc)
                          </p>
                          <p className="nex-text">
                            {opinionDetails?.familyHistory ?
                              <p dangerouslySetInnerHTML={{ __html: opinionDetails?.familyHistory }}></p>
                              : "N/A"}
                          </p>
                        </li>
                        <li>
                          <p className="nex-text-lg text-black bold">
                            Any past hospitalizations?
                          </p>
                          <p className="nex-text">
                            {opinionDetails?.pastHospitalization ?
                              <p dangerouslySetInnerHTML={{ __html: opinionDetails?.pastHospitalization }}></p>
                              : "N/A"}
                          </p>
                        </li>
                        <li>
                          <p className="nex-text-lg text-black bold">Any current medication?</p>
                          <p className="nex-text">{opinionDetails?.currentMedications ?
                            <p dangerouslySetInnerHTML={{ __html: opinionDetails?.currentMedications }}></p>
                            : "N/A"}
                          </p>
                        </li>
                      </ul>
                    </CardBody>
                  </div>
                </Card>
              </div>
            </Col>
            <Col className="nex-sidebar v2">
              <div className="nexogic-widget-sidebar-card card border-0 d-none">
                <div className="card-body">
                  <div className="status-col position-top"><span className="status-pill pending"><StatusButton opinionDetails={opinionDetails} getOpinionDetails={getOpinionDetails} /> </span></div>
                </div>
              </div>

              <Card className="nexogic-widget-sidebar-card bg-white">
                <h2 className="nex-aside-title">
                  Documents
                  <span className="icon ml-auto"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 13H14M8 17H16M13 3H5V21H19V9M13 3H14L19 8V9M13 3V7C13 8 14 9 15 9H19" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg></span>

                </h2>
                <div className="collapse-div nexogic-so-documents">
                  <CardBody>
                    {(docs && docs.length > 0) ?
                      <div className="nexogic-custom-scroll">
                        <div className="nexogic-document-lists">
                          <ul>
                            {docs.map((doc, index) => (
                              <li key={index} >
                                <div className="nexogic-document-item" style={{ cursor: "pointer" }} onClick={() => getReferralDoc(doc?.id)}>
                                  <svg className='icon'
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="32"
                                    fill="none"
                                    viewBox="0 0 26 32"
                                  // onClick={() => getResponseDoc(doc?.id)}
                                  >
                                    <path
                                      stroke="#3EABCA"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M16 1H4a3 3 0 00-3 3v24a3 3 0 003 3h18a3 3 0 003-3V10m-9-9l9 9m-9-9v9h9m-6 7.5H7m12 6H7m3-12H7"
                                    >
                                    </path>
                                  </svg>
                                  <div className="card-text">{doc?.name}</div>
                                </div>
                              </li>
                            ))}
                          </ul>

                        </div>
                      </div>
                      :
                      <div className="nexogic-not-found-text">
                        <div className="icon">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 13V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18L4 13M16 8L12 4M12 4L8 8M12 4L12 16" stroke="#46ABC9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg></div>

                        No Documents</div>
                    }
                    <div className="action">

                      {(isPatient() || isAdmin()) &&
                        <>
                          {isFileUploaing ?
                            <div className="text-center mb-5" style={{ height: "10px" }} >
                              <CommonLoader />
                            </div>
                            :
                            <button
                              className="btn nexogic-download-btn nex-btn-primary"
                              onClick={handleUploadClick}
                            >
                              <span className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="none"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 10.333v3.111A1.556 1.556 0 0113.444 15H2.556A1.556 1.556 0 011 13.444v-3.11m10.889-5.445L8 1m0 0L4.111 4.889M8 1v9.333"
                                  ></path>
                                </svg>
                              </span>
                              Uploads
                            </button>
                          }
                          <input
                            name="uploadDocument"
                            type="file"
                            id="uploadDocument"
                            accept="image/jpeg,image/jpg,image/png,application/pdf"
                            title="Upload Document"
                            className="nexogic-file-upload-input"
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                          />
                        </>
                      }</div>
                  </CardBody>
                </div>
                {
                  dataLoading ?
                    <div className="" style={{ zIndex: "111", position: "absolute", top: "0", left: "0", backgroundColor: "#edeff0b0", width: "100%", height: "100%" }}>
                      <div className="component-wrapper"><CommonLoader /></div>
                    </div>
                    :
                    <Modal isOpen={modal} toggle={toggleForm} backdrop="static" scrollable centered >
                      <ModalHeader toggle={toggleForm}><span style={{ color: "gray" }}>Document Viewer</span></ModalHeader>
                      <ModalBody>
                        <div className="modalbody">
                          <img src={documentData} alt="document preview" />
                        </div>
                        <div className="downloadbtn">
                          <FontAwesomeIcon color="#0087b9" size="xl" style={{ cursor: "pointer" }} icon={faDownload} onClick={handleDownload} />
                        </div>
                      </ModalBody>
                    </Modal>
                }
              </Card>

              {isPatient() &&
                <Card>
                  <div className="nexogic-widget-sidebar-card card border-0">
                    <div className="card-body">
                      <div className="nexogic-h-btn-groups">
                        <Link to={`/u/medicalcases/edit-my-second-opinion/${opinionId}`}
                          className="btn d-block nexogic_primary_button">
                          Edit
                        </Link>
                      </div>
                    </div>
                  </div>
                </Card>
              }

              <Card className="mt-3 mb-3 d-none">
                <div className="nexogic-widget-sidebar-card card border-0 ">
                  <div className="card-body">
                    <div className="nexogic-h-btn-groups">
                      <Link to={`/u/medicalcases/received-opinions/${opinionId}/response`}
                        className="btn d-block nexogic_primary_button">
                        Provide/View Response
                      </Link>
                    </div>
                  </div>
                </div>
              </Card>

              {false &&
                <div className="nexogic-widget-sidebar-card card border-0">
                  <div className="card-body">
                    <div className="nexogic-h-btn-groups">
                      {reqAssign === false && (
                        <a
                          href=""
                          className="btn d-block nexogic_primary_button"
                          onClick={(e) => openAssignModal(e)}
                        >
                          Assign
                        </a>
                      )}

                      {reqAssign === true && (
                        <Link
                          to="/u/medicalcases/details/consultlation/add"
                          className="btn d-block nexogic_primary_button"
                        >
                          Provide  Consultation
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              }

              {/** Selected assign patitioner or groups */}
              {reqAssign === true && (
                <div className="nexogic-widget-sidebar-card card border-0 nexogic-widget-assign">
                  <h3 className="card-title h2">Currently Assign</h3>
                  <div className="card-body">
                    <div className="nexogic-assign-pratitioner">
                      <div className="nexogic-practitioner-search-lists">
                        <div className="item selected">
                          <div className="graphic">
                            <img src={profile_sample} alt="" className="avatar" />
                          </div>
                          <div className="info">
                            <h3 className="card-title">Dr. Anamica Mitra (MBBs)</h3>
                            <p className="card-text light">Amri hospital</p>
                          </div>
                          <span className="active-check">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="9"
                              fill="none"
                              viewBox="0 0 21 14"
                            >
                              <path
                                stroke="#fff"
                                strokeWidth="2"
                                d="M1 4.781L7.88 12 20 1"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="actions">

                      <a
                        href=""
                        className="btn d-block nexogic_primary_button_red"
                        onClick={(e) => haldleUnassign(e)}
                      >
                        Unassign
                      </a>

                    </div>
                  </div>
                </div>
              )}

              <div className="nexogic-widget-sidebar-card card nexogic-sidebar-payment-card">
                <h3 className="nex-aside-title bg-light-purple">Payment <span className="icon ml-auto"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21 10V8C21 6.89543 20.1046 6 19 6H5C3.89543 6 3 6.89543 3 8V10M21 10V16C21 17.1046 20.1046 18 19 18H5C3.89543 18 3 17.1046 3 16V10M21 10H3M6 15H10" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                </span></h3>
                <div className="card-body">
                  <div className="info">
                    <p>
                      Second Opinion Allergy and Immunology Second Opinion -
                      Full Chart Review
                    </p>
                    <div className="price-sec">
                      <p>Total Payment : <br /><strong>$100</strong></p>
                      <p className="nex-pill green sm"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 9L9.99998 16L6.99994 13M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                        Paid</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nexogic-widget-sidebar-card card">
                <h3 className="nex-aside-title">Second Opinion</h3>
                <div className="card-body">
                  <ul className="nexogic-lists nexogic-custom-scroll">
                    <li>
                      <img
                        src={secondOpinionright}
                        alt=""
                        className="img-fluid"
                      />
                    </li>
                  </ul>
                </div>
              </div>

            </Col>
          </Row>
          {/* assign modal */}
          <AssignModal open={isAssignModal} setToggle={setIsAssignModal} onAssign={haldleAssign} />
        </CardBody>
      </Card>
      {
        dataLoading ?
          <div className="" style={{ zIndex: "111", position: "absolute", top: "0", left: "0", backgroundColor: "#edeff0b0", width: "100%", height: "100%" }}>
            <div className="component-wrapper"><CommonLoader /></div>
          </div>
          :
          <Modal isOpen={modal} toggle={toggleForm} backdrop="static" scrollable centered >
            <ModalHeader toggle={toggleForm}><span style={{ color: "gray" }}>Document Viewer</span></ModalHeader>
            <ModalBody>
              <div className="modalbody">
                <img src={documentData} alt="document preview" />
              </div>
              <div className="downloadbtn">
                <FontAwesomeIcon color="#0087b9" size="xl" style={{ cursor: "pointer" }} icon={faDownload} onClick={handleDownload} />
              </div>
            </ModalBody>
          </Modal>
      }
    </div>
  );
}
)

export const StatusButton = ({ opinionDetails, getOpinionDetails }) => {
  const [actions, setActions] = useState([])
  const [dropDownItem, setDropDownItem] = useState();
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    setActionsForTheStatus(opinionDetails?.status);
  }, [opinionDetails?.status])


  function getStatusClass(inp) {
    let classes = '';
    switch (inp) {
      case "closed":
        classes += ' accepted';
        break;
      case "submitted":
        classes += ' accepted';
        break;
      case "accept":
        classes += ' accepted';
        break;
      case "reject":
        classes += ' rejected';
        break;
      default:
        classes += '';
    }
    return classes;
  }

  function setActionsForTheStatus(status) {
    if (status === "RESPONDED") {
      setActions(["Close"])
    } else {
      setActions([])
    }

    // if (status === "ASSIGNED") {
    //   setActions(["Reject"])
    // } else if (status === "RESPONDED") {
    //   setActions(["Close"])
    // } else {
    //   setActions([])
    // }
  }


  const handleUpdateOpinionStatus = (status) => {
    let act = "";
    switch (status) {
      case "Reject":
        act = "REJECTED";
        break;
      default:
        act = "CLOSED";
    }

    const payload = {
      "speciality": opinionDetails?.speciality,
      "title": opinionDetails?.title,
      "requestedBy": opinionDetails?.requestedBy,
      "requestedFor": opinionDetails?.requestedFor,
      "status": act
    }


    OpinionService.updateOpinion(opinionDetails?.id, payload)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          showSuccessMessage(`Opinion updated Successfully.`);
          getOpinionDetails(opinionDetails?.id);
        }
        else {
          showErrorMessage(FAILTOSAVERECOED);
        }
      })
  }

  return (

    <Dropdown
      id="nex-rccf-status-btn"
      isOpen={dropDownItem == opinionDetails.id}
      toggle={(e) =>
        dropDownItem === opinionDetails?.id
          ? setDropDownItem(null)
          : setDropDownItem(opinionDetails?.id)
      }
    >
      <DropdownToggle
        id="nex-rccf-status-btn1"
        className={`nex-pill sm bold border-0 ${opinionDetails?.status?.toLowerCase()} ${getStatusClass(opinionDetails?.status?.toLowerCase())}`} caret color="primary">
        {opinionDetails?.status
          .toLowerCase()
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
        }
      </DropdownToggle>
      {(opinionDetails?.status === 'ASSIGNED' || opinionDetails?.status === 'RESPONDED') && (
        <DropdownMenu right className={`${getStatusClass(opinionDetails?.status?.toLowerCase())}`}>
          {actions.map((action, index) => (
            <DropdownItem
              id={`nex-rccf-status-dynamic-${index}`}
              key={index}
              onClick={(e) => {
                handleUpdateOpinionStatus(action);
              }}
            >
              {opinionDetails?.status != action && action}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </Dropdown>

  )
}