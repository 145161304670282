import React, { useState,useEffect } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Card, Col, Row } from 'reactstrap';
import itemType from './Components/Themes/ThemeContents/utils';
// import 'assets/styles/global.scss'
import ThemeOneTemplate from './Components/Themes/Components/ThemeOne/ThemeOneTemplate';
import ThemeOneTemplate2 from './Components/Themes/Components/ThemeOne/ThemeOneTemplate2';
import ThemeThree from './Components/Themes/Components/ThemeThree';
import ThemeTwo from './Components/Themes/Components/ThemeTwo';
import ThemeTwoTemplate from './Components/Themes/Components/ThemeOne/ThemeTwoTemplate';
import ThemeThreeTemplate from './Components/Themes/Components/ThemeOne/ThemeThreeTemplate';

const Draggable = ({ id, text,content, onDragEnd }) => {
    const [{ isDragging }, drag] = useDrag({
        // type: 'draggable',
        type: itemType.CARD,
        item: { id, text ,content},
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        // canDrag: () => {
        //     return !droppedItem || droppedItem.id === item.id;
        //   },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
                onDragEnd(item.id);
            }
        },
    });

    return (
        <div ref={drag} className="file-item" style={{ opacity: isDragging ? 0.5 : 1 }}>
        <Card className='content-card text-center'>{text}</Card>
    </div>
    );
}

const ThemeDesign2 = () => {
    const { state } = useLocation();
    const [themePColors,setThemePColors] = useState(state.themecolors);
    const [draggableItems, setDraggableItems] = useState([
        { id: '1', text: 'About', content:["p","Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer."]},
        { id: '2', text: 'Gallery', content:["image",['Ablation For Treatment Of Atrial Fibrillation', 'Abdominal Hysterectomy', 'Achilles Tendon Rupture Repair', 'asdadddd', 'Acl Anterior Cruciate Ligament Surgery']] },
        { id: '3', text: 'Business Hours', content:["p","when an unknown printer took a galley of type and scrambled it to make a type specimen book when an unknown printer took a galley of type and scrambled it to make a type specimen book when an unknown printer took a galley of type and scrambled it to make a type specimen book"]},
        { id: '4', text: 'Specialities', content:["list",['Neonatologist', 'Abdominal Imaging', 'Emergency Medicine', 'Health And Culture', 'Acupressure', 'Clinical Pharmacology', 'Child Neurology', 'Child Podiatrist']]},
        { id: '5', text: 'Services', content:["list",['Bbbsdfg','Ablation For Treatment Of Atrial Fibrillation','Eeedfgdfg','Ddddfgdfg','Acetabular Surgery','Abdominal Paracentesis','Abscess Incision And Drainage','Abdominoplasty','Ablation','Child Care','Abdominal Aortic','Abdominal Surgery']]},
        { id: '6', text: 'Amenities', content:["list",['24 Hours Childrens Emergency Services','24 Hours Cardiac Emergency','24 Hour Ambulance','24 Hours Blood Bank','24 Hour Pharmacy','Advanced Lab Services','Children Park Area','AC Rooms','24 Hrs Pathology']]},
        { id: '7', text: 'Other Branches', content:["list",['Delhi Asthama And Diabetes Centre Child Care Clinic']]},
    ]);

    const [draggableWidgets, setDraggableWidgets] = useState([
        { id: '1', text: 'Search', content:["search","Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer."]},
        { id: '2', text: 'Jobs', content:["job",['Ablation For Treatment Of Atrial Fibrillation', 'Abdominal Hysterectomy', 'Achilles Tendon Rupture Repair', 'asdadddd', 'Acl Anterior Cruciate Ligament Surgery']] },
        { id: '3', text: 'Features', content:["feature","when an unknown printer took a galley of type and scrambled it to make a type specimen book when an unknown printer took a galley of type and scrambled it to make a type specimen book when an unknown printer took a galley of type and scrambled it to make a type specimen book"]},
    ]);
    const [droppedItemIds, setDroppedItemIds] = useState([]);

    const handleDragEnd = (id) => {
        // console.log("handleDragEnd-----------", id);
        setDraggableItems(items => items.filter(item => item.id !== id));
    };

    const handleDroppedItems = (dropElement) => {
        // console.log("---------------------handleDroppedItem",dropElement);
        setDroppedItemIds(dropElement);
        // console.log("------------after---------handleDroppedItem",droppedItemIds);
    };

    useEffect(() => {
        if (draggableItems.length > 1) {
            draggableItems.sort((a, b) => a.id - b.id);
        }
    }, [draggableItems.length])

    return (
        <div className='container-fluid microsite-side-main' style={{}}>
            <main>
                <DndProvider backend={HTML5Backend}>
                    <Row>
                        <Col md={4} lg={3}>
                            <Row>
                                <Col className="card-fixed fixed-top">
                                    <Card className='mb-2'>
                                        <div className='main-header'>THEME CONTENTS</div>
                                    </Card>
                                    <Row className="">
                                        <Col md={12} lg={12} className='h-100'>
                                            <Card className='vheight'>
                                                {/* <div style={{ display: 'flex', height: "100px", backgroundColor: "green", flexWrap: "wrap", justifyContent: "space-around" }}> */}
                                                    {draggableItems.map(item => (
                                                        <Draggable key={item.id} id={item.id} text={item.text} content={item.content} onDragEnd={handleDragEnd} />
                                                    ))}
                                                {/* </div> */}
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Card className='mb-1'>
                                        <div className='main-header'>WIDGETS</div>
                                    </Card>
                                    <Row className="">
                                        <Col md={12} lg={12} className='h-100'>
                                            <Card className='vheight'>
                                                {/* <div style={{ display: 'flex', height: "100px", backgroundColor: "green", flexWrap: "wrap", justifyContent: "space-around" }}> */}
                                                    {draggableWidgets.map(widgets => (
                                                        <Draggable key={widgets.id} id={widgets.id} text={widgets.text} content={widgets.content} onDragEnd={handleDragEnd} />
                                                    ))}
                                                {/* </div> */}
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={8} lg={9} className="">
                            <Row>
                                <Col className="pl-0 ml-0">
                                    <Card className='p-0 border-0'>
                                        {/* <ThemeTwo /> */}
                                        {state.themename==='THEME2' ? 
                                        <ThemeOneTemplate2 themeDetails={themePColors} setThemePColors={setThemePColors} setDraggableItems={setDraggableItems} droppedItem={(elem)=>handleDroppedItems(elem)} />
                                        :state.themename==='THEME3' ?
                                        <ThemeTwoTemplate themeDetails={themePColors} setThemePColors={setThemePColors} setDraggableItems={setDraggableItems} droppedItem={(elem)=>handleDroppedItems(elem)} />
                                        :state.themename==='THEME4' ?
                                        <ThemeThreeTemplate themeDetails={themePColors} setThemePColors={setThemePColors} setDraggableItems={setDraggableItems} droppedItem={(elem)=>handleDroppedItems(elem)} />
                                        :''}
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>                    
            </DndProvider>
        </main>
        </div >
    );
}

export default ThemeDesign2;
