import React from 'react';
import {  Modal, ModalHeader, ModalBody } from 'reactstrap';
import PublicationForm from "./PublicationForm"
export const  RenderPublicatinModule = (props) => {
  const {
    isOpen,
    toggle
  } = props;

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} id="profile-modal" className="modal-dialog-scrollable modal-dialog-centered" backdrop="static">
      <ModalHeader toggle={toggle}>{"Publications & Presentations"}</ModalHeader>
        <ModalBody>
          <PublicationForm {...props}/>
        </ModalBody>
      </Modal>
    </div>
  );
}

