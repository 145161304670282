import React, { useEffect, useState, useRef, useCallback } from 'react'
import { ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import './css/style.scss'
import CustomThemeModalForm from './CustomThemeModalForm';
import FormikInputField from 'components/forms/formikFields/FormikInputField';
import { Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeDropper, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ChooseTheme = (props) => {
  const { setSelectedTheme, values, setFieldValue } = props;
  const [isOpen, setModal] = useState(false);
  const toggleModal = () => setModal(!isOpen);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [colorFlag, setColorFlag] = useState(false);
  // const [currentSelectedTheme, setCurrentSelectedTheme] = useState({ label:values.theme, color:values.color });
  const [themeDropdowItem, setThemeDropdowItem] = useState([
    // { label: "Theme Light", color: ['#e9c46a', '#264653', '#ce9305', '#ffffff', '#f4a261', '#029b9b'], },
    // { label: "Theme Light0", color: ['#609966', '#EDF1D6', '#9DC08B', '#609966', '#40513B', '#9DC08B'] },
    // { label: "Theme Light1", color: ['#e9c46a', '#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#2a9d8f'] },
    // { label: "Theme Light2", color: ['#a8dadc', '#f1faee', '#e63946', '#a8dadc', '#457b9d', '#219ebc'] },
    // { label: "Theme Light3", color: ['#ffb703', '#fefae0', '#219ebc', '#ffb703', '#fb8500', '#219ebc'] },
    // { label: "Theme Light4", color: ['#d62828', '#eae2b7', '#f77f00', '#d62828', '#003049', '#f77f00'] },
    { label: "Custom Theme", color: [`${values.color0}`, `${values.color1}`, `${values.color2}`, `${values.color3}`, `${values.color4}`, `${values.color5}`] },
  ]);

  const colorPalateTitle = ["Background ", "Page Heading", "Page Text", "Background ", "Header Background", "Heading "];

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const selectTheme = (item) => {
    if (item.label === "Custom Theme") {
      toggleModal();
    }
    // setCurrentSelectedTheme(item);
    // setSelectedTheme(item);
    // setFieldValue('color',item.color);
  };

  const themeRefresh = () => {
    setColorFlag(true)
  }

  useEffect(() => {
    themeRefresh()
  }, [values.color0, values.color1])

  return (
    <div className='micro-choose-theme p-2'>
      <div className="nex-pad-15">
        <h3 className="nex-text-xl small section-title mb-3">Choose Colors</h3>
        <div className="nex-flex nex-micro-choose-colors">
          <div className="nex-micro-choose-color">
            <div className="nex-text-lg text-black title">Page Colours</div>
            <div className="nex-color-boxes">
              <div className="nex-color-box">
                <div>
                  <Field name="color0" type="color" title={colorPalateTitle[0]} placeholder={colorPalateTitle[0]} className='cthemecolor m-0 p-0' />
                </div>
                <div className="nex-text mt-3">Background</div>
              </div>
              <div className="nex-color-box">
                <div>
                  <Field name="color1" type="color" title={colorPalateTitle[1]} component={FormikInputField} placeholder={colorPalateTitle[1]} className='cthemecolor' />
                </div>
                <div className="nex-text mt-3">Page Heading</div>
              </div>
              <div className="nex-color-box">
                <div>
                  <Field name="color2" type="color" title={colorPalateTitle[2]} component={FormikInputField} placeholder={colorPalateTitle[2]} className='cthemecolor' />
                </div>
                <div className="nex-text mt-3">Page Text</div>
              </div>
            </div>
          </div>
          <div className="nex-micro-choose-color">
            <div className="nex-text-lg text-black title">Section Colours</div>
            <div className="nex-color-boxes">
              <div className="nex-color-box">
                <div>
                <Field name="color3" type="color" title={colorPalateTitle[3]} component={FormikInputField} placeholder={colorPalateTitle[3]} className='cthemecolor m-0 p-0' />
              </div>
                <div className="nex-text mt-3">Background</div>
              </div>
              <div className="nex-color-box">
                <div>
                <Field name="color4" type="color" title={colorPalateTitle[4]} component={FormikInputField} placeholder={colorPalateTitle[4]} className='cthemecolor m-0 p-0' />
              </div>
                <div className="nex-text mt-3">Page Heading</div>
              </div>
              <div className="nex-color-box">
                <div>
                <Field name="color5" type="color" title={colorPalateTitle[5]} component={FormikInputField} placeholder={colorPalateTitle[5]} className='cthemecolor m-0 p-0' />
              </div>
                <div className="nex-text mt-3">Page Text</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='d-flex flex-wrap justify-content-between w-100'>
        <div className='theme-outer-container'>
            <div className='d-flex justify-content-between w-100'>
              <div className='d-flex align-items-center justify-content-between mb-2 mb-2'>
                <div>
                  <Field name="color0" type="color" title={colorPalateTitle[0]} component={FormikInputField} placeholder={colorPalateTitle[0]} className='cthemecolor m-0 p-0' />
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-between mb-2'>
                <div>
                  <Field name="color1" type="color" title={colorPalateTitle[1]} component={FormikInputField} placeholder={colorPalateTitle[1]} className='cthemecolor' />
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-between mb-2'>
                <div>
                  <Field name="color2" type="color" title={colorPalateTitle[2]} component={FormikInputField} placeholder={colorPalateTitle[2]} className='cthemecolor' />
                </div>
              </div>
            </div>
        </div> */}
        {/* <div className='theme-outer-container flex-row'>
            <div className='d-flex justify-content-between w-100'>
            <div className='d-flex align-items-center justify-content-between mb-2 mb-2'>
              <div>
                <Field name="color3" type="color" title={colorPalateTitle[3]} component={FormikInputField} placeholder={colorPalateTitle[3]} className='cthemecolor m-0 p-0' />
              </div>
            </div>
            <div className='d-flex align-items-center justify-content-between mb-2'>
              <div>
                <Field name="color4" type="color" title={colorPalateTitle[4]} component={FormikInputField} placeholder={colorPalateTitle[4]} className='cthemecolor m-0 p-0' />
              </div>
            </div>
            <div className='d-flex align-items-center justify-content-between mb-2'>
              <div>
                <Field name="color5" type="color" title={colorPalateTitle[5]} component={FormikInputField} placeholder={colorPalateTitle[5]} className='cthemecolor m-0 p-0' />
              </div>
            </div>
        </div> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  )
}
export default ChooseTheme;
