import { Card, CardBody, Col, Row, Button } from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import CommonLoader from "common/CommonLoader";
import { useEffect, useState } from "react";
import ProfileCardItem from "./ProfileCardItem";
import { PractitionerService } from "utils/PractitionerService";
import { FAILED_TO_FETCH_DATA } from "utils/Constants";
import profile_icon from "assets/images/profile-icon.png";
import arrowback from "assets/images/arrowback.png";
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";
import arrow_left from "assets/images/arrowleft.png";
import "./style.scss"

const PractitionerProfiles = ({ drname }) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [contents, setContents] = useState([]);
    const [initialValues, setInitialValues] = useState({
        name: drname
    });

    const [query, setQuery] = useState("");
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    const makeQuery = (name) => {
        let query = "";
        if (name !== "") {
            query = `(st==${encodeURIComponent('"READY_TO_CLAIM"')};na=="*${encodeURIComponent(name)}*")`;
        }
        return query;
    }

    const fetchProfileData = (values) => {
        let sq = "";
        if (values !== "") {
            sq = makeQuery(values?.name ? values?.name : "");
        }
        setQuery(sq);

        PractitionerService.getAllProfiles(sq, 0)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setContents(data?.contents);
                    setTotalPages(data?.totalPages);
                    setTotalItems(data?.totalItems);
                    setCurrentPage(data?.currentPage)
                    setHasMore((data?.currentPage + 1) < data?.totalPages);
                    setIsLoading(false);
                } else {
                    console.log(FAILED_TO_FETCH_DATA)
                    setIsLoading(false);
                }
            })
    }

    const fetchMoreProfileData = () => {
        PractitionerService.getAllProfiles(query, currentPage + 1)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setContents((contents) => [...contents, ...data?.contents]);
                    setTotalPages(data?.totalPages);
                    setTotalItems(data?.totalItems);
                    setCurrentPage(data?.currentPage)
                    setLoadingMore(false);
                } else {
                    setLoadingMore(true);
                    console.log(FAILED_TO_FETCH_DATA)
                }
            })
    }

    useEffect(() => {
        fetchProfileData(initialValues);
    }, [])

    const loadMore = () => {
        setLoadingMore(true);
        fetchMoreProfileData();
    };

    const handleRegisterBack = () => {
        history.push('/register');
    };

    return (
        <>
            <div className="nex-profile-cont-wrap">
                <Card className="profile-search-results">
                    <CardBody className="px-0">
                        <Row>
                            <Col lg={12} className="column-left">
                                <div className="search-results">
                                    <div className="nex-form-center-slot no-center">
                                        <div className="nex-list-filter-info nex-space-md">
                                            <div className="nex-col-left">
                                                <p className="nex-text-lg text-right"> <span onClick={handleRegisterBack} className="text-gray nex-link cursor-pointer"><img src={arrowback} width={16} alt="" className="icon" /> Back</span></p>
                                            </div>
                                            <div className="nex-col-right">
                                                <p className="nex-text-lg text-right ">Don’t see your profile? <Link to={'/new/register'} className="nex-link-dark">Click here</Link></p>
                                            </div>                                            
                                        </div>                                        
                                        <div className="nex-list-filter-info nex-list-filter-info-v2" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">
                                            <div className="nex-col-left">
                                                {/*<div><img src={arrow_left} width="21" className="cursor-pointer pb-2" onClick={handleRegisterBack} /></div>*/}
                                                <p className="nex-text-lg">Searched result with <b>{drname}</b></p>
                                                {/*contents && contents.length > 0 ? ( <p className="nex-text-lg text-black mt-2 nex-mob-hide">Please Select your profile and continue</p>):<br />*/}
                                            </div>
                                            <div className="nex-col-right d-none d-lg-block">
                                                {totalItems > 0 &&
                                                    <p className="text-right">{`${totalItems} ${totalItems === 1 ? ' profile' : 'profiles'} found`}</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isLoading ?
                                    <div className="text-center">
                                        <CommonLoader />
                                    </div>
                                    :
                                    <>
                                        {contents && contents.length > 0 ? (
                                            <>
                                                {/* {renderProfiles()} */}
                                                {contents.map((node, index) => (
                                                    <ProfileCardItem {...node} key={`profile_${index}`} viewMode={true} />
                                                ))}
                                                {hasMore &&
                                                    < div className="text-center mt-2">
                                                        {loadingMore ?
                                                            <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                                                            :
                                                            <LoadMoreButton loadMore={loadMore} />
                                                        }
                                                    </div>
                                                }
                                            </>
                                        ) : (
                                            <div className="nexogic-nodata-dotted">
                                                <div className="no-suggestion-text text-center">
                                                    <img src={profile_icon} className="icon" />
                                                    {"No Profile Found!"}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                }
                            </Col>
                        </Row>
                        {/* </div> */}
                    </CardBody>
                </Card>
            </div >
        </>
    );
}

export default PractitionerProfiles;
