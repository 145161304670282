import { withAlert } from "react-alert";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import SmartCardQR from "./components/SmartCardQR";
import html2canvas from "html2canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import "./index.css"

const SmartCard = (props) => {
    const { isOpen, toggle, practitioner } = props;

    const downloadSMartCard = () => {
        const smartcardId = document.getElementById('smartcard-content');
        html2canvas(smartcardId).then((canvas) => {
            const image = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = image;
            link.download = `${practitioner.profileId}-smartcard.png`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(err => {
            console.error('Error downloading SMartCard', err);
        });
    };

    return (
        <>
            <Button
                className="nex-btn-primary"
                id="nex-sc-view-btn"
                onClick={toggle}
            >
                View Smart Card
            </Button>
            <Modal
                id="smartcard-modal"
                isOpen={isOpen}
                toggle={toggle}
                contentClassName=""
                backdrop={true}
            >
                <ModalHeader toggle={toggle}>Smart Card</ModalHeader>
                <ModalBody>
                <div id="smartcard-content">
                    <SmartCardQR profile={practitioner} />
                    </div>
                    <Button className="btn nexogic-download-btn download-ext nexogic_primary_button" onClick={downloadSMartCard}><FontAwesomeIcon title="Download SmartCard" icon={faDownload} /></Button>
                </ModalBody>
            </Modal>
        </>
    );
};

export default withAlert()(SmartCard);
