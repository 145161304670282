import { useState } from "react";
import { withAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, FormGroup, Input, Label, ListGroup, ListGroupItem, Row } from "reactstrap";
import { authSetUser } from "redux-helpers/ActionCreator";
import { PractitionerService } from "utils/PractitionerService";

const ContactVisibility = (props) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();
  const [conVisibility, setConVisibility] = useState(
    currentUser.everyoneOrConnection
  );

  const handleChange = (Visibility) => {
    setConVisibility(Visibility);
    try {
      let { guid } = currentUser;
      const payload = { everyoneOrConnection: Visibility };
      PractitionerService.updatePractitioner({ guid, ...payload }).then(
        (response) => {
          const { data } = response;
          if (data.status === 200) {
            const updatedCurrentUser = {
              ...currentUser,
              everyoneOrConnection: Visibility,
            };
            dispatch(authSetUser(updatedCurrentUser));
            props.alert.success(response.data.message);
          } else {
            let errmsg =
              response.data.message || "Error updating profile visibility";
            alert.error(errmsg);
          }
        }
      );
    } catch (e) {
      let errmsg = e.message || "Error updating profile visibility";
      alert.error(errmsg);
    }
  };

  const handleContactVisibilityStatus = (visibilityEnabled) => {
    try {
      let { guid } = currentUser;
      const payload = {
        everyoneOrConnection: conVisibility,
        showContactDetails: visibilityEnabled,
      };
      PractitionerService.updatePractitioner({ guid, ...payload }).then(
        (response) => {
          const { data } = response;
          if (data.status === 200) {
            const updatedCurrentUser = {
              ...currentUser,
              everyoneOrConnection: conVisibility,
              showContactDetails: visibilityEnabled,
            };
            dispatch(authSetUser(updatedCurrentUser));
            props.alert.success(response.data.message);
          } else {
            let errmsg =
              response.data.message || "Error updating profile visibility";
            alert.error(errmsg);
          }
        }
      );
    } catch (e) {
      let errmsg = e.message || "Error updating profile visibility";
      alert.error(errmsg);
    }
  };

  return (
    <>
      {props.isTitle &&
        <div className="nexogic-page-title">Contact Visibility</div>
      }
      <Row>
        <Col lg={12}>
          <div className="section-content">
            <Row className="align-items-center">
              <Col sm={12}>
                <ListGroup className="nexogic-form-option-lists mt-0">
                  <ListGroupItem className="align-items-center">
                    <h3 className="card-title">Who can see your contact information.</h3>
                    <div className="action nexogic-swaches-btns">
                      <Button
                        className={
                          "calendar-status-btn enable-btn " +
                          (currentUser.showContactDetails ? "active" : "")
                        }
                        onClick={() => handleContactVisibilityStatus(true)}
                      >
                        Enable
                      </Button>
                      <Button
                        className={
                          "calendar-status-btn disable-btn " +
                          (!currentUser.showContactDetails ? "active" : "")
                        }
                        onClick={() => handleContactVisibilityStatus(false)}
                      >
                        Disable
                      </Button>
                    </div>
                  </ListGroupItem>
                  <ListGroupItem>
                    <div>
                    <div>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            checked={conVisibility === "everyone"}
                            type="radio"
                            name="visibility"
                            id="everyone"
                            onChange={() => handleChange("everyone")}
                          />{" "}
                          <span className="visibility-label ml-2">Everyone</span>
                        </Label>
                      </FormGroup>
                    </div>
                    <div>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            checked={conVisibility === "Connection"}
                            name="visibility"
                            id="Connection"
                            onChange={() => handleChange("Connection")}
                          />{" "}
                          <span className="visibility-label ml-2">
                            Only Connections
                          </span>
                        </Label>
                      </FormGroup>
                    </div>
                    </div>
                  </ListGroupItem>
                </ListGroup>

              </Col>

            </Row>

          </div>
        </Col>
      </Row>
    </>
  );
};
ContactVisibility.defaultProps = {
  isTitle: true,
};
export default withAlert()(ContactVisibility);
