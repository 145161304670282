import { Card } from "reactstrap";
import { encrypt } from "utils/EncryptDecrypt";
import { createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor, getGenderDetails } from "utils/Utils";
import { Link, useRouteMatch } from "react-router-dom";
import RefaralComment from "./AddPatient/Components/RefaralComment";
import RefaralDocument from "./AddPatient/Components/RefaralDocument";
import response_icon from 'assets/images/svgs/response-icon.svg'
import { useEffect } from "react";
import { useState } from "react";
import QuickPractitioner from "components/QuickPractitioner/QuickPractitioner";
import graduation_cap from "assets/images/graduation-cap.png";
import suitcase_icon from "assets/images/suitcase.png";
import location_icon from "assets/images/location-1.png";
import QuickInfo from "common/QuickInfo/QuickInfo";
import { ReferralService } from "utils/ReferralService";



// import { useEffect } from "react";
// import edit_blue_icon from "assets/images/edit-blue-icon.png";

const Referral = (props) => {
    const { referral, type } = props;
    const [referral_ismounted, setReferral_ismounted] = useState(false);
    const [referralDetails, setReferralDetails] = useState(false);

    let match = useRouteMatch("/referals/received-referrals");
    const capitalizeFirstLetter = (string) => {
        if (string === null) {
            return null;
        } else if (string === undefined) {
            return "";
        } else {
            const firstLetter = string[0].toUpperCase();
            const restOfString = string.slice(1);
            return firstLetter + restOfString;
        }
    };

    const getReferralById = (id) => {
        ReferralService.getReferralById(id, type)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setReferralDetails(data);
                }
                return null;
            })
    }

    useEffect(() => {
        setReferral_ismounted(true);
        return () => {
            setReferral_ismounted(false);
        }
    }, [referral.id])

    useEffect(() => {
        getReferralById(referral?.id)
    }, [])

    return (
        <Card className="nexogic-card-list-custom nexogic-reffer-card">
            <div className="card-body nexogic-referals-new">
                <div className="row m-3">
                    {/* <div className="col-lg-12 col-status nexogic-status-right"> */}

                    {/* {referral.status.toLowerCase() === 'draft' && (
                      <Link to={`/referrals/update/${referral?.referralCaseNo}`}>
                        <span className="status-pill cursor-pointer">
                                <img src={edit_blue_icon} alt="" width="18" className="mx-2"/>
                            </span>
                      </Link>
                    )} */}


                    {/* {referral.status.toLowerCase() === 'draft' ?
                            <span className="status-pill cursor-pointer">
                                <img src={edit_blue_icon} alt="" width="18" className="mx-2"/>
                            </span>
                            :
                            ''
                        } */}

                    {/* <span className={`status-pill ${referral.status.toLowerCase() === 'closed' ? 'accepted ' : ''}            
                        ${referral.status.toLowerCase() === 'submitted' ? 'accepted ' : ''}`}>
                            {referral?.status
                                .toLowerCase()
                                .split("_")
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(" ")
                            }
                        </span>
                    </div> */}

                    {type === "RECEIVED" &&
                        <div className="col-lg-12 Referring-physician-col">
                            <div className="d-flex justify-content-between">
                                <h3 className="nex-text-xl mb-4"> Referring Physician</h3>
                                <div className="col-status">
                                    <span className={`rounded-pill ${referral.status.toLowerCase() === 'closed' ? 'closed ' : referral.status.toLowerCase() === 'accepted' ? 'accepted ' : referral.status.toLowerCase() === 'in_progress' ? 'inprogress ' : referral.status.toLowerCase() === 'submitted' ? 'submitted ' : referral.status.toLowerCase() === 'draft' ? 'draft ' : ''}`}>
                                        {referral?.status.toLowerCase().split("_").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
                                    </span>
                                </div>
                            </div>
                            <div className="nex-stack">
                                <QuickPractitioner className="lg"
                                    avatar={referral?.referredByPhysician?.avatarId ?
                                        <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredByPhysician?.profileGuid}/${referral?.referredByPhysician?.avatarId}`}
                                            className="nex-avatar lg"
                                            alt="#"
                                        />
                                        :
                                        <>
                                            <img src={createImageFromInitials(50, `${referral?.referredByPhysician?.name.replace("Dr. ", "")}`,
                                                getRandomColor(encrypt(referral?.referredByPhysician?.profileGuid).toString()))}
                                                className="nex-avatar lg" alt="#"
                                            />
                                        </>
                                    }

                                    name={referral?.referredByPhysician?.name}
                                    isTitleLink={true}
                                    titleLink={'/profile/' + referral?.referredByPhysician?.profileId}
                                    speciality={referral?.referredByPhysician?.primarySpeciality || null}
                                >

                                </QuickPractitioner>
                                <div className="info md">
                                    {referral?.referredByPhysician?.qualification &&
                                        <p className="nex-text text-truncate"><span className="icon mr-2"><img src={graduation_cap} alt="" /></span>{referral?.referredByPhysician?.qualification || ''}</p>
                                    }
                                    {!isNaN(parseInt(referral?.referredByPhysician?.practicingSince)) &&
                                        <p className="nex-text text-truncate"><span className="icon mr-2"><img src={suitcase_icon} alt="" /></span>
                                            {parseInt(new Date().getFullYear()) - parseInt(referral?.referredByPhysician?.practicingSince) > 0 ? (
                                                `${parseInt(new Date().getFullYear()) -
                                                parseInt(referral?.referredByPhysician?.practicingSince)
                                                }+ Experience`
                                            ) : '0 Experience'}</p>}
                                </div>
                                <div className="info md">
                                    <p className="nex-text bold text-black text-truncate"><span className="icon mr-2"><img src={location_icon} width={16} alt="" /></span>{getFullAddressWithLocationCityAndState({ location: referral?.referredByPhysician?.location ?? "", city: referral?.referredByPhysician?.city ?? "", state: referral?.referredByPhysician?.state ?? "" })}</p>
                                    <p className="nex-text">Country : {referral?.referredByPhysician?.country}</p>
                                </div>
                            </div>
                            {/* old code */}
                            {/* <div className="head d-none">
                                {
                                    referral?.referredByPhysician?.avatarId ?
                                        <img
                                            src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredByPhysician?.profileGuid}/${referral?.referredByPhysician?.avatarId}`}
                                            className="card-img nex-avatar"
                                            alt="#"
                                        />
                                        :
                                        <>
                                            <img
                                                src={createImageFromInitials(
                                                    50,
                                                    `${referral?.referredByPhysician?.name.replace("Dr. ", "")}`,
                                                    getRandomColor(
                                                        encrypt(
                                                            referral?.referredByPhysician?.profileGuid
                                                        ).toString()
                                                    )
                                                )}
                                                className="card-img nex-avatar"
                                                alt="#"
                                            />
                                        </>
                                }
                                <div className="info">
                                    <p className="card-text nex-ref-physician-name"><b>Name  </b>  <Link to={`/profile/` + referral?.referredByPhysician?.profileId} >{referral?.referredByPhysician?.name}</Link></p>
                                    <p className="card-text"><b>Address  </b>
                                        {getFullAddressWithLocationCityAndState({ location: referral?.referredByPhysician?.location ?? "", city: referral?.referredByPhysician?.city ?? "", state: referral?.referredByPhysician?.state ?? "" })}
                                    </p>
                                </div>
                            </div> */}
                            {/** old code end */}
                        </div>
                    }

                    {type === "SENT" &&
                        <div className="col-lg-12 Referring-physician-col">
                            <div className="d-flex justify-content-between">
                                <h3 className="nex-text-xl mb-4"> {referral?.referredToPhysician !== null ? 'Receiving Physician' : "Receiving Hospital / Clinic"}</h3>
                                <div className="col-status">
                                    <span className={`rounded-pill ${referral.status.toLowerCase() === 'closed' ? 'closed ' : referral.status.toLowerCase() === 'accepted' ? 'accepted ' : referral.status.toLowerCase() === 'in_progress' ? 'inprogress ' : referral.status.toLowerCase() === 'submitted' ? 'submitted ' : referral.status.toLowerCase() === 'draft' ? 'draft ' : ''}`}>
                                        {referral?.status.toLowerCase().split("_").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
                                    </span>
                                </div>
                            </div>
                            <div className="nex-stack">
                                {(referral?.referredToPhysician !== null && referral?.referredToPhysician !== undefined && referral?.referredToPhysician !== "") ?
                                    <>
                                        <QuickPractitioner className="lg"
                                            avatar={referral?.referredToPhysician?.avatarId ?
                                                <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredToPhysician?.profileGuid}/${referral?.referredToPhysician?.avatarId}`}
                                                    className="nex-avatar lg"
                                                    alt="#"
                                                />
                                                :
                                                <>
                                                    <img src={createImageFromInitials(50, `${referral?.referredToPhysician?.name.replace("Dr. ", "")}`,
                                                        getRandomColor(encrypt(referral?.referredToPhysician?.profileGuid).toString()))}
                                                        className="nex-avatar lg" alt="#"
                                                    />
                                                </>
                                            }

                                            name={referral?.referredToPhysician?.name}
                                            isTitleLink={true}
                                            titleLink={'/profile/' + referral?.referredToPhysician?.profileId}
                                            speciality={referral?.referredToPhysician?.primarySpeciality || null}
                                        />
                                        <div className="info md">
                                            {referral?.referredToPhysician?.qualification &&
                                                <p className="nex-text text-truncate"><span className="icon mr-2"><img src={graduation_cap} alt="" /></span>{referral?.referredToPhysician?.qualification || ''}</p>
                                            }
                                            {!isNaN(parseInt(referral?.referredToPhysician?.practicingSince)) &&
                                                <p className="nex-text text-truncate"><span className="icon mr-2"><img src={suitcase_icon} alt="" /></span>
                                                    {parseInt(new Date().getFullYear()) - parseInt(referral?.referredToPhysician?.practicingSince) > 0 ? (
                                                        `${parseInt(new Date().getFullYear()) -
                                                        parseInt(referral?.referredToPhysician?.practicingSince)
                                                        }+ Experience`
                                                    ) : '0 Experience'}</p>}
                                        </div>
                                        <div className="info md">
                                            <p className="nex-text bold text-black text-truncate"><span className="icon mr-2"><img src={location_icon} width={16} alt="" /></span>{getFullAddressWithLocationCityAndState({ location: referral?.referredToPhysician?.location ?? "", city: referral?.referredToPhysician?.city ?? "", state: referral?.referredToPhysician?.state ?? "" })}, {referral?.referredToPhysician?.country}</p>
                                            {/* <p className="nex-text">Country : {referral?.referredToPhysician?.country}</p> */}
                                        </div>
                                    </>
                                    :
                                    <>
                                        <QuickPractitioner className="lg"
                                            avatar={referralDetails?.referredToInstitution?.avatarId ?
                                                <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referralDetails?.referredToInstitution?.guid}/${referralDetails?.referredToInstitution?.avatarId}`}
                                                    className="nex-avatar lg"
                                                    alt="#"
                                                />
                                                :
                                                <>
                                                    <img src={createImageFromInitials(50, `${referralDetails?.referredToInstitution?.name}`,
                                                        getRandomColor(encrypt(referralDetails?.referredToInstitution?.guid).toString()))}
                                                        className="nex-avatar lg" alt="#"
                                                    />
                                                </>
                                            }

                                            name={referralDetails?.referredToInstitution?.name}
                                            isTitleLink={true}
                                            titleLink={'/institution/' + referralDetails?.referredToInstitution?.profileId}
                                        // speciality={referralDetails?.referredToInstitution?.primarySpeciality || null}
                                        // address={`${getFullAddressWithLocationCityAndState({ location: referralDetails?.referredToInstitution?.address?.location ?? "", city: referralDetails?.referredToInstitution?.address?.city ?? "", state: referralDetails?.referredToInstitution?.address?.state ?? "" })}, ${referralDetails?.referredToInstitution?.address?.country}`}
                                        />
                                        <div className="info md">
                                            <p className="nex-text text-truncate">Speciality Requested :</p>
                                            <p className="nex-text text-truncate text-black bold">{referralDetails?.specialityRequested || null}</p>
                                        </div>
                                        <div className="info md">
                                            <p className="nex-text bold text-black text-truncate"><span className="icon mr-2"><img src={location_icon} width={16} alt="" /></span>{`${getFullAddressWithLocationCityAndState({ location: referralDetails?.referredToInstitution?.address?.location ?? "", city: referralDetails?.referredToInstitution?.address?.city ?? "", state: referralDetails?.referredToInstitution?.address?.state ?? "" })}, ${referralDetails?.referredToInstitution?.address?.country}`}</p>
                                            {/* <p className="nex-text">Country : {referral?.referredToPhysician?.country}</p> */}
                                        </div>
                                    </>

                                }

                                {/* <div className="info md">
                                    {referral?.referredToPhysician?.qualification &&
                                        <p className="nex-text text-truncate"><span className="icon mr-2"><img src={graduation_cap} alt="" /></span>{referral?.referredToPhysician?.qualification || ''}</p>
                                    }
                                    {!isNaN(parseInt(referral?.referredToPhysician?.practicingSince)) &&
                                        <p className="nex-text text-truncate"><span className="icon mr-2"><img src={suitcase_icon} alt="" /></span>
                                            {parseInt(new Date().getFullYear()) - parseInt(referral?.referredToPhysician?.practicingSince) > 0 ? (
                                                `${parseInt(new Date().getFullYear()) -
                                                parseInt(referral?.referredToPhysician?.practicingSince)
                                                }+ Experience`
                                            ) : '0 Experience'}</p>}
                                </div>
                                <div className="info md">
                                    <p className="nex-text bold text-black text-truncate"><span className="icon mr-2"><img src={location_icon} width={16} alt="" /></span>{getFullAddressWithLocationCityAndState({ location: referral?.referredToPhysician?.location ?? "", city: referral?.referredToPhysician?.city ?? "", state: referral?.referredToPhysician?.state ?? "" })}, {referral?.referredToPhysician?.country}</p>
                                </div> */}
                            </div>
                        </div>
                    }

                    {/* {type === "SENT" &&
                        <div className="col Referring-to-col">
                            <h3 className=" card-title big"> Receiving PhysicianSS </h3>
                            <div className="head">
                                <div className="card-graphic">
                                    {
                                        referral?.referredToPhysician?.avatarId ?
                                            <img
                                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredToPhysician?.profileGuid}/${referral?.referredToPhysician?.avatarId}`}
                                                className="card-img"
                                                alt="#"
                                            />
                                            :
                                            <>
                                                <img src={createImageFromInitials(50, `${referral?.referredToPhysician?.name.replace("Dr. ", "")}`, getRandomColor(encrypt(referral?.referredToPhysician?.profileGuid).toString()))}
                                                    className="card-img"
                                                    alt="#"
                                                />
                                            </>
                                    }

                                </div>
                                <div className="info">
                                    <p className="card-text"><b>Name  </b>
                                        {(referral?.referredToPhysician && referral?.referredToPhysician?.name) ?
                                            <Link to={`/profile/` + referral?.referredToPhysician?.profileId} >{referral?.referredToPhysician?.name}</Link>
                                            :
                                            <span>N/A</span>
                                        }
                                    </p>
                                    <p className="card-text"><b>Address  </b>
                                        {referral?.referredToPhysician ?
                                            <>
                                                {getFullAddressWithLocationCityAndState({ location: referral?.referredToPhysician?.location ?? "", city: referral?.referredToPhysician?.city ?? "", state: referral?.referredToPhysician?.state ?? "" })}
                                            </>
                                            :
                                            <span>N/A</span>
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    } */}

                    <div className="col-12 patient-col-info nexogic-referals-new">
                        <h3 className="nex-text-xl line mb-4 mt-4"> Patient</h3>
                        {/* <div className="line"></div> */}
                        <div className="nex-stack">
                            <QuickInfo className="lg"
                                avatar={referral?.patient?.avatarId ?
                                    <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.patient?.id}/${referral?.patient?.avatarId}`} className="nex-avatar lg" alt="#" />
                                    :
                                    <>
                                        <img src={createImageFromInitials(50, `${(referral?.patient?.firstName + " " + referral?.patient?.lastName)}`, getRandomColor(encrypt(referral?.patient?.id).toString()))} className="nex-avatar lg" alt="#" />
                                    </>
                                }
                                title={`${referral?.patient?.firstName} ${referral?.patient?.lastName}`}>
                                <p className="nex-text bold nex-text-blue text-truncate">Type : {capitalizeFirstLetter(referral?.patientType.toLowerCase())}</p>
                            </QuickInfo>
                            <div className="info md my-auto">
                                <p className="nex-text">DOB : {referral?.patient?.dateOfBirth ? referral?.patient?.dateOfBirth : 'NA'}</p>
                                <p className="nex-text">Gender : {getGenderDetails(referral?.patient?.gender)}</p>
                            </div>
                            <div className="action ml-auto my-auto">
                                <Link to={{
                                    pathname: `${type === "SENT" ? '/referrals/sent-referrals' : '/referrals/received-referrals'}/` + referral?.id,
                                    state: { type },
                                }} className="btn nex-btn-primary">Show Details</Link>
                            </div>
                        </div>
                        {/* <div className="head d-none">
                            {
                                referral?.patient?.avatarId ?
                                    <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.patient?.id}/${referral?.patient?.avatarId}`} className="card-img nex-avatar" alt="#"/>
                                    :
                                    <>
                                        <img src={createImageFromInitials(50,`${(referral?.patient?.firstName + " " + referral?.patient?.lastName)}`,getRandomColor(encrypt(referral?.patient?.id).toString()))} className="card-img nex-avatar"alt="#"/>
                                    </>
                            }
                            <div className="info">
                                <p className="card-text nex-patient-name"><b>NameSS  </b>  <span><Link to={`/patient-profile/${referral?.patient?.id}`}>{referral?.patient?.firstName}{" "}{referral?.patient?.lastName}</Link></span></p>
                                <p className="card-text"><b>DOB  </b>{referral?.patient?.dateOfBirth ? referral?.patient?.dateOfBirth : 'NA'}</p>
                                <p className="card-text"><b>Gender  </b> {getGenderDetails(referral?.patient?.gender)}</p>
                                <p className="card-text"><b>Type  </b> {capitalizeFirstLetter(referral?.patientType.toLowerCase())}</p>
                            </div>
                        </div> */}
                    </div>
                    {/* {type === "SENT" &&
                        <div className="col Referring-to-col">
                            <h3 className=" card-title big"> Receiving PhysicianSS </h3>
                            <div className="head">
                                <div className="card-graphic">
                                    {
                                        referral?.referredToPhysician?.avatarId ?
                                            <img
                                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredToPhysician?.profileGuid}/${referral?.referredToPhysician?.avatarId}`}
                                                className="card-img"
                                                alt="#"
                                            />
                                            :
                                            <>
                                                <img src={createImageFromInitials(50, `${referral?.referredToPhysician?.name.replace("Dr. ", "")}`, getRandomColor(encrypt(referral?.referredToPhysician?.profileGuid).toString()))}
                                                    className="card-img"
                                                    alt="#"
                                                />
                                            </>
                                    }

                                </div>
                                <div className="info">
                                    <p className="card-text"><b>Name  </b>
                                        {(referral?.referredToPhysician && referral?.referredToPhysician?.name) ?
                                            <Link to={`/profile/` + referral?.referredToPhysician?.profileId} >{referral?.referredToPhysician?.name}</Link>
                                            :
                                            <span>N/A</span>
                                        }
                                    </p>
                                    <p className="card-text"><b>Address  </b>
                                        {referral?.referredToPhysician ?
                                            <>
                                                {getFullAddressWithLocationCityAndState({ location: referral?.referredToPhysician?.location ?? "", city: referral?.referredToPhysician?.city ?? "", state: referral?.referredToPhysician?.state ?? "" })}
                                            </>
                                            :
                                            <span>N/A</span>
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    } */}
                    {/* <div className="col-auto col-status">
                        <span className={`status-pill 
                        ${referral.status.toLowerCase() === 'closed' ? 'accepted ' : ''} 
                        ${referral.status.toLowerCase() === 'submitted' ? 'accepted ' : ''} 
                        `}>
                            {referral?.status
                                .toLowerCase()
                                .split("_")
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(" ")
                            }
                        </span>
                    </div> */}
                </div>
                {/* <div className="row need-margin reason-sec d-none">
                    <div className="col-lg-12">
                        {referral?.referralReason &&
                            <>
                                <hr />
                                <h3 className="card-title"> Reason:</h3>
                                <div className="card-text">
                                    <div dangerouslySetInnerHTML={{ __html: referral?.referralReason }}></div>
                                </div>
                            </>
                        }
                        {type === "RECEIVED" ? (
                            <div className="card-text d-flex actions">
                                <div className="ml-auto mt-2">
                                    <Link
                                        to={{
                                            pathname: `${match?.url !== undefined ? match?.url : '/referrals/received-referrals'}/` + referral?.id,
                                            state: { type }, // Pass the 'type' as state
                                        }}
                                        className="nexogic_primary_button_outline "
                                    >
                                        Show Details
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div className="card-text d-flex actions">
                                <div className="ml-auto mt-2">
                                    <Link
                                        to={{
                                            pathname: `${match?.url !== undefined ? match?.url : '/referrals/sent-referrals'}/` + referral?.id,
                                            state: { type }, // Pass the 'type' as state
                                        }}
                                        className="nexogic_primary_button_outline "
                                    >
                                        Show Details
                                    </Link>
                                </div>
                            </div>
                        )}

                    </div>
                </div> */}
                {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                    <div className="nexogic-meta-description">
                        <div className="row need-margin">
                            <div className={`col ${referral?.dateOfReferal ? '' : 'order-3 d-none'}`}><p className={`card-text ${referral?.dateOfReferal ? '' : 'd-none'}`}><span className="icon"><svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 8V17C1 19.2091 2.79086 21 5 21H15C17.2091 21 19 19.2091 19 17V8M1 8V6.5C1 4.29086 2.79086 2.5 5 2.5H15C17.2091 2.5 19 4.29086 19 6.5V8M1 8H19M14 1V4M6 1V4" stroke="#5EA9C6" strokeWidth="1.5" strokeLinecap="round"></path></svg></span>  <b>Date of refferal  </b>  {referral?.dateOfReferal}</p></div>
                            {type === "RECEIVED" &&
                                <div className="col">
                                    {referral && referral?.responseId ?
                                        <Link to={`/referrals/sent-response/${referral?.id}`} className="nex-link no-underline">
                                            <span className="icon"><img width={28} alt="#" src={response_icon} /></span>
                                            View Response
                                        </Link>
                                        // <a href={`/referrals/sent-response/${referral?.id}`} className="nex-link no-underline"> 
                                        // <span className="icon"><img width={28} alt="#" src={response_icon} /></span>View Response
                                        // </a>
                                        :
                                        <span className="nex-link no-underline">
                                            <span className="icon" ><img width={28} alt="#" src={response_icon} /></span>
                                            No Response
                                        </span>
                                    }
                                </div>
                            }

                            <div className="col"><RefaralComment id={'ref_connemt_' + referral.id} /></div>
                            <div className="col-auto ml-auto"><RefaralDocument id={'ref_document_' + referral.id} referral={referral} /></div>
                        </div>
                    </div>
                }
            </div>
        </Card>
    )
}

export default Referral;