import React from "react";
import moment from "moment";

import { AppointmentType } from "utils/Constants";
import ApptBlock from "./ApptBlock";

const WeekSchedule = (props) => {
  const { timeInterval } = props

  let timedData = [];
  let tempObj = { ...timeInterval, 'consultations': [], [AppointmentType.LAB]: [] };
    
    props.selectedDay.consultations.map((item) => {
      const appointmentTime = moment.utc(item.appointmentTime, "HH:mm:ss").local()
      if (appointmentTime.hours() === timeInterval.hours) {
        tempObj.consultations.push(item);
      }
    });

    props.selectedDay[AppointmentType.LAB].map((item) => {
      const appointmentTime = moment.utc(item.appointmentTime, "HH:mm:ss").local()
      if (appointmentTime.hours() === timeInterval.hours) {
        tempObj[AppointmentType.LAB].push(item);
      }
    });

    timedData.push(tempObj);
   
  return (
    <>
      {timedData.map((data, index) => ( 
        <div key={index}>
          <div style={{padding:"8px"}}>
            {data.consultations.map((apptData, index) => 
              <ApptBlock key={apptData.appointmentReference} index={index} apptData={apptData} apptType="type-appt" label={'Consultations'} getAppts={props.getAppts} />
            )}

            {data[AppointmentType.LAB].map((apptData, index) => 
              <ApptBlock key={apptData.appointmentReference} index={index} apptData={apptData} apptType="type-proc" label="Lab" getAppts={props.getAppts} />
            )}
          </div>
        </div>
      ))}
    </>
  )
  
};

export default WeekSchedule;
