import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Field, useFormikContext } from 'formik';
import PrimarySpecialityTypeaheadField from "components/forms/formikFields/PrimarySpecialityTypeaheadField";
import { MetadataService } from 'utils/MetadataService';

const JobPrimarySpecialityEditModal = (props) => {
    const { modal, setModal } = props;
    const { values, setFieldValue } = useFormikContext();
    const [primarySpecialityList, setPrimarySpecialityList] = useState([]);
    const [selectedSpeciality, setSelectedSpeciality] = useState(values?.primarySpeciality)

    const handlePrimarySpeciality = (event) => {
        setSelectedSpeciality(event);
        let selectedValue = event;
        if (typeof event === 'object' && event !== null && event.hasOwnProperty('type')) {
            selectedValue = selectedValue.target.value;
        }
    };

    const getprimarySpeciality = () => {
        MetadataService.getPrimarySpecialities()
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setPrimarySpecialityList(response.data);
                }
            })
            .catch((error) => {
                console.log("MetaDataService.getprimarySpeciality error", error);
            });
    };

    const handleSave = () => {
        setFieldValue('primarySpeciality', selectedSpeciality);
        setModal(false);
    }

    useEffect(() => {
        getprimarySpeciality();
    }, []);

    return (
        <Modal isOpen={modal} toggle={() => setModal(!modal)} className="nex-model-v2 modal-dialog-scrollable modal-dialog-centered">
            <ModalHeader toggle={() => setModal(!modal)}>Edit Primary Speciality</ModalHeader>
            <ModalBody>
                <div className="form-group gap-md">
                    <h2 className='nex-text-lg text-black'>Select the Primary Speciality</h2>
                    <Field
                        id="primarySpeciality"
                        name="primarySpeciality"
                        autoComplete="primarySpeciality"
                        className="primary-specialities"
                        type="text"
                        component={PrimarySpecialityTypeaheadField}
                        placeholder="Select Primary Speciality"
                        inputprops={{
                            autoComplete: "off",
                        }}
                        spellCheck={false}
                        specialit={primarySpecialityList}
                        onChange={handlePrimarySpeciality}

                    />
                </div>
                <div className="form-group nex-action-flex">
                    <button className="btn nex-btn-primary ml-auto" onClick={handleSave}>Save</button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default JobPrimarySpecialityEditModal;
