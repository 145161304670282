import AlertDialog from "common/AlertDialog";
import moment from "moment";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import {
  profileAddEvent,
  updateProfileCompletness,
} from "redux-helpers/ActionCreator";
import { UserStatusConstants } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import {
  convertToMMMYYYYFormat,
  dateDifferenceInYearsAndMonths,
  getEmptySectionShowHide,
  getFullAddress,
  makeFullNameWithAddressWhenAddressIsNested,
  renderDateRange,
} from "utils/Utils";
import { RenderCertificationModal } from "./components/RenderCertificationModal";
import certifications_icon from "assets/images/license-certificate.png";
import NoData from "../NoData";

const initialValue = {
  id: "",
  name: "",
  institutionDetail: {
    institutionName: "",
    guid: "",
    onSelectNewinstitution: 0,
  },
  issDate: "",
  expDate: "",
  expires: false,
  mainItemName: "",
  city: "",
  state: "",
  country: "",
  location: "",
  phone: "",
  postalCode: "",
  addressLine1: "",
  websiteAddress: "",
  category: "",
};

const conversionMonthYearToDate = (parameterDate) => {
  if (parameterDate) {
    const changeToString = parameterDate.toString();
    if (changeToString?.includes("-")) {
      const splitedMonthYear = parameterDate?.split("-");
      if (splitedMonthYear.length === 2) {
        return new Date(splitedMonthYear[1], splitedMonthYear[0], -1);
      }
    }
  }
};

const RenderCertification = (props) => {
  const {
    certifications,
    handleEditItem,
    handleRemoveItem,
    isLoggedinUser,
    isAdmin,
  } = props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  if (certifications.length > 0) {
    certifications.map((certification) => {
      certification.issuedDateYear = !certification?.issuedOn
        ? moment().format("YYYY")
        : moment(conversionMonthYearToDate(certification.issuedOn)).format(
          "YYYY"
        );
      certification.issuedDateMonth = !certification?.issuedOn
        ? moment().format("MMM")
        : moment(conversionMonthYearToDate(certification.issuedOn)).format(
          "MMM"
        );
      certification.issuedDateMonthYearText =
        certification.issuedDateMonth + "-" + certification.issuedDateYear;
      certification.expiryDateYear = !certification?.expiredOn
        ? moment().format("YYYY")
        : moment(conversionMonthYearToDate(certification.expiredOn)).format(
          "YYYY"
        );
      certification.expiryDateMonth = !certification?.expiredOn
        ? moment().format("MMM")
        : moment(conversionMonthYearToDate(certification.expiredOn)).format(
          "MMM"
        );
      certification.expiryDateMonthYearText =
        certification.expiryDateMonth + "-" + certification.expiryDateYear;
      return certification;
    });
    // let sortCertifications = certifications
    //   .sort(function (a, b) {
    //     a = a.expiryDateMonth.split("-");
    //     b = b.expiryDateMonth.split("-");
    //     return new Date(a[1], a[0], 1) - new Date(b[1], b[0], 1);
    //   })
    //   .reverse();
    return certifications.map((item, index) => {
      const { institutionDetail } = item;
      const profileIdLink = item?.institutionDetail?.profileId
        ? item?.institutionDetail?.profileId
        : item?.institutionDetail?.guid;
      const institutionName = getFullAddress({
        name: institutionDetail?.name ?? "",
        abbreviation: institutionDetail?.abbreviation ?? "",
        location: institutionDetail?.address?.location ?? "",
        city: institutionDetail?.address?.city ?? "",
        state: institutionDetail?.address?.state ?? "",
      });
      return (
        <li
          className="nex-icon-list-item"
          key={`certi_${item.guid}`}
        >
          <div className="card-graphic">
            <img src={certifications_icon} alt="" className="contain-bg no-border-radius" />
          </div>
          <div className="nex-info">
            <h3 className="card-title">{item.name}</h3>
            <p className="card-text"><Link
                to={`/institution/${profileIdLink}`}
                className="mb-2 color-accent"
              >{institutionName}</Link></p>
            {item?.issuedOn &&
                <p className="card-text">{convertToMMMYYYYFormat(item?.issuedOn)}
                  {" - "}
                  {item?.doesNotExpire ? <span><span>{"No Expiry | "}</span><span>{dateDifferenceInYearsAndMonths(item?.issuedOn)}</span></span> : convertToMMMYYYYFormat(item?.expiredOn)}
                </p>
              }
              <div className="nex-action">
              {(isAdmin ||
                (isLoggedinUser &&
                  currentUser.status === UserStatusConstants.APPROVED)) && (
                  <div className="delete-action">
                    <Button
                      
                      color="link"
                      className="visible-hover py-0 nex-cs-certification-loop-edit-btn"
                      onClick={() => handleEditItem(item)}
                    >
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="15.9062" cy="16" r="15.375" fill="white" stroke="#DBF4FB" stroke-width="0.75"/>
<path d="M19.3612 9.53125L17.8557 11.0368L20.8667 14.0478L22.3722 12.5423L19.3612 9.53125ZM16.3502 12.5423L10.3281 18.5643V21.5754H13.3392L19.3612 15.5533L16.3502 12.5423Z" fill="#46ABC9"/>
</svg>
                    </Button>
                    <Button
                      color="link"
                      className="visible-hover delete py-0 nex-cs-certification-loop-del-btn"
                      onClick={() => handleRemoveItem(item)}
                    >
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="16.2266" cy="16" r="15.75" fill="#FFEAEF"/>
<path d="M11.3984 13.7734L12.2586 20.1323C12.426 21.3702 12.5097 21.9892 12.9337 22.3597C13.3577 22.7302 13.9823 22.7302 15.2315 22.7302H18.4692C19.7184 22.7302 20.343 22.7302 20.767 22.3597C21.191 21.9892 21.2747 21.3702 21.4421 20.1323L22.3023 13.7734" stroke="#FF9191" stroke-width="0.75" strokeLinecap="round" stroke-linejoin="round"/>
<path d="M14.5156 11.0475V11.0475C14.5156 10.2672 14.5156 9.87708 14.6572 9.57473C14.8061 9.25693 15.0616 9.00138 15.3794 8.85254C15.6818 8.71094 16.0719 8.71094 16.8522 8.71094V8.71094C17.6324 8.71094 18.0226 8.71094 18.3249 8.85254C18.6427 9.00138 18.8983 9.25693 19.0471 9.57473C19.1887 9.87708 19.1887 10.2672 19.1887 11.0475V11.0475" stroke="#FF9191" stroke-width="0.75" strokeLinecap="round" stroke-linejoin="round"/>
<path d="M22.3023 11.0547L11.3984 11.0547" stroke="#FF9191" stroke-width="0.75" strokeLinecap="round" stroke-linejoin="round"/>
<path d="M14.8906 14.1719L15.28 20.0132" stroke="#FF9191" stroke-width="0.75" strokeLinecap="round" stroke-linejoin="round"/>
<path d="M18.7957 14.1719L18.4062 20.0132" stroke="#FF9191" stroke-width="0.75" strokeLinecap="round" stroke-linejoin="round"/>
</svg>
                    </Button>
                  </div>
                )}
              </div>
          </div>


                {/*
          <div className="row g-0">
            <div className="col-auto">
              <img src={certifications_icon} alt="" />
            </div>
            <div className="col">
              <h3 className="card-title">{item.name}</h3>
              <p className="card-title mb-0"><small className="text-muted fw-semibold"><Link
                to={`/institution/${profileIdLink}`}
                className="mb-2 color-accent"
              >{institutionName}</Link></small></p>

              {item?.issuedOn &&
                <p className="card-text"> <small className="text-muted">{convertToMMMYYYYFormat(item?.issuedOn)}
                  {" - "}
                  {item?.doesNotExpire ? <span><span>{"No Expiry | "}</span><span>{dateDifferenceInYearsAndMonths(item?.issuedOn)}</span></span> : convertToMMMYYYYFormat(item?.expiredOn)}
                </small></p>
              }
            </div>


            <div className="col-auto d-flex align-items-start">
              {(isAdmin ||
                (isLoggedinUser &&
                  currentUser.status === UserStatusConstants.APPROVED)) && (
                  <div className="delete-action">
                    <Button
                      
                      color="link"
                      className="visible-hover py-0 nex-cs-certification-loop-edit-btn"
                      onClick={() => handleEditItem(item)}
                    >
                      <img src={edit_blue_icon} alt="" />
                    </Button>
                    <Button
                      color="link"
                      className="visible-hover delete py-0 nex-cs-certification-loop-del-btn"
                      onClick={() => handleRemoveItem(item)}
                    >
                      <img src={del_red_icon} alt="" />
                    </Button>
                  </div>
                )}
            </div>
          </div>
          */}
        </li>
      );
    });
  }
  return null;
};

const Certifications = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const eventName = useSelector((state) => state.profileComplete.eventName);
  const dispatch = useDispatch();
  const { guid } = props.practitioner;
  const { alert, isLoggedinUser, isAdmin, setShowCertificationsOnTabScroll } = props;
  const [certifications, setCertification] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);
  const [isOpenAlert, setAlertModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  useEffect(() => {
    dispatch(updateProfileCompletness(6, !!certifications.length));
    if (certifications.length > 0 || isLoggedinUser || isAdmin) {
      setShowCertificationsOnTabScroll(true);
    }else{
      setShowCertificationsOnTabScroll(false);
    }

  }, [certifications]);

  useEffect(() => {
    if (eventName === "ADD_CERTIFICATIONS") {
      handleEditItem(initialValue);
      dispatch(profileAddEvent(null));
    }
  }, [eventName]);

  const toggleAlert = () => {
    setLoading(false);
    setAlertModal(!isOpenAlert);
  };

  const handleEditItem = (selectedItem) => {
    const formData = {
      institutionDetail: {
        institutionName: selectedItem?.institutionDetail?.name ? makeFullNameWithAddressWhenAddressIsNested(selectedItem?.institutionDetail) : "",
        guid: selectedItem?.institutionDetail?.guid ?? "",
        onSelectNewinstitution: selectedItem?.institutionDetail?.guid ? 1 : 0,
      },
      issDate: selectedItem?.issuedOn ?? "",
      expDate: selectedItem?.expiredOn ?? "",
      expires: selectedItem?.doesNotExpire ?? false,
      mainItemName: selectedItem?.institutionDetail?.name ?? "",
      id: selectedItem?.guid ?? "",
      name: selectedItem?.name ?? "",
      city: "",
      state: "",
      country: "",
      location: "",
      phone: "",
      postalCode: "",
      addressLine1: "",
      websiteAddress: "",
      category: "",
      certificationNumber: selectedItem?.certificationNumber ?? "",
    };
    setSelectedItem(formData);
    toggle();
  };

  const handleRemoveItem = (selectedItem) => {
    const formData = {
      institutionDetail: {
        institutionName: selectedItem?.institutionDetail?.name ?? "",
        guid: selectedItem?.institutionDetail?.guid ?? "",
        onSelectNewinstitution: selectedItem?.institutionDetail?.guid ? 1 : 0,
      },
      issDate: selectedItem?.issuedOn ?? "",
      expDate: selectedItem?.expiredOn ?? "",
      expires: selectedItem?.expires ?? false,
      mainItemName: selectedItem?.institutionDetail?.name ?? "",
      id: selectedItem?.guid ?? "",
      name: selectedItem?.name ?? "",
      city: "",
      state: "",
      country: "",
      location: "",
      phone: "",
      postalCode: "",
      addressLine1: "",
      websiteAddress: "",
      category: "",
      certificationNumber: selectedItem?.certificationNumber ?? "",
    };
    setSelectedItem(formData);
    toggleAlert();
  };

  const handleClickOnYes = async () => {
    const userGUID = globalProfileInfo.guid;
    setLoading(true);
    try {
      const request = PractitionerService.deleteCertification(
        userGUID,
        selectedItem?.id
      );
      request
        .then(async (response) => {
          const { status } = response;
          if (status === 204) {
            await getData();
          } else {
            setLoading(false);
            let errmsg =
              response.data && !!response.data.message
                ? response.data.message
                : "Failed to save. Please try again";
            showErrorMessage(errmsg);
          }
        })
        .then(() => {
          setLoading(false);
          toggleAlert();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getData = async () => {
    const request = PractitionerService.getCertifications(guid);
    request.then((response) => {
      let { data } = response;
      if (data && data?.length > 0) {
        setCertification(data);
      } else {
        setCertification([]);
      }
    });
  };

  useEffect(() => {
    if (guid) {
      getData();
    }
  }, []);

  if (
    getEmptySectionShowHide(
      certifications ?? [],
      isAdmin,
      currentUser,
      globalProfileInfo,
      guid
    )
  )
    return null;

  return (
    <Card className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="certificationsSection">
      <div className="card-header"><h2>Licenses & certifications</h2>
        {(isAdmin ||
          (isLoggedinUser &&
            currentUser.status === UserStatusConstants.APPROVED)) && (
            <Button
              id="nex-cs-certification-add-btn"
              color="link"
              className="add-button ml-auto"
              onClick={() => handleEditItem(initialValue)}
            >
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="9.75" cy="9.75" r="8.4375" fill="white" stroke="#46ABC9" strokeWidth="1.125"/>
<path d="M9.625 6L9.625 13.2539" stroke="#46ABC9" strokeWidth="1.125" strokeLinecap="round"/>
<path d="M13.2656 9.63281L6.01172 9.63281" stroke="#46ABC9" strokeWidth="1.125" strokeLinecap="round"/>
</svg>
 Add More Licenses & certifications 
            </Button>
          )}
        <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
        <svg width={17}  height={10} viewBox="0 0 17 10" fill="none"  xmlns="http://www.w3.org/2000/svg" >
                        <path
                          d="M2.25 1.625L8.625 8L15 1.625"
                          stroke="black"
                          strokeWidth={3}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
            </svg>
        </button>
      </div>
      <div className={`collapse-div`}>
        <CardBody className={`${certifications && certifications.length > 0
          ? ""
          : "align-items-center"
          }`}>

          {certifications && certifications.length
            ? ""
            : (isLoggedinUser || isAdmin) &&
            currentUser.status === UserStatusConstants.APPROVED && (
              <Button
                id="nex-cs-certification-add-btn2"
                className="readmore"
                color="link"
                onClick={() => handleEditItem(initialValue)}
              >
                {"Add your certificate"}
              </Button>
            )}

          <ul className="nex-icon-list-within-card" id="nex-cs-certification-lists">
            <RenderCertification
              certifications={certifications}
              handleEditItem={handleEditItem}
              handleRemoveItem={handleRemoveItem}
              isLoggedinUser={isLoggedinUser}
              isAdmin={isAdmin}
            />
          </ul>
          {certifications.length < 1 && 
            <NoData>No Data</NoData>
            }
          <RenderCertificationModal
            selectedItem={selectedItem}
            toggle={toggle}
            isOpen={isOpen}
            practitionerId={guid}
            getData={getData}
            isAdmin={isAdmin}
          />
          <AlertDialog
            toggle={toggleAlert}
            isOpen={isOpenAlert}
            isLoading={isLoading}
            handleClickOnYes={handleClickOnYes}
            yesLabel={"Yes"}
            noLabel={"No"}
          />
        </CardBody>
      </div>
    </Card>
  );
};

export default withAlert()(Certifications);
