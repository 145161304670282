import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DropDown from "./DropDown";
import Search from "../../images/search.png";
import John from "../../images/ChatPerson/Bitmap1.png";
import Richard from "../../images/ChatPerson/Bitmap2.png";
import Alumni from "../../images/ChatPerson/Bitmap3.png";
import Edward from "../../images/ChatPerson/Bitmap4.png";
import { get_latest_feed } from "../../../../redux-helpers/FeedActionCreator";
import { Share_Post } from "utils/SharePostServices";
import { useSelector, useDispatch } from "react-redux";
import { titleCase } from "../../../../utils/titleCase";
import Default_Img from "../../../../assets/images/defaultPic.png";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import "./ShareModal.scss";

const mostRecentData = [
  { id: 1, name: "John Adley", img: John },
  { id: 2, name: "Richard Hampton", img: Richard },
  { id: 3, name: "Alumni Group ", img: Alumni },
  { id: 4, name: "Edward Jackson", img: Edward },
];

const ShareModal = ({
  share,
  modal,
  toggle,
  location,
  user,
  createdOn,
  feedId,
  mediaList,
  caption_text,
  share_feed_id,
}) => {
  // delete location["id"];
  const practionerId = useSelector(
    (state) => state.auth.currentUser.practitionerId
  );
  const [Privacy, setPrivacy] = useState("PUBLIC");
  const [text, setText] = useState("");
  const selected_privacy = (value) => {
    setPrivacy(value);
  };

  const dispatch = useDispatch();

  const onSavePost = () => {
    delete location["id"];

    let finalData = {
      feed: {
        commentVisibility: true,
        location: location,
        sharedFeedId: share_feed_id,
        status: true,
        text: text,
        type: "CONNECTION",
        user: {
          practitionerId: practionerId,
        },
        visibility: Privacy,
      },
      media:
        mediaList && mediaList.length > 0
          ? [
              {
                caption: mediaList[0] ? mediaList[0].caption : "",
                contentType: mediaList[0] ? mediaList[0].contentType : "",
                mediaUrl: mediaList[0] ? mediaList[0].mediaUrl : "",
              },
            ]
          : null,
    };
    Share_Post(finalData)
      .then((res) => {
        if (res.data.status == "SUCCESS") {
          showSuccessMessage(res.data.message);
          toggle();
          setTimeout(() => dispatch(get_latest_feed(practionerId)), 4000);
          setText("");
          setPrivacy("PUBLIC");
          window.scrollTo(10, 10);
        } else {
          showErrorMessage(
            res.data.message ? res.data.message : "Something Went Wrong"
          );
        }
      })
      .catch((err) => showErrorMessage(err.message));
  };
  let forwardMessage = (
    <>
      <section>
        <div className="header-search-forward">
          <img src={Search} alt="" />
          <input id="share_modal_gropu" type="text" placeholder="Search people and groups" />
        </div>
      </section>
      <section>
        <div className="most-recent-heading">Most recent</div>
        {mostRecentData.map((item) => {
          return (
            <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
              <div className="d-flex align-items-center">
                <div>
                  <img src={item.img} alt="Person_Img" />
                </div>
                <div className="ml-3">{item.name}</div>
              </div>
              <div>
                <button
                  id="share_modal_send"
                  type="button"
                  className="btn btn-outline-primary forward-send-btn "
                >
                  Send
                </button>
              </div>
            </div>
          );
        })}
      </section>
    </>
  );
  const createdDate = user.createdOn.split("T")[0];
  const createdTime = user.createdOn
    .split("T")[1]
    .split(".")[0]
    .split(":")
    .slice(0, 2)
    .join(":");
  let date_num = new Date(createdDate);
  let day_from_date = date_num.getDate();
  let year_from_date = date_num.getFullYear();
  let month_from_date = date_num.getMonth();

  // console.log(date_num, "date_num");
  // console.log(day_from_date, "day_from_date");
  // console.log(year_from_date, "year_from_date");
  // console.log(month_from_date, "month_from_date");

  let month = [
    "Jan",
    "Feb",
    "March",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let month_in_format = month.filter(
    (item, index) => index === month_from_date
  );

  return (
    <section className="feed-share-mdl">
      <div>
        <Modal
          isOpen={modal}
          toggle={toggle}
          size="md"
          className="modal-height"
          // className={share ? "modal_container_share" : null}
        >
          <ModalHeader
            className={share ? "header_modal_share" : "header_modal_send"}
            toggle={toggle}
          >
            {share ? "Share Post" : "Forward message"}
          </ModalHeader>

          <ModalBody>
            {share ? (
              <>
                <DropDown selected_privacy={selected_privacy} />
                <div className="input__body">
                  <textarea
                    placeholder="Type Something..."
                    // value={caption_text}
                    onChange={(e) => setText(e.target.value)}
                  />
                </div>
                {mediaList ? (
                  mediaList[0].mediaUrl &&
                  (mediaList[0].contentType == "image/png" ||
                    mediaList[0].contentType == "image/jpeg") ? (
                    <img
                      className="img-fluid"
                      style={{ width: "100%", height: "270px" }}
                      src={`${process.env.REACT_APP_API_ENDPOINT}getobject/${mediaList[0].mediaUrl}`}
                      alt=""
                    />
                  ) : (
                    <video width="100%" controls loop autoPlay muted>
                      <source
                        // src={mediaList[0].mediaUrl ? mediaList[0].mediaUrl : null}
                        src={
                          mediaList[0].mediaUrl
                            ? `${process.env.REACT_APP_API_ENDPOINT}getobject/${mediaList[0].mediaUrl}`
                            : null
                        }
                        type="video/mp4"
                      />
                    </video>
                  )
                ) : (
                  ""
                )}
                <div className="d-flex share-det">
                  <img
                    style={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "100%",
                    }}
                    src={
                      user.profilePicUrl !== null
                        ? `${process.env.REACT_APP_API_ENDPOINT}getobject/${user.profilePicUrl}`
                        : Default_Img
                    }
                    alt=""
                  />
                  <p className="ml-2" style={{ color: "#2D4552" }}>
                    {" "}
                    {titleCase(user.firstName !== null ? user.firstName : "") +
                      " " +
                      titleCase(
                        user.middleName !== null ? user.middleName : ""
                      ) +
                      " " +
                      titleCase(user.lastName !== null ? user.lastName : "")}
                  </p>
                  <p className="ml-2">
                    {month_in_format.map((month) => month)} {day_from_date},{" "}
                    {year_from_date} {createdTime}, {location && location.state}
                  </p>
                </div>
              </>
            ) : (
              forwardMessage
            )}

            <div className="input__body">
              {caption_text}
              {/* <textarea
                placeholder="Type Something..."
                value={caption_text}
                onChange={(e) => setText(e.target.value)}
              /> */}
            </div>
          </ModalBody>
          {
            share ? (
              <ModalFooter>
                <Button color="primary" onClick={onSavePost}>
                  Share Post
                </Button>
              </ModalFooter>
            ) : null
            // <textarea placeholder="Add caption"></textarea>
          }
        </Modal>
      </div>
    </section>
  );
};

export default ShareModal;
