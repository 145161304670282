import PersonalDetailsForm2 from "./components/PersonalDetailsForm2";
import PersonalDetailsForm1 from "./components/PersonalDetailsForm1";
import CreateNewRelatedPerson from "./components/CreateNewRelatedPerson";
import * as Yup from "yup";
import { useState } from "react";
import { Form, Formik } from "formik";
import { Card, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import MedicalHistoryForm1 from "./components/MedicalHistoryForm1";
import MedicalHistoryForm2 from "./components/MedicalHistoryForm2";
import MedicalHistoryForm3 from "./components/MedicalHistoryForm3";
import PractitionerForm from "./components/PractitionerForm";
import DocumentForm from "./components/DocumentForm";
import CreatePreviewPage from "./components/CreatePreviewPage";
import { OpinionService } from "utils/OpinionService";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { FAILED_TO_FETCH_DATA, FAILTOSAVERECOED } from "utils/Constants";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { MedialService } from "utils/MediaService";
import { PractitionerService } from "utils/PractitionerService";
import CommonLoader from "common/CommonLoader";

const PersonalDetailsForm2ValidationSchema = Yup.object().shape({
    title: Yup.string().required("This Field is required"),
    speciality: Yup.array().min(1, "This field is required"),
});

const MedicalHistoryForm1ValidationSchema = Yup.object().shape({
    title: Yup.string().required("This Field is required"),
    speciality: Yup.array().min(1, "This field is required"),
});

const getValidationSchema = (step) => {
    switch (step) {
        case 2:
            return PersonalDetailsForm2ValidationSchema;
        case 3:
            return MedicalHistoryForm1ValidationSchema;
        default:
            return null;
    }

}

const SecondOpinionCreationNew = () => {
    const currentUser = useSelector((state) => state.auth.currentUser);
    const { opinionId } = useParams();
    const [currentStep, setCurrentStep] = useState(opinionId ? 9 : 1);
    const [isSelf, setIsSelf] = useState(null);
    const history = useHistory();
    const [initialValues, setInitialValues] = useState({});

    const [isLoading, setIsLoading] = useState(false);

    // useEffect(() => {
    //     console.log("initialValues", initialValues);
    // }, [initialValues])

    const getPractitionerInfo = async (id) => {
        try {
            const response = await PractitionerService.getPractitionerById(id);
            const { status, data } = response;
            if (status === 200) {
                let profile = {
                    "name": `${data?.firstName} ${data?.lastName}`, // Example with full name
                    "profileGuid": data?.id
                };
                return profile;
            } else {
                return {};
            }
        } catch (error) {
            console.error("Error fetching practitioner info:", error);
            return {};
        }
    };

    const getOpinionDocs = (opinionId) => {
        return MedialService.getMedias("SECOND_OPINION", opinionId)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    let docs = data.map(file => ({
                        name: file.name,
                        size: file.size,
                        file: file
                    }));
                    return docs;
                } else {
                    showErrorMessage(FAILED_TO_FETCH_DATA);
                    return [];  // Return an empty array in case of failure
                }
            })
            .catch((error) => {
                showErrorMessage(FAILED_TO_FETCH_DATA);
                return [];  // Return an empty array in case of an error
            });
    };



    const getOpinionInfo = async (opinionId) => {
        try {
            const response = await OpinionService.getOpinion(opinionId);
            const { status, data } = response;
            if (status === 200) {
                const practitionerProfile = data?.practitionerAssignedTo ? await getPractitionerInfo(data?.practitionerAssignedTo) : {};
                const docs = await getOpinionDocs(data?.id);  // Await the docs

                setInitialValues({
                    "id": data?.id,
                    "speciality": [],
                    "title": data?.title,
                    "description": data?.description,
                    "symptoms": data?.symptoms.split(","),
                    "allergies": data?.allergies.split(","),
                    "surgeries": data?.surgeries.split(","),
                    "familyHistory": data?.familyHistory,
                    "pastHospitalization": data?.pastHospitalization,
                    "currentMedications": data?.currentMedications,
                    "requestedFor": currentUser,
                    "practitionerAssignedTo": practitionerProfile,
                    "docs": docs,  // Use the awaited docs
                });
            }
        } catch (error) {
            console.error("Error fetching opinion info:", error);
        }
    };



    useEffect(() => {
        if (opinionId) {
            getOpinionInfo(opinionId);
        } else {
            setInitialValues({
                "speciality": [],
                "title": "",
                "description": "",
                "symptoms": [],
                "allergies": [],
                "surgeries": [],
                "familyHistory": "",
                "pastHospitalization": "",
                "currentMedications": "",
                "requestedFor": {},
                "practitionerAssignedTo": {},
                "docs": [],
                "requestedBy": currentUser?.profileId,
                "createNewMember": false
            });
        }
    }, [])

    const uploadDoc = (doc, id) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('files', doc.file);

            MedialService.uploadMedia("SECOND_OPINION", id, formData)
                .then((response) => {
                    const { status } = response;
                    if (status === 200) {
                        setIsLoading(false);
                        resolve(); // Resolve the promise on success
                    } else if (status === 409) {
                        showErrorMessage("Can't upload the document with the same name again");
                        setIsLoading(false);
                        resolve(); // Resolve even if there's a conflict (409)
                    } else {
                        showErrorMessage(FAILTOSAVERECOED);
                        setIsLoading(false);
                        reject(); // Reject the promise on failure
                    }
                })
                .catch(error => {
                    showErrorMessage("Failed to upload document");
                    reject(error); // Reject the promise on error
                });
        });
    };


    const uploadAllDocs = (docs, id) => {
        if (docs && docs.length > 0) {
            const uploadPromises = docs.map((doc) => uploadDoc(doc, id));

            Promise.all(uploadPromises)
                .then(() => {
                    showSuccessMessage("Opinion request created successfully...");
                    setIsLoading(false)
                    history.push(`/u/medicalcases/my-opinions/${id}`);
                })
                .catch(() => {
                    showErrorMessage("Document(s) failed to upload.");
                    history.push(`/u/medicalcases/my-opinions/${id}`); // Optionally navigate even on error
                });
        } else {
            history.push(`/u/medicalcases/my-opinions/${id}`); // Navigate immediately if no docs to upload
        }
    };


    const createSecondOpinionRequest = (values) => {
        setIsLoading(true);
        let payload = {
            "speciality": values?.speciality[0],
            "title": values?.title,
            "status": "DRAFT",
            "requestedBy": currentUser?.profileId,
            "requestedFor": isSelf ? values?.requestedFor?.profileId : values?.requestedFor?.id,
            "practitionerAssignedTo": values?.practitionerAssignedTo?.profileGuid,
            "description": values?.description,
            "familyHistory": values?.familyHistory,
            "pastHospitalization": values?.pastHospitalization,
            "currentMedications": values?.currentMedications,
            "symptoms": values?.symptoms.join(', '),
            "allergies": values?.allergies.join(', '),
            "surgeries": values?.surgeries.join(', ')
        }

        OpinionService.createOpinion(payload)
            .then((response) => {
                const { status, data } = response;
                if (status === 201) {
                    if (values?.docs) {
                        uploadAllDocs(values?.docs, data?.id);
                    } else {
                        setIsLoading(false);
                        showSuccessMessage("Opinion request created successfully...");
                        history.push(`/u/medicalcases/my-opinions/${data?.id}`);
                    }
                } else {
                    showErrorMessage(FAILTOSAVERECOED);
                }
            });
    };

    const updateSecondOpinionRequest = (id, values) => {
        console.log(values);
        let payload = {
            "speciality": values?.speciality[0],
            "title": values?.title,
            "status": "DRAFT",
            "requestedBy": currentUser?.profileId,
            "requestedFor": currentUser?.profileId,
            "practitionerAssignedTo": values?.practitionerAssignedTo?.profileGuid,
            "description": values?.description,
            "familyHistory": values?.familyHistory,
            "pastHospitalization": values?.pastHospitalization,
            "currentMedications": values?.currentMedications,
            "symptoms": values?.symptoms.join(', '),
            "allergies": values?.allergies.join(', '),
            "surgeries": values?.surgeries.join(', ')
        }

        OpinionService.updateOpinion(id, payload)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    showSuccessMessage("Opinion request updated successfully...");
                    // showSuccessMessage("Uploading Docs...");
                    // if (values?.docs) {
                    //     // uploadAllDocs(values?.docs, data?.id);
                    // } else {
                    history.push(`/u/medicalcases/my-opinions/${data?.id}`);
                    // }
                } else {
                    showErrorMessage(FAILTOSAVERECOED);
                }
            });
    };


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema(currentStep)}
            onSubmit={(values, actions) => {
                console.log(values);
                if (currentStep < 9) {
                    if (currentStep === 1 && isSelf)
                        setCurrentStep(Number(currentStep) + 2);
                    else
                        setCurrentStep(Number(currentStep) + 1);
                } else {
                    if (opinionId)
                        updateSecondOpinionRequest(opinionId, values);
                    else
                        createSecondOpinionRequest(values);
                }
            }
            }
            enableReinitialize
        >
            {({ errors, touched, handleSubmit, values }) => (
                <Form>
                    <div className="nex-second-opinion-wrap">
                        <Row>
                            <Col className="nex-sidebar v2">
                                <div className="nexogic-widget-sidebar-card  nex-step-lists">
                                    <ListGroup className="nex-step-lists-ul">
                                        <ListGroupItem className={`${(currentStep === 1 || currentStep === 2 || currentStep === 3) ? 'current' : ''} disabled1`}>Personal Details</ListGroupItem>
                                        <ListGroupItem className={`${(currentStep === 4 || currentStep === 5 || currentStep === 6) ? 'current' : ''} disabled1`}>Medical History</ListGroupItem>
                                        <ListGroupItem className={`${(currentStep === 7) ? 'current' : ''} disabled1`}>Select Practitioner</ListGroupItem>
                                        <ListGroupItem className={`${(currentStep === 8) ? 'current' : ''} disabled1`}>Upload Documents</ListGroupItem>
                                        <ListGroupItem className={`${(currentStep === 9) ? 'current' : ''} disabled1`}>Preview</ListGroupItem>
                                    </ListGroup>
                                </div>
                            </Col>
                            <Col className="nex-step-from-wrap">
                                <Card className=" nex-list-card-v2">
                                    {currentStep === 1 &&
                                        <PersonalDetailsForm1
                                            currentStep={currentStep}
                                            setCurrentStep={setCurrentStep}
                                            isSelf={isSelf}
                                            setIsSelf={setIsSelf}
                                            currentUser={currentUser}
                                        />
                                    }
                                    {currentStep === 2 &&
                                        <CreateNewRelatedPerson
                                            currentStep={currentStep}
                                            setCurrentStep={setCurrentStep}
                                            isSelf={isSelf}
                                            setIsSelf={setIsSelf}
                                            currentUser={currentUser}
                                        />
                                    }
                                    {currentStep === 3 &&
                                        <PersonalDetailsForm2
                                            currentStep={currentStep}
                                            setCurrentStep={setCurrentStep}
                                            isSelf={isSelf}
                                            setIsSelf={setIsSelf}
                                            currentUserDetails={currentUser}
                                        />
                                    }
                                    {(currentStep === 4) &&
                                        <MedicalHistoryForm1
                                            currentStep={currentStep}
                                            setCurrentStep={setCurrentStep}
                                            isSelf={isSelf}
                                            setIsSelf={setIsSelf}
                                            currentUserDetails={currentUser}
                                            values={values}
                                        />
                                    }
                                    {(currentStep === 5) &&
                                        <MedicalHistoryForm2
                                            currentStep={currentStep}
                                            setCurrentStep={setCurrentStep}
                                            isSelf={isSelf}
                                            setIsSelf={setIsSelf}
                                            currentUserDetails={currentUser}
                                        />
                                    }
                                    {(currentStep === 6) &&
                                        <MedicalHistoryForm3
                                            currentStep={currentStep}
                                            setCurrentStep={setCurrentStep}
                                            isSelf={isSelf}
                                            setIsSelf={setIsSelf}
                                            currentUserDetails={currentUser}
                                        />
                                    }
                                    {(currentStep === 7) &&
                                        <PractitionerForm
                                            currentStep={currentStep}
                                            setCurrentStep={setCurrentStep}
                                            isSelf={isSelf}
                                            setIsSelf={setIsSelf}
                                            currentUserDetails={currentUser}
                                        />
                                    }
                                    {currentStep === 8 &&
                                        <DocumentForm
                                            currentStep={currentStep}
                                            setCurrentStep={setCurrentStep}
                                            isSelf={isSelf}
                                            setIsSelf={setIsSelf}
                                            currentUserDetails={currentUser}
                                        />
                                    }
                                    {currentStep === 9 &&
                                        <CreatePreviewPage
                                            currentStep={currentStep}
                                            setCurrentStep={setCurrentStep}
                                            isSelf={isSelf}
                                            setIsSelf={setIsSelf}
                                            currentUserDetails={currentUser}
                                        />
                                    }
                                </Card>
                                {isLoading ?
                                    <Row>
                                        <div
                                            className="m-auto"
                                        >
                                            <CommonLoader />
                                        </div>
                                    </Row>
                                    :
                                    <Row>
                                        {!opinionId &&
                                            <>
                                                {currentStep > 1 &&
                                                    <span
                                                        className="btn nex-btn-primary-outline"
                                                        onClick={() => {
                                                            if (currentStep == 3 && isSelf)
                                                                setCurrentStep(Number(currentStep) - 2)
                                                            else
                                                                setCurrentStep(Number(currentStep) - 1)
                                                        }}
                                                    >
                                                        {"Back"}
                                                    </span>
                                                }

                                                {currentStep < 9 &&
                                                    < span
                                                        className="btn nex-btn-primary ml-auto"
                                                        onClick={handleSubmit}
                                                    >
                                                        {"Next"}
                                                    </span>
                                                }
                                            </>
                                        }
                                        {
                                            currentStep === 9 &&
                                            < span
                                                className="btn nex-btn-primary ml-auto"
                                                onClick={handleSubmit}
                                            >
                                                {"Submit"}
                                            </span>
                                        }
                                    </Row>
                                }
                            </Col>
                        </Row>
                    </div>
                </Form >
            )
            }
        </Formik >
    )
}

export default SecondOpinionCreationNew;