import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import FormikCheckboxField from "components/forms/formikFields/FormikCheckboxField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikInputMonthYearFormatField from "components/forms/formikFields/FormikInputMonthYearFormatField";
import InstitutesTypeaheadFieldNew from "components/forms/formikFields/InstitutesTypeaheadFieldNew";
import { Field, Formik } from "formik";
import { Fragment, useState } from "react";
import { withAlert } from "react-alert";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { Button, Col, Label, Row, Spinner } from "reactstrap";
import { FAILTOSAVERECOED } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import { getAddressName, getLocationName } from "utils/Utils";
import * as Yup from "yup";
import NewInstituitionSection from "./NewInstituitionSection";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{5})(( x| ext)\d{1,5}){0,1}$/;

export const yearmonth = /^(0[1-9]|10|11|12)-\d{4}$/;

const validationSchema = Yup.object().shape({
  institutionProfile: Yup.lazy((value) => {
    if (value && value.onSelectNewInstitutionCollege === 0) {
      return Yup.object().test(
        "",
        "This field is required. You must select from options or add new",
        () => false
      );
    } else if (value && value.onSelectNewInstitutionCollege === 1) {
      return Yup.object();
    } else if (value && value.onSelectNewInstitutionCollege === 2) {
      return Yup.object();
    } else {
      return Yup.mixed().test(
        "",
        "This field is required. You must select from options or add new",
        (value) => value === {}
      );
    }
  }),
  position: Yup.string().min(3).max(50).required("This field is required"),
  description: Yup.string(),
  fromDate: Yup.string()
    .matches(yearmonth, "Please enter valid format (MM-YYYY)")
    .required("This field is required"),
  toDate: Yup.string().when("expires", {
    is: false,
    then: Yup.string()
      .matches(yearmonth, "Please enter valid format (MM-YYYY)")
      .required("This field is required")
      .test({
        name: "fromDate",
        message: "End Year should be greater than Start year",
        test: function (value) {
          if (this.parent.fromDate && value) {
            const fullStart = this.parent.fromDate.split("-");
            const fullEnd = value.split("-");
            if (fullStart.length > 0 && fullEnd.length > 0) {
              const [startYearM, startYearY] = fullStart;
              const [endYearM, endYearY] = fullEnd;
              return startYearY + startYearM < endYearY + endYearM;
            }
            return false;
          }
          return false;
        },
      }),
    otherwise: Yup.string(),
  }),
  expires: Yup.boolean(),
  city: Yup.array().when("institutionProfile.onSelectNewInstitutionCollege", {
    is: 2,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required"),
  }),
  state: Yup.array().when("institutionProfile.onSelectNewInstitutionCollege", {
    is: 2,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required"),
  }),
  country: Yup.array().when(
    "institutionProfile.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.array()
        .min(1, "This field is required")
        .required("This field is required"),
    }
  ),
  location: Yup.array().when(
    "institutionProfile.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.array()
        .min(1, "This field is required")
        .required("This field is required"),
    }
  ),
  mainItemName: Yup.string().when(
    "institutionProfile.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().required("This field is required"),
    }
  ),
  phone: Yup.string().when("institutionProfile.onSelectNewInstitutionCollege", {
    is: 2,
    then: Yup.string().when("phone", {
      is: (val) => val !== "",
      then: Yup.string().matches(
        phoneRegExp,
        "Please enter valid Phone Number"
      ),
      otherwise: Yup.string(),
    }),
  }),
  postalCode: Yup.string().when(
    "institutionProfile.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().required("This field is required"),
    }
  ),
  addressLine1: Yup.string().when(
    "institutionProfile.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().required("This field is required"),
    }
  ),
  websiteAddress: Yup.string().when(
    "institutionProfile.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().when("websiteAddress", {
        is: (val) => val !== "",
        then: Yup.string().url("Please enter a valid URL"),
        otherwise: Yup.string(),
      }),
    }
  ),
  category: Yup.string().when(
    "institutionProfile.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().required("This field is required"),
    }
  ),
});

const RenderForm = (props) => {
  const { setFieldValue, values, handleSubmit, isLoading, toggle } = props;

  const onNewInstitute = () => {
    toggleNested();
  };

  const toggleNested = () => {
    setFieldValue(`institutionProfile.onSelectNewInstitutionCollege`, 2);
    setFieldValue(`institutionProfile.institutionName`, "Other");
    setFieldValue(`institutionProfile.guid`, "");
  };

  return (
    <form className="nx-form" onSubmit={handleSubmit}>
      <Fragment>
        <Row form>
          <Col sm={12} md={12}>
            <Field
              name="institutionProfile"
              id="institutionProfile"
              type="text"
              label="Hospital/Clinic Name"
              component={InstitutesTypeaheadFieldNew}
              onNewInstituteReq={onNewInstitute}
              placeholder="Hospital / Clinic Name"
              autoComplete="off"
            />
          </Col>
          <Col sm={12} md={12}>
            <Field
              name="position"
              id="position"
              type="text"
              label="Position/Job Title"
              component={FormikInputField}
              placeholder="Your position name"
              autoComplete="off"
            />
          </Col>
        </Row>
        {values?.institutionProfile?.onSelectNewInstitutionCollege === 2 && (
          <NewInstituitionSection {...props} />
        )}
        <Row form>
              <Col xs={6} md={4}>
                <Label>Start Date</Label>
                <Field
                  name={`fromDate`}
                  id={`fromDate`}
                  type="text"
                  component={FormikInputMonthYearFormatField}
                  placeholder="MM-YYYY"
                  autoComplete="off"
                />
              </Col>
              <Col xs={6} md={4}>
                <Label>End Date</Label>
                {values.expires ? (
                  <Field
                    name={`Presenttime`}
                    id={`Presenttime`}
                    type="text"
                    placeholder="Present time"
                    autoComplete="off"
                    value="Present time"
                    disabled
                    className="form-control"
                  />
                ) : (
                  <Field
                    name={`toDate`}
                    id={`toDate`}
                    type="text"
                    component={FormikInputMonthYearFormatField}
                    placeholder="MM-YYYY"
                    autoComplete="off"
                  />
                )}
              </Col>
              <Col
                xs={12}
                md={4}
                className=""
              >
                <label className="d-xs-none">&nbsp;</label>
                <div className="nexogix-form-check-expire-date">
                <Field
                  name="expires"
                  id="expires"
                  type="checkbox"
                  label="Presently Working"
                  component={FormikCheckboxField}
                  className="check-flex"
                  autoComplete="expires"
                />
                </div>
              </Col>
            </Row>
        <Row form>
          <Col lg={12}>
            <Label>Description</Label>
            <Field
              name="description"
              id="description"
              type="textarea"
              component={FormikInputField}
              rows={4}
              cols={5}
              placeholder="Type something about your experience..."
            />
          </Col>
        </Row>
      </Fragment>
      {/*<hr className="profile-info-modal-hr mt-0" />*/}
      <div className="justify-content-end  modal-footer ">
        <Col xs="auto" className="action-group">
          {isLoading ? (
            <div className="loading-spiner">
              <Spinner />
            </div>
          ) : (
            <>
              
              <Button id="ef_btn_submit" className="nexogic_primary_button" color="primary" type="submit">
                Save
              </Button>
              <Button id="ef_btn_cancel"
                onClick={toggle}
                className="nexogic_primary_button_outline"
                color="primary"
                type="button"
              >
                Cancel
              </Button>
            </>
          )}
        </Col>
      </div>
    </form>
  );
};

const ExperienceForm = (props) => {
  const { selectedItem, getData, toggle, alert } = props;
  let initValue = selectedItem;
  if (!selectedItem.toMonth) {
    initValue = { ...initValue, toMonth: "" };
  }
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  const getendYearValue = (payload) => {
    let returnVal = null;
    if (payload?.expires === true) {
      returnVal = null;
    } else {
      if (payload?.toDate !== "") {
        returnVal = payload?.toDate;
      } else {
        returnVal = "";
      }
    }
    return returnVal;
  };

  const addItem = async (value) => {
    const userGUID = globalProfileInfo.guid;
    setLoading(true);
    let payload = value;
    try {
      const formData = {
        description: payload?.description,
        endYear: getendYearValue(payload),
        institutionDetail: {
          ...(!payload.institutionProfile.guid && {
            address: {
              addressLine1: payload?.addressLine1 ?? "",
              addressLine2: payload?.addressLine2 ?? "",
              addressType: payload?.addressType ?? "BILLING_ADDRESS",
              city: getAddressName(payload?.city),
              state: getAddressName(payload?.state),
              country: getAddressName(payload?.country),
              location: getLocationName(payload?.location),
              postalCode: payload?.postalCode ?? "",
            },
          }),
          ...(payload.institutionProfile.guid && {
            guid: payload.institutionProfile.guid,
          }),
          name: payload.mainItemName,
          phone: payload?.phone ?? "",
          websiteUrl: payload?.websiteAddress ?? "",
          ...(payload.category && {
            type: payload.category,
          }),
        },
        positionName: payload?.position ?? "",
        presentlyWorking: payload?.expires,
        startYear: payload?.fromDate,
      };
      const request = PractitionerService.registerExperiences(
        formData,
        userGUID,
        true
      );
      request
        .then(async (response) => {
          const { status, validationErrors } = response;
          if (status === 201) {
            await getData();
            setLoading(false);
            toggle();
            showSuccessMessage("Experience added Successfully");
          } else {
            setLoading(false);
            if (
              validationErrors &&
              validationErrors &&
              validationErrors.length > 0
            ) {
              const error = [];
              validationErrors.forEach((errors) => {
                error.push(errors.message);
              });
              showErrorMessage(error.join(","));
            } else {
              showErrorMessage(FAILTOSAVERECOED);
            }
          }
        })
        .catch((error) => {
          setErrorMessage(error.message);
          setLoading(false);
          console.log(error);
        });
    } catch (e) {
      setErrorMessage(e.message);
      setLoading(false);
      console.log(e);
    }
  };

  const updateItem = async (value) => {
    const userGUID = globalProfileInfo.guid;
    setLoading(true);
    let payload = value;
    try {
      const formData = {
        description: payload?.description,
        endYear: getendYearValue(payload),
        institutionDetail: {
          ...(!payload.institutionProfile.guid && {
            address: {
              addressLine1: payload?.addressLine1 ?? "",
              addressLine2: payload?.addressLine2 ?? "",
              addressType: payload?.addressType ?? "BILLING_ADDRESS",
              city: getAddressName(payload?.city),
              state: getAddressName(payload?.state),
              country: getAddressName(payload?.country),
              location: getLocationName(payload?.location),
              postalCode: payload?.postalCode ?? "",
            },
          }),
          ...(payload.institutionProfile.guid && {
            guid: payload.institutionProfile.guid,
          }),
          name: payload.mainItemName,
          phone: payload?.phone ?? "",
          websiteUrl: payload?.websiteAddress ?? "",
          ...(payload.category && {
            type: payload.category,
          }),
        },
        positionName: payload?.position ?? "",
        presentlyWorking: payload?.expires,
        startYear: payload?.fromDate,
      };
      const request = PractitionerService.updateExperiences(
        formData,
        userGUID,
        payload?.id
      );
      request
        .then(async (response) => {
          const { status, validationErrors } = response;
          if (status === 201) {
            await getData();
            setLoading(false);
            toggle();
            showSuccessMessage("Experience updated Successfully");
          } else {
            setLoading(false);
            if (
              validationErrors &&
              validationErrors &&
              validationErrors.length > 0
            ) {
              const error = [];
              validationErrors.forEach((errors) => {
                error.push(errors.message);
              });
              showErrorMessage(error.join(","));
            } else {
              showErrorMessage(FAILTOSAVERECOED);
            }
          }
        })
        .catch((error) => {
          setErrorMessage(error.message);
          setLoading(false);
          console.log(error);
        });
    } catch (e) {
      setErrorMessage(e.message);
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={initValue}
        onSubmit={(values) => {
          if (values.id) {
            updateItem(values);
          } else {
            addItem(values);
          }
        }}
        children={(props) => (
          <>
            <RenderForm toggle={toggle} {...props} isLoading={isLoading} />
          </>
        )}
      />
    </>
  );
};
export default withAlert()(ExperienceForm);
