import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import './style.scss'

export default function HeaderOne() {
  return (
    <Card className='micro-headers-sec-v1'>
        <CardBody>
            <Row>
                <Col lg={12}>
                    <img src="https://picsum.photos/id/10/1200/300" alt="" className='micro-headers-banner' />
                </Col>
                <Col lg={'auto'} className="profile-image position-h-center">
                    <img src="https://picsum.photos/id/64/200/300" alt="" className='card-profile-image' />
                </Col>
            </Row>
        </CardBody>
    </Card>
  )
}

