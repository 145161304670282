import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CommentAvatar from "../CommentAvatar/CommentAvatar";
import {
  fetch_Comment,
  Post_Comment,
  fetch_lat_Comment,
} from "../../../../utils/CommentServie";
import Picker from "emoji-picker-react";
import SmileIcon from "../../../FeedHomePage/images/messageIcon/smile.png";
import "./comment.css";

const Comment = ({ latestComment, id, increaseComment, number }) => {
  const [comments, setcomments] = useState([]);

  const [showEmoji, setShowEmoji] = useState(false);

  const [latComment, setLatComment] = useState([]);

  const [comment_text, setcomment_text] = useState("");

  const [newComment, setNewComment] = useState([]);

  const [pageNum, setpageNum] = useState(0);

  let practionerId = useSelector(
    (state) => state.auth.currentUser.practitionerId
  );

  useEffect(() => {
    fetch_lat_Comment(id, practionerId).then((res) => {
      setLatComment(res.data.data.content.comments);
    });
  }, []);

  let user = useSelector((state) => state.auth.currentUser);

  const load_comment = () => {
    return fetch_Comment(id, pageNum, practionerId).then((res) => {
      setpageNum(pageNum + 1);
      setcomments([...comments, ...res.data.data.content.comments]);
      setNewComment([]);
      setLatComment([]);
    });
  };
  const showEmoji_handler = () => {
    setShowEmoji(!showEmoji);
  };

  const onEmojiClick = (event, emojiObject) => {
    let text = comment_text ? comment_text : "";
    setcomment_text(text + emojiObject.emoji);
  };

  const post_comment_fn = (event) => {
    event.preventDefault();
    let data;
    if (comment_text) {
      data = {
        comment: {
          repliedOn: id,
          text: comment_text,
          type: "FEED",
          user: {
            practitionerId: practionerId,
          },
        },
      };

      Post_Comment(data, practionerId)
        .then((res) => {
          increaseComment();
          setNewComment([
            ...newComment,
            {
              profPic: user.profilePicUrl,
              commentText: comment_text,
              firstName: user.firstName,
              lastName: user.lastName,
              createdDate: new Date(),
            },
          ]);

          setcomment_text("");
          setLatComment([]);
        })
        .catch((err) => err.message);
    } else {
      console.log("err");
    }
  };

  // console.log(newComment, "newComment");
  // console.log(latComment, "latComment");
  // console.log(latestComment, "latestCommentlatestComment");

  return (
    <div className="mt-4">
      <div className="text-area">
        <form className="d-flex" onSubmit={post_comment_fn}>
          <input
            id="add_comment"
            name="add_comment"
            type="text"
            placeholder="Add comment..."
            value={comment_text}
            onChange={(event) => {
              setcomment_text(event.target.value);
              // setLatComment(event.target.value);
            }}
          />
          <span onClick={showEmoji_handler}>
            <img src={SmileIcon} alt="" style={{ margin: "5px" }} />
          </span>
        </form>

        {showEmoji && (
          <div className="emoji__place ">
            <Picker onEmojiClick={onEmojiClick} disableSearchBar={true} />
          </div>
        )}
      </div>
      {newComment.length > 0
        ? [...newComment].reverse().map((item) => {
            return (
              <CommentAvatar
                profPic={item.profPic}
                commentText={item.commentText}
                firstName={item.firstName != null ? item.firstName : ""}
                middleName={item.middleName != null ? item.middleName : ""}
                lastName={item.lastName != null ? item.lastName : ""}
                createdDate={item.createdDate}
                title={item.title != null ? item.title : ""}
              />
            );
          })
        : ""}
      {/* {newComment.length > 0 ? () => setLatComment([...newComment]) : null} */}
      {latComment.length > 0 && (
        <CommentAvatar
          profPic={latComment[0].user.profilePicUrl}
          commentText={latComment[0].text}
          title={
            latComment[0].user.title != null ? latComment[0].user.title : ""
          }
          firstName={
            latComment[0].user.firstName != null
              ? latComment[0].user.firstName
              : ""
          }
          middleName={
            latComment[0].user.middleName != null
              ? latComment[0].user.middleName
              : ""
          }
          lastName={latComment[0].user.lastName}
          profession={latComment[0].user.primarySpeciality[0]}
          createdDate={latComment[0].commentedOn}

          // View_more_comments={comments && comments}
        />
      )}

      <div className="mt-4">
        {comments.length
          ? comments.map((item) => {
              return (
                <CommentAvatar
                  key={item.id}
                  profPic={item.user.profilePicUrl}
                  commentText={item.text}
                  firstName={
                    item.user.firstName != null ? item.user.firstName : ""
                  }
                  middleName={
                    item.user.middleName != null ? item.user.middleName : ""
                  }
                  lastName={
                    item.user.lastName != null ? item.user.lastName : ""
                  }
                  createdDate={item.commentedOn}
                  title={item.user.title != null ? item.user.title : ""}
                  profession={item.user.primarySpeciality[0]}
                />
              );
            })
          : null}
      </div>
      {comments.length < number && number >= 2 && newComment.length < number ? (
        <p className="load__comment" onClick={load_comment}>
          Load more comments
        </p>
      ) : (
        ""
      )}
    </div>
  );
};

export default Comment;
