import contactsActiveIcon from "assets/images/action-groups-16.png";
import approvalIcon from "assets/images/approval_icon.png";
import marker_icon from "assets/images/marker_icon.png";
import plus_icon from "assets/images/plus-icon.png";
import degree_icon from "assets/svgIcon/degree_icon.svg";
import varified_icon from "assets/svgIcon/varified_icon.svg";
import spasalist_icon from "assets/images/stethoscope.png";
import file_icon from "assets/images/certificate.png";
import location_icon from "assets/images/location-marker.png";
import connection_icon from "assets/svgIcon/connection_icon.svg";
import language_icon from "assets/images/language.png";

import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { withAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import { profileAddEvent } from "redux-helpers/ActionCreator";
import { ConnectionService } from "utils/ConnectionService";
import { UserStatusConstants } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import ContainerRecentExperience from "./components/ContainerRecentExperience";
import ImageUploader from "./components/ImageUploader";
import RenderConnctBtn from "./components/RenderConnctBtn";
import RenderCoverImage from "./components/RenderCoverImage";
import SmartCard from "./components/SmartCard";
import RenderEditProfileForm from "./components/RenderEditProfileForm";
import { RenderRatingPopover } from "./components/RenderRatingPopover";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import SocialHandler from "../DoctorsDetails/components/SocialHandler";

const DoctorsProfile = (props) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);
  const [dropdownOpen, setDdropdownOpen] = useState(false);
  const reviews = [];
  const [currentProfileUser, setCurrentProfileUser] = useState(null);
  const [connectionsCount, setConnectionsCount] = useState(0);
  const { practitioner, isAdmin, isLoggedinUser, setPractitioner, alert } =
    props;
  const {
    headline,
    profilePicUrl,
    reviewCount,
    guid,
    bannerUrl,
    firstName,
    middleName,
    lastName,
    primarySpeciality,
    superSpeciality,
    qualification,
    title,
    address,
    languages
  } = practitioner;
  // const location = useLocation();
  const username = `${firstName} ${lastName}`;
  const dispatchEventName = useDispatch();
  const profileComplete = useSelector((state) => state.profileComplete);
  const sectionList = [
    { name: "About", event_name: "ADD_ABOUT", section_name: "aboutSection" },
    {
      name: "Skills & Expertise",
      event_name: "ADD_SKILL",
      section_name: "skillSection",
    },
    {
      name: "Services offered",
      event_name: "ADD_SERVICE",
      section_name: "serviceSection",
    },
    {
      name: "Affiliation",
      event_name: "ADD_Affiliation",
      section_name: "affiliationSection",
    },
    {
      name: "Experience",
      event_name: "ADD_EXPERIENCE",
      section_name: "experienceSection",
    },

    {
      name: "Education",
      event_name: "ADD_EDUCATION",
      section_name: "educationSection",
    },
    {
      name: "Certifications & Licensure",
      event_name: "ADD_CERTIFICATIONS",
      section_name: "certificationsSection",
    },
    {
      name: "Professional Memberships",
      event_name: "ADD_MEMBERSHIPS",
      section_name: "professionalSection",
    },
    {
      name: "Publications & Presentations",
      event_name: "ADD_PUB",
      section_name: "publicationsSection",
    },
    {
      name: "Awards & Recognitions",
      event_name: "ADD_AWARDS",
      section_name: "awardsSection",
    },
    {
      name: "Clinical Trials",
      event_name: "ADD_CLINICAL",
      section_name: "clinicalTrialsSection",
    },
    {
      name: "Committees",
      event_name: "ADD_COMMITTEES",
      section_name: "committeesSection",
    },
    {
      name: "Registration",
      event_name: "ADD_Registration",
      section_name: "registrationSection",
    },
    {
      name: "Registration Documents",
      event_name: "ADD_Registration",
      section_name: "registration_documents",
    },
    { name: "Social", event_name: "ADD_SOCIAL", section_name: "socialSection" },
  ];

  useEffect(() => {
    getConnectedCount();
  }, [guid]);

  // useEffect(() => {
  //   console.log(practitioner);
  // }, [])

  const getConnectedCount = async () => {
    ConnectionService.getConnectionsCount(currentUser.guid)
      .then((response) => {
        let { data } = response;
        if (response.status === 200 || response.status === 201) {
          setConnectionsCount(data);
        }
      })
      .catch((error) => {
        console.log("Error while getting connection status: ", error);
      });
  };

  const onHandleAddSection = (section) => {
    if (practitioner.status !== UserStatusConstants.APPROVED) {
      dropClick();
      showErrorMessage("Permission denied. Please contact admin for approval.");
    } else {
      dropClick();
      dispatchEventName(profileAddEvent(section));
    }
  };

  const onHandleScrollToSection = (sectionName) => {
    var element = document.getElementById(`${sectionName}`);
    var headerOffset = 45;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const connectionCount = useMemo(() => {
    return connectionsCount > 1
      ? `${connectionsCount} connections`
      : `${connectionsCount} connection`;
  }, [connectionsCount]);

  const dropClick = () => {
    if (dropdownOpen) {
      document.querySelectorAll('body')[0].classList.remove('add-section-on');
    } else {
      document.querySelectorAll('body')[0].classList.add('add-section-on');
    }
    setDdropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    if (isAdmin && globalProfileInfo?.guid && practitioner) {
      PractitionerService.getPractitionersPersonalDetails(
        globalProfileInfo?.guid
      )
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            if (response?.data) {
              const { emails, phones, address, dateOfBirth, headline } =
                response?.data;
              setCurrentProfileUser(() => {
                const clone = { ...practitioner };
                clone.emails = emails && emails.length > 0 ? emails : [];
                clone.phones = phones && phones.length > 0 ? phones : [];
                clone.address = address ?? null;
                clone.dateOfBirth = dateOfBirth ?? "";
                clone.headline = headline ?? "";
                return clone;
              });
            }
          }
        })
        .catch((error) => {
          console.log(
            "PractitionerService.getPractitionersPersonalDetails error",
            error
          );
        });
    } else {
      setCurrentProfileUser(() => {
        const clone = { ...practitioner };
        return clone;
      });
    }
  }, [isAdmin, globalProfileInfo, setPractitioner, practitioner]);

  useEffect(() => {
    document.body.classList.add('nex-screen-profile-page');
    document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "Profile";
    return () => {
      document.body.classList.remove('nex-screen-profile-page');
      document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "";
    }
  }, [])

  return (
    <section className="nexogic-profile-main" id="nexogic-doctor-main-profile">
      <div className="nexogic-card-banner card border-0 nexogic-banner-admin-card">
        <div className=" card-cover">
          <RenderCoverImage
            bannerUrl={bannerUrl}
            setPractitioner={setPractitioner}
            isLoggedinUser={isLoggedinUser}
            isAdmin={isAdmin}
            practitionerId={guid}
            practitioner={practitioner}
          />
        </div>
        <div className=" card-body">
          <div className="card-user-info-top-sec">
            <div className="card-user-img-w">
              <ImageUploader
                profilePicUrl={profilePicUrl}
                practitionerId={guid}
                setPractitioner={setPractitioner}
                isLoggedinUser={isLoggedinUser}
                isAdmin={isAdmin}
                username={username}
                practitioner={practitioner}
              />
            </div>
            <ul className="nex-links">
              <li> {isLoggedinUser && (
                <>
                  <span className="icon"><svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.75 6.75L14.6001 9.89995L13.25 8.54997M5.375 15.7501H13.625C14.6605 15.7501 15.5 14.9106 15.5 13.8751C15.5 10.8145 11 10.8751 9.5 10.8751C8 10.8751 3.5 10.8145 3.5 13.8751C3.5 14.9106 4.33947 15.7501 5.375 15.7501ZM12.5 5.25C12.5 6.90685 11.1569 8.25 9.5 8.25C7.84315 8.25 6.5 6.90685 6.5 5.25C6.5 3.59315 7.84315 2.25 9.5 2.25C11.1569 2.25 12.5 3.59315 12.5 5.25Z" stroke="#001A72" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  </span>
                  {practitioner.status === UserStatusConstants.APPROVED ? (
                    <Link
                      to={"/connections"}
                      className="text-decoration-none"
                    >
                      <span className="">
                        {connectionCount}
                      </span>
                    </Link>
                  ) : (
                    <span className="">
                      {connectionCount}
                    </span>
                  )}
                </>
              )}</li>
              <li><span className="icon"><svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 10.5C16.6569 10.5 18 12 18 13.125C18 13.7463 17.4963 14.25 16.875 14.25H16.5M13.5 8.25C14.7426 8.25 15.75 7.24264 15.75 6C15.75 4.75736 14.7426 3.75 13.5 3.75M4.5 10.5C2.84315 10.5 1.5 12 1.5 13.125C1.5 13.7463 2.00368 14.25 2.625 14.25H3M6 8.25C4.75736 8.25 3.75 7.24264 3.75 6C3.75 4.75736 4.75736 3.75 6 3.75M13.125 14.25H6.375C5.75368 14.25 5.25 13.7463 5.25 13.125C5.25 11.25 7.5 10.5 9.75 10.5C12 10.5 14.25 11.25 14.25 13.125C14.25 13.7463 13.7463 14.25 13.125 14.25ZM12 6C12 7.24264 10.9926 8.25 9.75 8.25C8.50736 8.25 7.5 7.24264 7.5 6C7.5 4.75736 8.50736 3.75 9.75 3.75C10.9926 3.75 12 4.75736 12 6Z" stroke="#001A72" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              </span> 1245 Followers</li>
            </ul>
          </div>
          <div className="info">
            <Card className="nexogic-profile-info-card">
              <CardBody>
                <Row className="align-items-start">
                  <Col className="nexogic-info-left">
                    <div className="nex-flex">
                    <h3 className="card-title w-full">
                      {title} {firstName} {middleName} {lastName} {" "} {qualification && (
                        <span className="card-text"><small>
                          {", " + qualification + " "} </small>
                        </span>
                      )}
                      {practitioner.status === UserStatusConstants.APPROVED ? (
                        <><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3 5.5L9 2.5L15 5.5C15 9.39492 12.9118 14.8823 9 16C5.08821 14.8823 3 9.39492 3 5.5Z" fill="#34EA72" />
                          <path d="M11.625 7.375L8.25 10.75L7.125 9.625M9 2.5L3 5.5C3 9.39492 5.08821 14.8823 9 16C12.9118 14.8823 15 9.39492 15 5.5L9 2.5Z" stroke="#257C6D" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                          {/*<img src={varified_icon} alt="verified icon" className="varify-img" />*/}
                        </>
                      ) : (
                        ""
                      )}
                      <div className="nexogic-profile-edit-icon">
                        {currentProfileUser && (
                          <RenderEditProfileForm
                            isLoggedinUser={isLoggedinUser}
                            practitioner={currentProfileUser}
                            setPractitioner={setPractitioner}
                            isAdmin={isAdmin}
                          />
                        )}
                      </div>
                      {/*headline && (
                        <small className="text-muted"><br />{"" + headline + " "}</small>
                      )*/}
                    </h3>
                      <p className="card-text">
                        <img src={spasalist_icon} alt="" width={15} className="icon" />
                        {primarySpeciality && (
                          <span className="">
                            {primarySpeciality + " "}
                          </span>
                        )}
                        {superSpeciality && (
                          <>{", "} <span className="">
                            {superSpeciality}
                          </span></>
                        )}
                      </p>
                      <p className="card-text">
                        <img src={file_icon} alt="" width={15} className="icon" />
                        <RenderExpYear practitioner={globalProfileInfo} />
                      </p>
                      {languages.length > 0 &&
                        <p className="card-text">
                          <img
                            src={language_icon}
                            className="icon" width={15}

                            alt="contactsActiveIcon"
                          />
                          {languages.map((item, i) => <span key={'lang' + i}>{i == 0 ? '' : ', '}{item}</span>)}
                        </p>
                      }
                    </div>
                    {address && address.length > 0 && (
                      <p className="card-text">
                        <img src={location_icon} alt="#" className="icon" />
                        {address[0].location && (
                          <span className="">
                            {address[0].location},
                          </span>
                        )}
                        {address[0].city && (
                          <span className="">
                            {address[0].city}
                          </span>
                        )}
                      </p>
                    )}

                    <div className="d-flex">
                      <div className="info-icons d-flex align-items-center">
                        {reviews && reviews.length > 0 && (
                          <React.Fragment>
                            <div className="reviews d-flex align-items-center">
                              <RenderRatingPopover
                                practitionerId={practitioner.guid}
                                reviews={reviews}
                                reviewCount={reviewCount}
                              />
                            </div>
                            <div className="dot"></div>
                          </React.Fragment>
                        )}

                      </div>
                    </div>
                    {/* <div className="profile-info-detail">
                      {!isLoggedinUser &&
                        !isAdmin &&
                        !isEmpty(currentUser) &&
                        globalProfileInfo && (
                          <Row className="mx-0">
                            <RenderConnctBtn getConnectedCount={getConnectedCount} />
                            <div className="pl-3">
                              <SmartCard toggle={toggle} isOpen={isOpen} practitioner={practitioner} />
                            </div>
                          </Row>
                        )}
                    </div> */}
                  </Col>
                  <Col className="nexogic-info-right">
                    <ContainerRecentExperience />
                  </Col>
                </Row>
                <Row className="">
                  <Col md={12} className="nex-banner-action">
                    {/* <div className="profile-info-detail"> */}
                    {!isLoggedinUser &&
                      !isAdmin &&
                      !isEmpty(currentUser) &&
                      globalProfileInfo && (
                        <div className="nexogic-btn-group mx-0">
                          <RenderConnctBtn getConnectedCount={getConnectedCount} />
                          <div className="edit-icon">
                            <SmartCard toggle={toggle} isOpen={isOpen} practitioner={practitioner} />
                          </div>
                        </div>
                      )}
                    {/* </div> */}
                    {isLoggedinUser &&
                      !isAdmin &&
                      currentUser.status === UserStatusConstants.APPROVED && (
                        <Row className="nexogic-btn-group mx-0">
                          <div className="edit-icon">
                            <SmartCard
                              toggle={toggle}
                              isOpen={isOpen}
                              practitioner={practitioner}
                            />
                          </div>
                          <Dropdown
                            id="nex-dp-add-setion-menu-btn"
                            isOpen={dropdownOpen}
                            toggle={dropClick}
                            className="nexogic_add_section_menu"

                          >
                            <DropdownToggle caret className="btn nex-btn-transparent nex-btn-sections"><span className="icon"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="9.75" cy="9.75" r="8.4375" fill="white" stroke="#46ABC9" stroke-width="1.125" />
                              <path d="M9.625 6L9.625 13.2539" stroke="#46ABC9" stroke-width="1.125" stroke-linecap="round" />
                              <path d="M13.2656 9.63281L6.01172 9.63281" stroke="#46ABC9" stroke-width="1.125" stroke-linecap="round" />
                            </svg>
                            </span> Add More Section</DropdownToggle>
                            <DropdownMenu style={{
                              position: 'absolute',
                              top: '100%',
                              transform: 'translateY(-1px) !important', // Minor adjustment to avoid potential clipping
                            }}>
                              {sectionList.map((section, index) => {
                                let currentItem = profileComplete.data.filter(
                                  (v) => v.event === section.event_name
                                )[0];
                                let isContentBold = currentItem.filled
                                  ? "has-data"
                                  : "";
                                return (
                                  <div className="nexogic_add_section_item" key={`${index}`}>
                                    <DropdownItem title={`Go to ${section.name} Section`}
                                      className={isContentBold}
                                      onClick={() =>
                                        onHandleScrollToSection(section.section_name)
                                      }
                                    >
                                      {section.name}
                                    </DropdownItem>
                                    <Button
                                      className="nexogic_add_section_plus_btn"
                                      onClick={() =>
                                        onHandleAddSection(section.event_name)
                                      }
                                      title={`Add ${section.name}`}
                                    >
                                      <img src={plus_icon} alt="plus" />
                                    </Button>
                                  </div>
                                );
                              })}
                            </DropdownMenu>
                          </Dropdown>
                        </Row>
                      )}
                    {(!isLoggedinUser && !isAdmin) &&
                      <SocialHandler {...props} />
                    }
                  </Col>
                </Row>
              </CardBody>
            </Card>













          </div>
        </div>
      </div>



    </section>
  );
};

const RenderExpYear = (props) => {
  let { practicingSince } = props.practitioner;
  let currentYear = new Date().getFullYear();
  const expYears = currentYear - practicingSince;
  let exHTML;
  if (isNaN(expYears)) {
    exHTML = "1 year experience";
  } else if (expYears === 0) {
    exHTML = "1 year experience";
  } else {
    exHTML = `${expYears}+ years experience`;
  }
  return <span className="speciality-category">{exHTML}</span>;
};

export default withAlert()(DoctorsProfile);
