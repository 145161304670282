import logo_provider from "assets/images/login-provider.png";
import graduation_cap_icon from "assets/images/graduation-cap.png";
import suitcase_icon from "assets/images/suitcase.png";
import pin_small_icon from "assets/images/pin-small.png";
import doctor_profile_img from "assets/images/doctor-profile-img.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Field, Formik } from "formik";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import * as Yup from "yup";
import { Button, Col, Row } from "reactstrap";
import {
    emailNonNumericRegEx,
    onlyAlphabets,
  } from "utils/Constants";

const titles = [
    {
        label: "Dr.",
        value: "Dr.",
    },
    {
        label: "Mr.",
        value: "Mr.",
    },
    {
        label: "Mrs.",
        value: "Mrs.",
    },
    {
        label: "Ms.",
        value: "Ms.",
    },
    {
        label: "Prof.",
        value: "Prof.",
    },
];

const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is required"),
    firstName: Yup.string().when("claimsProfileId", {
        is: (val) => val === undefined,
        then: Yup.string()
          .min(2)
          .max(30)
          .matches(onlyAlphabets, "Only Alphabets")
          .required("This field is required"),
        otherwise: Yup.string(),
      }),
      lastName: Yup.string().when("claimsProfileId", {
        is: (val) => val === undefined,
        then: Yup.string()
          .min(2)
          .max(30)
          .matches(onlyAlphabets, "Only Alphabets")
          .required("This field is required"),
        otherwise: Yup.string(),
      }),
      email: Yup.string()
    .max(50)
    .matches(emailNonNumericRegEx, "Please Enter an valid Email")
    .required("This field is required"),
});

const NewDoctorProfileV2 = (props) => {
    const [isSubmitting, setSubmitting] = useState(false);

    const initValues = useMemo(() => {
        return {
            title: titles[0].value,
            firstName: "",
            lastName: "",
            primarySpeciality: "",
            practicingSince: "",
            streetAddress1: "",
            streetAddress2: "",
            country: "",
            state: "",
            city: "",
            zipCode: "",
            location: "",
            phone: "",
            email: "",
            password: "",
            confirmPassword: "",
            rememberMe: false,
            claimsProfileId: "",
        };
    }, []);

    const [initialValues, setInitialValues] = useState(initValues);

    const onSubmit = (values) => {
        console.log(values);
        if (!isEmpty(values.name)) {
            setSubmitting(true);
            console.log(values);
        }

    };

    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <div className="nex-bg-light-blue">
            <main className="nex-login-register--w">
                <div className="container d-flex md" data-aos="fade-up">
                    <div className="nex-form-fields--w nex-form-fields--w-v3 no-min-height">
                        <div className="nex-form-top-slot nex-form-aside-v2">
                            <p className="text-center logo" data-aos="fade-up" data-aos-duration="800" data-aos-delay="100"><a href="/"><img src={logo_provider} alt="" /></a></p>
                            <p className="text-center mt-4 nex-text-lg text-black" data-aos="fade-up" data-aos-duration="800" data-aos-delay="150">We found below profiles matching with your name <br />Please select your profile and continue</p>
                        </div>
                        <div className="nex-form-center-slot nex-image-list--radiobtn--w no-center">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                enableReinitialize={true}
                                onSubmit={onSubmit}
                                children={(props) => {
                                    return (
                                        <>
                                            <RenderForm
                                                {...props}
                                                isSubmitting={isSubmitting}
                                            />
                                        </>
                                    );
                                }}
                            />


                        </div>

                    </div>
                </div>
            </main>
        </div>
    )
}

export default NewDoctorProfileV2;

const RenderForm = (props) => {
    const [iseditable, setisEditable] = useState(true);
    const {
        values,
        errors,
        isSubmitting,
        handleSubmit,
    } = props;

    return (
        <>
            <div className="nex-image-list nex-image-list--radiobtn" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
                <div className="form-group nex-radio-check--w">
                    <input type="radio" name="doctor" id="" className="nex-radio-check lg" />
                </div>
                <div className="nexogic-graphic">
                    <img src={doctor_profile_img} alt="" className="nexogic-avatar lg" />
                </div>
                <div className="nexogic-info">
                    <h3 className="nex-text-xl">Dr. Surya Prakash Rao </h3>
                    <ul className="nex-inline-list">
                        <li className="nex-text-ellipse"><a href="#" title="Cardiology">Cardiology</a></li>
                    </ul>
                    <p className="nex-lead text-dark"><span className="nex-icon xs"><img src={pin_small_icon} alt="" /></span>Hyderabad, Telengana, India</p>
                    <p><span className="nex-icon xs"><img src={graduation_cap_icon} alt="" /></span>BAMS</p>
                    <p><span className="nex-icon xs"><img src={suitcase_icon} alt="" /></span>23+ Experience</p>

                </div>
            </div>
            <div className="nex-image-list nex-image-list--radiobtn" data-aos="fade-up" data-aos-duration="800" data-aos-delay="250">
                <div className="form-group nex-radio-check--w">
                    <input type="radio" name="doctor" id="" className="nex-radio-check lg" />
                </div>
                <div className="nexogic-graphic">
                    <img src={doctor_profile_img} alt="" className="nexogic-avatar lg" />
                </div>
                <div className="nexogic-info">
                    <h3 className="nex-text-xl">Dr. Surya Reddy </h3>
                    <ul className="nex-inline-list">
                        <li className="nex-text-ellipse"><a href="#">Orthopedics</a></li>
                    </ul>
                    <p className="nex-lead text-dark"><span className="nex-icon xs"><img src={pin_small_icon} alt="" /></span>Medicover Hospitals, Hi-tech City, Hyderabad</p>
                    <p><span className="nex-icon xs"><img src={graduation_cap_icon} alt="" /></span>BAMS</p>
                    <p><span className="nex-icon xs"><img src={suitcase_icon} alt="" /></span>23+ Experience</p>

                </div>
            </div>
            <div className="nex-image-list nex-image-list--radiobtn" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">
                <div className="form-group nex-radio-check--w">
                    <input type="radio" name="doctor" id="" className="nex-radio-check lg" />
                </div>
                <div className="nexogic-graphic">
                    <img src={doctor_profile_img} alt="" className="nexogic-avatar lg" />
                </div>
                <div className="nexogic-info">
                    <h3 className="nex-text-xl">Dr. Suryashini Ray </h3>
                    <ul className="nex-inline-list">
                        <li className="nex-text-ellipse"><a href="#">Orthopedics</a></li>
                    </ul>
                    <p className="nex-lead text-dark"><span className="nex-icon xs"><img src={pin_small_icon} alt="" /></span>Medicover Hospitals, Hi-tech City, Hyderabad</p>
                    <p><span className="nex-icon xs"><img src={graduation_cap_icon} alt="" /></span>BAMS</p>
                    <p><span className="nex-icon xs"><img src={suitcase_icon} alt="" /></span>23+ Experience</p>

                </div>
            </div>

            <div className="form-group nex-max-md mx-auto mt-5" data-aos="fade-up" data-aos-duration="800" data-aos-delay="50">
                <input type="submit" name="" value={'Continue'} id="" className="btn btn-primary lg w-100" />
            </div>
            <div className="nex-max-md mx-auto mt-5 px-3" data-aos="fade-up" data-aos-duration="800" data-aos-delay="100">
                <div className="nex-or-txt--w mt-5">or</div>
                <p className="text-center nex-text-lg">Continue with your social login</p>
            </div>
            <div className="nex-max-lg mx-auto mt-5 px-3 nex-form-container" data-aos="fade-up" data-aos-duration="800" data-aos-delay="150">
                <Row>
                    <Col lg={6}>
                        <div className="form-group">
                            <div className="nex-input-prefix">
                                <Field
                                    id="firstName"
                                    name="firstName"
                                    autoComplete="firstName"
                                    type="text"
                                    component={FormikInputField}
                                    label="First Name"
                                    placeholder="Your first name"
                                    disabled={!iseditable}
                                />
                                <div className="nex-prefix-wrap">
                                    <Field
                                        id="title"
                                        name="title"
                                        type="text"
                                        label=""
                                        component={FormikSelectField}
                                        inputprops={{
                                            name: "title",
                                            options: titles,
                                            keys: {
                                                id: "value",
                                                label: "label",
                                            },
                                        }}
                                        disabled={!iseditable}
                                        autoComplete="phone"
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="form-group">
                            <Field
                                id="lastName"
                                name="lastName"
                                autoComplete="lastName"
                                type="text"
                                component={FormikInputField}
                                label="Last Name"
                                placeholder="Your last name"
                                disabled={!iseditable}
                            />
                        </div>
                    </Col>
                </Row>
                
                
                <div className="form-group">
                    <Field
                    id="email"
                    name="email"
                    autoComplete="email"
                    type="text"
                    component={FormikInputField}
                    label="Email Address"
                    placeholder="Enter email"
                    disabled={props.initialValues.email === "" ? false : true}
                    />
                </div>
                <div className="form-group pt-3">
                    <label className="nex-text-lg text-black mb-3" htmlFor=""><b>Gender</b></label>
                    <div className="nex-inline-radio-btn gender-radio">
                        <div className="inp-radio">
                            <input type="radio" name="gender" id="g1" className="nex-radio-check" checked />
                            <label for="g1" className="nex-text-lg">Male</label>
                        </div>
                        <div className="inp-radio">
                            <input type="radio" name="gender" id="g2" className="nex-radio-check" />
                            <label for="g2" className="nex-text-lg">Female</label>
                        </div>
                        <div className="inp-radio">
                            <input type="radio" name="gender" id="g3" className="nex-radio-check" />
                            <label for="g3" className="nex-text-lg">Prefer not to say </label>
                        </div>

                    </div>
                </div>

                <div className="form-group nex-max-md mx-auto mt-5">
                <Button
                    id="nex-continue-form-next2"
                    className="btn btn-primary lg w-100"
                    type="submit"
                    disabled={isSubmitting}
                    >
                    Continue
                    </Button>
                </div>
            </div>

        </>
    );
};