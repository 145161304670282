import React from 'react';
import { Link } from "react-router-dom";
import {
    Button,
    ButtonDropdown,
    Card,
    CardImg,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import LinesEllipsis from 'react-lines-ellipsis';
import ConnectButton from 'pages/FeedHomePage/components/PractitionerProfiles/ConnectButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { getAge, getFullNameOfPerson, getGenderDetails } from 'utils/Utils';
import { useState } from 'react';
import edit_icon from "assets/images/edit-blue-icon.png";

const GroupRecord = ({ profileDetails, index, group, handleEditItem }) => {
    const [dropdownOpen, seDropdownOpen] = useState(false);
    const toggle = () => {
        seDropdownOpen(!dropdownOpen);
    };

    return (
        <tr key={`profile_${index}`}>
            <td className="border font-weight-bold">
                <div className=' d-flex'>
                    <h4 className="link-text">
                        <Link className="group-link nex-gi-group-group-name" to={`/connections/groups/${profileDetails.id}`}>
                            {profileDetails.name}
                        </Link>
                    </h4>
                </div>
            </td>
            <td className="border">
                {typeof profileDetails.description != "undefined" &&
                    <LinesEllipsis
                        className="card-text"
                        text={profileDetails.description}
                        maxLine="1"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                    />
                }
            </td>
            <td className="border">
                <div className="actions">
                    <span
                        color="primary"
                        className="btn nex-gi-group-edit-btn"
                        onClick={() => handleEditItem(group)}
                    >
                        <img src={edit_icon} alt='' width={18} /> {/*Edit*/}
                    </span>
                </div>
            </td>
        </tr>
    );
}

const GroupsOwnedTabularView = (props) => {
    // const currentUser = useSelector((state) => state.auth.currentUser);
    const { theader, tdata, activeTab, handleEditItem, reportClick, setGroupIdToAct, toggleAlert } = props;
    const [dropdownOpen, seDropdownOpen] = useState(false);
    const toggle = () => {
        seDropdownOpen(!dropdownOpen);
    };

    // useEffect(() => {
    //     console.log(tdata);
    // }, [tdata])

    return (
        <div className="profiles-table">
            <table className="table border" >
                <thead>
                    <tr>
                        {theader.map((columns, index) => (
                            <th className="theader" key={index}>{columns}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tdata.map((profileDetails, index) => (
                        <GroupRecord profileDetails={profileDetails} index={index} group={profileDetails} handleEditItem={handleEditItem} />
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default GroupsOwnedTabularView