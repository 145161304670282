import "bootstrap/dist/css/bootstrap.min.css";
import { Provider as AlertProvider } from "react-alert";
// import AlertTemplate from "react-alert-template-basic";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Alert } from "reactstrap";
import { PersistGate } from "redux-persist/integration/react";

import "assets/styles/global.scss";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import AppContainer from "./containers/AppContainer";
//import "./index.css";
//import "./styles/global.scss";
import { persistor, store } from "./redux-helpers/Store";
import { JobFilterProvider } from "context/JobFilterContext";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { AuthContextProvider } from "context/AuthContext";

const history = createBrowserHistory();
const options = {
  timeout: 10000,
  position: "top center",
  // offset: "80px",
  transition: "scale",
};

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
  history.replace(path);
}

const AlertColors = { info: "info", success: "primary", error: "danger" };
const AlertTemplate = ({ style, options, message, close }) => (
  <Alert className="mt-2" color={AlertColors[options.type]} toggle={close}>
    <div>{message}</div>
  </Alert>
);




const App = () => {
  const [isOnline, setIsOnline] = useState(true);
  const [showBackOnlineAlert, setShowBackOnlineAlert] = useState(false);


  useEffect(() => {
    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);
    return () => {
      window.removeEventListener("online", handleNetworkChange);
      window.removeEventListener("offline", handleNetworkChange);
    };
  }, []);

  const handleNetworkChange = () => {
    setIsOnline(window.navigator.onLine);
    if (window.navigator.onLine) {
      setShowBackOnlineAlert(true);
      setTimeout(() => setShowBackOnlineAlert(false), 5000); // 5 seconds
    } else {
      setShowBackOnlineAlert(false);
    }
  };

  const onDismiss = () => {
    setShowBackOnlineAlert(false);
  }
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <AlertProvider template={AlertTemplate} {...options}>
            <JobFilterProvider>
              <>
                {!isOnline &&
                  (<Alert color="danger" className="nexogic-fixed-top-alert small text-center"> <div> Oops! It looks like there's no network connection. Please check your internet connection and try again.</div></Alert>)
                }

                <Alert
                  color="success" className="nexogic-fixed-top-alert small text-center"
                  isOpen={showBackOnlineAlert} toggle={onDismiss}
                > <div> You are back online!</div></Alert>

                <AuthContextProvider>
                  <ToastContainer position="top-center" autoClose={false} />
                  <AppContainer />
                </AuthContextProvider>
              </>
            </JobFilterProvider>
          </AlertProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
