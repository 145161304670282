import { useState } from "react";
import AsyncMessageProfileSearch from "./AsyncMessageProfileSearch";
import { Button, Card, CardBody, Input, InputGroup, InputGroupAddon } from 'reactstrap'

const NewMessage = (props) => {

  return (
    <div className="nexogic-chat-content-search">
      <Card className="nexogic-form">
        <div className="card-body">
          <h2 className="card-title">Search Practitioner</h2>
          <div className="">
            {/*<span className="to-txt">To</span>*/}
            {/* <Input type="text" placeholder="#group #name" /> */}
            <InputGroup >
              <InputGroupAddon addonType="append">
                <Button  color="link" className=" append-label">To
                  {/* <FontAwesomeIcon icon={faSearch} className="text-dark" /> */}
                </Button>
              </InputGroupAddon>
              <AsyncMessageProfileSearch
                id="n_msg_search"
                type="search"
                placeholder="Search..."
                
                handleToggle={props.handleToggle}
              />
            </InputGroup>
          </div>
        </div>
        {/*<CardBody>
          <div className="bottom-search">
          <InputGroup >
            <InputGroupAddon addonType="append">
                <Button color="link" className=" append-label">&nbsp;
                   <FontAwesomeIcon icon={faSearch} className="text-dark" /> 
                </Button>
              </InputGroupAddon>
            <Input type="text" placeholder="Type Something.." />
            <div></div>
            </InputGroup>
          </div>
  </CardBody>*/}
      </Card>
    </div>
  );
};

export default NewMessage;
