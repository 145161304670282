import AddressTypeaheadField from "components/forms/formikFields/AddressTypeaheadField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import LocationTypeaheadField from "components/forms/formikFields/LocationTypeaheadField";
import { Field } from "formik";
import PropTypes from "prop-types";
import { memo, useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-toastify/dist/ReactToastify.css";
import { Col, FormFeedback, InputGroup, Label, Row } from "reactstrap";
import { EducationInstitutionTypesList } from "utils/Constants";
import { MetadataService } from "utils/MetadataService";

const NewInstituitionSection = (props) => {
  const { index, values, setFieldValue, errors, touched } = props;
  const [countryList, setCountyList] = useState([]);
  const [statesList, setSatesList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const stateRef = useRef();
  const cityRef = useRef();
  const countryRef = useRef();
  const locRef = useRef();

  const getCountries = () => {
    setSatesList([]);
    setCityList([]);
    MetadataService.getCountry()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setCountyList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getCountries error", error);
      });
  };

  const getStates = (countryId) => {
    setCityList([]);
    MetadataService.getStates(countryId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setSatesList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getStates error", error);
      });
  };

  const getCities = (stateId) => {
    MetadataService.getCities(stateId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setCityList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getCities error", error);
      });
  };

  const getLocations = (cityId) => {
    MetadataService.getLocations(cityId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setLocationList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getCities error", error);
      });
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <>
      <Row form>
        <Col md={4}>
          <Label>Category</Label>
          <Field
            id={`educationFormData[${index}].category`}
            name={`educationFormData[${index}].category`}
            autocomplete={`educationFormData[${index}].category`}
            component={FormikSelectField}
            inputprops={{
              options: EducationInstitutionTypesList,
              defaultOption: "Choose",
              keys: {
                id: "id",
                label: "label",
              },
            }}
          />
        </Col>
        <Col md={8}>
          <Label>Medical College/University Name</Label>
          <Field
            name={`educationFormData[${index}].mainItemName`}
            id={`educationFormData[${index}].mainItemName`}
            autocomplete={`educationFormData[${index}].mainItemName`}
            type="text"
            component={FormikInputField}
            placeholder="Medical College/ University Name"
          />
        </Col>
      </Row>
      <Row form>
        <Col lg={6}>
          <InputGroup
            size="medium"
            className="code-phone-ig phone-input-container"
          >
            <Label>Phone Number</Label>
            <PhoneInput
              id="nim_phone"
              country={"in"}
              onlyCountries={["in"]}
              inputClass="phonenumber-form-control"
              containerClass="mb-2"
              inputProps={{
                name: `educationFormData[${index}].phone`,
                autoComplete: `educationFormData[${index}].phone`,
              }}
              onChange={(value, country, e, formattedValue) =>
                setFieldValue(
                  `educationFormData[${index}].phone`,
                  formattedValue
                )
              }
              inputStyle={{
                width: "100%",
              }}
              autocomplete={`educationFormData[${index}].phone`}
            />
            {!!touched?.educationFormData &&
              !!touched?.educationFormData[index] &&
              !!touched?.educationFormData[index].phone &&
              !!errors?.educationFormData &&
              !!errors?.educationFormData[index] &&
              !!errors?.educationFormData[index].phone &&
              typeof errors.educationFormData[index].phone === "string" && (
                <FormFeedback
                  className="d-flex"
                  invalid={!!errors.educationFormData[index].phone}
                >
                  {errors.educationFormData[index].phone}
                </FormFeedback>
              )}
          </InputGroup>
        </Col>

        <Col lg={6}>
          <Label>Website Address</Label>
          <Field
            name={`educationFormData[${index}].websiteAddress`}
            autocomplete={`educationFormData[${index}].websiteAddress`}
            id="websiteAddress"
            type="text"
            component={FormikInputField}
            placeholder="Website Address"
          />
        </Col>
      </Row>
      <fieldset className="u-flsd-wpr mb-3">
        {/** <legend>Mailing Address</legend>*/}
        <Row form>
          <Col lg={12}>
            <Label>Street Address </Label>
            <Field
              name={`educationFormData[${index}].addressLine1`}
              autocomplete={`educationFormData[${index}].addressLine1`}
              id="addressLine1"
              type="text"
              component={FormikInputField}
              placeholder="Street Address"
            />
          </Col>
          <Col lg={12}>
            <Label>Street Address2</Label>
            <Field
              name={`educationFormData[${index}].addressLine2`}
              autocomplete={`educationFormData[${index}].addressLine2`}
              id="addressLine2"
              type="text"
              component={FormikInputField}
              placeholder="Street Address2"
            />
          </Col>

          <Col lg={6}>
            <div className="form-group">
              <Label>Country</Label>
              <Field
                name={`educationFormData[${index}].country`}
                id={`educationFormData[${index}].country`}
                component={AddressTypeaheadField}
                placeholder="Select country"
                spellCheck={false}
                inputList={countryList}
                value={values[`educationFormData`][index].country}
                getAddressById={(contryId) => {
                  getStates(contryId);
                  setFieldValue(`educationFormData[${index}].state`, []);
                  setFieldValue(`educationFormData[${index}].city`, []);
                  setFieldValue(`educationFormData[${index}].location`, []);
                }}
                locationRef={countryRef}
              />
            </div>
          </Col>

          <Col lg={6}>
            <div className="form-group">
              <Label>State</Label>
              <Field
                name={`educationFormData[${index}].state`}
                id={`educationFormData[${index}].state`}
                component={AddressTypeaheadField}
                placeholder="Select state"
                spellCheck={false}
                inputList={statesList}
                value={values[`educationFormData`][index].state}
                getAddressById={(stateId) => {
                  getCities(stateId);
                  setFieldValue(`educationFormData[${index}].city`, []);
                  setFieldValue(`educationFormData[${index}].location`, []);
                }}
                locationRef={stateRef}
              />
            </div>
          </Col>

          <Col lg={4}>
            <div className="form-group">
              <Label>City</Label>
              <Field
                name={`educationFormData[${index}].city`}
                id={`educationFormData[${index}].city`}
                component={AddressTypeaheadField}
                placeholder="Select city"
                spellCheck={false}
                inputList={cityList}
                value={values[`educationFormData`][index].city}
                getAddressById={(cityId) => {
                  getLocations(cityId);
                }}
                locationRef={cityRef}
              />
            </div>
          </Col>

          <Col lg={4}>
            <Label>Zipcode </Label>
            <Field
              name={`educationFormData[${index}].postalCode`}
              id={`educationFormData[${index}].postalCode`}
              autocomplete={`educationFormData[${index}].postalCode`}
              type="text"
              component={FormikInputField}
              placeholder="Zipcode"
            />
          </Col>
          <Col lg={4}>
            <div className="form-group">
              <Label>Location</Label>
              <Field
                id={`educationFormData[${index}].location`}
                name={`educationFormData[${index}].location`}
                type="text"
                component={LocationTypeaheadField}
                value={values[`educationFormData`][index].location}
                placeholder="Select Location"
                spellCheck={false}
                locations={locationList}
                selectdLocation={(input) =>
                  setFieldValue(`educationFormData[${index}].location`, input)
                }
                locationRef={locRef}
              />
            </div>
          </Col>
        </Row>
      </fieldset>
    </>
  );
};

NewInstituitionSection.propTypes = {
  index: PropTypes.number.isRequired,
};

export default memo(NewInstituitionSection);
