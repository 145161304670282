import React, { useEffect } from 'react'
import { Card, CardBody, Col, Nav, NavItem, Row } from 'reactstrap';
//import './settings.scss';
// import General from './PractitionerSettings/components/General';
// import Integrations from './PractitionerSettings/components/Integrations';
// import ContactVisibility from './PractitionerSettings/components/ContactVisibility';
// import SetNotifications from './PractitionerSettings/components/SetNotifications';
// import Preferences from './PractitionerSettings/components/Preferences';
import settingsIcon from "assets/images/svgs/settings-icon.svg";
import { isPractitioner } from 'utils/UserHelper';
import { useState } from 'react';
import { Link, NavLink, Route, Switch, useRouteMatch } from 'react-router-dom/cjs/react-router-dom';
import ContactVisibility from './components/ContactVisibility';
import General from './components/General';
import Integrations from './components/Integrations';
import SetNotifications from './components/SetNotifications';
import Preferences from './components/Preferences';

const Settings = (props) => {

    //left section 
    let { path, url } = useRouteMatch();


    useEffect(() => {
        window.scrollTo(0, 0);

    }, [])

    return (
        <div className="nexogic-settings-main  ">

            <div className="nexogic-heading-sec">
                <h2 className="nexogix-section-title">Settings</h2>
            </div>
            <Row>
                <Col xl={3} lg={3} className='nexogic-aside-col'>
                    <Card>
                        <CardBody>
                            <Nav className='nexogic-aside-links'>
                                {/* <NavItem>
                                    <NavLink activeClassName="active" to={`${url}/change-password`}>Change Password</NavLink>
                                </NavItem> */}
                                {isPractitioner() &&
                                <NavItem>
                                    <NavLink activeClassName="active" to={`${url}/preferences`}>Preferences</NavLink>
                                </NavItem>
                                }
                                {process.env.REACT_APP_HIDE_STATIC_CONTENT === 'false' && isPractitioner() &&
                                <NavItem>
                                    <NavLink activeClassName="active" to={`${url}/integrations`}>Integrations</NavLink>
                                </NavItem>
                                }
                                {process.env.REACT_APP_HIDE_STATIC_CONTENT === 'false' && isPractitioner() &&
                                <NavItem>
                                    <NavLink activeClassName="active" to={`${url}/notifications`}>Notifications</NavLink>
                                </NavItem>
                                }
                                {process.env.REACT_APP_HIDE_STATIC_CONTENT === 'false' && isPractitioner() &&
                                <NavItem>
                                    <NavLink activeClassName="active" to={`${url}/Contact-visibility`}>Contact Visibility</NavLink>
                                </NavItem>
                                }
                            </Nav>
                        </CardBody>

                    </Card>
                </Col>
                <Col xl={9} lg={9}>
                    <Card className='nexogic-settings-card'>

                        <Switch>
                           <Route exact path={path}>
                                {/* <General isTitle={true} /> */}
                                <Preferences isTitle={true} />
                            </Route>
                             {/* <Route path={`${path}/change-password`}>
                                <General isTitle={true} />
                            </Route> */}
                            {isPractitioner() &&
                            <Route path={`${path}/preferences`}>
                                <Preferences isTitle={true} />
                            </Route>
                            }
                            {process.env.REACT_APP_HIDE_STATIC_CONTENT === 'false' && isPractitioner() &&
                            <Route path={`${path}/integrations`}>
                                <Integrations isTitle={true} />
                            </Route>
                        }
                            {process.env.REACT_APP_HIDE_STATIC_CONTENT === 'false' && isPractitioner() &&
                            <Route path={`${path}/notifications`}>
                                <SetNotifications isTitle={true} />
                            </Route>
                            }
                            {process.env.REACT_APP_HIDE_STATIC_CONTENT === 'false' && isPractitioner() &&
                            <Route path={`${path}/Contact-visibility`}>
                                <ContactVisibility isTitle={true} />
                            </Route>
                            }
                        </Switch>
                        
                        {/*
                        {process.env.REACT_APP_HIDE_STATIC_CONTENT === 'false' && isPractitioner() &&
                            <div className="mb-5">
                                <Integrations />
                            </div>
                        }
                        
                        {process.env.REACT_APP_HIDE_STATIC_CONTENT === 'false' && isPractitioner() &&
                            <div className="mb-5">
                                <ContactVisibility />
                            </div>
                        }
                        {isPractitioner() &&
                            <div className='mb-5'>
                                <Preferences />
                            </div>
                        }
                        
                        {process.env.REACT_APP_HIDE_STATIC_CONTENT === 'false' && isPractitioner() &&
                            <div className="mb-5">
                                <SetNotifications />
                            </div>
                        }
                        */}
                    </Card>
                </Col>
            </Row>


        </div>
    )
}

export default Settings