import React from 'react'

export default function IconMarker() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      fill="none"
      viewBox="0 0 12 16"
    >
      <path
        fill="#001244"
        d="M6 .32A5.127 5.127 0 00.88 5.44c0 4.516 4.681 9.903 4.88 10.13.061.07.147.11.24.11a.33.33 0 00.24-.11c.199-.231 4.88-5.71 4.88-10.13A5.127 5.127 0 006 .32zm0 3.52a1.92 1.92 0 11-.001 3.841 1.92 1.92 0 010-3.84z"
      ></path>
    </svg>
  )
}
