import React from 'react'

{/**
    props needed :
    className = string(optional)
    leftIcons = Component 
    title = string || component
    titleClass = string
    subTitle = string
    children action so can added nested component like right side filter buttons
*/}
export default function PageTitle(props) {
  return (
    <div className={`nex-heading-sec ${props.className || ''}`}>
        <div className='nex-heading-col-left'>
            {/* if any icon need to added before */}
            <h1 class={`nex-text-xxl ${props.titleClass || ''}`}>{props.leftIcons || ''}{props.title}</h1>
            {props.subTitle && 
                <p>{props.subTitle}</p>
            }
            
        </div>
        {props.children}
    </div>
  )
}
