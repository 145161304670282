import React, { useState } from "react"
import { Field, Formik } from 'formik';
import { Row, Col, Button, Spinner, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { withAlert } from "react-alert"
import * as Yup from 'yup';

import { InstitutionServices } from "utils/InstitutionServices"
import FormikInputField from "components/forms/formikFields/FormikInputField"
import { phoneRegExp } from 'utils/Constants'

const validationSchema = Yup.object().shape({
    institutionName: Yup.string().min(3).max(100).required("This field is required."),
    // noOfBed: Yup.number().positive().integer().typeError('Only numeric value.'),
    // icuBed: Yup.number().positive().integer().typeError('Only numeric value.'),
    streetAddress: Yup.string().min(3).max(100).required("This field is required"),
    streetAddress2: Yup.string().min(3).max(100).nullable(),
    location: Yup.string().min(3).max(50).nullable(),
    city: Yup.string().min(3).max(50).required("This field is required"),
    state: Yup.string().min(2).max(50).required("This field is required"),
    zipCode: Yup.number().typeError('Zipcode should be numeric only.').required("This field is required"),
    country: Yup.string().min(3).max(50).required("This field is required"),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("This field is required"),
    websiteAddress: Yup.string().url("Website url should be a valid."),
    aboutUs: Yup.string().min(3).max(255),
})

const initialValue = {
    institutionName: '',
    aboutUs: '',
    noOfBed: '',
    icuBed: '',
    streetAddress: '',
    streetAddress2: '',
    location: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    phone: '',
    websiteAddress: ''
}

const InstitutionForm = (props) => {
    const { toggle, practitionerId } = props;
    const [loading, setLoading] = useState(false)
    return (
        <Formik
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={initialValue}
            onSubmit={(values) => {
                setLoading(true);

                let payload = {
                    institutionName: values.institutionName,
                    aboutUs: values.aboutUs,
                    createdBy: practitionerId,
                    address: {
                        addressLine1: values.streetAddress,
                        addressLine2: values.streetAddress2,
                        district: values.location,
                        city: values.city,
                        state: values.state,
                        country: values.country,
                        pincode: values.zipCode
                    }
                }

                InstitutionServices.createInstitution(payload)
                    .then(response => {
                        const { data } = response
                        if (data.status === "SUCCESS") {
                            toggle()
                        }
                        setLoading(false)
                    })
            }}
            children={({ values, toggle, isValid, dirty, handleSubmit, isLoading }) => {
                return (
                    <form className="my-form" onSubmit={handleSubmit}>
                        <div>
                            <Row>
                                <Col xs={12}>
                                    <Field
                                        id="nim_institutionName"
                                        name="institutionName"
                                        type="text"
                                        label="Institution Name"
                                        component={FormikInputField}
                                        placeholder="Institution Name"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} xs={12}>
                                    <Field
                                        id="nim_streetAddress"
                                        name="streetAddress"
                                        type="text"
                                        label="Street Address"
                                        component={FormikInputField}
                                        placeholder="Street Address"
                                    />
                                </Col>
                                <Col sm={6} xs={12}>
                                    <Field
                                        id="nim_streetAddress2"
                                        name="streetAddress2"
                                        type="text"
                                        label="Street Address2"
                                        component={FormikInputField}
                                        placeholder="Street Address2"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} xs={12}>
                                    <Field
                                        id="nim_city"
                                        name="city"
                                        type="text"
                                        label="City"
                                        component={FormikInputField}
                                        placeholder="City"
                                    />
                                </Col>
                                <Col sm={6} xs={12}>
                                    <Field
                                        id="nim_state"
                                        name="state"
                                        type="text"
                                        label="State"
                                        component={FormikInputField}
                                        placeholder="State"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} xs={12}>
                                    <Field
                                        id="nim_country"
                                        name="country"
                                        type="text"
                                        label="Country"
                                        component={FormikInputField}
                                        placeholder="Country"
                                    />
                                </Col>
                                <Col sm={6} xs={12}>
                                    <Field
                                        id="nim_zipCode"
                                        name="zipCode"
                                        type="text"
                                        label="Zipcode"
                                        component={FormikInputField}
                                        placeholder="Zipcode"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} xs={12}>
                                    <Field
                                        id="nim_location"
                                        name="location"
                                        type="text"
                                        label="Location"
                                        component={FormikInputField}
                                        placeholder="Location"
                                    />
                                </Col>
                                <Col sm={6} xs={12}>
                                    <Field
                                        id="nim_phone"
                                        name="phone"
                                        type="text"
                                        label="Phone Number"
                                        component={FormikInputField}
                                        placeholder="Phone Number"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Field
                                        id="nim_websiteAddress"
                                        name="websiteAddress"
                                        type="text"
                                        label="Website Address"
                                        component={FormikInputField}
                                        placeholder="Website Address"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Field
                                        id="nim_aboutUs"
                                        name="aboutUs"
                                        type="textarea"
                                        label="About (optional)"
                                        rows={4}
                                        cols={5}
                                        component={FormikInputField}
                                        placeholder="Type something about your institution..."
                                    />
                                </Col>
                            </Row>
                        </div>
                        <hr className="profile-info-modal-hr" />
                        <Row className="modal-save-main justify-content-center mt-4 mb-2">
                            <Col xs="auto">
                                {isLoading ?
                                    <div className="loading-spiner"><Spinner style={{ width: '2rem', height: '2rem' }} /></div>
                                    :
                                    <Button id="nim_btn_submit" className="modal-save-btn" disabled={!dirty} color="primary" type="submit">Save</Button>
                                }
                            </Col>
                        </Row>
                    </form>
                )
            }}
        >
        </Formik>
    )
}

const NewInstitutionModal = (props) => {
    const { isOpen, toggle, closeAll } = props

    return (
        <Modal isOpen={isOpen} toggle={toggle} onClosed={closeAll ? toggle : undefined} id="profile-modal" className="modal-dialog-scrollable modal-dialog-centered">
            <ModalHeader toggle={toggle}>Add New Institution</ModalHeader>
            <ModalBody>
                <InstitutionForm {...props} />
            </ModalBody>
        </Modal>
    )
}

export default withAlert()(NewInstitutionModal);