import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFeed,
  getFeedCreator,
  SugesstedConn,
} from "../../redux-helpers/FeedActionCreator";
import Header from "./components/Header/header";
// import SideBar from "./components/SideBar/Sidebar"

import FeedContent from "./components/FeedContent/FeedContent";

const FeedHomePage = ({ message, channel, timeToken }) => {
  const [pageNum, setPageNum] = useState(0);

  let practitionerId = useSelector(
    (state) => state.auth.currentUser.practitionerId
  );

  let dispatch = useDispatch();

  // useEffect(() => {
  //   // dispatch(getFeedCreator(practitionerId, 0));
  //   dispatch(getFeed(practitionerId, 0));
  //   dispatch(SugesstedConn());
  // }, []);

  // const page_number = () => {
  //   dispatch(getFeedCreator(practitionerId, pageNum + 1));
  //   setPageNum(pageNum + 1);
  // };

  useEffect(() => {
    document.body.classList.add('body-profile-page');
    document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "Home";
    return () => {
      document.body.classList.remove('body-profile-page');
      document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "";
    }
}, [])

  return (
    <div>
      <div className="admin-home-page">
        <FeedContent
          // change_page={page_number}
          message={message}
          channel={channel}
          timeToken={timeToken}
        />
      </div>
    </div>
  );
};

export default FeedHomePage;
