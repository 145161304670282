import JobWidget from "common/JobWidget";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Row } from "reactstrap";
import { About } from "./components/About";
import Gallery from "./components/Gallery";
import Branches from "./components/Branches";
import HospitalTimings from "./components/HospitalTimings";
import InstitutionsAwards from "./components/InstitutionsAwards";
import { Services } from "./components/Services";
import SocialMedia from "./components/SocialMedia";
import Specialities from "./components/Specialities";

import website_link_icon from "assets/svgIcon/website-link-icon.svg";
import direction_icon from "assets/svgIcon/direction-icon.svg";
import phone_call_icon from "assets/svgIcon/phone-call-icon.svg";
import PractitionersContent from "./components/Practitioners";
import { useRef } from "react";
import scroller_arrow from "assets/svgIcon/scroller-arrow-right.svg";
import branch_image from "assets/images/branches-dummy-img.png";
import map_image from "assets/images/branches-map-img.png";
import graduation_cap_icon from "assets/images/graduation-cap.png";
import suitcase_icon from "assets/images/suitcase.png";
import pin_small_icon from "assets/images/pin-small.png";
import doctor_profile_img from "assets/images/doctor-profile-img.png";
import { isAdmin } from "utils/UserHelper";

const InstitutionsDetail = (props) => {
  const history = useHistory();
  const { isEditable, institution } = props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  const globalInstituteProfileInfo = useSelector(
    (state) => state.GlobalInstituteProfileReducer.globalInstituteInfo
  );


  /* tabs */
  const [showAboutOnTabScroll, setShowAboutOnTabScroll] = useState(false);
  const [showSpecialitiesOnTabScroll, setShowSpecialitiesOnTabScroll] = useState(false);
  const [showGalleryOnTabScroll, setShowGalleryOnTabScroll] = useState(false);
  const [showHospitalTimingsOnTabScroll, setShowHospitalTimingsOnTabScroll] = useState(false);
  const [showServicesOnTabScroll, setShowServicesOnTabScroll] = useState(false);
  const [showBranchesOnTabScroll, setShowBranchesOnTabScroll] = useState(false);
  const [showPractitionersContentOnTabScroll, setShowPractitionersContentOnTabScroll] = useState(false);
  const [showAwardsOnTabScroll, setShowAwardsOnTabScroll] = useState(false);
  const [tabScrolCus, setTabScrolCus] = useState(0);
  const [tabScrolhas, setTabScrolhas] = useState(false);
  const tabscrollRef = useRef(null);
  const [scrollelm, setScrollelm] = useState(false);

  const [activeTab, setActiveTab] = useState('aboutSection');
  const [tabScrollIsEnd, setTabScrollIsEnd] = useState(false);
  const [tabScrollIsStart, setTabScrollIsStart] = useState(true);


  function handleTabScroll__(label) {
    const cw = tabscrollRef.current.clientWidth;
    const sw = tabscrollRef.current.scrollWidth;
    let cal_w = tabScrolCus;

    if (label == 'next') {
      cal_w = cal_w > sw ? cal_w : cal_w + cw;
    } else {
      cal_w = cal_w <= 0 ? 0 : cal_w - cw;
    }

    setTabScrolCus(cal_w);
    if ((sw - cal_w) > (cw - 1)) {
      setTabScrolhas(true);
    } else {
      setTabScrolhas(false);
    }

  }

  function handleTabScroll(label) {
    let scroll_elm = document.querySelectorAll('.nexogic-profile-quick-link .profile-quick-scroller')[0];
    if (label == 'next') {
      scroll_elm.scrollLeft += 200;
    } else {
      if (scroll_elm.scrollLeft !== 0) {
        scroll_elm.scrollLeft -= 200;
      }
    }

  }

  useEffect(() => {
    const handleScroll = () => {
      setScrollelm(window.scrollY > 10);
    };

    // Add the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the scroll event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array means this useEffect runs only on mount and unmount

  useEffect(() => {
    tabScrollInit();
  }, [
    showAboutOnTabScroll,
    showSpecialitiesOnTabScroll,
    showGalleryOnTabScroll,
    showHospitalTimingsOnTabScroll,
    showServicesOnTabScroll,
    showBranchesOnTabScroll,
    showPractitionersContentOnTabScroll,
    showAwardsOnTabScroll,
  ]);

  useEffect(() => {
    tabScrollInit();
  }, []);

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     setScrollelm(window.scrollY > 10);
  //   });
  //   tabScrollInit();

  // }, [showAboutOnTabScroll,
  //   showSpecialitiesOnTabScroll,
  //   showGalleryOnTabScroll,
  //   showHospitalTimingsOnTabScroll,
  //   showServicesOnTabScroll,
  //   showBranchesOnTabScroll,
  //   showPractitionersContentOnTabScroll,
  //   showAwardsOnTabScroll,

  // ]);

  function tabScrollInit() {
    const cw = tabscrollRef.current.clientWidth;
    const sw = tabscrollRef.current.scrollWidth;
    setTabScrolhas(sw > cw);
  }


  /* get header height  */
  useEffect(() => {
    let scroll_elm = document.querySelectorAll('.nexogic-profile-quick-link .profile-quick-scroller')[0];
    setTimeout(() => {
      scroll_elm.scrollLeft = 0;
    }, 100);
    //console.log(scroll_elm);
    tabHandleResize();
    tabScrollHandle();
    window.addEventListener("resize", tabHandleResize);
    scroll_elm.addEventListener("scroll", tabScrollHandle);
    return () => {
      window.removeEventListener("resize", tabHandleResize);
      scroll_elm.removeEventListener("scroll", tabScrollHandle);
    };

  }, [showAboutOnTabScroll,
    showSpecialitiesOnTabScroll,
    showGalleryOnTabScroll,
    showHospitalTimingsOnTabScroll,
    showServicesOnTabScroll,
    showBranchesOnTabScroll,
    showPractitionersContentOnTabScroll,
    showAwardsOnTabScroll
  ]);

  const tabHandleResize = () => {
    let header = document.querySelectorAll('.nexogic-header-card')[0];
    tabscrollRef.current.style.setProperty('--top_gap', header.offsetHeight + 'px');
  };


  const tabScrollHandle = () => {
    let scroll_elm = document.querySelectorAll('.nexogic-profile-quick-link .profile-quick-scroller')[0];
    let end_scroll_val = scroll_elm.scrollWidth - scroll_elm.clientWidth;
    let scroll_val = scroll_elm.scrollLeft;
    setTabScrollIsEnd(scroll_val < end_scroll_val);
    setTabScrollIsStart(scroll_val > 1);

    //console.log(scroll_val < end_scroll_val, scroll_val > 1);

  };

  if (!isEditable && isEmpty(currentUser)) {
    return (
      <Card className="rounded-0 border-0 shadow p-3">
        <Row className="py-5 my-4">
          <Col xs="12">
            <p className="text-primary font-weight-bold font-italic">
              {" "}
              Please Sign In to access the full profile.
            </p>
            <Button color="primary" onClick={() => history.push("/login")}>
              {"Login"}
            </Button>
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <>

      <Row>
        <Col xl={9} className="nexogic-profile-details-left" >
          {props.children}
          {/** profile-quick-link */}
          <div className={`nexogic-profile-quick-link ${tabScrollIsStart ? 'prev_on' : ''} ${tabScrollIsEnd ? 'next_on' : ''}`} ref={tabscrollRef}>
            <div className={`scroller-arrow-left ${tabScrollIsStart ? 'on' : ''}`} ><img src={scroller_arrow} alt="" onClick={(e) => handleTabScroll('prev')} /></div>
            <div className={`scroller-arrow-right ${tabScrollIsEnd ? 'on' : ''}`} ><img src={scroller_arrow} alt="" onClick={(e) => handleTabScroll('next')} /></div>

            {/** style={{ 'transform': 'translateX(-' + tabScrolCus + 'px)' }} */}
            <ul className="profile-quick-scroller" >
              {showAboutOnTabScroll && <li><a href="#aboutSection" className={activeTab === 'aboutSection' ? 'active' : ''} onClick={() => setActiveTab('aboutSection')}>About </a></li>}
              {showGalleryOnTabScroll && <li><a href="#gallerySection" className={activeTab === 'gallerySection' ? 'active' : ''} onClick={() => setActiveTab('gallerySection')}>Gallery </a></li>}
              {showHospitalTimingsOnTabScroll && <li><a href="#hospitalTimingsSection" className={activeTab === 'hospitalTimingsSection' ? 'active' : ''} onClick={() => setActiveTab('hospitalTimingsSection')}>Business Hours </a></li>}
              {showSpecialitiesOnTabScroll && <li><a href="#specialitiesSection" className={activeTab === 'specialitiesSection' ? 'active' : ''} onClick={() => setActiveTab('specialitiesSection')}>Specialities </a></li>}
              {showServicesOnTabScroll && <li><a href="#servicesSection" className={activeTab === 'servicesSection' ? 'active' : ''} onClick={() => setActiveTab('servicesSection')}>Services </a></li>}
              {showBranchesOnTabScroll && <li><a href="#branchesSection" className={activeTab === 'branchesSection' ? 'active' : ''} onClick={() => setActiveTab('branchesSection')}>Branches </a></li>}
              {showPractitionersContentOnTabScroll && <li><a href="#practitionersContentSection" className={activeTab === 'practitionersContentSection' ? 'active' : ''} onClick={() => setActiveTab('practitionersContentSection')}>Practitioners </a></li>}
              {showAwardsOnTabScroll && <li><a href="#awardsSection" className={activeTab === 'awardsSection' ? 'active' : ''} onClick={() => setActiveTab('awardsSection')}>Awards </a></li>}
            </ul>
          </div>
          {/* <div className="position-relative mb-3 mt-4">
        <ProfileCompleteness {...props} />
      </div> */}
          {/* <pre>{JSON.stringify(jobListInfo, null, 4)}</pre> */}
          <div className="position-relative" id="aboutSection">
            <About {...props} setShowAboutOnTabScroll={setShowAboutOnTabScroll} />
          </div>
          <div className="position-relative" id="gallerySection">
            <Gallery {...props} setShowGalleryOnTabScroll={setShowGalleryOnTabScroll} />
          </div>
          <div className="position-relative " id="hospitalTimingsSection">
            <HospitalTimings {...props} setShowHospitalTimingsOnTabScroll={setShowHospitalTimingsOnTabScroll} />
          </div>
          {/*  <div className="position-relative ">
        <HospitalInformation {...props} />
      </div> */}
          {/*  <div className="position-relative ">
        <InstitutionsContatcts {...props} />
      </div> */}
          <div className="position-relative" id="specialitiesSection">
            <Specialities {...props} setShowSpecialitiesOnTabScroll={setShowSpecialitiesOnTabScroll} />
          </div>
          <div className="position-relative" id="servicesSection">
            <Services {...props} setShowServicesOnTabScroll={setShowServicesOnTabScroll} />
          </div>
          <div className="position-relative" id="branchesSection">
            <Branches {...props} setShowBranchesOnTabScroll={setShowBranchesOnTabScroll} />
          </div>
          <div className="position-relative " id="practitionersContentSection">
            <PractitionersContent {...props} setShowPractitionersContentOnTabScroll={setShowPractitionersContentOnTabScroll} />
          </div>
          <div className="position-relative " id="awardsSection">
            <InstitutionsAwards {...props} setShowAwardsOnTabScroll={setShowAwardsOnTabScroll} />
          </div>
          {/* <div className="position-relative ">
        <News {...props} />
      </div> */}
          {/** new html for branches  */}
          {/* <div className="nex-main-panel">
            <div className="nex-panel-head">
              <h1 className="nex-text-xl"> <span className="btn"><svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.125 2L1.125 10L9.125 18" stroke="black" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              </span> Our Branches <span className="nex-panel-action"><a className="btn nex-btn-primary-outline sm">Add More</a></span></h1>
            </div>
            <div className="nex-panel-body">
              <div className="nex-icon-list">
                <div className="graphic">
                  <img src={branch_image} alt="" className="nex-avatar lg" />
                </div>
                <div className="info">
                  <h2 className="nex-text-xl small">Yashoda Hospitals, Kukatpally </h2>
                  <p className="nex-text"><span className="icon"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.73689 16.3308C12.7632 14.7876 15.125 12.2321 15.125 9.125C15.125 5.81129 12.4387 3.125 9.125 3.125C5.81129 3.125 3.125 5.81129 3.125 9.125C3.125 12.2321 5.48681 14.7876 8.5131 16.3308C8.89759 16.5268 9.35241 16.5268 9.73689 16.3308Z" stroke="black" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11.375 9.125C11.375 10.3676 10.3676 11.375 9.125 11.375C7.88236 11.375 6.875 10.3676 6.875 9.125C6.875 7.88236 7.88236 6.875 9.125 6.875C10.3676 6.875 11.375 7.88236 11.375 9.125Z" stroke="black" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  </span> Yashoda Hospitals, KPHB, Near JNTU Bus stop. Opposite to Malabar Golds,
                    Kukatpally, Hyderabad 500083</p>
                </div>
                <div className="action">
                  <a href="" className="btn nex-btn-map">
                    <span>View on Maps</span>
                    <img src={map_image} alt="" />
                  </a>
                </div>
              </div>
              <div className="nex-icon-list">
                <div className="graphic">
                  <img src={branch_image} alt="" className="nex-avatar lg" />
                </div>
                <div className="info">
                  <h2 className="nex-text-xl small">Yashoda Hospitals, Kukatpally </h2>
                  <p className="nex-text"><span className="icon"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.73689 16.3308C12.7632 14.7876 15.125 12.2321 15.125 9.125C15.125 5.81129 12.4387 3.125 9.125 3.125C5.81129 3.125 3.125 5.81129 3.125 9.125C3.125 12.2321 5.48681 14.7876 8.5131 16.3308C8.89759 16.5268 9.35241 16.5268 9.73689 16.3308Z" stroke="black" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11.375 9.125C11.375 10.3676 10.3676 11.375 9.125 11.375C7.88236 11.375 6.875 10.3676 6.875 9.125C6.875 7.88236 7.88236 6.875 9.125 6.875C10.3676 6.875 11.375 7.88236 11.375 9.125Z" stroke="black" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  </span> Yashoda Hospitals, KPHB, Near JNTU Bus stop. Opposite to Malabar Golds,
                    Kukatpally, Hyderabad 500083</p>
                </div>
                <div className="action">
                  <a href="" className="btn nex-btn-map">
                    <span>View on Maps</span>
                    <img src={map_image} alt="" />
                  </a>
                </div>
              </div>
              <div className="nex-icon-list">
                <div className="graphic">
                  <img src={branch_image} alt="" className="nex-avatar lg" />
                </div>
                <div className="info">
                  <h2 className="nex-text-xl small">Yashoda Hospitals, Kukatpally </h2>
                  <p className="nex-text"><span className="icon"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.73689 16.3308C12.7632 14.7876 15.125 12.2321 15.125 9.125C15.125 5.81129 12.4387 3.125 9.125 3.125C5.81129 3.125 3.125 5.81129 3.125 9.125C3.125 12.2321 5.48681 14.7876 8.5131 16.3308C8.89759 16.5268 9.35241 16.5268 9.73689 16.3308Z" stroke="black" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11.375 9.125C11.375 10.3676 10.3676 11.375 9.125 11.375C7.88236 11.375 6.875 10.3676 6.875 9.125C6.875 7.88236 7.88236 6.875 9.125 6.875C10.3676 6.875 11.375 7.88236 11.375 9.125Z" stroke="black" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  </span> Yashoda Hospitals, KPHB, Near JNTU Bus stop. Opposite to Malabar Golds,
                    Kukatpally, Hyderabad 500083</p>
                </div>
                <div className="action">
                  <a href="" className="btn nex-btn-map">
                    <span>View on Maps</span>
                    <img src={map_image} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          {/** new html for Practitioners  */}
          {/* <div className="nex-main-panel">
            <div className="nex-panel-head">
              <h1 className="nex-text-xl"> <span className="btn"><svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.125 2L1.125 10L9.125 18" stroke="black" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              </span> Practitioners who work here <span className="nex-panel-action"><a className="btn nex-btn-primary-outline sm">Add More</a></span></h1>
            </div>
            <div className="nex-panel-body">
              <div className="nex-icon-list">
                <div className="graphic">
                  <img src={doctor_profile_img} alt="" className="nex-avatar lg" />
                </div>
                <div className="info">
                  <h2 className="nex-text-xl small">Dr. Surya Prakash Rao <span className="nex-icon"><svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.875 5.25L9.875 2.25L15.875 5.25C15.875 9.14492 13.7868 14.6323 9.875 15.75C5.96321 14.6323 3.875 9.14492 3.875 5.25Z" fill="#34EA72" />
                    <path d="M12.5 7.125L9.125 10.5L8 9.375M9.875 2.25L3.875 5.25C3.875 9.14492 5.96321 14.6323 9.875 15.75C13.7868 14.6323 15.875 9.14492 15.875 5.25L9.875 2.25Z" stroke="#257C6D" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  </span> <span className="nex-icon"><svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.5253 6.12686V9.28906M11.9442 7.70796H15.1064M5.93601 10.2377H10.9955C12.3927 10.2377 13.5253 11.3703 13.5253 12.7675V14.0324H3.40625V12.7675C3.40625 11.3703 4.53886 10.2377 5.93601 10.2377ZM10.9955 5.1782C10.9955 6.57535 9.86292 7.70796 8.46577 7.70796C7.06862 7.70796 5.93601 6.57535 5.93601 5.1782C5.93601 3.78105 7.06862 2.64844 8.46577 2.64844C9.86292 2.64844 10.9955 3.78105 10.9955 5.1782Z" stroke="#46ABC9" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                    </span> </h2>
                  <ul className="nex-inline-list">
                    <li><a href="#">Cardiology</a></li>
                    <li><a href="#">Radiology</a></li>
                  </ul>
                  <p className="nex-text"><span className="nex-icon xs"><img src={graduation_cap_icon} alt="" /></span> BAMS</p>
                  <p className="nex-text"><span className="nex-icon xs"><img src={suitcase_icon} alt="" /></span> 23+ Experience</p>
                  <p className="bold text-black mt-2"><span className="nex-icon xs"><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.61189 16.3308C12.6382 14.7876 15 12.2321 15 9.125C15 5.81129 12.3137 3.125 9 3.125C5.68629 3.125 3 5.81129 3 9.125C3 12.2321 5.36181 14.7876 8.3881 16.3308C8.77259 16.5268 9.22741 16.5268 9.61189 16.3308Z" stroke="black" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11.25 9.125C11.25 10.3676 10.2426 11.375 9 11.375C7.75736 11.375 6.75 10.3676 6.75 9.125C6.75 7.88236 7.75736 6.875 9 6.875C10.2426 6.875 11.25 7.88236 11.25 9.125Z" stroke="black" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  </span> Hyderabad, Telengana, India</p>

                </div>
                <div className="action">
                  <a href="" className="btn nex-btn-primary">View Profile</a>
                </div>
              </div>
              <div className="nex-icon-list">
                <div className="graphic">
                  <img src={doctor_profile_img} alt="" className="nex-avatar lg" />
                </div>
                <div className="info">
                  <h2 className="nex-text-xl small">Dr. Surya Prakash Rao <span className="nex-icon"><svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.875 5.25L9.875 2.25L15.875 5.25C15.875 9.14492 13.7868 14.6323 9.875 15.75C5.96321 14.6323 3.875 9.14492 3.875 5.25Z" fill="#34EA72" />
                    <path d="M12.5 7.125L9.125 10.5L8 9.375M9.875 2.25L3.875 5.25C3.875 9.14492 5.96321 14.6323 9.875 15.75C13.7868 14.6323 15.875 9.14492 15.875 5.25L9.875 2.25Z" stroke="#257C6D" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  </span> <span className="nex-icon"><svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.5253 6.12686V9.28906M11.9442 7.70796H15.1064M5.93601 10.2377H10.9955C12.3927 10.2377 13.5253 11.3703 13.5253 12.7675V14.0324H3.40625V12.7675C3.40625 11.3703 4.53886 10.2377 5.93601 10.2377ZM10.9955 5.1782C10.9955 6.57535 9.86292 7.70796 8.46577 7.70796C7.06862 7.70796 5.93601 6.57535 5.93601 5.1782C5.93601 3.78105 7.06862 2.64844 8.46577 2.64844C9.86292 2.64844 10.9955 3.78105 10.9955 5.1782Z" stroke="#46ABC9" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                    </span></h2>
                  <ul className="nex-inline-list">
                    <li><a href="#">Cardiology</a></li>
                    <li><a href="#">Radiology</a></li>
                  </ul>
                  <p className="nex-text"><span className="nex-icon xs"><img src={graduation_cap_icon} alt="" /></span> BAMS</p>
                  <p className="nex-text"><span className="nex-icon xs"><img src={suitcase_icon} alt="" /></span> 23+ Experience</p>
                  <p className="bold text-black mt-2"><span className="nex-icon xs"><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.61189 16.3308C12.6382 14.7876 15 12.2321 15 9.125C15 5.81129 12.3137 3.125 9 3.125C5.68629 3.125 3 5.81129 3 9.125C3 12.2321 5.36181 14.7876 8.3881 16.3308C8.77259 16.5268 9.22741 16.5268 9.61189 16.3308Z" stroke="black" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11.25 9.125C11.25 10.3676 10.2426 11.375 9 11.375C7.75736 11.375 6.75 10.3676 6.75 9.125C6.75 7.88236 7.75736 6.875 9 6.875C10.2426 6.875 11.25 7.88236 11.25 9.125Z" stroke="black" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  </span> Hyderabad, Telengana, India</p>
                </div>
                <div className="action">
                  <a href="" className="btn nex-btn-primary">View Profile</a>
                </div>
              </div>

            </div>
          </div> */}

        </Col>
        <Col xl={3} className="position-relative nex-sidebar-right">
          {/*<div className="card nexogic-widget-sidebar-card contact-card">
            <div className="card-body">
              {institution?.websiteURL &&
                <div className="item">
                  <a href={institution?.websiteURL} target="_blank" className="text-ellipsis">{institution?.websiteURL}</a>
                  <a href={institution?.websiteURL} target="_blank" className="icon"><img src={website_link_icon} alt="" /></a>
                </div>
              }
              {institution?.phoneContacts.length > 0 &&
                <div className="item phone">
                  <div >
                    {institution?.phoneContacts.map((phone_info, ind) => (

                      <a key={'rs-phone-key-' + ind} href={`tel:${phone_info.value}`}>{phone_info.value} <span>{phone_info.type}</span></a>
                    ))}
                    <span className="icon"><img src={phone_call_icon} alt="" /></span>
                  </div>

                </div>
              }
              {institution?.address &&

                <div className="item ">
                  <div className="address">
                    <p><a target="_blank" href={`https://maps.google.com/?q=${institution?.address?.addressLine1 ? institution?.address?.addressLine1 : ''}${institution?.address?.addressLine2 ? ", " + institution?.address?.addressLine2 : ''}${institution?.address?.city ? ", " + institution?.address?.city : ''}${institution?.address?.country ? ", " + institution?.address?.country : ''}${institution?.address?.postalCode ? ", " + institution?.address?.postalCode : ''}`} className="">Get Directions</a></p>
                    <p> {institution?.address?.addressLine1 && <span>{institution?.address?.addressLine1}</span>}
                      {institution?.address?.addressLine2 && <span>{institution?.address?.addressLine2}</span>}
                      {institution?.address?.city && <span>{institution?.address?.city}</span>}
                      {institution?.address?.country && <span>{institution?.address?.country}</span>}
                      {institution?.address?.postalCode && <span>{institution?.address?.postalCode}</span>}
                    </p></div>
                  <span className="icon"><img src={direction_icon} alt="" /></span>
                </div>
              }
              <div className="card-group nexogic-card-group-list">
                <SocialMedia {...props} />
              </div>

            </div>
          </div>*/}

          <JobWidget widgetTitle="Jobs" {...props} />
        </Col>
      </Row>
    </>
  );
};

export default InstitutionsDetail;
