import React from 'react'
import "./css/invoice1.scss";
import HtmlStringToPdf from '../SecondOpinion/components/EmailPreview/HtmlStringToPdf';

import logo  from 'assets/images/logo.png'

const InvoiceThree = () => {
  return (
    <>
      <div className='text-center'>
        <HtmlStringToPdf target="#invoice-pdf-three" openAs="download">Download Invoice 3 as pdf </HtmlStringToPdf>
      </div>
      <div className="pdf_wraper pdf-invoice-w v1" id='invoice-pdf-three' style={{ transformOrigin: 'top left', width: '595px', height:'841px', margin: '0 auto'}}>
      <div className="invoice-w">
            <div className="invoice-head">
                <div className="col1">
                    <div className="invoice-txt">Invoice</div>
                    <div className="billing-adrs">
                        <div className="label">Bill To : </div>
                        <div className="val">
                            <p>[Sample Patient Name ]</p>
                            <p className="small">[Sample Patient Address line 1]</p>
                        </div>
                    </div>
                </div>
                <div className="col2">
                    <div className="logo"><img src="" alt="Logo" /></div>
                    <div className="invoice-details">
                        <p>INVOICE DETAILS:</p>
                        <p><span className="key">Invoice#</span><span className="val">0000</span></p>
                    </div>
                    
                </div>
                
            </div>
            <div className="invoice-body">
                

                <div className="tbl">
                    <table className="invoice-tbl">
                        <tr>
                            <th>Description</th>
                            <th>Quantity</th>
                            <th>Unit price</th>
                            <th>Amount</th>
                        </tr>
                        <tr>
                            <td>Medical Materials</td>
                            <td>1</td>
                            <td>Rs. 100 </td>
                            <td>Rs. 100</td>
                        </tr>
                        <tr>
                            <td>Medical Services</td>
                            <td>1</td>
                            <td>Rs. 20 </td>
                            <td>Rs. 20</td>
                        </tr>
                        <tr>
                            <td>Product name</td>
                            <td>1</td>
                            <td>Rs. 0 </td>
                            <td>Rs. 0</td>
                        </tr>
                        <tr className="total">
                            <td></td>
                            <td></td>
                            <td>Total </td>
                            <td>Rs. 120</td>
                        </tr>
                    </table>
                </div>
                <div className="tbl-terms-w">
                    <table className="tbl-terms">
                        <tr>
                            <td>TERMS</td>
                            <td width="150"></td>
                            <td width="150"></td>
                        </tr>
                        <tr>
                            <td className="normal">Some text</td>
                            <td>Subtotal</td>
                            <td>$00.00</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Discount</td>
                            <td>-$00.00</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Tax Rate</td>
                            <td>5%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Tax</td>
                            <td>$00.00</td>
                        </tr>
                        <tr className="last">
                            <td></td>
                            <td>TOTAL</td>
                            <td>$00.00</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className="invoice-footer">
                <p>CONDITIONS/INSTRUCTIONS</p>
                <p>Text Here</p>
            </div>
        </div>
        <div className="invoice-footer-bot">
            <table className="ifb-tbl">
                <tr>
                    <td width="300">
                        <table>
                            <tr>
                                <td>
                                    <h2>Business Name</h2>
                                    <p>Street Address Line 01</p>
                                    <p>Street Address Line 02</p>
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td width="200">
                        <table>
                            <tr>
                                <td>
                                    <h2>+1 (999)-999-9999</h2>
                                    <p>Email Address</p>
                                    <p>Website</p>
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td className="last-col">
                        <img src="" alt="Logo" className="logo" />
                    </td>
                </tr>
            </table>
        </div>
      </div>
    </>
  )
}



export default InvoiceThree;