import logo_white from "assets/images/logo-white-transparent.png";
import google_img from "assets/images/social-google-img.png";
import facebook_img from "assets/images/facebook-social-img.png";
import apple_img from "assets/images/apple-social-img.png";
import arrow_back from "assets/images/nex-arrow-back.svg";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Field, Formik } from "formik";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import * as Yup from "yup";
import FormikMobileField from "components/forms/formikFields/FormikMobileField";
const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
  });

const PatientMobileValidation = (props) => {
    const initValues = useMemo(() => {
        return {
          phone: "",
        };
      }, []);
    const [isSubmitting, setSubmitting] = useState(false);
    const [initialValues, setInitialValues] = useState(initValues);
    
      const onSubmit = (values) => {
        console.log(values);
        if(!isEmpty(values.name)){
            setSubmitting(true);
            console.log(values);
        }
        
      };
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <div className="nex-bg-light-blue">
            <main className="nex-login-register--w" >
                <div className="container sm d-flex" data-aos="fade-up">
                    <div className="nex-form-aside" >
                        <div className="logo" data-aos="fade-down" data-aos-duration="800" data-aos-delay="800">
                            <a href="/"><img src={logo_white} alt="" /></a>
                        </div>
                        
                        <h2 className="nex-text-xxl nex-underline" data-aos="fade-down" data-aos-duration="1200" data-aos-delay="800">Verify your
mobile number</h2>
                        
                    </div>
                    <div className="nex-form-fields--w">
                        <div className="nex-form-top-slot" data-aos="fade-up" data-aos-duration="800" data-aos-delay="800">
                            <div className="nex-form-top-actions nex-text-lg d-none d-lg-flex">
                                <a href="#" className="text-gray nex-back-btn"><img src={arrow_back} alt="" /></a>
                                <a href="#" className="text-gray nex-cross-btn">&times;</a>
                            </div>
                        </div>
                        <div className="nex-form-center-slot">
                        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
          children={(props) => {
            return (
              <>
                <RenderForm
                  {...props}
                  isSubmitting={isSubmitting}
                />
              </>
            );
          }}
        />
                            
                        </div>
                        <div className="nex-form-bottom-slot">
                            <p className="nex-text-lg text-center">Are you a member? <a href="#" className="nex-link-dark">Login</a></p>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default  PatientMobileValidation;

const RenderForm = (props) => {
    const {
        values,
        errors,
        isSubmitting,
        handleSubmit,
      } = props;
  
    return (
      <div>
        <form className="new-doctor-register-form" onSubmit={handleSubmit}>
            <div className="nex-form-container">
                
                <div className="form-group-fields--w" data-aos="fade-down" data-aos-duration="800" data-aos-delay="1400">
                    <div className="form-group">
                        <label className="nex-text-lg">Enter your phone number to verify. We'll send an SMS to verify your phone number</label>
                    </div>
                    <div className="form-group">
                        
                        <Field
                            id="phone"
                            name="phone"
                            type="text"
                            label=""
                            component={FormikMobileField}
                            placeholder="Please enter your mobile number"
                            autoComplete="off"
                        />
                        
                    </div>
                    <div className="form-group">
                        <input type="submit" value="Continue" className="btn btn-primary w-100" />
                    </div>
                </div>
            </div>
        </form>
      </div>
    );
  };