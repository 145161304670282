import React from 'react'

export default function NotificationDetails() {
  return (
    <div className="nexogic-form-check-group mb-4">
                  <h3 className="card-title light">Notifications Center</h3>
                  <p className='card-text mb-3'><small>Welcome to your notification center. Just check/uncheck which emails and text messages you like to receive</small></p>

                  <h3 className="card-title light">Email</h3>
                  <div className="form-check checkbox mb-3">
                    <input className="form-check-input" type="checkbox" value="" id="check1" />
                    <label className="form-check-label ml-1" htmlFor="check1">
                    Healthcare reminders
                    </label>
                    <p className='card-text text-muted ml-1'><small>Reminders to help you stay on top of preventative care appointments</small></p>
                  </div>

                  <div className="form-check checkbox mb-3">
                    <input className="form-check-input" type="checkbox" value="" id="check1" />
                    <label className="form-check-label ml-1" htmlFor="check1">
                    Blog newsletters
                    </label>
                    <p className='card-text text-muted ml-1'><small>Our latest posts to our blog, Paper Gown</small></p>
                  </div>

                  <div className="form-check checkbox mb-3">
                    <input className="form-check-input" type="checkbox" value="" id="check1" />
                    <label className="form-check-label ml-1" htmlFor="check1">
                    Product news
                    </label>
                    <p className='card-text text-muted ml-1'><small>Getting started, new features and latest product updates from Nexogic</small></p>
                  </div>

                  <div className="form-check checkbox mb-3">
                    <input className="form-check-input" type="checkbox" value="" id="check1" />
                    <label className="form-check-label ml-1" htmlFor="check1">
                    Nexogic news and offers
                    </label>
                    <p className='card-text text-muted ml-1'><small>News, promotions, and events for you</small></p>
                  </div>

                  <div className="form-check checkbox mb-3">
                    <input className="form-check-input" type="checkbox" value="" id="check1" />
                    <label className="form-check-label ml-1" htmlFor="check1">
                    I no longer wish to receive any future marketing emails
                    </label>
                    <p className='card-text text-muted ml-1'><small>If you wish to unsubscribe from ALL marketing emails from Zocdoc, check the box and click the update button below

</small></p>
                  </div>

                  

                </div>
  )
}
