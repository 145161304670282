import {
  GET_ALL_FEEDS,
  GET_SUGGESTED_CONNECTION,
  GET_SHARE_FEED,
  HIDE_POST,
  RESET_ALL_FEEDS,
  GET_ALL,
} from "./Actions";
let initialState = {
  allFeeds: [],
  suggestedConn: [],
};

function HomePageReducer(state = initialState, action) {
  let actions =
    action.payload &&
    action.payload.data &&
    action.payload.data.feeds &&
    action.payload.data.feeds.content;
  switch (action.type) {
    case GET_ALL_FEEDS:
      return {
        ...state,
        allFeeds: [...state.allFeeds, ...actions],
      };
    case RESET_ALL_FEEDS: {
      return {
        ...state,
        allFeeds: [],
      };
    }
    case GET_SHARE_FEED:
      return {
        ...state,
        allFeeds: [...actions, ...state.allFeeds],
      };
    case HIDE_POST:
      let new_feed = state.allFeeds.filter(
        (feed) => feed?.id.toString() !== action?.id && action?.id.toString()
      );
      return {
        ...state,
        allFeeds: new_feed,
      };
    case GET_SUGGESTED_CONNECTION:
      return {
        ...state,
        suggestedConn: action.payload,
      };
    case GET_ALL:
      return {
        ...state,
        allFeeds: [...actions],
      };
    default:
      return state;
  }
}

export default HomePageReducer;
