import profile_filter_icon from "../../../../assets/images/filter_icon.png"
import CityAutocompleteTypeaheadField from "components/forms/formikFields/CityAutocompleteTypeaheadField";
import PrimarySpecialityTypeaheadField from "components/forms/formikFields/PrimarySpecialityTypeaheadField";
import { Field, Formik } from "formik";
import React, { memo, useEffect, useState } from "react";
import { Button, Col, Collapse, FormGroup, Row } from "reactstrap";
import { MetadataService } from "utils/MetadataService";
import * as Yup from "yup";

const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string(),
    city: Yup.array().nullable(),
    primarySpeciality: Yup.array().nullable(),
  });

const AdvancedSearch = (props) => {
  const { initialValues, setIsLoading, fetchProfileData, setTotalItems } = props;
  const [primarySpecialityList, setPrimarySpecialityList] = useState([]);
  const [showAdvanceFields, setShowAdvanceFields] = useState(false);
  const [advancedFieldToggle, setAdvancedFieldToggle] = useState(false);

  const getprimarySpeciality = () => {
    MetadataService.getPrimarySpecialities()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          // console.log(response.data);
          setPrimarySpecialityList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getprimarySpeciality error", error);
      });
  };

  const handleFilter = (values) => {
    setTotalItems(0);
    setIsLoading(true);
    fetchProfileData(values);
  };

  useEffect(() => {
    getprimarySpeciality();
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleFilter}
      children={(props) => {
        const { values, handleSubmit } = props;
        return (
          <>
            <div className="nex-filter-w">
              <div className="nex-filter-btn" onClick={() => setAdvancedFieldToggle(!advancedFieldToggle)}>
                Filter <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.3594 6.5625H1.64062C1.37955 6.5625 1.12917 6.46373 0.944567 6.28791C0.759961 6.1121 0.65625 5.87364 0.65625 5.625C0.65625 5.37636 0.759961 5.1379 0.944567 4.96209C1.12917 4.78627 1.37955 4.6875 1.64062 4.6875H19.3594C19.6204 4.6875 19.8708 4.78627 20.0554 4.96209C20.24 5.1379 20.3438 5.37636 20.3438 5.625C20.3438 5.87364 20.24 6.1121 20.0554 6.28791C19.8708 6.46373 19.6204 6.5625 19.3594 6.5625ZM16.0781 10.9375H4.92188C4.6608 10.9375 4.41042 10.8387 4.22582 10.6629C4.04121 10.4871 3.9375 10.2486 3.9375 10C3.9375 9.75136 4.04121 9.5129 4.22582 9.33709C4.41042 9.16127 4.6608 9.0625 4.92188 9.0625H16.0781C16.3392 9.0625 16.5896 9.16127 16.7742 9.33709C16.9588 9.5129 17.0625 9.75136 17.0625 10C17.0625 10.2486 16.9588 10.4871 16.7742 10.6629C16.5896 10.8387 16.3392 10.9375 16.0781 10.9375ZM12.1406 15.3125H8.85938C8.5983 15.3125 8.34792 15.2137 8.16332 15.0379C7.97871 14.8621 7.875 14.6236 7.875 14.375C7.875 14.1264 7.97871 13.8879 8.16332 13.7121C8.34792 13.5363 8.5983 13.4375 8.85938 13.4375H12.1406C12.4017 13.4375 12.6521 13.5363 12.8367 13.7121C13.0213 13.8879 13.125 14.1264 13.125 14.375C13.125 14.6236 13.0213 14.8621 12.8367 15.0379C12.6521 15.2137 12.4017 15.3125 12.1406 15.3125Z" fill="#009FD7" />
                </svg>
              </div>
              <div className="collapse-w">
                <Collapse isOpen={advancedFieldToggle}>
                  <form onSubmit={handleSubmit}>
                    <div className={`profile-search-wrap nexogit-filter-options ${showAdvanceFields ? 'profile-search-expand' : ''}`}>
                      <Row>
                        <Col lg={12}>
                          <FormGroup>
                            <Field
                              name="name"
                              id="name"
                              autoComplete="off"
                              className="search-icon form-control"
                              placeholder="Search by name"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={12}>
                          <Field
                            name="city"
                            id="city"
                            autoComplete="off"
                            type="text"
                            component={CityAutocompleteTypeaheadField}
                            placeholder="Select Location"
                            className="form-control"
                          />
                        </Col>


                        <Col className="specialities-col form-group" md={12}>
                          <Field
                            id="primarySpeciality"
                            name="primarySpeciality"
                            autoComplete="off"
                            className="primary-specialities"
                            type="text"
                            component={PrimarySpecialityTypeaheadField}
                            placeholder="Select Speciality"
                            inputprops={{
                              autoComplete: "off",
                            }}
                            value={values.primarySpeciality}
                            spellCheck={false}
                            specialit={primarySpecialityList}
                          />
                        </Col>
                        <Col lg={12}>
                          <Button
                            color="primary"
                            className="nex-btn-primary w-full"
                            type="submit"
                          >
                            Search
                          </Button>
                          {/*<i className="more-filter-btn" style={{ cursor: 'pointer' }} onClick={() => setShowAdvanceFields(!showAdvanceFields)}><img src={profile_filter_icon} alt="profile_filter_icon" /></i>*/}
                        </Col>

                      </Row>
                      <Row>
                      </Row>
                    </div>
                  </form>
                </Collapse>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

export default memo(AdvancedSearch);
