import CommonLoader from "common/CommonLoader";
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import confirm from "reactstrap-confirm";
import { setConnectionInfo } from "redux-helpers/ActionCreator";
import { ConnectionService } from "utils/ConnectionService";
import { encrypt } from "utils/EncryptDecrypt";
import { createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor } from "utils/Utils";
import ConnectionsTabularView from "components/tabularView/ConnectionsTabularView";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { FAILTOSAVERECOED } from "utils/Constants";
import PeopleKnowWidget from "common/PeopleKnowWidget";
import ListTableSwitch from "common/ListTableSwitch/ListTableSwitch";
import PageTitle from "common/PageTitle/PageTitle";
import QuickPractitioner from "components/QuickPractitioner/QuickPractitioner";

import graduation_cap from "assets/images/graduation-cap.png";
import suitcase_icon from "assets/images/suitcase.png";
import location_icon from "assets/images/location-1.png";
import React from "react";

const ConnectionCard = (props) => {
  const { getConnections, connectionsDetails, setConnectionsDetails, hasMore, isLoadingMore, setIsLoadingMore } = props;
  const [submittingId, setSubmittingId] = useState("0");
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [viewMode, setViewMode] = useState("card");
  const connectionInfo = useSelector(
    (state) => state.connectionsInfo.connections
  );
  const dispatch = useDispatch();
  let guid = currentUser.guid;

  const confirmWithdraw = async (status, connectionId, index) => {
    let result = await confirm({
      title: <>Confirm</>,
      message: "Are you sure to withdraw connection request ?",
      confirmText: "Yes",
      confirmColor: "primary",
      cancelColor: "link text-danger",
      modalProps: {
        backdrop: "static",
      },
    });
    if (result) {
      updateConnection(status, connectionId, index);
    }
  };

  const OnIgnoreOrAccept = async (status, connectionId, index, label) => {
    let result = await confirm({
      title: <>Confirm</>,
      message: `Are you sure to ${label} connection request ?`,
      confirmText: "Yes",
      confirmColor: "primary",
      cancelColor: "link text-danger",
      modalProps: {
        backdrop: "static",
      },
    });
    if (result) {
      updateConnection(status, connectionId, index);
    }
  };

  const updateConnection = (status, connectionId, index) => {
    const payload = {
      action: status,
    };
    setSubmittingId(connectionId);
    ConnectionService.updateConnection(payload, connectionId, guid)
      .then((response) => {
        let { data } = response;
        if (response.status === 200 || response.status === 201) {
          showSuccessMessage("Connection request updated successfully.")
          connectionsDetails.contents.splice(index, 1);
          setConnectionsDetails({
            ...connectionsDetails,
            contents: connectionsDetails.contents,
          });
          setSubmittingId("0");
          if (connectionsDetails.contents.length === 0) {
            getConnections(0);
          }
          if (data.status === "ACCEPTED") {
            getConnectionsCount();
          }
        } else {
          setSubmittingId("0");
        }
      })
      .catch((error) => {
        setSubmittingId("0");
        showErrorMessage(FAILTOSAVERECOED);
        console.log("Error while updating connection info: ", error);
      });
  };

  useEffect(() => {
    getConnectionsCount();
  }, []);

  const getConnectionsCount = () => {
    ConnectionService.getConnectionsCount(guid)
      .then((response) => {
        let { data } = response;
        if (response.status === 200 || response.status === 201) {
          dispatch(
            setConnectionInfo({ ...connectionInfo, count: { all: data } })
          );
        }
      })
      .catch((error) => {
        console.log("Error while getting connection: ", error);
      });
  };

  const fetchData = () => {
    setIsLoadingMore(true);
    getConnections(
      connectionsDetails.currentPage + 1,
      connectionsDetails.contents
    );
  };

  useEffect(() => {
    document.body.classList.add('body-network-page');
    document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "NetWork";
    return () => {
      document.body.classList.remove('body-network-page');
      document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "";
    }
  }, [])

  const renderProfiles = () => {
    if (viewMode === 'card') {
      return (
        <>
          {
            connectionsDetails.contents.map((connection, index) => {
              const conDetails = connection.practitionerDetails;
              let imageUrl = "";
              if (
                conDetails !== undefined &&
                conDetails.avatarId !== undefined &&
                conDetails.avatarId !== null
              ) {
                imageUrl =
                  process.env.REACT_APP_IMAGE_BASE_URL +
                  "/" +
                  conDetails.profileGuid +
                  "/" +
                  conDetails.avatarId;
              } else if (conDetails !== undefined) {
                imageUrl = createImageFromInitials(
                  69,
                  conDetails.name.replace("Dr. ", ""),
                  getRandomColor(encrypt(conDetails.profileGuid).toString())
                );
              }

              return (
                <React.Fragment key={index}>
                  {conDetails && (
                    <div className="nex-list-card-v2 bg-white card nex-network-list-item">
                      <div className="card-body ">
                        <div className="nex-stack">
                          <QuickPractitioner
                            className="lg"
                            isTitleLink={true}
                            titleLink={`/profile/` + conDetails.profileId}
                            avatar={<img className="nex-avatar lg" src={imageUrl} alt="" />}
                            name={conDetails.name}
                            speciality={`${conDetails.primarySpeciality}${conDetails.superSpeciality ? ` (${conDetails.superSpeciality})` : ""}`}
                            address={getFullAddressWithLocationCityAndState({ location: conDetails?.location ?? "", city: conDetails?.city ?? "", state: conDetails?.state ?? "" })}></QuickPractitioner>
                          <div className="info md">
                            {conDetails?.qualification &&
                              <p className="nex-text text-truncate" title={conDetails?.qualification}><span className="icon mr-2"><img src={graduation_cap} alt="" /></span>{conDetails?.qualification}</p>
                            }
                            {!isNaN(parseInt(conDetails?.practicingSince)) &&
                              <p className="nex-text"><span className="icon mr-2"><img src={suitcase_icon} alt="" /></span>
                                {parseInt(new Date().getFullYear()) - parseInt(conDetails?.practicingSince) > 0 ? (
                                  `${parseInt(new Date().getFullYear()) -
                                  parseInt(conDetails?.practicingSince)
                                  }+ Experience`
                                ) : '0 Experience'}</p>}

                            <p className="nex-text bold text-black text-truncate"><span className="icon mr-2"><img src={location_icon} width={16} alt="" /></span>{getFullAddressWithLocationCityAndState({ location: conDetails?.location ?? "", city: conDetails?.city ?? "", state: conDetails?.state ?? "" })}</p>
                          </div>
                          <div className="action ml-auto my-auto">
                            {connection.status === "PENDING" &&
                              guid === connection.receiverId && (
                                <div className="nexogic-action-part">
                                  <Button
                                    outline
                                    className="nex-btn-danger"
                                    disabled={submittingId == connection.id}
                                    onClick={
                                      () =>
                                        OnIgnoreOrAccept(
                                          "REJECTED",
                                          connection.id,
                                          index,
                                          "Rejected"
                                        )
                                      //  updateConnection("REJECTED", connection.id, index)
                                    }
                                  >
                                    Ignore
                                  </Button>
                                  <Button
                                    color="info"
                                    outline
                                    className="nex-btn-success"
                                    disabled={submittingId == connection.id}
                                    onClick={
                                      () =>
                                        OnIgnoreOrAccept(
                                          "ACCEPTED",
                                          connection.id,
                                          index,
                                          "Accepted"
                                        )
                                      // updateConnection("ACCEPTED", connection.id, index)
                                    }
                                  >
                                    Accept
                                  </Button>
                                </div>
                              )}

                            {connection.status === "PENDING" &&
                              guid === connection.senderId && (
                                <div className="nexogic-action-part nex-app-width-full">
                                  <Button
                                    outline
                                    className="nex-btn-primary"
                                    disabled={submittingId == connection.id}
                                    onClick={() =>
                                      confirmWithdraw(
                                        "WITHDRAWN",
                                        connection.id,
                                        index
                                      )
                                    }
                                  >
                                    Withdraw
                                  </Button>
                                </div>
                              )}
                            {connection.status === "REJECTED" &&
                              guid === connection.senderId && (
                                <div className="nexogic-action-part">
                                  <Button outline className="nex-btn-danger" disabled>
                                    Rejected
                                  </Button>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            }
            )

          }
        </>
      );
    } else {
      return (
        <ConnectionsTabularView theader={['Name', 'Qualification', 'Speciality', 'Experience', 'City']} tdata={connectionsDetails.contents} viewmode={viewMode} />
      );
    }
  };


  return (
    <div className="nex-container row">
      <div className="nex-app-no-pad col">
        <PageTitle title="My Connections">
          <ListTableSwitch viewMode={viewMode} cardViewAction={() => setViewMode("card")} tableViewAction={() => setViewMode("table")} />
        </PageTitle>


        {/* <div className="view-toggle nexogic-view-toggle"> 
          <div className="btns">
            <FontAwesomeIcon title="Card View" color={viewMode === "card" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faAddressCard} onClick={() => setViewMode("card")} /> | <FontAwesomeIcon title="Table View" color={viewMode === "table" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faListAlt} onClick={() => setViewMode("table")} />
          </div>
        </div> */}
        <div className="nexogic-card-group-list nexogic-connection-card nexogic-custom-scroll" id="connection-card-list" style={{ maxHeight: '650px' }}>
          <div>
            {submittingId &&
              connectionsDetails &&
              <>{renderProfiles()}</>
            }

            {hasMore &&
              <div className="text-center mt-3">
                {isLoadingMore
                  ?
                  <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                  :
                  <LoadMoreButton loadMore={fetchData} />
                }
              </div>
            }
          </div>
        </div>
      </div>
      <div className="nex-sidebar v2 col">
        <PeopleKnowWidget speciality={"Acupuncturist"} />
      </div>
    </div>
  );
};

export default ConnectionCard;
