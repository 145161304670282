import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody } from "reactstrap";
import deg_icon from "../../../../assets/images/svgs/deg-icon.svg"
import year_icon from "../../../../assets/svgIcon/year-icon.svg"
import address_icon from "../../../../assets/svgIcon/address-icon.svg"
import treatment_icon from "../../../../assets/svgIcon/treatment-icon.svg"
import { createImageFromInitials, getFullAddress, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import ConnectButton from "./ConnectButton";
import { useSelector } from "react-redux";
import { useState } from "react";
import QuickPractitioner from "components/QuickPractitioner/QuickPractitioner";

const ProfileCardItem = (props) => {
  const {
    name,
    profileGuid,
    qualification,
    primarySpeciality,
    superSpeciality,
    avatarId,
    practicingSince,
    location,
    city,
    state,
    profileId,
    profileStatus,
    viewmode
  } = props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [isExpand, setIsExpand] = useState(false);

  return (
    <Card className={`nex-list-card-v2 nexogic-so-list-card nex-profile-card-item`}>
      <CardBody>
        <div className="nex-stack wrap">
        <QuickPractitioner
              className="lg minus-info-lg"
              isTitleLink={true}
              titleLink={`/profile/${profileId}`}
              avatar={avatarId ?
                <img
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${profileGuid}/${avatarId}`}
                  className="nex-avatar lg"
                  alt="#"
                />
                :
                <img
                  src={createImageFromInitials(
                    50,
                    `${name.replace('Dr. ', '')}`,
                    getRandomColor(encrypt(profileGuid).toString())
                  )}
                  className="nex-avatar lg"
                  alt="#"
                />
              }
              speciality={qualification}
              address={getFullAddress({
                name: " ",
                location: location ?? "",
                city: city ?? "",
                state: state ?? "",
              }).replace("-", "").trim() || 'N/A'}
              name={name}
            />
            
          <div className="info my-auto">
              <p className="nex-text text-black">Experience : {!isNaN(parseInt(practicingSince)) &&
              parseInt(new Date().getFullYear()) - parseInt(practicingSince) > 0 ? (
              <>
                {`${parseInt(new Date().getFullYear()) -
                  parseInt(practicingSince)
                  }+ years`}
              </>
            ) : '0 year'}</p>
          </div>
          <div className="action ml-auto my-auto flex">
          <Link to={`/profile/${profileId}`} className="btn nex-btn-primary-outline">View Profile</Link>
          {(currentUser.guid !== profileGuid) &&
            <ConnectButton practitionerGuid={profileGuid} viewmode={viewmode} />
          }
          </div>
        </div>

      </CardBody>
    </Card>
  );
};

export default memo(ProfileCardItem);
