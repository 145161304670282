import { memo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { TalentService } from "utils/TalentService";
import event from "../../../../assets/images/svgs/events-calendar-icon.svg";
import createpoll_icon from "../../../../assets/images/svgs/poll-icon.svg";
import oppotunity_icon from "../../../../assets/images/svgs/job-search-icon.svg";
import second_opinion from "../../../../assets/images/svgs/second-opinion.svg";
import question_icon from "../../../../assets/images/svgs/ask-question.svg";
import sharedPatients_icon from "../../../../assets/images/svgs/patient-cases.svg";
import referals_icon from "../../../../assets/images/svgs/referals-icon.svg";
import profile_icon from "../../../../assets/images/profile-icon.png";
import AddEvent from "../AddEvent";
import AskaQuestion from "../AskaQuestion";
import CreateaPoll from "../CreateaPoll";
import JobsView from "../JobsView";
import ShareaPatient from "../ShareAPatient";
import AskQuestionForm from "./Component/AskQuestionForm";
import CreateJobContainer from "./Component/CreateJobContainer";
import CreatePollForm from "./Component/CreatePollForm";
import PatientCasesForm from "./Component/PatientCasesForm";
import JobFilter from "./JobFilter";
import ReceivedSecondOpinionLists from "pages/PatientProfile/SecondOpinion/components/ReceivedOpinionLists";
import RestrictedComponent from "common/RestrictedComponent";
import ShowReferrals from "./Component/ShowReferrals";
import ReferalsFilter from "./Component/ShowReferrals/ReferralFilter";
import ReceivedReferals from "pages/Referals/Components/ReceivedReferals";
import SentReferals from "pages/Referals/Components/SentReferals";
import { useEffect } from "react";
import { PractitionerService } from "utils/PractitionerService";
import { isPractitioner, isInstitutionAdmin } from "utils/UserHelper";
import { FAILTOGETRECOED } from "utils/Constants";
import CommonLoader from "common/CommonLoader";
import PractitionerProfiles from "../PractitionerProfiles";

let options = [{ id: 4, name: "Jobs", icon: { oppotunity_icon } }];

let optionDev = [
  { id: 1, name: "Ask a question", icon: { question_icon } },
  { id: 2, name: "Poll", icon: { createpoll_icon } },
  { id: 3, name: "Patient cases", icon: { sharedPatients_icon } },
  { id: 4, name: "Jobs", icon: { oppotunity_icon } },
  { id: 5, name: "Events", icon: { event } },
  { id: 6, name: "Referrals", icon: { referals_icon } },
];

const initValues = {
  institutionDetail: null,
  opportunity: "",
  position: "",
  title: "",
  qualifications: [],
  description: "",
  primarySpeciality: [],
  skills: [],
  shift: "",
  role: "",
  city: [],
  location: [],
  linkToApply: "",
  minExperience: "",
  maxExperience: "",
  minSalary: "",
  maxSalary: "",
  id: "",
};

const CreatePosthome = () => {
  const [morebtnOpen, setMorebtnOpen] = useState(false);
  const [modalopen, setmodalOpen] = useState(false);
  const [valueState, setValueState] = useState(1);
  const [slidediv, setSlidediv] = useState(false);
  const [initialValues, setInitialValues] = useState(initValues);
  const toggleModal = () => setmodalOpen(!modalopen);
  const [activeTab, setActiveTab] = useState("4");
  const [primarySpeciality, setPrimarySpeciality] = useState("");
  const [city, setCity] = useState("");
  const [opportunityType, setOpportunityType] = useState("");
  const [list_of_data, setList_of_data] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const status = useSelector(state => state.PreferenceFlags?.referalStatusFlag);
  const [referalStatus, setReferalStatus] = useState(false);
  const [filter, setFilter] = useState("RECEIVED");
  const [isPractitionerEligibleForReferrals, setIsPractitionerEligibleForReferrals] = useState(false);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [isLoadingNewTab, setIsLoadingNewTab] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const [viewMode, setViewMode] = useState("card");

  useEffect(() => {
    if (activeTab === '6') {
      setReferalStatus(status);
      setIsLoadingNewTab(false);
    }
  }, [activeTab])

  useEffect(() => {
    if (isPractitioner()) {
      getPractitionerPreferences(currentUser?.guid);
    }
  }, [])


  const getData = async (pageNo, feedlist, sq1 = "") => {
    const request = TalentService.listJob({ cp: pageNo, sq: sq1 });
    request
      .then(async (response) => {
        let { data } = response;
        if (pageNo === 0) {
          const allData = { ...data };
          const resolved = await Promise.all(
            allData.contents.map(async (item) => {
              const each = { ...item };
              return each;
            })
          );
          allData.contents = resolved;
          setList_of_data(allData);
          setIsLoading(false);
          setLoadingMore(false);
        } else {
          const resolved = await Promise.all(
            data.contents.map(async (item) => {
              const each = { ...item };
              return each;
            })
          );
          feedlist = feedlist.concat(resolved);
          setList_of_data({ ...data, contents: feedlist });
          setIsLoading(false);
          setLoadingMore(false);
        }
      })
      .catch((error) => {
        console.log("Error while getting job list: ", error);
      });
  };


  const dropToggle = () => {
    setSlidediv(!slidediv);
  };
  const history = useHistory();
  const dropModal = (id) => {
    if (id === 4) {
      history.push("/jobsboard/post-a-job");
    } else {
      setmodalOpen(true);
      setValueState(id);
      setInitialValues(initValues);
    }
  };

  const editJobItem = (id) => {
    const request = TalentService.getJob(id);
    request
      .then(async (response) => {
        const { status, data } = response;
        if (status === 200) {
          const {
            id,
            institutionDetails,
            opportunity,
            position,
            title,
            qualifications,
            description,
            primarySpeciality,
            skills,
            shift,
            role,
            linkToApply,
            minExperience,
            maxExperience,
            location,
            city,
          } = data;
          setInitialValues({
            institutionDetail: {
              guid: institutionDetails?.guid ?? "",
              institutionName: institutionDetails?.name ?? "",
            },
            opportunity,
            position,
            qualifications: qualifications ? qualifications.split(",") : [],
            title,
            description,
            primarySpeciality: primarySpeciality ? [primarySpeciality] : [],
            skills,
            shift,
            role,
            city: city ? [city] : [],
            location: location ? [location] : [],
            linkToApply,
            minExperience,
            maxExperience,
            minSalary: data?.minSalary ?? "",
            maxSalary: data?.maxSalary ?? "",
            id,
          });
          setmodalOpen(true);
          setValueState(4);
        } else {
          let errmsg =
            response.data && !!response.data.message
              ? response.data.message
              : "Failed to save. Please try again";
          alert.error(errmsg);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPractitionerPreferences = (guid) => {
    PractitionerService.getPreferences(guid)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          setIsPractitionerEligibleForReferrals(data?.openForReferral)
        } else {
          console.log(FAILTOGETRECOED);
        }
      })
  }


  return (
    <>
      <div className="mb-3">
        <Card className={`nexogic-pill-tabs has-more-options ${morebtnOpen ? 'show-all' : ''}`}>
          <CardBody className="">
            <div className="question-wrap">
              <Nav tabs>
                <>
                  <NavItem className={`${activeTab === "4" ? "active" : ""}`}>
                    <Button
                      className={`nexogic-pill-btn ${activeTab === "4" ? "active" : ""
                        }`}
                      onClick={() => setActiveTab("4")}
                    >
                      <span className="icon"><img src={oppotunity_icon} alt="#" className="nav-icon" /></span>{" "}
                      Jobs
                    </Button>
                  </NavItem>

                  <NavItem className={`${activeTab === "8" ? "active" : ""}`}>
                    <Button
                      className={`nexogic-pill-btn ${activeTab === "8" ? "active" : ""
                        }`}
                      onClick={() => setActiveTab("8")}
                    >
                      <span className="icon"><img src={profile_icon} alt="#" className="nav-icon" /></span>{" "}
                      Profiles
                    </Button>
                  </NavItem>

                  {isPractitionerEligibleForReferrals &&
                    <NavItem className={`${activeTab === "6" ? "active" : ""}`}>
                      <Button
                        className={`nexogic-pill-btn ${activeTab === "6" ? "active" : ""
                          }`}
                        onClick={() => {
                          setIsLoadingNewTab(true);
                          setActiveTab("6");
                        }
                        }
                      >
                        <span className="icon"><img src={referals_icon} width={20} alt="#" className="nav-icon" /></span>{" "}
                        Referrals
                      </Button>
                    </NavItem>
                  }


                  {!isInstitutionAdmin() &&
                    <NavItem className={`${activeTab === "7" ? "active" : ""}`}>
                      <Button
                        className={`nexogic-pill-btn ${activeTab === "7" ? "active" : ""
                          }`}
                        onClick={() => setActiveTab("7")}
                      >
                        <span className="icon"><img src={second_opinion} width={20} alt="#" className="nav-icon" /></span>{" "}
                        Second Opinion
                      </Button>
                    </NavItem>
                  }

                  {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                    <NavItem className={`${activeTab === "1" ? "active" : ""}`}>
                      <Button
                        className={`nexogic-pill-btn ${activeTab === "1" ? "active" : ""
                          }`}
                        onClick={() => setActiveTab("1")}
                      >
                        <span className="icon"><img src={question_icon} alt="#" className="nav-icon" /></span>{" "}
                        Ask a question
                      </Button>
                    </NavItem>
                  }
                  {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                    <NavItem className={`${activeTab === "3" ? "active" : ""}`}>
                      <Button
                        className={`nexogic-pill-btn ${activeTab === "3" ? "active" : ""
                          }`}
                        onClick={() => setActiveTab("3")}
                      >
                        <span className="icon">
                          <img
                            src={sharedPatients_icon}
                            alt="#"
                            className="nav-icon"
                          /></span>{" "}
                        Patient cases
                      </Button>
                    </NavItem>
                  }
                </>
                {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
                  <>
                    <NavItem className={`${activeTab === "5" ? "active" : ""}`}>
                      <Button
                        className={`nexogic-pill-btn ${activeTab === "5" ? "active" : ""
                          }`}
                        onClick={() => setActiveTab("5")}
                      >
                        <span className="icon"><img src={event} alt="#" className="nav-icon" /></span> Events
                      </Button>
                    </NavItem>
                  </>
                )}
                {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
                  <NavItem className={`${activeTab === "2" ? "active" : ""}`}>
                    <Button
                      className={`nexogic-pill-btn ${activeTab === "2" ? "active" : ""
                        }`}
                      onClick={() => setActiveTab("2")}
                    >
                      <span className="icon">
                        <img
                          src={createpoll_icon}
                          alt="#"
                          className="nav-icon"
                        /></span>{" "}
                      Poll
                    </Button>
                  </NavItem>)
                }
              </Nav>
            </div>
          </CardBody>

          <span className="more-btn" onClick={(e) => setMorebtnOpen(!morebtnOpen)}>{morebtnOpen ? 'Less' : 'More'} <span className="arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
            </svg></span>
          </span>

        </Card>
        <div className="post-filter-position-control">
          <div className="post-filter-wrp my-3 justify-content-end">
            {/** 
            <Dropdown toggle={dropToggle} isOpen={slidediv}>
              <DropdownToggle className="create-postbtn nexogic_primary_button btn-small" color="primary">
                Post Jobs
              </DropdownToggle>
              <DropdownMenu>
                {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false"
                  ? optionDev.map((option) => (
                    <DropdownItem
                      key={option.id}
                      onClick={(id) => dropModal(option.id)}
                    >
                      <img src={Object.values(option.icon)} alt={option.icon} />{" "}
                      {option.name}
                    </DropdownItem>
                  ))
                  : options.map((option) => (
                    <DropdownItem
                      key={option.id}
                      onClick={(id) => dropModal(option.id)}
                    >
                      <img src={Object.values(option.icon)} alt={option.icon} />{" "}
                      {option.name}
                    </DropdownItem>
                  ))} 
              </DropdownMenu>
            </Dropdown>
            */}
            {activeTab === "4" && viewMode === "card" &&
                <JobFilter setPrimarySpeciality={setPrimarySpeciality} setCity={setCity} setOpportunityType={setOpportunityType} getData={getData} />
            }

            <>
              {activeTab === "6" && referalStatus &&
                <>
                <ReferalsFilter isTitle={false}
                    filter={filter}
                    setFilter={setFilter}
                  />
                {/*<h1 className="nexogic-page-title w-100 nexogic-page-flter-title mb-0">My {filter === "RECEIVED" ? "Received" : "Sent"} Referrals
                  
                  
                  </h1>*/} 
                  </>
                  
              }
            </>

          </div>
        </div>
        {isLoadingNewTab ?
          <div className="loading-spiner text-center">
            <CommonLoader />
          </div>
          :
          <TabContent activeTab={activeTab}>
            {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
              <>
                <TabPane tabId="1">
                  <AskaQuestion />
                </TabPane>
                <TabPane tabId="2">
                  <CreateaPoll />
                </TabPane>
                <TabPane tabId="3">
                  <ShareaPatient />
                </TabPane>
              </>
            )}
            <TabPane tabId="4" className="nex-job-tab-pannel">
              <JobsView
                primarySpeciality={primarySpeciality}
                setPrimarySpeciality={setPrimarySpeciality}
                city={city}
                setCity={setCity}
                opportunityType={opportunityType}
                setOpportunityType={setOpportunityType}
                getData={getData}
                list_of_data={list_of_data}
                isLoading={isLoading}
                loadingMore={loadingMore}
                setLoadingMore={setLoadingMore}
                viewMode={viewMode}
                setViewMode={setViewMode}
              />
            </TabPane>
            {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
              <>
                <TabPane tabId="5">
                  <Row className="post-modal">
                    <AddEvent />
                  </Row>
                </TabPane>
              </>
            )}
            {/* {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && ( */}
            {/* <> */}
            <TabPane tabId="6">
              {referalStatus ?
                <>
                  {
                    filter === "RECEIVED"
                      ?
                      <ReceivedReferals isTitle={false} sidebar={false} isTopBtn={false} />
                      :
                      <SentReferals isTitle={false} sidebar={false} isTopBtn={false} />
                  }
                </>
                : <RestrictedComponent />}
            </TabPane>
            {/* </> */}
            {/* )} */}

            <>
              <TabPane tabId="7">
                <ReceivedSecondOpinionLists sidebar={false} isTopBtn={false} />
              </TabPane>
            </>


            <TabPane tabId="8">
              <PractitionerProfiles sidebar={false} isTopBtn={false} />
            </TabPane>
          </TabContent>
        }
      </div>
      <Modal
        isOpen={modalopen}
        toggle={toggleModal}
        scrollable={true}
        className="post-modal"
        backdrop="static"
      >
        <ModalHeader toggle={toggleModal}>
          Create
          {valueState === 1 && <span> Ask a Question</span>}
          {valueState === 2 && <span> a Poll</span>}
          {valueState === 3 && <span> Patient Cases</span>}
          {valueState === 4 && <span> Jobs</span>}
          {valueState === 5 && <span> Events</span>}
        </ModalHeader>
        <ModalBody>
          {valueState === 1 && <AskQuestionForm toggle={toggleModal} />}
          {valueState === 2 && <CreatePollForm toggle={toggleModal} />}
          {valueState === 3 && <PatientCasesForm toggle={toggleModal} />}
          {valueState === 4 && (
            <CreateJobContainer
              toggle={toggleModal}
              initialValues={initialValues}
            />
          )}
          {valueState === 5 && <AddEvent toggle={toggleModal} />}
        </ModalBody>
      </Modal>
    </>
  );
};

export default memo(CreatePosthome);
