import React from 'react'
import doc_img from 'assets/images/doctor-png-29750.png'
import ads_bg3 from 'assets/images/ads-v3-bg.jpg'
import logo from 'assets/images/logo-top-icon.png'
import './css/v1.scss'

function AdsThree(props) {
  return (
    <div className='nex-ads-wrapper size-md v3 mb-3' style={{backgroundImage:`url(${props.bg})`}}>
    <div className="nex-ads-brand">
        <a href={props.logo_url} target={props.logo_target}><img src={props.logo_img} alt="" /></a>
    </div>
    <div className="nex-ads-body">
        <div className="nex-ads-content">
            <div className="graphic">
                <img src={props.doctor_img} alt="" />
            </div>
            <div className="ads-info">
                <div className="ads-bg"></div>
                <div className="ads-bg2"></div>
                <div className="ads-conts">
                <h2 className='nex-ads-p-heading' dangerouslySetInnerHTML={{__html: props.title}}></h2>
                <p>{props.short_desc}</p>
                <p>
                    <a href={props.button_link} target={props.button_target} className='nex-add-btn'>{props.button_text}</a>
                </p>
                </div>
            </div>
        </div>
        <div className="nex-ads-doctor-details-w">
            <div className="nex-ads-doctor-details">
                <div className="nex-ads-dr-name">{props.doctor_name}</div>
                <p dangerouslySetInnerHTML={{__html: props.doctor_details}}></p>
               
            </div>
            <div className="bg-3"></div>
        </div>
        <div className="nex-ads-bot-links">
            <div className="left-links">
                <p>
                    <span className='bold-text'>Emargency Helpline</span>
                    <a className='p-number' href={'tel:'+props.phone}>{props.phone}</a>
                </p>
            </div>
            <div className="right-links">
            <p className='web-link-flex'>
                    <div className="icon">
                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.49996 12.5C9.92941 13.0741 10.4773 13.5491 11.1065 13.8929C11.7357 14.2367 12.4315 14.4411 13.1466 14.4923C13.8617 14.5435 14.5795 14.4403 15.2513 14.1897C15.9231 13.9392 16.5331 13.547 17.04 13.04L20.04 10.04C20.9507 9.09695 21.4547 7.83394 21.4433 6.52296C21.4319 5.21198 20.9061 3.95791 19.979 3.03087C19.052 2.10383 17.7979 1.57799 16.487 1.5666C15.176 1.5552 13.913 2.05918 12.97 2.96997L11.25 4.67997M13.5 10.5C13.0705 9.92584 12.5226 9.45078 11.8934 9.10703C11.2642 8.76327 10.5684 8.55885 9.8533 8.50763C9.13816 8.45641 8.42037 8.5596 7.74861 8.81018C7.07685 9.06077 6.46684 9.4529 5.95996 9.95996L2.95996 12.96C2.04917 13.903 1.54519 15.166 1.55659 16.477C1.56798 17.788 2.09382 19.042 3.02086 19.9691C3.9479 20.8961 5.20197 21.4219 6.51295 21.4333C7.82393 21.4447 9.08694 20.9408 10.03 20.03L11.74 18.32" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

                    </div>
                    <a className='web-link' href={'https://'+props.website}>{props.website}</a>
                </p>
            </div>
        </div>
    </div>
</div>
  )
}


/* default props */
AdsThree.defaultProps = {
    bg:ads_bg3,
    logo_img:logo,
    logo_url:"#",
    logo_target:"_blank",
    title: "Your <br/><span>Health</span><br/> Is Our <br/><span>Priority </span>",
    short_desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum dolores est non ipsa laborum?",
    doctor_img:doc_img,
    button_text: "Appointment",
    button_link: "#",
    button_target: "_blank",
    doctor_name: "DR. DAVID SMITH ",
    doctor_details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo, magni.",
    phone:"+123 456 7890",
    website:"www.madecare.com",
  }
  
  export default AdsThree;