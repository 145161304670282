
import React from 'react'
import './css/v1.scss'

export default function DoctorSearchSidebar() {
  return (
    <div className='nex-doctor-search-sidebar-card'>
        <h3 className="nex-form-title">Filter
            <a href="" className='nex-link'>Clear All</a>
        </h3>
        <div className="nex-form-container">
            <div className="nex-form-group">
                <label htmlFor="">Hospital</label>
                <select name="" id="" className='form-control'>
                    <option value="">Select Hospital</option>
                    <option value="">Test 1</option>
                    <option value="">Test 2</option>
                </select>
            </div>
            <div className="nex-form-group">
                <label htmlFor="">Speciality</label>
                <select name="" id="" className='form-control'>
                    <option value="">Select Speciality</option>
                    <option value="">Test 1</option>
                    <option value="">Test 2</option>
                </select>
            </div>
            <div className="nex-form-group">
                <label htmlFor="">Doctor</label>
                <select name="" id="" className='form-control'>
                    <option value="">Select Doctor</option>
                    <option value="">Test 1</option>
                    <option value="">Test 2</option>
                </select>
            </div>
            <div className="nex-form-group action">
                <input type="submit" name="" id="" className='nexogic_primary_button ' value='submit' />
            </div>
        </div>
    </div>
  )
}
