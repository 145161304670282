import { Field, Formik } from "formik";
import { useRef } from "react";
import { withAlert } from "react-alert";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Spinner } from "reactstrap";
import { authSetUser, setGlobalprofile } from "redux-helpers/ActionCreator";
import { PractitionerService } from "utils/PractitionerService";
import "assets/styles/customizedQuill.css";
import "react-quill/dist/quill.snow.css";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const CustomToolbar = () => (
  <div id="toolbar">
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-underline" />
    <button className="ql-strike" />
    <button className="ql-link" />
  </div>
);

const RenderField = (props) => {
  const {
    isValid,
    dirty,
    handleSubmit,
    isSubmitting,
    errors,
    isAdmin,
    toggle,
  } = props;
  const textInput = useRef();

  const validateProfileSummary = () => {
    const editorTxt = textInput.current?.getEditor().getText() || undefined;
    let error;
    if (editorTxt.trim() === "") {
      error = "This field is required";
    }
    return error;
  };

  return (
    <form onSubmit={handleSubmit}>
      <CustomToolbar />
      <Field
        name="profileSummary"
        validate={isAdmin ? null : validateProfileSummary}
      >
        {({ field }) => (
          <ReactQuill
            id="nex-pa-about-textarea"
            ref={textInput}
            bounds={".app"}
            modules={RenderAboutForm.modules}
            formats={RenderAboutForm.formats}
            onChange={field.onChange(field.name)}
            value={field.value}
          />
        )}
      </Field>
      {errors.profileSummary && (
        <div className="errMsg text-danger ml-2">{errors.profileSummary}</div>
      )}
      
      <div className="justify-content-end modal-footer">
        <Col xs="auto action-group">
          {isSubmitting ? (
            <div className="loading-spiner">
              <Spinner />
            </div>
          ) : (
            <>
            <Button
                id="nex-pa-about-submit-btn"
                className="nexogic_primary_button"
                color="primary"
                disabled={!dirty || !isValid}
                type="submit"
              >
                Save
              </Button>
              <Button
                id="nex-pa-about-cancel-btn"
                onClick={toggle}
                className="nexogic_primary_button_outline"
                color="primary"
              >
                Cancel
              </Button>
              
            </>
          )}
        </Col>
      </div>
    </form>
  );
};

const RenderAboutForm = (props) => {
  const { setPractitioner, practitioner, isAdmin } = props;
  const dispatch = useDispatch();
  const { toggle, profileSummary, alert } = props;
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );


  const initialValues = { profileSummary: profileSummary };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          const payload = [
            {
              op: "add",
              path: "/summary",
              value: values.profileSummary,
            },
          ];
          PractitionerService.updatePractitionersDetails(payload,globalProfileInfo?.guid).then(
            (response) => {
              const { guid, validationErrors } = response.data;
              if (guid) {
                const { profileSummary } = values;
                const updatedCurrentUser = {
                  ...practitioner,
                  summary: profileSummary,
                };
                setPractitioner(updatedCurrentUser);
                if (!isAdmin) {
                  dispatch(authSetUser(updatedCurrentUser));
                } else {
                  dispatch(setGlobalprofile(updatedCurrentUser));
                }
                showSuccessMessage("profile updated successfully");
                toggle();
              } else {
                if (
                  validationErrors &&
                  validationErrors &&
                  validationErrors.length > 0
                ) {
                  const error = [];
                  validationErrors.forEach((errors) => {
                    error.push(errors.message);
                  });
                  showErrorMessage(error.join(","));
                } else {
                  showErrorMessage("Error updating content");
                }
                /* let errmsg = response.data.message || "Error updating content";
                showErrorMessage(errmsg); */
              }
              setSubmitting(false);
            },
            (e) => {
              let errmsg =
                e.response && e.response.data
                  ? e.response.data.message || "Error updating content"
                  : e.message;
              showErrorMessage(errmsg);
              setSubmitting(false);
            }
          );
        }}
        children={(props) => {
          return <RenderField toggle={toggle} isAdmin={isAdmin} {...props} />;
        }}
      />
    </div>
  );
};

export default withAlert()(RenderAboutForm);

RenderAboutForm.modules = {
  toolbar: {
    container: "#toolbar",
  },
  clipboard: {
    matchVisual: false,
  },
};

RenderAboutForm.formats = [
  "bold",
  "italic",
  "underline",
  "link",
];
