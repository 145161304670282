import React from 'react';
import {  Modal, ModalHeader, ModalBody } from 'reactstrap';
import SocialHandlerForm from "./SocialHandlerForm"

const  RenderSocialHandlerModal = (props) => {
  const {
    isOpen,
    toggle
  } = props;

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} id="profile-modal" className="modal-dialog-scrollable modal-dialog-centered" backdrop="static">
      <ModalHeader toggle={toggle}>{"Social Media"}</ModalHeader>
        <ModalBody>
          <SocialHandlerForm {...props}/>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default RenderSocialHandlerModal