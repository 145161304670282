import API from "./API_Factory";

const getConnectionsCount = (guid) =>
  API({
    method: "GET",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/${guid}/connections/count`,
    ignoreToken: false,
  });

const getConnectionsInfo = (guid, type, pageNo) =>
  API({
    method: "GET",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/${guid}/connections?rt=${type}&cp=${pageNo}`,
    ignoreToken: false,
  });

const getConnectionStatus = (guid, colleague_GUID) =>
  API({
    method: "GET",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/${guid}/connections/connected-with/${colleague_GUID}`,
    ignoreToken: false,
  });

const addConnection = (data, guid) =>
  API({
    method: "POST",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/${guid}/connections`,
    data,
    ignoreToken: false,
  });

const updateConnection = (data, connectionId, guid) =>
  API({
    method: "PUT",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/${guid}/connections/${connectionId}`,
    data,
    ignoreToken: false,
  });

const checkFollowing = (practitionerId, instituationId) =>
  API({
    method: "GET",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/followings/${practitionerId}/is-following/${instituationId}?ft=INSTITUTION`,
    ignoreToken: false,
  });

const PostFollowing = (instituationId) =>
  API({
    method: "POST",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/followings?ft=INSTITUTION`,
    ignoreToken: false,
    data: {
      followedId: instituationId,
    },
  });

const UnFollowing = (followingId) =>
  API({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/followings/${followingId}`,
    ignoreToken: false,
  });

export const ConnectionService = {
  getConnectionsCount,
  getConnectionStatus,
  getConnectionsInfo,
  addConnection,
  updateConnection,
  checkFollowing,
  PostFollowing,
  UnFollowing,
};
