import React from 'react'
import Slider from "react-slick";
import './style.scss';

export default function Slider3() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4,
                }
              },
              {
                breakpoint: 1000,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 520,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
        ]
      };
  return (
    <div className='micro-slider-v3'>
        <Slider {...settings}>
        <div className="slider-item">
                <div className="micro-slider-card">
                    <div className="graphic"><img src="https://picsum.photos/id/200/300/150" alt="" /></div>
                    <h3 className="card-title">Item 1</h3>
                </div>
            </div>
            <div className="slider-item">
                <div className="micro-slider-card">
                    <div className="graphic"><img src="https://picsum.photos/id/203/300/150" alt="" /></div>
                    <h3 className="card-title">Item 2</h3>
                </div>
            </div>
            <div className="slider-item">
                <div className="micro-slider-card">
                    <div className="graphic"><img src="https://picsum.photos/id/204/300/150" alt="" /></div>
                    <h3 className="card-title">Item 3</h3>
                </div>
            </div>
            <div className="slider-item">
                <div className="micro-slider-card">
                    <div className="graphic"><img src="https://picsum.photos/id/208/300/150" alt="" /></div>
                    <h3 className="card-title">Item 4</h3>
                </div>
            </div>
            <div className="slider-item">
                <div className="micro-slider-card">
                    <div className="graphic"><img src="https://picsum.photos/id/206/300/150" alt="" /></div>
                    <h3 className="card-title">Item 5</h3>
                </div>
            </div>
            <div className="slider-item">
                <div className="micro-slider-card">
                    <div className="graphic"><img src="https://picsum.photos/id/210/300/150" alt="" /></div>
                    <h3 className="card-title">Item 6</h3>
                </div>
            </div>
            <div className="slider-item">
                <div className="micro-slider-card">
                    <div className="graphic"><img src="https://picsum.photos/id/209/300/150" alt="" /></div>
                    <h3 className="card-title">Item 7</h3>
                </div>
            </div>  
        </Slider>
    </div>
  )
}
