import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { withAlert } from "react-alert"
import ServicesForm from "./ServicesForm"
import ServiceFormBulkEdit from './ServiceFormBulkEdit';

const RenderServicesModal = (props) => {
  const { services, isOpen, toggle, practitionerId, isAdmin, institutionId, getInstitutionProfile, institution } = props
  const [addInBulk, setAddInBulk] = useState(false);
  const handleClick = () => {
    setAddInBulk(!addInBulk);
  };
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} id="profile-modal" className="skills-modal modal-dialog-scrollable modal-dialog-centered skills-expertise-modal" backdrop="static">
      <ModalHeader toggle={toggle} className="nex-institutions-modal-header">
        <div className='modal-filter-header nex-text-xl'>
          {`Services`}
          <span className="ml-auto">
            <label className='mb-0 mr-2'>Add in Bulk</label>
            <input className="add_in_bulk mr-3" id="nex-specialities-add-in-bulk" type="checkbox" checked={addInBulk} onClick={() => handleClick()} />
          </span>
          </div>
        </ModalHeader>
        <ModalBody bg-white>
        
        {/* <ModalHeader toggle={toggle}>
          <div className='modal-filter-header'>
          {`Services`}
          <span className='ml-auto'>
            <label className='mb-0 mr-2'>Add in Bulk</label>
            <input id='add_in_bulk' type="checkbox" checked={addInBulk} onClick={() => handleClick()} />
          </span>
          </div>
        </ModalHeader>
        <ModalBody> */}
          {addInBulk
            ?
            <ServiceFormBulkEdit
              services={services}
              practitionerId={practitionerId}
              toggle={toggle}
              isAdmin={isAdmin}
              institution={institution}
              institutionId={institutionId}
              getInstitutionProfile={getInstitutionProfile}
              addInBulk={addInBulk}
            />
            :
            <ServicesForm
              services={services}
              practitionerId={practitionerId}
              toggle={toggle}
              isAdmin={isAdmin}
              institution={institution}
              institutionId={institutionId}
              getInstitutionProfile={getInstitutionProfile}
              addInBulk={addInBulk}
            />
          }

        </ModalBody>
      </Modal>
    </div>
  );
}


export default withAlert()(RenderServicesModal)