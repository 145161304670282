import { Button, Col, Input, Row } from "reactstrap";
import PostUploadDocument from "./PostUploadDocument";

const AskQuestionForm = ({ toggle }) => {
  return (
    <>
      <Row>
        <Col md={12}>
          <Input
            id="exampleText"
            name="text"
            type="textarea"
            placeholder="What do you want to ask?"
          />
          <PostUploadDocument />
          <div className="d-flex align-items-center justify-content-center">
            <Button
              id="aqf_cancel"
              onClick={toggle}
              type="button"
              color="danger"
              className="cancel-btn"
            >
              Cancel
            </Button>
            <Button id="aqf_submit" color="primary" className="post-btn ml-3">
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default AskQuestionForm;
