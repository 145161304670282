import React, { useState } from "react";
import ShareIcon from "../../images/shareconn.png";
import MessageIcon from "../../images/sharemess.png";
import CopyIcon from "../../images/copylink.png";
import FacebookIcon from "../../images/facebook.png";
import LinkedinIcon from "../../images/Linkedin.png";
import ShareModal from "../ShareModal/ShareModal";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { FacebookShareButton, LinkedinShareButton } from "react-share";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { get_meta_data } from "../../../../utils/message_connection";
import DefaultImg from "../../../../assets/images/defaultPic.png";
import "./ShareDropdown.css";

const ShareDropdown = ({
  user,
  location,
  createdOn,
  feedId,
  mediaList,
  caption_text,
  share_feed_id,
  toggle_modal,
  handleModalMessage,
}) => {
  const practitionerId = useSelector(
    (state) => state.auth.currentUser.practitionerId
  );

  let url = window.location.origin;

  let link = `${url}/practitioner-service/practitioners/${practitionerId}/feeds/${share_feed_id}`;

  const copyLink = () => {
    navigator.clipboard.writeText(link).then((res) => {
      toast.info("Link Copied");
    });
  };

  return (
    <section>
      {/* <ToastContainer /> */}
      <div className="share-dropdown mt-2">
        <ul>
          <li onClick={() => toggle_modal()}>
            Share with connection <img src={ShareIcon} alt="" />
          </li>
          <hr />
          <li onClick={() => handleModalMessage()}>
            Sent in messages <img src={MessageIcon} alt="" />
          </li>
          <hr />

          <li onClick={copyLink}>
            Copy link <img src={CopyIcon} alt="" />
          </li>
          <hr />

          <li>
            <FacebookShareButton url={link} style={{ display: "contents" }}>
              Share in Facebook <img src={FacebookIcon} alt="" />
            </FacebookShareButton>
          </li>
          <hr />

          <li>
            <LinkedinShareButton url={link} style={{ display: "contents" }}>
              Share in Linkedin <img src={LinkedinIcon} alt="" />
            </LinkedinShareButton>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default ShareDropdown;
