import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import SpecialitiesModal from "./SpecialitiesModal";
import plusIcon from "assets/images/plus-icon-lg.png";


const Specialities = (props) => {
  const {
    isEditable,
    setPractitioner,
    isAdmin,
    institution,
    getInstitutionProfile,
    setShowSpecialitiesOnTabScroll
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState(12);
  const [specialities, setSpecialities] = useState([]);
  const [pannel_on, setPannel_on] = useState(true);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setSpecialities(institution?.specialities);
  }, [institution]);

  useEffect(() => {
    if (specialities && specialities?.length) {
      setShowSpecialitiesOnTabScroll(true);
    } else {
      setShowSpecialitiesOnTabScroll(false);
    }
  }, [specialities]);

  const RenderSpecialities = (props) => {
    const { specialities, count } = props;
    if (specialities && specialities?.length) {
      return (
        <>
          {specialities.slice(0, count).map((speciality, index) => {
            if (speciality !== "") {
              return (
                <li className="list-group-item nex-pill-list" key={index}>
                  <div className="row g-0">
                    <div className="col"><h3 className="card-title fw-semibold">{speciality}</h3></div>
                    {/*<FontAwesomeIcon icon={faCheckCircle} className="icon-check" />*/}
                  </div>
                </li>
              )
            }
          })}
        </>
      )
    }
    return "";
  };

  const ShowMoreServices = (props) => {
    const { services, count, setCount } = props;
    if (services && services.length > count) {
      const remainingCount = services.length - count;
      return (
        <Button
          id="nex-specialities-more-btn"
          color="link"
          onClick={() => setCount(services.length)}
          className="readmore"
        >
          {`+ ${remainingCount} more`}
        </Button>
      );
    }
    return null;
  };
  return (
    <>
      {((specialities.length > 0 && !isAdmin) || isAdmin) && (
        <Card className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="specialities">
          <div className="card-header"><h2>Specialities</h2>
            <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
              <svg width={17} height={10} viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path
                  d="M2.25 1.625L8.625 8L15 1.625"
                  stroke="black"
                  strokeWidth={3}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            {(isEditable || isAdmin) && (

              <Button
                id="nex-specialities-add-btn"
                color="link"
                className="add-button"
                onClick={toggle}
              >
                <img src={plusIcon} alt="" />
                {/*<FontAwesomeIcon icon={faPlus} />*/}
              </Button>
            )}
          </div>
          <div className={`collapse-div`}>
            <CardBody>
              {specialities && specialities.length > 0
                ? ""
                : (isEditable || isAdmin) && (
                  <p className="card-text">
                    <Button
                      id="nex-specialities-add-btn2"
                      className="readmore"
                      color="link"
                      onClick={toggle}
                    >
                      {"Add information"}
                    </Button>
                  </p>
                )}

              <div className="nexogic-card-list">
                <ul className="nex-pill-list-w">
                  <RenderSpecialities specialities={institution?.specialities} count={count} />
                </ul>
                <ShowMoreServices
                  services={specialities}
                  count={count}
                  setCount={setCount}
                />
              </div>
            </CardBody>
          </div>
        </Card>
      )}
      <SpecialitiesModal
        isOpen={isOpen}
        toggle={toggle}
        specialities={institution.specialities}
        institution={institution}
        getInstitutionProfile={getInstitutionProfile}
        practitionerId={637}
        setPractitioner={setPractitioner}
        isAdmin={isAdmin}
      />
    </>
  );
};

export default Specialities;
