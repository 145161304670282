import { useState, useEffect, useRef } from "react";
import { Field, useFormikContext } from "formik";
import ReactQuill from "react-quill";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import CityAutocompleteTypeaheadField from "components/forms/formikFields/CityAutocompleteTypeaheadField";
import LocationTypeaheadField from "components/forms/formikFields/LocationTypeaheadField";
import job_description from 'assets/images/job-icon-8.png';
import { MetadataService } from "utils/MetadataService";
import { FormGroup, Label } from "reactstrap";
import ToolTip from "common/ToolTip";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { APPLY_MODE_LIST } from "utils/Constants";
import edit_icon from 'assets/images/svgs/small-edit-btn.svg';

function JobCreateFormSeven(props) {
    const [applyMode, setApplyMode] = useState("EMAIL");
    const { values, setFieldValue } = useFormikContext();
    const locRef = useRef();
    const [address, setAddress] = useState({});
    const [locationList, setLocationList] = useState([]);
    const [showCityField, setShowCityField] = useState(values?.city[0] ? false : true);
    const [showLocationField, setShowLocationField] = useState(values?.location[0] ? false : true);

    useEffect(() => {
        if (!values.city || values.city.length === 0 || !values.city[0]?.id) {
            setFieldValue(`location`, []);
        } else {
            const cityId = values.city[0].id;
            if (cityId !== "") {
                MetadataService.getLocations(cityId).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        setLocationList(response.data || []);
                    }
                });
            }
        }
    }, [values.city, setFieldValue]);

    const selectdLocation = (locationName) => {
        setAddress({
            ...address,
            location: locationName,
        });
    };

    const handleApplyModeChange = (event) => {
        setApplyMode(event.target.value);
        setFieldValue("applyMode", event.target.value);
    };

    const handleLocationChange = (selected) => {
        if (selected && selected[0]?.customOption && selected[0]?.value != "") {
            setShowLocationField(false);
            setFieldValue("location", selected[0]?.value.split(','));
            // console.log(selected[0]?.value);
            // console.log(selected[0]?.value.split(','));
        } else if (selected && selected.length > 0 && selected[0] != "") {
            // console.log(selected);
            setShowLocationField(false);
            setFieldValue("location", selected);
        } else {

        }
        // console.log(selected);
        // console.log(selected[0]);
    }

    const handleCityChange = (city) => {
        setShowCityField(false);
        setShowLocationField(true);
    };

    return (
        <>
            <div className="nex-job-form-left-sec">
                <div className="nex-job-info">
                    <img src={job_description} alt="" className="graphic" />
                    <div className="conts">
                        <p><span className='light'>6/7</span> Job Post</p>
                        <h2 className='title'>Make the way more easier
                            for the candidate...</h2>
                    </div>
                </div>
            </div>
            <div className="nex-job-form-right-sec">
                <div className="row">
                    <div className="col-lg-6">
                        {showCityField ?
                            <div className="form-group mb-4">
                                <label className='bold'>City</label>
                                <Field
                                    name="city"
                                    id="city"
                                    autocomplete="city"
                                    type="text"
                                    component={CityAutocompleteTypeaheadField}
                                    placeholder="City"
                                    onChange={handleCityChange}
                                />
                            </div>
                            :
                            <div className="row mb-4">
                                <div className="col-lg-12">
                                    <label className='bold'>City</label>
                                    <div className="form-group-selected">
                                        <span>{values?.city[0].value ?? ""}</span>
                                        <div className="btn nex-floated-btn"><img onClick={() => setShowCityField(true)} src={edit_icon} alt="" /></div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col">
                        {showLocationField ?
                            <div className="form-group mb-4">
                                <label className='bold'>Location</label>
                                <Field
                                    id="location"
                                    name="location"
                                    type="text"
                                    component={LocationTypeaheadField}
                                    value={values.location}
                                    placeholder="Select Location"
                                    spellCheck={false}
                                    locations={locationList}
                                    selectdLocation={selectdLocation}
                                    locationRef={locRef}
                                    onChange={handleLocationChange}
                                />
                            </div>
                            :
                            <div className="row mb-4">
                                <div className="col-lg-12">
                                    <label className='bold'>Location</label>
                                    <div className="form-group-selected">
                                        <span>{values?.location[0] ?? ""}</span>
                                        <div className="btn nex-floated-btn"><img onClick={() => setShowLocationField(true)} src={edit_icon} alt="" /></div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group mb-4">
                            <FormGroup>
                                <Label for="applyMode">Apply Mode <ToolTip id="apply_mode_field" place="right">Please Enter Apply Mode</ToolTip></Label>
                                <Field
                                    id="applyMode"
                                    name="applyMode"
                                    type="text"
                                    component={FormikSelectField}
                                    onChange={handleApplyModeChange} // Add this line
                                    inputprops={{
                                        name: `applyMode`,
                                        options: APPLY_MODE_LIST,
                                        defaultOption: "Select",
                                        keys: {
                                            id: "value",
                                            label: "label",
                                        },
                                    }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="linkToApply">{applyMode === "EMAIL" ? "Provide your Email address" : "Provide External Link"} <ToolTip id="link_to_apply_field" place="right">Please Enter {applyMode === "EMAIL" ? "Email" : applyMode === "LINK" ? "External Link" : "Link to apply"}</ToolTip></Label>
                                <Field
                                    id="linkToApply"
                                    name="linkToApply"
                                    type="text"
                                    component={FormikInputField}
                                    placeholder={
                                        applyMode === "EMAIL"
                                            ? "Email"
                                            : "External Link"
                                    }
                                />
                            </FormGroup>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default JobCreateFormSeven;
