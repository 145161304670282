import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import approvalIcon from "assets/images/approval_icon.png";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import React, { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from "reactstrap";
import { ConnectionService } from "utils/ConnectionService";
import { FAILTOSAVERECOED } from "utils/Constants";

const RenderConnctBtn = (props) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );
  const [userConnectionStatus, setConnectionStatus] = useState(null);
  const [message, setMessage] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const { getConnectedCount } = props;
  const guid = currentUser.guid;
  const [isLoading, setIsLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
    getConnectionStatus();
  };

  const getConnectionStatus = () => {
    setConnectionStatus(null);
    ConnectionService.getConnectionStatus(guid, globalProfileInfo.guid)
      .then((response) => {
        let { data } = response;
        if (response.status === 200 || response.status === 201) {
          setConnectionStatus(data);
        } else {
          setConnectionStatus(null);
        }
      })
      .catch((error) => {
        console.log("Error while getting connection status: ", error);
        setConnectionStatus(null);
      });
  };

  const updateConnectionStatus = (actionStatus) => {
    const status = {
      action: actionStatus,
    };
    setIsLoading(true);
    ConnectionService.getConnectionStatus(guid, globalProfileInfo.guid)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          const { connectionId } = response?.data;
          if (connectionId) {
            return ConnectionService.updateConnection(
              status,
              connectionId,
              guid
            );
          }
          return false;
        }
        return false;
      })
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          showSuccessMessage("Connection request updated successfully.")
          getConnectionStatus();
        }
        return false;
      })
      .then(() => setIsLoading(false))
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(FAILTOSAVERECOED);
        console.log("Error while getting connection status: ", error);
      });
  };

  useEffect(() => {
    getConnectionStatus();
  }, [globalProfileInfo]);

  const handleClickOnConnect = () => {
    const payload = {
      message: message,
      receiverId: globalProfileInfo.guid,
    };
    setSubmitting(true);
    ConnectionService.addConnection(payload, guid)
      .then((response) => {
        let { data } = response;
        if (response.status === 200 || response.status === 201) {
          setConnectionStatus(data.status);
          toggleModal();
          showSuccessMessage("Connection request sent successfully.");
          setSubmitting(false);
          getConnectedCount();
        } else {
          showErrorMessage(FAILTOSAVERECOED);
          setConnectionStatus(null);
          setSubmitting(false);
        }
      })
      .catch((error) => {
        showErrorMessage(FAILTOSAVERECOED);
        console.log("Error while getting connection status: ", error);
        setConnectionStatus(null);
        setSubmitting(false);
      });
  };

  return (
    <React.Fragment>
      {(userConnectionStatus?.status === "SENT" ||
        userConnectionStatus?.status === "PENDING") && (
          <Button size="md" color="red" disabled={true} className="nexogic_primary_button_outline">
            Pending
          </Button>
        )}
      {userConnectionStatus?.status === "RECEIVED" &&
        (isLoading ? (
          <div className="loading-spiner text-center mt-2">
            <Spinner />
          </div>
        ) : (
          <div className="mt-2">
            <Button
              className="nexogic_primary_button"
              size="md"
              onClick={() => updateConnectionStatus("ACCEPTED")}
            >
              Accept
            </Button>
            <Button
              color="primary"
              outline
              size="md"
              className="nexogic_primary_button"
              onClick={() => updateConnectionStatus("REJECTED")}
            >
              Reject
            </Button>
          </div>
        ))}
      {userConnectionStatus?.status === "ACTIVE" && (
        <div className="nexogic-btn-group mt-0">
          <div className="connection_status_button">
            Connected <img src={approvalIcon} alt="verified icon" className="varify-img" />
          </div>
          <Link to="../messages">
            <Button className="nexogic_primary_button">
              Message
            </Button>
          </Link>
        </div>
      )}
      {userConnectionStatus?.status === "NONE" && (
        <Button onClick={toggleModal} size="md" className="nexogic_primary_button">
          Connect
        </Button>
      )}
      <Modal
        toggle={toggleModal}
        isOpen={isOpen}
        className="connectModal"
        backdrop="static"
      >
        <ModalHeader toggle={toggleModal}>
          Your Invitation is on it's way
        </ModalHeader>
        <ModalBody>
          <div className="text-quotes">
            <FontAwesomeIcon icon={faCheckCircle} />
            You can add a note to personalize your invitation to
            <span className="font-weight-bold ml-1">Connect.</span>
          </div>
          <FormGroup>
            <Input
              id="message"
              name="text"
              type="textarea"
              placeholder="Add a note here"
              onChange={(e) => setMessage(e.target.value)}
              rows={3}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button className="nexogic_primary_button_outline" onClick={toggleModal}>
            {" "}
            Cancel{" "}
          </Button>
          <Button
            className="nexogic_primary_button"
            disabled={isSubmitting}
            onClick={() => handleClickOnConnect()}
          >
            {" "}
            Send Request{" "}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default withAlert()(RenderConnctBtn);
