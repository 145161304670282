import { Field, Form, Formik, ErrorMessage } from "formik";
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Modal, ModalHeader, Row } from 'reactstrap'
import ThemeOne from 'assets/images/ui-icons/layout1.jpg'
import ThemeTwo from 'assets/images/ui-icons/layout2.jpg'
import ThemeThree from 'assets/images/ui-icons/layout3.jpg'
import * as Yup from "yup";
import ChooseTheme from "./Components/Themes/Components/ChooseTheme";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import secureLocalStorage from "react-secure-storage";
import './style.scss'
import configure_icon from "assets/images/configure-icon.png";
import CollapseablePannel from "common/CollapseablePannel";
import radio_tick from "assets/images/nex-completed-tick.png";

const initialValue = {
    theme: 'layouttwo',
    color0: '#e8f1ff', color1: '#6a8cbe', color2: '#8a8a8a', color3: '#e8f1ff', color4: '#6a8cbe', color5: '#8a8a8a',
}
const CreateInstitution = (props) => {
    // const [isOpen, setModal] = useState(false);
    // const toggle = () => setModal(!isOpen);
    const [selectedTheme, setSelectedTheme] = useState(initialValue);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();
    const validationSchema = Yup.object().shape({
        theme: Yup.string().required("Please select a Theme"),
    });

    const { passToPage } = history.location.state;
    const { institution, currentUserId, getCreatedUser, otherBranches, awards, practitioners, eThemeDetails } = passToPage;


    const onSubmit = (values) => {
        const pageData = { themecolors: [`${values.color0}`, `${values.color1}`, `${values.color2}`, `${values.color3}`, `${values.color4}`, `${values.color5}`], institutedetails: institution, otherBranches: otherBranches, practitioners: practitioners, awards: awards, layouttype: values.theme, eThemeDetails: eThemeDetails };
        secureLocalStorage.setItem("nexogic.temp_admin_inst_data", JSON.stringify(pageData))
        let themeTemplate = '';
        // if (values.theme === 'THEME2')
        //     themeTemplate = '/microsite/create-template/11'
        if (values.theme !== '')
            themeTemplate = '/microsite/custom-theme'
        window.location.href = `${themeTemplate}`;
        // history.push(`${themeTemplate}`, {
        // themename: values.theme,
        // data: ["About", "Services", "Specialities", "Amenities", "Business Hour", "Gallery", "Awards", "Other Branches", "Practitioners"],
        //     themecolors: values.color,
        //     institutedetails: institution,
        // });
    }
    return (
        <div className='container-fluid microsite-side-main'>
            <main className="nex-main-panel">
                <div className="nex-panel-head">
                    <h1 className='nex-text-xl'><span className="icon"><img src={configure_icon} alt="" width={30} /> </span> Create Your Website</h1>
                </div>
                <div className="nex-panel-body">

                    <div className="">
                        <Formik
                            initialValues={initialValue}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            children={(formProps) => {
                                return <Form>
                                    {/* <h2>Select your Theme</h2> */}
                                    <CollapseablePannel className="nex-small-gap" title="Select your Theme" noHeader={true}>
                                        <div role="group" aria-labelledby="my-radio-group" className="nex-pad-15">
                                            <h3 className="nex-text-xl small section-title">Select Your Layout</h3>

                                            <div className="nex-micro-theme-lists">
                                                <div className="nex-micro-theme-list">
                                                    <Field type="radio" className="nex-stratch-input" name="theme" id="layout-2" value="layouttwo" checked />
                                                    <div className="graphic">
                                                        <svg width="206" height="113" viewBox="0 0 206 113" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="206" height="19" rx="5" fill="#F7F7F7" />
                                                            <rect y="22" width="206" height="91" rx="5" fill="#F7F7F7" />
                                                            <circle cx="25.5" cy="45.5" r="13.5" fill="#D9D9D9" />
                                                            <rect x="49" y="42" width="145" height="3" fill="#D9D9D9" />
                                                            <rect x="49" y="48" width="103" height="3" fill="#D9D9D9" />
                                                        </svg>
                                                    </div>
                                                    <div className="nex-micro-tlist-label">
                                                        <span className='nex-radio-check'><img src={radio_tick} alt="" /></span>
                                                        Lightening
                                                    </div>

                                                </div>
                                                <div className="nex-micro-theme-list">
                                                    <Field type="radio" className="nex-stratch-input" name="theme" id="layout-1" value="layoutone" />
                                                    <div className="graphic">

                                                        <svg width="206" height="113" viewBox="0 0 206 113" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="206" height="19" rx="5" fill="#F7F7F7" />
                                                            <rect x="49" y="22" width="157" height="91" rx="5" fill="#F7F7F7" />
                                                            <rect y="22" width="44" height="91" rx="5" fill="#F7F7F7" />
                                                            <circle cx="22.5" cy="45.5" r="13.5" fill="#D9D9D9" />
                                                            <rect x="57" y="42" width="137" height="3" fill="#D9D9D9" />
                                                            <rect x="57" y="48" width="97" height="3" fill="#D9D9D9" />
                                                        </svg>

                                                    </div>
                                                    <div className="nex-micro-tlist-label">
                                                        <span className='nex-radio-check'><img src={radio_tick} alt="" /></span>
                                                        Lightening with Left Sidebar
                                                    </div>

                                                </div>
                                                <div className="nex-micro-theme-list">
                                                    <Field type="radio" className="nex-stratch-input" name="theme" id="layout-3" value="layoutthree" />
                                                    <div className="graphic">
                                                        <svg width="206" height="113" viewBox="0 0 206 113" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="206" height="19" rx="5" transform="matrix(-1 0 0 1 206 0)" fill="#F7F7F7" />
                                                            <rect width="157" height="91" rx="5" transform="matrix(-1 0 0 1 157 22)" fill="#F7F7F7" />
                                                            <rect width="44" height="91" rx="5" transform="matrix(-1 0 0 1 206 22)" fill="#F7F7F7" />
                                                            <circle cx="13.5" cy="13.5" r="13.5" transform="matrix(-1 0 0 1 37 32)" fill="#D9D9D9" />
                                                            <rect x="50" y="42" width="99" height="3" fill="#D9D9D9" />
                                                            <rect x="50" y="48" width="70" height="3" fill="#D9D9D9" />
                                                            <rect x="168" y="40" width="31" height="2" fill="#D9D9D9" />
                                                            <rect x="168" y="46" width="31" height="2" fill="#D9D9D9" />
                                                            <rect x="168" y="52" width="31" height="2" fill="#D9D9D9" />
                                                            <rect x="168" y="58" width="31" height="2" fill="#D9D9D9" />
                                                        </svg>

                                                    </div>
                                                    <div className="nex-micro-tlist-label">
                                                        <span className='nex-radio-check'><img src={radio_tick} alt="" /></span>
                                                        Lightening with Right Sidebar
                                                    </div>

                                                </div>
                                            </div>
                                            {/* {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />} */}
                                        </div>

                                        <div className="d-flex justify-content-end pl-3" style={{ fontSize: "1.2rem" }}>
                                            <div className='text-danger d-inline-block  mt-1'>
                                                <ErrorMessage name="theme">
                                                    {errorMsg => (errorMsg ? <RenderErrorMessage errorMsg={errorMsg} /> : null)}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                    </CollapseablePannel>
                                    <CollapseablePannel className="nex-small-gap" title="Choose Colors" noHeader={true}>
                                        {/* <div className="pt-4"> */}
                                        <div>
                                            <div className="nex-flex nex-micro-choose-colors">
                                            <ChooseTheme setSelectedTheme={setSelectedTheme} {...props} {...formProps} />
                                                {/* <div className="nex-micro-choose-color">
                                                    <div className="nex-text-lg text-black title">Page Colours</div>
                                                    <div className="nex-color-boxes">
                                                        <div className="nex-color-box">
                                                            <div className="color" style={{ backgroundColor: '#E8F1FF' }}></div>
                                                            <div className="label nex-text">Background</div>
                                                        </div>
                                                        <div className="nex-color-box">
                                                            <div className="color" style={{ backgroundColor: '#6A8CBE' }}></div>
                                                            <div className="label nex-text">Page Heading</div>
                                                        </div>
                                                        <div className="nex-color-box">
                                                            <div className="color" style={{ backgroundColor: '#8A8A8A' }}></div>
                                                            <div className="label nex-text">Page Text</div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="nex-micro-choose-color">
                                                    <div className="nex-text-lg text-black title">Section Colours</div>
                                                    <div className="nex-color-boxes">
                                                        <div className="nex-color-box">
                                                            <div className="color" style={{ backgroundColor: '#E8F1FF' }}></div>
                                                            <div className="label nex-text">Background</div>
                                                        </div>
                                                        <div className="nex-color-box">
                                                            <div className="color" style={{ backgroundColor: '#6A8CBE' }}></div>
                                                            <div className="label nex-text">Page Heading</div>
                                                        </div>
                                                        <div className="nex-color-box">
                                                            <div className="color" style={{ backgroundColor: '#8A8A8A' }}></div>
                                                            <div className="label nex-text">Page Text</div>
                                                        </div>

                                                    </div>
                                                </div> */}


                                            </div>
                                            <div className="d-flex flex-column pl-0" style={{ fontSize: "1.2rem" }}>

                                                <div className="colorbox-container">
                                                    <div className="w-100 mb-0">
                                                        {/* <ChooseTheme setSelectedTheme={setSelectedTheme} {...props} {...formProps} /> */}
                                                    </div>

                                                </div>
                                                <div className="w25 colorbox-btns nex-action">
                                                    {/* <div> */}
                                                    <Button
                                                        id="nex_cancel_edit"
                                                        className="nex-btn-primary-outline"
                                                        type="button"
                                                        onClick={() => history.goBack()}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        id="nex_next_edit"
                                                        className="nex-btn-primary next-btn"
                                                        type="submit"
                                                    // disabled={isSubmitting}
                                                    >
                                                        Next
                                                    </Button>
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </CollapseablePannel>

                                </Form>
                            }}
                        >
                        </Formik>
                    </div>

                </div>
            </main>
        </div>
    )
}

export default CreateInstitution