import React from 'react'
import { Row, Col, Button, InputGroup } from 'reactstrap'
import { Field } from 'formik';
import FormikInputField from "components/forms/formikFields/FormikInputField"

const EmailForm = (props) => {
  const { handleSubmit, isValid, dirty, isSubmitting } = props
  
  return(
    <form onSubmit={handleSubmit}>
      <div>
        <Row>
          <Col xs={12} className="text-left">
              <InputGroup>
                <Field
                  id="ef_email"
                  name="email"
                  type="email"
                  component={FormikInputField}
                  placeholder="Enter your email address" />
              </InputGroup>
            </Col>
          </Row>
          <Button id='ef_btn_submit' disabled={!isValid || !dirty || isSubmitting} className="mt-3" color="primary" size="lg" block>Send</Button>
      </div>
    </form>
  )
}

export default EmailForm