import { Modal, ModalBody, ModalHeader } from "reactstrap";
import AwardInstituteForm from "./InstituteAwardForm";

export const RenderInstituteAwardModal = (props) => {
  const { isOpen, toggle } = props;

  return (
    <div>
      <Modal
        backdrop="static"
        isOpen={isOpen}
        toggle={toggle}
        id="profile-modal"
        scrollable={true}
        centered={true}
      >
        <ModalHeader toggle={toggle} className="nex-institutions-modal-header"><span className="nex-text-xl">Awards</span></ModalHeader>
        <ModalBody className="bg-white">
          <AwardInstituteForm {...props} />
        </ModalBody>
      </Modal>
    </div>
  );
};
