import React from 'react'
import {
  Card,
  Col,
  Row,
} from "reactstrap";
import UIComponents from './Components/UIComponents';
import UISidebar from './Components/UISidebar';
import "./style.scss"
import { useState } from 'react';


const UIDashboard = () => {
  const [isMenuOn, setIsMenuOn] = useState(false);
  return (
    <div className='container-fluid microsite-side-main'>
      <main>
        <Row>
          <Col md={12} lg={3} className='nex-resp-sidebar-w'>
            <Row>
              <Col className=''>
                <div className={`nex-resp-sidebar ${isMenuOn ? 'menu-visible' : ''}`} >
                  <div className="nex-mob-menu" onClick={() => { setIsMenuOn(!isMenuOn) }}>Menu <span className='icon' >
                    <svg width="18" height="18" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.7586 15.2537L21.6785 14.0525C21.8885 12.9197 21.8885 11.7576 21.6785 10.6248L23.7586 9.42363C23.9979 9.28691 24.1053 9.00371 24.0272 8.74004C23.4852 7.00175 22.5623 5.42949 21.3563 4.1209C21.1707 3.9207 20.868 3.87187 20.6336 4.00859L18.5535 5.20976C17.6795 4.45781 16.6736 3.87675 15.5848 3.4959V1.09843C15.5848 0.824997 15.3943 0.585739 15.1258 0.527145C13.3338 0.126755 11.4979 0.146286 9.79376 0.527145C9.5252 0.585739 9.33477 0.824997 9.33477 1.09843V3.50078C8.25079 3.88652 7.24493 4.46758 6.36602 5.21465L4.29083 4.01347C4.05157 3.87675 3.75372 3.9207 3.56817 4.12578C2.36212 5.42949 1.43926 7.00175 0.897271 8.74492C0.814264 9.00859 0.926568 9.29179 1.16583 9.42851L3.2459 10.6297C3.03594 11.7625 3.03594 12.9246 3.2459 14.0574L1.16583 15.2586C0.926568 15.3953 0.819146 15.6785 0.897271 15.9422C1.43926 17.6805 2.36212 19.2527 3.56817 20.5613C3.75372 20.7615 4.05645 20.8103 4.29083 20.6736L6.3709 19.4725C7.24493 20.2244 8.25079 20.8055 9.33965 21.1863V23.5887C9.33965 23.8621 9.53008 24.1014 9.79864 24.16C11.5906 24.5603 13.4266 24.5408 15.1307 24.16C15.3992 24.1014 15.5897 23.8621 15.5897 23.5887V21.1863C16.6736 20.8006 17.6795 20.2195 18.5584 19.4725L20.6385 20.6736C20.8777 20.8103 21.1756 20.7664 21.3611 20.5613C22.5672 19.2576 23.49 17.6853 24.032 15.9422C24.1053 15.6736 23.9979 15.3904 23.7586 15.2537ZM12.4598 16.2449C10.3065 16.2449 8.55352 14.492 8.55352 12.3387C8.55352 10.1853 10.3065 8.43242 12.4598 8.43242C14.6131 8.43242 16.366 10.1853 16.366 12.3387C16.366 14.492 14.6131 16.2449 12.4598 16.2449Z" fill="#2D4552" />
                    </svg></span></div>
                  <div className="nex-resp-sidebar-content">
                    <Card className='mb-2'>
                      <div className='main-header'>UI Components</div>
                    </Card>
                    <UISidebar />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={12} lg={9} >
            <UIComponents />
          </Col>
        </Row>
      </main>
    </div>

  )
}

export default UIDashboard