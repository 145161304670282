import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import FormikCheckboxField from "components/forms/formikFields/FormikCheckboxField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikInputMonthYearFormatField from "components/forms/formikFields/FormikInputMonthYearFormatField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import SpecialityTypeaheadField from "components/forms/formikFields/SpecialityTypeaheadField";
import { Field, FieldArray, Formik } from "formik";
import _ from "lodash";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import InstitutesTypeaheadFieldNew from "pages/Onboarding/components/FormComponents/InstitutesTypeaheadFieldNew";
import NewInstituitionSection from "pages/Onboarding/components/FormComponents/NewInstituitionSection";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { withAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Button, Col, InputGroup, Label, Row, Spinner } from "reactstrap";
import { setGlobalprofile } from "redux-helpers/ActionCreator";
import { FAILTOSAVERECOED } from "utils/Constants";
import { MetadataService } from "utils/MetadataService";
import { PractitionerService } from "utils/PractitionerService";
import { getAddressName, getLocationName } from "utils/Utils";
import add_arrow from "assets/svgIcon/plus-add-icon.svg";
import * as Yup from "yup";
export const yearFormat = /^[12][0-9]{3}$/;


const validationSchema = () =>
  Yup.object().shape({
    educationFormData: Yup.array().of(
      Yup.object().shape({
        institutionDetail: Yup.lazy((value) => {
          if (value && value.onSelectNewMedicalCollege === 0) {
            return Yup.object().test(
              "",
              "This field is required. You must select from options or add new",
              () => false
            );
          } else if (value && value.onSelectNewMedicalCollege === 1) {
            return Yup.object();
          } else if (value && value.onSelectNewMedicalCollege === 2) {
            return Yup.object();
          } else {
            return Yup.mixed().test(
              "",
              "This field is required. You must select from options or add new",
              (value) => value === {}
            );
          }
        }),
        city: Yup.array().when("institutionDetail.onSelectNewMedicalCollege", {
          is: 2,
          then: Yup.array()
            .min(1, "This field is required")
            .required("This field is required"),
        }),
        state: Yup.array().when("institutionDetail.onSelectNewMedicalCollege", {
          is: 2,
          then: Yup.array()
            .min(1, "This field is required")
            .required("This field is required"),
        }),
        country: Yup.array().when(
          "institutionDetail.onSelectNewMedicalCollege",
          {
            is: 2,
            then: Yup.array()
              .min(1, "This field is required")
              .required("This field is required"),
          }
        ),
        location: Yup.array().when(
          "institutionDetail.onSelectNewMedicalCollege",
          {
            is: 2,
            then: Yup.array()
              .min(1, "This field is required")
              .required("This field is required"),
          }
        ),
        mainItemName: Yup.string().when(
          "institutionDetail.onSelectNewMedicalCollege",
          {
            is: 2,
            then: Yup.string().required("This field is required"),
          }
        ),
        postalCode: Yup.string().when(
          "institutionDetail.onSelectNewMedicalCollege",
          {
            is: 2,
            then: Yup.string().required("This field is required"),
          }
        ),
        websiteAddress: Yup.string().when(
          "institutionDetail.onSelectNewMedicalCollege",
          {
            is: 2,
            then: Yup.string().when("websiteAddress", {
              is: (val) => val !== "",
              then: Yup.string().url("Please enter a valid URL"),
              otherwise: Yup.string(),
            }),
          }
        ),
        addressLine1: Yup.string().when(
          "institutionDetail.onSelectNewMedicalCollege",
          {
            is: 2,
            then: Yup.string().required("This field is required"),
          }
        ),
        category: Yup.string().when(
          "institutionDetail.onSelectNewMedicalCollege",
          {
            is: 2,
            then: Yup.string().required("This field is required"),
          }
        ),
        degree: Yup.string().required("This field is required"),
        isStudying: Yup.bool().default(false),
        fieldOfStudy: Yup.array()
          .min(1, "This field is required")
          .required("This field is required"),
        startYear: Yup.string()
          .matches(yearFormat, "Please enter valid format (YYYY)")
          .required("This field is required"),
        endYear: Yup.string().when("isStudying", {
          is: false,
          then: Yup.string()
            .matches(yearFormat, "Please enter valid format (YYYY)")
            .required("This field is required")
            .test({
              name: "startYear",
              message: "End Year should be greater than Start year",
              test: function (value) {
                if (this.parent.startYear && value) {
                  const fullStart = this.parent.startYear.split("-");
                  const fullEnd = value.split("-");
                  if (fullStart.length > 0 && fullEnd.length > 0) {
                    const [startYearM, startYearY] = fullStart;
                    const [endYearM, endYearY] = fullEnd;
                    return startYearY + startYearM < endYearY + endYearM;
                  }
                  return false;
                }
                return false;
              },
            }),
          otherwise: Yup.string(),
        }),
      })
    ),
  });

const initialValuesObj = {
  institutionDetail: {
    institutionName: "",
    guid: "",
    onSelectNewMedicalCollege: 0,
  },
  addressLine2: "",
  city: [],
  country: [],
  location: [],
  postalCode: "",
  state: [],
  addressType: "BILLING_ADDRESS",
  degree: "",
  fieldOfStudy: [],
  startYear: "",
  endYear: "",
  additionalInfo: "",
  websiteAddress: "",
  mainItemName: "",
  category: "",
  addressLine1: "",
  id: "",
  isStudying: false,
};

const initialValues = {
  educationFormData: [{ ...initialValuesObj }],
};

const RenderForm = (props) => {
  const { index } = props;
  const [nestedModal, setNestedModal] = useState(false);
  const [degreeList, setdegreeList] = useState([]);
  const [field_Of_Stud_List, setField_Of_Stud_List] = useState([]);
  const { values, setFieldValue } = props;
  const location = useLocation();
  const specialityTypeaheadRef = useRef();

  const onNewInstitute = (index) => {
    toggleNested(index);
  };

  const toggleNested = (index) => {
    setNestedModal(!nestedModal);
    setFieldValue(
      `educationFormData[${index}].institutionDetail.onSelectNewMedicalCollege`,
      2
    );
    setFieldValue(
      `educationFormData[${index}].institutionDetail.institutionName`,
      "Other"
    );
    setFieldValue(`educationFormData[${index}].institutionDetail.guid`, "");
  };

  useEffect(() => {
    MetadataService.getDegree()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setdegreeList(response.data);
        }
      })
      .catch((error) => {
        console.log("MetadataService.getDegree error", error);
      });
  }, [location]);

  const handleChange = useCallback(
    (event) => {
      if (event.target.value) {
        MetadataService.getCourses(event.target.value)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              setField_Of_Stud_List([...response.data]);
            }
          })
          .catch((error) => {
            console.log("MetadataService.getDegree error", error);
          });
      }
      setFieldValue(`educationFormData[${index}].degree`, event.target.value);
      setFieldValue(`educationFormData[${index}].fieldOfStudy`, []);
    },
    [index, setFieldValue]
  );

  const handleChangeStuding = useCallback(
    (event) => {
      setFieldValue(
        `educationFormData[${index}].isStudying`,
        event.target.value
      );
      setFieldValue(`educationFormData[${index}].endYear`, "");
    },
    [index, setFieldValue]
  );

  return (
    <>
      <Row form id="nex-educationFormData-f">
        <Col lg={12} id="nex-ef-university-w">
          <Label>Medical College/University</Label>
          <Field
            name={`educationFormData[${index}].institutionDetail`}
            id={`educationFormData[${index}].institutionDetail`}
            autocomplete={`educationFormData[${index}].institutionDetail`}
            type="text"
            component={InstitutesTypeaheadFieldNew}
            onNewInstituteReq={(index) => onNewInstitute(index)}
            placeholder="Choose your Medical's college / University name"
            parentIndex={index}
          />
        </Col>
      </Row>
      {values?.educationFormData[index]?.institutionDetail
        ?.onSelectNewMedicalCollege === 2 && (
        <NewInstituitionSection {...props} index={index} />
      )}
      <Row form>
        <Col lg={4}>
          <Label>Degree</Label>
          <Field
            id={`educationFormData[${index}].degree`}
            name={`educationFormData[${index}].degree`}
            autocomplete={`educationFormData[${index}].degree`}
            type="text"
            component={FormikSelectField}
            onChange={handleChange}
            inputprops={{
              name: `educationFormData[${index}].degree`,
              options: degreeList,
              defaultOption: "Choose",
            }}
          />
        </Col>
        <Col lg={8} id="nex-ef-field-of-study">
          <Label>Field Of Study</Label>
          <Field
            id={`educationFormData[${index}].fieldOfStudy`}
            name={`educationFormData[${index}].fieldOfStudy`}
            autocomplete={`educationFormData[${index}].fieldOfStudy`}
            type="text"
            component={SpecialityTypeaheadField}
            value={values[`educationFormData`][index].fieldOfStudy}
            placeholder="Field Of Study"
            spellCheck={false}
            data={field_Of_Stud_List}
            selectdItem={(input) => {
              if (input) {
                setFieldValue(
                  `educationFormData[${index}].fieldOfStudy`,
                  input
                );
              }
            }}
            itemRef={specialityTypeaheadRef}
          />
        </Col>
      </Row>
      <Row form>
        <Col lg={4}>
          <div className="form-group">
            <label className="d-block">Start Year</label>
            <Field
                  name={`educationFormData[${index}].startYear`}
                  id={`educationFormData[${index}].startYear`}
                  autoComplete="off"
                  type="text"
                  component={FormikInputField}
                  placeholder="YYYY"
                  value={values[`educationFormData`][index].startYear}
                />
          </div>
        </Col>
        <Col lg={4}>
          <div className="form-group">
            <label className="d-block">End Year</label>
            {values?.educationFormData[index]?.isStudying ? (
                  <Field
                    name={`Presenttime`}
                    id={`Presenttime`}
                    type="text"
                    placeholder="Present time"
                    autocomplete="Presenttime"
                    value="Present time"
                    disabled
                    className="form-control"
                  />
                ) : (
                  <Field
                    name={`educationFormData[${index}].endYear`}
                    id={`educationFormData[${index}].endYear`}
                    autocomplete={`educationFormData[${index}].endYear`}
                    type="text"
                    component={FormikInputField}
                    placeholder="YYYY"
                    value={values[`educationFormData`][index].endYear}
                  />
                )}
            
            
          </div>
        </Col>
        <Col lg={4}>
        <div className="nexogix-form-check-expire-date">
        <label className="d-xs-none">&nbsp;</label>
        <InputGroup className="">
          
            
              <Field
                name={`educationFormData[${index}].isStudying`}
                id={`educationFormData[${index}].isStudying`}
                autocomplete={`educationFormData[${index}].isStudying`}
                type="checkbox"
                component={FormikCheckboxField}
                onClick={handleChangeStuding}
              />
              <label
                htmlFor={`educationFormData[${index}].isStudying`}
                className="form-check-label cs-study"
              >
                Currently Studing
              </label>
              
            </InputGroup>
            </div>
        </Col>
      </Row>
      <Row form>
        <Col lg={12}>
          <Field
            name={`educationFormData[${index}].additionalInfo`}
            autocomplete={`educationFormData[${index}].additionalInfo`}
            id="additionalInfo"
            type="textarea"
            label="Additional Information"
            rows={4}
            cols={5}
            component={FormikInputField}
            placeholder="Add some text here"
          />
        </Col>
      </Row>
    </>
  );
};

const RenderEducationForm = (props) => {
  const { dirty, handleSubmit, isLoading, toggle } = props;
  const [addmore, setAddmore] = useState(false);

  useEffect(() => {
    const id = _.reduce(
      props?.values.educationFormData,
      function (accumulated, e) {
        return accumulated + e.id;
      },
      ""
    );
    setAddmore(id === "");
  }, [props?.values]);

  return (
    <form className="" onSubmit={handleSubmit}>
      <FieldArray
        name="educationFormData"
        render={(arrayHelpers) => (
          <>
            {props.values.educationFormData.map((item, index) => (
              <Fragment key={index}>
                {index !== 0 && <div className="horizontal-line my-3"></div>}
                <RenderForm {...props} index={index} />
              </Fragment>
            ))}
            <Row form className="">
              <Col lg={6}>
                {addmore && (
                  <Button
                    id="nex-edu-more-btn"
                    onClick={() => {
                      props.validateForm().then((res) => {
                        if (!_.has(res, "educationFormData")) {
                          arrayHelpers.push({ ...initialValuesObj });
                        } else {
                          arrayHelpers.form.validateForm();
                          arrayHelpers.form.setTouched({
                            educationFormData:
                              arrayHelpers.form.values.educationFormData.map(
                                () => {
                                  return {
                                    institutionDetail: true,
                                    mainItemName: true,
                                    category: true,
                                    addressLine1: true,
                                    addressLine2: true,
                                    country: true,
                                    state: true,
                                    city: true,
                                    postalCode: true,
                                    location: true,
                                    websiteAddress: true,
                                    degree: true,
                                    fieldOfStudy: true,
                                    startYear: true,
                                    endYear: true,
                                    additionalInfo: true,
                                    isStudying: true,
                                  };
                                }
                              ),
                          });
                        }
                      });
                    }}
                    className="nexogic-trans-icon-btn"
                    type="button"
                  >
                    <span className="nexogic-fill-icon-small">
                      {/** <FontAwesomeIcon icon={faPlus} />*/}
                      <img src={add_arrow} alt="" />
                    </span>
                    <span className="text">Add more Education</span>
                  </Button>
                )}
              </Col>
              {props.values.educationFormData.length > 1 && (
                <Col lg={6} className="d-flex">
                  <div
                    onClick={() =>
                      arrayHelpers.remove(
                        props.values.educationFormData.length - 1
                      )
                    }
                    className="w-100 d-flex flex-row justify-content-end align-items-center"
                  >
                    <p className="m-0 text-danger btn-link">
                      Remove this education
                    </p>
                  </div>
                </Col>
              )}
            </Row>
          </>
        )}
      />
      {/*<hr className="profile-info-modal-hr mt-0" />*/}
      <div className="justify-content-end  modal-footer">
        <Col xs="auto" className="action-group">
          {isLoading ? (
            <div className="loading-spiner">
              <Spinner />
            </div>
          ) : (
            <>
              
              <Button
                id="ef_btn_submit"
                className="nexogic_primary_button "
                disabled={!dirty}
                color="primary"
                type="submit"
              >
                Save
              </Button>
              <Button
                id="ef_btn_cancel"
                className="nexogic_primary_button_outline"
                color="primary"
                type="button"
                onClick={toggle}
              >
                Cancel
              </Button>
            </>
          )}
        </Col>
      </div>
    </form>
  );
};

const EducationForm = (props) => {
  const { selectedItem, getData, toggle, alert } = props;
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");
  const formikRef = useRef();
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );
  const dispatch = useDispatch();

  const getFieldOfStudyName = (value) => {
    try {
      if (value.length > 0) {
        if (value[0]?.customOption) {
          return value[0]?.value;
        }
        return value[0];
      }
      return "";
    } catch (error) {
      console.log("get Field Of StudyName", error);
      return "";
    }
  };

  const updateGlobalprofileStorage = (guid) => {
    PractitionerService.gePractitionersDetails(guid).then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(setGlobalprofile(response.data));
      }
    });
  };

  const addItem = async (values) => {
    const userGUID = globalProfileInfo.guid;
    const formData = values.educationFormData.map((node) => {
      return {
        additionalInfo: node?.additionalInfo,
        courseTitle: node?.degree,
        guid: userGUID,
        institutionDetail: {
          ...(!node.institutionDetail.guid && {
            address: {
              addressLine1: node?.addressLine1 ?? "",
              addressLine2: node?.addressLine2 ?? "",
              addressType: node?.addressType ?? "BILLING_ADDRESS",
              city: getAddressName(node?.city),
              state: getAddressName(node?.state),
              country: getAddressName(node?.country),
              location: getLocationName(node?.location),
              postalCode: node?.postalCode ?? "",
            },
          }),
          ...(node.institutionDetail.guid && {
            guid: node.institutionDetail.guid,
          }),
          name: node.mainItemName,
          phone: node?.phone ?? "",
          websiteUrl: node?.websiteAddress ?? "",
          ...(node.category && {
            type: node.category,
          }),
        },
        ...(node.isStudying === false && {
          passingYear: node.endYear,
        }),
        specialization: getFieldOfStudyName(node?.fieldOfStudy),
        startYear: node.startYear,
        isStudying: node.isStudying,
      };
    });
    PractitionerService.registerEducationsDetails(formData, userGUID, true)
      .then(async (response) => {
        const { status, validationErrors } = response;
        if (status === 201) {
          await getData();
          setLoading(false);
          updateGlobalprofileStorage(globalProfileInfo?.guid);
          toggle();
          showSuccessMessage("Education added successfully");
        } else {
          setLoading(false);
          if (
            validationErrors &&
            validationErrors &&
            validationErrors.length > 0
          ) {
            const error = [];
            validationErrors.forEach((errors) => {
              error.push(errors.message);
            });
            showErrorMessage(error.join(","));
          } else {
            showErrorMessage(FAILTOSAVERECOED);
          }
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setLoading(false);
        console.log(error);
      });
  };

  const updateItem = async (values, id) => {
    const userGUID = globalProfileInfo.guid;
    const node = values.educationFormData[0];
    const formData = {
      additionalInfo: node?.additionalInfo,
      courseTitle: node?.degree,
      guid: userGUID,
      institutionDetail: {
        ...(!node.institutionDetail.guid && {
          address: {
            addressLine1: node?.addressLine1 ?? "",
            addressLine2: node?.addressLine2 ?? "",
            addressType: node?.addressType ?? "BILLING_ADDRESS",
            city: getAddressName(node?.city),
            state: getAddressName(node?.state),
            country: getAddressName(node?.country),
            location: getLocationName(node?.location),
            postalCode: node?.postalCode ?? "",
          },
        }),
        ...(node.institutionDetail.guid && {
          guid: node.institutionDetail.guid,
        }),
        name: node.mainItemName,
        phone: node?.phone ?? "",
        websiteUrl: node?.websiteAddress ?? "",
        ...(node.category && {
          type: node.category,
        }),
      },
      ...(node.isStudying === false && {
        passingYear: node.endYear,
      }),
      specialization: getFieldOfStudyName(node?.fieldOfStudy),
      startYear: node.startYear,
      isStudying: node.isStudying,
    };

    PractitionerService.updateEducationsDetails(formData, userGUID, id)
      .then(async (response) => {
        const { status, validationErrors } = response;
        if (status === 200) {
          await getData();
          setLoading(false);
          updateGlobalprofileStorage(globalProfileInfo?.guid);
          toggle();
          showSuccessMessage("Education updated successfully");
        } else {
          setLoading(false);
          if (
            validationErrors &&
            validationErrors &&
            validationErrors.length > 0
          ) {
            const error = [];
            validationErrors.forEach((errors) => {
              error.push(errors.message);
            });
            showErrorMessage(error.join(","));
          } else {
            showErrorMessage(FAILTOSAVERECOED);
          }
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (selectedItem) {
      const { setFieldValue } = formikRef.current;
      setFieldValue(
        `educationFormData[0].institutionDetail.guid`,
        selectedItem.institutionDetail.guid
      );
      setFieldValue(
        `educationFormData[0].institutionDetail.institutionName`,
        selectedItem?.institutionDetail?.institutionName ?? ""
      );
      setFieldValue(
        `educationFormData[0].mainItemName`,
        selectedItem?.institutionDetail?.institutionName ?? ""
      );
      setFieldValue(`educationFormData[0].degree`, selectedItem.degree);
      setFieldValue(
        `educationFormData[0].isStudying`,
        selectedItem?.isStudying
      );
      setFieldValue(
        `educationFormData[0].fieldOfStudy`,
        selectedItem.degree
          ? selectedItem.fieldOfStudy
            ? [selectedItem.fieldOfStudy]
            : []
          : []
      );
      setFieldValue(
        `educationFormData[0].startYear`,
        selectedItem?.startYear ?? ""
      );
      setFieldValue(
        `educationFormData[0].endYear`,
        selectedItem?.endYear ?? ""
      );
      setFieldValue(
        `educationFormData[0].additionalInfo`,
        selectedItem.additionalInfo ?? ""
      );
      setFieldValue(`educationFormData[0].id`, selectedItem?.id);
      if (selectedItem?.id) {
        setFieldValue(
          `educationFormData[0].institutionDetail.onSelectNewMedicalCollege`,
          1
        );
      }
    }
  }, [selectedItem]);

  return (
    <>
      {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          let id = _.reduce(
            values.educationFormData,
            function (accumulated, e) {
              return accumulated + e.id;
            },
            ""
          );
          if (id) {
            updateItem(values, id);
          } else {
            addItem(values);
          }
        }}
        children={(props) => {
          return (
            <>
              <RenderEducationForm
                toggle={toggle}
                {...props}
                isLoading={isLoading}
              />
            </>
          );
        }}
      ></Formik>
    </>
  );
};
export default withAlert()(EducationForm);
