import { Field, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import FormikInputField from 'components/forms/formikFields/FormikInputField';
import { useEffect } from 'react';

const JobExperienceEditModal = (props) => {
    const { modal, setModal } = props;
    const { values, setFieldValue } = useFormikContext();
    const [experienceLevel, setExperienceLevel] = useState(null);
    const [minExperience, setMinExperience] = useState(values?.minExperience);
    const [maxExperience, setMaxExperience] = useState(values?.maxExperience);
    const [showInputs, setShowInputs] = useState(false);

    const handleSubmit = () => {
        if (!showInputs) {
            setFieldValue('minExperience', minExperience);
            setFieldValue('maxExperience', maxExperience);
        }
        setModal(false);
    };

    const handleExperienceLevelChange = (event) => {
        const selectedLevel = event.target.value;
        setFieldValue('experienceLevel', selectedLevel);

        // Set default values based on selectedLevel
        if (selectedLevel === 'beginner') {
            setShowInputs(false);
            setMinExperience(0);
            setMaxExperience(5);
        } else if (selectedLevel === 'mid') {
            setShowInputs(false);
            setMinExperience(6);
            setMaxExperience(10);
        } else if (selectedLevel === 'expert') {
            setShowInputs(false);
            setMinExperience(11);
            setMaxExperience(30);
        } else {
            setShowInputs(true);
        }
    };

    useEffect(() => {
        if (maxExperience <= 5) {
            setExperienceLevel("beginner");
        } else if (maxExperience <= 10) {
            setExperienceLevel("mid");
        } else if (maxExperience >= 10) {
            setExperienceLevel("expert");
        } else {
            setExperienceLevel(null);
        }
    }, [minExperience, maxExperience])


    return (
        <Modal isOpen={modal} toggle={() => setModal(!modal)} className="nex-model-v2 modal-dialog-scrollable modal-dialog-centered">
            <ModalHeader toggle={() => setModal(!modal)}>Edit Job Experience</ModalHeader>
            <ModalBody>
                <div className="nex-job-form-right-sec">
                    <h2 className="nex-text-xl-small mb-4">How many years of experience are you looking for?</h2>
                    <div className="form-group nex-radio-content-group">
                        <div className="cus-radio-w">
                            <input
                                type="radio"
                                name="experienceLevel"
                                value="beginner"
                                onChange={handleExperienceLevelChange}
                                checked={experienceLevel === 'beginner'}
                            />
                        </div>
                        <div className="info">
                            <h3 className="card-title">Beginner</h3>
                            <p>Someone who has an experience of 0 to 5 years</p>
                        </div>
                    </div>
                    <div className="form-group nex-radio-content-group">
                        <div className="cus-radio-w">
                            <input
                                type="radio"
                                name="experienceLevel"
                                value="mid"
                                onChange={handleExperienceLevelChange}
                                checked={experienceLevel === 'mid'}
                            />
                        </div>
                        <div className="info">
                            <h3 className="card-title">Mid-Level</h3>
                            <p>Someone who has an experience of 6 to 10 years</p>
                        </div>
                    </div>
                    <div className="form-group nex-radio-content-group">
                        <div className="cus-radio-w">
                            <input
                                type="radio"
                                name="experienceLevel"
                                value="expert"
                                onChange={handleExperienceLevelChange}
                                checked={experienceLevel === 'expert'}
                            />
                        </div>
                        <div className="info">
                            <h3 className="card-title">Expert</h3>
                            <p>Someone who has an experience of more than 10 years</p>
                        </div>
                    </div>
                    <div className="form-group nex-radio-content-group">
                        <div className="cus-radio-w">
                            <input
                                type="radio"
                                name="experienceLevel"
                                value=""
                                onChange={handleExperienceLevelChange}
                                checked={showInputs}
                            />
                        </div>
                        <div className="info">
                            <h3 className="card-title">Add your own</h3>
                            <p>I would like to set the experience</p>
                        </div>
                    </div>
                    {showInputs && (
                        <div className="nex-flex nex-gap-30 nex-job-experience">
                            <div className="form-group nex-flex nex-gap-16">
                                <Field
                                    className="nex-max-w-100 nex-gap-16"
                                    id="minExperience"
                                    name="minExperience"
                                    autoComplete="minExperience"
                                    type="number"
                                    min="0"
                                    //label={"Min Experience"}
                                    component={FormikInputField}
                                    placeholder="Min"
                                />
                                <div className="label">Years</div>
                            </div>
                            <div className="form-group nex-flex nex-gap-16">
                                <Field
                                    className="nex-max-w-100"
                                    id="maxExperience"
                                    name="maxExperience"
                                    autoComplete="maxExperience"
                                    type="number"
                                    //label={"Max Experience"}
                                    min="0"
                                    component={FormikInputField}
                                    placeholder="Max"
                                />
                                <div className="label">Years</div>
                            </div>
                        </div>
                    )}
                </div>
                <button className="btn nex-btn-primary ml-auto" onClick={handleSubmit}>Save</button>
            </ModalBody>
        </Modal>
    );
};

export default JobExperienceEditModal;
