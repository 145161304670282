import { faEnvelope, faRupeeSign, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from 'react';
import { Button, Card, CardBody, Collapse, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useParams } from "react-router-dom";
import { useEffect } from 'react';
import { TalentService } from 'utils/TalentService';
import { showErrorMessage, showSuccessMessage } from 'common/AlertContainer';
import { FAILED_TO_FETCH_DATA, FAILTOSAVERECOED } from 'utils/Constants';
import { convertJobShiftToTitleCase, getFullAddressWithLocationCityAndState } from 'utils/Utils';
import CommonLoader from "common/CommonLoader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function JobPreview() {
    const { jobId } = useParams();
    const [jobInfo, setJobInfo] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [qualifications, setQualifications] = useState([]);
    const history = useHistory();

    const getJobInfo = (jobId) => {
        TalentService.getJob(jobId)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setJobInfo(data);
                    setQualifications(data.qualifications.split(', ').map(item => item.trim()));
                    setIsLoading(false);
                } else {
                    showErrorMessage(FAILED_TO_FETCH_DATA);
                    history.push(`/404`);
                    setIsLoading(false);
                }
            })
    }

    useEffect(() => {
        if (jobId)
            getJobInfo(jobId);
    }, [jobId])

    const ShowSalary = ({ min, max }) => {
        if ((!min && !max) || min === '0' && max === '0')
            return (
                <p>Negotiable</p>
            )
        else if (min && !max) {
            return (
                <p>
                    <span>
                        <FontAwesomeIcon
                            icon={faRupeeSign}
                            className="ml-2 mr-1 text-gray f-13"
                        />{min || 0}
                    </span>
                </p>
            );
        }
        else if (!min && max) {
            return (
                <p>
                    <span>
                        <FontAwesomeIcon
                            icon={faRupeeSign}
                            className="ml-2 mr-1 text-gray f-13"
                        />
                        {max || 0}
                    </span>
                </p>
            );
        }
        else {
            return (
                <p>
                    <span>
                        <FontAwesomeIcon
                            icon={faRupeeSign}
                            className="ml-2 mr-1 text-gray f-13"
                        />
                        {min || 0} -{" "}
                        <FontAwesomeIcon
                            icon={faRupeeSign}
                            className="ml-2 mr-1 text-gray f-13"
                        />
                        {max || 0}
                    </span>
                </p>
            );
        }
    }

    const updateJob = (statusToUpdate) => {
        const payload = {
            title: jobInfo.title,
            institutionId: jobInfo.institutionId,
            position: jobInfo.position,
            opportunity: jobInfo.opportunity,
            city: jobInfo?.city,
            location: jobInfo?.location,
            status: statusToUpdate,
            primarySpeciality: jobInfo?.primarySpeciality,
            description: jobInfo?.description,
            shift: jobInfo.shift,
            skills: jobInfo.skills,
            applyMode: jobInfo.applyMode,
            linkToApply: jobInfo.linkToApply,
            qualifications: jobInfo.qualifications,
            noOfPosition: jobInfo.noOfPosition,
        };

        TalentService.updateJob(payload, jobInfo.id)
            .then((response) => {
                const { status } = response;
                if (status === 200) {
                    if (statusToUpdate === 'SUBMITTED')
                        showSuccessMessage("Job Submitted Successfully...");
                    else
                        showSuccessMessage("Job Published Successfully...");
                    getJobInfo(jobId);
                } else {
                    showErrorMessage(FAILTOSAVERECOED);
                }
            })
            .catch((error) => {
                showErrorMessage(FAILTOSAVERECOED);
            });
    }

    const handleBack = () => {
        history.goBack();
    }

    return (
        <>
            {isLoading
                ?
                <div className="loading-spiner text-center">
                    <CommonLoader />
                </div>
                :
                <>
                    <div className="nex-job-flex">
                        <div className="nex-job-container">
                            <div className="nex-job-preview-card">
                                <div className="nex-title-card">
                                    <h1 className='primary-title'>Job Preview</h1>
                                    {jobInfo?.status === 'DRAFT' &&
                                        <a href={`/new/update-jobs/${jobInfo.id}`} className='btn nex-btn-primary ml-auto'>Edit this Job</a>
                                    }
                                </div>
                                <div className='nex-job-preview-content'>
                                    <div className="nex-jobs-details-card open">
                                        <h3 className="card-title">{jobInfo?.title}{"("}{jobInfo?.primarySpeciality}{")"}</h3>
                                        <p className='card-title-small'>{getFullAddressWithLocationCityAndState({ location: jobInfo?.institutionDetails?.address?.location ?? "", city: jobInfo?.institutionDetails?.address?.city ?? "", state: jobInfo?.institutionDetails?.address?.state ?? "" })}</p>

                                        <div className="nex-jobs-quick-item">
                                            <div className="item"><div className="icon"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.66406 7.33333C3.1578 7.33333 2.7474 7.74374 2.7474 8.25V17.4167C2.7474 17.9229 3.1578 18.3333 3.66406 18.3333H18.3307C18.837 18.3333 19.2474 17.9229 19.2474 17.4167V8.25C19.2474 7.74374 18.837 7.33333 18.3307 7.33333H3.66406ZM0.914062 8.25C0.914062 6.73122 2.14528 5.5 3.66406 5.5H18.3307C19.8495 5.5 21.0807 6.73122 21.0807 8.25V17.4167C21.0807 18.9355 19.8495 20.1667 18.3307 20.1667H3.66406C2.14528 20.1667 0.914062 18.9355 0.914062 17.4167V8.25Z" fill="#636363" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.21952 2.64139C7.73524 2.12567 8.43472 1.83594 9.16406 1.83594H12.8307C13.5601 1.83594 14.2595 2.12567 14.7753 2.64139C15.291 3.15712 15.5807 3.85659 15.5807 4.58594V19.2526C15.5807 19.7589 15.1703 20.1693 14.6641 20.1693C14.1578 20.1693 13.7474 19.7589 13.7474 19.2526V4.58594C13.7474 4.34282 13.6508 4.10966 13.4789 3.93776C13.307 3.76585 13.0738 3.66927 12.8307 3.66927H9.16406C8.92095 3.66927 8.68779 3.76585 8.51588 3.93776C8.34397 4.10966 8.2474 4.34282 8.2474 4.58594V19.2526C8.2474 19.7589 7.83699 20.1693 7.33073 20.1693C6.82447 20.1693 6.41406 19.7589 6.41406 19.2526V4.58594C6.41406 3.85659 6.70379 3.15712 7.21952 2.64139Z" fill="#636363" />
                                            </svg>
                                            </div> <ShowSalary min={jobInfo?.minSalary} max={jobInfo?.maxSalary} /> - {jobInfo?.position && convertJobShiftToTitleCase(jobInfo?.position)} - Senior Level
                                            </div>
                                            <div className="item"><div className="icon"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 5.5026C8.25 4.99634 8.66041 4.58594 9.16667 4.58594H19.25C19.7563 4.58594 20.1667 4.99634 20.1667 5.5026C20.1667 6.00887 19.7563 6.41927 19.25 6.41927H9.16667C8.66041 6.41927 8.25 6.00887 8.25 5.5026Z" fill="#636363" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 11.0026C8.25 10.4963 8.66041 10.0859 9.16667 10.0859H19.25C19.7563 10.0859 20.1667 10.4963 20.1667 11.0026C20.1667 11.5089 19.7563 11.9193 19.25 11.9193H9.16667C8.66041 11.9193 8.25 11.5089 8.25 11.0026Z" fill="#636363" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 16.5026C8.25 15.9963 8.66041 15.5859 9.16667 15.5859H19.25C19.7563 15.5859 20.1667 15.9963 20.1667 16.5026C20.1667 17.0089 19.7563 17.4193 19.25 17.4193H9.16667C8.66041 17.4193 8.25 17.0089 8.25 16.5026Z" fill="#636363" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.85442 3.93255C5.2124 3.57457 5.7928 3.57457 6.15079 3.93255C6.50877 4.29053 6.50877 4.87093 6.15079 5.22891L4.31745 7.06224C3.95947 7.42022 3.37907 7.42022 3.02109 7.06224L2.10442 6.14558C1.74644 5.7876 1.74644 5.2072 2.10442 4.84921C2.4624 4.49123 3.0428 4.49123 3.40079 4.84921L3.66927 5.1177L4.85442 3.93255Z" fill="#636363" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.85442 9.43255C5.2124 9.07457 5.7928 9.07457 6.15079 9.43255C6.50877 9.79053 6.50877 10.3709 6.15079 10.7289L4.31745 12.5622C3.95947 12.9202 3.37907 12.9202 3.02109 12.5622L2.10442 11.6456C1.74644 11.2876 1.74644 10.7072 2.10442 10.3492C2.4624 9.99123 3.0428 9.99123 3.40079 10.3492L3.66927 10.6177L4.85442 9.43255Z" fill="#636363" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.85442 14.9325C5.2124 14.5746 5.7928 14.5746 6.15079 14.9325C6.50877 15.2905 6.50877 15.8709 6.15079 16.2289L4.31745 18.0622C3.95947 18.4202 3.37907 18.4202 3.02109 18.0622L2.10442 17.1456C1.74644 16.7876 1.74644 16.2072 2.10442 15.8492C2.4624 15.4912 3.0428 15.4912 3.40079 15.8492L3.66927 16.1177L4.85442 14.9325Z" fill="#636363" />
                                            </svg>
                                            </div> Expertise : {(jobInfo?.skills && jobInfo?.skills.length > 0) && jobInfo?.skills.map((e, index) => (
                                                <span key={index}>{e}</span>
                                            ))}
                                            </div>
                                        </div>
                                    </div>

                                    <hr />
                                    <h3 className="nex-text-xl nex-job-section-title mt-4">Full Job Description</h3>

                                    <div className="nex-item mt-3">
                                        <div className="nex-info full">
                                            <div
                                                dangerouslySetInnerHTML={{ __html: jobInfo?.description }}
                                            />
                                        </div>


                                    </div>
                                    <div className="nex-item">
                                        <div className="nex-info full">
                                            <div className="card-title">Work Shift</div>
                                            <div className="card-text">{jobInfo?.position && jobInfo?.position != "" && convertJobShiftToTitleCase(jobInfo?.position)}</div>

                                        </div>
                                    </div>
                                    <div className="nex-item">
                                        <div className="nex-info full">
                                            <div className="card-title">Required Skills</div>
                                            <div className="nex-pill-group">
                                                {(jobInfo?.skills && jobInfo?.skills.length > 0) && jobInfo?.skills.map((e, index) => (
                                                    <span class="nex-pill nex-pill-primary" key={index}>{e}</span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nex-item full">
                                        <div className="nex-info full">
                                            <div className="card-title">Required Qualification</div>
                                            <div className="nex-pill-group">
                                                {(qualifications && qualifications.length > 0) && qualifications.map((e, index) => (
                                                    <span class="nex-pill nex-pill-primary" key={index}>{e}</span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nex-item">
                                        <div className="nex-info full">
                                            <div className="card-title">City</div>
                                            <div className="card-text">{jobInfo?.city}</div>

                                        </div>
                                    </div>
                                    <div className="nex-item">
                                        <div className="nex-info full">
                                            <div className="card-title">Location</div>
                                            <div className="card-text">{jobInfo?.location}</div>
                                        </div>
                                    </div>

                                    <div className="nex-item">
                                        <div className="nex-info full">
                                            <div className="card-title">Apply Mode</div>
                                            <div className="card-text">{jobInfo?.applyMode}</div>

                                        </div>
                                    </div>
                                    <div className="nex-item">
                                        <div className="nex-info full">
                                            <div className="card-title">Link</div>
                                            <div className="card-text">{jobInfo?.linkToApply}</div>

                                        </div>
                                    </div>
                                    {jobInfo?.screeningQuestions && jobInfo?.screeningQuestions.length > 0 &&
                                        <div className="nex-item">
                                            <div className="nex-info full">
                                                <div className="card-title">Screening Question</div>
                                                {jobInfo?.screeningQuestions.map((e) => (
                                                    <div className="card-text">{e?.question}</div>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                            <div className="nex-action-sec">
                                {jobInfo?.status === 'DRAFT' &&
                                    <span href="" className='btn nex-btn-primary' onClick={() => handleBack()}>Back</span>
                                }
                                {jobInfo?.status === 'DRAFT' &&
                                    <span href="" className='btn nex-btn-primary ml-auto' onClick={() => updateJob("SUBMITTED")}>Submit this Job</span>
                                }
                                {jobInfo?.status === 'SUBMITTED' &&
                                    <span href="" className='btn nex-btn-primary ml-auto' onClick={() => updateJob("PUBLISHED")} >Publish this Job</span>
                                }
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default JobPreview;