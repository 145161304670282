import React, { useState, PureComponent, useEffect } from "react"
import { Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from 'reactstrap'
import { Cropper } from "react-image-cropper"
import { withAlert } from "react-alert"
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment"
import b64toBlob from "b64-to-blob"
import unsplashIcon from 'assets/images/svgs/unsplash.svg';
import camera_icon from 'assets/images/camera-icon.png';
import delIcon from "assets/images/del-red-icon.png";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
// import CoverImage from "assets/images/defaultBannerPage.png"
import CoverImage from "assets/images/defaultBannerNew.png"


import { validateImageType, validateImageSize, getBannerUrl } from 'utils/Utils'
import { InstitutionServices } from "utils/InstitutionServices";
import swal from "sweetalert";

export class RenderDialog extends PureComponent {
    handleCroppImage = async () => {
        const { uploadImageDetails, onSave, toggle } = this.props;
        const cropImageBase64 = this.cropper.crop();
        const result = await this.resizeBase64Img(cropImageBase64);
        const unixTime = moment().unix();
        const res = await fetch(result);
        const blob = await res.blob();
        const file = new File([blob], `${unixTime}_${uploadImageDetails.name}`, {
            type: uploadImageDetails.type,
        });
        const validSize = validateImageSize(file, 1048576);
        toggle();
        if (validSize === 1) {
            onSave(file);
        } else {
            this.handleCancel();
            showErrorMessage("Image size should be less than 1 MB");
        }

    }

    resizeBase64Img = async (base64Str, MAX_WIDTH = 1450, MAX_HEIGHT = 290) => {
        return await new Promise((resolve) => {
            let img = new Image();
            img.src = base64Str;
            img.onload = () => {
                let canvas = document.createElement("canvas");
                let width = img.width;
                let height = img.height;
                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                let ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                resolve(canvas.toDataURL()); // this will return base64 image results after resize
            };
        });
    };

    handleCancel = () => {
        const { onSave, toggle } = this.props;
        toggle();
        onSave("");
    };

    render() {
        const { modal, toggle, profilePicUrl } = this.props
        return (
            <>
                <Modal isOpen={modal} toggle={toggle} className="modal-dialog-scrollable modal-dialog-centered modal-crop">
                    <ModalHeader toggle={toggle}>Banner Image</ModalHeader>
                    <ModalBody className="px-1 px-sm-4">
                        <div style={{ overflow: "hidden" }}
                            className="crop-disabled d-flex align-items-center justify-content-center mx-auto">
                            <Cropper
                                src={profilePicUrl}
                                ref={ref => { this.cropper = ref }}
                                ratio={5 / 1}
                            />

                        </div>
                    </ModalBody>
                    <ModalFooter className="justify-content-end  modal-footer ">
                        <div className="action-group">
                            <div className="banner-image-caption"><p>The image size must be less than 5 mb and it should be 1000 px wide and 200 px tall. </p></div>
                            <Button color="primary" className="nex-btn-primary" onClick={(e) => this.handleCroppImage(e)}>Save</Button>
                            <Button color="link" className="nex-btn-primary-outline" onClick={(e) => this.handleCancel(e)}>Cancel</Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

}

const RenderInstitutionCoverImageUI = (props) => {
    const { isEditable, isAdmin, alert, bannerUrl, institution } = props
    const [modal, setModal] = useState(false);
    const [showSpinner, setSpinner] = useState(false);
    const [uProgress, setUProgress] = useState(0);
    // const [imageDropdown, setimageDropdownOpen] = useState(false);
    // const imageDropdownToggle = () => setimageDropdownOpen(!imageDropdown);
    const [uploadImageDetails, setImageDetails] = useState({ name: "", type: "" })
    const [uploadImage, setImage] = useState("");
    const [sourceImage, setSourceImage] = useState("");
    const toggle = () => setModal(!modal);
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.auth.currentUser)

    const handleImageUpload = (event) => {
        let file = event.target.files[0]

        // fileType validation
        if (validateImageType(file)) {
            // fileSize validation
            const maxSize = 1000 * 1000 * 10; //10MB
            const validSize = validateImageSize(file, maxSize)
            if (validSize === 1) {
                let reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                    const imageBase64 = reader.result
                    setImageDetails({ name: file.name, type: file.type })
                    setImage(imageBase64)
                    toggle()
                }
                // getSignUrl(file)
            } else if (validSize === -1) {
                showErrorMessage('Invalid File!')
            } else {
                showErrorMessage('File must be less than 10MB')
            }
        } else {
            showErrorMessage('Invalid File!')
        }
    }

    const handleImageDelete = () => {
        swal("Are you sure you want to remove banner image?", {
            buttons: ["cancel", "yes"],
        })
            .then((yesRemove) => {
                if (yesRemove)
                    InstitutionServices.deleteInstitutionImage(institution.guid, "banner")
                        .then((response) => {
                            const { status, data } = response;
                            if (status === 204) {
                                showSuccessMessage("Banner Image deleted successfully");
                                institution.bannerId = null;
                                onLoadingDefaultImage(null, []);
                            }
                            else {
                                showErrorMessage("Something went wrong, Please try again later");
                            }
                        })
            })
    }

    const onLoadingDefaultImage = async (guidId, imageId) => {
        let imageSet = "";
        if (guidId && imageId) {
            imageSet = `${process.env.REACT_APP_IMAGE_BASE_URL}/${guidId}/${imageId}`;
        }
        setImage(imageSet);
        setSourceImage(imageSet);
    };


    useEffect(() => {
        onLoadingDefaultImage(institution.guid, institution.bannerId)
    }, [institution.guid, institution.bannerId])

    const onImageChange = (file) => {
        if (file) {
            setSpinner(true);
            const options = (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent <= 100) {
                    setUProgress(percent);
                }
            }
            InstitutionServices.new_fileUpload(file, institution.guid, "banner", options).then(
                (response) => {
                    if (response.status === 200 || response.status === 201) {
                        onLoadingDefaultImage(institution?.guid, response.data);
                        institution.bannerId = response.data;
                        setSpinner(false);
                        showSuccessMessage("Banner Image uploaded successfully");
                        if (uProgress <= 100) {
                            setTimeout(() => {
                                setUProgress(0)
                            }, 1000);
                        }
                    } else {
                        showErrorMessage("Something went wrong while uploading your profile picture");
                        setSpinner(false);
                    }
                },
                (err) => {
                    setSpinner(false);
                    onLoadingDefaultImage(institution?.guid, "");
                    console.log("failed banner upload", err.message);
                    showErrorMessage(err.message);
                }
            );
        } else {
            setSpinner(false);
            if (institution?.bannerId) {
                onLoadingDefaultImage(institution?.guid, institution?.bannerId);
            } else {
                onLoadingDefaultImage(institution?.guid, "");
            }
        }
    };

    return (
        <div className="card-cover">
            <RenderDialog
                modal={modal}
                toggle={toggle}
                profilePicUrl={uploadImage}
                uploadImageDetails={uploadImageDetails}
                onSave={onImageChange}
                alert={alert}
            />
            {uProgress > 0 ? (
                <div className="nexogic-banner-progress nexogic-profile-pbar">
                    <Progress value={uProgress} max="100">{uProgress}</Progress>
                </div>
            ) : null}
            {/* {
                showSpinner ?
                    <div className="position-absolute w-100 profile-loader">
                        <Spinner color="success" />
                    </div> : null
            } */}

            {<img src={institution.bannerId ? sourceImage : CoverImage} className="card-img" alt="#" style={{borderRadius:" 5px 5px 0 0"}} />}

            {
                isEditable &&
                    isAdmin &&
                <>
                    <div className="actions">

                        {institution?.bannerId &&
                            <Button id="ri_btn_cover_del" className="delete-cover-btn"
                                onClick={() => handleImageDelete()}
                            >
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M9.24994 4.75C9.24994 3.23122 10.4812 2 11.9999 2C13.5187 2 14.7499 3.23122 14.7499 4.75V4.91565C17.1349 5.2059 19.363 5.86798 21.3285 6.8258C21.7008 7.00725 21.8556 7.4562 21.6741 7.82855C21.4927 8.20091 21.0437 8.35566 20.6714 8.1742C18.1797 6.95998 15.2042 6.25 11.9999 6.25C8.79567 6.25 5.82012 6.95998 3.32848 8.1742C2.95613 8.35566 2.50718 8.20091 2.32572 7.82855C2.14427 7.4562 2.29902 7.00725 2.67137 6.8258C4.63686 5.86797 6.86496 5.2059 9.24994 4.91565V4.75ZM18.2653 18.283C18.1158 20.3763 16.374 21.998 14.2755 21.998H9.72445C7.62589 21.998 5.88413 20.3763 5.73461 18.283L5.04639 8.6479C7.16371 7.90959 9.5187 7.49805 12 7.49805C14.4812 7.49805 16.8362 7.90959 18.9536 8.6479L18.2653 18.283ZM10.75 10.998C10.75 10.5838 10.4142 10.248 9.99997 10.248C9.58576 10.248 9.24997 10.5838 9.24997 10.998V16.998C9.24997 17.4123 9.58576 17.748 9.99997 17.748C10.4142 17.748 10.75 17.4123 10.75 16.998V10.998ZM14 10.248C14.4142 10.248 14.75 10.5838 14.75 10.998V16.998C14.75 17.4123 14.4142 17.748 14 17.748C13.5858 17.748 13.25 17.4123 13.25 16.998V10.998C13.25 10.5838 13.5858 10.248 14 10.248Z"
                                        fill="#C93B3C"
                                    />
                                </svg>

                            </Button>
                        }
                        <Button id="ri_btn_cover_edit" className="edit-cover-btn">
                            {/* <FontAwesomeIcon icon={faCamera} className="fa-fw f-12" /> */}
                            <svg
                                width={20}
                                height={18}
                                viewBox="0 0 20 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6.40627 0.890599L5 3H4C1.79086 3 0 4.79086 0 7V14C0 16.2091 1.79086 18 4 18H16C18.2091 18 20 16.2091 20 14V7C20 4.79086 18.2091 3 16 3H15L13.5937 0.8906C13.2228 0.334202 12.5983 0 11.9296 0H8.07037C7.40166 0 6.7772 0.334202 6.40627 0.890599ZM13 10.5C13 12.1569 11.6569 13.5 10 13.5C8.34315 13.5 7 12.1569 7 10.5C7 8.84315 8.34315 7.5 10 7.5C11.6569 7.5 13 8.84315 13 10.5ZM10 4C10.5523 4 11 3.55228 11 3C11 2.44772 10.5523 2 10 2C9.44771 2 9 2.44772 9 3C9 3.55228 9.44771 4 10 4Z"
                                    fill="#28303F"
                                />
                            </svg>

                            <span>Edit cover photo</span>
                            <input
                                name="coverImage"
                                className="fileUploadForm"
                                type="file"
                                onChange={(event) => {
                                    handleImageUpload(event)
                                    event.target.value = null;
                                }}
                                id="coverImage"
                                accept="image/*, png, jpg, jpeg"
                                title=""
                            />
                        </Button>
                    </div>

                </>
            }
            {/* <img src={sourceImage ? sourceImage : CoverImage} className="cover-img" alt="#" /> */}
        </div>
    )
}

export default withAlert()(RenderInstitutionCoverImageUI)