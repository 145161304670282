import CollapseablePannel from 'common/CollapseablePannel'
import React, { useState } from 'react'
import { withAlert } from 'react-alert';
import ReactDOMServer from 'react-dom/server';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import ThemeOne from 'pages/UIDashboard/Components/CustomThemeDesign/ThemeOne';
import ThemeTwo from 'pages/UIDashboard/Components/CustomThemeDesign/ThemeTwo';
import ThemeThree from 'pages/UIDashboard/Components/CustomThemeDesign/ThemeThree';
import Swal from 'sweetalert2';
import { InstitutionServices } from 'utils/InstitutionServices';
import PreviewWindow from 'pages/UIDashboard/Components/CustomThemeDesign/PreviewWindow';
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

import configure_icon from "assets/images/configure-icon.png";
import configure_radio_icon from "assets/images/configure-radio-icon.png";
import configure_active_icon from "assets/images/configure-active-icon.png";
import radio_tick from "assets/images/nex-completed-tick.png";


const ConfigurePage = (props) => {
    const { institution, instituteThemeDetails, otherBranches, practitioners, awards, currentUserId } = props;
    const history = useHistory();
    const [Loader, setLoader] = useState(false);
    const [modal, setModal] = useState(false);
    const toggle = () => {
        setModal(!modal);
    };

    const passToPage = {
        institution: institution,
        currentUserId: currentUserId,
        otherBranches: otherBranches,
        awards: awards,
        practitioners: practitioners,
        eThemeDetails: instituteThemeDetails
    }

    const selectTheme = async (id) => {
        if (id === 'customtheme') {
            const content = (
                <div className='d-flex flex-column'>
                    <h2 className='text-dark'>Are you sure you want to update your current Microsite ?</h2>
                </div>
            );
            const { isConfirmed } = await Swal.fire({
                title: ' ',
                html: ReactDOMServer.renderToString(content),
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Yes',
            });

            if (isConfirmed) {
                history.push("/create-webpage", { passToPage });
            }
        }
        if (id === 'themedefault') {
            const content = (
                <div className='d-flex flex-column'>
                    <h2 className='text-dark'>Are you sure you want to update your current Microsite ?</h2>
                </div>
            );
            const { isConfirmed } = await Swal.fire({
                title: ' ',
                html: ReactDOMServer.renderToString(content),
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Yes',
            });

            if (isConfirmed) {
                setLoader(true);
                InstitutionServices.updateDisplayPreferences(instituteThemeDetails?.inst, {})
                    .then((response) => {
                        const { status, data } = response;
                        if (status === 200) {
                            showSuccessMessage("Preferences updated successfully");
                        }
                        else {
                            showErrorMessage("Something went wrong, Please try again later");
                        }
                    })
                setLoader(false);
                history.push(`/institution/${institution?.profileId}`);
            }
        }
    }

    return (
        <div className='admin-configure nex-main-panel'>
            <div className="nex-panel-head">
                <h1 className='nex-text-xl'><span className="icon"><img src={configure_icon} alt="" width={30} /> </span> Configure your Microsite</h1>
            </div>
            <CollapseablePannel className="nex-small-gap" title="Configure your Microsite" noHeader={true}>
                <div className="nex-pad-15">

                    {/* <div className="card">
                        <div className="card-body">
                            <h2 className="nex-text-xl pb-2">Your Current Microsite</h2>
                            <div className="nex-radio-image-options">
                                {instituteThemeDetails?.themeContainerType === 'layoutone' ?
                                    <div className="item active">
                                        <div className='radio-group justify-content-center'>
                                            <div className='preview' onClick={toggle} title="Preview">
                                                <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <line x1="5.5" y1="3" x2="5.5" y2="8" stroke="#3EABCA" />
                                                    <line x1="8" y1="5.5" x2="3" y2="5.5" stroke="#3EABCA" />
                                                    <path d="M11 11L8.58333 8.58333M9.88889 5.44444C9.88889 7.89904 7.89904 9.88889 5.44444 9.88889C2.98985 9.88889 1 7.89904 1 5.44444C1 2.98985 2.98985 1 5.44444 1C7.89904 1 9.88889 2.98985 9.88889 5.44444Z" stroke="#3EABCA" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            <div className="graphic">
                                                <svg width="60" height="64" viewBox="0 0 60 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="17" y="17" width="43" height="47" rx="5" fill="#0087b9" fillOpacity="0.8" />
                                                    <rect width="60" height="13" rx="5" fill="#0087b9" />
                                                    <rect y="17" width="13" height="47" rx="5" fill="#0087b9" />
                                                </svg>
                                            </div></div>
                                        <div className="label text-info" title='Lightening with Left Sidebar'>Lightening(LS)</div>
                                    </div>
                                    : instituteThemeDetails?.themeContainerType === 'layouttwo' ?
                                        <div className="item active">
                                            <div className='radio-group justify-content-center'>
                                                <div className='preview' onClick={toggle} title="Preview">
                                                    <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <line x1="5.5" y1="3" x2="5.5" y2="8" stroke="#3EABCA" />
                                                        <line x1="8" y1="5.5" x2="3" y2="5.5" stroke="#3EABCA" />
                                                        <path d="M11 11L8.58333 8.58333M9.88889 5.44444C9.88889 7.89904 7.89904 9.88889 5.44444 9.88889C2.98985 9.88889 1 7.89904 1 5.44444C1 2.98985 2.98985 1 5.44444 1C7.89904 1 9.88889 2.98985 9.88889 5.44444Z" stroke="#3EABCA" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                                <div className="graphic">
                                                    <svg width="60" height="64" viewBox="0 0 60 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect y="17" width="60" height="47" rx="5" fill="#0087b9" fillOpacity="0.8" />
                                                        <rect width="60" height="13" rx="5" fill="#0087b9" />
                                                    </svg>
                                                </div></div>
                                            <div className="label text-info" title='Lightening with No Sidebar'>Lightening</div>
                                        </div>
                                        : instituteThemeDetails?.themeContainerType === 'layoutthree' ?
                                            <div className="item active">
                                                <div className='radio-group justify-content-center'>
                                                    <div className='preview' onClick={toggle} title="Preview">
                                                        <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <line x1="5.5" y1="3" x2="5.5" y2="8" stroke="#3EABCA" />
                                                            <line x1="8" y1="5.5" x2="3" y2="5.5" stroke="#3EABCA" />
                                                            <path d="M11 11L8.58333 8.58333M9.88889 5.44444C9.88889 7.89904 7.89904 9.88889 5.44444 9.88889C2.98985 9.88889 1 7.89904 1 5.44444C1 2.98985 2.98985 1 5.44444 1C7.89904 1 9.88889 2.98985 9.88889 5.44444Z" stroke="#3EABCA" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>
                                                    <div className="graphic"><svg width="60" height="64" viewBox="0 0 60 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect y="17" width="43" height="47" rx="5" fill="#0087b9" fillOpacity="0.8" />
                                                        <rect width="60" height="13" rx="5" fill="#0087b9" />
                                                        <rect x="47" y="17" width="13" height="47" rx="5" fill="#0087b9" />
                                                    </svg>
                                                    </div>
                                                </div>
                                                <div className="label text-info" title='Lightening with Right Sidebar'>Lightening(RS)</div>
                                            </div>
                                            :
                                            <div className="item active">
                                                <div className='radio-group justify-content-center'>

                                                    <div className="graphic">
                                                        <svg width="60" height="64" viewBox="0 0 60 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect y="20" width="43" height="47" rx="2" fill="#0087b9" fillOpacity="0.8" />
                                                            <rect width="60" height="10" rx="2" fill="#0087b9" />
                                                            <rect y="12" width="60" height="5" rx="2" fill="#0087b9" />
                                                            <rect x="47" y="20" width="13" height="47" rx="2" fill="#0087b9" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="label text-info">Standard</div>
                                            </div>
                                }
                                <div>{instituteThemeDetails.themeContainerType}</div>
                            </div>
                        </div>
                    </div> */}

                    <h2 className="nex-text-lg text-black pb-2">Available Themes</h2>
                    <div className="nex-radio-boxes">
                        <div className="nex-radio-box">
                            <input type="radio" name="radAvailTheme" id="customtheme" onClick={() => selectTheme('customtheme')} />
                            <div className="radio-backgdrops"></div>
                            <span className='radio-check'>
                                <img src={radio_tick} alt="" />
                            </span>
                            <div className="graphic">
                                <img src={configure_active_icon} alt="" />
                            </div>

                            <div className="info">
                                <div className="nex-text-xl small label">Lightening</div>
                                <div className="nex-text">This is an editable layout, you can customise your view.</div>
                            </div>
                        </div>
                        <div className="nex-radio-box ">
                            <input type="radio" name="radAvailTheme" id="themedefault" onClick={() => selectTheme('themedefault')} />
                            <div className="radio-backgdrops"></div>
                            <span className='radio-check'>
                                <img src={radio_tick} alt="" />
                            </span>
                            <div className="graphic">
                                <img src={configure_radio_icon} alt="" />
                            </div>

                            <div className="info">
                                <div className="nex-text-xl small label">Standard (Default)</div>
                                <div className="nex-text">For the standard layout, you will get a standard design.</div>
                            </div>
                        </div>
                    </div>
                    
                    <Button className='btn nex-button-outline nex-current-btn' onClick={(e) => { e.preventDefault(); toggle(); }}>
                            <span className="icon">
                                <svg width={18} height={18} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.66406 1.16797V14.168M1.16406 7.66797H14.1641M12.7196 7.66797C12.7196 10.4601 10.4562 12.7235 7.66406 12.7235C4.87196 12.7235 2.60851 10.4601 2.60851 7.66797C2.60851 4.87586 4.87196 2.61241 7.66406 2.61241C10.4562 2.61241 12.7196 4.87586 12.7196 7.66797Z" stroke="#dbddeb" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>&nbsp;
                            Preview Current Microsite
                        </Button>
                </div>
            </CollapseablePannel>
            <Modal isOpen={modal} toggle={toggle} backdrop="static" scrollable centered style={{ maxWidth: '1200px' }}>
                <ModalHeader toggle={toggle} className="nex-institutions-modal-header"><span className="nex-text-xl">Preview</span></ModalHeader>
                <ModalBody style={instituteThemeDetails && Object.keys(instituteThemeDetails).length > 0 ? { backgroundColor: instituteThemeDetails?.themeColor[0] } : {}}>
                    <PreviewWindow themeDetails={instituteThemeDetails?.themeColor} content={instituteThemeDetails} institution={institution} otherBranches={otherBranches} practitioners={practitioners} awards={awards} previewFlag={true} />
                </ModalBody>
            </Modal>
        </div>
    )
}

export default withAlert()(ConfigurePage);
