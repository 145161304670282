import React, { useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import Invitation from "./Invitation";
import Pending from "./Pending";
import PeopleKnowWidget from "common/PeopleKnowWidget";
const GroupInvitation = () => {
  const [activeTab, setActiveTab] = useState("0");
  const handleToggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div className="nex-container row">
      <div className="nex-app-no-pad nex-lists-content col">
        <div className="nex-pill-group mb-3">
          <span className="nex-pill active md" onClick={() => {
                handleToggle("0");
              }}>Invitation</span>
              <span className="nex-pill outline md" onClick={() => {
                handleToggle("1");
              }}>Pending</span>
        </div>
        <Nav tabs className="nexogic-tabs d-none">
          <NavItem>
            <NavLink
              id="nex-gi-invitation-link"
              className={{
                active: activeTab === "0",
              }}
              onClick={() => {
                handleToggle("0");
              }}
            >
              Invitation
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              id="nex-gi-Pending-link"
              className={{
                active: activeTab === "1",
              }}
              onClick={() => {
                handleToggle("1");
              }}
            >
              Pending
            </NavLink>
          </NavItem>
        </Nav>
        {activeTab === "0" && <Invitation activeTab={activeTab} />}
        {activeTab === "1" && <Pending activeTab={activeTab} nosidebar={true} />}
      </div>
      <div className="nex-sidebar v2 col">
        <PeopleKnowWidget speciality={"Acupuncturist"} />
      </div>
    </div>
  );
};

export default GroupInvitation;
