import React from 'react'
import { useState } from 'react'
import SearchPractitioner from './SearchPractitioner';

export default function PractitionerSearchField(props) {
    const [hasSearch, setHasSearch] = useState(true);
    const [searchStatus, setSearchStatus] = useState('nosend');
    const [searchValue, setSearchValue] = useState('');
    const [searchtimer, setSearchtimer] = useState(null);
    const [searchResult, setSearchResult] = useState([{name:"Dr. Anamica Mitra", hospital:"Amri hospital", deg:"MBBs"},
    {name:"Dr. Adam Placeres ", hospital:"Golden Oak Clinic", deg:"MBBs"},
    {name:"Dr. Ethel Hinz ", hospital:"Valley Health Medical Center", deg:"MBBs"}]);
    const [hasAssignDrops, setHasAssignDrops] = useState(false);
    const [assignType, setAssignType] = useState('Practitioner');


    function getSearchData(e){
        setTimeout(()=>{
            setSearchStatus('success');
            setHasSearch(true);
            setSearchValue('');
            e.target.value = ''
            let data = [
                {name:"Dr. Anamica Mitra", hospital:"Amri hospital", deg:"MBBs"},
                {name:"Dr. Adam Placeres ", hospital:"Golden Oak Clinic", deg:"MBBs"},
                {name:"Dr. Ethel Hinz ", hospital:"Valley Health Medical Center", deg:"MBBs"},
            ];
            setSearchResult(data);
        }, 1500)
    }

    function handleSearch(e){
        setSearchValue(e.target.value)
        clearTimeout(searchtimer)

        const newTimer = setTimeout(() => {
            setSearchStatus('pending');
            getSearchData(e);
        }, 500)

        setSearchtimer(newTimer)
    }
    return (
        <div className="form-group nexogic-practi-search-fields">
            <label>{props.label}</label>
            <div className="nexogic-drop-select-search d-none" >
                <div className="nexogic-drops">
                    <span className="visible-label" onClick={()=>setHasAssignDrops(true)}>{assignType}</span>
                    {/* use on class to on the drops */}
                    <ul className={`drops ${hasAssignDrops ? 'on' : ''}`}>
                        <li onClick={()=>{setHasAssignDrops(false); setAssignType('Practitioner')}}>Practitioner</li>
                        <li onClick={()=>{setHasAssignDrops(false); setAssignType('Group')}}>Group</li>
                    </ul>
                </div>

                <div className="nexogic-inp">
                    <input
                        type="text"
                        name="ps_search_inp"
                        id="ps_search_inp"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => handleSearch(e)}

                    />
                    {searchStatus === 'pending' && <div className="spinner-border-w" ><div className="spinner-border" role="status"></div></div>}
                </div>


            </div>
            {hasSearch && <SearchPractitioner searchdata={searchResult} onSelect={(item)=>{props.onItemSelect(item); setHasSearch(false);}} />} 
        </div>
    )
}
