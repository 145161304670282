import API_Factory from "./API_Factory";

export const createOpinion = (data) =>
  API_Factory({
    method: "POST",
    url: `${process.env.REACT_APP_API_OPINION_SERVICE}/v1/opinions`,
    data,
    ignoreToken: false,
  });

export const assignOpinion = (opinionId, data) =>
  API_Factory({
    method: "PATCH",
    url: `${process.env.REACT_APP_API_OPINION_SERVICE}/v1/opinions/${opinionId}`,
    data,
    ignoreToken: false,
  });

export const updateOpinion = (id, data) =>
  API_Factory({
    method: "PUT",
    url: `${process.env.REACT_APP_API_OPINION_SERVICE}/v1/opinions/${id}`,
    data,
    ignoreToken: false,
  });

export const getOpinions = (sq, cp) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_OPINION_SERVICE}/v1/opinions?sq=${sq}&cp=${cp}&nr=10&so=DESC`,
    ignoreToken: false,
  });

export const getOpinion = (id) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_OPINION_SERVICE}/v1/opinions/${id}`,
    ignoreToken: false,
  });

export const createOpinionResponse = (opinionId, data) =>
  API_Factory({
    method: "POST",
    url: `${process.env.REACT_APP_API_OPINION_SERVICE}/v1/opinions/${opinionId}/responses`,
    data,
    ignoreToken: false,
  });

export const updateOpinionResponse = (opinionId, responseId, data) =>
  API_Factory({
    method: "PUT",
    url: `${process.env.REACT_APP_API_OPINION_SERVICE}/v1/opinions/${opinionId}/responses/${responseId}`,
    data,
    ignoreToken: false,
  });

export const getOpinionResponse = (opinionId) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_OPINION_SERVICE}/v1/opinions/${opinionId}/responses`,
    ignoreToken: false,
  });

export const deleteOpinionResponse = (opinionId, response_id) =>
  API_Factory({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_OPINION_SERVICE}/v1/opinions/${opinionId}/responses/${response_id}`,
    ignoreToken: false,
  });

export const OpinionService = {
  createOpinion,
  assignOpinion,
  getOpinions,
  getOpinion,
  updateOpinion,
  createOpinionResponse,
  updateOpinionResponse,
  getOpinionResponse,
  deleteOpinionResponse
};
