import React, { useState } from "react";
import { FormGroup, Input } from "reactstrap";

function formatPhoneNumber(value) {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 6) {
    return `${phoneNumber.slice(0, 4)}-${phoneNumber.slice(4)}`;
  }
  return `${phoneNumber.slice(0, 4)}-${phoneNumber.slice(
    4,
    6
  )}-${phoneNumber.slice(6, 8)}`;
}

export default function DOBFormatter() {
  const [inputValue, setInputValue] = useState("");

  const handleInput = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setInputValue(formattedPhoneNumber);
  };

  return (
    <FormGroup>
      <label className="label-color">Date of Birth</label>
      <Input
        placeholder="YYYY-MM-DD"
        onChange={(e) => handleInput(e)}
        value={inputValue}
      />
    </FormGroup>
  );
}
