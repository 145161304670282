import React from 'react'
import classnames from 'classnames';
import secondOpinionImg from 'assets/images/second-opinion.png'
import { useState } from "react";
import { Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import MedicalPrescriptions from './components/MedicalPrescriptions';
import MedicalReports from './components/MedicalReports';

const Reports = () => {
  const [activeTab, setActiveTab] = useState("Prescriptions");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <>
      <div className=''>
        {/** page title */}
        <h2 className='card-title big mb-3'>Medical Records</h2>
        <div className="card nexogic-medical-record-card border-0 bg-transparent">
          <Nav tabs className="nexogic-tabs">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "Prescriptions",
                })}
                onClick={() => {
                  toggle("Prescriptions");
                }}
              >
                Prescriptions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "Reports" })}
                onClick={() => {
                  toggle("Reports");
                }}
              >
                Reports
              </NavLink>
            </NavItem>
          </Nav>
          <Card>
            <CardBody className='px-0'>
              {activeTab === "Prescriptions" && <MedicalPrescriptions />}
              {activeTab === "Reports" && <MedicalReports />}
            </CardBody>
          </Card>
        </div>
      </div>
      {/** 
        <div className="card ">
        <div className="row  card-body">
        <div className="col-md-7">
          <img src={secondOpinionImg} alt="Image" width="100%" />
        </div>
        <div className="col-md-5 nexogic-form-content">
          <div>
          <h3 className="nexogic-form-title mb-2 text-warning">Reports</h3>         
          <h1 className="nexogic-form-title mb-2">UNDER CONSTRUCTION</h1>         
          </div>
        </div>
      </div>
      </div>
      */}
    </>
  )
}

export default Reports