import FormikInputField from "components/forms/formikFields/FormikInputField";
import { Field } from "formik";
import { withAlert } from "react-alert";
import { Button, Col, InputGroup, Row, Spinner } from "reactstrap";

const VerificationNumberForm = (props) => {
  const { dirty, handleSubmit, ResendOtp, isSubmitting } = props;

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs="12">
            <InputGroup size="medium">
              <Field
                id="varifyNumber"
                name="varifyNumber"
                type="text"
                label="Enter code"
                placeholder="Enter code"
                component={FormikInputField}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={6}>
            <Button
              id="vn_resend_otp"
              color="link"
              size="sm"
              block
              className="text-left"
              onClick={() => ResendOtp()}
            >
              Resend OTP
            </Button>
          </Col>
          <Col xs={6}>
            {isSubmitting ? (
              <div className="loading-spiner text-right">
                <Spinner style={{ width: "2rem", height: "2rem" }} />
              </div>
            ) : (
              <Button
                id="vn_btn_varify"
                color="primary"
                type="submit"
                disabled={!dirty}
                className="ml-auto d-block"
                style={{ width: "100px" }}
                size="md"
              >
                Verify
              </Button>
            )}
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default withAlert()(VerificationNumberForm);
