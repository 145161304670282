import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import logo_white from "assets/images/logo-white-transparent.png";
import PractitionerProfiles from '../PractitionerProfiles';

const SearchDoctorProfile = () => {
    const {name} = useParams();
        useEffect(() => {
        AOS.init();
    }, [])
    return (
        <div className="nex-bg-light-blue">
            <main className="nex-login-register--w">
                <div className="container d-flex lg" data-aos="fade-up">
                    <div className="nex-form-aside nex-form-aside-v2">
                        <div className="logo" data-aos="fade-down" data-aos-duration="800" data-aos-delay="800">
                            <a href="/"><img src={logo_white} alt="" /></a>
                        </div>                        
                        <h2 className="nex-text-xxl" data-aos="fade-down" data-aos-duration="1200" data-aos-delay="300">Select your <br/> profile</h2>
                        <p className="nex-text-lg nex-mob-visible text-black" data-aos="fade-down" data-aos-duration="1200" data-aos-delay="1300">Please Select your profile and continue</p>
                    </div>
                    <div className="nex-form-fields--w nex-form-fields--w-v2 no-min-height">                        
                        <PractitionerProfiles drname={name} />
                    </div>
                </div>
            </main>
        </div>
    )
}

export default SearchDoctorProfile;