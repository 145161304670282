import React from 'react'
import ImgSec1 from './images/img1.jpg'
import ImgSec2 from './images/img2.jpg'
import ImgSec3 from './images/img3.jpg'
import ImgSec4 from './images/img4.jpg'
import './css/style.css'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'

const Section6 = ()=>{
  return (
    <Card className="micro-section-card-v6">
        <CardHeader className='main-header'>Section Six Heading</CardHeader>
        <CardBody>
        <CardHeader className="card-header-sec card-h-center">
            <Row>
                <Col lg={12} >
                    <h2 className='card-title'>Heading</h2>
                    <h3 className='card-sub-title'>Sub Heading</h3>
                </Col>
            </Row>
        </CardHeader>
        <Row>
                <Col lg={3} sm={6} className="card-content">
                    <div className="card-graphic">
                        <img src={ImgSec1} alt="" className='card-img' />
                    </div>
                    <p className='card-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet architecto debitis perferendis aut! Molestias, iste!</p>
                </Col>
                <Col lg={3} sm={6} className="card-content">
                <div className="card-graphic">
                        <img src={ImgSec2} alt="" className='card-img' />
                    </div>
                <p className='card-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet architecto debitis perferendis aut! Molestias, iste!</p>
                </Col>
                <Col lg={3} sm={6} className="card-content">
                <div className="card-graphic">
                        <img src={ImgSec3} alt="" className='card-img' />
                    </div>
                <p className='card-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet architecto debitis perferendis aut! Molestias, iste!</p>
                </Col>
                <Col lg={3} sm={6} className="card-content">
                <div className="card-graphic">
                        <img src={ImgSec4} alt="" className='card-img' />
                    </div>
                <p className='card-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet architecto debitis perferendis aut! Molestias, iste!</p>
                </Col>
        </Row>
        </CardBody>
    </Card>
  )
}
export default Section6;
