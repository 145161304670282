import React, { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import SectionBlank from '../../Sections/SectionBlank';
import { AboutUI } from 'pages/Institutions/components/InstitutionsDetails/components/AboutUI';
import GalleryUI from 'pages/Institutions/components/InstitutionsDetails/components/GalleryUI';
import HospitalTimingsUI from 'pages/Institutions/components/InstitutionsDetails/components/HospitalTimingsUI';
import SpecialitiesUI from 'pages/Institutions/components/InstitutionsDetails/components/SpecialitiesUI';
import { ServicesUI } from 'pages/Institutions/components/InstitutionsDetails/components/ServicesUI';
import { AmenityUI } from 'pages/Institutions/components/InstitutionsDetails/components/AmenityUI';
import BranchesUI from 'pages/Institutions/components/InstitutionsDetails/components/BranchesUI';
import InstitutionsAwardsUI from 'pages/Institutions/components/InstitutionsDetails/components/InstitutionsAwardsUI';
import PractitionersUI from 'pages/Institutions/components/InstitutionsDetails/components/PractitionersUI';
import camera_icon from "assets/images/camera-icon.png";
import './style.css'
// import ThemeCustomTemplate from '../../Themes/Components/ThemeOne/ThemeCustomTemplate';

const ThemeThree = (props) => {
    const { content, institution, otherBranches, practitioners } = props;
    const { themeContainerType, sectionContent, themeColor } = content;

    return (
        <div className="preview-window" style={{ fontSize: '40px', minHeight: '300px', backgroundColor: '#f8f9fa' }}>
            <Card className="micro-site-wrap micro-site-theme-v1 p-0 border-0 theme-3 micro-site-wrap-header">
                <CardBody className="micro-main-content-wrap">
                    <Row>
                        <Col lg={12} className="pb-3">
                            <SectionBlank {...props} themeDetails={themeColor} institution={institution} getInstitutionProfile />
                        </Col>
                    </Row>
                    {themeContainerType === 'layoutthree' ?
                        <Row>
                            <Col lg={8} id="winl04" className='pr-3 pb-md-0'>
                                {sectionContent.filter(section => section.targetArea === "btnCol01").map((section, index) => (
                                    section.droppedItem.length > 0 ?
                                        <div key={index}>
                                            {section.droppedItem[2][0] === "AboutUI" ? <div className='mt-3 mb-4'><AboutUI {...props} institution={institution} getInstitutionProfile themeDetails={themeColor} /></div> :
                                                section.droppedItem[2][0] === "GalleryUI" ? <div className='mt-3 mb-4'><GalleryUI {...props} institution={institution} getInstitutionProfile themeDetails={themeColor} /></div> :
                                                    section.droppedItem[2][0] === "BusinessHrUI" && institution?.schedules.length > 0 ? <div className='mt-3 mb-4'><HospitalTimingsUI {...props} institution={institution} getInstitutionProfile themeDetails={themeColor} /></div> :
                                                        section.droppedItem[2][0] === "SpecialitiesUI" && institution?.specialities.length > 0 ? <div className='mt-3 mb-4'><SpecialitiesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeColor} /></div> :
                                                            section.droppedItem[2][0] === "ServicesUI" && institution?.services.length > 0 ? <div className='mt-3 mb-4'><ServicesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeColor} /></div> :
                                                                section.droppedItem[2][0] === "AmenityUI" && institution?.amenities.length > 0 ? <div className='mt-3 mb-4'><AmenityUI {...props} institution={institution} getInstitutionProfile themeDetails={themeColor} /></div> :
                                                                    section.droppedItem[2][0] === "BranchesUI" && otherBranches?.length > 1 ? <div className='mt-3 mb-4'><BranchesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeColor} winwidth={document.getElementById("winl04").clientWidth} /></div> :
                                                                        section.droppedItem[2][0] === "AwardUI" ? <div className='mt-3 mb-4'><InstitutionsAwardsUI {...props} institution={institution} getInstitutionProfile themeDetails={themeColor} /></div> :
                                                                            section.droppedItem[2][0] === "DoctorsUI" && practitioners?.length > 0 ? <div className='mt-3 mb-4'><PractitionersUI {...props} institution={institution} getInstitutionProfile themeDetails={themeColor} practitioners={practitioners} winwidth={document.getElementById("winl04").clientWidth} /></div> : ''
                                            }
                                        </div> : ''
                                ))}
                            </Col>
                            <Col lg={4} id="winl05" className='pl-3 pb-md-0'>
                                {sectionContent.filter(section => section.targetArea === "btnCol02").map((section, index) => (
                                    section.droppedItem.length > 0 ?
                                        <div key={index}>
                                            {section.droppedItem[2][0] === "AboutUI" ? <div className='mt-3 mb-4'><AboutUI {...props} institution={institution} getInstitutionProfile themeDetails={themeColor} /></div> :
                                                section.droppedItem[2][0] === "GalleryUI" ? <div className='mt-3 mb-4'><GalleryUI {...props} institution={institution} getInstitutionProfile themeDetails={themeColor} /></div> :
                                                    section.droppedItem[2][0] === "BusinessHrUI" && institution?.schedules.length > 0 ? <div className='mt-3 mb-4'><HospitalTimingsUI {...props} institution={institution} getInstitutionProfile themeDetails={themeColor} /></div> :
                                                        section.droppedItem[2][0] === "SpecialitiesUI" && institution?.specialities.length > 0 ? <div className='mt-3 mb-4'><SpecialitiesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeColor} /></div> :
                                                            section.droppedItem[2][0] === "ServicesUI" && institution?.services.length > 0 ? <div className='mt-3 mb-4'><ServicesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeColor} /></div> :
                                                                section.droppedItem[2][0] === "AmenityUI" && institution?.amenities.length > 0 ? <div className='mt-3 mb-4'><AmenityUI {...props} institution={institution} getInstitutionProfile themeDetails={themeColor} /></div> :
                                                                    section.droppedItem[2][0] === "BranchesUI" && otherBranches?.length > 1 ? <div className='mt-3 mb-4'><BranchesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeColor} winwidth={document.getElementById("winl05").clientWidth} /></div> :
                                                                        section.droppedItem[2][0] === "AwardUI" ? <div className='mt-3 mb-4'><InstitutionsAwardsUI {...props} institution={institution} getInstitutionProfile themeDetails={themeColor} /></div> :
                                                                            section.droppedItem[2][0] === "DoctorsUI" && practitioners?.length > 0 ? <div className='mt-3 mb-4'><PractitionersUI {...props} institution={institution} getInstitutionProfile themeDetails={themeColor} practitioners={practitioners} winwidth={document.getElementById("winl05").clientWidth} /></div> : ''
                                            }
                                        </div> : ''
                                ))}
                            </Col>
                        </Row>
                        :
                        ''
                    }
                </CardBody>
            </Card>
        </div>
    );
};

export default ThemeThree;