import React, { useState, useRef } from "react"
import { Label, FormGroup, Button } from 'reactstrap'
import { ErrorMessage } from 'formik';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import API from "utils/API_New"

import 'assets/styles/typeahead.css'


const InstitutesTypeaheadField = (props) => {
    const institutesRef = useRef(null)
    const { placeholder, type, onNewInstituteReq } = props
    const { name, value } = props.field
    const { setFieldValue, setFieldTouched, touched, errors } = props.form
    const [institutesData, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const handleSearch = async (query) => {
        if(query.length<3) return

        setLoading(true)
        try {
            const response = await API.get("institute/search/name", {params: {name: query}});
            
            if (response.status === 200) {   
                const { content } = response.data;
                setData(content)
                // setData(map(content, "institutionName"))
            }
            setLoading(false)
        }
        catch (e) {
            setLoading(false)
            if(props.alert)
                props.alert.error(e.message)
        }
    }

    const handleChange = (selected) => {
        // const value = (selected.length > 0) ? selected[0] : '';
        if(selected.length > 0)
            setFieldValue(name, selected[0], true)
    }
   
    return (
        <FormGroup className="flex-grow-1">
            <Label htmlFor={name} className="d-block">{props.label}</Label>
            <AsyncTypeahead
                ref={institutesRef}
                isLoading={loading}
                clearButton
                onSearch={handleSearch}
                id={'institutesAsyncInput'}
                options={institutesData}
                labelKey={"institutionName"}
                filterBy={['institutionName']}
                selected={value && value.institutionName ? [value] : ['']} 
                placeholder={placeholder}
                type={type}
                minLength={2}
                inputProps={{className:''}}
                onInputChange={(text, event) => {
                    setFieldValue(name, {"institutionName": text})
                    handleSearch(text)
                }}
                onChange={handleChange}
                onBlur={() => {return institutesRef !== null? institutesRef.current.hideMenu(): null}}
                renderMenu={(results, menuProps) => {
                    return (
                        <Menu {...menuProps}>
                            {results.map((result, index) => (
                                <MenuItem option={result} position={index}>
                                    {result.institutionName}
                                </MenuItem>
                            ))} 
                            {!!onNewInstituteReq &&
                                <MenuItem>
                                    <Button color={'link'} onClick={() => {onNewInstituteReq(); return  institutesRef !== null? institutesRef.current.hideMenu(): null}}>{'Add New Institution'}</Button>
                                </MenuItem>
                            }
                        </Menu>
                    )
                }}
                // useCache={false}
            />
            <ErrorMessage name={name} render={msg => <span className="text-danger f-12 d-inline-block  mt-1 line-height-error">{msg}</span>} />
  </FormGroup>
    )
}
export default InstitutesTypeaheadField