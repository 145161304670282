import dropdownIcon from "assets/images/dropdown_icon.png";
import rateActiveIcon from "assets/images/svgs/rate_active_icon.svg";
import rateIcon from "assets/images/svgs/rate_icon.svg";
import supportActiveIcon from "assets/images/svgs/support_active_icon.svg";
import supportIcon from "assets/images/svgs/support_icon.svg";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
} from "reactstrap";
import { encrypt } from "utils/EncryptDecrypt";
import { isAdmin, isInstitutionAdmin, isPractitioner, isPatient } from "utils/UserHelper";
import { UserService } from "utils/UserService";
import { createImageFromInitials, getRandomColor } from "utils/Utils";
import { RenderCustomerSupportModal } from "../CustomerSupportModal";
import Notifications from "../Notifications";
import { RenderRateAppModal } from "../RateAppModal";
import { UserStatusConstants } from "utils/Constants";

class NewProfileMenu extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleSupportModal = this.toggleSupportModal.bind(this);
    this.toggleRateAppModal = this.toggleRateAppModal.bind(this);

    this.state = {
      dropdownOpen: false,
      openSupportModal: false,
      isOpenRateAppModal: false,
      uploadImage: props.profilePicUrl,
    };
  }

  logout = () => {
    UserService.logout();
    window.location.reload();
  };

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  toggleSupportModal() {
    this.setState({
      openSupportModal: !this.state.openSupportModal,
    });
  }

  toggleRateAppModal() {
    this.setState({
      isOpenRateAppModal: !this.state.isOpenRateAppModal,
    });
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      uploadImage: nextProps?.currentUser?.avatarId
        ? `${process.env.REACT_APP_IMAGE_BASE_URL}/${nextProps?.currentUser?.guid}/${nextProps?.currentUser?.avatarId}`
        : "",
    };
  }

  render() {
    const { currentUser, globalProfileInfo } = this.props;
    const { firstName, lastName, title, location, primarySpeciality, headline, profileId, id, guid } = currentUser;
    const { dropdownOpen, uploadImage } = this.state;
    const username = isAdmin()
      ? "Nexogic Admin"
      : `${firstName || ""} ${lastName || ""}`;
    const username_single = isAdmin() ? "Nexogic Admin" : `${firstName}`;
    const getSubheading = () => {
      if (isAdmin())
        return "Admin";
      else if (isInstitutionAdmin())
        return "Institution Admin";
      else {
        return <span>{globalProfileInfo?.primarySpeciality ? globalProfileInfo?.primarySpeciality : ""}</span>;
      }
    };

    const getHeadline = () => {
      if (isAdmin())
        return "Admin";
      else if (isInstitutionAdmin() && isPractitioner())
        return <span>{headline ? headline : ""}</span>;
      else if (isInstitutionAdmin())
        return "Institution Admin";
      else {
        return <span>{headline ? headline : ""}</span>;
      }
    }

    return (
      <>
        <div className="nex-header-profile">
          <ButtonDropdown
            className="nexogic-user-profile-card"
            isOpen={dropdownOpen}
            toggle={this.toggle}
            inNavbar={true}
          >
            <DropdownToggle color="link" className="" id="nex-user-profile-drop-btn">
              <div className="nex-avatar">
                <img src={
                  uploadImage
                    ? uploadImage
                    : createImageFromInitials(
                      64,
                      username,
                      getRandomColor(encrypt(guid).toString())
                    )
                } alt="" className="nex-avatar-img" />
              </div>
              <div className="nex-profile-info">
                <div className="nex-profile-name">{title} {username_single}</div>
                <div className="nex-profile-deg">{getSubheading()}</div>
              </div>
              <div className="nex-profile-arrow"></div>
            </DropdownToggle>
            <DropdownMenu className="p-0">
              <>
                <div className="d-flex profile-block px-3 py-2">
                  <div className="profile-image pr-2">
                    <img
                      className="rounded-circle"
                      src={
                        uploadImage
                          ? uploadImage
                          : createImageFromInitials(
                            64,
                            username,
                            getRandomColor(encrypt(guid).toString())
                          )
                      }
                      alt="profilePic"
                      width="55"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = createImageFromInitials(
                          132,
                          username,
                          getRandomColor(encrypt(guid).toString())
                        );
                      }}
                    />
                  </div>
                  <div>
                    <p className="nex-next mb-0 text-black">{`${title ? title : ""
                      } ${username}`}</p>
                    <p className="nex-text small">{getHeadline()}</p>
                    <p className="nex-text small">{location || ""}</p>
                    {isPractitioner() &&
                      <div className="mt-0">
                        <Link
                          to={`/profile/${profileId}`}
                          className="nex-text small nex-link"
                          id="nex-user-profile-view-prof-btn"
                          onClick={this.toggle}
                        >
                          View Profile <i className="angleRightIc" />
                        </Link>
                      </div>
                    }
                    {isPatient() &&
                      <div className="mt-1">
                        <Link
                          id="nex-user-profile-view-prof-btn"
                          to={`/patient-profile/${currentUser?.profileId}`}
                          className="profile-link d-block text-decoration-none"
                          onClick={this.toggle}
                        >
                          View Profile <i className="angleRightIc" />
                        </Link>
                      </div>
                    }
                  </div>
                </div>

                <Nav>
                  {/* {isPatient() &&
                      <NavItem
                        className=""
                      >
                        <Link
                          to={`/u/profile/${currentUser?.profileGuid}`}
                          className="d-block text-decoration-none" onClick={this.toggle}>
                          Edit Profile
                        </Link>
                      </NavItem>

                    } */}
                  {((currentUser?.status === UserStatusConstants.APPROVED) && (isPractitioner() || isPatient())) ?
                    <NavItem
                      className=""
                    >
                      <Link
                        id="nex-user-profile-setting-link"
                        to="/settings" className="d-block text-decoration-none" onClick={this.toggle}>
                        Settings
                      </Link>
                    </NavItem>
                    : isAdmin() ?
                      <NavItem
                        className=""
                      >
                        <Link
                          id="nex-user-profile-c-practitioner-link"
                          to="/admin" className="d-block text-decoration-none" onClick={this.toggle}>
                          Create Practitioner
                        </Link>
                      </NavItem>
                      : ''
                  }
                  <NavItem
                    id="nex-user-profile-logout-btn"
                    className="nexogic-logout "
                    onClick={this.logout}
                  >
                    <Link
                      id="nex-user-profile-logout-link"
                      to="/login" className="d-block text-decoration-none text-danger">
                      Logout
                    </Link>
                  </NavItem>

                </Nav>
              </>
            </DropdownMenu>
          </ButtonDropdown>
        </div>
      </>
    );
  }
}
export default withRouter(NewProfileMenu);
