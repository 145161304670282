import { useEffect } from "react";
import { useState } from "react";
import { memo } from "react";
import Slider from "react-slick";
import { Spinner } from "reactstrap";



const GallerySlider = (props) => {
  const {
    items,
    Loader,
    isEditable,
    isAdmin,
    deleteGalleryImage,
    institutionId,
    getGalleryImages,
    totalPages,
    currentPage
  } = props;
  const [lastItemIndexInSlice, setlastItemIndexInSlice] = useState();

  const settings = {
    afterChange: function (index) {
      // console.log(
      //   `Index of Last Element : ${index + 4}`,
      //   "Length: ", items.length
      // );
      setlastItemIndexInSlice(index + 3);
      if ((currentPage < totalPages) && ((index + 4) % 10 > 0)) {
        getGalleryImages(currentPage + 1);
      }
    },
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  // useEffect
  return Loader ? (
    <div className="d-flex justify-content-center mt-3">
      <div className="loading-spiner">
        <Spinner style={{ width: "2rem", height: "2rem" }} />
      </div>
    </div>
  ) : (
    items && items.length > 0 ? (
      <div className="gallery-slider">
        <Slider {...settings}>
          {items.map((image, index) => (
            <div className="gallery-images" key={index}>
              {(isEditable || isAdmin) && (
                <span
                  className="gallery-delete-icon-inst"
                  onClick={() => deleteGalleryImage(image)}
                >                 
                 <div className="nex-del">
                    <svg width={12} height={16} viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }}>
                      <path d="M1 5.17969L1.53146 9.10872C1.75473 10.7593 1.86636 11.5846 2.43166 12.0786C2.99696 12.5725 3.83131 12.5725 5.5 12.5725V12.5725C7.16869 12.5725 8.00303 12.5725 8.56833 12.0786C9.13363 11.5846 9.24527 10.7593 9.46853 9.10872L9.99999 5.17969" stroke="#FF9191" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M3.57031 2.92857V2.92857C3.57031 1.86345 4.43376 1 5.49888 1V1C6.564 1 7.42745 1.86345 7.42745 2.92857V2.92857" stroke="#FF9191" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M10 2.92969L1 2.92969" stroke="#FF9191" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M3.89062 5.5L4.21205 10.3214" stroke="#FF9191" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M7.11049 5.5L6.78906 10.3214" stroke="#FF9191" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                </span>
              )}
              <img
                className="rounded single-img"
                src={`${process.env.REACT_APP_API_BASE_URL}institution-service/institutions/${institutionId}/medias/${image.id}/download`}
                alt="#"
              />
            </div>
          ))}
        </Slider>
      </div>
    ) : (null)
  );
};

GallerySlider.defaultProps = {
  items: [],
  loading: false,
  isEditable: false,
  isAdmin: false,
  deleteGalleryImage: () => { },
  institutionId: "",
};

export default GallerySlider;
