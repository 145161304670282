import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import SecondOpinionImg from "assets/images/second-opinion.png";
import { Link } from 'react-router-dom';

export default function AppointmentLists() {
    return (
        <>
            <div className='row'>
                
                <div className="col-lg-9">
                    <Card className='nexogic-doctor-appoint-card'>
                        <CardBody>
                            <Row className='need-margin'>
                                <Col lg={7} className="nexogic-appoint-doctor-info">
                                    <div className="nexogic-avatar size-lg">
                                        <img src="https://images-dev.nexogic.com/53efb6fd-1ceb-412a-b19d-b65032bde722/a8dd998a-8e20-4ac4-971f-bf91154aa6fc.png" alt="" className='' />
                                    </div>
                                    <div className="details">
                                        <h3 className="card-title">Dr. Anna Sotelo <span className='nexogic-green-tick'></span></h3>
                                        <p className="card-text">MBBS, MS - Orthopedic Surgeon (Orthopedist)</p>
                                        <p className="card-text">32 years experience overall</p>
                                        <p className="card-text">12 years as a specialist</p>
                                        <p className="card-text"><span className='icon'><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.99988 0.320068C5.17738 0.320068 2.87988 2.61757 2.87988 5.44007C2.87988 9.95632 7.56113 15.3426 7.75988 15.5701C7.82113 15.6401 7.90738 15.6801 7.99988 15.6801C8.09863 15.6738 8.17863 15.6401 8.23988 15.5701C8.43863 15.3388 13.1199 9.86007 13.1199 5.44007C13.1199 2.61757 10.8224 0.320068 7.99988 0.320068ZM7.99988 3.84007C9.05989 3.84007 9.91989 4.70007 9.91989 5.76007C9.91989 6.82007 9.05989 7.68007 7.99988 7.68007C6.93988 7.68007 6.07988 6.82007 6.07988 5.76007C6.07988 4.70007 6.93988 3.84007 7.99988 3.84007Z" fill="#001244" fillOpacity="0.6" />
                                        </svg>
                                        </span> California, CA, 18974</p>
                                        <p className="actions">
                                            <Link to="/u/appointments/add" className='btn nexogic_primary_button'>Appointment Request</Link>
                                        </p>
                                    </div>
                                </Col>
                                <Col lg={5} className="">
                                    <div className="nexogic-availability">
                                        <div className="head">
                                            <h2 className="card-title">Availability </h2>
                                            <div className="date-btns">
                                                <div className="date-prev-arrow"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_295_54)">
                                                        <path d="M10.8454 15.0076L11.8691 13.9838L6.87414 9.00007L11.8691 4.00507L10.8566 2.99257L5.35539 8.49382L4.83789 9.00007L5.35539 9.50632L10.8454 15.0076Z" fill="#2D4552" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_295_54">
                                                            <rect width="18" height="18" fill="white" transform="translate(18 18) rotate(-180)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                </div>
                                                <div className="date-text">Apr 22, 2021</div>
                                                <div className="date-next-arrow">
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_295_49)">
                                                            <path d="M7.15461 2.99243L6.13086 4.01618L11.1259 8.99993L6.13086 13.9949L7.14336 15.0074L12.6446 9.50618L13.1621 8.99993L12.6446 8.49368L7.15461 2.99243Z" fill="#2D4552" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_295_49">
                                                                <rect width="18" height="18" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="body-availability">
                                            <div className="nexogic-time-slots">
                                                <div className="slot">09:00 AM</div>
                                                <div className="slot">10:45 AM</div>
                                                <div className="slot">11:45 AM</div>
                                                <div className="slot">12:45 PM</div>
                                                <div className="slot active">09:00 AM</div>
                                                <div className="slot">10:45 AM</div>
                                                <div className="slot">11:45 AM</div>
                                                <div className="slot">12:45 PM</div>
                                            </div>
                                            <div className="actions">
                                                <a href="" className='link'><span className='icon'><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.31973 0C3.92598 0 3.59973 0.311409 3.59973 0.687248V1.3745H1.43973C1.02207 1.3745 0.719727 1.66309 0.719727 2.06175V4.46711H17.2797V2.06175C17.2797 1.66309 16.9774 1.3745 16.5597 1.3745H14.3997V0.687248C14.3997 0.311409 14.0735 0 13.6797 0H12.9597C12.566 0 12.2397 0.311409 12.2397 0.687248V1.3745H5.75973V0.687248C5.75973 0.311409 5.43348 0 5.03973 0H4.31973ZM4.31973 0.687248H5.03973V2.74899H4.31973V0.687248ZM12.9597 0.687248H13.6797V2.74899H12.9597V0.687248ZM0.719727 5.15436V15.8067C0.719727 16.2054 1.02207 16.494 1.43973 16.494H16.5597C16.9774 16.494 17.2797 16.2054 17.2797 15.8067V5.15436H0.719727ZM4.31973 7.21611H6.11973V8.93423H4.31973V7.21611ZM6.83973 7.21611H8.63973V8.93423H6.83973V7.21611ZM9.35973 7.21611H11.1597V8.93423H9.35973V7.21611ZM11.8797 7.21611H13.6797V8.93423H11.8797V7.21611ZM4.31973 9.62148H6.11973V11.3396H4.31973V9.62148ZM6.83973 9.62148H8.63973V11.3396H6.83973V9.62148ZM9.35973 9.62148H11.1597V11.3396H9.35973V9.62148ZM11.8797 9.62148H13.6797V11.3396H11.8797V9.62148ZM4.31973 12.0268H6.11973V13.745H4.31973V12.0268ZM6.83973 12.0268H8.63973V13.745H6.83973V12.0268ZM9.35973 12.0268H11.1597V13.745H9.35973V12.0268ZM11.8797 12.0268H13.6797V13.745H11.8797V12.0268Z" fill="#3EABCA" />
                                                </svg>
                                                </span> View all availability</a>
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card className='nexogic-doctor-appoint-card'>
                        <CardBody>
                            <Row className='need-margin'>
                                <Col lg={7} className="nexogic-appoint-doctor-info">
                                    <div className="nexogic-avatar size-lg">
                                        <img src="https://images-dev.nexogic.com/53efb6fd-1ceb-412a-b19d-b65032bde722/a8dd998a-8e20-4ac4-971f-bf91154aa6fc.png" alt="" className='' />
                                    </div>
                                    <div className="details">
                                        <h3 className="card-title">Dr. Anna Sotelo <span className='nexogic-green-tick'></span></h3>
                                        <p className="card-text">MBBS, MS - Orthopedic Surgeon (Orthopedist)</p>
                                        <p className="card-text">32 years experience overall</p>
                                        <p className="card-text">12 years as a specialist</p>
                                        <p className="card-text"><span className='icon'><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.99988 0.320068C5.17738 0.320068 2.87988 2.61757 2.87988 5.44007C2.87988 9.95632 7.56113 15.3426 7.75988 15.5701C7.82113 15.6401 7.90738 15.6801 7.99988 15.6801C8.09863 15.6738 8.17863 15.6401 8.23988 15.5701C8.43863 15.3388 13.1199 9.86007 13.1199 5.44007C13.1199 2.61757 10.8224 0.320068 7.99988 0.320068ZM7.99988 3.84007C9.05989 3.84007 9.91989 4.70007 9.91989 5.76007C9.91989 6.82007 9.05989 7.68007 7.99988 7.68007C6.93988 7.68007 6.07988 6.82007 6.07988 5.76007C6.07988 4.70007 6.93988 3.84007 7.99988 3.84007Z" fill="#001244" fillOpacity="0.6" />
                                        </svg>
                                        </span> California, CA, 18974</p>
                                        <p className="actions">
                                        <Link to="/u/appointments/add" className='btn nexogic_primary_button'>Appointment Request</Link>
                                        </p>
                                    </div>
                                </Col>
                                <Col lg={5} className="">
                                    <div className="nexogic-availability">
                                        <div className="head">
                                            <h2 className="card-title">Availability </h2>
                                            <div className="date-btns">
                                                <div className="date-prev-arrow"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_295_54)">
                                                        <path d="M10.8454 15.0076L11.8691 13.9838L6.87414 9.00007L11.8691 4.00507L10.8566 2.99257L5.35539 8.49382L4.83789 9.00007L5.35539 9.50632L10.8454 15.0076Z" fill="#2D4552" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_295_54">
                                                            <rect width="18" height="18" fill="white" transform="translate(18 18) rotate(-180)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                </div>
                                                <div className="date-text">Apr 22, 2021</div>
                                                <div className="date-next-arrow">
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_295_49)">
                                                            <path d="M7.15461 2.99243L6.13086 4.01618L11.1259 8.99993L6.13086 13.9949L7.14336 15.0074L12.6446 9.50618L13.1621 8.99993L12.6446 8.49368L7.15461 2.99243Z" fill="#2D4552" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_295_49">
                                                                <rect width="18" height="18" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="body-availability">
                                            <div className="nexogic-time-slots">
                                                <div className="slot">09:00 AM</div>
                                                <div className="slot">10:45 AM</div>
                                                <div className="slot active">11:45 AM</div>
                                                <div className="slot">12:45 PM</div>
                                                <div className="slot">09:00 AM</div>
                                                <div className="slot">10:45 AM</div>
                                                <div className="slot active">11:45 AM</div>
                                                <div className="slot">12:45 PM</div>
                                            </div>
                                            <div className="actions">
                                                <a href="" className='link'><span className='icon'><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.31973 0C3.92598 0 3.59973 0.311409 3.59973 0.687248V1.3745H1.43973C1.02207 1.3745 0.719727 1.66309 0.719727 2.06175V4.46711H17.2797V2.06175C17.2797 1.66309 16.9774 1.3745 16.5597 1.3745H14.3997V0.687248C14.3997 0.311409 14.0735 0 13.6797 0H12.9597C12.566 0 12.2397 0.311409 12.2397 0.687248V1.3745H5.75973V0.687248C5.75973 0.311409 5.43348 0 5.03973 0H4.31973ZM4.31973 0.687248H5.03973V2.74899H4.31973V0.687248ZM12.9597 0.687248H13.6797V2.74899H12.9597V0.687248ZM0.719727 5.15436V15.8067C0.719727 16.2054 1.02207 16.494 1.43973 16.494H16.5597C16.9774 16.494 17.2797 16.2054 17.2797 15.8067V5.15436H0.719727ZM4.31973 7.21611H6.11973V8.93423H4.31973V7.21611ZM6.83973 7.21611H8.63973V8.93423H6.83973V7.21611ZM9.35973 7.21611H11.1597V8.93423H9.35973V7.21611ZM11.8797 7.21611H13.6797V8.93423H11.8797V7.21611ZM4.31973 9.62148H6.11973V11.3396H4.31973V9.62148ZM6.83973 9.62148H8.63973V11.3396H6.83973V9.62148ZM9.35973 9.62148H11.1597V11.3396H9.35973V9.62148ZM11.8797 9.62148H13.6797V11.3396H11.8797V9.62148ZM4.31973 12.0268H6.11973V13.745H4.31973V12.0268ZM6.83973 12.0268H8.63973V13.745H6.83973V12.0268ZM9.35973 12.0268H11.1597V13.745H9.35973V12.0268ZM11.8797 12.0268H13.6797V13.745H11.8797V12.0268Z" fill="#3EABCA" />
                                                </svg>
                                                </span> View all availability</a>
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card className='nexogic-doctor-appoint-card'>
                        <CardBody>
                            <Row className='need-margin'>
                                <Col lg={7} className="nexogic-appoint-doctor-info">
                                    <div className="nexogic-avatar size-lg">
                                        <img src="https://images-dev.nexogic.com/53efb6fd-1ceb-412a-b19d-b65032bde722/a8dd998a-8e20-4ac4-971f-bf91154aa6fc.png" alt="" className='' />
                                    </div>
                                    <div className="details">
                                        <h3 className="card-title">Dr. Anna Sotelo <span className='nexogic-green-tick'></span></h3>
                                        <p className="card-text">MBBS, MS - Orthopedic Surgeon (Orthopedist)</p>
                                        <p className="card-text">32 years experience overall</p>
                                        <p className="card-text">12 years as a specialist</p>
                                        <p className="card-text"><span className='icon'><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.99988 0.320068C5.17738 0.320068 2.87988 2.61757 2.87988 5.44007C2.87988 9.95632 7.56113 15.3426 7.75988 15.5701C7.82113 15.6401 7.90738 15.6801 7.99988 15.6801C8.09863 15.6738 8.17863 15.6401 8.23988 15.5701C8.43863 15.3388 13.1199 9.86007 13.1199 5.44007C13.1199 2.61757 10.8224 0.320068 7.99988 0.320068ZM7.99988 3.84007C9.05989 3.84007 9.91989 4.70007 9.91989 5.76007C9.91989 6.82007 9.05989 7.68007 7.99988 7.68007C6.93988 7.68007 6.07988 6.82007 6.07988 5.76007C6.07988 4.70007 6.93988 3.84007 7.99988 3.84007Z" fill="#001244" fillOpacity="0.6" />
                                        </svg>
                                        </span> California, CA, 18974</p>
                                        <p className="actions">
                                        <Link to="/u/appointments/add" className='btn nexogic_primary_button'>Appointment Request</Link>
                                        </p>
                                    </div>
                                </Col>
                                <Col lg={5} className="">
                                    <div className="nexogic-availability">
                                        <div className="head">
                                            <h2 className="card-title">Availability </h2>
                                            <div className="date-btns">
                                                <div className="date-prev-arrow"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_295_54)">
                                                        <path d="M10.8454 15.0076L11.8691 13.9838L6.87414 9.00007L11.8691 4.00507L10.8566 2.99257L5.35539 8.49382L4.83789 9.00007L5.35539 9.50632L10.8454 15.0076Z" fill="#2D4552" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_295_54">
                                                            <rect width="18" height="18" fill="white" transform="translate(18 18) rotate(-180)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                </div>
                                                <div className="date-text">Apr 22, 2021</div>
                                                <div className="date-next-arrow">
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_295_49)">
                                                            <path d="M7.15461 2.99243L6.13086 4.01618L11.1259 8.99993L6.13086 13.9949L7.14336 15.0074L12.6446 9.50618L13.1621 8.99993L12.6446 8.49368L7.15461 2.99243Z" fill="#2D4552" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_295_49">
                                                                <rect width="18" height="18" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="body-availability">
                                            <div className="nexogic-time-slots">
                                                <div className="slot">09:00 AM</div>
                                                <div className="slot active">10:45 AM</div>
                                                <div className="slot">11:45 AM</div>
                                                <div className="slot">12:45 PM</div>
                                                <div className="slot">09:00 AM</div>
                                                <div className="slot">10:45 AM</div>
                                                <div className="slot">11:45 AM</div>
                                                <div className="slot">12:45 PM</div>
                                            </div>
                                            <div className="actions">
                                                <a href="" className='link'><span className='icon'><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.31973 0C3.92598 0 3.59973 0.311409 3.59973 0.687248V1.3745H1.43973C1.02207 1.3745 0.719727 1.66309 0.719727 2.06175V4.46711H17.2797V2.06175C17.2797 1.66309 16.9774 1.3745 16.5597 1.3745H14.3997V0.687248C14.3997 0.311409 14.0735 0 13.6797 0H12.9597C12.566 0 12.2397 0.311409 12.2397 0.687248V1.3745H5.75973V0.687248C5.75973 0.311409 5.43348 0 5.03973 0H4.31973ZM4.31973 0.687248H5.03973V2.74899H4.31973V0.687248ZM12.9597 0.687248H13.6797V2.74899H12.9597V0.687248ZM0.719727 5.15436V15.8067C0.719727 16.2054 1.02207 16.494 1.43973 16.494H16.5597C16.9774 16.494 17.2797 16.2054 17.2797 15.8067V5.15436H0.719727ZM4.31973 7.21611H6.11973V8.93423H4.31973V7.21611ZM6.83973 7.21611H8.63973V8.93423H6.83973V7.21611ZM9.35973 7.21611H11.1597V8.93423H9.35973V7.21611ZM11.8797 7.21611H13.6797V8.93423H11.8797V7.21611ZM4.31973 9.62148H6.11973V11.3396H4.31973V9.62148ZM6.83973 9.62148H8.63973V11.3396H6.83973V9.62148ZM9.35973 9.62148H11.1597V11.3396H9.35973V9.62148ZM11.8797 9.62148H13.6797V11.3396H11.8797V9.62148ZM4.31973 12.0268H6.11973V13.745H4.31973V12.0268ZM6.83973 12.0268H8.63973V13.745H6.83973V12.0268ZM9.35973 12.0268H11.1597V13.745H9.35973V12.0268ZM11.8797 12.0268H13.6797V13.745H11.8797V12.0268Z" fill="#3EABCA" />
                                                </svg>
                                                </span> View all availability</a>
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-lg-3">
                <div className="nexogic-widget-sidebar-card card border-0">
            <h3 className="card-title h2">Second Opinion</h3>
            <div className="card-body">
              <ul className="nexogic-lists nexogic-custom-scroll">
                <li>
                  <img src={SecondOpinionImg} alt="" className="img-fluid" />
                </li>
              </ul>
            </div>
          </div>
                </div>
            </div>
        </>
    )
}
