import React, { useState, useEffect, useMemo } from "react";
import { Col, Row, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

import { AppointmentType } from "utils/Constants"
import DaySchedule from "./DaySchedule";


const createDayTimeIntervals = () => {
  const timeIntervals = [];
  const intervalMinutes = 60; // Per time-slot to show
  const timeToday = new Date();

  timeToday.setHours(0); // Shift Start - (24 hours fomrat)
  timeToday.setMinutes(0);
  let currentInterval = timeToday.setSeconds(0);

  const shiftEnd = new Date();
  shiftEnd.setHours(24); // Shift End - 24 hours fomrat
  shiftEnd.setMinutes(0);
  const closingTime = shiftEnd.setSeconds(0);

  // create intervals of 1hr each
  while (currentInterval < closingTime) {
    timeIntervals.push({
      label:
        ("0" + timeToday.getHours()).slice(-2) +
        ":" +
        ("0" + timeToday.getMinutes()).slice(-2) +
        " " +
        (timeToday.getHours() < 12 ? "AM" : "PM"),
      hours: timeToday.getHours(),
      minutes: timeToday.getMinutes(),
    });
    currentInterval = timeToday.setMinutes(
      timeToday.getMinutes() + intervalMinutes
    );
  }
  return timeIntervals
}

const DayView = (props) => {
  const [activeTab, setActiveTab] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [selectedDay, setSelectedDay] = useState(null);
  const daytimeIntervals = createDayTimeIntervals()

  useEffect(() => {
    loadDay(props.days[activeTabIndex], activeTabIndex, true);
  }, [props.days]);

  const loadDay = (dayData, index, reload) => {
    setActiveTabIndex(index);
    if (activeTab !== dayData.id || reload) {
      setActiveTab(dayData.id);
      setSelectedDay(dayData);
    }
  };

  return (
    <Row className="px-3 mt-1">
      <Col md={4} className="pr-0">
        <Nav vertical className="appointment-day mb-4">
          {props.days.map((day, index) => {
            return (
              <NavItem key={index} style={{ paddingBottom: "10px" }}>
                <NavLink
                  className={classnames({
                    active: activeTab === day.id,
                  })}
                  onClick={() => {
                    loadDay(day, index, false);
                  }}
                >
                  <Row >
                    <Col
                      md={5}
                      className="text-center border-right border-color pt-2"
                    >
                      <div className="f-14 line-h-14">{day.day}</div>
                      <div className="day-date f-24 line-h-24 font-weight-bold">{day.date}</div>
                    </Col>
                    <Col md={7} className="pl-3 pr-0 py-1">
                      <div className="f-14 line-h-14 font-weight-bold mb-2 pb-1">
                        <b>{`${day.totalAppointments} appointment${day.totalAppointments > 1 ? 's' : ''}`}</b>
                      </div>
                      <div className="f-14 line-h-14 mb-2 pb-1">
                        {day.consultations.length} {'consultation'}
                        {day.consultations.length > 1 ? "s" : ""}
                      </div>
                      <div className="f-14 line-h-14">
                        {day[AppointmentType.LAB].length} {AppointmentType.LAB.toLowerCase()}
                        {day[AppointmentType.LAB].length > 1 ? "s" : ""}
                      </div>
                    </Col>
                  </Row>
                </NavLink>
              </NavItem>
            )
          })}
        </Nav>
      </Col>
      <Col md={8}>
        {selectedDay && (
          <DaySchedule dtInts={daytimeIntervals} selectedDay={selectedDay} getAppts={props.getAppts} />
        )}
      </Col>
    </Row>
  );
};

export default DayView;
