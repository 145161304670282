import React from "react";
import { Card, Nav, NavItem } from "reactstrap";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isAdmin, isPractitioner } from "utils/UserHelper";
import { Tooltip } from 'reactstrap';
import { useState } from "react";

const JobSidebar = (props) => {
  const [job_tooltip_Open, set_job_tooltip_open] = useState(false);
  const location = useLocation();
  const [mobileSidebarOn, setMobileSidebarOn] = useState(false);

  function get_active_sidebar_name(){
    if(location.pathname === "/jobsboard/all-jobs"){
      return 'All Jobs';
    } else if (location.pathname === "/jobsboard/posted-jobs"){
      return 'My Posted Jobs';
    } else if (location.pathname === "/jobsboard/applied-jobs"){
      return ' My Applied Jobs';
    } else if (location.pathname === "/jobsboard/post-a-job"){
      return ' Post a Job';
    } else {
      return '';
    }
}
  return (
    <>
    {mobileSidebarOn && 
        <div className='nex-mob-backdrops' onClick={()=>setMobileSidebarOn(false)}></div>
    }
    <div className='nex-mob-sticky-box cursor-pointer mt-2' onClick={()=>setMobileSidebarOn(!mobileSidebarOn)} >
                <span className='label'>{`${get_active_sidebar_name()}`}</span>
                <span><svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 10C2.55228 10 3 9.55228 3 9C3 8.44772 2.55228 8 2 8C1.44772 8 1 8.44772 1 9C1 9.55228 1.44772 10 2 10Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2 17C2.55228 17 3 16.5523 3 16C3 15.4477 2.55228 15 2 15C1.44772 15 1 15.4477 1 16C1 16.5523 1.44772 17 2 17Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10 10C10.5523 10 11 9.55228 11 9C11 8.44772 10.5523 8 10 8C9.44772 8 9 8.44772 9 9C9 9.55228 9.44772 10 10 10Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10 3C10.5523 3 11 2.55228 11 2C11 1.44772 10.5523 1 10 1C9.44772 1 9 1.44772 9 2C9 2.55228 9.44772 3 10 3Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10 17C10.5523 17 11 16.5523 11 16C11 15.4477 10.5523 15 10 15C9.44772 15 9 15.4477 9 16C9 16.5523 9.44772 17 10 17Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                </span>
      </div>
    
    <Card className={`tabs-border-radius nexogic-side-tab-mobile-visibility ${mobileSidebarOn ? 'mobile-on' : ''}`}>
      <div className="jobsboard-side">
        <Nav vertical>
          <NavItem
            active={
              location.pathname === "/jobsboard/all-jobs" ? true : false
            }
          >
            <NavLink
              className="nav-link  py-3 px-4 d-flex align-item-center"
              to="/jobsboard/all-jobs"
            >
              All Jobs {/*<span className="tooltip_icon" id="Tooltip-all-job"></span> <Tooltip
        placement={'right'}
        isOpen={job_tooltip_Open}
        target={'Tooltip-all-job'}
        toggle={()=>set_job_tooltip_open(!job_tooltip_Open)}
      >
        All Posted Jobs
          </Tooltip>*/}
              {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
                <span className="total-count">1</span>)
              }
              <div className="left-border-line"></div>
            </NavLink>
          </NavItem>
          {!isAdmin() &&
            <NavItem
              active={
                location.pathname === "/jobsboard/posted-jobs" ? true : false
              }
            >
              <NavLink
                className="nav-link  py-3 px-4 d-flex align-item-center"
                to="/jobsboard/posted-jobs"
              >
                My Posted Jobs
                {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
                  <span className="total-count">1</span>)
                }
                <div className="left-border-line"></div>
              </NavLink>
            </NavItem>
          }
          {isPractitioner() &&
            <NavItem
              active={
                location.pathname === "/jobsboard/applied-jobs" ? true : false
              }
            >
              <NavLink
                className="nav-link py-3 px-4 d-flex align-item-center"
                to="/jobsboard/applied-jobs"
              >
                My Applied Jobs
                {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
                  <span className="total-count">12</span>)}
                <div className="left-border-line"></div>
              </NavLink>
            </NavItem>
          }
        </Nav>
      </div>
    </Card>
    </>
  );
};
export default JobSidebar;
