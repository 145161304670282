import moment from 'moment'
import React from 'react'

export default function CalWeekDaysMobile(props) {
    function isCurrentDate(item){
        return moment().format('MMM Do YY') === moment(item).format('MMM Do YY');
    }
  return (
    <div className='nexogic-mob-week-days'>
        {props.week.map((item, ind)=>{
            return (
                <div className='nexogic-week-mobile-day' key={'mob-week-day-'+ind}>
                    <span className={`day-name ${isCurrentDate(item) ? 'active' : ''}`}>{moment(item).format('ddd').substring(0,1)}</span>
                    <span className={`day-number ${isCurrentDate(item) ? 'active' : ''}`} >{moment(item).format('DD')}</span>
                </div>
            )
        })}
    </div>
  )
}
