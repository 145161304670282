import { GET_GLOBAL_SEARCHTYPE, SET_GLOBAL_SEARCHTYPE } from "./Actions";

let initialState = {
  globalSearchType: "Practitioners",
};

function GlobalSearchTypeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GLOBAL_SEARCHTYPE:
      return {
        ...state,
        globalSearchType: action.payload,
      };
    case SET_GLOBAL_SEARCHTYPE:
      return {
        ...state,
        globalSearchType: action.payload,
      };
    default:
      return state;
  }
}

export default GlobalSearchTypeReducer;
