import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'reactstrap'
import patiebt_sidebar from "assets/images/nex-patient.png";

export default function ReferWidget() {
    return (
        <Card className="nexogic-widget-sidebar-card text-center">
            <div className="graphic">
                <img src={patiebt_sidebar} alt="" width={400} className="img-fluid" />
            </div>
            <div className="nex-quick-desc">
                <h2 className="nex-text-xl">Want to refer a Patient?</h2>
                <p className="nex-text mb-3">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed.</p>
            </div>
            <div className="action mb-3">
                <Link to={"/referrals/refer-patient"} className="btn nex-btn-primary-outline no-full-w">Refer Your Patient</Link>
            </div>
        </Card>
    )
}
