import React, { useState } from "react"
import connectionslgIcon from 'assets/images/svgs/connections_lg_icon.svg';

const NoInvitations = ({
    content
}) => {
    return (
        <div className="d-flex justify-content-center no-connection">
            <div className="border-area">
                <img src={connectionslgIcon} />
                <span className="no-conn-text">{content}</span>
            </div>
        </div>
    )
}

export default NoInvitations