import { Field } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button, Col, InputGroup, Label, Row, Spinner } from "reactstrap";

import FormikInputField from "components/forms/formikFields/FormikInputField";

const RegisterForm = (props) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
    dirty,
    handleSubmit,
  } = props;

  return (
    <div>
      <form className="signup-form" onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            {values.hasOwnProperty("phone") ? (
              <InputGroup size="medium" className="code-phone-ig">
                <Label>Mobile number</Label>
                <PhoneInput
                  country={"in"}
                  onlyCountries={["in"]}
                  inputClass="phonenumber-form-control"
                  containerClass="mb-2"
                  inputProps={{
                    name: "phone",
                  }}
                  onChange={(value, country, e, formattedValue) =>
                    setFieldValue("phone", formattedValue)
                  }
                  isValid={(value, country) =>
                    !!errors.phone === false || !touched.phone
                      ? true
                      : errors.phone
                  }
                />
              </InputGroup>
            ) : (
              <InputGroup size="medium">
                <Field
                  name="email"
                  id="email"
                  type="email"
                  label="Email Address"
                  component={FormikInputField}
                  placeholder="Email address"
                />
              </InputGroup>
            )}
          </Col>
        </Row>

        <Row className="mb-4 mt-4 align-items-center">
          <Col xs={12}>
            {isSubmitting ? (
              <div className="loading-spiner text-center">
                <Spinner style={{ width: "2rem", height: "2rem" }} />
              </div>
            ) : (
              <Button
                id="receive-otp"
                type="submit"
                disabled={!dirty}
                className=""
                style={{ width: "100%" }}
                color="primary"
                size="md"
              >
                Receive OTP
              </Button>
            )}
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default RegisterForm;
