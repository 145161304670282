import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import Rating from "react-rating";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { PractitionerService } from "utils/PractitionerService";
import starIcon from "assets/images/star.png";

const RenderReviews = (props) => {
  const { reviews, count } = props;
  if (reviews && reviews.length) {
    return reviews.slice(0, count).map((item, index) => {
      return (
        <Col xs={12} key={index}>
          <div className="individualFeedback mb-2 py-3">
            <Rating
              className="rating"
              emptySymbol="fa fa-star-o fa-1x f-14"
              fullSymbol="fa fa-star fa-1x"
              fractions={2}
              initialRating={item.recommendationRatings}
              readonly
            />
            <div className="FeedbackPera mt-1">
              <p className="m-0 font-weight-500">{item.feedback}</p>
              <p className="description-heading  f-13 mb-0">
                {moment(item.createdOn).format("MMM DD, YYYY")} by{" "}
                {item.anonymousUser
                  ? item.patientObj.firstName
                  : "a verified patient"}
              </p>
            </div>
          </div>
        </Col>
      );
    });
  } else {
    return null;
  }
};

export const RenderRatingPopover = (props) => {
  const [modal, setModal] = useState(false);
  const [reviewList, setReviews] = useState([]);
  const [overallRatings, setOverallRatings] = useState({});
  const [count, setCount] = useState(3);
  const { reviewCount, practitionerId } = props;
  useEffect(() => {
    const getReviews = async () => {
      try {
        // const response = await API.get(`/practitioners/${practitionerId}/reviews/`)
        PractitionerService.getPractitionerReview(practitionerId).then(
          (response) => {
            if (response && response.data) {
              if (response.data.status === "SUCCESS") {
                const { overallRatings, reviews } = response.data.data;
                setOverallRatings(overallRatings);
                setReviews(reviews);
              }
            }
          }
        );
      } catch (error) {
        console.log("error=======>", error);
      }
    };
    getReviews();
  }, [practitionerId]);

  const toggle = () => {
    if (!modal) {
      setCount(3);
    }
    setModal(!modal);
  };

  const handleLoaMore = () => {
    setCount(count + 3);
  };

  return (
    <Fragment>
      <Button
        color="link"
        id="Popover2"
        onClick={toggle}
        className="d-flex align-items-center"
      >
        {/* <Rating className="rating" emptySymbol="fa fa-star-o fa-1x" fullSymbol="fa fa-star fa-1x" fractions={2} initialRating={overallRatings.recommendationRatings} readonly />
                <span className="hypertext-link font-weight-300 ml-2" >{`${reviewCount} Reviews`}</span> */}
        <img src={starIcon} width="16" alt="star" />
        <a
          href
          onClick={(e) => {
            e.preventDefault();
          }}
          className="pl-2 hypertext-link font-weight-300"
        >{`${reviewCount} Reviews`}</a>
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        id="profile-modal"
        className="modal-dialog-scrollable modal-dialog-centered"
      >
        <ModalHeader className="d-flex align-items-center" toggle={toggle}>
          Feedbacks and Ratings
        </ModalHeader>
        <ModalBody className="px-0">
          <div className="feedbackStart">
            <div className="overall pb-3 px-4 mx-3">
              <Row>
                <Col>
                  <h6 className="f-18 font-weight-600">Overall</h6>
                  <Rating
                    className="rating"
                    emptySymbol="fa fa-star-o fa-1x f-14"
                    fullSymbol="fa fa-star fa-1x"
                    fractions={2}
                    initialRating={overallRatings.recommendationRatings}
                    readonly
                  />
                  <span className="text-description-heading d-inline-block pl-2">
                    {overallRatings.recommendationRatings}
                  </span>
                </Col>
                <Col>
                  <div>
                    <h6 className="f-18 font-weight-600">Wait Time</h6>
                    <Rating
                      className="rating"
                      emptySymbol="fa fa-star-o fa-1x f-14"
                      fullSymbol="fa fa-star fa-1x"
                      fractions={2}
                      initialRating={overallRatings.waitTimeRatings}
                      readonly
                    />
                    <span className="text-description-heading d-inline-block pl-2">
                      {overallRatings.waitTimeRatings}
                    </span>
                  </div>
                </Col>
                <Col>
                  <div>
                    <h6 className="f-18 font-weight-600">Manners</h6>
                    <Rating
                      className="rating"
                      emptySymbol="fa fa-star-o fa-1x f-14"
                      fullSymbol="fa fa-star fa-1x"
                      fractions={2}
                      initialRating={overallRatings.mannersRatings}
                      readonly
                    />
                    <span className="text-description-heading d-inline-block pl-2">
                      {overallRatings.mannersRatings}
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
            <hr />
            <div className="description-heading mb-2 px-4 mx-3">{`${reviewCount} Reviews`}</div>
            <div className="px-4 mx-3">
              <Row>
                <RenderReviews reviews={reviewList} count={count} />
              </Row>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="py-3 justify-content-start">
          {count === reviewCount || count > reviewCount ? null : (
            <Button type="button" color="link" onClick={handleLoaMore}>
              Load More
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
