import React from 'react';
import { Row, Col, CardBody, Card, Modal, ModalHeader, ModalBody } from "reactstrap";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import del_red_icon from "assets/images/del-red-icon.png";
import plus_icon from "assets/images/plus-icon-lg.png";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import swal from "sweetalert";
import { OpinionService } from 'utils/OpinionService';
import { withAlert } from 'react-alert';
import CommonLoader from 'common/CommonLoader';
import { useState } from 'react';
import FormikTextAreaField from "components/forms/formikFields/FormikTextAreaField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { FAILED_TO_FETCH_DATA, FAILTOSAVERECOED } from 'utils/Constants';
import { useEffect } from 'react';
import { isAdmin, isPractitioner } from 'utils/UserHelper';

export default withAlert()(function ResponseInfo({ opinionId, alert }) {
    const [pannel_two_on, setPannel_two_on] = useState(true);
    const [responses, setResponses] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isResponseLoading, setIsResponseLoading] = useState(true);
    const [editingResponseId, setEditingResponseId] = useState(null);
    const [initialValues, setInitialValues] = useState({
        message: "",
        prescription: ""
    });

    const toggleModal = () => {
        if (modalOpen) {
            setInitialValues({
                message: "",
                prescription: ""
            });
        }
        setModalOpen(!modalOpen);
        setEditingResponseId(null);
    }

    const handleSubmit = (values) => {
        // console.log('Form Values:', values);
        const payload = {
            "message": values?.message,
            "prescription": values?.prescription
        }
        if (editingResponseId) {
            OpinionService.updateOpinionResponse(opinionId, editingResponseId, payload)
                .then((response) => {
                    const { status, data } = response;
                    if (status === 200) {
                        showSuccessMessage("Response details Updated Successfully.");
                        toggleModal();
                        getOpinionResponse(opinionId);
                    } else {
                        showErrorMessage(FAILTOSAVERECOED);
                        toggleModal();
                    }
                })
        } else {
            OpinionService.createOpinionResponse(opinionId, payload)
                .then((response) => {
                    const { status, data } = response;
                    if (status === 201) {
                        showSuccessMessage("Response details added Successfully");
                        toggleModal();
                        getOpinionResponse(opinionId);
                    } else {
                        showErrorMessage(FAILTOSAVERECOED)
                        toggleModal();
                    }
                })
        }
    }

    const populateFormForEdit = (responseId) => {
        const responseToEdit = responses.find(response => response.id === responseId);
        if (responseToEdit) {

            // If the response is found, populate the form with its details
            setInitialValues({
                message: responseToEdit.message,
                prescription: responseToEdit.prescription
            })
            setEditingResponseId(responseId); // Set the response being edited
            setModalOpen(true);
        }
    }

    const getOpinionResponse = (opinionId) => {
        OpinionService.getOpinionResponse(opinionId)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setResponses(data);
                    setIsResponseLoading(false);
                } else {
                    console.error(FAILED_TO_FETCH_DATA);
                    setIsResponseLoading(false);
                }
            })
    }

    useEffect(() => {
        getOpinionResponse(opinionId);
    }, [opinionId])

    const handleResponseDelete = (responseId) => {
        swal("Are you sure you want to remove this response?", {
            buttons: ["cancel", "yes"],
        })
            .then((yesRemove) => {
                if (yesRemove)
                    OpinionService.deleteOpinionResponse(opinionId, responseId)
                        .then((response) => {
                            const { status, data } = response;
                            if (status === 204) {
                                getOpinionResponse(opinionId);
                                showSuccessMessage("Response deleted successfully");
                            }
                            else {
                                showSuccessMessage("Something went wrong, Please try again later");
                            }
                        })
            })
    }

    return (
        <>
            <Card className={`nexogic-card-color-header ${pannel_two_on ? 'expanded' : ''}`}>
                <div className="card-header">
                    <h2>Opinion Response </h2>
                    <button className="collapse-btn" type="button" onClick={() => setPannel_two_on(!pannel_two_on)}>
                        {/*<img src={pannel_arrow} alt="" />*/}
                        <svg width={17} height={10} viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.25 1.625L8.625 8L15 1.625" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" /></svg>
                    </button>

                    {(isPractitioner() || isAdmin()) &&
                        <button className="add-button btn btn-link ml-auto" type="button" id="nex-rds-plus-icon-btn" onClick={toggleModal}>
                            {/*<img src={plus_icon} alt=""/>*/}
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="12" cy="12" r="11.25" fill="white" stroke="#46ABC9" stroke-width="1.5"/>
<path d="M11.8359 7.00195L11.8359 16.6738" stroke="#46ABC9" stroke-width="1.5" stroke-linecap="round"/>
<path d="M16.6719 11.8379L7 11.8379" stroke="#46ABC9" stroke-width="1.5" stroke-linecap="round"/>
</svg> Add Opinion Response </button>
                    }

                </div>
                <div className="collapse-div">
                    <CardBody>
                        {isResponseLoading ?
                            <div className="text-center mt-3">
                                <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                            </div>
                            :
                            <>
                                {responses.length > 0 ?
                                    <> <ul className={`nex-question-lists ${(isPractitioner() || isAdmin()) ? 'nex-question-lists-action-space' : ''} `}>
                                        {
                                            responses.map((response) => (
                                                <li>
                                                   <div className='nex-text-lg text-black bold'>Message</div>
                                                   <p className='nex-text text-black mb-3'>{response?.message}</p>
                                                   <div className='nex-text-lg text-black bold'>Prescriptions</div>
                                                   <p className='nex-text text-black mb-3'>{response?.prescription}</p>
                                                   
                                                    {(isPractitioner() || isAdmin()) &&
                                                                        <div className="action nex-action-float">
                                                                            <button
                                                                                type="button"
                                                                                className="visible-hover py-0 btn btn-link nex-e-exp-edit-btn"
                                                                                onClick={() => populateFormForEdit(response.id)} // Call populateFormForEdit when edit button is clicked
                                                                            >
                                                                                {/*<img src={edit_blue_icon} alt="" />*/}
                                                                                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx={21} cy={21} r="20.5" fill="white" stroke="#DBF4FB" />
        <path d="M25.6222 12.3555L23.6149 14.3628L27.6296 18.3775L29.6369 16.3702L25.6222 12.3555ZM21.6075 16.3702L13.5781 24.3996V28.4143H17.5928L25.6222 20.3849L21.6075 16.3702Z" fill="#46ABC9" />
      </svg>

                                                                            </button>

                                                                            <button
                                                                                type="button"
                                                                                className="visible-hover py-0 delete btn btn-link nex-e-exp-del-btn"
                                                                                onClick={() => handleResponseDelete(response?.id)}
                                                                            >
                                                                                {/*<img src={del_red_icon} alt="" />*/}
                                                                                <svg width={43} height={42} viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="21.0781" cy={21} r={21} fill="#FFEAEF" />
        <path d="M14.6172 18.0547L15.764 26.5332C15.9873 28.1837 16.0989 29.009 16.6642 29.503C17.2295 29.997 18.0623 29.997 19.7279 29.997H24.0449C25.7105 29.997 26.5433 29.997 27.1086 29.503C27.6739 29.009 27.7855 28.1837 28.0088 26.5332L29.1556 18.0547" stroke="#FF9191" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.7656 14.422V14.422C18.7656 13.3817 18.7656 12.8615 18.9544 12.4584C19.1529 12.0346 19.4936 11.6939 19.9173 11.4954C20.3205 11.3066 20.8407 11.3066 21.881 11.3066V11.3066C22.9214 11.3066 23.4415 11.3066 23.8447 11.4954C24.2684 11.6939 24.6091 12.0346 24.8076 12.4584C24.9964 12.8615 24.9964 13.3817 24.9964 14.422V14.422" stroke="#FF9191" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M29.1556 14.4199L14.6172 14.4199" stroke="#FF9191" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.2891 18.5801L19.8083 26.3685" stroke="#FF9191" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M24.488 18.5801L23.9688 26.3685" stroke="#FF9191" strokeLinecap="round" strokeLinejoin="round" />
      </svg>

                                                                            </button>
                                                                        </div>
                                                                    }
                                                   
                                                    <CardBody className='px-0 nexogic-custom-scroll d-none'>
                                                        {/* {sortedTreatments.map((treatment) => ( */}
                                                        <Row key={"treatment.id"}>
                                                            <Col>
                                                                <div className='nexogic-treatment-item'>
                                                                    <div className='info'>
                                                                        <Row>
                                                                            <Col lg={12}>
                                                                                <div className='card-text lh-lg'>
                                                                                    <div><b>Message </b></div>
                                                                                    {response?.message}
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={12}>
                                                                                <div className='card-text lh-lg'>
                                                                                    <div><b>Prescriptions</b> </div>
                                                                                    {response?.prescription}
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    {(isPractitioner() || isAdmin()) &&
                                                                        <div className="action">
                                                                            <button
                                                                                type="button"
                                                                                className="visible-hover py-0 btn btn-link nex-e-exp-edit-btn"
                                                                                onClick={() => populateFormForEdit(response.id)} // Call populateFormForEdit when edit button is clicked
                                                                            >
                                                                                <img src={edit_blue_icon} alt="" />
                                                                            </button>

                                                                            <button
                                                                                type="button"
                                                                                className="visible-hover py-0 delete btn btn-link nex-e-exp-del-btn"
                                                                                onClick={() => handleResponseDelete(response?.id)}
                                                                            >
                                                                                <img src={del_red_icon} alt="" />
                                                                            </button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </Col>

                                                        </Row>
                                                        {/* ))} */}
                                                    </CardBody>
                                                </li>
                                            ))
                                        }
                                        </ul>
                                    </>
                                    :
                                    <div className="nexogic-not-found-text v2 mb-3">
                                        Response not Available
                                    </div>
                                }
                            </>
                        }
                    </CardBody>
                </div>
            </Card>

            {/* Modal for Form */}
            <Modal isOpen={modalOpen} toggle={toggleModal} className='nex-apply-jobs-modal'>
                <ModalHeader toggle={toggleModal} className='nex-apply-jobs-modal-header'><h2 className='nex-text-xl'>Add Opinion Response</h2></ModalHeader>
                <ModalBody className='bg-white'>
                    <Formik
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                        initialValues={initialValues}
                        onSubmit={(values, { resetForm }) => handleSubmit(values)}
                        children={(props) => {
                            return (
                                <>
                                    <RenderResponesForm
                                        {...props}
                                    />
                                </>
                            );
                        }}
                    >
                    </Formik>

                </ModalBody>
            </Modal>

        </>
    );
}
)

const validationSchema = Yup.object().shape({
    message: Yup.string().required("This field is required"),
    prescription: Yup.string(),
});

export const RenderResponesForm = (props) => {
    const { initialValues, handleSubmit } = props;

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <Field
                        id="message"
                        name="message"
                        value={initialValues.message}
                        autoComplete="off"
                        type="message"
                        label="Message"
                        component={FormikInputField}
                        placeholder={"message"}
                    />
                </div>
                <div className="form-group">
                    <Field
                        id="prescription"
                        name="prescription"
                        value={initialValues.prescription}
                        autoComplete="off"
                        type="prescription"
                        label="prescription"
                        component={FormikTextAreaField}
                        placeholder={"prescription"}
                    />
                </div>
                <div className="apply-btn-action mb-3">
                    <input type="submit" value="Save" className='nex-btn-primary btn' />
                </div>
            </form>
        </>
    )
}