import logo_white from "assets/images/logo-white-transparent.png";
import graduation_cap_icon from "assets/images/graduation-cap.png";
import suitcase_icon from "assets/images/suitcase.png";
import pin_small_icon from "assets/images/pin-small.png";
import doctor_profile_img from "assets/images/doctor-profile-img.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";

const NewDoctorProfile = (props) => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <div className="nex-bg-light-blue">
            <main className="nex-login-register--w">
                <div className="container d-flex lg" data-aos="fade-up">
                    <div className="nex-form-aside nex-form-aside-v2">
                        <div className="logo" data-aos="fade-down" data-aos-duration="800" data-aos-delay="800">
                            <a href="/"><img src={logo_white} alt="" /></a>
                        </div>
                        
                        <h2 className="nex-text-xxl" data-aos="fade-down" data-aos-duration="1200" data-aos-delay="300">Select your <br/> profile</h2>
                        <p className="nex-text-lg nex-mob-visible text-black" data-aos="fade-down" data-aos-duration="1200" data-aos-delay="1300">Please Select your profile and continue</p>
                    </div>
                    <div className="nex-form-fields--w nex-form-fields--w-v2 no-min-height">
                        <div className="nex-form-top-slot nex-mob-order-last" data-aos="fade-up" data-aos-duration="800" data-aos-delay="800">
                            <p className="nex-text-lg text-right">Don’t see your profile? <a href="#" className="nex-link-dark">Click here</a></p>
                        </div>
                        <div className="nex-form-center-slot no-center">
                            <div className="nex-list-filter-info nex-list-filter-info-v2" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">
                                <div className="nex-col-left">
                                    <p className="nex-text-lg">Searched result with <b>'Surya'</b></p>
                                    <p className="nex-text-lg text-black mt-2 nex-mob-hide">Please Select your profile and continue</p>
                                </div>
                                <div className="nex-col-right d-none d-lg-block">
                                    <p>36 profiles found</p>
                                </div>
                            </div>
                            <div className="nex-docor-profile nex-image-list" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">
                                <div className="nexogic-graphic">
                                    <img src={doctor_profile_img} alt="" className="nexogic-avatar lg" />
                                </div>
                                <div className="nexogic-info">
                                    <h3 className="nex-text-xl">Dr. Surya Prakash Rao {/* <a href="" className="nex-text-base">Claim this Profile</a> */}</h3>
                                    <ul className="nex-inline-list">
                                        <li><a href="#">Cardiology</a></li>
                                        <li><a href="#">Radiology</a></li>
                                    </ul>
                                    <p><span className="nex-icon xs"><img src={graduation_cap_icon} alt="" /></span>BAMS</p>
                                    <p><span className="nex-icon xs"><img src={suitcase_icon} alt="" /></span>23+ Experience</p>
                                    <p className="nex-lead text-dark"><span className="nex-icon xs"><img src={pin_small_icon} alt="" /></span>Hyderabad, Telengana, India</p>
                                </div>
                            </div>
                            <div className="nex-docor-profile nex-image-list" data-aos="fade-up" data-aos-duration="900" data-aos-delay="300">
                                <div className="nexogic-graphic">
                                    <img src={doctor_profile_img} alt="" className="nexogic-avatar lg" />
                                </div>
                                <div className="nexogic-info">
                                    <h3 className="nex-text-xl">Dr. Surya Prakash Rao {/* <a href="" className="nex-text-base">Claim this Profile</a> */}</h3>
                                    <ul className="nex-inline-list">
                                        <li><a href="#">Cardiology</a></li>
                                        <li><a href="#">Radiology</a></li>
                                    </ul>
                                    <p><span className="nex-icon xs"><img src={graduation_cap_icon} alt="" /></span>BAMS</p>
                                    <p><span className="nex-icon xs"><img src={suitcase_icon} alt="" /></span>23+ Experience</p>
                                    <p className="nex-lead text-dark"><span className="nex-icon xs"><img src={pin_small_icon} alt="" /></span>Hyderabad, Telengana, India</p>
                                </div>
                            </div>
                            <div className="nex-docor-profile nex-image-list" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                                <div className="nexogic-graphic">
                                    <img src={doctor_profile_img} alt="" className="nexogic-avatar lg" />
                                </div>
                                <div className="nexogic-info">
                                    <h3 className="nex-text-xl">Dr. Surya Prakash Rao {/* <a href="" className="nex-text-base">Claim this Profile</a> */}</h3>
                                    <ul className="nex-inline-list">
                                        <li><a href="#">Cardiology</a></li>
                                        <li><a href="#">Radiology</a></li>
                                    </ul>
                                    <p><span className="nex-icon xs"><img src={graduation_cap_icon} alt="" /></span>BAMS</p>
                                    <p><span className="nex-icon xs"><img src={suitcase_icon} alt="" /></span>23+ Experience</p>
                                    <p className="nex-lead text-dark"><span className="nex-icon xs"><img src={pin_small_icon} alt="" /></span>Hyderabad, Telengana, India</p>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </main>
        </div>
    )
}

export default  NewDoctorProfile;