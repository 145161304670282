import React from 'react'

export default function VisitForm() {
  return (
    
        <>
            <div className="row need-margin">
                <div className="col-lg-6 form-group">
                    <label htmlFor="" className="form-label">Visit</label>
                    <select className='form-control' id='vf_select_visit' name='vf_select_visit'>
                        <option value="">Follow up visit</option>
                    </select>
                </div>
                <div className="col-lg-6 form-group">
                    <label htmlFor="" className="form-label">This appointment is for</label>
                    <select className='form-control' id='vf_appoint_f' name='vf_appoint_f'>
                        <option value="">Anna Smith</option>
                    </select>
                </div>
                <div className="col-lg-12 form-group">
                    <label htmlFor="" className="form-label">Insurance</label>
                    <select className='form-control' id='vf_Insurance' name='vf_Insurance'>
                        <option value="">I am paying for myself</option>
                    </select>
                </div>
                <div className="col-lg-6 form-group">
                    <label htmlFor="" className="form-label">Email</label>
                    <input type="text" id='vf_email' name='vf_email' className='form-control' placeholder='annasmith@gmail.com' />
                </div>
                <div className="col-lg-6 form-group">
                    <label htmlFor="" className="form-label">Phone</label>
                    <input type="text" id='vf_phone' name='vf_phone' className='form-control' placeholder='+919999999999' />
                </div>
                <div className="col-lg-12 form-group">
                    <label htmlFor="" className="form-label">Purpose of visit</label>
                    <select className='form-control' id='vf_purpose' name='vf_purpose'>
                        <option value="">Illness</option>
                    </select>
                </div>
                <div className="col-lg-12 mb-1 text-right nexogic-flex-action">
                    <div className="nexogic-fee-preview mr-auto">
                        Consultation fee : <b className='price'>₹700</b>
                    </div>
                    <input id='vf_btn_submit' name='vf_btn_submit' type="submit" className='btn nexogic_primary_button ' value="Confirm appointment" />
                </div>
            </div>
            
            
            
        
            
        </>
    
  )
}
