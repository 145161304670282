import React from 'react'
import "./css/invoice2.scss";
import HtmlStringToPdf from '../SecondOpinion/components/EmailPreview/HtmlStringToPdf';

import logo  from 'assets/images/logo.png'

const InvoiceTwo = () => {
  return (
    <>
      <div className='text-center'>
        <HtmlStringToPdf target="#invoice-pdf-two" openAs={'browser'}>Open Invoice 2 as browser</HtmlStringToPdf>
      </div>
      <div className="pdf_wraper pdf-invoice-w v2" id='invoice-pdf-two' style={{ transformOrigin: 'top left', width: '595px', height:'841px', margin: '0 auto'}}>
        <div className="invoice-w">
            <div className="invoice-head">
                <div className="col1">
                    <h1>[Medical Center Name]</h1>
                    <div className="address">
                        <p className="small">[Medical Center Address]</p>
                        <p className="small">[City], [State] [Postal Code]</p>
                    </div>
                    <div className="contact-nums">
                        <p className="small">[Medical Center Phone Number]</p>
                        <p className="small">[Medical Center Email Address]</p>
                    </div>
                </div>
                <div className="col2">
                    <div className="invoice-txt">Invoice</div>
                </div>
            </div>
            <div className="invoice-body">
                <div className="billing-adrs">
                    <div className="label">Bill To</div>
                    <div className="val">
                        <p>[Sample Patient Name ]</p>
                        <p>[Sample Patient Address line 1]</p>
                        <p>[City], [State], [Postal code]</p>
                    </div>
                </div>
                <div className="invoice-info">
                    <div className="item">
                        <div className="key">Invoice Number</div>
                        <div className="val">2001321</div>
                    </div>
                    <div className="item">
                        <div className="key">Date</div>
                        <div className="val">1/8/2024</div>
                    </div>
                    <div className="item">
                        <div className="key">Physician name</div>
                        <div className="val"></div>
                    </div>
                </div>

                <div className="tbl-w">
                    <table className="invoice-tbl">
                        <tr>
                            <th>Description</th>
                            <th>Quantity</th>
                            <th>Unit price</th>
                            <th>Amount</th>
                        </tr>
                        <tr>
                            <td>Medical Materials</td>
                            <td>1</td>
                            <td>Rs. 100 </td>
                            <td>Rs. 100</td>
                        </tr>
                        <tr>
                            <td>Medical Services</td>
                            <td>1</td>
                            <td>Rs. 20 </td>
                            <td>Rs. 20</td>
                        </tr>
                        <tr>
                            <td>Product name</td>
                            <td>1</td>
                            <td>Rs. 0 </td>
                            <td>Rs. 0</td>
                        </tr>
                        <tr className="total">
                            <td></td>
                            <td></td>
                            <td>Total </td>
                            <td>Rs. 120</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className="invoice-footer">
                <p>[Bank Details]</p>
                <p>[Terms & Conditions]</p>
            </div>
        </div>
    </div>
    </>
  )
}



export default InvoiceTwo;