import { useState } from "react";
import { Collapse } from 'reactstrap';
import jobInfoImg from 'assets/images/job-left-icon.png';
import { TalentService } from "utils/TalentService";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { FAILED_TO_FETCH_DATA, FAILTOSAVERECOED } from "utils/Constants";
import { useEffect } from "react";
import CommonLoader from "common/CommonLoader";

function JobCreateFormOne(props) {
    const { jobToBeCreate, setJobToBeCreate, jobToBeUpdated, setJobToBeUpdated, jobToBeCloned, setJobToBeCloned, isLoadingFor2ndStep } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpen2, setDropdownOpen2] = useState(false);
    const [draftJobs, setDraftJobs] = useState([]);
    const [allMyJobs, setAllMyJobs] = useState([]);
    const toggleDropdown = () => {
        setDropdownOpen(prevState => !prevState);
        setDropdownOpen2(false);
        setJobToBeCreate(false);
    };
    const toggleDropdown2 = () => {
        setDropdownOpen2(prevState => !prevState);
        setDropdownOpen(false);
        setJobToBeCreate(false);
    };


    const getDraftJobs = () => {
        TalentService.listMyJobs(0, "DRAFT")
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setDraftJobs(data.contents);
                } else {
                    showErrorMessage(FAILED_TO_FETCH_DATA);
                }
            })
    }

    const getAllMyJobs = () => {
        TalentService.listMyJobs(0, "PUBLISHED")
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setAllMyJobs(data.contents);
                } else {
                    showErrorMessage(FAILED_TO_FETCH_DATA);
                }
            })
    }

    useEffect(() => {
        getDraftJobs();
        getAllMyJobs();
    }, [])

    const handleJobCreateClick = () => {
        setJobToBeCreate(true);
        setJobToBeUpdated(null);
        setJobToBeCloned(null);
        setDropdownOpen(false);
        setDropdownOpen2(false);
    };

    const handleDraftJobClick = (jobId) => {
        setJobToBeCreate(false);
        setJobToBeUpdated(jobId);
        setJobToBeCloned(null);
    };

    const handleJobClick = (jobId) => {
        setJobToBeCreate(false);
        setJobToBeUpdated(null);
        setJobToBeCloned(jobId)
    };

    return (
        <>
            <div className="nex-job-form-left-sec pt-4 mt-4">
                <div className="nex-job-info">
                    <img src={jobInfoImg} alt="" className="graphic" />
                    <h2 className='title'>How can we help you get started?</h2>
                </div>
            </div>
            <div className="nex-job-form-right-sec">
                {isLoadingFor2ndStep
                    ?
                    <div className="loading-spiner text-center m-5">
                        <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                    </div>
                    :
                    <>
                        <div className="form-group">
                            <div className={`nex-accor-btn-w ${jobToBeCreate && 'open'}`}>
                                <span onClick={() => handleJobCreateClick()} className='btn btn-light-primary w-100'>
                                    I want to create a new job
                                    <span className="nex-arrow-right">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071Z" fill="#646464" />
                                        </svg>
                                    </span>
                                </span>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className={`nex-accor-btn-w ${dropdownOpen ? 'open' : ''}`}>
                                <span onClick={toggleDropdown} className={`btn btn-light-primary w-100 ${dropdownOpen ? 'open' : ''}`}>
                                    I want to continue editing a Draft
                                    <span className="nex-arrow-right">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071Z" fill="#646464" />
                                        </svg>
                                    </span>
                                </span>
                                <Collapse className="nex-collapse-div" isOpen={dropdownOpen}>
                                    <div className="nex-collapse-card-accordion nexogic-custom-scroll">
                                        {draftJobs.length > 0 ? draftJobs.map(job => (
                                            <div className={`nex-collapse-item ${jobToBeUpdated === job.profileId ? 'selected' : ''}`}
                                                key={job.id} onClick={() => handleDraftJobClick(job.profileId)}>
                                                {job.title}
                                                {jobToBeUpdated === job.profileId && <span>(Selected)</span>}
                                            </div>
                                        )) : (
                                            <div className="nex-collapse-item" disabled>No pending drafts!</div>
                                        )}
                                    </div>
                                </Collapse>
                            </div>

                        </div>

                        <div className="form-group">
                            <div className={`nex-accor-btn-w ${dropdownOpen2 ? 'open' : ''}`}>
                                <span onClick={toggleDropdown2} className={`btn btn-light-primary w-100 ${dropdownOpen2 ? 'open' : ''}`}>
                                    I want to rework on a previous job post
                                    <span className="nex-arrow-right">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071Z" fill="#646464" />
                                        </svg>
                                    </span>
                                </span>
                                <Collapse className="nex-collapse-div" isOpen={dropdownOpen2}>
                                    <div className="nex-collapse-card-accordion nexogic-custom-scroll">
                                        {allMyJobs.length > 0 ? allMyJobs.map(job => (
                                            <div className={`nex-collapse-item ${jobToBeCloned === job.profileId ? 'selected' : ''}`} key={job.id} onClick={() => handleJobClick(job.profileId, job.title)}>
                                                {job.title}
                                                {jobToBeCloned === job.profileId && <span>(Selected)</span>}
                                            </div>
                                        )) : (
                                            <div className="nex-collapse-item" disabled>No Jobs available!</div>
                                        )}
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    );
}

export default JobCreateFormOne;
