import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import RefaralAsideFilter from "../RefaralAsideFilter";
import { ReferralService } from "utils/ReferralService";
import { FAILED_TO_FETCH_DATA, FAILTOSAVERECOED } from "utils/Constants";
import CommonLoader from "common/CommonLoader";
import Referral from "../Referral";
import notfoundImg from 'assets/images/not-found-img.png'
import { MultiSelect } from "react-multi-select-component";
import { isInstitutionAdmin, isPractitioner } from "utils/UserHelper";
import { getFullAddress } from "utils/Utils";
import { InstitutionServices } from "utils/InstitutionServices";
import filter_icon from "assets/images/svgs/filter-blue-icon.svg";
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faIdCard, faListAlt, faTable } from "@fortawesome/free-solid-svg-icons";
import ReferralsTabularView from "components/tabularView/ReferralsTabularView";
import { useSelector } from "react-redux";
import PageTitle from "common/PageTitle/PageTitle";
import ListTableSwitch from "common/ListTableSwitch/ListTableSwitch";
import RefaralAllFilter from "../RefaralAllFilter";

const SentReferals = (props) => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState("");
    const [referrals, setReferrals] = useState([]);
    const [query, setQuery] = useState("");
    const [pageNo, setPageNo] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [associatedInstitutions, setAssociatedInstitutions] = useState([]);
    const [insSelected, setInsSelected] = useState(associatedInstitutions);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [viewMode, setViewMode] = useState("card");
    const currentUser = useSelector((state) => state.auth.currentUser);

    const getSentReferrals = (pageNo) => {

        let instIds = "";
        instIds = insSelected.map(item => item.value);
        const commaSeparatedString = instIds.join(',');

        return ReferralService.getReferrals("SENT", query, pageNo, commaSeparatedString)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    if (data?.currentPage < (data?.totalPages - 1))
                        setHasMore(true);
                    else
                        setHasMore(false);
                    return data;
                } else {
                    console.log(FAILTOSAVERECOED);
                    return null;
                }
            })
            .catch((error) => {
                console.error("Error retrieving referrals:", error);
                return null;
            });
    };

    // Function to load more referrals on scroll
    const fetchMoreReferrals = () => {
        setIsLoadingMore(true);
        getSentReferrals(pageNo)
            .then((data) => {
                if (data && data.contents.length > 0) {
                    setReferrals((prevReferrals) => [...prevReferrals, ...data.contents]);
                    setPageNo(pageNo + 1);
                    setIsLoadingMore(false);
                }
            })
            .catch((error) => {
                console.error("Error fetching more referrals:", error);
                setIsLoadingMore(true);
            });
    };

    const getAssociatedInstitutions = () => {
        const insts = currentUser.groups;
        const promises = insts.map(instId => InstitutionServices.getInstitutionById(instId));
        Promise.all(promises)
            .then((responses) => {
                const successfulResponses = responses.filter(response => response.status === 200);
                if (successfulResponses.length > 0) {
                    let inst = successfulResponses.map(response => {
                        const institution = response.data;
                        return {
                            value: institution.guid,
                            label: getFullAddress(institution)
                        };
                    });
                    setAssociatedInstitutions(inst);
                    setInsSelected(inst);
                } else {
                    console.log("No successful institution data found");
                }
            })
            .catch((err) => {
                console.log("Something went wrong", err);
            });
    };


    useEffect(() => {
        setIsLoading(true);
        // Fetch initial referrals when component mounts
        const fetchReferrals = async () => {
            const data = await getSentReferrals(0);
            if (data && data.contents.length > 0) {
                setReferrals(data.contents);
                setPageNo(pageNo + 1);
                setIsLoading(false);
            } else {
                setReferrals([]);
                setIsLoading(false);
            }
        };

        fetchReferrals();
    }, [query, insSelected]);


    useEffect(() => {
        if (location.pathname) {
            setActiveTab(location.pathname)
        }
        if (isInstitutionAdmin() && isPractitioner()) {
            getAssociatedInstitutions();
        }
    }, [location, query]);

    const customStrings = {
        selectSomeItems: "Select Institution(s)", // Customize this message
        allItemsAreSelected: "All Institutions are selected", // Customize this message
        selectAll: "Select All",
        search: "Search here",
    };

    const [isFilterOn, setIsFilterOn] = useState(false);

    const renderReferrals = () => {
        if (viewMode === 'card') {
            return (
                <>
                    {referrals.map((referral, index) => (
                        <Referral referral={referral} type={"SENT"} key={`${referral?.id}-${index}`} />
                    ))}
                </>
            );
        } else { // For table view
            return (
                <ReferralsTabularView theader={['Case Id', 'Patient Name', 'Speciality', 'Receiving Physician', 'Status']} tdata={referrals} viewmode={viewMode} type={"SENT"} />
            );
        }
    };

    return (
        <>
            <Card className="bg-transparent border-0" id="nex-sent-referals-page-w">
                <CardBody className="p-0">
                    <Row className="mb-5 nexogic-sidebar-cont-wrap">
                        {/* {(props?.showTools) &&
                            <Col lg={3} md={4} sm={12} className="nexogic-sidebar-col- mb-3 nexogic-referrals-aside nex-mob-ref-sticky">
                                <div className="w-100 nexogic-sidebar-stiky nex-mob-fooler-sticky-actions">
                                    <Card className="nexogic-filter-btn-card mb-3">
                                        <div className="btn-groups">
                                            <Link to="/referrals/add-patient" id="nex-rr-referals-add-patient-link"><Button outline className="nexogic_primary_button_outline">Add a Patient</Button></Link>
                                            <Link to="/referrals/refer-patient" id="nex-rr-referals-refer-patient-link"><Button outline className="nexogic_primary_button">Refer a Patient</Button></Link>
                                        </div>
                                    </Card>
                                    <RefaralAsideFilter calledBy={"SentReferrals"} setQuery={setQuery} insSelected={insSelected} />
                                </div>
                            </Col>
                        } */}

                        <Col lg={12} md={12} sm={12} className="nexogic-content-col" id="nex-my-send-ref-page-cont">
                        <PageTitle title="My Sent Referrals" className="mb-0">
                                <ListTableSwitch viewMode={viewMode} cardViewAction={() => setViewMode("card")} tableViewAction={() => setViewMode("table")} >
                                    <Link to="/referrals/add-patient" id="nex-rr-referals-add-patient-link"><Button className="nex-btn-primary"><span className="icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" fill="white" />
                                        <path d="M20.9531 13V12.995M19 7.4C19.2608 6.58858 20.0366 6 20.9531 6C22.0836 6 23 6.89543 23 8C23 9.60675 21.2825 8.81678 21 10.5M8 15H16C18.2091 15 20 16.7909 20 19V21H4V19C4 16.7909 5.79086 15 8 15ZM16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    </span> Add a Patient</Button></Link>
                                    <Link to="/referrals/refer-patient" id="nex-rr-referals-refer-patient-link"><Button className="nex-btn-primary"><span className="icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" fill="white" />
                                        <path d="M20 8.5V13.5M17.5 11H22.5M8 15H16C18.2091 15 20 16.7909 20 19V21H4V19C4 16.7909 5.79086 15 8 15ZM16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    </span> Refer a Patient</Button></Link>
                                </ListTableSwitch>
                            </PageTitle>

                            <RefaralAllFilter calledBy={"SentReferrals"} setQuery={setQuery} insSelected={insSelected} />






                            {/* <div className="view-toggle nexogic-view-toggle">
                                <div className="btns">
                                    <FontAwesomeIcon title="Card View" color={viewMode === "card" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faAddressCard} onClick={() => setViewMode("card")} /> | <FontAwesomeIcon title="Table View" color={viewMode === "table" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faListAlt} onClick={() => setViewMode("table")} />
                                </div>
                            </div> */}
                            {/* {props.isTitle &&
                                <h1 className="">My Sent ReferralsSSS
                                    {(isInstitutionAdmin() && isPractitioner() && associatedInstitutions.length > 0) &&
                                        <span className="nex-filter-drop-btn" onClick={() => setIsFilterOn(!isFilterOn)}><img src={filter_icon} /></span>
                                    }
                                </h1>
                            } */}
                            {/* {(isInstitutionAdmin() && isPractitioner() && associatedInstitutions.length > 0) &&
                                <>{isFilterOn &&
                                    <Card className="nex-card-top-filter">
                                        <CardBody className="nex-filter-col">

                                            <div className="action-group">
                                                <span className="card-text">Your Institutions</span>
                                                <div className="nex-filter-select">
                                                    <MultiSelect
                                                        options={associatedInstitutions}
                                                        value={insSelected}
                                                        onChange={(value) => setInsSelected(value)}
                                                        labelledBy="Select"
                                                        overrideStrings={customStrings}
                                                    />
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                }</>
                            } */}
                            {
                                isLoading ?
                                    <div className="loading-spiner text-center  mt-2">
                                        <CommonLoader />
                                    </div>
                                    :
                                    <div>
                                        {referrals && referrals.length > 0 ?
                                            <>
                                                {renderReferrals()}
                                            </>
                                            :
                                            <div className="nexogic-not-found-card">
                                                <div className="nexogic-not-found-img-w">
                                                    <img src={notfoundImg} alt="" />
                                                </div>
                                                <h2 className="card-title">Referral Not Found</h2>
                                            </div>
                                        }
                                        {hasMore &&
                                            <div className="text-center mt-3">
                                                {isLoadingMore
                                                    ?
                                                    <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                                                    :
                                                    <LoadMoreButton loadMore={fetchMoreReferrals} />
                                                }
                                            </div>
                                        }
                                    </div>
                            }
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    )

}
SentReferals.defaultProps = {
    isTitle: true,
    showTools: true
}
export default SentReferals;
