import React, { useState, useEffect } from 'react';
import { Field, Formik } from 'formik';
import { Row, Col, Button, Spinner, Label } from 'reactstrap'
import * as Yup from 'yup';
import { withAlert } from 'react-alert'
import moment from 'moment'
import { TIME_CONSTANTS, InstitutionTypes } from "utils/Constants";
import FormikInputField from "components/forms/formikFields/FormikInputField"
import FormikDatePicker from "components/forms/formikFields/FormikDatePicker"
import { PractitionerService } from 'utils/PractitionerService'
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage"
import UploadDocuments from './UploadDocument';
import YearTypeaheadField from 'components/forms/formikFields/YearTypeaheadField';
import CommitteesTypeaheadField from 'components/forms/formikFields/CommitteesTypeaheadField';
import InstitutesTypeahead from 'pages/Onboarding/components/FormComponents/InstitutesTypeahead';
import NewInstitutionModal from "pages/Onboarding/components/FormComponents/NewInstituitionModal";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const validationSchema = Yup.object().shape({
  registrationNumber: Yup.string().min(3).max(100).required("This field is required").max(100, 'No longer than 100 characters').nullable(),
  registrationCouncil: Yup.lazy((value) => {
    if (value && value.name) {
      return Yup.object().shape({
        name: Yup.string().required(
          "This field is required. You must select from options"
        ),
      });
    } else {
      return Yup.mixed().test(
        "",
        "This field is required. You must select from options", // or add new
        (value) => value === {}
      );
    }
  }),
  yearOfRegistration: Yup.string().required("This field is required"),
});

const validationSchemaAdmin = Yup.object().shape({
  registrationNumber: Yup.string().min(3).max(100).max(100, 'No longer than 100 characters').nullable(),
  registrationCouncil: Yup.lazy((value) => {
    if (value && value.name) {
      return Yup.object().shape({
        name: Yup.string().required(
          "This field is required. You must select from options"
        ),
      });
    } else {
      return Yup.mixed().test(
        "",
        "This field is required. You must select from options", // or add new
        (value) => value === {}
      );
    }
  }),
  yearOfRegistration: Yup.string(),
});

const years = TIME_CONSTANTS.YEARS();

const RenderForm = (props) => {
  const { isValid, dirty, handleSubmit, isLoading, onDocumentsChange, toggle } = props
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);


  useEffect(() => {
    if (props.values.id && !props.values.registrationCouncil.hasOwnProperty('name')) {
      props.setFieldValue('registrationCouncil', { name: props.values.registrationCouncil }, true)
    }
  }, [props.values.registrationCouncil])

  const onNewInstitute = () => {
    toggleNested();
  };
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };
  return (
    <form onSubmit={handleSubmit} id='nex-rf-modal-form'>
      <Row form>
        <Col md={12} id='nex-rf-modal-reg-council'>
          {/*  {props.values.registrationCouncil} */}
          <Field
            id="registrationCouncil"
            name="registrationCouncil"
            label="Registration Council"
            type="text"
            component={InstitutesTypeahead}
            /* onNewInstituteReq={onNewInstitute} */
            instituteType={InstitutionTypes.BOARD_OR_REGISTRATION_COUNCIL}
            placeholder="Registration Council"
          />
        </Col>
      </Row>
      <Row form>
        <Col md={8}>
          <Field
            id="registrationNumber"
            name="registrationNumber"
            type="text"
            label="Registration Number"
            component={FormikInputField}
            placeholder="Number"
          />
        </Col>
        <Col md={4} id='nex-rf-modal-year-of-reg'>
          <Field
            id={"yearOfRegistration"}
            name={"yearOfRegistration"}
            type="text"
            component={YearTypeaheadField}
            label="Issue Year"
            placeholder={"YYYY"}
            spellCheck={false}
            years={years}
            autoComplete="off"
          /*    inputprops={{
               dateFormat: "yyyy",
               maxDate: new Date(),
               autoComplete: "off",
               showMonthYearPicker: true
             }} */
          />
        </Col>
      </Row>
      {/*   <Row>
        <Col>
          <Label>Registration documents</Label>
          <UploadDocuments {...props} onDocumentsChange={onDocumentsChange} />
        </Col>
      </Row> */}
      {/*<hr className="profile-info-modal-hr mt-0" />*/}
      <Row className="justify-content-end  modal-footer mx-0">
        {/* <Col>
          <p className="small font-italic text-danger">Once your profile is approved, registration details <strong>cannot be edited.</strong></p>
        </Col> */}
        {/* <Col xs="auto">
          <Button color="link" className="cancel-btn" size="sm" onClick={toggle} >Cancel</Button>
        </Col> */}
        <Col xs="auto" className='action-group'>
          {isLoading ?
            <div className="loading-spiner"><Spinner /></div>
            :
            <>
              <Button className="nexogic_primary_button" disabled={!isValid || !dirty} type="submit" id='nex-rf-modal-save-btn'>Save</Button>
              <Button className="nexogic_primary_button_outline" color="primary" type="button" onClick={toggle} id='nex-rf-modal-cancel-btn'>Cancel</Button>
              
            </>
          }
        </Col>
      </Row>
      <NewInstitutionModal
        isOpen={nestedModal}
        toggle={toggleNested}
        closeAll={closeAll}
      />
    </form>
  )
}

const RegistrationForm = (props) => {
  const [isLoading, setLoading] = useState(false)
  const [practitionerDocument, setPractitionerDocument] = useState([])
  const { details, toggle, setDetails, practitioner, getProfile, alert, isAdmin, selectedItem } = props

  const addMedicalRegistration = async (values) => {
    values.yearOfRegistration = parseInt(moment(values.yearOfRegistration).format("YYYY"));
    values.registrationCouncil = values.registrationCouncil.name;
    /*  let payload = {
       ...values,
       practitionerDocument: practitionerDocument.map(doc => ({ documentTitle: doc.name }))
     } */
    setLoading(true)

    /*  let formData = new FormData()
     formData.append('registrationDetails', JSON.stringify(payload))
     practitionerDocument.forEach(doc => {
       formData.append('file', doc)
     }) */
    PractitionerService.addUpdateRegistrationDocs(practitioner.guid, values)
      .then(response => {
        const { data, status } = response;
        if (status === 200 || status === 201) {
          setDetails()
          getProfile(practitioner.guid)
          showSuccessMessage("Registration details added successfully")
          toggle()
          setLoading(false)
        } else {
          setLoading(false)
          showErrorMessage("Failed to save registration details")
        }
      }, e => {
        setLoading(false)
        showErrorMessage(e.message)
      })

  }

  const updateMedicalRegistration = async (values) => {

    let payload = {
      ...values,
      // practitionerDocument: practitionerDocument.map(doc => ({ documentTitle: doc.name }))
    }
    values.registrationCouncil = values.registrationCouncil.name;
    setLoading(true)

    /*  let formData = new FormData()
     formData.append('registrationDetails', JSON.stringify(payload))
     practitionerDocument.forEach(doc => {
       formData.append('file', doc)
     }) */
    PractitionerService.updateRegistrationDocs(practitioner.guid, values, values.id)
      .then(response => {
        const { data, status } = response;
        if (status === 200) {
          setDetails()
          getProfile(practitioner.guid)
          showSuccessMessage("Registration details updated successfully")
          toggle()
          setLoading(false)
        } else {
          showErrorMessage(data?.message || "Failed to save registration details")
        }
      }, e => {
        showErrorMessage(e.message)
        setLoading(false)
      })

  }

  const onDocumentsChange = (newDocuments) => {
    setPractitionerDocument(newDocuments)
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={isAdmin ? validationSchemaAdmin : validationSchema}
        initialValues={selectedItem}
        onSubmit={(values) => {
          //  let yearOfRegistration = (isAdmin && values.yearOfRegistration === "") ? "" : moment(values.yearOfRegistration).format("YYYY-MM-DD")
          if (values.id) {
            updateMedicalRegistration(values)
          } else {
            addMedicalRegistration(values)
          }
        }}
        children={(formProps) => {
          return (
            <RenderForm
              toggle={toggle}
              isLoading={isLoading}
              onDocumentsChange={onDocumentsChange}
              {...props}
              {...formProps}
            />
          )
        }}
      >
      </Formik>
    </>
  );
}

export default withAlert()(RegistrationForm)