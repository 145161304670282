import Header from "common/Header";
import VerifyMobile from "components/OTPFlow/VerifyMobile";
import React, { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import { IdentityService } from "utils/IdentityService";
import { cleanUpPhoneNumber } from "utils/Utils";
import { useParams } from "react-router-dom";

const ClaimProfileNew = () => {
  const history = useHistory();
  const [values, setValues] = useState(null);
  const [userResponseGUID, setUserResponseGUID] = useState(null);
  const [userProfileId, setUserProfileId] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const { profileId } = useParams();

  useEffect(() => {
    otpVerificationPage();
    return () => {
      setUserResponseGUID(null);
    };
  }, [userResponseGUID]);

  useEffect(() => {
    setErrorMessage(errorMessage);
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  }, [errorMessage]);

  const onSubmit = (values, { setSubmitting }) => {
    // setValues(values);
    let phone = cleanUpPhoneNumber(values.phone);
    let utype = values.chkPatient ? "PATIENT" : "PRACTITIONER";
    values.userType=utype;
    setValues(values);

    const payload = {
      phoneNumber: phone,
      type: utype,
      rtType: userProfileId ? "CLAIM" : "REGISTRATION",
      profileId: userProfileId,
    };
    IdentityService.CreateRegistration(payload, "PHONE", payload.rtType)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setUserResponseGUID(response.data);
          setErrorMessage("");
        } else {
          if (response.validationErrors) {
            response.validationErrors.map((err) =>
              setErrorMessage(`${err.field}: ${err.message}`)
            );
          } else if (response.message) {
            setErrorMessage(response.message);
          }
        }
      })
      .catch((error) => {
        console.log("IdentityService.RegisterNewUser error", error);
        setErrorMessage(error.message);
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  const otpVerificationPage = () => {
    if (userResponseGUID?.id) {
      history.push(`/register/otp-verification/phone`, {
        data: {
          ...values,
          guid: userResponseGUID.id,
          profileId: userProfileId,
        },
      });
    }
  };

  useEffect(() => {
    if (profileId) {
      setUserProfileId(profileId);
    }
  }, [profileId]);

  return (
    <section id="claimProfile">
      <div className="nexogic-bg-light">
        <Header />
        <div className="theme-body-bg nexogic-dashboard-card">
          <Container className="nexogic-container-gap">
            <VerifyMobile handleSubmit={onSubmit} error={errorMessage} />
          </Container>
        </div>
      </div>
    </section>
  );
};

export default withAlert()(ClaimProfileNew);
