import logo_white from "assets/images/logo-white-nexogic.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useRef } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useMemo } from "react";
import * as Yup from "yup";
import FormikMobileField from "components/forms/formikFields/FormikMobileField";
import { Link } from "react-router-dom";
import SocialIconsForm from "../SocialIconsForm";
import { UserAuth } from "context/AuthContext";
import { Button, InputGroup } from 'reactstrap';
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import VerifyOtp from "pages/LoginSocialProfile/VerifyOtp";
import { phoneRegExp } from "utils/Constants";

const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
        .matches(phoneRegExp, "Mobile number is not valid")
        .required("This field is required"),
});

const PatientRegister = (props) => {
    const history = useHistory();
    const { state } = useLocation();
    const [isSubmitting, setSubmitting] = useState(false);
    const [userProfileId, setUserProfileId] = useState("");
    const { googleSignUp, facebookSignUp, appleSignUp, signUpWIthEmail, signInWithPhone, signUpWIthMobileOTP, user } = UserAuth();
    const [isPatient, setIsPatient] = useState(true);
    const [verificationId, setVerificationId] = useState(null);
    const isComponentMounted = useRef(true);
    const [otpSent, setOtpSent] = useState(false);
    const [mobileNumber, setMobileNumber] = useState("");

    const handleGoogleSignUp = async () => {
        try {
            const signedInUser = await googleSignUp();
            const { name, email, idToken, refreshToken, profiledetails, phoneNumber, provider } = signedInUser;
            if (!profiledetails) {
                try {
                    history.push(`${(isPatient) ? '/u/create-account' : '/register/onboarding'}`, {
                        firstName: name?.split(' ')[0],
                        lastName: name?.split(' ')[1] || '',
                        email: email,
                        phoneNumber: phoneNumber,
                        token: idToken,
                        refreshToken: refreshToken,
                        utype: isPatient,
                        claim: userProfileId ? true : false,
                        profileId: userProfileId ?? "",
                    });
                } catch (apiError) {
                    console.error('API call failed:', apiError);
                    showErrorMessage("Something went wrong..please try after sometime");
                    history.push('/login');
                }
            } else {
                console.log("Account Already Exists..");
                showErrorMessage("Account Already Exists..Please Login");
                history.push('/login')
            }
        } catch (error) {
            console.error('Google sign-in failed:', error);
        }
    }
    const handleFacebookSignUp = async () => {
        try {
            const signedInUser = await facebookSignUp();
            const { name, email, idToken, refreshToken, profiledetails, phoneNumber, provider } = signedInUser;
            if (!profiledetails) {
                try {
                    history.push(`${(isPatient) ? '/u/create-account' : '/register/onboarding'}`, {
                        firstName: name?.split(' ')[0],
                        lastName: name?.split(' ')[1] || '',
                        email: email,
                        phoneNumber: phoneNumber,
                        token: idToken,
                        refreshToken: refreshToken,
                        utype: isPatient,
                        provider: provider
                    });
                } catch (apiError) {
                    console.error('API call failed:', apiError);
                    showErrorMessage("Something went wrong..please try after sometime");
                    history.push('/login');
                }
            } else {
                console.log("Account Already Exists..");
                showErrorMessage("Account Already Exists..Please Login");
                history.push('/login')
            }
        } catch (error) {
            console.error('Facebook sign-in failed:', error);
        }
    }

    const handleAppleSignUp = async () => {
        try {
            const signedInUser = await appleSignUp();
            const { name, email, idToken, refreshToken, profiledetails, phoneNumber, provider } = signedInUser;
            if (!profiledetails) {
                try {
                    history.push(`${(isPatient) ? '/u/create-account' : '/register/onboarding'}`, {
                        firstName: name?.split(' ')[0],
                        lastName: name?.split(' ')[1] || '',
                        email: email,
                        phoneNumber: phoneNumber,
                        token: idToken,
                        refreshToken: refreshToken,
                        utype: isPatient,
                        provider: provider
                    });
                } catch (apiError) {
                    console.error('API call failed:', apiError);
                    showErrorMessage("Something went wrong..please try after sometime");
                    history.push('/login');
                }
            } else {
                console.log("Account ALready Exists..");
                showErrorMessage("Account Already Exists..Please Login");
                history.push('/login')
            }
        } catch (error) {
            console.error('Google sign-in failed:', error);
        }
    }

    const handleEmailPasswordSignUp = async (values, setSubmitting) => {
        try {
            const signedInUser = await signUpWIthEmail(values.email, values.password);
            const { name, email, idToken, refreshToken, profiledetails, phoneNumber, provider } = signedInUser;
            if (!profiledetails) {
                try {
                    history.push(`${(isPatient) ? '/u/create-account' : '/register/onboarding'}`, {
                        firstName: name?.split(' ')[0],
                        lastName: name?.split(' ')[1] || '',
                        email: email,
                        phoneNumber: phoneNumber,
                        token: idToken,
                        refreshToken: refreshToken,
                        utype: isPatient,
                        provider: provider
                    });
                } catch (apiError) {
                    console.error('API call failed:', apiError);
                    showErrorMessage("Something went wrong..please try after sometime");
                    history.push('/login');
                }
            } else {
                console.log("Account ALready Exists..");
                showErrorMessage("Account Already Exists..Please Login");
                history.push('/login')
            }
        } catch (error) {
            console.error('Google sign-in failed:', error);
        } finally {
            if (isComponentMounted.current) {
                setSubmitting(false);
            }
        }
    }

    const handleSendOtp = async (phoneNumber, setSubmitting, isComponentMounted) => {
        try {
            const confirmationResult = await signInWithPhone(phoneNumber);
            if (confirmationResult) {
                showSuccessMessage("OTP sent successfully");
                setMobileNumber(phoneNumber);
                setOtpSent(true);
            }
            // showSuccessMessage("OTP sent successfully");
            // setVerificationId(confirmationResult.verificationId);
            // history.push({
            //     pathname: '/register/verify-otp',
            //     state: { userProfileId: userProfileId, utype: isPatient },
            // });
        } catch (error) {
            console.error('Error sending OTP:', error);
            showErrorMessage('Unable to send OTP, Please refresh the page and try again');
        } finally {
            if (isComponentMounted.current) {
                setSubmitting(false);
            }
        }
    };

    const handleSignUpWithMobileOtp = async (code) => {
        try {
            const signedInUser = await signUpWIthMobileOTP(code)
            if (signedInUser) {
                const { name, email, idToken, refreshToken, profiledetails, phoneNumber, provider } = signedInUser;
                if (!profiledetails) {
                    try {
                        history.push(`${(isPatient) ? '/u/create-account' : '/register/onboarding'}`, {
                            firstName: name?.split(' ')[0],
                            lastName: name?.split(' ')[1] || '',
                            email: email,
                            phoneNumber: phoneNumber,
                            token: idToken,
                            refreshToken: refreshToken,
                            utype: isPatient,
                            provider: provider
                        });
                    } catch (apiError) {
                        console.error('API call failed:', apiError);
                        showErrorMessage("Something went wrong..please try after sometime");
                        history.push('/login');
                    }
                } else {
                    console.log("Account ALready Exists..");
                    showErrorMessage("Account Already Exists..Please Login");
                    history.push('/login')
                }
            }
        } catch (error) {
            console.error('Mobile sign-in failed:', error);
            showErrorMessage("Invalid Otp, Authentication failed")
        }
    }

    // useEffect(() => {
    //     return () => {
    //         isComponentMounted.current = false;
    //     };
    //   }, []);

    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <div className="nex-bg-light-blue">
            <main className="nex-login-register--w" >
                <div className="container sm d-flex" data-aos="fade-up">
                    <div className="nex-form-aside" >
                        <div className="logo" data-aos="fade-down" data-aos-duration="800" data-aos-delay="800">
                            <Link to={'/'}><img src={logo_white} alt="" /></Link>
                        </div>

                        <h2 className="nex-text-xxl nex-underline" data-aos="fade-down" data-aos-duration="1200" data-aos-delay="800">Hey, <br />
                            Create an account</h2>
                        <p className="nex-text-lg text-white mt-3">Register your details to continue with us</p>
                    </div>
                    <div className="nex-form-fields--w">
                        <div className="nex-form-top-slot" data-aos="fade-up" data-aos-duration="800" data-aos-delay="800">
                            <p className="nex-text-lg text-right">Are you a Provider?{' '}
                                <Link to={'/register'}>Register here</Link>
                            </p>
                        </div>
                        {/* <SocialIconsForm handleEmail={handleEmailPasswordSignUp} handleGoogle={handleGoogleSignUp} handleApple={handleAppleSignUp} /> */}
                        <div className="nex-form-center-slot">
                            <div className="nex-form-container">
                                {!otpSent ? (
                                    <>
                                        <div className="nex-social-login-btns--w" data-aos="fade-down" data-aos-duration="800" data-aos-delay="350">
                                            <SocialIconsForm handleEmail={handleEmailPasswordSignUp} handleGoogle={handleGoogleSignUp} handleFacebook={handleFacebookSignUp} handleApple={handleAppleSignUp} />
                                        </div>
                                        <div className="nex-or-txt--w" data-aos="fade-down" data-aos-duration="800" data-aos-delay="400">or</div>
                                        <div className="pt-2">
                                            <Formik
                                                initialValues={{ phoneNumber: '' }}
                                                validationSchema={validationSchema}
                                                onSubmit={({ phoneNumber }, { setSubmitting }) => handleSendOtp(phoneNumber, setSubmitting)}
                                            >
                                                {({ handleSubmit, isSubmitting, errors }) => (
                                                    <Form onSubmit={handleSubmit}>
                                                        <div className="nexogic-phone-field-wrap">
                                                            <InputGroup className="mt-3" size="large">
                                                                <Field
                                                                    id="mnf_phon"
                                                                    name="phoneNumber"
                                                                    type="text"
                                                                    component={FormikMobileField}
                                                                    placeholder="Phone Number" />
                                                            </InputGroup>
                                                        </div>
                                                        <Button id="btn_sign_in" size="lg" block className="btn btn-primary w-100" type="submit" disabled={isSubmitting}>Continue</Button>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </>
                                ) :
                                    <VerifyOtp handleVerifyOtp={handleSignUpWithMobileOtp} mobileNumber={mobileNumber} setOtpSent={setOtpSent} handleSubmit={handleSendOtp} />
                                }
                            </div>
                        </div>
                        <div className="nex-form-bottom-slot">
                            <p className="nex-text-lg text-center">Are you a member?{' '}
                                <Link to="/login" className="nex-link-dark"> Login </Link>
                            </p>
                        </div>
                    </div>
                </div>
                <div id="recaptcha-container"></div>
            </main>
        </div>
    )
}

export default PatientRegister;