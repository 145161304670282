import parse from "html-react-parser";
import { useState } from "react";
import { Button } from "reactstrap";

const Description = (props) => {
    const {description} = props;
    const [isOpen, setIsOpen] = useState(false);

    if (description && description !== "<p><br></p>") {
        if (isOpen) {
            return (
                <>
                    <div className="card-text">
                        {parse(description)}
                        <Button
                            color="link"
                            onClick={() => setIsOpen(false)}
                            className="readmore "
                        >
                            Read less
                        </Button>
                    </div>
                </>
            );
        } else {
            const showLessSummary = description.slice(0, 300) + "...";
            if (description.length > 300) {
                return (
                    <>
                        <div className="card-text">
                            {parse(showLessSummary)}
                            <Button
                                color="link"
                                onClick={() => setIsOpen(true)}
                                className="readmore "
                            >
                                Read more
                            </Button>
                        </div>
                    </>
                );
            } else {
                return (
                    <div className="about-text">{parse(description)}</div>
                );
            }
        }
    } else {
        return "";
    }
}

export default Description;