import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { Field, Formik } from "formik";
import { useState } from "react";
import { withAlert } from "react-alert";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { Button, Col, Row, Spinner } from "reactstrap";
import { FAILTOSAVERECOED } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import { getUser } from "utils/UserHelper";
import * as Yup from "yup";

export const yearmonth = /^(0[1-9]|10|11|12)-\d{4}$/;

const RenderForm = (props) => {
  const { handleSubmit, isLoading, options, toggle } = props;
  return (
    <form className="my-form" onSubmit={handleSubmit}>
      <div>
        <Row>
          <Col xs={12} sm={6}>
            <Field
              name="publishedOn"
              id="publishedOn"
              autocomplete="publishedOn"
              type="text"
              label="Published On"
              component={FormikInputField}
              placeholder="MM-YYYY"
            />
          </Col>
          <Col xs={12} sm={6}>
            <Field
              name="publicationType"
              id="publicationType"
              autocomplete="publicationType"
              type="text"
              label="Publication Type"
              component={FormikSelectField}
              inputprops={{
                name: "publicationType",
                options: options,
                defaultOption: "Select",
              }}
            />
          </Col>
          <Col xs={12}>
            <Field
              name="title"
              id="title"
              autocomplete="title"
              type="text"
              label="Title"
              component={FormikInputField}
              placeholder="Title"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Field
              name="url"
              id="url"
              autocomplete="url"
              type="url"
              label="URL"
              component={FormikInputField}
              placeholder="E.g: https://www.google.com"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12}>
            <Field
              name="author"
              id="author"
              autocomplete="author"
              type="text"
              label="Authors"
              component={FormikInputField}
              placeholder="Authors"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Field
              name="description"
              id="description"
              autocomplete="abstract"
              type="textarea"
              rows={4}
              cols={5}
              label="Abstract"
              component={FormikInputField}
              placeholder="Abstract"
            />
          </Col>
        </Row>
      </div>
      {/* <hr className="profile-info-modal-hr mt-0" />*/}
      <Row className="justify-content-end  modal-footer ">
        <Col xs="auto" className="action-group">
          {isLoading ? (
            <div className="loading-spiner">
              <Spinner />
            </div>
          ) : (
            <>
              
              <Button id="pf_submit" className="nexogic_primary_button" color="primary" type="submit">
                Save
              </Button>
              <Button id="pf_cancel" 
                className="nexogic_primary_button_outline"
                color="primary"
                type="button"
                onClick={toggle}
              >
                Cancel
              </Button>
            </>
          )}
        </Col>
      </Row>
    </form>
  );
};

const validationSchema = Yup.object({
  title: Yup.string().min(3).max(200).required("This field is required"),
  description: Yup.string()
    .min(10, "Abstract must be at least 10 characters")
    .max(500, "Abstract with in 500 characters")
    .required("This field is required"),
  url: Yup.string()
    .matches(
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
      "Enter a valid url."
    )
    .nullable(),
  author: Yup.string().min(2).max(100).required("This field is required"),
  publishedOn: Yup.string()
    .matches(yearmonth, "Please enter valid format (MM-YYYY)")
    .required("This field is required"),
  publicationType: Yup.string().required("This field is required"),
});

const PublicationForm = (props) => {
  const { selectedItem, getData, toggle, alert, pubTypes, isAdmin } = props;
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");

  const addItem = async (value) => {
    const userGUID = isAdmin ? props.guid : getUser().guid;
    setLoading(true);
    try {
      const payload = {
        ...value,
        type: value?.publicationType,
      };

      PractitionerService.registerPublications(payload, userGUID, true)
        .then(async (response) => {
          const { status, validationErrors } = response;
          if (status === 200) {
            await getData();
            toggle();
            showSuccessMessage("Profile updated successfully");
          } else {
            setLoading(false);
            if (
              validationErrors &&
              validationErrors &&
              validationErrors.length > 0
            ) {
              const error = [];
              validationErrors.forEach((errors) => {
                error.push(errors.message);
              });
              showErrorMessage(error.join(","));
            } else {
              showErrorMessage(FAILTOSAVERECOED);
            }
          }
        })
        .catch((error) => {
          setErrorMessage(error.message);
          setLoading(false);
          console.log(error);
        });
    } catch (e) {
      setErrorMessage(e.message);
      setLoading(false);
    }
  };

  const updateItem = async (value) => {
    const userGUID = isAdmin ? props.guid : getUser().guid;
    setLoading(true);
    const payload = {
      ...value,
      type: value?.publicationType,
    };
    PractitionerService.updatePublications(payload, userGUID, payload?.id, true)
      .then(async (response) => {
        const { status, validationErrors } = response;
        if (status === 200) {
          await getData();
          toggle();
          showSuccessMessage("Profile updated successfully");
        } else {
          setLoading(false);
          if (
            validationErrors &&
            validationErrors &&
            validationErrors.length > 0
          ) {
            const error = [];
            validationErrors.forEach((errors) => {
              error.push(errors.message);
            });
            showErrorMessage(error.join(","));
          } else {
            showErrorMessage(FAILTOSAVERECOED);
          }
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={selectedItem}
        onSubmit={(values) => {
          if (values.id) {
            updateItem(values);
          } else {
            addItem(values);
          }
        }}
        children={(props) => {
          return (
            <RenderForm
              toggle={toggle}
              {...props}
              isLoading={isLoading}
              options={pubTypes}
            />
          );
        }}
      />
    </>
  );
};
export default withAlert()(PublicationForm);
