import API from "./API";
import API_New from "./API_New";
import API_Factory from "./API_Factory";
import  secureLocalStorage  from  "react-secure-storage";
//import moment from "moment"
/*const convertToHours = (data) => {
    const { schedules } = data
    return schedules.map((item, index, array) => {
        const timings = item.timings.map((timing, index, array) => {
            const startTime = moment(timing.startTime).utc().format('HH:mm:ss')
            const endTime = moment(timing.endTime).utc().format('HH:mm:ss')
            return { ...timing, startTime, endTime }
        })
        return { ...item, timings }
    });
}*/

const searchFacilityByName = async (name) => {
  try {
    let response = await API.get(
      "facility/by-name/" + name + "?types=establishment"
    );
    return response;
  } catch (e) {
    return e;
  }
};
const searchFacilityByPlaceid = async (placeId) => {
  try {
    let response = await API.get("facility/by-name/details/" + placeId);
    return response;
  } catch (e) {
    return e;
  }
};
const addFacility = async (data) => {
  try {
    let response = await API.post("/facility/", data);
    return response;
  } catch (e) {
    return e;
  }
};
const addScheduleToFacility = async (
  practitionerId,
  facilityId,
  availableSchedules
) => {
  if (!availableSchedules.length) {
    return;
  }
  try {
    let response = await API.post(
      "practitioners/" +
      practitionerId +
      "/offices/" +
      facilityId +
      "/schedules/",
      { schedules: availableSchedules }
    );
    return response;
  } catch (e) {
    return e;
  }
};
const addUpdateScheduleToFacility = async (practitionerId, payload) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.post(
        `practitioner-service/practitioners/${practitionerId}/facility/schedule`,
        payload
      );
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};
const deleteFacility = async (practitionerId, facilityId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.delete(
        `practitioner-service/practitioner/${practitionerId}/facility/${facilityId}/schedule`
      );
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};
const updateScheduleToFacility = async (
  practitionerId,
  facilityId,
  data,
  payload
) => {
  try {
    const { schedules } = data;
    const { charges, duration } = payload;
    const deleted = await deleteScheduleFromFacility(
      practitionerId,
      facilityId
    );
    if (deleted.data.status === "SUCCESS") {
      await API.put(
        "practitioners/" + practitionerId + "/facility-affiliation/",
        { facility: facilityId, charges, duration }
      );
      if (schedules.length > 0) {
        let response = await API.post(
          "practitioners/" +
          practitionerId +
          "/offices/" +
          facilityId +
          "/schedules/",
          { schedules }
        );
        return response;
      }

      return deleted;
    } else if (deleted.data.status === "FAILED") {
      await API.post(
        "practitioners/" +
        practitionerId +
        "/offices/" +
        facilityId +
        "/schedules/",
        { schedules }
      );
      let response = await API.put(
        "practitioners/" + practitionerId + "/facility-affiliation/",
        { facility: facilityId, charges, duration }
      );
      return response;
    }
  } catch (e) {
    return e;
  }
};
const getPractitionerFacilities = async (practitionerid) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.get(
        `practitioner-service/practitioner/${practitionerid}/facility/schedule`
      );
      // let response = API_New.get(`practitioner-service/practitioners/facility/get-facility-schedule-by-practitionerid/${practitionerid}`)
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
  // try {
  //     let response = await API.get("practitioners/" + practitionerid + "/offices/facility-schedules/")
  //     return response
  // } catch (e) {
  //     return e
  // }
};
const deleteScheduleFromFacility = async (practitionerId, facilityId) => {
  try {
    let response = await API.delete(
      "practitioners/" +
      practitionerId +
      "/offices/" +
      facilityId +
      "/schedules/"
    );
    return response;
  } catch (e) {
    return e;
  }
};

const getFullUserProfile = async (practitionerId) => {
  try {
    const [
      offices,
      education,
      certifications,
      awards,
      publications,
      affiliations,
      clinicaltrials,
      reviews,
      commitee,
      proMembership,
    ] = await Promise.all([
      await getPractitionerFacilities(practitionerId),
      API.get(`/practitioners/${practitionerId}/education/`),
      API.get(`/practitioners/${practitionerId}/certifications/`),
      API.get(`/practitioners/${practitionerId}/awards/`),
      API.get(`/practitioners/${practitionerId}/publications/`),
      API.get(`/practitioners/${practitionerId}/affiliations/`),
      API.get(`/practitioners/${practitionerId}/clinical-trials/`),
      API.get(`/practitioners/${practitionerId}/reviews/`),
      API.get(`/practitioners/${practitionerId}/committee/`),
      API.get(`/practitioners/${practitionerId}/pro-membership/`),
    ]);
    return {
      offices: offices.data,
      education: education.data,
      certifications: certifications.data,
      awards: awards.data,
      publications: publications.data,
      affiliations: affiliations.data,
      clinicaltrials: clinicaltrials.data,
      reviews: reviews.data,
      commitee: commitee.data,
      proMembership: proMembership.data,
    };
  } catch (e) {
    return e;
  }
};

const getCommittees = (practitionerId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `practitioner-service/practitioners/${practitionerId}/committees`,
        method: "GET",
        responseType: "application/json",
        headers: {
          'Authorization': `Bearer ${secureLocalStorage.getItem('nexogic.token')}`,
        },
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getCommitteeById = (committeeId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/committee/get-committee-by-committeeid/${committeeId}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const addCommittee = (payload, guid) => {
  // console.log(payload)
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `practitioner-service/practitioners/${guid}/committees`,
        method: "POST",
        data: payload,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const updateCommittee = (payload, userGUID, committeeId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `practitioner-service/practitioners/${userGUID}/committees/${committeeId}`,
        data: payload,
        method: "PUT",
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const deleteCommittee = (committeeId, guid) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `practitioner-service/practitioners/${guid}/committees/${committeeId}`,
        method: "DELETE",
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const addProMembership = (pId, instId, payload) => {
  return new Promise((resolve, reject) => {
    const url = `/practitioner-service/practitioners/${pId}/membership/institute/${instId}`;
    try {
      let response = API_New.post(url, payload);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const updateProMembership = (pId, mId, instId, payload) => {
  return new Promise((resolve, reject) => {
    const url = `/practitioner-service/practitioners/${pId}/membership/${mId}/institute/${instId}`;
    try {
      let response = API_New.put(url, payload);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getProMembership = (practitionerId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/membership/get-membership-by-practitionerid/${practitionerId}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getProMembershipById = (membershipId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/membership/get-membership-by-membershipid/${membershipId}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const deleteProMembership = (membershipId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/membership/delete-membership-by-membershipid/${membershipId}`,
        method: "DELETE",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getExperience = (practitionerId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/experience/get-affiliation-by-practitionerid/${practitionerId}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getExperienceById = (affiliationId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/experience/get-by-affiliationid/${affiliationId}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const addExperience = (payload) => {
  return new Promise((resolve, reject) => {
    const url = `/practitioner-service/practitioners/${payload.practitioner}/affiliation/institute/${payload.institutionProfile.id}`;

    try {
      let response = API_New.post(url, payload);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const updateExperience = (payload) => {
  return new Promise((resolve, reject) => {
    const url = `/practitioner-service/practitioners/${payload.practitioner}/experience/${payload.id}/institute/${payload.institutionProfile.id}`;

    try {
      let response = API_New.put(url, payload);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const deleteExperience = (affiliationId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/experience/delete-by-affiliationid/${affiliationId}`,
        method: "DELETE",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getAward = (practitionerId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/awards/get-award-by-practitionerid/${practitionerId}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getAwardById = (awardId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/awards/get-by-awardid/${awardId}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const addAward = (pId, instId, payload) => {
  return new Promise((resolve, reject) => {
    const url = `/practitioner-service/practitioners/${pId}/awards/institute/${instId}`;
    try {
      let response = API_New.post(url, payload);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const updateAward = (pId, aId, instId, payload) => {
  return new Promise((resolve, reject) => {
    const url = `/practitioner-service/practitioners/${pId}/awards/${aId}/institute/${instId}`;
    try {
      let response = API_New.put(url, payload);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const deleteAward = (awardId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/awards/delete-by-awardid/${awardId}`,
        method: "DELETE",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getCertifications = (practitionerId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/certifications/get-certification-by-practitionerid/${practitionerId}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getCertificationById = (certificationId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/certifications/get-by-certificationid/${certificationId}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const addCertification = (instId, payload) => {
  // console.log("payload ", payload);
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `/practitioner-service/practitioners/certifications/add-certification/institute/${instId}`,
        method: "POST",
        responseType: "application/json",
        data: payload,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const updateCertification = (payload) => {
  // console.log("payload ", payload);
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `/practitioner-service/practitioners/certifications/update-certification/institute/${payload.instId}`,
        method: "PUT",
        responseType: "application/json",
        data: payload,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const deleteCertification = (certificationId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/certifications/delete-by-certificationId/${certificationId}`,
        method: "DELETE",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getEducation = (practitionerId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/educations/get-education-by-practitionerid/${practitionerId}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getEducationById = (educationId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/educations/get-by-educationid/${educationId}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const addEducation = (payload) => {
  return new Promise((resolve, reject) => {
    const url = `/practitioner-service/practitioners/${payload.practitioner}/educations/institute/${payload.institutionProfile.id}`;

    try {
      let response = API_New.post(url, payload);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const updateEducation = (payload) => {
  return new Promise((resolve, reject) => {
    const url = `/practitioner-service/practitioners/${payload.practitioner}/educations/${payload.id}/institute/${payload.institutionProfile.id}`;
    try {
      let response = API_New.put(url, payload);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const deleteEducation = (educationId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/educations/delete-by-educationid/${educationId}`,
        method: "DELETE",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getPublication = (practitionerId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/publications/get-publication-by-practitionerid/${practitionerId}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getPublicationById = (publicationId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/publications/get-publication-by-publicationid/${publicationId}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const addPublication = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/publications/add-publication`,
        method: "POST",
        responseType: "application/json",
        data: payload,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const updatePublication = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/publications/update-publication`,
        method: "PUT",
        responseType: "application/json",
        data: payload,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const deletePublication = (publicationId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/practitioners/publications/delete-by-publicationid/${publicationId}`,
        method: "DELETE",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getClinicalTrial = (practitionerId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `/practitioner-service/practitioners/${practitionerId}/trails`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getClinicalTrialById = (trialId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `practitioner-service/practitioners/clinical-trials/get-by-trialid/${trialId}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const addClinicalTrial = (payload, guid) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `/practitioner-service/practitioners/${guid}/trails`,
        method: "POST",
        responseType: "application/json",
        data: payload,
        headers: {
          'Authorization': `Bearer ${secureLocalStorage.getItem('nexogic.token')}`,
        },
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const updateClinicalTrial = (payload, guid, trail_id) => {
  // console.log(payload)
  delete payload.guid;
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `/practitioner-service/practitioners/${guid}/trails/${trail_id}`,
        method: "PUT",
        responseType: "application/json",
        data: payload,
        headers: {
          'Authorization': `Bearer ${secureLocalStorage.getItem('nexogic.token')}`,
        },
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const deleteClinicalTrial = (guid, trialId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `/practitioner-service/practitioners/${guid}/trails/${trialId}`,
        method: "DELETE",
        responseType: "application/json",
        headers: {
          'Authorization': `Bearer ${secureLocalStorage.getItem('nexogic.token')}`,
        },
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getDocuments = (practitionerId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.get(
        `practitioner-service/practitioners/documents/get-document-by-practitionerid/${practitionerId}`
      );
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getPrimarySpecialities = () => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.get(
        `practitioner-service/practitioners/skills/get-primay-specialities`
      );
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getSkills = () => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.get(
        `practitioner-service/practitioners/skills/get-all-skills`
      );
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getSkillsSuggestions = (practitionerId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.get(
        `practitioner-service/practitioners/skills/subspeciality/${practitionerId}`
      );
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const updateSkills = (practitionerId, payload) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.put(`practitioner-service/practitioners/skills/${practitionerId}`, payload);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

export const CommonServices = {
  searchFacilityByName,
  searchFacilityByPlaceid,
  addFacility,
  addScheduleToFacility,
  updateScheduleToFacility,
  getPractitionerFacilities,
  deleteScheduleFromFacility,
  addUpdateScheduleToFacility,
  deleteFacility,
  getFullUserProfile,
  getCommittees,
  getCommitteeById,
  addCommittee,
  updateCommittee,
  deleteCommittee,
  addProMembership,
  updateProMembership,
  getProMembership,
  getProMembershipById,
  deleteProMembership,
  getExperience,
  getExperienceById,
  addExperience,
  updateExperience,
  deleteExperience,
  getAward,
  getAwardById,
  addAward,
  updateAward,
  deleteAward,
  getCertifications,
  getCertificationById,
  addCertification,
  updateCertification,
  deleteCertification,
  getEducation,
  getEducationById,
  addEducation,
  updateEducation,
  deleteEducation,
  getPublication,
  getPublicationById,
  addPublication,
  updatePublication,
  deletePublication,
  getClinicalTrial,
  getClinicalTrialById,
  addClinicalTrial,
  updateClinicalTrial,
  deleteClinicalTrial,
  getDocuments,
  getPrimarySpecialities,
  getSkills,
  getSkillsSuggestions,
  updateSkills
};
