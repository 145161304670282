import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { setReferStatus } from 'redux-helpers/ActionCreator';

import { isAdmin, isInstitutionAdmin, isPractitioner, isPatient } from "utils/UserHelper";
import { ProfileStatusConstants } from "utils/Constants";

import { FAILTOSAVERECOED } from 'utils/Constants';
import { PractitionerService } from 'utils/PractitionerService';

import PageNotFound from "pages/404";
import Dashboard from "../../Dashboard";
import AppointmentsCalendar from "pages/AppointmentsCalendar";
import Messages from "pages/Messages";
import Jobs from "pages/Jobs";
import Feedbacks from "pages/Feedback/Feedback";
import Settings from "pages/Settings";
import Profile from "pages/Profile";
import FeedHomePage from "pages/FeedHomePage";
import Institutions from "pages/Institutions";
import FeedById from "pages/FeedByID";
import Referals from "pages/Referals";
import JobHomePage from "pages/JobHomePage";
import { isOnlyApprovedAllowPathPractitioner } from "utils/Utils";
import CreateJobs from "pages/FeedHomePage/components/CreateJobs";
import ApplyJobs from "pages/FeedHomePage/components/ApplyJobs";
import JobTracking from "pages/FeedHomePage/components/JobTracking";
import ApplicationDetails from "pages/FeedHomePage/components/JobTracking/Components/ApplicationDetails";
import SecondOpinion from "pages/PatientProfile/SecondOpinion";
import Appointments from "pages/PatientProfile/Appointments";
import Reports from "pages/PatientProfile/Reports";
import Doctors from "pages/PatientProfile/Doctors";
import MedicalCases from "pages/PatientProfile/MedicalCases";
import SecondOpinionForm from "pages/PatientProfile/SecondOpinion/components";
import ProfileDetails from "pages/PatientProfile/ProfileDetails";
import DoctorSingle from "pages/PatientProfile/DoctorSingle";
import Reviews from "pages/PatientProfile/Reviews";
import DemographicInfo from "pages/PatientProfile/DemographicInfo";
import InsuranceSettings from "pages/PatientProfile/InsuranceSettings";
import UserView from "pages/PatientProfile/UserView";
import UserSearchView from "pages/PatientProfile/UserView/UserSearchView";
import UIDashboard from "pages/UIDashboard";
import CreateInstitution from "pages/UIDashboard/CreateInstitution";
import EmailPreview from "pages/PatientProfile/SecondOpinion/components/EmailPreview";
import ConsultlationView from "pages/PatientProfile/SecondOpinion/components/Consultlation/view";
import ConsultlationAdd from "pages/PatientProfile/SecondOpinion/components/Consultlation";
import AppointmentLists from "pages/PatientProfile/Appointments/AppointmentLists";
import AppointmentUpcomming from "pages/PatientProfile/Appointments/AppointmentUpcomming";
import AppointmentDetails from "pages/PatientProfile/Appointments/AppointmentDetails";
import AppointmentAdd from "pages/PatientProfile/Appointments/AppointmentAdd";
import WeekCalender from "pages/PatientProfile/Appointments/Doctor/WeekCalender";
import DayCalender from "pages/PatientProfile/Appointments/Doctor/DayCalender";
import PatientDetails from "pages/Referals/Components/PatientDetails";
import MyReferrals from "pages/Referals/Components/PatientDetails/Components/MyReferrals";
import AdsOne from "pages/Ads/AdsOne";
import AdsTwo from "pages/Ads/AdsTwo";
import AdsThree from "pages/Ads/AdsThree";
import AdsFour from "pages/Ads/AdsFour";
import AdsFive from "pages/Ads/AdsFive";
import AdsSix from "pages/Ads/AdsSix";
import AdsSeven from "pages/Ads/AdsSeven";
import AdsEight from "pages/Ads/AdsEight";
import AdsNine from "pages/Ads/AdsNine";
import AdsTen from "pages/Ads/AdsTen";
import AdsEleven from "pages/Ads/AdsEleven";
import AdsTwelve from "pages/Ads/AdsTwelve";
import DoctorSearchTop from "pages/Search/DoctorSearchTop";
import DoctorSearchSidebar from "pages/Search/DoctorSearchSidebar";
import AdsTemplates from "pages/Ads/AdsTemplates";
import InstitutionsAdmin from "pages/Institutions/components/InstitutionsAdmin";
import Notifications from "pages/PatientProfile/Notifications";
import NoAppointments from "pages/PatientProfile/NoAppointments";

import ThemeDesign2 from "pages/UIDashboard/ThemeDesign2";
import CustomThemeDesign from "pages/UIDashboard/Components/CustomThemeDesign";
import MySecondOpinionLists from "pages/PatientProfile/SecondOpinion/components/MyOpinionsList";
import MyOpinionDetailPage from "pages/PatientProfile/SecondOpinion/components/MyOpinionDetailPage/index.js";
import ReceivedSecondOpinionLists from "pages/PatientProfile/SecondOpinion/components/ReceivedOpinionLists";
import Invoice from "pages/PatientProfile/Invoice";
import PractitionerProfilesPage from "pages/FeedHomePage/components/PractitionerProfilesPage";

import ReceivedOpinionResponse from "pages/PatientProfile/SecondOpinion/components/ReceivedOpinionResponse";
import OpinionDetailsPage from "pages/PatientProfile/SecondOpinion/components/OpinionDetailsPage";
import OpinionResponsePage from "pages/PatientProfile/SecondOpinion/components/OpinionResponsePage";
import ReceivedOpinionDetailsPage from "pages/PatientProfile/SecondOpinion/components/ReceivedOpinionDetailsPage";
import MyOpinionResponsePage from "pages/PatientProfile/SecondOpinion/components/MyOpinionResponsePage";
import CreateJobList from "common/NewJobScreen/CreateJobList";
import JobPreview from "pages/Jobs/Components/CreateJob/components/NewJobUi/NewForms/JobPreview";
import AllPractitionersWorkHereList from "pages/Institutions/components/InstitutionsDetails/components/Practitioners/AllPractitionersWorkHereList";
import StyleGuidline from "StyleGuidline";
import AllConnectionsTab from "pages/Connections/components/AllConnections/components/AllConnectionsTab";
import Invitations from "pages/Connections/components/AllConnections/components/Invitations";
import MyPatients from "pages/Connections/components/MyPatients";
import GroupOwned from "pages/Connections/components/Groups/components/GroupOwned";
import GroupFollow from "pages/Connections/components/Groups/components/GroupFollow";
import GroupInvitation from "pages/Connections/components/Groups/components/GroupInvitation";
import GroupView from "pages/Connections/components/Groups/components/GroupView";
import PagesOwned from "pages/Connections/components/Pages/components/PagesOwned";
import pagesFollowed from "pages/Connections/components/Pages/components/pagesFollowed";
import SecondOpinionCreationNew from "pages/PatientProfile/SecondOpinion/components/SecondOpinionCreationNew";
import Pending from "pages/Connections/components/AllConnections/components/Pending";

class Content extends React.PureComponent {
  //fetch recent messages from message component

  state = {
    message: "",
    channel: "",
    timeToken: "",
    activeFlag: false,
    redirectToNotFound: false,
  };

  getRecentMessage = (message, channel, timeToken) => {
    if (message && message.length > 0) {
      this.setState({
        ...this.state,
        message: message,
        channel: channel,
        timeToken: timeToken,
      });
    }
  };

  async getPractitionerReferFlag(guid) {
    try {
      const response = await PractitionerService.getPreferences(guid);
      const { status, data } = response;

      if (status === 200) {
        this.props.setReferStatus(data?.openForReferral);
      } else {
        console.log(FAILTOSAVERECOED);
      }
    } catch (error) {
      console.error("Error fetching preferences:", error);
    }
  }


  componentDidMount() {
    // This will be called after the component is mounted
    if (isPractitioner() && this.props.currentUser?.guid) {
      this.getPractitionerReferFlag(this.props.currentUser?.guid);
    }

    setTimeout(() => {
      this.setState({ redirectToNotFound: true });
    }, 2000);
  }

  render() {
    // console.log("--------------------------111111---22---------------",this.props);
    const isAdminUser = isAdmin();
    const isInstitutionAdminUser = isInstitutionAdmin();
    const isPractitionerUser = isPractitioner();
    const isPatientUser = isPatient();
    let { currentUser, referalStatusFlag, referUpdateFlag } = this.props;
    const { redirectToNotFound } = this.state;

    // if (!referUpdateFlag && !isAdminUser && !isInstitutionAdminUser && !isPatientUser) {
    //   return '';
    // }
    // console.log("Content.js Page--------------------------------------------",isAdminUser,"--",isInstitutionAdminUser,"--",isPractitionerUser,"--",isPatientUser);
    // console.log("Content.js Page---------------currentUser, referalStatusFlag, referUpdateFlag-----------------------------",currentUser,"--",referalStatusFlag,"--",referUpdateFlag);
    return (
      <>
        <Switch>

          <Route path={"/style-guidlines"} component={StyleGuidline} />


          <Route path={"/new/create-jobs"} component={CreateJobList} />
          <Route path={"/new/update-jobs/:jobId"} component={CreateJobList} />
          <Route path={"/new/job-preview/:jobId"} component={JobPreview} />
          {
            <Route
              path={isOnlyApprovedAllowPathPractitioner(
                ((isPractitionerUser && currentUser.status === ProfileStatusConstants.APPROVED) || isInstitutionAdminUser || isAdminUser || isPatientUser),
                "/home"
              )}
            >
              {isPatientUser ? <PatientDetails /> :
                <FeedHomePage
                  message={this.state.message}
                  channel={this.state.channel}
                  timeToken={this.state.timeToken}
                />
              }
            </Route>
          }
          {
            <Route
              path="/home"
              render={() => (
                (isPractitionerUser && currentUser.status !== ProfileStatusConstants.APPROVED) ? (
                  <Redirect to={`/profile/${currentUser?.profileId}/`} />
                ) : (
                  isPatientUser ? <PatientDetails /> :
                    <FeedHomePage
                      message={this.state.message}
                      channel={this.state.channel}
                      timeToken={this.state.timeToken}
                    />
                )
              )}
            />
          }
          <Route path={"/ui-dashboard"}>
            {isAdminUser ? <UIDashboard /> : ""}
          </Route>
          <Route path={"/create-webpage"}>
            {isAdminUser || isInstitutionAdminUser ? <CreateInstitution /> : ""}
          </Route>
          <Route path={"/jobs/:jobId"}>
            <JobHomePage />
          </Route>
          <Route path={"/apply-jobs"}>
            <ApplyJobs />
          </Route>
          <Route path={"/job-tracking/:jobId"}>
            <JobTracking />
          </Route>
          <Route path={"/application-details/:jobApplicationId"}>
            <ApplicationDetails />
          </Route>
          {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
            <Route path={"/create-jobs"} component={CreateJobs} />
          )}
          {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
            <Route path="/dashboard" component={Dashboard} />
          )}

          {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
            !isAdminUser && (
              <Route
                path="/practitioner-service/practitioners/:pid/feeds/:id"
                component={FeedById}
              />
            )}

          {currentUser.status === ProfileStatusConstants.APPROVED &&
            !isAdminUser && (
              <Route path="/appointments" component={AppointmentsCalendar} />
            )}

          {!isPatientUser &&
            <Route path="/profiles" component={PractitionerProfilesPage} />
          }

          {(currentUser.status === ProfileStatusConstants.APPROVED ||
            !isAdminUser || isInstitutionAdminUser || isPractitionerUser) && (
              <Route path="/messages">
                <Messages getRecentMessage={this.getRecentMessage} />
              </Route>
            )}


          <Route path="/networks/connections/active" component={AllConnectionsTab} />
          <Route path="/networks/connections/pending" component={Pending} />
          <Route path="/networks/connections/invitations" component={Invitations} />
          <Route path="/networks/my-patients" component={MyPatients} />

          <Route path="/networks/groups/owned" component={GroupOwned} />
          <Route path="/networks/groups/followed" component={GroupFollow} />
          <Route path="/networks/groups/invitations" component={GroupInvitation} />

          <Route path="/connections/groups/:id" component={GroupView} />

          <Route path="/networks/pages/owned" component={PagesOwned} />
          <Route path="/networks/pages/followed" component={pagesFollowed} />

          {(currentUser.status === ProfileStatusConstants.APPROVED || isAdminUser || isInstitutionAdminUser) ?
            <Route path="/jobsboard" component={Jobs} /> : ''}

          {((isPractitionerUser && currentUser.status === ProfileStatusConstants.APPROVED && referalStatusFlag) || isPatientUser) &&
            <Route path="/referrals" component={Referals} />
          }

          {isPatientUser && <Route exact path="/my-referrals" component={MyReferrals} />}

          {((isPractitionerUser && currentUser.status === ProfileStatusConstants.APPROVED) || isAdminUser) &&
            process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
              <Route path="/feedback" component={Feedbacks} />
            )}

          {/* <Route path="/admins" component={Admin}/> */}
          {!isAdminUser && <Route path="/settings" component={Settings} />}

          <Route exact path="/profile/:profileId/" component={Profile} />
          <Route exact path="/institution/:institutionID" component={Institutions} />
          <Route exact path="/institution/:institutionID/view-all-practitioners" component={AllPractitionersWorkHereList} />

          {/* {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
            <Route
              exact
              path="/institutions/:institutionID/"
              component={Institutions}
            />
          )} */}

          {/* MICROSITE MODULE ROUTES */}
          {/* {isAdminUser &&  <Route exact path="/microsite/create-template/:id" component={ThemeDesign} />} */}
          {isAdminUser && <Route exact path="/microsite/create-template/:id" component={ThemeDesign2} />}
          {isAdminUser || isInstitutionAdminUser ? <Route exact path="/microsite/custom-theme" component={CustomThemeDesign} /> : ''}
          {/* {isAdminUser &&  <Route exact path="/microsite/create-template2/:id" component={ThemeDesign2} />} */}

          {/* PATIENT MODULE ROUTES */}
          {isPatientUser && <Route exact path="/u/appointments" component={Appointments} />}
          {isPatientUser && <Route exact path="/u/reports" component={Reports} />}
          {isPatientUser && <Route exact path="/u/doctors" component={Doctors} />}
          {isPatientUser && <Route exact path="/u/doctor" component={DoctorSingle} />}
          {isPatientUser && <Route exact path="/u/doctor/reviews" component={Reviews} />}
          {isPatientUser && <Route exact path="/u/medicalcases" component={SecondOpinion} />}
          {isPatientUser && <Route exact path="/u/medicalcases" component={MedicalCases} />}
          {isPatientUser && <Route exact path="/u/profile/:firstName-:id" component={ProfileDetails} />}
          {isPatientUser && <Route exact path="/u/profile/:firstName-:id/demographic-info" component={DemographicInfo} />}
          {isPatientUser && <Route exact path="/u/profile/:firstName-:id/insurance" component={InsuranceSettings} />}

          {isPatientUser && <Route exact path="/u/medicalcases/edit-my-second-opinion/:opinionId" component={SecondOpinionForm} />}

          {isPatientUser && <Route exact path="/u/user-view" component={UserView} />}
          {isPatientUser && <Route exact path="/u/user-search-view" component={UserSearchView} />}
          {isPatientUser && <Route exact path="/u/medical-records" component={UserSearchView} />}

          {isPatientUser && <Route exact path="/u/appointments" component={Appointments} />}
          {isPatientUser && <Route exact path="/u/no-appointments" component={NoAppointments} />}
          {isPatientUser && <Route exact path="/u/reports" component={Reports} />}
          {isPatientUser && <Route exact path="/u/doctors" component={Doctors} />}
          {isPatientUser && <Route exact path="/u/doctor" component={DoctorSingle} />}
          {isPatientUser && <Route exact path="/u/doctor/reviews" component={Reviews} />}
          {isPatientUser && <Route exact path="/u/medicalcases" component={SecondOpinion} />}
          {isPatientUser && <Route exact path="/u/medicalcases" component={MedicalCases} />}
          {isPatientUser && <Route exact path="/u/profile/:firstName-:id" component={ProfileDetails} />}
          {isPatientUser && <Route exact path="/u/profile/:firstName-:id/demographic-info" component={DemographicInfo} />}
          {isPatientUser && <Route exact path="/u/profile/:firstName-:id/insurance" component={InsuranceSettings} />}
          {isPatientUser && <Route exact path="/u/profile/:firstName-:id/notification" component={Notifications} />}
          {(isPatientUser || isPractitionerUser) && <Route exact path="/u/medicalcases/create-new-second-opinion" component={SecondOpinionForm} />}
          {isPatientUser && <Route exact path="/u/medicalcases/email-preview" component={EmailPreview} />}
          {isPatientUser && <Route exact path="/u/user-view" component={UserView} />}
          {isPatientUser && <Route exact path="/u/user-search-view" component={UserSearchView} />}
          {isPatientUser && <Route exact path="/u/medical-records" component={UserSearchView} />}
          <Route exact path="/u/invoice" component={Invoice} />

          {/* second opinion routes */}
          <Route exact path="/u/medicalcases/opinions/:opinionId" component={OpinionDetailsPage} />
          <Route exact path="/u/medicalcases/opinions/:opinionId/response" component={OpinionResponsePage} />

          <Route exact path="/u/medicalcases/received-opinions" component={ReceivedSecondOpinionLists} />
          <Route exact path="/u/medicalcases/received-opinions/:opinionId/response" component={ReceivedOpinionResponse} />
          { /* second opinion html route  */}
          {/* <Route exact path="/u/medicalcases/second-opinions/html" component={SOHtml} /> */}
          <Route exact path="/u/medicalcases/second-opinion/create" component={SecondOpinionCreationNew} />
          <Route exact path="/u/medicalcases/second-opinion/update/:opinionId" component={SecondOpinionCreationNew} />
          {/* <Route exact path="/u/medicalcases/sent-opinions" component={SentSecondOpinionLists} />
          <Route exact path="/u/medicalcases/sent-opinions/:opinionId/response" component={OpinionResponse} /> */}

          <Route exact path="/u/medicalcases/received-opinions/:opinionId" component={ReceivedOpinionDetailsPage} />
          {/* <Route exact path="/u/medicalcases/sent-opinions/:opinionId" component={ViewDetails} /> */}

          <Route exact path="/u/medicalcases/my-opinions" component={MySecondOpinionLists} />
          <Route exact path="/u/medicalcases/my-opinions/:opinionId" component={MyOpinionDetailPage} />
          <Route exact path="/u/medicalcases/my-opinions/:opinionId/response" component={MyOpinionResponsePage} />

          <Route exact path="/u/medicalcases/details/consultlation/add" component={ConsultlationAdd} />
          <Route exact path="/u/medicalcases/details/consultlation/" component={ConsultlationView} />

          {/** appointment routes */}
          <Route exact path="/u/appointments/list" component={AppointmentLists} />
          <Route exact path="/u/appointments/upcoming" component={AppointmentUpcomming} />
          <Route exact path="/u/appointments/details" component={AppointmentDetails} />
          <Route exact path="/u/appointments/add" component={AppointmentAdd} />
          <Route exact path="/u/appointments/doctor/week-calender" component={WeekCalender} />
          <Route exact path="/u/appointments/doctor/day-calender" component={DayCalender} />

          {/** Profile route */}
          {<Route exact path="/patient-profile/:patientId" component={PatientDetails} />}

          {/* Ads */}
          <Route exact path="/u/ads-templates" component={AdsTemplates} />
          <Route exact path="/u/ads-one" component={AdsOne} />
          <Route exact path="/u/ads-two" component={AdsTwo} />
          <Route exact path="/u/ads-three" component={AdsThree} />
          <Route exact path="/u/ads-four" component={AdsFour} />
          <Route exact path="/u/ads-five" component={AdsFive} />
          <Route exact path="/u/ads-six" component={AdsSix} />
          <Route exact path="/u/ads-seven" component={AdsSeven} />
          <Route exact path="/u/ads-eight" component={AdsEight} />
          <Route exact path="/u/ads-nine" component={AdsNine} />
          <Route exact path="/u/ads-ten" component={AdsTen} />
          <Route exact path="/u/ads-eleven" component={AdsEleven} />
          <Route exact path="/u/ads-twelve" component={AdsTwelve} />

          {/* search */}
          <Route exact path="/u/doctor-search" component={DoctorSearchTop} />
          <Route exact path="/u/doctor-search-sidebar" component={DoctorSearchSidebar} />

          {/* institution admin page  */}
          {(isAdminUser || isInstitutionAdminUser) &&
            <Route exact path="/u/institution/:institutionID/admin" component={InstitutionsAdmin} />
          }
          {redirectToNotFound && <Route render={() => <PageNotFound />} />}
        </Switch>
      </>
    );
  }
}

const mapDispatchToProps = {
  setReferStatus, // Map the setFlag action creator to props
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  referalStatusFlag: state.PreferenceFlags?.referalStatusFlag,
  referUpdateFlag: state.PreferenceFlags?.referUpdateFlag
});
export default connect(mapStateToProps, mapDispatchToProps)(Content);
