import FormikDatePicker from "components/forms/formikFields/FormikDatePicker";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { Field, FieldArray, Form, Formik } from "formik";
import moment from "moment";
import HospitalTypeaheadFieldNew from "pages/Onboarding/components/FormComponents/HospitalTypeaheadFieldNew";
import NewHospitalSection from "pages/Onboarding/components/FormComponents/NewHospitalSection";
import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Button, Col, FormFeedback, FormGroup, Label, Row } from "reactstrap";
import { PractitionerService } from "utils/PractitionerService";
import { getUser } from "utils/UserHelper";
import { getAddressName, getLocationName } from "utils/Utils";
import * as Yup from "yup";

const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{5})(( x| ext)\d{1,5}){0,1}$/;

const validationSchema = () =>
  Yup.object().shape({
    hospitalClinicFormData: Yup.array().of(
      Yup.object().shape({
        hospitalDetail: Yup.lazy((value) => {
          if (value && value.onSelectNewHospital === 0) {
            return Yup.object().test(
              "",
              "This field is required. You must select from options or add new",
              () => false
            );
          } else if (value && value.onSelectNewHospital === 1) {
            return Yup.object();
          } else if (value && value.onSelectNewHospital === 2) {
            return Yup.object();
          } else {
            return Yup.mixed().test(
              "",
              "This field is required. You must select from options or add new",
              (value) => value === {}
            );
          }
        }),
        category: Yup.string().when("hospitalDetail.onSelectNewHospital", {
          is: 2,
          then: Yup.string().required("This field is required"),
        }),
        city: Yup.array().when("hospitalDetail.onSelectNewHospital", {
          is: 2,
          then: Yup.array()
            .min(1, "This field is required")
            .required("This field is required"),
        }),
        state: Yup.array().when("hospitalDetail.onSelectNewHospital", {
          is: 2,
          then: Yup.array()
            .min(1, "This field is required")
            .required("This field is required"),
        }),
        country: Yup.array().when("hospitalDetail.onSelectNewHospital", {
          is: 2,
          then: Yup.array()
            .min(1, "This field is required")
            .required("This field is required"),
        }),
        location: Yup.array().when("hospitalDetail.onSelectNewHospital", {
          is: 2,
          then: Yup.array()
            .min(1, "This field is required")
            .required("This field is required"),
        }),
        mainItemName: Yup.string().when("hospitalDetail.onSelectNewHospital", {
          is: 2,
          then: Yup.string().required("This field is required"),
        }),
        phone: Yup.string().when(
          "institutionDetail.onSelectNewMedicalCollege",
          {
            is: 2,
            then: Yup.string().when("phone", {
              is: (val) => val !== "",
              then: Yup.string().matches(
                phoneRegExp,
                "Please enter valid Phone Number"
              ),
              otherwise: Yup.string(),
            }),
          }
        ),
        postalCode: Yup.string().when("hospitalDetail.onSelectNewHospital", {
          is: 2,
          then: Yup.string().required("This field is required"),
        }),
        addressLine1: Yup.string().when("hospitalDetail.onSelectNewHospital", {
          is: 2,
          then: Yup.string().required("This field is required"),
        }),
        websiteAddress: Yup.string().when(
          "hospitalDetail.onSelectNewHospital",
          {
            is: 2,
            then: Yup.string().when("websiteAddress", {
              is: (val) => val !== "",
              then: Yup.string().url("Please enter a valid URL"),
              otherwise: Yup.string(),
            }),
          }
        ),
        currency: Yup.string()
          .required("This field is required")
          .default("INR"),
        charge: Yup.string().required("This field is required"),
        duration: Yup.string().required("This field is required"),
        schedulesHospital: Yup.array()
          .of(
            Yup.object().shape({
              startTime: Yup.string().required("Required!"),
              endTime: Yup.string()
                .required("Required!")
                .test(
                  "",
                  "End time must be after Start time",
                  function (values) {
                    return this.parent.startTime < values;
                  }
                ),
              day: Yup.string().required("Required!"),
            })
          )
          .min(1, "Atleast one work day is required"),
      })
    ),
  });

const initialValuesObj = {
  hospitalDetail: {
    hospitalName: "",
    guid: "",
    onSelectNewHospital: 0,
  },
  addressType: "BILLING_ADDRESS",
  addressLine1: "",
  addressLine2: "",
  city: [],
  country: [],
  location: [],
  state: [],
  postalCode: "",
  schedulesHospital: [],
  currency: "INR",
  charge: "",
  duration: "",
  websiteAddress: "",
  mainItemName: "",
  category: "",
  phone: "",
};

const initialValues = {
  hospitalClinicFormData: [{ ...initialValuesObj }],
};

const RenderAffiliationFormik = (props) => {
  const { onNext, onBack, setAffiliationDet } = props;

  const skipExit = (exitFlag) => {
    if(exitFlag==='skip'){
      // formRef.current.handleSubmit();
        props.skip();      
    }
  };

  const onSubmit = (values) => {
    const userGUID = getUser().guid;
    const formData = values.hospitalClinicFormData.map((node) => {
      return {
        charge: Number(node.charge),
        currency: node.currency,
        duration: Number(node.duration.replace(" min", "")),
        institutionDetail: {
          ...(!node.hospitalDetail.guid && {
            address: {
              addressLine1: node?.addressLine1 ?? "",
              addressLine2: node?.addressLine2 ?? "",
              addressType: node?.addressType ?? "BILLING_ADDRESS",
              city: getAddressName(node?.city),
              state: getAddressName(node?.state),
              country: getAddressName(node?.country),
              location: getLocationName(node?.location),
              postalCode: node?.postalCode ?? "",
            },
          }),
          ...(node.hospitalDetail.guid && {
            guid: node.hospitalDetail.guid,
          }),
          name: node.mainItemName,
          phone: node?.phone ?? "",
          websiteUrl: node?.websiteAddress ?? "",
          ...(node.category && {
            type: node.category,
          }),
        },
        schedules: node?.schedulesHospital.map((item) => {
          return {
            dayOfWeek: item?.day,
            endTime: moment(item?.endTime).format("HH:mm"),
            startTime: moment(item?.startTime).format("HH:mm"),
          };
        }),
      };
    });
    PractitionerService.registerHospitalAndClinicDetails(
      formData,
      userGUID,
      true
    )
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setAffiliationDet(response.data);
          onNext();
        }
      })
      .catch((error) => {
        console.log(
          "PractitionerService.registerHospitalAndClinicDetails error",
          error
        );
        props.alert.error(error.message);
      });
  };

  return (
    <Row className="render-Affiliation-form">
      <Col lg={12}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
          children={(props) => {
            return (
              <Form className="hospital-affiliation-form">
                <FieldArray
                  name="hospitalClinicFormData"
                  render={(arrayHelpers) => (
                    <>
                      {props.values.hospitalClinicFormData.map(
                        (item, index) => (
                          <div key={index}>
                            {index !== 0 && (
                              <div className="horizontal-line my-3"></div>
                            )}
                            <RenderForm {...props} index={index} />
                          </div>
                        )
                      )}
                      <Row className="mt-0 add-more-affiliation">
                        <Col lg={6}>
                          <Button
                          id="add_more_affiliations"
                            onClick={() => {
                              if (props.dirty && props.isValid) {
                                arrayHelpers.push({ ...initialValuesObj });
                              } else {
                                arrayHelpers.form.validateForm();
                                arrayHelpers.form.setTouched({
                                  hospitalClinicFormData:
                                    arrayHelpers.form.values.hospitalClinicFormData.map(
                                      () => {
                                        return {
                                          hospitalDetail: true,
                                          mainItemName: true,
                                          category: true,
                                          addressLine1: true,
                                          addressLine2: true,
                                          country: true,
                                          state: true,
                                          city: true,
                                          postalCode: true,
                                          location: true,
                                          websiteAddress: true,
                                          currency: true,
                                          charge: true,
                                          duration: true,
                                          schedulesHospital: true,
                                        };
                                      }
                                    ),
                                });
                              }
                            }}
                            className="d-flex flex-row align-items-center nexogic_primary_button_outline"
                            type="button"
                          > + Add more affiliations
                            
                          </Button>
                        </Col>
                        {props.values.hospitalClinicFormData.length > 1 && (
                          <Col lg={6}>
                            <div
                              onClick={() =>
                                arrayHelpers.remove(
                                  props.values.hospitalClinicFormData.length - 1
                                )
                              }
                              className="d-flex flex-row justify-content-end align-items-start"
                            >
                              <p className="my-2 card-title text-danger nexogic-danger-text">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
                              class="bi bi-trash mx-2" viewBox="0 0 16 16"><path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
                              <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"></path></svg>
                                Remove this work place
                              </p>
                            </div>
                          </Col>
                        )}
                      </Row>
                      <Row className="mt-4">
                        {/* <Col xs={6}>
                          <Button
                            className="d-block ml-auto back-btn"
                            type="button"
                            onClick={onBack}
                          >
                            Back
                          </Button>
                        </Col> */}
                        
                        <Col lg={12}>
                          <div className="actions d-flex flex-wrap">
                          <Button
                            className="nexogic_primary_button_outline ra_btn_skip"
                            type="button"
                            outline
                            onClick={()=>skipExit('skip')}
                          >
                            Skip
                          </Button>
                          <Button
                            className=" nexogic_primary_button ra_btn_next"
                            type="submit"
                          >
                            Next
                          </Button>
                          </div>
                        </Col>
                        
                      </Row>
                    </>
                  )}
                />
              </Form>
            );
          }}
        />
      </Col>
    </Row>
  );
};

const RenderForm = (props) => {
  const [nestedModal, setNestedModal] = useState(false);
  const { values, setFieldValue, index } = props;

  const onNewInstitute = () => {
    toggleNested();
  };

  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setFieldValue(
      `hospitalClinicFormData[${index}].hospitalDetail.onSelectNewHospital`,
      2
    );
    setFieldValue(
      `hospitalClinicFormData[${index}].hospitalDetail.hospitalName`,
      "Other"
    );
  };

  return (
    <>
      <Row className="mt-4">
        <Col lg={12}>
          <Field
            name={`hospitalClinicFormData[${index}].hospitalDetail`}
            id={`hospitalClinicFormData[${index}].hospitalDetail`}
            autoComplete={`hospitalClinicFormData[${index}].hospitalDetail`}
            type="text"
            label="Hospital/Clinic Name"
            component={HospitalTypeaheadFieldNew}
            onNewInstituteReq={(index) => onNewInstitute(index)}
            placeholder="Enter hospital / clinic name"
            parentIndex={index}
          />
        </Col>
      </Row>
      {values?.hospitalClinicFormData.length > 0 &&
        values?.hospitalClinicFormData[index]?.hospitalDetail
          ?.onSelectNewHospital === 2 && (
          <NewHospitalSection {...props} index={index} />
        )}
      <Row>
        <Col lg={4}>
          <Field
            id={`hospitalClinicFormData[${index}].currency`}
            name={`hospitalClinicFormData[${index}].currency`}
            autoComplete={`hospitalClinicFormData[${index}].currency`}
            type="text"
            label="Currency"
            component={FormikSelectField}
            inputprops={{
              name: `currency`,
              options: ["INR", "USD", "EUR"],
            }}
          />
        </Col>
        <Col lg={4}>
          <Field
            id={`hospitalClinicFormData[${index}].charge`}
            name={`hospitalClinicFormData[${index}].charge`}
            autoComplete={`hospitalClinicFormData[${index}].charge`}
            type="text"
            label="Charge"
            component={FormikInputField}
            placeholder="Enter"
          />
        </Col>
        <Col lg={4}>
          <Field
            id={`hospitalClinicFormData[${index}].duration`}
            name={`hospitalClinicFormData[${index}].duration`}
            autoComplete={`hospitalClinicFormData[${index}].duration`}
            type="text"
            label="Duration"
            component={FormikSelectField}
            inputprops={{
              name: `duration`,
              options: [
                "10 min",
                "15 min",
                "20 min",
                "30 min",
                "45 min",
                "60 min",
              ],
              defaultOption: "Select",
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex flex-row">
            <Label className="d-flex">
              Work Days 
            </Label>
            {!!props?.touched?.hospitalClinicFormData &&
              !!props?.touched?.hospitalClinicFormData[index] &&
              !!props?.touched?.hospitalClinicFormData[index]
                .schedulesHospital &&
              !!props?.errors?.hospitalClinicFormData &&
              !!props?.errors?.hospitalClinicFormData[index] &&
              !!props?.errors?.hospitalClinicFormData[index]
                .schedulesHospital &&
              typeof props.errors.hospitalClinicFormData[index]
                .schedulesHospital === "string" && (
                <FormFeedback
                  className="d-flex line-height-error"
                  invalid={
                    !!props.errors.hospitalClinicFormData[index]
                      .schedulesHospital
                  }
                >
                  {props.errors.hospitalClinicFormData[index].schedulesHospital}
                </FormFeedback>
              )}
          </div>
          <div className="my-2">
            <FieldArray
              name={`hospitalClinicFormData[${index}].schedulesHospital`}
              render={(arrayHelpersInner) => {
                return (
                  <>
                    {props.values.hospitalClinicFormData[
                      index
                    ].schedulesHospital.map((schedule, index) => {
                      const { startTime, endTime, day } = schedule;
                      const convertedStartTime = moment(
                        moment(moment.utc(startTime, "HH:mm:ss")).toDate()
                      ).format("hh:mm a");
                      const convertedEndTime = moment(
                        moment(moment.utc(endTime, "HH:mm:ss")).toDate()
                      ).format("hh:mm a");
                      return (
                        <div key={index} className="nexogic-day-block">
                          <Row className="py-1">
                            <Col sm={4} xs={12}>
                              <h3 className="card-title">Schedule</h3>
                              <div className="card-text">{`${convertedStartTime} - ${convertedEndTime}`}</div>
                            </Col>
                            <Col sm={7} xs={10} className="">
                              <h3 className="card-title">{day}</h3>
                            </Col>
                            <Col sm={1} xs={2} className="actions-btn">
                              <Button
                                color="danger"
                                className="nexogic-delete-btn mt-2"
                                onClick={() => arrayHelpersInner.remove(index)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-trash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                  />
                                </svg>
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                    <Row>
                      <Col>
                        <AddWorkHoursFields
                          addToSchedules={arrayHelpersInner}
                        />
                      </Col>
                    </Row>
                  </>
                );
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
export default RenderAffiliationFormik;

const addHoursValidationSchema = Yup.object().shape({
  startTime: Yup.string().required("Required!"),
  endTime: Yup.string()
    .required("Required!")
    .test("", "End time must be after Start time", function (values) {
      return this.parent.startTime < values;
    }),
  day: Yup.string().required("Required!"),
});

const AddWorkHoursFields = ({ addToSchedules }) => {
  return (
    <Formik
      initialValues={{ day: "", startTime: "", endTime: "" }}
      validationSchema={addHoursValidationSchema}
      children={(props) => {
        const handleAddHour = () => {
          const { values } = props;
          const { startTime, endTime, day } = values;
          props.setFieldTouched("day", true, true);
          props.setFieldTouched("startTime", true, true);
          props.setFieldTouched("endTime", true, true);
          props.validateForm();
          if (props.dirty && props.isValid) {
            addToSchedules.push({
              startTime,
              endTime,
              day,
            });
            props.resetForm();
          }
        };
        return (
          <Row className="work-slots">
            <Col xs={12} lg={3} md={4}>
              <Field
                id="day"
                name="day"
                type="text"
                component={FormikSelectField}
                inputprops={{
                  options: [
                    {
                      label: "MON",
                      value: "MONDAY",
                    },
                    {
                      label: "TUE",
                      value: "TUESDAY",
                    },
                    {
                      label: "WED",
                      value: "WEDNESDAY",
                    },
                    {
                      label: "THR",
                      value: "THURSDAY",
                    },
                    {
                      label: "FRI",
                      value: "FRIDAY",
                    },
                    {
                      label: "SAT",
                      value: "SATURDAY",
                    },
                    {
                      label: "SUN",
                      value: "SUNDAY",
                    },
                  ],
                  defaultOption: "Select",
                  keys: {
                    id: "value",
                    label: "label",
                  },
                  onchange,
                }}
              />
            </Col>
            <Col xs={6} lg={3} md={4}>
              <Field
                id="rf_startTime"
                name="startTime"
                type="text"
                placeholder="Start Time"
                component={FormikDatePicker}
                timeFormat={"hh:mm aa"}
                inputprops={{
                  dateFormat: "h:mm aa",
                  showTimeSelect: true,
                  showTimeSelectOnly: true,
                  calendarClassName: "mycustomcal",
                  autoComplete: "off",
                  showTwoColumnMonthYearPicker: true,
                }}
              />
            </Col>
            <Col xs={6} lg={3} md={4}>
              <Field
                id="rf_endTime"
                name="endTime"
                type="text"
                placeholder="End Time"
                component={FormikDatePicker}
                timeFormat={"hh:mm aa"}
                inputprops={{
                  dateFormat: "h:mm aa",
                  showTimeSelect: true,
                  showTimeSelectOnly: true,
                  calendarClassName: "mycustomcal",
                  autoComplete: "off",
                  showTwoColumnMonthYearPicker: true,
                }}
              />
            </Col>
            <Col xs={12} lg={3} md={4}>
              <FormGroup className="flex-grow-1">
                <Button id="rf_add_hours" block className="add-hours-btn nexogic_primary_button " onClick={handleAddHour}>
                  {"Add hours"}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        );
      }}
    />
  );
};
