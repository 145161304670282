import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { setReferStatus } from "redux-helpers/ActionCreator";
import { PractitionerService } from "utils/PractitionerService";

const Preferences = (props) => {
  const dispatch = useDispatch();
  const [realTimeChat, setRealTimeChat] = useState(false)
  const [videoChat, setVideoChat] = useState(false);
  const [secondOpinion, setSecondOpinion] = useState(false);
  const [isRealTimeChatLoading, setIsRealTimeChatLoading] = useState(true);
  const [isAvailableForReview, setIsAvailableForReview] = useState(true);
  const [isAvailableForReferral, setIsAvailableForReferral] = useState(true);


  const [isVideoChatLoading, setIsVideoChatLoading] = useState(true);
  const [isSecondOpinionLoading, setIsSecondOpinionLoading] = useState(true);

  let currentUser = useSelector((state) => state.auth.currentUser);

  const modifyRealTimeChat = (flag = false) => {
    if (realTimeChat != flag)
      updatePreferences(flag, videoChat, secondOpinion);
  };

  const modifyVideoChat = (flag = false) => {
    if (videoChat != flag)
      updatePreferences(realTimeChat, flag, secondOpinion);
  };

  const modifySecondOpinion = (flag = false) => {
    if (secondOpinion != flag)
      updatePreferences(realTimeChat, videoChat, flag);
  };

  const modifyReferral = (flag = false) => {
    if (isAvailableForReferral != flag)
      updatePreferences(realTimeChat, videoChat, secondOpinion, flag);
  };

  const getPreferences = (guid) => {
    PractitionerService.getPreferences(guid)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          setRealTimeChat(data?.availableForRealTimeChat);
          setVideoChat(data?.availableForVideoChat);
          setSecondOpinion(data?.availableForSecondOpinion);
          setIsAvailableForReferral(data?.openForReferral)

          setIsRealTimeChatLoading(false);
          setIsVideoChatLoading(false);
          setIsSecondOpinionLoading(false);
        }
        else {
          console.log("Something went wrong, Please try again later");
          setIsRealTimeChatLoading(false);
          setIsVideoChatLoading(false);
          setIsSecondOpinionLoading(false);
        }
      })
  }

  const updatePreferences = (realTimeChat1, videoChat1, secondOpinion1, isAvailableForReferral1) => {
    let payload = {
      "availableForSecondOpinion": secondOpinion1,
      "availableForVideoChat": videoChat1,
      "availableForRealTimeChat": realTimeChat1,
      "openForReferral": isAvailableForReferral1
    }

    PractitionerService.updatePreferences(currentUser?.guid, payload)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          dispatch(setReferStatus(data?.openForReferral));    
          setRealTimeChat(data?.availableForRealTimeChat);
          setVideoChat(data?.availableForVideoChat);
          setSecondOpinion(data?.availableForSecondOpinion);
          props.alert.success("Preferences updated successfully");
          setIsRealTimeChatLoading(false);
          setIsVideoChatLoading(false);
          setIsSecondOpinionLoading(false);
        }
        else {
          props.alert.error("Something went wrong, Please try again later");
          setIsRealTimeChatLoading(false);
          setIsVideoChatLoading(false);
          setIsSecondOpinionLoading(false);
        }
      })
  }

  useEffect(() => {
    getPreferences(currentUser?.guid)
  }, [currentUser.guid])

  
  return (
    <>
      {props.isTitle &&
        <div className="nexogic-page-title">Preferences</div>
      }
      <Row>
        <Col lg={12}>
          <div className="section-content">
            <Row className="align-items-center">
              <Col>
                <span className="card-text text-muted">
                  Set your preferences, you available for these service or not
                </span>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <ListGroup className="nexogic-form-option-lists">
                  {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                    <ListGroupItem>
                      <h3 className="card-title">Chat
                        <small className="card-text text-muted">Chat with Patients</small>
                      </h3>
                      <div className="action nexogic-swaches-btns">
                        <Button
                          className={
                            "calendar-status-btn enable-btn " +
                            (realTimeChat ? "active" : "")
                          }
                          onClick={() => {
                            setIsRealTimeChatLoading(true);
                            modifyRealTimeChat(true)
                          }
                          }
                          disabled={isRealTimeChatLoading}
                        >
                          Enable
                        </Button>
                        <Button
                          className={
                            "calendar-status-btn disable-btn " +
                            (!realTimeChat ? "active" : "")
                          }
                          onClick={() => {
                            setIsRealTimeChatLoading(true);
                            modifyRealTimeChat(false)
                          }
                          }
                          disabled={isRealTimeChatLoading}
                        >
                          Disable
                        </Button>
                      </div>
                    </ListGroupItem>
                  }
                  {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                    <ListGroupItem>
                      <h3 className="card-title">Video Chat
                        <small className="card-text text-muted">Video Chat with Patients</small>
                      </h3>
                      <div className="action nexogic-swaches-btns">
                        <Button
                          className={
                            "calendar-status-btn enable-btn " +
                            (videoChat ? "active" : "")
                          }
                          onClick={() => {
                            setIsVideoChatLoading(true);
                            modifyVideoChat(true)
                          }
                          }
                          disabled={isVideoChatLoading}
                        >
                          Enable
                        </Button>
                        <Button
                          className={
                            "calendar-status-btn disable-btn " +
                            (!videoChat ? "active" : "")
                          }
                          onClick={() => {
                            setIsVideoChatLoading(true);
                            modifyVideoChat(false)
                          }
                          }
                          disabled={isVideoChatLoading}
                        >
                          Disable
                        </Button>
                      </div>
                    </ListGroupItem>
                  }
                  {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                    <ListGroupItem>
                      <h3 className="card-title">Second Opinion
                        <small className="card-text text-muted"></small>
                      </h3>
                      <div className="action nexogic-swaches-btns">
                        <Button
                          className={
                            "calendar-status-btn enable-btn " +
                            (secondOpinion ? "active" : "")
                          }
                          onClick={() => {
                            setIsSecondOpinionLoading(true);
                            modifySecondOpinion(true)
                          }
                          }
                          disabled={isSecondOpinionLoading}
                        >
                          Enable
                        </Button>
                        <Button
                          className={
                            "calendar-status-btn disable-btn " +
                            (!secondOpinion ? "active" : "")
                          }
                          onClick={() => {
                            setIsSecondOpinionLoading(true);
                            modifySecondOpinion(false)
                          }
                          }
                          disabled={isSecondOpinionLoading}
                        >
                          Disable
                        </Button>
                      </div>
                    </ListGroupItem>
                  }
                  {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                    <ListGroupItem>
                      <h3 className="card-title">Review
                        <small className="card-text text-muted">Lorem Ipsum is simply dummy text of the printing.</small>
                      </h3>
                      <div className="action nexogic-swaches-btns">
                        <Button
                          className={
                            "calendar-status-btn enable-btn " +
                            (isAvailableForReview ? "active" : "")
                          }
                          onClick={() => {
                            setIsAvailableForReview(true);
                          }
                          }
                          disabled={isAvailableForReview}
                        >
                          Enable
                        </Button>
                        <Button
                          className={
                            "calendar-status-btn disable-btn " +
                            (!isAvailableForReview ? "active" : "")
                          }
                          onClick={() => {
                            setIsAvailableForReview(false);
                          }
                          }
                          disabled={!isAvailableForReview}
                        >
                          Disable
                        </Button>
                      </div>
                    </ListGroupItem>
                  }
                  <ListGroupItem>
                    <h3 className="card-title">Referrals
                      <small className="card-text text-muted">Enable your profile to receive referrals and refer your Patients</small>
                    </h3>
                    <div className="action nexogic-swaches-btns">
                      <Button
                        className={
                          "calendar-status-btn enable-btn " +
                          (isAvailableForReferral ? "active" : "")
                        }
                        onClick={() => {
                          setIsAvailableForReferral(true);
                          modifyReferral(true)
                        }
                        }
                        disabled={isAvailableForReferral}
                      >
                        Enable
                      </Button>
                      <Button
                        className={
                          "calendar-status-btn disable-btn " +
                          (!isAvailableForReferral ? "active" : "")
                        }
                        onClick={() => {
                          setIsAvailableForReferral(false);
                          modifyReferral(false)
                        }
                        }
                        disabled={!isAvailableForReferral}
                      >
                        Disable
                      </Button>
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </Col>
            </Row>
            {/*
            <Row className="align-items-center mt-4 ">
              <Col sm={8}>
                <div className="notification-title mb-2">Chat</div>
                <div className="setting-card-content">
                  Chat with Patients
                </div>
              </Col>
              <Col
                sm={4}
                className="d-flex justify-content-lg-end justify-content-center enb-dsb-btn"
              >
                <Button
                  className={
                    "calendar-status-btn enable-btn " +
                    (realTimeChat ? "active" : "")
                  }
                  onClick={() => {
                    setIsRealTimeChatLoading(true);
                    modifyRealTimeChat(true)
                  }
                  }
                  disabled={isRealTimeChatLoading}
                >
                  Enable
                </Button>
                <Button
                  className={
                    "calendar-status-btn disable-btn " +
                    (!realTimeChat ? "active" : "")
                  }
                  onClick={() => {
                    setIsRealTimeChatLoading(true);
                    modifyRealTimeChat(false)
                  }
                  }
                  disabled={isRealTimeChatLoading}
                >
                  Disable
                </Button>
              </Col>
            </Row>
            <Row className="align-items-center mt-4">
              <Col sm={8}>
                <div className="notification-title mb-2">Video Chat</div>
                <div className="setting-card-content">
                  Video Chat with Patients
                </div>
              </Col>
              <Col
                sm={4}
                className="d-flex justify-content-lg-end justify-content-center enb-dsb-btn"
              >
                <Button
                  className={
                    "calendar-status-btn enable-btn " +
                    (videoChat ? "active" : "")
                  }
                  onClick={() => {
                    setIsVideoChatLoading(true);
                    modifyVideoChat(true)
                  }
                  }
                  disabled={isVideoChatLoading}
                >
                  Enable
                </Button>
                <Button
                  className={
                    "calendar-status-btn disable-btn " +
                    (!videoChat ? "active" : "")
                  }
                  onClick={() => {
                    setIsVideoChatLoading(true);
                    modifyVideoChat(false)
                  }
                  }
                  disabled={isVideoChatLoading}
                >
                  Disable
                </Button>
              </Col>
            </Row>
            <Row className="align-items-center mt-4">
              <Col sm={8}>
                <div className="notification-title mb-2">Second Opinion</div>
                <div className="setting-card-content">
                  Second Opinion
                </div>
              </Col>
              <Col
                sm={4}
                className="d-flex justify-content-lg-end justify-content-center enb-dsb-btn"
              >
                <Button
                  className={
                    "calendar-status-btn enable-btn " +
                    (secondOpinion ? "active" : "")
                  }
                  onClick={() => {
                    setIsSecondOpinionLoading(true);
                    modifySecondOpinion(true)
                  }
                  }
                  disabled={isSecondOpinionLoading}
                >
                  Enable
                </Button>
                <Button
                  className={
                    "calendar-status-btn disable-btn " +
                    (!secondOpinion ? "active" : "")
                  }
                  onClick={() => {
                    setIsSecondOpinionLoading(true);
                    modifySecondOpinion(false)
                  }
                  }
                  disabled={isSecondOpinionLoading}
                >
                  Disable
                </Button>
              </Col>
            </Row>
                */}
          </div>
        </Col>
      </Row>
    </>
  );
};

Preferences.defaultProps = {
  isTitle: true,
};

export default withAlert()(Preferences);
