import CommonLoader from 'common/CommonLoader';
import React, { useState, useEffect } from 'react';

const AdsComponent = (props) => {
  const { institution, imgdet } = props;
  const { adspath, name, adslink } = imgdet;
  const [imageSrc, setImageSrc] = useState('');
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    const loadImage = () => {
      setDataLoading(true);
      try {
        const imageUrl = `${process.env.REACT_APP_IMAGE_BASE_URL}/micro_site_${institution?.guid}/${adspath.replace(/\s+/g, '_')}`;
        setImageSrc(imageUrl);
      } catch (error) {
        console.error('Error loading image:', error);
      }
      setDataLoading(false);
    };

    loadImage();
  }, []);

  return (
    <div className='w-100'>
      {dataLoading ?
        <div className="loading-spiner text-center mt-2">
          <CommonLoader style={{ width: "2rem", height: "2rem" }} />
        </div> :
        <>
          {adslink !== '' ?
            <a href={adslink} target="_blank" rel="noopener noreferrer">
              <img src={imageSrc} alt={name} width="100%" />
            </a>
            : <img src={imageSrc} alt={name} width="100%" />
          }
        </>
      }
    </div>
  );
};

export default AdsComponent;
