import API from "./API_New";

const getPractitionerAppts = (practitionerId,dateValue) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        url:  `appointment/api/schedule/practitioner/${practitionerId}`,
        method: "GET",
        responseType: "json",
        params: {dates: dateValue}
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getAvailableSlots = (pId,fId,dateValue) => {
  return new Promise((resolve,reject) => {
    try {
      let response = API({
        url : `/appointment/api/schedule/availability/${pId}`,
        method : "GET",
        responseType : "json",
        params : {facilityId : fId, date : dateValue }
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  })
}

/** {
  "appointmentReference": "string",
  "approvalType": "string",
  "requestedByUser": "string",
  "statusReason": "string"
} */
const updateAppointmentStatus = (slotId, data) => 
  API({
    method: 'POST',
    url: `appointment/api/admin/approval/${slotId}`,
    data
  })

const rescheduleAppointment = (slotId, data) => 
  API({
    method: 'POST',
    url: `appointment/api/booking/reschedule/${slotId}`,
    data
  })

export const appointmentServices = {
    getPractitionerAppts,
    getAvailableSlots,
    updateAppointmentStatus,
    rescheduleAppointment
}








