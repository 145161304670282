import React from 'react'
import './css/v1.scss'
import applo_logo_img from 'assets/images/logo-top-icon.png'
import applo_bg_img from 'assets/images/doctor-png-29750.png'

function AdsEight(props) {
  return (
    <div className='nex-ads-wrapper v8'>
        <div className="nex-ads-floated-v8-graphic" style={{backgroundImage: `url(${props.bg})`}}>
        </div>
        <div className="nex-ads-floated-v8-graphic-back"></div>
        <div className="nex-ads-brand">
            <a href="#"><img src={props.logo_img} alt="" /></a>
        </div>
        <div className="nex-ads-main-contain">
            <div className="nex-ads-content-wrap">
                <h2 className='nex-ads-headings' dangerouslySetInnerHTML={{__html: props.title}}></h2>
                <p className='nex-title'>{props.short_desc}</p>
                <p><a className='btn' href={props.button_url}>{props.button_name}</a></p>
                
            </div>
            <div className="nex-ads-content-bot">
                <div className="nex-ads-phone">
                    <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.55 5.5C15.5267 5.69057 16.4244 6.16826 17.1281 6.87194C17.8317 7.57561 18.3094 8.47326 18.5 9.45M14.55 1.5C16.5793 1.72544 18.4716 2.63417 19.9162 4.07701C21.3609 5.51984 22.272 7.41101 22.5 9.44M21.5 17.42V20.42C21.5011 20.6985 21.4441 20.9742 21.3325 21.2293C21.2209 21.4845 21.0573 21.7136 20.8521 21.9019C20.6468 22.0901 20.4046 22.2335 20.1407 22.3227C19.8769 22.4119 19.5974 22.4451 19.32 22.42C16.2428 22.0856 13.287 21.0341 10.69 19.35C8.27382 17.8147 6.22533 15.7662 4.68999 13.35C2.99997 10.7412 1.94824 7.77099 1.61999 4.68C1.595 4.40347 1.62787 4.12476 1.71649 3.86162C1.80512 3.59849 1.94756 3.35669 2.13476 3.15162C2.32196 2.94655 2.5498 2.78271 2.80379 2.67052C3.05777 2.55833 3.33233 2.50026 3.60999 2.5H6.60999C7.0953 2.49522 7.56579 2.66708 7.93376 2.98353C8.30173 3.29999 8.54207 3.73945 8.60999 4.22C8.73662 5.18007 8.97144 6.12273 9.30999 7.03C9.44454 7.38792 9.47366 7.77691 9.3939 8.15088C9.31415 8.52485 9.12886 8.86811 8.85999 9.14L7.58999 10.41C9.01355 12.9135 11.0864 14.9864 13.59 16.41L14.86 15.14C15.1319 14.8711 15.4751 14.6858 15.8491 14.6061C16.2231 14.5263 16.6121 14.5555 16.97 14.69C17.8773 15.0286 18.8199 15.2634 19.78 15.39C20.2658 15.4585 20.7094 15.7032 21.0265 16.0775C21.3437 16.4518 21.5122 16.9296 21.5 17.42Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

                    </span>
                    <div className="info">
                        Contact For details
                        <strong>{props.phone}</strong>
                    </div>
                </div>
                <div className="nex-ads-website">
                <span className="icon">
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.50002 12.5C9.92947 13.0741 10.4774 13.5492 11.1066 13.8929C11.7358 14.2367 12.4315 14.4411 13.1467 14.4923C13.8618 14.5436 14.5796 14.4404 15.2514 14.1898C15.9231 13.9392 16.5331 13.5471 17.04 13.04L20.04 10.04C20.9508 9.09699 21.4548 7.83398 21.4434 6.52299C21.432 5.21201 20.9062 3.95795 19.9791 3.03091C19.0521 2.10387 17.798 1.57802 16.487 1.56663C15.176 1.55524 13.913 2.05921 12.97 2.97L11.25 4.68M13.5 10.5C13.0706 9.92587 12.5227 9.45082 11.8935 9.10706C11.2643 8.7633 10.5685 8.55888 9.85337 8.50767C9.13822 8.45645 8.42043 8.55963 7.74867 8.81022C7.07691 9.0608 6.4669 9.45293 5.96002 9.96L2.96002 12.96C2.04923 13.903 1.54525 15.166 1.55665 16.477C1.56804 17.788 2.09388 19.0421 3.02092 19.9691C3.94796 20.8961 5.20203 21.422 6.51301 21.4334C7.82399 21.4448 9.08701 20.9408 10.03 20.03L11.74 18.32" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

                </span>
                    <div className="info">
                        Appointment
                        <strong><a href='#'>{props.website}</a></strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}


/* default props */
AdsEight.defaultProps = {
    bg:'https://source.unsplash.com/HSswzUk-CAU',
    logo_img:applo_logo_img,
    logo_url:"#",
    logo_target:"_blank",
    title:"Find Medical <span>Services</span>",
    short_desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    button_name: "Lorem ipsum",
    button_url: "#",
    website:"www.nexogic.com",
    phone:"+0123456789",

  }
  
  export default AdsEight;