import React from "react";
import { Field, Formik } from "formik";
import { Form } from "reactstrap";
import FormikInputField from "components/forms/formikFields/FormikInputField";
const ChooseColorTheme = (props) => {
    const { themeColors, setThemePColors } = props;
    const initialValue = { colortheme: themeColors };
    const handleThemeColor = (event) => {
        const newColorPalet = [event.target.form[0].value, event.target.form[1].value, event.target.form[2].value, event.target.form[3].value, event.target.form[4].value, event.target.form[5].value];
        setThemePColors(newColorPalet);
    }
    const colorPalateTitle = ["Microsite Background Color","Section Background Color","Section Heading Color","Section Header Background Color","Hospital/Clinic Name Color","Microsite Content Color"];
    const onSubmit = (values) => {
        // console.log(" Page Submitted...............", values);
        // history.push(`/microsite/create-template/11`, {
        //     themename: values.theme,
        //     data: ["About", "Services", "Specialities", "Amenities", "Business Hour", "Gallery", "Awards", "Other Branches", "Practitioners"],
        //     themecolors: values.colortheme,
        // });
    }
    return (
        <>
            <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}
                children={(formProps) => {
                    return <Form onChange={handleThemeColor}>
                        <div style={{ display: "flex", height: "35px" }}>
                            {initialValue.colortheme.map((coloritem, index) => {
                                return <span style={{ height: "35px", margin: "0 !important", padding: "0" }} key={index} title={`${colorPalateTitle[index]}`}>
                                    <Field name={`colortheme[${index}]`} id={index} type="color" title={`${colorPalateTitle[index]}`} component={FormikInputField} placeholder="Section Background" className='cthemecolor' />
                                </span>
                            })}
                        </div>
                    </Form>
                }}
            >
            </Formik>
        </>
    );
};
export default ChooseColorTheme;
