import React, { PureComponent } from "react";
import { Container } from "reactstrap";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import withAuth from "hoc/withAuth";
import Content from "./components/Content";
import { createBrowserHistory } from "history";
import { isAdmin, isInstitutionAdmin, isPatient, isPractitioner } from "utils/UserHelper";
import NewHeader from "common/Header/NewHeader";
import NewFooter from "common/Footer/NewFooter";
import OutsideClickHandler from 'react-outside-click-handler';
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Dashboard from "../../../src/assets/images/svgs/Dashboard.svg";
import New from "../../../src/assets/images/svgs/new.svg";
import Appointment from "../../../src/assets/images/svgs/Appointment.svg";
import network from "../../../src/assets/images/svgs/network.svg";
import Announcement from "../../../src/assets/images/svgs/Announcement.svg";
import Rocket_launch from "../../../src/assets/images/svgs/Rocket_launch.svg";
import People_alt from "../../../src/assets/images/svgs/People_alt.svg";
import thumbup from "../../../src/assets/images/svgs/thumbup.svg";
import Home from "../../../src/assets/images/svgs/Home.svg";
import Setting from "../../../src/assets/images/svgs/Setting.svg";
import Checked_bag from "../../../src/assets/images/svgs/Checked_bag.svg";
import menu_more from "../../../src/assets/images/svgs/menu-more.svg";
import ToolTip from "common/ToolTip";
import "./styles.css"

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      isSideExpand: false,
      isExpandAnimated: false,
      isjobSubmenuActive: false,
      isNetworkSubmenuActive: false,
      isReferralsSubmenuActive: false,
      activeMenu: '',
      isNewAdminView: !new URLSearchParams(window.location.search).has('old-ui-view'),

      tooltipVisibility: {}
    };
  }

  handleToggle = () => {
    this.setState((prevState) => ({ ...prevState, isActive: !prevState.isActive }));
  };

  menuExpandHandle = () => {
    this.setState((prevState) => ({ ...prevState, isSideExpand: !prevState.isSideExpand }));
    setTimeout(() => {
      this.setState((prevState) => ({ ...prevState, isExpandAnimated: !prevState.isExpandAnimated }));
    }, 20);
  };

  jobSubmenuHandle = () => {
    this.setState((prevState) => ({ ...prevState, isjobSubmenuActive: !prevState.isjobSubmenuActive }));
  };

  networkSubmenuHandle = () => {
    this.setState((prevState) => ({ ...prevState, isNetworkSubmenuActive: !prevState.isNetworkSubmenuActive }));
  };
  referralsSubmenuHandle = () => {
    this.setState((prevState) => ({ ...prevState, isReferralsSubmenuActive: !prevState.isReferralsSubmenuActive }));
  };

  submenuHandle = (val) => {
    this.setState((prevState) => ({ ...prevState, activeMenu: val }));
  };


  outsideSubmenuHandle = (getActiveMenu = null) => {
    if (getActiveMenu) {
      this.submenuHandle(getActiveMenu);
    } else {
      this.submenuHandle('');
    }

    if (this.state.isjobSubmenuActive) {
      this.jobSubmenuHandle();
    }

    if (this.state.isNetworkSubmenuActive) {
      this.networkSubmenuHandle();
    }
    if (this.state.isReferralsSubmenuActive) {
      this.referralsSubmenuHandle();
    }
  };

  closeJobMenuHandelar = () => {
    setTimeout(() => {
      this.setState((prevState) => ({ ...prevState, isjobSubmenuActive: false }));
    }, 200)
  }

  closeNetworkMenuHandelar = () => {
    setTimeout(() => {
      this.setState((prevState) => ({ ...prevState, isNetworkSubmenuActive: false }));
    }, 200)
  }

  closeReferralsMenuHandelar = () => {
    setTimeout(() => {
      this.setState((prevState) => ({ ...prevState, isReferralsSubmenuActive: false }));
    }, 200)
  }


  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.forceUpdate();
    }
  }

  checkIfJobsButtonIsActive = (path) => {
    switch (path) {
      case "/jobsboard/all-jobs":
      case "/jobsboard/applied-jobs":
      case "/jobsboard/posted-jobs/draft":
      case "/jobsboard/posted-jobs/submitted":
      case "/jobsboard/posted-jobs/published":
      case "/jobsboard/posted-jobs/closed":
        return true;
      default:
        return false;
    }
  };

  checkIfNetworkButtonIsActive = (path) => {
    switch (path) {
      case "/networks/my-patients":
      case "/networks/connections/active":
      case "/networks/connections/pending":
      case "/networks/connections/invitations":
      case "/networks/groups/owned":
      case "/networks/groups/followed":
      case "/networks/groups/invitations":
      case "/networks/pages/owned":
      case "/networks/pages/followed":
        return true;
      default:
        return false;
    }
  };
  checkIfReferralsButtonIsActive = (path) => {
    switch (path) {
      case "/referrals/add-patient":
      case "/referrals/refer-patient":
      case "/referrals/received-referrals":
      case "/referrals/sent-referrals":
        return true;
      default:
        return false;
    }
  };

  render() {
    const currentPath = window.location.pathname;
    const { isActive, isSideExpand, isExpandAnimated, isjobSubmenuActive, isNetworkSubmenuActive, isReferralsSubmenuActive, isNewAdminView, activeMenu } = this.state;
    const location = createBrowserHistory();
    const urlLocation = location.location.pathname;
    const isAdminUser = isAdmin();
    const isInstitutionAdminUser = isInstitutionAdmin();
    const isPractitionerUser = isPractitioner();
    const isPatientUser = isPatient();

    return (
      <>
        {isNewAdminView &&
          <>
            <div className="nex-admin--wrapper">
              <div className="d-none"><Header /></div>
              <NewHeader />
              <main
                className={`nex-admin-main--w ${urlLocation === "/messages" ? "message-layout" : ""
                  } ${isSideExpand ? 'expand' : ''}`}
              >
                <div className={`nex-admin-aside-menu ${isSideExpand ? 'expand' : ''} ${isExpandAnimated ? ' animated' : ''}`}>
                  <ul>
                    {isPractitionerUser && (
                      <li className={`${currentPath === '/dashboard' && 'active'}`}>
                        <Link to="/dashboard" id="aside_menu_1">
                          <div className="tooltip-container">
                            <span className="icon"><img src={Dashboard} alt="" /></span>
                            <span className="tooltip-text">Dashboard</span>
                          </div>
                          <span className="manu-label">Dashboard</span>
                        </Link>
                      </li>
                    )}
                    {(isPractitionerUser || isPatientUser || isInstitutionAdminUser) && (
                      <li className={`${currentPath === '/home' && 'active'}`}>
                        <Link to="/home" id="aside_menu_1">
                          <div className="tooltip-container">
                            <span className="icon"><img src={Home} alt="" /></span>
                            <span className="tooltip-text">Home</span>
                          </div>
                          <span className="manu-label">Home</span>
                        </Link>
                      </li>
                    )}
                    {(isPractitionerUser || isInstitutionAdminUser) && (
                      <li className={`${currentPath === '/messages' && 'active'}`}>
                        <Link to="/messages" id="aside_menu_1">
                          <div className="tooltip-container">
                            <span className="icon"><img src={New} alt="" /></span>
                            <span className="tooltip-text">Messages</span>
                          </div>
                          <span className="manu-label">Messages</span>
                        </Link>
                      </li>
                    )}
                    {isPractitionerUser && (
                      <li className={`${currentPath === '/appointments/day' && 'active'}`}>
                        <Link to="/appointments/day" id="aside_menu_1">
                          <div className="tooltip-container">
                            <span className="icon"><img src={Appointment} alt="" /></span>
                            <span className="tooltip-text">Appointments</span>
                          </div>
                          <span className="manu-label">Appointments</span>
                        </Link>
                      </li>
                    )}

                    {(isPractitionerUser || isInstitutionAdminUser) &&
                      <li className={`${isNetworkSubmenuActive ? 'open active' : this.checkIfNetworkButtonIsActive(currentPath) && 'active'}`}>
                        <span className="link" onClick={(e) => { e.preventDefault(); this.networkSubmenuHandle(); }}><div className="tooltip-container"><span className="icon"><img src={network} alt="" /></span><span className="tooltip-text">Network</span></div> <span className="manu-label">Network</span></span>
                        <ul className={`submenu lavel-2 ${isNetworkSubmenuActive ? 'open' : ''}`}>

                          {isPractitionerUser &&
                            <li className={`${currentPath === '/networks/my-patients' && 'open'}`}>
                              <Link to="/networks/my-patients" onClick={this.closeNetworkMenuHandelar} >My Patients</Link>
                            </li>
                          }

                          {isPractitionerUser &&
                            <li className={`${currentPath.startsWith('/networks/connections') && 'open'}`}>
                              <Link to="/networks/connections/active">My Network</Link>
                              <ul className="lavel-3">
                                <li className={`${currentPath === '/networks/connections/active' && 'active'}`}><Link to="/networks/connections/active" onClick={this.closeNetworkMenuHandelar}>Active</Link></li>
                                <li className={`${currentPath === '/networks/connections/pending' && 'active'}`}><Link to="/networks/connections/pending" onClick={this.closeNetworkMenuHandelar}>Pending</Link></li>
                                <li className={`${currentPath === '/networks/connections/invitations' && 'active'}`}><Link to="/networks/connections/invitations" onClick={this.closeNetworkMenuHandelar}>Invitations</Link></li>
                              </ul>
                            </li>
                          }

                          {isPractitionerUser &&
                            <li className={`${currentPath.startsWith('/networks/groups') && 'open'}`}>
                              <Link to="/networks/groups/owned">Groups</Link>
                              <ul className="lavel-3">
                                <li className={`${currentPath === '/networks/groups/owned' && 'active'}`}><Link to="/networks/groups/owned" onClick={this.closeNetworkMenuHandelar}>Owned</Link></li>
                                <li className={`${currentPath === '/networks/groups/followed' && 'active'}`}><Link to="/networks/groups/followed" onClick={this.closeNetworkMenuHandelar}>Followed</Link></li>
                                <li className={`${currentPath === '/networks/groups/invitations' && 'active'}`}><Link to="/networks/groups/invitations" onClick={this.closeNetworkMenuHandelar}>Invitations</Link></li>
                              </ul>
                            </li>
                          }
                          {isInstitutionAdminUser &&
                            <li className={`${currentPath.startsWith('/networks/pages') && 'open'}`}>
                              <Link to="/networks/pages/owned">Pages</Link>
                              <ul className="lavel-3">
                                <li className={`${currentPath === '/networks/pages/owned' && 'active'}`}><Link to="/networks/pages/owned" onClick={this.closeNetworkMenuHandelar}>Owned</Link></li>
                              </ul>
                            </li>
                          }

                          {isPractitionerUser &&
                            <li className={`${currentPath.startsWith('/networks/pages') && 'open'}`}>
                              <Link to="/networks/pages/followed">Pages</Link>
                              <ul className="lavel-3">
                                <li className={`${currentPath === '/networks/pages/followed' && 'active'}`}><Link to="/networks/pages/followed" onClick={this.closeNetworkMenuHandelar}>Followed</Link></li>
                              </ul>
                            </li>
                          }

                        </ul>
                      </li>
                    }

                    {(isPractitionerUser || isInstitutionAdminUser) && (
                      <li className={`${isjobSubmenuActive ? 'open active' : this.checkIfJobsButtonIsActive(currentPath) && 'active'}`}>
                        <span className="link" onClick={(e) => { e.preventDefault(); this.jobSubmenuHandle(); }}><div className="tooltip-container"><span className="icon"><img src={Checked_bag} alt="" /></span><span className="tooltip-text">Jobs</span></div> <span className="manu-label">Jobs</span></span>
                        <OutsideClickHandler onOutsideClick={() => { this.outsideSubmenuHandle() }}>
                          <ul className={`submenu lavel-2 ${isjobSubmenuActive ? 'open' : ''}`}>
                            {/* {isPractitionerUser && ( */}
                            {/* <> */}
                            <li className={`${currentPath === '/jobsboard/all-jobs' && 'open'}`}>
                              <Link to="/jobsboard/all-jobs" onClick={this.closeJobMenuHandelar}>All Jobs</Link>
                            </li>
                            {(isPractitionerUser || isInstitutionAdminUser) && (
                              <li className={`${currentPath.startsWith('/jobsboard/posted-jobs') && 'open'}`}>
                                <Link to="/jobsboard/posted-jobs/draft">My Posted Jobs</Link>
                                <ul className="lavel-3">
                                  <li className={`${currentPath === '/jobsboard/posted-jobs/draft' && 'active'}`}><Link to="/jobsboard/posted-jobs/draft" onClick={this.closeJobMenuHandelar}>Draft Jobs</Link></li>
                                  <li className={`${currentPath === '/jobsboard/posted-jobs/submitted' && 'active'}`}><Link to="/jobsboard/posted-jobs/submitted" onClick={this.closeJobMenuHandelar}>Submitted Jobs</Link></li>
                                  <li className={`${currentPath === '/jobsboard/posted-jobs/published' && 'active'}`}><Link to="/jobsboard/posted-jobs/published" onClick={this.closeJobMenuHandelar}>Published Jobs</Link></li>
                                  <li className={`${currentPath === '/jobsboard/posted-jobs/closed' && 'active'}`}><Link to="/jobsboard/posted-jobs/closed" onClick={this.closeJobMenuHandelar}>Closed Jobs</Link></li>
                                </ul>
                              </li>
                            )}
                            {isPractitionerUser && (
                              <li className={`${currentPath === '/jobsboard/applied-jobs' && 'open'}`}>
                                <Link to="/jobsboard/applied-jobs" onClick={this.closeMenuHandelar}>Applied Jobs</Link>
                              </li>
                            )}
                            {(isPractitionerUser || isInstitutionAdminUser) && (
                              <li className={`nex-aside-white-btn ${currentPath === '/new/create-jobs' && 'open'}`}>
                                <Link to="/new/create-jobs" onClick={this.closeMenuHandelar}><span className="icon"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_1356_2049)">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.67274 3.57899C2.1285 3.12323 2.74665 2.86719 3.39119 2.86719H9.06177C9.50917 2.86719 9.87186 3.22987 9.87186 3.67727C9.87186 4.12467 9.50917 4.48735 9.06177 4.48735H3.39119C3.17634 4.48735 2.97029 4.5727 2.81837 4.72462C2.66645 4.87654 2.5811 5.08259 2.5811 5.29744V16.6386C2.5811 16.8535 2.66645 17.0595 2.81837 17.2114C2.97029 17.3633 3.17634 17.4487 3.39119 17.4487H14.7324C14.9472 17.4487 15.1533 17.3633 15.3052 17.2114C15.4571 17.0595 15.5424 16.8535 15.5424 16.6386V10.968C15.5424 10.5206 15.9051 10.1579 16.3525 10.1579C16.7999 10.1579 17.1626 10.5206 17.1626 10.968V16.6386C17.1626 17.2832 16.9066 17.9013 16.4508 18.3571C15.995 18.8128 15.3769 19.0689 14.7324 19.0689H3.39119C2.74665 19.0689 2.1285 18.8128 1.67274 18.3571C1.21698 17.9013 0.960938 17.2832 0.960938 16.6386V5.29744C0.960938 4.6529 1.21698 4.03475 1.67274 3.57899Z" fill="#425B76" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M16.3514 2.7686C16.1105 2.7686 15.8795 2.86431 15.7091 3.03466L8.17197 10.5718L7.74377 12.2846L9.45659 11.8564L16.9937 4.31928C17.1641 4.14893 17.2598 3.91788 17.2598 3.67697C17.2598 3.43606 17.1641 3.20501 16.9937 3.03466C16.8234 2.86431 16.5923 2.7686 16.3514 2.7686ZM14.5635 1.88903C15.0377 1.41484 15.6808 1.14844 16.3514 1.14844C17.022 1.14844 17.6652 1.41484 18.1394 1.88903C18.6135 2.36322 18.8799 3.00636 18.8799 3.67697C18.8799 4.34758 18.6135 4.99072 18.1394 5.46491L10.4436 13.1607C10.3397 13.2645 10.2097 13.3382 10.0672 13.3738L6.82689 14.1839C6.55083 14.2529 6.25881 14.172 6.0576 13.9708C5.85639 13.7696 5.7755 13.4776 5.84452 13.2015L6.6546 9.96116C6.69021 9.81872 6.76386 9.68864 6.86768 9.58482L14.5635 1.88903Z" fill="#425B76" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1356_2049">
                                      <rect width="19.442" height="19.442" fill="white" transform="translate(0.148438 0.4375)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                </span> Post a Job</Link>
                              </li>
                            )}
                            {/* </> */}
                            {/* )} */}
                          </ul>
                        </OutsideClickHandler>
                      </li>
                    )}
                    {(isPractitionerUser || isPatientUser) && (
                      <>
                        {isPractitionerUser &&
                          <li className={`${currentPath === '/u/medicalcases/received-opinions' && 'active'}`}>
                            <Link to="/u/medicalcases/received-opinions" id="aside_menu_1">
                              <div className="tooltip-container">
                                <span className="icon"><img src={Announcement} alt="" /></span>
                                <span className="tooltip-text">Second Opinion</span>
                              </div>
                              <span className="manu-label">Second Opinion</span>
                            </Link>
                          </li>
                        }

                        {isPatientUser &&
                          <li className={`${currentPath === '/u/medicalcases/my-opinions' && 'active'}`}>
                            <Link to="/u/medicalcases/my-opinions" id="aside_menu_1">
                              <div className="tooltip-container">
                                <span className="icon"><img src={Announcement} alt="" /></span>
                                <span className="tooltip-text">Second Opinion</span>
                              </div>
                              <span className="manu-label">Second Opinion</span>
                            </Link>
                          </li>
                        }


                        {(isPractitionerUser || isInstitutionAdminUser) && (
                          <li className={`${isReferralsSubmenuActive ? 'open active' : this.checkIfReferralsButtonIsActive(currentPath) && 'active'}`}>
                            <span className="link" onClick={(e) => { e.preventDefault(); this.referralsSubmenuHandle(); }}><div className="tooltip-container"><span className="icon"><img src={Rocket_launch} alt="" /></span><span className="tooltip-text">Referrals</span></div> <span className="manu-label">Referrals</span></span>
                            <OutsideClickHandler onOutsideClick={() => { this.outsideSubmenuHandle() }}>
                            {(isPractitionerUser) && (
                              <ul className={`submenu lavel-2 ${isReferralsSubmenuActive ? 'open' : ''}`}>                               
                                  <li className={`${currentPath === '/referrals/add-patient' && 'open'}`}>
                                  <Link to="/referrals/add-patient" onClick={this.closeMenuHandelar}>Add a Patient</Link>
                                </li>
                                <li className={`${currentPath === '/referrals/refer-patient' && 'open'}`}>
                                <Link to="/referrals/refer-patient" onClick={this.closeMenuHandelar}>Refer a Patient</Link>
                              </li>
                                  <li className={`${currentPath === 
                                  '/referrals/received-referrals' && 'open'}`}>
                                    <Link to="/referrals/received-referrals">Received Referrals</Link>
                                  </li>
                                  <li className={`${currentPath === '/referrals/sent-referrals' && 'open'}`}>
                                    <Link to="/referrals/sent-referrals" onClick={this.closeMenuHandelar}>Sent Referrals</Link>
                                  </li>
                              </ul>
                              
                            )}
                            </OutsideClickHandler>
                          </li>
                        )}

                        {/* {isPractitionerUser &&
                          <li className={`${currentPath === '/referrals/received-referrals' && 'active'}`}>
                            <Link to="/referrals/received-referrals" id="aside_menu_1">
                              <div className="tooltip-container">
                                <span className="icon"><img src={Rocket_launch} alt="" /></span>
                                <span className="tooltip-text">Referrals</span>
                              </div>
                              <span className="manu-label">Referrals</span>
                            </Link>
                          </li>
                        } */}

                        {isPatientUser &&
                          <li className={`${currentPath === '/my-referrals' && 'active'}`}>
                            <Link to="/my-referrals" id="aside_menu_1">
                              <div className="tooltip-container">
                                <span className="icon"><img src={Rocket_launch} alt="" /></span>
                                <span className="tooltip-text">Referrals</span>
                              </div>
                              <span className="manu-label">Referrals</span>
                            </Link>
                          </li>
                        }

                      </>
                    )}
                    {(isPractitionerUser || isInstitutionAdminUser) && (
                      <li className={`${currentPath === '/profiles' && 'active'}`}>
                        <Link to="/profiles" id="aside_menu_1">
                          <div className="tooltip-container">
                            <span className="icon"><img src={People_alt} alt="" /></span>
                            <span className="tooltip-text">Profiles</span>
                          </div>
                          <span className="manu-label">Profiles</span>
                        </Link>
                      </li>
                    )}
                    {isPractitionerUser && (
                      <li className={`${currentPath === '/reviews' && 'active'}`}>
                        <Link to="/reviews" id="aside_menu_1">
                          <div className="tooltip-container">
                            <span className="icon"><img src={thumbup} alt="" /></span>
                            <span className="tooltip-text">Reviews</span>
                          </div>
                          <span className="manu-label">Reviews</span>
                        </Link>
                      </li>
                    )}
                  </ul>
                  <hr style={{ border: '1px solid #ffffff0f', margin: '25px auto', width: '50%' }} />
                  <ul>
                    <li className={`${currentPath === '/settings' && 'active'}`}><Link to="/settings"><div className="tooltip-container"><span className="icon"><img src={Setting} alt="" /></span><span className="tooltip-text">Settings</span></div> <span className="manu-label">Settings</span> </Link></li>
                  </ul>
                  <div className="expand-icons" onClick={this.menuExpandHandle}>
                    <svg width={17} height={17} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" >
                      <rect x={0.5} y={0.5} width={16} height={16} rx={1.5} stroke="white" />
                      <rect x={2} y={2} width={8} height={13} rx={1} fill="white" />
                    </svg>
                  </div>
                </div>
                <div className="nex-resp-footer-menu">
                <ul>
                  <li>
                    <Link to="/dashboard">
                      <img src={Dashboard} alt="Dashboard" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/jobsboard/all-jobs">
                      <img src={Checked_bag} alt="Checked Bag" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/home">
                      <img src={Home} alt="Home" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/appointment">
                      <img src={Appointment} alt="Appointment" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/more">
                      <img src={menu_more} alt="More" width={24} />
                    </Link>
                  </li>
                </ul>
                  {/* <ul>
                    <li><a href=""><img src={Dashboard} alt="" /></a></li>
                    <li><a href=""><img src={Checked_bag} alt="" /></a></li>
                    <li><a href=""><img src={Home} alt="" /></a></li>
                    <li><a href=""><img src={Appointment} alt="" /></a></li>
                    <li><a href=""><img src={menu_more} alt="" width={24} /></a></li>
                  </ul> */}
                </div>
                <div className="nex-admin-main-container">
                  <div className="nex-main-container-inner">
                    <Content />
                  </div>
                  <NewFooter />
                </div>
                
              </main>
            </div>
          </>
        }
        {!isNewAdminView &&
          <>
            <section>
              <Header />
              <main
                className={`theme-body-bg nexogic-dashboard-card ${urlLocation === "/messages" ? "message-layout" : ""
                  }`}
              >
                <Container fluid className="container-fluid card-body">
                  <div className="row nexogic-content-area">
                    <div className="nexogic-admin-content col">
                      <Content />
                    </div>
                  </div>
                </Container>
              </main>
              <Footer />
            </section>
          </>
        }
      </>
    );
  }
}

export default withAuth(withRouter(App));