import { Form, Formik } from "formik";
import { memo, useState, useEffect } from "react";
import { withAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import "../../style.scss";
import JobCreateFormOne from "./NewForms/JobCreateFormOne";
import JobCreateFormTwo from "./NewForms/JobCreateFormTwo";
import JobCreateFormThree from "./NewForms/JobCreateFormThree";
import JobCreateFormFour from "./NewForms/JobCreateFormFour";
import JobCreateFormFive from "./NewForms/JobCreateFormFive";
import JobCreateFormSix from "./NewForms/JobCreateFormSix";
import JobCreateFormSeven from "./NewForms/JobCreateFormSeven";
import JobCreateFormEight from "./NewForms/JobCreateFormEight";
import JobCreatePreview from "./NewForms/JobCreatePreview";
import { TalentService } from "utils/TalentService";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { FAILTOSAVERECOED } from "utils/Constants";
import CommonLoader from "common/CommonLoader";

// Validation schemas for each step
const StepTwoSchema = Yup.object().shape({
  institutionDetail: Yup.string().required("Please select Hospital/Clinic"),
});

const StepThreeSchema = Yup.object().shape({
  title: Yup.string().required("This field is required")
    .min(3, "Title must be at least 3 characters long"),
  primarySpeciality: Yup.array().min(1, "This field is required"),
  noOfPosition: Yup.number()
    .required("This field is required")
    .min(1, "The number of positions must be at least 1")
    .max(100, "The number of positions cannot exceed 100"),
  opportunity: Yup.string().required("This field is required"),
  position: Yup.string().required("This field is required"),
});


const StepFourSchema = Yup.object().shape({
  qualifications: Yup.array().min(1, "This field is required"),
  skills: Yup.array().min(1, "At least one skill is required"),
  shift: Yup.string().required("This field is required"),
});

const StepFiveSchema = Yup.object().shape({
  description: Yup.string().required("This field is required"),
  role: Yup.string().required("This field is required"),
});

const StepSixSchema = Yup.object().shape({
  minExperience: Yup.number().required().min(0, "Minimum experience must be at least 0 years"),
  maxExperience: Yup.number().required().min(
    Yup.ref("minExperience"),
    "Maximum experience must be greater than minimum experience"
  ),
  minSalary: Yup.number().required().min(0, "Minimum salary must be at least 0"),
  maxSalary: Yup.number().required().min(
    Yup.ref("minSalary"),
    "Maximum salary must be greater than minimum salary"
  ),
});


const StepSevenSchema = Yup.object().shape({
  city: Yup.array().min(1, "This field is required"),
  location: Yup.array().min(1, "This field is required"),
  applyMode: Yup.string().required("This field is required"),
  linkToApply: Yup.string()
    .required("This field is required")
    .when("applyMode", {
      is: "EMAIL",
      then: Yup.string().email("Must be a valid email address"),
    }),
});


const getValidationSchema = (step) => {
  switch (step) {
    case 2:
      return StepTwoSchema;
    case 3:
      return StepThreeSchema;
    case 4:
      return StepFourSchema;
    case 5:
      return StepFiveSchema;
    case 6:
      return StepSixSchema;
    case 7:
      return StepSevenSchema;
    default:
      return null;
  }
};

const NewCreateJob = (props) => {
  const { jobId } = props;
  const [newSteps, setNewSteps] = useState(1);
  const [data, setData] = useState({
    institutionDetail: '',
    title: '',
    primarySpeciality: [],
    position: '',
    noOfPosition: '',
    qualifications: [],
    skills: [],
    description: '',
    city: [],
    location: [],
    applyMode: 'EMAIL',
    linkToApply: '',
    opportunity: '',
    position: '',
    shift: '',
    minExperience: 0,
    maxExperience: 0,
    minSalary: 0,
    maxSalary: 0,
    experienceLevel: 'beginner',
    salaryLevel: 'negotiable',
    role: '',
    screeningQuestions: []
  });
  const history = useHistory();
  const [progressLine, setProgressLine] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [jobToBeCreate, setJobToBeCreate] = useState(false);
  const [jobToBeUpdated, setJobToBeUpdated] = useState(null);
  const [jobToBeCloned, setJobToBeCloned] = useState(null);
  const [isLoadingFor2ndStep, setIsLoadingFor2ndStep] = useState(false);

  useEffect(() => {
    // console.log(data);
  }, [data])

  useEffect(() => {
    if (jobId) {
      TalentService.getJob(jobId)
        .then((response) => {
          const jobData = response.data;
          const qualificationsArray = jobData.qualifications.split(', ').map(item => item.trim());
          setData({
            jobGuid: jobData.id,
            institutionDetail: jobData.institutionId || '',
            title: jobData.title || '',
            primarySpeciality: jobData.primarySpeciality ? [jobData.primarySpeciality] : [],
            position: jobData.position || '',
            noOfPosition: jobData.noOfPosition || '',
            opportunity: jobData.opportunity || '',
            position: jobData.position || '',
            shift: jobData.shift || '',
            role: jobData.role || '',
            minExperience: jobData.minExperience || '',
            maxExperience: jobData.maxExperience || '',
            minSalary: jobData.minSalary || 0,
            maxSalary: jobData.maxSalary || 0,
            qualifications: qualificationsArray,
            skills: jobData.skills || [],
            description: jobData.description || '',
            city: jobData.city ? [{ value: jobData.city, label: jobData.city }] : [],
            location: jobData.location ? [jobData.location] : [],
            applyMode: jobData.applyMode || 'EMAIL',
            linkToApply: jobData.linkToApply || '',
            screeningQuestions: jobData.screeningQuestions
          });
          setNewSteps(9);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Failed to fetch job details:", error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [jobId]);

  useEffect(() => {
    setProgressLine(11 * newSteps);
  }, [newSteps]);

  const createNewJob = (values, submit = false) => {
    const payload = {
      title: values.title,
      institutionId: values.institutionDetail,
      position: values.position,
      opportunity: values.opportunity,
      city: values?.city[0]?.value.split(",")[0],
      location: values?.location[0],
      status: submit ? "SUBMITTED" : "DRAFT",
      primarySpeciality: values?.primarySpeciality[0],
      description: values?.description,
      shift: values.shift,
      role: values.role,
      skills: values.skills,
      applyMode: values.applyMode,
      linkToApply: values.linkToApply,
      qualifications: values.qualifications.join(', '),
      noOfPosition: values.noOfPosition,
      minExperience: values.minExperience,
      maxExperience: values.maxExperience,
      minSalary: values.minSalary,
      maxSalary: values.maxSalary,
      screeningQuestions: values.screeningQuestions
    };

    if (jobId) {
      TalentService.updateJob(payload, data.jobGuid)
        .then((response) => {
          const { status, data } = response;
          if (status === 200) {
            if (submit) {
              showSuccessMessage("Job Submitted Successfully.");
              history.push(`/jobsboard/posted-jobs/submitted`);
            } else {
              showSuccessMessage("Job Updated Successfully.");
              history.push(`/jobsboard/posted-jobs/draft`);
            }
          } else {
            showErrorMessage(FAILTOSAVERECOED);
          }
        })
        .catch((error) => {
          showErrorMessage(FAILTOSAVERECOED);
        });
    } else {
      TalentService.registerJob(payload)
        .then((response) => {
          const { status, data } = response;
          if (status === 201) {
            showSuccessMessage("Job Created Successfully...");
            // history.push("/jobsboard/posted-jobs");
            history.push(`/new/job-preview/${data?.id}`);
          } else {
            showErrorMessage(FAILTOSAVERECOED);
          }
        })
        .catch((error) => {
          showErrorMessage(FAILTOSAVERECOED);
        });
    }
  };

  const cloneAJob = (jobId) => {
    TalentService.getJob(jobId)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          let payload = {
            "title": data.title,
            "institutionId": data.institutionId,
            "position": data.position,
            "city": data.city,
            "location": data.location,
            "opportunity": data.opportunity,
            "primarySpeciality": data.primarySpeciality,
            "description": data.description,
            "noOfPosition": data.noOfPosition,
            "shift": data.shift,
            "applyMode": data.applyMode,
            "linkToApply": data.linkToApply,
            "skills": data.skills,
            "role": data.role,
            "qualifications": data.qualifications,
            "minSalary": data.minSalary,
            "maxSalary": data.maxSalary,
            "minExperience": data.minExperience,
            "maxExperience": data.maxExperience,
            "screeningQuestions": data.screeningQuestions
          }

          TalentService.registerJob(payload)
            .then((response) => {
              const { status, data } = response;
              if (status === 201) {
                showSuccessMessage("Job Cloned Successfully...");
                window.open(`/new/update-jobs/${data?.id}`, '_self');
                setIsLoadingFor2ndStep(false);
              } else {
                showErrorMessage(FAILTOSAVERECOED);
              }
            })
        }
      })
  }

  const handleContinueButton = () => {
    if (jobToBeCreate) {
      setNewSteps(2);
    } else {
      if (jobToBeUpdated)
        window.open(`/new/update-jobs/${jobToBeUpdated}`, '_self');
      else {
        setIsLoadingFor2ndStep(true);
        cloneAJob(jobToBeCloned);
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <div className="loading-spiner text-center m-5">
          <CommonLoader style={{ width: "2rem", height: "2rem" }} />
        </div>
      ) : (
        <div className={`nex-job-form-wrap-flex ${newSteps === 9 ? " no-top-gap" : ""}`}>
          <Formik
            initialValues={data}
            validationSchema={getValidationSchema(newSteps)}
            onSubmit={(values, actions) => {
              setData(values);
              if (newSteps < 9) {
                setNewSteps(newSteps + 1);
              } else {
                createNewJob(values);
              }
            }}
            enableReinitialize
          >
            {({ errors, touched, handleSubmit, values }) => (
              <Form>
                <div className="nex-create-job-form-inner">
                  {newSteps === 1 && <JobCreateFormOne
                    onSetNewSteps={(item) => setNewSteps(item)}
                    jobToBeUpdated={jobToBeUpdated}
                    jobToBeCreate={jobToBeCreate}
                    setJobToBeCreate={setJobToBeCreate}
                    setJobToBeUpdated={setJobToBeUpdated}
                    jobToBeCloned={jobToBeCloned}
                    setJobToBeCloned={setJobToBeCloned}
                    isLoadingFor2ndStep={isLoadingFor2ndStep}
                  />}
                  {newSteps === 2 && <JobCreateFormTwo />}
                  {newSteps === 3 && <JobCreateFormThree values={values} />}
                  {newSteps === 4 && <JobCreateFormFour data={data} />}
                  {newSteps === 5 && <JobCreateFormFive />}
                  {newSteps === 6 && <JobCreateFormSix />}
                  {newSteps === 7 && <JobCreateFormSeven values={values} />}
                  {newSteps === 8 && <JobCreateFormEight />}
                  {newSteps === 9 && <JobCreatePreview data={data} setData={setData} values={values} />}
                </div>

                <div className="nex-job-form-outer-sec">
                  <div className="nex-placeholder-long">
                    <div className="nex-highlight-bar" style={{ width: `${progressLine}%` }}></div>
                  </div>

                  {newSteps === 1 ?
                    <>
                      {
                        (jobToBeCreate || jobToBeUpdated || jobToBeCloned) &&
                        <div className="nex-action-sec">
                          <span
                            className="btn nex-btn-primary ml-auto"
                            onClick={() => handleContinueButton()}
                          >
                            Continue
                          </span>
                        </div>
                      }
                    </>
                    :
                    <div className="nex-action-sec">
                      {newSteps > 1 && (!jobId) && (
                        <span
                          className="btn nex-btn-primary"
                          onClick={() => setNewSteps(newSteps - 1)}
                        >
                          Previous
                        </span>
                      )}
                      {newSteps != 9 &&
                        <span
                          className="btn nex-btn-primary ml-auto"
                          onClick={handleSubmit}
                        >
                          {"Next"}
                        </span>
                      }
                      {newSteps === 9 &&
                        <>
                          <span
                            className="btn nex-btn-primary"
                            onClick={() => createNewJob(values, false)}
                          >
                            {"Save as Draft"}
                          </span>
                          {jobId &&
                            <span
                              className="btn nex-btn-primary ml-auto"
                              onClick={() => createNewJob(values, true)}
                            >
                              {"Submit this Job"}
                            </span>
                          }
                        </>
                      }
                    </div>
                  }
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default withAlert()(memo(NewCreateJob));
