import FormikInputField from 'components/forms/formikFields/FormikInputField';
import { Field, Form, Formik } from 'formik';
import React, {
    useEffect,
    useState,
} from "react";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { useSelector } from 'react-redux';
import { Card, Col, FormGroup, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import { MetadataService } from 'utils/MetadataService';
import FormikSelectField from 'components/forms/formikFields/FormikSelectField';
import FormikDatePicker from 'components/forms/formikFields/FormikDatePicker';
import { OpinionService } from 'utils/OpinionService';
import { FAILED_TO_FETCH_DATA, FAILTOSAVERECOED } from 'utils/Constants';
import { withAlert } from 'react-alert';
import { isPatient } from 'utils/UserHelper';
import { PatientService } from 'utils/PatientService';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom';
import { PractitionerService } from 'utils/PractitionerService';
import PrimarySpecialitiesOpinionTypeheadField from 'components/forms/formikFields/PrimarySpecialitiesOpinionTypeheadField';
import AssociatedPractitionersOpinionTypeaheadField from 'components/forms/formikFields/AssociatedPractitionersOpinionTypeaheadField';
import CommonMultipleSelectTypeaheadField from 'components/forms/formikFields/CommonMultipleSelectTypeaheadField';
import radio_tick from "assets/images/nex-completed-tick.png";
import profile_image from 'assets/images/profile-image.png'

const validationSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string().email("Invalid email"),
    dateOfBirth: Yup.string(),
    gender: Yup.string(),
});


const PersonalDetails = (props) => {
    const { opinionId } = useParams();

    const [isSelf, setIsSelf] = useState(true);
    const [elseAvailable, setElseAvailable] = useState(true);
    const [initialValues, setInitialValues] = useState({});
    const currentUser = useSelector((state) => state.auth.currentUser);
    const [currentUserDetails, setCurrentUserDetails] = useState({});
    const [primarySpecialityList, setPrimarySpecialityList] = useState([]);
    const [showAssociatedPractitioners, setShowAssociatedPractitioners] = useState(false);
    const [practitioners, setPractitioners] = useState([]);
    const [speciality, setSpeciality] = useState([]);
    const [relatedPeople, setRelatedPeople] = useState([]);
    const [selectedRelatedPerson, setSelectedRelatedPerson] = useState(null);
    const [languagesList, setLanguagesList] = useState([]);

    const getLanguages = () => {
        MetadataService.getLanguages()
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setLanguagesList(response.data);
                    // setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log("IdentityService.getLanguages error", error);
                // setIsLoading(false);
            });
    };

    const getPrimarySpeciality = () => {
        MetadataService.getPrimarySpecialities()
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setSpeciality(response.data);
                }
            })
            .catch((error) => {
                console.log("IdentityService.getPrimarySpeciality error", error);
            });
    };

    const relationshipTypes = [
        {
            label: "Mother",
            value: "MOTHER",
        },
        {
            label: "Father",
            value: "FATHER",
        },
        {
            label: "Sibling",
            value: "SIBLING",
        },
        {
            label: "Spouse",
            value: "SPOUSE",
        },
        {
            label: "Other",
            value: "OTHER",
        }
    ];


    const genders = [
        {
            label: "Male",
            value: "M",
        },
        {
            label: "Female",
            value: "F",
        },
        {
            label: "Prefer not to answer",
            value: "U",
        },
    ];

    const titles = [
        {
            label: "Dr.",
            value: "Dr.",
        },
        {
            label: "Mr.",
            value: "Mr.",
        },
        {
            label: "Mrs.",
            value: "Mrs.",
        },
        {
            label: "Ms.",
            value: "Ms.",
        },
        {
            label: "Prof.",
            value: "Prof.",
        },
    ];

    const getLoggedInPatientDetails = (id) => {
        PatientService.getPatientById(id)
            .then((response) => {
                const { status, data } = response;
                if (status == 200) {
                    setCurrentUserDetails(data);
                }
                else {
                    showErrorMessage(FAILED_TO_FETCH_DATA);
                }
            })
    }

    const getRelatedPeople = (id) => {
        PatientService.getRelatedPeople(id)
            .then((response => {
                const { status, data } = response;
                if (status == 200) {
                    const updatedData = [
                        ...data,
                        { firstName: "Add New", firstName: "Add New" },
                    ];
                    setRelatedPeople(updatedData);
                }
            }))
    }

    const handleSelectChange = (selectedOption) => {
        // console.log('Selected Value:', selectedOption?.target?.value);
        if (selectedOption?.target?.value === 'Add New') {
            setElseAvailable(false);
        } else {
            setSelectedRelatedPerson(selectedOption?.target?.value);
            setElseAvailable(true);
        }

    };

    useEffect(() => {
        getLanguages();
        getRelatedPeople(currentUser?.profileGuid);
        getPrimarySpeciality();
        if (isPatient()) {
            getLoggedInPatientDetails(currentUser?.profileGuid);
        }
    }, [])

    const setInitals = () => {
        // console.log(currentUser);
        // Initialize form values based on the selected option
        if (isSelf) {
            setInitialValues((prevState) => ({
                ...prevState,
                inquiry: "",
                primarySpeciality: [],
                practitioner: [],

                title: currentUserDetails?.title || "",
                firstName: currentUserDetails?.firstName,
                middleName: currentUserDetails?.middleName || "",
                lastName: currentUserDetails?.lastName || "",
                dateOfBirth: currentUserDetails?.dateOfBirth || "",
                gender: currentUserDetails?.gender || "",
                phoneNumber: currentUserDetails?.cellularPhoneNumber || "",
                emailAddress: currentUserDetails?.emailAddress || "",
                abhaNumber: currentUserDetails?.abhaNumber || "",
                languages: [],
            }));
        } else {
            setInitialValues({
                inquiry: "",
                primarySpeciality: [],
                practitioner: [],

                title: "",
                firstName: "",
                middleName: "",
                lastName: "",
                dateOfBirth: "",
                gender: "",
                phoneNumber: "",
                abhaNumber: "",
                phoneNumber: "",
                relationshipType: "",
                languages: [],
            });
        }
    }

    const getAndSetInitials = () => {
        OpinionService.getOpinion(opinionId)
            .then((response) => {
                const { status, data } = response;

                if (status === 200) {
                    setInitialValues((prevState) => ({
                        ...prevState,
                        title: data?.title,
                        firstName: data?.firstName,
                        middleName: data?.middleName || "",
                        lastName: data?.lastName || "",

                        inquiry: data?.title || "",
                        primarySpeciality: data?.speciality ? [data.speciality] : [],
                        practitioner: data?.practitioner || "",
                        requestedFor: data?.requestedFor || "",
                        requestedBy: data?.requestedBy || "",

                        description: data?.description || "",
                        familyHistory: data?.familyHistory || "",
                        pastHospitalization: data?.pastHospitalization || "",
                    }));
                    // console.log(data);
                } else {
                    console.log(FAILED_TO_FETCH_DATA);
                }
            })
    }

    useEffect(() => {
        if (opinionId) {
            props.setOpinionId(opinionId);
            getAndSetInitials();
        }
        else
            setInitals();
    }, [isSelf, currentUserDetails, opinionId]);

    const createRelatedPerson = async (patientId, values) => {
        const payload = {
            "title": values?.title,
            "firstName": values?.firstName,
            "middleName": values?.middleName,
            "lastName": values?.lastName,
            "gender": values?.gender,
            "dateOfBirth": values?.dateOfBirth
                ? new Date(values.dateOfBirth).toISOString().split("T")[0]
                : "",
            "abhaNumber": values?.abhaNumber,
            "relationshipType": values.relationshipType,
            "languages": values.languages
        };

        try {
            const response = await PatientService.createRelatedPerson(patientId, payload);
            const { status, data } = response;
            if (status === 201) {
                return data; // Returning the whole data object
            } else {
                console.error(FAILTOSAVERECOED);
                return null;
            }
        } catch (error) {
            console.error(FAILTOSAVERECOED, error);
            return null;
        }
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        let payload = {};
        let relatedPersonId = null;

        if (elseAvailable) {
            relatedPersonId = selectedRelatedPerson;
        } else {
            const personInfo = await createRelatedPerson(currentUser?.profileGuid, values);
            relatedPersonId = personInfo?.id;
        }

        payload = {
            "title": values?.inquiry,
            "requestedBy": currentUser?.profileGuid,
            "requestedFor": isSelf ? currentUser?.profileGuid : relatedPersonId,
            "speciality": (values.primarySpeciality && ((values.primarySpeciality)[0])) ? ((values.primarySpeciality)[0]) : "",
            "practitioner": values?.practitioner ? values?.practitioner[0]?.profileGuid : "",
            "status": "DRAFT"
        }

        // console.log("Payload Values:", payload);
        if (opinionId) {
            setInitialValues((prevState) => ({
                ...prevState,
                requestedBy: values?.requestedBy || "",
                requestedFor: values?.requestedFor || "",
            }));

            OpinionService.updateOpinion(opinionId, payload)
                .then((response) => {
                    const { status, data } = response;
                    if (status === 200) {
                        showSuccessMessage("Personal Details updated Successfully.")
                        props.setOpinionId(data?.id);
                        props.handleStepSwitch(1);
                    } else {
                        showErrorMessage(FAILTOSAVERECOED)
                    }
                })
        }
        else {
            OpinionService.createOpinion(payload)
                .then((response) => {
                    const { status, data, message } = response;
                    if (status === 201) {
                        showSuccessMessage("Personal Details added Successfully.")
                        props.setOpinionId(data?.id);
                        props.handleStepSwitch(1);
                    } else if (status === 400) {
                        showErrorMessage(message)
                    } else {
                        showErrorMessage(FAILTOSAVERECOED)
                    }
                })

        }
        setSubmitting(false);
    }

    const getPractitioners = async (speciality) => {
        let sq = `st=='Approved';ps=='${speciality}'`;
        await PractitionerService.getPractitionersForOpinion(sq, 0
        ).then((response) => {
            const { status, data } = response;
            if (status === 200) {
                setPractitioners(() => data?.contents);
            } else {
                console.log("Something went wrong");
            }
        });
    };

    return (
        <div className='card-body pb-0 pt-0'>
            <div className="nexogic-second-option-form-wrap">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    <Form className='nex-patient-info-form'>


                        <div className="form-step">
                            <h2 className='nex-text-xxl mb-5'>Create Second Opinion Request for</h2>
                            <div className="nex-radio-boxes">
                                    <div className="nex-radio-box">
                                        <input type="radio" name="radAvailTheme" id="customtheme" checked={isSelf}
                                                    onChange={() => setIsSelf(true)} />
                                        <div className="radio-backgdrops"></div>
                                        <span className='radio-check'>
                                            <img src={radio_tick} alt="" />
                                        </span>
                                        <div className="graphic">
                                            <img src={profile_image} width={68} alt="" />
                                        </div>

                                        <div className="info">
                                            <div className="nex-text-xl small label">Yourself</div>
                                            <div className="nex-text">You can take a second opinion for yourself only.</div>
                                        </div>
                                        
                                    </div>
                                    <div className="nex-radio-box ">
                                        <input type="radio" name="radAvailTheme" id="themedefault" checked={!isSelf}
                                                    onChange={() => setIsSelf(false)}  />
                                        <div className="radio-backgdrops"></div>
                                        <span className='radio-check'>
                                            <img src={radio_tick} alt="" />
                                        </span>
                                        <div className="graphic">
                                            <img src={profile_image} width={68} alt="" />
                                        </div>
                                        
                                        <div className="info">
                                            <div className="nex-text-xl small label">Someone Else</div>
                                            <div className="nex-text">You can take a second opinion for your family members too.</div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className="form-step">
                            <h2 className='nex-text-xxl mb-5'>Second Opinion for</h2>
                            <div className="nex-stack mb-3 form-group">
                                <div className="nex-avatar-info nex-flex">
                                    <div className="graphic">
                                        <img src="https://images-dev.nexogic.com/53efb6fd-1ceb-412a-b19d-b65032bde722/a8dd998a-8e20-4ac4-971f-bf91154aa6fc.png" alt="" class="nex-avatar lg" />
                                    </div>
                                    <div className="info">
                                        <h3 className="nex-text-lg">Rumana Nasrin (36)</h3>
                                        <p>Female</p>
                                        <p>Email : rumana.nasrin@gmail.com</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group v2">
                                <h3 className='nex-text-lg'>Describe your health issue in brief ?</h3>
                                <textarea className='form-control'></textarea>
                            </div>
                            <div className="form-group v2">
                                <h3 className='nex-text-lg'>Choose Doctor Specialty</h3>
                                <input type='text' className='form-control' placeholder='Click here to choose speciality' />
                            </div>
                            

                        </div>

                       



                        <Row>
                            <Col lg={12}>
                                <legend className='px-0'>Basic Information</legend>
                            </Col>
                            <Col lg={4}>
                                <Label className="d-block">Speciality Requested </Label>
                                <Field
                                    id="primarySpeciality"
                                    name="primarySpeciality"
                                    type="text"
                                    component={PrimarySpecialitiesOpinionTypeheadField}
                                    placeholder="Select Speciality"
                                    value={initialValues.primarySpeciality}
                                    spellCheck={false}
                                    specialit={speciality}
                                    autoComplete="primarySpeciality"
                                    getPractitionersList={getPractitioners}
                                    setShowAssociatedPractitioners={setShowAssociatedPractitioners}
                                />
                            </Col>
                            <Col lg={8}>
                                <Field
                                    title="inquiry"
                                    label="Describe your Health Concern"
                                    name="inquiry"
                                    value={initialValues.inquiry}
                                    id="inquiry"
                                    type="text"
                                    component={FormikInputField}
                                    placeholder="Inquiry"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} id="nex-rpn-refer-to-practitioner" className='mb-4'>
                                <Label className="d-block mb-2">Practitioner</Label>
                                <Field
                                    className="nex-refer-to-practitioner"
                                    id="practitioner"
                                    name="practitioner"
                                    type="text"
                                    practitioners={practitioners}
                                    activeFlag={showAssociatedPractitioners ? false : true}
                                    component={AssociatedPractitionersOpinionTypeaheadField}
                                    placeholder="Select Practitioner"
                                    value={initialValues.practitioner}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <label className='ml-2'>
                                    <input
                                        type="radio"
                                        value="self"
                                        checked={isSelf}
                                        onChange={() => setIsSelf(true)}
                                        className='custom-radio-inp'
                                    />
                                    Self
                                </label>
                                <label className='ml-5'>
                                    <input
                                        type="radio"
                                        value="someoneElse"
                                        checked={!isSelf}
                                        onChange={() => setIsSelf(false)}
                                        className='custom-radio-inp'
                                    />
                                    Someone Else
                                </label>
                            </Col>
                        </Row>
                        {!isSelf ?
                            <>
                                <>
                                    <Field
                                        id="relatedPeople"
                                        name="relatedPeople"
                                        type="text"
                                        label="Related People"
                                        component={FormikSelectField}
                                        onChange={handleSelectChange}
                                        // value={initialValues?.relatedPeople}
                                        inputprops={{
                                            name: "relatedPeople",
                                            options: relatedPeople,
                                            defaultOption: "Select",
                                            keys: {
                                                id: "id",
                                                label: "firstName",
                                            },
                                        }}
                                    />
                                </>

                                {!elseAvailable &&
                                    <>
                                        <Row>
                                            <legend className='px-0'>Patient Information</legend>
                                            <Col lg={12}>
                                                <Field
                                                    id="relationshipType"
                                                    name="relationshipType"
                                                    type="text"
                                                    label="Relation Type"
                                                    component={FormikSelectField}
                                                    // value={initialValues?.title}
                                                    inputprops={{
                                                        name: "relationshipType",
                                                        options: relationshipTypes,
                                                        defaultOption: "Select",
                                                        keys: {
                                                            id: "value",
                                                            label: "label",
                                                        },
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={2}>
                                                <Field
                                                    id="title"
                                                    name="title"
                                                    type="text"
                                                    label="Title"
                                                    component={FormikSelectField}
                                                    // value={initialValues?.title}
                                                    inputprops={{
                                                        name: "title",
                                                        options: titles,
                                                        defaultOption: "Select",
                                                        keys: {
                                                            id: "value",
                                                            label: "label",
                                                        },
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={4}>
                                                <Field
                                                    title="firstName"
                                                    label="First Name"
                                                    name="firstName"
                                                    value={initialValues.firstName}
                                                    id="firstName"
                                                    type="text"
                                                    component={FormikInputField}
                                                    placeholder="First Name"
                                                />
                                            </Col>
                                            <Col lg={3}>
                                                <Field
                                                    title="middleName"
                                                    label="Middle Name"
                                                    name="middleName"
                                                    value={initialValues.middleName}
                                                    id="middleName"
                                                    type="text"
                                                    component={FormikInputField}
                                                    placeholder="Middle Name"
                                                />
                                            </Col>
                                            <Col lg={3}>
                                                <Field
                                                    title="lastName"
                                                    label="Last Name"
                                                    name="lastName"
                                                    id="lastName"
                                                    value={initialValues.lastName}
                                                    type="text"
                                                    component={FormikInputField}
                                                    placeholder="Last Name"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4}>
                                                <Field
                                                    title="Abha Number"
                                                    label="Abha Number"
                                                    name="abhaNumber"
                                                    value={initialValues.abhaNumber}
                                                    id="abhaNumber"
                                                    type="text"
                                                    component={FormikInputField}
                                                    placeholder="Abha Number"
                                                />
                                            </Col>
                                            <Col lg={4}>
                                                <Field
                                                    id="dateOfBirth"
                                                    name="dateOfBirth"
                                                    type="text"
                                                    component={FormikDatePicker}
                                                    inputprops={{
                                                        dateFormat: "yyyy-MM-dd",
                                                        showYearDropdown: true,
                                                        showMonthDropdown: true,
                                                        scrollableYearDropdown: true,
                                                        yearDropdownItemNumber: 10,
                                                        maxDate: new Date(),
                                                        autoComplete: "off",
                                                    }}
                                                    label="Date of Birth"
                                                    placeholder="YYYY-MM-DD"
                                                />
                                            </Col>
                                            <Col lg={4}>
                                                <Field
                                                    id="gender"
                                                    name="gender"
                                                    type="text"
                                                    label="Gender"
                                                    // value={initialValues.gender}
                                                    component={FormikSelectField}
                                                    inputprops={{
                                                        name: `gender`,
                                                        options: genders,
                                                        defaultOption: "Select",
                                                        keys: {
                                                            id: "value",
                                                            label: "label",
                                                        },
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={12}>
                                                <div className="form-group">
                                                    <Field
                                                        id="languages"
                                                        name="languages"
                                                        label="Language Spoken"
                                                        className="language-suggest"
                                                        type="text"
                                                        component={CommonMultipleSelectTypeaheadField}
                                                        placeholder="Select Language"
                                                        spellCheck={false}
                                                        inputOptions={languagesList}
                                                        values={initialValues?.languages}
                                                        multiple={true}
                                                        autoComplete="languages"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </>
                            :
                            <Card>
                                <legend className='px-0'>Patient Information (Self)</legend>
                                <Row>
                                    <Col lg={3}>Full Name :</Col>
                                    <Col lg={9}>{initialValues?.title}{" "}{initialValues?.firstName}{" "}{initialValues?.middleName}{" "}{initialValues?.lastName}</Col>
                                </Row>
                                <Row>
                                    <Col lg={3}>Date of Birth :</Col>
                                    <Col lg={9}>{initialValues?.dateOfBirth}</Col>
                                </Row>
                                <Row>
                                    <Col lg={3}>Gender :</Col>
                                    <Col lg={9}>{initialValues?.gender}</Col>
                                </Row>
                                <Row>
                                    <Col lg={3}>Email Address :</Col>
                                    <Col lg={9}>{initialValues?.emailAddress}</Col>
                                </Row>
                                <Row>
                                    <Col lg={3}>Phone Number :</Col>
                                    <Col lg={9}>{initialValues?.phoneNumber}</Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <Link to={`/u/profile/${currentUser?.profileGuid}`} className='btn nexogic_primary_button'>Edit Profile</Link>
                                    </Col>
                                </Row>
                            </Card>
                        }
                        <Row>
                            <Col lg={12} className="text-center mb-3 mt-4 d-flex justify-content-end">
                                <button type="submit" className="btn nexogic_primary_button">
                                    Submit
                                </button>
                            </Col>
                        </Row>
                    </Form>
                </Formik>
            </div>
        </div>
    )
}

export default withAlert()(PersonalDetails);