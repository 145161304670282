import React, { useState } from 'react'
import { Button, ButtonGroup, Card, CardBody, CardText } from 'reactstrap';
import AppointmentPopup from '../components/AppointmentPopup';

export default function DayCalender() {

    /* for appointment popup */
    const [popvisible, setPopVisible] = useState(false);
    const [mousePosition, setMousePosition] = useState({
        x: 0,
        y: 0,
    });

    const handleAppointmentPops = (e) => {
       e.preventDefault();
        const  cur_elm = e.target.getBoundingClientRect();
        const screen_width = window.innerWidth;
        const popup_size = document.querySelectorAll('.nexogic-appointment-popup')[0].clientWidth;
        setPopVisible(true);

       if(screen_width < 992){
        setMousePosition({
        x: (screen_width / 2)  - (popup_size / 2),
        y: cur_elm.top ,
        });
       }else{
        setMousePosition({
            x: screen_width < (cur_elm.right + popup_size) ? (cur_elm.left -  popup_size) - 20 : cur_elm.right + 20,
            y: cur_elm.top,
        });
       }
        
    };


    const [date, setDate] = useState(new Date());
    const [view, setView] = useState('day');

    const handleNext = () => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        setDate(newDate);
    };

    const handlePrev = () => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() - 1);
        setDate(newDate);
    };
    return (
        <div className='row'>
            <div className="col-lg-3">
                <div className="card nexogic-widget-sidebar-card nexogic-widget-patient">
                    <div className="card-body">
                        <h5 className="card-title">Appointment for Patient</h5>
                        <div className="nexogic-quick-patient-details">
                            <div className="graphic nexogic-avatar">
                                <img src="https://images-dev.nexogic.com/53efb6fd-1ceb-412a-b19d-b65032bde722/a8dd998a-8e20-4ac4-971f-bf91154aa6fc.png" className="" alt="Patient's photo" />
                            </div>
                            <div className="info">
                                <p className="card-text"><span className='key'>Name</span> John Doe</p>
                                <p className="card-text"><span className='key'>Age</span> 35</p>
                                <p className="card-text"><span className='key'>Gender</span> Male</p>

                            </div>
                        </div>
                        <h5 className="card-title">Appointment Type : <span className='light'>Vartial</span></h5>
                    </div>
                </div>

                {/* filter  */}
                <div className="card nexogic-filter-list  type-bullets">
                    <div className="card-body p-0">
                        <ul className="list-group list-group-flush nexogic-aside-lists">
                            <li className="list-group-item">
                                <a href="#"><span className='nexogic-color-bullet lightgreen'></span> Pending (15)</a>
                            </li>
                            <li className="list-group-item">
                                <a href="#"><span className='nexogic-color-bullet green'></span> Accept (20)</a>
                            </li>
                            <li className="list-group-item">
                                <a href="#"><span className='nexogic-color-bullet red'></span> Reject (5)</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg-9">
                <Card className='nexogic-calender-week-navigate'>
                    <CardBody>
                        <CardText>Today</CardText>
                        <ButtonGroup className='nexogic-day-navigate'>
                            <Button onClick={handlePrev}><svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.5 1L2 9.5L10.5 18" stroke="#2D4552" strokeOpacity="0.71" strokeWidth="2" />
                            </svg>
                            </Button>
                            <Button onClick={handleNext}><svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 18L9.5 9.5L1 0.999999" stroke="#2D4552" strokeOpacity="0.71" strokeWidth="2" />
                            </svg>
                            </Button>
                        </ButtonGroup>
                        <CardText>{date.toLocaleDateString()}</CardText>
                        <ButtonGroup className='nexogic-day-week-switcher'>
                            <Button active={view === 'day'} onClick={() => setView('day')}>Day</Button>
                            <Button active={view === 'week'} onClick={() => setView('week')}>Week</Button>

                        </ButtonGroup>
                    </CardBody>
                </Card>

                <div className="row">
                    <div className="col-lg-3 d-flex">
                        <div className="card nexogic-day-selected-card">
                            <div className="card-header">
                                <h5 className="card-title">Sun <span className="big">26</span></h5>
                                <h6 className="card-subtitle mb-2 text-muted">15 Appointment booked</h6>
                            </div>
                            <div className="card-body p-0">
                                <ul className="list-group">
                                    <li className="list-group-item"><a href=""> Anima jana</a></li>
                                    <li className="list-group-item"><a href="">Niraj Bosh</a></li>
                                    <li className="list-group-item"><a href="">Anima jana</a></li>
                                    <li className="list-group-item"><a href="">Niraj Bosh</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="day-cal-tbl">
                            <div className="day-time-slots-h">
                                <div className="time-slot">
                                    <span className="small-label">Time</span>
                                    09:00AM</div>
                                <div className="time-slot">09:15AM</div>
                                <div className="time-slot">09:30AM</div>
                                <div className="time-slot">09:45AM</div>
                                <div className="time-slot">10:00AM</div>
                                <div className="time-slot">10:15AM</div>
                            </div>
                            <div className="day-time-slots">
                                <div className="time-slot"></div>
                                <div className="time-slot inactive"></div>
                                <div className="time-slot inactive"></div>
                                <div className="time-slot expand" style={{ "--item-expand-num": 3 }}>
                                    <div className='nexogic-appoint-block active' onClick={(e) => handleAppointmentPops(e)}>
                                        <span className="name">Anima jana</span>
                                        <span className="time"> 10:30AM - 10:45AM</span>
                                    </div>
                                </div>

                            </div>
                            <div className="day-time-slots">
                                <div className="time-slot expand" style={{ "--item-expand-num": 2 }}>
                                    <div className='nexogic-appoint-block pending' onClick={(e) => handleAppointmentPops(e)}>
                                        <span className="name">Anima jana</span>
                                        <span className="time"> 10:30AM - 10:45AM</span>
                                    </div>
                                </div>
                                <div className="time-slot"></div>
                                <div className="time-slot "></div>
                                <div className="time-slot "></div>
                                <div className="time-slot "></div>


                            </div>
                            <div className="day-time-slots">
                                
                                <div className="time-slot"></div>
                                <div className="time-slot"></div>
                                <div className="time-slot"></div>
                                <div className="time-slot "></div>
                                <div className="time-slot "></div>
                                <div className="time-slot "></div>


                            </div>
                            <div className="day-time-slots">
                                
                                <div className="time-slot"></div>
                                <div className="time-slot inactive"></div>
                                <div className="time-slot"></div>
                                <div className="time-slot "></div>
                                <div className="time-slot "></div>
                                <div className="time-slot inactive"></div>


                            </div>
                            <div className="day-time-slots">
                                
                                <div className="time-slot"></div>
                                <div className="time-slot inactive"></div>
                                <div className="time-slot inactive"></div>
                                <div className="time-slot "></div>
                                <div className="time-slot "></div>
                                <div className="time-slot "></div>


                            </div>
                        </div>
                    </div>
                </div>

                <AppointmentPopup popvisible={popvisible}  mousePosition={mousePosition} closePopup={()=>setPopVisible(false)} />
            </div>
        </div>
    )
}
