import { Modal, ModalBody, ModalHeader } from "reactstrap";
import MedicalHistoryForm1 from "../MedicalHistoryForm1";

const MedicalHistoryForm1Modal = (props) => {
    const { modal, setModal } = props;

    return (
        <Modal isOpen={modal} toggle={() => setModal(!modal)} className="nex-model-v2 modal-dialog-scrollable modal-dialog-centered">
            <ModalHeader toggle={() => setModal(!modal)}>Edit Medical History</ModalHeader>
            <ModalBody>
                <MedicalHistoryForm1 />
            </ModalBody>
        </Modal>
    )
}

export default MedicalHistoryForm1Modal;