import React, { useState } from 'react';
import { faEnvelope, faRupeeSign, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import edit_icon from 'assets/images/svgs/small-edit-btn.svg';
import JobHospitalEditModal from './EditModals/JobHospitalEditModal';
import JobPositionEditModal from './EditModals/JobPositionEditModal';
import JobNoOfPositionsEditModal from './EditModals/JobNoOfPositionsEditModal';
import JobTitleEditModal from './EditModals/JobTitleEditModal';
import JobTypeEditModal from './EditModals/JobTypeEditModal';
import JobRoleEditModal from './EditModals/JobRoleEditModal';
import JobPrimarySpecialityEditModal from './EditModals/JobPrimarySpecialityEditModal';
import JobDescriptionEditModal from './EditModals/JobDescriptionEditModal';
import JobWorkShiftEditModal from './EditModals/JobWorkShiftEditModal';
import JobSkillsEditModal from './EditModals/JobSkillsEditModal';
import JobQualificationEditModal from './EditModals/JobQualificationEditModal';
import JobCityAndLocationEditModal from './EditModals/JobCityAndLocationEditModal';
import JobApplyModeAndLinkEditModal from './EditModals/JobApplyModeAndLinkEditModal';
import { useEffect } from 'react';
import { InstitutionServices } from 'utils/InstitutionServices';
import { showErrorMessage } from 'common/AlertContainer';
import { FAILED_TO_FETCH_DATA, Opportunity_Type } from 'utils/Constants';
import { convertJobShiftToTitleCase, getFullAddress, getFullAddressWithLocationCityAndState } from 'utils/Utils';
import JobScreeningQuestionsEditModal from './EditModals/JobScreeningQuestionsEditModal';
import { Col, Row } from 'reactstrap';
import JobExperienceEditModal from './EditModals/JobExperienceEditModal';
import JobSalaryEditModal from './EditModals/JobSalaryEditModal';
// import JobScreeningQuestionEditModal from './EditModals/JobScreeningQuestionEditModal';

function JobCreatePreview(props) {
    const { values } = props;
    const [institutionsInfo, setInstitutionsInfo] = useState({});

    // useEffect(() => {
    //     console.log(values);
    // }, [values])


    const getInstitutionInfo = (id) => {
        InstitutionServices.getInstitutionById(id)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setInstitutionsInfo(data);
                } else {
                    showErrorMessage(FAILED_TO_FETCH_DATA);
                }
            })
    }

    useEffect(() => {
        getInstitutionInfo(values?.institutionDetail);
    }, [values?.institutionDetail])

    const getLocation = (location) => {
        if (location && location[0]?.customOption && location[0]?.value != "") {
            // setFieldValue("location", location[0]?.value.split(','));
            return (location[0]?.value.split(','));
        } else if (location && location.length > 0 && location[0] != "") {
            return location;
        } else {
            return null;
        }
    }

    const [openJobHospitalEditModal, setOpenJobHospitalEditModal] = useState(false);
    const [openJobPositionEditModal, setOpenJobPositionEditModal] = useState(false);
    const [openJobNoOfPositionsEditModal, setOpenJobNoOfPositionsEditModal] = useState(false);
    const [openJobTitleEditModal, setOpenJobTitleEditModal] = useState(false);
    const [openJobTypeEditModal, setOpenJobTypeEditModal] = useState(false);
    const [openJobExperienceEditModal, setOpenJobExperienceEditModal] = useState(false);
    const [openJobSalaryEditModal, setOpenJobSalaryEditModal] = useState(false);
    const [openJobRoleEditModal, setOpenJobRoleEditModal] = useState(false);
    const [openJobPrimarySpecialityEditModal, setOpenJobPrimarySpecialityEditModal] = useState(false);
    const [openJobDescriptionEditModal, setOpenJobDescriptionEditModal] = useState(false);
    const [openJobWorkShiftEditModal, setOpenJobWorkShiftEditModal] = useState(false);
    const [openJobSkillsEditModal, setOpenJobSkillsEditModal] = useState(false);
    const [openJobQualificationEditModal, setOpenJobQualificationEditModal] = useState(false);
    const [openJobCityAndLocationEditModal, setOpenJobCityAndLocationEditModal] = useState(false);
    const [openJobApplyModeAndLinkEditModal, setOpenJobApplyModeAndLinkEditModal] = useState(false);
    const [openJobScreeningQuestionEditModal, setOpenJobScreeningQuestionEditModal] = useState(false);

    const ShowSalary = ({ min, max }) => {
        if ((!min && !max) || min === '0' && max === '0')
            return (
                <p>Negotiable</p>
            )
        else if (min && !max) {
            return (
                <p>
                    <span>
                        <FontAwesomeIcon
                            icon={faRupeeSign}
                            className="ml-2 mr-1 text-gray f-13"
                        />{min || 0}
                    </span>
                </p>
            );
        }
        else if (!min && max) {
            return (
                <p>
                    <span>
                        <FontAwesomeIcon
                            icon={faRupeeSign}
                            className="ml-2 mr-1 text-gray f-13"
                        />
                        {max || 0}
                    </span>
                </p>
            );
        }
        else {
            return (
                <p>
                    <span>
                        <FontAwesomeIcon
                            icon={faRupeeSign}
                            className="ml-2 mr-1 text-gray f-13"
                        />
                        {min || 0} -{" "}
                        <FontAwesomeIcon
                            icon={faRupeeSign}
                            className="ml-2 mr-1 text-gray f-13"
                        />
                        {max || 0}
                    </span>
                </p>
            );
        }
    }

    return (
        <>
            <div className="nex-job-flex">
                <div className="nex-job-container">
                    <div className="nex-job-preview-card">
                        <div className="nex-title-card">
                            <h1 className='primary-title'>Job Details</h1>
                        </div>
                        <div className='nex-job-preview-content'>
                            <Row>
                                <Col lg={6}>
                                    <div className="nex-item">
                                        <div className="nex-info">
                                            <div className="card-title">Hospital/Clinic</div>
                                            <div className="card-text">{institutionsInfo.name}{", "}
                                                {getFullAddressWithLocationCityAndState({ location: institutionsInfo?.address?.location ?? "", city: institutionsInfo?.address?.city ?? "", state: institutionsInfo?.address?.state ?? "" })}
                                            </div>
                                        </div>

                                        <div className="btn nex-floated-btn" onClick={() => setOpenJobHospitalEditModal(true)}>
                                            <img src={edit_icon} alt="" />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="nex-item">
                                        <div className="nex-info">
                                            <div className="card-title">Job Title</div>
                                            <div className="card-text">{values?.title}</div>
                                        </div>
                                        <div className="btn nex-floated-btn" onClick={() => setOpenJobTitleEditModal(true)}>
                                            <img src={edit_icon} alt="" />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="nex-item">
                                        <div className="nex-info">
                                            <div className="card-title">Position</div>
                                            <div className="card-text">{convertJobShiftToTitleCase(values?.position)}</div>
                                        </div>

                                        <div className="btn nex-floated-btn" onClick={() => setOpenJobPositionEditModal(true)}>
                                            <img src={edit_icon} alt="" />
                                        </div>
                                    </div>
                                </Col>

                                <Col lg={6}>
                                    <div className="nex-item">
                                        <div className="nex-info">
                                            <div className="card-title">Type</div>
                                            <div className="card-text">{values?.opportunity}</div>
                                        </div>
                                        <div className="btn nex-floated-btn" onClick={() => setOpenJobTypeEditModal(true)}>
                                            <img src={edit_icon} alt="" />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="nex-item">
                                        <div className="nex-info">
                                            <div className="card-title">Experience</div>
                                            <div className="card-text">{values?.minExperience}-{values?.maxExperience} years</div>
                                        </div>
                                        <div className="btn nex-floated-btn" onClick={() => setOpenJobExperienceEditModal(true)}>
                                            <img src={edit_icon} alt="" />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="nex-item">
                                        <div className="nex-info">
                                            <div className="card-title">Salary</div>
                                            <div className="card-text"><ShowSalary min={values?.minSalary} max={values?.maxSalary} /></div>
                                        </div>
                                        <div className="btn nex-floated-btn" onClick={() => setOpenJobSalaryEditModal(true)}>
                                            <img src={edit_icon} alt="" />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="nex-item">
                                        <div className="nex-info">
                                            <div className="card-title">Role</div>
                                            <div className="card-text">{values?.role}</div>
                                        </div>
                                        <div className="btn nex-floated-btn" onClick={() => setOpenJobRoleEditModal(true)}>
                                            <img src={edit_icon} alt="" />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="nex-item">
                                        <div className="nex-info">
                                            <div className="card-title">Number of Positions</div>
                                            <div className="card-text">{values?.noOfPosition}</div>
                                        </div>
                                        <div className="btn nex-floated-btn" onClick={() => setOpenJobNoOfPositionsEditModal(true)}>
                                            <img src={edit_icon} alt="" />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="nex-item">
                                        <div className="nex-info">
                                            <div className="card-title">Primary Speciality</div>
                                            <div className="card-text">{values?.primarySpeciality.join(', ')}</div>
                                        </div>
                                        <div className="btn nex-floated-btn" onClick={() => setOpenJobPrimarySpecialityEditModal(true)}>
                                            <img src={edit_icon} alt="" />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="nex-item">
                                        <div className="nex-info w-full">
                                            <div className="card-title">Description</div>
                                            <div className="card-text">
                                                <div dangerouslySetInnerHTML={{ __html: values?.description }}></div>
                                            </div>
                                        </div>
                                        <div className="btn nex-floated-btn" onClick={() => setOpenJobDescriptionEditModal(true)}>
                                            <img src={edit_icon} alt="" />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="nex-item">
                                        <div className="nex-info w-full">
                                            <div className="card-title">Work Shift</div>
                                            <div className="card-text">{convertJobShiftToTitleCase(values?.shift)}</div>
                                        </div>
                                        <div className="btn nex-floated-btn" onClick={() => setOpenJobWorkShiftEditModal(true)}>
                                            <img src={edit_icon} alt="" />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="nex-item">
                                        <div className="nex-info w-full">
                                            <div className="card-title">Required Skills</div>
                                            <div className="nex-pill-group">
                                                {values?.skills.map((e, index) => (
                                                    <span className="nex-pill nex-pill-primary" key={index}>{e}</span>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="btn nex-floated-btn" onClick={() => setOpenJobSkillsEditModal(true)}>
                                            <img src={edit_icon} alt="" />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="nex-item">
                                        <div className="nex-info w-full">
                                            <div className="card-title">Required Qualification</div>
                                            <div className="nex-pill-group">
                                                {values?.qualifications.map((e, index) => (
                                                    <span className="nex-pill nex-pill-primary" key={index}>{e}</span>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="btn nex-floated-btn" onClick={() => setOpenJobQualificationEditModal(true)}>
                                            <img src={edit_icon} alt="" />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="nex-item">
                                        <div className="nex-info w-full">
                                            <div className="card-title">City & Location</div>
                                            <div className="card-text">{values?.city[0] ? values?.city[0].value : ""}{", "}{getLocation(values?.location)}</div>
                                        </div>

                                        <div className="btn nex-floated-btn" onClick={() => setOpenJobCityAndLocationEditModal(true)}>
                                            <img src={edit_icon} alt="" />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="nex-item">
                                        <div className="nex-info">
                                            <div className="card-title">Apply Mode & Link</div>
                                            <div className="card-text">{values?.applyMode}{":- "}{values?.linkToApply}</div>
                                        </div>
                                        <div className="btn nex-floated-btn" onClick={() => setOpenJobApplyModeAndLinkEditModal(true)}>
                                            <img src={edit_icon} alt="" />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="nex-item">
                                        <div className="nex-info w-full">
                                            <div className="card-title">Screening Question</div>
                                            {values?.screeningQuestions && values?.screeningQuestions.map((e) => (
                                                <>
                                                    <div className="card-text">{e.question}</div>
                                                    <div className="card-text bold">{"Answer Type"}{": "}{e.renderType}</div>
                                                </>
                                            ))}
                                        </div>
                                        <div className="btn nex-floated-btn" onClick={() => setOpenJobScreeningQuestionEditModal(true)}>
                                            <img src={edit_icon} alt="" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                            </Row>
                        </div>

                        <JobHospitalEditModal modal={openJobHospitalEditModal} setModal={setOpenJobHospitalEditModal} />
                        <JobPositionEditModal modal={openJobPositionEditModal} setModal={setOpenJobPositionEditModal} />
                        <JobNoOfPositionsEditModal modal={openJobNoOfPositionsEditModal} setModal={setOpenJobNoOfPositionsEditModal} />
                        <JobTitleEditModal modal={openJobTitleEditModal} setModal={setOpenJobTitleEditModal} />
                        <JobTypeEditModal modal={openJobTypeEditModal} setModal={setOpenJobTypeEditModal} />
                        <JobExperienceEditModal modal={openJobExperienceEditModal} setModal={setOpenJobExperienceEditModal} />
                        <JobSalaryEditModal modal={openJobSalaryEditModal} setModal={setOpenJobSalaryEditModal} />
                        <JobRoleEditModal modal={openJobRoleEditModal} setModal={setOpenJobRoleEditModal} />
                        <JobPrimarySpecialityEditModal modal={openJobPrimarySpecialityEditModal} setModal={setOpenJobPrimarySpecialityEditModal} />
                        <JobDescriptionEditModal modal={openJobDescriptionEditModal} setModal={setOpenJobDescriptionEditModal} />
                        <JobWorkShiftEditModal modal={openJobWorkShiftEditModal} setModal={setOpenJobWorkShiftEditModal} />
                        <JobSkillsEditModal modal={openJobSkillsEditModal} setModal={setOpenJobSkillsEditModal} />
                        <JobQualificationEditModal modal={openJobQualificationEditModal} setModal={setOpenJobQualificationEditModal} />
                        <JobCityAndLocationEditModal modal={openJobCityAndLocationEditModal} setModal={setOpenJobCityAndLocationEditModal} />
                        <JobApplyModeAndLinkEditModal modal={openJobApplyModeAndLinkEditModal} setModal={setOpenJobApplyModeAndLinkEditModal} />
                        <JobScreeningQuestionsEditModal modal={openJobScreeningQuestionEditModal} setModal={setOpenJobScreeningQuestionEditModal} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default JobCreatePreview;
