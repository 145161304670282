import { memo } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Card, CardBody, CardHeader, Spinner } from "reactstrap";
import { capitalizeFirstLetter, createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor } from "utils/Utils";
import './style.css'
import { encrypt } from "utils/EncryptDecrypt";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
};

const OtherLocationSlider = (props) => {
  const {
    items,
    loading,
    winwidth
  } = props;

  return loading ? (
    <div className="d-flex justify-content-center mt-3">
      <div className="loading-spiner">
        <Spinner style={{ width: "2rem", height: "2rem" }} />
      </div>
    </div>
  ) : (
    items.length > 0 && (
      <div className="nex-icon-lists-w location-slider-w">
        {winwidth > 500 ?
        <Slider style={{ width: "100%" }} slidesToShow={1} slidesToScroll={1} {...settings}>
          {items.filter(location => location.profileId !== props.profileId).map((location, index) => (
            <div className="nex-icon-list" key={index}>
            <div className="nex-graphic">
              {/* <img src="" alt="" className="nex-avatar md" /> */}
              <img className="nex-avatar md" src={createImageFromInitials(50,location?.name.replace("Dr. ", ""),getRandomColor(encrypt(location?.profileId).toString()))} alt="profilepic" />
            </div>
           <div className="content-lg text-left">
             <Link to={`/institution/${location?.profileId}`} target="_blank" className="sm-title text-decoration-none"><h3 className="mb-0" style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',margin: '0'}}>{location?.name}</h3></Link>
             <div className="nex-text text-black text-truncate text-capitalize">{capitalizeFirstLetter(location?.type.replaceAll("_", " ").toLowerCase())}</div>
             <p className="nexogic-14-px mb-0 text-truncate" title={getFullAddressWithLocationCityAndState({ location: location?.location ?? "", city: location?.city ?? "" })}>{getFullAddressWithLocationCityAndState({ location: location?.location ?? "", city: location?.city ?? "" })}</p>
             <p className="nexogic-14-px mb-1">{getFullAddressWithLocationCityAndState({ state: location?.state ?? "" })}</p>
             {/* <p>
               <Link to={`/institution/${location?.profileId}`} target="_blank" className="font-weight-normal"> View Details </Link>
             </p> */}
           </div>
            {/* <div className="info">
              <div className="nex-text-xl small text-truncate"><a href={`./${location.profileId}`} target="_blank"> {location?.name}</a></div>
              <div className="nex-text text-black text-truncate text-capitalize">{capitalizeFirstLetter(location?.type.replaceAll("_", " ").toLowerCase())}</div>
              <div className="nex-text text-black text-truncate">{getFullAddressWithLocationCityAndState(
                { location: location?.location ?? "", city: location?.city ?? "", state: location?.state ?? ""}
              )}</div>
            </div> */}
          </div>
            // <div className="slide m-2" key={index} style={{width:'300px'}}>
            //   <div className="d-flex other-loc-slider-content">
            //     <div className="map-thumbnail align-self-start">
            //       <img src={location.avatarId ? location?.imageSource : createImageFromInitials(132, location?.name, getRandomColor(encrypt(location?.guid).toString()))} alt="profile pic" />
            //     </div>
            //     <div className="content-lg text-left">
            //       <Link to={`/institution/${location?.profileId}`} target="_blank" className="sm-title text-decoration-none"><h3 className="mb-0" style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',margin: '0'}}>{location?.name}</h3></Link>
            //       <p className="nexogic-14-px mb-0 text-truncate" title={getFullAddressWithLocationCityAndState({ location: location?.location ?? "", city: location?.city ?? "" })}>{getFullAddressWithLocationCityAndState({ location: location?.location ?? "", city: location?.city ?? "" })}</p>
            //       <p className="nexogic-14-px mb-1">{getFullAddressWithLocationCityAndState({ state: location?.state ?? "" })}</p>
            //       <p>
            //         <Link to={`/institution/${location?.profileId}`} target="_blank" className="font-weight-normal"> View Details </Link>
            //       </p>
            //     </div>
            //   </div>
            // </div>
          ))}
        </Slider>
        :
        <>
            <div className="nexogic-location-slider nexogic-custom-scroll p-1">
            {items.filter(otherinst => otherinst.profileId !== props.profileId).map((otherinst, idx) => (
                  <div className="slide m-2" key={idx} style={{width:'300px'}}>
                  <div className="d-flex other-loc-slider-content">
                    <div className="map-thumbnail align-self-start">
                      <img src={otherinst.avatarId ? otherinst?.imageSource : createImageFromInitials(132, otherinst?.name, getRandomColor(encrypt(otherinst?.guid).toString()))} alt="profile pic" />
                    </div>
                    <div className="content-lg text-left">
                      <Link to={`/institution/${otherinst?.profileId}`} target="_blank" className="sm-title text-decoration-none"><h3 className="mb-0" style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',margin: '0'}}>{otherinst?.name}</h3></Link>
                      <p className="nexogic-14-px mb-0 text-truncate" title={getFullAddressWithLocationCityAndState({ location: otherinst?.location ?? "", city: otherinst?.city ?? "" })}>{getFullAddressWithLocationCityAndState({ location: otherinst?.location ?? "", city: otherinst?.city ?? "" })}</p>
                      <p className="nexogic-14-px mb-1">{getFullAddressWithLocationCityAndState({ state: otherinst?.state ?? "" })}</p>
                      <p>
                        <Link to={`/institution/${otherinst?.profileId}`} target="_blank" className="font-weight-normal"> View Details </Link>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
              </div>
              </>
        }
      </div>
    )
  );
};

OtherLocationSlider.defaultProps = {
  items: [],
  loading: false,
};

export default memo(OtherLocationSlider);
