import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faHospital } from "@fortawesome/free-solid-svg-icons";
import { faUserMd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import filterIcon from "assets/images/svgs/filter_icon.svg";
import hospital_icon from "assets/images/svgs/hospital-icon.svg";
import doctor_icon from "assets/images/svgs/doctor-icon.svg";
import AsyncGlobalSearchInput from "components/forms/formikFields/AsyncGlobalSearchInput";
import React, { useCallback, useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { setGlobalSearchType } from "redux-helpers/ActionCreator";
import {
  Button,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from "reactstrap";

const Search = (props) => {  
  const dispatch = useDispatch();
  const globalSearchTypek = useSelector(
    (state) => state.GlobalSearchTypeReducer.globalSearchType
  );
  const [searchType, setSearchType] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState();
  const typeaheadRef = useRef(null);
  let iii=1;
  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleResponsiveModal = () => {
    if (props.responsiveToggle) {
      props.responsiveToggleFunc();
    }
  };
  const search_drops = useRef();
  const [search_drop_down, setSearch_drop_down] = useState(false);   

  function change_search_drops(val){
    search_drops.current.value = val;  
    search_drops.current.dispatchEvent(new Event('change', { bubbles: true}));
    setSearch_drop_down(false);
  }

  const handleDropDownChange = useCallback((event) => {
    dispatch(setGlobalSearchType(event.target.value));    
    typeaheadRef.current?.focus();
    typeaheadRef.current?.clear();
    // event.target.value=globalSearchTypek;
  },[dispatch]);

   

  


  return (
    <>
      <InputGroup className="nexogic-header-search">
        <InputGroupAddon addonType="append">
          <Button color="link" className="py-0 min-w-0" id="nexogic_btn_search">
            <FontAwesomeIcon icon={faSearch} className="text-dark" />
          </Button>
        </InputGroupAddon>
        <div className="search-dropdown">
          <select onChange={handleDropDownChange}
          value={globalSearchTypek}
          ref={search_drops}
          >
          {/* <select onChange={(event) => {
            typeaheadRef.current?.focus();
            setSearchType(event.target.value);
            typeaheadRef.current?.clear();
            // handleDropDownChange(event);
          }}
          value={searchType}
          > */}
            <option value="Practitioners"> Practitioners</option>
            <option value="Institutions">Hospitals / Clinics</option>
          </select>
          <div className={`nexogic-custom-select ${search_drop_down ? 'on' : ''}`}>
              <div className="selected-label small-down-arrow" onClick={()=>setSearch_drop_down(!search_drop_down)}>
              {globalSearchTypek == "Practitioners" && <><span className="icon-w doctor-icon d-none"><img src={doctor_icon} alt="Practitioners Icon" /></span><span>Practitioners</span></>}
              {globalSearchTypek == "Institutions" && <><span className="icon-w d-none"><img src={hospital_icon} alt="Institutions Icon" /></span><span>Hospitals</span></>}
              </div>
              <ul>
                {globalSearchTypek != "Practitioners" && 
                  <li onClick={()=>change_search_drops('Practitioners')}><span className="icon-w doctor-icon d-none"><img src={doctor_icon} alt="Institutions Icon" /></span> <span>Practitioners</span></li>
                }
                {globalSearchTypek != "Institutions" && 
                  <li onClick={()=>change_search_drops('Institutions')}><span className="icon-w d-none"><img src={hospital_icon} alt="Practitioners Icon" /></span> <span>Hospitals</span></li>
                }
              </ul>
          </div>
          

        </div>
        <AsyncGlobalSearchInput
          id="nexogic-global-search-input"
          type="search"
          placeholder="Search..."
          className="border-0"
          handleResponsiveModal={handleResponsiveModal}
          searchType={globalSearchTypek}
          typeaheadRef={typeaheadRef}
        />
        {/* {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
          <ButtonDropdown
            className="ml-3"
            isOpen={dropdownOpen}
            toggle={toggle}
          >
            <DropdownToggle color="link" className="min-w-0 p-0">
              <img src={filterIcon} alt="" />
            </DropdownToggle>
            <DropdownMenu className="filter-menu p-3">
              <FormGroup check>
                <Label check>
                  <Input
                    className="search-type"
                    type="radio"
                    name="searchType"
                    value="PRACTITIONERS"
                    checked={searchType === "PRACTITIONERS"}
                    onChange={() => handleRadioChange("PRACTITIONERS")}
                  />{" "}
                  <span className="black-color-accent f-14 line-h-14">
                    Practitioner
                  </span>
                </Label>
              </FormGroup>
              <FormGroup className="mt-1" check>
                <Label check>
                  <Input
                    className="search-type"
                    type="radio"
                    name="searchType"
                    value="INSTITUTIONS"
                    checked={searchType === "INSTITUTIONS"}
                    onChange={() => handleRadioChange("INSTITUTIONS")}
                  />{" "}
                  <span className="black-color-accent f-14 line-h-14">
                    Institutions
                  </span>
                </Label>
              </FormGroup>
            </DropdownMenu>
          </ButtonDropdown>
        )} */}
      </InputGroup>
    </>
  );
};

export default connect(null,null)(Search);
