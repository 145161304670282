import React, { useState, useRef } from "react"
import { Row, Col, Button, Spinner, FormGroup, Label } from 'reactstrap'
import { Field, Formik } from 'formik';
import { withAlert } from "react-alert"
import FormikInputField from "components/forms/formikFields/FormikInputField"
import * as Yup from 'yup';
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { phoneRegExp, emailNonNumericRegEx } from 'utils/Constants'

import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage"
import dropdownIcon from 'assets/images/dropdown_icon.png'
import tickCircleIcon from 'assets/images/svgs/tick_circle_icon.svg';
import 'assets/styles/typeahead.css' 

const validationSchema = Yup.object().shape({
    firstName: Yup.string().min(3).max(30).required("This field is required"),
    lastName: Yup.string().min(3).max(30).required("This field is required"),
    email: Yup.string().max(100).matches(emailNonNumericRegEx, 'Please Enter an valid Email').required("This field is required"),
    phoneno: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("This field is required"),
    topic: Yup.string().required("This field is required"),
    describeQuestion: Yup.string().min(3).max(255).required("This field is required"),
});

const RenderForm = (props) => {
    const { values, toggle, isValid, dirty, handleSubmit, isLoading, isSubmittedSuccess } = props;
    const topicsRef = useRef(null);
    const [topic, setTopic] = useState('');
    const [topicsData, setTopicsData] = useState(['Account settings', 'Integrations', 'Password', 'Security']);

    return (
        <>
            {
                !isSubmittedSuccess ? 
                <form className="my-form" onSubmit={handleSubmit}>
                    <div>
                        <Row>
                            <Col md={6}>
                                <Field
                                    id="csf_first_name"
                                    name="firstName"
                                    type="text"
                                    label="First Name"
                                    placeholder="First Name"
                                    component={FormikInputField}
                                />
                            </Col>
                            <Col md={6}>
                                <Field
                                    id="csf_last_name"
                                    name="lastName"
                                    type="text"
                                    label="Last Name"
                                    placeholder="Last Name"
                                    component={FormikInputField}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Field
                                    id="csf_email"
                                    name="email"
                                    type="text"
                                    label="Email"
                                    placeholder="Email"
                                    component={FormikInputField}
                                />
                            </Col>
                            <Col md={6}>
                                <Field
                                    id="csf_phoneno"
                                    name="phoneno"
                                    type="text"
                                    label="Phone Number"
                                    placeholder="Phone Number"
                                    component={FormikInputField}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col xs={12}>
                                <FormGroup className="searchbox-input-chips mb-1">
                                    <Label>Topic</Label>
                                    <Typeahead
                                        ref={topicsRef}
                                        // allowNew
                                        clearButton
                                        newSelectionPrefix="Topic"
                                        selected={topic}
                                        id="topic"
                                        options={topicsData}
                                        placeholder="Choose topic"
                                        onBlur={() => {return topicsRef !== null? topicsRef.current.hideMenu(): null}}
                                        onChange={(selected) => { 
                            
                                            setTopic(selected);
                                            
                                        }}
                                        renderMenu={(results, menuProps) => {
                                            if (results && results.length) {
                                            return (
                                                    <Menu {...menuProps}>
                                                        <>  
                                                            <div className="d-flex align-items-center">
                                                                <div className="black-color-accent f-14 line-h-14">Topic</div>
                                                            </div>    
                                                            {
                                                                results.map((result, index) => (
                                                                    <MenuItem key={index} option={result} position={index} className="no-wrap">
                                                                        <span>{result}</span>
                                                                    </MenuItem>
                                                                ))
                        
                                                            }
                                                        </>
                                                    </Menu>
                                                )
                                            }
                        
                                            return null
                        
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Field
                                    id="csf_describeQuestion"
                                    name="describeQuestion"
                                    type="textarea"
                                    label="Describe your question"
                                    component={FormikInputField}
                                    rows={4}
                                    cols={5}
                                    placeholder="How can we help you?"
                                />
                            </Col>
                        </Row>
                        <hr className="profile-info-modal-hr" />
                        <Row className="modal-save-main justify-content-center mt-4 mb-2">
                            <Col xs="auto">
                                {isLoading ? 
                                    <div className="loading-spiner"><Spinner style={{ width: '2rem', height: '2rem' }} /></div>
                                    :
                                    <Button id="csf_submit"  className="modal-save-btn" disabled={!isValid || !dirty} color="primary" type="submit">Submit request</Button>
                                }
                            </Col>
                        </Row>
                    </div>
                </form> :
                <div className="success-text position-relative text-center">
                    <img src={tickCircleIcon} />
                    <div className="mt-4 text-complimentary f-14 line-h-20 font-weight-500">Your question was submitted successfully. Our Customer Support will get back to you within 3 working days. Thank you!</div>
                </div>
            }
        </>    
    )
}

const CustomerSupportForm = (props) => {
    let initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phoneno: '',
        topic: '',
        describeQuestion: '',
    }

    const { selectedItem, practitionerId, getData, toggle, alert } = props
    const Url = "/practitioners/" + practitionerId + "/clinical-trials/"
    const [isLoading, setLoading] = useState(false)
    const [errorMsg, setErrorMessage] = useState("");
    const [isSubmittedSuccess, SetIsSubmittedSuccess] = useState(false);

    return (
        <>
            { errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} /> }
            <Formik
                enableReinitialize={true}
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(values) => {
                    if (values.id) {

                    } else {
                        SetIsSubmittedSuccess(true); //for now set true after submit
                    }
                }}

                children={(props) => {
                    return <RenderForm toggle={toggle} isSubmittedSuccess={isSubmittedSuccess} {...props} isLoading={isLoading} />
                }}
            >
            </Formik>
        </>
    )

}

export default withAlert()(CustomerSupportForm);