import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FAILTOSAVERECOED } from 'utils/Constants';
import { PatientService } from 'utils/PatientService';
import RenderEditProfileForm from './RenderEditProfileForm';

export default function Personal() {
  const [isLoading, setIsLoading] = useState(true);
  const [patientProfile, setPatientProfile] = useState(null);
  const currentUser = useSelector((state) => state.auth.currentUser);

  const getPatientProfile = (id) => {
    PatientService.getPatientById(id)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          setPatientProfile(response.data);
        }
        else {
          console.log(FAILTOSAVERECOED);
          setIsLoading(false);
        }
      })
  }

  useEffect(() => {
    getPatientProfile(currentUser.profileId)
  }, [currentUser.profileId])


  return (
    <>
      {patientProfile &&
        <RenderEditProfileForm patientProfile={patientProfile} isLoading={isLoading} setIsLoading={setIsLoading} />
      }
    </>
  )
}
