import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody } from "reactstrap";

const SymptomsForm = (props) => {
    const [selectedSymptoms, setSelectedSymptoms] = useState([]);
    const [customSymptom, setCustomSymptom] = useState('');
    const [symptomsOptions, setSymptomsOptions] = useState([]);
    const { values, setFieldValue } = useFormikContext();

    const predefinedSymptoms = [
        { "label": "Fever", "value": "Fever" },
        { "label": "Cough", "value": "Cough" },
        { "label": "Shortness of breath", "value": "Shortness of breath" },
        { "label": "Fatigue", "value": "Fatigue" },
        { "label": "Headache", "value": "Headache" },
        { "label": "Muscle aches", "value": "Muscle aches" },
        { "label": "Joint pain", "value": "Joint pain" },
        { "label": "Nausea", "value": "Nausea" },
        { "label": "Vomiting", "value": "Vomiting" },
        { "label": "Diarrhea", "value": "Diarrhea" },
        { "label": "Chest pain", "value": "Chest pain" },
        { "label": "Abdominal pain", "value": "Abdominal pain" },
        { "label": "Dizziness", "value": "Dizziness" },
        { "label": "Rash", "value": "Rash" },
        { "label": "Swelling", "value": "Swelling" },
        { "label": "Difficulty swallowing", "value": "Difficulty swallowing" },
        { "label": "Vision changes", "value": "Vision changes" },
        { "label": "Bleeding", "value": "Bleeding" },
        { "label": "Bruising", "value": "Bruising" },
        { "label": "Memory loss", "value": "Memory loss" },
        { "label": "Anxiety", "value": "Anxiety" },
        { "label": "Depression", "value": "Depression" },
        { "label": "Sleep disturbances", "value": "Sleep disturbances" },
        { "label": "Chest tightness", "value": "Chest tightness" },
        { "label": "Sore throat", "value": "Sore throat" },
        { "label": "Nasal congestion", "value": "Nasal congestion" },
        { "label": "Watery eyes", "value": "Watery eyes" },
        { "label": "Sneezing", "value": "Sneezing" },
        { "label": "Difficulty breathing", "value": "Difficulty breathing" },
        { "label": "Wheezing", "value": "Wheezing" },
        { "label": "Loss of taste or smell", "value": "Loss of taste or smell" },
        { "label": "Chest pressure", "value": "Chest pressure" },
        { "label": "Heart palpitations", "value": "Heart palpitations" },
        { "label": "Difficulty concentrating", "value": "Difficulty concentrating" },
        { "label": "Speech difficulties", "value": "Speech difficulties" },
        { "label": "Tremors", "value": "Tremors" },
        { "label": "Balance problems", "value": "Balance problems" },
        { "label": "Muscle weakness", "value": "Muscle weakness" },
    ];

    useEffect(() => {
        // Initialize the options with predefined symptoms plus any existing values in Formik
        setSymptomsOptions([...predefinedSymptoms, ...values.symptoms.map(symptom => ({ label: symptom, value: symptom }))]);
        setSelectedSymptoms(values.symptoms || []);
    }, [values.symptoms]);

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        setSelectedSymptoms((prev) => {
            if (prev.includes(value)) {
                return prev.filter((symptom) => symptom !== value);
            } else {
                return [...prev, value];
            }
        });
    };

    const handleCustomSymptomChange = (e) => {
        setCustomSymptom(e.target.value);
    };

    const addCustomSymptom = () => {
        if (customSymptom.trim() !== '' && !symptomsOptions.some(option => option.value === customSymptom.trim())) {
            const newSymptom = { label: customSymptom.trim(), value: customSymptom.trim() };
            setSymptomsOptions((prev) => [...prev, newSymptom]);
            setSelectedSymptoms((prev) => [...prev, customSymptom.trim()]);
            setCustomSymptom(''); // Clear the input field
        }
    };

    const handleClose = () => {
        setFieldValue('symptoms', selectedSymptoms);
        props.toggle(); // Close the modal
    };

    return (
        <>
            {props.modal &&
                <Card className="nex-full-covered-card">
                    <CardBody>
                        <h2 className="nex-text-xl mb-3">Please specify your Symptoms</h2>
                        <div className="nex-checkbox-lists nexogic-custom-scroll">
                            <ul>
                                {symptomsOptions.map((item) => (
                                    <li key={item.value}>
                                        <div className="nex-check">
                                            <input
                                                type="checkbox"
                                                value={item.value}
                                                onChange={handleCheckboxChange}
                                                checked={selectedSymptoms.includes(item.value)}
                                            />
                                        </div>
                                        <div className="label">{item.label}</div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="nex-custom-symptom">
                            <label htmlFor="customSymptom">Add your own:</label>
                            <input
                                type="text"
                                id="customSymptom"
                                value={customSymptom}
                                onChange={handleCustomSymptomChange}
                            />
                            <Button className="nex-btn-secondary" onClick={addCustomSymptom}>
                                Add Custom Symptom
                            </Button>
                        </div>
                        <div className="nex-modal-actions justify-content-end mt-3">
                            <Button className="nex-btn-primary" onClick={handleClose}>
                                Add
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            }
        </>
    );
};

export default SymptomsForm;
