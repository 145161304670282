import PeopleKnowWidget from "common/PeopleKnowWidget";
import { useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import PractitionerProfiles from "../PractitionerProfiles";
import { isPractitioner } from "utils/UserHelper";
import JobWidget from "common/JobWidget";
import referals_icon from "assets/images/svgs/referals-icon.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ReferWidget from "common/ReferWidget/ReferWidget";

const PractitionerProfilesPage = () => {
    const currentUser = useSelector((state) => state.auth.currentUser);
    const isPractitionerEligibleForReferrals = useSelector(state => state.PreferenceFlags?.referalStatusFlag);
    const { primarySpeciality } = currentUser;

    return (
        <>
            <Row>
                <Col lg={isPractitioner() ? 9 : 12} md={isPractitioner() ? 9 : 12}>
                    <PractitionerProfiles />
                </Col>

                {isPractitioner() && isPractitionerEligibleForReferrals &&
                    <Col lg={3} md={3}>
                         <ReferWidget />
                        {primarySpeciality &&
                            <>
                                <PeopleKnowWidget speciality={primarySpeciality} />
                                <JobWidget widgetTitle="Recommended Jobs" primarySpeciality={primarySpeciality} />
                            </>
                        }
                    </Col>
                }
            </Row>
        </>
    )
}

export default PractitionerProfilesPage;