import React, { useEffect } from "react";
import { createImageFromInitials, dateFormatter, dateFormatterNew, getFullAddress, getFullAddressWithLocationCityAndState, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import { Col, FormGroup, Input, Row, Card, CardBody, Button, FormFeedback } from "reactstrap";
import { Label } from "reactstrap/lib";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import AddressInstitution from "pages/Profile/components/DoctorsDetails/components/AddressInstitution";
import wordFileIcon from "assets/images/svgs/word-file-icon.svg";
import pdfFileIcon from "assets/images/svgs/pdf-file-icon.svg";
import SuccessMessage from "./SuccessMessage";

const JobPreview = (props) => {
  const { practitioner, values, handleSubmit, setFieldValue, errors, jobDet } = props;
  const {
    title,
    firstName,
    middleName,
    lastName,
    address,
    qualification,
    primarySpeciality,
    superSpeciality,
  } = practitioner;


  function getQuestionCodes() {
    jobDet?.screeningQuestions?.map((squestion, index) => {
      setFieldValue(`answers[${index}].questionCode`, squestion.questionCode)
      setFieldValue(`answers[${index}].question`, squestion.question)
    })
  }

  useEffect(() => {
    getQuestionCodes();
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <div className="pvw-aplication">
        <div className="job-pop-review-wrap">
          <h4 className="nex-text-lg text-center text-black">Review Your Application</h4>
          <p className="nex-text text-center">
            The employer will also receive a copy of your profile.
          </p>
          <div className="nexogic-job-popup">
            <div className="nex-job-apply-preview-card">              
              <div className="nex-job-apply-preview-content">
                <div className="nex-quick-info text-center">
                    <div className="nex-graphic">
                    {(practitioner && practitioner?.avatarId) ?
                      < img
                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${practitioner.guid}/${practitioner.avatarId}`}
                        className="card-img rounded-circle" alt="#" />
                      :
                      < img
                        src={createImageFromInitials(50, `${practitioner?.name}`, getRandomColor(encrypt(practitioner?.guid).toString()))}
                        className="card-img rounded-circle" alt="#" />
                    }
                  </div>
                <h3 className="nex-text-xl">{title} {firstName ?? ""} {middleName ?? ""} {lastName ?? ""}, <span style={{ color: "#767878" }}>{qualification}</span></h3>
                <p className="nex-text">{primarySpeciality ?? ""} , {superSpeciality ?? ""}</p>
                <p className="nex-text"><AddressInstitution
                  city={address[0]?.city ?? ""}
                  state={address[0]?.state ?? ""}
                  country={
                    address[0]?.country ?? ""
                  }
                /></p>
                </div>
                <hr />
                <div className="nex-flex nex-number-sec">
                  <div className="item">
                    <p className="nex-text bold text-black">Phone Number</p>
                    <p className="nex-text">{values.phonenumber}</p>
                  </div>
                  <div className="item">
                    <p className="nex-text bold text-black">Email Address</p>
                    <p className="nex-text"><a href={`mailto:${values.emailid}`}>{values.emailid}</a></p>
                  </div>
                </div>
                <div className="selected-resume-sec">
                  <p className="nex-text bold text-black">Selected Resume</p>
                  <div className="nex-selected-item">
                    <div className="icon">
                    {values?.resumeDetails?.type === 'application/pdf' ? <img src={pdfFileIcon} alt="PDF" /> : <img src={wordFileIcon} alt="Word" />}
                    </div>
                    <div className="info">
                      <div className="nex-text text-black selected-resume-title text-truncate">{values.cvID === "" ? values?.resumeDetails?.name : values?.resumeDetails?.fileName}</div>
                      {/*<div className="nex-text">{formData?.resumeDetails?.size}</div>
                      <div className="nex-text">{formData?.resumeDetails?.createdOn && dateFormatterNew(formData?.resumeDetails?.createdOn)}</div>*/}
                    </div>
                    {/* <div className="nex-cross-btn">
                      &times;
                    </div> */} 
                  </div>
                </div>

                <div className="nex-cover-letter-sec">
                  <p class="nex-text bold text-black">Cover Letter</p>
                  <div className="nex-cover-letter-prev">
                    <p className="nex-text">
                      <div dangerouslySetInnerHTML={{ __html: values?.coverLetter }}></div></p>
                  </div>
                </div>
                
                <p class="nex-text bold text-black mb-3">Screening Question</p>
                {values.answers.map((e,index) => (
                    <div className="nex-cover-letter-sec" key={index}>
                      <div className="nex-cover-letter-prev nex-text">
                        <p>{e.question}</p>
                        <p><b>{e.answer}</b></p>
                      </div>
                    </div>
                ))
                }                
              </div>
            </div>
            <div className="card-body d-flex- px-0" style={{ display: 'none' }}>
              <div className="info px-3">

                <p className="card-text">
                  <AddressInstitution
                    city={address[0]?.city ?? ""}
                    state={address[0]?.state ?? ""}
                    country={
                      address[0]?.country ?? ""
                    }
                  />
                </p>
              </div>
            </div>
          </div>
          <hr className="mb-2 d-none" />
          <Row className="d-none">
            <Col md={6} className="py-0">
              <FormGroup className="mb-2">
                <Label className="prev-label">Phone Number</Label>
                <p className="prev-val-txt py-2">{values.phonenumber}</p>
              </FormGroup>
            </Col>
            <Col md={6} className="py-0">
              <FormGroup className="mb-2">
                <Label className="prev-label">Email Address</Label>
                <p className="prev-val-txt py-2">{values.emailid}</p>
              </FormGroup>
            </Col>
          </Row>
          <hr className="mb-2 d-none" />
          <div className="nex-cover-letter-sec d-none">
            <p className="nex-text bold text-black">Cover Letter</p>
            <div
              className="list-para mt-1"
              dangerouslySetInnerHTML={{
                __html: values?.coverLetter,
              }}
            ></div>
          </div>

          {jobDet?.screeningQuestions && (jobDet?.screeningQuestions.length > 0) && (
            <fieldset className="u-flsd-wpr mt-4 mb-4 d-none">
              <legend>Screening Questions</legend>
              <div className="prev-address">
                {jobDet?.screeningQuestions?.map((squestion, index) => {
                  return (
                    <div key={index}>
                      <div className="screening-question" key={index}>
                        <div className="screen-qhead">
                          <div className="q-txt"> {squestion?.question}</div>
                        </div>
                        <div className="screen-body">
                          <Row>
                            <Col md={12} className="d-flex align-items-start ">
                              {values?.answers[index]?.answer}
                            </Col>
                          </Row>
                        </div>
                      </div>
                      {errors?.answers && (
                        <p>
                          <RenderErrorMessage
                            errorMsg={errors?.answers[index]?.answer}
                          />
                        </p>
                      )}
                    </div>
                  )
                })}
              </div>
            </fieldset>
          )}
          {/* <FormGroup check>
            <Input type="checkbox" />
            <Label check>
              Please Follow <b>Company</b> for latest updates.
            </Label>
          </FormGroup> */}



        </div>
      </div>
    </form>
  );
};

export default JobPreview;
