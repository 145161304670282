import API_Factory from "./API_Factory";

// Practitioner channerl data get service
const getPractitionerChannelById = (id) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                method: "GET",
                url: `${process.env.REACT_APP_API_BASE_URL}practitioner-service/practitioners/${id}/channels`,
                ignoreToken: false,
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

// Create dynamic channel services
const createPractitionerChannel = (practitionerId, payload) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                method: "POST",
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/practitioner-service/practitioners/${practitionerId}/channels`,
                data: payload,
                ignoreToken: false,
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

// Search practioner data service
const getPractitionerSearchData = async (query,type,cp) => {
    try {
        if (query.length < 3) return;
        const response = await API_Factory({
            url: `${process.env.REACT_APP_API_BASE_URL}practitioner-service/profiles/suggestions?q=${query}&f=${type}&cp=${cp}`,
            method: 'GET',
            responseType: "application/json",
            ignoreToken: false
        })
        return response;
    } catch (e) {
        return e
    }
}

export const PractitionerChannelServices = {
    getPractitionerChannelById,
    createPractitionerChannel,
    getPractitionerSearchData
}