import React from 'react'
import Availability from './Availability'

export default function AppointmentBookingForm() {
    return (
        <div className='card nexogic-widget-sidebar-card '>
            <h3 className='card-title h2 mb-2'>Book an appointment</h3>
            <div className="card-body">
                <div className="form-group">
                    <label htmlFor="" className='form-label'>What is your insurance plan?</label>
                    <select className='form-control'>
                        <option value="">I am paying for myself</option>
                    </select>
                </div>
                <div className="row need-margin">
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label htmlFor="" className='form-label'>Purpose of visit</label>
                            <select className='form-control'>
                        <option value="">Illness</option>
                    </select>
                            
                        </div>
                    </div>
                </div>
            <div className="nexogic-form">
                
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label htmlFor="" className='form-label'>Visit</label>
                            <div className="check-box-flex">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="visit" id="visit_1" checked />
                                <label className="form-check-label" htmlFor='visit_1'>
                                    First
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="visit" id="visit_2" />
                                <label className="form-check-label" htmlFor='visit_2'>
                                    Follow up visit
                                </label>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label htmlFor="" className='form-label'>Choose the type of appointment</label>
                            <div className='check-box-flex'>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="office_visit" id="office_visit_1" checked />
                                <label className="form-check-label" htmlFor='office_visit'>
                                    Office visit
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="office_visit" id="office_visit_2" />
                                <label className="form-check-label" htmlFor='office_visit'>
                                    Video appointment
                                </label>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label htmlFor="" className='form-label'>Select an available time </label>
                            <Availability />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-group">
                            <input type="submit" name="continue_booking" id="continue_booking" className='btn nexogic_primary_button  d-block w-100' value='Continue booking' />
                        </div>
                    </div>
                </div>

            </div>

            </div>

        </div>
    )
}
