import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from "classnames";
import { encrypt } from "utils/EncryptDecrypt";
import { isAdmin } from "utils/UserHelper"
import { getProfilePicUrl, createImageFromInitials, getRandomColor } from 'utils/Utils'
const ResponsiveProfileInfo = ({ currentUser, toggle, uploadImage }) => {
  const [profilePicUrl, setProfilePicUrl] = useState('');
  let currentUserId = encrypt(currentUser.guid);
  const username = isAdmin() ? "Nexogic Admin" : `${currentUser.firstName || ''} ${currentUser.lastName || ''}`;

  useEffect(() => {
    setProfilePicUrl(uploadImage)
  }, [uploadImage])

  return (
    <div>
      <div className="profile-info d-flex w-100">
        <div className="responsive-profile-image mr-2">
          <img
            className="rounded-circle"
            src={
              profilePicUrl
                ? profilePicUrl
                : createImageFromInitials(
                  64,
                  username,
                  getRandomColor(currentUserId.toString())
                )
            }
            alt="profilePic"
            width="64"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = createImageFromInitials(132, username, getRandomColor(currentUserId.toString()));
            }}
          />
          <div className="mt-1">
            <Link
              to={`/profile/${currentUserId}`}
              className="d-block text-primary text-decoration-none f-12 py-1"
              onClick={() => toggle()}
            >
              View Profile <i className="angleRightIc" />
            </Link>
          </div>
        </div>
        <div className={classnames({ "ml-3": !isAdmin(), "m-4": isAdmin() })}>
          <h6 className={"mb-1 f-16 line-h-10 mb-0"}>{`${currentUser.title ? currentUser.title : ""
            } ${username}`}</h6>
          <span className="f-12 line-h-12">{currentUser.headline || ''}</span>
          <span className="f-12 d-block line-h-10">{currentUser.location || ''}</span>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveProfileInfo;