import React from 'react'
import secondOpinionImg  from 'assets/images/second-opinion.png'

const MedicalCases = () => {
  return (
    <div className="nexogic-container-gap container-fluid nexogic-second-option">
        <div className="card ">
        <div className="row">
        <div className="col-md-7">
          <img src={secondOpinionImg} alt="Image" width="100%" />
        </div>
        <div className="col-md-5 nexogic-form-content">
          <div>
          <h3 className="nexogic-form-title mb-2 text-primary">MedicalCases</h3>
          <h1 className="nexogic-form-title mb-2">UNDER CONSTRUCTION</h1>         
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default MedicalCases