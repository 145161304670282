import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ShareDropdown from "./ShareDropdown";
import { ButtonDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { get_meta_data } from "../../../../utils/message_connection";
import DefaultImg from "../../../../assets/images/defaultPic.png";
import ShareModal from "../ShareModal/ShareModal";
// import pubnub from "pubnub";
import { usePubNub } from "pubnub-react";
import { toast, ToastContainer } from "react-toastify";
import "./Postinput.css";
import { titleCase } from "utils/titleCase";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const PostInputOption = ({
  Icon,
  title,
  number,
  IconOpen,
  likedStatus,
  handleClick,
  feedId,
  activeIcon,
  toggleComment,
  showComment,
  location,
  createdOn,
  mediaList,
  user,
  text,
  id,
  totalComment,
}) => {
  console.log(totalComment, 3434);

  const practitionerId = useSelector(
    (state) => state.auth.currentUser.practitionerId
  );
  const pubnub = usePubNub();

  let url = window.location.origin;

  let link = `${url}/practitioner-service/practitioners/${practitionerId}/feeds/${feedId}`;

  const [modal, setModal] = useState(false);

  const [connections, setconnections] = useState([]);

  const [dropdown, setDropDown] = useState(false);

  const [like, setLike] = useState();

  const [noOfReact, setNoOfReact] = useState();

  const [noOfComment, setnoOfComment] = useState();

  const toggle = () => setDropDown(!dropdown);

  const [modalMessage, setmodalMessage] = useState(false);

  const toggle_modal = () => {
    setModal(!modal);
    // debugger;
  };

  useEffect(() => {
    // setnoOfComment(0);
    setNoOfReact(number);
    setLike(likedStatus);
    // setnoOfComment(number);
  }, [number, likedStatus]);

  useEffect(() => {
    setnoOfComment(totalComment);
  }, [totalComment]);

  //pubnub send message
  const sendMessageFn = (channelName) => {
    pubnub
      .publish({
        channel: channelName,
        message: {
          content: link,
          sender: practitionerId,
        },
      })
      .then((res, status) => {
        showSuccessMessage("Message Sent Successfully", {
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => setmodalMessage(false), 3000);
      })
      .catch((err) => showErrorMessage("Something went wrong."));
  };

  //api call to get data
  const handleModalMessage = () => {
    setmodalMessage(!modalMessage);
    get_meta_data(practitionerId, 0, 10, "").then((res) =>
      setconnections(res?.data?.data?.data)
    );
  };

  //fn to handle click
  const handleReact = () => {
    if (title === "Like") {
      let action = like == true ? "delete" : "add";

      if (action === "add") {
        let status = handleClick(action, feedId, practitionerId, (status) => {
          if (status === "SUCCESS") {
            setLike(!like);
            setNoOfReact(noOfReact + 1);
          }
        });
      } else {
        let status = handleClick(action, feedId, practitionerId, (status) => {
          if (status === "SUCCESS") {
            setLike(!like);
            setNoOfReact(noOfReact - 1);
          }
        });
      }
    }
  };

  return (
    <div
      className={
        title === "Comment"
          ? "container-fluid d-flex justify-content-center"
          : title === "Share"
          ? "container-fluid d-flex justify-content-end"
          : "container-fluid"
      }
      style={{ cursor: "pointer" }}
    >
      <div className="row">
        <div className="col-sm-12">
          <div className="d-flex">
            {title === "Like" || title === "Comment" ? (
              <div
                onClick={
                  title === "Like"
                    ? handleReact
                    : title === "Comment"
                    ? toggleComment
                    : toggle
                }
              >
                <img
                  src={dropdown || showComment || like ? IconOpen : Icon}
                  style={title === "Like" ? { marginTop: "-8px" } : null}
                />
                <span
                  className={
                    dropdown || showComment || like
                      ? "ml-2 text_open"
                      : "ml-2 text_off"
                  }
                >
                  {title}
                </span>
              </div>
            ) : (
              <>
                <ButtonDropdown isOpen={dropdown} toggle={toggle}>
                  <DropdownToggle
                    color="link"
                    className="min-w-0 p-0 post_dropdown_menu"
                  >
                    <div onClick={toggle}>
                      <img
                        src={dropdown || showComment || like ? IconOpen : Icon}
                        style={title === "Like" ? { marginTop: "-8px" } : null}
                      />
                      <span
                        className={
                          dropdown || showComment || like
                            ? "ml-2 text_open"
                            : "ml-2 text_off"
                        }
                      >
                        {title}
                      </span>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu className="p-0 post_dropdown_menu_box">
                    <ShareDropdown
                      feedId={feedId}
                      share_feed_id={id ? id : null}
                      location={location}
                      createdOn={createdOn}
                      mediaList={mediaList}
                      user={user}
                      caption_text={text}
                      toggle_drop={toggle}
                      dropdown={dropdown}
                      toggle_modal={toggle_modal}
                      handleModalMessage={handleModalMessage}
                    />
                  </DropdownMenu>
                </ButtonDropdown>
                <ShareModal
                  toggle={toggle_modal}
                  modal={modal}
                  share="share"
                  user={user}
                  location={location}
                  createdOn={createdOn}
                  feedId={feedId}
                  mediaList={mediaList}
                  caption_text={text}
                  share_feed_id={id ? id : null}
                />
              </>
            )}
            <div
              className={
                dropdown || showComment ? "ml-2 text_open" : "ml-2 text_off "
              }
            >
              {title === "Like"
                ? noOfReact === 0
                  ? ""
                  : noOfReact
                : title === "Comment"
                ? noOfComment === 0
                  ? ""
                  : noOfComment
                : number === 0
                ? ""
                : "number"}
            </div>
          </div>
        </div>
      </div>
      {connections.length > 0 && (
        <Modal isOpen={modalMessage} toggle={() => setmodalMessage(false)}>
          <ModalHeader toggle={() => setmodalMessage(false)}>
            Sent in messages
          </ModalHeader>
          <ModalBody
            className={
              connections.length > 5 ? "post_inputOption_modal_message" : null
            }
          >
            {connections.map((item, index) => (
              <div
                className="d-flex justify-content-between align-items-center mb-4"
                key={index}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={
                      item.contacts[0].profilePicUrl != null
                        ? item.contacts[0].profilePicUrl
                        : DefaultImg
                    }
                    className="post_inputOption_modal_message_image"
                  />

                  <div className="ml-3">
                    {item.contacts[0].firstName != null
                      ? titleCase(item.contacts[0].firstName) + " "
                      : ""}
                    {item.contacts[0].middleName != null
                      ? titleCase(item.contacts[0].middleName) + " "
                      : ""}
                    {item.contacts[0].lastName != null
                      ? item.contacts[0].lastName
                      : ""}
                  </div>
                </div>
                <button
                  className="btn btn-outline-primary"
                  onClick={() => sendMessageFn(item.channel.channelName)}
                >
                  Send
                </button>
              </div>
            ))}
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};
export default PostInputOption;
