import React, { useEffect, useState } from "react";
import { Card, CardBody, Collapse, Input } from "reactstrap";
import PencilIcon from "./images/Vector.png";
import Search from "./images/search.png";
import MessageContact from "./components/MessageContact/MessageContact";
import ChatScreen from "./components/ChatScreen/ChatScreen";
import { PractitionerChannelServices } from "utils/PractitionerChannelServices";
import AsyncMessageProfileSearch from "../Messages/components/NewMessage/AsyncMessageProfileSearch";
import DefaultImg from "../../assets/images/defaultPic.png";
import profImg from "../../assets/images/reviewer_image_2.png";
import defaultpin from "../../assets/images/defaultpin.png";
import Dots from "../../assets/images/dots.png";
import {
  get_contact_list,
  get_meta_data,
} from "../../utils/message_connection";
import { useSelector } from "react-redux";
import MessageModal from "./components/ChatMessage/MessageModal/index";
import { store_metaData, search_contact } from "../../utils/message_connection";
import PubNub from "pubnub";
import { PubNubProvider, usePubNub } from "pubnub-react";
import { ToastContainer, toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "reactstrap";
//import "./message.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faEdit,
  faEllipsisH,
  faPenSquare,
} from "@fortawesome/free-solid-svg-icons";
import ChatFlashScreen from "./components/ChatFlashScreen";
import NewMessage from "./components/NewMessage";
import { showSuccessMessage } from "common/AlertContainer";

//initalizing pubnub
const pubnub = new PubNub({
  publishKey: "pub-c-603af66f-0e5f-4002-9290-e34501f03ee2",
  subscribeKey: "sub-c-4dcad3c3-a486-4917-b80d-3682cc359032",
  secretKey: "sec-c-YjVhZDQzN2ItZjJhZC00NTJmLThkZmEtNDdiNDQ5YTA1M2Yx",
});

const Messages = (props) => {
  const [res_data, setres_data] = useState([]);

  const [selected_id, setselected_id] = useState();

  const [selected_profile_pic, setselected_profile_pic] = useState();

  const [selected_first_name, setselected_first_name] = useState();

  const [selected_middle_name, setselected_middle_name] = useState();

  const [selected_last_name, setselected_last_name] = useState();

  const [dynamic_channel, setdynamic_channel] = useState("");

  const [get_history_parent, setget_history_parent] = useState(0);

  const [key_clicked_val, setkey_clicked_val] = useState(0);

  const [Modal, setModal] = useState(false);

  const [res_data_New_modal_data, setres_data_New_modal_data] = useState([]);

  const [recent_message, setrecent_message] = useState("");

  const [recent_channel, setrecent_channel] = useState("");

  const [recent_timeToken, setrecent_timeToken] = useState("");

  const [all_channel, setall_channel] = useState([]);

  const [pageSize, setpageSize] = useState(10);

  const [pageNum, setpageNum] = useState(0);

  const [isLoading, setisLoading] = useState(false);
  const [drop, showDrop] = useState(false);
  const [data, setData] = useState([]);
  const [olddata, setOldData] = useState([]);
  const [toggle, setToggle] = useState(false);

  const showDropClick = () => {
    showDrop(!drop);
  };

  const practionerId = useSelector((state) => state.auth.currentUser.guid);

  const practionerIdChannel = async () => {
    let val = "";
    await PractitionerChannelServices.getPractitionerChannelById(
      practionerId
    ).then((res) => {
      setData(res.data);
      setOldData(res.data);
      val = res.data;
    });
    if (val.length > 0) {
      switchChat(val[0].subscribers, val[0].channelId);
    }
  };
  useEffect(() => {
    practionerIdChannel();
  }, [toggle]);

  // useEffect(
  //   () =>
  //     get_meta_data(practionerId, pageNum, pageSize).then((res) => {
  //       if (
  //         res.data &&
  //         res.data.data &&
  //         res.data.data.data &&
  //         res.data.data.data.length > 0
  //       ) {
  //         setres_data(res.data.data.data);

  //         let newarr = [];

  //         res.data.data.data.map((i) => newarr.push(i.channel.channelName));
  //         setall_channel(newarr);
  //         setselected_first_name(res.data.data.data[0].contacts[0].firstName);
  //         setselected_middle_name(res.data.data.data[0].contacts[0].middleName);
  //         setselected_last_name(res.data.data.data[0].contacts[0].lastName);

  //         setdynamic_channel(res.data.data.data[0].channel.channelName);
  //         setisLoading(false);
  //       } else {
  //         setres_data([]);
  //         setdynamic_channel("");
  //       }
  //     }),
  //   []
  // );

  const click_handler = (
    id,
    profile_pic,
    first_name,
    middle_name,
    last_name,
    key_clicked,
    channel_selected
  ) => {
    setselected_id(id);

    setselected_profile_pic(profile_pic);

    setselected_first_name(first_name);

    setselected_middle_name(middle_name);

    setselected_last_name(last_name);

    setkey_clicked_val(key_clicked);

    setdynamic_channel(channel_selected);

    setget_history_parent((prev) => prev + 1);
  };

  //create_message_action
  // const create_message_action = () => {
  //   setModal(!Modal);
  //   get_contact_list(practionerId).then((res) => {
  //     if (res.data && res.data.data && res.data.data.connection) {
  //       setres_data_New_modal_data(res.data.data.connection);
  //     }
  //   });
  // };

  const forwardMessage = () => {
    setModal(!Modal);
  };

  //add message
  const new_message_action = (item) => {
    let pid = item.practitionerId;
    let channel_create;
    if (practionerId > pid) {
      channel_create = "U" + pid + "U" + practionerId;
    } else {
      channel_create = "U" + practionerId + "U" + pid;
    }

    let channel = {
      channelName: channel_create,
      channelNicName: "string",
      channelType: "INDIVIDUAL",
      contacts: [practionerId, pid],
      timeToken: 0,
    };

    store_metaData(channel)
      .then((res) => {
        if (res.data.message == "Metadata stored successfully") {
          showSuccessMessage(res.data.message, {
            autoClose: 2000,
          });

          setTimeout(() => {
            res_data.unshift({
              channel,
              contacts: [item],
            });
            setModal(false);
          }, 2000);
        } else {
          toast.info("Contact already present", {
            autoClose: 2000,
          });
          setTimeout(() => setModal(false), 2000);

          setselected_id(pid);

          setselected_profile_pic(item.profilePicUrl);

          setselected_first_name(item.firstName);

          setselected_middle_name(item.middleName);

          setselected_last_name(item.lastName);

          let keyClicked = res_data.map((item1) =>
            item1.contacts[0].practitionerId === item.practitionerId
              ? true
              : false
          );
          let index_of_connection = keyClicked.indexOf(true);
          setkey_clicked_val(index_of_connection);

          setdynamic_channel(res.data.data.channel.channelName);

          setget_history_parent((prev) => prev + 1);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetch_recent_message();
  }, [recent_channel, recent_message]);

  useEffect(() => {
    document.body.classList.add('nexogic-chat-page');
    return () => {
      document.body.classList.remove('nexogic-chat-page');
    };
  }, []);

  const fetch_recent_message = (message, channel, timeToken) => {
    setrecent_message(message);
    setrecent_channel(channel);

    props.getRecentMessage(message, channel, timeToken);
  };

  //fetching more data in infinite scroll
  const fetchMoreData = () => {
    let page = pageNum;
    page = page + 1;
    setpageNum(page);
    setisLoading(true);
    get_meta_data(practionerId, page, pageSize).then((res) => {
      setres_data([...res_data, ...res.data.data.data]);
      setisLoading(false);
    });
  };

  //Search connections onchange listner
  const searchConnections = async (event) => {
    let search = event.target.value;
    setisLoading(true);

    let val = olddata.filter((item) => {
      if (item.subscribers) {
        if (item.subscribers[0].firstName.toLowerCase().startsWith(search)) {
          return item;
        }
      }
    });

    if (search == "") {
      setData(olddata);
    } else {
      setData(val);
    }
    setisLoading(false);
    // get_meta_data(practionerId, pageNum, pageSize, search).then((res) => {

    //   setres_data(res.data.data.data);
    //   setselected_first_name("");
    //   setselected_middle_name("");
    //   setselected_last_name("");
    //   setdynamic_channel("");
    //   setisLoading(false);
    // });
  };
  const [newmsg, setnewmsg] = useState(false);
  const Open_create_message = () => {
    setnewmsg(!newmsg);
  };
  const [isOpenchannel, setIsOpenchannel] = useState(false);
  const [isOpendirect, setIsOpendirect] = useState(true);

  const toggleChannel = () => setIsOpenchannel(!isOpenchannel);

  const toggleDirect = () => setIsOpendirect(!isOpendirect);
  const [id, setId] = useState(0);
  const [response, setResponse] = useState({});

  //Switch the chat screen
  const switchChat = async (item, dynamicId) => {
    setId(item[0].guid);
    setdynamic_channel(dynamicId);
    setResponse(item[0]);
    setnewmsg(false);
  };

  //After channel creation add user on the list.
  const handleToggle = async (resu) => {
    setToggle(!toggle);
    setnewmsg(false);
    data.map((item) => {
      if (item.subscribers) {
        if (item.channelId == resu) {
          switchChat(item.subscribers, item.channelId);
        }
      }
    });
  };

  useEffect(() => {
    document.body.classList.add('body-message-page');
    document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "Messages";
    return () => {
      document.body.classList.remove('body-message-page');
      document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "";
    }
}, [])

  return (
    <div className="nexogic-messages-wrap">
      <ToastContainer />

      <div className="nexogic-messages-container card">
        <div className="nexogic-messages-aside">
          <div className="nexogic-messages-head">

            <h3 className="nexogix-section-title">Messages
              <span className="nexogix-icon nexogix-edit-btn" onClick={Open_create_message}>
                {/* onClick={create_message_action} */}
                {/**  <img src={PencilIcon} alt="#" /> */}
                <FontAwesomeIcon icon={faEdit} />
              </span>
            </h3>

            {/* <div className="search-select">
                <Input id="exampleSelect" name="select" type="select">
                  <option>Dr. Ted Russell</option>
                  <option>Dr.Ted Russell 2</option>
                  <option>Dr.Ted Russell3</option>
                  <option>Dr.Ted Russell 4</option>
                  <option>Dr.Ted Russell 5</option>
                </Input>
              </div> */}
          </div>
          <div className="nexogic-search-card">
            <img src={Search} alt="" className="icon" />
            <input
              id="message_search"
              type="text"
              placeholder="Search"
              onChange={searchConnections}
              className="form-control"
            />
            {isLoading && (
              <div className="loading-spiner">
                <Spinner />
              </div>
            )}

          </div>

          <div className="nexogic-messages-contacts">

            <div className="nexogic-messages-tab" onClick={toggleDirect}>
              <svg className={`icon ${isOpendirect ? 'on' : ''}`} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
              </svg>

              Direct
            </div>
            <Collapse isOpen={isOpendirect}>
              <div className="nexogic-thumb-lists nexogic-custom-scroll">
                {data.map((item, key) => {
                  return (
                    <>
                      {item.subscribers && (
                        <div
                          onClick={(e) =>
                            switchChat(item.subscribers, item.channelId)
                          }
                          className={
                            id === item.subscribers[0].guid
                              ? "nexogic-item selected active"
                              : "nexogic-item"
                          }
                        >
                          <div className="thumb">
                            <img
                              className="card-img"
                              src={
                                item.subscribers[0].avatarId != undefined
                                  ? `${process.env.REACT_APP_IMAGE_BASE_URL}/${item.subscribers[0].guid}/${item.subscribers[0].avatarId}`
                                  : DefaultImg
                              }
                              alt="#"
                            />
                            <span className="online-status"></span>
                          </div>
                          <div className="card-body">
                            <h3 className="card-title">
                              <div className="d-inline-block text-truncate">
                                {item.subscribers[0].title}{" "}
                                {item.subscribers[0].firstName}{" "}
                                {item.subscribers[0].lastName}
                                {item.subscribers[0].qualification && (
                                  <span className="qualification">
                                    , {item.subscribers[0].qualification}{" "}
                                  </span>
                                )}
                              </div>
                            </h3>
                            <p className="card-text">
                              {item.subscribers[0].primarySpeciality}
                            </p>
                          </div>
                          {/*<div className="time-sec">
                                 <div className="d-flex justify-content-end mess-dark">
                              09:41
                            </div> 
                              </div>*/}
                        </div>
                      )}
                    </>
                  );
                })}

                {/* <InfiniteScroll
                      dataLength={res_data && res_data.length}
                      next={fetchMoreData}
                      hasMore={true}
                      loader={
                        isLoading ? (
                          <div className="text-center mt-2">
                            <Spinner color="primary" />
                          </div>
                        ) : (
                          ""
                        )
                      }
                      scrollableTarget="scrollableDiv"
                      style={{ overflow: "hidden" }}
                    >
                      {res_data && res_data.length > 0 ? (
                        res_data.map((item, index) => (
                          <PubNubProvider client={pubnub} key={index}>
                            <MessageContact
                              key_count={index}
                              contact={item.contacts[0]}
                              Click={click_handler}
                              selected={
                                index == key_clicked_val ? "contact" : ""
                              }
                              selected_contact={get_history_parent}
                              channel_default={item.channel}
                              contact_default_Firtname={
                                item.contacts[0].firstName +
                                " " +
                                item.contacts[0].lastName
                              }
                              profilePic={item.contacts[0].profilePicUrl}
                              recent_message={recent_message}
                              recent_channel={recent_channel}
                              recent_timeToken={recent_timeToken}
                              all_channel={res_data}
                            />
                          </PubNubProvider>
                        ))
                      ) : (
                        <p className="text-center mt-2">
                          No Connections Present
                        </p>
                      )}
                    </InfiniteScroll> */}
              </div>
            </Collapse>
            <div className="nexogic-messages-tab" onClick={toggleChannel}>
              <svg className={`icon ${isOpenchannel ? 'on' : ''}`} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
              </svg>

              Channel
            </div>
            <Collapse isOpen={isOpenchannel} >
              {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
                <div className="nexogic-thumb-lists nexogic-custom-scroll">
                  <div className="nexogic-item selected active">
                    <div className="thumb">
                      <img className="card-img" src={profImg} alt="#" />
                      <span className="online-status"></span>
                      {/* <img className="online_Status" src={OnlineIcon} alt="" /> */}
                    </div>
                    <div className="card-body">

                      <h3 className="card-title has-time">John Adley <div className="text-muted time">09:41</div> <div className="default-pin">
                        <img src={defaultpin} alt="#" />
                      </div></h3>

                      <p className="card-text text-clamp">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit, sed do...
                      </p>
                      <div className="card-text">


                      </div>
                    </div>

                  </div>
                  <div className="nexogic-item selected ">
                    <div className="thumb">
                      <img className="card-img" src={profImg} alt="#" />
                      <span className="online-status"></span>
                      {/* <img className="online_Status" src={OnlineIcon} alt="" /> */}
                    </div>
                    <div className="card-body">
                      <h3 className="card-title has-time">John Adley <div className="text-muted time">09:41</div> <div className="default-pin">
                        <img src={defaultpin} alt="#" />
                      </div></h3>
                      <p className="card-text text-clamp">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit, sed do...
                      </p>
                      <div className="card-text">

                      </div>
                    </div>

                  </div>

                  {/* <InfiniteScroll
                      dataLength={res_data && res_data.length}
                      next={fetchMoreData}
                      hasMore={true}
                      loader={
                        isLoading ? (
                          <div className="text-center mt-2">
                            <Spinner color="primary" />
                          </div>
                        ) : (
                          ""
                        )
                      }
                      scrollableTarget="scrollableDiv"
                      style={{ overflow: "hidden" }}
                    >
                      {res_data && res_data.length > 0 ? (
                        res_data.map((item, index) => (
                          <PubNubProvider client={pubnub} key={index}>
                            <MessageContact
                              key_count={index}
                              contact={item.contacts[0]}
                              Click={click_handler}
                              selected={
                                index == key_clicked_val ? "contact" : ""
                              }
                              selected_contact={get_history_parent}
                              channel_default={item.channel}
                              contact_default_Firtname={
                                item.contacts[0].firstName +
                                " " +
                                item.contacts[0].lastName
                              }
                              profilePic={item.contacts[0].profilePicUrl}
                              recent_message={recent_message}
                              recent_channel={recent_channel}
                              recent_timeToken={recent_timeToken}
                              all_channel={res_data}
                            />
                          </PubNubProvider>
                        ))
                      ) : (
                        <p className="text-center mt-2">
                          No Connections Present
                        </p>
                      )}
                    </InfiniteScroll> */}
                </div>
              )}
            </Collapse>

          </div>
          {/* {Modal && (
              <MessageModal
                showModal={Modal}
                new_message_action={new_message_action}
                modaltitle="New Message"
                connections={res_data_New_modal_data}
                forwardMessage={forwardMessage}
              // forward_message_action={forward_message_action}
              // message_text={message.text}
              />
            )} */}
          {/* </> */}
        </div>
        <div className="nexogic-messages-chat-wrap">
          {newmsg && <NewMessage handleToggle={handleToggle} />}

          {/* <ChatFlashScreen /> */}
          {Object.keys(response).length > 0 ?
            (
              <ChatScreen
                selected_id={id}
                header_profile_pic={response.avatarId}
                header_title={response.title}
                header_first_name={response.firstName}
                header_middle_name={selected_middle_name}
                header_last_name={response.lastName}
                header_qualification={response.qualification}
                dynamic_channel={dynamic_channel}
                get_history={get_history_parent}
                initial_user={res_data && res_data[0]?.contacts[0].name}
                connections={res_data}
                fetch_recent_message={fetch_recent_message}
              />
            )
            : (
              <ChatFlashScreen />
            )}


          {/* {res_data && (
              <ChatScreen
                selected_id={selected_id}
                header_profile_pic={selected_profile_pic}
                // header_first_name={selected_first_name}
                header_first_name="Richard"
                header_middle_name={selected_middle_name}
                // header_last_name={selected_last_name}
                header_last_name="Hampton"
                dynamic_channel={dynamic_channel}
                // get_history={get_history_parent}
                get_history={get_history_parent}
                initial_user={res_data && res_data[0]?.contacts[0].name}
                connections={res_data}
                fetch_recent_message={fetch_recent_message}
              />
            )} */}
        </div>
      </div>

    </div>
  );
};

export default Messages;
