import React, { useState, useEffect } from "react";
import { FAILED_TO_FETCH_DATA } from "utils/Constants";
import { PatientService } from "utils/PatientService";
import RelationInfo from "./RelationInfo";
import CommonLoader from "common/CommonLoader";

const MyRelations = ({ patient }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [relations, setRelations] = useState([]);

    const getRelatedPeople = (id) => {
        PatientService.getRelatedPeople(id)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    setIsLoading(false);
                    setRelations(data);
                }
                else {
                    setIsLoading(false);
                    console.log(FAILED_TO_FETCH_DATA);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error("Error fetching related people:", error);
            });
    }

    useEffect(() => {
        getRelatedPeople(patient.id);
    }, [patient.id])

    if (isLoading) {
        return (
            <div className="loading-spinner text-center mt-2">
                <CommonLoader style={{ width: "2rem", height: "2rem" }} />
            </div>
        );
    }

    return (
        <>
            {relations.length > 0 ? (
                relations.map((relation) => (
                    <RelationInfo key={relation.id} relation={relation} />
                ))
            ) : (
                <div className="no-relations-msg text-center mt-2">
                    No relations found.
                </div>
            )}
        </>
    )
}

export default MyRelations;
