import "assets/styles/typeahead.css";
import { ErrorMessage } from "formik";
import { useRef, useState } from "react";
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { Button, FormGroup, Label } from "reactstrap";
import { InstitutionServices } from "utils/InstitutionServices";
import { getFullName, makeFullNameWithAddressWhenAddressIsNested } from "utils/Utils";

const InstitutesReferralTypehead = (props) => {
  const institutesRef = useRef(null);
  const { placeholder, type, onNewInstituteReq, disabled, setShowAssociatedPractitioners, getSelectedInstitution, selectedReferByInstitution } =
    props;
  const { name, value } = props.field;
  const { setFieldValue } = props.form;
  const [institutesData, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [innerText, setInnerText] = useState("");
  const handleSearch = async (query) => {
    setData([]);
    if (query.length < 3) return;
    setLoading(true);
    try {
      let searchQuery = `re==true;na=='*${query}*',abb=='*${query}*'`;
      InstitutionServices.getReferralActiveInstitutionsList(query, searchQuery)
        .then((response) => {
          if (response.status === 200) {
            const filteredData = response.data.contents.filter(
              (item) => item.guid !== selectedReferByInstitution
            );
            setData(filteredData);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (props.alert) props.alert.error(e.message);
    }
  };

  const handleChange = (selected) => {
    if (selected[0]) {
      if (props.form.values.specialityRequested.length > 0)
        setShowAssociatedPractitioners(true);
      setFieldValue(name, selected[0], true);
      getSelectedInstitution(selected);

    }
    else {
      getSelectedInstitution(null);
      setShowAssociatedPractitioners(false);
      // setFieldValue(name, selected, true);
      // setFieldValue(`referToInstitution`, []);
    }
  };
  return (
    <FormGroup className="flex-grow-1">
      <Label htmlFor={name} className="d-block mb-2">
        {props.label}
      </Label>
      <AsyncTypeahead
        ref={institutesRef}
        isLoading={loading}
        clearButton={value.name !== '' || innerText.length > 0}
        onSearch={handleSearch}
        // id={"institutesAsyncInput"}
        id={name}
        options={institutesData}
        labelKey={"name"}
        filterBy={() => ["name", "abbreviation"]}
        selected={value && value.name ? [makeFullNameWithAddressWhenAddressIsNested(value)] : [""]}
        placeholder={placeholder}
        type={type}
        minLength={2}
        inputProps={{ className: "" }}
        onInputChange={(text, event) => {
          setFieldValue(name, { name: text });
          handleSearch(text);
          setInnerText(text);
        }}
        onChange={handleChange}
        onBlur={() => {
          return institutesRef !== null
            ? institutesRef.current.hideMenu()
            : null;
        }}
        renderMenu={(results, menuProps) => {
          if (!results.length) {
            return null;
          }
          return (
            <Menu {...menuProps}>
              {results.map((result, index) => (
                <MenuItem key={index} option={result} position={index}>
                  {getFullName(result)}
                </MenuItem>
              ))}
              {!!onNewInstituteReq && (
                <Button
                  className="btn--outline-success bg-white border-0 ml-3 f-16 line-h-20"
                  onClick={() => {
                    onNewInstituteReq();
                    return institutesRef !== null
                      ? institutesRef.current.hideMenu()
                      : null;
                  }}
                  style={{ color: "#5CBA0D" }}
                >
                </Button>
              )}
            </Menu>
          );
        }}
        disabled={disabled}
      />
      <ErrorMessage
        name={name}
        render={(msg) => (
          <span className="text-danger f-12 d-inline-block  mt-1 line-height-error ">
            {msg}
          </span>
        )}
      />
    </FormGroup>
  );
};

InstitutesReferralTypehead.defaultProps = {
  disabled: false,
};

export default InstitutesReferralTypehead;
