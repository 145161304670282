import CommonLoader from "common/CommonLoader";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { withAlert } from "react-alert";
import { Card, CardBody } from "reactstrap";
import { FAILTOGETRECOED, FAILTOSAVERECOED } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import { isAdmin } from "utils/UserHelper";
import { useSelector } from "react-redux";
import ResumeInfo from "./ResumeInfo";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { validateImageSize } from "utils/Utils";

const PractitionerResumes = (props) => {
    const { practitionerGuid } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [listResumes, setListResumes] = useState([]);
    const [isFileUploaing, setIsFileUploaing] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const currentUser = useSelector((state) => state.auth.currentUser);
    const fileInputRef = useRef(null);

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const getProfileResumes = (guid) => {
        try {
            PractitionerService.getResumesProfile(guid).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setListResumes(response.data);
                    setIsLoading(false);
                } else {
                    showErrorMessage(FAILTOGETRECOED);
                    setIsLoading(false);
                }
            });
        } catch (e) {
            console.log("Error while getting Practitioner getProfileResumes: ", e);
        }
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {

            const maxSize = 10485760; //10 MB
            const validSize = validateImageSize(selectedFile, maxSize);
            if (validSize === 1) {
                const allowedFileTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "text/plain"];

                if (!allowedFileTypes.includes(selectedFile.type)) {
                    // Issue a warning if the file type is not allowed
                    showErrorMessage("Please select a valid file type (PDF, DOC, DOCX, TXT).");
                    return;
                }

                // Perform any necessary validation or processing of the selected file here
                setIsFileUploaing(true);

                // Submit the file to the API using your preferred method (e.g., fetch, axios)
                PractitionerService.uploadResumePractitioner(practitionerGuid, selectedFile)
                    .then((response) => {
                        const { status } = response;
                        if (status === 201) {
                            showSuccessMessage("Resume uploaded Successfully");
                            getProfileResumes(practitionerGuid);
                            setIsFileUploaing(false);
                        }
                        else {
                            showErrorMessage(FAILTOSAVERECOED);
                            setIsFileUploaing(false);
                        }
                    })
            } else if (validSize === -1) {
                showErrorMessage("Invalid File!");
            } else {
                showErrorMessage("File must be less than 10 MB");
            }
        }
    };

    useEffect(() => {
        if (isAdmin() || practitionerGuid === currentUser?.guid)
            setIsEditable(true);
        getProfileResumes(practitionerGuid);
    }, [practitionerGuid])

    return (
        <>
            <Card className="nexogic-widget-sidebar-card document-sidebar-widget">
                <h2 className="nex-aside-title bg-light-yellow">Resumes <span className="icon ml-auto"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.25 6.75H12.25M6.25 9.75H12.25M6.25 12.75H9.25M2.875 9.75C2.875 4.875 4.375 3.375 9.25 3.375C14.125 3.375 15.625 4.875 15.625 9.75C15.625 14.625 14.125 16.125 9.25 16.125C4.375 16.125 2.875 14.625 2.875 9.75Z" stroke="black" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                </span></h2>
                <CardBody>
                    {isLoading ?
                        <div className="text-center mb-5" style={{ height: "10px" }} >
                            <CommonLoader />
                        </div>
                        :
                        <>
                            {(listResumes && listResumes.length > 0) ?
                                <div className="nexogic-custom-scroll">
                                    <div className="nexogic-document-lists">
                                        <ul>
                                            {listResumes.map((doc, index) => (
                                                <ResumeInfo docId={doc?.id} doc={doc} practitionerGuid={practitionerGuid} getProfileResumes={getProfileResumes} />
                                            ))}
                                        </ul>

                                    </div>
                                </div>
                                :
                                <div className="nexogic-not-found-text">No Resume</div>
                            }
                        </>
                    }
                    <div className="action">
                        {isEditable &&
                            <>
                                {isFileUploaing ?
                                    <div className="text-center mb-5" style={{ height: "10px" }} >
                                        <CommonLoader />
                                    </div>
                                    :
                                    <button className="btn nexogic-download-btn nex-btn-primary"
                                        onClick={handleUploadClick}
                                    >
                                        <span className="icon"><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="none"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                stroke="#fff"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M15 10.333v3.111A1.556 1.556 0 0113.444 15H2.556A1.556 1.556 0 011 13.444v-3.11m10.889-5.445L8 1m0 0L4.111 4.889M8 1v9.333"
                                            ></path>
                                        </svg>
                                        </span> Upload Resume
                                    </button>
                                }

                                <input
                                    name="uploadDocument"
                                    type="file"
                                    id="uploadDocument"
                                    accept=".pdf,.doc,.docx,.rtf,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    title="Upload Document"
                                    className="nexogic-file-upload-input"
                                    style={{ display: 'none' }}
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                />
                            </>
                        }
                    </div>
                </CardBody>
            </Card>

        </>
    );
}

export default withAlert()(PractitionerResumes);