import React, { useEffect, useState } from 'react';
import { FormGroup, Input, Label } from "reactstrap";
import { ErrorMessage, Field, getIn } from 'formik';
import PhoneInput from "react-phone-input-2";


const FormikMobileField = ({ field: { ...fields }, form: { touched, errors, handleBlur, setFieldValue, setFieldError, setErrors, setTouched, ...rest }, ...props }) => {



    const onHandleChange = (value, country, e, formattedValue) => {
        setFieldValue(fields.name, formattedValue);
    }

    const onHandleIsValid = (value, country, countries, hiddenAreaCode) => {
        return true
    }

    const countryCode = ['in']; //['in', 'fr', 'at']
    const countryMask = { in: '..........' }; //{ in: '..........', fr: '(...) ..-..-..', at: '(....) ...-....' }

    return (

        <FormGroup className="flex-grow-1">
            {props.label && <Label htmlFor={props.id} className={"label-color"}>{props.label}</Label>}
            <PhoneInput
                {...props}
                {...fields}
                country={"in"}
                onlyCountries={countryCode}
                masks={countryMask}
                value={props.value}
                containerClass="phone-input-container"
                inputClass={
                    (touched[fields.name] && errors[fields.name])
                        ? "form-control-input  is-invalid"
                        : "form-control-input"
                }
                inputProps={{
                    name: fields.name,
                    required: true,
                    id: props.id
                }}
                countryCodeEditable={false}
                onChange={onHandleChange}
                isValid={onHandleIsValid}
            />
            <ErrorMessage name={fields.name} render={msg => <span className="text-danger f-12 d-inline-block  mt-1 line-height-error">{msg}</span>} />
        </FormGroup>
    )
};
export default FormikMobileField;