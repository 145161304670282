import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Progress,
  Row,
} from "reactstrap";
import tick_icon from "../../../../../assets/images/tick-color-icon.png";
import gallery_add from "../../../../../assets/images/gallery-add-img-1.jpg";
import plus_lg from "../../../../../assets/images/plus-icon-lg.png";
import attach_2 from "../../../../../assets/images/modal-attach-2.png";
import closeQuestion from "../../../../../assets/images/close-question.png";
import PublishMenu from "../../PublishMenu";
import PostUploadDocument from "./PostUploadDocument";
const PatientCasesForm = ({ toggle }) => {
  const [casestudy, setCasestudy] = useState(1);

  const radioToggle = (status) => {
    setCasestudy(status);
  };

  return (
    <Row>
      <Col md={6}>
        <label className="poll-option-part">
          <input
            id="pcf_poll-test-1"
            type="radio"
            name="test"
            value="small"
            checked={casestudy === 1}
            onClick={(e) => radioToggle(1)}
          />
          <span className="option-name">
            <span className="option-head">
              <span> Second opinion</span>{" "}
              <img src={tick_icon} alt="tick-color-icon" className="tickicon" />
            </span>
            <span className="option-text">Get help with this case</span>
          </span>
        </label>
      </Col>
      <Col md={6}>
        <label className="poll-option-part">
          <input
          id="pcf_poll-test-2"
            type="radio"
            name="test"
            value="big"
            checked={casestudy === 2}
            onClick={(e) => radioToggle(2)}
          />
          <span className="option-name">
            <span className="option-head">
              <span> Case study</span>{" "}
              <img src={tick_icon} alt="tick-color-icon" className="tickicon" />
            </span>
            <span className="option-text">Share this case as a study</span>
          </span>
        </label>
      </Col>

      {casestudy === 1 && (
        <Col md={12}>
          <Col md={12}>
            <FormGroup>
              <Label for="Title">Title</Label>
              <Input id="Title" name="text" type="text" placeholder="Title" />
            </FormGroup>
          </Col>
          <Col md={12}>
            <Input
              id="exampleText"
              name="text"
              type="textarea"
              placeholder="What would you like to say?"
            />
          </Col>
          {/* start gallery */}
          <Card className="uploaded-img-wrp mt-3">
            <CardBody>
              <div className="remove-image">Remove images</div>

              <div className="galley-card-wrp">
                <div className="gallery-card">
                  <img src={gallery_add} alt="#" className="gl-lg-img" />
                  <Button id="pcf_del-btn" className="delete-btn">
                    <img src={closeQuestion} alt="close Question" />
                  </Button>
                </div>
                <div className="gallery-card">
                  <img src={gallery_add} alt="#" className="gl-lg-img" />
                  <Button id="pcf_del-btn2" className="delete-btn">
                    <img src={closeQuestion} alt="close Question" />
                  </Button>
                </div>
                <div className="gallery-card">
                  <img src={gallery_add} alt="#" className="gl-lg-img" />
                  <Button id="pcf_del-btn3" className="delete-btn">
                    <img src={closeQuestion} alt="close Question" />
                  </Button>
                </div>
                <div className="gallery-card add-card">
                  <img src={plus_lg} alt="plus-icon-lg" />
                </div>
              </div>
            </CardBody>
          </Card>
          {/* documents */}
          <div className="documents-uploaded">
            <div className="document-item">
              <div className="document-img">
                <img src={attach_2} alt="#" />
              </div>
              <p className="dc-name">
                Lorem ipsum dolor sit amet, consectetur adipiscing .pdf
              </p>
              <span className="file-size">4 MB</span>
              <span className="delete-file ml-1">
                <img src={closeQuestion} alt="close Question" />
              </span>
            </div>
            <div className="document-item">
              <div className="document-img">
                <img src={attach_2} alt="#" />
              </div>
              <p className="dc-name">
                Lorem ipsum dolor sit amet, consectetur adipiscing .pdf
              </p>

              <span className="upload-process">
                <div className="uploaded-amount">158/190 MB</div>
                <Progress value={50} />
              </span>
            </div>
          </div>
        </Col>
      )}
      {casestudy === 2 && (
        <Col md={12}>
          <div className="form-group">
            <PublishMenu />
          </div>
        </Col>
      )}
      <Col md={12}>
        <PostUploadDocument />
        <div className="d-flex align-items-center justify-content-center">
          <Button
            onClick={toggle}
            type="button"
            color="danger"
            className="cancel-btn"
          >
            Cancel
          </Button>
          <Button color="primary" className="post-btn ml-3">
            Save
          </Button>
        </div>
      </Col>
    </Row>
  );
};
export default PatientCasesForm;
