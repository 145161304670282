import React from "react";
import { Col, FormGroup, Row } from "reactstrap";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { createImageFromInitials, getFullAddress, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import { Field, Form } from "formik";
import AddressInstitution from "pages/Profile/components/DoctorsDetails/components/AddressInstitution";
import graduation_cap from "assets/images/graduation-cap.png";
import suitcase_icon from "assets/images/suitcase.png";
import marker_icon from "assets/images/pin-small.png";
import { useEffect } from "react";
import { useState } from "react";

const ContactInfo = (props) => {
  const { formData, setFormData, handleSubmit, practitioner } = props;
  const [fullName, setFullName] = useState("");
  useEffect(() => {
    let temp = `${practitioner?.firstName}${" "}${practitioner?.lastName}`
    setFullName(temp);
  }, [practitioner])

  const {
    title,
    firstName,
    middleName,
    lastName,
    address,
    phones,
    emails,
    qualification,
    primarySpeciality,
    superSpeciality,
  } = practitioner;

  const getDefaultValue = (items) => {
    if (items && items.length > 0) {
      return {
        list: items.map((item) => { return item.value }),
        primayItem:
          items.findIndex((node) => node.primary === true) !== -1
            ? items.findIndex((node) => node.primary === true).toString()
            : "",
      };
    }
    return {
      list: [],
      primayItem: "",
    };
  };
  return (
    <Form className='form-container' onSubmit={handleSubmit}>
      <div className="nexogic-job-popup">
        <h4 className="form-title d-none">Contact Info</h4>
        <div className="card no-bg border-0">
          <div className="card-body d-flex px-0">
            <div className="nex-avatar-radius">
              {(practitioner && practitioner?.avatarId) ?
                < img
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${practitioner.guid}/${practitioner.avatarId}`}
                  className="card-img rounded-circle" alt="#" />
                :
                < img
                  src={createImageFromInitials(50, fullName, getRandomColor(encrypt(practitioner?.guid).toString()))}
                  className="card-img rounded-circle" alt="#" />
              }
            </div>
            <div className="info px-3">
              <h3 className="nex-text-xl ">{title} {firstName ?? ""} {middleName ?? ""} {lastName ?? ""}</h3>
              <p className="nex-text-lg bold nex-text-blue">{primarySpeciality ?? ""} , {superSpeciality ?? ""}</p>
              <p className="nex-text-lg nex-flex"><div className="icon"><img src={graduation_cap} alt="" /></div> {qualification}</p>
              <p className="nex-text-lg nex-flex"><div className="icon"><img src={suitcase_icon} alt="" /></div> 23+ Experience</p>
            </div>
          </div>
          <div className="nex-flex flex-wrap nex-job-apply-meta">
            <div className="phone-info">
              <div className="item"><div className="icon"><svg
                width={22}
                height={22}
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.41406 2.7474C5.9078 2.7474 5.4974 3.1578 5.4974 3.66406V18.3307C5.4974 18.837 5.9078 19.2474 6.41406 19.2474H15.5807C16.087 19.2474 16.4974 18.837 16.4974 18.3307V3.66406C16.4974 3.1578 16.087 2.7474 15.5807 2.7474H6.41406ZM3.66406 3.66406C3.66406 2.14528 4.89528 0.914062 6.41406 0.914062H15.5807C17.0995 0.914062 18.3307 2.14528 18.3307 3.66406V18.3307C18.3307 19.8495 17.0995 21.0807 15.5807 21.0807H6.41406C4.89528 21.0807 3.66406 19.8495 3.66406 18.3307V3.66406Z"
                  fill="#5B5B5B"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.0859 16.5026C10.0859 15.9963 10.4963 15.5859 11.0026 15.5859H11.0118C11.518 15.5859 11.9284 15.9963 11.9284 16.5026C11.9284 17.0089 11.518 17.4193 11.0118 17.4193H11.0026C10.4963 17.4193 10.0859 17.0089 10.0859 16.5026Z"
                  fill="#5B5B5B"
                />
              </svg></div> {formData.phonenumber}</div>
              <div className="item"><div className="icon"><svg
                width={22}
                height={22}
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.66406 4.58333C3.16199 4.58333 2.7474 4.99793 2.7474 5.5V16.5C2.7474 17.0021 3.16199 17.4167 3.66406 17.4167H18.3307C18.8328 17.4167 19.2474 17.0021 19.2474 16.5V5.5C19.2474 4.99793 18.8328 4.58333 18.3307 4.58333H3.66406ZM0.914062 5.5C0.914062 3.98541 2.14947 2.75 3.66406 2.75H18.3307C19.8453 2.75 21.0807 3.98541 21.0807 5.5V16.5C21.0807 18.0146 19.8453 19.25 18.3307 19.25H3.66406C2.14947 19.25 0.914062 18.0146 0.914062 16.5V5.5Z"
                  fill="#5B5B5B"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.07985 4.97701C1.37017 4.56226 1.94174 4.4614 2.35648 4.75172L10.9975 10.8004L19.6385 4.75172C20.0532 4.4614 20.6248 4.56226 20.9151 4.97701C21.2054 5.39175 21.1046 5.96332 20.6898 6.25365L11.5231 12.6703C11.2075 12.8913 10.7874 12.8913 10.4718 12.6703L1.30513 6.25365C0.890389 5.96332 0.789524 5.39175 1.07985 4.97701Z"
                  fill="#5B5B5B"
                />
              </svg>
              </div>{formData.emailid}</div>
            </div>
            <div className="pharmasist-address">
              <p className="nex-text-lg bold text-black">
                <AddressInstitution
                  city={address[0]?.city ?? ""}
                  state={address[0]?.state ?? ""}
                  country={
                    address[0]?.country ?? ""
                  }
                />
              </p>
            </div>
          </div>
        </div>
        {/*<Row>
          <Col md={12}>
            <FormGroup>
              <Field
                id="ci_phonenumber"
                name="phonenumber"
                type="text"
                label="Phone Number"
                component={FormikSelectField}
                value={formData.phonenumber}
                onChange={(event) =>
                  setFormData({ ...formData, phonenumber: event.target.value })
                }
                inputprops={{
                  name: `phonenumber`,
                  options: getDefaultValue(phones).list,
                }}
              />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <Field
                id="ci_emailid"
                name="emailid"
                type="text"
                label="Email Address"
                component={FormikSelectField}
                value={formData.emailid}
                onChange={(event) =>
                  setFormData({ ...formData, emailid: event.target.value })
                }
                inputprops={{
                  name: `emailid`,
                  options: getDefaultValue(emails).list,
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        */}
      </div>
    </Form>
  );
};

export default ContactInfo;
