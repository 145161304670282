import React from 'react'
import ortho_img from 'assets/images/hospital-icon-ortho.png'
import IconMarker from './icons/IconMarker'
import IconGlob from './icons/IconGlob'
import IconCall from './icons/IconCall'
import { CardBody } from 'reactstrap'

export default function AsideOrthopedists() {
  return (
    <div className='card nexogic-widget-sidebar-card '>
        <h2 className="card-title h2 mb-2">Hospitals with top rated orthopedists</h2>
        <CardBody>
            <div className="nexogic-orthopedist-lists">

            
        <div className="item">
            <div className="card-graphic">
                <img src={ortho_img} alt="" />
            </div>
            <div className="info">
                <h3 className="card-title">MedRite Hospital</h3>
                <p className='card-text'><span className='icon'><IconMarker /></span>98, HAL Airport Road, Bangalore</p>
                <p className='card-text'><span className="icon"><IconGlob /></span><a href="www.pennmedicine.org">www.pennmedicine.org</a></p>
                <p className='card-text'><span className="icon"><IconCall /></span>+1 (323)  307-7751</p>
            </div>
        </div>
        <div className="item">
            <div className="card-graphic">
                <img src={ortho_img} alt="" />
            </div>
            <div className="info">
                <h3 className="card-title">MedRite Hospital</h3>
                <p className='card-text'><span className='icon'><IconMarker /></span>98, HAL Airport Road, Bangalore</p>
                <p className='card-text'><span className="icon"><IconGlob /></span><a href="www.pennmedicine.org">www.pennmedicine.org</a></p>
                <p className='card-text'><span className="icon"><IconCall /></span>+1 (323)  307-7751</p>
            </div>
        </div>
        <div className="item">
            <div className="card-graphic">
                <img src={ortho_img} alt="" />
            </div>
            <div className="info">
                <h3 className="card-title">MedRite Hospital</h3>
                <p className='card-text'><span className='icon'><IconMarker /></span>98, HAL Airport Road, Bangalore</p>
                <p className='card-text'><span className="icon"><IconGlob /></span><a href="www.pennmedicine.org">www.pennmedicine.org</a></p>
                <p className='card-text'><span className="icon"><IconCall /></span>+1 (323)  307-7751</p>
            </div>
        </div>
        </div>
        </CardBody>
    </div>
  )
}
