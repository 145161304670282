import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import CommonLoader from "common/CommonLoader";
import CityAutocompleteTypeaheadField from "components/forms/formikFields/CityAutocompleteTypeaheadField";
import CommonMultipleSelectTypeaheadField from "components/forms/formikFields/CommonMultipleSelectTypeaheadField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
// import JobCompanyTypeaheadField from "components/forms/formikFields/JobCompanyTypeaheadField";
import InstitutionsListTypeaheadField from "components/forms/formikFields/InstitutionsListTypeaheadField";
import LocationTypeaheadField from "components/forms/formikFields/LocationTypeaheadField";
import PrimarySpecialityTypeaheadField from "components/forms/formikFields/PrimarySpecialityTypeaheadField";
import { ErrorMessage, Field } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";
import { isAdmin, isInstitutionAdmin, isPractitioner } from "utils/UserHelper";
import { useEffect, useRef, useState, useMemo } from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useSelector } from "react-redux";
import RenderAboutForm from "pages/Profile/components/DoctorsDetails/components/About/components/RenderAboutForm";
import ReactQuill from "react-quill";
import { Tooltip } from 'reactstrap';
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import {
  APPLY_MODE_LIST,
  JOB_PositionTypesList,
  JOB_Work_Shift_TypesList,
  Opportunity_Type,
} from "utils/Constants";
import { MetadataService } from "utils/MetadataService";
import { PractitionerService } from "utils/PractitionerService";
import { makeFullNameWithAddress, makeFullNameWithAddressWhenAddressIsNested } from "utils/Utils";
import { InstitutionServices } from "utils/InstitutionServices";
import MoreSkills from "./MoreSkills";
import { sample } from "lodash";
import SkillsBulkEntry from "./SkillsBulkEntry";
import ToolTip from "common/ToolTip";

const FormStep1 = (mainProps) => {
  const { fetchLoading } = mainProps;
  return (
    <Row>
      <Col md={12}>
        <Card className="form-step1-wrapper bg-white">
          <CardBody className="formWrap">
            {fetchLoading && (
              <div className="loaderWrap">
                <CommonLoader />
              </div>
            )}
            <RenderForm {...mainProps} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default FormStep1;

const RenderForm = (props) => {
  const locRef = useRef();
  const [address, setAddress] = useState({});
  const [locationList, setLocationList] = useState([]);
  const [degreeList, setdegreeList] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [hospital, setHospital] = useState('');
  const [primarySpecialityList, setPrimarySpecialityList] = useState([]);
  const [allskillsList, setallskillsList] = useState([]);
  const [skillsList, setSkillsList] = useState([]);
  const textInput = useRef();
  const { values, handleSubmit, setFieldValue, touched, errors, loading } = props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  const isAdminUser = useMemo(() => isAdmin());
  const isPractitionerUser = useMemo(() => isPractitioner());
  const isInstitutionAdminUser = useMemo(() => isInstitutionAdmin());
  const [isOpen, setIsOpen] = useState(false);
  const skillsRef = useRef(null);
  const toggle = () => setIsOpen(!isOpen);
  const [addInBulk, setAddInBulk] = useState(false);
  const handleClick = () => {
    setAddInBulk(!addInBulk);
  };


  const getHospitalList = () => {
    if (isPractitionerUser)
      PractitionerService.listExperiences(currentUser?.guid)
        .then((response) => {
          if (response.status === 200) {
            setHospitalList(
              [...response.data].map((node) => {
                return {
                  ...node,
                  institutionName: makeFullNameWithAddressWhenAddressIsNested(node?.institutionDetail),
                  name: node?.institutionDetail?.name,
                  institutionId: node?.institutionDetail?.guid,
                };
              })
                .filter((nodeItem) => nodeItem?.presentlyWorking)
            );
          }
        })
        .catch((err) => {
          console.log(err.message);
          showErrorMessage(err.message);
        });
    if (isInstitutionAdminUser) {
      const insts = currentUser.groups;

      // Create an array of promises for each institution ID
      const promises = insts.map(instId => InstitutionServices.getInstitutionById(instId));

      // Use Promise.all to wait for all promises to resolve
      Promise.all(promises)
        .then((responses) => {
          // Filter successful responses and map them to the required format
          const hospitalList = responses
            .filter(response => response.status === 200)
            .map(response => {
              const node = response.data;
              return {
                ...node,
                institutionName: makeFullNameWithAddress(node),
                name: node?.name,
                institutionId: node?.guid,
              };
            });

          // Set the hospital list
          setHospitalList(hospitalList);
        })
        .catch((err) => {
          console.log(err.message);
          showErrorMessage(err.message);
        });
    }

  }
  const { city, applyMode, primarySpeciality } = values;
  const selectdLocation = (locationName) => {
    setAddress({
      ...address,
      location: locationName,
    });
  };

  // const setDefaultAddress = (cityId) => {
  //   console.log("setDefaultAddress+++++++++++++++++++++++++++++++++++++++",cityId,city,values);
  //   if (cityId) {
  //     setLocationList([]);
  //     new Promise(function (resolve, reject) {
  //       MetadataService.getCities(cityId).then((response) => {
  //         if (response.status === 200 || response.status === 201) {
  //           resolve(response.data);
  //         }
  //       });
  //     });
  //   }
  // };

  const getprimarySpeciality = () => {
    MetadataService.getPrimarySpecialities()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setPrimarySpecialityList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getprimarySpeciality error", error);
      });
  };

  const getAllSkillSet = (primarySpecialityItem) => {
    MetadataService.getSpecialitySkillSet(primarySpecialityItem)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setallskillsList(response.data);
        }
      })
      .catch((error) => {
        console.log("MetadataService.getSpecialitySkillSet error", error);
      });
  };

  useEffect(() => {
    getHospitalList();
  }, []);

  // useEffect(() => {
  //   if(!city)
  //   setDefaultAddress(city);
  // }, [city,values]);

  useEffect(() => {
    MetadataService.getDegree()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setdegreeList(response.data);
        }
      })
      .catch((error) => {
        console.log("MetadataService.getDegree error", error);
      });
  }, []);

  useEffect(() => {
    if ((city && city.length === 0) || city[0]?.id === "") {
      setFieldValue(`location`, []);
    } else {
      if (city[0]?.id !== "") {
        MetadataService.getLocations(city[0]?.id).then((response) => {
          if (response.status === 200 || response.status === 201) {
            setLocationList(response.data);
          }
        });
      }
    }
  }, [city, setFieldValue]);

  useEffect(() => {
    getprimarySpeciality();
  }, []);

  useEffect(() => {
    if (primarySpeciality && primarySpeciality.length > 0) {
      getAllSkillSet(primarySpeciality[0]);
    } else {
      setFieldValue(`skills`, []);
    }
  }, [primarySpeciality, setFieldValue]);

  const onChangeHandler = (campaign) => {
    const preData = campaign.map((item) => {
      let clone = item;
      let custom = item?.customOption ? true : false;
      if (custom) {
        clone = item?.name;
      }
      return clone;
    });
    preData.length ? setFieldValue("skills", preData, true) : setFieldValue("skills", [], true);
  };
  return (
    <form className="Institution-vacancy-form" onSubmit={handleSubmit}>
      <>
        <Row>
          <Col md={6}>
            <FormGroup>
              <div className="form-group" id="nex-jf1-hospital-inp-w">
                {(!isAdminUser) ? (
                  <>
                    <label htmlFor="institutionDetail">Hospital/Clinic
                      <ToolTip id="hospital_clinic_field" place="right">Please Enter Hospital / Clinic</ToolTip>
                    </label>
                    <Field
                      name="institutionDetail"
                      id="institutionDetail"
                      type="text"
                      component={FormikSelectField}
                      inputprops={{
                        name: `institutionDetail`,
                        options: hospitalList,
                        defaultOption: "Select a Hospital/Clinic",
                        keys: {
                          id: "institutionId",
                          label: "institutionName",
                        },
                      }}
                    />
                  </>
                ) : (
                  <>
                    <label htmlFor="institutionDetail">Hospital/Clinic
                      <ToolTip id="hospital_clinic_field" place="right">Please Enter Hospital / Clinic</ToolTip></label>
                    <Field
                      name="institutionDetail"
                      id="institutionDetail"
                      autoComplete="institutionDetail"
                      type="text"
                      component={InstitutionsListTypeaheadField}
                      placeholder="Hospital/Clinic"
                    />
                  </>
                )}
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label htmlFor="noOfPosition">Number of Positions <ToolTip id="job_totalpositions_field" place="right">Number of Positions</ToolTip></label>
              <Field
                id="noOfPosition"
                name="noOfPosition"
                autoComplete="noOfPosition"
                type="number"
                min="1"
                component={FormikInputField}
                placeholder="Number of Positions"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <label htmlFor="opportunity">Type <ToolTip id="type_field" place="right">Please Enter Type</ToolTip></label>
              <Field
                id="opportunity"
                name="opportunity"
                type="text"
                component={FormikSelectField}
                inputprops={{
                  name: `opportunity`,
                  options: Opportunity_Type,
                  defaultOption: "Select",
                  keys: {
                    id: "value",
                    label: "label",
                  },
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label htmlFor="position">Position <ToolTip id="position_field" place="right">Please Enter Position</ToolTip></label>
              <Field
                id="position"
                name="position"
                type="text"
                component={FormikSelectField}
                inputprops={{
                  name: `position`,
                  options: JOB_PositionTypesList,
                  defaultOption: "Select",
                  keys: {
                    id: "value",
                    label: "label",
                  },
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <label htmlFor="title">Job Title <ToolTip id="job_title_field" place="right">Please Enter Job Title</ToolTip></label>
          <Field
            name="title"
            id="title"
            type="text"
            component={FormikInputField}
            placeholder="Job Title"
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="role">Role <ToolTip id="role_field" place="right">Please Enter Role</ToolTip></label>
          <Field
            name="role"
            id="role"
            type="text"
            component={FormikInputField}
            placeholder="Role"
            autoComplete="role"
          />
        </FormGroup>
        <FormGroup>
          <Label for="Company">Description <ToolTip id="description_field" place="right">Please Enter Description</ToolTip></Label>
          <ReactQuill
            id="j_description"
            onChange={(html) => {
              setFieldValue("description", html, true);
            }}
            ref={textInput}
            value={values.description || ""}
            bounds={".app"}
            modules={RenderAboutForm.modules}
            formats={RenderAboutForm.formats}
          />
          {!!touched &&
            !!touched?.description &&
            !!errors &&
            !!errors?.description &&
            typeof errors.description === "string" && (
              <FormFeedback className="d-flex" invalid={!!errors?.description}>
                {errors.description}
              </FormFeedback>
            )}
        </FormGroup>
        <FormGroup id="nex-jf-primary-speciality">
          <label htmlFor="primarySpeciality">Primary Speciality <ToolTip id="primary_speciality_field" place="right">Please Enter Primary Speciality</ToolTip></label>
          <Field
            id="primarySpeciality"
            name="primarySpeciality"
            autoComplete="primarySpeciality"
            // label="Primary Speciality"
            className="primary-specialities"
            type="text"
            component={PrimarySpecialityTypeaheadField}
            placeholder="Select Speciality"
            inputprops={{
              autoComplete: "off",
            }}
            value={values.primarySpeciality}
            spellCheck={false}
            specialit={primarySpecialityList}
            allowNew={true}
          />
        </FormGroup>
        <FormGroup className="sec-header-item-wrap" id="nex-jf-req-skills">
          <label htmlFor="skills" className="">
            <span>Required Skills <ToolTip id="required_skills_field" place="right">Please Select Required Skills</ToolTip></span> <span className="btn btn-sm" color="dark" type="button" onClick={toggle} id="nex-jf-add-skills"><span className="plus-icon">+</span> <small>Add Skills </small></span></label>
          {/* <div className="bg-primary"> */}
          <Row>
            <Col md={12}>
              <Typeahead
                ref={skillsRef}
                allowNew
                clearButton
                newSelectionPrefix="Add Skills: "
                selected={values.skills}
                // selected={skillsList}
                id="skills"
                name="skills"
                multiple
                options={allskillsList}
                labelKey={"name"}
                placeholder="Add Required Skills "
                onBlur={() => {
                  return skillsRef !== null
                    ? skillsRef.current.hideMenu()
                    : null;
                }}
                onChange={onChangeHandler}
                disabled
              />
              <ErrorMessage name="skills" render={msg => <span className="text-danger skill-err f-12 d-inline-block  mt-1 line-height-error">{msg}</span>} />
              {/* <Field
            id="skills"
            name="skills"
            type="text"
            newSelectionPrefix="Add Skill: "
            component={CommonMultipleSelectTypeaheadField}
            placeholder="Select Required Skills"
            spellCheck={false}
            allowNew={true}
            inputOptions={allskillsList}
            multiple={true}
            autoComplete="skills"
          /> */}
            </Col>

          </Row>
          {/* </div> */}
        </FormGroup>
        {/* <FormGroup>
          <Field
            id="skills"
            name="skills"
            label="Required Skills"
            type="text"
            component={CommonMultipleSelectTypeaheadField}
            placeholder="Select Required Skills"
            spellCheck={false}
            inputOptions={allskillsList}
            multiple={true}
            autoComplete="skills"
          />
        </FormGroup> */}
        <FormGroup id="nex-jf-req-qualifications">
          <Label for="qualifications">Required Qualifications <ToolTip id="required_qualifications_field" place="right">Please Enter Required Qualifications</ToolTip></Label>
          <Field
            id="qualifications"
            name="qualifications"
            className="language-suggest"
            type="text"
            component={CommonMultipleSelectTypeaheadField}
            placeholder="Select"
            spellCheck={false}
            inputOptions={degreeList}
            multiple={true}
            autoComplete="qualifications"
          />
        </FormGroup>
        <FormGroup>
          <Label for="shift">Work Shift <ToolTip id="work_shift_field" place="right">Please Enter Work Shift</ToolTip></Label>
          <Field
            id="shift"
            name="shift"
            type="text"
            component={FormikSelectField}
            inputprops={{
              name: `shift`,
              options: JOB_Work_Shift_TypesList,
              defaultOption: "Select",
              keys: {
                id: "value",
                label: "label",
              },
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="applyMode">Apply Mode <ToolTip id="apply_mode_field" place="right">Please Enter Apply Mode</ToolTip></Label>
          <Field
            id="applyMode"
            name="applyMode"
            type="text"
            component={FormikSelectField}
            inputprops={{
              name: `applyMode`,
              options: APPLY_MODE_LIST,
              defaultOption: "Select",
              keys: {
                id: "value",
                label: "label",
              },
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="linkToApply">{applyMode === "EMAIL" ? "Email" : applyMode === "LINK" ? "External Link" : "Link to apply"} <ToolTip id="link_to_apply_field" place="right">Please Enter {applyMode === "EMAIL" ? "Email" : applyMode === "LINK" ? "External Link" : "Link to apply"}</ToolTip></Label>
          <Field
            id="linkToApply"
            name="linkToApply"
            type="text"
            component={FormikInputField}
            placeholder={
              applyMode === "EMAIL"
                ? "Email"
                : applyMode === "LINK"
                  ? "External Link"
                  : "Link to apply"
            }
          // label={
          //   applyMode === "EMAIL"
          //     ? "Email"
          //     : applyMode === "LINK"
          //       ? "External Link"
          //       : "Link to apply"
          // }
          />
        </FormGroup>
        <FormGroup className="mb-0">
          <Row>
            <Col md={6} id="nex-jf-city">
              <Label for="city">City <ToolTip id="city_field" place="right">Please Enter City</ToolTip></Label>
              <Field
                name="city"
                id="city"
                autocomplete="city"
                type="text"
                component={CityAutocompleteTypeaheadField}
                placeholder="City"
              />
            </Col>
            <Col md={6} id="nex-jf-location">
              <Label for="location">Location <ToolTip id="location_field" place="right">Please Enter Location</ToolTip></Label>
              <Field
                id="location"
                name="location"
                type="text"
                component={LocationTypeaheadField}
                value={values.location}
                placeholder="Select Location"
                spellCheck={false}
                locations={locationList}
                selectdLocation={selectdLocation}
                locationRef={locRef}
              />
            </Col>
          </Row>
        </FormGroup>
        <Row className="justify-content-center">
          {loading ? (
            <div className="loading-spiner text-center">
              <CommonLoader />
            </div>
          ) : (
            <Col className="text-right nex-mob-top-gap">
              <Button id="fs_1_btn_next" className="nex-btn-primary btn-150" color="primary" type="submit">
                Next
              </Button>
            </Col>
          )}
        </Row>
        <div>
          <Modal isOpen={isOpen} toggle={toggle} id="profile-modal" className="skills-modal modal-dialog-scrollable modal-dialog-centered skills-expertise-modal" backdrop="static">
            <ModalHeader toggle={toggle}>
              <div className="modal-filter-header">
                {`Skills`}
                <span className="ml-auto" >
                  <label className="mb-0 mr-2">Add in Bulk</label>
                  <input id="fs_1_add_in_bulk" type="checkbox" checked={addInBulk} onChange={() => handleClick()} />
                </span>
              </div>
            </ModalHeader>
            <ModalBody>
              {addInBulk
                ?
                <SkillsBulkEntry
                  allskillsList={allskillsList}
                  toggle={toggle}
                  setSkillsList={setSkillsList}
                  {...props}
                  primarySpeciality={primarySpeciality[0]}
                />
                :
                <MoreSkills
                  allskillsList={allskillsList}
                  toggle={toggle}
                  setSkillsList={setSkillsList}
                  {...props}
                  primarySpeciality={primarySpeciality[0]}
                />
              }
            </ModalBody>
          </Modal>
        </div>
      </>
    </form>
  );
};
