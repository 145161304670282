import React from "react"

import tickIcon from "assets/images/onboarding/tick.svg"
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Stepper = ({steps,userdet,onNextStep}) => {
  return (
    <ul className="nexogic-stepper-container nex-form-steps-lists">
      {steps.map((step, index) => {
        let active = step.status === "INPROGRESS",
            isCompleted = step.status === "COMPLETED",
            isSkipped = step.status === "SKIPPED"

        return (
        <li key={`${step.key}`} className={` step ${active? 'active current' : isCompleted? 'cactive completed': isSkipped? 'sactive':''}`}>
          <div><div className="nex-indicator"></div> {step.name}</div>
          {/* 
          <div className="d-flex flex-row align-items-center">
            <div className="step-content">
            <div className={`${step.status === "INPROGRESS" ? "step-inprogress" : "step-heading"}`}>
              <div className="count-inner">
                {isCompleted?
                  <FontAwesomeIcon size="sm" icon={faCheck} />
                :
                <span>{index+1}</span>
                }
              </div>
              {step.name}
              </div>
              {step.status &&
                step.status === "INPROGRESS" ?
                <div className="status inprogress">In Progress</div>
                : isCompleted ? <div className="status completed">Completed</div>
                : step.status === "SKIPPED" ? <div className="status skipped">Skipped</div> 
                :null
              }
            </div>
          </div>
          {index !== steps.length-1 &&
            <div className="step-path">
              <div className="step-vline m-auto"></div>
            </div>
          }
          */}
        </li>
      )})}
    </ul>
  )
}

export default Stepper