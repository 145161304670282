import React from "react";

function IconPrev() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="14"
      fill="none"
      viewBox="0 0 8 14"
    >
      <path
        fill="#001244"
        d="M6.845 13.007l1.024-1.023L2.874 7 7.87 2.005 6.857.992 1.355 6.494.838 7l.517.506 5.49 5.501z"
      ></path>
    </svg>
  );
}

export default IconPrev;
