import React, { useEffect } from 'react'
import { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { useSelector } from 'react-redux';
import { FAILTOSAVERECOED } from 'utils/Constants';
import { ReferralService } from 'utils/ReferralService';
import { isInstitutionAdmin } from 'utils/UserHelper';
import swal from "sweetalert";
import { withAlert } from 'react-alert';
import { PractitionerService } from 'utils/PractitionerService';
import OpinionPractitionerSearchField from './Practitioner/OpinionPractitionerSearchField';
import { OpinionService } from 'utils/OpinionService';

export default withAlert()(function OpinionAssignModal(props) {
  const { referral, opinionDetails, getOpinionDetails, alert, selectedSpeciality } = props;
  const [selectedOption, setSelectedOption] = useState(null);
  const [practitioners, setPractitioners] = useState([]);

  const getAllPractitioner = (guid) => {
    let sq = `st=='Approved';ps=='${encodeURIComponent(selectedSpeciality)}';so==true`;
    PractitionerService.getPractitionersForOpinion(sq, 0)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          setPractitioners(data?.contents)
        } else {
          console.log("Something went wrong");
        }
      });
  }


  useEffect(() => {
    getAllPractitioner(referral?.referredToInstitution?.guid)
  }, [referral])


  const confirmAndUpdateAssignee = () => {
    swal("Are you sure you want to update the Assignee?", {
      buttons: ["cancel", "yes"],
    })
      .then((yes) => {
        if (yes)
          updateReferralAssignee();
      })
  }


  const updateReferralAssignee = () => {
    // console.log(selectedOption);
    var payload = {
      "practitionerAssignedTo": selectedOption?.value
    }

    OpinionService.assignOpinion(opinionDetails?.id, payload)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          getOpinionDetails(opinionDetails?.id);
          showSuccessMessage(`Opinion status updated Successfully.`);
          props.toggle();
        }
        else {
          showErrorMessage(FAILTOSAVERECOED);
        }
      })
  }



  return (
    <Modal
      isOpen={props.open}
      toggle={props.toggle}
    >
      <ModalHeader toggle={props.toggle}>
        Assign To
      </ModalHeader>

      {practitioners && practitioners.length > 0 ?
        <ModalBody>
          <OpinionPractitionerSearchField
            label="Select Practitioner"
            referral={referral}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            practitioners={practitioners}
          />

          <div className="form-group">
            <a href="" className="btn nexogic_primary_button" onClick={(e) => {
              e.preventDefault();
              // props.onAssign(true)
              confirmAndUpdateAssignee();
            }}>
              Assign
            </a>
          </div>

        </ModalBody>
        :
        <div className='nexogic-nodata-dotted m-4'>
          <div className='no-suggestion-text text-center'>There are no practitioners available to assign a opinion.</div>
        </div>
      }
    </Modal>
  )
}
);