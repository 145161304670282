import { ReferralService } from "utils/ReferralService";
import BackIcon from "assets/images/backIcon.png";
import { Row, Col, Button, CardBody, Card, Modal, ModalHeader, ModalBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import { useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useEffect } from "react";
import CommonLoader from "common/CommonLoader";
import { createImageFromInitials, getFileIcon, getFullAddressWithLocationCityAndState, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import { MedialService } from "utils/MediaService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FAILTOSAVERECOED } from "utils/Constants";
import MyReferralPreview from "../MyReferralPreview";
import QuickPractitioner from "components/QuickPractitioner/QuickPractitioner";


const MyReferralDetails = () => {

    let { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [pannel_one_on, setPannel_one_on] = useState(true);
    const [pannel_two_on, setPannel_two_on] = useState(true);
    const [pannel_three_on, setPannel_three_on] = useState(true);
    const [pannel_four_on, setPannel_four_on] = useState(true);
    const [referral, setReferral] = useState({});

    const [docsInfo, setDocsInfo] = useState([]);
    const [dropDownItem, setDropDownItem] = useState();
    const [documentType, setDocumentType] = useState(null);
    const [documentData, setDocumentData] = useState(null);
    const [modal, setModal] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);

    const history = useHistory();
    const handleBack = () => {
        history.goBack()
    }
    const toggle = () => {
        setModal(!modal);
    };

    const toggleForm = () => setModal(!modal);
    const getReferralDoc = async (id) => {
        try {
            setDataLoading(true);
            const { data, headers } = await MedialService.getMediaFile(id);
            const createContentType = headers["content-type"] === "application/octet-stream"
                ? "application/pdf"
                : headers["content-type"];
            let generateURL = `data:${createContentType};base64,${Buffer.from(data).toString("base64")}`;
            setDocumentData(generateURL);
            let docArray = [data, createContentType];
            setDocumentType(docArray);
            if (createContentType === "application/pdf") {
                setDataLoading(false);
                onOpenBase64(generateURL);
            }
            else {
                setDataLoading(false);
                toggleForm();
            }
        } catch (error) {
            console.error("Error retrieving referral document:", error);
        }
    };

    const onOpenBase64 = (base64URL) => {
        // const onOpenBase64 = () => {
        try {
            var win = window.open();
            win.document.write(`
            <iframe class="nexogic-iframe" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" 
              src="${base64URL}" frameborder="0" allowfullscreen></iframe>
          `);
        } catch (error) {
            console.error("Error opening base64 URL:", error);
            // Handle error, show error message, etc.
        }
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = documentData;
        let ext = documentType[1].split('/').pop();
        link.download = 'reports.' + ext;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
    };

    const getMyReferral = (id) => {
        ReferralService.getMyReferralById(id)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    // console.log("data", data);
                    setReferral(data);
                    setIsLoading(false);
                } else if (status === 400 || status === 404) {
                    handleBack();
                }
                else {
                    console.log("Error");
                }
            })
    }

    const getDocsInfo = () => {
        MedialService.getMedias("REFERRAL", id)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setDocsInfo(data);
                } else {
                    console.log(FAILTOSAVERECOED);
                }
            })
    }

    useEffect(() => {
        getMyReferral(id);
        // getReferralResponse(id);
        getDocsInfo();
    }, [id])

    // useEffect(() => {
    //     document.querySelectorAll('.nexogic-dashboard-card')[0].classList.remove('v2');
    // }, [id])


    return (
        <div className="complete-form nexogic-ref-details-page">
            <div className="routing-header d-flex justify-content-between align-items-center mb-3">
                <div className="nexogic-top-section-title">
                    <span><Link to="" onClick={handleBack}><img src={BackIcon} alt="back" /></Link></span>
                    <h2 className="h2">Patient referral <span>{!isLoading && `(${referral?.patient?.firstName} ${referral?.patient?.lastName})`}</span></h2>
                </div>
            </div>
            {isLoading ?
                <div className="loading-spiner text-center  mt-2">
                    <CommonLoader />
                </div>
                :
                <>
                    <Card className="nexogic-form-text-display-w border-0 bg-transparent nexogic-referral-details  nexogic-referals-new">
                        <CardBody className="px-0">
                            <Row className="need-margin">
                                <Col xl={9}>
                                    <div className="position-relative mb-3">
                                        <Card className="nexogic-card-list-custom nexogic-reffer-card">
                                            <div className="card-body">
                                                <div className="row need-margin">
                                                    {/** Receiving */}
                                                    <div className="col-12 reffering-to-col">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <h3 className="nex-text-xl mb-3"> Receiving Physician </h3>
                                                                    <div className="nex-stack">
                                                                        <QuickPractitioner
                                                                            isTitleLink={true}
                                                                            titleLink={`/profile/` + referral?.referredToPhysician?.profileId}
                                                                            titleLinkid="nex-view-prof-name2"
                                                                            avatar={
                                                                                referral?.referredToPhysician?.avatarId ?
                                                                                    <img
                                                                                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredToPhysician?.profileGuid}/${referral?.referredToPhysician?.avatarId}`}
                                                                                        className="nex-avatar lg"
                                                                                        alt="#"
                                                                                    />
                                                                                    :
                                                                                    <>
                                                                                        <img
                                                                                            src={createImageFromInitials(
                                                                                                50,
                                                                                                `${referral?.referredToPhysician?.name.replace("Dr. ", "")}`,
                                                                                                getRandomColor(
                                                                                                    encrypt(
                                                                                                        referral?.referredToPhysician?.profileGuid
                                                                                                    ).toString()
                                                                                                )
                                                                                            )}
                                                                                            className="nex-avatar lg"
                                                                                            alt="#"
                                                                                        />
                                                                                    </>
                                                                            }
                                                                            name={(referral?.referredToPhysician && referral?.referredToPhysician?.name) ? referral?.referredToPhysician?.name : 'N/A'}
                                                                            speciality={`${referral?.referredToPhysician?.primarySpeciality}${referral?.referredToPhysician?.superSpeciality ? ` ( ${referral?.referredToPhysician?.superSpeciality} ) ` : ''}`}
                                                                            address={getFullAddressWithLocationCityAndState({ location: referral?.referredToPhysician?.location ?? "", city: referral?.referredToPhysician?.city ?? "", state: referral?.referredToPhysician?.state ?? "" })}
                                                                        ></QuickPractitioner>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    {referral?.referredToInstitution && referral?.referredToInstitution?.guid &&
                                                                        <div className="reffering-physician-cols ">
                                                                            <h3 className=" nex-text-xl mb-3"> Receiving Hospital / Clinic </h3>
                                                                            <div className="nex-stack">
                                                                                <QuickPractitioner
                                                                                    isTitleLink={true}
                                                                                    titleLink={`/institution/` + referral?.referredToInstitution?.profileId}
                                                                                    titleLinkId="nex-view-prof-name3"
                                                                                    avatar={
                                                                                        referral?.referredToInstitution?.avatarId ?
                                                                                            <img
                                                                                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredToInstitution?.guid}/${referral?.referredToInstitution?.avatarId}`}
                                                                                                className="nex-avatar lg"
                                                                                                alt="#"
                                                                                            />
                                                                                            :
                                                                                            <>
                                                                                                <img
                                                                                                    src={createImageFromInitials(
                                                                                                        50,
                                                                                                        `${(referral?.referredToInstitution?.name)}`,
                                                                                                        getRandomColor(
                                                                                                            encrypt(
                                                                                                                referral?.referredToInstitution?.guid
                                                                                                            ).toString()
                                                                                                        )
                                                                                                    )}
                                                                                                    className="nex-avatar lg"
                                                                                                    alt="#"
                                                                                                />
                                                                                            </>
                                                                                    }
                                                                                    name={referral?.referredToInstitution?.name}
                                                                                    address={getFullAddressWithLocationCityAndState({ location: referral?.referredToInstitution?.address?.location ?? "", city: referral?.referredToInstitution?.address?.city ?? "", state: referral?.referredToInstitution?.address?.state ?? "" })}
                                                                                ></QuickPractitioner>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {!referral?.referredToInstitution && referral?.referredToInstitution?.guid &&
                                                                        <div className="reffering-physician-cols ">
                                                                            <h3 className=" nex-text-xl mb-3"> Receiving Hospital / Clinic </h3>
                                                                            <div className="head">
                                                                                <p className="nex-text">No Receiving Hospital / Clinic Found</p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    {/* <div className="col-12 reffering-to-col">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h3 className=" card-title big"> Receiving Physician </h3>
                                                                <div className="head">
                                                                    {
                                                                        referral?.referredToPhysician?.avatarId ?
                                                                            <img
                                                                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredToPhysician?.profileGuid}/${referral?.referredToPhysician?.avatarId}`}
                                                                                className="card-img"
                                                                                alt="#"
                                                                            />
                                                                            :
                                                                            <>
                                                                                <img
                                                                                    src={createImageFromInitials(
                                                                                        50,
                                                                                        `${referral?.referredToPhysician?.name.replace("Dr. ", "")}`,
                                                                                        getRandomColor(
                                                                                            encrypt(
                                                                                                referral?.referredToPhysician?.profileGuid
                                                                                            ).toString()
                                                                                        )
                                                                                    )}
                                                                                    className="card-img"
                                                                                    alt="#"
                                                                                />
                                                                            </>
                                                                    }
                                                                    <div className="info">
                                                                        <p className="card-text"><b>Name  </b>  <Link to={`/profile/` + referral?.referredToPhysician?.profileId} >{referral?.referredToPhysician?.name}</Link></p>
                                                                        <p className="card-text">
                                                                            <b>Address  </b> {getFullAddressWithLocationCityAndState({ location: referral?.referredToPhysician?.location ?? "", city: referral?.referredToPhysician?.city ?? "", state: referral?.referredToPhysician?.state ?? "" })}</p>
                                                                        <p className="card-text"><b>Speciality  </b> {referral?.referredToPhysician?.primarySpeciality}{referral?.referredToPhysician?.superSpeciality ? ` ( ${referral?.referredToPhysician?.superSpeciality} ) ` : ''}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                {referral?.referredToInstitution && referral?.referredToInstitution?.guid &&
                                                                    <div className="reffering-physician-cols ">
                                                                        <h3 className=" card-title big"> Receiving Hospital / Clinic </h3>
                                                                        <div className="head">
                                                                            {
                                                                                referral?.referredToInstitution?.avatarId ?
                                                                                    <img
                                                                                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredToInstitution?.guid}/${referral?.referredToInstitution?.avatarId}`}
                                                                                        className="card-img"
                                                                                        alt="#"
                                                                                    />
                                                                                    :
                                                                                    <>
                                                                                        <img
                                                                                            src={createImageFromInitials(
                                                                                                50,
                                                                                                `${(referral?.referredToInstitution?.name)}`,
                                                                                                getRandomColor(
                                                                                                    encrypt(
                                                                                                        referral?.referredToInstitution?.guid
                                                                                                    ).toString()
                                                                                                )
                                                                                            )}
                                                                                            className="card-img"
                                                                                            alt="#"
                                                                                        />
                                                                                    </>
                                                                            }
                                                                            <div className="info">
                                                                                <p className="card-text"><b>Name  </b><Link to={`/institution/` + referral?.referredToInstitution?.profileId} >  {referral?.referredToInstitution?.name}</Link></p>
                                                                                <p className="card-text">
                                                                                    <b>Address  </b>
                                                                                    {getFullAddressWithLocationCityAndState({ location: referral?.referredToInstitution?.address?.location ?? "", city: referral?.referredToInstitution?.address?.city ?? "", state: referral?.referredToInstitution?.address?.state ?? "" })}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {!referral?.referredToInstitution && referral?.referredToInstitution?.guid &&
                                                                    <div className="reffering-physician-cols ">
                                                                        <h3 className=" card-title big"> Receiving Hospital / Clinic </h3>
                                                                        <div className="head">
                                                                            <p className="card-text">No Receiving Hospital / Clinic Found</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>

                                                    </div> */}

                                                    {/** Referring */}

                                                    <div className="col-12 referring-cols">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <h3 className="nex-text-xl mb-3">Referring Physician</h3>
                                                                    <div className="nex-stack">
                                                                        <QuickPractitioner
                                                                            isTitleLink={true}
                                                                            titleLink={`/profile/` + referral?.referredByPhysician?.profileId}
                                                                            titleLinkId="nex-view-prof-name4"
                                                                            avatar={
                                                                                referral?.referredByPhysician?.avatarId ?
                                                                                    <img
                                                                                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredByPhysician?.profileGuid}/${referral?.referredByPhysician?.avatarId}`}
                                                                                        className="nex-avatar lg"
                                                                                        alt="#"
                                                                                    />
                                                                                    :
                                                                                    <>
                                                                                        <img
                                                                                            src={createImageFromInitials(
                                                                                                50,
                                                                                                `${referral?.referredByPhysician?.name.replace("Dr. ", "")}`,
                                                                                                getRandomColor(
                                                                                                    encrypt(
                                                                                                        referral?.referredByPhysician?.profileGuid
                                                                                                    ).toString()
                                                                                                )
                                                                                            )}
                                                                                            className="nex-avatar lg"
                                                                                            alt="#"
                                                                                        />
                                                                                    </>
                                                                            }
                                                                            name={referral?.referredByPhysician?.name}
                                                                            speciality={`${referral?.referredByPhysician?.primarySpeciality}${referral?.referredByPhysician?.superSpeciality ? ` ( ${referral?.referredByPhysician?.superSpeciality} ) ` : ''}`}
                                                                            address={getFullAddressWithLocationCityAndState({ location: referral?.referredByPhysician?.location ?? "", city: referral?.referredByPhysician?.city ?? "", state: referral?.referredByPhysician?.state ?? "" })}
                                                                        >
                                                                        </QuickPractitioner>
                                                                    </div>
                                                                    <div className="reffering-physician-cols mb-1 d-none">
                                                                        <h3 className=" card-title big">  Referring Physician</h3>
                                                                        <div className="head">
                                                                            {
                                                                                referral?.referredByPhysician?.avatarId ?
                                                                                    <img
                                                                                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredByPhysician?.profileGuid}/${referral?.referredByPhysician?.avatarId}`}
                                                                                        className="card-img"
                                                                                        alt="#"
                                                                                    />
                                                                                    :
                                                                                    <>
                                                                                        <img
                                                                                            src={createImageFromInitials(
                                                                                                50,
                                                                                                `${referral?.referredByPhysician?.name.replace("Dr. ", "")}`,
                                                                                                getRandomColor(
                                                                                                    encrypt(
                                                                                                        referral?.referredByPhysician?.profileGuid
                                                                                                    ).toString()
                                                                                                )
                                                                                            )}
                                                                                            className="card-img"
                                                                                            alt="#"
                                                                                        />
                                                                                    </>
                                                                            }
                                                                            <div className="info">
                                                                                <p className="card-text"><b>Name  </b>  <Link id="nex-view-prof-name4" to={`/profile/` + referral?.referredByPhysician?.profileId} >{referral?.referredByPhysician?.name}</Link></p>
                                                                                <p className="card-text">
                                                                                    <b>Address</b>
                                                                                    {getFullAddressWithLocationCityAndState({ location: referral?.referredByPhysician?.location ?? "", city: referral?.referredByPhysician?.city ?? "", state: referral?.referredByPhysician?.state ?? "" })}
                                                                                </p>
                                                                                <p className="card-text"><b>Speciality  </b> {referral?.referredByPhysician?.primarySpeciality}{referral?.referredByPhysician?.superSpeciality ? ` ( ${referral?.referredByPhysician?.superSpeciality} ) ` : ''}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    {referral?.referredByInstitution && referral?.referredByInstitution?.guid &&
                                                                        <div className="reffering-physician-colS mb-1">
                                                                            <h3 className="nex-text-xl mb-3"> Referring Hospital / Clinic</h3>
                                                                            <div className="nex-stack">
                                                                                <QuickPractitioner
                                                                                    isTitleLink={true}
                                                                                    titleLink={`/institution/` + referral?.referredByInstitution?.profileId}
                                                                                    avatar={
                                                                                        referral?.referredByInstitution?.avatarId ?
                                                                                            <img
                                                                                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredByInstitution?.guid}/${referral?.referredByInstitution?.avatarId}`}
                                                                                                className="nex-avatar lg"
                                                                                                alt="#"
                                                                                            />
                                                                                            :
                                                                                            <>
                                                                                                <img
                                                                                                    src={createImageFromInitials(
                                                                                                        50,
                                                                                                        `${(referral?.referredByInstitution?.name)}`,
                                                                                                        getRandomColor(
                                                                                                            encrypt(
                                                                                                                referral?.referredByInstitution?.guid
                                                                                                            ).toString()
                                                                                                        )
                                                                                                    )}
                                                                                                    className="nex-avatar lg"
                                                                                                    alt="#"
                                                                                                />
                                                                                            </>
                                                                                    }
                                                                                    name={referral?.referredByInstitution?.name}
                                                                                    address={getFullAddressWithLocationCityAndState({ location: referral?.referredByInstitution?.address?.location ?? "", city: referral?.referredByInstitution?.address?.city ?? "", state: referral?.referredByInstitution?.address?.state ?? "" })}
                                                                                ></QuickPractitioner>
                                                                            </div>

                                                                            <div className="head d-none">
                                                                                {
                                                                                    referral?.referredByInstitution?.avatarId ?
                                                                                        <img
                                                                                            src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredByInstitution?.guid}/${referral?.referredByInstitution?.avatarId}`}
                                                                                            className="card-img"
                                                                                            alt="#"
                                                                                        />
                                                                                        :
                                                                                        <>
                                                                                            <img
                                                                                                src={createImageFromInitials(
                                                                                                    50,
                                                                                                    `${(referral?.referredByInstitution?.name)}`,
                                                                                                    getRandomColor(
                                                                                                        encrypt(
                                                                                                            referral?.referredByInstitution?.guid
                                                                                                        ).toString()
                                                                                                    )
                                                                                                )}
                                                                                                className="card-img"
                                                                                                alt="#"
                                                                                            />
                                                                                        </>
                                                                                }
                                                                                <div className="info">
                                                                                    <p className="card-text"><b>Name  </b><Link id="nex-view-prof-name5" to={`/institution/` + referral?.referredByInstitution?.profileId} >  {referral?.referredByInstitution?.name}</Link></p>
                                                                                    <p className="card-text">
                                                                                        <b>Address  </b> {getFullAddressWithLocationCityAndState({ location: referral?.referredByInstitution?.address?.location ?? "", city: referral?.referredByInstitution?.address?.city ?? "", state: referral?.referredByInstitution?.address?.state ?? "" })}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {!referral?.referredByInstitution && referral?.referredByInstitution?.guid &&
                                                                        <div className="col-lg-12 reffering-physician-colS mb-1">
                                                                            <h3 className="nex-text-xl mb-3"> Referring Hospital / Clinic</h3>
                                                                            <div className="head">
                                                                                <p className="nex-text">No Referring Hospital / Clinic Found</p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>


                                                    {/* <div className="col-12 referring-cols">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="reffering-physician-cols mb-1">
                                                                    <h3 className=" card-title big">  Referring Physician</h3>
                                                                    <div className="head">
                                                                        {
                                                                            referral?.referredByPhysician?.avatarId ?
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredByPhysician?.profileGuid}/${referral?.referredByPhysician?.avatarId}`}
                                                                                    className="card-img"
                                                                                    alt="#"
                                                                                />
                                                                                :
                                                                                <>
                                                                                    <img
                                                                                        src={createImageFromInitials(
                                                                                            50,
                                                                                            `${referral?.referredByPhysician?.name.replace("Dr. ", "")}`,
                                                                                            getRandomColor(
                                                                                                encrypt(
                                                                                                    referral?.referredByPhysician?.profileGuid
                                                                                                ).toString()
                                                                                            )
                                                                                        )}
                                                                                        className="card-img"
                                                                                        alt="#"
                                                                                    />
                                                                                </>
                                                                        }
                                                                        <div className="info">
                                                                            <p className="card-text"><b>Name  </b>  <Link to={`/profile/` + referral?.referredByPhysician?.profileId} >{referral?.referredByPhysician?.name}</Link></p>
                                                                            <p className="card-text">
                                                                                <b>Address</b>
                                                                                {getFullAddressWithLocationCityAndState({ location: referral?.referredByPhysician?.location ?? "", city: referral?.referredByPhysician?.city ?? "", state: referral?.referredByPhysician?.state ?? "" })}
                                                                            </p>
                                                                            <p className="card-text"><b>Speciality  </b> {referral?.referredByPhysician?.primarySpeciality}{referral?.referredByPhysician?.superSpeciality ? ` ( ${referral?.referredByPhysician?.superSpeciality} ) ` : ''}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                {referral?.referredByInstitution && referral?.referredByInstitution?.guid &&
                                                                    <div className="col-lg-12 reffering-physician-colS mb-1">
                                                                        <h3 className=" card-title big"> Referring Hospital / Clinic</h3>
                                                                        <div className="head">
                                                                            {
                                                                                referral?.referredByInstitution?.avatarId ?
                                                                                    <img
                                                                                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredByInstitution?.guid}/${referral?.referredByInstitution?.avatarId}`}
                                                                                        className="card-img"
                                                                                        alt="#"
                                                                                    />
                                                                                    :
                                                                                    <>
                                                                                        <img
                                                                                            src={createImageFromInitials(
                                                                                                50,
                                                                                                `${(referral?.referredByInstitution?.name)}`,
                                                                                                getRandomColor(
                                                                                                    encrypt(
                                                                                                        referral?.referredByInstitution?.guid
                                                                                                    ).toString()
                                                                                                )
                                                                                            )}
                                                                                            className="card-img"
                                                                                            alt="#"
                                                                                        />
                                                                                    </>
                                                                            }
                                                                            <div className="info">
                                                                                <p className="card-text"><b>Name  </b><Link to={`/institution/` + referral?.referredByInstitution?.profileId} >  {referral?.referredByInstitution?.name}</Link></p>
                                                                                <p className="card-text">
                                                                                    <b>Address  </b> {getFullAddressWithLocationCityAndState({ location: referral?.referredByInstitution?.address?.location ?? "", city: referral?.referredByInstitution?.address?.city ?? "", state: referral?.referredByInstitution?.address?.state ?? "" })}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {!referral?.referredByInstitution && referral?.referredByInstitution?.guid &&
                                                                    <div className="col-lg-12 reffering-physician-colS mb-1">
                                                                        <h3 className=" card-title big"> Referring Hospital / Clinic</h3>
                                                                        <div className="head">
                                                                            <p className="card-text">No Referring Hospital / Clinic Found</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="position-relative mb-3">
                                        <Card className={`nexogic-card-color-header other-det-bg ${pannel_one_on ? 'expanded' : ''}`}>
                                            <div className="card-header other-det-bg ">
                                                <h2>Referral Reason</h2>
                                                <button className="collapse-btn" type="button" onClick={() => setPannel_one_on(!pannel_one_on)}>
                                                    <img src={pannel_arrow} alt="" />
                                                </button>
                                            </div>
                                            <div className="collapse-div">
                                                <CardBody className="other-det-bg ">
                                                    <div>
                                                        {referral?.referralReason ?
                                                            (<div dangerouslySetInnerHTML={{ __html: referral?.referralReason }}></div>)
                                                            : (<div className="nexogic-not-found-text">Not Available</div>)

                                                        }
                                                    </div>

                                                </CardBody>
                                            </div>
                                        </Card>
                                        <Card className={`nexogic-card-color-header other-det-bg ${pannel_two_on ? 'expanded' : ''}`}>
                                            <div className="card-header other-det-bg">
                                                <h2>Diagnosis</h2>
                                                <button className="collapse-btn" type="button" onClick={() => setPannel_two_on(!pannel_two_on)}>
                                                    <img src={pannel_arrow} alt="" />
                                                </button>
                                            </div>
                                            <div className="collapse-div">
                                                <CardBody className="other-det-bg">

                                                    {/* <div className="mb-1 f-14 about-text">{parse(referral?.diagnosis)}</div> */}
                                                    <div>
                                                        {referral?.diagnosis ?
                                                            (<div dangerouslySetInnerHTML={{ __html: referral?.diagnosis }}></div>)
                                                            :
                                                            <div className="nexogic-not-found-text">Not Available</div>
                                                        }
                                                    </div>

                                                </CardBody>
                                            </div>
                                        </Card>
                                        <Card className={`nexogic-card-color-header other-det-bg ${pannel_three_on ? 'expanded' : ''}`}>
                                            <div className="card-header other-det-bg">
                                                <h2>Treatment Medications</h2>
                                                <button className="collapse-btn" type="button" onClick={() => setPannel_three_on(!pannel_three_on)}>
                                                    <img src={pannel_arrow} alt="" />
                                                </button>
                                            </div>
                                            <div className="collapse-div">
                                                <CardBody className="other-det-bg">
                                                    <div>
                                                        {referral?.treatmentMedications ?
                                                            (<div dangerouslySetInnerHTML={{ __html: referral?.treatmentMedications }}></div>)
                                                            :
                                                            <div className="nexogic-not-found-text">Not Available</div>
                                                        }
                                                    </div>
                                                </CardBody>
                                            </div>
                                        </Card>
                                        <Card className={`nexogic-card-color-header other-det-bg ${pannel_four_on ? 'expanded' : ''}`}>
                                            <div className="card-header other-det-bg">
                                                <h2>Additional Information</h2>
                                                <button className="collapse-btn" type="button" onClick={() => setPannel_four_on(!pannel_four_on)}>
                                                    <img src={pannel_arrow} alt="" />
                                                </button>
                                            </div>
                                            <div className="collapse-div">
                                                <CardBody className="other-det-bg">

                                                    <div>
                                                        {referral?.additionalInformation ?
                                                            (<div dangerouslySetInnerHTML={{ __html: referral?.additionalInformation }}></div>)
                                                            :
                                                            <div className="nexogic-not-found-text">Not Available</div>
                                                        }
                                                    </div>

                                                </CardBody>
                                            </div>
                                        </Card>
                                    </div>
                                </Col>
                                <Col xl={3} className="position-relative nex-app-no-pad">
                                    <Card className="nexogic-widget-sidebar-card document-sidebar-widget nex-app-block">
                                        <CardBody>
                                            <Link className="btn nex-btn-primary-outline w-100" to={`/referrals/my-response/${referral?.id}`}>
                                                View Response
                                            </Link>
                                        </CardBody>
                                    </Card>
                                    {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                                        <Card className="nexogic-widget-sidebar-card document-sidebar-widget nex-app-block">
                                            <CardBody>
                                                <MyReferralPreview id={referral?.id} />
                                            </CardBody>
                                        </Card>
                                    }
                                    <Card className="nexogic-widget-sidebar-card document-sidebar-widget nex-app-block">
                                        <h2 className="card-title h2 d-flex flex-wrap">
                                            Referral Documents
                                        </h2>
                                        <CardBody>
                                            {docsInfo && docsInfo.length > 0 ?
                                                <div className="nexogic-custom-scroll">
                                                    <div className="nexogic-document-lists">
                                                        <ul>
                                                            {docsInfo.map((doc, index) => (
                                                                <li key={index} >
                                                                    <div className="nexogic-document-item" style={{ cursor: "pointer" }} onClick={() => getReferralDoc(doc?.id)}>
                                                                        <img src={getFileIcon(doc?.type)} style={{ cursor: "pointer", height: '50px' }} />
                                                                        <div className="card-text">{doc?.name}</div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>

                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    <div className="nexogic-not-found-text">No Documents</div>
                                                </>
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card >
                </>
            }
            {
                dataLoading ?
                    <div className="" style={{ zIndex: "111", position: "absolute", top: "0", left: "0", backgroundColor: "#edeff0b0", width: "100%", height: "100%" }}>
                        <div className="component-wrapper"><CommonLoader /></div>
                    </div>
                    :
                    <Modal isOpen={modal} toggle={toggleForm} backdrop="static" scrollable centered >
                        <ModalHeader toggle={toggleForm}><span style={{ color: "gray" }}>Document Viewer</span></ModalHeader>
                        <ModalBody>
                            <div className="modalbody">
                                <img src={documentData} alt="document preview" />
                            </div>
                            <div className="downloadbtn">
                                <FontAwesomeIcon color="#0087b9" size="xl" style={{ cursor: "pointer" }} icon={faDownload} onClick={handleDownload} />
                            </div>
                        </ModalBody>
                    </Modal>
            }
        </div >
    )
}

export default MyReferralDetails;