import React, { useState, useEffect } from "react";
import {
  Link,
  useLocation,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  Button,
  CustomInput,
  DropdownToggle,
  DropdownMenu,
  ButtonDropdown,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { withAlert } from "react-alert";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import filterIcon from "assets/images/svgs/filter_icon.svg";

import DayView from "./components/DayView";
import WeekView from "./components/WeekViews";
import CreateEvent from "./components/CreateEvent";

import "./AppointmentsCalendar.css";
import nextCircle from "assets/images/NextCircle.png";
import prevCircle from "assets/images/PrevCircle.png";
import appointmentIcon from "assets/images/svgs/calendar-icon.svg";
// import PatientSummary from "./PatientSummary";
import { appointmentServices } from "utils/appointmentsServices";
import { AppointmentType, AppointmentStatus } from "utils/Constants"

const AppointmentsCalendar = (props) => {

  const location = useLocation();
  const maxDaysListLmit = 5;
  let { url } = useRouteMatch();
  const practitionerId = useSelector((state) => state.auth.currentUser.practitionerId);
  const [appointments, setAppointments] = useState([]);
  const [nextMove, setNextMove] = useState(0);
  let nextDays = nextMove * maxDaysListLmit;
  //console.log("After initialise ==="+nextDays+"==== nextMove : ==="+nextMove+"maxDaysListLmit : ==="+maxDaysListLmit)
  const [days, setDays] = useState([]);
  const [isOpen, setModal] = useState(false);
  let currentDaysData = [];
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const togglefilter = () => setDropdownOpen(!dropdownOpen);
  const [filterType, setFilterType] = useState(
    [
      { code: "ALL", label: 'ALL', selected: true },
      ...Object.keys(AppointmentStatus).map(status => ({ code: status, label: status, selected: false }))
    ]);
  // ,
  //   { code: "PENDING", label: "Pending", selected: false },
  //   { code: "CONFIRMED", label: "Confirmed", selected: false },
  //   { code: "CANCELED", label: "Canceled", selected: false },

  const handleFilterTypeChange = (event) => {
    filterType.map((val) => {
      if (val.code === event.target.value) {
        val.selected = event.target.checked;
      }
    });
    setDays([]);
    setFilterType(filterType);
  };

  const changePeriod = (event) => {
    event.preventDefault();
    if (parseInt(event.target.dataset.value) === 1) {
      setNextMove((oldMove) => oldMove + 1);
    } else {
      setNextMove((oldMove) => oldMove - 1);
    }
    nextDays *= maxDaysListLmit; // comment on 221021, did not seem it form any change
  };

  for (let d = 0; d < maxDaysListLmit; d++) {
    let currDate = new Date();
    currDate.setDate(currDate.getDate() + d + nextDays);

    let dayObj = {
      id:
        currDate.getFullYear() + (currDate.getMonth() + 1) + currDate.getDate(),
      date:
        currDate.toLocaleString("default", { month: "short" }) +
        " " +
        ("0" + currDate.getDate()).slice(-2),
      day: currDate.toLocaleString("default", { weekday: "short" }),
      totalAppointments: 0,
      consultations: [],
      procedures: [],
      LAB: [],
    };
    appointments.map((apptData) => {
      if (moment(apptData.appointmentDate).isSame(moment(currDate).format("yyyy-MM-DD"))) {
        let flag = false;
        filterType.map((type) => {
          // if (filterType[0].code === "all" && filterType[0].selected) {
          //   flag = true;
          // }
          if (type.selected && (type.code === apptData.appointmentStatus || type.code === "ALL")) {
            flag = true;
          }
        });

        if (flag) {
          dayObj.totalAppointments++;
          switch (apptData.appointmentType) {
            case AppointmentType.PHYSICAL:
            case AppointmentType.VIDEO:
              dayObj.consultations.push(apptData);
              break;
            case "procedure":
              dayObj.procedures.push(apptData);
              break;
            case AppointmentType.LAB:
              dayObj.LAB.push(apptData);
              break;
            default:
              console.log("Not found!! Error in appointmentType");
          }
        }
      }
    });
    currentDaysData.push(dayObj);
  }

  if (days.length < 1) {
    setDays(currentDaysData);
  }

  const filterLabel = () => {
    let currDate = new Date();
    let label = "";
    currDate.setDate(currDate.getDate() + nextDays);
    label =
      currDate.toLocaleString("default", { month: "short" }) +
      " " +
      ("0" + currDate.getDate()).slice(-2) +
      " - ";
    currDate.setDate(currDate.getDate() + (maxDaysListLmit - 1));
    label +=
      currDate.toLocaleString("default", { month: "short" }) +
      " " +
      ("0" + currDate.getDate()).slice(-2);
    return label;
  };

  const toggleCE = () => setModal(!isOpen);

  useEffect(() => {

    getAppts();

  }, [nextMove])

  const getRangeDates = () => {
    let arr = [];
    for (let d = 0; d < maxDaysListLmit; d++) {
      let today = new Date();
      today = today.setDate(today.getDate() + d + nextDays);
      let formattedDate = moment(today).format("YYYY-MM-DD");
      arr.push(formattedDate);
    }
    return arr;
  }
  const getAppts = async () => {
    let apptDates = getRangeDates().join()
    const request = appointmentServices.getPractitionerAppts(practitionerId, apptDates)
    request.then(response => {
      let { data } = response
      if (data && data.appointments) {
        setAppointments(data.appointments)
      } else {
        setAppointments([])
      }
    })
  }



  return (
    <section className="appointments-calendar-wrapper mb-4 pt-0">
      {/*
      <div className="nexogic-heading-sec nexogic-card">
            <h2 className="nexogix-section-title"><img src={appointmentIcon} className="icon" />Appointments</h2>
        </div>
        */}
      <Card>
        <CardBody className={classnames({ "px-0 pb-0": location.pathname === "/appointments/week", "px-0": location.pathname === "/appointments/day" })}>
          <Row className="align-items-center pl-3 pr-1 justify-content-between w-100">
            <Col md={2} className="px-3 day-month">
              <Nav className="list-inline mb-0 text-center custom-css">
                <NavItem
                  title="Day"
                  active={
                    location.pathname === "/appointments/day" ? true : false
                  }
                  className="list-inline-item text-center mx-2"
                >
                  <Link className="nav-link" to="/appointments/day">
                    Day
                  </Link>
                </NavItem>
                <NavItem
                  title="Week"
                  active={
                    location.pathname === "/appointments/week" ? true : false
                  }
                  className="list-inline-item text-center mx-2"
                >
                  <Link className="nav-link" to="/appointments/week">
                    Week
                  </Link>
                </NavItem>
                {/* <NavItem
                  title="month"
                  active={
                    location.pathname === "/appointments/month" ? true : false
                  }
                  className="list-inline-item text-center mx-2"
                >
                  <Link className="nav-link" to="/appointments/month">
                    Month
                  </Link>
                </NavItem> */}
              </Nav>
            </Col>
            <Col md={3} className="d-flex align-items-center mxw-35 mx-1">
              <div className="date-filter text-center mr-4">
                <a
                  className="mover mr-3"
                  onClick={changePeriod}
                  data-value={-1}
                >
                  <img
                    src={prevCircle}
                    alt="Prev"
                  />
                </a>
                <span className="d-inline align-middle">{filterLabel()}</span>
                <a className="mover ml-3" onClick={changePeriod} data-value={1}>
                  <img
                    src={nextCircle}
                    alt="Next"
                  />
                </a>
              </div>
              <ButtonDropdown isOpen={dropdownOpen} toggle={togglefilter} left className="mr-3">
                <DropdownToggle color="link" className="min-w-0 p-0">
                  <img src={filterIcon} alt="filter-icon" />
                </DropdownToggle>
                <DropdownMenu className="filter-menu p-3">
                  {filterType.map((type, index) => (
                    <FormGroup
                      check
                      key={index}
                      className={index + 1 < filterType.length ? "mb-1" : ""}
                    >
                      <Label check>
                        <Input
                          id={`app_cal_filter_${index}`}
                          name={`app_cal_filter_${index}`}
                          type="checkbox"
                          defaultChecked={type.selected}
                          value={type.code}
                          disabled={
                            type.code !== "ALL" &&
                              filterType[0].code === "ALL" &&
                              filterType[0].selected
                              ? true
                              : false
                          }
                          onClick={handleFilterTypeChange}
                        />
                        <span className="f-14 line-h-14 text-capitalize">{type.label}</span>
                      </Label>
                    </FormGroup>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
            </Col>
            <Col md={5} className="text-right ml-0 p-0 mxw-25" style={{}}>

              {/** Comment these for now so that QA do report static UI as not functional error :)  */}
              {/* <div className="toggle-btn d-inline-block text-center ml-1 pr-1">
                <label className="toggle-label d-block mb-0 f-10 line-h-10">
                  Virtal Clinic
                </label>
                <CustomInput
                  type="switch"
                  id="exampleCustomSwitch"
                  name="customSwitch"
                  className="d-inline-block text-center mr-2"
                />
              </div>
              <Button className="btn-primary create-btn ml-4 mb-1 f-16 line-h-16" onClick={toggleCE}>
                <FontAwesomeIcon size="sm" color="#fff" icon={faPlus} className="mr-2"/>
                Create
              </Button> */}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="divider pt-2"></div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="pt-2" style={{ paddingTop: "0px important" }}>
              <Switch>
                {/* <Redirect from={`${url}`} exact to={`${url}/day`} /> */}
                <Route path={`${url}/day`}>
                  <DayView days={currentDaysData} getAppts={getAppts} />
                </Route>
                <Route path={`${url}/week`}>
                  <WeekView days={currentDaysData} getAppts={getAppts} />
                </Route>
                {/*  <Route path={`${url}/patientsummary`}>
                  <PatientSummary />
                </Route> */}
                <Redirect exact to={`${url}/day`} />
              </Switch>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <CreateEvent toggle={toggleCE} isOpen={isOpen} />
    </section>
  );
};

export default withAlert()(AppointmentsCalendar);
