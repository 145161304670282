import React, { useState } from 'react'
import { Col, Container, Row, Card, CardBody, Button } from 'reactstrap'
import { Bar } from 'react-chartjs-2';

import connectionIcon from 'assets/images/contacts_active_icon.png';
import expandArrow from 'assets/images/expand_arrow.png';

const Workload = (props) => {
    return (
        <>
            <div className="section-heading mb-3">Workload</div>
            <Card className="dashboard-chart">
                <CardBody>
                    <div className="d-flex filter-button">
                        <Button className="chart-filter-btn active">Weekly</Button>
                        <Button className="chart-filter-btn">Monthly</Button>
                        <Button className="chart-filter-btn">30 days</Button>
                    </div>
                    <div>
                        <DashboardBarChartUI />
                    </div>
                </CardBody>
            </Card>
        </>
    )
}

const DashboardBarChartUI = () => {
    let data = {
        labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        datasets: [
            {
                label: 'Online',
                backgroundColor: '#3EABCA',
                borderColor: '#3EABCA',
                borderWidth: 1,
                hoverBackgroundColor: '#3EABCA',
                hoverBorderColor: '#3EABCA',
                data: [5, 8, 3, 4, 4, 6, 2],
                stack: true
            },
            {
                label: 'Offline',
                backgroundColor: '#ACB9FF',
                borderColor: '#ACB9FF',
                hoverBackgroundColor: '#ACB9FF',
                hoverBorderColor: '#ACB9FF',
                data: [3, 2, 3, 4, 6, 9, 7],
                stack: true
            }
        ]
    };
    return (
        <>
            <Bar
                data={data}
                height={240}
                options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        xAxes: [{
                            barThickness: 10,  // number (pixels) or 'flex'
                            maxBarThickness: 10 // number (pixels)
                        }]
                    },
                    legend: {
                        position: 'top',
                        align: 'end',
                        labels: {
                            boxWidth: 12,
                        }
                    }
                }}
            />
        </>
    )
}

export default Workload

