import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { isPatient } from "utils/UserHelper";

import "./styles.scss";
import SentReferals from "./Components/SentReferals";
import ReceivedReferals from "./Components/ReceivedReferals";
import RenderReferalSuccessModal from "./Components/RenderModalPage/RenderReferalSuccessModal/index.js";
import CompleteForm from "./Components/CompleteForm";
import ReferPatientNew from "./ReferPatientNew";
import Treatment from "./Components/Treatment";
import TreatmentDetails from "./Components/TreatmentDetails";
import ResponseDetails from "./Components/CompleteForm/responseDetails";
import PatientForm from "./Components/AddPatient";
import MyReferralDetails from "./Components/PatientDetails/Components/MyReferrals/MyReferralDetails";
import MyReferralResponse from "./Components/PatientDetails/Components/MyReferrals/MyReferralResponse";
import MyReferralPreview from "./Components/PatientDetails/Components/MyReferralPreview";
import ReceivedReferralPreview from "./Components/ReceivedReferals/Components/ReceivedReferralPreview";
import SentReferralPreview from "./Components/SentReferals/Components/SentReferralPreview";

const Referals = () => {
    const location = useLocation();
    const [referalModal, setReferalModal] = useState(false)
    const toggleReferal = () => setReferalModal(!referalModal)
    const isPatientUser = isPatient();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    useEffect(() => {
        document.body.classList.add('body-nex-referals-page');
        document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "Referals";
        return () => {
          document.body.classList.remove('body-nex-referals-page');
          document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "";
        }
    }, [])

    return (
        <div className="nexogic-referals pt-0">
            <Switch>
                {!isPatientUser && <Route exact path="/referrals" component={SentReferals} />}
                {!isPatientUser && <Route exact path="/referrals/refer-patient" component={ReferPatientNew} />}
                {!isPatientUser && <Route exact path="/referrals/received-referrals" component={ReceivedReferals} />}
                {!isPatientUser && <Route exact path="/referrals/sent-referrals" component={SentReferals} />}
                {!isPatientUser && <Route exact path="/referrals/add-patient" component={PatientForm} />}
                {!isPatientUser && <Route exact path="/referrals/received-referrals/:id" component={CompleteForm} />}
                {!isPatientUser && <Route exact path="/referrals/received-referrals/:id/email-preview" component={ReceivedReferralPreview} />}
                {!isPatientUser && <Route exact path="/referrals/sent-referrals/:id" component={CompleteForm} />}
                {!isPatientUser && <Route exact path="/referrals/sent-referrals/:id/email-preview" component={SentReferralPreview} />}
                {!isPatientUser && <Route exact path="/referrals/sent-response/:id" component={ResponseDetails} />}
                {!isPatientUser && <Route exact path="/referrals/update/:id" component={ReferPatientNew} />}
                {!isPatientUser && <Route exact path="/referrals/:id/treatment" component={Treatment} />}
                {!isPatientUser && <Route path="/referrals/:id/treatment-details" component={TreatmentDetails} />}

                {!isPatientUser && <Redirect from="/referrals" to="/referrals/received-referrals" />}
                <Route exact path="/referrals/:id/treatment-details" component={TreatmentDetails} />
                <Route exact path="/referrals/my-referrals/:id" component={MyReferralDetails} />
                <Route exact path="/referrals/my-referrals/:id/email-preview" component={MyReferralPreview} />
                <Route exact path="/referrals/my-response/:id" component={MyReferralResponse} />
                <Redirect to="/home" />
            </Switch>

            <RenderReferalSuccessModal
                toggle={toggleReferal}
                isOpen={referalModal}
            />
        </div>
    )
}
export default Referals;