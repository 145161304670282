import Header from "common/Header";
import verifyMobileImg from "assets/images/otp-flow/verifyMobile.svg";
import logo from "assets/images/logo-white.png";
import React, { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { Link, useHistory } from "react-router-dom";
import { Col, Container } from "reactstrap";
import { IdentityService } from "utils/IdentityService";
import { cleanUpPhoneNumber } from "utils/Utils";
import { useParams } from "react-router-dom";
import SocialClaimForm from "components/OTPFlow/SocialClaimForm";
import { UserAuth } from "context/AuthContext";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const ClaimProfileSocial = (props) => {
  const history = useHistory();
  const [values, setValues] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [verificationId, setVerificationId] = useState(null);
  const [userResponseGUID, setUserResponseGUID] = useState(null);
  const [userProfileId, setUserProfileId] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const { claimProfileId } = useParams();
  const { googleSignUp, appleSignUp, signUpWIthEmail, signInWithPhone, signUpWIthMobileOTP, user } = UserAuth();  
  const [isPatient, setIsPatient] = useState(false);

  useEffect(() => {
    setErrorMessage(errorMessage);
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  }, [errorMessage]);


  const onSubmit = (values, { setSubmitting }) => {
    // setValues(values);
    let phone = cleanUpPhoneNumber(values.phone);
    let utype = values.chkPatient ? "PATIENT" : "PRACTITIONER";
    values.userType = utype;
    setValues(values);

    const payload = {
      phoneNumber: phone,
      type: utype,
      rtType: userProfileId ? "CLAIM" : "REGISTRATION",
      claimProfileId: userProfileId,
    };
    IdentityService.CreateRegistration(payload, "PHONE", payload.rtType)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setUserResponseGUID(response.data);
          setErrorMessage("");
        } else {
          if (response.validationErrors) {
            response.validationErrors.map((err) =>
              setErrorMessage(`${err.field}: ${err.message}`)
            );
          } else if (response.message) {
            setErrorMessage(response.message);
          }
        }
      })
      .catch((error) => {
        console.log("IdentityService.RegisterNewUser error", error);
        setErrorMessage(error.message);
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  
  const handleGoogleSignUp = async () => {
    try {
      const signedInUser = await googleSignUp();
      const {name,email,idToken,refreshToken,profiledetails,phoneNumber} = signedInUser;
      if (!profiledetails) {
        try {
          history.push('/account-info-entry', {
              firstName: name?.split(' ')[0],
              lastName: name?.split(' ')[1] || '',
              email: email,
              phoneNumber: phoneNumber,
              token: idToken,
              refreshToken: refreshToken,
              utype:isPatient,
              claim: userProfileId ? true : false,
              profileId: userProfileId ?? "",
          });
        } catch (apiError) {
          console.error('API call failed:', apiError);
          history.push('/login');
        }
      }else{
        console.log("Account ALready Exists..");
        history.push('/login')
      }
    } catch (error) {
      console.error('Google sign-in failed:', error);
    }
  }

  const handleAppleSignUp = async () => {
    try {
      const signedInUser = await appleSignUp();
      const {name,email,idToken,refreshToken,profiledetails,phoneNumber} = signedInUser;
      if (!profiledetails) {
        try {
          history.push('/account-info-entry', {
              firstName: name?.split(' ')[0],
              lastName: name?.split(' ')[1] || '',
              email: email,
              phoneNumber: phoneNumber,
              token: idToken,
              refreshToken: refreshToken,
              utype:isPatient,
              claim: userProfileId ? true : false,
              profileId: userProfileId ?? "",
          });
        } catch (apiError) {
          console.error('API call failed:', apiError);
          history.push('/login');
        }
      }else{
        console.log("Account ALready Exists..");
        history.push('/login')
      }
    } catch (error) {
      console.error('Google sign-in failed:', error);
    }

  }
  const handleEmailPasswordSignUp = async (values, setSubmitting) => {
    try {
      const signedInUser = await signUpWIthEmail(values.email,values.password);
      const {name,email,idToken,refreshToken,profiledetails,phoneNumber} = signedInUser;
      if (!profiledetails) {
        try {
          history.push('/account-info-entry', {
              firstName: name?.split(' ')[0],
              lastName: name?.split(' ')[1] || '',
              email: email,
              phoneNumber: phoneNumber,
              token: idToken,
              refreshToken: refreshToken,
              utype:isPatient,
              claim: userProfileId ? true : false,
              profileId: userProfileId ?? "",
          });
        } catch (apiError) {
          console.error('API call failed:', apiError);
          history.push('/login');
        }
      }else{
        console.log("Account ALready Exists..");
        history.push('/login')
      }
    } catch (error) {
      console.error('Google sign-in failed:', error);
    } finally {
      setSubmitting(false);
    }
    
  }
  const handleSendOtp = async (phoneNumber, setSubmitting) => {
    await signInWithPhone(phoneNumber)
      .then((confirmationResult) => {        
        showSuccessMessage("OTP sent successfully");
        setVerificationId(confirmationResult.verificationId);
        setOtpSent(true);
      })
      .catch((error) => {
        console.error('Error sending OTP:', error);
      })
      .finally(() => setSubmitting(false));
  };
  
  const handleSignUpWithMobileOtp = async (code) => {
    try{
      const signedInUser = await signUpWIthMobileOTP(code)
      const {name,email,idToken,refreshToken,profiledetails,phoneNumber} = signedInUser;
      if (!profiledetails) {
        try {
          history.push('/account-info-entry', {
              firstName: name?.split(' ')[0],
              lastName: name?.split(' ')[1] || '',
              email: email,
              phoneNumber: phoneNumber,
              token: idToken,
              refreshToken: refreshToken,
              utype:isPatient,
              claim: userProfileId ? true : false,
              profileId: userProfileId ?? "",
          });
        } catch (apiError) {
          console.error('API call failed:', apiError);
          history.push('/login');
        }
      }else{
        console.log("Account ALready Exists..");
        history.push('/login')
      }

      // if (signedInUser?.accessToken) {
      //   try {
      //     IdentityService.UserAuthentication(signedInUser?.accessToken)
      //       .then((response) => {
      //         console.log("UserAuthentication--------------------------------", response, signedInUser, user);
      //         if (response.status === 200) {
      //           const { data } = response;
      //           getUserDetails(data, signedInUser);
      //           // history.push('/user-info-validate')
      //         } else if(response.status === 404){
      //           setOtpSent(false);
      //           setIsActive(false);
      //           showErrorMessage("Please verify the information you entered and try again, or consider signing up for a new account.");
      //         }
      //       })
      //       .catch((error) => {
      //         console.log("Login Issue..", error);
      //       })
      //   } catch (apiError) {
      //     console.error('API call failed:', apiError);
      //     history.push('/login');
      //   }
      // }
    }catch (error) {
        console.error('Mobile sign-in failed:', error);
      }

  }

  useEffect(() => {
    if (claimProfileId) {
      setUserProfileId(claimProfileId);
    }
  }, [claimProfileId]);
  
  const handleCheckboxChange = () => {
    setIsPatient(!isPatient);
};

  return (
    <section id="claimProfile">
      <div className="nexogic-bg-light">
        <Header />
        <div className="theme-body-bg nexogic-dashboard-card">
          <Container className="nexogic-container-gap">
            <div className="nexogic-login-register-card card border-0 bg-transparent">
              <div className="row">
                <Col
                  lg={7}
                  md={6}
                  xs={12}
                  className="nexogic-form-side-content text-center"
                >
                  <div className="nexogic-form-info ">
                    <p className="hide-on-app"><img src={logo} alt="" className="nexogic-filter-white" /></p>
                    <p className="card-text text-white mb-5 hide-on-app">Create Your Profile</p>
                    <p>
                      <img
                        src={verifyMobileImg}
                        className="img-fluid"
                        alt="nexogic"
                      />
                    </p>
                  </div>
                </Col>
                <Col lg={5} md={6} xs={12} className="nexogic-form-content card-body p-0">
                  <div className="nexogic-form-container">
                        <div className="nex-social-login-btns">
                    <div className="form-group text-right m-1">
                    <div className="form-check">
                      <label className="font-weight-bold mr-4 pr-1">
                        Are you a Patient ? 
                        </label>
                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" checked={isPatient} onChange={handleCheckboxChange} />
                      </div>
                    </div>
                    </div>
                    <SocialClaimForm {...props}
                      onEmailPasswordSignUp={handleEmailPasswordSignUp}
                      onGoogleSignUp={handleGoogleSignUp}
                      onAppleSignUp={handleAppleSignUp}
                      onSignUp={handleSendOtp}
                      onVerifyOtp={handleSignUpWithMobileOtp}
                      otpSent={otpSent} />
                    <p className="card-text text-center mt-4 fw-semibold">
                      Already have an account?{" "}
                      <Link to={`/login`} > Login </Link>{" "}
                    </p>
                  </div>
                </Col>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default withAlert()(ClaimProfileSocial);
