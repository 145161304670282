import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import Headers from './Headers';
import Sections from './Sections';
import Sliders from './Sliders';
import Themes from './Themes';
import Section1 from './Sections/Section1';
import Section2 from './Sections/Section2';
import Section3 from './Sections/Section3';
import Section4 from './Sections/Section4';
import Section5 from './Sections/Section5';
import Section6 from './Sections/Section6';
import Widgets from './Widgets';
import HeaderOne from './Headers/HeaderOne';
import HeaderTwo from './Headers/HeaderTwo';
import WidgetOne from './Widgets/WidgetOne';
import WidgetTwo from './Widgets/WidgetTwo';
import WidgetThree from './Widgets/WidgetThree';
import ThemeOne from './Themes/Components/ThemeOne';
import ThemeTwo from './Themes/Components/ThemeTwo';
import Slider1 from './Sliders/Components/Slider1';
import Slider2 from './Sliders/Components/Slider2';
import Slider3 from './Sliders/Components/Slider3';
import ThemeThree from './Themes/Components/ThemeThree';
import SectionsHeading from './SectionsHeading';
import SectionHeadingOne from './SectionsHeading/SectionHeadingOne';
import SectionHeadingTwo from './SectionsHeading/SectionHeadingTwo';

const UIComponents = () => {
  let { path, url } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${url}/themes/theme1`} component={ThemeOne} />
        <Route path={`${url}/themes/theme2`} component={ThemeTwo} />
        <Route path={`${url}/themes/theme3`} component={ThemeThree} />
        <Route path={`${url}/themes`} component={Themes} />
        <Route path={`${url}/headers/header1`} component={HeaderOne} />
        <Route path={`${url}/headers/header2`} component={HeaderTwo} />
        <Route path={`${url}/headers`} component={Headers} />
        <Route path={`${url}/headings/heading1`} component={SectionHeadingOne} />
        <Route path={`${url}/headings/heading2`} component={SectionHeadingTwo} />
        <Route path={`${url}/headings`} component={SectionsHeading} />
        
        <Route exact path={`${url}/sections/section1`} component={Section1} />
        <Route exact path={`${url}/sections/section2`} component={Section2} />
        <Route exact path={`${url}/sections/section3`} component={Section3} />
        <Route exact path={`${url}/sections/section4`} component={Section4} />
        <Route exact path={`${url}/sections/section5`} component={Section5} />
        <Route exact path={`${url}/sections/section6`} component={Section6} />
        <Route path={`${url}/sections`} component={Sections} />
        <Route path={`${url}/sliders/slider1`} component={Slider1} />
        <Route path={`${url}/sliders/slider2`} component={Slider2} />
        <Route path={`${url}/sliders/slider3`} component={Slider3} />
        <Route path={`${url}/sliders`} component={Sliders} />

        <Route path={`${url}/widgets/widget1`} component={WidgetOne} />
        <Route path={`${url}/widgets/widget2`} component={WidgetTwo} />
        <Route path={`${url}/widgets/widget3`} component={WidgetThree} />
        <Route path={`${url}/widgets`} component={Widgets} />
        {/* <Route path={`${url}/update-a-job/:jobId`} component={CreateJobContainer} /> */}
        <Redirect exact to={`${url}/themes`} /> 
      </Switch>
      
    {/* <Card className="">
      <div className="microsite-side">
    <h1>UIs</h1>
    </div>
    </Card> */}
    </>
  )
}

export default UIComponents