import AlertDialog from "common/AlertDialog";
import { first } from "lodash";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import {
  profileAddEvent,
  setGlobalprofile,
  set_Recent_Education_User,
  updateProfileCompletness,
} from "redux-helpers/ActionCreator";
import { UserStatusConstants } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import { getUser } from "utils/UserHelper";
import {
  dateDifferenceInYears,
  getEmptySectionShowHide,
  getFullAddress,
  makeFullNameWithAddressWhenAddressIsNested,
  renderDateRange,
} from "utils/Utils";
import { RenderEducationModal } from "./components/RenderEducationModal";
import education_avatar from "assets/images/education-avatar-v2.png";
import NoData from "../NoData";


const initialValue = {
  institutionDetail: {
    institutionName: "",
    guid: "",
    onSelectNewMedicalCollege: 0,
  },
  addressLine2: "",
  city: [],
  country: [],
  location: [],
  postalCode: "",
  state: [],
  addressType: "BILLING_ADDRESS",
  degree: "",
  fieldOfStudy: [],
  startYear: "",
  endYear: "",
  additionalInfo: "",
  websiteAddress: "",
  mainItemName: "",
  category: "",
  isStudying: false,
};

const RenderEducation = (props) => {
  const {
    isAdmin,
    educations,
    handleEditItem,
    handleRemoveItem,
    isLoggedinUser,
  } = props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  if (educations.length > 0) {
    const sortedEducations = educations.sort((a, b) => {
      return b.startYear - a.startYear;
    });
    return sortedEducations.map((item, index) => {
      const { startYear, passingYear, isStudying } = item;
      const timeDuration = renderDateRange(startYear, passingYear, isStudying);
      const profileIdLink = item?.institutionDetail?.profileId
        ? item?.institutionDetail?.profileId
        : item?.institutionDetail?.guid;
      const institutionName = getFullAddress({
        name: item?.institutionDetail?.name ?? "",
        abbreviation: item?.institutionDetail?.abbreviation ?? "",
        location: item?.institutionDetail?.address?.location ?? "",
        city: item?.institutionDetail?.address?.city ?? "",
        state: item?.institutionDetail?.address?.state ?? "",
      });
      const avatarId = item?.institutionDetail?.avatarId ?? null;
      const instGuid = item?.institutionDetail?.guid ?? null;

      return (
        <li
          className="nex-icon-list-item"
          key={`education_${index}`}
        >
            <div className="card-graphic">
              {avatarId ?
                <img
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${instGuid}/${avatarId}`}
                  style={{ maxHeight: "46px" }}
                  alt="" />
                :
                <img src={education_avatar} alt="" className="contain-bg" />
              }
            </div>
            <div className="nex-info">
              <h3 className="card-title"><Link
                to={`/institution/${profileIdLink}`}
                className="mb-2 color-accent"
              >{institutionName}</Link></h3>

              <p className="card-text">{item.courseTitle} {item.specialization ? ', '+item.specialization :''}</p>
              <p className="card-text">{item.startYear} -{" "}{item.isStudying ? <span>{"Present | "}{dateDifferenceInYears(item?.startYear)}</span> : item.passingYear}</p>
              <p className="card-text">{timeDuration}</p>
              <div className="nex-action">
              {(isAdmin ||
                (isLoggedinUser &&
                  currentUser.status === UserStatusConstants.APPROVED)) && (
                  <>
                    
                      <Button
                        color="link"
                        className="visible-hover py-0 nex-edu-loop-edit-btn"
                        onClick={() => handleEditItem(item)}
                      >
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="15.9062" cy="16" r="15.375" fill="white" stroke="#DBF4FB" stroke-width="0.75"/>
<path d="M19.3612 9.53125L17.8557 11.0368L20.8667 14.0478L22.3722 12.5423L19.3612 9.53125ZM16.3502 12.5423L10.3281 18.5643V21.5754H13.3392L19.3612 15.5533L16.3502 12.5423Z" fill="#46ABC9"/>
</svg>
                      </Button>

                      <Button
                        color="link"
                        className="visible-hover py-0 delete nex-edu-loop-del-btn"
                        onClick={() => handleRemoveItem(item)}
                      >
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="16.2266" cy="16" r="15.75" fill="#FFEAEF"/>
<path d="M11.3984 13.7734L12.2586 20.1323C12.426 21.3702 12.5097 21.9892 12.9337 22.3597C13.3577 22.7302 13.9823 22.7302 15.2315 22.7302H18.4692C19.7184 22.7302 20.343 22.7302 20.767 22.3597C21.191 21.9892 21.2747 21.3702 21.4421 20.1323L22.3023 13.7734" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.5156 11.0475V11.0475C14.5156 10.2672 14.5156 9.87708 14.6572 9.57473C14.8061 9.25693 15.0616 9.00138 15.3794 8.85254C15.6818 8.71094 16.0719 8.71094 16.8522 8.71094V8.71094C17.6324 8.71094 18.0226 8.71094 18.3249 8.85254C18.6427 9.00138 18.8983 9.25693 19.0471 9.57473C19.1887 9.87708 19.1887 10.2672 19.1887 11.0475V11.0475" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.3023 11.0547L11.3984 11.0547" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.8906 14.1719L15.28 20.0132" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.7957 14.1719L18.4062 20.0132" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                      </Button>
                    
                  </>
                )}
            </div>
            </div>
            {/** 
            <div className="education flex-grow-1">
              <div className="d-flex">
                <div className="img-32px">
                  <FontAwesomeIcon
                    icon={faUniversity}
                    className="fa-fw"
                    size="2x"
                  />
                </div>
                <div className="pl-2">
                  <Link
                    to={`/institution/${profileIdLink}`}
                    className="mb-2 color-accent"
                  >
                    <h6 className="section-item-title item-blue-title m-0">
                      {institutionName}
                    </h6>
                  </Link>
                  <div className="section-item-des mt-2">
                    {item.courseTitle} - {item.specialization}
                  </div>
                  <div className="section-item-shortdes mt-2 text-capitalize">
                    {item.startYear} -{" "}
                    {item.isStudying ? "Present" : item.passingYear}
                  </div>
                  <div className="section-item-shortdes mt-2">
                    {timeDuration}
                  </div>
                </div>
              </div>
            </div>
            */}
            {/**  
            <div className="col-auto d-flex align-items-start">
              {(isAdmin ||
                (isLoggedinUser &&
                  currentUser.status === UserStatusConstants.APPROVED)) && (
                  <>
                    <div className="delete-action">
                      <Button
                        color="link"
                        className="visible-hover py-0 nex-edu-loop-edit-btn"
                        onClick={() => handleEditItem(item)}
                      >
                        <img src={edit_blue_icon} alt="" />
                      </Button>

                      <Button
                        color="link"
                        className="visible-hover py-0 delete nex-edu-loop-del-btn"
                        onClick={() => handleRemoveItem(item)}
                      >
                        <img src={del_red_icon} alt="" />
                      </Button>
                    </div>
                  </>
                )}
            </div>
          */}
        </li>
      );
    });
  }
  return null;
};

const Education = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { guid } = props.practitioner;
  const eventName = useSelector((state) => state.profileComplete.eventName);
  const dispatch = useDispatch();
  const practitionerGuid = props.practitioner.guid;
  const { alert, isLoggedinUser, isAdmin, setShowEducationsOnTabScroll } = props;
  const [educations, setEducation] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);
  const [isOpenAlert, setAlertModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const toggleAlert = () => {
    setLoading(false);
    setAlertModal(!isOpenAlert);
  };

  useEffect(() => {
    if (practitionerGuid) {
      getData();
    }
  }, [practitionerGuid]);

  useEffect(() => {
    dispatch(updateProfileCompletness(5, !!educations.length));
    if (educations.length > 0 || isLoggedinUser || isAdmin) {
      setShowEducationsOnTabScroll(true);
    }else{
      setShowEducationsOnTabScroll(false);
    }
  }, [educations]);

  useEffect(() => {
    if (eventName === "ADD_EDUCATION") {
      handleEditItem(initialValue);
      dispatch(profileAddEvent(null));
    }
  }, [eventName]);

  const formatEditItemData = (item) => {
    return {
      institutionDetail: {
        institutionName: item?.institutionDetail ? makeFullNameWithAddressWhenAddressIsNested(item?.institutionDetail) : "",
        guid: item?.institutionDetail.guid ?? "",
        onSelectNewMedicalCollege: 0,
      },
      addressLine2: "",
      city: [],
      country: [],
      location: [],
      postalCode: "",
      state: [],
      addressType: "BILLING_ADDRESS",
      degree: item?.courseTitle,
      fieldOfStudy: item?.specialization,
      startYear: item?.startYear,
      endYear: item?.passingYear,
      additionalInfo: item?.additionalInfo,
      websiteAddress: item?.institutionDetail.websiteURL,
      mainItemName: "",
      category: "",
      id: item?.guid ?? "",
      isStudying: item?.isStudying ?? false,
    };
  };

  const handleEditItem = (selectedItem) => {
    setSelectedItem(formatEditItemData(selectedItem));
    toggle();
  };

  const handleRemoveItem = (selectedItem) => {
    setSelectedItem(formatEditItemData(selectedItem));
    toggleAlert();
  };

  const updateGlobalprofileStorage = (guid) => {
    PractitionerService.gePractitionersDetails(guid).then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(setGlobalprofile(response.data));
      }
    });
  };

  const handleClickOnYes = async () => {
    const userGUID = globalProfileInfo.guid;
    setLoading(true);
    try {
      const request = PractitionerService.deleteEducationsDetails(
        userGUID,
        selectedItem.id
      );
      request.then(
        async (response) => {
          const { data, status } = response;
          if (status === 204) {
            await getData();
            setLoading(false);
            updateGlobalprofileStorage(globalProfileInfo?.guid);
            showSuccessMessage("Education deleted successfully");
            toggleAlert();
          } else {
            showErrorMessage(data.status.message);
            setLoading(false);
            toggleAlert();
          }
        },
        (e) => {
          setLoading(false);
          let errmsg = e.message;
          if (e.response) {
            errmsg =
              e.response.data && !!e.response.data.message
                ? e.response.data.message
                : errmsg;
          }
          showErrorMessage(errmsg);
        }
      );
    } catch (e) {
      showErrorMessage(e.message);
      setLoading(false);
      toggleAlert();
    }
  };

  const getData = async () => {
    const request = PractitionerService.getEducation(practitionerGuid);
    request.then((response) => {
      let { data } = response;
      if (data && data.length > 0) {
        setEducation(data);
        const incoming_data = first(data) ? [first(data)] : [];
        dispatch(set_Recent_Education_User(incoming_data));
      } else {
        setEducation([]);
        dispatch(set_Recent_Education_User([]));
      }
    });
  };

  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  if (
    getEmptySectionShowHide(
      educations ?? [],
      isAdmin,
      currentUser,
      globalProfileInfo,
      guid
    )
  )
    return null;

  return (
    <>
      <Card className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="educationSection">
        <div className="card-header"><h2>Education</h2>
          {(isAdmin ||
            (isLoggedinUser &&
              currentUser.status === UserStatusConstants.APPROVED)) && (
              <Button
                id="nex-edu-add-button"
                color="link"
                className="add-button ml-auto"
                onClick={() => handleEditItem(initialValue)}
              >
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="9.75" cy="9.75" r="8.4375" fill="white" stroke="#46ABC9" stroke-width="1.125"/>
<path d="M9.625 6L9.625 13.2539" stroke="#46ABC9" stroke-width="1.125" stroke-linecap="round"/>
<path d="M13.2656 9.63281L6.01172 9.63281" stroke="#46ABC9" stroke-width="1.125" stroke-linecap="round"/>
</svg> Add More Education

              </Button>
            )}

          <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
          <svg width={17}  height={10} viewBox="0 0 17 10" fill="none"  xmlns="http://www.w3.org/2000/svg" >
                        <path
                          d="M2.25 1.625L8.625 8L15 1.625"
                          stroke="black"
                          strokeWidth={3}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
            </svg>
          </button>
        </div>
        <div className={`collapse-div`}>
          <CardBody className={`${educations && educations.length > 0 ? "" : "align-items-center"}`}>
            {educations && educations.length
              ? ""
              : (isLoggedinUser || isAdmin) &&
              currentUser.status === UserStatusConstants.APPROVED && (
                <Button
                  id="nex-edu-add-button2"
                  className="readmore"
                  color="link"
                  onClick={() => handleEditItem(initialValue)}
                >
                  {"Add education"}
                </Button>
              )}

            <ul className="nex-icon-list-within-card" id="nex-edu-lists">
              <RenderEducation
                educations={educations}
                handleEditItem={handleEditItem}
                handleRemoveItem={handleRemoveItem}
                isLoggedinUser={isLoggedinUser}
                isAdmin={isAdmin}
              />
            </ul>
            {educations.length < 1 && 
            <NoData>No Data</NoData>
            }
            <RenderEducationModal
              selectedItem={selectedItem}
              toggle={toggle}
              isOpen={isOpen}
              getData={getData}
              isAdmin={isAdmin}
            />
            <AlertDialog
              toggle={toggleAlert}
              isOpen={isOpenAlert}
              isLoading={isLoading}
              handleClickOnYes={handleClickOnYes}
              yesLabel={"Yes"}
              noLabel={"No"}
            />
          </CardBody>
        </div>
      </Card>
    </>
  );
};

export default withAlert()(Education);
