import React from 'react'
import IconVideo from '../components/icons/IconVideo';
import user_img from 'assets/images/user-profile-sample.png';
import IconVarify from '../components/icons/IconVarify';

 function DoctorShortInfo(props) {
  return (
        <div className="nexogic-user-search-list card" id={props.id}>
                                <div className="nexogic-thumb-preview-w">
                                    <div className="nexogic-thumb-preview">
                                        <img src={user_img} alt="" />
                                        <span className="icon-upload-btn icon text-white">
                                            <IconVideo />
                                        </span>
                                    </div>
                                    {/* review */}
                                    <div className="card-text"><span className="icon"><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="13"
                                        fill="none"
                                        viewBox="0 0 14 13"
                                    >
                                        <path
                                            fill="#FF9D07"
                                            d="M7 10.513L11.12 13l-1.094-4.687 3.64-3.153-4.793-.407L7 .333l-1.874 4.42-4.793.407 3.64 3.153L2.88 13 7 10.513z"
                                        ></path>
                                    </svg></span> <span>5.0</span>
                                        <div><a className='link underline'>24 reviews</a></div>
                                    </div>
                                </div>
                                <div className="card-body">

                                    <div className="row">
                                        <div className="user-information col-lg-8">
                                            <h3 className="card-title"><a className='link'>Dr. Konstantin Maklackov</a> <span className='icon'><IconVarify /></span></h3>
                                            <p className="card-text md">MBBS, MS - Ortopaedics, Orthopedist, Joint replacement surgeon </p>
                                            <div className="nexogic-doctor-exp">
                                                <p className="card-text md">32 years experience overall</p>
                                                <p className="card-text md">12 years as a specialist</p>
                                            </div>
                                            <div className="card-text"><span className="icon"><svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14 7.54545C14 12.6364 7.5 17 7.5 17C7.5 17 1 12.6364 1 7.54545C1 5.80949 1.68482 4.14463 2.90381 2.91712C4.12279 1.68961 5.77609 1 7.5 1C9.22391 1 10.8772 1.68961 12.0962 2.91712C13.3152 4.14463 14 5.80949 14 7.54545Z" stroke="#3EABCA" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M7.5 9.72727C8.69662 9.72727 9.66667 8.75044 9.66667 7.54545C9.66667 6.34047 8.69662 5.36364 7.5 5.36364C6.30338 5.36364 5.33333 6.34047 5.33333 7.54545C5.33333 8.75044 6.30338 9.72727 7.5 9.72727Z" stroke="#3EABCA" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></span> California, CA, 18974</div>
                                            <div className="card-text nexogic-fee-text">Consultation fee : <b>₹700</b></div>
                                        </div>
                                        {props.availableBooking && 
                                            <div className="nexogic-user-action col-lg-4">
                                            <div className="nexogic-appoint-available ">
                                                <span className="icon">
                                                    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.1111 1V4.2M4.88889 1V4.2M1 7.4H15M2.55556 2.6H13.4444C14.3036 2.6 15 3.31634 15 4.2V15.4C15 16.2837 14.3036 17 13.4444 17H2.55556C1.69645 17 1 16.2837 1 15.4V4.2C1 3.31634 1.69645 2.6 2.55556 2.6Z" stroke="#4AA928" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round" />
                                                    </svg></span>

                                                Available Today
                                            </div>
                                            <a href="" className="btn nexogic_primary_button">Book appointment</a>
                                            <p className='card-text mb-0'>No Booking fee</p>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>

  )
}

DoctorShortInfo.defaultProps = {
    availableBooking: false
}

export default DoctorShortInfo;
