import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, CardBody, Card, Modal, ModalHeader, ModalBody } from "reactstrap";
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import entryData from "pages/Referals/Components/Common/data/CommonData";
import RejectionReason from "./Components/RejectionReason";
import BackIcon from "assets/images/backIcon.png";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import referals_icon from "assets/images/svgs/referals-icon.svg";
import { useState } from "react";
import { useEffect } from "react";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import profile_sample from "assets/images/user-profile-sample.png";
import plus_icon from "assets/images/plus-icon-lg.png";
import PatientDetailsInfo from "./Components/PatientDetailsInfo";
import TreatmentInfo from "./Components/TreatmentInfo";
import PatientStatus from "./Components/PatientStatus";
import { ReferralResponseService } from "utils/ReferralResponseService";
import { ReferralService } from "utils/ReferralService";
import { FAILTOSAVERECOED } from "utils/Constants";
import UpdateStatusModal from "./Components/UpdateStatusModal";
import { MedialService } from "utils/MediaService";
import { useRef } from "react";
import { withAlert } from "react-alert";
import CommonLoader from "common/CommonLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PeopleKnowWidget from "./PeopleKnowWidget";
import swal from "sweetalert";
import { getFileIcon } from "utils/Utils";
import ReferWidget from "common/ReferWidget/ReferWidget";


const ResponseDetails = (props) => {
    const [isResponseLoading, setIsResponseLoading] = useState(true);
    const [pannel_one_on, setPannel_one_on] = useState(true);
    const [pannel_two_on, setPannel_two_on] = useState(true);
    const [pannel_three_on, setPannel_three_on] = useState(true);
    const [pannel_four_on, setPannel_four_on] = useState(true);
    const [reqAssign, setReqAssign] = useState(false);
    // const [isAssignModal, setIsAssignModal] = useState(false);
    const [referral, setReferral] = useState({});
    const [referralResponse, setReferralResponse] = useState();
    const [docsInfo, setDocsInfo] = useState([]);
    const [isFileUploaing, setIsFileUploaing] = useState(false);
    const fileInputRef = useRef(null);
    const [dataLoading, setDataLoading] = useState(false);
    const [documentType, setDocumentType] = useState(null);
    const [documentData, setDocumentData] = useState(null);

    const [statusModal, setStatusModal] = useState(false);
    const toggleUpdateStatus = () => setStatusModal(!statusModal);

    const [modal, setModal] = useState(false);

    const [isResponseSent, setIsResponseSent] = useState(false);
    const [isEditable, setIsEditable] = useState(false);

    let { id } = useParams();
    const history = useHistory();
    const handleBack = () => {
        history.goBack()
    }
    const filteredArray = entryData.filter((data) => {
        return id === data.id
    });
    const values = filteredArray[0];
    const statusStyling = {
        color: values?.status === "Pending" ? "#3EABCA" : (values?.status === "Rejected" ? "#FF9090" : "#A3DD72"),
        fontWeight: "600px"
    }

    useEffect(() => {
        let url = window.location.pathname;
        const myArray = url.split("/");
        let txt = myArray[myArray.length - 2];
        if (txt === "sent-response") {
            setIsResponseSent(true);
        }
        else {
            setIsResponseSent(false);
        }
    }, [id])


    const getDocsInfo = (id) => {
        MedialService.getMedias("REFERRAL", id)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    // console.log(data);
                    setDocsInfo(data);
                } else {
                    console.log(FAILTOSAVERECOED);
                }
            })
    }


    // function openAssignModal(e) {
    //     e.preventDefault();
    //     setIsAssignModal(!isAssignModal);
    //     console.log(isAssignModal);
    // }

    // function haldleAssign(data) {
    //     if (data === true) {
    //         setIsAssignModal(false);
    //         setReqAssign(data);
    //     }
    // }

    // function haldleUnassign(e) {
    //     e.preventDefault();
    //     setReqAssign(false);
    // }

    const getReferralById = (id) => {
        let url = window.location.pathname;
        const myArray = url.split("/");
        let txt = myArray[myArray.length - 2];

        ReferralService.getReferralById(id, txt === "sent-response" ? "RECEIVED" : "SENT")
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setReferral(data);
                    setIsResponseLoading(false);
                    if (data?.status === 'ACCEPTED' || data?.status === 'IN_PROGRESS') {
                        setIsEditable(true);
                    }
                } else {
                    console.log(FAILTOSAVERECOED);
                }
            })
    }

    const getReferralResponse = (id) => {
        let url = window.location.pathname;
        const myArray = url.split("/");
        let txt = myArray[myArray.length - 2];
        ReferralResponseService.getReferralResponse(id, txt === "sent-response" ? "RECEIVED" : "SENT")
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setReferralResponse(data);
                    getDocsInfo(data?.id)
                }
                else {
                    console.log(FAILTOSAVERECOED);
                }
            })
    }

    useEffect(() => {
        getReferralById(id);
        getReferralResponse(id);
    }, [id])


    useEffect(() => {
        document.body.classList.add("nexogic-refer-page");
        return () => document.body.classList.remove("nexogic-refer-page");
    }, []);

    const toggleForm = () => setModal(!modal);
    const getReferralDoc = async (id) => {
        try {
            setDataLoading(true);
            const { data, headers } = await MedialService.getMediaFile(id);
            const createContentType = headers["content-type"] === "application/octet-stream"
                ? "application/pdf"
                : headers["content-type"];
            let generateURL = `data:${createContentType};base64,${Buffer.from(data).toString("base64")}`;
            setDocumentData(generateURL);
            let docArray = [data, createContentType];
            setDocumentType(docArray);
            if (createContentType === "application/pdf") {
                setDataLoading(false);
                onOpenBase64(generateURL);
            }
            else {
                setDataLoading(false);
                toggleForm();
            }
        } catch (error) {
            console.error("Error retrieving referral document:", error);
        }
    };


    const onOpenBase64 = (base64URL) => {
        // const onOpenBase64 = () => {
        try {
            var win = window.open();
            win.document.write(`
            <iframe class="nexogic-iframe" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" 
              src="${base64URL}" frameborder="0" allowfullscreen></iframe>
          `);
        } catch (error) {
            console.error("Error opening base64 URL:", error);
            // Handle error, show error message, etc.
        }
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = documentData;
        let ext = documentType[1].split('/').pop();
        link.download = 'reports.' + ext;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const maxSizeInBytes = 10 * 1024 * 1024;
            if (selectedFile.size > maxSizeInBytes) {
                showErrorMessage("File size exceeds 10MB. Please upload a smaller file.");
                return;
            }
            setIsFileUploaing(true);
            const formData = new FormData();
            formData.append('files', selectedFile);
            MedialService.uploadMedia("REFERRAL", referralResponse?.id, formData)
                .then((response) => {
                    const { status } = response;
                    if (status === 200) {
                        showSuccessMessage("Document uploaded Successfully");
                        getDocsInfo(referralResponse?.id);
                        setIsFileUploaing(false);
                    } else if (status === 409) {
                        props.alert.info("Can't upload the document with the same name again");
                        setIsFileUploaing(false);
                    }
                    else {
                        showErrorMessage(FAILTOSAVERECOED);
                        setIsFileUploaing(false);
                    }
                })
        }
    };


    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const canNotAddTreatmentAlert = () => {
        swal("Patient Status is required to add before adding Treatment Details");
    };

    return (
        <div className="complete-form">
            <div className="routing-header d-flex justify-content-between align-items-center mb-3">
                <div className="nexogic-top-section-title">
                    <span><Link onClick={handleBack}><img src={BackIcon} alt="back" /></Link></span>
                    <h2 className="h2">Referral Response <span>{!isResponseLoading && `(${referral?.patient?.firstName} ${referral?.patient?.lastName})`}</span></h2>
                </div>
            </div>
            {isResponseLoading ?
                <div className="loading-spiner text-center  mt-2">
                    <CommonLoader />
                </div>
                :
                <Card className="nexogic-form-text-display-w border-0 bg-transparent">
                    <CardBody className="px-0">
                        <Row className="need-margin">
                            <Col xl={9}>
                                <div className="position-relative mb-3">
                                    <Card className={`nexogic-card-color-header ${pannel_one_on ? 'expanded' : ''}`}>
                                        <div className="card-header">
                                            <h2>Patient Details</h2>
                                            <button className="collapse-btn" type="button" onClick={() => setPannel_one_on(!pannel_one_on)}>
                                                <img src={pannel_arrow} alt="" />
                                            </button>
                                        </div>
                                        <div className="collapse-div">
                                            <CardBody>
                                                {referral &&
                                                    <PatientDetailsInfo patient={referral?.patient} patientType={referral?.patientType} />
                                                }
                                            </CardBody>
                                        </div>
                                    </Card>
                                </div>
                                {/** Patient  Status */}
                                <div className="position-relative mb-3">
                                    <Card className={`nexogic-card-color-header ${pannel_two_on ? 'expanded' : ''}`}>
                                        <div className="card-header">
                                            <h2>Patient Status</h2>
                                            <button className="collapse-btn" type="button" onClick={() => setPannel_two_on(!pannel_two_on)}>
                                                <img src={pannel_arrow} alt="" />
                                            </button>
                                            {(isResponseSent && isEditable) &&
                                                <button className="add-button btn btn-link" type="button" id="nex-rds-plus-icon-btn" >
                                                    <img src={plus_icon} alt="" onClick={toggleUpdateStatus} />
                                                </button>
                                            }
                                        </div>
                                        <div className="collapse-div">
                                            <CardBody>
                                                {(referralResponse && referralResponse.patientStatuses && referralResponse.patientStatuses.length > 0) ?
                                                    < PatientStatus
                                                        referral={referral}
                                                        referralResponse={referralResponse}
                                                        getReferralResponse={getReferralResponse}
                                                        isResponseSent={isResponseSent}
                                                        isEditable={isEditable}
                                                    />
                                                    :
                                                    <div className="nexogic-not-found-text">Not Available</div>
                                                }
                                            </CardBody>
                                        </div>
                                    </Card>
                                </div>
                                <UpdateStatusModal
                                    toggle={toggleUpdateStatus}
                                    modal={statusModal}
                                    setModal={setStatusModal}
                                    referralId={referral?.id}
                                    responseId={referralResponse?.id}
                                    getReferralResponse={getReferralResponse}
                                />

                                {/** Treatment Details  */}
                                <div className="position-relative mb-3">
                                    <Card className={`nexogic-card-color-header ${pannel_three_on ? 'expanded' : ''}`}>
                                        <div className="card-header">
                                            <h2>Treatment Detail</h2>
                                            {isResponseSent && isEditable &&
                                                <>
                                                    {(referralResponse && referralResponse?.patientStatuses && referralResponse?.patientStatuses?.length > 0)
                                                        ?
                                                        <Link to={`/referrals/${referral?.id}/treatment`} className="add-button btn btn-link"><img src={plus_icon} alt="" id="nex-rds-plus-icon-btn2" /></Link>
                                                        :
                                                        <button className="add-button btn btn-link" type="button" id="nex-rds-plus-icon-btn3">
                                                            <img src={plus_icon} alt="" onClick={() => {
                                                                canNotAddTreatmentAlert();
                                                            }} />
                                                        </button>
                                                    }
                                                </>
                                            }
                                            <button className="collapse-btn" type="button" onClick={() => setPannel_three_on(!pannel_three_on)}>
                                                <img src={pannel_arrow} alt="" />
                                            </button>
                                        </div>
                                        <div className="collapse-div">
                                            <CardBody>
                                                {(referralResponse && referralResponse.treatments && referralResponse.treatments.length > 0)
                                                    ?
                                                    <TreatmentInfo treatments={referralResponse?.treatments} isResponseSent={isResponseSent} isEditable={isEditable} />
                                                    :
                                                    <div className="nexogic-not-found-text">Not Available</div>
                                                }
                                            </CardBody>
                                        </div>
                                    </Card>
                                </div>

                                {/** Comunication Section */}
                                {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                                    <div className="position-relative mb-3">
                                        <Card className={`nexogic-card-color-header nexogic-comunication-box ${pannel_four_on ? 'expanded' : ''}`}>
                                            <div className="card-header">
                                                <h2>Communication</h2>
                                                <button className="collapse-btn" type="button" onClick={() => setPannel_four_on(!pannel_four_on)}>
                                                    <img src={pannel_arrow} alt="" />
                                                </button>
                                            </div>
                                            <div className="collapse-div">
                                                <CardBody>
                                                    <div className="nexogic-floated-input">
                                                        <textarea type="text" name="ref_comment" id="ref_comment" className='form-control nexogic-form-control-gray small' placeholder="Write your comment"></textarea>
                                                        <button type="button" name="ref_comment_submit" id="ref_comment_submit" className='btn btn-trans'> <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="23"
                                                            height="22"
                                                            fill="none"
                                                            viewBox="0 0 23 22"
                                                        >
                                                            <path
                                                                stroke="#3EABCA"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M21.252 11.797l-15.538-.738m15.538.738L1.746 20.073l3.968-9.014m15.538.738L2.618 1.709l3.096 9.35"
                                                            ></path>
                                                        </svg></button>
                                                    </div>
                                                    <div className="nexogic-custom-scroll">

                                                        <div className="nexogic-comment-lists">
                                                            <ul>
                                                                <li>
                                                                    <div className="nexogic-comment-item">
                                                                        <div className="graphic">
                                                                            <img src="https://images-dev.nexogic.com/53efb6fd-1ceb-412a-b19d-b65032bde722/a8dd998a-8e20-4ac4-971f-bf91154aa6fc.png" alt="" className='card-img' />
                                                                        </div>
                                                                        <div className="info">
                                                                            <div className="comment-text">
                                                                                <h3 className="card-title">Steve Jobs <span className='text-muted'>2h ago</span></h3>
                                                                                <p className='card-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum voluptates, earum, dolore architecto odio dolor ipsa saepe possimus, aliquam omnis facere aut praesentium. Labore cupiditate, doloribus reprehenderit placeat nihil doloremque impedit totam est esse explicabo!</p>
                                                                            </div>
                                                                            <p className='card-text'><a href="" className="link">Reply</a></p>
                                                                        </div>
                                                                    </div>
                                                                    <ul>
                                                                        <li>
                                                                            <div className="nexogic-comment-item">
                                                                                <div className="graphic">
                                                                                    <img src="https://images-dev.nexogic.com/53efb6fd-1ceb-412a-b19d-b65032bde722/a8dd998a-8e20-4ac4-971f-bf91154aa6fc.png" alt="" className='card-img' />
                                                                                </div>
                                                                                <div className="info">
                                                                                    <div className="comment-text">
                                                                                        <h3 className="card-title">Steve Jobs <span className='text-muted'>2h ago</span></h3>
                                                                                        <p className='card-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum voluptates, earum, dolore architecto odio dolor ipsa saepe possimus, aliquam omnis facere aut praesentium. Labore cupiditate, doloribus reprehenderit placeat nihil doloremque impedit totam est esse explicabo!</p>
                                                                                    </div>
                                                                                    <p className='card-text'><a href="" className="link">Reply</a></p>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                                <li>
                                                                    <div className="nexogic-comment-item">
                                                                        <div className="graphic">
                                                                            <img src="https://images-dev.nexogic.com/53efb6fd-1ceb-412a-b19d-b65032bde722/a8dd998a-8e20-4ac4-971f-bf91154aa6fc.png" alt="" className='card-img' />
                                                                        </div>
                                                                        <div className="info">
                                                                            <div className="comment-text">
                                                                                <h3 className="card-title">Steve Jobs <span className='text-muted'>2h ago</span></h3>
                                                                                <p className='card-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum voluptates, earum, dolore architecto odio dolor ipsa saepe possimus, aliquam omnis facere aut praesentium. Labore cupiditate, doloribus reprehenderit placeat nihil doloremque impedit totam est esse explicabo!</p>
                                                                            </div>
                                                                            <p className='card-text'><a href="" className="link">Reply</a></p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </div>
                                        </Card>
                                    </div>
                                }

                                {/** Why Request Rejected*/}
                                {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                                    <>
                                        {
                                            (values?.status === "Rejected") &&
                                            (
                                                <>
                                                    <div className="position-relative mb-3">
                                                        <Card className="card nexogic-card-color-header expanded">
                                                            <div className="card-header">
                                                                <h2>Reason for Rejection</h2>
                                                            </div>
                                                            <div className="collapse-div">
                                                                <CardBody>
                                                                    <RejectionReason values={values} />
                                                                </CardBody>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </>
                                }



                            </Col>
                            <Col xl={3} className="position-relative ">
                                {/*
                        <div className="nexogic-widget-sidebar-card card border-0">
                            <div className="card-body">
                            <div className="nexogic-h-btn-groups">
                                {reqAssign === false && (
                                <a
                                    href=""
                                    className="btn d-block nexogic_primary_button"
                                    onClick={(e) => openAssignModal(e)}
                                >
                                    Assign
                                </a>
                                )}

                                {reqAssign === true && (
                                <Link
                                    to="/referals/treatment/"
                                    className="btn d-block nexogic_primary_button"
                                >
                                    Provide  Consultation
                                </Link>
                                )}
                            </div>
                            </div>
                        </div>
                                */}
                                {/** Selected assign patitioner or groups */}
                                {reqAssign === true && (
                                    <div className="nexogic-widget-sidebar-card card border-0 nexogic-widget-assign">
                                        <h3 className="card-title h2">Currently Assign</h3>
                                        <div className="card-body">
                                            <div className="nexogic-assign-pratitioner">
                                                <div className="nexogic-practitioner-search-lists">
                                                    <div className="item selected">
                                                        <div className="graphic">
                                                            <img src={profile_sample} alt="" className="avatar" />
                                                        </div>
                                                        <div className="info">
                                                            <h3 className="card-title">Dr. Anamica Mitra (MBBs)</h3>
                                                            <p className="card-text light">Amri hospital</p>
                                                        </div>
                                                        <span className="active-check">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="9"
                                                                fill="none"
                                                                viewBox="0 0 21 14"
                                                            >
                                                                <path
                                                                    stroke="#fff"
                                                                    strokeWidth="2"
                                                                    d="M1 4.781L7.88 12 20 1"
                                                                ></path>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="actions">

                                            <a
                                                href=""
                                                className="btn d-block nexogic_primary_button_red"
                                                onClick={(e) => haldleUnassign(e)}
                                            >
                                                Unassign
                                            </a>

                                        </div> */}
                                        </div>
                                    </div>
                                )}
                                {referralResponse &&
                                    <Card className="nexogic-widget-sidebar-card document-sidebar-widget">
                                        <h2 className="nex-aside-title">
                                            Response Documents
                                            <span className="icon ml-auto"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 13H14M8 17H16M13 3H5V21H19V9M13 3H14L19 8V9M13 3V7C13 8 14 9 15 9H19" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            </span>
                                        </h2>
                                        {/* <h2 className="card-title h2 d-flex flex-wrap">Response Documents */}
                                        {/* {isEditable && isResponseSent &&
                                                <>
                                                    {isFileUploaing ?
                                                        <div className="text-center mb-5" style={{ height: "10px" }} >
                                                            <CommonLoader />
                                                        </div>
                                                        :
                                                        <button className="btn nexogic-download-btn nexogic_primary_button" onClick={handleUploadClick}>
                                                            <span className="icon"><svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                fill="none"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <path
                                                                    stroke="#fff"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth="2"
                                                                    d="M15 10.333v3.111A1.556 1.556 0 0113.444 15H2.556A1.556 1.556 0 011 13.444v-3.11m10.889-5.445L8 1m0 0L4.111 4.889M8 1v9.333"
                                                                ></path>
                                                            </svg>
                                                            </span> Uploads
                                                        </button>
                                                    }

                                                    <input
                                                        name="uploadDocument"
                                                        type="file"
                                                        id="uploadDocument"
                                                        accept="image/jpeg,image/jpg,image/png,application/pdf"
                                                        title="Upload Document"
                                                        className="nexogic-file-upload-input"
                                                        style={{ display: 'none' }}
                                                        ref={fileInputRef}
                                                        onChange={handleFileChange}
                                                    />
                                                </>
                                            } */}
                                        {/* </h2> */}
                                        <CardBody>
                                            {(docsInfo && docsInfo.length > 0) ?
                                                <div className="nexogic-custom-scroll">
                                                    <div className="nexogic-document-lists">
                                                        <ul>
                                                            {docsInfo.map((doc, index) => (
                                                                <li key={index} >
                                                                    <div className="nexogic-document-item" style={{ cursor: "pointer" }} onClick={() => getReferralDoc(doc?.id)}>
                                                                        <img width={28} alt="#" src={getFileIcon(doc?.type)} style={{ cursor: "pointer" }} />
                                                                        <div className="card-text">{doc?.name}</div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>

                                                    </div>
                                                </div>
                                                :
                                                <div className="nexogic-not-found-text">
                                                    <div className="icon">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M20 13V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18L4 13M16 8L12 4M12 4L8 8M12 4L12 16" stroke="#46ABC9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </div>
                                                    No Documents
                                                </div>
                                            }
                                            {isEditable && isResponseSent &&
                                                <>
                                                    <div className="action">
                                                        {isFileUploaing ?
                                                            <div className="text-center mb-5" style={{ height: "10px" }} >
                                                                <CommonLoader />
                                                            </div>
                                                            :
                                                            <button className="btn nexogic-download-btn nex-btn-primary" onClick={handleUploadClick}>
                                                                <span className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
                                                                        <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 10.333v3.111A1.556 1.556 0 0113.444 15H2.556A1.556 1.556 0 011 13.444v-3.11m10.889-5.445L8 1m0 0L4.111 4.889M8 1v9.333"></path>
                                                                    </svg>
                                                                </span> Uploads
                                                            </button>
                                                        }
                                                        <input
                                                            name="uploadDocument"
                                                            type="file"
                                                            id="uploadDocument"
                                                            accept="image/jpeg,image/jpg,image/png,application/pdf"
                                                            title="Upload Document"
                                                            className="nexogic-file-upload-input"
                                                            style={{ display: 'none' }}
                                                            ref={fileInputRef}
                                                            onChange={handleFileChange}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </CardBody>
                                    </Card>
                                }
                                <ReferWidget />

                                {referral && referral?.specialityRequested &&
                                    <PeopleKnowWidget speciality={referral?.specialityRequested} />
                                }
                            </Col>
                        </Row>


                        {/*
                    <h4 className="nexogic-form-title">Essenstial Information</h4>
                    <EssenstialInfo values={values} />
                    <h4 className="nexogic-form-title">Reffering Physician Information</h4>
                    <PhysicianInfo values={values} />
                    <h4 className="nexogic-form-title">Patient Information</h4>
                    <PatientInfo values={values} />
                    <h4 className="nexogic-form-title">Appointment Request</h4>
                    <AppointmentReq values={values} />
                    
                    {
                        (values?.status === "Rejected") &&
                        (
                            <>
                                <h4 className="nexogic-form-title">Reason for Rejection</h4>
                                <RejectionReason values={values} />
                            </>
                        )
                    }
                     */}


                    </CardBody>
                </Card>
            }
            {
                dataLoading ?
                    <div className="" style={{ zIndex: "111", position: "absolute", top: "0", left: "0", backgroundColor: "#edeff0b0", width: "100%", height: "100%" }}>
                        <div className="component-wrapper"><CommonLoader /></div>
                    </div>
                    :
                    <Modal isOpen={modal} toggle={toggleForm} backdrop="static" scrollable centered >
                        <ModalHeader toggle={toggleForm}><span style={{ color: "gray" }}>Document Viewer</span></ModalHeader>
                        <ModalBody>
                            <div className="modalbody">
                                <img src={documentData} alt="document preview" />
                            </div>
                            <div className="downloadbtn">
                                <FontAwesomeIcon color="#0087b9" size="xl" style={{ cursor: "pointer" }} icon={faDownload} onClick={handleDownload} />
                            </div>
                        </ModalBody>
                    </Modal>
            }

            {/* <ReferAssignModal open={isAssignModal} setToggle={setIsAssignModal} onAssign={haldleAssign} /> */}
        </div>
    )
}
export default withAlert()(ResponseDetails);