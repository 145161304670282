import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunkMiddleware from "redux-thunk";
import ConnectionsFilterReducer from "./ConnectionsFilterReducer";
import GlobalProfileReducer from "./GlobalProfileReducer";
import GlobalSearchTypeReducer from "./GlobalSearchTypeReducer";
import GlobalInstituteProfileReducer from "./GlobalInstituteProfileReducer";
import RecentExperienceReducer from "./RecentExperienceReducer";
import ConnectionsReducer from "./ConnectionsReducer";
import HomePageReducer from "./HomePageReducer";
import ProfileCompleteReducer from "./ProfileCompleteReducer";
import ProfileReducer from "./ProfileReducer";
import authReducer from "./reducer";
import ProfilePreferencesReducer from "./ProfilePreferencesReducer";
const persistConfig = {
  storage,
  blacklist: [
    "profileComplete",
    "feed",
    "connectionsFilter",
    "ProfileData",
    "GlobalProfileReducer",
    "GlobalSearchTypeReducer",
    "GlobalInstituteProfileReducer",
    "RecentExperienceReducer",
    "connectionsInfo",
    "PreferenceFlags"
  ],
  key: "root",
};
const rootReducer = combineReducers({
  auth: authReducer,
  feed: HomePageReducer,
  profileComplete: ProfileCompleteReducer,
  connectionsFilter: ConnectionsFilterReducer,
  ProfileData: ProfileReducer,
  GlobalProfileReducer: GlobalProfileReducer,
  GlobalInstituteProfileReducer:GlobalInstituteProfileReducer,
  GlobalSearchTypeReducer: GlobalSearchTypeReducer,
  RecentExperienceReducer: RecentExperienceReducer,
  connectionsInfo : ConnectionsReducer,
  PreferenceFlags : ProfilePreferencesReducer
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export let store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

export let persistor = persistStore(store);
export const Store = {
  store,
  persistor,
};
