import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import secondOpinionright from 'assets/images/second-opinion.png'
import { useEffect } from 'react';
import { useState } from 'react';
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import profile_sample from 'assets/images/user-profile-sample.png'
import ReactQuill from 'react-quill';
import DropAttachment from '../SubElements/Attachments/DropAttachment';
import AttachFileLists from '../SubElements/Attachments/AttachFileLists';
import { Link } from 'react-router-dom';

export default function ConsultlationAdd() {
    const [pannel_one_on, setPannel_one_on] = useState(true);
    const [consultationValue, setConsultationValue] = useState('');
    const [attachments, setAttachments] = useState([]);

    useEffect(() => {
        document.body.classList.add('nexogic-so-page');
        return () => document.body.classList.remove('nexogic-so-page');
    }, []);

    // useEffect(() => {
    //     console.log(attachments[0]);
    // }, [attachments])

    return (
        <div className='nexogic-second-view-details'>
            <Card className="nexogic-form-text-display-w border-0 bg-transparent">
                <CardBody className="">
                    <Row className="need-margin">
                        <Col xl={9}>
                            <div className='nexogic-breadcumbs mb-3'>
                                <Link to="/">Home</Link>
                                <Link to="/u/medicalcases/opinions">second opinion</Link>
                                <Link to="/u/medicalcases/my-opinions/abc">details</Link>
                                <span >consultation</span>
                            </div>
                            <Card className={`nexogic-card-color-header ${pannel_one_on ? 'expanded' : ''}`}>
                                <div className="card-header">
                                    <h2>Patient Information</h2>
                                    <button className="collapse-btn" type="button" onClick={() => setPannel_one_on(!pannel_one_on)}>
                                        <img src={pannel_arrow} alt="" />
                                    </button>
                                </div>
                                <div className="collapse-div nexogic-patient-info">
                                    <CardBody>
                                        <div className="mb-3">
                                            <h3 className="card-title">Add Your Consultation</h3>
                                            <div className="quill-editor-w">
                                                <ReactQuill id="consultation_fld" theme="snow" value={consultationValue} onChange={setConsultationValue} style={{ height: '300px' }} />
                                            </div>

                                        </div>
                                        <h3 className="card-title">{attachments.length === 0 ? 'Add Your Attachments' : 'Your Attachments'}</h3>
                                        <div className="row need-margin">
                                            {attachments.length === 0 &&
                                                <div className="col-lg-12">
                                                    <p className='card-text text-muted mb-2'>Please upload any medical notes, results from diagnostic studies etc</p>
                                                    <DropAttachment onFileDrop={(files) => setAttachments([...attachments, files])} />
                                                </div>
                                            }
                                            <div className="col-lg-12">
                                                {attachments.length > 0 ? <AttachFileLists attachData={attachments} /> : null}
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group nexogic-action-groups">
                                                    <input type="button" name='sa-draft' value="Save as draft" className='nexogic_primary_button_outline' />
                                                    <input type="button" name='st-patient' value="Send to Patient" className='nexogic_primary_button' />
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </div>
                            </Card>

                        </Col>
                        <Col xl={3}>
                            <div className="nexogic-widget-sidebar-card card border-0">
                                <div className="card-body">
                                    <Link
                                        to="/u/medicalcases/details/consultlation"
                                        className="btn d-block nexogic_primary_button"
                                    >
                                        View Details
                                    </Link>
                                </div>
                            </div>
                            <div className="nexogic-widget-sidebar-card card border-0">
                                <h3 className="card-title h2">Patient Info</h3>
                                <div className="card-body">
                                    <div className="nexogic-quick-patient-info">
                                        <div className="graphic">
                                            <img src={profile_sample} alt="" className='avatar' />
                                        </div>
                                        <div className="Info">
                                            <p className='card-text'><b>Name</b> <span>Virat Palla</span></p>
                                            <p className='card-text'><b>Blood group </b>   <span> AB+</span></p>
                                            <p className='card-text'><b>Specialty </b>   <span> Surgery</span></p>
                                            <p className='card-text'><b>Weight </b>   <span> 60kg</span></p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='nexogic-widget-sidebar-card card border-0 nexogic-sidebar-payment-card'>
                                <h3 className="card-title h2">Payment</h3>
                                <div className="card-body">
                                    <div className="info">
                                        <p>Second Opinion
                                            Allergy and Immunology Second Opinion
                                            - Full Chart Review</p>
                                        <div className="price-sec"><p>Total Payment : $100</p>
                                            <p>Paid</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="nexogic-widget-sidebar-card card border-0">
                                <h3 className="card-title h2">Second Opinion</h3>
                                <div className="card-body">
                                    <ul className="nexogic-lists nexogic-custom-scroll">
                                        <li>
                                            <img src={secondOpinionright} alt="" className="img-fluid" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    )
}
