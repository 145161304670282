import React, { PureComponent } from "react";
import Nexogic from "assets/images/logo-icon.svg";
import NexogicLogo from "assets/images/logo.svg";
class Logo extends PureComponent {
  render() {
    return (
      <img src={this.props.role ? NexogicLogo :Nexogic } alt="Nexogic" className="img-fluid" width={this.props.width} />
    );
  }
}

export default Logo;