import AlertDialog from "common/AlertDialog";
import moment from "moment";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import {
  profileAddEvent,
  updateProfileCompletness,
} from "redux-helpers/ActionCreator";
import { CommonServices } from "utils/CommonServices";
import { UserStatusConstants } from "utils/Constants";
import {
  convertToMMMYYYYFormat,
  dateDifferenceInYearsAndMonths,
  getEmptySectionShowHide,
  getFullAddress,
  makeFullNameWithAddressWhenAddressIsNested,
  renderDateRange,
} from "utils/Utils";
import { RenderCommitteModal } from "./components/RenderCommitteModal";
import committiees_icon from "assets/images/committees-icon.png";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import del_red_icon from "assets/images/del-red-icon.png";
import NoData from "../NoData";

const RenderCommittee = (props) => {
  const {
    isAdmin,
    committee,
    handleEditItem,
    handleRemoveItem,
    isLoggedinUser,
  } = props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  if (committee && committee.length > 0) {
    return committee.map((item, index) => {
      const { startedOn, endedOn, doesNotExpire } = item;
      const profileIdLink = item?.institutionDetail?.profileId
        ? item?.institutionDetail?.profileId
        : item?.institutionDetail?.guid;
      const committeeName = item.name ? item.name : "";
      const institutionName = getFullAddress({
        name: item?.institutionDetail?.name ?? "",
        abbreviation: item?.institutionDetail?.abbreviation ?? "",
        location: item?.institutionDetail?.address?.location ?? "",
        city: item?.institutionDetail?.address?.city ?? "",
        state: item?.institutionDetail?.address?.state ?? "",
      });
      item.startYear = item?.startedOn ?? "";
      item.endYear = item?.endedOn ?? "";
      item.committeeName = item?.name ?? "";
      item.currentMember = item?.doesNotExpire === true;
      if (item?.institutionDetail?.guid) {
        item.institutionDetail = {
          guid: item?.institutionDetail?.guid,
          name: item?.institutionDetail?.name,
          institutionName: item?.institutionDetail ? makeFullNameWithAddressWhenAddressIsNested(item?.institutionDetail) : "",
          ...(item?.institutionDetail?.abbreviation && {
            abbreviation: item?.institutionDetail?.abbreviation,
          }),
          ...(item?.institutionDetail?.address && {
            address: item?.institutionDetail?.address,
          }),
          onSelectNewInstitutionCollege: 1,
        };
      }
      return (
        <li
          className="nex-icon-list-item"
          key={`committee_${item.guid}`}
        >
          <div className="card-graphic">
            <img src={committiees_icon} alt="" className="contain-bg" />
          </div>
          <div className="nex-info">
          <h3 className="card-title">{committeeName} {" - "} {item.positionHeld ? item.positionHeld : null}</h3>
              <p className="card-text bold small mb-0"><Link
                to={`/institution/${profileIdLink}`}
                className=""
              >{institutionName}</Link></p>

              {item?.startedOn &&
                <p className="card-text small">
                  <span>{" Member Since: "}</span>
                    <span>
                      <span>
                        {convertToMMMYYYYFormat(item?.startedOn)}{" - "}
                      </span>
                      <span>{item?.doesNotExpire ? <span><span> {"Present | "}</span><span>{dateDifferenceInYearsAndMonths(item?.startedOn)} </span> </span> : convertToMMMYYYYFormat(item?.endedOn)}</span>
                    </span>
                  
                </p>}

                <div className="nex-action">
                {(isAdmin ||
                (isLoggedinUser &&
                  currentUser.status === UserStatusConstants.APPROVED)) && (
                  <div className="delete-action">
                    <Button
                      color="link"
                      className="visible-hover py-0 nex-cs-committees-edit-btn"
                      onClick={() => handleEditItem(item)}
                    >
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="15.9062" cy="16" r="15.375" fill="white" stroke="#DBF4FB" strokeWidth="0.75"/>
<path d="M19.3612 9.53125L17.8557 11.0368L20.8667 14.0478L22.3722 12.5423L19.3612 9.53125ZM16.3502 12.5423L10.3281 18.5643V21.5754H13.3392L19.3612 15.5533L16.3502 12.5423Z" fill="#46ABC9"/>
</svg>
                    </Button>
                    <Button
                      color="link"
                      className="visible-hover delete py-0 nex-cs-committees-del-btn"
                      onClick={() => handleRemoveItem(item)}
                    >
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="16.2266" cy="16" r="15.75" fill="#FFEAEF"/>
<path d="M11.3984 13.7734L12.2586 20.1323C12.426 21.3702 12.5097 21.9892 12.9337 22.3597C13.3577 22.7302 13.9823 22.7302 15.2315 22.7302H18.4692C19.7184 22.7302 20.343 22.7302 20.767 22.3597C21.191 21.9892 21.2747 21.3702 21.4421 20.1323L22.3023 13.7734" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" stroke-linejoin="round"/>
<path d="M14.5156 11.0475V11.0475C14.5156 10.2672 14.5156 9.87708 14.6572 9.57473C14.8061 9.25693 15.0616 9.00138 15.3794 8.85254C15.6818 8.71094 16.0719 8.71094 16.8522 8.71094V8.71094C17.6324 8.71094 18.0226 8.71094 18.3249 8.85254C18.6427 9.00138 18.8983 9.25693 19.0471 9.57473C19.1887 9.87708 19.1887 10.2672 19.1887 11.0475V11.0475" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" stroke-linejoin="round"/>
<path d="M22.3023 11.0547L11.3984 11.0547" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" stroke-linejoin="round"/>
<path d="M14.8906 14.1719L15.28 20.0132" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" stroke-linejoin="round"/>
<path d="M18.7957 14.1719L18.4062 20.0132" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" stroke-linejoin="round"/>
</svg>
                    </Button>
                  </div>
                )}
                </div>
          </div>
          {/** old code */}
          <div className="row g-0 d-none">
            <div className="col-auto">
              <img src={committiees_icon} alt="" />
            </div>

            <div className="col">
              <h3 className="card-title">{committeeName} {" - "} {item.positionHeld ? item.positionHeld : null}</h3>
              <p className="card-title mb-0"><small className="text-muted fw-semibold"><Link
                to={`/institution/${profileIdLink}`}
                className=""
              >{institutionName}</Link></small></p>

              {item?.startedOn &&
                <p className="card-text">
                  <small className="text-muted"><span>{" Member Since: "}</span>
                    <span>
                      <span>
                        {convertToMMMYYYYFormat(item?.startedOn)}{" - "}
                      </span>
                      <span>{item?.doesNotExpire ? <span><span> {"Present | "}</span><span>{dateDifferenceInYearsAndMonths(item?.startedOn)} </span> </span> : convertToMMMYYYYFormat(item?.endedOn)}</span>
                    </span>
                  </small>
                </p>}
            </div>



            {/** 
            <div className="flex-grow-1">
              <div className="d-flex">
                <div className="prize-icon badgeshape">
                  <img src={blackGroupIcon} width="16" alt="" />
                </div>
                <div className="ml-2">
                  <div className="section-item-shortdes  mb-2">
                    {committeeName}
                    {" - "}
                    {item.positionHeld ? item.positionHeld : null}
                  </div>
                  <Link
                    to={`/institution/${profileIdLink}`}
                    className="mb-2 d-block color-accent"
                  >
                    <h6 className="section-item-title item-blue-title f-12 m-0">
                      {institutionName}
                    </h6>
                  </Link>
                  <p className="text-gray f-14 font-weight-500 m-0">
                    {timeDuration}
                  </p>
                </div>
              </div>
            </div>
            */}



            <div className="col-auto d-flex align-items-start">
              {(isAdmin ||
                (isLoggedinUser &&
                  currentUser.status === UserStatusConstants.APPROVED)) && (
                  <div className="delete-action">
                    <Button
                      color="link"
                      className="visible-hover py-0 nex-cs-committees-edit-btn"
                      onClick={() => handleEditItem(item)}
                    >
                      <img src={edit_blue_icon} alt="" />
                    </Button>
                    <Button
                      color="link"
                      className="visible-hover delete py-0 nex-cs-committees-del-btn"
                      onClick={() => handleRemoveItem(item)}
                    >
                      <img src={del_red_icon} alt="" />
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </li>
      );
    });
  } else {
    return null;
  }
};

const initialValue = {
  committeeName: "",
  institutionName: "",
  positionHeld: "",
  startYear: "",
  endYear: "",
  description: "",
  expires: false,
  currentMember: false,
  addressLine1: "",
  addressLine2: "",
  city: [],
  country: [],
  location: [],
  postalCode: "",
  state: [],
  category: "",
};

const Committees = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const eventName = useSelector((state) => state.profileComplete.eventName);
  const dispatch = useDispatch();
  const userGUID = props.practitioner.guid;
  const { alert, isLoggedinUser, isAdmin, setShowCommitteesOnTabScroll } = props;
  const [committee, setCommittee] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);
  const [isOpenAlert, setAlertModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(updateProfileCompletness(11, !!committee.length));

    if (committee.length > 0 || isLoggedinUser || isAdmin) {
      setShowCommitteesOnTabScroll(true);
    }else{
      setShowCommitteesOnTabScroll(false);
    }
  }, [committee]);

  useEffect(() => {
    if (eventName === "ADD_COMMITTEES") {
      handleEditItem(initialValue);
      dispatch(profileAddEvent(null));
    }
  }, [eventName]);

  const toggleAlert = () => {
    setLoading(false);
    setAlertModal(!isOpenAlert);
  };

  useEffect(() => {
    const getCommittees = async () => {
      getData();
    };
    getCommittees();
  }, [userGUID]);

  const handleEditItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    toggle();
  };

  const handleRemoveItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    toggleAlert();
  };

  const handleClickOnYes = async () => {
    setLoading(true);
    try {
      const response = CommonServices.deleteCommittee(
        selectedItem.guid,
        userGUID
      );
      response.then(
        (response) => {
          const { status } = response;
          if (status === 204) {
            setTimeout(() => {
              showSuccessMessage("Committee deleted successfully.");
              getData();
              setLoading(false);
              toggleAlert();
            }, 1000);
          } else {
            showErrorMessage("Cannot delete committee.");
            setLoading(false);
            toggleAlert();
          }
        },
        (e) => {
          setLoading(false);
          console.log("error", e.message);
          let errmsg = e.message;
          if (e.response) {
            errmsg =
              e.response.data && !!e.response.data.message
                ? e.response.data.message
                : errmsg;
          }
          showErrorMessage(errmsg);
        }
      );
    } catch (e) {
      showErrorMessage(e.message);
      setLoading(false);
      toggleAlert();
    }
  };

  const getData = () => {
    const response = CommonServices.getCommittees(userGUID);
    response.then(
      (response) => {
        let { data } = response;
        if (data && data.length > 0) {
          setCommittee(
            data.map((node) => {
              const [fromMonth, fromYear] = node?.startedOn
                ? node?.startedOn.split("-")
                : "";
              const [toMonth, toYear] = node?.endedOn
                ? node?.endedOn.split("-")
                : "";
              return {
                ...node,
                fromMonth:
                  fromMonth && fromYear
                    ? moment(fromMonth + "/01/" + fromYear).format("MMMM")
                    : "",
                fromYear:
                  fromMonth && fromYear
                    ? moment(fromMonth + "/01/" + fromYear).format("YYYY")
                    : "",
                toMonth:
                  toMonth && toYear
                    ? moment(toMonth + "/01/" + toYear).format("MMMM")
                    : "",
                toYear:
                  toMonth && toYear
                    ? moment(toMonth + "/01/" + toYear).format("YYYY")
                    : "",
                toDate: node?.endYear ?? "",
              };
            })
          );
        } else {
          setCommittee([]);
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  if (
    getEmptySectionShowHide(
      committee ?? [],
      isAdmin,
      currentUser,
      globalProfileInfo,
      userGUID
    )
  )
    return null;

  return (
    <>
      <Card className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="committeesSection">
        <div className="card-header"><h2>Committees</h2>


          {(isAdmin ||
            (isLoggedinUser &&
              currentUser.status === UserStatusConstants.APPROVED)) && (
              <Button
                id="nex-cs-committees-add-btn"
                color="link"
                className="add-button ml-auto"
                onClick={() => handleEditItem(initialValue)}
              >
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="9.75" cy="9.5" r="8.4375" fill="white" stroke="#46ABC9" strokeWidth="1.125"/>
<path d="M9.625 5.75L9.625 13.0039" stroke="#46ABC9" strokeWidth="1.125" strokeLinecap="round"/>
<path d="M13.2656 9.38281L6.01172 9.38281" stroke="#46ABC9" strokeWidth="1.125" strokeLinecap="round"/>
</svg> Add More
              </Button>
            )}
          <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
          <svg width={17}  height={10} viewBox="0 0 17 10" fill="none"  xmlns="http://www.w3.org/2000/svg" >
                        <path
                          d="M2.25 1.625L8.625 8L15 1.625"
                          stroke="black"
                          strokeWidth={3}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
            </svg>
          </button>
        </div>
        <div className={`collapse-div`}>
          <CardBody className={
            committee && committee.length > 0
              ? ""
              : "align-items-center"
          }>
            {committee && committee.length
              ? ""
              : (isLoggedinUser || isAdmin) &&
              currentUser.status === UserStatusConstants.APPROVED && (
                <Button
                id="nex-cs-committees-add-btn2"
                  className="readmore"
                  color="link"
                  onClick={() => handleEditItem(initialValue)}
                >
                  {"Add committees"}
                </Button>
              )}
            {/** 
          <Row
            
          >
            <Col>
              <h3 className="section-title m-0 mb-3">Committees</h3>
              {committee && committee.length
                ? ""
                : (isLoggedinUser || isAdmin) &&
                  currentUser.status === UserStatusConstants.APPROVED && (
                    <div className="mt-3">
                      <Row className="align-items-center">
                        <Col className="add-infomation-part">
                          <Button
                            className="add-infomation p-0"
                            color="link"
                            onClick={() => handleEditItem(initialValue)}
                          >
                            {"Add committees"}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  )}
            </Col>
            
          </Row>
          */}
            <ul className="nex-icon-list-within-card" id="nex-cs-committees-lists">

              <RenderCommittee
                committee={committee}
                handleEditItem={handleEditItem}
                handleRemoveItem={handleRemoveItem}
                isLoggedinUser={isLoggedinUser}
                isAdmin={isAdmin}
              />
            </ul>
            {committee.length < 1 && 
            <NoData>No Data</NoData>
            }
            <RenderCommitteModal
              selectedItem={selectedItem}
              toggle={toggle}
              isOpen={isOpen}
              userGUID={userGUID}
              getData={getData}
              isAdmin={isAdmin}
            />
            <AlertDialog
              toggle={toggleAlert}
              isOpen={isOpenAlert}
              isLoading={isLoading}
              handleClickOnYes={handleClickOnYes}
              yesLabel={"Yes"}
              noLabel={"No"}
            />
          </CardBody>
        </div>
      </Card>
    </>
  );
};

export default withAlert()(Committees);
