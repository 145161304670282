import { Field, useFormikContext } from 'formik';
import FormikInputField from 'components/forms/formikFields/FormikInputField';
import job_description from 'assets/images/job-icon-9.png';
import { useEffect } from 'react';

function JobCreateFormSix() {
    const { values, setFieldValue, validateForm } = useFormikContext();

    const handleExperienceLevelChange = (event) => {
        const selectedLevel = event.target.value;
        setFieldValue('experienceLevel', selectedLevel);

        // Set default values based on selectedLevel
        if (selectedLevel === 'beginner') {
            setFieldValue('minExperience', '0');
            setFieldValue('maxExperience', '5');
        } else if (selectedLevel === 'mid') {
            setFieldValue('minExperience', '6');
            setFieldValue('maxExperience', '10');
        } else if (selectedLevel === 'expert') {
            setFieldValue('minExperience', '11');
            setFieldValue('maxExperience', '30');
        } else {
            // Clear values if "Add your own" is selected
            setFieldValue('minExperience', '0');
            setFieldValue('maxExperience', '0');
        }
    };

    const handleSalaryLevelChange = (event) => {
        const selectedLevel = event.target.value;
        setFieldValue('salaryLevel', selectedLevel);

        // Set default values based on selectedLevel
        if (selectedLevel === 'negotiable') {
            setFieldValue('minSalary', '0');
            setFieldValue('maxSalary', '0');
        } else {
            setFieldValue('minSalary', '0');
            setFieldValue('maxSalary', '0');
        }
    };

    useEffect(() => {
        // Manually trigger form validation after state updates
        validateForm().then();
    }, [values.experienceLevel, values.salaryLevel]); // Ensure useEffect runs whenever experienceLevel changes

    return (
        <>
            <div className="nex-job-form-left-sec">
                <div className="nex-job-info">
                    <img src={job_description} alt="" className="graphic" />
                    <div className="conts">
                        <p>
                            <span className="light">5/7</span> Job Post
                        </p>
                        <h2 className="title">You are almost there...</h2>
                        <p className="lead">Go on, few more steps....</p>
                    </div>
                </div>
            </div>
            <div className="nex-job-form-right-sec">
                <h2 className="nex-text-xl-small mb-4">How many years of experience are you looking for?</h2>
                <div className="form-group nex-radio-content-group">
                    <div className="cus-radio-w">
                        <input
                            type="radio"
                            name="experienceLevel"
                            value="beginner"
                            onChange={handleExperienceLevelChange}
                            checked={values.experienceLevel === 'beginner'}
                        />
                    </div>
                    <div className="info">
                        <h3 className="card-title">Beginner</h3>
                        <p>Someone who has an experience of 0 to 5 years</p>
                    </div>
                </div>
                <div className="form-group nex-radio-content-group">
                    <div className="cus-radio-w">
                        <input
                            type="radio"
                            name="experienceLevel"
                            value="mid"
                            onChange={handleExperienceLevelChange}
                            checked={values.experienceLevel === 'mid'}
                        />
                    </div>
                    <div className="info">
                        <h3 className="card-title">Mid-Level</h3>
                        <p>Someone who has an experience of 6 to 10 years</p>
                    </div>
                </div>
                <div className="form-group nex-radio-content-group">
                    <div className="cus-radio-w">
                        <input
                            type="radio"
                            name="experienceLevel"
                            value="expert"
                            onChange={handleExperienceLevelChange}
                            checked={values.experienceLevel === 'expert'}
                        />
                    </div>
                    <div className="info">
                        <h3 className="card-title">Expert</h3>
                        <p>Someone who has an experience of more than 10 years</p>
                    </div>
                </div>
                <div className="form-group nex-radio-content-group">
                    <div className="cus-radio-w">
                        <input
                            type="radio"
                            name="experienceLevel"
                            value=""
                            onChange={handleExperienceLevelChange}
                            checked={!values.experienceLevel}
                        />
                    </div>
                    <div className="info">
                        <h3 className="card-title">Add your own</h3>
                        <p>I would like to set the experience</p>
                    </div>
                </div>
                {values.experienceLevel === '' && (
                    <div className="form-group gap-md nex-flex nex-gap-30 nex-job-experience">
                        <div className="form-group nex-flex nex-gap-16">
                            <div className="label">Form</div>
                            <Field
                                className="nex-max-w-100 nex-gap-16"
                                id="minExperience"
                                name="minExperience"
                                autoComplete="minExperience"
                                // type="number"
                                min="0"
                                //label={"Min Experience"}
                                component={FormikInputField}
                                placeholder="Yrs"
                            />
                            
                        </div>
                        <div className="form-group nex-flex nex-gap-16">
                            <div className="label">To</div>
                            <Field
                                className="nex-max-w-100"
                                id="maxExperience"
                                name="maxExperience"
                                autoComplete="maxExperience"
                                // type="number"
                                //label={"Max Experience"}
                                min="0"
                                component={FormikInputField}
                                placeholder="Yrs"
                            />
                            
                        </div>
                    </div>
                )}

                {/* ==============================Salary Fields============================================= */}

                <div>
                    <h2 className="nex-text-xl-small mb-4">How much salary you are offering?</h2>
                    <div className="form-group nex-radio-content-group">
                        <div className="cus-radio-w">
                            <input
                                type="radio"
                                name="salaryLevel"
                                value="negotiable"
                                onChange={handleSalaryLevelChange}
                                checked={values.salaryLevel === 'negotiable'}
                            />
                        </div>
                        <div className="info">
                            <h3 className="card-title">Negotiable</h3>
                            <p>Salary can be negotiate at the time of hiring</p>
                        </div>
                    </div>
                    <div className="form-group nex-radio-content-group">
                        <div className="cus-radio-w">
                            <input
                                type="radio"
                                name="salaryLevel"
                                value=""
                                onChange={handleSalaryLevelChange}
                                checked={!values.salaryLevel}
                            />
                        </div>
                        <div className="info">
                            <h3 className="card-title">Add your own</h3>
                            <p>I would like to set the salary</p>
                        </div>
                    </div>
                    {values.salaryLevel === '' && (
                        <div className="nex-flex nex-gap-30 nex-job-experience">
                            <div className="form-group nex-flex nex-gap-16">
                                <div className="label">From</div>
                                <Field
                                    className="nex-max-w-100 nex-gap-16"
                                    id="minSalary"
                                    name="minSalary"
                                    autoComplete="minSalary"
                                    // type="number"
                                    min="0"
                                    //label={"Min Experience"}
                                    component={FormikInputField}
                                    placeholder="INR"
                                />
                                
                            </div>
                            <div className="form-group nex-flex nex-gap-16">
                                <div className="label">To</div>
                                <Field
                                    className="nex-max-w-100"
                                    id="maxSalary"
                                    name="maxSalary"
                                    autoComplete="maxSalary"
                                    // type="number"
                                    //label={"Max Experience"}
                                    min="0"
                                    component={FormikInputField}
                                    placeholder="INR"
                                />
                                
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default JobCreateFormSix;
