import { emailNonNumericRegEx, onlyAlphabets } from "utils/Constants";
import * as Yup from "yup";

Yup.addMethod(Yup.array, "unique", function (field, message) {
  return this.test("unique", message, function (array) {
    const uniqueData = Array.from(
      new Set(array.map((row) => row[field]?.toLowerCase()))
    );
    const isUnique = array.length === uniqueData.length;
    if (isUnique) {
      return true;
    }
    const index = array.findIndex(
      (row, i) => row[field]?.toLowerCase() !== uniqueData[i]
    );
    if (array[index][field] === "") {
      return true;
    }
    return this.createError({
      path: `${this.path}.${index}.${field}`,
      message,
    });
  });
});

export const validationSchema = Yup.object().shape({
  title: Yup.string().required("This field is required"),
  firstName: Yup.string()
    .min(1)
    .max(30)
    .matches(onlyAlphabets, "Only Alphabets")
    .required("This field is required"),
  lastName: Yup.string()
    .min(2)
    .max(30)
    .matches(onlyAlphabets, "Only Alphabets")
    .required("This field is required"),
  headline: Yup.string()
    .min(2)
    .max(150)
    .required("This field is required")
    .nullable(),
  dateOfBirth: Yup.string().required("This field is required"),
  gender: Yup.string()
    .oneOf(["M", "F", "U"], "You must select your respective gender.")
    .required("This field is required")
    .nullable(),
  primarySpeciality: Yup.array().min(1, "This field is required"),
  city: Yup.array()
    .min(1, "This field is required")
    .required("This field is required")
    .nullable(),
  state: Yup.array()
    .min(1, "This field is required")
    .required("This field is required")
    .nullable(),
  country: Yup.array()
    .min(1, "This field is required")
    .required("This field is required")
    .nullable(),
  location: Yup.array()
    .min(1, "This field is required")
    .required("This field is required")
    .nullable(),
  zipCode: Yup.string().max(10).required("This field is required").nullable(),
  practicingSince: Yup.string().required("This field is required").nullable(),
  phones: Yup.array()
    .of(
      Yup.object().shape({
        primary: Yup.bool().default(false),
        type: Yup.string().required("This field is required"),
        value: Yup.string().required("This field is required"),
      })
    )
    .min(1, "This field is required")
    .required("This field is required")
    .unique("value", "Please provide a unique Phones."),
  primaryItemPhone: Yup.string().required("This Primary field is required"),
  emails: Yup.array()
    .of(
      Yup.object().shape({
        primary: Yup.bool().default(false),
        type: Yup.string().required("This field is required"),
        value: Yup.string()
          .matches(emailNonNumericRegEx, "Please Enter an valid Email")
          .required("This field is required"),
      })
    )
    .min(1, "This field is required")
    .required("This field is required")
    .unique("value", "Please provide a unique Email."),
  primaryItemEmails: Yup.string().required("This Primary field is required"),
});

export const validationSchemaAdmin = Yup.object().shape({
  title: Yup.string().required("This field is required"),
  firstName: Yup.string()
    .min(1)
    .max(30)
    .matches(onlyAlphabets, "Only Alphabets")
    .required("This field is required"),
  lastName: Yup.string()
    .min(2)
    .max(30)
    .matches(onlyAlphabets, "Only Alphabets")
    .required("This field is required"),
  headline: Yup.string()
    .min(2)
    .max(150),
  dateOfBirth: Yup.string(),
  gender: Yup.string()
    .oneOf(["M", "F", "U"], "You must select your respective gender."),
  primarySpeciality: Yup.array(),
  city: Yup.array(),
  state: Yup.array(),
  country: Yup.array(),
  location: Yup.array(),
  zipCode: Yup.string(),
  practicingSince: Yup.string(),
  phones: Yup.array()
    .of(
      Yup.object().shape({
        primary: Yup.bool().default(false),
        type: Yup.string().required("This field is required"),
        value: Yup.string().required("This field is required"),
      })
    )
    .unique("value", "Please provide a unique Phones."),
  primaryItemPhone: Yup.string(),
  emails: Yup.array()
    .of(
      Yup.object().shape({
        primary: Yup.bool().default(false),
        type: Yup.string().required("This field is required"),
        value: Yup.string()
          .matches(emailNonNumericRegEx, "Please Enter an valid Email")
          .required("This field is required"),
      })
    )
    .unique("value", "Please provide a unique Email."),
  primaryItemEmails: Yup.string(),
});
