import API_New from "../utils/API_New";
import axios from "axios";

export const get_contact_list = (id) => {
  return API_New.get(`practitioner-service/practitioner/${id}/colleagues`);
};

let cancelToken_meta;
export const get_meta_data = (id, pageNum, pageSize, querry) => {
  if (querry !== undefined || !id ) {
    if (typeof cancelToken_meta != typeof undefined) {
      cancelToken_meta.cancel("cancelling the prev request");
    }
    cancelToken_meta = axios.CancelToken.source();
    // debugger;
    return API_New.get(
      `practitioner-service/chat/metadata/get/${id}/INDIVIDUAL/${pageNum}/${pageSize}?q=${querry}`,
      { cancelToken: cancelToken_meta.token }
    );
  } else if (querry === "") {
    return API_New.get(
      `practitioner-service/chat/metadata/get/${id}/INDIVIDUAL/${pageNum}/${pageSize}`
    );
  } else {
    // debugger;
    return API_New.get(
      `practitioner-service/chat/metadata/get/${id}/INDIVIDUAL/${pageNum}/${pageSize}`
    );
  }
};

export const store_metaData = (channel_data) => {
  return API_New.post(`practitioner-service/chat/metadata/store`, channel_data);
};

let cancelToken;

export const search_contact = (id, name) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("cancelling the prev request");
  }
  cancelToken = axios.CancelToken.source();
  return API_New.get(
    `practitioner-service/practitioner/${id}/connections/search?query=${name}`,
    { cancelToken: cancelToken.token }
  );
};

export const storeChat = (data) => {
  return API_New.post(`practitioner-service/chats`, data);
};

export const getMessage = (channel, pageNum, pageSize) => {
  return API_New.get(
    `practitioner-service/chats?channel=${channel}&pageNum=${pageNum}&pageSize=${pageSize}`
  );
};
