import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import InstitutesTypeaheadFieldNew from "components/forms/formikFields/InstitutesTypeaheadFieldNew";
import { Field, Formik } from "formik";
import { useState } from "react";
import { withAlert } from "react-alert";
import { Button, Col, Label, Row, Spinner } from "reactstrap";
import { CommonServices } from "utils/CommonServices";
import { getAddressName, getLocationName } from "utils/Utils";
import * as Yup from "yup";
import FormikCheckboxField from "../../../../../../../components/forms/formikFields/FormikCheckboxField";
import NewInstituitionSection from "../../Experience/components/NewInstituitionSection";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{5})(( x| ext)\d{1,5}){0,1}$/;

export const yearmonth = /^(0[1-9]|10|11|12)-\d{4}$/;

const validationSchema = Yup.object().shape({
  committeeName: Yup.string()
    .min(3)
    .max(100)
    .required("This field is required"),
  institutionDetail: Yup.lazy((value) => {
    if (value && value.onSelectNewInstitutionCollege === 0) {
      return Yup.object().test(
        "",
        "This field is required. You must select from options or add new",
        () => false
      );
    } else if (value && value.onSelectNewInstitutionCollege === 1) {
      return Yup.object();
    } else if (value && value.onSelectNewInstitutionCollege === 2) {
      return Yup.object();
    } else {
      return Yup.mixed().test(
        "",
        "This field is required. You must select from options or add new",
        (value) => value !== {}
      );
    }
  }),
  positionHeld: Yup.string().min(3).max(100).required("This field is required"),
  description: Yup.string().min(3).max(100).required("This field is required"),
  startYear: Yup.string()
    .matches(yearmonth, "Please enter valid format (MM-YYYY)")
    .required("This field is required"),
  endYear: Yup.string().when("currentMember", {
    is: false,
    then: Yup.string()
      .matches(yearmonth, "Please enter valid format (MM-YYYY)")
      .required("This field is required")
      .test({
        name: "startYear",
        message: "End Year should be greater than Start year",
        test: function (value) {
          if (this.parent.startYear && value) {
            const fullStart = this.parent.startYear.split("-");
            const fullEnd = value.split("-");
            if (fullStart.length > 0 && fullEnd.length > 0) {
              const [startYearM, startYearY] = fullStart;
              const [endYearM, endYearY] = fullEnd;
              return startYearY + startYearM < endYearY + endYearM;
            }
            return false;
          }
          return false;
        },
      }),
    otherwise: Yup.string(),
  }),
  currentMember: Yup.boolean(),
  city: Yup.array().when("institutionDetail.onSelectNewInstitutionCollege", {
    is: 2,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required"),
  }),
  state: Yup.array().when("institutionDetail.onSelectNewInstitutionCollege", {
    is: 2,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required"),
  }),
  country: Yup.array().when("institutionDetail.onSelectNewInstitutionCollege", {
    is: 2,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required"),
  }),
  location: Yup.array().when(
    "institutionDetail.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.array()
        .min(1, "This field is required")
        .required("This field is required"),
    }
  ),
  mainItemName: Yup.string().when(
    "institutionDetail.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().required("This field is required"),
    }
  ),
  phone: Yup.string().when("institutionDetail.onSelectNewInstitutionCollege", {
    is: 2,
    then: Yup.string().when("phone", {
      is: (val) => val !== "",
      then: Yup.string().matches(
        phoneRegExp,
        "Please enter valid Phone Number"
      ),
      otherwise: Yup.string(),
    }),
  }),
  postalCode: Yup.string().when(
    "institutionDetail.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().required("This field is required"),
    }
  ),
  addressLine1: Yup.string().when(
    "institutionDetail.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().required("This field is required"),
    }
  ),
  websiteAddress: Yup.string().when(
    "institutionDetail.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().when("websiteAddress", {
        is: (val) => val !== "",
        then: Yup.string().url("Please enter a valid URL"),
        otherwise: Yup.string(),
      }),
    }
  ),
  category: Yup.string().when(
    "institutionDetail.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().required("This field is required"),
    }
  ),
});

const RenderForm = (props) => {
  const { handleSubmit, isLoading, toggle } = props;

  const onNewInstitute = () => {
    toggleNested();
  };

  const toggleNested = () => {
    props.setFieldValue(`institutionDetail.onSelectNewInstitutionCollege`, 2);
    props.setFieldValue(`institutionDetail.institutionName`, "Other");
    props.setFieldValue(`institutionDetail.guid`, "");
    props.setFieldValue(`city`, []);
    props.setFieldValue(`state`, []);
    props.setFieldValue(`country`, []);
    props.setFieldValue(`location`, []);
    props.setFieldValue(`postalCode`, "");
    props.setFieldValue(`addressLine1`, "");
    props.setFieldValue(`category`, "");
  };

  return (
    <form className="my-form" onSubmit={handleSubmit} id="nex-commitee-modal-form">
      <div>
        <Row form>
          <Col sm={12} xs={12} id="nex-commitee-modal-institution-detail">
            <Field
              name="institutionDetail"
              id="institutionDetail"
              autocomplete="institutionDetail"
              type="text"
              label="Institution Name"
              component={InstitutesTypeaheadFieldNew}
              onNewInstituteReq={onNewInstitute}
              placeholder="Select"
            />
          </Col>
          <Col sm={6} xs={12}>
            <Field
              name="committeeName"
              id="committeeName"
              autocomplete="committeeName"
              type="text"
              label="Committee Name"
              component={FormikInputField}
              placeholder="Committee Name"
            />
          </Col>

          <Col sm={6} xs={12}>
            <Field
              name="positionHeld"
              id="positionHeld"
              autocomplete="positionHeld"
              type="text"
              label="Position Held"
              component={FormikInputField}
              placeholder="Your position name"
            />
          </Col>
        </Row>
        {props.values?.institutionDetail?.onSelectNewInstitutionCollege ===
          2 && <NewInstituitionSection {...props} />}

        <Row form>
          <Col sm={4} xs={12}>
            <Label>Start Year</Label>
            <Field
              name={`startYear`}
              id={`startYear`}
              autocomplete={`startYear`}
              type="text"
              component={FormikInputField}
              placeholder="MM-YYYY"
            />
          </Col>
          <Col sm={4} xs={12}>
            <Label>End Year</Label>
            {props.values?.currentMember ? (
              <Field
                name={`Presenttime`}
                id={`Presenttime`}
                autocomplete={`Presenttime`}
                type="text"
                placeholder="Present time"
                value="Present time"
                disabled
                className="form-control"
              />
            ) : (
              <Field
                name={`endYear`}
                autocomplete={`endYear`}
                id={`endYear`}
                type="text"
                component={FormikInputField}
                placeholder="MM-YYYY"
              />
            )}
          </Col>
          <Col sm={4} xs={12}>
            <label className="d-xs-none">&nbsp;</label>
            <div className="nexogix-form-check-committees">
              <Field
                name="currentMember"
                autocomplete="currentMember"
                type="checkbox"
                id="currentMember"
                label="Current Member"
                component={FormikCheckboxField}
                placeholder="Current Member"
              />
            </div>
          </Col>
          <Col xs={12}>
            <Field
              name="description"
              autocomplete="description"
              type="textarea"
              label="Description"
              component={FormikInputField}
              rows={4}
              cols={5}
              placeholder="Description"
            />
          </Col>
        </Row>
      </div>
      {/** <hr className="profile-info-modal-hr mt-0" />*/}
      <Row form className="justify-content-end  modal-footer mx-0">
        <Col xs="auto action-group">
          {isLoading ? (
            <div className="loading-spiner">
              <Spinner />
            </div>
          ) : (
            <>
              
              <Button
                id="cf-btn-submit"
                className="nexogic_primary_button"
                //   disabled={!isValid || !dirty}
                color="primary"
                type="submit"
              >
                Save
              </Button>
              <Button
                id="cf-btn-cancel"
                className="nexogic_primary_button_outline"
                color="primary"
                type="button"
                onClick={toggle}
              >
                Cancel
              </Button>
            </>
          )}
        </Col>
      </Row>
    </form>
  );
};

const CommitteesForm = (props) => {
  const { selectedItem, userGUID, getData, toggle, alert } = props;
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");

  const addItem = async (value) => {
    setLoading(true);
    try {
      const generateFormData = createPayload(value);
      const response = CommonServices.addCommittee(generateFormData, userGUID);
      response.then(
        (response) => {
          const { data, validationErrors } = response;
          if (data && data.guid) {
            setTimeout(() => {
              showSuccessMessage("Committee added successfully");
              getData();
              setLoading(false);
              toggle();
            }, 1000);
          } else {
            setLoading(false);
            if (
              validationErrors &&
              validationErrors &&
              validationErrors.length > 0
            ) {
              const error = [];
              validationErrors.forEach((errors) => {
                error.push(errors.message);
              });
              showErrorMessage(error.join(","));
            } else {
              showErrorMessage("Error while adding committee");
            }
          }
        },
        (e) => {
          console.log(e.message);
        }
      );
    } catch (e) {
      setErrorMessage(e.message);
      setLoading(false);
    }
  };

  const updateItem = async (value) => {
    setLoading(true);
    try {
      const { guid } = value;

      const generateFormData = createPayload(value);
      const response = CommonServices.updateCommittee(
        generateFormData,
        userGUID,
        guid
      );
      response.then(
        (response) => {
          const { data, validationErrors } = response;
          if (data && data.guid) {
            setTimeout(() => {
              showSuccessMessage("Committee updated successfully");
              getData();
              setLoading(false);
              toggle();
            }, 1000);
          } else {
            setLoading(false);
            if (
              validationErrors &&
              validationErrors &&
              validationErrors.length > 0
            ) {
              const error = [];
              validationErrors.forEach((errors) => {
                error.push(errors.message);
              });
              showErrorMessage(error.join(","));
            } else {
              showErrorMessage("Error while updating committee");
            }
          }
        },
        (e) => {
          setLoading(false);
          console.log("error", e.message);
          let errmsg = e.message;
          if (e.response) {
            errmsg =
              e.response.data && !!e.response.data.message
                ? e.response.data.message
                : errmsg;
          }
          showErrorMessage(errmsg);
        }
      );
    } catch (e) {
      setErrorMessage(e.message);
      setLoading(false);
    }
  };

  const createPayload = (value) => {
    const { positionHeld, description, startYear, endYear, currentMember } =
      value;
    let payload = value;
    const formData = {
      description: description,
      doesNotExpire:
        typeof currentMember !== "undefined" ? currentMember : false,
      ...(payload?.institutionDetail?.institutionName && {
        institutionDetail: {
          ...(!payload.institutionDetail.guid && {
            address: {
              addressLine1: payload?.addressLine1 ?? "",
              addressLine2: payload?.addressLine2 ?? "",
              addressType: payload?.addressType ?? "BILLING_ADDRESS",
              city: getAddressName(payload?.city),
              state: getAddressName(payload?.state),
              country: getAddressName(payload?.country),
              location: getLocationName(payload?.location),
              postalCode: payload?.postalCode ?? "",
            },
          }),
          ...(payload.institutionDetail.guid && {
            guid: payload.institutionDetail.guid,
          }),
          name: payload.mainItemName ? payload.mainItemName : payload.name,
          phone: payload?.phone ?? "",
          websiteUrl: payload?.websiteAddress ?? "",
          ...(payload.category && {
            type: payload.category,
          }),
        },
      }),
      name: payload.committeeName,
      positionHeld: positionHeld,
      startedOn: startYear,
      ...((typeof currentMember !== "undefined" ? currentMember : false) ===
        false && {
        endedOn: endYear,
      }),
    };
    return formData;
  };

  return (
    <>
      {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={selectedItem}
        onSubmit={(values) => {
          if (values.guid) {
            updateItem(values);
          } else {
            addItem(values);
          }
        }}
        children={(props) => {
          return (
            <>
              <RenderForm toggle={toggle} {...props} isLoading={isLoading} />
            </>
          );
        }}
      ></Formik>
    </>
  );
};
export default withAlert()(CommitteesForm);
