import { faMedrt } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertDialog from "common/AlertDialog";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import {
  profileAddEvent,
  updateProfileCompletness
} from "redux-helpers/ActionCreator";
import { UserStatusConstants } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import {
  convertToMMMYYYYFormat,
  dateDifferenceInYearsAndMonths,
  getEmptySectionShowHide,
  getFullAddress,
  makeFullNameWithAddressWhenAddressIsNested,
  renderDateRange
} from "utils/Utils";
import { RenderProMembershipModal } from "./component/RenderProMembershipModal";
import certifications_icon from "assets/images/license-certificate.png";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import del_red_icon from "assets/images/del-red-icon.png";
import NoData from "../NoData";

const RenderProMember = (props) => {
  const {
    isAdmin,
    proMembers,
    handleEditItem,
    handleRemoveItem,
    isLoggedinUser,
  } = props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  if (proMembers && proMembers.length > 0) {
    return proMembers.map((item) => {
      const { doesNotExpire, memberSince, expiredOn } = item;
      const profileIdLink = item?.institutionDetail?.profileId
        ? item?.institutionDetail?.profileId
        : item?.institutionDetail?.guid;
      const institutionName = getFullAddress({
        name: item?.institutionDetail?.name ?? "",
        abbreviation: item?.institutionDetail?.abbreviation ?? "",
        location: item?.institutionDetail?.address?.location ?? "",
        city: item?.institutionDetail?.address?.city ?? "",
        state: item?.institutionDetail?.address?.state ?? "",
      });
      item.memberSince = item?.memberSince ?? "";
      item.expiredOn = item?.expiredOn ?? "";
      item.doesNotExpire = item?.doesNotExpire ?? false;
      item.currentMember = item?.doesNotExpire ?? false;
      item.memberShipName = item?.name ?? "";
      item.startYear = item?.memberSince ?? "";
      item.endYear = item?.expiredOn ?? "";
      if (item?.institutionDetail?.guid) {
        item.institutionDetail = {
          guid: item?.institutionDetail?.guid,
          name: item?.institutionDetail ? makeFullNameWithAddressWhenAddressIsNested(item?.institutionDetail) : "",
          institutionName: item?.institutionDetail?.name,
          onSelectNewInstitutionCollege: 1,
        };
      }

      return (
        <li
          className="nex-icon-list-item"
          key={`promembership_${item.guid}`}
        >
          <div className="card-graphic">
            <img src={certifications_icon} alt="" className="contain-bg no-border-radius" />
          </div>
          <div className="nex-info">
            <h3 className="card-title">{item.memberShipName}</h3>
            <p className="card-text mb-0"><Link
                to={`/institution/${profileIdLink}`}
                className="mb-2 color-accent"
              >{institutionName}</Link></p>
            <p className="card-text">{item?.membershipNumber &&
                <>
                  <FontAwesomeIcon
                    icon={faMedrt}
                    title="Membership Details"
                  />{" "}
                  {item.membershipNumber}
                </>
              }</p>
              {item?.memberSince && <p className="card-text">
                Issued On: {convertToMMMYYYYFormat(item?.memberSince)} {" - "}
                {item?.doesNotExpire ? <span><span>{"Present | "}</span><span>{dateDifferenceInYearsAndMonths(item?.memberSince)}</span></span> : <span>{convertToMMMYYYYFormat(item?.expiredOn)}</span>}
              </p>}

              <div className="nex-action">
              {(isAdmin ||
                (isLoggedinUser &&
                  currentUser.status === UserStatusConstants.APPROVED)) && (
                  <div className="delete-action">
                    <Button
                      color="link"
                      className="visible-hover py-0 nex-pm-professional-edit-btn"
                      onClick={() => handleEditItem(item)}
                    >
                      {/* <img src={grayPencilIcon} /> */}
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="15.9062" cy="16" r="15.375" fill="white" stroke="#DBF4FB" strokeWidth="0.75"/>
<path d="M19.3612 9.53125L17.8557 11.0368L20.8667 14.0478L22.3722 12.5423L19.3612 9.53125ZM16.3502 12.5423L10.3281 18.5643V21.5754H13.3392L19.3612 15.5533L16.3502 12.5423Z" fill="#46ABC9"/>
</svg>
                    </Button>

                    <Button
                      color="link"
                      className="visible-hover delete py-0 nex-pm-professional-del-btn"
                      onClick={() => handleRemoveItem(item)}
                    >
                      {/* <img src={grayTrashIcon} /> */}
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="16.2266" cy="16" r="15.75" fill="#FFEAEF"/>
<path d="M11.3984 13.7734L12.2586 20.1323C12.426 21.3702 12.5097 21.9892 12.9337 22.3597C13.3577 22.7302 13.9823 22.7302 15.2315 22.7302H18.4692C19.7184 22.7302 20.343 22.7302 20.767 22.3597C21.191 21.9892 21.2747 21.3702 21.4421 20.1323L22.3023 13.7734" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" stroke-linejoin="round"/>
<path d="M14.5156 11.0475V11.0475C14.5156 10.2672 14.5156 9.87708 14.6572 9.57473C14.8061 9.25693 15.0616 9.00138 15.3794 8.85254C15.6818 8.71094 16.0719 8.71094 16.8522 8.71094V8.71094C17.6324 8.71094 18.0226 8.71094 18.3249 8.85254C18.6427 9.00138 18.8983 9.25693 19.0471 9.57473C19.1887 9.87708 19.1887 10.2672 19.1887 11.0475V11.0475" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" stroke-linejoin="round"/>
<path d="M22.3023 11.0547L11.3984 11.0547" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" stroke-linejoin="round"/>
<path d="M14.8906 14.1719L15.28 20.0132" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" stroke-linejoin="round"/>
<path d="M18.7957 14.1719L18.4062 20.0132" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" stroke-linejoin="round"/>
</svg>
                    </Button>
                  </div>
                )}
              </div>
          </div>

              {/**  old code */}  
          <div className="row g-0 d-none">

            <div className="col-auto">
              
            </div>
            <div className="col">
              <h3 className="card-title">{item.memberShipName}</h3>
              <p className="card-title mb-0"><small className="text-muted fw-semibold"><Link
                to={`/institution/${profileIdLink}`}
                className="mb-2 color-accent"
              >{institutionName}</Link></small></p>
              <p className="card-text"><small className="text-muted">{item?.membershipNumber &&
                <>
                  <FontAwesomeIcon
                    icon={faMedrt}
                    title="Membership Details"
                  />{" "}
                  {item.membershipNumber}
                </>
              }</small></p>
              {item?.memberSince && <p className="card-text"><small>
                Issued On: {convertToMMMYYYYFormat(item?.memberSince)} {" - "}
                {item?.doesNotExpire ? <span><span>{"Present | "}</span><span>{dateDifferenceInYearsAndMonths(item?.memberSince)}</span></span> : <span>{convertToMMMYYYYFormat(item?.expiredOn)}</span>}
              </small></p>}
            </div>

            {/** 
            <div className="flex-grow-1">
              <div className="d-flex">
                <div className="img-32px">
                  <FontAwesomeIcon icon={faUniversity} size="2x" />
                </div>
                <div className="ml-0">
                  <h6 className="section-item-title f-14">
                    {item.memberShipName}
                  </h6>
                  <h6 className="f-14 m-0 text-primary">
                    <Link
                      to={`/institution/${profileIdLink}`}
                      className="mb-2 color-accent"
                    >
                      {institutionName}
                    </Link>
                  </h6>
                  <div
                    className="section-item-shortdes f-14 mt-2 text-capitalize"
                    
                  >
                    {item?.membershipNumber &&
                      <>
                        <FontAwesomeIcon
                          icon={faMedrt}
                          
                          title="Membership Details"
                        />{" "}
                        {item.membershipNumber}
                      </>
                    }
                  </div>
                  {item?.memberSince && (
                    <div
                      className="section-item-shortdes mt-2 text-capitalize"
                      
                    >
                      Issued On: {timeStamp}
                    </div>
                  )}
                </div>
              </div>
            </div>

*/}

            <div className="col-auto d-flex align-items-start">
              {(isAdmin ||
                (isLoggedinUser &&
                  currentUser.status === UserStatusConstants.APPROVED)) && (
                  <div className="delete-action">
                    <Button
                      color="link"
                      className="visible-hover py-0 nex-pm-professional-edit-btn"
                      onClick={() => handleEditItem(item)}
                    >
                      {/* <img src={grayPencilIcon} /> */}
                      <img src={edit_blue_icon} alt="" />
                    </Button>

                    <Button
                      color="link"
                      className="visible-hover delete py-0 nex-pm-professional-del-btn"
                      onClick={() => handleRemoveItem(item)}
                    >
                      {/* <img src={grayTrashIcon} /> */}
                      <img src={del_red_icon} alt="" />
                    </Button>
                  </div>
                )}
            </div>
          </div>
          
        </li>
      );
    });
  }
  return null;
};

const ProfessionalMemberships = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const eventName = useSelector((state) => state.profileComplete.eventName);
  const dispatch = useDispatch();
  const { alert, isLoggedinUser, isAdmin, setShowMembershipOnTabScroll } = props;
  const { guid } = props.practitioner;
  const [proMembers, setProMembers] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);
  const initialValue = {
    institutionDetail: {},
    memberShipName: "",
    positionHeld: "",
    memberSince: "",
    startYear: "",
    endYear: "",
    membershipNumber: "",
    expiredOn: "",
    doesNotExpire: false,
    currentMember: false,
    addressLine1: "",
    addressLine2: "",
    city: [],
    country: [],
    location: [],
    postalCode: "",
    state: [],
    category: "",
  };
  const [isOpenAlert, setAlertModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(updateProfileCompletness(7, !!proMembers.length));
    if (proMembers.length > 0 || isLoggedinUser || isAdmin) {
      setShowMembershipOnTabScroll(true);
    }else{
      setShowMembershipOnTabScroll(false);
    }
  }, [proMembers]);

  useEffect(() => {
    if (eventName === "ADD_MEMBERSHIPS") {
      handleEditItem(initialValue);
      dispatch(profileAddEvent(null));
    }
  }, [eventName]);

  const toggleAlert = () => {
    setLoading(false);
    setAlertModal(!isOpenAlert);
  };

  useEffect(() => {
    getData();
  }, [guid]);

  const handleEditItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    toggle();
  };

  const handleRemoveItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    toggleAlert();
  };

  const handleClickOnYes = async () => {
    setLoading(true);
    try {
      const request = PractitionerService.deleteProMembership(
        guid,
        selectedItem.guid
      );
      request.then((response) => {
        const { status } = response;
        if (status === 204) {
          setTimeout(() => {
            showSuccessMessage("Membership deleted successfully");
            getData();
            setLoading(false);
            toggleAlert();
          }, 1000);
        } else {
          showErrorMessage("Error deleting membership");
          setLoading(false);
          toggleAlert();
        }
      });
    } catch (e) {
      showErrorMessage(e.message);
      setLoading(false);
      toggleAlert();
    }
  };

  const getData = () => {
    const response = PractitionerService.getProMembership(guid);
    response.then((response) => {
      let { status, data } = response;
      if (status === 200 || status === 201) {
        if (data) {
          setProMembers(data);
        }
      } else {
        setProMembers([]);
      }
    });
  };

  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  if (
    getEmptySectionShowHide(
      proMembers ?? [],
      isAdmin,
      currentUser,
      globalProfileInfo,
      guid
    )
  )
    return null;

  return (
    <>
      <Card className={`nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="professionalSection">
        <div className="card-header"><h2>Professional Memberships</h2>
          {(isAdmin ||
            (isLoggedinUser &&
              currentUser.status === UserStatusConstants.APPROVED)) && (
              <Button
                id="nex-pm-professional-add-btn"
                color="link"
                className="add-button ml-auto"
                onClick={() => handleEditItem(initialValue)}
              >
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="9" cy="9.5" r="8.4375" fill="white" stroke="#46ABC9" strokeWidth="1.125"/>
<path d="M8.875 5.75L8.875 13.0039" stroke="#46ABC9" strokeWidth="1.125" strokeLinecap="round"/>
<path d="M12.5156 9.38281L5.26172 9.38281" stroke="#46ABC9" strokeWidth="1.125" strokeLinecap="round"/>
</svg> Add More </Button>
            )}
          <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
          <svg width={17}  height={10} viewBox="0 0 17 10" fill="none"  xmlns="http://www.w3.org/2000/svg" >
                        <path
                          d="M2.25 1.625L8.625 8L15 1.625"
                          stroke="black"
                          strokeWidth={3}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
            </svg>
          </button>
        </div>
        <div className={`collapse-div`}>
          <CardBody className={
            proMembers && proMembers.length > 0 ? "" : "align-items-center"
          }>
            {proMembers && proMembers.length
              ? ""
              : (isLoggedinUser || isAdmin) &&
              currentUser.status === UserStatusConstants.APPROVED && (
                <Button
                  id="nex-pm-professional-add-btn2"
                  className="readmore"
                  color="link"
                  onClick={() => handleEditItem(initialValue)}
                >
                  {"Add your membership"}
                </Button>
              )}
            {/** 
          <Row
            
          >
            <Col>
              <h3 className="section-title m-0 mb-3">
                Professional Memberships
              </h3>
              {proMembers && proMembers.length
                ? ""
                : (isLoggedinUser || isAdmin) &&
                currentUser.status === UserStatusConstants.APPROVED && (
                  <div className="mt-3">
                    <Row className="align-items-center">
                      <Col className="add-infomation-part">
                        <Button
                          className="add-infomation p-0"
                          color="link"
                          onClick={() => handleEditItem(initialValue)}
                        >
                          {"Add your membership"}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )}
            </Col>
            {(isAdmin ||
              (isLoggedinUser &&
                currentUser.status === UserStatusConstants.APPROVED)) && (
                <Col xs="auto">
                  <Button
                    color="link"
                    className="round-icon-btn rounded-circle"
                    onClick={() => handleEditItem(initialValue)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </Col>
              )}
          </Row>
*/}

            <ul className="nex-icon-list-within-card" id="nex-pm-professional-lists">
              <RenderProMember
                proMembers={proMembers}
                handleEditItem={handleEditItem}
                handleRemoveItem={handleRemoveItem}
                isLoggedinUser={isLoggedinUser}
                isAdmin={isAdmin}
              />
            </ul>
            {proMembers.length < 1 && 
            <NoData>No Data</NoData>
            }
            <RenderProMembershipModal
              selectedItem={selectedItem}
              toggle={toggle}
              isOpen={isOpen}
              practitionerId={guid}
              getData={getData}
              isAdmin={isAdmin}
            />
            <AlertDialog
              toggle={toggleAlert}
              isOpen={isOpenAlert}
              isLoading={isLoading}
              handleClickOnYes={handleClickOnYes}
              yesLabel={"Yes"}
              noLabel={"No"}
            />
          </CardBody>
        </div>
      </Card>
    </>
  );
};

export default withAlert()(ProfessionalMemberships);
