import React from 'react'
import user_img from 'assets/images/user-profile-sample.png'
import IconPrev from './icons/IconPrev'
import IconNext from './icons/IconNext'
import { Card, CardBody } from 'reactstrap'

export default function UserSearchLists() {
    return (
        <div className="nexogic-user-search-lists mb-4">
            <Card className="nexogic-user-search-list">
                <div className="nexogic-thumb-preview">
                    <img src={user_img} alt="" />
                    <span className="icon-upload-btn icon"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none" viewBox="0 0 12 10"><path fill="#fff" d="M7.272 1.111L8.37 2.222h2.43V8.89H1.2V2.222h2.43l1.098-1.11h2.544zM7.8 0H4.2L3.102 1.111H1.2c-.66 0-1.2.5-1.2 1.111V8.89C0 9.5.54 10 1.2 10h9.6c.66 0 1.2-.5 1.2-1.111V2.222c0-.61-.54-1.11-1.2-1.11H8.898L7.8 0zM6 3.889c.99 0 1.8.75 1.8 1.667 0 .916-.81 1.666-1.8 1.666s-1.8-.75-1.8-1.666c0-.917.81-1.667 1.8-1.667zm0-1.111c-1.656 0-3 1.244-3 2.778 0 1.533 1.344 2.777 3 2.777S9 7.09 9 5.556c0-1.534-1.344-2.778-3-2.778z"></path></svg></span>
                </div>
                <CardBody>
                    <div className="row">
                        <h3 className="card-title col">Dr. Anna Sotelo </h3>
                    </div>
                    <div className="row">
                        <div className="user-information col-lg-6">
                            <p className="card-text">MBBS, MS - Orthopedic Surgeon (Orthopedist)</p>
                            <p className="card-text">32 years experience overall</p>
                            <p className="card-text">12 years as a specialist</p>
                            <p className="card-text"><span className='icon'><svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="16"
                                fill="none"
                                viewBox="0 0 12 16"
                            >
                                <path
                                    fill="#001244"
                                    fillOpacity="0.6"
                                    d="M6 .32A5.127 5.127 0 00.88 5.44c0 4.516 4.681 9.903 4.88 10.13.061.07.147.11.24.11a.33.33 0 00.24-.11c.199-.231 4.88-5.71 4.88-10.13A5.127 5.127 0 006 .32zm0 3.52a1.92 1.92 0 11-.001 3.841 1.92 1.92 0 010-3.84z"
                                ></path>
                            </svg></span> California, CA, 18974</p>
                            <p className="card-text"><span className="icon"><svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="13"
                                fill="none"
                                viewBox="0 0 14 13"
                            >
                                <path
                                    fill="#FF9D07"
                                    d="M7 10.513L11.12 13l-1.094-4.687 3.64-3.153-4.793-.407L7 .333l-1.874 4.42-4.793.407 3.64 3.153L2.88 13 7 10.513z"
                                ></path>
                            </svg></span> <a className='link underline'>24 reviews</a></p>
                            <p className="card-text"><a href=""><span className="icon"><svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="17"
                                fill="none"
                                viewBox="0 0 18 17"
                            >
                                <path
                                    fill="#FF9090"
                                    d="M16.572 2.552a4.84 4.84 0 00-3.6-1.568c-1.04 0-1.99.329-2.829.977A5.786 5.786 0 009 3.155 5.783 5.783 0 007.856 1.96 4.528 4.528 0 005.028.984a4.84 4.84 0 00-3.6 1.568C.508 3.55 0 4.915 0 6.394c0 1.522.567 2.916 1.785 4.385 1.09 1.315 2.656 2.65 4.47 4.195.618.528 1.32 1.126 2.05 1.763a1.055 1.055 0 001.39 0c.73-.637 1.432-1.235 2.051-1.763 1.813-1.546 3.38-2.88 4.469-4.195C17.433 9.31 18 7.916 18 6.394c0-1.48-.507-2.844-1.428-3.842z"
                                ></path>
                            </svg></span> Added to my doctors</a> </p>
                            <div className="actions">
                                <a href="" className="btn nexogic_primary_button">Consult now</a>
                            </div>
                        </div>
                        <div className="user-availability col-lg-6">
                            <h3 className="h3 d-flex flex-wrap">Availability <div className='nexogic-date-switcher ms-auto'>
                                <div className="btn btn-prev"><IconPrev /></div>
                                <span className="date">Apr 22, 2021</span>
                                <div className="btn btn-next"><IconNext /></div>
                            </div></h3>
                            <div className="nexogic-time-slot">
                                <span className='time'>09:00 am</span>
                                <span className='time'>10:45 am</span>
                                <span className='time'>11:45 am</span>
                                <span className='time'>12:45 pm</span>
                                <span className='time'>01:45 pm</span>
                                <span className='time'>02:00 pm</span>
                                <span className='time'>03:45 pm</span>
                                <span className='time'>05:45 pm</span>
                            </div>
                            <div className="actions">
                                <div className="btn nexogic-btn-def"><span className='icon'><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    fill="none"
                                    viewBox="0 0 18 18"
                                >
                                    <path
                                        fill="#3EABCA"
                                        d="M4.32.854c-.394 0-.72.311-.72.687v.687H1.44c-.418 0-.72.289-.72.688V5.32h16.56V2.916c0-.4-.303-.688-.72-.688H14.4v-.687c0-.376-.327-.687-.72-.687h-.72c-.394 0-.72.311-.72.687v.687H5.76v-.687c0-.376-.327-.687-.72-.687h-.72zm0 .687h.72v2.062h-.72V1.54zm8.64 0h.72v2.062h-.72V1.54zM.72 6.008V16.66c0 .4.302.688.72.688h15.12c.417 0 .72-.289.72-.688V6.008H.72zm3.6 2.062h1.8v1.718h-1.8V8.07zm2.52 0h1.8v1.718h-1.8V8.07zm2.52 0h1.8v1.718h-1.8V8.07zm2.52 0h1.8v1.718h-1.8V8.07zm-7.56 2.405h1.8v1.718h-1.8v-1.718zm2.52 0h1.8v1.718h-1.8v-1.718zm2.52 0h1.8v1.718h-1.8v-1.718zm2.52 0h1.8v1.718h-1.8v-1.718zm-7.56 2.406h1.8v1.718h-1.8V12.88zm2.52 0h1.8v1.718h-1.8V12.88zm2.52 0h1.8v1.718h-1.8V12.88zm2.52 0h1.8v1.718h-1.8V12.88z"
                                    ></path>
                                </svg>
                                </span> View all availability</div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
            {/* card end */}

            <Card className="nexogic-user-search-list">
                <div className="nexogic-thumb-preview">
                    <img src={user_img} alt="" />
                    <span className="icon-upload-btn icon"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none" viewBox="0 0 12 10"><path fill="#fff" d="M7.272 1.111L8.37 2.222h2.43V8.89H1.2V2.222h2.43l1.098-1.11h2.544zM7.8 0H4.2L3.102 1.111H1.2c-.66 0-1.2.5-1.2 1.111V8.89C0 9.5.54 10 1.2 10h9.6c.66 0 1.2-.5 1.2-1.111V2.222c0-.61-.54-1.11-1.2-1.11H8.898L7.8 0zM6 3.889c.99 0 1.8.75 1.8 1.667 0 .916-.81 1.666-1.8 1.666s-1.8-.75-1.8-1.666c0-.917.81-1.667 1.8-1.667zm0-1.111c-1.656 0-3 1.244-3 2.778 0 1.533 1.344 2.777 3 2.777S9 7.09 9 5.556c0-1.534-1.344-2.778-3-2.778z"></path></svg></span>
                </div>
                <CardBody>
                    <div className="row">
                        <h3 className="card-title col">Dr. Anna Sotelo </h3>
                    </div>
                    <div className="row">
                        <div className="user-information col-lg-6">
                            <p className="card-text">MBBS, MS - Orthopedic Surgeon (Orthopedist)</p>
                            <p className="card-text">32 years experience overall</p>
                            <p className="card-text">12 years as a specialist</p>
                            <p className="card-text"><span className='icon'><svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="16"
                                fill="none"
                                viewBox="0 0 12 16"
                            >
                                <path
                                    fill="#001244"
                                    fillOpacity="0.6"
                                    d="M6 .32A5.127 5.127 0 00.88 5.44c0 4.516 4.681 9.903 4.88 10.13.061.07.147.11.24.11a.33.33 0 00.24-.11c.199-.231 4.88-5.71 4.88-10.13A5.127 5.127 0 006 .32zm0 3.52a1.92 1.92 0 11-.001 3.841 1.92 1.92 0 010-3.84z"
                                ></path>
                            </svg></span> California, CA, 18974</p>
                            <p className="card-text"><span className="icon"><svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="13"
                                fill="none"
                                viewBox="0 0 14 13"
                            >
                                <path
                                    fill="#FF9D07"
                                    d="M7 10.513L11.12 13l-1.094-4.687 3.64-3.153-4.793-.407L7 .333l-1.874 4.42-4.793.407 3.64 3.153L2.88 13 7 10.513z"
                                ></path>
                            </svg></span> <a className='link underline'>24 reviews</a></p>
                            <p className="card-text"><a href=""><span className="icon"><svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="17"
                                fill="none"
                                viewBox="0 0 18 17"
                            >
                                <path
                                    fill="#FF9090"
                                    d="M16.572 2.552a4.84 4.84 0 00-3.6-1.568c-1.04 0-1.99.329-2.829.977A5.786 5.786 0 009 3.155 5.783 5.783 0 007.856 1.96 4.528 4.528 0 005.028.984a4.84 4.84 0 00-3.6 1.568C.508 3.55 0 4.915 0 6.394c0 1.522.567 2.916 1.785 4.385 1.09 1.315 2.656 2.65 4.47 4.195.618.528 1.32 1.126 2.05 1.763a1.055 1.055 0 001.39 0c.73-.637 1.432-1.235 2.051-1.763 1.813-1.546 3.38-2.88 4.469-4.195C17.433 9.31 18 7.916 18 6.394c0-1.48-.507-2.844-1.428-3.842z"
                                ></path>
                            </svg></span> Added to my doctors</a> </p>
                            <div className="actions">
                                <a href="" className="btn nexogic_primary_button ">Consult now</a>
                            </div>
                        </div>
                        <div className="user-availability col-lg-6">
                            <h3 className="h3 d-flex flex-wrap">Availability <div className='nexogic-date-switcher ms-auto'>
                                <div className="btn btn-prev"><IconPrev /></div>
                                <span className="date">Apr 22, 2021</span>
                                <div className="btn btn-next"><IconNext /></div>
                            </div></h3>
                            <div className="nexogic-time-slot">
                                <span className='time'>09:00 am</span>
                                <span className='time'>10:45 am</span>
                                <span className='time'>11:45 am</span>
                                <span className='time'>12:45 pm</span>
                                <span className='time'>01:45 pm</span>
                                <span className='time'>02:00 pm</span>
                                <span className='time'>03:45 pm</span>
                                <span className='time'>05:45 pm</span>
                            </div>
                            <div className="actions">
                                <div className="btn nexogic-btn-def"><span className='icon'><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    fill="none"
                                    viewBox="0 0 18 18"
                                >
                                    <path
                                        fill="#3EABCA"
                                        d="M4.32.854c-.394 0-.72.311-.72.687v.687H1.44c-.418 0-.72.289-.72.688V5.32h16.56V2.916c0-.4-.303-.688-.72-.688H14.4v-.687c0-.376-.327-.687-.72-.687h-.72c-.394 0-.72.311-.72.687v.687H5.76v-.687c0-.376-.327-.687-.72-.687h-.72zm0 .687h.72v2.062h-.72V1.54zm8.64 0h.72v2.062h-.72V1.54zM.72 6.008V16.66c0 .4.302.688.72.688h15.12c.417 0 .72-.289.72-.688V6.008H.72zm3.6 2.062h1.8v1.718h-1.8V8.07zm2.52 0h1.8v1.718h-1.8V8.07zm2.52 0h1.8v1.718h-1.8V8.07zm2.52 0h1.8v1.718h-1.8V8.07zm-7.56 2.405h1.8v1.718h-1.8v-1.718zm2.52 0h1.8v1.718h-1.8v-1.718zm2.52 0h1.8v1.718h-1.8v-1.718zm2.52 0h1.8v1.718h-1.8v-1.718zm-7.56 2.406h1.8v1.718h-1.8V12.88zm2.52 0h1.8v1.718h-1.8V12.88zm2.52 0h1.8v1.718h-1.8V12.88zm2.52 0h1.8v1.718h-1.8V12.88z"
                                    ></path>
                                </svg>
                                </span> View all availability</div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
            {/* card end */}
            
        </div>
    )
}
