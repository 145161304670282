import React, { useState } from 'react'
import { Switch, Route } from "react-router-dom"

import { Container, Row } from 'reactstrap'
//import './style.css'
import Header from "common/Header"
import Footer from "common/Footer"
import Sidebar from "../App/components/Sidebar"
import PageNotFound from 'pages/404'
import Search from "./components/Search"
import withAdminAuth from "hoc/withAdminAuth"
import CreateProfile from './components/CreateProfile'

const Admin = () => {
  const [isActive, setIsActive] = useState(false);
  const handleToggle = () => {
    setIsActive(!isActive);
  }

  return (
    <section id="adminSection" className='theme-bg'>
      <Header />
      <main className='nexogic-dashboard-card'>
        <Container fluid className={`nexogic-container-gap card-body ${isActive ? 'side-open' : 'col-sm-0'}`}>
          <div className="row need-margin">
            {/** 
            <div className={`nexogic-admin-menu `}>
              
              <Sidebar toggleButton={handleToggle} isActive={isActive} />
            </div>
            */}
            <div className={`nexogic-admin-content`}>
              <div className="content-wrapper ml-0">
                <Switch>
                  <Route exact path="/admin" component={CreateProfile} />
                  <Route exact path="/admin/search" component={Search} />
                  <Route render={() => <PageNotFound />} />
                </Switch>
              </div>
            </div>
          </div>
        </Container>
      </main>
      <Footer />
    </section>
  )
}

export default withAdminAuth(Admin);