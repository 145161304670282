import { faEllipsisH, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dropdown_icon from "assets/images/dropdown_icon.png";
import groupViewImage from "assets/images/groupview_img.png";
import groupUnsplash from "assets/images/group_unsplash.png";
import likeIcon from "assets/images/review_active_icon.png";
import commentsIcon from "assets/images/svgs/comments_icon.svg";
import lockIcon from "assets/images/svgs/lock_icon.svg";
import private_privacy_icon from "assets/images/svgs/private_privacy_icon.svg";
import shareIcon from "assets/images/svgs/share_icon.svg";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import camera_icon from "assets/images/camera-icon.png";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useSelector } from "react-redux";
import AlertDialog from "common/AlertDialog";
import {
  Button,
  Card,
  CardBody,
  CardImg,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { ConnectionServices } from "utils/ConnectionServices";
import { encrypt } from "utils/EncryptDecrypt";
import { createImageFromInitials, getRandomColor } from "utils/Utils";
import GroupviewSide from "./GroupviewSide";
import { useHistory } from "react-router-dom";
import AboutGroupDetails from "./AboutGroupDetails";
import CommonLoader from "common/CommonLoader";
import { FAILTOSAVERECOED } from "utils/Constants";
import { CreateGroupModal } from "./CreateGroupModal";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const GroupView = (props) => {
  let { id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [memberOpen, setMemberOpen] = useState(false);
  const [seletDrop, setSeletDrop] = useState("Member");
  const [groupUsers, setGroupUsers] = useState([]);
  const [groupAdmins, setGroupAdmins] = useState([]);
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bannerImage, setBannerImage] = useState(null);
  const [avatarImage, setAvatarImage] = useState(null);
  const [connections, setConnections] = useState([]);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [nextMembershipStatus, setNextMembershipStatus] = useState("Join");
  const [isOpenAlert, setAlertModal] = useState(false);
  const [description, setDescription] = useState("No Details");
  const [currentGroupId, setCurrentGroupId] = useState(null);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [isCGOpen, setIsCGOpen] = useState(false);
  const toggleCGModal = () => setIsCGOpen(!isCGOpen);
  const [selectedItem, setSelectedItem] = useState({
    groupName: "",
    privacy: "PRIVATE",
    status: "LISTED",
    groupDescription: "",
    rules: "",
    bannerId: "",
  });

  const handleImageUpload = (event, group_guid, type) => {
    let file = event.target.files[0];
    ConnectionServices.uploadGroupImage(file, group_guid, type).then(
      (response) => {
        if (response.status === 201) {
          if (type === "BANNER")
            showSuccessMessage("Banner Image updated successfully.");
          else showSuccessMessage("Profile Image updated successfully.");
          getGroup();
        } else {
          showErrorMessage("Something went wrong.");
        }
      }
    );
  };

  const memberToggle = () => {
    setMemberOpen(!memberOpen);
  };
  const seletDropItem = (value) => {
    setSeletDrop(value);
  };

  const getGroup = (groupId) => {
    ConnectionServices.getGroupByID(groupId).then((response) => {
      setCurrentGroupId(groupId);
      const { data } = response;
      setGroup(data);

      setSelectedItem({
        id: data?.id,
        groupName: data?.name,
        privacy: data?.privacy,
        status: data?.status,
        groupDescription: data?.description,
        rules: data?.rules,
        bannerId: data?.bannerId,
      });

      if (data?.bannerId) {
        let path = `${process.env.REACT_APP_IMAGE_BASE_URL}/${groupId}/${data?.bannerId}`;
        setBannerImage(path);
      }
      if (data?.profileImageId) {
        let path = `${process.env.REACT_APP_IMAGE_BASE_URL}/${groupId}/${data?.profileImageId}`;
        setAvatarImage(path);
      }
      if (data?.description) setDescription(data?.description);
    });
    setTimeout(() => setIsLoading(false), 500);
  };

  const getGroupUsers = (groupId) => {
    ConnectionServices.getGroupMembers(groupId).then((response) => {
      const { data } = response;
      setGroupUsers(data.contents);
    });
  };

  const getGroupAdmins = (groupId) => {
    ConnectionServices.getGroupMembers(groupId, "ACTIVE", "ADMIN").then(
      (response) => {
        const { data } = response;
        setGroupAdmins(data.contents);
      }
    );
  };

  const getMembershipStatus = (groupId) => {
    let userId = currentUser.guid;
    ConnectionServices.hasMembership(userId, groupId).then((response) => {
      if (response.status === 200 && response.data) {
        switch (response.data.status) {
          case "ACTIVE":
            setNextMembershipStatus("Leave");
            break;
          case "PENDING":
            setNextMembershipStatus("Pending");
            break;
          case "REJECTED":
            setNextMembershipStatus("Rejected");
            break;
          default:
            setNextMembershipStatus("Join");
        }
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getGroup(id);
    getGroupUsers(id);
    getGroupAdmins(id);
    getCurrentUserRole(id);
    getMembershipStatus(id);
  }, [id]);

  useEffect(() => {
    getGroup();
  }, [isCGOpen]);

  const getConnections = () => {
    setLoading(true);
    ConnectionServices.getConnectionAll(currentUser.guid)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setConnections(response.data.contents);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleMembershipClick = () => {
    // console.log("nextMembershipStatus", nextMembershipStatus);
    let url = window.location.pathname;
    const myArray = url.split("/");
    let groupId = myArray[myArray.length - 1];
    let userId = currentUser.guid;

    if (nextMembershipStatus === "Leave") {
      if (currentUserRole === "ADMIN") {
        setAlertText("Are you sure, you want to delete this group?");
        toggleAlert();
      } else {
        setAlertText("Are you sure, you want to leave this group?");
        toggleAlert();
      }
    }

    if (nextMembershipStatus === "Join") {
      joinTheGroup(userId, groupId);
    }
  };

  const toggleAlert = () => {
    setAlertModal(!isOpenAlert);
  };

  const handleClickOnYes = () => {
    toggleAlert();
    let url = window.location.pathname;
    const myArray = url.split("/");
    let groupId = myArray[myArray.length - 1];
    let userId = currentUser.guid;

    if (nextMembershipStatus === "Leave") {
      if (currentUserRole === "ADMIN") deleteTheGroup();
      else leaveTheGroup(userId, groupId);
    }
  };

  const leaveTheGroup = (userId, groupId) => {
    ConnectionServices.leaveTheGroup(userId, groupId).then((response) => {
      if (response.status === 204) {
        showSuccessMessage("You have left the Group, successfully...");
        setNextMembershipStatus("Join");
      } else {
        showErrorMessage(FAILTOSAVERECOED);
      }
    });
  };

  const joinTheGroup = (userId, groupId) => {
    // console.log("Joining");
    ConnectionServices.joinTheGroup(userId, groupId).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "ACTIVE") {
          showSuccessMessage("You have joined the Group, successfully...");
          setNextMembershipStatus("Leave");
        } else {
          showSuccessMessage(
            "Request to  join the Group has been sent,wait for approvel."
          );
          setNextMembershipStatus("Pending");
        }
      } else {
        showErrorMessage("Something went wrong...");
      }
    });
  };

  const getCurrentUserRole = () => {
    let url = window.location.pathname;
    const myArray = url.split("/");
    let groupId = myArray[myArray.length - 1];

    let userId = currentUser.guid;
    ConnectionServices.hasMembership(userId, groupId).then((response) => {
      if (response.status === 200) {
        setCurrentUserRole(response.data.role);
      } else {
        showErrorMessage("Something went wrong...");
      }
    });
  };

  const history = useHistory();
  const deleteTheGroup = () => {
    let url = window.location.pathname;
    const myArray = url.split("/");
    let groupId = myArray[myArray.length - 1];

    ConnectionServices.deleteTheGroup(groupId).then((response) => {
      setAlertText("Are you sure, you want to delete this group?");
      if (response.status === 204) {
        showSuccessMessage("Group deleted Successfully...");
        history.push("/connections/groups");
      } else {
        showErrorMessage("Something went wrong...");
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <div className="loading-spiner text-center">
          {/* <Spinner style={{ width: "2rem", height: "2rem" }} /> */}
          <CommonLoader />
        </div>
      ) : (
        group && (
          <div className="nexogic-group-profile" id="nex-profile-group">

            <Card className="nexogic-card-banner card border-0 nexogic-banner-admin-card ">
              <div className="card-cover">
                <div className="cover-bg position-relative">
                  {currentUserRole === "ADMIN" && (
                    <Button className="edit-cover-btn" id="nex-profile-edit-cover-btn">
                      <img src={camera_icon} alt="" className="icon" width={18} />
                      <input
                        name="coverImage"
                        className="fileUploadForm"
                        type="file"
                        onChange={(event) =>
                          handleImageUpload(event, group.id, "BANNER")
                        }
                        id="coverImage"
                        accept="image/*, png, jpg, jpeg"
                        title=""
                      />
                      <span>Edit cover photo</span>
                    </Button>
                  )}
                  {group && (
                    <CardImg
                      top
                      width="100%"
                      className="card-img"
                      src={bannerImage ? bannerImage : groupViewImage}
                      alt="Card image cap"
                    />
                  )}
                </div>
              </div>
              <div className=" card-body">

                <div className="card-user-img-w">


                  <div className="card-user-img">
                    <img
                      src={
                        avatarImage
                          ? avatarImage
                          : createImageFromInitials(
                            64,
                            group.name,
                            getRandomColor(null)
                          )
                      }
                      alt="#"
                      className="prof-img"
                    />
                  </div>
                  <input
                    name="profileImage"
                    className="fileUploadForm"
                    type="file"
                    onChange={(event) =>
                      handleImageUpload(event, group.id, "AVATAR")
                    }
                    id="profileImage"
                    accept="image/*, png, jpg, jpeg"
                    title=""
                    hidden
                  />
                  {currentUserRole === "ADMIN" && (
                    <label
                      htmlFor="profileImage"
                      className="edit-user-profile-img-btn btn btn-secondary"
                    >
                      <img src={camera_icon} width="18" />
                    </label>
                  )}

                </div>
                <div className="info">
                  <div className="nexogic-profile-info-card card">
                    <div className="card-body pb-0">
                      <div className="align-items-center row">
                        <div className="col-lg-12">
                          {group && (
                            <h3 className="card-title">
                              {group.name}
                            </h3>
                          )}
                        </div>
                        <div className="col-lg-12">
                          <p className="card-text">
                            {group && (
                              <span className="icon">
                                <img
                                  src={`${group.privacy == "PUBLIC"
                                    ? private_privacy_icon
                                    : lockIcon
                                    }`}
                                  alt="#"
                                />
                              </span>
                            )}
                            {group && (
                              <span>
                                {group.privacy === "PUBLIC" ? "Public " : "Private "}
                              </span>
                            )}

                            {group && (
                              <>
                                <span className="dot"></span>
                                <span>
                                  {group.membersCount}{" "}
                                  {group.membersCount === 1 ? "Member" : "Members"}
                                </span>
                              </>
                            )}
                          </p>
                        </div>
                        <div className="col-lg-12">
                          <div className="actions">
                            {group &&
                              (nextMembershipStatus === "Leave" ? (
                                currentUserRole === "ADMIN" ? (
                                  <>
                                    <Button
                                      id="gv_btn_del_group"
                                      color="danger"
                                      outline
                                      className="nex-btn-primary-outline"
                                      onClick={handleMembershipClick}
                                      disabled={nextMembershipStatus === "Pending" || nextMembershipStatus === "Rejected"}
                                    >
                                      Delete group
                                    </Button>

                                    <Button
                                      id="gv_btn_del_group"
                                      color="danger"
                                      outline
                                      className="nex-btn-primary-outline"
                                      onClick={toggleCGModal}
                                      disabled={isCGOpen}
                                    >
                                      Edit group
                                    </Button>
                                  </>
                                ) : (
                                  <Button
                                    id="gv_btn_leave_group"
                                    color="danger"
                                    outline
                                    className="nex-btn-primary-outline"
                                    onClick={handleMembershipClick}
                                    disabled={nextMembershipStatus === "Pending" || nextMembershipStatus === "Rejected"}
                                  >
                                    Leave Group
                                  </Button>
                                )
                              ) : nextMembershipStatus === "Join" &&
                                group.privacy === "PRIVATE" ? (
                                <Button
                                  id="gv_btn_req_join"
                                  color="info"
                                  outline
                                  className="nex-btn-primary"
                                  onClick={handleMembershipClick}
                                  disabled={nextMembershipStatus === "Pending" || nextMembershipStatus === "Rejected"}
                                >
                                  Request to Join
                                </Button>
                              ) : (
                                <Button
                                  id="gv_btn_ship_status"
                                  color="info"
                                  outline
                                  className="nex-btn-primary"
                                  onClick={handleMembershipClick}
                                  disabled={nextMembershipStatus === "Pending" || nextMembershipStatus === "Rejected"}
                                >
                                  {nextMembershipStatus}
                                </Button>
                              ))}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="nexogic-group-user-img-wrap">
                            {groupUsers.map((data, i) => {
                              return (
                                <div className="nexogic-group-user-images" key={i}>
                                  {data?.memberDetails?.avatarId ? (
                                    <img
                                      className="user-image rounded-circle"
                                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${data?.memberId}/${data?.memberDetails?.avatarId}`}
                                      alt="#"
                                    />
                                  ) : (
                                    <img
                                      className="rounded-circle"
                                      src={createImageFromInitials(
                                        64,
                                        data?.memberDetails?.name,
                                        getRandomColor(null)
                                      )}
                                      alt="profilePic"
                                      width={64}
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = createImageFromInitials(
                                          132,
                                          data.memberDetails.name,
                                          getRandomColor(null)
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>


            {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
              <div className="position-relative mb-3 ">
                <Row>
                  <Col md={8}>
                    <div className="group-cards d-none">
                      <Card>
                        <CardBody className="p-3">
                          <div className="d-flex">
                            <div className="d-flex align-items-center">
                              <div>
                                <img
                                  src={createImageFromInitials(
                                    50,
                                    "Dr. Ranjan Shetty",
                                    getRandomColor(encrypt(21).toString())
                                  )}
                                  className="rounded-circle"
                                  width="32"
                                  height="32"
                                  alt="#"
                                />
                              </div>
                              <div className="pl-2 black-color-accent f-12 line-h-14 font-weight-500">
                                Dr. Ranjan Shetty
                              </div>
                              <div className="pl-12 text-complimentary f-12 line-h-14">
                                Jun 12, 2020, 15:30, Europe
                              </div>
                            </div>
                            <div className="ml-auto">
                              <FontAwesomeIcon
                                color="#2D4552"
                                className="font-weight-500"
                                size="sm"
                                icon={faEllipsisH}
                              />
                            </div>
                          </div>
                          <Card className="group-inner-card mt-2">
                            <CardImg
                              top
                              width="100%"
                              className="inner-group-cardimg"
                              src={groupUnsplash}
                              alt="Card image cap"
                            />
                            <CardBody className="p-12">
                              <div className="d-flex align-items-center flex-wrap">
                                <div>
                                  <div className="black-color-accent f-16 line-h-16 font-weight-500">
                                    Our emergency and trauma care is a state of
                                    the art department
                                  </div>
                                  <div className="d-flex align-items-center mt-2">
                                    <div className="text-complimentary f-12 line-h-12 group-text">
                                      website.com
                                    </div>
                                    <div className="dot mx-2"></div>
                                    <div className="text-complimentary f-12 line-h-12 group-text">
                                      6 min
                                    </div>
                                  </div>
                                </div>
                                <Button
                                  color="primary"
                                  className="btn-outline-primary view-more-btn py-2 px-4 f-14 line-h-16 ml-auto"
                                >
                                  View More
                                </Button>
                              </div>
                            </CardBody>
                          </Card>
                          <div className="mb-2 mt-3 px-2 d-flex align-items-center justify-content-between">
                            <div className="bottom-icons d-flex align-items-center">
                              <div>
                                <img src={likeIcon} alt="#" />
                              </div>
                              <div className="active pl-2">Likes</div>
                              <div className="pl-2 number">1230</div>
                            </div>
                            <div className="bottom-icons d-flex align-items-center">
                              <div>
                                <img src={commentsIcon} alt="#" />
                              </div>
                              <div className="pl-2">Comment</div>
                              <div className="pl-2 number">12</div>
                            </div>
                            <div className="bottom-icons d-flex align-items-center">
                              <div>
                                <img src={shareIcon} alt="#" />
                              </div>
                              <div className="pl-2">Share</div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    <AboutGroupDetails groupId={currentGroupId} />
                  </Col>
                  <Col md={4}>
                    <GroupviewSide
                      description={description}
                      groupUsers={groupUsers}
                      groupAdmins={groupAdmins}
                      groupId={currentGroupId}
                    />
                  </Col>
                </Row>
              </div>
            )}
            <Modal
              backdrop="static"
              scrollable
              isOpen={modalOpen}
              toggle={() => setModalOpen(!modalOpen)}
              centered={true}
            >
              <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
                Invite Connections
              </ModalHeader>
              <ModalBody className="connections-managements ">
                {loading ? (
                  <div className="loading-spiner text-center">
                    <Spinner />
                  </div>
                ) : (
                  connections.map((connection) => (
                    <div className="tabular-wrap">
                      <div className="user-image-section">
                        <img
                          className="user-image"
                          src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${connection.practitionerDetails.profileGuid}/${connection.practitionerDetails.avatarId}`}
                          alt="#"
                        />
                      </div>
                      <div className="name-content">
                        <a
                          className="practice-name"
                          href="/profile/drbareetsingh-5c1154"
                        >
                          {connection.practitionerDetails.name}
                        </a>
                        <div className="area-knowledge">
                          {connection.practitionerDetails.primarySpeciality}{" "}
                        </div>
                        <div className="host-name">
                          {connection.practitionerDetails.location}
                          {", "}
                          {connection.practitionerDetails.city}
                          {", "}
                          {connection.practitionerDetails.state}
                        </div>
                      </div>
                      <div className="actions">
                        <Dropdown toggle={memberToggle} isOpen={memberOpen}>
                          <DropdownToggle className="msg-button" outline>
                            {seletDrop} <img src={dropdown_icon} alt="#" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              id="ga_group_admin"
                              onClick={() => seletDropItem("Group Admin")}
                              dropDownValue="Group Admin"
                            >
                              Group Admin
                            </DropdownItem>
                            <DropdownItem
                              id="ga_member"
                              onClick={() => seletDropItem("Member")}
                              dropDownValue="EUR"
                            >
                              Member
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                        <Button
                          id="ga_invite"
                          className="nex-btn-primary-outline  ml-3"
                          color="primary"
                          outline
                        >
                          invite
                        </Button>
                      </div>
                    </div>
                  ))
                )}
              </ModalBody>
              <ModalFooter className="justify-content-end  modal-footer">
                <div className="action-group">
                  <Button
                    id="ga_cancel"
                    color="primary"
                    outline
                    onClick={() => setModalOpen(!modalOpen)}
                    className="nex-btn-primary"
                  >
                    Cancel
                  </Button>
                </div>
              </ModalFooter>
            </Modal>

            <AlertDialog
              bodyText={alertText}
              toggle={toggleAlert}
              isOpen={isOpenAlert}
              handleClickOnYes={handleClickOnYes}
              yesLabel={"Yes"}
              noLabel={"No"}
            />
            <CreateGroupModal
              isOpen={isCGOpen}
              toggle={toggleCGModal}
              selectedItem={selectedItem}
              handleEditItem={setSelectedItem}
              values={group}
              onDetailPage={true}
            />
          </div>
        )
      )}
    </>
  );
};

export default withAlert()(GroupView);
