import React from 'react';
import QRCode from "react-qr-code";
import Nexogic from "assets/images/logo-nexogic.png";

const SmartCardQR = (props) => {
    const { title, profileId, firstName,lastName, qualification } = props.profile;
    let qrUrl = `${process.env.REACT_APP_WEBSITE_URL}/profile/${profileId}`;
    const username = `${title} ${firstName} ${lastName}`;
    return (
        <>
            <div className="nexogic-vcard-wrap">
                    <div className='nexogic-vcard'>
                                    <div className='w-100'>
                                        <div className="nexogic-vcard-content">Nexogic</div>
                                        <div className="nexogic-vcard-container">
                                            <div className="col-left">
                                                <h6 className='nexogic-vcard-title'>{username}</h6>
                                                <p className='nexogic-vcard-subtitle'>{qualification}</p>
                                            </div>
                                            <div className="col-right">
                                                <img src={Nexogic} className="logo" />
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="text-center p-3">
                                                <QRCode value={qrUrl} bgColor={'#45aac800'} fgColor={'#ffffff'} size={80} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 text-center">
                                                <h6 className='nexogic-footer-text'>Powered By</h6>
                                                <p className='nexogic-footer-bigtext'>Nexogic</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
            </div>
        </>
    )
}

export default SmartCardQR