import { ErrorMessage } from "formik";
import NumberFormat from "react-number-format";
import { FormGroup, Label } from "reactstrap";
import moment from "moment";

const FormikInputMonthYearFormatField = ({
  startYear,
  endYear,
  field: { ...fields },
  form: { touched, errors, handleBlur, setFieldValue },
  ...props
}) => {
  const withValueCheck = (inputObj) => {
    const { formattedValue } = inputObj;
    const formattedValueArray = formattedValue.split("-");
    const [monthVal, yearVal] = formattedValueArray;
    if (monthVal && !monthVal.includes("M")) {
      if (monthVal > 12) return false;
    }
    if (yearVal && !yearVal.includes("Y")) {
      const num = yearVal;
      const low = startYear;
      const high = endYear;
      return parseInt(num) >= parseInt(low) && parseInt(num) <= parseInt(high);
    }
    return true;
  };

  return (
    <FormGroup className="flex-grow-1">
      {props.label && (
        <Label htmlFor={props.id} className={"label-color"}>
          {props.label}
        </Label>
      )}
      <NumberFormat
        {...props}
        {...fields}
        format="##-####"
        placeholder="MM-YYYY"
        mask={["M", "M", "Y", "Y", "Y", "Y"]}
        isAllowed={withValueCheck}
        onBlur={(e) => {
          if (props.type === "textarea") {
            handleBlur(e);
          } else {
            const val = (e.target.value || "").replace(/\s+/gi, " ");
            setFieldValue(fields.name, val.trim());
            handleBlur(e);
          }
        }}
        invalid={Boolean(touched[fields.name] && errors[fields.name]).toString()}
        className="form-control"
      />
      <ErrorMessage
        name={fields.name}
        render={(msg) => (
          <span className="text-danger f-12 d-inline-block  mt-1 line-height-error">
            {msg}
          </span>
        )}
      />
    </FormGroup>
  );
};

FormikInputMonthYearFormatField.defaultProps = {
  startYear: moment().subtract(50, "years").format("YYYY"),
  endYear: moment().add(50, "Y").format("YYYY"),
};

export default FormikInputMonthYearFormatField;
