import addIcon from "assets/images/svgs/add_icon.svg";
import "assets/styles/typeahead.css";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import { useFormik } from "formik";
import { findIndex } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { withAlert } from "react-alert";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, FormGroup, Row, Spinner } from "reactstrap";
import { authSetUser, setGlobalprofile } from "redux-helpers/ActionCreator";
import { MetadataService } from "utils/MetadataService";
import { PractitionerService } from "utils/PractitionerService";
import { setUser } from "utils/UserHelper";
import * as Yup from "yup";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const validationSchema = Yup.object().shape({
  expert: Yup.string().required("This field is required"),
});

const SkillsForm = (props) => {
  const { toggle, setPractitioner, practitioner, isAdmin, alert } = props;
  const [skillsData, setData] = useState([]);
  const [skills, setSkills] = useState(practitioner.skills);
  const [suggestedSkills, setSuggestedSkills] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");
  const skillsRef = useRef(null);
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        MetadataService.getAllSkillSet().then((response) => {
          const { status, data } = response;
          if (status === 200 || status === 201) {
            setData(data);
          }
        });
      } catch (e) {
        setErrorMessage(e.message);
        setLoading(false);
      }
    };
    getData();
    getSuggestedSkills();
  }, []);

  const formik = useFormik({
    initialValues: { skills: skills },
    validationSchema: isAdmin ? null : validationSchema,
  });

  const updateItems = async (value) => {
    setLoading(true);
    try {
      const payload = [
        {
          op: "replace",
          path: "/skills",
          value: value,
        },
      ];
      PractitionerService.updatePractitionersDetails(
        payload,
        globalProfileInfo.guid
      ).then((response) => {
        const { status, validationErrors } = response;
        if (status === 200 || status === 201) {
          updateStoreSkils();
          showSuccessMessage("Skills updated Successfully");
        } else {
          // showErrorMessage(response.message);
          setLoading(false);
          if (
            validationErrors &&
            validationErrors &&
            validationErrors.length > 0
          ) {
            const error = [];
            validationErrors.forEach((errors) => {
              error.push(errors.message);
            });
            showErrorMessage(error.join(","));
          } else {
            showErrorMessage("Error while updating skills");
          }
        }
      });
    } catch (e) {
      console.log("PractitionerService.updatePractitionersDetails error", e);
      showErrorMessage("Server facing problem while updating skills.");
      setLoading(false);
    }
  };

  const getSuggestedSkills = () => {
    try {
      MetadataService.getSpecialitySkillSet(
        practitioner.primarySpeciality
      ).then((response) => {
        const { status, data } = response;
        if (status === 200 || status === 201) {
          setSuggestedSkills(data);
        }
      });
    } catch (e) {
      setErrorMessage(e.message);
      setLoading(false);
    }
  };

  const updateStoreSkils = () => {
    PractitionerService.gePractitionersDetails(globalProfileInfo.guid)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setUser(response.data);
          setTimeout(() => {
            setPractitioner(response.data);
            if (!isAdmin) {
              dispatch(authSetUser(response.data));
            } else {
              dispatch(setGlobalprofile(response.data));
            }
            toggle();
          }, 1000);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("PractitionerService.gePractitionersDetails error", error);
        showErrorMessage(error.message);
        setLoading(false);
      });
  };

  const handleSuggestedSkills = (skill) => {
    setSkills([...skills, skill]);
  };

  const skillsSuggestion = useMemo(() => {
    if (suggestedSkills && suggestedSkills.length > 0) {
      const uniqueSuggestedSkills = suggestedSkills.filter(
        (s) => findIndex(skills, (o) => o === s) < 0
      );
      return uniqueSuggestedSkills.map((data) => {
        return (
          <div
            className="nexogic-suggested-skill d-flex align-items-center"
            onClick={() => handleSuggestedSkills(data)}
          >
            <div className="card-text">{data}</div>
            <div className="add-icon ml-1">
              <img src={addIcon} alt="" />
            </div>
          </div>
        );
      });
    }
    return null;
  }, [skills, suggestedSkills]);

  const onChangeHandler = (campaign) => {
    const preData = campaign.map((item) => {
      let clone = item;
      let custom = item?.customOption ? true : false;
      if (custom) {
        clone = item?.name;
      }
      return clone;
    });
    preData.length ? setSkills(preData) : setSkills([]);
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit} id="nex-ps-skill-form">
        {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
        <Row form>
          <Col xs={12}>
            <FormGroup className="searchbox-input-chips mb-1">
              <Typeahead
                ref={skillsRef}
                allowNew
                clearButton
                newSelectionPrefix="Add Skill: "
                selected={skills}
                id="skills"
                multiple
                options={skillsData}
                labelKey={"name"}
                placeholder="Add your skills ex. Cardiology"
                onBlur={() => {
                  return skillsRef !== null
                    ? skillsRef.current.hideMenu()
                    : null;
                }}
                onChange={onChangeHandler}
              />
            </FormGroup>
            <p className="card-text mt-3"><small>You can add upto 50 skills</small></p>
            {/* <div className="selected-skills d-flex flex-wrap mt-3">
              {skills.map((data) => {
                return (
                  <div className="selected-skill d-flex align-items-center p-2">
                    <div className="skill-name">{data}</div>
                    <div className="checkmark ml-3">
                      <img src={tickCheckmark} alt="" />
                    </div>
                  </div>
                );
              })}
            </div> */}
            <h5 className="card-title mt-3">
              Suggested skills based on your profile:
            </h5>
            <div className="nexogic-suggested-skills mt-3">
              {skillsSuggestion}
            </div>
          </Col>
        </Row>
        {/*<hr className="profile-info-modal-hr" />*/}
        <div className="justify-content-end  modal-footer">
          <Col xs="auto" className="action-group">
            {isLoading ? (
              <div className="loading-spiner">
                <Spinner />
              </div>
            ) : (
              <>
              <Button
                  id="nex-ps-skill-submit-btn"
                  className="nexogic_primary_button"
                  color="primary"
                  type="submit"
                  onClick={() => updateItems(skills)}
                  disabled={skills.length === 0 && !isAdmin}
                >
                  Save
                </Button>
                <Button
                  id="nex-ps-skill-cancel-btn"
                  onClick={toggle}
                  className="nexogic_primary_button_outline"
                  color="primary"
                  type="submit"
                >
                  Cancel
                </Button>
                
              </>
            )}
          </Col>
        </div>
      </form>
    </>
  );
};
export default withAlert()(SkillsForm);
