import React from 'react'
import './css/v1.scss'
import applo_logo_img from 'assets/images/logo-top-icon.png'
import applo_bg_img from 'assets/images/ads_six_bg.jpg'
import AdsFive from '../AdsFive'

function AdsSix(props) {
  return (
    <>
    <div className='nex-ads-wrapper-h v6' style={{backgroundImage: `url(${props.bg})`}}>
        <div className="nex-ads-brand">
            <a href={props.logo_url}><img src={props.logo_img} alt="" /></a>
        </div>
        <div className="nex-ads-main-contain">
            <div className="nex-ads-content-wrap">
                <h2 className='nex-ads-headings'>{props.title}</h2>
                <p>{props.short_desc}</p>
                <p><a target={props.button_target} href={props.button_link} className='btn'>{props.button_text}</a></p>
            </div>
        </div>
    </div>
    </>
  )
}

/* default props */
AdsSix.defaultProps = {
  bg:applo_bg_img,
  logo_img:applo_logo_img,
  logo_url:"#",
  logo_target:"_blank",
  title: "40 Years of ploneering Cardiac Care",
  short_desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum dolores est non ipsa laborum? Veritatis odio animi deleniti mollitia et neque tempora accusantium. Placeat, minus?",
  button_text: "Book Now",
  button_link: "#",
  button_target: "_blank"
}

export default AdsSix;
