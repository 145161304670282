import React from "react"

import { Redirect } from "react-router-dom"

import { UserService } from "utils/UserService"

const withAuth = Component => props => {
  return UserService.verifyToken() ? <Component {...props} /> : <Redirect to="/login" />
}

export default withAuth
