import React from 'react'
import { Card, CardBody, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from "classnames";
import { useState } from 'react';
import DentalInsurance from '../InsuranceSettings/DentalInsurance';
import VisionInsurance from '../InsuranceSettings/VisionInsurance';
import MedicalInsurance from '../InsuranceSettings/MedicalInsurance';


export default function DemographicInfo() {
  const [activeTab, setActiveTab] = useState("Medical");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div className='nexogic-connections-container px-0 row'>
      <div className="col-md-4 col-lg-3">
        <div className="nexogic-sidebar-stiky mb-3">
          <div className="tabs-border-radius card">
            <ul className='top-links nexogic-aside-menu'>
              <li className='nav-item active'><a href="" className='nav-link'>Profile</a></li>
              {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                <>
                  <li className='nav-item'><a href="" className='nav-link'>Notifications & Password Settings</a></li>
                  <li className='nav-item'><a href="" className='nav-link'>Insurance</a></li>
                  <li className='nav-item'><a href="" className='nav-link'>Demographic Info</a></li>
                </>
              }
            </ul>
          </div>
        </div>
      </div>
      <div className='col-md-8 col-lg-9'>
        <div className="border-0 bg-transparent">
          <div className="card-body p-0">
            <Card>
              <CardBody>
                <div className="nexogic-form-check-group mb-4">
                  <h3 className="card-title light">Race</h3>
                  <p className='card-text mb-3'><small>Select one or more</small></p>

                  <div className="form-check checkbox mb-3">
                    <input className="form-check-input" type="checkbox" value="" id="check1" />
                    <label className="form-check-label" htmlFor="check1">
                      American Indian or Alaska Native
                    </label>
                  </div>

                  <div className="form-check checkbox mb-3">
                    <input className="form-check-input" type="checkbox" value="" id="check2" />
                    <label className="form-check-label" htmlFor="check2">
                      Asian
                    </label>
                  </div>

                  <div className="form-check checkbox mb-3">
                    <input className="form-check-input" type="checkbox" value="" id="check3" />
                    <label className="form-check-label" htmlFor="check3">
                      Black or African American
                    </label>
                  </div>

                  <div className="form-check checkbox mb-3">
                    <input className="form-check-input" type="checkbox" value="" id="check4" />
                    <label className="form-check-label" htmlFor="check4">
                      Native Hawaiian
                    </label>
                  </div>

                  <div className="form-check checkbox mb-3">
                    <input className="form-check-input" type="checkbox" value="" id="check5" />
                    <label className="form-check-label" htmlFor="check5">
                      Other Pacific Islander
                    </label>
                  </div>

                  <div className="form-check checkbox mb-3">
                    <input className="form-check-input" type="checkbox" value="" id="check6" />
                    <label className="form-check-label" htmlFor="check6">
                      White
                    </label>
                  </div>

                  <div className="form-check checkbox mb-3">
                    <input className="form-check-input" type="checkbox" value="" id="check7" />
                    <label className="form-check-label" htmlFor="check7">
                      Other
                    </label>
                  </div>

                  <div className="form-check checkbox mb-3">
                    <input className="form-check-input" type="checkbox" value="" id="check8" />
                    <label className="form-check-label" htmlFor="check8">
                      Decline to Answer
                    </label>
                  </div>
                </div>
                <h3 className="card-title mb-3">Ethnicity</h3>
                <div className='nexogic-form-check-group'>
                  <div className="form-check checkbox mb-3">
                    <input className="form-check-input" type="checkbox" value="" id="check9" />
                    <label className="form-check-label" htmlFor="check9">
                      Hispanic or Latino
                    </label>
                  </div>
                  <div className="form-check checkbox mb-3">
                    <input className="form-check-input" type="checkbox" value="" id="check10" />
                    <label className="form-check-label" htmlFor="check10">
                      Not Hispanic or Latino
                    </label>
                  </div>
                  <div className="form-check checkbox mb-3">
                    <input className="form-check-input" type="checkbox" value="" id="check11" />
                    <label className="form-check-label" htmlFor="check11">
                      Decline to Answer
                    </label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>

      <div className="nexogic-patient-content">

        <div className="border-0 bg-transparent">
          <div className="card-body p-0">


          </div>
        </div>


      </div>
    </div>
  )
}
