import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem,Card,CardBody} from 'reactstrap';
import dropdownIcon from 'assets/images/dropdown_icon.png'
import "./Feedback.scss";
import ReviewsMade from "./Components/ReviewsMade/ReviewsMadeUi";
import ReviewsTable from "./Components/ReviewTableNew/FeedApp";
import reviewIcon from "assets/images/svgs/reviews-icon.svg";
const Feedback = () => {
    
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const[chooseDoctor,setChooseDoctor]=useState("Doctors");
    
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const handleClick = (e) => {
        e.preventDefault();
        let doc=e.target.innerHTML;
        setChooseDoctor(doc);
    }
return(
        <div className="feedback">
            {/** 
            <div className="nexogic-heading-sec nexogic-card">
                
                <h2 className="nexogix-section-title"><img src={reviewIcon} className='icon' /> Feedback</h2>
                <Dropdown isOpen={dropdownOpen} toggle={toggle} className="ml-auto">
                <DropdownToggle className="d-flex align-items-center py-1 toggle justify-content-between " >

                   <div className="ml-1 mr-4 doctor">{chooseDoctor}</div> 
                    <div className="mr-1 ml-4">
                        <img src={dropdownIcon} alt="dropmenu"/>
                    </div>

                </DropdownToggle>
                 <DropdownMenu className="text-left">
                    <DropdownItem onClick={handleClick}>Dr. Ted Rusell</DropdownItem>
                    <DropdownItem onClick={handleClick}>Dr. Angela yu</DropdownItem>
                    <DropdownItem onClick={handleClick}>Dr. Srinivasan</DropdownItem>
                 </DropdownMenu>
                 
                </Dropdown>
                
            </div> 
            */}
            <ReviewsMade/>
            <Card className="mt-4">
                <CardBody className="mx-3 pt-3">
                    <ReviewsTable/>
                </CardBody>
            </Card>
           
        </div>
    )
}
export default Feedback;