import { Form, Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import { useCallback } from "react";
import { memo, useEffect, useMemo, useState } from "react";
import { withAlert } from "react-alert";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { InstitutionServices } from "utils/InstitutionServices";
import { isAdmin } from "utils/UserHelper";
import { sortByDay, sortByTime } from "utils/Utils";
import * as Yup from "yup";
import HospitalTimingForm from "./HospitalTimingForm";
import TimingslotContainer from "./TimingslotContainer";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";

const initialValuesObj = {
  schedulesHospital: [],
  scheduleType: "",
};

const validationSchema = () =>
  Yup.object().shape({
    scheduleType: Yup.string(),
    schedulesHospital: Yup.array()
      .of(
        Yup.object()
          .shape({
            startTime: Yup.string().required("This field is required"),
            endTime: Yup.string().required("This field is required"),
            mon: Yup.boolean(),
            tue: Yup.boolean(),
            wed: Yup.boolean(),
            thu: Yup.boolean(),
            fri: Yup.boolean(),
            sat: Yup.boolean(),
            sun: Yup.boolean(),
          })
          .test("myCustomTest", "Select at least on day", (obj) => {
            if (
              obj.mon ||
              obj.tue ||
              obj.wed ||
              obj.thu ||
              obj.fri ||
              obj.sat ||
              obj.sun
            ) {
              return true; // everything is fine
            }
            return new Yup.ValidationError(
              "Please select at one day",
              null,
              "daySelected"
            );
          })
      )
      .min(1, "Atleast one work day is required"),
  });

const HospitalTimingsUI = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [formType, setFormType] = useState("add");
  const [selectedItem, setSelectedItem] = useState(null);
  const [listData, setListData] = useState({
    hospital_Hours_list: null,
    vising_Hours_list: null,
    oPD_Hours_list: null,
  });
   // const { institution, alert, isAdmin, setShowHospitalTimingsOnTabScroll } = props;
  const {institution, getInstitutionProfile,themeDetails,previewFlag,alert,isEditable,isAdmin } = props;
  const { guid } = props.institution;
  const [pannel_on, setPannel_on] = useState(true);
  const [unformatedSchedules, setUnformatedSchedules] = useState([]);
  const sectionColors = (sThemeColor) => {
    // const frmDoc = document.documentElement.style.setProperty;
    document.documentElement.style.setProperty('--section-bg-color', sThemeColor[3]);
    document.documentElement.style.setProperty('--section-headerbg-color', sThemeColor[4]);
    document.documentElement.style.setProperty('--section-headerfont-color', sThemeColor[5]);
    document.documentElement.style.setProperty('--section-font-color', sThemeColor[2]);
    document.documentElement.style.setProperty('--section-heading-color', sThemeColor[1]);
  }

  useEffect(()=>{
    if(themeDetails.length >0){
      sectionColors(themeDetails)
    }
  },[themeDetails])
  // useEffect(()=>{
  //   if(isDisplayed){
  //     setShowHospitalTimingsOnTabScroll(true);
  //   }else{
  //     setShowHospitalTimingsOnTabScroll(false);
  //   }
  // }, [isDisplayed])

  function toggle() {
    setModalOpen(!modalOpen);
  }

  const sortOrderByDate = (schedules) =>
    _.mapValues(_.groupBy([...schedules], "dayOfWeek"), (clist) =>
      clist.map((schedule) => _.omit(schedule, "dayOfWeek"))
    );

  const fillUpMissingDay = (data, type) => {
    const sorter = {
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 7,
    };
    let mdata = [];
    Object.keys(sorter).map((node) => {
      let timeObj =
        data.map((each) => {
          return each.dayOfWeek.toLocaleUpperCase() === node.toLocaleUpperCase()
        }
        ).length > 0 ? data.filter((each) => {
          return each.dayOfWeek.toLocaleUpperCase() === node.toLocaleUpperCase();
        }) : "";
      if (timeObj.length > 0) {
        for (let i = 0; i < timeObj.length; i++) {
          let item = {
            dayOfWeek: node.toLocaleUpperCase(),
            endTime: timeObj[i]?.endTime ?? "",
            scheduleType: type,
            startTime: timeObj[i]?.startTime ?? "",
          };
          mdata.push({ ...item });
        }
      } else {
        let item = {
          dayOfWeek: node.toLocaleUpperCase(),
          endTime: "",
          scheduleType: type,
          startTime: "",
        };
        mdata.push({ ...item });

      }
      return node;
    });
    return mdata;
  };

  const getData = useCallback(() => {
    setListData({
      hospital_Hours_list: null,
      vising_Hours_list: null,
      oPD_Hours_list: null,
    });
    const request = InstitutionServices.getInstitutionById(guid);
    request.then((response) => {
      let { data } = response;
      let { schedules } = data;
      setUnformatedSchedules(schedules);
      if (schedules && schedules.length > 0) {
        let manupulateData = {
          hospital_Hours_list: null,
          vising_Hours_list: null,
          oPD_Hours_list: null,
        };
        const mainHospital_hours = _.filter(
          [...schedules],
          (node) => node.scheduleType === "HOSPITAL_HOURS"
        );
        if (mainHospital_hours.length > 0) {
          sortByTime(mainHospital_hours);
          sortByDay(mainHospital_hours);
          let filterData = fillUpMissingDay(
            mainHospital_hours,
            "HOSPITAL_HOURS"
          );
          manupulateData.hospital_Hours_list = sortOrderByDate(filterData);
        }
        const mainVisitor_hours = _.filter(
          [...schedules],
          (node) => node.scheduleType === "VISITOR_HOURS"
        );
        if (mainVisitor_hours.length > 0) {
          sortByTime(mainVisitor_hours);
          sortByDay(mainVisitor_hours);
          let filterData = fillUpMissingDay(mainVisitor_hours, "VISITOR_HOURS");
          manupulateData.vising_Hours_list = sortOrderByDate(filterData);
        }
        const mainoPD_HOURS = _.filter(
          [...schedules],
          (node) => node.scheduleType === "OPD_HOURS"
        );
        if (mainoPD_HOURS.length > 0) {
          sortByTime(mainoPD_HOURS);
          sortByDay(mainoPD_HOURS);
          let filterData = fillUpMissingDay(mainoPD_HOURS, "OPD_HOURS");
          manupulateData.oPD_Hours_list = sortOrderByDate(filterData);
        }
        setListData(manupulateData);
      } else {
        setListData(null);
      }
    });
  }, [institution.guid]);

  const formatPayloadTime = (incommingData, type) => {
    let preDataSet = [];
    if (incommingData) {
      Object.keys(incommingData).forEach((schedule) => {
        for (let i = 0; i < incommingData[schedule].length; i++) {
          const { startTime, endTime } = incommingData[schedule][i];
          if (startTime && endTime) {
            preDataSet.push({
              dayOfWeek: schedule,
              startTime,
              endTime,
              scheduleType: type,
            });
          }
        }
        return schedule;
      });
    }
    return preDataSet;
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    let newSchedules = [];
    const { scheduleType, schedulesHospital } = values;
    schedulesHospital.map((obj) => {
      const newValue = {
        dayOfWeek: "",
        startTime: moment(obj.startTime).format("HH:mm:ss"),
        endTime: moment(obj.endTime).format("HH:mm:ss"),
      };
      Object.keys(obj).forEach((key) => {
        if (key === "mon" && obj[key] === true) {
          newSchedules.push({ ...newValue, dayOfWeek: "MONDAY", scheduleType });
        }
        if (key === "tue" && obj[key] === true) {
          newSchedules.push({
            ...newValue,
            dayOfWeek: "TUESDAY",
            scheduleType,
          });
        }
        if (key === "wed" && obj[key] === true) {
          newSchedules.push({
            ...newValue,
            dayOfWeek: "WEDNESDAY",
            scheduleType,
          });
        }
        if (key === "thu" && obj[key] === true) {
          newSchedules.push({
            ...newValue,
            dayOfWeek: "THURSDAY",
            scheduleType,
          });
        }
        if (key === "fri" && obj[key] === true) {
          newSchedules.push({ ...newValue, dayOfWeek: "FRIDAY", scheduleType });
        }
        if (key === "sat" && obj[key] === true) {
          newSchedules.push({
            ...newValue,
            dayOfWeek: "SATURDAY",
            scheduleType,
          });
        }
        if (key === "sun" && obj[key] === true) {
          newSchedules.push({ ...newValue, dayOfWeek: "SUNDAY", scheduleType });
        }
      });
      return obj;
    });
    let formNewSchedules = [...newSchedules];
    if (
      scheduleType !== "HOSPITAL_HOURS" &&
      listData !== null &&
      listData?.hospital_Hours_list !== null &&
      Object.keys(listData.hospital_Hours_list).length > 0
    ) {
      formNewSchedules = [
        ...formNewSchedules,
        ...formatPayloadTime(listData.hospital_Hours_list, "HOSPITAL_HOURS"),
      ];
    }
    if (
      scheduleType !== "VISITOR_HOURS" &&
      listData !== null &&
      listData?.vising_Hours_list !== null &&
      Object.keys(listData.vising_Hours_list).length > 0
    ) {
      formNewSchedules = [
        ...formNewSchedules,
        ...formatPayloadTime(listData.vising_Hours_list, "VISITOR_HOURS"),
      ];
    }
    if (
      scheduleType !== "OPD_HOURS" &&
      listData !== null &&
      listData?.oPD_Hours_list !== null &&
      Object.keys(listData.oPD_Hours_list).length > 0
    ) {
      formNewSchedules = [
        ...formNewSchedules,
        ...formatPayloadTime(listData.oPD_Hours_list, "OPD_HOURS"),
      ];
    }
    InstitutionServices.patchInstitutionDetails(
      [
        {
          op: "replace",
          path: "/schedules",
          value: formNewSchedules,
        },
      ],
      guid
    )
      .then(async (response) => {
        const { status } = response;
        if (status === 200 || status === 201) {
          await getData();
          setLoading(false);
          showSuccessMessage("Institution details updated successfully");
          toggle();
        } else {
          setLoading(false);
          let errmsg =
            response.data && !!response.data.message
              ? response.data.message
              : "Failed to save. Please try again";
          showErrorMessage(errmsg);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(
          "PractitionerService.registerHospitalAndClinicDetails error",
          error
        );
      });
  };

  const handleToggle = (e) => {
    e.preventDefault();
    toggle();
  };

  const formatEditItemData = (item, type) => {
    let finalSchedules = [];
    if (item && Object.keys(item).length > 0) {
      const newSchedules = [];
      let newTimings = [];
      Object.keys(item).forEach((schedule) => {
        for (let i = 0; i < item[schedule].length; i++) {
          const { startTime, endTime } = item[schedule][i];
          if (startTime && endTime) {
            const newItem = {
              startTime,
              endTime,
              mon: schedule === "MONDAY" ? true : false,
              tue: schedule === "TUESDAY" ? true : false,
              wed: schedule === "WEDNESDAY" ? true : false,
              thu: schedule === "THURSDAY" ? true : false,
              fri: schedule === "FRIDAY" ? true : false,
              sat: schedule === "SATURDAY" ? true : false,
              sun: schedule === "SUNDAY" ? true : false,
            };
            newTimings.push({ startTime, endTime });
            newSchedules.push(newItem);
          }

        }
        return schedule;
      });
      _.uniqBy(newTimings, "startTime", "endTime").map((time) => {
        let { startTime, endTime } = time;
        let newItem = {
          startTime:
            startTime && endTime ? moment(startTime, "HH:mm:ss").toDate() : "",
          endTime:
            startTime && endTime ? moment(endTime, "HH:mm:ss").toDate() : "",
          mon: false,
          tue: false,
          wed: false,
          thu: false,
          fri: false,
          sat: false,
          sun: false,
        };
        newSchedules.map((obj) => {
          if (startTime === obj.startTime && endTime === obj.endTime) {
            if (obj.mon === true) {
              newItem = { ...newItem, mon: true };
            }
            if (obj.tue === true) {
              newItem = { ...newItem, tue: true };
            }
            if (obj.wed === true) {
              newItem = { ...newItem, wed: true };
            }
            if (obj.thu === true) {
              newItem = { ...newItem, thu: true };
            }
            if (obj.fri === true) {
              newItem = { ...newItem, fri: true };
            }
            if (obj.sat === true) {
              newItem = { ...newItem, sat: true };
            }
            if (obj.sun === true) {
              newItem = { ...newItem, sun: true };
            }
          }
          return obj;
        });
        finalSchedules.push(newItem);
        return time;
      });
    }
    return {
      schedulesHospital: finalSchedules,
      scheduleType: type ?? "",
    };
  };

  const handleEditItem = (selectedItem, type) => {
    setSelectedItem(formatEditItemData(selectedItem, type));
    toggle();
    setFormType(type);
  };

  useEffect(() => {
    if (institution?.guid) {
      getData();
    }
  }, [institution?.guid]);

  useEffect(() => {
    if (formType === "add") {
      setSelectedItem(initialValuesObj, "add");
    }
  }, [formType]);

  useEffect(() => {
    if (listData) {
      if (isAdmin) {
        setIsDisplayed(true);
      } else {
        setIsDisplayed(
          listData?.hospital_Hours_list !== null ||
          listData?.vising_Hours_list !== null ||
          listData?.oPD_Hours_list !== null
        );
      }
    }
  }, [isAdmin, listData]);
  return isDisplayed ? (
    <Row>
      <Col lg={12} className="text-center px-3">
        <div id="section-services" className="m-v1">
      <Card className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''} micro-section-card-vs1`} >
        <div className="card-header section-header"><h2>Business Hours</h2>
          <button id="ht-btn-pannel-1" className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
            <img src={pannel_arrow} alt="" />
          </button>
        </div>
        <div className={`collapse-div`}>
          <CardBody className="bg-color">
            <TimingslotContainer
              list={listData}
              handleEditItem={handleEditItem}
              isAdminUser={isAdmin}
              isEditable={isEditable??true}
              unformatedSchedules={unformatedSchedules}
            />
          </CardBody>
        </div>
      </Card>
      <Modal
        backdrop="static"
        isOpen={modalOpen}
        toggle={toggle}
        id="profile-modal"
        transition="false"
      >
        <ModalHeader toggle={toggle} className="nex-institutions-modal-header"><span className="nex-text-xl">Update Hospital Hour/Timings</span></ModalHeader>
        <ModalBody>
          <Formik
            initialValues={selectedItem}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            children={(props) => {
              return (
                <Form className="affiliation-form">
                  <HospitalTimingForm {...props} list={listData} />
                  {/*<hr className="profile-info-modal-hr mt-0" />*/}
                  <div className="justify-content-end  modal-footer">
                    <div className="action-group">
                      {isLoading ? (
                        <div className="loading-spiner">
                          <Spinner />
                        </div>
                      ) : (
                        <>
                        <Button id="ht-btn-save" className="modal-save-btn nex-btn-primary" color="secondary " type="submit">Save</Button>
                        <Button id="ht-btn-cancel" className="modal-cancel-btn nex-btn-primary-outline" color="primary " type="button" onClick={handleToggle}>Cancel</Button>
                        </>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          />
        </ModalBody>
      </Modal>
    </div>
    </Col>
    </Row>
  ) : null;
};
export default withAlert()(memo(HospitalTimingsUI));
