import React from 'react';
import { Row, Col } from "reactstrap";
import './styles.css';

const PatientDetConfirmBox = (props) => {
const { pdetails } = props;
  return (
    <div border>
      <p className='msgtext'>The patient already exists with the same email or phone number</p>
      <div className="personal-info border m-2">
                <h3 className="header">Patient Details</h3>
                <div className="text-left mt-3">
                    <Row className='mx-1'>
                        <Col lg={4} className='px-0'>
                            <div className="nexogic-text-box pl-0 mb-0">
                                <h6 className="card-title field-title">Name</h6> 
                            </div>
                        </Col>
                        <Col lg={8} className='px-0'>
                            <div className="nexogic-text-box pl-0 mb-0">                                
                                <p className="card-text px-1">{pdetails.firstName} {pdetails.lastName} </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mx-1'>
                        <Col lg={4} className='px-0'>
                            <div className="nexogic-text-box pl-0 mb-0">                                
                                <h6 className="card-title field-title">Gender</h6>
                            </div>
                        </Col>
                        <Col lg={8} className='px-0'>
                            <div className="nexogic-text-box pl-0 mb-0"> 
                                <p className="card-text px-1">{pdetails.gender ==='M' ? 'Male':pdetails.gender ==='F' ? 'Female' : ' '}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mx-1'>
                        <Col lg={4} className='px-0'>
                            <div className="nexogic-text-box pl-0 mb-0">
                                <h6 className="card-title field-title">Phone Number</h6>
                            </div>
                        </Col>
                        <Col lg={8} className='px-0'>
                            <div className="nexogic-text-box pl-0 mb-0">                                
                                <p className="card-text px-1">{pdetails.cellularPhoneNumber}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mx-1'>
                        <Col lg={4} className='px-0'>
                            <div className="nexogic-text-box pl-0 mb-0">
                                <h6 className="card-title field-title">Email </h6>
                            </div>
                        </Col>
                        <Col lg={8} className='px-0'>
                            <div className="nexogic-text-box pl-0">                                
                                <p className="card-text px-1 nexogic-word-break">{pdetails.emailAddress}</p>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col lg={4}>
                            <div className="nexogic-text-box">
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="nexogic-text-box">
                            </div>
                        </Col>
                    </Row> */}
                </div>
            </div>
      <p className='msgtext'>Do you want to add him to your patient ?</p>
    </div>
  );
}

export default PatientDetConfirmBox