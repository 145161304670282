import { useState, useEffect } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import PrimarySpecialityTypeaheadField from "components/forms/formikFields/PrimarySpecialityTypeaheadField";
import hiring_icon from 'assets/images/hiring-icon.png';
import { MetadataService } from "utils/MetadataService";
import edit_icon from 'assets/images/svgs/small-edit-btn.svg';

function JobCreateFormThree(props) {
    const { values, setFieldValue } = useFormikContext();
    const [primarySpecialityList, setPrimarySpecialityList] = useState([]);


    const handlePrimarySpeciality = (event) => {
        let selectedValue = event;
        if (typeof event === 'object' && event !== null && event.hasOwnProperty('type')) {
            selectedValue = selectedValue.target.value;
        }

        if (String(selectedValue).length > 2) {
            // setFieldShowNum(2);
        }
    };

    const [opportunityValSelected, setopportunityValSelected] = useState(values?.opportunity ? true : false);
    const handleOpportunity = (e) => {
        setopportunityValSelected(true);
        if (e.target.value.length > 2) {
            // setFieldShowNum(3);
        }
    }

    const [positionSelected, setPositionSelected] = useState(values?.position ? true : false);
    const handlePosition = (e) => {
        setPositionSelected(true);
        if (e.target.value.length > 2) {
            // setFieldShowNum(4);
        }
    }



    const getprimarySpeciality = () => {
        MetadataService.getPrimarySpecialities()
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setPrimarySpecialityList(response.data);
                }
            })
            .catch((error) => {
                console.log("IdentityService.getprimarySpeciality error", error);
            });
    };

    useEffect(() => {
        getprimarySpeciality();
    }, []);

    return (
        <>
            <div className="nex-job-form-left-sec">
                <div className="nex-job-info">
                    <img src={hiring_icon} alt="" className="graphic" />
                    <div className="conts">
                        <p><span className='light'>2/7</span> Job Post</p>
                        <h2 className='title'>Let’s start with a strong & fabulous title...</h2>
                        <p className='lead'>Fill the forms with some better and trendy words.</p>
                    </div>
                </div>
            </div>
            <div className="nex-job-form-right-sec">
                <>
                    <h2 className='nex-text-xl'>What position you are hiring for?</h2>
                    <div className="form-group gap-md">
                        <Field
                            name="title"
                            id="title"
                            type="text"
                            component={FormikInputField}
                            placeholder="Type the Job Title"
                            onInput={(e) => {

                                if (e.currentTarget.value.length > 2) {
                                    // setFieldShowNum(1);
                                } else {
                                    // setFieldShowNum(0);
                                }
                            }}
                        />
                    </div>
                    <div className="form-group gap-md">
                        <h2 className='nex-text-md'>Example Title</h2>
                        <ul className="nex-ul-info">
                            <li onClick={() => setFieldValue("title", "Cardiologists")} style={{ "cursor": "pointer" }}>Cardiologists</li>
                            <li onClick={() => setFieldValue("title", "Orthopedics")} style={{ "cursor": "pointer" }} >Orthopedics</li>
                            <li onClick={() => setFieldValue("title", "Gynecologists and obstetricians")} style={{ "cursor": "pointer" }} >Gynecologists and obstetricians</li>
                        </ul>
                    </div>
                </>

                {values?.title && values?.title.length > 2 &&
                    < div className="form-group gap-md">
                        <h2 className='nex-text-lg text-black'>Select the Primary Speciality</h2>
                        <Field
                            id="primarySpeciality"
                            name="primarySpeciality"
                            autoComplete="primarySpeciality"
                            className="primary-specialities"
                            type="text"
                            component={PrimarySpecialityTypeaheadField}
                            placeholder="Select Primary Speciality"
                            inputprops={{
                                autoComplete: "off",
                            }}
                            spellCheck={false}
                            specialit={primarySpecialityList}
                            onChange={handlePrimarySpeciality}

                        />
                    </div>
                }

                {values?.title && (values?.primarySpeciality && values?.primarySpeciality.length > 0) &&
                    <div className="form-group gap-md">
                        <h2 className='nex-text-lg text-black'>What is the type of this opportunity?</h2>
                        {opportunityValSelected === false &&
                            <>
                                <div className="nex-inline-radio-group">
                                    <div className="item">
                                        <Field id="opportunity_job" type="radio" name="opportunity" value="Job" onInput={(e) => handleOpportunity(e)} /> <label htmlFor="opportunity_job">Job</label>
                                    </div>
                                    <div className="item">
                                        <Field id="opportunity_Fellowship" type="radio" name="opportunity" value="Fellowship" onInput={(e) => handleOpportunity(e)} /> <label htmlFor="opportunity_Fellowship">Fellowship</label>
                                    </div>
                                    <div className="item">
                                        <Field id="opportunity_Mentorship" type="radio" name="opportunity" value="Mentorship" onInput={(e) => handleOpportunity(e)} /> <label htmlFor="opportunity_Mentorship">Mentorship</label>
                                    </div>
                                    <div className="item">
                                        <Field id="opportunity_Volunteer" type="radio" name="opportunity" value="Volunteer" onInput={(e) => handleOpportunity(e)} /> <label htmlFor="opportunity_Volunteer">Volunteer</label>
                                    </div>
                                </div>
                                <ErrorMessage name="opportunity" component="div" className="error-message"
                                    style={{ color: 'red', fontSize: '0.875rem', marginTop: '0.25rem' }}
                                />
                            </>
                        }
                        {opportunityValSelected === true &&
                            <div className="form-group-selected">
                                <strong>{values?.opportunity}</strong>
                                <div className="btn nex-floated-btn"><img src={edit_icon} alt="" onClick={() => setopportunityValSelected(false)} /></div>
                            </div>
                        }
                    </div>
                }


                {values?.title && (values?.primarySpeciality && values?.primarySpeciality.length > 0) && values?.opportunity &&
                    <div className="form-group gap-md">
                        <h2 className='nex-text-lg text-black'>Select the type of position you are hiring for</h2>
                        {positionSelected === false &&
                            <>
                                <div className="nex-inline-radio-group">
                                    <div className="item">
                                        <Field id="full_time" type="radio" name="position" value="Full Time" onInput={(e) => handlePosition(e)} /> <label htmlFor="full_time">Full Time</label>
                                    </div>
                                    <div className="item">
                                        <Field id="part_time" type="radio" name="position" value="Part Time" onInput={(e) => handlePosition(e)} /> <label htmlFor="part_time">Part Time</label>
                                    </div>
                                    <div className="item">
                                        <Field id="flexible_time" type="radio" name="position" value="Flexible-Time" onInput={(e) => handlePosition(e)} /> <label htmlFor="flexible_time">Flexible-Time</label>
                                    </div>
                                    <div className="item">
                                        <Field type="radio" id="consultant" name="position" value="Consultant" onInput={(e) => handlePosition(e)} /> <label htmlFor="consultant">Consultant</label>
                                    </div>
                                </div>
                                <ErrorMessage name="position" component="div" className="error-message"
                                    style={{ color: 'red', fontSize: '0.875rem', marginTop: '0.25rem' }}
                                />
                            </>
                        }

                        {positionSelected === true &&
                            <div className="form-group-selected">
                                <strong>{values?.position}</strong>
                                <div className="btn nex-floated-btn"><img src={edit_icon} alt="" onClick={() => setPositionSelected(false)} /></div>
                            </div>
                        }
                    </div>
                }
                {values?.title && (values?.primarySpeciality && values?.primarySpeciality.length > 0) && values?.opportunity && values?.position &&
                    <div className="form-group gap-md">
                        <h2 className='nex-text-lg text-black'>How many positions you are hiring?</h2>
                        <Field
                            id="noOfPosition"
                            name="noOfPosition"
                            autoComplete="noOfPosition"
                            type="number"
                            min="1"
                            component={FormikInputField}
                            placeholder="Number of Positions"
                        />
                    </div>
                }
            </div >
        </>
    );
}

export default JobCreateFormThree;
