import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import CertificationForm from "./CertificationForm"
export const RenderCertificationModal = (props) => {
  const {
    isOpen,
    toggle
  } = props;

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} scrollable={true} id="profile-modal" className="modal-dialog-scrollable modal-dialog-centered certifications-modal" backdrop="static">
        <ModalHeader toggle={toggle}>{`Certifications & Licensure`}</ModalHeader>
        <ModalBody>
          <CertificationForm {...props} />
        </ModalBody>
      </Modal>
    </div>
  );
}

