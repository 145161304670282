import React, { memo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Button, Card, CardBody } from "reactstrap";
import deg_icon from "assets/images/svgs/deg-icon.svg"
import year_icon from "assets/svgIcon/year-icon.svg"
import address_icon from "assets/svgIcon/address-icon.svg"
import treatment_icon from "assets/svgIcon/treatment-icon.svg"

import graduation_cap_icon from "assets/images/graduation-cap.png";
import suitcase_icon from "assets/images/suitcase.png";
import pin_small_icon from "assets/images/pin-small.png";
import { createImageFromInitials, getFullAddress, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import { useSelector } from "react-redux";
import { useState } from "react";
import { showErrorMessage } from "common/AlertContainer";

const ProfileCardItem = (props) => {
  const locationState = useLocation();
  const history = useHistory();
  const {
    name,
    profileGuid,
    qualification,
    primarySpeciality,
    superSpeciality,
    avatarId,
    practicingSince,
    location,
    city,
    state,
    profileId,
    profileStatus,
    viewMode,
    signedInUser
  } = props;

  const handleClaimProfile = () => {
    const { name, email, idToken, refreshToken, profiledetails, phoneNumber } = signedInUser;
      if (!profiledetails) {
        try {
          history.push(`${'/register/onboarding'}`, {
            firstName: name?.split(' ')[0],
            lastName: name?.split(' ')[1] || '',
            email: email,
            phoneNumber: phoneNumber,
            token: idToken,
            refreshToken: refreshToken,
            utype: false,
            claim: profileId ? true : false,
            profileId: profileId ?? "",
          });
        } catch (apiError) {
          console.error('API call failed:', apiError);
          showErrorMessage("Something went wrong..please try after sometime");
          history.push('/login');
        }
      } else {
        console.log("Account Already Exists..");
        showErrorMessage("Account Already Exists..Please Login");
        history.push('/login')
      }
  }
  // const currentUser = useSelector((state) => state.auth.currentUser);
  // const [isExpand, setIsExpand] = useState(false);
  return (
    <div className="nex-docor-profile nex-image-list" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">
      <div className="nexogic-graphic">
        <div className="graphic">
          {avatarId ?
            <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${profileGuid}/${avatarId}`} className="nexogic-avatar lg" alt="#" style={{ height: "120px", width: "120px" }} />
            :
            <img src={createImageFromInitials(50, `${name.replace('Dr. ', '')}`, getRandomColor(encrypt(profileGuid).toString()))} className="nexogic-avatar lg" alt="#" style={{ height: "120px", width: "120px" }} />
          }
        </div>
      </div>
      <div className="nexogic-info">
        {name && (
          <h3 className="card-title">{name}</h3>
        )}
        {typeof primarySpeciality != "undefined" &&
          <ul className="nex-inline-list">
            <li>{primarySpeciality}</li>
            {superSpeciality && superSpeciality != "undefined" &&
            <li> {superSpeciality}</li>
          }
          </ul>
        }
        {qualification && (<p><span className="nex-icon xs"><img src={graduation_cap_icon} alt="" /></span>{qualification}</p>)}
        {typeof practicingSince != "undefined" &&
          <p><span className="nex-icon xs"><img src={suitcase_icon} alt="" /></span>
            {!isNaN(parseInt(practicingSince)) &&
              parseInt(new Date().getFullYear()) - parseInt(practicingSince) > 0 ? (
              `${parseInt(new Date().getFullYear()) -
              parseInt(practicingSince)
              }+ years experience`
            ) : '0 year experience'}
          </p>
        }
        {(location && city && state) && (<p className="nex-lead text-dark"><span className="nex-icon xs"><img src={pin_small_icon} alt="" /></span>
          {getFullAddress({
            name: " ",
            location: location ?? "",
            city: city ?? "",
            state: state ?? "",
          }).replace("-", "")}
        </p>)}
      </div>
      {viewMode &&
          // <div className="nex-stratch-btn" to={{ pathname: `/claim-profile/${profileId}`, state: { props } }}></div>
          <div className="nex-stratch-btn cursor-pointer" onClick={handleClaimProfile}></div>
        }
    </div>
  );
};

export default memo(ProfileCardItem);
