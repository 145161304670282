import React, { useEffect, useState, useMemo, useRef } from "react";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import suitcase from "assets/images/suitcase.png";
import address_icon from "assets/images/location-marker.png";
import Slider from "react-slick";
import { PractitionerService } from "utils/PractitionerService";
// import { createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import { createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor } from "utils/Utils";
import { useHistory,Link } from "react-router-dom";
import { RenderAllPractitionersModal } from "./RenderAllPractitionersModal";
import { Col, Row } from "reactstrap";
import './style.css';

let settings = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
};

const PractitionersUI = (props) => {
  const history = useHistory();
  const [pannel_on, setPannel_on] = useState(true);
  // const { institution, setShowPractitionersContentOnTabScroll } = props;
  const { institution, getInstitutionProfile, themeDetails, previewFlag, alert, isEditable, isAdmin, winwidth } = props;
  const [practitioners, setPractitioners] = useState([]);
  const [isAllPractitionersOpen, setIsAllPractitionersOpen] = useState(false);
  const institutionID = institution.profileId;
  const [snum, setSnum] = useState(2);
  const [width, setWidth] = useState(0);
  const elm = useRef(null);

  const sectionColors = (sThemeColor) => {
    document.documentElement.style.setProperty('--section-bg', sThemeColor[0]);
    document.documentElement.style.setProperty('--section-bg-color', sThemeColor[3]);
    document.documentElement.style.setProperty('--section-headerbg-color', sThemeColor[4]);
    document.documentElement.style.setProperty('--section-headerfont-color', sThemeColor[5]);
    document.documentElement.style.setProperty('--section-font-color', sThemeColor[2]);
    document.documentElement.style.setProperty('--section-heading-color', sThemeColor[1]);
  }

  useEffect(() => {
    if (themeDetails.length > 0) {
      sectionColors(themeDetails)
    }
  }, [themeDetails])
  const toggleAllPractitioners = () =>
    setIsAllPractitionersOpen(!isAllPractitionersOpen);

  const allPractitionersWorkHere = () => {
    const impProps = {
      institution: props.institution,
      isAdmin: props.isAdmin,
      isEditable: props.isEditable,
      practitionerId: props.practitionerId,
      institutionGuid: props.institution?.guid,
    };
    history.push(`/institution/${institutionID}/view-all-practitioners`, {
      institution: props.institution,
      isAdmin: props.isAdmin,
      isEditable: props.isEditable,
      practitionerId: props.practitionerId,
      institutionGuid: props.institution?.guid,
    })
  }

  useEffect(() => {
    PractitionerService.getPractitionersAssociatedtoInstitutionWithPagination(
      institution?.guid
    ).then((response) => {
      const { status, data } = response;
      if (status === 200) {
        setPractitioners(data.contents);
      } else {
        console.log("Something went wrong");
      }
    });
  }, [institution?.guid]);

  // useEffect(()=>{
  //   if(practitioners.length > 0){
  //     setShowPractitionersContentOnTabScroll(true);
  //   }else{
  //     setShowPractitionersContentOnTabScroll(false);
  //   }
  // }, [practitioners])

  return (
    // practitioners.length > 0 ?
    <Row>
      <Col lg={12} className="text-center px-3">
        <div
          className={`card nexogic-card-color-header practitioners-slider-card m-v1 ${pannel_on ? "expanded" : ""
            } micro-section-card-vs1`}
          id="practitioners_pannel"
        >
          <div className="card-header section-header">
            <h2>Practitioners who work here</h2>
            <button
              className="collapse-btn"
              type="button"
              onClick={() => setPannel_on(!pannel_on)}
            >
              <svg width={17} height={10} viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path
                  d="M2.25 1.625L8.625 8L15 1.625"
                  stroke="black"
                  strokeWidth={3}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            {practitioners.length >= 10 && (
              <>
                <h3
                  id="nex-ip-practitioners-view-all-btn"
                  className="viewall-btn"
                  onClick={() => toggleAllPractitioners()}
                  // onClick={allPractitionersWorkHere}
                >
                  View All
                </h3>
                {institution && (
                  <RenderAllPractitionersModal
                    isOpen={isAllPractitionersOpen}
                    toggle={toggleAllPractitioners}
                    institutionGuid={institution?.guid}
                  />
                )}
              </>
            )}
          </div>
          <div className={`collapse-div`}>
            <div className={`card-body bg-color minh-50 slider-column-${winwidth} ${winwidth < 500 ? 'px-0' : 'px-3'}`} ref={elm}>
              {/* {snum === 2 &&  */}
              {winwidth > 500 ?
              <Slider {...settings}>
              {practitioners.map((practitioner, idx) => (
                  <div className="slider-item slider-item-aside py-3" style={{maxWidth:{winwidth}}} key={idx}>
                    <div className="slider-item-aside-inner bg-white">
                      <div className="card-img">
                        {practitioner?.avatarId ? (
                          <img
                            src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${practitioner?.profileGuid}/${practitioner?.avatarId}`}
                            alt="#"
                          />
                        ) : (
                          <img
                            src={createImageFromInitials(
                              50,
                              practitioner?.name.replace("Dr. ", ""),
                              getRandomColor(
                                encrypt(practitioner?.profileGuid).toString()
                              )
                            )}
                            alt="#"
                          />
                        )}
                      </div>
                      <div className="info">
                      {/* <p className="nex-text-xl text-truncate"> */}
                        <a
                          href={`../profile/${practitioner?.profileId}`}
                          target="_blank" rel="noreferrer"
                        >
                          <p className="ui-heading mb-0">{practitioner?.name}</p>
                        </a>
                      {/* </p> */}
                      <div className="nex-text bold nex-text-blue text-truncate mb-2">
                        {practitioner?.primarySpeciality}
                        {practitioner?.superSpeciality ? ', '+practitioner?.superSpeciality : ''}
                      </div>
                      <div className="nex-text text-truncate">
                          <span className="icon"><img src={suitcase} alt="" /></span>
                          {new Date().getFullYear() -
                            practitioner?.practicingSince >
                            1 ? (
                            <>
                              {" "}
                              {new Date().getFullYear() -
                                practitioner?.practicingSince}
                              {"+ years experience"}
                            </>
                          ) : (
                            "1 year experience"
                          )}
                      </div>
                      <p className="nex-text text-truncate text-black">
                        <span className="icon"><img src={address_icon} alt="" /></span>
                        {getFullAddressWithLocationCityAndState({ location: practitioner?.location ?? "", city: practitioner?.city ?? "", state: practitioner?.state ?? "" })}
                      </p>
                      </div>
                    </div>
                  </div>
              ))}
            </Slider>
                // <Slider slidesToShow={snum} {...settings}>
                //   {practitioners.map((practitioner, idx) => (
                //     <div className="slider-item" key={idx}>
                //       <div className="card-img">
                //         {practitioner?.avatarId ? (
                //           <img
                //             src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${practitioner?.profileGuid}/${practitioner?.avatarId}`}
                //             alt="#"
                //           />
                //         ) : (
                //           <img
                //             src={createImageFromInitials(
                //               50,
                //               practitioner?.name.replace("Dr. ", ""),
                //               getRandomColor(
                //                 encrypt(practitioner?.profileGuid).toString()
                //               )
                //             )}
                //             alt="#"
                //           />
                //         )}
                //       </div>
                //       {/* <h3 className="text-truncate fw-bold"> */}
                //       <Link to={`/profile/${practitioner?.profileId}`} target="_blank" className="text-decoration-none"><h3 className="text-truncate fw-bold mb-1">{practitioner?.name}</h3></Link>
                //       {/* </h3> */}
                //       <p className="card-text text-truncate m-0">
                //         {practitioner?.primarySpeciality} {(practitioner?.superSpeciality) ? ` | ${practitioner?.superSpeciality}` : ''}
                //       </p>
                //       <p className="card-text m-0">
                //         {/* <strong className="d-block text-truncate"> */}
                //         {new Date().getFullYear() -
                //           practitioner?.practicingSince >
                //           1 ? (
                //           <>
                //             {" "}
                //             {new Date().getFullYear() -
                //               practitioner?.practicingSince}
                //             {"+ years experience"}
                //           </>
                //         ) : (
                //           "1 year experience"
                //         )}
                //         {/* </strong> */}
                //       </p>
                //       <p className="card-text text-truncate m-0">
                //         {getFullAddressWithLocationCityAndState({ location: practitioner?.location ?? "", city: practitioner?.city ?? "", state: practitioner?.state ?? "" })}
                //       </p>
                //     </div>
                //   ))}
                // </Slider>
                :
                <>
                  <div className="nex-practitioners-list-view nexogic-custom-scroll">
                    {practitioners.map((practitioner, idx) => (
                      <div className="slider-item" key={idx}>
                        <div className="card-img">
                          {practitioner?.avatarId ? (
                            <img
                              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${practitioner?.profileGuid}/${practitioner?.avatarId}`}
                              alt="#"
                            />
                          ) : (
                            <img
                              src={createImageFromInitials(
                                50,
                                practitioner?.name.replace("Dr. ", ""),
                                getRandomColor(
                                  encrypt(practitioner?.profileGuid).toString()
                                )
                              )}
                              alt="#"
                            />
                          )}
                        </div>
                        <div className="info">
                          <Link to={`/profile/${practitioner?.profileId}`} target="_blank" className="text-decoration-none"><h3 className="text-truncate fw-bold mb-1">{practitioner?.name}</h3></Link>
                          <p className="card-text text-truncate m-0">
                            {practitioner?.primarySpeciality} {practitioner?.superSpeciality ? ` | ${practitioner?.superSpeciality}` : ''}
                          </p>
                          <p className="card-text m-0">
                            {/* <strong className="d-block text-truncate"> */}
                            {new Date().getFullYear() -
                              practitioner?.practicingSince >
                              1 ? (
                              <>
                                {" "}
                                {new Date().getFullYear() -
                                  practitioner?.practicingSince}
                                {"+ years experience"}
                              </>
                            ) : (
                              "1 year experience"
                            )}
                            {/* </strong> */}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              }
              {/** new code here */}
              {/* {snum === 1 && 
            <>            
              <div className="nex-practitioners-list-view nexogic-custom-scroll">
                {practitioners.map((practitioner, idx) => (
                  <div className="slider-item" key={idx}>
                    <div className="card-img">
                      {practitioner?.avatarId ? (
                        <img
                          src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${practitioner?.profileGuid}/${practitioner?.avatarId}`}
                          alt="#"
                        />
                      ) : (
                        <img
                          src={createImageFromInitials(
                            50,
                            practitioner?.name.replace("Dr. ", ""),
                            getRandomColor(
                              encrypt(practitioner?.profileGuid).toString()
                            )
                          )}
                          alt="#"
                        />
                      )}
                    </div>
                    <div className="info">
                    <h3 className="card-text h3 text-truncate fw-bold">
                    <Link to={`../profile/${practitioner?.profileId}`} target="_blank">
                      {practitioner?.name}
                    </Link>
                    </h3>
                    <p className="card-text text-truncate m-0">
                      {practitioner?.primarySpeciality} {practitioner?.superSpeciality ? ` | ${practitioner?.superSpeciality}`:''}
                    </p>
                    <p className="card-text m-0">
                        {new Date().getFullYear() -
                          practitioner?.practicingSince >
                          1 ? (
                          <>
                            {" "}
                            {new Date().getFullYear() -
                              practitioner?.practicingSince}
                            {"+ years experience"}
                          </>
                        ) : (
                          "1 year experience"
                        )}
                    </p>
                    
                    </div>
                  </div>
              ))}
              </div>
             
              </>
            } */}

            </div>
          </div>
        </div>
      </Col>
    </Row>
    // : ""
  );
};

export default PractitionersUI;
