import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import facebookLogo from "assets/images/social-facebook-icon.png";
//import pencilIcon from "assets/images/pencil_icon.png";
import editIcon from "assets/images/edit-blue-icon.png";
import facebookGreyLogo from "assets/images/social-facebook-grey.png";
import {
  profileAddEvent,
  updateProfileCompletness,
} from "redux-helpers/ActionCreator";
import instagramGreyLogo from "assets/images/hospital-insta-icon.png";
import instagramLogo from "assets/images/social-insta-icon.png";
import linkedinLogo from "assets/images/social-linkedin-icon.png";
import linkedinGreyLogo from "assets/images/social-linkedin-grey.png";
import twitterGreyLogo from "assets/images/social-twitter-grey.png";
import twitterLogo from "assets/images/social-twitter-icon.png";

import { UserStatusConstants } from "utils/Constants";
import RenderSocialHandlerModal from "./components/RenderSocialHandlerModal";

const SocialHandler = (props) => {
  const dispatch = useDispatch();
  const eventName = useSelector((state) => state.profileComplete.eventName);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { isLoggedinUser, isAdmin } = props;
  const { guid, socialContacts } = props.practitioner;
  const [isOpen, setModal] = useState(false);

  const getSocialName = (socialContacts) => {
    let linkedinHandle = socialContacts.find(
      (o) => o.type === "LINKEDIN"
    )?.value;
    let facebookHandle = socialContacts.find(
      (o) => o.type === "FACEBOOK"
    )?.value;
    let twitterHandle = socialContacts.find((o) => o.type === "TWITTER")?.value;
    let instaHandle = socialContacts.find((o) => o.type === "INSTAGRAM")?.value;
    return { facebookHandle, twitterHandle, linkedinHandle, instaHandle };
  };

  const initialValues = getSocialName(socialContacts);

  const toggle = () => setModal(!isOpen);
  const [socialHandlers, setSocialHandlers] = useState(initialValues);

  useEffect(() => {
    dispatch(
      updateProfileCompletness(
        12,
        !!socialHandlers.facebookHandle ||
          !!socialHandlers.twitterHandle ||
          !!socialHandlers.linkedinHandle ||
          !!socialHandlers.instaHandle
      )
    );
  }, [socialHandlers]);

  useEffect(() => {
    if (eventName === "ADD_SOCIAL") {
      toggle();
      dispatch(profileAddEvent(null));
    }
  }, [eventName]);

  const onChange = (values) => {
    setSocialHandlers(values);
    toggle();
  };

  return (
    <>
      <Card className="nexogic-social-card" id="socialSection">
        
        <CardBody>
        <h5 className="card-title pl-0 pt-0">Social Connect </h5>
          <div className="social-links">
          {socialHandlers.instaHandle ? (
                    <a
                      href={socialHandlers.instaHandle}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={instagramLogo} alt={"insta logo"} width="24" />
                    </a>
                  ) : (
                    <img
                      src={instagramLogo}
                      alt={"insta logo"}
                      className="inactive"
                    />
                  )}
                  {socialHandlers.linkedinHandle ? (
                    <a
                      href={socialHandlers.linkedinHandle}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={linkedinLogo}
                        alt={"linkedIn logo"}
                      />
                    </a>
                  ) : (
                    <img
                      src={linkedinLogo}
                      alt={"linkedIn logo"}
                      className="inactive"
                    />
                  )}
                  {socialHandlers.facebookHandle ? (
                    <a
                      href={socialHandlers.facebookHandle}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={facebookLogo} alt={"fb logo"}  />
                    </a>
                  ) : (
                    <img src={facebookLogo} alt={"fb logo"} className="inactive"  />
                  )}

{socialHandlers.twitterHandle ? (
                    <a
                      href={socialHandlers.twitterHandle}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={twitterLogo} alt={"twitter logo"}  />
                    </a>
                  ) : (
                    <img
                      src={twitterLogo}
                      alt={"twitter logo"}
                      className="inactive"
                    />
                  )}

{(isAdmin ||
              (isLoggedinUser &&
                currentUser.status === UserStatusConstants.APPROVED)) && (
              
                <Button
                  id="nex-dd-social-section-edit-btn"
                  color="link"
                  className="card-edit-btn-round"
                  onClick={toggle}
                >
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="13.25" cy="12.75" r="12.375" fill="white" stroke="#DBF4FB" stroke-width="0.75"/>
<path d="M16.0625 7.5L14.8438 8.71875L17.2812 11.1562L18.5 9.9375L16.0625 7.5ZM13.625 9.9375L8.75 14.8125V17.25H11.1875L16.0625 12.375L13.625 9.9375Z" fill="#46ABC9"/>
</svg>

                </Button>
              
            )}
          </div>
          





          {/** 
          <Row className="align-items-center">
            
            <Col>
              <h5 className="section-title  mb-3">Social</h5>
              <div className="mt-2 d-flex align-items-center">
                <div className="mr-3">
                  {socialHandlers.instaHandle ? (
                    <a
                      href={socialHandlers.instaHandle}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={instagramLogo} alt={"insta logo"} width="24" />
                    </a>
                  ) : (
                    <img
                      src={instagramGreyLogo}
                      alt={"insta logo"}
                      width="24"
                    />
                  )}
                </div>
                <div className="mr-3">
                  {socialHandlers.linkedinHandle ? (
                    <a
                      href={socialHandlers.linkedinHandle}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={linkedinLogo}
                        alt={"linkedIn logo"}
                        width="24"
                      />
                    </a>
                  ) : (
                    <img
                      src={linkedinGreyLogo}
                      alt={"linkedIn logo"}
                      className="social_image"
                    />
                  )}
                </div>
                <div className="mr-3">
                  {socialHandlers.facebookHandle ? (
                    <a
                      href={socialHandlers.facebookHandle}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={facebookLogo} alt={"fb logo"} width="24" />
                    </a>
                  ) : (
                    <img src={facebookGreyLogo} alt={"fb logo"} width="24" />
                  )}
                </div>
                <div className="mr-3">
                  {socialHandlers.twitterHandle ? (
                    <a
                      href={socialHandlers.twitterHandle}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={twitterLogo} alt={"twitter logo"} width="24" />
                    </a>
                  ) : (
                    <img
                      src={twitterGreyLogo}
                      alt={"twitter logo"}
                      width="24"
                    />
                  )}
                </div>
              </div>
            </Col>
            {(isAdmin ||
              (isLoggedinUser &&
                currentUser.status === UserStatusConstants.APPROVED)) && (
              <Col xs="auto">
                <Button
                  color="link"
                  className="round-icon-btn rounded-circle"
                  onClick={toggle}
                >
                  <img src={pencilIcon} alt="#" />
                </Button>
              </Col>
            )}
          </Row>
*/}
          <RenderSocialHandlerModal
            isOpen={isOpen}
            toggle={toggle}
            data={socialHandlers}
            practitionerId={guid}
            onSave={onChange}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default SocialHandler;
