import React, { useState } from "react";
import { Field, FieldArray, useFormikContext } from "formik";
import { FormGroup, Card, Button, FormFeedback, Row, Col } from "reactstrap";
import ReactQuill from "react-quill";
import AlertDialog from "common/AlertDialog";
import { withAlert } from "react-alert";
import wordFileIcon from "assets/images/svgs/word-file-icon.svg";
import pdfFileIcon from "assets/images/svgs/pdf-file-icon.svg";
import "../apply-jobs.css";
import { PractitionerService } from "utils/PractitionerService";
import { FAILTOSAVERECOED } from "utils/Constants";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import { showSuccessMessage } from "common/AlertContainer";

const Resume = ({
  setIsResumePresent,
  practitionerResumes,
  alert,
  practitioner,
  getProfileResumes,
  jobDet,
  setResumeValidation,
}) => {
  const { values, setFieldValue, errors, touched } = useFormikContext();
  const [fileType, setFileType] = useState();
  const [fileNm, setFileNm] = useState();
  const [selectFlag, setSelectFlag] = useState({});
  const [isOpenAlert, setAlertModal] = useState(false);
  const [resumeToDelete, setResumeToDelete] = useState(null);

  const removeSVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="currentColor"
      className="bi bi-trash"
      viewBox="0 0 16 16"
    >
      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
      <path
        fillRule="evenodd"
        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
      />
    </svg>
  );

  const handleFileUpload = (e) => {
    const tempFile = e.target.files[0];
    if (tempFile) {
      if (tempFile.type === "application/msword" || tempFile.type === "application/pdf" || tempFile.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        handleChange(tempFile);
        setIsResumePresent(true);
        setResumeValidation(true);
      } else {
        setFieldValue('hiddenFile', 'Please upload only doc/docx/pdf type resume');
        setResumeValidation(false);
      }
    }
  };

  const handleChange = (fileDetails) => {
    setFieldValue('resumeDetails', fileDetails);
    setFieldValue('hiddenFile', fileDetails?.name?.split('.').pop());
    setFieldValue('cvID', "");
    const ext = fileDetails?.name?.split('.').pop();
    const fileExt = ext?.toUpperCase();
    setFileType(fileExt);
    setFileNm(fileDetails?.name);
    setIsResumePresent(true);
    setResumeValidation(true);
  };

  const onClose = () => {
    setFieldValue('resumeDetails', null);
    setIsResumePresent(false);
    setResumeValidation(false);
    if (selectFlag !== null) selectFlag?.classList.remove('active');
  };

  const onDelete = () => {
    toggleAlert();
  };

  const handleDeleteButtonClick = (id) => {
    setResumeToDelete(id);
    toggleAlert();
  };

  const toggleAlert = () => {
    setAlertModal(!isOpenAlert);
  };

  const handleClickOnYes = () => {
    PractitionerService.deletePractitionerResume(practitioner.guid, resumeToDelete)
      .then((response) => {
        const { status } = response;
        if (status === 204) {
          showSuccessMessage("Resume Removed Successfully...");
          setFieldValue('resumeDetails', null);
          setFileNm(null);
          setIsResumePresent(false);
          setResumeValidation(false);
        } else {
          alert.error(FAILTOSAVERECOED);
        }
      });
    toggleAlert();
  };

  const selectResume = (resume, evt) => {
    setSelectFlag(evt.target);
    setFieldValue('resumeDetails', resume);
    setFieldValue('hiddenFile', resume?.fileName?.split('.').pop());
    setFieldValue('cvID', resume?.id);
    const ext = resume?.fileName?.split('.').pop();
    const fileExt = ext?.toUpperCase();
    setFileType(fileExt);
    setFileNm(resume?.fileName);
    setIsResumePresent(true);
    setResumeValidation(true);
  };

  const handleClickOnResume = (resume, evt) => {
    if (values?.resumeDetails?.id === resume?.id) {
      const header = document.getElementById("docBtn");
      const btns = header.getElementsByClassName("btnResume");
      for (let i = 0; i < btns.length; i++) {
        if (evt.target.id === btns[i].id) {
          evt.target.classList.remove("active");
        }
      }
      setFieldValue('resumeDetails', null);
      setIsResumePresent(false);
    } else {
      selectResume(resume, evt);
    }
  };

  const handleCoverLetterChange = (value) => {
    setFieldValue('coverLetter', value);
  };

  return (
    <>
      <div className="nexogic-job-popup nex-job-resume-w">
        <div className="nex-document-lists nex-resume-lists">
          {practitionerResumes?.length > 0 ? (
            <div className="d-flex flex-wrap pb-3" id="docBtn">
              {practitionerResumes.map((resume, index) => (
                <div className="nex-resume-btn-item" key={index}>
                  <span name={`btn${index}`} key={index} id={`btn${index}`} className="" onClick={(evt) => handleClickOnResume(resume, evt)}>
                    <div className={`nex-document-item ${values?.resumeDetails?.id === resume?.id ? "activebtn" : ""}`} style={{ pointerEvents: "none" }}>
                      <div className="card-thumb">{resume.mimeType === 'application/pdf' ? <img src={pdfFileIcon} alt="PDF" /> : <img src={wordFileIcon} alt="Word" />}</div>
                      <div className="text-truncate card-text filename">{resume.fileName}</div>
                      <div className="nex-text bold nex-text-light-blue ml-auto nex-selected-txt">{values?.resumeDetails?.id === resume?.id ? "Selected" : "Select"}</div>
                    </div>
                  </span>
                  <div className="nex-del">
                    <svg width={11} height={14} viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }} onClick={() => handleDeleteButtonClick(resume?.id)}>
                      <path d="M1 5.17969L1.53146 9.10872C1.75473 10.7593 1.86636 11.5846 2.43166 12.0786C2.99696 12.5725 3.83131 12.5725 5.5 12.5725V12.5725C7.16869 12.5725 8.00303 12.5725 8.56833 12.0786C9.13363 11.5846 9.24527 10.7593 9.46853 9.10872L9.99999 5.17969" stroke="#FF9191" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M3.57031 2.92857V2.92857C3.57031 1.86345 4.43376 1 5.49888 1V1C6.564 1 7.42745 1.86345 7.42745 2.92857V2.92857" stroke="#FF9191" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M10 2.92969L1 2.92969" stroke="#FF9191" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M3.89062 5.5L4.21205 10.3214" stroke="#FF9191" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M7.11049 5.5L6.78906 10.3214" stroke="#FF9191" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                </div>
              ))}
            </div>
          ) : ""}
          <div className="nex-upload-card v2">
            {(values.resumeDetails === null) ? (
              <>
                <div className="nex-file-upload-w">
                  <div className="nex-file-upload-link-w">
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M4 11C4.55228 11 5 11.4477 5 12V20C5 20.2652 5.10536 20.5196 5.29289 20.7071C5.48043 20.8946 5.73478 21 6 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19 20V12C19 11.4477 19.4477 11 20 11C20.5523 11 21 11.4477 21 12V20C21 20.7957 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7957 23 18 23H6C5.20435 23 4.44129 22.6839 3.87868 22.1213C3.31607 21.5587 3 20.7956 3 20V12C3 11.4477 3.44772 11 4 11Z" fill="#46ABC9" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M11.2929 1.29289C11.6834 0.902369 12.3166 0.902369 12.7071 1.29289L16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711C16.3166 7.09763 15.6834 7.09763 15.2929 6.70711L12 3.41421L8.70711 6.70711C8.31658 7.09763 7.68342 7.09763 7.29289 6.70711C6.90237 6.31658 6.90237 5.68342 7.29289 5.29289L11.2929 1.29289Z" fill="#46ABC9" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M12 1C12.5523 1 13 1.44772 13 2V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V2C11 1.44772 11.4477 1 12 1Z" fill="#46ABC9" />
                    </svg> Upload Resume
                    <label htmlFor="resume_file" className="nex-file-upload-label"></label>
                    <Field type="file" name="file" id="resume_file" className="nex-file-upload-inp" onChange={handleFileUpload} accept=".pdf,.doc,.docx" />
                  </div>
                  <p className="nex-file-upload-info">
                    Please upload resume in “pdf”, “doc”, “docx” type
                  </p>
                  <Field type="hidden" className="form-control" name="hiddenFile" value="" id="resume_hiddenFile" />
                </div>
              </>
            ) : (
              <div>
                {(values.cvID === "" || values.cvID === undefined) ? (
                  <>
                    <div className="nex-resume-btn-item">
                      <Button name="btnResumeup" id="btnResumeup" className="btnResume active">
                        <div className="nex-document-item" style={{ pointerEvents: "none" }}>
                          <div className="card-thumb">{values?.resumeDetails?.type === 'application/pdf' ? <img src={pdfFileIcon} alt="PDF" /> : <img src={wordFileIcon} alt="Word" />}</div>
                          <div className="text-truncate card-text filename">{values?.resumeDetails?.name}</div>
                          <div className="nex-text bold nex-text-light-blue ml-auto nex-selected-txt">Selected</div>
                        </div>
                      </Button>
                      <span id="resume_btn_closefile" className="close-btn" onClick={onClose}>x</span>
                    </div>
                  </>
                ) : ("")}
              </div>
            )}
          </div>
          {touched.hiddenFile && errors.hiddenFile && (
            <FormFeedback className="d-block mb-3 text-center w-100" invalid={!!errors.hiddenFile}>
              {errors.hiddenFile}
            </FormFeedback>
          )}
          <div className="coverletter-wrap">
            <h4 className="nex-model-sub-title">Cover Letter</h4>
            <div className="covUpload">
              <FormGroup>
                <ReactQuill
                  onChange={handleCoverLetterChange}
                  value={values.coverLetter}
                  name="coverLetter"
                  className="coverletter-quill"
                />
              </FormGroup>
            </div>
          </div>
          <Row>
            <Col lg={12} className="d-flex">
              <FieldArray name="answers">
                {() => (
                  <div className="contact-card border-0 p-0">
                    {jobDet?.screeningQuestions !== null && jobDet?.screeningQuestions?.length > 0 && (
                      <>
                        <h2 className="nex-model-sub-title">Screening Questions</h2>
                        <fieldset className="u-flsd-wpr mt-1 mb-4">
                          <div className="prev-address">
                            {jobDet?.screeningQuestions?.map((squestion, index) => (
                              <FormGroup key={index}>
                                <p className="card-text mb-2">{squestion.question}</p>
                                {(() => {
                                  if (squestion?.renderType === "TEXT") {
                                    return (
                                      <Field
                                        id={`answers[${index}].answer`}
                                        name={`answers[${index}].answer`}
                                        type="text"
                                        component={FormikInputField}
                                        placeholder="Type your answer"
                                      />
                                    );
                                  } else if (squestion?.renderType === "NUMBER") {
                                    return (
                                      <Field
                                        id={`answers[${index}].answer`}
                                        name={`answers[${index}].answer`}
                                        type="number"
                                        className="form-control"
                                        component={FormikInputField}
                                        min="0"
                                        placeholder="Enter a number"
                                      />
                                    );
                                  } else {
                                    return (
                                      <Field
                                        id={`answers[${index}].answer`}
                                        name={`answers[${index}].answer`}
                                        type="select"
                                        component={FormikSelectField}
                                        inputprops={{
                                          name: `answers[${index}].answer`,
                                          defaultOption: "Select",
                                          options: squestion.options,
                                        }}
                                      />
                                    );
                                  }
                                })()}
                              </FormGroup>
                            ))}
                          </div>
                        </fieldset>
                      </>
                    )}
                  </div>
                )}
              </FieldArray>
            </Col>
          </Row>
        </div>
      </div >
      <AlertDialog
        bodyText={"Are you sure to delete this Resume?"}
        toggle={toggleAlert}
        isOpen={isOpenAlert}
        handleClickOnYes={handleClickOnYes}
        yesLabel={"Yes"}
        noLabel={"Cancel"}
      />
    </>
  );
};

export default withAlert()(Resume);