import API_Factory from "./API_Factory";
import API_New from "./API_New";

const getConnectionAll = (guid) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/${guid}/connections`,
    ignoreToken: false,
  });


const getConnectionSent = (pId, status = "ACCEPTED") => {
  return new Promise((resolve, reject) => {
    const url = `practitioner-service/practitioner/${pId}/connections-sent`;
    try {
      let response = API_New.get(url, { params: { status } });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getConnectionReceived = (pId, status = "PENDING") => {
  return new Promise((resolve, reject) => {
    const url = `practitioner-service/practitioner/${pId}/connections-received`;
    try {
      let response = API_New.get(url, { params: { status } });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getConnectionById = (pId, cId) => {
  return new Promise((resolve, reject) => {
    const url = `practitioner-service/practitioner/${pId}/connection/${cId}`;
    try {
      let response = API_New.get(url);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const addConnection = (pId, payload) => {
  return new Promise((resolve, reject) => {
    const url = `practitioner-service/practitioner/${pId}/connection`;
    try {
      let response = API_New.post(url, payload);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const updateConnection = (pId, cId, status) => {
  return new Promise((resolve, reject) => {
    const url = `practitioner-service/practitioner/${pId}/connection/${cId}?status=${status}`;
    try {
      let response = API_New.put(url);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const deleteConnection = (pId, cId) => {
  return new Promise((resolve, reject) => {
    const url = `practitioner-service/practitioner/${pId}/connection/${cId}`;
    try {
      let response = API_New.delete(url);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getConnectionByPractitionerId = (pId, cpId) => {
  const url = `practitioner-service/practitioner/${pId}/connected/${cpId}`;
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.get(url);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getConnectionsCount = (pId) => {
  const url = `practitioner-service/practitioner/${pId}/connections-count`;
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.get(url);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getConnectionSummary = (pId) => {
  const url = `practitioner-service/practitioner/${pId}/connections-summary`;
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.get(url);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getSuggestions = (pId, endPoint) => {
  const url = `practitioner-service/practitioner/${pId}/${endPoint}`;
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.get(url);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getMyPatients = (pId) => {
  const url = `practitioner-service/practitioner/${pId}/patients`;
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.get(url);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getAlumnisByPractionerId = (pId) => {
  const url = `practitioner-service/practitioner/${pId}/alumni`;
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.get(url);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getColleaguesByPractionerId = (pId) => {
  const url = `practitioner-service/practitioner/${pId}/colleagues`;
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.get(url);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getGroups = (guid, role, pageNo = 0) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/${guid}/groups?role=${role}&cp=${pageNo}&nr=10`,
    ignoreToken: false,
  });

const addGroup = (payload) =>
  API_Factory({
    method: "POST",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/groups`,
    data: payload,
    ignoreToken: false,
  });

const updateGroup = (groupID, payload) =>
  API_Factory({
    method: "PUT",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/groups/${groupID}`,
    data: payload,
    ignoreToken: false,
  });

const getGroupByID = (groupID) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/groups/${groupID}`,
    ignoreToken: false,
  });

const uploadGroupImage = (file, group_guid, type) => {
  const formData = new FormData();
  formData.append("image", file);
  formData.append("source", type);
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/groups/${group_guid}/profile-image`,
        method: "PUT",
        data: formData,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getGroupMembers = (groupID, status = "ACTIVE", role = "MEMBER", cp = 0) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/groups/${groupID}/members?status=${status}&role=${role}&cp=${cp}`,
    ignoreToken: false,
  });

const hasMembership = (userID, groupID) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/${userID}/groups/${groupID}`,
    ignoreToken: false,
  });

const leaveTheGroup = (userID, groupID) =>
  API_Factory({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/${userID}/groups/${groupID}/leave`,
    ignoreToken: false,
  });

const joinTheGroup = (userID, groupID) =>
  API_Factory({
    method: "POST",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/${userID}/groups/${groupID}/join`,
    ignoreToken: false,
  });

const getGroupMembersipRequests = (requestType = "RECEIVED", pageNo = 0) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/groups/members/requests?requestType=${requestType}&cp=${pageNo}`,
    ignoreToken: false,
  });

const actOnGroupMembershipRequest = (payload, groupID, memberId) =>
  API_Factory({
    method: "PUT",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/groups/${groupID}/members/${memberId}`,
    data: payload,
    ignoreToken: false,
  });

const deleteGroupImage = (group_guid, type) => {
  const formData = new FormData();
  formData.append("source", type);
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/groups/${group_guid}/profile-image`,
        method: "DELETE",
        data: formData,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const removeGroupMember = (groupId, memberId) =>
  API_Factory({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/groups/${groupId}/members/${memberId}`,
    ignoreToken: false,
  });

const deleteTheGroup = (groupId) =>
  API_Factory({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/groups/${groupId}`,
    ignoreToken: false,
  });

const getPagesIFollow = (practitionerId, followType = "PRACTITIONER", pageNo) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_CONNECTION_SERVICE}/followings/${practitionerId}?ft=${followType}&cp=${pageNo}&nr=10`,
    ignoreToken: false,
  });

export const ConnectionServices = {
  getConnectionAll,
  getConnectionSent,
  getConnectionReceived,
  getConnectionById,
  addConnection,
  updateConnection,
  deleteConnection,
  getConnectionByPractitionerId,
  getConnectionsCount,
  getConnectionSummary,
  getSuggestions,
  getMyPatients,
  getAlumnisByPractionerId,
  getColleaguesByPractionerId,
  addGroup,
  updateGroup,
  getGroups,
  getGroupByID,
  uploadGroupImage,
  getGroupMembers,
  hasMembership,
  leaveTheGroup,
  joinTheGroup,
  getGroupMembersipRequests,
  actOnGroupMembershipRequest,
  deleteGroupImage,
  removeGroupMember,
  deleteTheGroup,
  getPagesIFollow
};
