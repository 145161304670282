import React from 'react'
import ImgSec from './images/img1.jpg'
import './css/style.css'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'

const Section3 = ()=> {
  return (
    <Card className="micro-section-card-v3 ">
            <CardHeader className='main-header'>Section Three Heading</CardHeader>
            <CardBody>
            <CardHeader className='card-header-sec'>
                <Row>
                    <Col lg={1} >
                        <img src={ImgSec} alt="" width="100%" className="card-img-brand rounded-circle" />
                    </Col>
                    <div lg={11}>
                        <h2 className="card-title">Lorem ipsum dolor sit amet.</h2>
                    </div>
                </Row>
            </CardHeader>
            <Row>
                <Col lg={6} className="card-content">
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Expedita rerum illo ducimus, quasi temporibus modi hic nobis dolore aperiam numquam ea quam adipisci debitis placeat vitae. Ea architecto exercitationem harum ratione voluptas </p>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Expedita rerum illo ducimus, quasi temporibus modi hic nobis dolore aperiam numquam ea quam adipisci debitis placeat vitae. Ea architecto exercitationem harum ratione voluptas </p>
                </Col>
                <Col lg={6} className="card-content">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse odit vero quis. Eaque mollitia dolore tempora ratione nesciunt error, rem non excepturi ad ullam corrupti natus id. Alias odit eos dignissimos nihil numquam ad consequuntur!</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse odit vero quis. Eaque mollitia dolore tempora ratione nesciunt error, rem non excepturi ad ullam corrupti natus id. Alias odit eos dignissimos nihil numquam ad consequuntur!</p>
                </Col>
            </Row>
            </CardBody>
    </Card>
  )
}
export default Section3;
