import React from "react";
import { useState } from "react";
import { Card, Nav, NavItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, } from "reactstrap";
import { NavLink, useLocation } from "react-router-dom";
import ThemeIcon from 'assets/images/theme-icon.png'
import HeaderIcon from 'assets/images/header-icon.png'
import SectionIcon from 'assets/images/sections-icon.png'
import SliderIcon from 'assets/images/slider-icon.png'

const UISidebar = (props) => {
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  return (
    <Card className="">
      <div className="microsite-side">
        <Nav vertical>
          <NavItem active={location.pathname === "/ui-dashboard/themes" ? true : false} >
            <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/themes" >
              <img src={ThemeIcon} /> <span>Themes</span>
            </NavLink>
            <Nav vertical>
              <NavItem active={location.pathname === "/ui-dashboard/themes/theme1" ? true : false}>
                <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/themes/theme1">
                  <img src={ThemeIcon} /> <span>Theme One</span>
                </NavLink>
              </NavItem>
              <NavItem active={location.pathname === "/ui-dashboard/themes/theme2" ? true : false}>
                <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/themes/theme2">
                  <img src={ThemeIcon} /> <span>Theme Two</span>
                </NavLink>
              </NavItem>
              <NavItem active={location.pathname === "/ui-dashboard/themes/theme3" ? true : false}>
                <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/themes/theme3">
                  <img src={ThemeIcon} /> <span>Theme Three</span>
                </NavLink>
              </NavItem>
            </Nav>
          </NavItem>
          <NavItem active={location.pathname === "/ui-dashboard/headers" ? true : false}>
            <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/headers">
              <img src={HeaderIcon} /> <span>Headers</span>
            </NavLink>
            <Nav vertical>
              <NavItem active={location.pathname === "/ui-dashboard/headers/header1" ? true : false}>
                <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/headers/header1">
                  <img src={HeaderIcon} /> <span>Header One</span>
                </NavLink>
              </NavItem>
              <NavItem active={location.pathname === "/ui-dashboard/headers/header2" ? true : false}>
                <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/headers/header2">
                  <img src={HeaderIcon} /> <span>Header Two</span>
                </NavLink>
              </NavItem>
            </Nav>
          </NavItem>

          <NavItem active={location.pathname === "/ui-dashboard/headings" ? true : false}>
            <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/headings">
              <img src={HeaderIcon} /> <span>Heading</span>
            </NavLink>
            <Nav vertical>
              <NavItem active={location.pathname === "/ui-dashboard/headings/heading1" ? true : false}>
                <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/headings/heading1">
                  <img src={HeaderIcon} /> <span>Heading One</span>
                </NavLink>
              </NavItem>
              <NavItem active={location.pathname === "/ui-dashboard/headings/heading2" ? true : false}>
                <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/headings/heading2">
                  <img src={HeaderIcon} /> <span>Heading Two</span>
                </NavLink>
              </NavItem>
            </Nav>
          </NavItem>


          <NavItem active={location.pathname === "/ui-dashboard/sections" ? true : false}>
            <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/sections">
              <img src={SectionIcon} /> <span>Sections</span>
            </NavLink>
            <Nav vertical>
              <NavItem active={location.pathname === "/ui-dashboard/sections/section1" ? true : false}>
                <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/sections/section1">
                  <img src={SectionIcon} /> <span>Section 1</span>
                </NavLink>
              </NavItem>
              <NavItem active={location.pathname === "/ui-dashboard/sections/section2" ? true : false}>
                <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/sections/section2">
                  <img src={SectionIcon} /> <span>Section 2</span>
                </NavLink>
              </NavItem>
              <NavItem active={location.pathname === "/ui-dashboard/sections/section3" ? true : false}>
                <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/Sections/Section3">
                  <img src={SectionIcon} /> <span>Section 3</span>
                </NavLink>
              </NavItem>
              <NavItem active={location.pathname === "/ui-dashboard/sections/section4" ? true : false}>
                <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/Sections/Section4">
                  <img src={SectionIcon} /> <span>Section 4</span>
                </NavLink>
              </NavItem>
              <NavItem active={location.pathname === "/ui-dashboard/sections/section5" ? true : false}>
                <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/Sections/Section5">
                  <img src={SectionIcon} /> <span>Section 5</span>
                </NavLink>
              </NavItem>
              <NavItem active={location.pathname === "/ui-dashboard/sections/section6" ? true : false}>
                <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/Sections/Section6">
                  <img src={SectionIcon} /> <span>Section 6</span>
                </NavLink>
              </NavItem>
            </Nav>
          </NavItem>
          <NavItem active={location.pathname === "/ui-dashboard/sliders" ? true : false} >
            <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/sliders">
              <img src={SliderIcon} /> <span>Sliders</span>
            </NavLink>
            <Nav vertical>
              <NavItem active={location.pathname === "/ui-dashboard/sliders/slider1" ? true : false} >
                <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/sliders/slider1">
                  <img src={SliderIcon} /> <span>Slider One</span>
                </NavLink>
              </NavItem>
              <NavItem active={location.pathname === "/ui-dashboard/sliders/slider2" ? true : false} >
                <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/sliders/slider2">
                  <img src={SliderIcon} /> <span>Slider Two</span>
                </NavLink>
              </NavItem>
              <NavItem active={location.pathname === "/ui-dashboard/sliders/slider3" ? true : false} >
                <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/sliders/slider3">
                  <img src={SliderIcon} /> <span>Slider Three</span>
                </NavLink>
              </NavItem>
            </Nav>
          </NavItem>

          <NavItem active={location.pathname === "/ui-dashboard/widgets" ? true : false}>
            <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/widgets">
              <img src={HeaderIcon} /> <span>Widgets</span>
            </NavLink>
            <Nav vertical>
              <NavItem active={location.pathname === "/ui-dashboard/widgets/widget1" ? true : false}>
                <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/widgets/widget1">
                  <img src={HeaderIcon} /> <span>Widget One</span>
                </NavLink>
              </NavItem>
              <NavItem active={location.pathname === "/ui-dashboard/widgets/widget2" ? true : false}>
                <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/widgets/widget2">
                  <img src={HeaderIcon} /> <span>Widget Two</span>
                </NavLink>
              </NavItem>
              <NavItem active={location.pathname === "/ui-dashboard/widgets/widget3" ? true : false}>
                <NavLink className="nav-link d-flex align-item-center" to="/ui-dashboard/widgets/widget3">
                  <img src={HeaderIcon} /> <span>Widget Three</span>
                </NavLink>
              </NavItem>
            </Nav>
          </NavItem>
        </Nav>
      </div>
    </Card>
  );
};
export default UISidebar;
