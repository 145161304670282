import React from 'react'
import { useState } from 'react';
import { Button, Card, CardBody, CardText, CardTitle, Col, Collapse, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import Slider from "react-slick";
import Close from "assets/images/close.png";

/* to dos 
    1. tabs dynamic position 
    2. image content
*/
export default function OurLocations() {
  const [activeTab, setActiveTab] = useState('1');
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
   
    ],
  }

  return (
    
    <Card className='nex-our-locations'>
      <CardBody>
        <div className="head-info">
         <h2 className='card-title text-center'>Our Locations</h2>
         <p className='card-text text-center'>CARE Hospitals, a part of the Evercare Group, brings international quality healthcare to serve patients across the world. With 17 healthcare facilities serving 8 cities across 6 states in India we are counted among the top 5 pan-Indian hospital chains.</p>
         </div>
        {/* tab section  */}
        <div className="nex-tab-links">

            <Nav tabs>
            <NavItem>
              <NavLink
                className={`${activeTab == 1 ? 'active': ''}`}
                onClick={() => { setActiveTab('1'); }}
              >
                Tab 1
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab == 2 ? 'active': ''}`}
                onClick={() => { setActiveTab('2'); }}
              >
                Tab 2
              </NavLink>
            </NavItem>
          </Nav>


        </div>
        <div className="nex-tab-content">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                
                  <div className='nex-tab-slider need-margin'>
                      <Slider {...settings}>
                          <div className="slider-item">
                              <img src="https://picsum.photos/id/202/600/400" alt="" className='slider-img' />
                              <div className="caption">
                                  <h3 className="card-title">Lorem ipsum dolor sit amet.</h3>
                                  <p className='card-text'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. At voluptate modi tenetur, doloremque explicabo corporis.</p>
                                  <p><a href="" className='nexogic_primary_button btn btn-link'>Know More</a></p>
                              </div>
                          </div>
                          <div className="slider-item">
                              <img src="https://picsum.photos/id/203/600/400" alt="" className='slider-img' />
                              <div className="caption">
                                  <h3 className="card-title">Lorem ipsum dolor sit amet.</h3>
                                  <p className='card-text'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. At voluptate modi tenetur, doloremque explicabo corporis.</p>
                                  <p><a href="" className='nexogic_primary_button btn btn-link'>Know More</a></p>
                              </div>
                          </div>
                          <div className="slider-item">
                              <img src="https://picsum.photos/id/204/600/400" alt="" className='slider-img' />
                              <div className="caption">
                                  <h3 className="card-title">Lorem ipsum dolor sit amet.</h3>
                                  <p className='card-text'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. At voluptate modi tenetur, doloremque explicabo corporis.</p>
                                  <p><a href="" className='nexogic_primary_button btn btn-link'>Know More</a></p>
                              </div>
                          </div>
                          <div className="slider-item">
                              <img src="https://picsum.photos/id/209/600/400" alt="" className='slider-img' />
                              <div className="caption">
                                  <h3 className="card-title">Lorem ipsum dolor sit amet.</h3>
                                  <p className='card-text'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. At voluptate modi tenetur, doloremque explicabo corporis.</p>
                                  <p><a href="" className='nexogic_primary_button btn btn-link'>Know More</a></p>
                              </div>
                          </div>
                      </Slider>
                  </div>
                
              </TabPane>
              <TabPane tabId="2">
                
                  <div  className='nex-tab-slider need-margin'>
                  <Slider {...settings}>
                          <div className="slider-item">
                              <img src="https://picsum.photos/id/204/600/400" alt="" className='slider-img' />
                              <div className="caption">
                                  <h3 className="card-title">Lorem ipsum dolor sit amet.</h3>
                                  <p className='card-text'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. At voluptate modi tenetur, doloremque explicabo corporis.</p>
                                  <p><a href="" className='nexogic_primary_button btn btn-link'>Know More</a></p>
                              </div>
                          </div>
                          <div className="slider-item">
                              <img src="https://picsum.photos/id/202/600/400" alt="" className='slider-img' />
                              <div className="caption">
                                  <h3 className="card-title">Lorem ipsum dolor sit amet.</h3>
                                  <p className='card-text'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. At voluptate modi tenetur, doloremque explicabo corporis.</p>
                                  <p><a href="" className='nexogic_primary_button btn btn-link'>Know More</a></p>
                              </div>
                          </div>
                          <div className="slider-item">
                              <img src="https://picsum.photos/id/203/600/400" alt="" className='slider-img' />
                              <div className="caption">
                                  <h3 className="card-title">Lorem ipsum dolor sit amet.</h3>
                                  <p className='card-text'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. At voluptate modi tenetur, doloremque explicabo corporis.</p>
                                  <p><a href="" className='nexogic_primary_button btn btn-link'>Know More</a></p>
                              </div>
                          </div>
                          
                          <div className="slider-item">
                              <img src="https://picsum.photos/id/209/600/400" alt="" className='slider-img' />
                              <div className="caption">
                                  <h3 className="card-title">Lorem ipsum dolor sit amet.</h3>
                                  <p className='card-text'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. At voluptate modi tenetur, doloremque explicabo corporis.</p>
                                  <p><a href="" className='nexogic_primary_button btn btn-link'>Know More</a></p>
                              </div>
                          </div>
                      </Slider>
                  </div>
                 
                
              </TabPane>
            </TabContent>

        </div>
      </CardBody>
    </Card>
    
  )
}
