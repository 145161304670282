import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { withAlert } from "react-alert";
import SpecialitiesForm from "./SpecialitiesForm";
import SpecialitiesFormBulkEdit from "./SpecialitiesFormBulkEdit";

const SpecialitiesModal = (props) => {
  const {
    specialities,
    isOpen,
    toggle,
    practitionerId,
    setPractitioner,
    practitioner,
    isAdmin,
    institution,
    getInstitutionProfile,
  } = props;
  const [addInBulk, setAddInBulk] = useState(false);
  const handleClick = () => {
    setAddInBulk(!addInBulk);
  };
  return (
    <div className="nexogix-hospital-specialty">
      <Modal
        centered={true}
        scrollable={true}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
        id="profile-modal"
        className="skills-modal skills-expertise-modal"
      >
        <ModalHeader toggle={toggle} className="nex-institutions-modal-header">
        <div className='modal-filter-header nex-text-xl'>
          {`Specialities`}
          <span className="ml-auto">
            <label className='mb-0 mr-2'>Add in Bulk</label>
            <input className="add_in_bulk mr-3" id="nex-specialities-add-in-bulk" type="checkbox" checked={addInBulk} onClick={() => handleClick()} />
          </span>
          </div>
        </ModalHeader>
        <ModalBody>
          {addInBulk ?
            <SpecialitiesFormBulkEdit
              specialities={specialities}
              practitionerId={practitionerId}
              toggle={toggle}
              institution={institution}
              getInstitutionProfile={getInstitutionProfile}
              setPractitioner={setPractitioner}
              practitioner={practitioner}
              isAdmin={isAdmin}
            />
            :
            <SpecialitiesForm
              specialities={specialities}
              practitionerId={practitionerId}
              toggle={toggle}
              institution={institution}
              getInstitutionProfile={getInstitutionProfile}
              setPractitioner={setPractitioner}
              practitioner={practitioner}
              isAdmin={isAdmin}
            />
          }
        </ModalBody>
      </Modal>
    </div>
  );
};

export default withAlert()(SpecialitiesModal);
