import React from 'react';
import { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import phone_icon from "assets/svgIcon/phone-icon.svg";
import email_icon from "assets/svgIcon/email-icon.svg";
import { capitalizeFirstLetter } from "utils/Utils";

// const status = [
//     {
//         "type": "BILLING",
//         "value": "+91 9163955863"
//     },
//     {
//         "type": "BILLING",
//         "value": "+91 2223955111"
//     },
//     {
//         "type": "BILLING",
//         "value": "+91 9993955111"
//     },
//     {
//         "type": "APPOINTMENT",
//         "value": "+91 9993955000"
//     },
//     {
//         "type": "APPOINTMENT",
//         "value": "+91 9993955999"
//     },
//     {
//         "type": "EMERGENCY",
//         "value": "+91 8989898984"
//     },
//     {
//         "type": "ENQUIRY",
//         "value": "+91 6395586202"
//     },
//     {
//         "type": "EMERGENCY",
//         "value": "+91 9090909090"
//     }
// ]

const ContactsList = (props) => {
    const [isOpenA, setIsOpenA] = useState(false);
    const [isOpenO, setIsOpenO] = useState(false);
    const [isOpenB, setIsOpenB] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenE, setIsOpenE] = useState(false);
    const { phoneContacts, emailContacts } = props;

    const toggleDropdown = (isFlag) => {
        if (isFlag === 'EM')
            setIsOpen(!isOpen);
        if (isFlag === 'E')
            setIsOpenE(!isOpenE);
        if (isFlag === 'A')
            setIsOpenA(!isOpenA);
        if (isFlag === 'B')
            setIsOpenB(!isOpenB);
        if (isFlag === 'O')
            setIsOpenO(!isOpenO);
    };

    const handleCallPhonenumber = (phnumber) => {
        const telUri = `tel:${phnumber}`;
        window.open(telUri, '_self');
    }

    const handleCallEmails = (email) => {
        const EmailUri = `mailto:${email}`;
        window.open(EmailUri, '_self');
    }

    const groupedContacts = phoneContacts.reduce((groups, contact) => {
        const { type, value } = contact;
        if (!groups[type]) {
            groups[type] = [];
        }
        groups[type].push(value);
        return groups;
    }, {});

    const sortedTypes = Object.keys(groupedContacts).sort((a, b) => {
        if (a === 'EMERGENCY') return -1;
        if (b === 'EMERGENCY') return 1;
        return b.localeCompare(a);
    });

    return (
        <div className='d-flex flex-wrap nex-contact-list-btns'>
            {sortedTypes.map((type) => (
                <div key={type} className='pr-3 pt-3'>
                    {type === 'EMERGENCY' ? groupedContacts[type].length > 1 ?
                        <Dropdown isOpen={isOpenE} toggle={() => toggleDropdown('E')} id={`nex-phone-contact-btn-${type.toLowerCase()}`}>
                            <DropdownToggle caret style={{ border: '1px solid #f93505', borderRadius: '2px', width: '150px', textAlign: 'center', backgroundColor: '#08a123', padding: '5px', color: '#ffffff' }}>{type}</DropdownToggle>
                            <DropdownMenu>
                                {groupedContacts[type].map((phNumbers, index) => (
                                    <DropdownItem key={index} style={{ textDecoration: 'none', color: '#000', fontSize: '14px', padding: '2px 10px' }} onClick={(e) => handleCallPhonenumber(phNumbers)}>
                                        <img src={phone_icon} width="15" alt="phicon" />&nbsp; {phNumbers}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown> :
                        <a href={"tel:" + groupedContacts[type]} style={{ textDecoration: 'none' }} ><div style={{ border: '1px solid #f93505', borderRadius: '2px', width: '150px', textAlign: 'center', backgroundColor: '#dc2604', padding: '5px' }}><span className="icon"><img src={phone_icon} width="15" alt="phicon" /></span> {type}</div></a>
                        : type === 'APPOINTMENT' ? groupedContacts[type].length > 1 ?
                            <Dropdown isOpen={isOpenA} toggle={() => toggleDropdown('A')} id={`nex-phone-contact-btn-${type.toLowerCase()}`}>
                                <DropdownToggle caret style={{ border: '1px solid #168e02', borderRadius: '2px', width: '150px', textAlign: 'center', backgroundColor: '#168e02', padding: '5px', color: '#ffffff' }}>{type}</DropdownToggle>
                                <DropdownMenu>
                                    {groupedContacts[type].map((phNumbers, index) => (
                                        <DropdownItem key={index} style={{ textDecoration: 'none', color: '#000', fontSize: '14px', padding: '2px 10px' }} onClick={(e) => handleCallPhonenumber(phNumbers)}>
                                            <img src={phone_icon} width="15" alt="phicon" />&nbsp; {phNumbers}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown> :
                            <a href={"tel:" + groupedContacts[type]} style={{ textDecoration: 'none' }} ><div style={{ border: '1px solid #168e02', borderRadius: '2px', width: '120px', textAlign: 'center', backgroundColor: '#168e02', padding: '5px' }}>{type}</div></a>
                            : type === 'BILLING' ? groupedContacts[type].length > 1 ?
                                <Dropdown isOpen={isOpenB} toggle={() => toggleDropdown('B')} id={`nex-phone-contact-btn-${type.toLowerCase()}`}>
                                    <DropdownToggle caret style={{ border: '1px solid #05baf9', borderRadius: '2px', width: '150px', textAlign: 'center', backgroundColor: '#049ddc', padding: '5px', color: '#ffffff' }}>{type}</DropdownToggle>
                                    <DropdownMenu>
                                        {groupedContacts[type].map((phNumbers, index) => (
                                            <DropdownItem key={index} style={{ textDecoration: 'none', color: '#000', fontSize: '14px', padding: '2px 10px' }} onClick={(e) => handleCallPhonenumber(phNumbers)}>
                                                <img src={phone_icon} width="15" alt="phicon" />&nbsp; {phNumbers}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown> :
                                <a href={"tel:" + groupedContacts[type]} style={{ textDecoration: 'none' }} ><div style={{ border: '1px solid #05baf9', borderRadius: '2px', width: '120px', textAlign: 'center', backgroundColor: '#049ddc', padding: '5px' }}>{type}</div></a>
                                : groupedContacts[type].length > 1 ?
                                    <Dropdown isOpen={isOpenO} toggle={() => toggleDropdown('O')} id={`nex-phone-contact-btn-${type.toLowerCase()}`}>
                                        <DropdownToggle caret style={{ border: '1px solid #f93505', borderRadius: '2px', width: '150px', textAlign: 'center', backgroundColor: '#dc2604', padding: '5px', color: '#ffffff' }}>{type}</DropdownToggle>
                                        <DropdownMenu>
                                            {groupedContacts[type].map((phNumbers, index) => (
                                                <DropdownItem key={index} style={{ textDecoration: 'none', color: '#000', fontSize: '14px', padding: '2px 10px' }} onClick={(e) => handleCallPhonenumber(phNumbers)}>
                                                    <img src={phone_icon} width="15" alt="phicon" />&nbsp; {phNumbers}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown> :
                                    <a href={"tel:" + groupedContacts[type]} style={{ textDecoration: 'none' }} ><div style={{ border: '1px solid #828682', borderRadius: '2px', width: '120px', textAlign: 'center', backgroundColor: '#424040', padding: '5px', color: '#ffffff' }}>{type}</div></a>
                    }
                </div>
            ))}
            {emailContacts?.length > 0 &&
                <Dropdown isOpen={isOpen} toggle={() => toggleDropdown('EM')} id={`nex-emailContacts-contact-btn`} className='pt-3'>
                    <DropdownToggle caret style={{ border: '1px solid #000', borderRadius: '2px', width: '150px', textAlign: 'center', backgroundColor: '#424040', padding: '5px', color: '#ffffff' }}>EMAIL</DropdownToggle>
                    <DropdownMenu>
                        {emailContacts.map((emailId, index) => (
                            <DropdownItem key={index} style={{ textDecoration: 'none', color: '#000', fontSize: '14px', padding: '2px 10px' }} onClick={(e) => handleCallEmails(emailId)}>
                                {capitalizeFirstLetter(emailId?.type.toLowerCase())}-&nbsp; {emailId?.value}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            }
        </div>
    );
}

export default ContactsList;
