import React from 'react'
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row } from 'reactstrap'
import PatientPersonal from './Components/PatientPersonal';
import { useState } from 'react';
import classnames from "classnames";
import PatientHealth from './Components/PatientHealth';
import PatientLifestyle from './Components/PatientLifestyle';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { PatientService } from 'utils/PatientService';
import { FAILTOSAVERECOED } from 'utils/Constants';
import CommonLoader from 'common/CommonLoader';
import MyReferrals from './Components/MyReferrals';
import { isPatient } from 'utils/UserHelper';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import MyRelations from './Components/MyRelations';
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const PatientDetails = () => {
  let { patientId } = useParams();
  const [activeTab, setActiveTab] = useState("Personal");
  const [patientProfile, setPatientProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const currentUser = useSelector((state) => state.auth.currentUser);


  const getPatientProfile = (id) => {
    PatientService.getPatientById(id)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          setPatientProfile(data);
          setIsLoading(false);
        }
        else {
          showErrorMessage(FAILTOSAVERECOED);
          setIsLoading(false);
        }
      })
  }

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath.includes('/home')) {
      getPatientProfile(currentUser?.profileId);
    }
    else {
      getPatientProfile(patientId);
    }

  }, [patientId])

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    document.body.classList.add('patient-detail-page');
    return () => {
      document.body.classList.remove('patient-detail-page');
    }
  }, [])


  return (
    <>
      {
        isLoading
          ?
          <div className="loading-spiner text-center  mt-2">
            <CommonLoader style={{ width: "2rem", height: "2rem" }} />
          </div>
          :
          <div className='nexogic-patient-detail'>
            <Row>
              
              <Col lg={3} className=''>
                <div className='nex-style-section nex-list-card-v2'>
                  <ul className='nex-step-lists-ul'>
                    <li className={classnames({
                      active: activeTab === "Personal",
                    })}>
                      <a onClick={() => {
                        toggle("Personal");
                      }}>Personal</a>
                    </li>
                    <li className={classnames({
                        active: activeTab === "Health",
                      })}>
                      <a onClick={() => {
                          toggle("Health");
                        }}>Health</a>
                    </li>
                    <li className={classnames({
                        active: activeTab === "Lifestyle",
                      })}>
                      <a onClick={() => {
                          toggle("Lifestyle");
                        }}>Lifestyle</a>
                    </li>
                    <li className={classnames({
                        active: activeTab === "Relations",
                      })}>
                      <a onClick={() => {
                          toggle("Relations");
                        }}>Relations</a>
                    </li>
                  </ul>
                </div>
                {/*<Nav tabs className="nex-step-lists-ul">
                  <NavItem
                    className={classnames({
                      active: activeTab === "Personal",
                    })}
                  >
                    <NavLink className='navpointer'
                      onClick={() => {
                        toggle("Personal");
                      }}
                    >
                      Personal
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classnames({
                      active: activeTab === "Health",
                    })}
                  >
                    <NavLink className='navpointer'
                      onClick={() => {
                        toggle("Health");
                      }}
                    >Health</NavLink>
                  </NavItem>
                  <NavItem
                    className={classnames({
                      active: activeTab === "Lifestyle",
                    })}
                  >
                    <NavLink className='navpointer'
                      onClick={() => {
                        toggle("Lifestyle");
                      }}
                    >Lifestyle</NavLink>
                  </NavItem>
                  <NavItem
                    className={classnames({
                      active: activeTab === "Relations",
                    })}
                  >
                    <NavLink className='navpointer'
                      onClick={() => {
                        toggle("Relations");
                      }}
                    >
                      Relations
                    </NavLink>
                  </NavItem>
                </Nav>*/}
              </Col>
              <Col lg={9} className='nexogic-main-col'>
              {isPatient() &&
                <Col lg={12} className='nex-heading-sec'>
                  <h1 className='nex-text-xxl'>Personal Information</h1>
                  <div className='nex-btn-group right'>
                    <Link to={`/u/profile/${currentUser?.profileId}`} className='btn nex-btn-primary'>Edit Profile</Link>
                  </div>
                </Col>
              }
                {activeTab === "Personal" && <PatientPersonal patient={patientProfile} />}
                {activeTab === "Health" && <PatientHealth patient={patientProfile} />}
                {activeTab === "Lifestyle" && <PatientLifestyle patient={patientProfile} />}
                {activeTab === "Relations" && <MyRelations patient={patientProfile} />}
              </Col>
            </Row>
          </div>
      }
    </>
  )
}

export default PatientDetails;
