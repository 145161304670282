import API_Factory from "./API_Factory";

const createPatient = (data) =>
  API_Factory({
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URL}patient-service/v1/patients`,
    data,
    ignoreToken: false,
  });

const addExisitingPatientAsMyPatient = (data) =>
  API_Factory({
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URL}patient-service/v1/my-patients`,
    data,
    ignoreToken: false,
  });

const getPatientById = (id) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}patient-service/v1/patients/${id}`,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
}

const updatePatient = (payload, id) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "PUT",
        url: `${process.env.REACT_APP_API_BASE_URL}patient-service/v1/patients/${id}`,
        ignoreToken: false,
        data: payload,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
}

const getPatientLifestyle = (id) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}patient-service/v1/${id}/lifestyle`,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
}

const updatePatientLifestyle = (id, payload) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "PUT",
        url: `${process.env.REACT_APP_API_BASE_URL}patient-service/v1/${id}/lifestyle`,
        ignoreToken: false,
        data: payload,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
}

const getPatientHealth = (id) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}patient-service/v1/${id}/health`,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
}

const updatePatientHealth = (id, payload) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "PUT",
        url: `${process.env.REACT_APP_API_BASE_URL}patient-service/v1/${id}/health`,
        ignoreToken: false,
        data: payload,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
}

const getPatients = (sq) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}patient-service/v1/patients?${sq}`,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
}
const getMyPatientsList = (pQuery) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}patient-service/v1/my-patients/autocomplete?${pQuery ?? ""}`,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
}

const getMyPatients = (pageNo = 0) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}patient-service/v1/my-patients?cp=${pageNo}&sb=createdOn&so=DESC`,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
}

const createRelatedPerson = (patientId, payload) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "POST",
        url: `${process.env.REACT_APP_API_BASE_URL}patient-service/v1/${patientId}/related-persons`,
        ignoreToken: false,
        data: payload,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
}

const getRelatedPeople = (patientId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}patient-service/v1/${patientId}/related-persons`,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
}

const getRelatedPersonById = (patientId, person_id) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}patient-service/v1/${patientId}/related-persons/${person_id}`,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
}

const removeMyPatient = (patientId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_BASE_URL}patient-service/v1/my-patients/${patientId}`,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
}

const getPatientByIdpaasToken = (id, token) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}patient-service/v1/patients/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
}

export const PatientService = {
  createPatient,
  getPatientById,
  updatePatient,
  getPatientLifestyle,
  updatePatientLifestyle,
  updatePatientHealth,
  getPatientHealth,
  getPatients,
  getMyPatients,
  removeMyPatient,
  getMyPatientsList,
  addExisitingPatientAsMyPatient,
  createRelatedPerson,
  getRelatedPeople,
  getRelatedPersonById,
  getPatientByIdpaasToken
};
