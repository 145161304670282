import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { Field } from "formik";
import { Button, Col, Row } from "reactstrap";
import { InstituationEmailType } from "utils/Constants";
import { getOAuthUser } from "utils/UserHelper";
import del_red_icon from "assets/images/del-red-icon.png";

const removeSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-trash"
    viewBox="0 0 16 16"
  >
    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
    <path
      fillRule="evenodd"
      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
    />
  </svg>
);

export const RenderInstituationEmailFormFields = (props) => {
  const { index, values, remove, setFieldValue } = props;
  const oAuthUser = getOAuthUser();
  const regEmailAddress = oAuthUser.emailAddress;

  return (
    <Row className="institution-email-field">
      <Col md={3} className="">
      <label className="">Type</label>
        <Field
          id={`emails[${index}].type`}
          name={`emails[${index}].type`}
          type="text"
          component={FormikSelectField}
          value={values.emails[index].type}
          inputprops={{
            name: `emails[${index}].type`,
            options: InstituationEmailType,
            keys: {
              id: "value",
              label: "label",
            },
          }}
        />
      </Col>
      <Col md={8} className="email-number-field">
      <label className="">Email</label>
        <Field
          id={`emails[${index}].value`}
          name={`emails[${index}].value`}
          type="text"
          component={FormikInputField}
          placeholder="Enter Email"
          value={values.emails[index].value}
        />
      </Col>
      <Col md={1} xs={6} className="contact-form-radio">
      <label className="d-xs-none">&nbsp;</label>
        <Button
          id="ref_del_btn"
          color="danger"
          className="nexogic-del-btn"
          onClick={() => {
            const fullItem = values.emails.filter(
              (_, indexItem) => indexItem !== index
            );
            remove(index);
            setFieldValue(`emails`, fullItem);
          }}
          disabled={regEmailAddress === values.emails[index].value}
        >
          {/*removeSVG()*/}
          <img src={del_red_icon} alt="" />
        </Button>
      </Col>
    </Row>
  );
};
