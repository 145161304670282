import React from 'react'
import "./css/invoice3.scss";
import HtmlStringToPdf from '../SecondOpinion/components/EmailPreview/HtmlStringToPdf';

import logo  from 'assets/images/logo.png';
import InvoiceTwo from './InvoiceTwo';
import InvoiceThree from './InvoiceThree';


const Invoice = () => {
  return (
    <>
    <div id="pdf_canvas_preview"></div>
      <div className='text-center'>
        <HtmlStringToPdf target="#invoice-pdf"/>
      </div>
      <div className='d-none'>
      <div className="pdf_wraper pdf-invoice-w v3" id='invoice-pdf' style={{ transformOrigin: 'top left', width: '595px', height:'841px', margin: '0 auto'}}>
        <div className="invoice-w">
            <div className="invoice-head">
                <div className="logo"><img width={178} height={48} src={logo} alt="Logo"
                        className="logo" />
                </div>
                <div className="col1">
                    <div className="billing-adrs">
                        <div className="label">Bill To : </div>
                        <div className="val">
                            <p>[Sample Patient Name ]</p>
                            <p className="small">[Sample Patient Address line 1]</p>
                        </div>
                    </div>
                </div>
                <div className="col2">

                    <div className="invoice-details">
                        <p>INVOICE DETAILS:</p>
                        <p><span className="key">Invoice#</span><span className="val">0000</span></p>
                    </div>

                </div>

            </div>
            <div className="invoice-body">


                <div className="tbl invoice-tbl-w">
                    <table className="invoice-tbl">
                        <tr>
                            <th>Description</th>
                            <th>Quantity</th>
                            <th>Unit price</th>
                            <th>Amount</th>
                        </tr>
                        <tr>
                            <td>Medical Materials</td>
                            <td>1</td>
                            <td>Rs. 100 </td>
                            <td>Rs. 100</td>
                        </tr>
                        <tr>
                            <td>Medical Services</td>
                            <td>1</td>
                            <td>Rs. 20 </td>
                            <td>Rs. 20</td>
                        </tr>
                        <tr>
                            <td>Product name</td>
                            <td>1</td>
                            <td>Rs. 0 </td>
                            <td>Rs. 0</td>
                        </tr>
                        
                    </table>
                    <div className="tbl-terms-w">
                        <table className="tbl-terms">
                            <tr>
                                <td>TERMS</td>
                                <td width="150"></td>
                                <td width="150"></td>
                            </tr>
                            <tr>
                                <td className="normal">Some text</td>
                                <td>Subtotal</td>
                                <td>$00.00</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Discount</td>
                                <td>-$00.00</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Tax Rate</td>
                                <td>5%</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Tax</td>
                                <td>$00.00</td>
                            </tr>
                            <tr className="last-tr">
                                <td></td>
                                <td>TOTAL</td>
                                <td>$00.00</td>
                            </tr>
                        </table>
                    </div>
                    <div className="invoice-footer">
                        <p className="title">CONDITIONS/INSTRUCTIONS</p>
                        <p className="sub-text">Text Here</p>
                    </div>
                </div>
                
            </div>
            <div className="invoice-footer-bot">
                <table className="ifb-tbl">
                    <tr>
                        <td className="first-col">
                            <img width={178} height={48} src={logo} alt="Logo" className="logo" />
                        </td>
                        <td width="300">
                            <table>
                                <tr>
                                    <td>
                                        <h2>Business Name</h2>
                                        <p>Street Address Line 01</p>
                                        <p>Street Address Line 02</p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td width="200">
                            <table>
                                <tr>
                                    <td>
                                        <h2>+1 (999)-999-9999</h2>
                                        <p>Email Address</p>
                                        <p>Website</p>
                                    </td>
                                </tr>
                            </table>
                        </td>

                    </tr>
                </table>
            </div>

        </div>
        
    </div>
    </div>
    {/** 
     * 
     * <div className="invoice-body">


                <div className="tbl invoice-tbl-w">
                    <table className="invoice-tbl">
                        <tr>
                            <th>Description</th>
                            <th>Quantity</th>
                            <th>Unit price</th>
                            <th>Amount</th>
                        </tr>
                        <tr>
                            <td>Medical Materials</td>
                            <td>1</td>
                            <td>Rs. 100 </td>
                            <td>Rs. 100</td>
                        </tr>
                        <tr>
                            <td>Medical Services</td>
                            <td>1</td>
                            <td>Rs. 20 </td>
                            <td>Rs. 20</td>
                        </tr>
                        <tr>
                            <td>Product name</td>
                            <td>1</td>
                            <td>Rs. 0 </td>
                            <td>Rs. 0</td>
                        </tr>
                        
                    </table>
                </div>
                <div className="tbl-terms-w">
                    <table className="tbl-terms">
                        <tr>
                            <td>TERMS</td>
                            <td width="150"></td>
                            <td width="150"></td>
                        </tr>
                        <tr>
                            <td className="normal">Some text</td>
                            <td>Subtotal</td>
                            <td>$00.00</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Discount</td>
                            <td>-$00.00</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Tax Rate</td>
                            <td>5%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Tax</td>
                            <td>$00.00</td>
                        </tr>
                        <tr className="last-tr">
                            <td></td>
                            <td>TOTAL</td>
                            <td>$00.00</td>
                        </tr>
                    </table>
                </div>
                <div className="invoice-footer">
                    <p className="title">CONDITIONS/INSTRUCTIONS</p>
                    <p className="sub-text">Text Here</p>
                </div>
            </div>
     * <div className="invoice-footer-bot">
            <table className="ifb-tbl">
                <tr>
                    <td className="first-col">
                        <img width={178} height={48} src={logo} alt="Logo" className="logo" />
                    </td>
                    <td width="300">
                        <table>
                            <tr>
                                <td>
                                    <h2>Business Name</h2>
                                    <p>Street Address Line 01</p>
                                    <p>Street Address Line 02</p>
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td width="200">
                        <table>
                            <tr>
                                <td>
                                    <h2>+1 (999)-999-9999</h2>
                                    <p>Email Address</p>
                                    <p>Website</p>
                                </td>
                            </tr>
                        </table>
                    </td>

                </tr>
            </table>
        </div>
     * 
    */}

    <InvoiceTwo />
    <InvoiceThree />
    </>
  )
}



export default Invoice