import React from 'react'
import './css/v1.scss'
import logo_img from 'assets/images/logo-white-img.png'
import bg_img from 'assets/images/doctor-img-2.jpg'

function AdsTwelve(props) {
  return (
    <div className='nex-ads-wrapper v12' >
        <div className="ads-mask-image">


        <svg width="458" height="954" viewBox="0 0 458 954" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
            <mask id="svgmask1">
            <path d="M412.5 390C291.321 205.095 379.333 77.6667 412.5 0H0.5V953.5H121L121.746 953.05C160.942 929.438 594.758 668.105 412.5 390Z" fill="white"/>
            </mask>
            <image xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={props.bg} mask="url(#svgmask1)"></image>
        </svg>

        </div>
        <div className="nex-ads-content">
            <div className="nex-ads-brand" >
                <img src={props.logo_img} alt="" />
            </div>
            <div className="nex-ads-middle-block">
            <h2 className="nex-ads-title">{props.title}</h2>
            <p className='card-text' >{props.short_desc}</p>
        </div>


        <div className="nex-ads-contact-block">
            <div className="nex-ads-phone">
            
                <div className="info">
                call Us Now <strong>{props.phone}</strong>
                </div>
            </div>
            <div className="nex-ads-website">
            <div className="icon">
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.49996 12.5C9.92941 13.0741 10.4773 13.5491 11.1065 13.8929C11.7357 14.2367 12.4315 14.4411 13.1466 14.4923C13.8617 14.5435 14.5795 14.4403 15.2513 14.1897C15.9231 13.9392 16.5331 13.547 17.04 13.04L20.04 10.04C20.9507 9.09695 21.4547 7.83394 21.4433 6.52296C21.4319 5.21198 20.9061 3.95791 19.979 3.03087C19.052 2.10383 17.7979 1.57799 16.487 1.5666C15.176 1.5552 13.913 2.05918 12.97 2.96997L11.25 4.67997M13.5 10.5C13.0705 9.92584 12.5226 9.45078 11.8934 9.10703C11.2642 8.76327 10.5684 8.55885 9.8533 8.50763C9.13816 8.45641 8.42037 8.5596 7.74861 8.81018C7.07685 9.06077 6.46684 9.4529 5.95996 9.95996L2.95996 12.96C2.04917 13.903 1.54519 15.166 1.55659 16.477C1.56798 17.788 2.09382 19.042 3.02086 19.9691C3.9479 20.8961 5.20197 21.4219 6.51295 21.4333C7.82393 21.4447 9.08694 20.9408 10.03 20.03L11.74 18.32" stroke="#00D77F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>

                    </div>
                <div className="info">
                 <strong>{props.website}</strong>
                </div>
            </div>
             
        </div>




        </div>
        


        
        
    </div>
  )
}

/* default props */
AdsTwelve.defaultProps = {
    bg:bg_img,
    logo_img:logo_img,
    logo_url:"#",
    logo_target:"_blank",
    title:"Your Health is our priority",
    short_desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo, magni.",
    website:"www.brandname.com",
    phone:"+0123456789",

  }
  
  export default AdsTwelve;
