import logo_white from "assets/images/logo-white-transparent.png";
import google_img from "assets/images/social-google-img.png";
import facebook_img from "assets/images/facebook-social-img.png";
import arrow_left from "assets/images/arrowleft.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Field, Form, Formik } from "formik";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import FormikMobileField from "components/forms/formikFields/FormikMobileField";
import SocialIconsForm from "pages/ClaimSocialProfile/Components/SocialIconsForm";
import { UserAuth } from "context/AuthContext";
import { IdentityService } from "utils/IdentityService";
import { useDispatch } from "react-redux";
import { getUser, resetAuthSession, setLoggedInUserInstitutions, setOAuthTokens, setOAuthUser, setUser } from "utils/UserHelper";
import { authSetUser } from "redux-helpers/ActionCreator";
import { PractitionerService } from "utils/PractitionerService";
import { Link } from "react-router-dom";
import { Button, InputGroup } from "reactstrap";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import VerifyOtp from "./VerifyOtp";
import CommonLoader from "common/CommonLoader";
import { phoneRegExp } from "utils/Constants";
const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Mobile number is not valid")
    .required("This field is required"),
});

const LoginSocialProfile = (props) => {
  const { googleSignIn, facebookSignIn, appleSignIn, signInWIthEmail, signInWithPhone, verifyOTP, user } = UserAuth();
  const dispatch = useDispatch();
  const pageFlag = "LOGIN";
  const history = useHistory();
  const [useOtp, setUseOtp] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const initValues = useMemo(() => {
    return {
      phone: "",
    };
  }, []);
  const [isSubmitting, setSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState(initValues);
  const jobsDetailstUrl = new URLSearchParams(window.location.search).get('jobdetails');
  const [loading, setLoading] = useState(false);
  const [vloading, setVloading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");

  const getUserDetails = (userDetails, signedUser) => {
    const { accessToken, refreshToken } = signedUser?.stsTokenManager;
    const { types, profileId, id, groups} = userDetails;

    // if (types.includes("INSTITUTION_ADMIN") && types.includes("PRACTITIONER")) {
    //   setOAuthTokens(accessToken, refreshToken);
    //   setLoggedInUserInstitutions(groups);
    //   PractitionerService.gePractitionersDetails(id)
    //     .then((practResp) => {
    //       if (practResp && practResp.data) {
    //         const data = practResp.data;
    //         setOAuthTokens(accessToken, refreshToken);
    //         setOAuthUser(userRes.data);
    //         setUser(data);
    //         dispatch(authSetUser(data));
    //         if (jobsDetailstUrl !== null) {
    //           history.push(`/jobs/${jobsDetailstUrl}`)
    //         } else
    //           history.push(`/home`);
    //       }
    //       setSubmitting(false);
    //     })
    //     .catch((error) => {
    //       console.log(
    //         "PractitionerService.gePractitionersDetails error",
    //         error
    //       );
    //       props.alert.error(error.message);
    //       resetAuthSession();
    //       setSubmitting(false);
    //     });
    //   return;
    // }
    if (types.includes("ADMIN")) {
      setOAuthTokens(accessToken, refreshToken);
      setOAuthUser(userDetails);
      setUser(userDetails);
      dispatch(authSetUser(userDetails));
      setSubmitting(false);
      setLoading(false);
      return;
    }

    if (types.includes("INSTITUTION_ADMIN")) {
      setLoggedInUserInstitutions(groups);
      setOAuthTokens(accessToken, refreshToken);
      setOAuthUser(userDetails);
      setUser(userDetails);
      dispatch(authSetUser(userDetails));
      history.push(`/home`);
      setSubmitting(false);
      setLoading(false);
      return;
    }

    if (types.includes("PATIENT")) {
      setOAuthTokens(accessToken, refreshToken);
      setOAuthUser(userDetails);
      setUser(userDetails);
      dispatch(authSetUser(userDetails));
      history.push(`/home`);
      setSubmitting(false);
      setLoading(false);
      return;
    }
    setOAuthTokens(accessToken, refreshToken); 
    PractitionerService.gePractitionersDetails(profileId)
      .then((practResp) => {
        if (practResp && practResp.data) {
          const data = practResp.data;
          setOAuthTokens(accessToken, refreshToken);
          setOAuthUser(userDetails);
          setUser(data);
          dispatch(authSetUser(data));
          if (jobsDetailstUrl !== null) {
            history.push(`/jobs/${jobsDetailstUrl}`)
          } else
            history.push(`/home`);
        }
        setSubmitting(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log(
          "PractitionerService.gePractitionersDetails error",
          error
        );
        props.alert.error(error.message);
        resetAuthSession();
        setSubmitting(false);
        setLoading(false);
      });
  }


  const handleGoogleSignIn = async () => {
    try {
      const signedInUser = await googleSignIn();
      if (signedInUser?.accessToken) {
        try {
          IdentityService.UserAuthentication(signedInUser?.accessToken)
            .then((response) => {
              if (response.status === 200) {
                const { data } = response;
                getUserDetails(data, signedInUser);
              } else {
                history.push('/register')
              }
            })
            .catch((error) => {
              console.log("Login Issue..", error);
            })
        } catch (apiError) {
          console.error('API call failed:', apiError);
          history.push('/login');
        }
      }
    } catch (error) {
      console.error('Google sign-in failed:', error);
    }
  };

  const handleFacebookLogin = async () => {
    try {
      const signedInUser = await facebookSignIn();
      if (signedInUser?.accessToken) {
        try {
          IdentityService.UserAuthentication(signedInUser?.accessToken)
            .then((response) => {
              if (response.status === 200) {
                const { data } = response;
                getUserDetails(data, signedInUser);
              } else {
                history.push('/register')
              }
            })
            .catch((error) => {
              console.log("Login Issue..", error);
            })
        } catch (apiError) {
          console.error('API call failed:', apiError);
          history.push('/login');
        }
      }
    } catch (error) {
      console.error('Google sign-in failed:', error);
    }
  };

  const handleAppleLogin = async () => {
    try {
      const signedInUser = await appleSignIn();
      if (signedInUser?.accessToken) {
        try {
          IdentityService.UserAuthentication(signedInUser?.accessToken)
            .then((response) => {
              if (response.status === 200) {
                const { data } = response;
                getUserDetails(data, signedInUser);
              } else {
                history.push('/register', {
                  data: { user }
                })
              }
            })
            .catch((error) => {
              console.log("Login Issue..", error);
            })
        } catch (apiError) {
          console.error('API call failed:', apiError);
          history.push('/login');
        }
      }
    } catch (error) {
      console.error('Apple sign-in failed:', error);
    }
  };

  const handleEmailPasswordLogin = async (values, setSubmitting) => {
    setLoading(true);
    const { email, password } = values;
    try {
      const signedInUseremail = await signInWIthEmail(email, password);
      if (signedInUseremail?.accessToken) {
        try {
          IdentityService.UserAuthentication(signedInUseremail?.accessToken)
            .then(async response => {
              if (await response.status === 200) {
                const { data } = response;
                getUserDetails(data, signedInUseremail);
                // history.push('/user-info-validate')
              } else {
                setLoading(false);
                history.push('/register', {
                  data: { user }
                })
              }
            })
            .catch((error) => {
              setLoading(false);
              console.log("Login Issue..", error);
            })
        } catch (apiError) {
          setLoading(false);
          console.error('API call failed:', apiError);
          history.push('/login');
        }
      }else{
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Email/Password sign-in failed:', error);
    }
  };

  const handleSendOtp = async (phoneNumber, setSubmitting) => {
    setLoading(true);
    try {
      const confirmationResult = await signInWithPhone(phoneNumber);
      if (confirmationResult) {
        showSuccessMessage("OTP sent successfully");
        setMobileNumber(phoneNumber);
        setOtpSent(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error sending OTP:', error);
      showErrorMessage('Too many attempts requesting OTP..please try after sometime');
    } 
    // finally {
    //   if (isComponentMounted.current) {
    //     setSubmitting(false);
    //   }
    // }
  };

  const handleVerifyOtp = async (code) => {
    setVloading(true);
    try {
      const signedInUser = await verifyOTP(code)
      if (signedInUser?.accessToken) {
        try {
          IdentityService.UserAuthentication(signedInUser?.accessToken)
            .then((response) => {
              if (response.status === 200) {
                const { data } = response;
                getUserDetails(data, signedInUser);
                setVloading(false)
              } else if (response.status === 404) {
                //   setOtpSent(false);
                //setIsActive(false);
                setVloading(false);
                showErrorMessage("Account not found, please verify your number or consider signing up for a new account.");
              } else {
                showErrorMessage("Something went wrong..please try after sometime");
                setVloading(false);
              }
            })
            .catch((error) => {
              console.log("Login Issue..", error);
              showErrorMessage("Login Issue..please try after sometime");
              setVloading(false);
            })
        } catch (apiError) {
          console.error('API call failed:', apiError);
          history.push('/login');
          setVloading(false);
        }
      }else{
        setVloading(false);
      }
    } catch (error) {
      console.error('Mobile sign-in failed:', error);setVloading(false);
    }
  };

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className="nex-bg-light-blue">
      <main className="nex-login-register--w" >
        <div className="container sm d-flex" data-aos="fade-up">
          <div className="nex-form-aside" >
            <div className="logo" data-aos="fade-down" data-aos-duration="800" data-aos-delay="800">
              <a href="/"><img src={logo_white} alt="" /></a>
            </div>

            <h2 className="nex-text-xxl nex-underline" data-aos="fade-down" data-aos-duration="1200" data-aos-delay="800">Welcome Back to Nexogic</h2>
            <p className="nex-text-lg text-white mt-3">Sign in to continue with your account</p>
          </div>
          <div className="nex-form-fields--w">            
            {useOtp && (<img src={arrow_left} width="21" className="cursor-pointer" onClick={() => history.goBack()} />)}
            <div className="nex-form-center-slot">
              {!useOtp && (
                
                <SocialIconsForm
                  handleGoogle={handleGoogleSignIn}
                  handleFacebook={handleFacebookLogin}
                  handleApple={handleAppleLogin}
                  handleEmail={handleEmailPasswordLogin}
                  pageFlag={pageFlag}
                  useOtp={useOtp}
                  setUseOtp={setUseOtp} 
                  setLoading={setLoading}
                  loading={loading}
                  />
                  
              )}

              {useOtp && (
                <>
                  {!otpSent ? (
                    <div>
                      <div className="nex-text-lg text-center py-5">
                        <h5>Enter your phone number to verify. We'll send an SMS to verify your phone number</h5>
                      </div>
                      <Formik
                        initialValues={{ phoneNumber: '' }}
                        validationSchema={validationSchema}
                        onSubmit={({ phoneNumber }, { setSubmitting }) => handleSendOtp(phoneNumber, setSubmitting)}
                      >
                        {({ handleSubmit, isSubmitting, errors }) => (
                          <Form onSubmit={handleSubmit}>
                            <div className="nexogic-phone-field-wrap">
                              <InputGroup className="mt-3" size="large">
                                <Field
                                  id="mnf_phon"
                                  name="phoneNumber"
                                  type="text"
                                  component={FormikMobileField}
                                  placeholder="Phone Number" />
                              </InputGroup>
                            </div>
                           
                              {/* <Button id="btn_sign_in" size="lg" block className="btn-link" type="submit" disabled={isSubmitting}>Resend OTP</Button></div> */}
                              {loading ? (
                                <div className="loading-spiner text-center">
                                  <CommonLoader />
                                </div>
                              ) :
                                <Button id="btn_sign_in" size="lg" block className="btn btn-primary w-100" type="submit" disabled={isSubmitting}>Continue</Button>
                              }
                          </Form>
                        )}
                      </Formik>
                    </div>) :
                    <VerifyOtp handleVerifyOtp={handleVerifyOtp} mobileNumber={mobileNumber} setOtpSent={setOtpSent} handleSubmit={handleSendOtp} vloading={vloading} />
                  }
                </>
              )}
            </div>
            <div className="nex-form-bottom-slot">
              <p className="nex-text-lg text-center">
                Not a member yet?{' '}
                <Link to="/register" className="nex-link-dark"> Register </Link>
              </p>
            </div>
          </div>
        </div>
        <div id="recaptcha-container"></div>
      </main>
    </div>    
  )
}

export default LoginSocialProfile;
