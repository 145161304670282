import React, { useState } from "react";
import RenderDocumentFormik from "./Components/RenderDocumentFormik";
import { withAlert } from "react-alert";
import { Col, Row } from "reactstrap";
const DocumentForm = (props, { onBack }) => {
  const [documentType, setDocumentType] = useState([
    {
      id: 0,
      type: "PRIMARY",
      formCount: 1,
    },
    {
      id: 1,
      type: "PRIMARY",
      formCount: 2,
    },
  ]);

  const addNewDocument = (newDocument) => {
    setDocumentType((prev) => {
      return [...prev, newDocument];
    });
  };
  const deleteDocument = (id) => {
    setDocumentType((prev) => {
      return prev.filter((obj, index) => {
        return index !== id;
      });
    });
  };

  return (
    <div className="nexogic-onboarding-form nexogic-form-content nex-app-document-upload">
      <Row>
        {/*<Col md={12}>
          <h3 className="nexogic-form-title mb-1">Add Documents</h3>
          <p className="nexogic-form-title-sub mb-2">
            Provide your government issued ID and medical registration for
            verification of your identity.
          </p>
  </Col>*/}
      </Row>
      <fieldset className="u-flsd-wpr mb-3 nex-login-info-field-sec nex-fieldset-field-sec ">
          <legend>Add Documents</legend>
          
            <p className="nexogic-form-title-sub">
            Provide your government issued ID and medical registration for
            verification of your identity.
            </p>
      <RenderDocumentFormik
        documentTypes={documentType}
        onBack={onBack}
        onAddDocument={addNewDocument}
        onDelete={deleteDocument}
        {...props}
      />
      </fieldset>
    </div>
  );
};

export default withAlert()(DocumentForm);
