import React from "react";
import Logo from "../Header/components/Logo";
import NexogicLogo from "assets/images/logo.png";
import "./footer.css";

const Footer = () => {
  return (
    <>
    <footer className="nexogic_footer">
        <div className="nexogic-container-gap container-fluid">
            <div className="row">
                <div className="col-auto">
                <div className="logo">
                  <a
                    href={`${process.env.REACT_APP_WEBSITE_URL}/`}
                    className="m-0 navbar-brand"
                  >
                    <img src={NexogicLogo} alt="Nexogic" className="img-fluid footer-logo" />
                  </a>
                </div>
                <div className="all-rights mr-2">
                {(new Date().getFullYear())} &copy; Nexogic. All Rights Reserved.
            </div>
                </div>
                <div className="col-auto ml-auto">
                    <div className="footer-links">
                    <a className="links"
                  target="_blank"
                  href={`https://nexogic.com/privacy-policy`}
                >
                  Privacy Policy
                </a>
                <a
                  className="links"
                  target="_blank"
                  href={`https://nexogic.com/terms-and-conditions`}
                >
                  Terms and Conditions
                </a>
                     
                    </div>
                </div>
            </div>
        </div>
    </footer>
    
    

    {/** 
      <footer className="footer-wrapper p-3">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="powered-by">Powered by</div>
          <div className="logo">
            <a
              href={`${process.env.REACT_APP_WEBSITE_URL}/`}
              className="m-0 navbar-brand"
            >
              <Logo />
            </a>
          </div>
          <div className="bottom-text d-flex justify-content-center align-items-center column-reverse">
            <div className="all-rights mr-2">
              2021 &copy; Nexogic. All Rights Reserved.
            </div>
            <div className="d-flex">
              <div className="mr-2">
                <a
                  className="links"
                  target="_blank"
                  href={`${process.env.REACT_APP_WEBSITE_URL}privacy-policy/`}
                >
                  Privacy Policy
                </a>
              </div>
              <div>
                <a
                  className="links"
                  target="_blank"
                  href={`${process.env.REACT_APP_WEBSITE_URL}terms-and-conditions`}
                >
                  Terms and Conditions
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      */}
    </>
  );
};

export default Footer;
