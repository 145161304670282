import React, { useState, useEffect, useRef } from "react"
import { Row, Col, FormGroup, Label, Button, Spinner, Input } from 'reactstrap'
import "react-datepicker/dist/react-datepicker.css";
import { Field, ErrorMessage } from 'formik';
import moment from "moment";

import { appointmentServices } from "utils/appointmentsServices";
import FormikInputField from "components/forms/formikFields/FormikInputField"
import FormikDatePicker from "components/forms/formikFields/FormikDatePicker"
import FormikSelectField from "components/forms/formikFields/FormikSelectField"

export const RescheduleFormFields = (props) => {
    const { setFieldValue, values, handleSubmit, isValid, dirty, toggle, isSubmitting, errors, practitionerId, facilityId } = props
    const [avlSlots, setAvlSlots] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if(values.apptDate) {
            getAvailSlots()
        }
    }, [values.apptDate])

    const getAvailSlots = async () => {
        const dated = moment(values.apptDate).format("YYYY-MM-DD")
        const request = appointmentServices.getAvailableSlots(practitionerId, facilityId, dated)
        setIsLoading(true)
        setAvlSlots([])
        request.then(response => {
            let { data } = response
            if (data && data.availableSlots && data.availableSlots[dated]) {
                //console.log("data.availableSlots", data.availableSlots[dated]);
                
                // only available slots should be in the list
                let availableSlots = data.availableSlots[dated].filter(item => item.status === "AVAILABLE")
                availableSlots = availableSlots.map(item => ({
                    label: `${moment.utc(item.startTime, 'HH:mm:ss').local().format('HH:mm A')} - ${moment.utc(item.endTime, 'HH:mm:ss').local().format('HH:mm A')}`,
                    value: item.id
                }))
                setAvlSlots(availableSlots)
            } else {
                setAvlSlots([])
            }
            setIsLoading(false)
        })
      }

    return (
        <form onSubmit={handleSubmit}>
            <Row className="pl-5">
                <Col md={4}>
                    {/* <div className="text-center f-16 line-h-16 font-weight-bold my-3" style={{ color: "#2D4552", letterSpacing: "1.5px" }}>Appointment Date</div> */}
                    {/* <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                        setStartDate(date)
                        setIsLoading(true)
                    }}
                    minDate={new Date()}
                    inline
                    showMonthDropdown
                    useShortMonthInDropdown

                    /> */}
                    <Field
                        id="rf_apptDate"
                        name="apptDate"
                        type="text"
                        component={FormikDatePicker}
                        inputprops={
                            {
                                dateFormat: "yyyy-MM-dd",
                                showYearDropdown: true,
                                showMonthDropdown: true,
                                scrollableYearDropdown: true,
                                yearDropdownItemNumber: 10,
                                inline:true,
                                minDate: new Date(),
                                autoComplete:"off"
                            }   
                        }
                        label="Appointment Date"
                        placeholder="YYYY-MM-DD" />
                </Col>
                <Col md={8} className="text-center">
                    {/* <Cards slotDatas={avlSlots}/> */}
                    {/* <DropDownBox slotDatas={avlSlots} /> */}

                    {isLoading ?
                        <div className="mt-3 mb-2 text-center"><Spinner color="dark" /></div>
                        :
                        <Field
                            id="rf_slotId"
                            name="slotId"
                            type="text"
                            label="Available slots"
                            component={FormikSelectField}
                            inputprops={{
                                name: `slotId`,
                                options: avlSlots,
                                defaultOption: "Choose any slot",
                                keys: {
                                    id: "value",
                                    label: "label"
                                }
                            }}
                        />
                    }
                </Col>
            </Row>
            <hr className="profile-info-modal-hr" />
            <Row className="justify-content-center modal-save-main">
                <Col xs="auto">
                    {isSubmitting? 
                        <div className="loading-spiner"><Spinner style={{ width: '2rem', height: '2rem' }} /></div>
                        :
                        <Button id="rf_submit" className="modal-save-btn" color="primary " type="submit" disabled={!dirty}>Save</Button>
                    }
                </Col>
            </Row>
        </form>
    )
}

export default RescheduleFormFields