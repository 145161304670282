import parse from "html-react-parser";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import RenderAboutForm from "./RenderAboutForm";
import pencilIcon from "assets/images/pencil_icon.png";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import edit_icon_card_head from "assets/images/edit-blue-icon.png";
import { useEffect } from "react";

const RenderinstituteSummary = (props) => {
  const { instituteSummary, isOpen, setIsOpen } = props;
  if (instituteSummary && instituteSummary !== "<p><br></p>") {
    if (isOpen) {
      return (
        <>
          <div className="card-text">
            {parse(instituteSummary)}
            <Button
              color="link"
              onClick={() => setIsOpen(false)}
              className="readmore "
            >
              Read less
            </Button>
          </div>
        </>
      );
    } else {
      const showLessSummary = instituteSummary.slice(0, 500) + "...";
      if (instituteSummary.length > 500) {
        return (
          <>
            <div className="card-text">
              {parse(showLessSummary)}
              <Button
                color="link"
                onClick={() => setIsOpen(true)}
                className="readmore "
              >
                Read more
              </Button>
            </div>
          </>
        );
      } else {
        return (
          <div className="about-text">{parse(instituteSummary)}</div>
        );
      }
    }
  } else {
    return "";
  }
};

export const About = (props) => {
  const [modalisOpen, setmodalIsOpen] = useState(false);
  const modalToggle = () => setmodalIsOpen(!modalisOpen);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { isEditable, isAdmin, institution, getInstitutionProfile, setShowAboutOnTabScroll } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const toggle = () => setIsEditMode(!isEditMode);
  let instituteSummary = institution?.aboutUs;
  const [pannel_on, setPannel_on] = useState(true);


  useEffect(() => {
    if (instituteSummary && instituteSummary.length > 0 || isAdmin) {
      setShowAboutOnTabScroll(true)
    } else {
      setShowAboutOnTabScroll(false);
    }
  }, [instituteSummary, isAdmin])

  return (
    <>
      {((institution?.aboutUs && !isAdmin) || isAdmin) && (
        <Card className={`nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="about">
          <div className="card-header"><h2>About</h2>
            <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
              <svg width={17} height={10} viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path
                  d="M2.25 1.625L8.625 8L15 1.625"
                  stroke="black"
                  strokeWidth={3}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            {(isEditable || isAdmin) && (

              <Button
                id="nex-ids-about-edit-btn"
                color="link"
                className="add-button"
                onClick={modalToggle}
              >
                <svg width="40" height="40" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="13.5" cy="13.5" r="12.375" fill="white" stroke="#DBF4FB" stroke-width="0.75" />
                  <path d="M16.3125 8.25L15.0938 9.46875L17.5312 11.9062L18.75 10.6875L16.3125 8.25ZM13.875 10.6875L9 15.5625V18H11.4375L16.3125 13.125L13.875 10.6875Z" fill="#46ABC9" />
                </svg>
              </Button>

            )}
          </div>
          <div className={`collapse-div`}>
            <CardBody>
              <div>
                {isEditMode ? (
                  <RenderAboutForm
                    toggle={toggle}
                    instituteSummary={instituteSummary}
                    isEditable={isEditable}
                    isAdmin={isAdmin}
                    practitionerId={currentUser?.practitionerId}
                    institutionId={institution?.id}
                    getInstitutionProfile={getInstitutionProfile}
                  />
                ) : instituteSummary !== "" ? (
                  <RenderinstituteSummary
                    instituteSummary={instituteSummary}
                    isOpen={isOpen}
                    isEditable={isEditable}
                    setIsOpen={setIsOpen}
                    practitionerId={currentUser?.practitionerId}
                  />
                ) : (
                  // <div className="mt-3">
                  //   <Row>
                  //     <Col className="">
                  //         <div className="empty-about-box">
                  //             <div className="about-placeholder">Showcase your work by featuring your best posts, documents, media and websites.</div>
                  //             <div className="add-infomation">Add information</div>
                  //         </div>
                  //     </Col>
                  //   </Row>
                  // </div>
                  ""
                )}
              </div>
            </CardBody>
          </div>
        </Card>
      )}
      {/* about modal */}
      <Modal
        isOpen={modalisOpen}
        toggle={modalToggle}
        className="institute-modal"
        backdrop="static"
      >
        <ModalHeader close={<button className="close" onClick={modalToggle}> × </button>} toggle={modalToggle} className="nex-institutions-modal-header"><span className="nex-text-xl">About</span></ModalHeader>
        <ModalBody className="bg-white">
          <RenderAboutForm
            toggle={modalToggle}
            instituteSummary={instituteSummary}
            isEditable={isEditable}
            isAdmin={isAdmin}
            institution={institution}
            practitionerId={currentUser?.guid}
            institutionId={institution?.guid}
            getInstitutionProfile={getInstitutionProfile}
          />
        </ModalBody>
      </Modal>
    </>
  );
};
