import React, { useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button, Col, Row } from "reactstrap";
import { UserService } from "utils/UserService";
import RenderAffiliationFormik from "./Components/RenderAffiliationFormik";

const HospitalAffiliationForm = ({ onNext, onBack, onSkip, setAffiliationDet}) => {
  const [afflType, setAfflType] = useState([
    {
      id: 0,
      type: "PRIMARY",
      formCount: 1,
    },
  ]);

  const addNewAffl = (newAffl) => {
    setAfflType((prev) => {
      return [...prev, newAffl];
    });
  };
  const deleteAffl = (id) => {
    setAfflType((prev) => {
      return prev.filter((obj, index) => {
        return index !== id;
      });
    });
  };

  const logout = () => {
    UserService.logout();
    window.location.reload();
  };

  return (
    <div className="nexogic-onboarding-form nexogic-form-content row">
      <div className="col">
      <Row className="cs-column-reverse">
        <Col md={9}>
          <h3 className="nexogic-form-title mb-1">Add Hospital &amp; Clinic Affiliation</h3>
          <p className="nexogic-form-title-sub mb-2">
            Provide your Hospital Affiliation and work timings
          </p>
        </Col>
        <Col md={'auto'} className="ml-auto">
          <Button
            className="btn nexogic_primary_button_outline  d-block ml-auto save-exit"
            onClick={logout}
          >
            Exit
          </Button>
        </Col>

      </Row>

      <TransitionGroup>
        {afflType.map((affl, i) => {
          const lastAfflObj = afflType[afflType.length - 1];
          return (
            <CSSTransition key={affl.id} timeout={500} className="item-trans">
              <RenderAffiliationFormik
                afflObj={affl}
                onNext={onNext}
                skip={onSkip}
                setAffiliationDet={setAffiliationDet}
                onBack={onBack}
                lastAfflObj={lastAfflObj}
                onAddAffl={addNewAffl}
                onDelete={deleteAffl}
              />
            </CSSTransition>
          );
        })}
      </TransitionGroup>
      </div>
    </div>
  );
};

export default HospitalAffiliationForm;
