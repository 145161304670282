import "assets/styles/typeahead.css";
import React, { useRef, useState } from "react";
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { withRouter } from "react-router-dom";
import { PractitionerChannelServices } from "utils/PractitionerChannelServices";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";

const AsyncMessageProfileSearch = (props) => {
  const { placeholder, type, history } = props;
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [userQuery, setUserQuery] = useState("");
  const currentPage = useRef(0);
  const ref = React.createRef();
  const practionerId = useSelector(state => state.auth.currentUser.guid);
  
  const getData = async (query) => {
    PractitionerChannelServices.getPractitionerSearchData(query,true,currentPage.current).then((response) => {
      const { data } = response;
      currentPage.current = data.currentPage + 1;
      if (data.currentPage !== 0) {
        setHasMore(data.currentPage !== data.totalPages);
      }
      if (data && data.contents && data.contents.length > 0) {
        let practitioners = data.contents;
        if (practitioners.length > 0) {
          const filteredPracts = practitioners.filter((p) =>
            p.name.toLowerCase().startsWith(query.toLowerCase())
          );
          if (filteredPracts.length > 0) practitioners = filteredPracts;
        }
        let results = practitioners.map((p) => {
          let {
            primarySpeciality,
            title,
            name,
            city,
            country,
            state,
            profileId,
            avatarId,
            guid,
          } = p;
          let value = `${title || ""} ${name || ""} ${
            !!primarySpeciality && primarySpeciality.length > 0
              ? ", " + primarySpeciality[0]
              : ""
          }${!!city ? ", " + city : ""}${!!state ? ", " + state : ""}${
            !!country ? ", " + country : ""
          }`;
          const location = `${!!city ? " " + city : ""}${
            !!state ? ", " + state : ""
          }${!!country ? ", " + country : ""}`;
          return {
            id: guid,
            name: value,
            username: name,
            profilePicUrl: avatarId,
            profileId,
            location,
          };
        });
        setOptions((prevState) => [...prevState, ...results]);
        setLoading(false);
      }
      setLoading(false);
    });
  };

  const handleSearch = async (query) => {
    if (query.length < 3) return false;
    setOptions([]);
    currentPage.current = 0;
    setLoading(true);
    setUserQuery(query);
    getData(query);
  };

  const fetchMoreData = () => {
    setTimeout(() => getData(userQuery), 500);
  };

  const handleChange = async (selected) => {
    if (selected && selected[0]) {
      let result = selected[0];
      history.push('/messages');
      ref.current.clear();
    }
  };

// Dynamic channel creation code here. 
const handleDynamicChannel = (res)=>{
  
  let payloads = {
    "provider": "PUBNUB",
    "subscribers": [
      res.id
    ]
  }

  PractitionerChannelServices.createPractitionerChannel(practionerId, payloads).then(async(res) => {
   await props.handleToggle(res.data)
  })
  
}
  return (
    <>
      <AsyncTypeahead
        ref={ref}
        isLoading={loading}
        clearButton
        onSearch={handleSearch}
        id={""}
        options={options}
        labelKey={"name"}
        placeholder={placeholder}
        type={type}
        minLength={3}
        maxResults={options.length}
        inputProps={{ className: "" }}
        onChange={handleChange}
        useCache={false}
        delay={1000}
        filterBy={(option) => {
          return option;
        }}
        renderMenu={(results, menuProps) => {
          if (results && results.length) {
            return (
              <Menu {...menuProps}>
                <>
                  <InfiniteScroll
                    dataLength={options.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<p className="loadingSearch">Loading...</p>}
                    height={275}
                    className="nexogic-loading-search-Container"
                  >
                    {results.map(
                      (result, index) =>
                        result?.name && (
                          <MenuItem
                            key={index}
                            option={result}
                            position={index}
                            className="card mb-2"
                            onClick={() => handleDynamicChannel(result)}
                          >
                            <div className="card-body">
                              <h3 className="card-title">{result.username}</h3>
                              <div className="card-text">
                                {result.location}
                              </div>
                            </div>
                          </MenuItem>
                        )
                    )}
                  </InfiniteScroll>
                </>
              </Menu>
            );
          }
          return null;
        }}
      />
    </>
  );
};
export default withRouter(AsyncMessageProfileSearch);
