import { useState } from "react";
import { memo } from "react";
import { Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import Timingslot from "./TimingSlot";

const TimingslotContainer = (props) => {
  const { list, handleEditItem, isAdminUser, unformatedSchedules, isEditable} = props;
  const [activeTabind, setActiveTabind] = useState("1");

  function toggleActive(val, event) {
    event.preventDefault();
    setActiveTabind(val);
  }

  return (
    <>
      <div className="nexogix-hospital-tab-wrap">
        {/* <Nav tabs vertical className="nexogix-hospital-tab">
          <NavItem>
            <NavLink onClick={(e)=>toggleActive("1", e)}  active={activeTabind == "1" ? true : false}> <span className="icon"><img src={opening_arrow_left} /></span> Hospital Hours</NavLink>
          </NavItem>
          <NavItem>
            <NavLink  onClick={(e)=>toggleActive("3", e)} active={activeTabind == "3" ? true : false}><span className="icon"><img src={opening_arrow_left} /></span> OPD Hours</NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={(e)=>toggleActive("2", e)} active={activeTabind == "2" ? true : false}><span className="icon"><img src={opening_arrow_left} /></span> Visiting Hours</NavLink>
          </NavItem>
      </Nav> 
      <TabContent activeTab={activeTabind} className="nexogix-hospital-tab-content"> */}

        {/* <TabPane tabId="1"> */}
        {/* {(list !== null || list?.hospital_Hours_list !== null || isAdminUser) && ( */}

        {(list !== null || list?.hospital_Hours_list !== null) &&
          <Timingslot
            title="Hospital Hours"
            formatedSchedules={list?.hospital_Hours_list}
            unformatedSchedules={unformatedSchedules}
            handleEditItem={handleEditItem}
            editType="HOSPITAL_HOURS"
            sList={list}
            isAdminUser={isAdminUser}
            isEditable={isEditable}
          />
        }


        {/* )} */}
        {/* </TabPane> */}
        {/* <TabPane tabId="2"> */}
        {/* {(list !== null || list?.vising_Hours_list !== null || isAdminUser) && (
            <Timingslot
              title="Visiting Hours"
              formatedSchedules={list?.vising_Hours_list}
              handleEditItem={handleEditItem}
              editType="VISITOR_HOURS"
              sList={list}
              isAdminUser={isAdminUser}
            />
          
            )}
        </TabPane>
        <TabPane tabId="3">
        {(list !== null || list?.oPD_Hours_list !== null || isAdminUser) && (
            <Timingslot
            title="OPD Hours"
            formatedSchedules={list?.oPD_Hours_list}
            handleEditItem={handleEditItem}
            editType="OPD_HOURS"
            sList={list}
            isAdminUser={isAdminUser}
          />
            )}
        </TabPane>
      </TabContent> */}
      </div>
      {/** 
    <div className="hours-wrapper">
      <Row>
        {(list !== null || list?.hospital_Hours_list !== null || isAdminUser) && (
          <Col sm={4}>
            <Timingslot
              title="Hospital Hours"
              formatedSchedules={list?.hospital_Hours_list}
              handleEditItem={handleEditItem}
              editType="HOSPITAL_HOURS"
              sList={list}
              isAdminUser={isAdminUser}
            />
          </Col>
        )}
        {(list !== null || list?.vising_Hours_list !== null || isAdminUser) && (
          <Col sm={4}>
            <Timingslot
              title="Visiting Hours"
              formatedSchedules={list?.vising_Hours_list}
              handleEditItem={handleEditItem}
              editType="VISITOR_HOURS"
              sList={list}
              isAdminUser={isAdminUser}
            />
          </Col>
        )}
        {(list !== null || list?.oPD_Hours_list !== null || isAdminUser) && (
          <Col sm={4}>
            <Timingslot
              title="OPD Hours"
              formatedSchedules={list?.oPD_Hours_list}
              handleEditItem={handleEditItem}
              editType="OPD_HOURS"
              sList={list}
              isAdminUser={isAdminUser}
            />
          </Col>
        )}
      </Row>
    </div>
    */}
    </>
  );
};

TimingslotContainer.defaultProps = {
  list: null,
  isAdminUser: false,
  handleEditItem: () => { },
};

export default memo(TimingslotContainer);
