import { useState } from "react";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import notificationIcon from "assets/images/svgs/bell.svg";
import { encrypt } from "utils/EncryptDecrypt";
import { createImageFromInitials, getRandomColor } from "utils/Utils";

const Notifications = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <>
      <ButtonDropdown
        className="notifications-menu position-initial"
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle color="link" className="min-w-0 p-0">
          <img src={notificationIcon} alt="" />
          <div className="notification-count position-absolute rounded-circle p-1">
            2
          </div>
        </DropdownToggle>
        <DropdownMenu className="pt-12 pb-12" right>
          <DropdownItem>
            <div className="d-flex align-items-center">
              <div className="text-complimentary f-12 line-h-12">21st Jan</div>
              <div className="dot mx-2"></div>
              <div className="text-complimentary f-12 line-h-12">
                09:30 p.m.
              </div>
            </div>
            <div className="d-flex align-items-center mt-2">
              <div className="user-image-section">
                <img
                  className="user-image rounded-circle"
                  src={createImageFromInitials(
                    30,
                    "Dr. John Hunter",
                    getRandomColor(encrypt(637).toString())
                  )}
                  width="30"
                  height="30"
                  alt=""
                />
              </div>
              <div className="ml-2 mr-1 black-color-accent f-12 line-h-12 font-weight-600">
                Dr. John Hunter{" "}
              </div>
              <div className="notification-txt f-12 line-h-12 text-truncate">
                viewed your profile
              </div>
            </div>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem>
            <div className="d-flex align-items-center">
              <div className="text-complimentary f-12 line-h-12">21st Jan</div>
              <div className="dot mx-2"></div>
              <div className="text-complimentary f-12 line-h-12">
                09:30 p.m.
              </div>
            </div>
            <div className="d-flex align-items-center mt-2">
              <div className="user-image-section">
                <img
                  className="user-image rounded-circle"
                  src={createImageFromInitials(
                    30,
                    "Dr. Dan Hunnington",
                    getRandomColor(encrypt(637).toString())
                  )}
                  width="30"
                  height="30"
                  alt=""
                />
              </div>
              <div className="ml-2 mr-1 black-color-accent f-12 line-h-12 font-weight-600">
                Dr. Dan Hunnington
              </div>
              <div className="notification-txt f-12 line-h-12 text-truncate">
                published a post “How COVID-19 inf inf inf
              </div>
            </div>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem>
            <div className="d-flex align-items-center">
              <div className="text-complimentary f-12 line-h-12">10th Jan</div>
              <div className="dot mx-2"></div>
              <div className="text-complimentary f-12 line-h-12">
                09:30 p.m.
              </div>
            </div>
            <div className="d-flex align-items-center mt-2">
              <div className="user-image-section">
                <img
                  className="user-image rounded-circle"
                  src={createImageFromInitials(
                    30,
                    "Jessica Ford",
                    getRandomColor(encrypt(637).toString())
                  )}
                  width="30"
                  height="30"
                  alt=""
                />
              </div>
              <div className="ml-2 mr-1 black-color-accent f-12 line-h-12 font-weight-600">
                Jessica Ford
              </div>
              <div className="notification-txt f-12 line-h-12 text-truncate">
                sent you a connection invite
              </div>
            </div>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem>
            <div className="d-flex align-items-center">
              <div className="text-complimentary f-12 line-h-12">19th Jan</div>
              <div className="dot mx-2"></div>
              <div className="text-complimentary f-12 line-h-12">
                09:30 p.m.
              </div>
            </div>
            <div className="d-flex align-items-center mt-2">
              <div className="user-image-section">
                <img
                  className="user-image rounded-circle"
                  src={createImageFromInitials(
                    30,
                    "Dr. Dan Fox",
                    getRandomColor(encrypt(637).toString())
                  )}
                  width="30"
                  height="30"
                  alt=""
                />
              </div>
              <div className="d-flex">
                <div className="ml-2 mr-1 black-color-accent f-12 line-h-12 font-weight-600">
                  Dr. Dan Fox
                </div>
                <div className="notification-txt f-12 line-h-12 text-truncate">
                  invites you to join his new group “Vaccinnation.
                </div>
              </div>
            </div>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem>
            <div className="d-flex align-items-center">
              <div className="text-complimentary f-12 line-h-12">21st Jan</div>
              <div className="dot mx-2"></div>
              <div className="text-complimentary f-12 line-h-12">
                09:30 p.m.
              </div>
            </div>
            <div className="d-flex align-items-center mt-2">
              <div className="user-image-section">
                <img
                  className="user-image rounded-circle"
                  src={createImageFromInitials(
                    30,
                    "Dr. John Hunter",
                    getRandomColor(encrypt(637).toString())
                  )}
                  width="30"
                  height="30"
                  alt=""
                />
              </div>
              <div className="ml-2 mr-1 black-color-accent f-12 line-h-12 font-weight-600">
                Dr. John Hunter
              </div>
              <div className="notification-txt f-12 line-h-12 text-truncate">
                sent you a connection invite
              </div>
            </div>
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </>
  );
};

export default Notifications;
