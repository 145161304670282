import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { ErrorMessage } from "formik";
const FormikInputField = ({
  field: { ...fields },
  form: { touched, errors, handleBlur, setFieldValue, ...rest },
  ...props
}) => (
  <FormGroup className="flex-grow-1">
    {props.label && (
      <Label htmlFor={props.id} className={"label-color"}>
        {props.label}
      </Label>
    )}
    <Input
      {...props}
      {...fields}
      onBlur={(e) => {
        if (props.type === "textarea") {
          handleBlur(e);
        } else {
          const val = (e.target.value || "").replace(/\s+/gi, " ");
          setFieldValue(fields.name, val.trim());
          handleBlur(e);
        }
      }}
      invalid={Boolean(touched[fields.name] && errors[fields.name])}
    />
    <ErrorMessage
      name={fields.name}
      render={(msg) => (
        <span className="text-danger d-inline-block  mt-1 line-height-error">
          {msg}
        </span>
      )}
    />
  </FormGroup>
);
export default FormikInputField;
