import { GET_GLOBAL_PROFILE_INSTITUTE, SET_GLOBAL_PROFILE_INSTITUTE } from "./Actions";

let initialState = {
    globalInstituteInfo: null,
};

function GlobalInstituteProfileReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GLOBAL_PROFILE_INSTITUTE:
      return {
        ...state,
        globalInstituteInfo: action.payload,
      };
    case SET_GLOBAL_PROFILE_INSTITUTE:
      return {
        ...state,
        globalInstituteInfo: action.payload,
      };
    default:
      return state;
  }
}

export default GlobalInstituteProfileReducer;
