import { memo } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Card, CardBody, CardHeader, Spinner } from "reactstrap";
import { encrypt } from "utils/EncryptDecrypt";
import { capitalizeFirstLetter, createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor } from "utils/Utils";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
};

const OtherLocationSlider = (props) => {
  const {
    items,
    loading
  } = props;

  return loading ? (
    <div className="d-flex justify-content-center mt-3">
      <div className="loading-spiner">
        <Spinner style={{ width: "2rem", height: "2rem" }} />
      </div>
    </div>
  ) : (
    items.length > 0 && (
      <div className="nex-icon-lists-w location-slider-w">
        <Slider style={{ width: "100%" }} {...settings}>
          {items.filter(location => location.profileId !== props.profileId).map((location, index) => (
            <div className="nex-icon-list" key={index}>
              <div className="nex-graphic">
                {/* <img src="" alt="" className="nex-avatar md" /> */}
                <img className="nex-avatar md" src={createImageFromInitials(50,location?.name.replace("Dr. ", ""),getRandomColor(encrypt(location?.profileId).toString()))} alt="profilepic" />
              </div>
              <div className="info">
                <div className="nex-text-xl small text-truncate"><a href={`./${location.profileId}`} target="_blank"> {location?.name}</a></div>
                {/* <div className="nex-text text-black text-truncate text-capitalize">{location?.type.replaceAll("_", " ")}</div> */}
                <div className="nex-text text-black text-truncate text-capitalize">{capitalizeFirstLetter(location?.type.replaceAll("_", " ").toLowerCase())}</div>
                <div className="nex-text text-black text-truncate">{getFullAddressWithLocationCityAndState(
                  { location: location?.location ?? "", city: location?.city ?? "", state: location?.state ?? ""}
                )}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  );
};

OtherLocationSlider.defaultProps = {
  items: [],
  loading: false,
};

export default memo(OtherLocationSlider);
