import React from 'react';
import { Row, Col, CardBody, Card, Modal, ModalHeader, ModalBody } from "reactstrap";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import del_red_icon from "assets/images/del-red-icon.png";
import plus_icon from "assets/images/plus-icon-lg.png";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import swal from "sweetalert";
import { OpinionService } from 'utils/OpinionService';
import { withAlert } from 'react-alert';
import CommonLoader from 'common/CommonLoader';
import { useState } from 'react';
import FormikTextAreaField from "components/forms/formikFields/FormikTextAreaField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { FAILED_TO_FETCH_DATA, FAILTOSAVERECOED } from 'utils/Constants';
import { useEffect } from 'react';
import { isAdmin, isPractitioner } from 'utils/UserHelper';

export default withAlert()(function ResponseInfo({ opinionId, alert }) {
    const [pannel_two_on, setPannel_two_on] = useState(true);
    const [responses, setResponses] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isResponseLoading, setIsResponseLoading] = useState(true);
    const [editingResponseId, setEditingResponseId] = useState(null);
    const [initialValues, setInitialValues] = useState({
        message: "",
        prescription: ""
    });

    const toggleModal = () => {
        if (modalOpen) {
            setInitialValues({
                message: "",
                prescription: ""
            });
        }
        setModalOpen(!modalOpen);
        setEditingResponseId(null);
    }

    const handleSubmit = (values) => {
        // console.log('Form Values:', values);
        const payload = {
            "message": values?.message,
            "prescription": values?.prescription
        }
        if (editingResponseId) {
            OpinionService.updateOpinionResponse(opinionId, editingResponseId, payload)
                .then((response) => {
                    const { status, data } = response;
                    if (status === 200) {
                        showSuccessMessage("Response details Updated Successfully.");
                        toggleModal();
                        getOpinionResponse(opinionId);
                    } else {
                        showErrorMessage(FAILTOSAVERECOED);
                        toggleModal();
                    }
                })
        } else {
            OpinionService.createOpinionResponse(opinionId, payload)
                .then((response) => {
                    const { status, data } = response;
                    if (status === 201) {
                        showSuccessMessage("Response details added Successfully");
                        toggleModal();
                        getOpinionResponse(opinionId);
                    } else {
                        showErrorMessage(FAILTOSAVERECOED)
                        toggleModal();
                    }
                })
        }
    }

    const populateFormForEdit = (responseId) => {
        const responseToEdit = responses.find(response => response.id === responseId);
        if (responseToEdit) {

            // If the response is found, populate the form with its details
            setInitialValues({
                message: responseToEdit.message,
                prescription: responseToEdit.prescription
            })
            setEditingResponseId(responseId); // Set the response being edited
            setModalOpen(true);
        }
    }

    const getOpinionResponse = (opinionId) => {
        OpinionService.getOpinionResponse(opinionId)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setResponses(data);
                    setIsResponseLoading(false);
                } else {
                    console.error(FAILED_TO_FETCH_DATA);
                    setIsResponseLoading(false);
                }
            })
    }

    useEffect(() => {
        getOpinionResponse(opinionId);
    }, [opinionId])

    const handleResponseDelete = (responseId) => {
        swal("Are you sure you want to remove this response?", {
            buttons: ["cancel", "yes"],
        })
            .then((yesRemove) => {
                if (yesRemove)
                    OpinionService.deleteOpinionResponse(opinionId, responseId)
                        .then((response) => {
                            const { status, data } = response;
                            if (status === 204) {
                                getOpinionResponse(opinionId);
                                showSuccessMessage("Response deleted successfully");
                            }
                            else {
                                showSuccessMessage("Something went wrong, Please try again later");
                            }
                        })
            })
    }

    return (
        <>
            <Card className={`nexogic-card-color-header ${pannel_two_on ? 'expanded' : ''}`}>
                <div className="card-header">
                    <h2>Opinion Response</h2>
                    {(isPractitioner() || isAdmin()) &&
                        <button className="add-button btn btn-link" type="button" id="nex-rds-plus-icon-btn" >
                            <svg onClick={toggleModal} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12" cy="12" r="11.25" fill="white" stroke="#46ABC9" stroke-width="1.5"/>
                                <path d="M11.8359 7.00195L11.8359 16.6738" stroke="#46ABC9" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M16.6719 11.8379L7 11.8379" stroke="#46ABC9" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>

                            {/*<img src={plus_icon} alt=""
                                onClick={toggleModal}
                            /> */} Add Opinion Response 
                        </button>
                    }
                    <button className="collapse-btn" type="button" onClick={() => setPannel_two_on(!pannel_two_on)}>
                    <svg width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2 10.5L10.5 2L19 10.5" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" /></svg>
                    </button>

                    

                </div>
                <div className="collapse-div">
                    <CardBody>
                        {isResponseLoading ?
                            <div className="text-center mt-3">
                                <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                            </div>
                            :
                            <>
                                {responses.length > 0 ?
                                    <>
                                    <ul className={`nex-question-lists ${(isPractitioner() || isAdmin()) ? 'nex-question-lists-action-space' : ''} `}>
                                        {
                                            responses.map((response) => (
                                                <li>
                                                    <div className='nex-text-lg text-black bold'>Message</div>
                                                    <p className='nex-text text-black mb-3'>{response?.message}</p>
                                                    <div className='nex-text-lg text-black bold'>Prescriptions</div>
                                                    <p className='nex-text text-black mb-3'>{response?.prescription}</p>
                                                    <CardBody className='px-0 nexogic-custom-scroll d-none'>
                                                        {/* {sortedTreatments.map((treatment) => ( */}
                                                        <Row key={"treatment.id"}>
                                                            <Col>
                                                                <div className='nexogic-treatment-item'>
                                                                    <div className='info'>
                                                                        <Row>
                                                                            <Col lg={12}>
                                                                                <div className='card-text lh-lg'>
                                                                                    <div><b>Message </b></div>
                                                                                    {response?.message}
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={12}>
                                                                                <div className='card-text lh-lg'>
                                                                                    <div><b>Prescriptions</b> </div>
                                                                                    {response?.prescription}
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    {(isPractitioner() || isAdmin()) &&
                                                                        <div className="action">
                                                                            <button
                                                                                type="button"
                                                                                className="visible-hover py-0 btn btn-link nex-e-exp-edit-btn"
                                                                                onClick={() => populateFormForEdit(response.id)} // Call populateFormForEdit when edit button is clicked
                                                                            >
                                                                                <img src={edit_blue_icon} alt="" />
                                                                            </button>

                                                                            <button
                                                                                type="button"
                                                                                className="visible-hover py-0 delete btn btn-link nex-e-exp-del-btn"
                                                                                onClick={() => handleResponseDelete(response?.id)}
                                                                            >
                                                                                <img src={del_red_icon} alt="" />
                                                                            </button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </Col>

                                                        </Row>
                                                        {/* ))} */}
                                                    </CardBody>
                                                </li>
                                            ))
                                        }
                                        </ul>
                                    </>
                                    :
                                    <div className="nexogic-not-found-text v2 mb-3">
                                        Response not Available
                                    </div>
                                }
                            </>
                        }
                    </CardBody>
                </div>
            </Card>

            {/* Modal for Form */}
            <Modal isOpen={modalOpen} toggle={toggleModal} className='nex-apply-jobs-modal'>
                <ModalHeader toggle={toggleModal} className='nex-apply-jobs-modal-header'><h2 className='nex-text-xl'>Add Opinion Response</h2></ModalHeader>
                <ModalBody className='bg-white'>
                    <Formik
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                        initialValues={initialValues}
                        onSubmit={(values, { resetForm }) => handleSubmit(values)}
                        children={(props) => {
                            return (
                                <>
                                    <RenderResponesForm
                                        {...props}
                                    />
                                </>
                            );
                        }}
                    >
                    </Formik>

                </ModalBody>
            </Modal>

        </>
    );
}
)

const validationSchema = Yup.object().shape({
    message: Yup.string().required("This field is required"),
    prescription: Yup.string(),
});

export const RenderResponesForm = (props) => {
    const { initialValues, handleSubmit } = props;

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <Field
                        id="message"
                        name="message"
                        value={initialValues.message}
                        autoComplete="off"
                        type="message"
                        label="Message"
                        component={FormikInputField}
                        placeholder={"message"}
                    />
                </div>
                <div className="form-group">
                    <Field
                        id="prescription"
                        name="prescription"
                        value={initialValues.prescription}
                        autoComplete="off"
                        type="prescription"
                        label="prescription"
                        component={FormikTextAreaField}
                        placeholder={"prescription"}
                    />
                </div>
                <div className="form-group mt-3">
                    <input type="submit" value="Save" className='nex-btn-primary btn' />
                </div>
            </form>
        </>
    )
}