import { Card, CardBody } from "reactstrap";
import AppliedJobsList from "./Components/AppliedJobList";

const AppliedJobsContent = () => {
    return (
        <>
            <Card className="bg-transparent border-0">
                <CardBody className="p-0">
                    <AppliedJobsList />
                </CardBody>
            </Card>
        </>
    );
};

export default AppliedJobsContent;
