import React from 'react'

export default function AttachFileLists(props) {
  return (
    <div className="nexogic-upload-lists-card">
        {props.attachData.map((item, ind)=>{
            return (<div className="item" key={'attachment_'+ind}>
            <div className="card-body">
                <div className="card-img">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="38"
                        height="46"
                        fill="none"
                        viewBox="0 0 38 46"
                        className='icon'
                    >
                        <path
                            fill="#000"
                            stroke="#fff"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M23.334 1.333H6a4.333 4.333 0 00-4.333 4.334v34.667A4.333 4.333 0 006 44.666h26a4.333 4.333 0 004.334-4.334v-26l-13-13zm8.666 39H6V5.668h15.167V16.5H32v23.834z"
                        ></path>
                    </svg>
                </div>
                <div className="card-info">
                    <h3 className="card-title">{item.name}</h3>
                    <p className='card-text text-muted small'> {/*2 seconds left*/} {item.size} bytes</p>
                </div>
                <div className="actions">
                    <div className="btns">
                        <span className='btn'>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    fill="#001244"
                                    d="M1.4 14L0 12.6 5.6 7 0 1.4 1.4 0 7 5.6 12.6 0 14 1.4 8.4 7l5.6 5.6-1.4 1.4L7 8.4 1.4 14z"
                                ></path>
                            </svg>
                        </span>
                        <span className='btn'>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="18"
                                fill="none"
                                viewBox="0 0 16 18"
                            >
                                <path
                                    fill="#001244"
                                    d="M3 18c-.55 0-1.021-.196-1.413-.588A1.922 1.922 0 011 16V3H0V1h5V0h6v1h5v2h-1v13c0 .55-.196 1.021-.588 1.413A1.922 1.922 0 0113 18H3zM13 3H3v13h10V3zM5 14h2V5H5v9zm4 0h2V5H9v9z"
                                ></path>
                            </svg>
    
                        </span>
                        <span className="btn">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    stroke="#001244"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.6"
                                    d="M20 12c0 4.8-3.512 8-7.845 8-3.521 0-6.363-1.868-7.355-4.8M4 12c0-4.8 3.512-8 7.845-8 3.522 0 6.363 1.868 7.355 4.8"
                                ></path>
                                <path
                                    stroke="#001244"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.6"
                                    d="M8 16l-3.2-.8-.8 3.2M16 8l3.2.8.8-3.2"
                                ></path>
                            </svg>
                        </span>
                    </div>
                    <p className='card-text text-muted upload-percentage-txt'>44%</p>
                </div>
            </div>
            <div className="upload-indicator progress">
                <span className='progress-bar' style={{ width: '40%' }}></span>
            </div>
        </div>)
        })}
    
</div>
  )
}
