import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import suitcase from "assets/images/suitcase.png";
import address_icon from "assets/images/location-marker.png";
import Slider from "react-slick";
import { PractitionerService } from "utils/PractitionerService";
// import { createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import { createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor } from "utils/Utils";
import { RenderAllPractitionersModal } from "./RenderAllPractitionersModal";

let settings = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      }
    },
  ],
};

const PractitionersContent = (props) => {
  const history = useHistory();
  const [pannel_on, setPannel_on] = useState(true);
  const { institution, setShowPractitionersContentOnTabScroll } = props;
  const [practitioners, setPractitioners] = useState([]);
  const [isAllPractitionersOpen, setIsAllPractitionersOpen] = useState(false);
  const institutionID = institution.profileId;
  const toggleAllPractitioners = () =>
    setIsAllPractitionersOpen(!isAllPractitionersOpen);

  const allPractitionersWorkHere = () => {
    const impProps = {
      institution: props.institution,
      isAdmin: props.isAdmin,
      isEditable: props.isEditable,
      practitionerId: props.practitionerId,
      institutionGuid: props.institution?.guid,
    };
    history.push(`/institution/${institutionID}/view-all-practitioners`, {
      institution: props.institution,
      isAdmin: props.isAdmin,
      isEditable: props.isEditable,
      practitionerId: props.practitionerId,
      institutionGuid: props.institution?.guid,
    })
  }

  useEffect(() => {
    PractitionerService.getPractitionersAssociatedtoInstitutionWithPagination(
      institution?.guid
    ).then((response) => {
      const { status, data } = response;
      if (status === 200) {
        setPractitioners(data.contents);
      } else {
        console.log("Something went wrong");
      }
    });
  }, [institution]);

  useEffect(()=>{
    if(practitioners.length > 0){
      setShowPractitionersContentOnTabScroll(true);
    }else{
      setShowPractitionersContentOnTabScroll(false);
    }
  }, [practitioners])

  return (
    practitioners.length > 0 ?
      <div
        className={`card nexogic-card-color-header practitioners-slider-card  ${pannel_on ? "expanded" : ""
          }`}
        id="practitioners_pannel"
      >
        <div className="card-header">
          <h2>Practitioners who work here</h2>
          <button
            className="collapse-btn"
            type="button"
            onClick={() => setPannel_on(!pannel_on)}
          >
              <svg width={17}  height={10} viewBox="0 0 17 10" fill="none"  xmlns="http://www.w3.org/2000/svg" >
                  <path
                      d="M2.25 1.625L8.625 8L15 1.625"
                      stroke="black"
                      strokeWidth={3}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  />
              </svg>
          </button>
          {practitioners.length >= 10 && (
            <>
              <span
                id="nex-ip-practitioners-view-all-btn"
                className="btn btn-link ml-auto"
                // onClick={() => toggleAllPractitioners()}
                onClick={allPractitionersWorkHere}
              >
                View All
              </span>
              {institution && (
                <RenderAllPractitionersModal
                  isOpen={isAllPractitionersOpen}
                  toggle={toggleAllPractitioners}
                  institutionGuid={institution?.guid}
                />
              )}
            </>
          )}
        </div>
        <div className={`collapse-div`}>
          <div className={`card-body`}>
            <Slider {...settings}>
              {practitioners.map((practitioner, idx) => (
                  <div className="slider-item slider-item-aside" key={idx}>
                    <div className="slider-item-aside-inner">
                      <div className="card-img">
                        {practitioner?.avatarId ? (
                          <img
                            src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${practitioner?.profileGuid}/${practitioner?.avatarId}`}
                            alt="#"
                          />
                        ) : (
                          <img
                            src={createImageFromInitials(
                              50,
                              practitioner?.name.replace("Dr. ", ""),
                              getRandomColor(
                                encrypt(practitioner?.profileGuid).toString()
                              )
                            )}
                            alt="#"
                          />
                        )}
                      </div>
                      <div className="info">
                      <h3 className="nex-text-xl text-truncate">
                        <a
                          href={`../profile/${practitioner?.profileId}`}
                          target="_blank"
                        >
                          {practitioner?.name}
                        </a>
                      </h3>
                      <div className="nex-text bold nex-text-blue text-truncate mb-2">
                        {practitioner?.primarySpeciality}
                        {practitioner?.superSpeciality ? ', '+practitioner?.superSpeciality : ''}
                      </div>
                      <div className="nex-text text-truncate">
                          <span className="icon"><img src={suitcase} alt="" /></span>
                          {new Date().getFullYear() -
                            practitioner?.practicingSince >
                            1 ? (
                            <>
                              {" "}
                              {new Date().getFullYear() -
                                practitioner?.practicingSince}
                              {"+ years experience"}
                            </>
                          ) : (
                            "1 year experience"
                          )}
                      </div>
                      <p className="nex-text text-truncate text-black">
                        <span className="icon"><img src={address_icon} alt="" /></span>
                        {getFullAddressWithLocationCityAndState({ location: practitioner?.location ?? "", city: practitioner?.city ?? "", state: practitioner?.state ?? "" })}
                      </p>
                      </div>
                    </div>
                  </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      : ""
  );
};

export default PractitionersContent;
