import React from 'react';
import {  Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReportForm from './ReportForm.js';

export const RenderReportModal = (props) => {
  const {
    isOpen,
    toggle
  } = props;

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} id="connection-modal" className="modal-dialog-scrollable modal-dialog-centered">
        <ModalHeader toggle={toggle}>Report</ModalHeader>
        <ModalBody className="p-4">
          <ReportForm {...props}/>
        </ModalBody>
      </Modal>
    </div>
  );
}

