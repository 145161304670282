
import { Button } from 'reactstrap';
import { Link } from "react-router-dom";
import { useEffect } from 'react';
import { useState } from 'react';
import NewCreateJob from 'pages/Jobs/Components/CreateJob/components/NewJobUi/NewCreateJob';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';




function CreateJobList(props) {
    const [isDraftJob, setIsDraftJob] = useState({ status: null });
    const [newSteps, setNewSteps] = useState(1);
    const { jobId } = useParams();

    return (
        <div className="nex-job-flex">
            <div className="nex-job-container">
                {/* include formic job from  */}
                {/*{...props} {...mainProps} isLoading={isLoading}*/}
                <NewCreateJob steps={newSteps} onSetSteps={(item) => setNewSteps(item)} {...props} jobId={jobId} />
            </div>
        </div>
    );
}

export default CreateJobList;