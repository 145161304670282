import { useContext, createContext, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
  OAuthProvider,
  signInWithEmailAndPassword,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  EmailAuthProvider,
  linkWithCredential
} from 'firebase/auth';

import { auth } from 'firebaseconfig';
import CommonLoader from "common/CommonLoader";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const history = useHistory();
  const [user, setUser] = useState(null);

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider)
      .then((result) => {
        setUser(result.user);
        return result.user;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
    // signInWithPopup(auth, provider);
    // signInWithRedirect(auth, provider)
  };

  const facebookSignIn = async () => {
    const provider = new FacebookAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      return result.user;
    }
    catch (error) {
      console.error(error);
      throw error;
    };
  };

  // const facebookSignUp = () => {
  //   const provider = new FacebookAuthProvider();
  //   return signInWithPopup(auth, provider)
  //     .then((result) => {
  //       const {displayName,email, phoneNumber,accessToken,stsTokenManager,reloadUserInfo} = result.user;
  //       const userInfo = {name:displayName,email:email, phoneNumber: phoneNumber,idToken:accessToken,refreshToken:stsTokenManager?.refreshToken,profiledetails:reloadUserInfo.customAttributes !== undefined ? JSON.parse(reloadUserInfo?.customAttributes):null};

  //       setUser(userInfo);
  //       return userInfo;
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       throw error;
  //     });
  // };

  const facebookSignUp = async () => {
    const provider = new FacebookAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const { displayName, email, phoneNumber, accessToken, stsTokenManager, reloadUserInfo } = result.user;

      const userInfo = {
        name: displayName,
        email: email,
        phoneNumber: phoneNumber,
        idToken: accessToken,
        refreshToken: stsTokenManager?.refreshToken,
        profiledetails: reloadUserInfo.customAttributes !== undefined ? JSON.parse(reloadUserInfo?.customAttributes) : null,
        provider: result?.providerId
      };
      setUser(userInfo);
      return userInfo;
    } catch (error) {
      console.error(error);
      throw error;
    }
};

const googleSignUp = () => {
  const provider = new GoogleAuthProvider();
  return signInWithPopup(auth, provider)
    .then((result) => {
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
      const { displayName, email, phoneNumber, accessToken, stsTokenManager, reloadUserInfo } = result.user;
      const userInfo = { name: displayName, email: email, phoneNumber: phoneNumber, idToken: accessToken, refreshToken: stsTokenManager?.refreshToken, profiledetails: reloadUserInfo.customAttributes !== undefined ? JSON.parse(reloadUserInfo?.customAttributes) : null, provider: result?.providerId };

      setUser(userInfo);
      return userInfo;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

const appleSignUp = () => {
  const provider = new OAuthProvider('apple.com');
  return signInWithPopup(auth, provider)
    .then((iosresult) => {
      const { displayName, email, phoneNumber, accessToken, stsTokenManager, reloadUserInfo } = iosresult.user;
      const userInfo = { name: displayName, email: email, phoneNumber: phoneNumber, idToken: accessToken, refreshToken: stsTokenManager?.refreshToken, profiledetails: reloadUserInfo.customAttributes !== undefined ? JSON.parse(reloadUserInfo?.customAttributes) : null, provider: iosresult?.providerId };

      setUser(iosresult.user);
      const credential = OAuthProvider.credentialFromResult(iosresult);
      return userInfo;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

const appleSignIn = () => {
  const provider = new OAuthProvider('apple.com');
  // provider.addScope('email');
  // provider.addScope('name');
  return signInWithPopup(auth, provider)
    .then((iosresult) => {
      setUser(iosresult.user);
      const credential = OAuthProvider.credentialFromResult(iosresult);
      const accessToken = credential.accessToken;
      const idToken = credential.idToken;
      return iosresult.user;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

const signInWIthEmail = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      setUser(userCredential.user);
      return userCredential.user;
    })
    .catch((error) => {
      // const errorCode = error.code;
      const errorMessage = error.message;
      // console.error("Error code:", errorCode);
      console.error("Error message:", errorMessage);
      showErrorMessage("Email or Password is incorrect");
    });
}

const signUpWIthEmail = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const { displayName, email, phoneNumber, accessToken, stsTokenManager, reloadUserInfo } = userCredential.user;
      const userInfo = { name: displayName, email: email, phoneNumber: phoneNumber, idToken: accessToken, refreshToken: stsTokenManager?.refreshToken, profiledetails: reloadUserInfo.customAttributes !== undefined ? JSON.parse(reloadUserInfo?.customAttributes) : null, provider: userCredential?.providerId };

      return userInfo;
    })
    .catch((error) => {
      //   const errorCode = error.code;
      // const errorMessage = error.message;
      // console.error("Error code:", errorCode);
      // console.error("Error message:", errorMessage);
      showErrorMessage("Email account already exists");
    });
}

//FOr Mobile OTP Login

const signInWithPhone = (phoneNumber) => {
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        console.log("Captcha resolved");
      },
      'expired-callback': () => {
        console.log("Captcha expired");
      }
    });
    window.recaptchaVerifier.render().catch(error => {
      console.error("ReCAPTCHA render failed:", error);
    });
  }
  return signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier)
    .then(confirmationResult => {
      window.confirmationResult = confirmationResult; // You might want to handle this with better state management
      return confirmationResult;
    })
    .catch(error => {
      console.error("Error during signInWithPhoneNumber:", error);
      throw error;
    });
};

const verifyOTP = (code) => {
  if (window.confirmationResult) {
    return window.confirmationResult.confirm(code)
      .then((result) => {
        setUser(result.user);
        return result.user;
      })
      .catch((error) => {
        showErrorMessage("Incorrect OTP ");
        console.error('Error verifying OTP:', error);
        throw error;
      });
  } else {
    throw new Error("No confirmationResult available to verify the OTP.");
  }
};

const signUpWIthMobileOTP = (code) => {
  if (window.confirmationResult) {
    return window.confirmationResult.confirm(code)
      .then((userCredential) => {
        const { displayName, email, phoneNumber, accessToken, stsTokenManager, reloadUserInfo } = userCredential.user;
        const userInfo = { name: displayName, email: email, phoneNumber: phoneNumber, idToken: accessToken, refreshToken: stsTokenManager?.refreshToken, profiledetails: reloadUserInfo.customAttributes !== undefined ? JSON.parse(reloadUserInfo?.customAttributes) : null, provider: userCredential?.providerId };
        return userInfo;
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // console.error("Error code:", errorCode);
        // console.error("Error verifying OTP:", errorMessage);
        showErrorMessage("Invalid verification code");
      });
  } else {
    throw new Error("No confirmationResult available to verify the OTP.");
  }
}

const logOut = () => {
  signOut(auth)
}

useEffect(() => {
  const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });
  return () => {
    unsubscribe();
  };
}, []);

const authValue = useMemo(() => ({ googleSignIn, googleSignUp, appleSignIn, appleSignUp, facebookSignIn, facebookSignUp, signInWIthEmail, signUpWIthEmail, signInWithPhone, verifyOTP, signUpWIthMobileOTP, logOut, user }), [user]);

return (
  <AuthContext.Provider value={authValue}>
    {children}
  </AuthContext.Provider>
);
};

export const UserAuth = () => {
  return useContext(AuthContext);
};