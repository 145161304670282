import React, {useState, useEffect} from 'react'
const CountDown = (props) => {
  const {time, onComplete} = props
  const [timer, setTimer] = useState(time)

  useEffect(() => {
    if(timer>0) {
      setTimeout(()=>{
      setTimer(timer=>--timer)
    }, 1000)
  } else {
      onComplete()
    }
  },[timer])

  return (
    <div className="d-inline">
      {!!props.digital?
        <span>( {`${timer}`} )</span>
        :
        <span>{`${timer} sec(s) left`}</span>
      }
    </div>
  )
}
export default CountDown
