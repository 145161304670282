import React, { useState } from "react";
import DefaultImg from "../../../../assets/images/defaultPic.png";
import Dots from "../../images/ellipsis-h-solid.svg";
import SearchChatIcon from "../../images/Search_Chat.png";
import ChatMessage from "../../components/ChatMessage/ChatMessage";
import { titleCase } from "../../../../utils/titleCase";
import "./ChatScreen.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";

const ChatScreen = (props) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const toggleDropDown = () => setShowDropDown(!showDropDown);

  const [function_delete, setfunction_delete] = useState(0);

  const delete_all_message = () => {
    setfunction_delete((prev) => prev + 1);
    setShowDropDown(!showDropDown);
  };

  return (
    <div className="nexogic-chat-screen">
      <div className="nexogic-chat-header">
        <div className="thumb">
          <img className="card-img"
            src={
              props.header_profile_pic != undefined
                ? `${process.env.REACT_APP_IMAGE_BASE_URL}/${props.selected_id}/${props.header_profile_pic}`
                : DefaultImg
            }
            alt="#"
          />
        </div>
        <div className="nexogic-chat-header-info">
          <h3 className="card-title">
            {props.header_title ? (
              <>
                {titleCase(props.header_title) + " "}
              </>
            ) : (
              ""
            )}
            {props.header_first_name ? (
              <>
                {titleCase(props.header_first_name) + " "}
              </>
            ) : (
              ""
            )}
            {props.header_last_name ? (
              <>
                {titleCase(props.header_last_name)}
              </>
            ) : (
              ""
            )}
            {props.header_qualification &&
              <span className="card-text text-truncate "><small className="text-muted">{" " + props.header_qualification}</small></span>
            }
          </h3>
          {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
            <p className="card-text"><small className="text-muted">Active | Last Seen</small></p>
          }
          {showDropDown && (
            <div className="dropdown__cls">
              <div
                className="dropdown_chat_options"
                onClick={delete_all_message}
              >
                Delete All
              </div>
            </div>
          )}
        </div>

        <hr />
      </div>

      <div className="nexogic-chat-body">
        <ChatMessage
          dynamic_channel={props.dynamic_channel && props.dynamic_channel}
          get_history_new={props.get_history}
          connections={props.connections}
          fetch_recent_message={props.fetch_recent_message}
          selected_id={props.selected_id}
          firstName={props.header_first_name}
          lastName={props.header_last_name}
        />
      </div>
    </div>
  );
};

export default ChatScreen;
