import React, { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const SEOWrapper = (props) => {
  const { title, children, content } = props;
  const [pageTitle, setPageTitle] = useState("");
  const [contentData, setContentData] = useState("");

  useEffect(() => {
    if (title) {
      setPageTitle(title);
    }
    if (content) {
      setContentData(content);
    }
    // return () => {
    //   document.title = "Nexogic";
    //   document.description = "Nexogic";
    // };
  }, [title, content]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name={pageTitle} content={pageTitle} />
        <meta name="description" content={contentData} />
      </Helmet>
      {children}
    </>
  );
};

SEOWrapper.defaultProps = {
  title: "Nexogic",
  content: "Nexogic",
};

SEOWrapper.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  // children: PropTypes.object.isRequired,
};

export default memo(SEOWrapper);
