import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import AppliedJobsContent from "./AppliedJobsContent";
import AllJobs from "./Components/AllJobs";
import CreateJobContainer from "./CreateJobContainer_Trash";
import JobListDraft from "./Components/JobListDraft";
import JobListSubmitted from "./Components/JobListSubmitted";
import JobListPublished from "./Components/JobListPublished";
import JobListClosed from "./Components/JobListClosed";

const JobsNavContent = () => {
  let { path, url } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${url}/all-jobs`} component={AllJobs} />
        <Route path={`${url}/posted-jobs/draft`} component={JobListDraft} />
        <Route path={`${url}/posted-jobs/submitted`} component={JobListSubmitted} />
        <Route path={`${url}/posted-jobs/published`} component={JobListPublished} />
        <Route path={`${url}/posted-jobs/closed`} component={JobListClosed} />

        <Route path={`${url}/applied-jobs`} component={AppliedJobsContent} />
        <Route path={`${url}/post-a-job`} component={CreateJobContainer} />
        <Route path={`${url}/update-a-job/:jobId`} component={CreateJobContainer} />
        <Redirect exact to={`${url}/all-jobs`} />
      </Switch>
    </>
  );
};
export default JobsNavContent;
