import logo_white from "assets/images/logo-white-transparent.png";
import google_img from "assets/images/social-google-img.png";
import facebook_img from "assets/images/facebook-social-img.png";
import apple_img from "assets/images/apple-social-img.png";
import CountDown from "components/CountDown";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import ReactCodeInput from "react-verification-code-input";
import { useState } from "react";
import { Button } from "reactstrap";

const PatientOtpVarify = (props) => {
    const [verifyCode, setVerifyCode] = useState("");
    useEffect(() => {
        AOS.init();
    }, [])
    
    return (
        <div className="nex-bg-light-blue">
            <main className="nex-login-register--w">
                <div className="container sm d-flex">
                    <div className="nex-form-aside">
                        <div className="logo" data-aos="fade-up" data-aos-duration="800" data-aos-delay="100">
                            <a href="/"><img src={logo_white} alt="" /></a>
                        </div>
                        
                        <h2 className="nex-text-xxl" data-aos="fade-up" data-aos-duration="800" data-aos-delay="150">Verify your <br/>OTP</h2>
                    </div>
                    <div className="nex-form-fields--w">
                        <div className="nex-form-top-slot nex-mob-hide" data-aos="fade-down" data-aos-duration="800" data-aos-delay="100">
                            <p className="nex-text-lg text-right"><a href="#" className="text-gray nex-cross-btn">&times;</a></p>
                        </div> 
                        <div className="nex-form-center-slot">
                            <div className="nex-form-container">
                                <p className="nex-text-lg text-black mb-5" data-aos="fade-down" data-aos-duration="800" data-aos-delay="150">Verify your account by entering the 6 digit code we sent via text to your mobile number ******6485</p>            
                                
                                <div className="form-group nex-otp-group" data-aos="fade-down" data-aos-duration="800" data-aos-delay="200">
                                <ReactCodeInput
                                    id="vo_fields"
                                    //ref={otpRef}
                                    type="number"
                                    placeholder={[" ", " ", " ", " ", " ", " "]}
                                    fields={6}
                                    fieldWidth={42}
                                    fieldHeight={38}
                                    onChange={(val) => setVerifyCode(val)}
                                    className="verification-code-masked-input"
                                />
                                   {/**
                                    <input type="text" name="" id="" className="form-control nex-otp-inp" placeholder="0" />
                                    <input type="text" name="" id="" className="form-control nex-otp-inp" placeholder="0" />
                                    <input type="text" name="" id="" className="form-control nex-otp-inp" placeholder="0" />
                                    <input type="text" name="" id="" className="form-control nex-otp-inp" placeholder="0" />
                                    <input type="text" name="" id="" className="form-control nex-otp-inp" placeholder="0" />
                                    <input type="text" name="" id="" className="form-control nex-otp-inp" placeholder="0" />
                                     */}
                                    </div>
                                <p className="nex-text-lg text-right text-black mb-5" data-aos="fade-down" data-aos-duration="800" data-aos-delay="250">Your OTP expires in {/*<CountDown
                    time={60}
                    digital
                    //onComplete={() => setCanResendOTP(true)}
                                    />*/}</p>
                                <div className="form-group" data-aos="fade-down" data-aos-duration="800" data-aos-delay="300">
                                <Button
                    id="nex-continue-form-next2"
                    className="btn btn-primary lg w-100"
                    type="submit"
    
                    >
                    Verify
                    </Button>
                                    
                                </div>
                                <p className="nex-text-lg pt-3 pb-2" data-aos="fade-down" data-aos-duration="800" data-aos-delay="350"><a href="" className="text-light-purple-dark">Entered a wrong number?</a></p>
                                <p className="nex-text-lg" data-aos="fade-down" data-aos-duration="800" data-aos-delay="400"><a href="" className="text-light-purple-dark">Receive OTP via email instead</a></p>
                            </div>
                        </div>
                        <div className="nex-form-bottom-slot">
                            <p className="nex-text-lg text-left">If you are having problem receiving OTP <br/>
Please contact to  <a href="#" className="text-light-purple">Support</a></p>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default  PatientOtpVarify;