import { faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash";
import { memo, useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import { InstitutionServices } from "utils/InstitutionServices";
import OtherLocationSlider from "./OtherLocationSlider";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";

const Branches = (props) => {
  const [modalisOpen, setmodalIsOpen] = useState(false);
  const modalToggle = () => setmodalIsOpen(!modalisOpen);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const history = useHistory();
  const { isEditable, isAdmin, institution, alert, setShowBranchesOnTabScroll } = props;
  const [otherBranches, setOtherBranches] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [oBranchFlag, setOBranchFlag] = useState(false);
  const [pannel_on, setPannel_on] = useState(true);

  const getAllOtherBranches = async () => {
    setOBranchFlag(true);
    const response = await InstitutionServices.instituteOtherLocation(
      institution?.profileId
    );
    if (response.status === 200) {
      setOBranchFlag(false);
      setOtherBranches(response.data);
    } else {
      setOBranchFlag(false);
    }
  };

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
    if (institution?.profileId) {
      getAllOtherBranches();
    }
  }, [institution?.profileId])

  useEffect(() => {
    if ((otherBranches && otherBranches?.length > 1 && !isAdmin) || isAdmin) {
      setShowBranchesOnTabScroll(true);
    } else {
      setShowBranchesOnTabScroll(false);
    }
  }, [otherBranches])

  return (
    <>
      {((otherBranches && otherBranches?.length > 1 && !isAdmin) || isAdmin) && (
        <Card className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`}>
          <div className="card-header"><h2>Other Branches</h2>
            <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
              {/*<img src={pannel_arrow} alt="" />*/}
              <svg width={17} height={10} viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path
                  d="M2.25 1.625L8.625 8L15 1.625"
                  stroke="black"
                  strokeWidth={3}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className={`collapse-div`}>
            <CardBody>
              <Row className={otherBranches && otherBranches?.length > 0 ? "" : "align-items-center"}>
              </Row>
              <OtherLocationSlider
                items={otherBranches}
                loading={oBranchFlag}
                profileId={institution?.profileId}
              />
              {!isEditable && isEmpty(currentUser) && (
                <Row className="py-5 my-4">
                  <Col xs="12">
                    <p className="text-primary font-weight-bold font-italic">
                      Please Sign In to access the full profile.
                    </p>
                    <Button
                      color="primary"
                      onClick={() => history.push("/login")}
                    >
                      Login
                    </Button>
                  </Col>
                </Row>
              )}
            </CardBody>
          </div>
        </Card>
      )}
    </>
  );
};

export default withAlert()(memo(Branches));
