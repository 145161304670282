import { useFormikContext } from "formik";
import React, { useState, useEffect } from "react";
import { Button, Card, CardBody } from "reactstrap";

const AllergiesForm = (props) => {
    const { values, setFieldValue } = useFormikContext();
    const [selectedAllergies, setSelectedAllergies] = useState([]);
    const [allergiesOptions, setAllergiesOptions] = useState([]);
    const [customAllergy, setCustomAllergy] = useState('');

    const predefinedAllergies = [
        { "label": "Pollen", "value": "Pollen" },
        { "label": "Dust mites", "value": "Dust mites" },
        { "label": "Pet dander", "value": "Pet dander" },
        { "label": "Mold", "value": "Mold" },
        { "label": "Food", "value": "Food" },
        { "label": "Insect stings", "value": "Insect stings" },
        { "label": "Medications", "value": "Medications" },
        { "label": "Latex", "value": "Latex" },
        { "label": "Chemicals", "value": "Chemicals" },
        { "label": "Cosmetics", "value": "Cosmetics" },
        { "label": "Metals", "value": "Metals" },
        { "label": "Plants", "value": "Plants" },
        { "label": "Smoke", "value": "Smoke" },
        { "label": "Sunlight", "value": "Sunlight" },
        { "label": "Cold", "value": "Cold" },
        { "label": "Heat", "value": "Heat" },
        { "label": "Exercise-induced", "value": "Exercise-induced" },
        { "label": "Environmental factors", "value": "Environmental factors" },
        { "label": "Perfumes", "value": "Perfumes" },
        { "label": "Dyes", "value": "Dyes" },
        { "label": "Fabrics", "value": "Fabrics" },
        { "label": "Pesticides", "value": "Pesticides" },
        { "label": "Plastics", "value": "Plastics" },
        { "label": "Shellfish", "value": "Shellfish" },
        { "label": "Nuts", "value": "Nuts" },
        { "label": "Fungi", "value": "Fungi" },
        { "label": "Bee products", "value": "Bee products" },
        { "label": "Anesthetics", "value": "Anesthetics" },
        { "label": "Vaccines", "value": "Vaccines" },
        { "label": "Sunscreen", "value": "Sunscreen" },
        { "label": "Air pollutants", "value": "Air pollutants" }
    ];
    
    

    useEffect(() => {
        // Initialize the options with predefined allergies plus any existing values in Formik
        setAllergiesOptions([...predefinedAllergies, ...values.allergies.map(allergy => ({ label: allergy, value: allergy }))]);
        setSelectedAllergies(values.allergies || []);
    }, [values.allergies]);

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        setSelectedAllergies((prev) => {
            if (prev.includes(value)) {
                return prev.filter((allergy) => allergy !== value);
            } else {
                return [...prev, value];
            }
        });
    };

    const handleCustomAllergyChange = (e) => {
        setCustomAllergy(e.target.value);
    };

    const addCustomAllergy = () => {
        if (customAllergy.trim() !== '' && !allergiesOptions.some(option => option.value === customAllergy.trim())) {
            const newAllergy = { label: customAllergy.trim(), value: customAllergy.trim() };
            setAllergiesOptions((prev) => [...prev, newAllergy]);
            setSelectedAllergies((prev) => [...prev, customAllergy.trim()]);
            setCustomAllergy(''); // Clear the input field
        }
    };

    const handleClose = () => {
        setFieldValue('allergies', selectedAllergies);
        props.toggle(); // Close the modal
    };

    return (
        <>
            {props.modal &&
                <Card className="nex-full-covered-card">
                    <CardBody>
                        <h2 className="nex-text-xl mb-3">Please specify your Allergies</h2>
                        <div className="nex-checkbox-lists nexogic-custom-scroll">
                            <ul>
                                {allergiesOptions.map((item) => (
                                    <li key={item.value}>
                                        <div className="nex-check">
                                            <input
                                                type="checkbox"
                                                value={item.value}
                                                onChange={handleCheckboxChange}
                                                checked={selectedAllergies.includes(item.value)}
                                            />
                                        </div>
                                        <div className="label">{item.label}</div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="nex-custom-allergy">
                            <label htmlFor="customAllergy">Add your own:</label>
                            <input
                                type="text"
                                id="customAllergy"
                                value={customAllergy}
                                onChange={handleCustomAllergyChange}
                            />
                            <Button className="nex-btn-secondary" onClick={addCustomAllergy}>
                                Add Custom Allergy
                            </Button>
                        </div>
                        <Button className="nex-btn-primary" onClick={handleClose}>
                            Add
                        </Button>
                    </CardBody>
                </Card>
            }
        </>
    );
};

export default AllergiesForm;
