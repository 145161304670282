import { useState } from "react";
import { useEffect } from "react";

const FooterMobileMenu = ()=>{
    const ls_app_val = localStorage.getItem('app_status') || null; 
    const [isApp, setIsAPP] = useState(false);
    //const is_browser = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Win/i.test(navigator.userAgent);
    //const is_react_native = /ReactNativeWebView|WebView/i.test(navigator.userAgent);
    useEffect(()=>{
        const queryParams = new URLSearchParams(window.location.search);
        const inApp = queryParams.get('inapp');
        if(inApp === '1'  || ls_app_val == 'inapp'){
            setIsAPP(true);
            localStorage.setItem('app_status','inapp'); 
            document.body.classList.add('view_in_app');
        }
        
    }, []);

    return (
        <>
        {isApp && 
        <div class="mob-footer-fixed">
        <ul>
            <li class="active"><a href="">
            <div class="icon"><svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 21V11H13V21M1 8L10 1L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </div>
            <span class="label">Home</span>
            </a></li>
            <li><a href="">
            <div class="icon"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 15L11.6167 11.6167M13.4444 7.22222C13.4444 10.6587 10.6587 13.4444 7.22222 13.4444C3.78578 13.4444 1 10.6587 1 7.22222C1 3.78578 3.78578 1 7.22222 1C10.6587 1 13.4444 3.78578 13.4444 7.22222Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
        </div>
            <span class="label">Search</span>
            </a></li>
            <li><a href="">
            <div class="icon"><svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 7H19M1 1H19M1 13H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
        </div>
            <span class="label">Menu</span>
            </a></li>
            <li><a href="">
            <div class="icon next-fp-avatar"></div>
            <span class="label">Me</span>
            </a></li>
            
        </ul>
        </div>
        }
        
        </>
    );
}
export default FooterMobileMenu;