import React from 'react'

export default function IconVideo() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="10"
    fill="none"
    viewBox="0 0 14 10"
  >
    <path
      fill="currentcolor"
      d="M1.4.52C.616.52 0 1.136 0 1.92v6.16c0 .784.616 1.4 1.4 1.4h7.56c.784 0 1.4-.616 1.4-1.4V1.92c0-.784-.616-1.4-1.4-1.4H1.4zm12.6.928l-3.08 1.645v3.815L14 8.553V1.448z"
    ></path>
  </svg>
  )
}
