import { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import CreateGroupForm from './CreateGroupForm.js';
import { useEffect } from 'react';

export const CreateGroupModal = (props) => {
  const {
    isOpen,
    toggle
  } = props;
  // useEffect(() => {
  //   console.log(props);
  // }, [props])


  const [groupHeaderText, setGroupHeaderText] = useState("Create Group");
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} id="connection-modal" className="modal-dialog-scrollable modal-dialog-centered" backdrop="static">
        <ModalHeader toggle={toggle}>{groupHeaderText}</ModalHeader>
        <ModalBody>
          <CreateGroupForm {...props} onDetailPage={props?.onDetailPage} setGroupHeaderText={setGroupHeaderText} />
        </ModalBody>
      </Modal>
    </div>
  );
}