import parse from "html-react-parser";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import RenderAboutForm from "./RenderAboutForm";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import edit_icon_card_head from "assets/images/edit-blue-icon.png";
import { useEffect } from "react";
import './style.css'

const RenderinstituteSummary = (props) => {
  const { instituteSummary, isOpen, setIsOpen } = props;
  if (instituteSummary && instituteSummary !== "<p><br></p>") {
    if (isOpen) {
      return (
        <>
          <div className="card-text text-left">
            {parse(instituteSummary)}
            <Button
              color="link"
              onClick={() => setIsOpen(false)}
              className="readmore "
            >
              Read less
            </Button>
          </div>
        </>
      );
    } else {
      const showLessSummary = instituteSummary.slice(0, 500) + "...";
      if (instituteSummary.length > 500) {
        return (
          <>
            <div className="card-text text-left">
              {parse(showLessSummary)}
              <Button
                color="link"
                onClick={() => setIsOpen(true)}
                className="readmore "
              >
                Read more
              </Button>
            </div>
          </>
        );
      } else {
        return (
          <div className="about-text text-left">{parse(instituteSummary)}</div>
        );
      }
    }
  } else {
    return "";
  }
};

export const AboutUI = (props) => {
  const [modalisOpen, setmodalIsOpen] = useState(false);
  const modalToggle = () => setmodalIsOpen(!modalisOpen);
  const currentUser = useSelector((state) => state.auth.currentUser);
  // const {institution } = props;
  // const  isEditable = false; 
  // const isAdmin = true;
  const { institution, getInstitutionProfile, themeDetails, previewFlag, alert, isEditable, isAdmin } = props;
  // const { isEditable, isAdmin, institution, getInstitutionProfile } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const toggle = () => setIsEditMode(!isEditMode);
  let instituteSummary = institution?.aboutUs;
  const [pannel_on, setPannel_on] = useState(true);

  const sectionColors = (sThemeColor) => {
    document.documentElement.style.setProperty('--section-bg-color', sThemeColor[3]);
    document.documentElement.style.setProperty('--section-headerbg-color', sThemeColor[4]);
    document.documentElement.style.setProperty('--section-headerfont-color', sThemeColor[5]);
    document.documentElement.style.setProperty('--section-font-color', sThemeColor[2]);
    document.documentElement.style.setProperty('--section-heading-color', sThemeColor[1]);
  }

  useEffect(() => {
    if (themeDetails.length > 0) {
      sectionColors(themeDetails)
    }
  }, [themeDetails])
  // useEffect(() => {
  //   if (instituteSummary && instituteSummary.length > 0 || isAdmin) {
  //     setShowAboutOnTabScroll(true)
  //   }else{
  //     setShowAboutOnTabScroll(false);
  //   }
  // }, [instituteSummary, isAdmin])

  return (
    <Row>
      <Col lg={12} className="text-center px-3">
        <div id="sectionid-about" className="m-v1">
          {((institution?.aboutUs && !isAdmin) || isAdmin) && (
            <Card className={`nexogic-card-color-header ${pannel_on ? 'expanded' : ''} micro-section-card-vs1`} id="about">
              <div className="card-header section-header"><h2>About</h2>
                <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
                  <img src={pannel_arrow} alt="pannel_arrow" />
                </button>
                {isEditable &&
                  isAdmin &&
                  !previewFlag && (
                    <div className="edit-btn-round">
                <Button
                id="nex-ids-about-edit-btn"
                color="link"
                className="p-0 m-0"
                onClick={modalToggle}
              >
                <svg width="40" height="40" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12.5" cy="12.5" r="11.375" fill="white" stroke="#DBF4FB" stroke-width="0.75" />
                  <path d="M16.3125 8.25L15.0938 9.46875L17.5312 11.9062L18.75 10.6875L16.3125 8.25ZM13.875 10.6875L9 15.5625V18H11.4375L16.3125 13.125L13.875 10.6875Z" fill="#46ABC9" />
                </svg>
             </Button>
           </div>
                  )}
              </div>
              <div className={`collapse-div`}>
                <CardBody className="bg-color">
                  <div>
                    {isEditMode ? (
                      <RenderAboutForm
                        toggle={toggle}
                        instituteSummary={instituteSummary}
                        isEditable={isEditable}
                        isAdmin={isAdmin}
                        practitionerId={currentUser?.practitionerId}
                        institutionId={institution?.guid}
                        getInstitutionProfile={getInstitutionProfile}
                      />
                    ) : instituteSummary !== "" ? (
                      <RenderinstituteSummary
                        instituteSummary={instituteSummary}
                        isOpen={isOpen}
                        isEditable={isEditable}
                        setIsOpen={setIsOpen}
                        practitionerId={currentUser?.practitionerId}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </CardBody>
              </div>
            </Card>
          )}
        </div>
        {/* about modal */}
        <Modal
          isOpen={modalisOpen}
          toggle={modalToggle}
          className="institute-modal"
          backdrop="static"
        >
          <ModalHeader toggle={modalToggle} className="nex-institutions-modal-header"><span className="nex-text-xl">About</span></ModalHeader>
          <ModalBody>
            <RenderAboutForm
              toggle={modalToggle}
              instituteSummary={instituteSummary}
              isEditable={isEditable}
              isAdmin={isAdmin}
              institution={institution}
              practitionerId={currentUser?.guid}
              institutionId={institution?.guid}
              getInstitutionProfile={getInstitutionProfile}
            />
          </ModalBody>
        </Modal>
      </Col>
    </Row>
    // </>
  );
};
