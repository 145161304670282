import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import AddressTypeaheadField from "components/forms/formikFields/AddressTypeaheadField";
import CommonMultipleSelectTypeaheadField from "components/forms/formikFields/CommonMultipleSelectTypeaheadField";
import FormikDatePicker from "components/forms/formikFields/FormikDatePicker";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import LocationTypeaheadField from "components/forms/formikFields/LocationTypeaheadField";
import PrimarySpecialityTypeaheadField from "components/forms/formikFields/PrimarySpecialityTypeaheadField";
import YearTypeaheadField from "components/forms/formikFields/YearTypeaheadField";
import { Field, FieldArray } from "formik";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Col, Row, Spinner } from "reactstrap";
import { TIME_CONSTANTS } from "utils/Constants";
import { MetadataService } from "utils/MetadataService";
import { RenderEmailFormFields } from "./RenderEmailFormFields";
import { RenderPhoneFormFields } from "./RenderPhoneFormFields";
import plusIconModal from "assets/images/modal-card-plus.png";

const years = TIME_CONSTANTS.YEARS();

const titles = [
  {
    label: "Dr.",
    value: "Dr.",
  },
  {
    label: "Mr.",
    value: "Mr.",
  },
  {
    label: "Mrs.",
    value: "Mrs.",
  },
  {
    label: "Ms.",
    value: "Ms.",
  },
  {
    label: "Prof.",
    value: "Prof.",
  },
];

const genders = [
  {
    label: "Male",
    value: "M",
  },
  {
    label: "Female",
    value: "F",
  },
  {
    label: "Prefer not to answer",
    value: "U",
  },
];

export const RenderProfileFormFields = (props) => {
  const { setFieldValue, values, handleSubmit, dirty, toggle, isSubmitting } =
    props;
  const { primarySpeciality, practicingSince, gender } = values;
  const [speciality, setSpeciality] = useState([]);
  const [superSpecialitySkills, setSuperSpecialitySkills] = useState([]);
  const [languagesList, setLanguagesList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [statesList, setSatesList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [address, setAddress] = useState({});
  const stateRef = useRef();
  const cityRef = useRef();
  const countryRef = useRef();
  const locRef = useRef();

  useEffect(() => {
    getPrimarySpeciality();
    getLanguages();
    if (primarySpeciality.length > 0) {
      getSpecialitySkillSet(primarySpeciality[0]);
    }
    setDefaultAddress();
  }, [primarySpeciality]);

  const getPrimarySpeciality = () => {
    MetadataService.getPrimarySpecialities()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setSpeciality(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getPrimarySpeciality error", error);
      });
  };

  const getSpecialitySkillSet = (selectedSpeciality) => {
    if (isEmpty(selectedSpeciality)) {
      setSuperSpecialitySkills([]);
      return;
    }
    MetadataService.getSpecialitySkillSet(selectedSpeciality)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setSuperSpecialitySkills(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getSpecialitySkillSet error", error);
      });
  };

  const getLanguages = () => {
    MetadataService.getLanguages()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setLanguagesList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getLanguages error", error);
      });
  };

  const setDefaultAddress = () => {
    new Promise(function (resolve, reject) {
      MetadataService.getCountry().then((response) => {
        if (response.status === 200 || response.status === 201) {
          setCountryList(response.data);
          resolve(response.data);
        }
      });
    })
      .then(function (result) {
        return new Promise(function (resolve, reject) {
          if (result !== undefined && result.length > 0) {
            result.forEach((obj, index, array) => {
              if (obj.value === values.country[0]) {
                setFieldValue("country", [obj]);
                MetadataService.getStates(obj.id).then((response) => {
                  if (response.status === 200 || response.status === 201) {
                    setSatesList(response.data);
                    resolve(response.data);
                  }
                });
              }
            });
          }
        });
      })
      .then(function (result) {
        return new Promise(function (resolve, reject) {
          if (result !== undefined && result.length > 0) {
            result.forEach((obj, index, array) => {
              if (obj.value === values.state[0]) {
                setFieldValue("state", [obj]);
                MetadataService.getCities(obj.id).then((response) => {
                  if (response.status === 200 || response.status === 201) {
                    setCityList(response.data);
                    resolve(response.data);
                  }
                });
              }
            });
          }
        });
      })
      .then(function (result) {
        return new Promise(function (resolve, reject) {
          if (result !== undefined && result.length > 0) {
            result.forEach((obj, index, array) => {
              if (obj.value === values.city[0]) {
                setFieldValue("city", [obj]);
                MetadataService.getLocations(obj.id).then((response) => {
                  if (response.status === 200 || response.status === 201) {
                    setLocationList(response.data);
                    resolve(response.data);
                  }
                });
              }
            });
          }
        });
      });
  };

  const getStates = (countryId) => {
    setSatesList([]);
    setCityList([]);
    setLocationList([]);
    getselectedAddress(countryList, countryId, "COUNTRY");
    if (countryId === "") {
      return false;
    }
    MetadataService.getStates(countryId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setSatesList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getStates error", error);
      });
  };

  const getCities = (stateId) => {
    setCityList([]);
    setLocationList([]);
    getselectedAddress(statesList, stateId, "STATE");
    if (stateId === "") {
      return false;
    }
    MetadataService.getCities(stateId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setCityList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getCities error", error);
      });
  };

  const getLocations = (cityId) => {
    setLocationList([]);
    getselectedAddress(cityList, cityId, "CITY");
    if (cityId === "") {
      return false;
    }
    MetadataService.getLocations(cityId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setLocationList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getCities error", error);
      });
  };

  const getselectedAddress = (array, id, type) => {
    let filterValue = "";
    if (!isEmpty(values.country)) {
      array.map((obj) => {
        if (obj.id.toString() === id.toString()) {
          filterValue = obj.value;
        }
        return true;
      });
    }
    if (type === "COUNTRY") {
      setAddress({
        ...address,
        country: filterValue,
        state: "",
        city: "",
        location: "",
      });
      values.state = [];
      values.city = [];
      values.location = [];
    } else if (type === "STATE") {
      setAddress({
        ...address,
        state: filterValue,
        city: "",
        location: "",
      });
      values.city = [];
      values.location = [];
    } else if (type === "CITY") {
      setAddress({
        ...address,
        city: filterValue,
        location: "",
      });
      values.location = [];
    }
  };

  const selectdLocation = (locationName) => {
    setAddress({
      ...address,
      location: locationName,
    });
  };

  return (
    <form onSubmit={handleSubmit} id="nex-rpff-form">
      <Row className="mb-1">
        <Col>
          <fieldset className=" mb-0">
            {/*<legend>Personal Informations</legend>*/}
            <Row form>
              <Col md={2}>
                <Field
                  id="title"
                  name="title"
                  type="text"
                  label="Title"
                  component={FormikSelectField}
                  value={values.title}
                  inputprops={{
                    options: titles,
                    defaultOption: "Select",
                    keys: {
                      id: "value",
                      label: "label",
                    },
                  }}
                />
              </Col>
              <Col md={4}>
                <Field
                  id="firstName"
                  name="firstName"
                  type="text"
                  component={FormikInputField}
                  label="First Name"
                  placeholder="Your first name"
                  autoComplete="firstName"
                />
              </Col>
              <Col md={3}>
                <Field
                  id="middleName"
                  name="middleName"
                  type="text"
                  component={FormikInputField}
                  label="Middle Name"
                  placeholder="Your middle name"
                  autoComplete="middleName"
                />
              </Col>
              <Col md={3}>
                <Field
                  id="lastName"
                  name="lastName"
                  type="text"
                  component={FormikInputField}
                  label="Last Name"
                  placeholder="Your last name"
                  autoComplete="lastName"
                />
              </Col>
              <Col lg={4} id="nex-rpff-dob-w">
                <Field
                  id="dateOfBirth"
                  name="dateOfBirth"
                  type="text"
                  component={FormikDatePicker}
                  inputprops={{
                    dateFormat: "yyyy-MM-dd",
                    showYearDropdown: true,
                    showMonthDropdown: true,
                    scrollableYearDropdown: true,
                    yearDropdownItemNumber: 10,
                    maxDate: new Date(),
                    autoComplete: "off",
                  }}
                  label="Date of Birth"
                  placeholder="YYYY-MM-DD"
                />
              </Col>
              <Col lg={4}>
                <Field
                  id="gender"
                  name="gender"
                  type="text"
                  label="Gender"
                  value={gender}
                  component={FormikSelectField}
                  inputprops={{
                    name: `duration`,
                    options: genders,
                    defaultOption: "Select",
                    keys: {
                      id: "value",
                      label: "label",
                    },
                  }}
                />
              </Col>
              <Col lg={4}>
                <div className="form-group" id="nex-rpff-practicing-since">
                  <Field
                    name="practicingSince"
                    id="practicingSince"
                    type="text"
                    label="Practicing Since"
                    selected={practicingSince}
                    component={YearTypeaheadField}
                    placeholder="YYYY"
                    value={values.practicingSince}
                    spellCheck={false}
                    years={years}
                    autoComplete="practicingSince"
                  />
                </div>
              </Col>
            </Row>
            <Row form>
              <Col lg={6} className="searchbox-input-chips">
                <div className="form-group" id="nex-rpff-primary-speciality">
                  <Field
                    id="primarySpeciality"
                    name="primarySpeciality"
                    label="Primary Speciality"
                    className="primary-specialities"
                    type="text"
                    component={PrimarySpecialityTypeaheadField}
                    placeholder="Select Speciality"
                    value={values.primarySpeciality}
                    spellCheck={false}
                    specialit={speciality}
                    getSelectedSpeciality={(speciality) =>
                      getSpecialitySkillSet(speciality)
                    }
                    autoComplete="primarySpeciality"
                  />
                </div>
              </Col>
              <Col lg={6} className="searchbox-input-chips">
                <div className="form-group" id="nex-rpff-super-speciality">
                  <Field
                    id="superSpeciality"
                    name="superSpeciality"
                    label="Super Speciality"
                    className="primary-specialities"
                    type="text"
                    component={PrimarySpecialityTypeaheadField}
                    placeholder="Select Super Speciality"
                    value={values.superSpeciality}
                    spellCheck={false}
                    specialit={superSpecialitySkills}
                    allowNew={true}
                    autoComplete="superSpeciality"
                  />
                </div>
              </Col>

              <Col lg={12}>
                <div className="form-group" id="nex-rpff-languages">
                  <Field
                    id="languages"
                    name="languages"
                    label="Language Spoken"
                    className="language-suggest"
                    type="text"
                    component={CommonMultipleSelectTypeaheadField}
                    placeholder="Select Language"
                    spellCheck={false}
                    inputOptions={languagesList}
                    multiple={true}
                    autoComplete="languages"
                  />
                </div>
              </Col>
            </Row>
          </fieldset>
          <fieldset className=" mb-3">
            {/** <legend>Headline</legend>*/}
            <label>Headline</label>
            <Row form>
              <Col lg={12}>
                <Field
                  id="headline"
                  name="headline"
                  type="text"
                  component={FormikInputField}
                  placeholder="Headline"
                  autoComplete="headline"
                />
              </Col>
            </Row>
          </fieldset>
          <fieldset className=" mb-3">
            {/** <legend>Contact Information 1</legend>*/}


            <Row form>
              <Col className="d-flex px-0">
                <FieldArray
                  name="phones"
                  render={(arrayHelpers) => (
                    <div className="nexogic-modal-contact-card card" id="nex-rpff-phone-group-card">
                      <div className="card-header">
                        <h5 className="card-title">
                          <span className="bold nex-text">Phone Number(s)</span>
                          {typeof props?.errors?.phones === "string" &&
                            props?.errors?.phones && (

                              <RenderErrorMessage
                                errorMsg={props.errors.phones}
                              />

                            )}
                          {typeof props?.errors?.phones === "object" &&
                            props?.errors?.primaryItemPhone && (

                              <RenderErrorMessage
                                errorMsg={props.errors.primaryItemPhone}
                              />

                            )}
                          <Button
                            id="nex-rpff-plus-btn"
                            className="plus-btn"
                            onClick={() => {
                              arrayHelpers.push({
                                primary: false,
                                type: "HOME",
                                value: "",
                              });
                            }}
                          >
                            <span className="icon"><svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="9.25" cy="9" r="8.4375" fill="#ECF8FF" stroke="#46ABC9" strokeWidth="1.125" />
                              <path d="M9.125 5.25L9.125 12.5039" stroke="#46ABC9" strokeWidth="1.125" strokeLinecap="round" />
                              <path d="M12.75 8.875L5.49609 8.875" stroke="#46ABC9" strokeWidth="1.125" strokeLinecap="round" />
                            </svg></span> Add More
                            {/* <FontAwesomeIcon icon={faPlus} />*/}
                          </Button>
                        </h5>

                      </div>

                      <div className="card-body">
                        {/* 
                        <Row>
                          <Col md={3}>
                            <label className="">Type</label>
                          </Col>
                          <Col md={6}>
                            <label className="">Phone Number</label>
                          </Col>
                          <Col
                            md={3}
                            
                          >
                            <label className="">{Primary}</label>
                          </Col>
                        </Row>
*/}
                        {props?.values?.phones.map((_, index) => (
                          <RenderPhoneFormFields
                            {...arrayHelpers}
                            {...props}
                            index={index}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                />
              </Col>
              <Col lg={12} className="d-flex mt-3 px-0">
                <FieldArray
                  name="emails"
                  render={(arrayHelpers) => (
                    <div className="nexogic-modal-contact-card card" id="nex-rpff-email-group-card">
                      <div className="card-header">
                        <h5 className="card-title">
                          <span className="nex-text bold">Email Address(s)</span>
                          {typeof props?.errors?.emails === "string" &&
                            props?.errors?.emails && (
                              <p>
                                <RenderErrorMessage
                                  errorMsg={props.errors.emails}
                                />
                              </p>
                            )}
                          {typeof props?.errors?.emails === "object" &&
                            props?.errors?.primaryItemEmails && (
                              <p>
                                <RenderErrorMessage
                                  errorMsg={props.errors.primaryItemEmails}
                                />
                              </p>
                            )}
                          <Button
                            id="nex-rpff-email-plus-btn"
                            className="plus-btn"
                            onClick={() => {
                              arrayHelpers.push({
                                primary: false,
                                type: "WORK",
                                value: "",
                              });
                            }}
                          >
                            <span className="icon"><svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="9.25" cy="9" r="8.4375" fill="#ECF8FF" stroke="#46ABC9" strokeWidth="1.125" />
                              <path d="M9.125 5.25L9.125 12.5039" stroke="#46ABC9" strokeWidth="1.125" strokeLinecap="round" />
                              <path d="M12.75 8.875L5.49609 8.875" stroke="#46ABC9" strokeWidth="1.125" strokeLinecap="round" />
                            </svg></span> Add More
                            {/**<img src={plusIconModal} alt="" />
                           <FontAwesomeIcon icon={faPlus} />*/}
                          </Button>
                        </h5>

                      </div>
                      <div className="card-body">
                        {/** 
                        <Row>
                          <Col md={3} className="d-flex align-items-center">
                            <label className="">Type</label>
                          </Col>
                          <Col md={6} className="d-flex align-items-center">
                            
                          </Col>
                          <Col
                            md={3}
                            
                          >
                            Primary
                          </Col>
                        </Row>
                        */}
                        {props.values.emails.map((_, index) => (
                          <RenderEmailFormFields
                            {...arrayHelpers}
                            {...props}
                            index={index}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                />
              </Col>
            </Row>
          </fieldset>
          <fieldset className=" mb-3">
            {/** <legend>Mailing Address</legend>*/}
            <Row form>
              <Col lg={12}>
                <Field
                  id="streetAddress1"
                  name="streetAddress1"
                  type="text"
                  component={FormikInputField}
                  label="Street Address"
                  placeholder="Current address"
                  autoComplete="streetAddress1"
                />
              </Col>
              <Col lg={12}>
                <Field
                  id="streetAddress2"
                  name="streetAddress2"
                  type="text"
                  component={FormikInputField}
                  label="Street Address 2"
                  placeholder="Other Address"
                  autoComplete="streetAddress2"
                />
              </Col>
              <Col lg={6}>
                <div className="form-group" id="nex-rpff-country">
                  <Field
                    id="country"
                    name="country"
                    type="text"
                    component={AddressTypeaheadField}
                    label="Country"
                    value={values.country}
                    selected={"test"}
                    placeholder="Select country"
                    spellCheck={false}
                    inputList={countryList}
                    getAddressById={(contryId) => getStates(contryId)}
                    locationRef={countryRef}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-group" id="nex-rpff-state">
                  <Field
                    id="state"
                    name="state"
                    type="text"
                    component={AddressTypeaheadField}
                    label="State"
                    value={values.state}
                    placeholder="Select state"
                    spellCheck={false}
                    inputList={statesList}
                    getAddressById={(stateId) => getCities(stateId)}
                    locationRef={stateRef}
                  />
                </div>
              </Col>
              <Col lg={4}>
                <div className="form-group" id="nex-rpff-city">
                  <Field
                    id="city"
                    name="city"
                    type="text"
                    component={AddressTypeaheadField}
                    label="City"
                    value={values.city}
                    placeholder="Select city"
                    spellCheck={false}
                    inputList={cityList}
                    getAddressById={(cityId) => getLocations(cityId)}
                    locationRef={cityRef}
                  />
                </div>
              </Col>
              <Col lg={4}>
                <Field
                  id="zipCode"
                  name="zipCode"
                  type="text"
                  component={FormikInputField}
                  label="Zip Code"
                  placeholder="Enter code"
                  autoComplete="zipCode"
                />
              </Col>
              <Col lg={4} id="nex-rpff-location">
                <Field
                  id="location"
                  name="location"
                  type="text"
                  component={LocationTypeaheadField}
                  label="Location"
                  value={values.location}
                  placeholder="Select Location"
                  spellCheck={false}
                  locations={locationList}
                  selectdLocation={selectdLocation}
                  locationRef={locRef}
                />
              </Col>
            </Row>
          </fieldset>
        </Col>
      </Row>

      <div className="justify-content-end  modal-footer mx-0">
        <Col xs="auto" className="action-group">
          {isSubmitting ? (
            <div className="loading-spiner">
              <Spinner />
            </div>
          ) : (
            <>
              <Button id="rpf_btn_submit" className="modal-save-btn nex-btn-primary" color="primary " type="submit">
                Save
              </Button>
              <Button id="rpf_btn_cancel"
                className="modal-cancel-btn nex-btn-primary-outline"
                color="primary "
                type="button"
                onClick={() => toggle()}
              >
                Cancel
              </Button>
            </>
          )}
        </Col>
      </div>
    </form>
  );
};