import PropTypes from "prop-types";
import React, { useReducer } from "react";
import { encrypt } from "utils/EncryptDecrypt";
import { createImageFromInitials, getRandomColor } from "utils/Utils";
import { TalentService } from "utils/TalentService";
import defaultAvtar from "../assets/images/defaultPic.png";

const JobFilterStateContext = React.createContext();
const JobFilterDispatchContext = React.createContext();

const initValues = {
  loading: false,
  opportunity: "",
  primarySpeciality: [],
  city: [],
  totalItems: 0,
  totalPages: 2,
  currentPage: 0,
  contents: [],
};
//Below const created for validating null for createdBy value to set createdBy for Admin/internal users 
const adminDP = {
  name: "System",
  avatarUrl: defaultAvtar,
  profileUuid: 'admin',
};
function errorMessageReducer(state, action) {
  switch (action.type) {
    case "LIST_DATA_SUCCESS": {
      return { ...state, ...action?.data };
    }
    case "TOGGLE_SUCCESS": {
      return { ...state, loading: !state?.loading };
    }
    case "START_LOADER_SUCCESS": {
      return { ...state, loading: true };
    }
    case "END_LOADER_SUCCESS": {
      return { ...state, loading: false };
    }
    case "UPDATE_FILTER_DATA_SUCCESS": {
      return { ...state, ...action?.formData };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const JobFilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(errorMessageReducer, initValues);
  return (
    <JobFilterStateContext.Provider value={state}>
      <JobFilterDispatchContext.Provider value={dispatch}>
        {children}
      </JobFilterDispatchContext.Provider>
    </JobFilterStateContext.Provider>
  );
};

JobFilterProvider.propTypes = {
  children: PropTypes.objectOf(PropTypes.any),
};

JobFilterProvider.defaultProps = {
  children: null,
};

function useJobFilterState() {
  const context = React.useContext(JobFilterStateContext);
  if (context === undefined) {
    throw new Error(
      "useJobFilterState must be used within a JobFilterProvider"
    );
  }
  return context;
}

function useJobFilterDispatch() {
  const context = React.useContext(JobFilterDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useJobFilterDispatch must be used within a JobFilterProvider"
    );
  }
  return context;
}

const onLoadingDefaultImage = async (guidId, imageId, name) => {
  if (guidId && imageId) {
    return `${process.env.REACT_APP_IMAGE_BASE_URL}/${guidId}/${imageId}`;
  }
  return createImageFromInitials(
    132,
    name,
    getRandomColor(encrypt(guidId).toString())
  );
};

function makeQuery(sqOpportunity, sqCity, sqPrimary) {
  let query = "";
  if (sqOpportunity !== "") {
    query = `(op=="${encodeURIComponent(sqOpportunity)}")`;
  }

  if (sqCity !== "") {
    if (query === "")
      query = `(cy=="${encodeURIComponent(sqCity)}")`;
    else
      query += `;(cy=="${encodeURIComponent(sqCity)}")`;
  }

  if (sqPrimary.length>0) {
    if (query === "")
      query = `(ps=="${encodeURIComponent(sqPrimary[0])}")`;
    else
      query += `;(ps=="${encodeURIComponent(sqPrimary[0])}")`;
  }

  return query;
}

async function getJobData(
  dispatch,
  initLoading = false,
  pageNo,
  feedlist,
  searchQuery = {
    cp: 0,
    opportunity: "",
    primarySpeciality: [],
    city: [],
  }
) {
  if (initLoading) {
    startLoader(dispatch);
  }

  const sqOpportunity = searchQuery?.opportunity ? searchQuery?.opportunity : "";
  const sqCity = searchQuery?.city &&
    searchQuery?.city.length > 0 &&
    searchQuery?.city[0]?.value
    ? searchQuery?.city[0]?.value.split(",")[0] ?? ""
    : "";
  const sqPrimary = searchQuery?.primarySpeciality &&
    searchQuery?.primarySpeciality.length > 0
    ? searchQuery?.primarySpeciality
    : "";

  const request = TalentService.listJob({
    cp: searchQuery?.cp,
    sq: makeQuery(sqOpportunity, sqCity, sqPrimary)
  });
  request
    .then(async (response) => {
      let { data } = response;
      if (pageNo === 0) {
        const allData = { ...data };
        const resolved = await Promise.all(
          allData.contents.map(async (item) => {
            const each = { ...item };
            if (each?.creatorProfile) {
              each.creatorProfile.avatarUrl = await onLoadingDefaultImage(
                each?.creatorProfile?.profileGuid,
                each.creatorProfile.avatarId,
                each.creatorProfile.name
              );
            } else {
              each.creatorProfile = adminDP;
            }
            return each;
          })
        );
        allData.contents = resolved;
        dispatch({ type: "LIST_DATA_SUCCESS", data: allData });
        endLoader(dispatch);
      } else {
        const resolved = await Promise.all(
          data.contents.map(async (item) => {
            const each = { ...item };
            if (each.creatorProfile !== null) {
              each.creatorProfile.avatarUrl = await onLoadingDefaultImage(
                each?.creatorProfile?.profileGuid,
                each.creatorProfile.avatarId,
                each.creatorProfile.name
              );
            } else {
              each.creatorProfile = adminDP;
            }
            return each;
          })
        );
        feedlist = feedlist.concat(resolved);
        const allData = { ...data, contents: feedlist };
        dispatch({ type: "LIST_DATA_SUCCESS", data: allData });
        endLoader(dispatch);
      }
    })
    .catch((error) => {
      console.log("Error while getting job list: ", error);
      endLoader(dispatch);
    });
}

const fetchJobData = (dispatch, currentPage, contents, searchQuery) => {
  getJobData(dispatch, false, currentPage, contents, searchQuery);
};

function toggleLoader(dispatch) {
  dispatch({ type: "TOGGLE_SUCCESS" });
}
function startLoader(dispatch) {
  dispatch({ type: "START_LOADER_SUCCESS" });
}
function endLoader(dispatch) {
  dispatch({ type: "END_LOADER_SUCCESS" });
}

function updateFilterData(
  dispatch,
  formData = { opportunity: "", primarySpeciality: [], city: [] }
) {
  dispatch({ type: "UPDATE_FILTER_DATA_SUCCESS", formData });
}

export {
  JobFilterProvider,
  useJobFilterState,
  useJobFilterDispatch,
  toggleLoader,
  fetchJobData,
  getJobData,
  startLoader,
  endLoader,
  updateFilterData,
};
