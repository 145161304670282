import React, { useState, useEffect } from "react";
import PubNub from "pubnub";
import { useSelector } from "react-redux";
import { PubNubProvider, usePubNub } from "pubnub-react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AudioIcon from "../../../FeedHomePage/images/messageIcon/Vector.png";
import AttachIcon from "../../../FeedHomePage/images/messageIcon/attach.png";
import SmileIcon from "../../../FeedHomePage/images/messageIcon/smile.png";
import SendIcon from "../../../FeedHomePage/images/messageIcon/sendIcon.png";
import bigInt from "big-integer";
import MessageModal from "./MessageModal/index";
import Picker from "emoji-picker-react";
import PDF_IMG from "../../images/pdf.png";
import appointment_icon from "../../../../assets/images/appointment_active_icon.png";
import {
  get_contact_list,
  storeChat,
} from "../../../../utils/message_connection";
import { getTime } from "../../../../utils/Constants";
import "./ChatMessage.css";
import { Card, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faPaperclip,
  faPaperPlane,
  faSmile,
  faSmileBeam,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import OutsideClickHandler from "react-outside-click-handler";
import ReactQuill from "react-quill";
import avtar from "assets/images/reviewer_image.png";
import { getFullNameOfPerson } from "utils/Utils";
import moment from "moment";
//initalizing pubnub
const pubnub = new PubNub({
  publishKey: "pub-c-603af66f-0e5f-4002-9290-e34501f03ee2",
  subscribeKey: "sub-c-4dcad3c3-a486-4917-b80d-3682cc359032",
  secretKey: "sec-c-YjVhZDQzN2ItZjJhZC00NTJmLThkZmEtNDdiNDQ5YTA1M2Yx",
});

let fileImageEvent = "";

//chatMessage component
function ChatMessage(props) {
  return (
    <PubNubProvider client={pubnub}>
      <Chat
        dynamic_channel={props.dynamic_channel && props.dynamic_channel}
        get_history={props.get_history}
        delete_all_message={
          props.delete_all_message && props.delete_all_message
        }
        connections={props.connections}
        fetch_recent_message={props.fetch_recent_message}
        selected_id={props.selected_id}
        firstName={props.firstName}
        lastName={props.lastName}
      />
    </PubNubProvider>
  );
}

//chat component
function Chat(props) {
  //initializing pubnub
  const pubnub = usePubNub();

  //practioner id from redux store
  let practionerId = useSelector((state) => state.auth.currentUser.guid);

  let initialState = []; // defining a empty array

  initialState.push(props.dynamic_channel); // setting initial prop in state

  // usestate hooks
  const [channels, setchannels] = useState([]);
  let val = props;
  const [message_drop_down, setmessage_drop_down] = useState(false);

  const [messages, addMessage] = useState([]);

  const [message, setMessage] = useState();

  const [Dropdownindex, setDropdownindex] = useState(0);

  const [showModal, setshowModal] = useState(false);

  const [Image_pub, setImage_pub] = useState();

  const [Image_pub_flag, setImage_pub_flag] = useState();

  const [isDisabled, setDisabled] = useState(false);

  const [Preview_modal, setPreview_modal] = useState(false);

  const [Uploaded_item, setUploaded_item] = useState("");

  const [chosenEmoji, setChosenEmoji] = useState(null);

  const [showEmoji, setShowEmoji] = useState(false);

  const [ForwardConnection, setForwardConnection] = useState([]);

  const [latest_message, setlatest_message] = useState("");

  const [Loader, setLoader] = useState(false);

  const [size_file, setsize_file] = useState(0);

  const [ForwardMessageData, setForwardMessageData] = useState({});

  const [file, setFile] = useState();

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [imagePreview, setImagePreview] = useState();

  //Auto scroll when message send or recive.
  function useChatScroll() {
    const ref = React.useRef();
    React.useEffect(() => {
      if (ref.current) {
        ref.current.scrollTop = ref.current.scrollHeight;
      }
    }, [messages]);
    return ref;
  }

  //setting the channels everytime
  useEffect(
    () => setchannels([props.dynamic_channel]),
    [props.dynamic_channel]
  );

  //message listner text/text+emoji
  useEffect(() => {
    pubnub.addListener({ message: handleMessage });
  }, [pubnub]);

  //file listner img/pdf
  useEffect(() => {
    pubnub.addListener({ file: fileHandler });
  }, [pubnub]);

  //fetch message on channel change on switching channels
  useEffect(() => {
    get_history();
    setmessage_drop_down(false);
    pubnub.subscribe({ channels });
  }, [channels, props.get_history]);

  // depends on delete message prop coming from parent
  useEffect(() => {
    deleteMessage();
  }, [props.delete_all_message]);

  // api call to fetch the contact list for the practioner id
  // useEffect(() => {
  //   get_contact_list(practionerId).then((res) => {
  //     if (res.data && res.data.data && res.data.data.connection) {
  //       setForwardConnection(res.data.data.connection);
  //     }
  //   });
  // }, []);

  //render message on screen function(own)
  const handleMessage = (event) => {
    const message = event.message.content;
    const sender_id = event.publisher;

    let channel = event.channel;

    let timeToken = event.timetoken;
    const text = message.text || message;

    if (event.message.action && event.message.action == "forward") {
      //adding the message to the state array
      addMessage((messages) => [
        {
          text: text,
          align: sender_id == practionerId ? true : false,
          timeToken: event.timetoken,
          channel: event.channel,
        },
      ]);

      //prop method to send the recent channel and message to index,js fir rendering it on left container
      // props.fetch_recent_message(text, channel, timeToken);
    } else {
      if (typeof message === "string" || message.hasOwnProperty("text")) {
        const text = message.text || message;

        //adding the message to the state array
        addMessage((messages) => [
          ...messages,
          {
            text: text,
            align: sender_id == practionerId ? true : false,
            timeToken: event.timetoken,
            channel: event.channel,
          },
        ]);

        //prop method to send the recent channel and message to index,js fir rendering it on left container
        // props.fetch_recent_message(text, channel, timeToken);

        // if (practionerId == sender_id) {
        //   let data = {
        //     channel: channel,
        //     content: message,
        //     messageType: 0,
        //     publisher: sender_id,
        //     timetoken: timeToken,
        //     uuid: practionerId,
        //   };
        //   storeChat(data).then((res) => console.log(res));
        // }
      }
    }
  };

  //listener callback for send file in useffect
  const fileHandler = (event) => {
    const file_channelName = event.channel; // Channel to which the file belongs

    const file_channelGroup = event.subscription; // Channel group or wildcard subscription match (if exists)

    const file_publisher = event.publisher; // File publisher

    const file_timetoken = event.timetoken; // Event timetoken

    const file_message = event.message; // Optional message attached to the file

    const file_fileId = event.file.id; // File unique id

    const file_fileName = event.file.name; // File name

    const file_fileUrl = event.file.url; // File direct URL

    setLoader(false);

    //adding the message to the state array
    addMessage((messages) => [
      ...messages,
      {
        align: practionerId == file_publisher ? true : false,
        timeToken: file_timetoken,
        channel: file_channelName,
        media: { id: file_fileId, name: file_fileName, fileUrl: file_fileUrl },
      },
    ]);

    //prop method to send the recent channel and message to index,js fir rendering it on left container
    props.fetch_recent_message(file_fileName, file_channelName, file_timetoken);

    if (practionerId == file_publisher) {
      //store message(text) in db
      let data = {
        channel: file_channelName,
        fileId: file_fileId,
        fileName: file_fileName,
        publisher: file_publisher,
        messageType: 4,
        uuid: practionerId,
        timetoken: file_timetoken,
      };
      storeChat(data).then((res) => console.log(res));
    }
  };

  //function to send message
  const sendMessage = (message) => {
    //file uploaded code here.
    if (Image_pub_flag) {
      setLoader(true);
      setsize_file(Image_pub[0].size);
      pubnub.sendFile({
        channel: channels[0],
        file: Image_pub[0],
      });
      setImage_pub_flag(false);
      fileImageEvent.target.value = "";
    }

    if (message.trim().length > 0 && message.trim() != "") {
      pubnub.setUUID(practionerId);
      pubnub
        .publish({
          channel: channels[0],
          message: {
            content: message,
            sender: practionerId,
          },
        })
        .then((res) => {
          if (typeof message === "string" || message.hasOwnProperty("text")) {
            const text = message.text || message;
          }
          setMessage("");
          setmessage_drop_down(false);
        });
    }
  };

  //fetch message from pubnub db
  const get_history = () => {
    let end_var = new Date();
    end_var = end_var.getTime() * 10000;
    pubnub.fetchMessages(
      {
        channels: channels,
        start: end_var,
        count: 20,
      },
      (status, response) => {
        addMessage([]);
        response &&
          response.channels[props.dynamic_channel].map((item, index) =>
            addMessage((messages) => [
              ...messages,
              {
                media: item.message.file,
                channel: item.channel,
                text: item.message && item.message.content,
                align: item.uuid == practionerId ? true : false,
                timeToken: item.timetoken,
                id: index,
              },
            ])
          );
      }
    );
  };

  //sending files to pubnub images/pdf
  const get_attach_item = async (e, event) => {
    setImage_pub(e);
    setImage_pub_flag(true);
    setFile(URL.createObjectURL(event.target.files[0]));
    fileImageEvent = event;
    // event.target.value = "";
  };

  //function to delete all messages
  const deleteMessage = () => {
    if (channels.length) {
      pubnub.deleteMessages(
        {
          channel: channels[0],
        },
        function (status, response) {
          addMessage([]);
          //fetch after bulk delete
          fetch_post_delete(channels);
        }
      );
    }
  };

  //show_actions_message function
  const show_actions_message = (id) => {
    setDropdownindex(id);
    setmessage_drop_down(!message_drop_down);
  };

  const fetch_post_delete = (channels) => {
    pubnub.fetchMessages(
      {
        channels: channels,
        start: null,
        end: null,
        count: 1,
      },
      (status, response) => {
        if (
          response &&
          response.channels != null &&
          response.channels[channels[0]].message.content
        ) {
          props.fetch_recent_message(
            response.channels[channels[0]].message.content,
            channels[0]
          );
        } else if (
          response &&
          response.channels != null &&
          response.channels[channels[0]].message.file.name
        ) {
          props.fetch_recent_message(
            response.channels[channels[0]].message.file.name,
            channels[0]
          );
        } else {
          props.fetch_recent_message("No new Message", channels[0]);
        }
      }
    );
  };
  //delete a personal message
  const delete_individual_message = (timeToken) => {
    let start_int = bigInt(timeToken);

    start_int = start_int.value;

    let value_to_use = bigInt(1);

    value_to_use = value_to_use.value;

    let start_value = start_int - value_to_use;

    pubnub.deleteMessages(
      {
        channel: channels[0],
        start: start_value.toString(),
        end: timeToken,
      },
      function (status, response) {
        //fetch message to render it on left action container -\/-
        fetch_post_delete(channels);
      }
    );
    addMessage(messages.filter((item) => item.timeToken !== timeToken));
    setmessage_drop_down(false);
  };

  //forward message onclick functon to open a modal on forward
  const forwardMessage = (message) => {
    setshowModal(!showModal);
    setForwardMessageData(message);
  };

  //forward message onclick functon to open a modal on forward
  const PreviewMessage = () => {
    setPreview_modal(!Preview_modal);
    setDisabled(false);
    // setPreview_modal(false)
  };

  //forward_message_action function to send a meesage to a particular user
  const forward_message_action = (message, channelName) => {
    debugger;
    if (message.media == undefined) {
      pubnub
        .publish({
          channel: channelName,
          message: {
            content: message.text,
            sender: practionerId,
            action: "forward",
          },
        })
        .then((res) => {
          setmessage_drop_down(false);
          setshowModal(false);
        });
    } else {
      pubnub
        .sendFile({
          channel: channelName,
          file: message.media,
        })
        .then((res) => {
          setmessage_drop_down(false);
          setshowModal(false);
        });
    }
  };

  //onclick for emoji
  const onEmojiClick = (event, emojiObject) => {
    let text = message ? message : "";
    setMessage(text + emojiObject.emoji);
  };

  //toggle emoji function
  const showEmoji_handler = () => {
    setShowEmoji(!showEmoji);
  };

  const media_url_callback = (channel_message, media_message) => {
    let url_from_pub = pubnub.getFileUrl({
      channel: channel_message,
      id: media_message.id,
      name: media_message.name,
    });
    return url_from_pub;
  };

  const download = (channel, media) => {
    let url = media_url_callback(channel, media);
    let filename = media.name;
    fetch(url).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", filename);
        a.click();
      });
    });
  };

  const ref = useChatScroll(messages);

  const convertTimetokenToDate = (timetoken) => {
    // Convert nanoseconds to milliseconds
    const timestampInMilliseconds = parseInt(timetoken, 10) / 1e4;

    // Create a Date object with the timestamp
    const date = new Date(timestampInMilliseconds);

    const parsedDate = moment(date, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
    const formattedDate = parsedDate.format('HH:mm, DD MMM');

    // Return the formatted date string
    return formattedDate;
  };

  return (
    <>
      {Loader && <div className="uploadLoader"></div>}

      <div className="nexogic-chats">
        <div style={listStyles} ref={ref} id="custom_scroll_bar">
          {messages.length > 0 &&
            messages.map((message, index) => {
              return (
                <>
                  {message.channel == props.dynamic_channel && (
                    <
                      // key={message.timeToken}
                      // style={{
                      //   display: "flex",
                      //   justifyContent: message.align
                      //     ? "flex-end "
                      //     : "flex-start",
                      // }}
                      >
                      {message_drop_down && index === Dropdownindex ? (
                        <div
                          className="message_action_dropdown_menu"
                          id={index}
                          style={{ width: "145px" }}
                        >
                          <div onClick={() => forwardMessage(message)}>
                            Forward
                          </div>
                          <div
                            className="ml-4"
                            onClick={() =>
                              delete_individual_message(message.timeToken)
                            }
                          >
                            Delete
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div
                        key={`message-${index}`}
                        className={
                          message.align
                            ? "nexogic-conver-item right"
                            : "nexogic-conver-item left"
                        }
                      // style={
                      //   message.media
                      //     ? {
                      //         border: "none",
                      //         background: "#f8fdff",
                      //         opacity: "1",
                      //       }
                      //     : null
                      // }
                      >
                        <div className="card-body">

                          <div className="author_desc">
                            <span className="card-title">{getFullNameOfPerson(props.firstName, "", props.lastName)}</span>
                            <span className="time">{convertTimetokenToDate(message.timeToken)}</span>
                          </div>
                          <div className="chat-const">
                            {message.media ? (
                              <div className="nexogic-media-wrap">
                                {message.media.name.includes("pdf") ? (
                                  <>
                                    <img
                                      src={PDF_IMG}
                                      className="pdf_img_css"
                                      alt="#"
                                    />
                                    <div className="pdf_container">
                                      <div className="attachment_name">
                                        {message.media.name}
                                      </div>
                                      <div className="d-flex align-items-baseline">
                                        <div
                                          className="pdf_download_text"
                                          onClick={() =>
                                            download(
                                              message.channel,
                                              message.media
                                            )
                                          }
                                        >
                                          Download
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : message.media.name.includes("mp4") ? (
                                  <video
                                    className="radius"
                                    width="50%"
                                    controls
                                    loop
                                    autoPlay
                                    muted
                                  >
                                    <source
                                      src={
                                        message.media.fileUrl
                                          ? message.media.fileUrl
                                          : media_url_callback(
                                            message.channel,
                                            message.media
                                          )
                                      }
                                      width="200px"
                                      type="video/mp4"
                                    />
                                  </video>
                                ) : (
                                  <img onClick={() => { toggle(); setImagePreview(message) }}
                                    className="radius"

                                    src={
                                      message.media.fileUrl
                                        ? message.media.fileUrl
                                        : media_url_callback(
                                          message.channel,
                                          message.media
                                        )
                                    }
                                    width="200px"
                                    alt="#"
                                  />
                                )}
                              </div>
                            ) : (
                              <>
                                <div id="scroll_bar5" className="card-text nexogic-chat-text">
                                  {message.text}
                                </div>
                              </>
                            )}
                          </div>

                          <img src={avtar} alt="#" className="nexogic-chat-thumb" />
                          {/** 
                          <div className="msg-inner-content">
                            <div className="msg-data-prt">
                              <div className="sndr-info">
                                <span className="sender-name">Lorem Ipsum</span>
                                <span className="sender-desg">, M.B.B.S</span>
                              </div>
                              <span className="msg-time">6:00PM</span>
                            </div>
                            
                          </div>
                          */}
                        </div>
                        {/* <div
                        className="dots-message-container d-flex flex-column"
                        onClick={() => show_actions_message(index)}
                      >
                        <div className="d-flex">
                          <span className="dots"></span>
                          <span className="dots"></span>
                          <span className="dots"></span>
                        </div>
                        <div style={{ fontSize: "12px", width: "25px" }}>
                          {getTime(message.timeToken)}
                        </div>
                      </div> */}
                      </div>

                      {showModal && (
                        <MessageModal
                          showModal={showModal}
                          forwardMessage={forwardMessage}
                          modaltitle="Forward"
                          connections={props.connections}
                          forward_message_action={forward_message_action}
                          message_text={ForwardMessageData}
                          forwardMessage_connection={props.connections}
                        />
                      )}
                    </>
                  )}
                </>
              );
            })}

          <Modal isOpen={modal} toggle={toggle} className="nexogic-chat-attachment-modal">
            <ModalBody>
              {imagePreview && <img className="radius"
                src={
                  imagePreview.media.fileUrl
                    ? imagePreview.media.fileUrl
                    : media_url_callback(
                      imagePreview.channel,
                      imagePreview.media
                    )
                }
                width="665px"
                alt="#" />}
            </ModalBody>
          </Modal>


          {Preview_modal ? (
            <MessageModal
              showModal={Preview_modal}
              forwardMessage={PreviewMessage}
              modaltitle="Preview"
              Uploaded_item={Uploaded_item}
            // send_file={send_file}
            />
          ) : (
            // console.log("err")
            <></>
          )}
        </div>
      </div>
      <div className="nexogic-chat-send">
        <form className="">
          <div className="emoji-icon-w">
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowEmoji(false);
              }}
            >
              <span onClick={showEmoji_handler} className="smile-icon">
                {/* <img src={SmileIcon} alt="" style={{ margin: "5px" }} /> */}
                <FontAwesomeIcon icon={faSmileBeam} />
              </span>
              {showEmoji && (
                <div className="emoji__pos">
                  <Picker onEmojiClick={onEmojiClick} disableSearchBar={true} />
                </div>
              )}
            </OutsideClickHandler>
          </div>
          <div className="nexogic-chat-type-wrap">
            <textarea
              id="cm_text"
              name="cm_text"
              className="input-field form-control"
              // style={{ width: "80px !important" }}
              type="text"
              // style={inputStyles}
              placeholder="Type Something ..."
              value={message}
              disabled={isDisabled}
              onKeyPress={(e) => {
                if (e.key !== "Enter") return;
                sendMessage(message);
              }}
              onChange={(e) => setMessage(e.target.value)}
            />
            {Image_pub_flag > 0 && (
              <div className="files_wrapper">
                {Image_pub_flag && (
                  <span className="upload-img">
                    <img src={file} alt="#" className="file-image" />
                    <FontAwesomeIcon icon={faTimes} className="dlt-icon" />
                  </span>
                )}
              </div>
            )}
          </div>
          {/* <ReactQuill theme="snow" placeholder="Enter ..." /> */}
          {/* <img src={AudioIcon} alt="" style={{ margin: "5px" }} /> */}
          <div className="nexogic-chat-actions">
            <span

              onClick={() => sendMessage(message)}
            >
              {/* <img
                  src={SendIcon}
                  alt=""
                  style={{ margin: "5px", opacity: "0.6" }}
                /> */}
              <FontAwesomeIcon className="text-primary" icon={faPaperPlane} />
            </span>
            <label

              htmlFor="upload_attachment"
              className="ml-2 mb-0"
            >
              {/* <img src={AttachIcon} alt="" style={{ margin: "5px" }} /> */}
              <FontAwesomeIcon icon={faPaperclip} />
            </label>
            <input
              id="upload_attachment"
              type="file"
              accept="image/jpeg,image/gif,image/png,application/pdf,video/*"
              style={{ display: "none" }}
              onChange={(event) => get_attach_item(event.target.files, event)}
            />
          </div>
        </form>
      </div>
    </>
  );
}

const listStyles = {
  // alignItems: "flex-end",
  // backgroundColor: "white",
  // margin: "20px",
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  overflowY: "auto",
  padding: "0px",
  // flexDirection: "column-reverse"
};

const messageStyles_right = {
  wordBreak: "break-word",
  maxWidth: "85%",
  background: "#FFFFFF",
  borderRadius: "5px",
  color: "#333",
  fontSize: "1.1rem",
  margin: "5px",
  padding: "8px 15px",
  textAlign: "left",
  maxWidth: "85%",
  wordBreak: "word-break",

  // background: "#DBF7FF",
  // opacity: 0.6,
  /* Complimentary */

  border: "1px solid rgba(0, 128, 167, 0.1)",
  boxSizing: "border-box",
  borderRadius: "10px 10px 10px 0px",
  // transform: "matrix(1, 0, 0, 1, 0, 0)",
};

const messageStyles_left = {
  wordBreak: "break-word",
  maxWidth: "85%",

  borderRadius: "5px",
  color: "#333",
  fontSize: "1.1rem",
  margin: "5px",
  padding: "8px 15px",
  textAlign: "right",

  background: "#DBF7FF",
  opacity: 0.6,
  /* Complimentary */

  border: "1px solid rgba(0, 128, 167, 0.1)",
  boxSizing: "border-box",
  borderRadius: "10px 10px 10px 0px",
};

const inputStyles = {
  flexGrow: 1,
  fontSize: "1.1rem",
  padding: "10px 15px",
};
export default ChatMessage;
