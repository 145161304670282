import * as React from 'react';
import { FormGroup, Input, Label } from "reactstrap";
import { ErrorMessage } from 'formik';

const FormikSelectField = ({
    field,
    form: { isSubmitting, touched, errors },
    disabled = false,
    ...props
}) => {
    return (
        <FormGroup className="flex-grow-1">
            {props.label && <Label htmlFor={props.inputprops.id} className={"label-color"}>{props.label}</Label>}
            <Input id={props.inputprops.id} {...field} {...props} type="select"
                invalid={Boolean(touched[field.name] && errors[field.name])}
                disabled={disabled}
                direction="down"
            >
                {props.inputprops.defaultOption &&
                    <option value="">{props.inputprops.defaultOption}</option>
                }
                {props.inputprops.options.map((option, index) => {
                    const { keys } = props.inputprops
                    if (keys)
                        return (<option value={option[keys.id]} key={index}>{option[keys.label]}</option>);
                    if (option.key)
                        return (<option value={option.key} key={index}>{option.label}</option>);
                    return (<option value={option} key={index}>{props.flag ? `<img src={googleImage}` : option}</option>)
                })}
            </Input>
            <ErrorMessage name={field.name} render={msg => <span className="text-danger f-12 d-inline-block  mt-1 line-height-error">{msg}</span>} />
        </FormGroup>
    )
};

export default FormikSelectField;