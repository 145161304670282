import React, { useState } from "react";
import { Button, PopoverBody, Popover } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faCheckCircle, faCalendarPlus, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { AppointmentStatus } from 'utils/Constants'

// bookingAction and rescheduleOpen are functions passed through prop
const AppointmentActionItems = ({ appointmentReference, appointmentStatus, rescheduleOpen, bookingAction, type }) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const toggle = () => setPopoverOpen(!popoverOpen);
    let actionItems = [];
    let acceptObject = {
        id: 1,
        label: 'Accept',
        icon: faCheckCircle,
        btnClasses: "w-100 status-icon f-12 line-h-12 mb-2",
        color: "primary"
    }
    let rejectObject = {
        id: 2,
        label: 'Reject',
        icon: faExclamationTriangle,
        btnClasses: "w-100 status-icon f-12 line-h-12 mb-2",
        color: "danger"
    }
    let rescheduleObject = {
        id: 3,
        label: 'Reschedule',
        icon: faCalendarPlus,
        btnClasses: "w-100 status-icon reschedule-btn f-12 line-h-12",
        color: "primary"
    }
    let joinObject = {
        id: 4,
        label: 'Join',
        icon: faVideo,
        btnClasses: "w-100 status-icon f-12 line-h-12 mb-2",
        color: "primary"
    }
    switch (appointmentStatus) {
        case AppointmentStatus.BOOKED:
            actionItems.push(acceptObject, rejectObject, rescheduleObject)
            break;
        case AppointmentStatus.CONFIRMED:
            actionItems.push(joinObject, rescheduleObject)
            break;
        case AppointmentStatus.CANCELLED:
            return null;
        case AppointmentStatus.REJECTED:
            return null;
        default: return null;
    }
    const handleClick = (label) => {
        if (label === "Accept") {
            bookingAction("accept")
            setPopoverOpen(false)
        } else if (label === "Reject") {
            bookingAction("reject")
            setPopoverOpen(false)
        } else if (label === "Reschedule") {
            rescheduleOpen()
            setPopoverOpen(false)
        }
    }
    if (actionItems.length > 1) {
        return (
            <div className="three-dots">
                <div id={appointmentReference} className="text-dark font-weight-bold mr-3">

                </div>
                <Popover placement="bottom" isOpen={popoverOpen} target={appointmentReference} trigger={'legacy'} toggle={toggle} className="action-btn-popover">
                    <PopoverBody className="contents d-flex flex-column justify-content-center align-items-center">

                        {
                            actionItems.map((item) => {
                                return (
                                    <Button key={item.id} className={item.btnClasses} color={item.color} onClick={() => handleClick(item.label)}>
                                        {item.label}
                                        <FontAwesomeIcon size="1x" icon={item.icon} className="ml-2" style={{ display: (type !== 'VIDEO' && item.label === 'Join') && "none" }} />
                                    </Button>
                                )
                            })
                        }

                    </PopoverBody>
                </Popover>

            </div>

        )
    } else if (actionItems.length === 1) {
        const item = actionItems[0];
        return (
            <Button className={item.btnClasses} color={item.color} onClick={() => handleClick(item.label)}>
                {item.label}
                <FontAwesomeIcon size="1x" icon={item.icon} className="ml-2" style={{ display: (type !== 'VIDEO' && item.label === 'Join') && "none" }} />
            </Button>
        )
    } else {
        return null;
    }

}
export default AppointmentActionItems;
