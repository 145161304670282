import React, { useState, PureComponent, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Spinner, ButtonGroup, Progress } from 'reactstrap';
import { Cropper } from "react-image-cropper"
import delIcon from "assets/images/del-red-icon.png";
import unsplashIcon from 'assets/images/svgs/unsplash.svg';
import camera_icon from "assets/images/camera-icon.png";
import { withAlert } from "react-alert"
import moment from "moment"

import { encrypt } from "utils/EncryptDecrypt"
import { validateImageType, validateImageSize, createImageFromInitials, getRandomColor } from 'utils/Utils'
import { InstitutionServices } from "utils/InstitutionServices";
import swal from "sweetalert";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

class RenderDialog extends PureComponent {

    handleCroppImage = async () => {
        const { uploadImageDetails, onSave, toggle } = this.props;
        const cropImageBase64 = this.cropper.crop();
        const result = await this.resizeBase64Img(cropImageBase64);
        const unixTime = moment().unix();
        const res = await fetch(result);
        const blob = await res.blob();
        const file = new File([blob], `${unixTime}_${uploadImageDetails.name}`, {
            type: uploadImageDetails.type,
        });
        // console.log("After croping profile image size", file.size);
        const validSize = validateImageSize(file, 1048576);
        toggle();
        if (validSize === 1) {
            onSave(file);
        } else {
            this.handleCancel();
            showErrorMessage("Image size should be less than 1 MB");
        }

    }
    resizeBase64Img = async (base64Str, MAX_WIDTH = 450, MAX_HEIGHT = 450) => {
        return await new Promise((resolve) => {
            let img = new Image();
            img.src = base64Str;
            img.onload = () => {
                let canvas = document.createElement("canvas");
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                let ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                resolve(canvas.toDataURL()); // this will return base64 image results after resize
            };
        });
    };

    handleCancel = () => {
        const { onSave, toggle } = this.props;
        toggle();
        onSave("");
    };

    render() {
        const { modal, toggle, profilePicUrl } = this.props
        return (
            <div>
                <Modal isOpen={modal} toggle={toggle} className="modal-dialog-scrollable modal-dialog-centered modal-crop">
                    <ModalHeader toggle={toggle} className="nex-institutions-modal-header"><span className="nex-text-xl">Profile Picture</span></ModalHeader>
                    <ModalBody className="px-1 px-sm-4">
                        <div style={{ width: "350px", maxWidth: "100%", overflow: "hidden" }} className="profile-cropper-circle d-flex align-items-center justify-content-center mx-auto">
                            <Cropper
                                src={profilePicUrl}
                                ref={ref => { this.cropper = ref }}
                                width={200} height={200}
                            />

                        </div>
                    </ModalBody>
                    <ModalFooter className="justify-content-end  modal-footer ">
                        <div className="action-group">
                            <Button color="primary" className="modal-save-btn nex-btn-primary" onClick={(e) => this.handleCroppImage(e)}>Save</Button>
                            <Button color="primary" className="modal-cancel-btn nex-btn-primary-outline" onClick={(e) => this.handleCancel(e)}>Cancel</Button>

                        </div>
                    </ModalFooter>
                </Modal>
            </div >
        );
    }

}

export const InstitutionImageUploader = (props) => {
    const { practitionerId, isEditable, isAdmin, institutionName, alert, institution } = props
    const [modal, setModal] = useState(false);
    const [showSpinner, setSpinner] = useState(false);
    const [uProgress, setUProgress] = useState(0);
    const [uploadImageDetails, setImageDetails] = useState({ name: "", type: "" })
    const [uploadImage, setImage] = useState("");
    const [sourceImage, setSourceImage] = useState("");

    const toggle = () => setModal(!modal);

    useEffect(() => {
        onLoadingDefaultImage(institution.guid, institution.avatarId)
    }, [institution.guid, institution.avatarId])

    const handleImageUpload = (event) => {
        let file = event.target.files[0]

        // fileType validation
        if (validateImageType(file)) {
            // fileSize validation
            const maxSize = 1000 * 1000 * 10; //10MB
            const validSize = validateImageSize(file, maxSize)
            if (validSize === 1) {
                let reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                    const imageBase64 = reader.result
                    setImageDetails({ name: file.name, type: file.type })
                    setImage(imageBase64)
                    toggle()
                }
            } else if (validSize === -1) {
                showErrorMessage('Invalid File!')
            } else {
                showErrorMessage('File must be less than 10MB')
            }
        } else {
            showErrorMessage('Invalid File!')
        }
    }

    const onLoadingDefaultImage = async (guidId, imageId) => {
        let imageSet = "";
        if (guidId && imageId) {
            imageSet = `${process.env.REACT_APP_IMAGE_BASE_URL}/${guidId}/${imageId}`;
        }
        setImage(imageSet);
        setSourceImage(imageSet);

    };

    const onImageChange = (file) => {
        if (file) {
            setSpinner(true);
            const options = (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent <= 100) {
                    setUProgress(percent);
                }
            }
            InstitutionServices.new_fileUpload(
                file,
                institution.guid,
                "profile",
                options
            ).then(
                (response) => {
                    if (response.status === 200 || response.status === 201) {
                        onLoadingDefaultImage(institution?.guid, response.data);
                        institution.avatarId = response.data;
                        showSuccessMessage("Profile Image uploaded successfully");
                        if (uProgress <= 100) {
                            setTimeout(() => {
                                setUProgress(0)
                            }, 1000);
                        }
                    } else {
                        showErrorMessage("Something went wrong while uploading your profile picture");
                    }
                    setSpinner(false);
                },
                (err) => {
                    setSpinner(false);
                    onLoadingDefaultImage(institution?.guid, "");
                    showErrorMessage(err.message);
                }
            );
        } else {
            setSpinner(false);
            if (institution?.avatarId) {
                onLoadingDefaultImage(institution?.guid, institution?.avatarId);
            } else {
                onLoadingDefaultImage(institution?.guid, "");
            }
        }
    };

    const handleImageDelete = () => {
        swal("Are you sure you want to remove profile image?", {
            buttons: ["cancel", "yes"],
        })
            .then((yesRemove) => {
                if (yesRemove)
                    InstitutionServices.deleteInstitutionImage(institution.guid, "profile")
                        .then((response) => {
                            const { status, data } = response;
                            if (status === 204) {
                                showSuccessMessage("Profile Image deleted successfully");
                                institution.avatarId = null;
                                onLoadingDefaultImage(null, null);
                            }
                            else {
                                showErrorMessage("Something went wrong, Please try again later");
                            }
                        })
            })
    }


    return (
        <>
            <RenderDialog
                modal={modal}
                toggle={toggle}
                profilePicUrl={uploadImage}
                uploadImageDetails={uploadImageDetails}
                onSave={onImageChange}
                alert={alert}
            />
            <>
                <div className="card-user-img">
                    {uProgress > 0 ? (
                        <div className="position-absolute nexogic-profile-pbar">
                            <Progress value={uProgress} max="100">{uProgress}</Progress>
                        </div>
                    ) : ""}
                    {<img src={institution.avatarId
                        ? sourceImage : createImageFromInitials(132, institutionName, getRandomColor(encrypt(institution.guid).toString()))} className="img-fluid" alt="profile picture" />}
                </div>
                {(isEditable || isAdmin) &&
                    <>
                        <Button color="secondary" className="edit-user-profile-img-btn" id="nex-iiu-edit-profile-img">

                            {/*<img src={camera_icon} width="18" />*/}
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="15.9062" cy="16" r="15.375" fill="white" stroke="#DBF4FB" stroke-width="0.75" />
                                <path d="M19.3612 9.53125L17.8557 11.0368L20.8667 14.0478L22.3722 12.5423L19.3612 9.53125ZM16.3502 12.5423L10.3281 18.5643V21.5754H13.3392L19.3612 15.5533L16.3502 12.5423Z" fill="#46ABC9" />
                            </svg>
                            <input
                                disabled={!isEditable && !isAdmin}
                                name="profilePicUrl"
                                className="fileUploadForm"
                                type="file"
                                onChange={(event) => {
                                    handleImageUpload(event)
                                    event.target.value = null
                                }}
                                id="profilePicUrl"
                                accept="image/*, png, jpg, jpeg"
                                title="" />
                        </Button>
                        {institution?.avatarId &&
                            <button type="button" className="remove-card-user-img-btn" id="institution_del_btn">
                                <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleImageDelete()}>
                                    <path d="M0.398438 5.77344L1.25857 12.1323C1.42602 13.3702 1.50975 13.9892 1.93372 14.3597C2.3577 14.7302 2.9823 14.7302 4.2315 14.7302H7.46922C8.71842 14.7302 9.34302 14.7302 9.76699 14.3597C10.191 13.9892 10.2747 13.3702 10.4421 12.1323L11.3023 5.77344" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M3.51562 3.04747V3.04747C3.51562 2.26721 3.51562 1.87708 3.65723 1.57473C3.80607 1.25693 4.06162 1.00138 4.37942 0.852541C4.68176 0.710938 5.0719 0.710938 5.85216 0.710938V0.710938C6.63243 0.710938 7.02256 0.710938 7.32491 0.852541C7.6427 1.00138 7.89825 1.25693 8.0471 1.57473C8.1887 1.87708 8.1887 2.26721 8.1887 3.04747V3.04747" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11.3023 3.05469L0.398438 3.05469" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M3.89062 6.17188L4.28005 12.0132" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.79567 6.17188L7.40625 12.0132" stroke="#FF9191" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {/*<img src={delIcon} width="18"  />*/}
                            </button>
                        }
                    </>
                }
            </>
        </>
    )
}

export default withAlert()(InstitutionImageUploader)