import React from 'react'
import { useState } from 'react';
import { ReferralService } from 'utils/ReferralService';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';
import { Button } from 'reactstrap';
import { createImageFromInitials, getRandomColor } from 'utils/Utils';
import HtmlStringToPdf from 'pages/PatientProfile/SecondOpinion/components/EmailPreview/HtmlStringToPdf';
export default function SentReferralPreview({ id }) {
    // let { id } = useParams();
    const [myReferralDetails, setMyReferralDetails] = useState({});
    const htmlStringToPdfRef = React.createRef();

    const handlePreviewPDFOutside = async () => {
        // Access the ref to HtmlStringToPdf component and call the function
        if (htmlStringToPdfRef.current) {
            await htmlStringToPdfRef.current.handlePreviewPDF();
        }
    };

    const history = useHistory();
    const handleBack = () => {
        history.goBack()
    }

    const getReferral = (id) => {
        ReferralService.getReferralById(id, "SENT")
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    console.log("data", data);
                    setMyReferralDetails(data);
                    // setIsLoading(false);
                } else if (status === 400 || status === 404) {
                    handleBack();
                }
                else {
                    console.log("Error");
                }
            })
    }

    useEffect(() => {
        getReferral(id);
    }, [id])

    return (
        <>
            <div className='text-center'>
                <HtmlStringToPdf target="#nex_email_preview_pdf" ref={htmlStringToPdfRef} />
            </div>
            <div style={{ height: "0px", overflow: "scroll" }}>
                <div className="pdf-warap" id="nex_email_preview_pdf" style={{ transformOrigin: 'top left', transform: 'scale(.17)', maxWidth: '1240px', height: '1754px', margin: '0 auto' }}>

                    <div className="pdf-header">
                        <div className="pdf-row">
                            <div className="pdf-col-half">
                                <h1 className="logo-text">
                                    <div className="job-info-image" >
                                        {myReferralDetails?.referredByInstitution &&
                                            // myReferralDetails?.referredByInstitution?.avatarId ? (
                                            //     <img
                                            //         src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${myReferralDetails?.referredByInstitution?.id}/${myReferralDetails?.referredByInstitution?.avatarId}`}
                                            //         alt="#"
                                            //         className="img-fluid"
                                            //         style={{ height: "50px" }}
                                            //     />
                                            // ) : (
                                            <img
                                                src={createImageFromInitials(
                                                    280,
                                                    myReferralDetails?.referredByInstitution?.name,
                                                    getRandomColor(null)
                                                )}
                                                alt="profile pic"
                                                className="img-fluid"
                                                style={{ height: "50px" }}
                                            />
                                            // )
                                        }
                                    </div>
                                </h1>
                            </div>
                            <div className="pdf-col-half address">
                                <p>{myReferralDetails?.referredByInstitution?.name}</p>
                                <p>{myReferralDetails?.referredByInstitution?.address?.location}
                                    {", "}{myReferralDetails?.referredByInstitution?.address?.city}
                                    {", "}{myReferralDetails?.referredByInstitution?.address?.state}
                                </p>

                            </div>
                        </div>

                    </div>


                    <div className="pdf-sec">
                        <h2 className="pdf-title">Essential information</h2>
                        <div className="pdf-row">
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Doctor name to whom refer</h3>
                                <p className="pdf-val">{myReferralDetails?.referredToPhysician?.name}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Hospital to which refer</h3>
                                <p className="pdf-val">{myReferralDetails?.referredToInstitution?.name}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Patient name </h3>
                                <p className="pdf-val">{myReferralDetails?.patient?.firstName}{" "}{myReferralDetails?.patient?.middleName}{" "}{myReferralDetails?.patient?.lastName}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Patient type</h3>
                                <p className="pdf-val">{myReferralDetails?.patientType}</p>
                            </div>
                        </div>
                    </div>

                    {/** row 1 */}
                    <div className="pdf-sec">
                        <h2 className="pdf-title">Patient information</h2>
                        <div className="pdf-row">
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Name </h3>
                                <p className="pdf-val">{myReferralDetails?.patient?.firstName}{" "}{myReferralDetails?.patient?.middleName}{" "}{myReferralDetails?.patient?.lastName}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Gender </h3>
                                <p className="pdf-val">{myReferralDetails?.patient?.gender}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Age </h3>
                                <p className="pdf-val">{myReferralDetails?.patient?.dateOfBirth}</p>
                            </div>
                        </div>
                    </div>

                    {/** row 2 */}
                    <div className="pdf-sec">
                        <h2 className="pdf-title">Referring physician information</h2>
                        <div className="pdf-row">
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Physician name </h3>
                                <p className="pdf-val">{myReferralDetails?.referredByPhysician?.name}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Date(requested on) </h3>
                                <p className="pdf-val">{"2023-08-01"}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Practice name</h3>
                                <p className="pdf-val">{myReferralDetails?.referredByPhysician?.primarySpeciality}</p>
                            </div>

                            <div className="pdf-col-half">
                                <h3 className="pdf-label">City</h3>
                                <p className="pdf-val">Philadelphia[S]</p>
                            </div>

                            <div className="pdf-col-half">
                                <h3 className="pdf-label">State (Required for Domestic Patient)</h3>
                                <p className="pdf-val">Pennsylvania[S]</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">ZIP code (Required for Domestic Patient)</h3>
                                <p className="pdf-val">19093[S]</p>
                            </div>
                        </div>
                    </div>

                    {/** row 3 */}
                    <div className="pdf-sec">
                        <h2 className="pdf-title">Referring Institution information</h2>
                        <div className="pdf-row">
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Institution name </h3>
                                <p className="pdf-val">{myReferralDetails?.referredByInstitution?.name}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Location</h3>
                                <p className="pdf-val">{myReferralDetails?.referredByInstitution?.address?.location}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">City</h3>
                                <p className="pdf-val">{myReferralDetails?.referredByInstitution?.address?.city}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">State</h3>
                                <p className="pdf-val">{myReferralDetails?.referredByInstitution?.address?.state}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">ZIP code</h3>
                                <p className="pdf-val"><p className="pdf-val">{"243432[S]"}</p></p>
                            </div>
                        </div>
                    </div>

                    {/** row 3 */}
                    <div className="pdf-sec">
                        <h2 className="pdf-title">Receiving physician information</h2>
                        <div className="pdf-row">
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Physician name </h3>
                                <p className="pdf-val">{myReferralDetails?.referredToPhysician?.name}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Date(requested on) </h3>
                                <p className="pdf-val">{"2023-08-01"}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Practice name</h3>
                                <p className="pdf-val">{myReferralDetails?.referredToPhysician?.primarySpeciality}</p>
                            </div>

                            <div className="pdf-col-half">
                                <h3 className="pdf-label">City</h3>
                                <p className="pdf-val">Philadelphia[S]</p>
                            </div>

                            <div className="pdf-col-half">
                                <h3 className="pdf-label">State (Required for Domestic Patient)</h3>
                                <p className="pdf-val">Pennsylvania[S]</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">ZIP code (Required for Domestic Patient)</h3>
                                <p className="pdf-val">19093[S]</p>
                            </div>
                        </div>
                    </div>

                    <div className="pdf-sec">
                        <h2 className="pdf-title">Receiving Institution information</h2>
                        <div className="pdf-row">
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Institution name </h3>
                                <p className="pdf-val">{myReferralDetails?.referredToInstitution?.name}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Location</h3>
                                <p className="pdf-val">{myReferralDetails?.referredToInstitution?.address?.location}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">City</h3>
                                <p className="pdf-val">{myReferralDetails?.referredToInstitution?.address?.city}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">State</h3>
                                <p className="pdf-val">{myReferralDetails?.referredToInstitution?.address?.state}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">ZIP code</h3>
                                <p className="pdf-val"><p className="pdf-val">{"243432[S]"}</p></p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
