import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { Field } from "formik";
import { Col, Row } from "reactstrap";
import { RenderSchedules } from "../RenderSchedules";

const HospitalTimingForm = (props) => {
  return (
    <>
      <Row form>
        {/* <Col lg={12}>
          <Field
            id={`scheduleType`}
            name={`scheduleType`}
            autoComplete={`scheduleType`}
            type="text"
            label="Type"
            component={FormikSelectField}
            inputprops={{
              name: `scheduleType`,
              options: [
                {
                  value: "HOSPITAL_HOURS",
                  label: "Hospital Hours",
                },
                {
                  value: "VISITOR_HOURS",
                  label: "Visiting Hours",
                },
                {
                  value: "OPD_HOURS",
                  label: "OPD Hours",
                },
              ],
              defaultOption: "Select",
              keys: {
                id: "value",
                label: "label",
              },
            }}
            disabled
          />
        </Col> */}
        <RenderSchedules {...props}/>
      </Row>
    </>
  );
};
export default HospitalTimingForm;
