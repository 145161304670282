import Footer from "common/Footer";
import Header from "common/Header";
import _, { add } from "lodash";
import Sidebar from "pages/App/components/Sidebar";
import { useEffect, useState, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row, Spinner } from "reactstrap";
import { setGlobalInstituteProfile } from "redux-helpers/ActionCreator";
import { InstitutionServices } from "utils/InstitutionServices";
import { getLoggedInUserInstitutions, isAdmin, isInstitutionAdmin } from "utils/UserHelper";
import { makeFullNameWithAddressWhenAddressIsNested } from "utils/Utils";
import InstitutionsDetail from "./components/InstitutionsDetails";
import InstitutionsProfile from "./components/InstitutionsProfile";
import RightSidebar from "./components/RightSidebar";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
//import "./institutions.scss";

const Institutions = (props) => {
  const dispatch = useDispatch();
  let {
    match: {
      params: { institutionID },
    },
  } = props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  const globalInstituteProfileInfo = useSelector(
    (state) => state.GlobalInstituteProfileReducer.globalInstituteInfo
  );
  const [institution, setInstitution] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setisActive] = useState(false);
  const [instituteThemeDetails, setInstituteThemeDetails] = useState(null);
  const [isCurrentUserInstitutionAdmin, setIsCurrentUserInstitutionAdmin] = useState(false);
  const currentUserId = currentUser.guid;
  const history = useHistory();
  // const navigate = useNavigate();
  const handleToggle = () => setisActive((value) => !value);
  const loggedInUserInstitutions = getLoggedInUserInstitutions();
  const getInstitutionProfileInfo = useCallback(
    (instguid) => {
      setIsLoading(true);
      try {
        InstitutionServices.getInstitutionById(instguid).then((response) => {
          if (response.status === 200 || response.status === 201) {
            setIsLoading(true);
            dispatch(setGlobalInstituteProfile(response.data));
            setInstitution(response.data);

            if (response.data?.parent) {
              InstitutionServices.getInstitutionById(response.data?.parent)
                .then((response1) => {
                  const { status } = response1;
                  if (status === 200) {
                    let d = {
                      "guid": response1?.data?.guid,
                      "institutionName": makeFullNameWithAddressWhenAddressIsNested(response1?.data)
                    }
                    response.data.parent = d;
                  }
                  else {
                    history.push(`/404`);
                    console.log("Something Went Wrong...");
                  }
                })
            }
            if (isInstitutionAdmin())
              checkIsCurrentUserInstitutionAdmin(response.data?.guid);
            getInstituteThemeDetails(response.data?.guid);
          } else {
            setIsLoading(false);
            history.push(`/404`);
            setInstitution(null);
          }
        });
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.log("Error while getting Institution getProfile: ", e);
      }
    },
    [dispatch]
  );

  const getInstituteThemeDetails = async (instguid) => {
    await InstitutionServices.getDisplayPreferences(instguid)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          setInstituteThemeDetails(data);
        }
        else {
          setInstituteThemeDetails({});
        }
      })
  }

  const checkIsCurrentUserInstitutionAdmin = (instId) => {
    const insts = currentUser.groups;

    if (insts.includes(instId)) {
      setIsCurrentUserInstitutionAdmin(true);
    } else {
      setIsCurrentUserInstitutionAdmin(false);
    }
  };

  const getCreatedUser = () => {
    if (parseInt(currentUserId) === parseInt(institution?.createdBy)) {
      return true;
    } else {
      return false;
    }
  };

  const passToPage = {
    institution: institution,
    currentUserId: currentUserId,
  }

  const CreateWebpageDashboard = () => {
    history.push("/create-webpage", { passToPage });
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    if (institutionID) {
      getInstitutionProfileInfo(institutionID);
    }
  }, [institutionID]);

  useEffect(() => {
    if (institution?.guid) {
      getInstituteThemeDetails(institution?.guid);
    }
  }, [institution?.guid]);
  return (
    <section id="onboardingPage" className="nexogic-intitution-page theme-bg">
      {/* <Header key={currentUserId} /> */}
      {(isAdmin() || (loggedInUserInstitutions && loggedInUserInstitutions.length > 0 && loggedInUserInstitutions.includes(institution?.guid))) &&
        <div className="nex-floated-notification">
          <p className="card-title"><span className="nex-app-d-none">Switch to admin view for more features.</span><Link
            to={{
              pathname: `/u/institution/${institution?.profileId}/admin`,
              state: { institution },
            }}
            className="nex-btn-primary">View as Admin</Link></p>
        </div>
      }
      <main className="theme-body-bg nexogic-dashboard-card">
        <Container fluid className={`card-body nex-app-institution-wrap p-0 ${isActive ? "side-open" : "col-sm-0"}`}>
          <div className="row">
            <div className="nexogic-admin-content">
              <div className="content-wrapper ml-0">
                {institution === null || instituteThemeDetails === null ? (
                  <div className="loading-spiner text-center pt-5">
                    <Spinner style={{ width: "2rem", height: "2rem" }} />
                  </div>
                ) : (
                  <>
                    <Row className="">
                      <Col>
                        <div className="nexogic-institute-wrapper">
                          <Row>
                            <Col xl={12}>
                              <InstitutionsDetail
                                institution={institution}
                                isAdmin={isAdmin() || isCurrentUserInstitutionAdmin}
                                isEditable={getCreatedUser()}
                                practitionerId={currentUserId}
                                getInstitutionProfile={getInstitutionProfileInfo}
                              >
                                <InstitutionsProfile
                                  institution={institution}
                                  practitionerId={currentUserId}
                                  isEditable={getCreatedUser()}
                                  isAdmin={isAdmin() || isCurrentUserInstitutionAdmin}
                                  getInstitutionProfile={getInstitutionProfileInfo}
                                // getInstitutionProfile={getInstitutionProfile}
                                />
                              </InstitutionsDetail>
                            </Col>
                          </Row>
                          {/* } */}
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </div>
          </div>
        </Container>
      </main>
    </section>
  );
};

export default connect(null, null)(Institutions);
