import pencilIcon from "assets/images/pencil_icon.png";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import { useState } from "react";
import { withAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { setGlobalprofile } from "redux-helpers/ActionCreator";
import { UserStatusConstants } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

import {
  validationSchema,
  validationSchemaAdmin
} from "./DoctorProfileValidationSchema";
import { RenderProfileFormFields } from "./RenderProfileFormFields";

const RenderEditProfileForm = (props) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const { practitioner, isAdmin, isLoggedinUser } = props;
  const {
    title,
    firstName,
    middleName,
    lastName,
    primarySpeciality,
    superSpeciality,
    guid,
    headline,
    dateOfBirth,
    gender,
    languages,
    address,
    phones,
    emails,
    status,
  } = practitioner;
  const practicingSince = practitioner.practicingSince || "";

  const getDefaultValue = (items) => {
    if (items && items.length > 0) {
      return {
        list: items,
        primayItem:
          items.findIndex((node) => node.primary === true) !== -1
            ? items.findIndex((node) => node.primary === true).toString()
            : "",
      };
    }
    return {
      list: [],
      primayItem: "",
    };
  };

  const initialValues = {
    title,
    firstName,
    middleName,
    lastName,
    primarySpeciality: isEmpty(primarySpeciality) ? [] : [primarySpeciality],
    superSpeciality: isEmpty(superSpeciality) ? [] : [superSpeciality],
    headline,
    dateOfBirth,
    practicingSince: practicingSince,
    gender,
    languages,
    streetAddress1:
      address && address.length > 0 && address[0]?.addressLine1
        ? address[0].addressLine1
        : "",
    streetAddress2:
      address && address.length > 0 && address[0]?.addressLine2
        ? address[0].addressLine2
        : "",
    city:
      address && address?.length > 0 && address[0]?.city
        ? [address[0].city]
        : [],
    state:
      address && address?.length > 0 && address[0]?.state
        ? [address[0].state]
        : [],
    country:
      address && address?.length > 0 && address[0]?.country
        ? [address[0].country]
        : [],
    zipCode:
      address && address?.length > 0 && address[0]?.postalCode
        ? address[0].postalCode
        : "",
    location:
      address && address.length > 0 && address[0].location !== undefined
        ? [address[0].location]
        : [],
    phones: getDefaultValue(phones).list,
    primaryItemPhone: getDefaultValue(phones).primayItem,
    emails: getDefaultValue(emails).list,
    primaryItemEmails: getDefaultValue(emails).primayItem,
  };

  const addOrReplace = (value) => {
    if (value === undefined || value === "" || value.length === 0) {
      return "add";
    } else {
      return "replace";
    }
  };

  const getActualDataForm = (newPayload, key, defaultValue = null) => {
    return newPayload.filter(
      (node) => node?.path.toLowerCase() === key.toLowerCase()
    ).length > 0
      ? newPayload.filter(
        (node) => node?.path.toLowerCase() === key.toLowerCase()
      )[0].value
      : defaultValue;
  };

  const updatePractitionersDetails = async (values, setSubmitting) => {
    try {
      let dob = null;

      if (values?.dateOfBirth.length === 10)
        dob = values?.dateOfBirth;
      else {
        dob = values?.dateOfBirth
          ? `${values.dateOfBirth.getFullYear()}-${String(values.dateOfBirth.getMonth() + 1).padStart(2, '0')}-${String(values.dateOfBirth.getDate()).padStart(2, '0')}`
          : "";
      }

      let payload = [
        {
          op: addOrReplace(practitioner.title),
          path: "/title",
          value: values.title,
        },
        {
          op: addOrReplace(practitioner.firstName),
          path: "/firstName",
          value: values.firstName,
        },
        {
          op: addOrReplace(practitioner.middleName),
          path: "/middleName",
          value: values?.middleName ?? "",
        },
        {
          op: addOrReplace(practitioner.lastName),
          path: "/lastName",
          value: values.lastName,
        },
        {
          op: addOrReplace(practitioner.dateOfBirth),
          path: "/dateOfBirth",
          value: dob ?? "",
        },
        {
          op: addOrReplace(practitioner.gender),
          path: "/gender",
          value: values?.gender || null,
        },
        {
          op: addOrReplace(practitioner.headline),
          path: "/headline",
          value: values?.headline ?? "",
        },
        {
          op: addOrReplace(practitioner.primarySpeciality),
          path: "/primarySpeciality",
          value: values?.primarySpeciality[0] ?? "",
        },
        {
          op: addOrReplace(practitioner.superSpeciality),
          path: "/superSpeciality",
          value: values?.superSpeciality[0] ?? "",
        },
        {
          op: addOrReplace(practitioner.practicingSince),
          path: "/practicingSince",
          value: values?.practicingSince ?? "",
        },
        {
          op: addOrReplace(practitioner.languages),
          path: "/languages",
          value:
            values?.languages && values?.languages.length > 0
              ? values?.languages
              : [],
        },
        {
          op: "replace",
          path: "/address",
          value: [
            {
              addressLine1: values?.streetAddress1 ?? "",
              addressLine2: values?.streetAddress2 ?? "",
              city: values?.city[0]?.value ?? "",
              country: values?.country[0]?.value ?? "",
              location: values?.location[0] ?? "",
              postalCode: values?.zipCode ?? "",
              state: values?.state[0]?.value ?? "",
            },
          ],
        },
        {
          op: "replace",
          path: "/phones",
          value: values.phones,
        },
        {
          op: "replace",
          path: "/emails",
          value: values.emails,
        },
      ];
      let newPayload = payload && payload.length > 0 ? [...payload] : [];
      if (payload && payload.length > 0) {
        newPayload = payload.map((obj) => {
          let cloneMain = { ...obj };
          if (
            cloneMain?.path === "/address" &&
            cloneMain?.value &&
            cloneMain?.value.length > 0
          ) {
            cloneMain.value = cloneMain.value.map((item) => {
              let clone = { ...item };
              let customLocation = item?.location?.customOption ? true : false;
              if (customLocation) {
                clone.location = item?.location?.value;
              }
              return clone;
            });
          }
          return cloneMain;
        });
      }
      setSubmitting(true);
      const payloadForNewApi = {
        address: getActualDataForm(newPayload, "/address", []),
        dateOfBirth: dob,
        emails: getActualDataForm(newPayload, "/emails", []),
        firstName: getActualDataForm(newPayload, "/firstName", ""),
        gender: getActualDataForm(newPayload, "/gender", null),
        headline: getActualDataForm(newPayload, "/headline", ""),
        languages: getActualDataForm(newPayload, "/languages", []),
        lastName: getActualDataForm(newPayload, "/lastName", ""),
        middleName: getActualDataForm(newPayload, "/middleName", ""),
        phones: getActualDataForm(newPayload, "/phones", []),
        practicingSince: getActualDataForm(newPayload, "/practicingSince", 0),
        primarySpeciality: getActualDataForm(
          newPayload,
          "/primarySpeciality",
          ""
        ),
        superSpeciality: getActualDataForm(newPayload, "/superSpeciality", ""),
        title: getActualDataForm(newPayload, "/title", ""),
      };

      PractitionerService.updatePractitionersPersonalDetails(
        payloadForNewApi,
        guid
      )
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setSubmitting(false);
            updateStorage(guid);
            showSuccessMessage("Profile updated Successfully");
          } else {
            setSubmitting(false);
            showErrorMessage(
              response?.message ??
              "Server posting problem while updating details."
            );
          }
        })
        .catch((error) => {
          console.log(
            "PractitionerService.updatePractitionerPersonalDetails error",
            error
          );
          setSubmitting(false);
          showErrorMessage("Server facing problem while updating details.");
        });
    } catch (e) {
      console.log(e);
    }
  };

  const updateStorage = (guid) => {
    PractitionerService.gePractitionersDetails(guid).then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(setGlobalprofile(response.data));
      }
      toggle();
    });
  };

  return (
    <>
      {(isAdmin ||
        (isLoggedinUser && status === UserStatusConstants.APPROVED)) && (
          <Button
            id="nex-ep-profile-details-title-edit-btn"
            color="link"
            className=""
            onClick={toggle}
            title="Edit Profile Personal Details"
          >
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.3125 0.25L6.09375 1.46875L8.53125 3.90625L9.75 2.6875L7.3125 0.25ZM4.875 2.6875L0 7.5625V10H2.4375L7.3125 5.125L4.875 2.6875Z" fill="#46ABC9"/>
</svg>


          </Button>
        )}
      <Modal
        id="profile-modal"
        isOpen={modal}
        toggle={toggle}
        className="profile-wrapper modal-dialog-scrollable modal-dialog-centered nex-apply-jobs-modal"
        backdrop="static"
      >
        <ModalHeader toggle={toggle} className="nex-apply-jobs-modal-header"><span className="nex-text-xl">Personal Details</span></ModalHeader>
        <ModalBody className="bg-white">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={
              isAdmin ? validationSchemaAdmin : validationSchema
            }
            onSubmit={(values, { setSubmitting }) =>
              updatePractitionersDetails(values, setSubmitting)
            }
            children={(props) => {
              return (
                <>
                  <RenderProfileFormFields {...props} toggle={toggle} />
                </>
              );
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default withAlert()(RenderEditProfileForm);
