import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fbIcon from "assets/images/svgs/fb-icon.svg";
import googleIcon from "assets/images/svgs/google-icon.svg";
import { Formik } from "formik";
import { useState } from "react";
import { withAlert } from "react-alert";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { emailNonNumericRegEx } from "utils/Constants";
import { IdentityService } from "utils/IdentityService";
import * as Yup from "yup";
import Footer from "../../../common/Footer";
import Header from "../../../common/Header";
import RegisterForm from "../components/RegisterForm";
import "../style.scss";

const initialValues = {
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string()
    .matches(emailNonNumericRegEx, "Email is not valid")
    .required("This field is required"),
});

const googleAuthLink = `${process.env.REACT_APP_LB_API_ENDPOINT}identity-service/oauth2/authorize/google?redirect_uri=${window.location.origin}/oauth2/redirect&app_id=practitioner`;

const facebookAuthLink = `${process.env.REACT_APP_LB_API_ENDPOINT}identity-service/oauth2/authorize/facebook?redirect_uri=${window.location.origin}/oauth2/redirect&app_id=practitioner`;

const RegisterNewWithEmail = (props) => {
  const history = useHistory();
  const { guid } = useParams();
  const [userResponse, setUserResponse] = useState(null);
  const [showRegistrationSuccessModal, setShowRegistrationSuccessModal] =
    useState(false);

  const toggleRegSuccessModal = () => {
    setShowRegistrationSuccessModal(false);
    if (userResponse?.guid)
      history.push(`/register/otp-verification/email`, {
        data: { guid: userResponse.guid },
      });
  };

  const registerUser = (values, { setSubmitting }) => {
    const payload = {
      email: values.email,
      type: "practitioner",
    };
    IdentityService.CreateRegistrationWithEmail(guid, payload)
      .then((response) => {
        if (response.status === 200) {
          setUserResponse(response.data);
          setShowRegistrationSuccessModal(true);
        } else {
          if (response.validationErrors) {
            response.validationErrors.map((err) => {
              props.alert.error(`${err.field}: ${err.message}`);
              return true;
            });
          } else if (response.message) {
            props.alert.error(response.message);
          }
        }
      })
      .catch((error) => {
        console.log("IdentityService.RegisterNewUser error", error);
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  return (
    <section>
      <div className="bg-light">
        <Header />
        <div className="bg-light-blue border-top py-5">
          <Container className="container-dashboard register-page">
            <Row>
              <Col>
                <Button
                  className="btn-icon mb-3"
                  color={"link"}
                  onClick={() => history.push("/register")}
                >
                  <FontAwesomeIcon icon={faChevronLeft} className="text-dark" />
                </Button>
              </Col>
            </Row>
            <Row className="pt-3 pb-5 justify-content-center">
              <Col lg={6} md={8} xs={10}>
                <div className="register-page__form-wrapper p-md-4 p-2">
                  <div>
                    <h4 className="register-page__form-wrapper__title mb-5">
                      Sign up
                    </h4>

                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      enableReinitialize={true}
                      onSubmit={registerUser}
                      children={(props) => {
                        return <RegisterForm {...props} />;
                      }}
                    />

                    <div className="register-page__separator">
                      <p>or signup with</p>
                    </div>

                    <Row className="register-page__social-logins">
                      <Col md={6}>
                        <a
                          href={facebookAuthLink}
                          className="register-page__social-logins--social-btn social-btn--fb d-flex align-items-center justify-content-center mb-4"
                        >
                          <div className="icon">
                            <img className="img-fluid" src={fbIcon} alt="" />
                          </div>
                          <div>Facebook</div>
                        </a>
                      </Col>
                      <Col md={6}>
                        <a
                          href={googleAuthLink}
                          className="register-page__social-logins--social-btn social-btn--google d-flex align-items-center justify-content-center mb-4"
                        >
                          <div className="icon">
                            <img
                              className="img-fluid"
                              src={googleIcon}
                              alt=""
                            />
                          </div>
                          <div>Google</div>
                        </a>
                      </Col>
                    </Row>
                    <p className="text-muted text-center">
                      Already have an account? <Link to="/login">Log in</Link>{" "}
                    </p>
                    {/* success modal */}
                    <Modal
                      isOpen={showRegistrationSuccessModal}
                      toggle={toggleRegSuccessModal}
                      modalClassName="nxg-modal"
                    >
                      <ModalHeader toggle={toggleRegSuccessModal}>
                        Verify OTP
                      </ModalHeader>
                      <ModalBody className={"bg-light-blue"}>
                        <p className="text-center p-5">
                          {`We have sent you an OTP to your email`}
                        </p>
                      </ModalBody>
                    </Modal>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default withAlert()(RegisterNewWithEmail);
