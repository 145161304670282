import React, { useState, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const JobPositionEditModal = (props) => {
    const { modal, setModal } = props;
    const { values, setFieldValue } = useFormikContext();
    const [selectedPosition, setSelectedPosition] = useState(values?.position);

    useEffect(() => {
        if (values?.position) {
            setSelectedPosition(values?.position);
        }
    }, [values?.position]);

    const handleRadioClick = (e) => {
        setSelectedPosition(e.target.value);
    };

    const handleSubmit = () => {
        setFieldValue('position', selectedPosition);
        setModal(false);
    };

    return (
        <Modal isOpen={modal} toggle={() => setModal(!modal)} className="nex-model-v2 modal-dialog-scrollable modal-dialog-centered">
            <ModalHeader toggle={() => setModal(!modal)}>Edit Job Position</ModalHeader>
            <ModalBody>
                <div className="form-group gap-md">
                    <h2 className='nex-text-lg text-black'>Select the type of position you are hiring for</h2>
                    <div className="nex-inline-radio-group">
                        <div className="item">
                            <Field 
                                type="radio" 
                                name="positionType" 
                                value="Full Time" 
                                checked={selectedPosition === "Full Time"} 
                                onClick={handleRadioClick} 
                            /> Full Time
                        </div>
                        <div className="item">
                            <Field 
                                type="radio" 
                                name="positionType" 
                                value="Part Time" 
                                checked={selectedPosition === "Part Time"} 
                                onClick={handleRadioClick} 
                            /> Part Time
                        </div>
                        <div className="item">
                            <Field 
                                type="radio" 
                                name="positionType" 
                                value="Flexible Time" 
                                checked={selectedPosition === "Flexible Time"} 
                                onClick={handleRadioClick} 
                            /> Flexible-Time
                        </div>
                        <div className="item">
                            <Field 
                                type="radio" 
                                name="positionType" 
                                value="Consultant" 
                                checked={selectedPosition === "Consultant"} 
                                onClick={handleRadioClick} 
                            /> Consultant
                        </div>
                    </div>
                </div>
                <div className="form-group nex-action-flex">
                <button className="btn nex-btn-primary ml-auto" onClick={handleSubmit}>Save</button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default JobPositionEditModal;
