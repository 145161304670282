import React, { useState } from 'react'
import AppointmentBookingForm from '../Doctors/components/BookingForm'
import DoctorProfileSec from '../Doctors/components/DoctorProfile'
import DoctorShortInfo from '../Doctors/components/DoctorShortInfo'
import CollapseableCard from '../Doctors/components/CollapseableCard'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
} from 'reactstrap';
import CollapseableItem from 'common/CollapseableItem'
import small_marker from "assets/svgIcon/marker-small.svg";
import clock_icon from "assets/images/small_clock_icon.png";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import del_red_icon from "assets/images/del-red-icon.png";
import awards_icon from "assets/svgIcon/awards_icon.svg";



export function PlusBtn(){
  return (
    <button className="btn p-0" type="button">test</button>
  )
}

export default function DoctorSingle() {
  const [open, setOpen] = useState('1');
  return (
    <div className="nexogic-page-columns">
        
            <div className="row">
                <div className="col-lg-12">
                  <h2 className="card-title big mb-3">Profile</h2>
                </div>
                <div className="col-lg-8 left-col">
                    <DoctorShortInfo id="nex-doctor-short-info" />
                    <CollapseableCard id="nex-doctor-service" title="Service" headAction={''}>
                        <ul className='list-group-flush nexogic-bullet-lists'>
                          <li className='list-group-item'>
                            Ablation For Treatment Of Atrial Fibrillation
                          </li>
                          <li className='list-group-item'>
                            Achilles Tendon Rupture Repair
                          </li>
                          <li className='list-group-item'>
                            Acl Anterior Cruciate Ligament Surgery
                          </li>
                          <li className='list-group-item'>
                            Abdominal Pain
                          </li>
                          <li className='list-group-item'>
                            Ablation
                          </li>
                          <li className='list-group-item'>
                          Abdominal Aortic
                          </li>
                        </ul>
                    </CollapseableCard>

                    <CollapseableCard id="nex-doctor-faq" title="Frequently asked questions" className="nex-faq-sec">
                        <CollapseableItem title="How soon can I make an appointment with Dr. Anna Sotelo?" isPannelOn={true}>
                          <p>Generally, Dr. Anna Sotelo has appointments available on Nexogic within 1 week. You can see Dr. Dellosso's earliest availability on Zocdoc and make an appointment online.</p>
                        </CollapseableItem>
                        <CollapseableItem title="Is Dr. Anna Sotelo accepting new patients?" >
                          <p>Generally, Dr. Anna Sotelo has appointments available on Nexogic within 1 week. You can see Dr. Dellosso's earliest availability on Zocdoc and make an appointment online.</p>
                        </CollapseableItem>
                        <CollapseableItem title="Does Dr. Anna Sotelo accept my insurance?" >
                          <p>Generally, Dr. Anna Sotelo has appointments available on Nexogic within 1 week. You can see Dr. Dellosso's earliest availability on Zocdoc and make an appointment online.</p>
                        </CollapseableItem>
                        <CollapseableItem title="Can I make an appointment with Dr. Anna Sotelo online?">
                          <p>Generally, Dr. Anna Sotelo has appointments available on Nexogic within 1 week. You can see Dr. Dellosso's earliest availability on Zocdoc and make an appointment online.</p>
                        </CollapseableItem>
                    </CollapseableCard>


                    {/*hospital */}
                    <CollapseableCard id="nex-doctor-service" title="Orthopaedic Ophthalmic Physiotherapy Clinic" headAction={''}>
                      <div id='affiliationSection'>
                      <ul className="list-group list-group-flush" id="nex-ha-institution-list">
                        <li className="list-group-item">
                          <div className="row">
                            <div className="col-left col-lg-5">
                                <div className="nexogic-hospital-card">
                                    <img className='icon' src="https://images-dev.nexogic.com/55d51516-b9e5-42e0-b2a2-79e8ca6c73ce/19e8e6dd-ebcc-42a0-8718-70ff23471f35.jpg" alt="" />
                                  <div className="info">
                                    <h3 className="card-title">Malabar Ayurvedic Hospital</h3>
                                    <p className='card-text address-text'>
                                      <span className="icon-w">
                                        <img src={small_marker} alt="" />
                                      </span>
                                      <small className="text-muted"> <span>Annassery, </span><span>Kozhikode, </span><span>Kerala, </span><span>India</span></small>
                                    </p>
                                  </div>
                                </div>
                            </div>
                            <div className="col-right col-lg-5">
                              <div className="nexogic-opening-times">
                                <h3 className="card-title">
                                  <img src={clock_icon} alt="" className="icon" />   Consulting Hours  
                                  <a className="readmore"> Show More </a>
                                </h3>
                                <div>
                                  <div className="item card-text">
                                    <small className="date text-muted">TUE</small>
                                    <small className="times text-muted"><div className="time">01:00 PM - 01:30 PM</div><div className="time">02:00 PM - 05:30 PM</div><div className="time">06:00 PM - 11:00 PM</div></small>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                            <div className="col-action d-flex nexogic-hospital-times-card">
                                <div className="delete-action">
                                <Button
                                color="link"
                                className="edit py-0"
                                
                              >
                                <img src={edit_blue_icon} alt="" />
                              </Button>
                              <Button
                                color="link"
                                className="delete py-0"
                                
                              >
                                <img src={del_red_icon} alt="" />
                              </Button>
                                </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                      </div>
                    </CollapseableCard>

                    {/* Awords */}
                    <CollapseableCard id="nex-doctor-service" title="Awards & Recognitions" headAction={''}>
                      <ul className="list-group list-group-flush" id="nex-ar-awards-lists">
                        <li className='list-group-item'>
                          <div className="row g-0">
                            <div className="col-auto">
                              <img src={awards_icon} alt="" />
                            </div>
                            <div className="col">
                                <h3 className="card-title">Best Surgeon</h3>
                                <p className="card-title mb-0">
                                  <small className="text-muted fw-semibold"><a className="mb-2 color-accent" href="/institution/gov-a-grade-aurvadic-hospital">Gov A Grade Aurvadic Hospital , GAAC2 - ddddddd - Jaipur , Rajasthan</a></small>
                                </p>
                                <p className="card-text"><small className="text-muted">Issued On: Dec 2019</small></p>
                            </div>
                            <div className="col-auto d-flex align-items-start">
                              <div className="delete-action">
                              <Button
                                color="link"
                                className="edit py-0"
                                
                              >
                                <img src={edit_blue_icon} alt="" />
                              </Button>
                              <Button
                                color="link"
                                className="delete py-0"
                                
                              >
                                <img src={del_red_icon} alt="" />
                              </Button>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </CollapseableCard>

                     {/* Spoken */}
                     <CollapseableCard id="nex-doctor-service" title="Languages spoken" headAction={''}>
                        <ul className='list-group-flush nexogic-bullet-lists'>
                          <li className='list-group-item'>English</li>
                          <li className='list-group-item'>Spanish</li>
                        </ul>
                    </CollapseableCard>
                </div>
                <div className="col-lg-4 right-col">
                    {/* appointment booking */}
                    <AppointmentBookingForm />
                </div>
            </div>
    </div>
  )
}
