import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import ConnectionCard from "./ConnectionCard";
import NoConnection from "./NoConnection";
import { ConnectionService } from 'utils/ConnectionService';
import CommonLoader from "common/CommonLoader";

const AllConnectionsTab = (props) => {

    const currentUser = useSelector(state => state.auth.currentUser);
    const guid = currentUser.guid;
    const [allConnections, setAllConnections] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    useEffect(() => {
        getActiveConnections(0, []);
    }, [guid]);

    const getActiveConnections = (pageNo, connections) => {
        ConnectionService.getConnectionsInfo(guid, "ACTIVE", pageNo)
            .then(response => {
                let { data } = response;
                if (data?.currentPage < (data?.totalPages - 1))
                    setHasMore(true);
                else
                    setHasMore(false);
                setIsLoadingMore(false);
                if (response.status === 200 || response.status === 201) {
                    if (pageNo == 0) {
                        setAllConnections(data);
                        setIsLoading(false);
                    } else {
                        connections = connections.concat(data.contents)
                        setAllConnections({ ...data, "contents": connections });
                        setIsLoading(false);
                    }
                }
            })
            .catch((error) => {
                setIsLoadingMore(false);
                console.log("Error while getting connection: ", error);
            })
    }

    if (isLoading) {
        return (
            <div className="loading-spiner text-center  mt-2">
                <CommonLoader style={{ width: "2rem", height: "2rem" }} />
            </div>
        );
    }

    return (
        <>
            {
                allConnections.totalItems ?
                    <ConnectionCard
                        connectionsDetails={allConnections}
                        getConnections={getActiveConnections}
                        setConnectionsDetails={setAllConnections}
                        setIsLoading={setIsLoading}
                        isLoadingMore={isLoadingMore}
                        setIsLoadingMore={setIsLoadingMore}
                        hasMore={hasMore}
                    />
                    :
                    <NoConnection content={'No Connections'} />
            }
        </>
    )
}

export default AllConnectionsTab