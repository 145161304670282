import {
  GET_RECENT_EXPERIENCE,
  SET_RECENT_EXPERIENCE,
  GET_RECENT_EDUCATION,
  SET_RECENT_EDUCATION,
} from "./Actions";

let initialState = {
  recentExperienceList: [],
  recentEducationList: [],
};

function RecentExperienceReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RECENT_EXPERIENCE:
      return {
        ...state,
        recentExperienceList: action.payload,
      };
    case GET_RECENT_EDUCATION:
      return {
        ...state,
        recentEducationList: action.payload,
      };
    case SET_RECENT_EXPERIENCE:
      return {
        ...state,
        recentExperienceList: action.payload,
      };
    case SET_RECENT_EDUCATION:
      return {
        ...state,
        recentEducationList: action.payload,
      };
    default:
      return state;
  }
}

export default RecentExperienceReducer;
