import React from 'react'
import Slider from "react-slick";
import './style.scss';

export default function FullWidth() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
  return (
    <div className='micro-slider-v1'>
        <Slider {...settings}>
            <div className="slider-item">
                <img src="https://picsum.photos/id/202/600/200" alt="" />
            </div>
            <div className="slider-item">
                <img src="https://picsum.photos/id/203/600/200" alt="" />
            </div>
            <div className="slider-item">
                <img src="https://picsum.photos/id/204/600/200" alt="" />
            </div>
        </Slider>
    </div>
  )
}
