import { Badge } from "reactstrap";
import { convertJobShiftToTitleCase } from "utils/Utils";

const ShowStatus = ({ status }) => {

  switch (status) {
    case "APPLIED":
      return <Badge className="badgetext badge-green" color="dark">{convertJobShiftToTitleCase(status)}</Badge>;
    case "SHORTLISTED":
      return <Badge className="badgetext badge-blue" color="primary">{convertJobShiftToTitleCase(status)}</Badge>;
    case "INTERVIEW":
      return <Badge className="badgetext badge-blue-light" color="">{convertJobShiftToTitleCase(status)}</Badge>;
    case "ACCEPTED":
      return <Badge className="badgetext badge-green accepted" color="">{convertJobShiftToTitleCase(status)}</Badge>;
    case "OFFERED":
      return <Badge className="badgetext badge-green-light" color="warning">{convertJobShiftToTitleCase(status)}</Badge>;
    case "HIRED":
      return <Badge className="badgetext badge-green" color="success">{convertJobShiftToTitleCase(status)}</Badge>;
    case "CLOSED":
      return <Badge className="badgetext badge-dengar" color="secondary">{convertJobShiftToTitleCase(status)}</Badge>;
    case "ON_HOLD":
      return <Badge className="badgetext" color="info">{convertJobShiftToTitleCase(status)}</Badge>;
    default:
      return <Badge className="badgetext badge-dengar" color="danger">{convertJobShiftToTitleCase(status)}</Badge>;
  }
};

export default ShowStatus;