import Section001 from 'pages/UIDashboard/Components/Sections/Section001';
import Section002 from 'pages/UIDashboard/Components/Sections/Section002';
import Section1 from 'pages/UIDashboard/Components/Sections/Section1';
import Section3 from 'pages/UIDashboard/Components/Sections/Section3';
import React from 'react';
import { useState, useEffect } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import './style.scss';
import List from 'pages/UIDashboard/Components/Lists/List';
import educations_icon from "assets/svgIcon/educations-icon.svg";
import OurLocations from 'pages/UIDashboard/Components/OurLocations';
import FullSlider from 'pages/UIDashboard/Components/Sliders/Components/FullSlider';

const ThemeOne = () => {
    const [themeBgColor, setThemeBgColor] = useState('#00b7ac');
    const [sectionBG, setSectionBG] = useState('#f6f7fd');
    const [sectionFont, setSectionFont] = useState('');
    const [sectionHeaderBg, setSectionHeaderBg] = useState('#02827a');
    const [sectionHeaderFontColor, setSectionHeaderFontColor] = useState('#ffffff');
    const [sHeaderFontSize, setSHeaderFontSize] = useState('1rem');
    const [sHeaderFontWeight, setSHeaderFontWeight] = useState('bold');
    const [sHeader, setSHeader] = useState(''); //Header or none for No Header 
    const theme = {
        bg: {
            backgroundColor: `${themeBgColor}`,
        },
        sectionbg: {
            padding: "0",
            color: `${sectionFont ? sectionFont : ''}`,
            backgroundColor: `${sectionBG}`
        },
        theader: {
            backgroundColor: `${sectionHeaderBg}`,
            fontSize: `${sHeaderFontSize}`,
            color: `${sectionHeaderFontColor}`,
            fontWeight: `${sHeaderFontWeight}`
        },
    };
    // const [themeBgColor,setThemeBgColor] = useState('red');
    // const [themeBgColor,setThemeBgColor] = useState('red');

    // useEffect(()=>{
    //     if(themeBgColor){

    //     }
    // },[themeBgColor])
    return (
        <Card className="micro-site-wrap micro-site-theme-v1" style={theme.bg}>
            <CardHeader className="micro-header micro-bg-gray" style={{ backgroundImage: "url(https://picsum.photos/id/10/1200/300)" }}>
                <div className="micro-logo" style={{ backgroundImage: "url(https://picsum.photos/id/64/200/300)" }}>Logo</div>
            </CardHeader>
            <CardBody className="micro-main-content-wrap">
                <Row className='m-0'>
                    <Col lg={12} className='px-0 pb-3'>
                        {/* Slider */}
                        <FullSlider />
                        {/* Slider ends */}
                    </Col>
                </Row>

                <Row>
                    <Col className='mb-3'>
                        <List list={['Test 1', 'Test 2', 'Test 3']} column={2} icon={educations_icon} />
                        <br />
                        <List list={['Test 1', 'Test 2', 'Test 3']} column={3}  />
                        <br />
                        <List list={['Test 1', 'Test 2', 'Test 3']} column={1}  />
                    </Col>
                </Row>

                <Row>
                    <Col className='mb-3'>
                        <OurLocations />
                    </Col>
                </Row>

                <Row>
                    <Col lg={4} className="mb-3">
                        <Section1 />
                        </Col>
                    <Col lg={8} className="mb-3">
                        <Section3 />
                        </Col>
                </Row>
                {/** 
                <Section001 />               
                <div className="micro-content-flex">
                    <Section002 />
                </div>
                */}
                <Row>
                    <Col lg={12} className=" mb-3" >
                        <div className='h-lg' style={theme.sectionbg}></div>
                    </Col>
                </Row>
                {/** 
                <div className="micro-content-flex bg-warning" style={theme.sectionbg}>
                    <div className="col-12 col-lg-12 h-lg"></div>
                </div>
                */}
            </CardBody>
            <CardFooter className="micro-footer micro-bg-blue text-center text-white">Footer</CardFooter>
        </Card>
    )
}

export default ThemeOne