import API from "./API_New";
import API_Factory from "./API_Factory"

const createInstitution = (payload) => {
    return new Promise((resolve, reject) => {
        const url = `institute/profile`
        try {
            let response = API.post(url, payload)
            return resolve(response)
        } catch (e) {
            return reject(e)
        }
    })
}

const getInstitutionById = (guid) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `institution-service/institutions/${guid}`,
                method: "GET",
                responseType: "application/json",
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const updateInstitution = (payload, institutionId) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `institution-service/institutions/${institutionId}`,
                method: "PUT",
                responseType: "application/json",
                data: payload,
                ignoreToken: false
            });
            return resolve(response)
        } catch (e) {
            return reject(e)
        }
    })
}

const getInstitutions = async (query, searchQuery, type) => {
    try {
        if (query.length < 3) return;
        const response = await API_Factory({
            url: `${process.env.REACT_APP_API_INSTITUTION_SERVICE}/institutions/autocomplete?q=${query}&t=${type}`,
            method: 'GET',
            responseType: "application/json",
            ignoreToken: false
        })
        return response;
    } catch (e) {
        return e
    }
}

//Get all Institution with Referral Service enabled
const getReferralActiveInstitutionsList = async (query, searchQuery) => {
    try {
        if (query.length < 3) return;
        const response = await API_Factory({
            url: `${process.env.REACT_APP_API_INSTITUTION_SERVICE}/institutions/search?sq=${searchQuery}`,
            method: 'GET',
            responseType: "application/json",
            ignoreToken: false
        })
        return response;
    } catch (e) {
        return e
    }
}

const awardsInstitution = (institutionId) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `institution-service/institutions/${institutionId}/awards`,
                method: "GET",
                ignoreToken: false
            });
            return resolve(response)
        } catch (e) {
            return reject(e)
        }
    })
}

const deleteAwardInstitution = (institutionId, AWARD_GUID) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `institution-service/institutions/${institutionId}/awards/${AWARD_GUID}`,
                method: "DELETE",
                ignoreToken: false
            });
            return resolve(response)
        } catch (e) {
            return reject(e)
        }
    })
}

const addAwardInstitution = (payload, institutionId) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `institution-service/institutions/${institutionId}/awards`,
                method: "POST",
                responseType: "application/json",
                data: payload,
                ignoreToken: false
            });
            return resolve(response)
        } catch (e) {
            return reject(e)
        }
    })
}


const updateAwardInstitution = (payload, institutionId, AWARD_GUID) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `institution-service/institutions/${institutionId}/awards/${AWARD_GUID}`,
                method: "PUT",
                responseType: "application/json",
                data: payload,
                ignoreToken: false
            });
            return resolve(response)
        } catch (e) {
            return reject(e)
        }
    })
}

const galleryInstitution = (institutionId, cp = 0) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `institution-service/institutions/${institutionId}/medias?cp=${cp}`,
                method: "GET",
                ignoreToken: false
            });
            return resolve(response)
        } catch (e) {
            return reject(e)
        }
    })
}

const instituteOtherLocation = (institutionId) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `institution-service/institutions/${institutionId}/branches`,
                method: "GET",
                ignoreToken: false
            });
            return resolve(response)
        } catch (e) {
            return reject(e)
        }
    })
}

const galleryInstitutionDownload = (ID, MEDIA_ID) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `institution-service/institutions/${ID}/medias/${MEDIA_ID}/download`,
                method: "GET",
                ignoreToken: false
            });
            return resolve(response)
        } catch (e) {
            return reject(e)
        }
    })
}

const addGalleryInstitution = (files, ID) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        formData.append(`photos`, files[i]);
    }
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `institution-service/institutions/${ID}/medias`,
                method: "POST",
                data: formData,
                ignoreToken: false,
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const deleteGalleryInstitution = (ID, IMAGE_ID) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `institution-service/institutions/${ID}/medias/${IMAGE_ID}`,
                method: "DELETE",
                ignoreToken: false
            });
            return resolve(response)
        } catch (e) {
            return reject(e)
        }
    })
}

const new_fileUpload = (file, guid, fileType, options) => {
    const categoryType = fileType === "profile" ? "AVATAR" : "BANNER";
    const formData = new FormData();
    formData.append("image", file);
    // formData.append("source", categoryType);
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `${process.env.REACT_APP_API_INSTITUTION_SERVICE}/institutions/${guid}/images?source=${categoryType}`,
                method: "PUT",
                data: formData,
                ignoreToken: false,
                onUploadProgress: options
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const deleteInstitutionImage = async (guid, fileType) => {
    const categoryType = fileType === "profile" ? "AVATAR" : "BANNER";
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `${process.env.REACT_APP_API_INSTITUTION_SERVICE}/institutions/${guid}/images?source=${categoryType}`,
                method: "Delete",
                // params: {
                //   UUID: guid,
                //   source: categoryType,
                // },
                ignoreToken: false,
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const patchInstitutionDetails = (data, guid) =>
    API_Factory({
        method: "PATCH",
        url: `${process.env.REACT_APP_API_INSTITUTION_SERVICE}/institutions/${guid}`,
        data,
        ignoreToken: false,
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });

const searchInstitutions = async (q, cp) => {
    try {
        if (q.length < 3) return;
        const response = await API_Factory({
            url: `${process.env.REACT_APP_API_INSTITUTION_SERVICE}/institutions/search?sq=${q}&cp=${cp}&nr=10`,
            method: 'GET',
            responseType: "application/json",
            ignoreToken: false
        })
        return response;
    } catch (e) {
        return e
    }
}

const addInstitution = (payload) => {

    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_URL,
                url: `institution-service/institutions`,
                method: "POST",
                responseType: "application/json",
                data: payload,
                ignoreToken: false
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const getPreferences = (guid) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `institution-service/${guid}/preferences`,
                method: "GET",
                responseType: "application/json",
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const updatePreferences = (guid, payload) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
                url: `institution-service/${guid}/preferences`,
                method: "PUT",
                responseType: "application/json",
                data: payload,
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const getDisplayPreferences = (guid) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `institution-service/${guid}/preferences/display-preference`,
                method: "GET",
                responseType: "application/json",
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const updateDisplayPreferences = (guid, payload) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
                url: `institution-service/${guid}/preferences/display-preference`,
                method: "PUT",
                responseType: "application/json",
                data: payload,
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};


export const InstitutionServices = {
    createInstitution,
    getInstitutionById,
    updateInstitution,
    awardsInstitution,
    addAwardInstitution,
    updateAwardInstitution,
    deleteAwardInstitution,
    getInstitutions,
    getReferralActiveInstitutionsList,
    instituteOtherLocation,
    galleryInstitution,
    addGalleryInstitution,
    deleteGalleryInstitution,
    galleryInstitutionDownload,
    new_fileUpload,
    deleteInstitutionImage,
    patchInstitutionDetails,
    searchInstitutions,
    addInstitution,
    getPreferences,
    updatePreferences,
    getDisplayPreferences,
    updateDisplayPreferences
}