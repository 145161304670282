import React, { useRef, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Row, Col, Spinner } from 'reactstrap';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import FormikInputField from "components/forms/formikFields/FormikInputField"
import { withAlert } from "react-alert"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css';
import 'assets/styles/customizedQuill.css'
import { InstitutionServices } from "utils/InstitutionServices"
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const validationSchema = Yup.object().shape({
  instituteSummary: Yup.string().required("This field is required").nullable()
});

const CustomToolbar = () => (
  <div id="toolbar">

    <select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
      <option value="1" />
      <option value="2" />
      <option value="3" />
      <option value="4" />
      <option value="5" />
      <option value="6" />
      <option selected />
    </select>
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-underline" />
    <button className="ql-strike" />
    {/* <select className="ql-align" /> */}
    <button className="ql-list" value="ordered" />
    <button className="ql-list" value="bullet" />
    <button className="ql-link" />
  </div>
);


const RenderField = (props) => {
  const { isValid, dirty, handleSubmit, toggle, values, setFieldValue, isLoading } = props
  const textInput = useRef();

  const handleChange = (html) => {
    setFieldValue("instituteSummary", html, true)
  }
  return (
    <form onSubmit={handleSubmit}>
      <CustomToolbar />
      <ReactQuill
        id='raf_institute_summary'
        onChange={handleChange}
        ref={textInput}
        value={values.instituteSummary || ""}
        bounds={".app"}
        modules={RenderAboutForm.modules}
        formats={RenderAboutForm.formats}
      />
      <div className="justify-content-end  modal-footer ">
        <div className="action-group">
          {isLoading ?
            <div className="loading-spiner"><Spinner style={{ width: '2rem', height: '2rem' }} /></div>
            :
            <>
              <Button id="raf_btn_save" className="modal-save-btn nex-btn-primary" disabled={!isValid || !dirty} color="secondary " type="submit">Save</Button>
              <Button id="raf_btn_cancel" className="modal-cancel-btn nex-btn-primary-outline" color="primary " type="button" onClick={toggle}>Cancel</Button>
            </>
          }
          {/* <Button
            id='raf_btn_save'
            color="primary"
            className="nexogic_primary_button"
            type="submit"
            disabled={!dirty}
          >
            Save
          </Button>
          <Button
            id='raf_btn_cancel'
            color="primary"
            outline
            className="nexogic_primary_button_outline"
            type="button"
            onClick={toggle}
          >
            Cancel
          </Button> */}
        </div>
      </div>
    </form>
  );
}

const RenderAboutForm = (props) => {
  const { isOpen, toggle, instituteSummary, alert, isAdmin, practitionerId, institutionId, getInstitutionProfile, institution } = props;
  const initialValues = { "instituteSummary": instituteSummary }
  const [isLoading, setLoading] = useState(false)
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setLoading(true)
          setTimeout(async () => {
            setSubmitting(true)
            let payload = [
              {
                op: "add",
                path: "/aboutUs",
                value: values.instituteSummary,
              }
            ]
            InstitutionServices.patchInstitutionDetails(payload, institutionId)
              .then(response => {
                const { status } = response;
                if (status === 200) {
                  setLoading(false)
                  toggle();
                  getInstitutionProfile(institutionId);
                  showSuccessMessage("Institution updated successfully");
                } else {
                  setLoading(false)
                  showErrorMessage(response.message);
                }
              }).catch(err => {
                showErrorMessage(err.message)
              });
            setLoading(false)
            toggle()
            setSubmitting(false)
          }, 400);
        }}
        children={(props) => {
          return <RenderField toggle={toggle} {...props} isLoading={isLoading} />
        }}
      />
    </div>
  );
}

export default withAlert()(RenderAboutForm)
RenderAboutForm.modules = {
  toolbar: {
    container: "#toolbar"
  },
  clipboard: {
    matchVisual: false
  }
}
// RenderAboutForm.formats = [
//   "bold",
//   "italic",
//   "underline",
//   "strike",
//   "link",
// ]