import React, { useState, PureComponent, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Spinner, ButtonGroup, Progress } from 'reactstrap';
import { Cropper } from "react-image-cropper"
import delIcon from "assets/images/del-red-icon.png";
import unsplashIcon from 'assets/images/svgs/unsplash.svg';
import camera_icon from "assets/images/camera-icon.png";
import { withAlert } from "react-alert"
import moment from "moment"

import { encrypt } from "utils/EncryptDecrypt"
import { validateImageType, validateImageSize, createImageFromInitials, getRandomColor } from 'utils/Utils'
import { InstitutionServices } from "utils/InstitutionServices";
import swal from "sweetalert";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

class RenderDialog extends PureComponent {

    handleCroppImage = async () => {
        const { uploadImageDetails, onSave, toggle } = this.props;
        const cropImageBase64 = this.cropper.crop();
        const result = await this.resizeBase64Img(cropImageBase64);
        const unixTime = moment().unix();
        const res = await fetch(result);
        const blob = await res.blob();
        const file = new File([blob], `${unixTime}_${uploadImageDetails.name}`, {
            type: uploadImageDetails.type,
        });
        const validSize = validateImageSize(file, 1048576);
        toggle();
        if (validSize === 1) {
            onSave(file);
        } else {
            this.handleCancel();
            showErrorMessage("Image size should be less than 1 MB");
        }

    }
    resizeBase64Img = async (base64Str, MAX_WIDTH = 450, MAX_HEIGHT = 450) => {
        return await new Promise((resolve) => {
            let img = new Image();
            img.src = base64Str;
            img.onload = () => {
                let canvas = document.createElement("canvas");
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                let ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                resolve(canvas.toDataURL()); // this will return base64 image results after resize
            };
        });
    };

    handleCancel = () => {
        const { onSave, toggle } = this.props;
        toggle();
        onSave("");
    };

    render() {
        const { modal, toggle, profilePicUrl } = this.props
        return (
            <div>
                <Modal isOpen={modal} toggle={toggle} className="modal-dialog-scrollable modal-dialog-centered modal-crop">
                    <ModalHeader toggle={toggle}>Profile Picture</ModalHeader>
                    <ModalBody className="px-1 px-sm-4">
                        <div style={{ width: "350px", maxWidth: "100%", overflow: "hidden" }} className="profile-cropper-circle d-flex align-items-center justify-content-center mx-auto">
                            <Cropper
                                src={profilePicUrl}
                                ref={ref => { this.cropper = ref }}
                                width={200} height={200}
                            />

                        </div>
                    </ModalBody>
                    <ModalFooter className="justify-content-end  modal-footer ">
                        <div className="action-group">
                            <Button color="primary" className="nex-btn-primary" onClick={(e) => this.handleCroppImage(e)}>Save</Button>
                            <Button color="link" className="nex-btn-primary-outline" onClick={(e) => this.handleCancel(e)}>Cancel</Button>

                        </div>
                    </ModalFooter>
                </Modal>
            </div >
        );
    }

}

export const InstitutionImageUploaderUI = (props) => {
    const { isEditable, isAdmin, institutionName, alert, institution } = props
    const [modal, setModal] = useState(false);
    const [showSpinner, setSpinner] = useState(false);
    const [uProgress, setUProgress] = useState(0);
    const [uploadImageDetails, setImageDetails] = useState({ name: "", type: "" })
    const [uploadImage, setImage] = useState("");
    const [sourceImage, setSourceImage] = useState("");

    const toggle = () => setModal(!modal);

    useEffect(() => {
        onLoadingDefaultImage(institution.guid, institution.avatarId)
    }, [institution.guid, institution.avatarId])

    const handleImageUpload = (event) => {
        let file = event.target.files[0]

        // fileType validation
        if (validateImageType(file)) {
            // fileSize validation
            const maxSize = 1000 * 1000 * 10; //10MB
            const validSize = validateImageSize(file, maxSize)
            if (validSize === 1) {
                let reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                    const imageBase64 = reader.result
                    setImageDetails({ name: file.name, type: file.type })
                    setImage(imageBase64)
                    toggle()
                }
            } else if (validSize === -1) {
                showErrorMessage('Invalid File!')
            } else {
                showErrorMessage('File must be less than 10MB')
            }
        } else {
            showErrorMessage('Invalid File!')
        }
    }

    const onLoadingDefaultImage = async (guidId, imageId) => {
        let imageSet = "";
        if (guidId && imageId) {
            imageSet = `${process.env.REACT_APP_IMAGE_BASE_URL}/${guidId}/${imageId}`;
        }
        setImage(imageSet);
        setSourceImage(imageSet);

    };

    const onImageChange = (file) => {
        if (file) {
            setSpinner(true);
            const options = (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent <= 100) {
                    setUProgress(percent);
                }
            }
            InstitutionServices.new_fileUpload(
                file,
                institution.guid,
                "profile",
                options
            ).then(
                (response) => {
                    if (response.status === 200 || response.status === 201) {
                        onLoadingDefaultImage(institution?.guid, response.data);
                        institution.avatarId = response.data;
                        showSuccessMessage("Profile Image uploaded successfully");
                        if (uProgress <= 100) {
                            setTimeout(() => {
                                setUProgress(0)
                            }, 1000);
                        }
                    } else {
                        showErrorMessage("Something went wrong while uploading your profile picture");
                    }
                    setSpinner(false);
                },
                (err) => {
                    setSpinner(false);
                    onLoadingDefaultImage(institution?.guid, "");
                    showErrorMessage(err.message);
                }
            );
        } else {
            setSpinner(false);
            if (institution?.avatarId) {
                onLoadingDefaultImage(institution?.guid, institution?.avatarId);
            } else {
                onLoadingDefaultImage(institution?.guid, "");
            }
        }
    };

    const handleImageDelete = () => {
        swal("Are you sure you want to remove profile image?", {
            buttons: ["cancel", "yes"],
        })
            .then((yesRemove) => {
                if (yesRemove)
                    InstitutionServices.deleteInstitutionImage(institution.guid, "profile")
                        .then((response) => {
                            const { status, data } = response;
                            if (status === 204) {
                                showSuccessMessage("Profile Image deleted successfully");
                                institution.avatarId = null;
                                onLoadingDefaultImage(null, null);
                            }
                            else {
                                showErrorMessage("Something went wrong, Please try again later");
                            }
                        })
            })
    }


    return (
        <>
            <RenderDialog
                modal={modal}
                toggle={toggle}
                profilePicUrl={uploadImage}
                uploadImageDetails={uploadImageDetails}
                onSave={onImageChange}
                alert={alert}
            />
            <>
                <div className="card-user-img">
                    {uProgress > 0 ? (
                        <div className="position-absolute nexogic-profile-pbar">
                            <Progress value={uProgress} max="100">{uProgress}</Progress>
                        </div>
                    ) : ""}
                    {<img src={institution.avatarId
                        ? sourceImage : createImageFromInitials(132, institutionName, getRandomColor(encrypt(institution.guid).toString()))} className="img-fluid" alt="profile picture" />}
                </div>
                {isEditable &&
                    isAdmin &&
                    <>
                        <Button color="secondary" className="edit-user-profile-img-btn" id="nex-iiu-edit-profile-img">

                            <img src={camera_icon} width="18" />

                            <input
                                disabled={!isEditable && !isAdmin}
                                name="profilePicUrl"
                                className="fileUploadForm"
                                type="file"
                                onChange={(event) => {
                                    handleImageUpload(event)
                                    event.target.value = null
                                }}
                                id="profilePicUrl"
                                accept="image/*, png, jpg, jpeg"
                                title="" />
                        </Button>
                        {institution?.avatarId &&
                            <button type="button" className="remove-card-user-img-btn" id="institution_del_btn">
                                <img src={delIcon} width="18" onClick={() => handleImageDelete()} />
                            </button>
                        }
                    </>
                }
            </>
        </>
    )
}

export default withAlert()(InstitutionImageUploaderUI)