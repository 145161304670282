import React from 'react'
import profile_sample from "assets/images/user-profile-sample.png";

export default function AssignPractitioner(props) {
    return (
        <div className="nexogic-practitioner-search-lists">
            <div className="item selected">
                <div className="graphic">
                    <img src={profile_sample} alt="" className="avatar" />
                </div>
                <div className="info">
                    <h3 className="card-title">{props.assignData.name} {props.assignData.deg.length > 0 ? '('+props.assignData.deg+')' : ''}</h3>
                    <p className="card-text light">{props.assignData.hospital}</p>
                </div>
                <span className="active-check">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="9"
                        fill="none"
                        viewBox="0 0 21 14"
                    >
                        <path
                            stroke="#fff"
                            strokeWidth="2"
                            d="M1 4.781L7.88 12 20 1"
                        ></path>
                    </svg>
                </span>
            </div>
        </div>
    )
}
