import React from 'react'

export default function MedicalInsurance() {
  return (
    <div className='nexogic-form-w'>
      <div className="row">
        <div className="col-lg-6 form-group">
          <label>Choose Plan </label>
          <select name="" id="" className='form-control'>
            <option value="">None selected</option>
          </select>
        </div>
        <div className="col-lg-6 form-group">
          <label>Member ID (Optional) </label>
          <input id='member_id' name='member_id' type="text" className='form-control' placeholder='Member ID (Optional)' />
        </div>
        <div className="col-lg-12 form-group">
          <label>Photo </label>
          <div className='nexogic-file-uploader-box'>
              <p className="card-text light">Please upload photo</p>
              <p className="card-text lg"><a>Select photo</a> or Drag & Drop here</p>
              <input id='member_f_upload' name='member_f_upload' type="file" className='d-none' />
          </div>
        </div>
      </div>
    </div>
  )
}
