
import React from "react"
import DatePicker from "react-datepicker";
import { ErrorMessage } from 'formik';
import { FormGroup, Label } from 'reactstrap'

const FormikDatePicker = (props) => {
    //const [startDate, setStartDate] = useState(null);
    const { id, form, field, placeholder, inputprops, timeFormat } = props
    const { touched, errors, setFieldValue, setFieldTouched } = form
    let formatedDate = ""
    if (field.value) {
        const isValidDate = new Date(field.value) === "Invalid Date"
        if (!isValidDate) {
            formatedDate = new Date(field.value);
            //console.log("formatedDate --"+formatedDate);
        }

    }

    const handleChange = (date) => {
        const checkValidDate = date == null ? "" : date
        setFieldValue(field.name, checkValidDate, true)
        //setStartDate(date)
    }
    const onBlur = () => {
        setFieldTouched(field.name, true, true)
    }
    return (
        <FormGroup className="flex-grow-1">
            <Label htmlFor={field.name}>{props.label}</Label>
            <DatePicker
                placeholderText={placeholder}
                yearDropdownItemNumber={15}
                id={id}
                invalid={Boolean(touched[field.name] && errors[field.name])}
                selected={formatedDate}
                timeFormat={timeFormat}
                onChange={handleChange}
                timeIntervals={60}
                name={field.name}
                dropdownMode="select"
                onKeyDown={(e) => {
                    e.preventDefault();
                 }}
                className={`form-control d-block ${touched[field.name] && errors[field.name] && 'is-invalid'}`}
                onClickOutside={onBlur}
                {...inputprops}
            />
            <ErrorMessage name={field.name} render={msg => <span className="text-danger f-12 d-inline-block  mt-1 line-height-error">{msg}</span>} />
        </FormGroup>
    )
}

FormikDatePicker.defualtProps = {
    timeFormat: "HH:mm aa"
}
export default FormikDatePicker;