import React from "react"
const Loader = () =>

    (
        <div className="py-5 h-100 d-flex align-items-center justify-content-center">
            <div className="spinner-border z-index-1" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )

export default Loader