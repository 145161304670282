import React from 'react'
// import hospital_logo from 'src\assets\images\hospital_logo.png'
import { useState } from 'react';
import { ReferralService } from 'utils/ReferralService';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';
import { Button } from 'reactstrap';
import { createImageFromInitials, getRandomColor } from 'utils/Utils';
import HtmlStringToPdf from 'pages/PatientProfile/SecondOpinion/components/EmailPreview/HtmlStringToPdf';
export default function ReceivedReferralPreview({ id }) {
    // let { id } = useParams();
    const [myReferralDetails, setMyReferralDetails] = useState({});
    const htmlStringToPdfRef = React.createRef();

    // const handlePreviewPDFOutside = async () => {
    //     // Access the ref to HtmlStringToPdf component and call the function
    //     if (htmlStringToPdfRef.current) {
    //         await htmlStringToPdfRef.current.handlePreviewPDF();
    //     }
    // };

    const history = useHistory();
    const handleBack = () => {
        history.goBack()
    }

    const getReferral = (id) => {
        ReferralService.getReferralById(id, "RECEIVED")
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    console.log("data", data);
                    setMyReferralDetails(data);
                    // setIsLoading(false);
                } else if (status === 400 || status === 404) {
                    handleBack();
                }
                else {
                    console.log("Error");
                }
            })
    }

    useEffect(() => {
        getReferral(id);
    }, [id])

    return (
        <>
            <div className='text-center' style={{width: '100%'}}>
                <HtmlStringToPdf target="#nex_email_preview_pdf" ref={htmlStringToPdfRef} elmClass="btn nex-pdf-download-btn">
                    <>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.0438 6.105L14.5337 1.5675L14.0525 1.375H3.4375L2.75 2.0625V9.625H4.125V2.75H12.375V7.5625L13.0625 8.25H17.875V9.625H19.25V6.6L19.0438 6.105ZM13.75 6.875V2.75L17.875 6.875H13.75ZM3.4375 11L2.75 11.6875V19.9375L3.4375 20.625H18.5625L19.25 19.9375V11.6875L18.5625 11H3.4375ZM17.875 17.875V19.25H4.125V12.375H17.875V17.875ZM6.875 16.5H6.435V17.875H5.5V13.75H6.9575C7.98875 13.75 8.51125 14.245 8.51125 15.125C8.51391 15.312 8.47595 15.4974 8.39998 15.6683C8.32402 15.8392 8.21186 15.9916 8.07125 16.115C7.7305 16.3806 7.30671 16.517 6.875 16.5ZM6.7925 14.5063H6.435V15.785H6.7925C7.2875 15.785 7.535 15.565 7.535 15.1388C7.535 14.7125 7.2875 14.5063 6.7925 14.5063ZM12.375 17.2975C12.5781 17.0975 12.7369 16.8571 12.8411 16.5918C12.9453 16.3265 12.9926 16.0422 12.98 15.7575C12.98 14.3825 12.2513 13.75 10.78 13.75H9.3225V17.875H10.78C11.0708 17.8891 11.3616 17.8453 11.6354 17.7462C11.9092 17.647 12.1606 17.4945 12.375 17.2975ZM10.2437 17.1187V14.5063H10.6975C10.8729 14.4951 11.0487 14.5198 11.2142 14.5789C11.3797 14.6381 11.5314 14.7303 11.66 14.85C11.7765 14.9724 11.8671 15.1171 11.9262 15.2754C11.9852 15.4338 12.0116 15.6024 12.0037 15.7713C12.0243 16.1337 11.9008 16.4896 11.66 16.7612C11.5341 16.8817 11.3852 16.9755 11.2221 17.0369C11.0591 17.0983 10.8853 17.1262 10.7112 17.1187H10.2437ZM16.3625 16.28H15.0288V17.875H14.0938V13.75H16.4862V14.5063H15.0288V15.5237H16.3625V16.28Z" fill="#009FD7"/>
</svg> Download PDF

                    </>
                </HtmlStringToPdf>
            </div>
            <div style={{ height: "0px", overflow: "scroll" }}>
                <div className="pdf-warap" id="nex_email_preview_pdf" style={{ transformOrigin: 'top left', width: '595px', height:'841px', margin: '0 auto'}}>
                    <div class="pdf-page">
                    <div className="pdf-header">
                        <div className="pdf-row">
                            <div className="pdf-col-half">
                                <h1 className="logo-text">
                                    <div className="job-info-image" >
                                        {myReferralDetails?.referredByInstitution &&
                                            //     myReferralDetails?.referredByInstitution?.avatarId ? (
                                            //     <img
                                            //         src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${myReferralDetails?.referredByInstitution?.id}/${myReferralDetails?.referredByInstitution?.avatarId}`}
                                            //         alt="#"
                                            //         className="img-fluid"
                                            //         style={{ height: "50px" }}
                                            //     />
                                            // ) : (
                                            <img
                                                src={createImageFromInitials(
                                                    280,
                                                    myReferralDetails?.referredByInstitution?.name,
                                                    getRandomColor(null)
                                                )}
                                                alt="profile pic"
                                                className="img-fluid"
                                                style={{ height: "50px", width: "100px" }}
                                            />
                                            // )
                                        }
                                    </div>
                                </h1>
                            </div>
                            <div className="pdf-col-half address">
                                <p>{myReferralDetails?.referredByInstitution?.name}</p>
                                <p>{myReferralDetails?.referredByInstitution?.address?.location}
                                    {", "}{myReferralDetails?.referredByInstitution?.address?.city}
                                    {", "}{myReferralDetails?.referredByInstitution?.address?.state}
                                </p>

                            </div>
                        </div>

                    </div>


                    <div className="pdf-sec">
                        <h2 className="pdf-title">Essential information</h2>
                        <div className="pdf-row">
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Doctor name to whom refer</h3>
                                <p className="pdf-val">{myReferralDetails?.referredToPhysician?.name}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Hospital to which refer</h3>
                                <p className="pdf-val">{myReferralDetails?.referredToInstitution?.name}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Patient name </h3>
                                <p className="pdf-val">{myReferralDetails?.patient?.firstName}{" "}{myReferralDetails?.patient?.middleName}{" "}{myReferralDetails?.patient?.lastName}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Patient type</h3>
                                <p className="pdf-val">{myReferralDetails?.patientType}</p>
                            </div>
                        </div>
                    </div>

                    {/** row 1 */}
                    <div className="pdf-sec">
                        <h2 className="pdf-title">Patient information</h2>
                        <div className="pdf-row">
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Name </h3>
                                <p className="pdf-val">{myReferralDetails?.patient?.firstName}{" "}{myReferralDetails?.patient?.middleName}{" "}{myReferralDetails?.patient?.lastName}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Gender </h3>
                                <p className="pdf-val">{myReferralDetails?.patient?.gender}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Age </h3>
                                <p className="pdf-val">{myReferralDetails?.patient?.dateOfBirth}</p>
                            </div>
                        </div>
                    </div>

                    {/** row 2 */}
                    <div className="pdf-sec">
                        <h2 className="pdf-title">Referring physician information</h2>
                        <div className="pdf-row">
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Physician name </h3>
                                <p className="pdf-val">{myReferralDetails?.referredByPhysician?.name}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Date(requested on) </h3>
                                <p className="pdf-val">{"2023-08-01"}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Practice name</h3>
                                <p className="pdf-val">{myReferralDetails?.referredByPhysician?.primarySpeciality}</p>
                            </div>

                            <div className="pdf-col-half">
                                <h3 className="pdf-label">City</h3>
                                <p className="pdf-val">Philadelphia[S]</p>
                            </div>

                            <div className="pdf-col-half">
                                <h3 className="pdf-label">State (Required for Domestic Patient)</h3>
                                <p className="pdf-val">Pennsylvania[S]</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">ZIP code (Required for Domestic Patient)</h3>
                                <p className="pdf-val">19093[S]</p>
                            </div>
                        </div>
                    </div>
                    

                    
                    </div>
                    <div class="pdf-page">

                    {/** row 3 */}
                    <div className="pdf-sec ">
                        <h2 className="pdf-title">Referring Institution information</h2>
                        <div className="pdf-row">
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Institution name </h3>
                                <p className="pdf-val">{myReferralDetails?.referredByInstitution?.name}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Location</h3>
                                <p className="pdf-val">{myReferralDetails?.referredByInstitution?.address?.location}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">City</h3>
                                <p className="pdf-val">{myReferralDetails?.referredByInstitution?.address?.city}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">State</h3>
                                <p className="pdf-val">{myReferralDetails?.referredByInstitution?.address?.state}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">ZIP code</h3>
                                <p className="pdf-val"><p className="pdf-val">{"243432[S]"}</p></p>
                            </div>
                        </div>
                    </div>
                    

                    {/** row 3 */}
                    <div className="pdf-sec">
                        <h2 className="pdf-title">Receiving physician information</h2>
                        <div className="pdf-row">
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Physician name </h3>
                                <p className="pdf-val">{myReferralDetails?.referredToPhysician?.name}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Date(requested on) </h3>
                                <p className="pdf-val">{"2023-08-01"}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Practice name</h3>
                                <p className="pdf-val">{myReferralDetails?.referredToPhysician?.primarySpeciality}</p>
                            </div>

                            <div className="pdf-col-half">
                                <h3 className="pdf-label">City</h3>
                                <p className="pdf-val">Philadelphia[S]</p>
                            </div>

                            <div className="pdf-col-half">
                                <h3 className="pdf-label">State (Required for Domestic Patient)</h3>
                                <p className="pdf-val">Pennsylvania[S]</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">ZIP code (Required for Domestic Patient)</h3>
                                <p className="pdf-val">19093[S]</p>
                            </div>
                        </div>
                    </div>

                    <div className="pdf-sec">
                        <h2 className="pdf-title">Receiving Institution information</h2>
                        <div className="pdf-row">
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Institution name </h3>
                                <p className="pdf-val">{myReferralDetails?.referredToInstitution?.name}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">Location</h3>
                                <p className="pdf-val">{myReferralDetails?.referredToInstitution?.address?.location}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">City</h3>
                                <p className="pdf-val">{myReferralDetails?.referredToInstitution?.address?.city}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">State</h3>
                                <p className="pdf-val">{myReferralDetails?.referredToInstitution?.address?.state}</p>
                            </div>
                            <div className="pdf-col-half">
                                <h3 className="pdf-label">ZIP code</h3>
                                <p className="pdf-val"><p className="pdf-val">{"243432[S]"}</p></p>
                            </div>
                        </div>
                    </div>
                    </div>

                </div>
            </div>
        </>
    )
}
