import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommonLoader from "common/CommonLoader";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { withAlert } from "react-alert";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { ConnectionServices } from "utils/ConnectionServices";
import { FAILTOSAVERECOED } from "utils/Constants";
import { createImageFromInitials, getRandomColor } from "utils/Utils";
import NoConnection from "../../NoConnection";
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";
import { faAddressCard, faIdCard, faListAlt, faTable } from "@fortawesome/free-solid-svg-icons";
import GroupInvitationReceivedTabularView from "components/tabularView/GroupInvitationReceivedTabularView";
import ListTableSwitch from "common/ListTableSwitch/ListTableSwitch";
import PageTitle from "common/PageTitle/PageTitle";

const GroupFollow = forwardRef((props, ref) => {
  const { alert } = props;
  const statusArray = ["Accept", "Reject"];
  const [list_of_data, setList_of_data] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [viewMode, setViewMode] = useState("card");

  const getData = async (pageNo, feedlist) => {
    const request = ConnectionServices.getGroupMembersipRequests("RECEIVED", pageNo);
    request
      .then(async (response) => {
        let { data } = response;
        if (data?.currentPage < (data?.totalPages - 1))
          setHasMore(true);
        else
          setHasMore(false);
        setIsLoadingMore(false);
        if (pageNo === 0) {
          const allData = { ...data };
          setList_of_data(allData);
          setIsLoading(false);
        } else {
          const resolved = await Promise.all(
            data.contents.map(async (item) => {
              const each = { ...item };
              return each;
            })
          );
          feedlist = feedlist.concat(resolved);
          setList_of_data({ ...data, contents: feedlist });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoadingMore(false);
        console.log("Error while getting groups list: ", error);
      });
  };

  const fetchData = () => {
    setIsLoadingMore(true);
    getData(list_of_data.currentPage + 1, list_of_data.contents);
  };

  useImperativeHandle(ref, () => ({
    getJobListData() {
      getData(0, []);
    },
  }));

  useEffect(() => {
    getData(0, []);
  }, []);

  const handleResponse = (response, groupId, memberId) => {
    // console.log("test1");
    let payload = {
      action: response === "Accept" ? "ACTIVE" : "REJECTED",
    };
    ConnectionServices.actOnGroupMembershipRequest(
      payload,
      groupId,
      memberId
    ).then((response) => {
      if (response.status === 200) {
        showSuccessMessage("Request updated Successfully...");
        getData(0, []);
      } else {
        showErrorMessage(FAILTOSAVERECOED);
      }
    });
    //console.log("test2");
  };

  if (isLoading) {
    return (
      <div className="loading-spiner text-center  mt-2">
        <CommonLoader style={{ width: "2rem", height: "2rem" }} />
      </div>
    );
  }

  const renderProfiles = () => {
    if (viewMode === 'card') {
      return (
        <>
          {list_of_data &&
            list_of_data.contents.map((e, i) => (
              <div className="card-body">
                <div className="row no-gutters">
                  {e.memberDetails && (
                    <div className="col-auto col-img">
                      {e.memberDetails.avatarId
                        ?
                        <img
                          className="card-img rounded card-img-md"
                          src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${e.memberId}/${e.memberDetails.avatarId}`}
                          alt="#"
                        />
                        :
                        <img
                          className="card-img rounded card-img-md"
                          src={createImageFromInitials(64, e.memberDetails.name, getRandomColor(null))}
                          alt="profilePic"
                          width={64}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = createImageFromInitials(132, e.memberDetails.name, getRandomColor(null));
                          }}
                        />
                      }
                    </div>
                  )}
                  {!e.memberDetails && e.groupName && (
                    <div className="col-auto col-img">
                      <img
                        className="card-img rounded card-img-md"
                        src={createImageFromInitials(64, e.groupName, getRandomColor(null))}
                        alt="profilePic"
                        width={64}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = createImageFromInitials(132, e.groupName, getRandomColor(null));
                        }}
                      />
                    </div>
                  )}
                  <div className="col col-content-right-btn">
                    <div className="nexogic-content-details">
                      {e.memberDetails && (
                        <>
                          <h3 className="card-title"><a
                            className="practice-name"
                            href={`../profile/${e.memberDetails.profileId}`}
                          >
                            {e.memberDetails.name}
                          </a>
                          </h3>
                          <div className="card-text">
                            {e.memberDetails.primarySpeciality}
                            {", "}
                            {e.memberDetails.superSpeciality}
                          </div>
                          <div className="card-text">
                            {" "}
                            {e.memberDetails.location}
                            {", "}
                            {e.memberDetails.city}
                            {", "}
                            {e.memberDetails.state}
                          </div>
                        </>
                      )}

                      <h3 className="card-title"><a className="practice-name" href={`groups/${e.groupId}`}>
                        {e.groupName}
                      </a></h3>

                      <div className="card-text"> {e.groupType}</div>

                      <div className="card-text"> <small className="text-muted">{e.role} </small></div>
                    </div>
                    <div className="nexogic-action-part align-items-center">
                      <UncontrolledDropdown >
                        <DropdownToggle
                          size="lg"
                          color="info"
                          outline
                          className="nex-btn-primary-outline"
                        >
                          {e.status ? e.status : "--Status--"}
                          <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                        </DropdownToggle>
                        <DropdownMenu className="border-0 px-0">
                          {statusArray.map((item) => (
                            <DropdownItem
                              tag={"button"}
                              key={item.name}
                              onClick={() =>
                                handleResponse(
                                  item,
                                  e?.groupId,
                                  e?.memberId
                                )
                              }
                              className={`nex-btn-primary-outline mb-1 ${item === "Accept"
                                ? "text-success"
                                : null || item === "Reject"
                                  ? "text-danger"
                                  : null
                                } `}
                            >
                              {item}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </>
      );
    } else { // For table view
      return (
        <GroupInvitationReceivedTabularView
          handleResponse={handleResponse}
          theader={['Group Name', 'Group Type', 'Member Name', 'Member Speciality', 'Action']} tdata={list_of_data.contents} viewmode={viewMode} />
      );
    }
  };

  return (
    <>
      {/* <div className="view-toggle nexogic-view-toggle"> */}
      <PageTitle title="Group I follow">
        <ListTableSwitch viewMode={viewMode} cardViewAction={() => setViewMode("card")}  tableViewAction={()=>setViewMode("table")} />
      </PageTitle>
      {/** <div className="btns">
          <FontAwesomeIcon title="Card View" color={viewMode === "card" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faAddressCard} onClick={() => setViewMode("card")} /> | <FontAwesomeIcon title="Table View" color={viewMode === "table" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faListAlt} onClick={() => setViewMode("table")} />
        
      </div>*/}
      {/* </div> */}
      {
        list_of_data && list_of_data.contents.length > 0 ?
          <div className="card-group nexogic-card-group-list card nexogic-connection-card nexogic-custom-scroll" id="invitation-card-list">
            {renderProfiles()}
          </div>
          :
          <div className="loading-spiner text-center mt-5 nex-app-need-pad">
            <NoConnection content={'No Invitation'} />
          </div>
      }
      {hasMore &&
        <div className="text-center mt-3">
          {isLoadingMore
            ?
            <CommonLoader style={{ width: "2rem", height: "2rem" }} />
            :
            <LoadMoreButton loadMore={fetchData} />
          }
        </div>
      }
    </>
  )
});

GroupFollow.defaultProps = {
  toggleCGModal: () => { },
  isCGOpen: false,
};

export default withAlert()(GroupFollow);
