import React, { useState, useEffect } from "react"
import ConnectionCard from "./ConnectionCard";
import NoConnection from "./NoConnection";
import { useSelector } from 'react-redux';
import { ConnectionService } from 'utils/ConnectionService'
import CommonLoader from "common/CommonLoader";

const Pending = (props) => {

    const currentUser = useSelector(state => state.auth.currentUser);
    const guid = currentUser.guid;
    const [pendingConnections, setPendingConnections] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    useEffect(() => {
        getPendingConnections(0, []);
    }, []);

    const getPendingConnections = (pageNo, connections) => {
        ConnectionService.getConnectionsInfo(guid, "SENT", pageNo)
            .then(response => {
                let { data } = response;
                if (data?.currentPage < (data?.totalPages - 1))
                    setHasMore(true);
                else
                    setHasMore(false);
                setIsLoadingMore(false);
                if (response.status === 200 || response.status === 201) {
                    if (pageNo == 0) {
                        setPendingConnections(data);
                        setIsLoading(false);
                    } else {
                        connections = connections.concat(data.contents)
                        setPendingConnections({ ...data, "contents": connections });
                        setIsLoading(false);
                    }
                }
            })
            .catch((error) => {
                setIsLoadingMore(false);
                console.log("Error while getting connection: ", error);
            })
    }


    if (isLoading) {
        return (
            <div className="loading-spiner text-center mt-2">
                <CommonLoader />
            </div>
        );
    }

    return (
        <>
            {
                pendingConnections.totalItems ?
                    <ConnectionCard
                        connectionsDetails={pendingConnections}
                        getConnections={getPendingConnections}
                        setConnectionsDetails={setPendingConnections}
                        isLoadingMore={isLoadingMore}
                        setIsLoadingMore={setIsLoadingMore}
                        hasMore={hasMore}
                    />
                    :
                    <NoConnection content={'No Pending Connections'} />
            }
        </>
    )
}

export default Pending