import React from 'react'
import chat_logo from '../../../../assets/images/chat-logo.png'

const ChatFlashScreen = () => {

    return (
        <>
            <div className="chat-spash-wrap">
                <div className='splash-content'>
                    <div className='img-wrap'>
                        <img src={chat_logo} alt="#" className="card-img" />
                    </div>
                    <h3 className='card-title'>Welcome to NEXOGIC chat system</h3>
                    <p className='card-text '><span className='blinkprt'>Start</span> real time conversations with your connections</p>
                </div>
            </div>
        </>
    )
}

export default ChatFlashScreen