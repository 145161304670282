import React, { useState, useEffect } from "react";
import {
  getMessage,
  get_meta_data,
} from "../../../../utils/message_connection";
import Star from "../../images/Home page/calender/Star1.png";
import Video_Img from "../../images/Home page/Vector.png";
import BitmapCopy4 from "../../images/Home page/BitmapCopy4.png";
import BitmapCopy3 from "../../images/Home page/BitmapCopy3.png";
import BitmapCopy2 from "../../images/Home page/cards/BitmapCopy2.png";
import ArrowOpen from "../../images/Home page/cards/Vector.png";
import { useSelector } from "react-redux";
import AppointCard from "./AppointCard/AppointCard";
import { Link } from "react-router-dom";
import DefaultPic from "assets/images/defaultPic.png";
import { get_appointment } from "utils/appointment";
import abc from "../../../../assets/images/approval_icon.png";
import ReactDatePicker from "react-datepicker";
import { titleCase } from "utils/titleCase";
import { getTime } from "utils/Constants";
import "./UserDetailComponent.css";
import referals_icon from "assets/images/svgs/referals-icon.svg";
import { Card, CardBody } from "reactstrap";
import { isPractitioner } from "utils/UserHelper";
import PeopleKnowWidget from "common/PeopleKnowWidget";
import JobWidget from "common/JobWidget";
import ReferWidget from "common/ReferWidget/ReferWidget";

const UserDetailComponent = () => {
  //state variables
  const [appointment_data, setappointment_data] = useState([]);

  const [Chat, setChat] = useState([]);

  const [full_date_state, setfull_date_state] = useState(
    new Date().toISOString().split("T")[0]
  );

  //current user
  const user = useSelector((state) => state.auth.currentUser);
  const { primarySpeciality } = user;

  let practitionerId = useSelector(
    (state) => state.auth.currentUser.practitionerId
  );

  let date_to_render = new Date();

  let get_date = date_to_render.getDate();

  const week_day = [
    {
      day: date_to_render.toString().split(" ")[0],
      date: get_date,
      full_date: new Date().toISOString().split("T")[0],
    },
    {
      day: new Date(date_to_render.getTime() + 86400000)
        .toString()
        .split(" ")[0],
      date: get_date + 1,
      full_date: new Date().toISOString().split("T")[0],
    },
    {
      day: new Date(date_to_render.getTime() + 86400000 + 86400000)
        .toString()
        .split(" ")[0],
      date: get_date + 2,
      full_date: new Date().toISOString().split("T")[0],
    },
    {
      day: new Date(date_to_render.getTime() + 86400000 + 86400000 + 86400000)
        .toString()
        .split(" ")[0],
      date: get_date + 3,
      full_date: new Date().toISOString().split("T")[0],
    },
    {
      day: new Date(
        date_to_render.getTime() + 86400000 + 86400000 + 86400000 + 86400000
      )
        .toString()
        .split(" ")[0],
      date: get_date + 4,
      full_date: new Date().toISOString().split("T")[0],
    },
    {
      day: new Date(
        date_to_render.getTime() +
        86400000 +
        86400000 +
        86400000 +
        86400000 +
        86400000
      )
        .toString()
        .split(" ")[0],
      date: get_date + 5,
      full_date: new Date().toISOString().split("T")[0],
    },
    {
      day: new Date(
        date_to_render.getTime() +
        86400000 +
        86400000 +
        86400000 +
        86400000 +
        86400000 +
        86400000
      )
        .toString()
        .split(" ")[0],
      date: get_date + 6,
      full_date: new Date().toISOString().split("T")[0],
    },
  ];

  // fetch appointments using id 260 and hard coded date
  // useEffect(() => {
  //   get_appointment(full_date_state, full_date_state)
  //     .then((res) => {
  //       if (res.data.status === "SUCCESS") {
  //         setappointment_data(res.data.data ? res.data.data.appointment : []);
  //       } else {
  //         setappointment_data([]);
  //       }
  //     })
  //     .catch((err) => console.log(err.message));
  // }, []);

  //meta data to show the latest user
  let data = [];
  // useEffect(() => {
  //   get_meta_data(practitionerId, 0, 10).then((res) => {
  //     let sliced_data = res?.data?.data?.data.slice(0, 3);
  //     let obj_message;
  //     sliced_data.map((item) =>
  //       getMessage(item.channel.channelName, 0, 1).then((res) => {
  //         let data_message = res.data.data[item.channel.channelName][0];
  //         obj_message = {
  //           ...item,
  //           messages: data_message,
  //         };
  //         setChat((chats) => [...chats, obj_message]);
  //       })
  //     );
  //   });
  // }, []);

  // const get_api_date = (full_date) => {
  //   setfull_date_state(full_date);
  //   get_appointment(full_date_state, full_date_state).then((item) =>
  //     setappointment_data(item.data.data.appointment)
  //   );
  // };

  const date_changed = (val) => {
    let new_date = 1;
    return new_date;
    new_date += 1;
  };
  return (
    <div className="nex-sidebar v2">
      <div className="nex-right-content-container">
          <ReferWidget />

        {isPractitioner() && primarySpeciality &&
          <>
            <PeopleKnowWidget speciality={primarySpeciality} />
            <JobWidget widgetTitle="Recommended Jobs" primarySpeciality={primarySpeciality} />
          </>
        }

        {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
          <div className="right-content-container ">
            <section>
              <div className="d-flex align align-items-center mb-5">
                <div className="user_detail_img">
                  <div className="user_img_user_detail_style">
                    <img
                      style={{
                        width: "65px",
                        height: "65px",
                        borderRadius: "100%",
                      }}
                      src={user.profilePicUrl ? user.profilePicUrl : DefaultPic}
                      alt=""
                    />
                  </div>
                  <div className="user_detail_rating">
                    <div>
                      <img src={Star} alt="Star.png" style={{ padding: "10px" }} />
                    </div>
                    <div className="mini_calendar_user_detail">5</div>
                  </div>
                </div>
                <p className="home_greet_user_detail">
                  Hello, {user.firstName}
                </p>
              </div>
            </section>
            <section>
              <div className="user_detail_apointments_heading">
                <div className="mini_calendar_user_detail">Appointments</div>
              </div>
              <div className="user_detail_calender_view">
                {week_day.map((item, index) => {
                  return (
                    <div key={index}
                    // onClick={() => get_api_date(item.full_date)}
                    >
                      <div className="mini_calendar_user_detail_day_name user_detail_red_dot_alert ">
                        {item.day}
                      </div>
                      <div className="mini_calendar_user_detail">
                        {item.date > 31 ? date_changed(item.date) : item.date}
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
            <section>

              <div
                className="card user_detail_card"
                style={{
                  borderLeft: "5px solid #148FB2"
                  // : "5px solid #A3DD72",
                }}
              >
                <div className="card-body p-3">
                  <div className="user_detail_card_container">
                    <div className="user_detail_card_left">
                      <div
                        className="user_vid_process_container mb-2"
                        style={{
                          background: "#148FB2",
                        }}
                      >
                        <img src={Video_Img} alt="Video Img" />
                        <div className="appoint_card_process">
                          Consultation
                        </div>
                      </div>
                      <div className="user_detail_card_date">
                        Jan 23
                      </div>
                      <div className="user_detail_card_time">
                        11:00-11:30
                      </div>
                    </div>
                    <div className="user_detail_card_right">
                      <div>
                        <img
                          src={DefaultPic}
                          className="mesaage_img_user_detail" alt="#"
                        />
                      </div>
                      <div className="appointcard_name_style">
                        Jessica Ford
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card user_detail_card"
                style={{
                  borderLeft: "5px solid #6DD400"
                  // : "5px solid #7ad8f4",
                }}
              >
                <div className="card-body p-3">
                  <div className="user_detail_card_container">
                    <div className="user_detail_card_left">
                      <div
                        className="user_vid_process_container mb-2"
                        style={{
                          background: "#6DD400",
                        }}
                      >
                        {/* <img src={Video_Img} alt="Video Img" /> */}
                        <div className="appoint_card_process">
                          Procedure
                        </div>
                      </div>
                      <div className="user_detail_card_date">
                        Jan 23
                      </div>
                      <div className="user_detail_card_time">
                        11:00-11:30
                      </div>
                    </div>
                    <div className="user_detail_card_right">
                      <div>
                        <img
                          src={DefaultPic}
                          className="mesaage_img_user_detail" alt="#"
                        />
                      </div>
                      <div className="appointcard_name_style">
                        Jessica Ford
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {appointment_data.length > 0
                ? appointment_data
                  .slice(1)
                  .map((item) => (
                    <AppointCard
                      key={item.id}
                      img_vid={Video_Img}
                      process="Consultation"
                      date="Jan 23"
                      time="11:00-11:30"
                      user_img={BitmapCopy4}
                      user_name="Jessica Ford"
                      appointment_data={item}
                    />
                  ))
                : "No Appointments"}

              <Link to="/appointments">
                <button
                  id="ud_btn_all_appointments"
                  type="button"
                  className="nex-btn-primary btn-block mt-2"
                >
                  View all appointments
                </button>
              </Link>
              <section>
                <div className="user_detail_message_heading">Messages</div>
                <div className="user_detail_message_container">
                  <div>
                    {Chat.length < 4 &&
                      Chat.map((item, index) => (
                        <div
                          className="card message_card_border_bottom"
                          key={item.contacts[0].practitionerId}
                        >
                          <div className="card-body d-flex  align-items-center">
                            <img
                              src={
                                item.contacts[0].profilePicUrl != null
                                  ? item.contacts[0].profilePicUrl
                                  : DefaultPic
                              }
                              alt=""
                              className="mesaage_img_user_detail"
                            />
                            <div className="user_detail_message_card_text ml-4">
                              <div className="user_detail_heading_css">
                                {item.contacts[0].firstName != null
                                  ? titleCase(item.contacts[0].firstName) + " "
                                  : ""}
                                {item.contacts[0].middleName != null
                                  ? titleCase(item.contacts[0].middleName) + " "
                                  : ""}
                                {item.contacts[0].lastName != null
                                  ? titleCase(item.contacts[0].lastName) + " "
                                  : ""}
                              </div>
                              <div className="user_detail_body_css">
                                {item.messages?.message &&
                                  item.messages?.message?.content
                                  ? item.messages?.message?.content
                                  : item.messages?.message?.file?.name}
                              </div>
                            </div>
                            <div>
                              <div className="user_timestamp_message">
                                {item.messages ?
                                  getTime(item.messages.timetoken)
                                  :
                                  null
                                }
                              </div>
                              {/* <div className="collapse_message_circle">
                            <div>
                              <img src={ArrowOpen} alt="Arrow right" />
                            </div>
                          </div> */}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <Link to="/messages" style={{ textDecoration: "none" }}>
                  <button
                    id="ud_btn_all_message"
                    type="button"
                    className="nex-btn-primary btn-block mb-3"
                    style={{
                      fontSize: "16px",
                      marginTop: "10px",
                      minHeight: "40px",
                    }}
                  >
                    View all messages
                  </button>
                </Link>
              </section>
            </section>
          </div>
        }

      </div>
    </div>
  );
};
export default UserDetailComponent;
