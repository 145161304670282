import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { getJobData, useJobFilterDispatch } from "context/JobFilterContext";
import { Formik } from "formik";
import { memo, useState } from "react";
import { withAlert } from "react-alert";
import { TalentService } from "utils/TalentService";
import { getAddressName, getLocationName } from "utils/Utils";
import * as Yup from "yup";
import CreateJob from "./Components/CreateJob/index_Trash";

const initValues = {
  institutionDetail: null,
  opportunity: "",
  position: "",
  title: "",
  qualifications: [],
  description: "",
  primarySpeciality: [],
  skills: [],
  shift: "",
  role: "",
  city: [],
  location: [],
  linkToApply: "",
  minExperience: "",
  maxExperience: "",
  minSalary: "",
  maxSalary: "",
  applyType: "",
  id: "",
};
const validationSchema = Yup.object().shape({
  institutionDetail: Yup.lazy((value) => {
    if (value && (value.guid === "" || value.guid === undefined)) {
      return Yup.object().test(
        "",
        "This field is required. You must select from options or add new",
        () => false
      );
    } else {
      return Yup.mixed().test(
        "",
        "This field is required. You must select from options or add new",
        (value) => value !== null
      );
    }
  }),
  opportunity: Yup.string().required("This field is required"),
  position: Yup.string().required("This field is required"),
  qualifications: Yup.array()
    .min(1, "This field is required")
    .required("This field is required"),
  title: Yup.string().required("This field is required"),
  description: Yup.string().required("This field is required"),
  primarySpeciality: Yup.array().min(1, "This field is required"),
  skills: Yup.array().min(1, "This field is required"),
  shift: Yup.string().required("This field is required"),
  role: Yup.string().required("This field is required"),
  city: Yup.array()
    .min(1, "This field is required")
    .required("This field is required")
    .nullable(),
  location: Yup.array()
    .min(1, "This field is required")
    .required("This field is required")
    .nullable(),
  linkToApply: Yup.string().url("Please enter a valid URL").nullable(),
  minExperience: Yup.string(),
  maxExperience: Yup.string(),
  minSalary: Yup.string(),
  maxSalary: Yup.string(),
  id: Yup.string(),
});

const CreateJobContainer = (mainProps) => {
  const [isLoading, setLoading] = useState(false);
  const { toggle, alert, initialValues } = useState(initValues);
  const dispatch = useJobFilterDispatch();

  const addItem = async (value) => {
    setLoading(true);
    let payload = { ...value };
    try {
      const formData = {
        ...payload,
        ...(payload.institutionDetail.guid && {
          institutionId: payload.institutionDetail.guid,
        }),
        ...(payload.primarySpeciality.length > 0 && {
          primarySpeciality: payload.primarySpeciality[0],
        }),
        city: getAddressName(payload?.city).split(",")[0],
        location: getLocationName(payload?.location),
        ...(payload.minSalary && {
          minSalary: payload.minSalary,
        }),
        ...(payload.maxSalary && {
          maxSalary: payload.maxSalary,
        }),
        qualifications:
          payload?.qualifications.length > 0
            ? payload?.qualifications.join(",")
            : "",
      };
      const request = TalentService.registerJob(formData);
      request
        .then(async (response) => {
          const { status } = response;
          if (status === 201) {
            getJobData(dispatch, true, 0, [], {
              cp: 0,
              opportunity: "",
              primarySpeciality: [],
              city: [],
            });
            toggle();
          } else {
            setLoading(false);
            let errmsg =
              response.data && !!response.data.message
                ? response.data.message
                : "Failed to save. Please try again";
            showErrorMessage(errmsg);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const updateItem = async (value) => {
    setLoading(true);
    let payload = { ...value };
    try {
      const formData = {
        ...payload,
        ...(payload.institutionDetail.guid && {
          institutionId: payload.institutionDetail.guid,
        }),
        ...(payload.primarySpeciality.length > 0 && {
          primarySpeciality: payload.primarySpeciality[0],
        }),
        city: getAddressName(payload?.city),
        location: getLocationName(payload?.location),
        ...(payload.minSalary && {
          minSalary: payload.minSalary,
        }),
        ...(payload.maxSalary && {
          maxSalary: payload.maxSalary,
        }),
        qualifications:
          payload?.qualifications.length > 0
            ? payload?.qualifications.join(",")
            : "",
      };
      const request = TalentService.updateJob(formData, formData?.id);
      request
        .then(async (response) => {
          const { status } = response;
          if (status === 200) {
            getJobData(dispatch, true, 0, [], {
              cp: 0,
              opportunity: "",
              primarySpeciality: [],
              city: [],
            });
            toggle();
            showSuccessMessage("Job Updated Successfully...");
          } else {
            setLoading(false);
            let errmsg =
              response.data && !!response.data.message
                ? response.data.message
                : "Failed to save. Please try again";
            showErrorMessage(errmsg);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
        if (values.id) {
          updateItem(values);
        } else {
          addItem(values);
        }
      }}
      children={(props) => (
        <>
          <CreateJob {...props} {...mainProps} isLoading={isLoading} />
        </>
      )}
    />
  );
};

export default withAlert()(memo(CreateJobContainer));
