import React from "react";
import { Card, Row, Col, Button } from "reactstrap";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { SkillsAndExpertise } from "./components/SkillsAndExpertise";
import { ServicesOffered } from "./components/ServiceOffered";
import { About } from "./components/About";
import ProfileCompleteness from "./components/ProfileCompleteness";
import HospitalAffiliations from "./components/HospitalAffiliations";
import AwardsRecognitions from "./components/AwardsRecognitions";
import Experience from "./components/Experience";
import Education from "./components/Education";
import Certifications from "./components/Certifications";
import Publications from "./components/Publications";
import ProfessionalMemberships from "./components/ProfessionalMemberships";
import ClinicalTrials from "./components/ClinicalTrials";
import Committees from "./components/Committees";
import SocialHandler from "./components/SocialHandler";
import Registration from "./components/Registration";
import { ProfileStatusConstants } from "utils/Constants";
import Documents from "./components/Documents";
import JobWidget from "common/JobWidget";
import { isInstitutionAdmin, isPractitioner } from "utils/UserHelper";
import scroller_arrow from "assets/svgIcon/scroller-arrow-right.svg";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import PractitionerResumes from "./components/PractitionerResumes";
import PeopleKnowWidget from "common/PeopleKnowWidget";
import DoctorsProfile from "../DoctorsProfile";

const InfoDetail = (props) => {
  const history = useHistory();
  const { isLoggedinUser, isAdmin } = props;
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { skills, email, status, primarySpeciality } = currentUser;

  const [showAboutOnTabScroll, setShowAboutOnTabScroll] = useState(false);
  const [showSkillsOnTabScroll, setShowSkillsOnTabScroll] = useState(false);
  const [showServicesOnTabScroll, setShowServicesOnTabScroll] = useState(false);
  const [showAffiliationsOnTabScroll, setShowAffiliationsOnTabScroll] = useState(false);
  const [showExperienceOnTabScroll, setShowExperienceOnTabScroll] = useState(false);
  const [showEducationsOnTabScroll, setShowEducationsOnTabScroll] = useState(false);
  const [showCertificationsOnTabScroll, setShowCertificationsOnTabScroll] = useState(false);
  const [showMembershipOnTabScroll, setShowMembershipOnTabScroll] = useState(false);
  const [showPresentationOnTabScroll, setShowPresentationOnTabScroll] = useState(false);
  const [showAwardsOnTabScroll, setShowAwardsOnTabScroll] = useState(false);
  const [showTrialsOnTabScroll, setShowTrialsOnTabScroll] = useState(false);
  const [showCommitteesOnTabScroll, setShowCommitteesOnTabScroll] = useState(false);
  const [showRegistrationOnTabScroll, setShowRegistrationOnTabScroll] = useState(false);
  const [tabScrolCus, setTabScrolCus] = useState(0);
  const [tabScrolhas, setTabScrolhas] = useState(false);
  const tabscrollRef = useRef(null);
  const [scrollelm, setScrollelm] = useState(false);

  const [activeTab, setActiveTab] = useState('aboutSection');
  const [tabScrollIsEnd, setTabScrollIsEnd] = useState(false);
  const [tabScrollIsStart, setTabScrollIsStart] = useState(true);


  function handleTabScroll__(label) {
    const cw = tabscrollRef.current.clientWidth;
    const sw = tabscrollRef.current.scrollWidth;
    let cal_w = tabScrolCus;

    if (label == 'next') {
      cal_w = cal_w > sw ? cal_w : cal_w + cw;
    } else {
      cal_w = cal_w <= 0 ? 0 : cal_w - cw;
    }

    setTabScrolCus(cal_w);
    if ((sw - cal_w) > (cw - 1)) {
      setTabScrolhas(true);
    } else {
      setTabScrolhas(false);
    }

  }

  function handleTabScroll(label) {
    let scroll_elm = document.querySelectorAll('.nexogic-profile-quick-link .profile-quick-scroller')[0];
    if (label == 'next') {
      scroll_elm.scrollLeft += 200;
    } else {
      if (scroll_elm.scrollLeft !== 0) {
        scroll_elm.scrollLeft -= 200;
      }
    }
  }

  useEffect(() => {
    tabScrollInit();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollelm(window.scrollY > 10);
    });
    tabScrollInit();

  }, [showAboutOnTabScroll,
    showAffiliationsOnTabScroll,
    showExperienceOnTabScroll,
    showEducationsOnTabScroll,
    showServicesOnTabScroll,
    showCertificationsOnTabScroll,
    showSkillsOnTabScroll,
    showMembershipOnTabScroll,
    showPresentationOnTabScroll,
    showAwardsOnTabScroll,
    showCommitteesOnTabScroll,
    showTrialsOnTabScroll,
    showRegistrationOnTabScroll
  ]);


  function tabScrollInit() {
    const cw = tabscrollRef.current.clientWidth;
    const sw = tabscrollRef.current.scrollWidth;
    setTabScrolhas(sw > cw);
  }

  /* get header height  */
  useEffect(() => {
    let scroll_elm = document.querySelectorAll('.nexogic-profile-quick-link .profile-quick-scroller')[0];
    setTimeout(() => {
      scroll_elm.scrollLeft = 0;
    }, 800);
    //console.log('66666 ', props);
    //console.log(scroll_elm);
    tabHandleResize();
    tabScrollHandle();
    window.addEventListener("resize", tabHandleResize);
    scroll_elm.addEventListener("scroll", tabScrollHandle);
    return () => {
      window.removeEventListener("resize", tabHandleResize);
      scroll_elm.removeEventListener("scroll", tabScrollHandle);
    };




  }, [showAboutOnTabScroll,
    showAffiliationsOnTabScroll,
    showExperienceOnTabScroll,
    showEducationsOnTabScroll,
    showServicesOnTabScroll,
    showCertificationsOnTabScroll,
    showSkillsOnTabScroll,
    showMembershipOnTabScroll,
    showPresentationOnTabScroll,
    showAwardsOnTabScroll,
    showCommitteesOnTabScroll,
    showTrialsOnTabScroll,
    showRegistrationOnTabScroll]);

  const tabHandleResize = () => {
    let header = document.querySelectorAll('.nexogic-header-card')[0];
    tabscrollRef.current.style.setProperty('--top_gap', header.offsetHeight + 'px');
  };


  const tabScrollHandle = () => {
    let scroll_elm = document.querySelectorAll('.nexogic-profile-quick-link .profile-quick-scroller')[0];
    let end_scroll_val = scroll_elm.scrollWidth - scroll_elm.clientWidth;
    let scroll_val = scroll_elm.scrollLeft;
    setTabScrollIsEnd(scroll_val < end_scroll_val);
    setTabScrollIsStart(scroll_val > 1);

    //console.log(scroll_val < end_scroll_val, scroll_val > 1);

  };


  if (
    !isLoggedinUser &&
    isEmpty(currentUser) &&
    skills &&
    skills.length === 0
  ) {
    return (
      <Card className="rounded-0 border-0 shadow p-3">
        <Row className="py-5 my-4">
          <Col xs="12">
            <p className="text-primary font-weight-bold font-italic">
              {" "}
              Please Sign In to access the full profile.
            </p>
            <Button color="primary" onClick={() => history.push("/login")}>
              {"Login"}
            </Button>
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <>
      <div className="unvarify-prfole-only">
        {isAdmin && !globalProfileInfo?.emails.length > 0 ? (
          <div color="link" size="sm" className="alert alert-danger p-1">
            This practitioner does not have an email associated. Please update
            the email to verify this practitioner.
          </div>
        ) : null}
        {isLoggedinUser &&
          [
            ProfileStatusConstants.PENDING_VERIFICATION,
            ProfileStatusConstants.REVIEWED,
          ].includes(status) ? (
          <div color="link" size="sm" className="alert alert-danger p-1">
            Our experts are reviewing your profile, once they approved we can
            book appointments for you.
          </div>
        ) : null}
      </div>
      {/*(isLoggedinUser || isAdmin) && (
        <div className="position-relative mb-3">
          <ProfileCompleteness />
        </div>
      )*/}
      <div className="position-relative mb-3 d-none">
        <Card className="doctors-details your-dashboard card">
          <div className="card-body">
            <h3 className="section-title">Your Dashboard</h3>
            <p className="tip-hints">Only you can see this</p>

            <div className="reach-profile-wrp">
              <Row>
                <Col md={4}>
                  <div className="num-card">
                    <div className="total-num">10</div>
                    <p className="count-label">Reached your profile</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="num-card">
                    <div className="total-num">10</div>
                    <p className="count-label">Viewed posts</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="num-card no-dot">
                    <div className="total-num">10</div>
                    <p className="count-label">Search appearences</p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Card>
      </div>



      <Row className="nex-profile-details-sec">
        <Col className="nexogic-profile-details-left nex-profile-details-left">
          <DoctorsProfile
            {...props}
          />

          {/** profile-quick-link */}
          <div
            className={`nexogic-profile-quick-link ${tabScrollIsStart ? 'prev_on' : ''} ${tabScrollIsEnd ? 'next_on' : ''}`}

            ref={tabscrollRef}>
            <div className={`scroller-arrow-left ${tabScrollIsStart ? 'on' : ''}`} ><img src={scroller_arrow} alt="" onClick={(e) => handleTabScroll('prev')} /></div>

            <div className={`scroller-arrow-right ${tabScrollIsEnd ? 'on' : ''}`} ><img src={scroller_arrow} alt="" onClick={(e) => handleTabScroll('next')} /></div>

            {/* style={{ 'transform': 'translateX(-' + tabScrolCus + 'px)' }} */}
            <ul className="profile-quick-scroller" >
              {showAboutOnTabScroll && <li><a href="#aboutSection" className={activeTab === 'aboutSection' ? 'active' : ''} onClick={() => setActiveTab('aboutSection')}>About </a></li>}
              {showSkillsOnTabScroll && <li><a href="#skillSection" className={activeTab === 'skillsSection' ? 'active' : ''} onClick={() => setActiveTab('skillsSection')}>Skills &amp; Expertise </a></li>}
              {showServicesOnTabScroll && <li><a href="#serviceSection" className={activeTab === 'serviceSection' ? 'active' : ''} onClick={() => setActiveTab('serviceSection')}>Services </a></li>}
              {showAffiliationsOnTabScroll && <li><a href="#affiliationSection" className={activeTab === 'hospitalsSection' ? 'active' : ''} onClick={() => setActiveTab('hospitalsSection')}>Hospital/Clinic Affiliations  </a></li>}
              {showExperienceOnTabScroll && <li><a href="#experienceSection" className={activeTab === 'experiencesSection' ? 'active' : ''} onClick={() => setActiveTab('experiencesSection')}>Experience </a></li>}
              {showEducationsOnTabScroll && <li><a href="#educationSection" className={activeTab === 'educationSection' ? 'active' : ''} onClick={() => setActiveTab('educationSection')}>Education </a></li>}
              {showCertificationsOnTabScroll && <li><a href="#certificationsSection" className={activeTab === 'certificationsSection' ? 'active' : ''} onClick={() => setActiveTab('certificationsSection')}>Certification </a></li>}
              {showMembershipOnTabScroll && <li><a href="#professionalSection" className={activeTab === 'membershipSection' ? 'active' : ''} onClick={() => setActiveTab('membershipSection')}>Membership </a></li>}
              {showPresentationOnTabScroll && <li><a href="#publicationsSection" className={activeTab === 'publicationSection' ? 'active' : ''} onClick={() => setActiveTab('publicationSection')}>Publications </a></li>}
              {showAwardsOnTabScroll && <li><a href="#awardsSection" className={activeTab === 'awardssSection' ? 'active' : ''} onClick={() => setActiveTab('awardssSection')}>Awards </a></li>}
              {showTrialsOnTabScroll && <li><a href="#clinicalTrialsSection" className={activeTab === 'trialsSection' ? 'active' : ''} onClick={() => setActiveTab('trialsSection')}>Clinical Trials </a></li>}
              {showCommitteesOnTabScroll && <li><a href="#committeesSection" className={activeTab === 'committeeSection' ? 'active' : ''} onClick={() => setActiveTab('committeeSection')}>Committees </a></li>}
              {showRegistrationOnTabScroll && <li><a href="#registrationSection" className={activeTab === 'registrationssSection' ? 'active' : ''} onClick={() => setActiveTab('registrationssSection')}>Registration </a></li>}
              {isLoggedinUser && <li><a href="#registration_documents" className={activeTab === 'registrationssDocsSection' ? 'active' : ''} onClick={() => setActiveTab('registrationssDocsSection')}>Registration Documents</a></li>}
            </ul>
          </div>


          <div className="position-relative mb-3">
            <About {...props} setShowAboutOnTabScroll={setShowAboutOnTabScroll} />
          </div>
          <div className="position-relative mb-3">
            <SkillsAndExpertise {...props} setShowSkillsOnTabScroll={setShowSkillsOnTabScroll} />
          </div>
          <div className="position-relative mb-3">
            <ServicesOffered {...props} setShowServicesOnTabScroll={setShowServicesOnTabScroll} />
          </div>
          <div className="position-relative mb-3">
            <HospitalAffiliations {...props} setShowAffiliationsOnTabScroll={setShowAffiliationsOnTabScroll} />
          </div>
          <div className="position-relative mb-3">
            <Experience {...props} setShowExperienceOnTabScroll={setShowExperienceOnTabScroll} />
          </div>
          <div className="position-relative mb-3">
            <Education {...props} setShowEducationsOnTabScroll={setShowEducationsOnTabScroll} />
          </div>
          <div className="position-relative mb-3">
            <Certifications {...props} setShowCertificationsOnTabScroll={setShowCertificationsOnTabScroll} />
          </div>
          <div className="position-relative mb-3">
            <ProfessionalMemberships {...props} setShowMembershipOnTabScroll={setShowMembershipOnTabScroll} />
          </div>
          <div className="position-relative mb-3">
            <Publications {...props} setShowPresentationOnTabScroll={setShowPresentationOnTabScroll} />
          </div>
          <div className="position-relative mb-3">
            <AwardsRecognitions {...props} setShowAwardsOnTabScroll={setShowAwardsOnTabScroll} />
          </div>
          <div className="position-relative mb-3">
            <ClinicalTrials {...props} setShowTrialsOnTabScroll={setShowTrialsOnTabScroll} />
          </div>
          <div className="position-relative mb-3">
            <Committees {...props} setShowCommitteesOnTabScroll={setShowCommitteesOnTabScroll} />
          </div>
          <div className="position-relative mb-3">
            <Registration {...props} setShowRegistrationOnTabScroll={setShowRegistrationOnTabScroll} />
          </div>
          {((isLoggedinUser && globalProfileInfo.guid === currentUser.guid) ||
            isAdmin) && (
              <div className="position-relative mb-3">
                <Documents {...props} />
              </div>
            )}
          {/** 
          <div className="position-relative mb-3">
            <SocialHandler {...props} />
          </div>
          */}
        </Col>
        <Col className="position-relative nex-sidebar v2">

          {/* html for social and profile complete ness*/}
          <div className="card nexogic-widget-sidebar-card">

          {(isLoggedinUser || isAdmin) && (
            <>
              <ProfileCompleteness />
            </>
          )}
          {isLoggedinUser && 
            <SocialHandler {...props} />
          }

          </div>

          {/*isLoggedinUser &&
            <div className="card nexogic-widget-sidebar-card">
              <div className="card-body">
                <div className="card-group nexogic-card-group-list">
                <SocialHandler {...props} />
                </div>
              </div>
            </div>
          */}
          
          {(isAdmin || (isPractitioner() && globalProfileInfo?.guid === currentUser.guid)) &&
            <PractitionerResumes practitionerGuid={globalProfileInfo?.guid} />
          }

          {isPractitioner() &&
            <PeopleKnowWidget speciality={primarySpeciality} />
          }

          {isPractitioner() &&
            <JobWidget widgetTitle="Recommended Jobs" primarySpeciality={primarySpeciality} />
          }
        </Col>
      </Row>
    </>
  );
};

export const DoctorsDetails = (props) => {
  return (
    <div className="position-relative mb-3 ">
      <InfoDetail {...props} />
    </div>
  );
};
