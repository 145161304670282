import React from 'react'
import IconBooking from './icons/IconBooking'
import IconNext from './icons/IconNext'
import IconPrev from './icons/IconPrev'

export default function Availability() {
  return (
    <div className='nexogic-availability-card'>
        <h3 className="card-title">Availability 
            <div className='nexogic-date-switcher'>
                <div className="btn btn-prev"><IconPrev /></div>
                <span className="date">Apr 22, 2021</span>
                <div className="btn btn-next"><IconNext /></div>
            </div>
        </h3>
        <div className="btn-group btn-check-group" role="group">
            <input type="checkbox" className="btn-check" id="avail_1" autocomplete="off" />
            <label className="btn btn-outline-primary" for="avail_1">09:00 am</label>
            <input type="checkbox" className="btn-check" id="avail_2" autocomplete="off" />
            <label className="btn btn-outline-primary" for="avail_2">10:45 am</label>

            <input type="checkbox" className="btn-check" id="avail_3" autocomplete="off" />
            <label className="btn btn-outline-primary" for="avail_3">11:45 am</label>

            <input type="checkbox" className="btn-check" id="avail_4" autocomplete="off" />
            <label className="btn btn-outline-primary" for="avail_4">12:45 pm</label>

            <input type="checkbox" className="btn-check" id="avail_5" autocomplete="off" />
            <label className="btn btn-outline-primary" for="avail_5">1:45 pm</label>

            <input type="checkbox" className="btn-check" id="avail_6" autocomplete="off" />
            <label className="btn btn-outline-primary" for="avail_6">2:00 pm</label>

            <input type="checkbox" className="btn-check" id="avail_6" autocomplete="off" />
            <label className="btn btn-outline-primary" for="avail_6">3:45 pm</label>

            <input type="checkbox" className="btn-check" id="avail_7" autocomplete="off" />
            <label className="btn btn-outline-primary" for="avail_7">4:45 pm</label>
        </div>
        <div className="btn nexogic-btn-def"><span className='icon'><IconBooking /> </span> View all availability</div>
    </div>
  )
}
