import React, { forwardRef, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import doctor_profile_img from "assets/images/doctor-profile-img.png";
import graduation_cap_icon from "assets/images/graduation-cap.png";
import suitcase_icon from "assets/images/suitcase.png";
import { Col, Row } from 'reactstrap';
import JobWidget from 'common/JobWidget';
import InfiniteScroll from "react-infinite-scroll-component";
import { PractitionerService } from "utils/PractitionerService";

import { createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import CommonLoader from "common/CommonLoader";
import LoadMoreButton from 'common/LoadMoreButton/LoadMoreButton';
import ProfileCardItem from 'pages/FeedHomePage/components/PractitionerProfiles/ProfileCardItem';

const AllPractitionersWorkHereList = () => {
    const history = useHistory();
    const location = useLocation();
    const props = location.state || {};
    const [hasMore, setHasMore] = useState(false);    
    const [loadingMore, setLoadingMore] = useState(false);
    const { institutionGuid } = props;
    const [list_of_data, setList_of_data] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getData = async (pageNo, feedlist) => {
        const request = PractitionerService.getPractitionersAssociatedtoInstitutionWithPagination(
            institutionGuid,
            pageNo
        );
        request
            .then(async (response) => {
                let { data } = response;
                if (data?.currentPage < (data?.totalPages - 1))
                    setHasMore(true);
                  else
                    setHasMore(false);
                    setLoadingMore(false);
                if (pageNo === 0) {
                    const allData = { ...data };
                    const resolved = await Promise.all(
                        allData.contents.map(async (item) => {
                            const each = { ...item };
                            return each;
                        })
                    );
                    allData.contents = resolved;
                    setList_of_data(allData);
                    setIsLoading(false);
                } else {
                    const resolved = await Promise.all(
                        data.contents.map(async (item) => {
                            const each = { ...item };
                            return each;
                        })
                    );
                    feedlist = feedlist.concat(resolved);
                    setList_of_data({ ...data, contents: feedlist });
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log("Error while getting practitioners' list: ", error);
            });
    };

    const fetchData = () => {
        getData(list_of_data.currentPage + 1, list_of_data.contents);
    };

    useEffect(() => {
        getData(0, []);
    }, []);

    const loadMore = () => {
        setLoadingMore(true);
        fetchData();
    };

    if (isLoading) {
        return (
            <div className="loading-spiner text-center  mt-2">
                <CommonLoader />
            </div>
        );
    }

    return list_of_data && list_of_data.contents.length > 0 ? (
        <>
            <Row>
                <Col xl={9} className="nexogic-profile-details-left" >
                    <div className="nex-main-panel">
                        <div className="nex-panel-head">
                            <h1 className="nex-text-xl"> <span className="btn" onClick={() => history.goBack()}><svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.125 2L1.125 10L9.125 18" stroke="black" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            </span> Practitioners who work here </h1>
                        </div>
                        <div className="nex-panel-body">
                                {list_of_data &&
                                    list_of_data.contents.map((practitioner, id) => (
                                        <>
                                        <ProfileCardItem {...practitioner} key={`profile_${id}`} viewmode />
                                            {/* <div className="nex-icon-list">
                                                <div className="graphic">
                                                    {practitioner?.avatarId ? (
                                                        <img
                                                            src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${practitioner?.profileGuid}/${practitioner?.avatarId}`}
                                                            alt="#"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={createImageFromInitials(
                                                                100,
                                                                practitioner?.name.replace("Dr. ", ""),
                                                                getRandomColor(
                                                                    encrypt(practitioner?.profileGuid).toString()
                                                                )
                                                            )}
                                                            alt="#"
                                                        />
                                                    )}
                                                </div>
                                                <div className="info">
                                                    <h2 className="nex-text-xl small"><a href={`../profile/${practitioner?.profileId}`} target="_blank" >{practitioner?.name}</a>
                                                        {practitioner.profileStatus === "APPROVED" ? (
                                                            <span className="nex-icon">
                                                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.875 5.25L9.875 2.25L15.875 5.25C15.875 9.14492 13.7868 14.6323 9.875 15.75C5.96321 14.6323 3.875 9.14492 3.875 5.25Z" fill="#34EA72" />
                                                                <path d="M12.5 7.125L9.125 10.5L8 9.375M9.875 2.25L3.875 5.25C3.875 9.14492 5.96321 14.6323 9.875 15.75C13.7868 14.6323 15.875 9.14492 15.875 5.25L9.875 2.25Z" stroke="#257C6D" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </span>
                                                        ) : (
                                                            ""
                                                        )}                                                        
                                                    </h2>
                                                    <ul className="nex-inline-list">
                                                        <li>{practitioner?.primarySpeciality}</li>
                                                        <li> {practitioner?.superSpeciality}</li>
                                                    </ul>
                                                    <p className="nex-text"><span className="nex-icon xs"><img src={graduation_cap_icon} alt="" /></span>{practitioner?.qualification}</p>
                                                    <p className="nex-text"><span className="nex-icon xs"><img src={suitcase_icon} alt="" /></span>
                                                        {new Date().getFullYear() - practitioner?.practicingSince >
                                                            1 ? (
                                                            <>
                                                                {" "}
                                                                {new Date().getFullYear() -
                                                                    practitioner?.practicingSince}
                                                                {"+ years experience"}
                                                            </>
                                                        ) : (
                                                            "1 year experience"
                                                        )}
                                                    </p>
                                                    <p className="bold text-black mt-2"><span className="nex-icon xs"><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.61189 16.3308C12.6382 14.7876 15 12.2321 15 9.125C15 5.81129 12.3137 3.125 9 3.125C5.68629 3.125 3 5.81129 3 9.125C3 12.2321 5.36181 14.7876 8.3881 16.3308C8.77259 16.5268 9.22741 16.5268 9.61189 16.3308Z" stroke="black" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M11.25 9.125C11.25 10.3676 10.2426 11.375 9 11.375C7.75736 11.375 6.75 10.3676 6.75 9.125C6.75 7.88236 7.75736 6.875 9 6.875C10.2426 6.875 11.25 7.88236 11.25 9.125Z" stroke="black" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    </span> {getFullAddressWithLocationCityAndState({ location: practitioner?.location ?? "", city: practitioner?.city ?? "", state: practitioner?.state ?? "" })}</p>

                                                </div>
                                                <div className="action">
                                                    <a href="" className="btn nex-btn-primary">View Profile</a>
                                                </div>
                                            </div> */}
                                        </>
                                    ))}
                            {hasMore &&
                                < div className="text-center mt-2">
                                    {loadingMore ?
                                        <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                                        :
                                        <LoadMoreButton loadMore={loadMore} />
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </Col>
                <Col xl={3} className="position-relative nex-sidebar-right">
                    <JobWidget widgetTitle="Jobs" {...props} />
                </Col>
            </Row>
        </>
    ) : (
        <h1>No Data</h1>
    )
}

export default AllPractitionersWorkHereList