import React from 'react';
import { Link } from "react-router-dom";
import { useEffect } from 'react';

const GroupRecord = ({ profileDetails, index }) => {
    return (
        <tr key={`profile_${index}`}>
            <td className="border font-weight-bold">
                <div className=' d-flex'>
                    <h4 className="link-text">
                        <Link className="group-link nex-gi-group-group-name" to={`/connections/groups/${profileDetails?.id}`}>
                            {profileDetails?.groupName}
                        </Link>
                    </h4>
                </div>
            </td>
            <td className="border">
                {profileDetails?.groupType}
            </td>
            <td className="border">
                {profileDetails?.role}
            </td>
            <td className="border">
                {profileDetails?.status}
            </td>
        </tr >
    );
}

const GroupInvitationPendingTabularView = (props) => {
    const { theader, tdata } = props;

    // useEffect(() => {
    //     console.log(tdata);
    // }, [tdata])

    return (
        <div className="profiles-table">
            <table className="table border" >
                <thead>
                    <tr>
                        {theader.map((columns, index) => (
                            <th className="theader" key={index}>{columns}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tdata.map((profileDetails, index) => (
                        <GroupRecord
                            profileDetails={profileDetails}
                            index={index}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default GroupInvitationPendingTabularView;