import React, { useEffect } from 'react'
import { useState } from 'react'
import ReferSearchPractitioner from './OpinionSearchPractitioner';
import { PractitionerService } from 'utils/PractitionerService';

export default function OpinionPractitionerSearchField(props) {
    const { referral, selectedOption, setSelectedOption, practitioners } = props;

    return (
        <div className="form-group nexogic-practi-search-fields">
            <label>{props.label}</label>
            <div className="nexogic-inp">
                <ReferSearchPractitioner searchdata={practitioners}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    referral={referral}
                />
            </div>
        </div>
    )
}
