import React from 'react'
import './css/v1.scss'
import logo_img from 'assets/images/logo-top-icon.png'
import bg_img from 'assets/images/ads_nine_bg.jpg'

 function AdsNine(props) {
  return (
    <div className='nex-ads-wrapper-h v9' style={{backgroundImage: `url(${props.bg})`}}>
        <div className="nex-ads-brand">
            <a href={props.logo_url}><img src={props.logo_img} alt="" /></a>
        </div>
        <div className="nex-ads-content-w">
            <div className="nex-ads-content">
                <h2 className="nex-ads-content-title" dangerouslySetInnerHTML={{__html: props.short_desc}}>
                    
                </h2>
            </div>
        </div>
    </div>
  )
}


/* default props */
AdsNine.defaultProps = {
    bg:bg_img,
    logo_img:logo_img,
    logo_url:"#",
    logo_target:"_blank",
    short_desc: "22Lorem ipsum dolor sit <b>amet consectetur</b> adipisicing elit. Atque minima esse, <b>totam corporis</b> praesentium commodi!",
  }
  
  export default AdsNine;