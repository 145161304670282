import React from "react";

function IconNext() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="14"
      fill="none"
      viewBox="0 0 8 14"
    >
      <path
        fill="#001244"
        d="M1.155.993L.13 2.016 5.126 7 .13 11.995l1.012 1.013 5.502-5.502L7.162 7l-.517-.506L1.155.993z"
      ></path>
    </svg>
  );
}

export default IconNext;
