import {
  faBriefcase,
  faComment,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import marker_icon from "assets/images/marker-2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import graduation_cap from "assets/images/graduation-cap.png";
import suitcase_icon from "assets/images/suitcase.png";
import pdf_icon from "assets/images/pdf-icon.png";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Fade,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap";
import { useEffect } from "react";
import { TalentService } from "utils/TalentService";
import CommonLoader from "common/CommonLoader";
import { calculateExperienceYears, convertJobShiftToTitleCase, createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor } from "utils/Utils";
import { FAILED_TO_FETCH_DATA, FAILTOSAVERECOED } from "utils/Constants";
import AlertDialog from "common/AlertDialog";
import { withAlert } from "react-alert";
import ShowStatus from "../../ShowStatus";
import SendEmail from "../SendEmail";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { PractitionerService } from "utils/PractitionerService";
import { useSelector } from "react-redux";

const ApplicationDetails = (props) => {
  const location = useLocation();
  const { state } = location;
  const params = useParams();
  const { jobApplicationId } = params;
  const [isOpenResume, setisOpenResume] = useState(false);
  const [jobApplication, setJobApplication] = useState({});
  const [jobDetails, setJobDetails] = useState({});
  const [viewDoc, setViewDoc] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [dropDownItem, setDropDownItem] = useState();
  const [jobApplicationIdToReject, setJobApplicationIdToReject] =
    useState(null);
  const [isOpenAlert, setAlertModal] = useState(false);
  const [isContactOpen, setisContactOpen] = useState(true);
  const [actions, setActions] = useState([])
  let history = useHistory();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [resumeInfo, setResumeInfo] = useState({});
  const [showCoverLetter, setShowCoverLetter] = useState(true);

  const [isCoverLetterOpen, setIsCoverLetterOpen] = useState(false);
  const toggleCoverLetter = () => {
    setIsCoverLetterOpen(!isCoverLetterOpen);
  }

  function ContactOpen() {
    setisContactOpen(!isContactOpen);
  }

  function ResumeFunc() {
    // setResumeLink(true);
    setisOpenResume(!isOpenResume);
  }

  function ResumeDownload() {
    fetch(viewDoc).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.target = "_blank";
        alink.click();
      });
    });
  }

  function setActionsForTheStatus(status) {
    if (status === "APPLIED") {
      setActions(["Shortlist", "Reject", "Hold"])
    } else if (status === "SHORTLISTED") {
      setActions(["Interview", "Hold"])
    } else if (status === "INTERVIEW") {
      setActions(["Reject", "Offer", "Hold"])
    } else if (status === "OFFERED") {
      setActions(["Accept", "Reject", "Hold"])
    } else if (status === "ACCEPTED") {
      setActions(["Hire"])
    } else if (status === "HIRED") {
      setActions(["Close"])
    } else if (status === "ON_HOLD") {
      setActions(["Shortlist", "Interview", "Offer", "Accept", "Hire"]);
    } else {
      setActions([])
    }
  }

  const getResumeInfo = (id) => [
    PractitionerService.getResumesProfile(id)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          setResumeInfo(data);
        } else {
          showErrorMessage(FAILED_TO_FETCH_DATA);
        }
      })
  ]

  const getJob = (jobId) => {
    if (jobId) {
      (async () => {
        TalentService.getJob(jobId)
          .then((response) => {
            let { data } = response;
            if (response.status === 200 || response.status === 201) {
              setJobDetails(data);
            }
          })
          .catch((error) => {
            console.log("Error while getting connection status: ", error);
          });
      })();
    }
  };

  useEffect(() => {
    getJob(state?.params?.jobProfileId);
  }, [state?.params?.jobProfileId]);

  const getJobApplication = () => {
    TalentService.getJobApplicationById(jobApplicationId).then((response) => {
      const { status, data } = response;
      if (status === 200) {
        setJobApplication(data);
        // getResumeInfo(data.applicant.profileGuid);
        const viewDocument = `${process.env.REACT_APP_API_BASE_URL}practitioner-service/practitioners/${data.applicant.profileGuid}/resumes/${data.cvID}`;
        setIsLoading(false);
        setViewDoc(viewDocument);
        setActionsForTheStatus(data?.status)
      } else {
        console.log("Loading Failed...Please try again");
        setIsLoading(false);
      }
    });
  };
  useEffect(() => {
    getJobApplication();
  }, []);

  const RenderExpYear = ({ practiceStartYear }) => {
    let currentYear = new Date().getFullYear();
    if (isNaN(practiceStartYear) || currentYear - practiceStartYear < 1) {
      return "1 year experience";
    } else {
      return `${currentYear - practiceStartYear}+ years experience`;
    }
  };

  const toggleAlert = () => {
    setAlertModal(!isOpenAlert);
  };

  const handleUpdateJobStatus = (status) => {
    if (status === "Reject") {
      setJobApplicationIdToReject(jobApplicationId);
      toggleAlert();
      return;
    }
    let act = "";
    switch (status) {
      case "Shortlist":
        act = "SHORTLISTED";
        break;
      case "Reject":
        act = "REJECTED";
        break;
      case "Interview":
        act = "INTERVIEW";
        break;
      case "Offer":
        act = "OFFERED";
        break;
      case "Accept":
        act = "ACCEPTED";
        break;
      case "Hire":
        act = "HIRED";
        break;
      case "Hold":
        act = "ON_HOLD";
        break;
      default:
        act = "CLOSED";
    }

    const payload = [
      {
        op: "replace",
        path: "/status",
        value: act,
      },
    ];
    TalentService.patchJobApplication(jobApplicationId, payload).then(
      (response) => {
        const { status } = response;
        if (status === 200) {
          showSuccessMessage("Jobs Application has been updated Successfully...");
          getJobApplication();
        } else {
          showErrorMessage(FAILTOSAVERECOED);
        }
      }
    );
  };

  const handleClickOnYes = () => {
    const payload = [
      {
        op: "replace",
        path: "/status",
        value: "REJECTED",
      },
    ];

    TalentService.patchJobApplication(jobApplicationIdToReject, payload).then(
      (response) => {
        const { status } = response;
        if (status === 200) {
          showSuccessMessage("Jobs Application has been rejected Successfully...");
          getJobApplication();
        } else {
          showErrorMessage(FAILTOSAVERECOED);
        }
      }
    );
    toggleAlert();
  };

  const getFullSpeciality = (primarySpeciality, superSpeciality) => {
    let fullSpeciality = "";
    if (primarySpeciality !== undefined && primarySpeciality !== "") {
      fullSpeciality += primarySpeciality;
    }

    if (superSpeciality !== undefined && superSpeciality !== "" && superSpeciality !== null) {
      fullSpeciality += "(";
      fullSpeciality += superSpeciality;
      fullSpeciality += ")";
    }

    return fullSpeciality;
  };

  if (isLoading) {
    return (
      <div className="loading-spiner text-center  mt-2">
        <CommonLoader style={{ width: "2rem", height: "2rem" }} />
      </div>
    );
  }

  return (
    <div className="nex-job-applic-details">
      <h2 className="nex-text-xl">Applications for {jobDetails?.title}</h2>
      {/* <p className="nex-text-lg">Have a look, how many people have applied for the role</p> */}
      <Card className="mt-2 mb-2 bg-white d-none">
        <CardBody className="applic-breadcumb-wrap">
          <>
            <Breadcrumb className="job-bread">
              <BreadcrumbItem>
                <Link to={`/jobs/${state?.params?.jobProfileId}`} className={`${state?.params?.jobProfileId === undefined ? "disable-go-back" : ""}`}>
                  <FontAwesomeIcon icon={faBriefcase}
                  />{" "}
                  {state?.params?.jobTitle ? state?.params?.jobTitle : "Job"}
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link onClick={() => history.goBack()} className={`${history.length <= 2 ? "disable-go-back" : ""}`}>applications</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {jobApplication?.applicant?.name}
              </BreadcrumbItem>
              <div className="application-status">
                <ShowStatus status={jobApplication?.status} />
              </div>
            </Breadcrumb>
            <div className="button-action">
              <>
                <SendEmail email="" subject={`Job Opportunity – ${state?.params?.jobTitle} Position at ${jobDetails?.institutionDetails?.name}`}
                  body={`%0D%0A%0D%0A${jobDetails?.institutionDetails?.name} is looking for  ${state?.params?.jobTitle} position%0D%0APlease click link below to check the Complete Job details%0D%0A${process.env.REACT_APP_LB_API_ENDPOINT}jobs/${state?.params?.jobProfileId}%0D%0A%0D%0A%0D%0A`}>
                  <Button color="info" outline className="mr-2 nex-btn-primary-outline">
                    <FontAwesomeIcon icon={faEnvelope} /> Email
                  </Button></SendEmail>
              </>
            </div>
          </>
        </CardBody>
      </Card>

      <Card className="nex-job-applic-details-card">
        <CardBody>
          <div className="nex-flex-wrap gap-md nex-job-applic-details-top">
            <div className="nex-job-application-info nex-flex-wrap gap-md">
              <div className="graphic">
                <Link to={`/profile/${jobApplication?.applicant?.profileId}`}>
                  {jobApplication.applicant?.avatarId ? (
                    <img
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${jobApplication.applicant?.profileGuid}/${jobApplication.applicant?.avatarId}`}
                      alt="#"
                      className="nex-avatar lg"
                    />
                  ) : (
                    <img
                      src={createImageFromInitials(
                        40,
                        jobApplication.applicant?.name.replace('Dr. ', ''),
                        getRandomColor(null)
                      )}
                      alt="profilePic"
                      width={64}
                      className="nex-avatar lg"
                    />
                  )}
                </Link>
              </div>
              <div className="info">
                <h3 className="nex-text-xl bold">
                  <Link
                    to={`/profile/${jobApplication?.applicant?.profileId}`}
                  >
                    {jobApplication?.applicant?.name}
                  </Link>
                  <div className="nex-status-pill purple">{convertJobShiftToTitleCase(jobApplication?.status)}</div>
                </h3>
                <div className="nex-text-md-2 bold nex-text-blue mb-2">{jobApplication?.applicant?.primarySpeciality}  {jobApplication?.applicant?.superSpeciality ? '| ' + jobApplication?.applicant?.superSpeciality : ''}</div>
                <div className="nex-text-sm text-black">{getFullAddressWithLocationCityAndState({
                  location: jobApplication?.applicant?.location ?? "",
                  city: jobApplication?.applicant?.city ?? "",
                  state: jobApplication?.applicant?.state ?? ""
                }) !== "" &&
                  < img src={marker_icon} alt="#" width={10} />
                }{" "}
                  {getFullAddressWithLocationCityAndState({
                    location: jobApplication?.applicant?.location ?? "",
                    city: jobApplication?.applicant?.city ?? "",
                    state: jobApplication?.applicant?.state ?? ""
                  })}</div>
              </div>
            </div>

            <div className="nex-job-application-action">
              {jobApplication?.status !== "REJECTED" && jobApplication?.status !== "CLOSED" && (
                <Dropdown
                  isOpen={dropDownItem == jobApplication.id}
                  toggle={(e) =>
                    dropDownItem === jobApplication?.id
                      ? setDropDownItem(null)
                      : setDropDownItem(jobApplication?.id)
                  }
                >
                  <DropdownToggle className="nex-btn-primary-outline"
                    caret
                    color="primary"
                  >
                    Status
                  </DropdownToggle>
                  <DropdownMenu right>
                    {actions.map((action) => (
                      <DropdownItem
                        key={action}
                        onClick={(e) => {
                          handleUpdateJobStatus(action);
                        }}
                      >
                        {jobApplication?.status != action && action}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              )}
            </div>
          </div>
          <div className="nex-application-quick-info">
            <div className="nex-light-blue-outline-card">
              <div className="resume-btn-w">
                <div className="nex-upload-btn"><svg onClick={() => ResumeDownload()} style={{ cursor: "pointer" }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_697_2037)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M5.9727 1.75777C6.73003 1.69784 7.49107 1.81126 8.19801 2.08944C8.90495 2.36761 9.53921 2.80323 10.0526 3.36319C10.459 3.80643 10.7812 4.31836 11.005 4.87376H11.6952C12.4239 4.88358 13.1305 5.12477 13.7132 5.5624C14.2959 6.00002 14.7244 6.6115 14.9369 7.30854C15.1495 8.00559 15.135 8.75213 14.8955 9.4404C14.6561 10.1287 14.2042 10.723 13.6049 11.1377C13.3211 11.3341 12.9317 11.2633 12.7353 10.9794C12.5389 10.6956 12.6098 10.3062 12.8936 10.1098C13.275 9.84594 13.5626 9.4677 13.7149 9.02971C13.8673 8.59172 13.8765 8.11665 13.7413 7.67307C13.606 7.2295 13.3333 6.84038 12.9625 6.56189C12.5928 6.28419 12.1446 6.13083 11.6823 6.12376H10.5618C10.285 6.12376 10.0412 5.94174 9.96254 5.67639C9.80061 5.13011 9.51631 4.62792 9.13126 4.20794C8.7462 3.78797 8.27051 3.46126 7.74031 3.25263C7.2101 3.04399 6.63932 2.95892 6.07132 3.00388C5.50332 3.04883 4.95303 3.22263 4.46224 3.51207C3.97146 3.8015 3.55308 4.19897 3.23888 4.67429C2.92468 5.14961 2.72293 5.69027 2.64894 6.25523C2.57495 6.82018 2.63067 7.39456 2.81187 7.93476C2.99307 8.47496 3.29499 8.96676 3.69469 9.37282C3.93683 9.61882 3.93371 10.0145 3.68771 10.2567C3.44171 10.4988 3.046 10.4957 2.80385 10.2497C2.27092 9.70828 1.86837 9.05255 1.62677 8.33228C1.38517 7.61202 1.31087 6.84618 1.40952 6.09291C1.50818 5.33963 1.77718 4.61875 2.19611 3.98499C2.61504 3.35124 3.17288 2.82128 3.82726 2.43536C4.48165 2.04944 5.21536 1.81771 5.9727 1.75777Z" fill="#46ABC9" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.25 7.375C8.59518 7.375 8.875 7.65482 8.875 8V13.625C8.875 13.9702 8.59518 14.25 8.25 14.25C7.90482 14.25 7.625 13.9702 7.625 13.625V8C7.625 7.65482 7.90482 7.375 8.25 7.375Z" fill="#46ABC9" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M5.30806 10.6831C5.55214 10.439 5.94786 10.439 6.19194 10.6831L8.25 12.7411L10.3081 10.6831C10.5521 10.439 10.9479 10.439 11.1919 10.6831C11.436 10.9271 11.436 11.3229 11.1919 11.5669L8.69194 14.0669C8.44786 14.311 8.05214 14.311 7.80806 14.0669L5.30806 11.5669C5.06398 11.3229 5.06398 10.9271 5.30806 10.6831Z" fill="#46ABC9" />
                  </g>
                  <defs>
                    <clipPath id="clip0_697_2037">
                      <rect width="15" height="15" fill="white" transform="translate(0.75 0.5)" />
                    </clipPath>
                  </defs>
                </svg>
                </div>
                <div className="nex-btn" onClick={ResumeFunc}>

                  <div className="icon"><img src={pdf_icon} width={28} alt="" /></div>
                  <div className="label">View the Resume</div>
                </div>
              </div>
            </div>
            <div className="nex-job-application-meta-info nex-flex-wrap gap-md">
              <ul className="nex-lists">
                <li><div className="icon"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M6.41406 2.7474C5.9078 2.7474 5.4974 3.1578 5.4974 3.66406V18.3307C5.4974 18.837 5.9078 19.2474 6.41406 19.2474H15.5807C16.087 19.2474 16.4974 18.837 16.4974 18.3307V3.66406C16.4974 3.1578 16.087 2.7474 15.5807 2.7474H6.41406ZM3.66406 3.66406C3.66406 2.14528 4.89528 0.914062 6.41406 0.914062H15.5807C17.0995 0.914062 18.3307 2.14528 18.3307 3.66406V18.3307C18.3307 19.8495 17.0995 21.0807 15.5807 21.0807H6.41406C4.89528 21.0807 3.66406 19.8495 3.66406 18.3307V3.66406Z" fill="#5B5B5B" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.0859 16.5026C10.0859 15.9963 10.4963 15.5859 11.0026 15.5859H11.0118C11.518 15.5859 11.9284 15.9963 11.9284 16.5026C11.9284 17.0089 11.518 17.4193 11.0118 17.4193H11.0026C10.4963 17.4193 10.0859 17.0089 10.0859 16.5026Z" fill="#5B5B5B" />
                </svg>
                </div>{jobApplication?.contactDetails?.phoneNumber}</li>
                <li>
                  <div className="icon"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M3.66406 4.58333C3.16199 4.58333 2.7474 4.99793 2.7474 5.5V16.5C2.7474 17.0021 3.16199 17.4167 3.66406 17.4167H18.3307C18.8328 17.4167 19.2474 17.0021 19.2474 16.5V5.5C19.2474 4.99793 18.8328 4.58333 18.3307 4.58333H3.66406ZM0.914062 5.5C0.914062 3.98541 2.14947 2.75 3.66406 2.75H18.3307C19.8453 2.75 21.0807 3.98541 21.0807 5.5V16.5C21.0807 18.0146 19.8453 19.25 18.3307 19.25H3.66406C2.14947 19.25 0.914062 18.0146 0.914062 16.5V5.5Z" fill="#5B5B5B" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.07985 4.97701C1.37017 4.56226 1.94174 4.4614 2.35648 4.75172L10.9975 10.8004L19.6385 4.75172C20.0532 4.4614 20.6248 4.56226 20.9151 4.97701C21.2054 5.39175 21.1046 5.96332 20.6898 6.25365L11.5231 12.6703C11.2075 12.8913 10.7874 12.8913 10.4718 12.6703L1.30513 6.25365C0.890389 5.96332 0.789524 5.39175 1.07985 4.97701Z" fill="#5B5B5B" />
                  </svg>
                  </div> <a href={`mailto: ${jobApplication?.contactDetails?.emailAddress}`}>{jobApplication?.contactDetails?.emailAddress}</a>
                </li>
              </ul>
              <ul className="nex-lists">
                <li><div className="icon"><img src={graduation_cap} alt="" />
                </div> {jobApplication?.applicant?.qualification}</li>
                <li>
                  <div className="icon"><img src={suitcase_icon} alt="" />
                  </div> {calculateExperienceYears(jobApplication?.applicant?.practicingSince) <= 1 ? <span> calculateExperienceYears(jobApplication?.applicant?.practicingSince){" year"}</span> : calculateExperienceYears(jobApplication?.applicant?.practicingSince)}{" years"} Experience
                </li>
              </ul>
            </div>
          </div>

          {/** cover letter */}
          <div className="nex-card-v2 border cover-letter-sec">
            <div className="card-title">Cover Letter <span href="" className="nex-btn-primary-outline" onClick={() => setShowCoverLetter(!showCoverLetter)}>{`${showCoverLetter ? 'Hide' : 'Show'}`}</span></div>
            {showCoverLetter &&
              <div dangerouslySetInnerHTML={{ __html: jobApplication?.coverLetter }}></div>
            }
          </div>

          {/** question answer  */}
          <div className="nex-card-v2 border nex-job-ques-answer-card">
            <div className="card-body">
              <div className="card-title">Applicant’s Answer</div>
              <div className="mb-3">{
                jobApplication?.answers && jobApplication?.answers.map((e) => (
                  <>
                    <p className="nex-text-lg">{e?.question}</p>
                    <p className="nex-text-lg">{"Answer: "}<b>{e?.answer} </b></p>
                    <br />
                  </>
                ))
              }
              </div>
            </div>
          </div>
          <Row className="d-none">
            <Col md={12}>
              <div className="nex-job-quick-info">
                <Link to={`/profile/${jobApplication?.applicant?.profileId}`}>
                  {jobApplication.applicant?.avatarId ? (
                    <img
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${jobApplication.applicant?.profileGuid}/${jobApplication.applicant?.avatarId}`}
                      alt="#"
                      className="applicant-img"
                    />
                  ) : (
                    <img
                      src={createImageFromInitials(
                        40,
                        jobApplication.applicant?.name.replace('Dr. ', ''),
                        getRandomColor(null)
                      )}
                      alt="profilePic"
                      width={64}
                      className="applicant-img"
                    />
                  )}
                </Link>
                <div className="applicant-content">
                  <span className="senedr-name">
                    <h3 className="nex-text-xl bold">
                      <Link
                        to={`/profile/${jobApplication?.applicant?.profileId}`}
                      >
                        {jobApplication?.applicant?.name}
                      </Link>
                    </h3>

                    <p className="nex-text-xl mb-2 nex-text-blue">{jobApplication?.applicant?.qualification}</p>
                  </span>
                  <div className="applicant-speciality">
                    {getFullSpeciality(
                      jobApplication?.applicant?.primarySpeciality,
                      jobApplication?.applicant?.superSpeciality
                    )}
                  </div>
                  <div className="applicant-location">
                    {getFullAddressWithLocationCityAndState({
                      location: jobApplication?.applicant?.location ?? "",
                      city: jobApplication?.applicant?.city ?? "",
                      state: jobApplication?.applicant?.state ?? ""
                    }) !== "" &&
                      < img src={marker_icon} alt="#" />
                    }{" "}
                    {getFullAddressWithLocationCityAndState({
                      location: jobApplication?.applicant?.location ?? "",
                      city: jobApplication?.applicant?.city ?? "",
                      state: jobApplication?.applicant?.state ?? ""
                    })}
                  </div>
                  <div className="applicant-speciality">
                    <RenderExpYear
                      practiceStartYear={
                        jobApplication?.applicant?.practicingSince
                      }
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="contact-card">
                <div>
                  <h2 className="card-title">
                    Contact Details
                    <span className=" ml-auto btn btn-small" onClick={ContactOpen}>
                      {isContactOpen ? "Hide" : "Show"}
                    </span>
                  </h2>
                  {isContactOpen && (
                    <>
                      <div className="contact-item email">
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="icn-cls"
                        />
                        {jobApplication?.contactDetails?.emailAddress}
                      </div>
                      <div className="contact-item">
                        <FontAwesomeIcon icon={faPhone} className="icn-cls" />
                        {jobApplication?.contactDetails?.phoneNumber}
                      </div>
                      <div className="contact-item">
                        <FontAwesomeIcon icon={faComment} className="icn-cls" />
                        <Link to="/messages">Chat with Candidate</Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {jobApplication?.answers?.length > 0 &&
        <Card className="mb-2 bg-white questions-answers d-none">
          <CardBody>
            <Row><h3 className="card-title">Applicant's Answers</h3></Row>
            {jobApplication?.answers && jobApplication?.answers.map((e, index) => (
              <div className="q-a" key={index}>
                <Row >
                  <Col className="card-text" lg={2} md={2} sm={2} xs={12}><b>Question:</b> </Col>
                  <Col className="card-text" lg={10} md={10} sm={10} xs={12}>{e?.question}?</Col>
                </Row>
                <Row className="mt-2">
                  <Col className="card-text" lg={2} md={2} sm={2} xs={12}><b>Answer:</b> </Col>
                  <Col className="card-text" lg={10} md={10} sm={10} xs={12}>{e?.answer}</Col>
                </Row>
              </div>
            ))
            }
          </CardBody>
        </Card>
      }

      <div className={`cov-letter-preview ${isOpenResume ? 'show' : ''}`}>
        <Fade className="mt-3">
          <Toast isOpen={isOpenResume}>
            <ToastHeader toggle={ResumeFunc}>View Resume</ToastHeader>
            <ToastBody style={{ height: "1000px" }}>
              <embed
                src={`https://docs.google.com/viewer?url=${viewDoc}&embedded=true`}
                style={{ width: "100%", height: "100%" }}
              />
            </ToastBody>
          </Toast>
        </Fade>
      </div>

      <Modal isOpen={isCoverLetterOpen} toggle={toggleCoverLetter}>
        <ModalHeader toggle={toggleCoverLetter}>View Cover Letter</ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: jobApplication?.coverLetter }}></div>
        </ModalBody>
      </Modal>

      <AlertDialog
        bodyText={"Are you sure, you want to Reject this candidate?"}
        toggle={toggleAlert}
        isOpen={isOpenAlert}
        handleClickOnYes={handleClickOnYes}
        yesLabel={"Yes"}
        noLabel={"No"}
      />
    </div >
  );
};

export default withAlert()(ApplicationDetails);
