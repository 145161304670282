// import React, { useState, useRef } from 'react'
// import { Tooltip } from 'reactstrap';

// const ToolTip = React.forwardRef((props, target) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const targetRef = useRef(null);

//   const handleToggle = () => {
//     if (targetRef.current) {
//       setIsOpen(!isOpen)
//     }
//   };
//   return (
//     <>
//       <span className={`tooltip_icon${props.customClass ? ' '+props.customClass : ''}`} id={props.id} ref={targetRef}></span>
//       <Tooltip className={`${props.customClass ? ' '+props.customClass+'-pops' : ''}`} placement={props.place} isOpen={isOpen}
//         target={targetRef} toggle={handleToggle} 
//       >
//         {props.children}
//       </Tooltip>
//     </>
//   )
// });

// export default ToolTip;
import React, { useRef } from 'react';
import { Tooltip } from 'reactstrap';

const ToolTip = React.forwardRef((props, ref) => {
  const targetRef = useRef(null);

  return (
    <>
      <span
        className={`tooltip_icon${props.customClass ? ' ' + props.customClass : ''}`}
        id={props.id}
        ref={targetRef}
      >
        {props.hoverTarget}
      </span>
      <Tooltip
        className={`${props.customClass ? ' ' + props.customClass + '-pops' : ''}`}
        placement={props.place}
        isOpen={props.isOpen}
        target={targetRef}
      >
        <span style={props.titleStyle}>{props.children}</span>
      </Tooltip>
    </>
  );
});

export default ToolTip;
