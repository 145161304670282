import React, { useState } from "react";
import { Field, Formik } from "formik";
import { Row, Col, Button, Spinner, Form } from "reactstrap";
import { withAlert } from "react-alert";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
const CustomThemeModalForm = (props) => {
  const { toggle, values, setFieldValue, isValid, dirty, setDropdownOpen, colortitle } = props;
  // console.log("-----------------CstThmMdlForm----------------------", props);
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");

  // const initialValue = { color0: values.color[0], color1: values.color[1], color2: values.color[2], color3: values.color[3], color4: values.color[4], color5: values.color[5], }

  const handleThemeColor = () => {
    // setFieldValue()
    // console.log("-----------------------handleThemeColor---------------------", values);
    setDropdownOpen(() => [{ label: "Custom Theme", color: [`${values.color0}`,`${values.color1}`,`${values.color2}`,`${values.color3}`,`${values.color4}`,`${values.color5}`] }])
    // setCurrentSelectedTheme((themeColors) => ({ ...themeColors, color: values.colortheme }))
    toggle();
  }

  // console.log("CustomThemeModalForm------------values.----------",values);

  return (
    <>
      {/* <Formik
        initialValues={initialValue}
        onSubmit={handleThemeColor}
        children={(props) => {
          return <Form>
            <div>
              <Row >
                <Col xs={8}>{colortitle[0]}</Col>
                <Col xs={4}>
                  <Field
                    name="color0"
                    type="color"
                    title={colortitle[0]}
                    component={FormikInputField}
                    placeholder={colortitle[0]}
                    // defaultValue={values.color[0]}
                    className='cthemecolor' />
                </Col>
              </Row>
              <Row >
                <Col xs={8}>{colortitle[2]}</Col>
                <Col xs={4}>
                  <Field
                    name="color1"
                    type="color"
                    title={colortitle[2]}
                    component={FormikInputField}
                    placeholder={colortitle[2]}
                    // defaultValue={values.color[1]}
                    className='cthemecolor' />
                </Col>
              </Row>
              <Row >
                <Col xs={8}>{colortitle[2]}</Col>
                <Col xs={4}>
                  <Field
                    name="color2"
                    type="color"
                    title={colortitle[2]}
                    component={FormikInputField}
                    placeholder={colortitle[2]}
                    // defaultValue={values.color[2]}
                    className='cthemecolor' />
                </Col>
              </Row>
              <Row >
                <Col xs={8}>{colortitle[3]}</Col>
                <Col xs={4}>
                  <Field
                    name="color3"
                    type="color"
                    title={colortitle[3]}
                    component={FormikInputField}
                    placeholder={colortitle[3]}
                    // defaultValue={values.color[3]}
                    className='cthemecolor' />
                </Col>
              </Row>
              <Row >
                <Col xs={8}>{colortitle[4]}</Col>
                <Col xs={4}>
                  <Field
                    name="color4"
                    type="color"
                    title={colortitle[4]}
                    component={FormikInputField}
                    placeholder={colortitle[4]}
                    // defaultValue={values.color[4]}
                    className='cthemecolor' />
                </Col>
              </Row>
              <Row >
                <Col xs={8}>{colortitle[5]}</Col>
                <Col xs={4}>
                  <Field
                    name="color5"
                    type="color"
                    title={colortitle[5]}
                    component={FormikInputField}
                    placeholder={colortitle[5]}
                    // defaultValue={values.color[5]}
                    className='cthemecolor' />
                </Col>
              </Row>
              <div className="justify-content-end  modal-footer mx-0">
                <Col lg="auto" className="action-group">
                  {isLoading ? (
                    <div className="loading-spiner">
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      <Button
                        className="nexogic_primary_button"
                        // disabled={!isValid || !dirty}
                        color="primary"
                        type="submit"
                        // onClick={(e) => handleThemeColor()}
                      >
                        Save
                      </Button>
                      <Button className="nexogic_primary_button_outline" onClick={toggle}>
                        Cancel
                      </Button>
                    </>
                  )}
                </Col>
              </div>
            </div>
          </Form>
        }}
      >
      </Formik> */}
      {/* {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />} */}
      <form className="my-form">
        <div>
          <Row >
            <Col xs={8}>{colortitle[0]}</Col>
            <Col xs={4}>
              <Field
                name="color0"
                type="color"
                title={colortitle[0]}
                component={FormikInputField}
                placeholder={colortitle[0]} 
                defaultValue={values.color0}               
                className='cthemecolor' />
            </Col>
          </Row>
          <Row >
            <Col xs={8}>{colortitle[2]}</Col>
            <Col xs={4}>
              <Field
                name="color1"
                type="color"
                title={colortitle[2]}
                component={FormikInputField}
                placeholder={colortitle[2]}
                defaultValue={values.color1}     
                className='cthemecolor' />
            </Col>
          </Row>
          <Row >
            <Col xs={8}>{colortitle[2]}</Col>
            <Col xs={4}>
              <Field
                name="color2"
                type="color"
                title={colortitle[2]}
                component={FormikInputField}
                placeholder={colortitle[2]}
                defaultValue={values.color2}
                className='cthemecolor' />
            </Col>
          </Row>
          <Row >
            <Col xs={8}>{colortitle[3]}</Col>
            <Col xs={4}>
              <Field
                name="color3"
                type="color"
                title={colortitle[3]}
                component={FormikInputField}
                placeholder={colortitle[3]}
                defaultValue={values.color3}
                className='cthemecolor' />
            </Col>
          </Row>
          <Row >
            <Col xs={8}>{colortitle[4]}</Col>
            <Col xs={4}>
              <Field
                name="color4"
                type="color"
                title={colortitle[4]}
                component={FormikInputField}
                placeholder={colortitle[4]}
                defaultValue={values.color4}
                className='cthemecolor' />
            </Col>
          </Row>
          <Row >
            <Col xs={8}>{colortitle[5]}</Col>
            <Col xs={4}>
              <Field
                name="color5"
                type="color"
                title={colortitle[5]}
                component={FormikInputField}
                placeholder={colortitle[5]}
                defaultValue={values.color5}
                className='cthemecolor' />
            </Col>
          </Row>
          <div className="justify-content-end  modal-footer mx-0">
            <Col lg="auto" className="action-group">
              {isLoading ? (
                <div className="loading-spiner">
                  <Spinner />
                </div>
              ) : (
                <>
                  <Button
                    className="nexogic_primary_button"
                    disabled={!isValid || !dirty}
                    color="primary"
                    type="button"
                    onClick={(e) => handleThemeColor()}
                  >
                    Save
                  </Button>
                  <Button className="nexogic_primary_button_outline" onClick={toggle}>
                    Cancel
                  </Button>
                </>
              )}
            </Col>
          </div>
        </div>
      </form>
    </>
  );
};
export default withAlert()(CustomThemeModalForm);
