import React from 'react'
import { Row, Col, Button, InputGroup, Label } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt} from '@fortawesome/free-solid-svg-icons'
import { Field } from 'formik'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import FormikInputField from "components/forms/formikFields/FormikInputField"

const PhoneNumberForm = (props) => {
  const { values, handleSubmit, setFieldValue, errors, touched, isValid, dirty, isSubmitting } = props
  
  return(
    <form onSubmit={handleSubmit}>
      <div>
        <Row>
          <Col xs={12} className="text-left">
              {/* <InputGroup>
                <Field
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel"
                  component={FormikInputField}
                  placeholder="Phone number with country code" />
              </InputGroup> */}
              <InputGroup size="medium" className="code-phone-ig">
                <Label>Mobile number</Label>
                <PhoneInput 
                  id="pnf_mobile_number"
                  country={"in"}
                  onlyCountries={['in']}
                  inputClass="w-100"
                  containerClass="mb-2"
                  inputProps={{
                    name: 'phoneNumber'
                  }}
                  onChange={(value, country, e, formattedValue) => setFieldValue('phoneNumber', formattedValue) }
                  isValid={(value, country) => (!!errors.phoneNumber === false || !touched.phoneNumber ? true : errors.phoneNumber)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Button id="pnf_btn_submit" disabled={!isValid || !dirty || isSubmitting} className="mt-3" color="primary" size="lg" block>Send</Button>
      </div>
    </form>
  )
}

export default PhoneNumberForm