import React from 'react'
import { Link } from "react-router-dom";
import location_icon from "assets/images/location-1.png";

export default function QuickPractitioner(props) {
  return (
    <>{props.name &&
      <div className={`nex-avatar-info nex-flex nex-quick-profile ${props.className}`}>
        {props.avatar &&
          <div className="graphic">
            {props.avatar}
          </div>
        }
        <div className="info w-full">
          <h2 className={`${props.titleClass || 'nex-text-lg'} stack-title bold mb-1 text-truncate`}>
            {props.name !== 'N/A' ? (
              props.isTitleLink ? (
                <Link to={props.titleLink} id={props.titleLinkId || ''}>
                  {props.name}
                </Link>
              ) : (
                <span>{props.name}</span>
              )
            ) : (
              <span>N/A</span>
            )}
          </h2>
          {/* {props.isTitleLink ? <Link to={props.titleLink} id={props.titleLinkId  || ''}>{props.name}</Link> : <span>{props.name}</span>}</h2> */}
          {props.speciality &&
            <p className="nex-text bold nex-text-blue text-truncate">{props.speciality}</p>
          }
          {props.address &&
            <p className="nex-text text-black text-truncate"><span className="icon"><img src={location_icon} alt="" /></span> {props.address}</p>
          }
          {props.children}
        </div>
      </div>
    }</>
  )
}
