import { Card } from "reactstrap";
import { encrypt } from "utils/EncryptDecrypt";
import { createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor, getGenderDetails, capitalizeFirstLetter } from "utils/Utils";
import { Link, useRouteMatch } from "react-router-dom";
import RefaralComment from "pages/Referals/Components/AddPatient/Components/RefaralComment";
import RefaralDocument from "pages/Referals/Components/AddPatient/Components/RefaralDocument";
import response_icon from 'assets/images/svgs/response-icon.svg';
import QuickPractitioner from "components/QuickPractitioner/QuickPractitioner";

const MyReferralCard = (props) => {
    const { referral } = props;

    return (
        <Card className="nexogic-card-list-custom nexogic-reffer-card nexogic-referals-new">
            <div className="card-body">
                <div className="row need-margin">
                    <div className="col Referring-physician-col">
                        <h3 className="nex-text-xl mb-3"> Receiving Physician </h3>
                        <div className="nex-stack">
                            <QuickPractitioner
                                isTitleLink={true}
                                titleLink={`/profile/` + referral?.referredByPhysician?.profileId}
                                titleLinkId="nex-view-prof-name4"
                                avatar={
                                    referral?.referredByPhysician?.avatarId ?
                                        <img
                                            src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredByPhysician?.profileGuid}/${referral?.referredByPhysician?.avatarId}`}
                                            className="nex-avatar lg"
                                            alt="#"
                                        />
                                        :
                                        <>
                                            <img
                                                src={createImageFromInitials(
                                                    50,
                                                    `${referral?.referredByPhysician?.name.replace("Dr. ", "")}`,
                                                    getRandomColor(
                                                        encrypt(
                                                            referral?.referredByPhysician?.profileGuid
                                                        ).toString()
                                                    )
                                                )}
                                                className="nex-avatar lg"
                                                alt="#"
                                            />
                                        </>
                                }
                                name={referral?.referredByPhysician?.name}
                                speciality={`${referral?.referredByPhysician?.primarySpeciality}${referral?.referredByPhysician?.superSpeciality ? ` ( ${referral?.referredByPhysician?.superSpeciality} ) ` : ''}`}
                                address={getFullAddressWithLocationCityAndState({ location: referral?.referredByPhysician?.location ?? "", city: referral?.referredByPhysician?.city ?? "", state: referral?.referredByPhysician?.state ?? "" })}
                            >
                            </QuickPractitioner>
                        </div>
                    </div>
                    <div className="col Referring-to-col">
                        <h3 className="nex-text-xl mb-3"> Receiving Physician </h3>
                        <div className="nex-stack">
                            <QuickPractitioner
                                isTitleLink={true}
                                titleLink={`/profile/` + referral?.referredToPhysician?.profileId}
                                titleLinkid="nex-view-prof-name2"
                                avatar={
                                    referral?.referredToPhysician?.avatarId ?
                                        <img
                                            src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredToPhysician?.profileGuid}/${referral?.referredToPhysician?.avatarId}`}
                                            className="nex-avatar lg"
                                            alt="#"
                                        />
                                        :
                                        <>
                                            <img
                                                src={createImageFromInitials(
                                                    50,
                                                    `${referral?.referredToPhysician?.name.replace("Dr. ", "")}`,
                                                    getRandomColor(
                                                        encrypt(
                                                            referral?.referredToPhysician?.profileGuid
                                                        ).toString()
                                                    )
                                                )}
                                                className="nex-avatar lg"
                                                alt="#"
                                            />
                                        </>
                                }
                                name={(referral?.referredToPhysician && referral?.referredToPhysician?.name) ? referral?.referredToPhysician?.name : 'N/A'}
                                speciality={`${referral?.referredToPhysician?.primarySpeciality}${referral?.referredToPhysician?.superSpeciality ? ` ( ${referral?.referredToPhysician?.superSpeciality} ) ` : ''}`}
                                address={getFullAddressWithLocationCityAndState({ location: referral?.referredToPhysician?.location ?? "", city: referral?.referredToPhysician?.city ?? "", state: referral?.referredToPhysician?.state ?? "" })}
                            ></QuickPractitioner>
                        </div>
                    </div>
                    <div className="col-2 col-status nexogic-status-right d-block mt-0 mr-3 text-right">
                        <span className={`rounded-pill ${referral.status.toLowerCase() === 'closed' ? 'closed ' : referral.status.toLowerCase() === 'accepted' ? 'accepted ' : referral.status.toLowerCase() === 'in_progress' ? 'inprogress ' : referral.status.toLowerCase() === 'submitted' ? 'submitted ' : referral.status.toLowerCase() === 'draft' ? 'draft ' : 'others'}`}>
                                        {referral?.status.toLowerCase().split("_").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
                                    </span>
                    </div>
                </div>

                <div className="row need-margin reason-sec">
                    <div className="col-lg-12">
                        {referral?.referralReason &&
                            <>
                                <hr />
                                <h3 className="card-title"> Reason:</h3>
                                <div className="card-text">
                                    <div dangerouslySetInnerHTML={{ __html: referral?.referralReason }}></div>
                                </div>
                            </>
                        }

                        <div className="card-text d-flex"><div className="ml-auto mt-2"> <Link to={`/referrals/my-referrals/${referral?.id}`} className="nex-btn-primary">Show Details</Link></div></div>
                    </div>
                </div>
                <div className="nexogic-meta-description">
                    {referral &&
                        <div className="row need-margin">
                            {(referral && referral?.responseId) ?
                                <div className="col-auto">
                                    <a href={`/referrals/my-response/${referral?.id}`} className="nex-link no-underline">
                                        <span className="icon" ><img width={28} src={response_icon} />
                                        </span>
                                        View Response
                                    </a>
                                </div>
                                :
                                <div className="col-auto">
                                    <span className="nex-link no-underline">
                                        <span className="icon" ><img width={28} src={response_icon} />
                                        </span>
                                        No Response
                                    </span>
                                </div>
                            }
                            {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                                <div className="col-auto ml-auto"><RefaralComment id={'ref_connemt_' + referral.id} /></div>
                            }
                            <div className="col-auto ml-auto"><RefaralDocument id={'ref_document_' + referral.id} referral={referral} /></div>
                        </div>
                    }
                </div>

            </div>
        </Card >
    );
}

export default MyReferralCard;