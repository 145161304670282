import moment from 'moment';
import React from 'react'

export default function WeekTimeCol() {
    const getCurrentDate = (startHour=9) => {
        const today = moment();
        today.hour(startHour);
        today.minute(0);
        return today;
      };

    const get15MinuteSlots = (datetime, slotInterval=15, hours=13) => {
        const slots = [];
        for (let i = 0; i < hours * 60; i += slotInterval) {
          slots.push(moment(datetime).add(i, "minutes"));
        }
        return slots;
      };

      const renderTimeSlots = (slots) => { 

        return (
            <>
                {slots.map((slot) => (
                    <div className="td" key={slot.format()}>{slot.format("hh:mm A")}</div>
                ))}
            </>
            
        );
      }
  return (
    <div className="nexogic-time-slot-w">
                                <div className="nexogic-time-slot">
                                    <div className="tr">
                                        <div className="th">
                                            TIME <span className='big'> 15MIN </span> Time Slot
                                        </div>
                                        {renderTimeSlots(get15MinuteSlots(getCurrentDate()))}
                                    </div>
                                </div>

                            </div>
  )
}

