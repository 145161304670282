import { Formik } from "formik";
import { useState } from "react";
import { withAlert } from "react-alert";
import { useSelector } from "react-redux";
import { FAILTOSAVERECOED, UserStatusConstants } from "utils/Constants";
import { RenderProfileFormFields } from "./RenderProfileFormFields";
import { PatientService } from "utils/PatientService";
import { IdentityService } from "utils/IdentityService";
import { useEffect } from "react";
import * as Yup from "yup";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),
    gender: Yup.string().required("This field is required"),
    height: Yup.number()
        .typeError("Height must be a number")
        .nullable()
        .positive("Height must be a positive number"),
    weight: Yup.number()
        .typeError("Weight must be a number")
        .nullable()
        .positive("Weight must be a positive number"),
    phoneNumber: Yup.string()
        .test("phone-number", "Invalid phone number", (value) => {
            const phoneRegex = /^[0-9]{10}$/; // Adjust the regex pattern based on your phone number format
            return phoneRegex.test(value);
        })
});


const RenderEditProfileForm = (props) => {

    const { patientProfile } = props;
    const [vmode, setVmode] = useState(null);
    const currentUser = useSelector((state) => state.auth.currentUser);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const {
        title,
        firstName,
        middleName,
        lastName,
        id,
        dateOfBirth,
        gender,
        languages,
        address,
        phoneNumber,
        emailAddress,
        bloodGroup,
        weight,
        height,
    } = patientProfile;

    const initialValues = {
        title,
        firstName,
        middleName,
        lastName,
        dateOfBirth,
        gender,
        languages,
        bloodGroup,
        height,
        weight,
        streetAddress1: address && address?.addressLine1
            ? address.addressLine1
            : "",
        streetAddress2: address && address?.addressLine2
            ? address.addressLine2
            : "",
        city: address && address?.city
            ? address.city.split(" ")
            : [],
        state: address && address?.state
            ? address.state.split(" ")
            : [],
        country: address && address?.country
            ? address.country.split(" ")
            : [],
        zipCode: address && address?.zipCode ? address?.zipCode : "",
        location: address && address?.location
            ? address.location.split(" ")
            : [],
        phoneNumber: phoneNumber,
        email: emailAddress,
        maritalStatus: "",
    };

    const getVerificationMode = (guid) => {
        IdentityService.GetUserDetailsByGuid(guid)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (response?.data?.mobileVerified) {
                        setVmode("PHONE");
                    }
                    if (response?.data?.emailVerified) {
                        setVmode("EMAIL");
                    }
                }
            })
            .catch((error) => {
                console.log("IdentityService.GetVerificationMode error", error);
            });
    }

    const updatePatientDetails = async (values) => {
        setIsSubmitting(true);
        let payload = {
            "title": values?.title ?? "",
            "firstName": values?.firstName ?? "",
            "middleName": values?.middleName ?? "",
            "lastName": values?.lastName ?? "",
            "gender": values?.gender ?? "",
            "dateOfBirth": values?.dateOfBirth
                ? new Date(values.dateOfBirth).toISOString().split("T")[0]
                : "",
            "height": values?.height ?? "",
            "weight": values?.weight ?? "",
            "bloodGroup": values?.bloodGroup ?? "",
            "languages": values?.languages && values?.languages.length > 0
                ? values?.languages
                : [],


            "phoneNumber": values?.phoneNumber,
            "emailAddress": values?.email,
            "address": {
                "addressLine1": values?.streetAddress1,
                "addressLine2": values?.streetAddress2,
                "city": values?.city && values?.city.length > 0 ? values?.city[0].value : "",
                "state": values?.state && values?.state.length > 0 ? values?.state[0].value : "",
                "zipCode": values?.zipCode,
                "country": values?.country && values?.country.length > 0 ? values?.country[0].value : "",
            }
        }


        PatientService.updatePatient(payload, currentUser?.profileId)
            .then((response) => {
                const { status } = response;
                if (status === 200) {
                    showSuccessMessage("Profile updated Successfully");
                    setIsSubmitting(false);
                }
                else {
                    console.log(FAILTOSAVERECOED);
                    setIsSubmitting(false);
                }
            })
    };

    // const updateStorage = (guid) => {
    //     PractitionerService.gePractitionersDetails(guid).then((response) => {
    //         if (response.status === 200 || response.status === 201) {
    //             dispatch(setGlobalprofile(response.data));
    //         }
    //         toggle();
    //     });
    // };

    useEffect(() => {
        getVerificationMode(currentUser?.guid);
    }, [currentUser?.guid])

    const toggle = () => { }
    return (
        <>

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={updatePatientDetails}
                children={(props) => {
                    return (
                        <>
                            <RenderProfileFormFields {...props} toggle={toggle} vmode={vmode} isSubmitting={isSubmitting}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                            />
                        </>
                    );
                }}
            />
        </>
    );
};

export default withAlert()(RenderEditProfileForm);
