import {
  faCheckCircle,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { Link, useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { IdentityService } from "utils/IdentityService";
import logo from "assets/images/logo-white.png";
import verifyMobileImg from 'assets/images/otp-flow/verifyMobile.svg'

const validationSchema = Yup.object({
  password: Yup.string()
    .min(8)
    .max(32)
    .required("This field is required")
    .matches(
      /^(?=(.*[a-z]){3,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,}).{8,}$/,
      "Too weak. Try to add lower-case, upper-case, numbers, special characters, 8 or more characters"
    )
    .matches(
      /(?=(.*[!@#$%^&*()\-__+.]){1,})/,
      "Moderate. Try to add aleast 1 special characters or symbols"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const ResetPasswordForm = (props) => {
  const history = useHistory();
  const [isValidToken, setIsValidToken] = useState(null);
  const [isSubmited, onChangeSubmited] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showPassword, onShowPassword] = useState(false);

  useEffect(() => {
    // validate token first
    IdentityService.VerifyForgotPasswordToken(props.token).then((response) => {
      if (response.status === 200) {
        setIsValidToken(true);
      } else {
        setIsValidToken(false);
      }
    });
  }, []);

  const toggleShowPassword = () => onShowPassword(!showPassword);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await IdentityService.CompleteForgotPassword(
          props.token,
          values
        );
        if (response.status === 200) {
          formik.resetForm();
          onChangeSubmited(true);
          props.alert.success("Password reset successfully.");
          history.push("/login");
        } else {
          props.alert.error(
            response.message || "Failed to reset password!. Please try again"
          );
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        props.alert.error(
          error || "Failed to reset password!. Please try again"
        );
        setLoading(false);
      }
    },
    validationSchema,
  });

  const onHandleCancel = () => {
    history.push("/");
  };

  return (
    <>
      {!!isValidToken === false ? (
        <div className="text-center">
          {isValidToken === null ? (
            <>
              <div className="loading-spinner">
                <Spinner style={{ width: "2rem", height: "2rem" }} />{" "}
              </div>
              ...Verifying
            </>
          ) : (
            <div className="nexogic-login-register-card card border-0 bg-transparent">
              <Row className="">
                <Col md={6} lg={7} xs={12} className="nexogic-form-side-content text-center">
                  <div className="nexogic-form-info">
                    <p><img src={logo} alt="" className="nexogic-filter-white" /></p>
                    {/*<p className="card-text text-white mb-5">Sign in or create an account</p>*/}
                    <img src={verifyMobileImg} className="img-fluid" alt="nexogic" />
                  </div>
                </Col>
                <Col md={6} lg={5} xs={12} className="nexogic-form-content card-body p-0">
                  <div className="nexogic-form-container">
                  <h4 className="nexogic-form-title mb-2">Sorry, Your link is expired.</h4>
                    <p className="nexogic-form-title-sub">Please click the below link to generate new password:</p>
                    <Link to="/forgot-password" className="nexogic_primary_button btn-block">Click here</Link>
                  </div>
                </Col>
              </Row>
              {/** 
              <Container>
                <h2 className="bg-white border-0 font-weight-bold">
                  Sorry, Your link is expired,
                  <br /> Please click the below link to generate new password:
                </h2>
                
              </Container>
              */}
            </div>
          )}
        </div>
      ) : (
        <div className="nexogic-login-register-card card border-0 bg-transparent">
          <Row id="resetPass" className="">
            <Col md={6} lg={7} xs={12} className="nexogic-form-side-content text-center">
              <div className="nexogic-form-info">
                <p><img src={logo} alt="" className="nexogic-filter-white" /></p>
                {/*<p className="card-text text-white mb-5">Sign in or create an account</p>*/}
                <img src={verifyMobileImg} className="img-fluid" alt="nexogic" />
              </div>
            </Col>
            <Col md={6} lg={5} xs={12} className="nexogic-form-content card-body p-0">
              <div className="nexogic-form-container">
                <h4 className="nexogic-form-title">
                  Create a new password
                </h4>
                <form onSubmit={formik.handleSubmit}>
                  <InputGroup className="mt-5">
                    <div className="nexogic-password-field-wrap">
                      <FormGroup className="flex-grow-1">
                        <Label htmlFor="password">New Password</Label>
                        <Input
                          id="password"
                          name="password"
                          type={showPassword ? "text " : "password"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                          placeholder="New Password"
                          className={
                            formik.errors.password && formik.touched.password
                              ? "error is-invalid "
                              : "form-control"
                          }
                        />
                        {!!formik.errors.password === false && (
                          <FontAwesomeIcon
                            onClick={toggleShowPassword}
                            className="show-password "
                            color="#ccc"
                            icon={!showPassword ? faEyeSlash : faEye}
                          />
                        )}
                        {formik.errors.password && formik.touched.password ? (
                          <div className="invalid-feedback">
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </InputGroup>
                  <InputGroup className="mt-3">
                    <FormGroup className="flex-grow-1">
                      <Label htmlFor="confirmPassword">Confirm Password</Label>
                      <Input
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                        placeholder="Confirm Password"
                        className={
                          formik.errors.confirmPassword &&
                            formik.touched.confirmPassword
                            ? "error is-invalid "
                            : "form-control"
                        }
                      />
                      {formik.errors.confirmPassword &&
                        formik.touched.confirmPassword ? (
                        <div className="invalid-feedback">
                          {formik.errors.confirmPassword}
                        </div>
                      ) : null}
                    </FormGroup>
                  </InputGroup>
                  {isSubmited && (
                    <Alert color="success mt-3 text-dark bg-green">
                      <FontAwesomeIcon
                        className="text-success"
                        size="lg"
                        icon={faCheckCircle}
                      />{" "}
                      Password changed, please enjoy!
                    </Alert>
                  )}
                  <Row>
                  <Col className="col-lg-12">
                      {isLoading ? (
                        <div className="loading-spinner">
                          <Spinner style={{ width: "2rem", height: "2rem" }} />
                        </div>
                      ) : (
                        <Button
                          type="submit"
                          disabled={!formik.isValid || !formik.dirty}
                          className="mt-3 nexogic_primary_button btn-block"
                          color="primary"
                          size="lg"
                          block
                        >
                          Change password
                        </Button>
                      )}
                    </Col>
                    <Col className="col-lg-12">
                      <Button
                        className="mt-3 nexogic_primary_button_outline btn-block"
                        color="primary"
                        size="lg"
                        block
                        type="button"
                        onClick={onHandleCancel}

                      >
                        Cancel
                      </Button>
                    </Col>
                    
                  </Row>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default withAlert()(ResetPasswordForm);
