import React from 'react'
import hospital_logo from '../../../../../assets/images/hospital-icon-ortho.png'
import HtmlStringToPdf from "./HtmlStringToPdf";
import ChartsComponent from 'common/ChartsComponent';
import { useState } from 'react';
import { ReferralService } from 'utils/ReferralService';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';
export default function EmailPreview() {
    let { id } = useParams();
    const [myReferralDetails, setMyReferralDetails] = useState({});

    const history = useHistory();
    const handleBack = () => {
        history.goBack()
    }

    const getMyReferral = (id) => {
        ReferralService.getMyReferralById(id)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    // console.log("data", data);
                    setMyReferralDetails(data);
                    // setIsLoading(false);
                } else if (status === 400 || status === 404) {
                    handleBack();
                }
                else {
                    console.log("Error");
                }
            })
    }

    useEffect(() => {
        getMyReferral(id);
        // getReferralResponse(id);
        // getDocsInfo();
    }, [id])

    return (
        <>
            <ChartsComponent />
            <HtmlStringToPdf target="#nex_email_preview_pdf" />
            <div className="pdf-warap" id="nex_email_preview_pdf">

                <div className="pdf-header">
                    <div className="pdf-row">
                        <div className="pdf-col-half">
                            <h1 className="logo-text"><img src={hospital_logo} alt="" /> Penn Hospital[S]</h1>
                        </div>
                        <div className="pdf-col-half address">
                            <p>www.pennmedicine.org[S]</p>
                            <p>+44 1902 444141[S]</p>
                            <p>98, HAL Airport Road, Philadelphia[S]</p>
                            <p>Pennsylvania,19093[S] </p>
                        </div>
                    </div>

                </div>


                <div className="pdf-sec">
                    <h2 className="pdf-title">Essential information</h2>
                    <div className="pdf-row">
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">Doctor name to whom refer</h3>
                            <p className="pdf-val">{myReferralDetails?.referredToPhysician?.name}</p>
                        </div>
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">Hospital to which refer</h3>
                            <p className="pdf-val">{myReferralDetails?.referredToInstitution?.name}</p>
                        </div>
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">Patient name </h3>
                            <p className="pdf-val">{myReferralDetails?.patient?.firstName}{" "}{myReferralDetails?.patient?.middleName}{" "}{myReferralDetails?.patient?.lastName}</p>
                        </div>
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">Patient type</h3>
                            <p className="pdf-val">{myReferralDetails?.patientType}</p>
                        </div>
                    </div>
                </div>

                {/** row 1 */}
                <div className="pdf-sec">
                    <h2 className="pdf-title">Patient information</h2>
                    <div className="pdf-row">
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">Name </h3>
                            <p className="pdf-val">{myReferralDetails?.patient?.firstName}{" "}{myReferralDetails?.patient?.middleName}{" "}{myReferralDetails?.patient?.lastName}</p>
                        </div>
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">Gender </h3>
                            <p className="pdf-val">{myReferralDetails?.patient?.gender}</p>
                        </div>
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">Age </h3>
                            <p className="pdf-val">{myReferralDetails?.patient?.dateOfBirth}</p>
                        </div>
                    </div>
                </div>

                {/** row 2 */}
                <div className="pdf-sec">
                    <h2 className="pdf-title">Referring physician information</h2>
                    <div className="pdf-row">
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">Physician name </h3>
                            <p className="pdf-val">{myReferralDetails?.referredByPhysician?.name}</p>
                        </div>
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">Date(requested on) </h3>
                            <p className="pdf-val">{"2023-08-01"}</p>
                        </div>
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">Practice name</h3>
                            <p className="pdf-val">{myReferralDetails?.referredByPhysician?.primarySpeciality}</p>
                        </div>

                        <div className="pdf-col-half">
                            <h3 className="pdf-label">City</h3>
                            <p className="pdf-val">Philadelphia[S]</p>
                        </div>

                        <div className="pdf-col-half">
                            <h3 className="pdf-label">State (Required for Domestic Patient)</h3>
                            <p className="pdf-val">Pennsylvania[S]</p>
                        </div>
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">ZIP code (Required for Domestic Patient)</h3>
                            <p className="pdf-val">19093[S]</p>
                        </div>
                    </div>
                </div>

                {/** row 3 */}
                <div className="pdf-sec">
                    <h2 className="pdf-title">Referring Institution information</h2>
                    <div className="pdf-row">
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">Institution name </h3>
                            <p className="pdf-val">{myReferralDetails?.referredByInstitution?.name}</p>
                        </div>
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">Location</h3>
                            <p className="pdf-val">{myReferralDetails?.referredByInstitution?.address?.location}</p>
                        </div>
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">City</h3>
                            <p className="pdf-val">{myReferralDetails?.referredByInstitution?.address?.city}</p>
                        </div>
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">State</h3>
                            <p className="pdf-val">{myReferralDetails?.referredByInstitution?.address?.state}</p>
                        </div>
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">ZIP code</h3>
                            <p className="pdf-val"><p className="pdf-val">{"243432[S]"}</p></p>
                        </div>
                    </div>
                </div>

                 {/** row 3 */}
                 <div className="pdf-sec">
                    <h2 className="pdf-title">Receiving physician information</h2>
                    <div className="pdf-row">
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">Physician name </h3>
                            <p className="pdf-val">{myReferralDetails?.referredToPhysician?.name}</p>
                        </div>
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">Date(requested on) </h3>
                            <p className="pdf-val">{"2023-08-01"}</p>
                        </div>
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">Practice name</h3>
                            <p className="pdf-val">{myReferralDetails?.referredToPhysician?.primarySpeciality}</p>
                        </div>

                        <div className="pdf-col-half">
                            <h3 className="pdf-label">City</h3>
                            <p className="pdf-val">Philadelphia[S]</p>
                        </div>

                        <div className="pdf-col-half">
                            <h3 className="pdf-label">State (Required for Domestic Patient)</h3>
                            <p className="pdf-val">Pennsylvania[S]</p>
                        </div>
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">ZIP code (Required for Domestic Patient)</h3>
                            <p className="pdf-val">19093[S]</p>
                        </div>
                    </div>
                </div>

                <div className="pdf-sec">
                    <h2 className="pdf-title">Receiving Institution information</h2>
                    <div className="pdf-row">
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">Institution name </h3>
                            <p className="pdf-val">{myReferralDetails?.referredToInstitution?.name}</p>
                        </div>
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">Location</h3>
                            <p className="pdf-val">{myReferralDetails?.referredToInstitution?.address?.location}</p>
                        </div>
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">City</h3>
                            <p className="pdf-val">{myReferralDetails?.referredToInstitution?.address?.city}</p>
                        </div>
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">State</h3>
                            <p className="pdf-val">{myReferralDetails?.referredToInstitution?.address?.state}</p>
                        </div>
                        <div className="pdf-col-half">
                            <h3 className="pdf-label">ZIP code</h3>
                            <p className="pdf-val"><p className="pdf-val">{"243432[S]"}</p></p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
