import React, { useEffect, useState, useRef } from "react"
import { Label } from 'reactstrap'
import { ErrorMessage } from 'formik';
import { Typeahead } from 'react-bootstrap-typeahead';
import { isEmpty } from "lodash"

import 'assets/styles/typeahead.css'

const CommonMultipleSelectTypeaheadField = (props) => {
    const { disabled, inputProps, placeholder, className, getSelectedValue, allowNew} = props
    const { name, value } = props.field
    const { setFieldValue, setFieldTouched, touched, errors } = props.form
    const [selectedValue, setSelectedValue] = useState("");
    const [allowNewFlag, setAllowNewFlag] = useState(false);
    const typeaRef = useRef();
   
    useEffect(() => {
        if(allowNew === true){
            setAllowNewFlag(true)
        }
    },[allowNew])

    const handleChange = (selected) => {
        setFieldValue(name, selected, true)
        if(getSelectedValue != undefined){
            if(selected !=undefined && selected.length > 0){
                setSelectedValue(selected[0]);
            }else{
                setSelectedValue("");
                getSelectedValue("");
            }
        }
        if(props.onChange){
            props.onChange(selected);
        }
    }

    const setOnBlur = (name, flag) => {
        setFieldTouched(name, flag)
        if(getSelectedValue != undefined){
            getSelectedValue(selectedValue);
        }
        typeaRef.current.hideMenu();
    }

    return (
        <>
            {props.label && <Label htmlFor={name} className="d-block skills">{props.label}</Label>}
            <Typeahead
                ref={typeaRef}
                className={className}
                disabled={disabled}
                clearButton
                selected={value}
                id={name}
                options= {props.inputOptions}
                labelKey={"value"}
                placeholder={placeholder}
                multiple={true}
                allowNew={allowNewFlag}
                onChange={handleChange}
                onBlur={(e) => setOnBlur(name, true)}
                isInvalid={Boolean(touched[name] && errors[name])}
            />
            <ErrorMessage name={name} render={msg => <span className="text-danger skill-err f-12 d-inline-block  mt-1 line-height-error">{msg}</span>} />
        </>
    )
}
export default CommonMultipleSelectTypeaheadField