import { Modal, ModalBody, ModalHeader } from "reactstrap";
import EducationForm from "./EducationForm";

export const RenderEducationModal = (props) => {
  const { isOpen, toggle } = props;

  return (
    <div>
      <Modal
        md
        isOpen={isOpen}
        toggle={toggle}
        id="profile-modal"
        className="modal-dialog-scrollable modal-dialog-centered"
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>Education</ModalHeader>
        <ModalBody>
          <EducationForm {...props} />
        </ModalBody>
      </Modal>
    </div>
  );
};
