import React, { useEffect, useState } from "react"
import { Label } from 'reactstrap'
import { ErrorMessage } from 'formik';
import { Typeahead } from 'react-bootstrap-typeahead';
import { map } from "lodash"

import { CommonServices } from 'utils/CommonServices'
import 'assets/styles/typeahead.css'


const SkiilsTypeaheadField = (props) => {
    const { disabled, inputProps, placeholder, className } = props
    const { name, value } = props.field
    const { setFieldValue, setFieldTouched, touched, errors } = props.form
    const [skillsData, setData] = useState([]);

    const getData = async () => {
        try {
            // const response = await API.get("/skills/");
            CommonServices.getPrimarySpecialities()
                .then(response => {
                    const { data, status, message } = response.data
                    if (status === "SUCCESS") {
                        setData(data.skill)
                    } else {
                        if (props.alert)
                            props.alert.error(message)
                    }
                }, e => {
                    if (props.alert)
                        props.alert.error(e.message)
                })
        }
        catch (e) {
            if (props.alert)
                props.alert.error(e.message)
        }
    }
    const handleChange = (selected) => {
        // const value = (selected.length > 0) ? selected[0] : '';
        setFieldValue(name, selected, true)
    }
    useEffect(() => {
        getData()
    }, []);

    return (
        <>
            {props.label && <Label htmlFor={name} className="d-block skills">{props.label}</Label>}
            <Typeahead
                ref={inputProps && inputProps.ref ? inputProps.ref : null}
                className={className}
                disabled={disabled}
                clearButton
                selected={value}
                id={name}
                options={skillsData}
                labelKey={"name"}
                placeholder={placeholder}
                // onInputChange={(text, event) => {
                //     setFieldValue(name, text)
                // }}
                onChange={handleChange}
                onBlur={(e) => setFieldTouched(name, true)}
                isInvalid={Boolean(touched[name] && errors[name])}
            />
            <ErrorMessage name={name} render={msg => <span className="text-danger skill-err f-12 d-inline-block  mt-1 line-height-error">{msg}</span>} />
        </>
    )
}
export default SkiilsTypeaheadField