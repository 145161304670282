
import { useState, useMemo } from "react";
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import logo_white from "assets/images/logo-white-transparent.png";
import arrow_back from "assets/images/nex-arrow-back.svg";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Formik, Form, Field } from 'formik'
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikMobileField from "components/forms/formikFields/FormikMobileField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { titles, User_Types, onlyAlphabets, emailNonNumericRegEx } from 'utils/Constants'
import * as Yup from "yup";
import DOBFormatter from "components/DOBFormatter";
import { IdentityService } from 'utils/IdentityService'
import { Button } from "reactstrap";
import { setOAuthTokens, setOAuthUser, setUser, updateAccessToken } from 'utils/UserHelper'
import { authSetUser } from 'redux-helpers/ActionCreator';
import { useRef } from "react";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import Swal from "sweetalert2";
import ReactDOMServer from 'react-dom/server';

const phoneRegExp =
    /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{5})(( x| ext)\d{1,5}){0,1}$/
const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2)
        .max(30)
        .matches(onlyAlphabets, 'Only Alphabets')
        .required('This field is required'),
    lastName: Yup.string()
        .min(2)
        .max(30)
        .matches(onlyAlphabets, 'Only Alphabets')
        .required('This field is required'),
    phone: Yup.string()
        .matches(phoneRegExp, 'Mobile number is not valid')
        .required('This field is required'),
    email: Yup.string()
        .max(50)
        .matches(emailNonNumericRegEx, 'Email id is not valid')
        .required('This field is required'),
})

const PatientRegisterAccount = (props) => {
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch();
    const isComponentMounted = useRef(true);
    const { firstName, lastName, email, phoneNumber, token, refreshToken, utype, provider } = location.state || {}
    const initValues = {
        title: !utype ? titles[0].value : titles[1].value,
        firstName: firstName || '',
        lastName: lastName || '',
        phone: phoneNumber || '',
        email: email || '',
    }

    const [initialValues, setInitialValues] = useState(initValues)
    const [errorMessage, setErrorMessage] = useState(null)

    useEffect(() => {
        setErrorMessage(errorMessage)
        setTimeout(() => {
            setErrorMessage('')
        }, 5000)
    }, [errorMessage])

    useEffect(() => {
        return () => {
            isComponentMounted.current = false;
        };
    }, []);

    const handleSubmit = async (values, { setSubmitting }) => {
        const payload = {
            salutationCode: values.title,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phoneNumber: values.phone,
            type: utype ? 'PATIENT' : 'PRACTITIONER'
        }
        const payload_refrshToken = {
            refresh_token: refreshToken,
            grant_type: "refresh_token"
        }
        setSubmitting(true);
        try {
            if (provider === 'phone') {
                IdentityService.AddUserDetails(token, payload)
                    .then(async response => {
                        if (response.status === 200 || response.status === 201) {
                            const content = (
                                <div className='d-flex flex-column'>
                                    <h2 className='text-success'>Profile Created Successfully..</h2>
                                </div>
                            );
                            const { isConfirmed } = await Swal.fire({
                                title: ' ',
                                html: ReactDOMServer.renderToString(content),
                                icon: 'success',
                                showCancelButton: false,
                                // cancelButtonText: 'Cancel',
                                confirmButtonText: 'Proceed',
                            });

                            if (isConfirmed) {
                                setSubmitting(false);
                                history.push(`/login`);
                            }
                            // showSuccessMessage("Profile created successfully..")
                        } else if (response.status === 409) {
                            showErrorMessage("User Already Exists..Please Sign In...")
                            setErrorMessage(`User Already Exists`);
                            throw new Error('User Already Exists');
                        } else if (response.message) {
                            setErrorMessage(response.message);
                            throw new Error(response.message);
                        } else {
                            throw new Error('Unhandled API response status');
                        }
                    })
            } else {
                IdentityService.AddUserDetails(token, payload)
                    .then(response => {
                        if (response.status === 200 || response.status === 201) {
                            return IdentityService.RefreshUserToken(payload_refrshToken).then(secondApiResponse => {
                                return { response, secondApiResponse };
                            });
                        } else if (response.status === 409) {
                            showErrorMessage("User Already Exists..Please Sign In...")
                            setErrorMessage(`User Already Exists`);
                            throw new Error('User Already Exists');
                        } else if (response.message) {
                            setErrorMessage(response.message);
                            throw new Error(response.message);
                        } else {
                            throw new Error('Unhandled API response status');
                        }
                    })
                    .then(refreshResponse => {
                        if (refreshResponse.secondApiResponse.status === 200 || refreshResponse.secondApiResponse.status === 201) {
                            const { response, secondApiResponse } = refreshResponse;
                            updateAccessToken(secondApiResponse.data?.access_token);
                            setOAuthTokens(secondApiResponse.data?.access_token, secondApiResponse.data?.refresh_token);
                            IdentityService.GetUserDetailsByGuid(response.data.id)
                                .then(userdetailsResponse => {
                                    if (userdetailsResponse.status === 200 || userdetailsResponse.status === 201) {
                                        const { data } = userdetailsResponse;
                                        setOAuthUser(data);
                                        setUser(data);
                                        const { types } = data;
                                        if (types.includes("PATIENT")) {
                                            dispatch(authSetUser(data));
                                            history.push(`/home`);
                                            setSubmitting(false);
                                        }
                                        setErrorMessage('');
                                    } else {
                                        setErrorMessage('Failed to Refresh Registration Info');
                                        throw new Error('Failed to refresh Registration Info');
                                    }
                                })
                        } else {
                            setErrorMessage('Failed to Refresh Registration Info');
                            throw new Error('Failed to refresh Registration Info');
                        }
                    })
                    .catch(error => {
                        console.error('API call error:', error);
                        setErrorMessage(error.message || 'Something went wrong.....!');
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }
        } catch (error) {
            console.error('Caught synchronous error in handleSubmit:', error);
            setSubmitting(false);
        }
    }

    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <div className="nex-bg-light-blue">
            <main className="nex-login-register--w">
                <div className="container sm d-flex" data-aos="fade-up">
                    <div className="nex-form-aside">
                        <div className="logo" data-aos="fade-up" data-aos-duration="800" data-aos-delay="100">
                            <a href="/"><img src={logo_white} alt="" /></a>
                        </div>

                        <h2 className="nex-text-xxl" data-aos="fade-up" data-aos-duration="800" data-aos-delay="150">Hey, <br />
                            Create an account</h2>
                        <p className="nex-text-lg nex-text-light" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">Register your details to continue
                            with us</p>
                    </div>
                    <div className="nex-form-fields--w">
                        <div className="nex-form-top-slot nex-mob-hide" data-aos="fade-down" data-aos-duration="800" data-aos-delay="200">
                            <div className="nex-form-top-actions nex-text-lg">
                                <Link to={'/u/register'} className="text-gray nex-back-btn"><img src={arrow_back} alt="" /></Link>
                            </div>
                        </div>
                        <div className="nex-form-center-slot">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                // onSubmit={handleSubmit}
                                onSubmit={(values, { setSubmitting }) => {
                                    handleSubmit(values, { setSubmitting })
                                }}>
                                {({ isSubmitting }) => (
                                    <Form className='personal-details-form'>
                                        <div className="nex-form-container">
                                            <div className="form-group-fields--w" data-aos="fade-down" data-aos-duration="800" data-aos-delay="250">
                                                <div className="form-group">
                                                    <Field
                                                        id='title'
                                                        name='title'
                                                        type='text'
                                                        label=''
                                                        className='p55'
                                                        component={FormikSelectField}
                                                        inputprops={{
                                                            name: 'title',
                                                            options: titles,
                                                            keys: {
                                                                id: 'value',
                                                                label: 'label',
                                                            },
                                                        }}
                                                        // disabled={!iseditable}
                                                        autoComplete='titles'
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <Field
                                                        id='firstName'
                                                        name='firstName'
                                                        autoComplete='firstName'
                                                        type='text'
                                                        component={FormikInputField}
                                                        label=''
                                                        placeholder='Your first name'
                                                    // disabled={!iseditable}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <Field
                                                        id='lastName'
                                                        name='lastName'
                                                        autoComplete='lastName'
                                                        type='text'
                                                        component={FormikInputField}
                                                        label=''
                                                        placeholder='Your last name'
                                                    // disabled={!iseditable}
                                                    />
                                                </div>
                                                <div className="form-group"></div>
                                                <Field
                                                    id='email'
                                                    name='email'
                                                    autoComplete='email'
                                                    type='text'
                                                    component={FormikInputField}
                                                    label=''
                                                    placeholder='Your Email'
                                                    disabled={email !== null ? true : false}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <Field
                                                    id='mnf_phon'
                                                    name='phone'
                                                    autoComplete='phone'
                                                    type='text'
                                                    component={FormikMobileField}
                                                    label=''
                                                    placeholder='Your Phone Number'
                                                    value={phoneNumber}
                                                    disabled={phoneNumber === null ? false : true}
                                                />
                                                {/* </InputGroup> */}
                                                {/* </div> */}
                                            </div>
                                        </div>
                                        {errorMessage ? <div className="card-text text-danger m-auto col-lg-12">{errorMessage}</div> : null}
                                        <div className="form-group">
                                            <Button
                                                id="btn_sign_in"
                                                className="btn btn-primary w-100"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Continue
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default PatientRegisterAccount;