import React from "react";
import { Col, Row } from "reactstrap";

import ApptBlock from "./ApptBlock";
import { AppointmentType } from "utils/Constants"
import moment from "moment";


// function custom_sort(a, b) {
//   return new Date(a.appointmentDate).getTime() - new Date(b.appointmentDate).getTime();
// }

const DaySchedule = (props) => {
  let timedData = [];
  props.dtInts.map((dtInt) => {
    let tempObj = { ...dtInt, 'consultations': [], [AppointmentType.LAB]: [] };

    props.selectedDay.consultations.map((item) => {
      const appointmentTime = moment.utc(item.appointmentTime, "HH:mm:ss").local()
      if (appointmentTime.hours() === dtInt.hours) {
        tempObj.consultations.push(item);
      }
    });
    // tempObj.consultations.sort(custom_sort);

    props.selectedDay[AppointmentType.LAB].map((item) => {
      const appointmentTime = moment.utc(item.appointmentTime, "HH:mm:ss").local()
      if (appointmentTime.hours() === dtInt.hours) {
        tempObj[AppointmentType.LAB].push(item);
      }
    });
    //  tempObj[AppointmentType.LAB].sort(custom_sort);
    timedData.push(tempObj);
  }, props);

  return (
    <>
      {timedData.map((data, index) => {
        let timeFormat_12 = moment(data.label, "HH:mm:ss A").format("hh:mm A");
        return (<Row key={index} className="appnt-time-row">
          <Col md={1} className="pl-0 pr-3">
            <div className="day-time ">{timeFormat_12}</div>
          </Col>
          <Col md={11} className="pr-4">
            <div className="appt-block">

              {data.consultations.map((apptData, index) =>
                <ApptBlock key={apptData.appointmentReference} index={index} apptData={apptData} apptType="type-consult" label={'Consultations'} getAppts={props.getAppts} />
              )}

              {data[AppointmentType.LAB].map((apptData, index) =>
                <ApptBlock key={apptData.appointmentReference} index={index} apptData={apptData} apptType="type-lab" label="Lab" getAppts={props.getAppts} />
              )}
            </div>
          </Col>
        </Row>)
      })}
    </>
  );
};

export default DaySchedule;
