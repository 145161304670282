  
import React from 'react';
import {Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const Paginations = ({postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  
  const getPageCount = (e, number) => {  
    paginate(number) 
  }
   
  return (
    <nav>
      <Pagination aria-label="Page navigation example">
        {pageNumbers.map(number => ( 
        <PaginationItem key={number} className={`${currentPage===number ? `active`: ``}`}>
            <PaginationLink key={number} onClick={(e) => getPageCount(e, number)}>{number}</PaginationLink>
        </PaginationItem>
        ))}
      </Pagination>
    </nav>
  );
};

export default Paginations;