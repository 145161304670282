import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import FormikCheckboxField from "components/forms/formikFields/FormikCheckboxField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikInputMonthYearFormatField from "components/forms/formikFields/FormikInputMonthYearFormatField";
import { Field, Formik } from "formik";
import { useState } from "react";
import { withAlert } from "react-alert";
import { useSelector } from "react-redux";
import { Button, Col, Label, Row, Spinner } from "reactstrap";
import { FAILTOSAVERECOED } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import { getAddressName, getLocationName } from "utils/Utils";
import * as Yup from "yup";
import CertificationInstitutesTypeaheadField from "./CertificationInstitutesTypeaheadField";
import NewInstituitionSection from "./NewInstituitionSection";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
export const yearmonth = /^(0[1-9]|10|11|12)-\d{4}$/;

const validationSchema = Yup.object().shape({
  institutionDetail: Yup.lazy((value) => {
    if (value && value.onSelectNewinstitution === 0) {
      return Yup.object().test(
        "",
        "This field is required. You must select from options or add new",
        () => false
      );
    } else if (value && value.onSelectNewinstitution === 1) {
      return Yup.object();
    } else if (value && value.onSelectNewinstitution === 2) {
      return Yup.object();
    } else {
      return Yup.mixed().test(
        "",
        "This field is required. You must select from options or add new",
        (value) => value === {}
      );
    }
  }),
  city: Yup.array().when(["institutionDetail.onSelectNewinstitution"], {
    is: (item1) => item1 === 2,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required"),
  }),
  state: Yup.array().when(["institutionDetail.onSelectNewinstitution"], {
    is: (item1) => item1 === 2,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required"),
  }),
  country: Yup.array().when(["institutionDetail.onSelectNewinstitution"], {
    is: (item1) => item1 === 2,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required"),
  }),
  location: Yup.array().when(["institutionDetail.onSelectNewinstitution"], {
    is: (item1) => item1 === 2,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required"),
  }),
  mainItemName: Yup.string().when("institutionDetail.onSelectNewinstitution", {
    is: 2,
    then: Yup.string().required("This field is required"),
  }),
  name: Yup.string().required("This field is required"),
  certificationNumber: Yup.string(),
  category: Yup.string().when(["institutionDetail.onSelectNewinstitution"], {
    is: (item1) => item1 === 2,
    then: Yup.string().required("This field is required"),
  }),
  phone: Yup.string().when(["institutionDetail.onSelectNewinstitution"], {
    is: (item1) => item1 === 2,
    then: Yup.string().required("This field is required"),
  }),
  postalCode: Yup.string().when(["institutionDetail.onSelectNewinstitution"], {
    is: (item1) => item1 === 2,
    then: Yup.string().required("This field is required"),
  }),
  addressLine1: Yup.string().when(
    ["institutionDetail.onSelectNewinstitution"],
    {
      is: (item1) => item1 === 2,
      then: Yup.string().required("This field is required"),
    }
  ),
  websiteAddress: Yup.string().when(
    "institutionDetail.onSelectNewinstitution",
    {
      is: 2,
      then: Yup.string().when("websiteAddress", {
        is: (val) => val !== "",
        then: Yup.string().url("Please enter a valid URL"),
        otherwise: Yup.string(),
      }),
    }
  ),
  expires: Yup.boolean(),
  issDate: Yup.string()
    .matches(yearmonth, "Please enter valid format (MM-YYYY)")
    .required("This field is required"),
  expDate: Yup.string().when("expires", {
    is: false,
    then: Yup.string()
      .matches(yearmonth, "Please enter valid format (MM-YYYY)")
      .required("This field is required")
      .test({
        name: "issDate",
        message: "End Year should be greater than Start year",
        test: function (value) {
          if (this.parent.issDate && value) {
            const fullStart = this.parent.issDate.split("-");
            const fullEnd = value.split("-");
            if (fullStart.length > 0 && fullEnd.length > 0) {
              const [issDateM, issDateY] = fullStart;
              const [expDateM, expDateY] = fullEnd;
              return issDateY + issDateM < expDateY + expDateM;
            }
            return false;
          }
          return false;
        },
      }),
    otherwise: Yup.string(),
  }),
});

const RenderForm = (props) => {
  const { handleSubmit, isLoading, values, setFieldValue, toggle } = props;

  const onNewInstitute = () => {
    toggleNested();
  };

  const toggleNested = () => {
    setFieldValue(`institutionDetail.onSelectNewinstitution`, 2);
    setFieldValue(`institutionDetail.institutionName`, "Other");
    setFieldValue(`institutionDetail.guid`, "");
  };

  return (
    <form className="my-form" onSubmit={handleSubmit}>
      <div>
        <Row form>
          <Col md={6} xs={12}>
            <Label>Name</Label>
            <Field
              id="cf_name"
              name="name"
              autoComplete="off"
              type="text"
              component={FormikInputField}
              placeholder="Name"
            />
          </Col>
          <Col md={6} xs={12}>
            <Label>Certification Number</Label>
            <Field
              id="cf_certificationNumber"
              name="certificationNumber"
              autoComplete="off"
              type="text"
              component={FormikInputField}
              placeholder="Certification Number"
            />
          </Col>
        </Row>
        <Row form>
          <Col sm={12} md={12} id="nex-cf_institution-detail-w">
            <Field
              id="cf_institutionDetail"
              name="institutionDetail"
              autoComplete="off"
              type="text"
              label="Issuing Organization"
              component={CertificationInstitutesTypeaheadField}
              onNewInstituteReq={onNewInstitute}
              placeholder="Issuing Organization"
            />
          </Col>
        </Row>
        {values?.institutionDetail?.onSelectNewinstitution === 2 && (
          <NewInstituitionSection {...props} />
        )}
        <Row form>
              <Col xs={6} md={4}>
                <Label>Issued Date</Label>
                <Field
                  name={`issDate`}
                  id={`issDate`}
                  autoComplete="off"
                  type="text"
                  component={FormikInputMonthYearFormatField}
                  placeholder="MM-YYYY"
                />
              </Col>
              <Col xs={6} md={4}>
                <Label>Expiration Date</Label>
                {values.expires ? (
                  <Field
                    name={`Presenttime`}
                    id={`Presenttime`}
                    autoComplete="off"
                    type="text"
                    placeholder="No Expiry"
                    value="No Expiry"
                    disabled
                    className="form-control"
                  />
                ) : (
                  <Field
                    name={`expDate`}
                    id={`expDate`}
                    autoComplete="off"
                    type="text"
                    component={FormikInputMonthYearFormatField}
                    placeholder="MM-YYYY"
                  />
                )}
              </Col>
              <Col
                xs={12}
                md={4}
                
              >
                <label className="d-xs-node">&nbsp;</label>
                <div className="nexogix-form-check-expire-date">
                <Field
                  id="cf_expires"
                  name="expires"
                  autoComplete="off"
                  type="checkbox"
                  label="Does not expire"
                  component={FormikCheckboxField}
                  className="check-flex"
                  onClick={() => {
                    setFieldValue("expDate", "");
                  }}
                />
                </div>
              </Col>
            </Row>

        {/*<hr className="profile-info-modal-hr mt-0" />*/}
        <div className="justify-content-end  modal-footer ">
          <Col xs="auto" className="action-group">
            {isLoading ? (
              <div className="loading-spiner">
                <Spinner />
              </div>
            ) : (
              <>
              <Button
                id="cf_btn_submit"
                  className="nexogic_primary_button"
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  id="cf_btn_cancel"
                  className="nexogic_primary_button_outline"
                  color="primary"
                  type="button"
                  onClick={toggle}
                >
                  Cancel
                </Button>
                
              </>
            )}
          </Col>
        </div>
      </div>
    </form>
  );
};

const CertificationsForm = (props) => {
  const { selectedItem, getData, toggle, alert } = props;
  let initValue = selectedItem;
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  const addItem = async (value) => {
    const userGUID = globalProfileInfo.guid;
    setLoading(true);
    let payload = value;
    try {
      const formData = {
        ...(payload?.expires === false && {
          expiredOn: payload?.expDate,
        }),
        institutionDetail: {
          ...(!payload.institutionDetail.guid && {
            address: {
              addressLine1: payload?.addressLine1 ?? "",
              addressLine2: payload?.addressLine2 ?? "",
              addressType: payload?.addressType ?? "BILLING_ADDRESS",
              city: getAddressName(payload?.city),
              state: getAddressName(payload?.state),
              country: getAddressName(payload?.country),
              location: getLocationName(payload?.location),
              postalCode: payload?.postalCode ?? "",
              phone: payload?.phone ?? "",
              websiteUrl: payload?.websiteAddress ?? "",
            },
          }),
          ...(payload.institutionDetail.guid && {
            guid: payload.institutionDetail.guid,
          }),
          name: payload.mainItemName ?? "",
          ...(payload.category && {
            type: payload.category,
          }),
        },
        name: payload.name ?? "",
        doesNotExpire: payload?.expires,
        issuedOn: payload?.issDate ?? "",
        issuedBy: payload?.mainItemName ?? "",
        certificationNumber: payload?.certificationNumber ?? "",
      };
      const request = PractitionerService.addCertification(userGUID, formData);
      request
        .then(async (response) => {
          const { status, validationErrors } = response;
          if (status === 201) {
            await getData();
            setLoading(false);
            toggle();
            showSuccessMessage("Certification added Successfully");
          } else {
            setLoading(false);
            if (
              validationErrors &&
              validationErrors &&
              validationErrors.length > 0
            ) {
              const error = [];
              validationErrors.forEach((errors) => {
                error.push(errors.message);
              });
              showErrorMessage(error.join(","));
            } else {
              showErrorMessage(FAILTOSAVERECOED);
            }
          }
        })
        .catch((error) => {
          setErrorMessage(error.message);
          setLoading(false);
          console.log(error);
        });
    } catch (e) {
      setErrorMessage(e.message);
      setLoading(false);
      console.log(e);
    }
  };

  const updateItem = async (value) => {
    const userGUID = globalProfileInfo.guid;
    setLoading(true);
    let payload = value;
    try {
      const formData = {
        ...(payload?.expires === false && {
          expiredOn: payload?.expDate,
        }),
        institutionDetail: {
          ...(!payload.institutionDetail.guid && {
            address: {
              addressLine1: payload?.addressLine1 ?? "",
              addressLine2: payload?.addressLine2 ?? "",
              addressType: payload?.addressType ?? "BILLING_ADDRESS",
              city: getAddressName(payload?.city),
              state: getAddressName(payload?.state),
              country: getAddressName(payload?.country),
              location: getLocationName(payload?.location),
              postalCode: payload?.postalCode ?? "",
              phone: payload?.phone ?? "",
              websiteUrl: payload?.websiteAddress ?? "",
            },
          }),
          ...(payload.institutionDetail.guid && {
            guid: payload.institutionDetail.guid,
          }),
          name: payload.mainItemName ?? "",
          ...(payload.category && {
            type: payload.category,
          }),
        },
        name: payload.name,
        doesNotExpire: payload?.expires,
        issuedOn: payload?.issDate ?? "",
        issuedBy: payload?.mainItemName ?? "",
        certificationNumber: payload?.certificationNumber ?? "",
      };
      const request = PractitionerService.updateCertification(
        userGUID,
        payload?.id,
        formData
      );
      request
        .then(async (response) => {
          const { status, validationErrors } = response;
          if (status === 200) {
            setLoading(false);
            await getData();
            toggle();
            showSuccessMessage("Certification updated Successfully");
          } else {
            setLoading(false);
            if (
              validationErrors &&
              validationErrors &&
              validationErrors.length > 0
            ) {
              const error = [];
              validationErrors.forEach((errors) => {
                error.push(errors.message);
              });
              showErrorMessage(error.join(","));
            } else {
              showErrorMessage(FAILTOSAVERECOED);
            }
          }
        })
        .catch((error) => {
          setErrorMessage(error.message);
          setLoading(false);
          console.log(error);
        });
    } catch (e) {
      setErrorMessage(e.message);
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={initValue}
        onSubmit={(values) => {
          if (values.id) {
            updateItem(values);
          } else {
            addItem(values);
          }
        }}
        children={(props) => {
          return (
            <>
              <RenderForm toggle={toggle} {...props} isLoading={isLoading} />
            </>
          );
        }}
      ></Formik>
    </>
  );
};
export default withAlert()(CertificationsForm);
