import React from 'react'
import './css/v1.scss'

export default function DoctorSearchTop() {
  return (
    <div className="nex-doc-search-card" style={{backgroundImage: `url(https://source.unsplash.com/HSswzUk-CAU)`}}>
        <div className="nex-form-container">
            <div className="nex-form-group">
                <select name="" id="" className='form-control'>
                    <option value="">Select Hospital</option>
                    <option value="">Test 1</option>
                    <option value="">Test 2</option>
                </select>
            </div>
            <div className="nex-form-group">
                <select name="" id="" className='form-control'>
                    <option value="">Select Speciality</option>
                    <option value="">Test 1</option>
                    <option value="">Test 2</option>
                </select>
            </div>
            <div className="nex-form-group">
                <select name="" id="" className='form-control'>
                    <option value="">Select Doctor</option>
                    <option value="">Test 1</option>
                    <option value="">Test 2</option>
                </select>
            </div>
            <div className="nex-form-group">
                <input type="submit" name="" id="" className='nexogic_primary_button ' value='Submit' />
            </div>
        </div>
    </div>
  )
}
