import React from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import LinesEllipsis from 'react-lines-ellipsis';
import ConnectButton from 'pages/FeedHomePage/components/PractitionerProfiles/ConnectButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';


const ConnectionsTabularView = (props) => {
    // const currentUser = useSelector((state) => state.auth.currentUser);
    const { theader, tdata, viewmode } = props;

    // useEffect(() => {
    //     console.log(tdata);
    // }, [tdata])

    return (
        <div className="profiles-table">
            <table className="table border" >
                <thead>
                    <tr>
                        {theader.map((columns, index) => (
                            <th className="theader" key={index}>{columns}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tdata.map((profileDetails, index) => (
                        <tr key={`profile_${index}`}>
                            <td className="border font-weight-bold">
                                {profileDetails?.practitionerDetails?.name && (
                                    <div className=' d-flex'>
                                        <h4 className="link-text">
                                            <Link to={`/profile/${profileDetails?.practitionerDetails?.profileId}`} style={{ color: "#2eabca" }} onClick={() => window.scrollTo(0, 0)}>
                                                {profileDetails?.practitionerDetails?.name}
                                            </Link>
                                        </h4>
                                    </div>
                                )}
                            </td>
                            <td className="border">
                                {typeof profileDetails?.practitionerDetails?.qualification != "undefined" &&
                                    <LinesEllipsis
                                        className="card-text"
                                        text={profileDetails?.practitionerDetails?.qualification}
                                        maxLine="1"
                                        ellipsis="..."
                                        trimRight
                                        basedOn="letters"
                                    />
                                }
                            </td>
                            <td className="border">
                                {typeof profileDetails?.practitionerDetails?.primarySpeciality != "undefined" &&
                                    <LinesEllipsis
                                        className="card-text"
                                        text={profileDetails?.practitionerDetails?.primarySpeciality}
                                        maxLine="1"
                                        ellipsis="..."
                                        trimRight
                                        basedOn="letters"
                                    />
                                }
                            </td>
                            <td className="border">
                                {typeof profileDetails?.practitionerDetails?.practicingSince != "undefined" &&
                                    <div className="card-text experience-text">{!isNaN(parseInt(profileDetails?.practitionerDetails?.practicingSince)) &&
                                        parseInt(new Date().getFullYear()) - parseInt(profileDetails?.practitionerDetails?.practicingSince) > 0 ? (
                                        <p className="m-0 lh-base lh-lg">
                                            {`${parseInt(new Date().getFullYear()) -
                                                parseInt(profileDetails?.practitionerDetails?.practicingSince)
                                                }+ Years`}
                                        </p>
                                    ) : '0'} </div>
                                }
                            </td>
                            <td className="border">
                                <LinesEllipsis
                                    className="card-text"
                                    text={profileDetails?.practitionerDetails?.city}
                                    maxLine="1"
                                    ellipsis="..."
                                    trimRight
                                    basedOn="letters"
                                />
                            </td>
                        </tr>   
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default ConnectionsTabularView