import React from 'react'

export default function IconGlob() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <g fill="#000" clipPath="url(#clip0_809_2062)">
      <path d="M10.041 2.605C9.641 1.566 9.138.691 8.56.02a8.128 8.128 0 00-1.146 0C6.834.691 6.33 1.566 5.93 2.605c-.13.336-.246.683-.349 1.041h4.808c-.103-.358-.22-.705-.349-1.04zM5.347 11.416h5.278c.201-.938.319-1.93.346-2.947H5c.028 1.017.145 2.01.347 2.947zM10.625 4.584H5.347c-.202.938-.32 1.93-.347 2.947h5.971a16.143 16.143 0 00-.346-2.947zM5.93 13.395c.4 1.039.904 1.914 1.483 2.585a8.146 8.146 0 001.146 0c.579-.671 1.082-1.546 1.482-2.585.13-.336.246-.684.349-1.041H5.582c.103.357.22.705.349 1.04zM11.583 4.584c.19.936.302 1.927.328 2.947h4.063a7.947 7.947 0 00-.748-2.948l-.013.001h-3.63zM4.391 11.416a17.165 17.165 0 01-.328-2.947H0a7.948 7.948 0 00.748 2.948H4.39zM11.361 12.354c-.349 1.301-.853 2.468-1.48 3.421a7.95 7.95 0 003.762-2.118 8.074 8.074 0 001.057-1.303h-3.339zM4.611 3.646c.35-1.3.853-2.468 1.48-3.42A7.95 7.95 0 002.33 2.342a8.078 8.078 0 00-1.058 1.303h3.34zM4.611 12.354H1.272c.303.465.656.901 1.058 1.303a7.95 7.95 0 003.761 2.118c-.627-.953-1.13-2.12-1.48-3.421zM11.361 3.646H14.7a8.075 8.075 0 00-1.057-1.303A7.95 7.95 0 009.88.225c.627.953 1.131 2.12 1.48 3.421zM11.911 8.469a17.167 17.167 0 01-.328 2.947h3.643a7.947 7.947 0 00.748-2.947h-4.063zM4.063 7.531c.026-1.02.139-2.011.328-2.947H.748A7.948 7.948 0 000 7.53h4.063z"></path>
    </g>
    <defs>
      <clipPath id="clip0_809_2062">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
  )
}
