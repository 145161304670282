import logo_img from 'assets/images/logo-nexogic.png'
import { Link } from "react-router-dom";
const AppHeaderTop = () => {
    return (
        <div className="nex-app-top-header">
            <div className="nex-logo-icon">
                <a><img src={logo_img} alt="" /></a>
            </div>
            <div className="nex-page-name"></div>
            <Link className="next-top-signup-btn" to={`/register`}>
                <span className='icon'><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 2.375C0 1.064 1.08554 0 2.42308 0H5.88462C6.16003 4.02261e-09 6.42417 0.107238 6.61892 0.298123C6.81367 0.489009 6.92308 0.747905 6.92308 1.01786C6.92308 1.28781 6.81367 1.54671 6.61892 1.73759C6.42417 1.92848 6.16003 2.03571 5.88462 2.03571H2.42308C2.33127 2.03571 2.24323 2.07146 2.17831 2.13509C2.11339 2.19872 2.07692 2.28502 2.07692 2.375V16.625C2.07692 16.8123 2.232 16.9643 2.42308 16.9643H5.88462C6.16003 16.9643 6.42417 17.0715 6.61892 17.2624C6.81367 17.4533 6.92308 17.7122 6.92308 17.9821C6.92308 18.2521 6.81367 18.511 6.61892 18.7019C6.42417 18.8928 6.16003 19 5.88462 19H2.42308C1.78044 19 1.16412 18.7498 0.709703 18.3044C0.255288 17.859 0 17.2549 0 16.625V2.375ZM9.08308 8.48214H16.9615C17.237 8.48214 17.5011 8.58938 17.6958 8.78027C17.8906 8.97115 18 9.23005 18 9.5C18 9.76995 17.8906 10.0288 17.6958 10.2197C17.5011 10.4106 17.237 10.5179 16.9615 10.5179H9.08308L11.8108 13.1914C11.9475 13.3158 12.0474 13.4741 12.1 13.6495C12.1527 13.8249 12.1561 14.011 12.1098 14.1881C12.0636 14.3653 11.9695 14.5269 11.8375 14.656C11.7054 14.7851 11.5402 14.877 11.3594 14.9218C11.1789 14.9671 10.9892 14.964 10.8104 14.9126C10.6315 14.8613 10.4701 14.7636 10.3431 14.63L5.84308 10.2193C5.64861 10.0284 5.53938 9.76973 5.53938 9.5C5.53938 9.23027 5.64861 8.97156 5.84308 8.78071L10.3431 4.37C10.47 4.23602 10.6315 4.13807 10.8104 4.08648C10.9894 4.03489 11.1792 4.03156 11.3599 4.07686C11.5407 4.12215 11.7056 4.21438 11.8373 4.34383C11.9691 4.47329 12.0627 4.63517 12.1085 4.81243C12.1547 4.98937 12.1515 5.17526 12.0991 5.35055C12.0467 5.52584 11.9471 5.68406 11.8108 5.80857L9.08308 8.48214Z" fill="#3EABCA" />
                </svg></span></Link>
            <Link className="next-top-login-btn" to={`/login`}>
                <span className='icon'><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 2.375C0 1.064 1.08554 0 2.42308 0H5.88462C6.16003 4.02261e-09 6.42417 0.107238 6.61892 0.298123C6.81367 0.489009 6.92308 0.747905 6.92308 1.01786C6.92308 1.28781 6.81367 1.54671 6.61892 1.73759C6.42417 1.92848 6.16003 2.03571 5.88462 2.03571H2.42308C2.33127 2.03571 2.24323 2.07146 2.17831 2.13509C2.11339 2.19872 2.07692 2.28502 2.07692 2.375V16.625C2.07692 16.8123 2.232 16.9643 2.42308 16.9643H5.88462C6.16003 16.9643 6.42417 17.0715 6.61892 17.2624C6.81367 17.4533 6.92308 17.7122 6.92308 17.9821C6.92308 18.2521 6.81367 18.511 6.61892 18.7019C6.42417 18.8928 6.16003 19 5.88462 19H2.42308C1.78044 19 1.16412 18.7498 0.709703 18.3044C0.255288 17.859 0 17.2549 0 16.625V2.375ZM9.08308 8.48214H16.9615C17.237 8.48214 17.5011 8.58938 17.6958 8.78027C17.8906 8.97115 18 9.23005 18 9.5C18 9.76995 17.8906 10.0288 17.6958 10.2197C17.5011 10.4106 17.237 10.5179 16.9615 10.5179H9.08308L11.8108 13.1914C11.9475 13.3158 12.0474 13.4741 12.1 13.6495C12.1527 13.8249 12.1561 14.011 12.1098 14.1881C12.0636 14.3653 11.9695 14.5269 11.8375 14.656C11.7054 14.7851 11.5402 14.877 11.3594 14.9218C11.1789 14.9671 10.9892 14.964 10.8104 14.9126C10.6315 14.8613 10.4701 14.7636 10.3431 14.63L5.84308 10.2193C5.64861 10.0284 5.53938 9.76973 5.53938 9.5C5.53938 9.23027 5.64861 8.97156 5.84308 8.78071L10.3431 4.37C10.47 4.23602 10.6315 4.13807 10.8104 4.08648C10.9894 4.03489 11.1792 4.03156 11.3599 4.07686C11.5407 4.12215 11.7056 4.21438 11.8373 4.34383C11.9691 4.47329 12.0627 4.63517 12.1085 4.81243C12.1547 4.98937 12.1515 5.17526 12.0991 5.35055C12.0467 5.52584 11.9471 5.68406 11.8108 5.80857L9.08308 8.48214Z" fill="#3EABCA" />
                </svg></span></Link>
        </div>
    )
}

export default AppHeaderTop;