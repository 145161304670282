import React from 'react'

export default function AppointmentPopup(props) {
    return (
        <> {props.popvisible}
            {/* popup  */}
             
            <div className={`nexogic-appointment-popup-wrap ${props.popvisible ? 'on' : ''}`}>
                <div className={`nexogic-appointment-popup card ${props.popvisible ? 'on' : ''}`} style={{
                    top: props.mousePosition.y - 20,
                    left: props.mousePosition.x,
                }} >
                    <div className="card-header">Appointment Booking <span className='close close-btn' onClick={() => props.closePopup()}>&times;</span></div>
                    <div className="card-body">
                        <div className="form-group">
                            <div className="label small">Appointment Date</div>
                            <div className="value"><span className="icon"><svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.3889 1V4M4.61111 1V4M1 7H14M2.44444 2.5H12.5556C13.3533 2.5 14 3.17157 14 4V14.5C14 15.3284 13.3533 16 12.5556 16H2.44444C1.6467 16 1 15.3284 1 14.5V4C1 3.17157 1.6467 2.5 2.44444 2.5Z" stroke="#2D4552" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            </span> Monday, May 23  -  Monday, May 23</div>
                        </div>
                        <div className="form-group">
                            <div className="label small">Select Time Slot</div>
                            <div className="nexogic-times-slots">
                                <span className='time active'>10:30AM</span>
                                <span className='time active'>10:45AM</span>
                                <span className='time active'>11:00AM</span>
                                <span className='time'>11:30AM</span>
                                <span className='time'>11:45PM</span>
                                <span className='time'>12:00PM</span>
                                <span className='time'>12:15PM</span>
                                <span className='time'>12:30PM</span>
                                <span className='time'>12:45PM</span>
                                <span className='time'>01:00PM</span>
                                <span className='time'>01:15PM</span>
                                <span className='time'>01:30PM</span>
                                <span className='time'>01:45PM</span>
                                <span className='time'>02:00PM</span>
                                <span className='time'>02:15PM</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="label small">Visit</div>
                                    <div className="value">Follow up visit</div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="label small">This appointment is for</div>
                                    <div className="value">Anna Smith</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="label small">Purpose of visit</div>
                                    <div className="value">Illness</div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="label small">Insurance</div>
                                    <div className="value">I am paying for myself</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="label small">Phone</div>
                                    <div className="value">+919999999999</div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="label small">Email</div>
                                    <div className="value">annasmith@gmail.com</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer actions">
                        <a href="" className="btn btn-danger">Reject</a>
                        <a href="" className="btn btn-success">Accept</a>
                    </div>
                </div>

            </div>
            
        </>
    )
}
