import AlertDialog from "common/AlertDialog";
import CommonLoader from "common/CommonLoader";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { withAlert } from "react-alert";
import { useSelector } from "react-redux";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { ConnectionServices } from "utils/ConnectionServices";
import { FAILTOSAVERECOED } from "utils/Constants";
import NoConnection from "../../NoConnection";
import GroupItem from "./GroupItem";
import { RenderReportModal } from "./RenderReportModal";
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faIdCard, faListAlt, faTable } from "@fortawesome/free-solid-svg-icons";
import GroupsFollowedTabularView from "components/tabularView/GroupsFollowedTabularView";
import PeopleKnowWidget from "common/PeopleKnowWidget";
import PageTitle from "common/PageTitle/PageTitle";
import ListTableSwitch from "common/ListTableSwitch/ListTableSwitch";
import { Col, Row } from "reactstrap";

const GroupFollow = forwardRef((props, ref) => {
  const { activeTab } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [groupIdToAct, setGroupIdToAct] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { alert } = props;
  const [isOpenAlert, setAlertModal] = useState(false);
  const [viewMode, setViewMode] = useState("card");
  const [list_of_data, setList_of_data] = useState(null);

  const toggle = () => setIsOpen(!isOpen);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [hasMore, setHasMore] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);


  const getData = async (pageNo, feedlist) => {
    const request = ConnectionServices.getGroups(
      currentUser.guid,
      "MEMBER",
      pageNo
    );
    request
      .then(async (response) => {
        // console.log(response);
        let { data } = response;
        // console.log(data);
        if (data?.currentPage < (data?.totalPages - 1))
          setHasMore(true);
        else
          setHasMore(false);
        setIsLoadingMore(false);
        if (pageNo === 0) {
          const allData = { ...data };
          // console.log(allData);
          setList_of_data(allData);
          setIsLoading(false);
        } else {
          const resolved = await Promise.all(
            data.contents.map(async (item) => {
              const each = { ...item };
              return each;
            })
          );
          feedlist = feedlist.concat(resolved);
          setList_of_data({ ...data, contents: feedlist });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoadingMore(false);
        console.log("Error while getting groups list: ", error);
      });
  };

  const fetchData = () => {
    setIsLoadingMore(true);
    getData(list_of_data.currentPage + 1, list_of_data.contents);
  };

  useImperativeHandle(ref, () => ({
    getJobListData() {
      getData(0, []);
    },
  }));

  const reportClick = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    getData(0, []);
  }, []);

  if (isLoading) {
    return (
      <div className="loading-spiner text-center  mt-2">
        <CommonLoader style={{ width: "2rem", height: "2rem" }} />
      </div>
    );
  }

  const toggleAlert = () => {
    setAlertModal(!isOpenAlert);
  };

  const handleClickOnYes = () => {
    handleUnfollow(groupIdToAct);
    toggleAlert();
  };

  const handleUnfollow = (groupId) => {
    // console.log("id", groupId);
    ConnectionServices.leaveTheGroup(currentUser.guid, groupId).then(
      (response) => {
        if (response.status === 204) {
          showSuccessMessage("You have left the Group, successfully...");
          toggleAlert();
          getData(0, []);
        } else {
          showErrorMessage(FAILTOSAVERECOED);
          toggleAlert();
        }
      }
    );
  };

  const renderProfiles = () => {
    if (viewMode === 'card') {
      return (
        <>
          {list_of_data &&
            list_of_data.contents.map((e, i) => (
              <GroupItem
                key={i}
                group={e}
                activeTab={activeTab}
                reportClick={reportClick}
                toggleAlert={toggleAlert}
                setGroupIdToAct={setGroupIdToAct}
              />
            ))}
        </>
      );
    } else { // For table view
      return (
        <GroupsFollowedTabularView theader={['Name', 'Description', 'Unfollow']} tdata={list_of_data.contents} viewmode={viewMode}
          toggleAlert={toggleAlert}
          setGroupIdToAct={setGroupIdToAct}
        />
      );
    }
  };


  return (
    <Row className="nex-container">
      <Col className="nex-lists-content">
        <PageTitle title="Group I follow">
            <ListTableSwitch viewMode={viewMode} cardViewAction={() => setViewMode("card")}  tableViewAction={()=>setViewMode("table")} />
        </PageTitle>
        <div
        //  className="view-toggle nexogic-view-toggle"
        >
          {/*<div className="btns">
            <FontAwesomeIcon title="Card View" color={viewMode === "card" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faAddressCard} onClick={() => setViewMode("card")} /> | <FontAwesomeIcon title="Table View" color={viewMode === "table" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faListAlt} onClick={() => setViewMode("table")} />
          </div>*/}
        </div>
        {
          list_of_data && list_of_data.contents.length > 0 ?
            <div className="card-group  nexogic-connection-card nexogic-custom-scroll position-relative" id="grouped-follow-lists">
              <div className="nexogic-groups-lists w-full">
                <div className="groups-follow py-2">
                  {renderProfiles()}
                  <AlertDialog
                    bodyText={"Are you sure, you want to leave this group?"}
                    toggle={toggleAlert}
                    isOpen={isOpenAlert}
                    handleClickOnYes={handleClickOnYes}
                    yesLabel={"Yes"}
                    noLabel={"No"}
                  />
                </div>
                <RenderReportModal isOpen={isOpen} toggle={toggle} />
              </div>
            </div>
            :
            <div className="loading-spiner text-center mt-5">
              <NoConnection content={"You don't follow any group"} />
            </div>
        }
        
        {hasMore &&
          <div className="text-center mt-3">
            {isLoadingMore
              ?
              <CommonLoader style={{ width: "2rem", height: "2rem" }} />
              :
              <LoadMoreButton loadMore={fetchData} />
            }
          </div>
        }
      </Col>
      <Col className="nex-sidebar v2">
        <PeopleKnowWidget speciality={"Acupuncturist"} />
      </Col>
    </Row>
  )
});

GroupFollow.defaultProps = {
  toggleCGModal: () => { },
  isCGOpen: false,
};

export default withAlert()(GroupFollow);
