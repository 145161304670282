import camera_icon from "assets/images/camera-icon.png";
import delIcon from "assets/images/del-red-icon.png";
import moment from "moment";
import React, { PureComponent, useEffect, useRef, useState } from "react";
import { withAlert } from "react-alert";
import { Cropper } from "react-image-cropper";
import { useDispatch, useSelector } from "react-redux";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import {
  Button,
  ButtonGroup,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Progress,
} from "reactstrap";
import { authSetUser } from "redux-helpers/ActionCreator";
import swal from "sweetalert";
import { encrypt } from "utils/EncryptDecrypt";
import { PractitionerService } from "utils/PractitionerService";
import { setUser } from "utils/UserHelper";
import {
  createImageFromInitials,
  getRandomColor,
  validateImageSize,
  validateImageType,
} from "utils/Utils";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useHasChanged = (val) => {
  const prevVal = usePrevious(val);
  return prevVal !== val;
};

class RenderDialog extends PureComponent {
  handleCroppImage = async () => {
    const { uploadImageDetails, onSave, toggle } = this.props;
    const cropImageBase64 = this.cropper.crop();
    const result = await this.resizeBase64Img(cropImageBase64);
    const unixTime = moment().unix();
    const res = await fetch(result);
    const blob = await res.blob();
    const file = new File([blob], `${unixTime}_${uploadImageDetails.name}`, {
      type: uploadImageDetails.type,
    });
    const validSize = validateImageSize(file, 1048576);
    toggle();
    if (validSize === 1) {
      onSave(file);
    } else {
      this.handleCancel();
      showErrorMessage("Image size should be less than 1 MB");
    }
  };

  resizeBase64Img = async (base64Str, MAX_WIDTH = 450, MAX_HEIGHT = 450) => {
    return await new Promise((resolve) => {
      let img = new Image();
      img.src = base64Str;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL()); // this will return base64 image results after resize
      };
    });
  };

  handleCancel = () => {
    const { onSave, toggle } = this.props;
    toggle();
    onSave("");
  };

  render() {
    const { modal, toggle, profilePicUrl } = this.props;
    return (
      <div>
        <Modal
          isOpen={modal}
          toggle={toggle}
          className="modal-dialog-scrollable modal-dialog-centered modal-crop"
        >
          <ModalHeader toggle={toggle}>Profile Picture</ModalHeader>
          <ModalBody className="px-1 px-sm-4">
            <div

              className="nexogic-profile-cropper-circle d-flex align-items-center justify-content-center mx-auto"
            >
              <Cropper
                src={profilePicUrl}
                ref={(ref) => {
                  this.cropper = ref;
                }}
                width={200}
                height={200}
              />
            </div>
          </ModalBody>
          <ModalFooter className="justify-content-end  modal-footer extra-x-padd">
            <div className="action-group">
              <Button color="primary" onClick={(e) => this.handleCroppImage(e)} className="nexogic_primary_button">
                Save
              </Button>
              <Button
                color="link"
                className="nexogic_primary_button_outline 
              "
                onClick={(e) => this.handleCancel(e)}
              >
                Cancel
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export const ImageUploader = (props) => {
  const {
    practitioner,
    practitionerId,
    isLoggedinUser,
    isAdmin,
    username,
    alert,
  } = props;
  const [modal, setModal] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [uploadImageDetails, setImageDetails] = useState({
    name: "",
    type: "",
  });
  const [uploadImage, setImage] = useState("");
  const [sourceImage, setSourceImage] = useState("");
  const [uProgress, setUProgress] = useState(0);
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  // const currentUser = useSelector((state) => state.auth.currentUser);
  const currentUser = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );
  const hasVal1Changed = useHasChanged(practitioner?.profileId);

  useEffect(() => {
    if (hasVal1Changed) {
      onLoadingDefaultImage(practitioner?.guid, practitioner?.avatarId);
    }
  });

  const handleImageUpload = (event) => {

    let file = event.target.files[0];
    if (validateImageType(file)) {
      const maxSize = 10485760; //10 MB
      const validSize = validateImageSize(file, maxSize);
      if (validSize === 1) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const imageBase64 = reader.result;
          setImageDetails({ name: file.name, type: file.type });
          setImage(imageBase64);
          toggle();
        };
      } else if (validSize === -1) {
        showErrorMessage("Invalid File!");
      } else {
        showErrorMessage("File must be less than 10 MB");
      }
    } else {
      showErrorMessage("Invalid File!");
    }
  };

  const onLoadingDefaultImage = async (guidId, imageId) => {
    let imageSet = "";
    if (guidId && imageId) {
      imageSet = `${process.env.REACT_APP_IMAGE_BASE_URL}/${guidId}/${imageId}`;
      setShowDeleteButton(true);
    }
    setImage(imageSet);
    setSourceImage(imageSet);
  };

  const onImageChange = (file) => {
    if (file) {
      setSpinner(true);
      const options = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setUProgress(percent);
        }
      }
      PractitionerService.new_fileUpload(
        file,
        currentUser.guid,
        "profile",
        options
      ).then(
        (response) => {
          if (response.status === 200 || response.status === 201) {
            onLoadingDefaultImage(practitioner?.guid, response.data);
            const updatedUserDetails = {
              ...currentUser,
              avatarId: response.data,
            };
            if (!isAdmin) {
              dispatch(authSetUser(updatedUserDetails));
              setUser(updatedUserDetails);
            }
            showSuccessMessage("Profile Image uploaded successfully");
            setShowDeleteButton(true);
            if (uProgress <= 100) {
              setTimeout(() => {
                setUProgress(0)
              }, 1000);
            }
          } else {
            showErrorMessage("Failed Profile Image upload");
          }
          setSpinner(false);
        },
        (err) => {
          setSpinner(false);
          onLoadingDefaultImage(practitioner?.guid, "");
          showErrorMessage(err.message);
        }
      );
    }
  };

  const removeProfileImage = () => {
    swal("Are you sure you want to remove Profile Image?", {
      buttons: ["cancel", "yes"],
    })
      .then((yesRemove) => {
        if (yesRemove) {
          setSpinner(true);
          PractitionerService.new_deletePractitionerImage(
            currentUser.guid,
            "profile"
          ).then(
            (response) => {
              setSpinner(false);
              const { status } = response;
              if (status === 204) {
                setShowDeleteButton(false);
                const updatedUserDetails = {
                  ...currentUser,
                  profilePicUrl: uploadImage,
                  avatarId: "",
                };
                if (!isAdmin) {
                  dispatch(authSetUser(updatedUserDetails));
                  setUser(updatedUserDetails);
                }
                onLoadingDefaultImage(practitioner?.guid, "");
                showSuccessMessage("Profile Image removed successfully");
                setImage("");
              } else {
                showErrorMessage("Error removing Profile Image");
              }
            },
            (err) => {
              setSpinner(false);
              let errmsg = err.message || "Error removing Profile Image";
              if (err.response) {
                errmsg =
                  err.response.data && !!err.response.data.message
                    ? err.response.data.message
                    : errmsg;
              }
              showErrorMessage(errmsg);
            }
          );
        }
      })
      .catch((err) => {
        showErrorMessage("Error removing Profile Image");
        setSpinner(false);
      });
  };

  return (
    <>
      <RenderDialog
        modal={modal}
        toggle={toggle}
        profilePicUrl={uploadImage}
        uploadImageDetails={uploadImageDetails}
        onSave={onImageChange}
        alert={alert}
      />
      {uProgress > 0 ? (
        <div className="position-absolute nexogic-profile-pbar">
          <Progress value={uProgress} max="100">{uProgress}</Progress>
        </div>
      ) : ""}
      {
        <div className="card-user-img">
          <img
            src={
              sourceImage
                ? sourceImage
                : createImageFromInitials(
                  132,
                  username,
                  getRandomColor(encrypt(practitionerId).toString())
                )
            }
            className="img-fluid "
            alt="profile picture"
            aria-hidden
          />
        </div>
      }
      {showDeleteButton && (isLoggedinUser || isAdmin) && (
        <Button className="remove-card-user-img-btn" onClick={removeProfileImage} id="nex-remove-card-user-img-btn">
          <img src={delIcon} />
          {/*<FontAwesomeIcon icon={faTrash} className="fa-fw f-12" />*/}
        </Button>
      )}
      {(isLoggedinUser || isAdmin) && (
        <Button
          className="edit-user-profile-img-btn" id="nex-iu-profile-img-btn"
        >
          {/*<img src={camera_icon} alt="" width={18} />*/}
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.3125 0.25L6.09375 1.46875L8.53125 3.90625L9.75 2.6875L7.3125 0.25ZM4.875 2.6875L0 7.5625V10H2.4375L7.3125 5.125L4.875 2.6875Z" fill="#46ABC9" />
          </svg>

          <input
            disabled={!isLoggedinUser && !isAdmin}
            name="profilePicUrl"
            className="fileUploadForm"
            type="file"
            onChange={(event) => {
              handleImageUpload(event)
              event.target.value = null;
            }}
            id="profilePicUrl"
            accept="image/*, png, jpg, jpeg"
            title=""
          />
        </Button>

      )}
    </>
  );
};

export default withAlert()(ImageUploader);
