import { useState, useEffect, useRef } from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import job_icon_4 from 'assets/images/job-icon-4.png';
import { JOB_Work_Shift_TypesList } from "utils/Constants";
import { MetadataService } from "utils/MetadataService";

function JobCreateFormFour(props) {
    const { data } = props;
    const { values, setFieldValue } = useFormikContext();
    const skillsRef = useRef(null);
    const qualificationsRef = useRef(null);
    const [allSkillsList, setAllSkillsList] = useState([]);
    const [degreeList, setDegreeList] = useState([]);
    const [skills, setSkills] = useState([]);
    const [qualifications, setQualifications] = useState([]);
    const [popularSkills, setPopularSkills] = useState([]);
    const [popularSkillsToShow, setPopularSkillsToShow] = useState([]);

    const [fieldShowNum, setFieldShowNum] = useState(0);
    const [isSkillSelected, setIsSkillSelected] = useState(false);
    const [inputValue, setInputValue] = useState([]);
    const [qualificationInputValue, setQualificationInputValue] = useState([]);

    const removeSkill = (skillToRemove) => {
        const updatedSkills = skills.filter(skill => skill !== skillToRemove);
        setSkills(updatedSkills);
        setFieldValue('skills', updatedSkills);
        setPopularSkillsToShow(prevSkills => prevSkills.map(skill =>
            skill.name === skillToRemove ? { ...skill, disabled: false } : skill
        ));
    };

    const removeQualification = (qualificationToRemove) => {
        const updatedQualifications = qualifications.filter(qualification => qualification !== qualificationToRemove);
        setQualifications(updatedQualifications);
        setFieldValue('qualifications', updatedQualifications);
    };

    const onChangeHandler = (selected) => {
        const newSkills = selected.map(item => item.customOption ? item.name : item);
        const updatedSkills = [...skills, ...newSkills.filter(skill => !skills.includes(skill))];
        setSkills(updatedSkills);
        setFieldValue('skills', updatedSkills);
        setInputValue([]); // Clear the input value
    };

    const onQualificationChangeHandler = (selected) => {
        const newQualifications = selected.map(item => item.customOption ? item.name : item);
        const updatedQualifications = [...qualifications, ...newQualifications.filter(qualification => !qualifications.includes(qualification))];
        setQualifications(updatedQualifications);
        setFieldValue('qualifications', updatedQualifications);
        setQualificationInputValue([]); // Clear the input value
    };

    const onMultipleQualiHandler = (selected) => {
        if (String(selected).length > 0) {
            setFieldShowNum(2);
        }
    }

    const getPopularSkillsSet = () => {
        MetadataService.getAllSkillSet()
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    const skillsWithDisabledFlag = response.data.map(skill => ({ name: skill, disabled: false }));
                    setPopularSkills(skillsWithDisabledFlag);
                    setPopularSkillsToShow(skillsWithDisabledFlag.slice(0, 10));
                }
            })
            .catch((error) => {
                console.log("MetadataService.setPopularSkills error", error);
            });
    }

    useEffect(() => {
        const getSkillSet = (primarySpecialityItem) => {
            MetadataService.getSpecialitySkillSet(encodeURIComponent(primarySpecialityItem))
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        setAllSkillsList(response.data);
                    }
                })
                .catch((error) => {
                    console.log("MetadataService.getSpecialitySkillSet error", error);
                });
        };

        const getDegrees = () => {
            MetadataService.getDegree()
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        setDegreeList(response.data);
                    }
                })
                .catch((error) => {
                    console.log("MetadataService.getDegree error", error);
                });
        };

        if (data?.primarySpeciality) {
            getSkillSet(data?.primarySpeciality);
        }
        getDegrees();
        getPopularSkillsSet();
    }, [data?.primarySpeciality]);

    const handleLoadMore = (len) => {
        let temp = popularSkills.slice(0, len).map(skill =>
            skills.includes(skill.name) ? { ...skill, disabled: true } : skill
        );
        setPopularSkillsToShow(temp);
    }

    const handleAddSkill = (skill) => {
        if (!skills.includes(skill.name)) {
            const updatedSkills = [...skills, skill.name];
            setSkills(updatedSkills);
            setFieldValue('skills', updatedSkills);
            setPopularSkillsToShow(prevSkills => prevSkills.map(item =>
                item.name === skill.name ? { ...item, disabled: true } : item
            ));
        }
    };

    useEffect(() => {
        if (values?.skills)
            setSkills(values?.skills);
        if (values?.qualifications)
            setQualifications(values?.qualifications);
    }, [])


    return (
        <>
            <div className="nex-job-form-left-sec">
                <div className="nex-job-info">
                    <img src={job_icon_4} alt="" className="graphic" />
                    <div className="conts">
                        <p><span className='light'>3/7</span> Job Post</p>
                        <h2 className='title'>Add the required skill for
                            a better understanding</h2>
                        <p className='lead'>so that the candidate gets a more proper view</p>
                    </div>
                </div>
            </div>
            <div className="nex-job-form-right-sec">
                <h2 className='nex-text-lg text-black'>Required Skills</h2>
                <div className="form-group gap-md">
                    <Typeahead
                        ref={skillsRef}
                        allowNew
                        clearButton
                        newSelectionPrefix="Add Skill: "
                        selected={inputValue}
                        id="skills"
                        multiple
                        options={allSkillsList}
                        labelKey={"name"}
                        placeholder="Add your skills ex. Cardiology"
                        onBlur={(e) => {
                            if (skills.length > 0) {
                                setIsSkillSelected(true);
                                setFieldShowNum(1);
                            }
                            return skillsRef !== null
                                ? skillsRef.current.hideMenu()
                                : null;
                        }}
                        onChange={onChangeHandler}
                    />
                    <ErrorMessage name="skills" render={msg => <span className="text-danger skill-err f-12 d-inline-block mt-1 line-height-error">{msg}</span>} />
                </div>
                <>
                    {(skills && skills.length) ? <h2 className='nex-text-md'>Selected Skills</h2> : ""}
                    <div className="form-group gap-md">
                        <div className="nex-pill-group nex-selected-pill-group">
                            {(skills && skills.length > 0)
                                && skills.map((e, index) => (
                                    <span key={index} className='nex-pill nex-pill-primary'>{e}
                                        <span
                                            className="times"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => removeSkill(e)}
                                        >
                                            &times;
                                        </span>
                                    </span>
                                ))
                            }
                        </div>
                    </div>
                </>
                <div className="form-group gap-md">
                    <h2 className='nex-text-md'>Popular Skills</h2>
                    <div className="nex-pill-group nexogic-custom-scroll">
                        {popularSkillsToShow.length > 0 &&
                            <>
                                {popularSkillsToShow.map((item, index) => (
                                    <span
                                        key={index}
                                        className={`nex-pill nex-pill-primary ${item.disabled ? 'disabled' : ''}`}
                                        onClick={() => !item.disabled && handleAddSkill(item)}
                                        style={{ cursor: item.disabled ? 'not-allowed' : 'pointer' }}
                                    >
                                        {item.name} +
                                    </span>
                                ))}
                                {popularSkills.length > popularSkillsToShow.length && (
                                    <button type="button" className="btn btn-link" onClick={() => handleLoadMore(popularSkillsToShow.length + 10)}>Load More</button>
                                )}
                            </>
                        }
                    </div>
                </div>
                {values?.skills && values?.skills.length > 0 &&
                    <>
                        <h2 className='nex-text-md'>Multiple Qualification</h2>
                        <div className="form-group gap-md">
                            <Typeahead
                                ref={qualificationsRef}
                                clearButton
                                newSelectionPrefix="Add Qualification: "
                                selected={qualificationInputValue}
                                id="qualifications"
                                multiple
                                options={degreeList}
                                labelKey={"name"}
                                placeholder="Add your qualifications"
                                onChange={onQualificationChangeHandler}
                            />
                        </div>
                        <>
                            {(qualifications && qualifications.length) ? <h2 className='nex-text-md'>Selected Qualifications</h2> : ""}
                            <div className="form-group gap-md">
                                <div className="nex-pill-group nex-selected-pill-group">
                                    {(qualifications && qualifications.length > 0)
                                        && qualifications.map((e, index) => (
                                            <span key={index} className='nex-pill nex-pill-primary'>{e}
                                                <span
                                                    className="times"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => removeQualification(e)}
                                                >
                                                    &times;
                                                </span>
                                            </span>
                                        ))
                                    }
                                </div>
                            </div>
                        </>
                        <h2 className='nex-text-md'>Work Shift</h2>
                        <div className="form-group gap-md">
                            <Field
                                id="shift"
                                name="shift"
                                component={FormikSelectField}
                                inputprops={{
                                    name: `shift`,
                                    options: JOB_Work_Shift_TypesList,
                                    defaultOption: "Select",
                                    keys: {
                                        id: "value",
                                        label: "label",
                                    },
                                }}
                            />
                        </div>
                    </>
                }
            </div>
        </>
    );
}

export default JobCreateFormFour;
