import React, { useState } from 'react';
import { Card, Col, Row } from 'reactstrap'
// import ThemeOne from './Components/Themes/Components/ThemeOne'
// import ThemeContents from './Components/Themes/ThemeContents'
import { useHistory, useLocation, useParams } from "react-router-dom";
import UISidebar from './Components/UISidebar';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from "immutability-helper";
import SectionMenuList from './Components/Themes/ThemeContents/SectionMenuList';
import ThemeMenus from './Components/Themes/ThemeContents/ThemeMenus';
import ThemeOneTemplate from './Components/Themes/Components/ThemeOne/ThemeOneTemplate';
import SectionsCard from './Components/Themes/ThemeContents/SectionsCard';
import { createContext } from 'react';
import 'assets/styles/global.scss'


export const CardContext = createContext({
  markAsDragged: (id) => { },
})

const ThemeDesign = () => {
  const history = useHistory();
  const { state } = useLocation();
  const [dragStatus, setDragStatus] = useState('drag');
  const themeSectionHeadings = [
    { id: 1, slabel: "About", scontent: "1 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." },
    { id: 2, slabel: "Gallery", scontent: "2 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." },
    { id: 3, slabel: "Business Hours", scontent: "3 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." },
    { id: 4, slabel: "Specialities", scontent: "4 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." },
    { id: 5, slabel: "Services", scontent: "5 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." },
    { id: 6, slabel: "Amenities", scontent: "6 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." },
    { id: 7, slabel: "Other Branches", scontent: "7 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." },
    { id: 8, slabel: "Awards", scontent: "8 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." },
  ]

  const finalObj = themeSectionHeadings.map((selement) => ({
    ...selement, status: dragStatus
  })).filter((element) => element.status === 'drag');

  // console.log("==================================", finalObj, state);
  const [sections, setSections] = useState(finalObj);

  // const moveImage = (dragIndex, hoverIndex) => {
  //   const draggedImage = themeSectionHeadings[dragIndex];
  //   setImages(
  //     update(themeSectionHeadings, {
  //       $splice: [[dragIndex, 1], [hoverIndex, 0, draggedImage]]
  //     })
  //   );
  // };
  const markAsDragged = (id) => {
    const draggedTask = sections.filter((secHead, index) => secHead.id === id)[0];
    // const updatedObj = draggedTask.map((selement)=>({
    //   ...selement,status:"dropped"
    // }));
    draggedTask.status = "dropped";
    // console.log("Dragged Element Details----", draggedTask, id);
  }

  return (
    <CardContext.Provider value={{ markAsDragged }}>
      <div className='container-fluid microsite-side-main' style={{}}>
        <main>
          <DndProvider backend={HTML5Backend}>
            <Row>
              <Col md={4} lg={3}>
                <Row>
                  <Col className="card-fixed fixed-top">
                    <Card className='mb-2'>
                      <div className='main-header'>THEME CONTENTS</div>
                    </Card>

                    <Row className="">
                      <Col md={12} lg={12} className='h-100'>
                        <Card className='vheight'>
                          {sections.map((sElements, index) => {
                            return <SectionsCard key={sElements.id} sectionDetails={sElements} setSections={setSections} />
                            // return <ContentsCard key={sElements.id} sectionDetails={sElements} opacity={isDragging ? '0.5' : '1'} />
                          })}
                          {/* <SectionsCard title={"About UsS"} /> */}
                          {/* <SectionsCard /> */}
                          {/* <SectionsCard /> */}
                          {/* <div className="file-item" >
                          <Card className='content-card text-center'>About Us</Card>
                        </div> */}
                          {/* {sectionDetails.map((sElements, index) => {
                          return <ContentsCard key={sElements.id} sectionDetails={sElements} opacity={isDragging ? '0.5' : '1'} />
                        })} */}
                          {/* <div className="file-item" >
                          <Card className='content-card text-center'>About Us</Card>
                        </div> */}
                        </Card>
                      </Col>
                    </Row>
                    {/* <ThemeMenus sectionDetails={themeSectionHeadings} moveImage={moveImage}/> */}
                    {/* <SectionMenuList images={themeSectionHeadings} moveImage={moveImage}/> */}
                    {/* <SectionMenuList /> */}
                    {/* <ThemeContents sectionDetails={themeSectionHeadings} moveImage={moveImage}/> */}
                  </Col>
                </Row>
              </Col>
              <Col md={8} lg={9} className="">
                <Row>
                  <Col className="pl-0 ml-0">
                    <Card className='p-0 border-0'>
                      <ThemeOneTemplate themeDetails={state.themecolors} />
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </DndProvider>
        </main>
      </div>
    </CardContext.Provider>
  )
}

export default ThemeDesign