import React from 'react';
import ImgSec from './images/img1.jpg'
import './css/styles2.scss'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

const Section2 = () => {
  return (

        <Card className="micro-section-card-v2 card">
            <CardHeader className='main-header'>Section Two Heading</CardHeader>
            <CardBody>
            <Row className="row">
                <Col lg={6} className="card-graphic">
                    <img src={ImgSec} alt="" width="100%" className="card-img" />
                </Col>
                <Col lg={6} className="card-content">
                    <h2 className="card-title">Lorem ipsum dolor sit amet.</h2>
                    {/* <h3 className="h4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Temporibus, obcaecati.</h3> */}
                    {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate facere omnis nisi excepturi, enim expedita est, quisquam repudiandae aspernatur ipsam eius harum ex explicabo iusto, mollitia necessitatibus? Dolore maxime, incidunt totam laborum rerum explicabo ipsum delectus aspernatur molestiae nisi voluptas amet, omnis eligendi, aliquid quaerat?</p> */}
                    <p>Lorem ipsum dolor sit amet consectetur <a href="#">adipisicing elit</a>. Iusto praesentium eius molestiae officiis. Voluptas minima aliquid laborum quisquam. Quo, blanditiis nisi, nam ipsa, earum libero sint dolorem delectus consequuntur qui sequi aut nulla praesentium. Blanditiis dolorem id unde earum. Asperiores modi perferendis esse dolores dolorem sapiente. Ipsum quos sequi quasi. Impedit, aspernatur culpa animi obcaecati ea facere necessitatibus beatae quos rem minima iste est voluptatum sequi quaerat ipsum soluta blanditiis consequuntur architecto, similique aut nulla quibusdam? Quisquam illum maxime nam?</p>
                </Col>
            </Row>
            </CardBody>
        </Card>

  )
}

export default Section2