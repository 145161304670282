import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactQuill from "react-quill";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PostUploadDocument from "../CreatePosthome/Component/PostUploadDocument";

const AddEvent = ({ toggle }) => {
  return (
    <div className="create-common">
      <Row>
        <Col md={12}>
          <FormGroup>
            <div className="write-preview p-0">
              <Input type="file" hidden id="upload-cover-img" />
              <label className="cover-img" htmlFor="upload-cover-img">
                <FontAwesomeIcon icon={faCamera} />
                <h3>Upload a cover for event</h3>
                <p className="para-hints">
                  Minimum width 480 pixels, 16: 9 recommended{" "}
                </p>
              </label>
            </div>
          </FormGroup>
          <Card className="oppotunity-card">
            <CardBody>
              <div className="oppor-title">Event type</div>
              <div className="opportunity-type">
                <FormGroup check>
                  <Input name="radio1" type="radio" id="job" />
                  <Label check htmlFor="job">
                    Online
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Input name="radio1" type="radio" id="Mentorship" />
                  <Label check htmlFor="Mentorship">
                    In person
                  </Label>
                </FormGroup>
              </div>
            </CardBody>
          </Card>
          <div className="mt-3">
            <FormGroup>
              <Label for="Eventname">Event name</Label>
              <Input
                id="Eventname"
                name="email"
                placeholder="Event name"
                type="text"
              />
            </FormGroup>
            <FormGroup>
              <Label for="Location">Time Zone</Label>
              <Input
                id="Location"
                name="Location"
                placeholder="Location"
                type="select"
              >
                <option>Mumbai, Maharastra (GMT+5:30)</option>
                <option>Kolkata, West Bengal (GMT+5:30)</option>
                <option>Chennai, Tamilnadu (GMT+5:30)</option>
                <option>Delhi, Delhi (GMT+5:30)</option>
              </Input>
            </FormGroup>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="linkto-apply">Start date</Label>
                  <Input
                    id="linkto-apply"
                    name="linkto-apply"
                    placeholder="Link to apply"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="linkto-apply">End date</Label>
                  <Input
                    id="linkto-apply"
                    name="linkto-apply"
                    placeholder="Link to apply"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="linkto-apply">Start time</Label>
                  <Input
                    id="linkto-apply"
                    name="linkto-apply"
                    placeholder="Link to apply"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="linkto-apply">End time</Label>
                  <Input
                    id="linkto-apply"
                    name="linkto-apply"
                    placeholder="Link to apply"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label for="Eventlink">Event link</Label>
              <Input
                id="Eventlink"
                name="Event link"
                placeholder="www.event.com/link"
                type="text"
              />
            </FormGroup>
            <FormGroup>
              <Label for="EventDescription">Event description</Label>
              <ReactQuill theme="snow" value={"Enter description"} />
            </FormGroup>
            <FormGroup>
              <Label for="Eventlink">Speaker</Label>
              <Input
                id="Speaker"
                name="Speaker"
                placeholder="Speaker"
                type="text"
              />
            </FormGroup>
          </div>
          <PostUploadDocument />
          <div className="d-flex align-items-center justify-content-center">
            <Button
              id="ae_btn_cancel"
              onClick={toggle}
              type="button"
              color="danger"
              className="cancel-btn"
            >
              Cancel
            </Button>
            <Button id="ae_btn_submit" color="primary" className="post-btn ml-3">
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AddEvent;
