
import React from 'react';
import {  Modal, ModalHeader, ModalBody } from 'reactstrap';
import ProMembershipForm from "./ProMembershipForm"
export const  RenderProMembershipModal = (props) => {
  const {
    isOpen,
    toggle
  } = props;

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} id="profile-modal" className="modal-dialog-scrollable modal-dialog-centered" backdrop="static">
        <ModalHeader toggle={toggle}>Professional Memberships</ModalHeader>
        <ModalBody>
          <ProMembershipForm {...props}/>
        </ModalBody>
      </Modal>
    </div>
  );
}

