import React, {useState} from "react";
import { Col, Row } from "reactstrap";
// import classnames from "classnames";
import { useSelector } from "react-redux";
import { withAlert } from "react-alert"
import moment from "moment";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faVideo, faCheckCircle, faCalendarPlus, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import RescheduleModal from "../RescheduleModal"
import AppointmentActionItems from "../AppointmentActionItems"
import AlertDialog from "common/AlertDialog"
import { createImageFromInitials, getRandomColor } from "utils/Utils";
import { AppointmentStatus, AppointmentType } from 'utils/Constants'
import { appointmentServices } from "utils/appointmentsServices";
import Videocam from "assets/images/Videocam.png";

const ApptBlock = ({ apptData, apptType, index, label, getAppts, alert }) => {
  
  const [openACM, setOpenACM] = useState(false);
  const [openRCM, setOpenRCM] = useState(false);
  const [openMod, setOpenMod] = useState(false); 
  const practitionerId = useSelector((state) => state.auth.currentUser.practitionerId);
  const { patientProfile: { firstName, lastName }, patientProfileId, appointmentType, appointmentTime, visitType, diagnosis, appointmentStatus } = apptData
  const appointmentTimeLocal = moment.utc(appointmentTime, 'HH:mm:ss').local().format('hh:mm A')

  let cssclasses;
  if(appointmentStatus === AppointmentStatus.BOOKED){
    cssclasses = "appt-content type-booked";
  }else if(appointmentStatus === AppointmentStatus.CANCELLED){
    cssclasses = "appt-content type-cancel";
  }else if(appointmentStatus === AppointmentStatus.REJECTED){
    cssclasses = "appt-content type-cancel";
  }else if(appointmentStatus === AppointmentStatus.CONFIRMED){
    cssclasses =  "appt-content type-confirm";
  }else{
    cssclasses = "appt-content type-complete";
  }

  const toggleOpenACM = () => setOpenACM(!openACM)
  const toggleOpenRCM = () => setOpenRCM(!openRCM)
  const toggleOpenMod = () => setOpenMod(!openMod)
  const handleApptAction = (actionType, time, patientName) => {
    if(actionType === 'accept') {
      toggleOpenACM()
    } else if (actionType === 'reject') {
      toggleOpenRCM()
    }
  }

  const onAccept = () => {
    const payload = { 
      appointmentReference: apptData.appointmentReference,
      approvalType: 'CONFIRMED',
      requestedByUser: practitionerId,
      statusReason: ""
    }
    // console.log("Call api to accept appt", apptData.appointmentSlotId, payload);
    
    appointmentServices.updateAppointmentStatus(apptData.appointmentSlotId, payload)
    .then(response => {
      // console.log("updateAppointmentStatus res", response);
      if(response.status === 200 && response.data && response.data.status === "Success") {
        alert.success("Appointment accepted")
        getAppts()
      }
    })
    .catch(error => {
      console.log("updateAppointmentStatus error", error);
      alert.error(error.message)
    })
    .then(() => {
      toggleOpenACM()
    })
  }
 
  const onReject = () => {
    const payload = { 
      appointmentReference: apptData.appointmentReference,
      approvalType: 'REJECTED',
      requestedByUser: practitionerId,
      statusReason: ""
    }
    // console.log("Call api to reject appt", apptData.appointmentSlotId, payload);
    
    appointmentServices.updateAppointmentStatus(apptData.appointmentSlotId, payload)
    .then(response => {
      // console.log("updateAppointmentStatus res", response);
      if(response.status === 200 && response.data && response.data.status === "Success") {
        alert.success("Appointment rejected")
        getAppts()
      }
    })
    .catch(error => {
      console.log("updateAppointmentStatus error", error);
      alert.error(error.message)
    })
    .then(() => {
      toggleOpenRCM()
    })
  }

  return(
    <div className={cssclasses} key={index}>
      <Row>
        <Col md={3}>
          <div>
            <span className="appt-label">{appointmentType === AppointmentType.VIDEO ? <img src={Videocam} className="mr-1" alt="videoCam" style={{transform: "translate(-20%, -20%)"}}/> : ''}{label}</span>
          </div>
          <div className="appt-info mt-2">
            <span className="rounded">
              <img src={createImageFromInitials(20,`${firstName} ${lastName}`, getRandomColor(patientProfileId.toString()))} className="mr-2" alt="profile-pic"/>
            </span>
            <span className={'text-capitalize patient-name'}>{firstName} {lastName}</span>
          </div>
          <div className="mt-2">{appointmentTimeLocal}</div>
        </Col>
        <Col md={3}>
          <div>Diagnosis</div>
          <div className="appt-info">{diagnosis || "No diagnosis"}</div>
        </Col>
        <Col md={2}>
          <div>Previous Visit</div>
          <div className="appt-info">{visitType || "First Visit"}</div>
        </Col>
        <Col md={4} className="text-right">
          
         <AppointmentActionItems appointmentReference={apptData.appointmentReference} appointmentStatus={appointmentStatus} rescheduleOpen={toggleOpenMod} bookingAction={handleApptAction} type={appointmentType}/>

        </Col>
      </Row>
      
      {/* Accept appt modal */}
      <AlertDialog
        toggle={toggleOpenACM}
        isOpen={openACM}
        isLoading={false}
        handleClickOnYes={onAccept}
        yesLabel={"Yes"}
        noLabel={"No"}
        noProperties={{color:"secondary", outline:true}}
        yesProperties={{color:"primary", filled:true, outline:false}}
        headerTitle={'Accept Appointment'}
        bodyText={<p>Do you want to accept <b>{firstName} {lastName}</b> appointment at <b>{appointmentTimeLocal}</b>?</p>}
      />

      {/* Reject appt modal */}
      <AlertDialog
        toggle={toggleOpenRCM}
        isOpen={openRCM}
        isLoading={false}
        handleClickOnYes={onReject}
        yesLabel={"Yes"}
        noLabel={"No"}
        headerTitle={'Reject Appointment'}
        bodyText={<p>Do you want to reject <b>{firstName} {lastName}</b> appointment at <b>{appointmentTimeLocal}</b>?</p>}
      />
     <RescheduleModal toggle={toggleOpenMod} isOpen={openMod} apptData={apptData} getAppts={getAppts} />
      
    </div>
  )
};

export default withAlert()(ApptBlock);