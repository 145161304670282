import React, { useState } from "react";
import BlueArrow from "../../../../assets/images/svgs/arrow-down-blue-icon.svg";
// import BlueArrow from "../../images/bluearrow.png";
import SharePost from "../SharePost/SharePost";
import Post from "../Post/Post";
import SuggestedConn from "../SuggestedConnections/SuggestedConn";
import UserDetailComponent from "../UserDetailComponent/UserDetailComponent";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from "reactstrap";
import PubNub from "pubnub";
import { PubNubProvider, usePubNub } from "pubnub-react";
import "./Feedcontent.css";
import CreatePosthome from "../CreatePosthome";
import homeIcon from "../../../../assets/images/svgs/home-icon.svg";
import { isPractitioner } from "utils/UserHelper";


const pubnub = new PubNub({
  publishKey: "pub-c-4e63eabd-bc80-4d44-8df4-3fddf4cf6707",
  subscribeKey: "sub-c-ca02f786-92b2-11eb-b45a-4ab5cd6a50d7",
  uuid: "",
  secretKey: "sec-c-MWRmZGQ3NmQtYzgxNC00NDE0LTk1ODEtZjI1N2Q4NWNjNGVk",
});

const FeedContent = ({ change_page, message, channel, timeToken }) => {
  let allFeeds = useSelector(
    (state) => state && state.feed && state.feed.allFeeds
  );

  //feeds from store
  let feed_temp =
    allFeeds &&
    allFeeds.map((feed, index) => (
      <PubNubProvider client={pubnub}>
        <Post feed={feed} key={index} />
      </PubNubProvider>
    ));

  let suggestComp = <SuggestedConn />;
  let index = 4;

  feed_temp && feed_temp.splice(index, 0, suggestComp);
  index += 4;
  const [dropopen, setDropopen] = useState(false)

  function toggle() {
    setDropopen(!dropopen)
  }
  return (
    <>
      {/** 
    <div className="nexogic-heading-sec nexogic-card side-extra">
      <h2 className="nexogix-section-title"><img src={homeIcon} className="icon" /> Home</h2>
      <div className="ml-auto">{process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
            <Dropdown
              isOpen={dropopen}
              toggle={toggle}
              direction="down"
              className="statistic-wrap">

              <DropdownToggle color="primary">
                Show statistics  <img src={BlueArrow} alt="#" className="ml-2" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>Another Action</DropdownItem>
                <DropdownItem>Another Action</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}</div>
    </div>
    */}

      <div className="d-flex feed-main--">
        <div className="left-content">
          <CreatePosthome />
          {/* <SharePost /> */}

          {/* <InfiniteScroll
          dataLength={allFeeds.length}
          next={change_page}
          hasMore={true}
        // loader={
        //   <div style={{ textAlign: "center", marginTop: "10px" }}>
        //     <span className="spinner_feed_content">
        //       <Spinner type="grow" color="primary" />
        //     </span>
        //     <span className="spinner_feed_content">
        //       <Spinner type="grow" color="primary" />
        //     </span>
        //     <span className="spinner_feed_content">
        //       <Spinner type="grow" color="primary" />
        //     </span>
        //   </div>
        // }
        // endMessage={<div>End</div>}
        >
          {feed_temp}
        </InfiniteScroll> */}

        </div>
        {isPractitioner() && (
          <PubNubProvider client={pubnub}>
            <UserDetailComponent
              message={message}
              channel={channel}
              timeToken={timeToken}
            />
          </PubNubProvider>
        )}
      </div>
    </>
  );
};

export default FeedContent;
