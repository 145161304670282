import React from "react";
import { useState } from "react";
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, FormGroup, Input, ListGroup, ListGroupItem, Row } from "reactstrap";
import selected_profile_avatar from 'assets/images/user-profile-sample.png'
import check_icon from 'assets/images/nex-completed-tick.png'
import ReactQuill from "react-quill";
import { OpinionService } from "utils/OpinionService";
import { useEffect } from "react";
import { PractitionerService } from "utils/PractitionerService";
import { FAILED_TO_FETCH_DATA, FAILTOSAVERECOED } from "utils/Constants";
import { createImageFromInitials, getRandomColor, getFullAddressWithLocationCityAndState } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import CommonLoader from "common/CommonLoader";
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";
import { withAlert } from "react-alert";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { useFormikContext } from "formik";

const PractitionerForm = ({ currentStep, setCurrentStep, isSelf, setIsSelf }) => {
    const { values, setFieldValue } = useFormikContext();
    const [opinionInfo, setOpinionInfo] = useState({});
    const [practitioners, setPractitioners] = useState([])
    const [hasMore, setHasMore] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [preSelectedPractitioner, setPreSelectedPractitioner] = useState({});
    const [selectedSpeciality, setSelectedSpeciality] = useState("Acupuncturist")

    const getPractitioners = async (speciality, pageNo = 0) => {
        let sq = `st=='Approved';ps=='${speciality}';so==true`;
        await PractitionerService.getPractitionersForOpinion(sq, pageNo
        ).then((response) => {
            const { status, data } = response;
            if (status === 200) {
                setTotalPages(data?.totalPages);
                setTotalItems(data?.totalItems);
                setCurrentPage(data?.currentPage)
                setHasMore((data?.currentPage + 1) < data?.totalPages);
                setIsLoadingMore(false);
                setPractitioners((practitioners) => [...practitioners, ...data?.contents]);
            } else {
                setIsLoadingMore(false);
                console.log(FAILED_TO_FETCH_DATA);
            }
        });
    };

    const fetchData = () => {
        setIsLoadingMore(true);
        if (values?.speciality && values?.speciality[0])
            getPractitioners(values?.speciality[0], currentPage + 1);
    }

    useEffect(() => {
        if (values?.speciality)
            getPractitioners(values?.speciality, 0);
    }, [values?.speciality])

    const getPreSelectedPractitioner = (id) => {
        PractitionerService.getPractitionerProfileById(id)
            .then((response) => {
                const { status, data } = response;
                if (status == 200) {
                    // console.log(data);
                    setPreSelectedPractitioner(data);
                }
            })
    }

    const handleSelectPractitioner = (e) => {
        setFieldValue('practitionerAssignedTo', e)
    }

    useEffect(() => {
        if (values?.practitionerAssignedTo)
            getPreSelectedPractitioner(values?.practitionerAssignedTo?.profileGuid);
    }, [values?.practitionerAssignedTo])

    return (
        <>
            <CardBody className={"nex-form-step active"}>
                <br />
                <div className="nex-form-field-container">
                    <div className="nex-form-group">
                        <div className="nex-form-group-c mt-0">
                            <label htmlFor="" className="nex-text-xl mb-3">Please Select Practitioner from the Below Options</label>


                            <div className="nexogic-custom-scroll">
                                <ul className="nex-practitioner-check-lists">
                                    {preSelectedPractitioner && preSelectedPractitioner?.profileGuid &&
                                        <li key={preSelectedPractitioner.id} className="active">
                                            <i className="check">
                                                <img src={check_icon} alt="" width={10} />
                                            </i>
                                            <div className="graphic">
                                                {
                                                    preSelectedPractitioner?.avatarId ?
                                                        <img
                                                            src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${preSelectedPractitioner?.profileGuid}/${preSelectedPractitioner?.avatarId}`}
                                                            className="card-img nex-avatar"
                                                            alt="#"
                                                        />
                                                        :
                                                        <>
                                                            <img
                                                                src={createImageFromInitials(
                                                                    50,
                                                                    `${preSelectedPractitioner?.name.replace("Dr. ", "")}`,
                                                                    getRandomColor(
                                                                        encrypt(
                                                                            preSelectedPractitioner?.profileGuid
                                                                        ).toString()
                                                                    )
                                                                )}
                                                                className="card-img nex-avatar"
                                                                alt="#"
                                                            />
                                                        </>
                                                }
                                            </div>

                                            <div className="info w-full">
                                                <h3 className="nex-text-lg bold mb-0 text-truncate" style={{ maxWidth: 'calc(100% - 40px)' }}>
                                                    <a className="text-black" href={`/profile/${preSelectedPractitioner?.profileId}`} target="_blank"> {preSelectedPractitioner?.name}</a>
                                                    <span>{" "}{preSelectedPractitioner?.qualification}</span></h3>
                                                <p className="nex-text bold nex-text-blue">{preSelectedPractitioner?.primarySpeciality}</p>
                                                <p className="nex-text text-black">
                                                    <span className="icon mr-2"><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.61189 16.2058C12.6382 14.6626 15 12.1071 15 9C15 5.68629 12.3137 3 9 3C5.68629 3 3 5.68629 3 9C3 12.1071 5.36181 14.6626 8.3881 16.2058C8.77259 16.4018 9.22741 16.4018 9.61189 16.2058Z" stroke="black" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M11.25 9C11.25 10.2426 10.2426 11.25 9 11.25C7.75736 11.25 6.75 10.2426 6.75 9C6.75 7.75736 7.75736 6.75 9 6.75C10.2426 6.75 11.25 7.75736 11.25 9Z" stroke="black" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg></span>
                                                    {getFullAddressWithLocationCityAndState({ location: preSelectedPractitioner?.location ?? "", city: preSelectedPractitioner?.city ?? "", state: preSelectedPractitioner?.state ?? "" })}</p>
                                                <div className="meta">
                                                </div>
                                            </div>
                                        </li>
                                    }
                                    {practitioners.length > 0 ? practitioners.map((practitioner) => (
                                        <>
                                            {(preSelectedPractitioner?.profileGuid !== practitioner?.profileGuid) &&
                                                <li key={practitioner.id} onClick={() => handleSelectPractitioner(practitioner)} style={{ cursor: "pointer" }}>
                                                    <i className="check" >
                                                        <img src={check_icon} alt="" width={10} />
                                                    </i>
                                                    <div className="graphic">
                                                        {
                                                            practitioner?.avatarId ?
                                                                <img
                                                                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${practitioner?.profileGuid}/${practitioner?.avatarId}`}
                                                                    className="card-img nex-avatar"
                                                                    alt="#"
                                                                />
                                                                :
                                                                <>
                                                                    <img
                                                                        src={createImageFromInitials(
                                                                            50,
                                                                            `${practitioner?.name.replace("Dr. ", "")}`,
                                                                            getRandomColor(
                                                                                encrypt(
                                                                                    practitioner?.profileGuid
                                                                                ).toString()
                                                                            )
                                                                        )}
                                                                        className="card-img nex-avatar"
                                                                        alt="#"
                                                                    />
                                                                </>
                                                        }
                                                    </div>
                                                    <div className="info">
                                                        <h3 className="nex-text-lg bold mb-0">
                                                            <a className="text-black" href={`/profile/${practitioner?.profileId}`} target="_blank"> {practitioner?.name}</a>
                                                            {/*<span>{" "}{practitioner?.qualification}</span>*/}</h3>
                                                        <p className="nex-text bold nex-text-blue">{preSelectedPractitioner?.primarySpeciality}</p>
                                                        <p className="nex-text text-black">
                                                            <span className="icon mr-2"><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M9.61189 16.2058C12.6382 14.6626 15 12.1071 15 9C15 5.68629 12.3137 3 9 3C5.68629 3 3 5.68629 3 9C3 12.1071 5.36181 14.6626 8.3881 16.2058C8.77259 16.4018 9.22741 16.4018 9.61189 16.2058Z" stroke="black" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M11.25 9C11.25 10.2426 10.2426 11.25 9 11.25C7.75736 11.25 6.75 10.2426 6.75 9C6.75 7.75736 7.75736 6.75 9 6.75C10.2426 6.75 11.25 7.75736 11.25 9Z" stroke="black" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg></span>
                                                            {getFullAddressWithLocationCityAndState({ location: practitioner?.location ?? "", city: practitioner?.city ?? "", state: practitioner?.state ?? "" })}</p>
                                                        <div className="meta">
                                                        </div>
                                                    </div>
                                                </li>
                                            }
                                        </>
                                    ))
                                        :
                                        <div className="nexogic-not-found-text">No Practitioner Available for selected Specialty</div>
                                    }
                                    {hasMore &&
                                        <div className="text-center mt-3">
                                            {isLoadingMore
                                                ?
                                                <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                                                :
                                                <LoadMoreButton loadMore={fetchData} />
                                            }
                                        </div>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </CardBody >
        </>
    )
}
export default withAlert()(PractitionerForm);