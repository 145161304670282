import logo_white from "assets/images/logo-white-transparent.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import ReactCodeInput from "react-verification-code-input";
import { useState } from "react";
import { Button } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { maskingPhoneNumber } from 'utils/Utils'
import CountDown from "components/CountDown";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import CommonLoader from "common/CommonLoader";
import { useRef } from "react";
import CountDownTimer from "components/CountDownTimer";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const validationSchema = Yup.object().shape({
  verifyCode: Yup.string()
    .required('Verification code is required')
    // .matches(/^\d{6}$/, 'Verification code must be exactly 6 digits'),
});

const VerifyOtp = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [verifyCodes, setVerifyCodes] = useState("");
  const [canResendOTP, setCanResendOTP] = useState(true);
  const [loading, setLoading] = useState(false);
  const codeInputRef = useRef(null);
  const { handleVerifyOtp, mobileNumber, setOtpSent, handleSubmit, vloading} = props;

  const handleOTPVerification = (values) => {
    // e.preventDefault();
    if(values.verifyCode.length < 6){
      showErrorMessage("OTP must be exactly 6 digits");
      return false;
    }
    handleVerifyOtp(values.verifyCode)
  };

  const handleWrongNumberClick = () => {
    setOtpSent(false);
  };

  const handleResendOTP = (formikProps) => {
    setCanResendOTP(true);
    formikProps.resetForm({ values: { verifyCode: '' } });
    if (codeInputRef.current) {
      codeInputRef.current.state.values = Array(6).fill('');
      codeInputRef.current.forceUpdate();
    }
    handleSubmit(mobileNumber)
  }

  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className="nex-form-fields--w">
      <div className="nex-form-center-slot">
        <div className="nex-form-container">
          <p className="nex-text-lg text-black mb-5" data-aos="fade-down" data-aos-duration="800" data-aos-delay="150">Verify your account by entering the 6 digit code we sent via text to your mobile number {maskingPhoneNumber(mobileNumber)}</p>
          <Formik
            initialValues={{ verifyCode: '' }}
            validationSchema={validationSchema}
            onSubmit={(values) => { handleOTPVerification(values) }}
          >
            {(formikProps) => (
              <Form>
                <div className="form-group nex-otp-group">
                  <Field
                    type="text"
                    name="verifyCode"
                    render={({ field }) => (
                      <ReactCodeInput
                        {...field}
                        ref={codeInputRef}
                        id="vo_fields"
                        type="number"
                        placeholder={[" ", " ", " ", " ", " ", " "]}
                        fields={6}
                        fieldWidth={42}
                        fieldHeight={38}
                        onChange={(val) => { field.onChange({ target: { name: field.name, value: val } }); }}
                        className="verification-code-masked-input"
                      />
                    )}
                  />
                </div>
                <ErrorMessage name="verifyCode" component="div" className="text-danger" />
                {canResendOTP ? (
                  <div className="d-flex justify-content-end">
                    <h6 className="nex-text-sm text-right font-weight-normal text-black mb-5">Not received your OTP? &nbsp;</h6>
                    <CountDownTimer time={60} digital onComplete={() => setCanResendOTP(false)} />
                  </div>
                ) : (
                  <div className="d-flex justify-content-end mb-5">
                    <span size="lg" color="dark" className="btn-link" type="button" onClick={() => handleResendOTP(formikProps)}>
                      <small>Resend OTP</small>
                    </span>
                  </div>
                )}
                <div className="form-group">
                  {vloading ? (
                    <div className="loading-spiner text-center">
                      <CommonLoader />
                    </div>
                  ) :
                    <Button id="nex-continue-form-next2" className="btn btn-primary lg w-100" type="submit">
                      Verify
                    </Button>
                  }
                </div>
              </Form>
            )}
          </Formik>
          <p onClick={handleWrongNumberClick} style={{ color: "#40859B" }} className="nex-text-lg cursor-pointer" data-aos="fade-down" data-aos-duration="800" data-aos-delay="350">Entered a wrong number?</p>
        </div>
      </div>
      <div className="nex-form-bottom-slot">
        <p className="nex-text-lg text-left">If you are having problem receiving OTP <br />
          Please contact to  <a href="https://nexogic.com/contact-us" className="text-light-purple">Support</a></p>
      </div>
    </div>
  )
}

export default VerifyOtp;