import React from "react"
import { Container, Row, Col } from "reactstrap"
import { useHistory, useLocation } from "react-router-dom" 
import { withAlert } from "react-alert"
import { useDispatch } from "react-redux"
import { Formik } from "formik";
import * as Yup from "yup";
import jwtDecode from "jwt-decode"

import Header from "common/Header"
import Footer from "common/Footer"

import API from "utils/API"
import { UserService } from "utils/UserService"
import {  encrypt } from "utils/EncryptDecrypt"
import { setUser, setToken } from "utils/UserHelper"
import { emailNonNumericRegEx } from "utils/Constants"
import { authSetUser } from "redux-helpers/ActionCreator";
import ClaimSignupForm from "../ClaimForms/ClaimSignupForm"

const validationSchema = Yup.object({
  email: Yup.string().max(100).matches(emailNonNumericRegEx, 'Please Enter an valid Email').required("This field is required"),
  password: Yup.string().required("This field is required").min(8, "Use 8 or more characters with a mix of letters, numbers & symbols").matches(/^(?=.*[a-zA-Z])(?=.*[0-9]).{6,}$/,"Use 8 or more characters with a mix of letters, numbers & symbols"),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], "Passwords must match").required('Password confirm is required'),
})

let initialValues = {
  email: '',
  password: '',
  confirmPassword: ''
}

const ClaimProfileSignup = (props) => {
  const {state} = useLocation()
  const  history = useHistory();
  const dispatch = useDispatch()

  React.useEffect(() => {
    // protect direct access
    if(!(state && state.id && (state.phoneNumber || state.email))){
      history.replace("/")
    }

    if(state.email) initialValues.email = state.email
  }, [])

  const onSubmit = async (values) => {   
    // console.log(values)
    // return 
    const {id} = state
    let payload = {
      ...values,
      practitionerId: id
    }
    const response = await UserService.createPassword(payload)
    if(response instanceof Error === false) {
      if (response.data && response.data.status === "SUCCESS") {
        history.replace('/login')
        // Comment old login way
        // const { data: { practitioner, jwt } } = response.data
        
        // // temprary backend fix
        // // practitioner.id = (!!practitioner.id)? practitioner.id : practitioner.practitionerId
        // if (jwt) {
        //   setToken(jwt)
        // }
        // dispatch(authSetUser(practitioner))
        // setUser(practitioner)
        // const { firstName, lastName, practitionerId } = practitioner
        // let currentUserId = encrypt(practitionerId)
        
        // history.push(`/profile/${currentUserId}`)
      } else {
        props.alert.error(response.data.message)
      }
    } else {
      if(response.response){
        let {data} = response.response
        if(typeof(data.message) === 'string'){
          props.alert.error(data.message)  
        } else {
          props.alert.error(response.message)  
        }
      } else {
        props.alert.error(response.message)
      }
    }
  }

    return (
      <section id="claimProfilePage">
        <div className="bg-light">
          <Header />
            <div className="bg-white border-top py-5 content">
              <Container>
                <Row className="py-5 justify-content-center text-center">
                  <Col md={5} sm={8} xs={10}>
                    <h3><strong> Claim Profile Assistance </strong></h3>
                    <p className="mb-4 pb-2">Set password to activate your profile</p>
                    <Formik
                      initialValues={initialValues}
                      onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        children={props => {
                          return <ClaimSignupForm {...props} />
                        }}
                      />
                    </Col>
                  </Row>
              </Container>
            </div>
          <Footer />
        </div>
      </section> 
    )
}


export default withAlert()(ClaimProfileSignup); 