import React from "react";

import tickIcon from "assets/images/onboarding/tick.svg";

const Stepper = ({ steps }) => {
  return (
    <div className="stepper-container pr-2 d-flex flex-row align-items-center">
      {steps.map((step, index) => {
        let active = step.status === "INPROGRESS",
          isCompleted = step.status === "COMPLETED";

        return (
          <div
            key={`${step.key}`}
            className={`step step-col2 width-sm ${active ? "active" : ""} ${isCompleted ? "completed" : ""}`}
          >
            <div
              className="count-outter d-flex flex-row align-items-center"
            >
              <div className="count-inner">
                {isCompleted ? (
                  <img src={tickIcon} alt="#" />
                ) : (
                  <span>{index + 1} </span>
                )}
              </div>

              <div className="ml-2 step-content">
                <span>{step.name}</span>
                {step.status && step.status === "INPROGRESS" ? (
                  <div className="status inprogress">In progress</div>
                ) : isCompleted ? (
                  <div className="status completed">Completed</div>
                ) : null}
              </div>
            </div>
            {/* {index !== steps.length-1 &&
            <div className="step-path">
              <div className="step-vline m-auto"></div>
            </div>
          } */}
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
