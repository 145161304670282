import { toast } from 'react-toastify';

// Success message for 10 seconds
const showSuccessMessage = (message) => {
  toast.success(message, {
    position: "top-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

//Error message for 30 seconds
const showErrorMessage = (message) => {
    toast.error(message, {
    position: "top-center",
    autoClose: 30000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export { showSuccessMessage, showErrorMessage };