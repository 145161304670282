import React from "react";
import { useState } from "react";
import { Button, CardBody, FormGroup, Input } from "reactstrap";
import { createImageFromInitials, getAgeFromDOB, getFullNameOfPerson, getGenderDetails, getRandomColor } from "utils/Utils";
import { useEffect } from "react";
import { encrypt } from "utils/EncryptDecrypt";
import { withAlert } from "react-alert";
import { Field, useFormikContext } from "formik";
import PrimarySpecialityTypeaheadField from "components/forms/formikFields/PrimarySpecialityTypeaheadField";
import { MetadataService } from "utils/MetadataService";
import FormikTextAreaField from "components/forms/formikFields/FormikTextAreaField";

const PersonalDetailsForm2 = ({ currentStep, setCurrentStep, isSelf, setIsSelf, currentUserDetails }) => {
    const { values, setFieldValue } = useFormikContext();
    const [primarySpecialityList, setPrimarySpecialityList] = useState([]);
    const getprimarySpeciality = () => {
        MetadataService.getPrimarySpecialities()
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setPrimarySpecialityList(response.data);
                }
            })
            .catch((error) => {
                console.log("MetaDataService.getprimarySpeciality error", error);
            });
    };

    const handlePrimarySpeciality = () => {
    }

    useEffect(() => {
        getprimarySpeciality();
    }, [])

    return (
        <>
            <CardBody className={"nex-form-step active"}>
                <div className="nex-form-field-container">
                    <div className="nex-form-group">
                        <div className="nex-form-group-c mt-mobile-0">
                            <label htmlFor="" className="nex-text-xxl mb-5">Second opinion for </label>
                            <div className="nex-stack mb-3 form-group">
                                <div className="nex-avatar-info nex-flex">
                                    <div className="graphic">
                                        <img
                                            src={createImageFromInitials(
                                                50,
                                                `${getFullNameOfPerson(values?.requestedFor?.firstName, values?.requestedFor?.middleName, values?.requestedFor?.lastName)}`,
                                                getRandomColor(
                                                    encrypt(
                                                        values?.requestedFor?.id
                                                    ).toString()
                                                )
                                            )}
                                            className="nex-avatar lg"
                                            alt="#"
                                        />

                                    </div>
                                    <div className="info">
                                        <h3 className="nex-text-lg">{getFullNameOfPerson(values?.requestedFor?.firstName, values?.requestedFor?.middleName, values?.requestedFor?.lastName)}</h3>
                                        <p>{getGenderDetails(values?.requestedFor?.gender)}</p>
                                        {values?.requestedFor?.emailAddress &&
                                            <p>Email : {values?.requestedFor?.emailAddress}</p>
                                        }
                                        {values?.requestedFor?.cellularPhoneNumber &&
                                            <p>Phone : {values?.requestedFor?.cellularPhoneNumber}</p>
                                        }

                                    </div>
                                </div>
                            </div>

                            <div>
                                <label htmlFor="" className="nex-text-lg large">Describe your health issue in brief ?</label>
                                <FormGroup className="v2">
                                    <Field
                                        id="title"
                                        name="title"
                                        value={values.title}
                                        autoComplete="off"
                                        component={FormikTextAreaField}
                                        placeholder={"Enter Your health issue..."}
                                    />
                                </FormGroup>
                            </div>

                            <div>
                                <label htmlFor="" className="nex-text-lg large">Choose Doctor Specialty</label>
                                <FormGroup className="v2">
                                    <Field
                                        id="speciality"
                                        name="speciality"
                                        autoComplete="speciality"
                                        className="primary-specialities"
                                        type="text"
                                        component={PrimarySpecialityTypeaheadField}
                                        placeholder="Select Primary Speciality"
                                        inputprops={{
                                            autoComplete: "off",
                                        }}
                                        spellCheck={false}
                                        specialit={primarySpecialityList}
                                        onChange={handlePrimarySpeciality}
                                    />
                                </FormGroup>
                            </div>
                        </div >
                    </div >
                </div >
            </CardBody >
        </>
    );
};

export default withAlert()(PersonalDetailsForm2);
