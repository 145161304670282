import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import prizeIcon from "assets/images/svgs/prize_icon.svg";
import classnames from "classnames";
import AlertDialog from "common/AlertDialog";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import {
  profileAddEvent,
  setGlobalprofile,
  updateProfileCompletness,
} from "redux-helpers/ActionCreator";
import { UserStatusConstants } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import {
  getEmptySectionShowHide,
  getFullAddress,
  makeFullNameWithAddressWhenAddressIsNested,
  renderDateRange,
} from "utils/Utils";
import { RenderAwardModal } from "./components/RenderAwardModal";
import small_marker from "assets/svgIcon/marker-small.svg";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import awards_icon from "assets/images/awords.png";
import plusIcon from "assets/images/plus-icon-lg.png";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import del_red_icon from "assets/images/del-red-icon.png";
import NoData from "../NoData";

const RenderAwards = (props) => {
  const { awards, handleEditItem, handleRemoveItem, isLoggedinUser, isAdmin } =
    props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  if (awards && awards.length > 0) {
    const sortAwards = awards.sort((a, b) => {
      return b.issuedYear - a.issuedYear;
    });

    return sortAwards.map((item, index) => {
      const { issuedYear, institutionDetail } = item;
      const timeDuration = renderDateRange(issuedYear, "", false, "", true);
      const institutionName = getFullAddress({
        name: institutionDetail?.name ?? "",
        abbreviation: institutionDetail?.abbreviation ?? "",
        location: institutionDetail?.address?.location ?? "",
        city: institutionDetail?.address?.city ?? "",
        state: institutionDetail?.address?.state ?? "",
      });
      const profileIdLink = institutionDetail.profileId
        ? institutionDetail?.profileId
        : institutionDetail?.guid;
      return (
        <li
          className="nex-icon-list-item"
          key={`award_${item.guid}`}
        >
          <div className="card-graphic">
            <img src={awards_icon} alt="" />
          </div>
          <div className="nex-info">
            <h3 className="card-title">{item.name}</h3>
            <p className="card-text">
              <Link
                to={`/institution/${profileIdLink}`}                
              >{institutionName}</Link>
            </p>

            {timeDuration && (
              <p className="card-text">
                Issued On: {timeDuration}
              </p>
            )}

            <div className="nex-action">
              {(isAdmin ||
                (isLoggedinUser &&
                  currentUser.status === UserStatusConstants.APPROVED)) && (
                  <div className="delete-action">
                    <Button
                      color="link"
                      className="visible-hover py-0 nex-ar-awards-edit-btn"
                      onClick={() => handleEditItem(item)}
                    >
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="15.9062" cy="16" r="15.375" fill="white" stroke="#DBF4FB" stroke-width="0.75" />
                        <path d="M19.3612 9.53125L17.8557 11.0368L20.8667 14.0478L22.3722 12.5423L19.3612 9.53125ZM16.3502 12.5423L10.3281 18.5643V21.5754H13.3392L19.3612 15.5533L16.3502 12.5423Z" fill="#46ABC9" />
                      </svg>
                    </Button>

                    <Button
                      color="link"
                      className="visible-hover delete py-0 nex-ar-awards-del-btn"
                      onClick={() => handleRemoveItem(item)}
                    >
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16.2266" cy="16" r="15.75" fill="#FFEAEF" />
                        <path d="M11.3984 13.7734L12.2586 20.1323C12.426 21.3702 12.5097 21.9892 12.9337 22.3597C13.3577 22.7302 13.9823 22.7302 15.2315 22.7302H18.4692C19.7184 22.7302 20.343 22.7302 20.767 22.3597C21.191 21.9892 21.2747 21.3702 21.4421 20.1323L22.3023 13.7734" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14.5156 11.0475V11.0475C14.5156 10.2672 14.5156 9.87708 14.6572 9.57473C14.8061 9.25693 15.0616 9.00138 15.3794 8.85254C15.6818 8.71094 16.0719 8.71094 16.8522 8.71094V8.71094C17.6324 8.71094 18.0226 8.71094 18.3249 8.85254C18.6427 9.00138 18.8983 9.25693 19.0471 9.57473C19.1887 9.87708 19.1887 10.2672 19.1887 11.0475V11.0475" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M22.3023 11.0547L11.3984 11.0547" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14.8906 14.1719L15.28 20.0132" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.7957 14.1719L18.4062 20.0132" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </Button>
                  </div>
                )}
            </div>
          </div>

          {/** old code */}
          <div className="row g-0 d-none">
            <div className="col-auto">
              <img src={awards_icon} alt="" />
            </div>
            <div className="col">
              <h3 className="card-title">{item.name}</h3>
              <p className="card-title mb-0"><small className="text-muted fw-semibold"><Link
                to={`/institution/${profileIdLink}`}
                className="mb-2 color-accent"
              >{institutionName}</Link></small></p>

              {timeDuration && (
                <p className="card-text"><small className="text-muted">
                  Issued On: {timeDuration}
                </small>
                </p>
              )}
            </div>

            {/** 
            <div className="award flex-grow-1">
              <div className="d-flex">
                <div className="prize-icon badgeshape">
                  <img src={prizeIcon} width="20" alt="" />
                </div>
                <div className="ml-2">
                  <h6 className="section-item-title f-14 m-0">{item.name}</h6>
                  <div className="section-item-shortdes mt-2">
                    <Link
                      to={`/institution/${profileIdLink}`}
                      className="mb-2 color-accent"
                    >
                      {institutionName}
                    </Link>
                  </div>
                  {timeDuration && (
                    <div className="section-item-shortdes mt-2">
                      Issued On: {timeDuration}
                    </div>
                  )}
                </div>
              </div>
            </div>
*/}


            <div className="col-auto d-flex align-items-start">
              {(isAdmin ||
                (isLoggedinUser &&
                  currentUser.status === UserStatusConstants.APPROVED)) && (
                  <div className="delete-action">
                    <Button
                      color="link"
                      className="visible-hover py-0 nex-ar-awards-edit-btn"
                      onClick={() => handleEditItem(item)}
                    >
                      <img src={edit_blue_icon} alt="" />
                    </Button>

                    <Button
                      color="link"
                      className="visible-hover delete py-0 nex-ar-awards-del-btn"
                      onClick={() => handleRemoveItem(item)}
                    >
                      <img src={del_red_icon} alt="" />
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </li>
      );
    });
  } else return null;
};

const AwardsRecognitions = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const eventName = useSelector((state) => state.profileComplete.eventName);
  const dispatch = useDispatch();
  const { alert, isLoggedinUser, isAdmin, setShowAwardsOnTabScroll } = props;
  const practitionerGuid = props.practitioner.guid;
  const [awards, setAwards] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);
  const [isOpenAlert, setAlertModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  useEffect(() => {
    dispatch(updateProfileCompletness(9, !!awards.length));

    if (awards.length > 0 || isLoggedinUser || isAdmin) {
      setShowAwardsOnTabScroll(true);
    } else {
      setShowAwardsOnTabScroll(false);
    }

  }, [awards]);

  useEffect(() => {
    if (eventName === "ADD_AWARDS") {
      handleEditItem(initialValue);
      dispatch(profileAddEvent(null));
    }
  }, [eventName]);

  const toggleAlert = () => {
    setLoading(false);
    setAlertModal(!isOpenAlert);
  };

  const initialValue = {
    mainItemName: "",
    institutionDetail: {
      institutionName: "",
      guid: "",
      onSelectNewinstitution: 0,
    },
    addressLine1: "",
    addressLine2: "",
    city: [],
    country: [],
    location: [],
    postalCode: "",
    state: [],
    addressType: "BILLING_ADDRESS",
    mainCategory: "",
    issuedMonthYear: "",
    name: "",
  };

  useEffect(() => {
    if (practitionerGuid) {
      getData();
    }
  }, [practitionerGuid]);

  const formatEditItemData = (item) => {
    return {
      institutionDetail: {
        institutionName: item?.institutionDetail ? makeFullNameWithAddressWhenAddressIsNested(item?.institutionDetail) : "",
        guid: item?.institutionDetail.guid ?? "",
        ...(item?.guid && {
          onSelectNewinstitution: 1,
        }),
      },
      category: "",
      addressLine1: "",
      addressLine2: "",
      city: [],
      country: [],
      location: [],
      state: [],
      postalCode: "",
      addressType: "BILLING_ADDRESS",
      mainCategory: item?.category ?? "",
      issuedMonthYear: item?.issuedYear ?? "",
      mainItemName: item?.institutionDetail.name ?? "",
      name: item?.name ?? "",
      id: item?.guid ?? "",
    };
  };

  const handleEditItem = (selectedItem) => {
    setSelectedItem(formatEditItemData(selectedItem));
    toggle();
  };

  const handleRemoveItem = (selectedItem) => {
    setSelectedItem(formatEditItemData(selectedItem));
    toggleAlert();
  };

  const updateGlobalprofileStorage = (guid) => {
    PractitionerService.gePractitionersDetails(guid).then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(setGlobalprofile(response.data));
      }
    });
  };

  const handleClickOnYes = async () => {
    setLoading(true);
    try {
      const request = PractitionerService.deleteAwards(
        globalProfileInfo?.guid,
        selectedItem.id
      );
      request.then(
        async (response) => {
          const { data, status } = response;
          if (status === 204) {
            await getData();
            updateGlobalprofileStorage(globalProfileInfo?.guid);
            setLoading(false);
            toggleAlert();
            showSuccessMessage("Award deleted successfully");
          } else {
            showErrorMessage(data.status.message);
            setLoading(false);
            toggleAlert();
          }
        },
        (e) => {
          setLoading(false);
          let errmsg = e.message;
          if (e.response) {
            errmsg =
              e.response.data && !!e.response.data.message
                ? e.response.data.message
                : errmsg;
          }
          showErrorMessage(errmsg);
        }
      );
    } catch (e) {
      showErrorMessage(e.message);
      setLoading(false);
      toggleAlert();
    }
  };

  const getData = async () => {
    const request = PractitionerService.getAwards(practitionerGuid);
    request.then((response) => {
      let { data } = response;
      if (data && data.length > 0) {
        setAwards(data);
      } else {
        setAwards([]);
      }
    });
  };

  if (
    getEmptySectionShowHide(
      awards ?? [],
      isAdmin,
      currentUser,
      globalProfileInfo,
      practitionerGuid
    )
  )
    return null;

  return (
    <>
      <Card className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="awardsSection">
        <div className="card-header"><h2>Awards, Recognitions, Honors & Fellowship</h2>
          {(isAdmin ||
            (isLoggedinUser &&
              currentUser.status === UserStatusConstants.APPROVED)) && (
              <Button
                id="nex-ar-awards-add-btn"
                color="link"
                className="add-button ml-auto"
                onClick={() => handleEditItem(initialValue)}
              > <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9.75" cy="9.75" r="8.4375" fill="white" stroke="#46ABC9" stroke-width="1.125" />
                  <path d="M9.625 6L9.625 13.2539" stroke="#46ABC9" stroke-width="1.125" stroke-linecap="round" />
                  <path d="M13.2656 9.63281L6.01172 9.63281" stroke="#46ABC9" stroke-width="1.125" stroke-linecap="round" />
                </svg> Add More
              </Button>
            )}
          <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
            <svg width={17} height={10} viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg" >
              <path
                d="M2.25 1.625L8.625 8L15 1.625"
                stroke="black"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <div className={`collapse-div`}>
          <CardBody className={
            awards && awards.length > 0 ? "" : "align-items-center"
          }>

            {/**  
          <Row
            className={
              awards && awards.length > 0 ? "mb-3" : "align-items-center mb-3"
            }
          >
            <Col>
              <h3 className="section-title m-0 mb-3">
                Awards, Recognitions, Honors & Fellowship
              </h3>
              {awards && awards.length
                ? ""
                : (isLoggedinUser || isAdmin) &&
                  currentUser.status === UserStatusConstants.APPROVED && (
                    <div className="mt-3">
                      <Row className="align-items-center">
                        <Col className="add-infomation-part">
                          <Button
                            className="add-infomation p-0"
                            color="link"
                            onClick={() => handleEditItem(initialValue)}
                          >
                            {"Add your awards"}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  )}
            </Col>
            {(isAdmin ||
              (isLoggedinUser &&
                currentUser.status === UserStatusConstants.APPROVED)) && (
              <Col xs="auto">
                <Button
                  color="link"
                  className="round-icon-btn rounded-circle"
                  onClick={() => handleEditItem(initialValue)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Col>
            )}
          </Row>
                
 */}
            <ul className="nex-icon-list-within-card" id="nex-ar-awards-lists">
              <RenderAwards
                awards={awards}
                handleEditItem={handleEditItem}
                handleRemoveItem={handleRemoveItem}
                isLoggedinUser={isLoggedinUser}
                isAdmin={isAdmin}
              />
            </ul>
            {awards.length < 1 &&
              <NoData><p>No Data</p>
                {awards && awards.length
                  ? ""
                  : (isLoggedinUser || isAdmin) &&
                  currentUser.status === UserStatusConstants.APPROVED && (
                    <Button
                      id="nex-ar-awards-add-btn2"
                      className="readmore"
                      color="link"
                      onClick={() => handleEditItem(initialValue)}
                    >
                      {"Add your awards"}
                    </Button>
                  )}
              </NoData>
            }
            <RenderAwardModal
              selectedItem={selectedItem}
              toggle={toggle}
              isOpen={isOpen}
              getData={getData}
              isAdmin={isAdmin}
            />
            <AlertDialog
              toggle={toggleAlert}
              isOpen={isOpenAlert}
              isLoading={isLoading}
              handleClickOnYes={handleClickOnYes}
              yesLabel={"Yes"}
              noLabel={"No"}
            />
          </CardBody>
        </div>
      </Card>
    </>
  );
};

export default withAlert()(AwardsRecognitions);
