import React from "react"

import { Redirect } from "react-router-dom"

import { isAdmin } from "utils/UserHelper"

const withAdminAuth = Component => props => {
  return isAdmin() ? <Component {...props} /> : <Redirect to="/login" />
}

export default withAdminAuth
