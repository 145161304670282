import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikInputMonthYearFormatField from "components/forms/formikFields/FormikInputMonthYearFormatField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { Field, Formik } from "formik";
import { useState } from "react";
import { withAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Label, Row, Spinner } from "reactstrap";
import { setGlobalprofile } from "redux-helpers/ActionCreator";
import { FAILTOSAVERECOED, list_Of_Awards_Type } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import { getAddressName, getLocationName } from "utils/Utils";
import * as Yup from "yup";
import AwardsInstitutesTypeaheadField from "./AwardsInstitutesTypeaheadField";
import NewInstituitionSection from "./NewInstituitionSection";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{5})(( x| ext)\d{1,5}){0,1}$/;
export const yearmonth = /^(0[1-9]|10|11|12)-\d{4}$/;

const validationSchema = Yup.object().shape({
  institutionDetail: Yup.lazy((value, main) => {
    if (value && value.onSelectNewinstitution === 0) {
      return Yup.object().test(
        "",
        "This field is required. You must select from options or add new",
        () => false
      );
    } else if (value && value.onSelectNewinstitution === 1) {
      return Yup.object();
    } else if (value && value.onSelectNewinstitution === 2) {
      return Yup.object();
    } else if (main?.parent?.mainCategory !== "AWARD_AND_RECOGNITION") {
      return Yup.mixed().test(
        "",
        "This field is required. You must select from options or add new",
        (value) => value === {}
      );
    } else {
      return Yup.object();
    }
  }),
  city: Yup.array().when(
    ["institutionDetail.onSelectNewinstitution", "mainCategory"],
    {
      is: (item1, item2) => item1 === 2 && item2 === "FELLOWSHIP",
      then: Yup.array()
        .min(1, "This field is required")
        .required("This field is required"),
    }
  ),
  state: Yup.array().when(
    ["institutionDetail.onSelectNewinstitution", "mainCategory"],
    {
      is: (item1, item2) => item1 === 2 && item2 === "FELLOWSHIP",
      then: Yup.array()
        .min(1, "This field is required")
        .required("This field is required"),
    }
  ),
  country: Yup.array().when(
    ["institutionDetail.onSelectNewinstitution", "mainCategory"],
    {
      is: (item1, item2) => item1 === 2 && item2 === "FELLOWSHIP",
      then: Yup.array()
        .min(1, "This field is required")
        .required("This field is required"),
    }
  ),
  location: Yup.array().when(
    ["institutionDetail.onSelectNewinstitution", "mainCategory"],
    {
      is: (item1, item2) => item1 === 2 && item2 === "FELLOWSHIP",
      then: Yup.array()
        .min(1, "This field is required")
        .required("This field is required"),
    }
  ),
  mainItemName: Yup.string().when("institutionDetail.onSelectNewinstitution", {
    is: 2,
    then: Yup.string().required("This field is required"),
  }),
  name: Yup.string().required("This field is required"),
  phone: Yup.string().when("institutionDetail.onSelectNewinstitution", {
    is: 2,
    then: Yup.string().when("phone", {
      is: (val) => val !== "",
      then: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
      otherwise: Yup.string(),
    }),
  }),
  category: Yup.string().when(
    ["institutionDetail.onSelectNewinstitution", "mainCategory"],
    {
      is: (item1, item2) => item1 === 2 && item2 === "FELLOWSHIP",
      then: Yup.string().required("This field is required"),
    }
  ),
  postalCode: Yup.string().when(
    ["institutionDetail.onSelectNewinstitution", "mainCategory"],
    {
      is: (item1, item2) => item1 === 2 && item2 === "FELLOWSHIP",
      then: Yup.string().required("This field is required"),
    }
  ),
  addressLine1: Yup.string().when(
    ["institutionDetail.onSelectNewinstitution", "mainCategory"],
    {
      is: (item1, item2) => item1 === 2 && item2 === "FELLOWSHIP",
      then: Yup.string().required("This field is required"),
    }
  ),
  websiteAddress: Yup.string().when(
    "institutionDetail.onSelectNewinstitution",
    {
      is: 2,
      then: Yup.string().when("websiteAddress", {
        is: (val) => val !== "",
        then: Yup.string().url("Please enter a valid URL"),
        otherwise: Yup.string(),
      }),
    }
  ),
  mainCategory: Yup.string().required("This field is required"),
  issuedMonthYear: Yup.string()
    .matches(yearmonth, "Please enter valid format (MM-YYYY)")
    .required("This field is required"),
});

const RenderForm = (props) => {
  const { handleSubmit, isLoading, values, setFieldValue, toggle } = props;

  const onNewInstitute = () => {
    toggleNested();
  };

  const toggleNested = () => {
    setFieldValue(`institutionDetail.onSelectNewinstitution`, 2);
    setFieldValue(`institutionDetail.institutionName`, "Other");
    setFieldValue(`institutionDetail.guid`, "");
  };

  const resetValue = () => {
    setFieldValue(`institutionDetail`, {
      institutionName: "",
      guid: "",
    });
    setFieldValue(`addressLine1`, "");
    setFieldValue(`addressLine2`, "");
    setFieldValue(`city`, []);
    setFieldValue(`country`, []);
    setFieldValue(`location`, []);
    setFieldValue(`state`, []);
    setFieldValue(`postalCode`, "");
  };

  return (
    <form className="" onSubmit={handleSubmit} id="nex-af-awward-form">
      <div>
        <Row form>
          <Col md={12}>
            <Label>Category</Label>
            <Field
              id={`mainCategory`}
              name={`mainCategory`}
              autoComplete={`mainCategory`}
              component={FormikSelectField}
              onChange={(e) => {
                resetValue();
                setFieldValue(`mainCategory`, e?.currentTarget?.value);
                setFieldValue(`name`, "");
              }}
              inputprops={{
                options: list_Of_Awards_Type,
                defaultOption: "Choose",
                keys: {
                  id: "value",
                  label: "label",
                },
              }}
            />
          </Col>
          <Col md={12} id="nex-af-awward-institution-name">
            <label className="d-block">Institution Name</label>
            <Field
              name={`institutionDetail`}
              id={`institutionDetail`}
              autoComplete="off"
              type="text"
              component={AwardsInstitutesTypeaheadField}
              onNewInstituteReq={onNewInstitute}
              placeholder="Institution Name"
            />
          </Col>

          {values?.institutionDetail?.onSelectNewinstitution === 2 && (
            <NewInstituitionSection {...props} />
          )}

          <Col md={12}>
            <Field
              id="name"
              name="name"
              autoComplete="off"
              type="text"
              label="Name"
              component={FormikInputField}
              placeholder={
                values.mainCategory === "FELLOWSHIP"
                  ? "Type fellowship name e.g. FRCS"
                  : "Name"
              }
              maxLength={values.mainCategory === "FELLOWSHIP" ? 8 : null}
            />
          </Col>

          <Col md={12}>
            <Field
              name={`issuedMonthYear`}
              id={`issuedMonthYear`}
              autoComplete="off"
              type="text"
              component={FormikInputMonthYearFormatField}
              label="Issued On"
              placeholder="MM-YYYY"
            />
          </Col>
        </Row>
      </div>
      {/*<hr className="profile-info-modal-hr mt-0" />*/}
      <div className="justify-content-end  modal-footer">
        <Col xs="auto" className="action-group">
          {isLoading ? (
            <div className="loading-spiner">
              <Spinner />
            </div>
          ) : (
            <>
            <Button id="af_btn_submit" className="nexogic_primary_button" color="primary" type="submit">
                Save
              </Button>
              <Button id="af_btn_submit"
                className="nexogic_primary_button_outline "
                color="primary"
                type="button"
                onClick={toggle}
              >
                Cancel
              </Button>
              
            </>
          )}
        </Col>
      </div>
    </form>
  );
};

const AwardsForm = (props) => {
  const { selectedItem, getData, toggle, alert } = props;
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  const updateGlobalprofileStorage = (guid) => {
    PractitionerService.gePractitionersDetails(guid).then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(setGlobalprofile(response.data));
      }
    });
  };

  const addItem = async (values) => {
    const formData = {
      category: values?.mainCategory,
      institutionDetail: {
        ...(!values?.institutionDetail?.guid &&
          values.city.length > 0 && {
            address: {
              addressLine1: values?.addressLine1 ?? "",
              addressLine2: values?.addressLine2 ?? "",
              addressType: values?.addressType ?? "BILLING_ADDRESS",
              city: getAddressName(values?.city),
              state: getAddressName(values?.state),
              country: getAddressName(values?.country),
              location: getLocationName(values?.location),
              postalCode: values?.postalCode ?? "",
            },
          }),
        ...(values?.institutionDetail?.guid && {
          guid: values.institutionDetail.guid,
        }),
        name: values?.mainItemName,
        phone: values?.phone ?? "",
        websiteUrl: values?.websiteAddress ?? "",
        ...(values.category && {
          type: values?.category,
        }),
      },
      name: values?.name,
      issuedYear: values?.issuedMonthYear,
    };
    setLoading(true);
    PractitionerService.registerAwards(formData, globalProfileInfo?.guid, true)
      .then(async (response) => {
        const { status, validationErrors } = response;
        setLoading(false);
        if (status === 201) {
          await getData();
          updateGlobalprofileStorage(globalProfileInfo?.guid);
          toggle();
          showSuccessMessage("Award added successfully");
        } else {
          if (
            validationErrors &&
            validationErrors &&
            validationErrors.length > 0
          ) {
            const error = [];
            validationErrors.forEach((errors) => {
              error.push(errors.message);
            });
            showErrorMessage(error.join(","));
          } else {
            showErrorMessage(FAILTOSAVERECOED);
          }
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setLoading(false);
        console.log(error);
      });
  };

  const updateItem = async (values, id) => {
    const formData = {
      category: values?.mainCategory,
      institutionDetail: {
        ...(!values?.institutionDetail?.guid &&
          values.city.length > 0 && {
            address: {
              addressLine1: values?.addressLine1 ?? "",
              addressLine2: values?.addressLine2 ?? "",
              addressType: values?.addressType ?? "BILLING_ADDRESS",
              city: getAddressName(values?.city),
              state: getAddressName(values?.state),
              country: getAddressName(values?.country),
              location: getLocationName(values?.location),
              postalCode: values?.postalCode ?? "",
            },
          }),
        ...(values?.institutionDetail?.guid && {
          guid: values.institutionDetail.guid,
        }),
        name: values?.mainItemName ?? "",
        phone: values?.phone ?? "",
        websiteUrl: values?.websiteAddress ?? "",
        ...(values.category && {
          type: values?.category,
        }),
      },
      name: values?.name ?? "",
      issuedYear: values?.issuedMonthYear ?? "",
    };
    setLoading(true);
    PractitionerService.updateAwards(
      formData,
      globalProfileInfo?.guid,
      id,
      true
    )
      .then(async (response) => {
        const { status, validationErrors } = response;
        setLoading(false);
        if (status === 200) {
          await getData();
          updateGlobalprofileStorage(globalProfileInfo?.guid);
          toggle();
          showSuccessMessage("Award updated successfully");
        } else {
          if (
            validationErrors &&
            validationErrors &&
            validationErrors.length > 0
          ) {
            const error = [];
            validationErrors.forEach((errors) => {
              error.push(errors.message);
            });
            showErrorMessage(error.join(","));
          } else {
            showErrorMessage(FAILTOSAVERECOED);
          }
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={selectedItem}
        onSubmit={(values) => {
          if (values.id) {
            updateItem(values, values.id);
          } else {
            addItem(values);
          }
        }}
        children={(props) => {
          return (
            <>
              <RenderForm toggle={toggle} {...props} isLoading={isLoading} />
            </>
          );
        }}
      ></Formik>
    </>
  );
};

export default withAlert()(AwardsForm);
