import React, { useState } from "react";
import { Col, Row, Popover, PopoverBody } from "reactstrap";
// import classnames from "classnames";
import { withAlert } from "react-alert"
import { useSelector } from "react-redux";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt, faVideo, faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";

import AlertDialog from "common/AlertDialog"
import { appointmentServices } from "utils/appointmentsServices";
import { AppointmentType, AppointmentStatus } from "utils/Constants";
import Videocam from "assets/images/Videocam.png";
import RescheduleModal from "../RescheduleModal"
import "./style.scss";

const ApptBlock = ({ apptData, apptType, index, label, getAppts, alert }) => {
  const [openACM, setOpenACM] = useState(false)
  const [openRCM, setOpenRCM] = useState(false)
  const [openMod, setOpenMod] = useState(false);
  const practitionerId = useSelector((state) => state.auth.currentUser.practitionerId);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  const { patientProfile: { firstName, lastName }, appointmentStatus, appointmentTime, appointmentReference, appointmentType, visitType, visitPurpose, diagnosis } = apptData
  const appointmentTimeLocal = moment.utc(appointmentTime, 'HH:mm:ss').local().format('hh:mm A')

  let cssclasses;
  if (appointmentStatus === AppointmentStatus.BOOKED) {
    cssclasses = "appt-content type-booked";
  } else if (appointmentStatus === AppointmentStatus.CANCELLED) {
    cssclasses = "appt-content type-cancel";
  } else if (appointmentStatus === AppointmentStatus.REJECTED) {
    cssclasses = "appt-content type-cancel";
  } else if (appointmentStatus === AppointmentStatus.CONFIRMED) {
    cssclasses = "appt-content type-confirm";
  } else {
    cssclasses = "appt-content type-complete";
  }

  const toggleOpenACM = () => setOpenACM(!openACM)
  const toggleOpenRCM = () => setOpenRCM(!openRCM)
  const toggleOpenMod = () => {
    setOpenMod(!openMod)
    setPopoverOpen(false)
  }
  const handleApptAction = (actionType, time, patientName) => {
    if (actionType === 'accept') {
      toggleOpenACM()
      toggle()
    } else if (actionType === 'reject') {
      toggleOpenRCM()
      toggle()
    }
  }

  const onAccept = () => {
    const payload = {
      appointmentReference: apptData.appointmentReference,
      approvalType: 'CONFIRMED',
      requestedByUser: practitionerId,
      statusReason: ""
    }
    // console.log("Call api to accept appt", apptData.appointmentSlotId, payload);

    appointmentServices.updateAppointmentStatus(apptData.appointmentSlotId, payload)
      .then(response => {
        // console.log("updateAppointmentStatus res", response);
        if (response.status === 200 && response.data && response.data.status === "Success") {
          alert.success("Appointment accepted")
          getAppts()
        }
      })
      .catch(error => {
        console.log("updateAppointmentStatus error", error);
        alert.error(error.message)
      })
      .then(() => {
        toggleOpenACM()
      })
  }

  const onReject = () => {
    const payload = {
      appointmentReference: apptData.appointmentReference,
      approvalType: 'REJECTED',
      requestedByUser: practitionerId,
      statusReason: ""
    }
    // console.log("Call api to reject appt", apptData.appointmentSlotId, payload);

    appointmentServices.updateAppointmentStatus(apptData.appointmentSlotId, payload)
      .then(response => {
        // console.log("updateAppointmentStatus res", response);
        if (response.status === 200 && response.data && response.data.status === "Success") {
          alert.success("Appointment rejected")
          getAppts()
        }
      })
      .catch(error => {
        console.log("updateAppointmentStatus error", error);
        alert.error(error.message)
      })
      .then(() => {
        toggleOpenRCM()
      })
  }

  const actionBtns = () => {
    switch (appointmentStatus) {
      case AppointmentStatus.REJECTED:
        return null;
      case AppointmentStatus.CANCELLED:
        return null;
      case AppointmentStatus.BOOKED:
        return (
          <>
            <div title="Accept" className="accept" onClick={() => handleApptAction('accept')}><FontAwesomeIcon className="accept-icon mr-2" icon={faCheck} onClick={() => actionBtns(apptData, 'chat')} /></div>
            <div title="Reject" className="reject" onClick={() => handleApptAction('reject')}><FontAwesomeIcon className="reject-icon mx-1" icon={faTrashAlt} onClick={() => actionBtns(apptData, 'delete')} /></div>
            <div title="Reschedule" className="reschedule" onClick={toggleOpenMod}><FontAwesomeIcon className="reschedule-icon mx-2" icon={faPencilAlt} onClick={() => actionBtns(apptData, 'edit')} /></div>
          </>
        )
      case AppointmentStatus.CONFIRMED:
        return(
          <>
            <div title="join" className="join"><FontAwesomeIcon className="join-icon mx-2" icon={appointmentType === "VIDEO" ? faVideo : faTelegramPlane} onClick={() => actionBtns(apptData, 'edit')} /></div>
            <div title="Reschedule" className="reschedule" onClick={toggleOpenMod}><FontAwesomeIcon className="reschedule-icon mx-2" icon={faPencilAlt} onClick={() => actionBtns(apptData, 'edit')} /></div>
          </>
        )
      default: return null;
    }
  }

  return (
    <>
      <div key={appointmentReference} id={`nxApBx-${appointmentReference}`} className={cssclasses} style={{ minHeight: "4.8rem" }}>
        <Row>
          <Col>
            <div>
              <span className="appt-label f-12 line-h-12">{appointmentType === AppointmentType.VIDEO ? <img src={Videocam} className="mr-1" alt="videoCam" style={{ transform: "translate(-20%, -20%)" }} /> : ''}{label}</span>
            </div>
            <div className="appt-info pt-4 pl-1 pb-1 pr-2 d-flex justify-content-between align-items-center">
              <div className="f-14 line-h-12" style={{ textTransform: "capitalize", color: "#2D4552" }}>
                <span className="patient-name">{firstName} {lastName}</span>
              </div>
              <div className="f-10 line-h-10" style={{ color: "rgba(0, 18, 68, 0.6)" }}><span>{visitType || "First Visit"}</span></div>
            </div>
          </Col>
        </Row>
      </div>
      <Popover placement="right" isOpen={popoverOpen} trigger="legacy" target={`nxApBx-${appointmentReference}`} toggle={toggle} className="appt-popover">
        <PopoverBody style={{ padding: "0.2rem" }}>
          <div className={cssclasses}>
            <Row style={{ width: "250px" }}>
              <Col md={8}><span className="appt-label pt1 pb3">{appointmentType === AppointmentType.VIDEO ? <img src={Videocam} className="mr-1" alt="videoCam" style={{ transform: "translate(-20%, -20%)" }} /> : ''}{label}</span></Col>
              <Col md={4} className="btn-icons d-flex justify-content-center align-items-center">
                {actionBtns()}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="modaldiv pt-3 f-16 line-h-12" style={{ textTransform: "capitalize", color: "#2D4552" }}>{firstName} {lastName}</div>
                <div className="modalnormal pt-3 f-12 line-h-12">{visitType || "First Visit"}</div>
                <div className="modaldiv pt-3 f-12 line-h-12" style={{ color: "rgba(0, 18, 68, 1)" }}>Appointment At</div>
                <div className="modalnormal pt-2 f-12 line-h-12">{appointmentTimeLocal}</div>
                <div className="modaldiv pt-3 f-12 line-h-12" style={{ color: "rgba(0, 18, 68, 1)" }}>Purpose of Visit</div>
                <div className="modalnormal pt-2 f-12 line-h-12">{visitPurpose || 'N/A'}</div>
                <div className="modaldiv pt-3 f-12 line-h-12" style={{ color: "rgba(0, 18, 68, 1)" }}>Diagnosys</div>
                <div className="modalnormal pt-2 f-12 line-h-12">{diagnosis || "No diagnosis"}</div>
                <div className="pt-3 text-primary f-12 line-h-12">View Docs</div>
              </Col>
            </Row>
          </div>
        </PopoverBody>
      </Popover>

      {/* Accept appt modal */}
      <AlertDialog
        toggle={toggleOpenACM}
        isOpen={openACM}
        isLoading={false}
        handleClickOnYes={onAccept}
        yesLabel={"Yes"}
        noLabel={"No"}
        noProperties={{ color: "secondary", outline: true }}
        yesProperties={{ color: "primary", filled: true, outline: false }}
        headerTitle={'Accept Appointment'}
        bodyText={<p>Do you want to accept <b>{firstName} {lastName}</b> appointment at <b>{appointmentTimeLocal}</b>?</p>}
      />

      {/* Reject appt modal */}
      <AlertDialog
        toggle={toggleOpenRCM}
        isOpen={openRCM}
        isLoading={false}
        handleClickOnYes={onReject}
        yesLabel={"Yes"}
        noLabel={"No"}
        headerTitle={'Reject Appointment'}
        bodyText={<p>Do you want to reject <b>{firstName} {lastName}</b> appointment at <b>{appointmentTimeLocal}</b>?</p>}
      />

      <RescheduleModal toggle={toggleOpenMod} isOpen={openMod} apptData={apptData} getAppts={getAppts} />

    </>
  )
};

export default withAlert()(ApptBlock);
