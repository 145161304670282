import React from "react";
import { useState, useEffect, useRef } from "react";
import "assets/styles/typeahead.css";
import { ErrorMessage } from "formik";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { Label } from "reactstrap";

const MyPatientsTypeaheadField = (props) => {
  const {
    disabled,
    placeholder,
    className,
    selectdPatient,
    activeFlag,
    setAddPatient
  } = props;
  const patientRef = useRef();
  const { name, value } = props.field;
  const { setFieldValue, setFieldTouched, touched, errors } = props.form;
  const [patients, setPatients] = useState([]);
  const [selectdItem, setSelectdItem] = useState("");
  const [searchedPatients, setSearchedPatients] = useState([]);
  const loadmoreBtn = {
    backgroundColor: '#e9ecef',
    textAlign: 'center',
    color: '#3498db',
    textDecoration: 'none',
  }
  const handleSearchedRecords = (searchQuery) => {
    const patientList = props.patients;
    const matchedPatients = patientList.filter((pdet) => {
      // return (pdet?.firstName+' '+pdet?.lastName).toLowerCase().includes(searchQuery) || pdet.lastName?.toLowerCase().includes(searchQuery) || pdet.cellularPhoneNumber?.toLowerCase().includes(searchQuery) || pdet.emailAddress?.toLowerCase().includes(searchQuery)
      return (pdet?.firstName+' '+pdet?.lastName).toLowerCase().includes(searchQuery.toLowerCase()) || pdet.lastName?.toLowerCase().includes(searchQuery.toLowerCase()) || pdet.cellularPhoneNumber?.toLowerCase().includes(searchQuery.toLowerCase()) || pdet.emailAddress?.toLowerCase().includes(searchQuery.toLowerCase())
    }
    // const fnameMatch = pdet.firstName?.toLowerCase().includes(searchQuery);
    // const lnameMatch = pdet.lastName?.toLowerCase().includes(searchQuery);
    // const mobileMatch = pdet.cellularPhoneNumber?.toLowerCase().includes(searchQuery);
    // const emailMatch = pdet.emailAddress?.toLowerCase().includes(searchQuery);
    // }
      // fruit.label.toLowerCase().includes(input.toLowerCase())
    )
    matchedPatients.length === 0 ? setAddPatient(true) : setAddPatient(false);
    setSearchedPatients(matchedPatients);
  };
  const handleChange = (selected) => {
    if (selected.length > 0) {
      setFieldValue(name, selected, true);
      setAddPatient(false);
    } else {
      setFieldValue(name, [], true);
    }
  };
  const customFilterBy = (option, props) => {
    const searchQuery = props.text.toLowerCase();
    // const fnameMatch = option.firstName?.toLowerCase().includes(searchQuery);
    // const lnameMatch = option.lastName?.toLowerCase().includes(searchQuery);
    const cname = (option.firstName+' '+option.lastName).toLowerCase().includes(searchQuery);
    const mobileMatch = option.cellularPhoneNumber?.toLowerCase().includes(searchQuery);
    const emailMatch = option.emailAddress?.toLowerCase().includes(searchQuery);
    // setSelectdItem(fnameMatch);
    return cname || mobileMatch || emailMatch;
  };

  function getFullName(result) {
    let cellNumber = result.cellularPhoneNumber ?? '';
    let cNumber = cellNumber !== '' ? `, ${cellNumber}` : '';
    var fullName = result.firstName !== undefined ? (`${result.firstName} ${result.lastName} ${cNumber}`) : (<div style={{ width: '100%', backgroundColor: '#e9ecef', color: 'blue', textAlign: 'center' }}>Load More</div>)
    return fullName;
  }
  //Diaplay FName LName , Mobile on select
  const customeLabelKey = (option) => `${option.firstName} ${option.lastName}`;

  return (
    <>
      {props.label && (
        <Label htmlFor={name} className="d-block skills">
          {props.label}
        </Label>
      )}
      <Typeahead
        ref={patientRef}
        clearButton
        className={className}
        disabled={activeFlag}
        selected={value}
        id={name}
        options={props.patients}
        labelKey={customeLabelKey}
        placeholder={placeholder}
        allowNew={false}
        maxResults={10}
        onChange={handleChange}
        // onInputChange={handleSearchedRecords}
        filterBy={customFilterBy
          //   (option) => {
          //   return option.firstName;
          // }
        }
        onInputChange={(input) => {
          selectdPatient([input])
          handleSearchedRecords(input)
        }
        }
        onBlur={(e) => {
          setFieldTouched(name, true);
          patientRef?.current && patientRef.current.hideMenu();
        }}
        renderMenu={(results, menuProps) => {
          if (!results.length) {
            return null;
          }
          return (
            <Menu {...menuProps}>
              {results.map((result, index) => (
                (result.label !== '') ?
                  <MenuItem key={index} option={result} position={index} >
                    {getFullName(result)}
                  </MenuItem>
                  :
                  <MenuItem key={index} option={result} position={index} style={loadmoreBtn}>
                    Load More
                  </MenuItem>
              ))}
            </Menu>
          );
        }}
        isInvalid={Boolean(touched[name] && errors[name])}
      />
      <ErrorMessage name={name} render={(msg) => (<span className="text-danger skill-err f-12 d-inline-block  mt-1 line-height-error">{msg}</span>)}
      />
    </>
  );
};

MyPatientsTypeaheadField.defaultProps = {
  allowNew: true,
};

export default MyPatientsTypeaheadField;
