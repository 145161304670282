export const AUTH_SET_USER = "AUTH_SET_USER";
export const AUTH_SET_TOKEN = "AUTH_SET_TOKEN";
export const AUTH_DISCARD_TOKEN = "AUTH_DISCARD_TOKEN";
export const LOGOUT_USER = "LOGOUT_USER";
export const GET_ALL_FEEDS = "GET_ALL_FEEDS";
export const UPDATE_PROFILE_PROGRESS = "UPDATE_PROFILE_PROGRESS";
export const PROFILE_ADD_EVENT = "PROFILE_ADD_EVENT";
export const GET_SUGGESTED_CONNECTION = "GET_SUGGESTED_CONNECTION";
export const GET_SHARE_FEED = "GET_SHARE_FEED";
export const HIDE_POST = "HIDE_POST";
export const RESET_ALL_FEEDS = "RESET_ALL_FEEDS";
export const APPLY_FILTER = "APPLY_FILTER";
export const HANDLE_REMOVE_FILTER = "HANDLE_REMOVE_FILTER";
export const GET_ALL = "GET_ALL";
export const GET_AFFILIATIONS = "GET_AFFILIATIONS";
export const GET_USER_AFFILIATIONS = "GET_USER_AFFILIATIONS";
export const GET_GLOBAL_PROFILE_USER = "GET_GLOBAL_PROFILE_USER";
export const SET_GLOBAL_PROFILE_USER = "SET_GLOBAL_PROFILE_USER";
export const SET_REFER_STATUS = "SET_REFER_STATUS";
export const GET_GLOBAL_PROFILE_INSTITUTE = "GET_GLOBAL_PROFILE_INSTITUTE"; 
export const SET_GLOBAL_PROFILE_INSTITUTE = "SET_GLOBAL_PROFILE_INSTITUTE";
export const GET_COONETIONS_INFO = "GET_COONETIONS_INFO";
export const SET_COONETIONS_INFO = "SET_COONETIONS_INFO";
export const GET_RECENT_EXPERIENCE = "GET_RECENT_EXPERIENCE";
export const SET_RECENT_EXPERIENCE = "SET_RECENT_EXPERIENCE";
export const GET_RECENT_EDUCATION = "GET_RECENT_EDUCATION";
export const SET_RECENT_EDUCATION = "SET_RECENT_EDUCATION";
export const SET_GLOBAL_SEARCHTYPE = "SET_GLOBAL_SEARCHTYPE";
export const GET_GLOBAL_SEARCHTYPE = "GET_GLOBAL_SEARCHTYPE";

const exportedObject = {
  AUTH_SET_USER,
  LOGOUT_USER,
};

export default exportedObject;
