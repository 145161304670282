import { useFormikContext } from "formik";
import React, { useState, useEffect } from "react";
import { Button, Card, CardBody } from "reactstrap";

const SurgeriesForm = (props) => {
    const { values, setFieldValue } = useFormikContext();
    const [selectedSurgeries, setSelectedSurgeries] = useState([]);
    const [surgeriesOptions, setSurgeriesOptions] = useState([]);
    const [customSurgery, setCustomSurgery] = useState('');

    const predefinedSurgeries = [
        { "label": "Appendectomy", "value": "Appendectomy" },
        { "label": "Cataract surgery", "value": "Cataract surgery" },
        { "label": "Coronary artery bypass graft (CABG)", "value": "Coronary artery bypass graft (CABG)" },
        { "label": "Cholecystectomy (gallbladder removal)", "value": "Cholecystectomy (gallbladder removal)" },
        { "label": "Hernia repair", "value": "Hernia repair" },
        { "label": "Total knee replacement", "value": "Total knee replacement" },
        { "label": "Total hip replacement", "value": "Total hip replacement" },
        { "label": "Laminectomy (spinal surgery)", "value": "Laminectomy (spinal surgery)" },
        { "label": "Mastectomy", "value": "Mastectomy" },
        { "label": "Prostatectomy", "value": "Prostatectomy" },
        { "label": "Colon resection", "value": "Colon resection" },
        { "label": "Gastric bypass surgery", "value": "Gastric bypass" },
        { "label": "Tonsillectomy", "value": "Tonsillectomy" },
        { "label": "Hip arthroscopy", "value": "Hip arthroscopy" },
        { "label": "Lung resection", "value": "Lung resection" },
        { "label": "Heart valve replacement", "value": "Heart valve replacement" },
        { "label": "Cesarean section (C-section)", "value": "Cesarean section (C-section)" },
        { "label": "Craniotomy (brain surgery)", "value": "Craniotomy (brain surgery)" },
        { "label": "Appendix surgery", "value": "Appendix surgery" },
        { "label": "Gastric sleeve surgery", "value": "Gastric sleeve" },
        { "label": "Coronary angioplasty", "value": "Coronary angioplasty" },
        { "label": "ACL reconstruction", "value": "ACL reconstruction" },
        { "label": "Liver transplant", "value": "Liver transplant" },
        { "label": "Kidney transplant", "value": "Kidney transplant" },
        { "label": "Hysterectomy", "value": "Hysterectomy" },
        { "label": "Bone fracture repair", "value": "Bone fracture repair" },
        { "label": "Thyroidectomy", "value": "Thyroidectomy" },
        { "label": "Gallbladder surgery", "value": "Gallbladder surgery" },
        { "label": "Spleen removal", "value": "Spleen removal" },
        { "label": "Pancreatic surgery", "value": "Pancreatic surgery" },
        { "label": "Tumor removal surgery", "value": "Tumor removal surgery" },
        { "label": "Facelift surgery", "value": "Facelift surgery" },
        { "label": "Joint fusion surgery", "value": "Joint fusion surgery" },
        { "label": "Bladder surgery", "value": "Bladder surgery" }
    ];

    useEffect(() => {
        // Initialize the options with predefined surgeries plus any existing values in Formik
        setSurgeriesOptions([...predefinedSurgeries, ...values.surgeries.map(surgery => ({ label: surgery, value: surgery }))]);
        setSelectedSurgeries(values.surgeries || []);
    }, [values.surgeries]);

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        setSelectedSurgeries((prev) => {
            if (prev.includes(value)) {
                return prev.filter((surgery) => surgery !== value);
            } else {
                return [...prev, value];
            }
        });
    };

    const handleCustomSurgeryChange = (e) => {
        setCustomSurgery(e.target.value);
    };

    const addCustomSurgery = () => {
        if (customSurgery.trim() !== '' && !surgeriesOptions.some(option => option.value === customSurgery.trim())) {
            const newSurgery = { label: customSurgery.trim(), value: customSurgery.trim() };
            setSurgeriesOptions((prev) => [...prev, newSurgery]);
            setSelectedSurgeries((prev) => [...prev, customSurgery.trim()]);
            setCustomSurgery(''); // Clear the input field
        }
    };

    const handleClose = () => {
        setFieldValue('surgeries', selectedSurgeries);
        props.toggle(); // Close the modal
    };

    return (
        <>
            {props.modal &&
                <Card className="nex-full-covered-card">
                    <CardBody>
                        <h2 className="nex-text-xl mb-3">Please specify your Surgeries</h2>
                        <div className="nex-checkbox-lists nexogic-custom-scroll">
                            <ul>
                                {surgeriesOptions.map((item) => (
                                    <li key={item.value}>
                                        <div className="nex-check">
                                            <input
                                                type="checkbox"
                                                value={item.value}
                                                onChange={handleCheckboxChange}
                                                checked={selectedSurgeries.includes(item.value)}
                                            />
                                        </div>
                                        <div className="label">{item.label}</div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="nex-custom-surgery">
                            <label htmlFor="customSurgery">Add your own:</label>
                            <input
                                type="text"
                                id="customSurgery"
                                value={customSurgery}
                                onChange={handleCustomSurgeryChange}
                            />
                            <Button className="nex-btn-secondary" onClick={addCustomSurgery}>
                                Add Custom Surgery
                            </Button>
                        </div>
                        <Button className="nex-btn-primary" onClick={handleClose}>
                            Add
                        </Button>
                    </CardBody>
                </Card>
            }
        </>
    );
};

export default SurgeriesForm;
