import React from "react";
import DefaultImg from "../../../../assets/images/defaultPic.png";
import "./commentAvt.css";
import Default_img from "../../../../assets/images/defaultPic.png";

// import ProfPic from "../../../Messages/images/MessageImg.png"

const CommentAvatar = (props) => {
  const {
    profPic,
    commentText,
    firstName,
    middleName,
    lastName,
    createdDate,
    View_more_comments,
    title,
  } = props;
  const name =
    title != null
      ? title + " " + firstName + " " + middleName + " " + lastName
      : firstName + " " + middleName + " " + lastName;
  const nDate = new Date(createdDate).toString().split(" ");
  const finalDate = nDate[2] + "th" + " " + nDate[1];

  return (
    <div className="commentAvt">
      <div className="left__part">
        <img
          className="user-pic"
          src={
            profPic
              ? firstName == "lokesh"
                ? profPic
                : profPic.includes("http")
                ? Default_img
                : `${process.env.REACT_APP_API_ENDPOINT}getobject/${profPic}`
              : Default_img
          }
          alt=""
        />
      </div>
      <div className="right__part">
        <div className="comment__header">
          <span className="text-style first">{name ? name : ""}</span>
          <span className="text-style">{props.profession}</span>
          <span className="text-style last">
            {finalDate ? finalDate : "date"}
          </span>
        </div>
        <p className="comment-text">{commentText ? commentText : ""}</p>
      </div>
    </div>
  );
};

export default CommentAvatar;
