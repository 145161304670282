import React, { useState, useEffect, useRef } from 'react';
import { Field, useFormikContext } from 'formik';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import CityAutocompleteTypeaheadField from 'components/forms/formikFields/CityAutocompleteTypeaheadField';
import LocationTypeaheadField from 'components/forms/formikFields/LocationTypeaheadField';
import { MetadataService } from 'utils/MetadataService';

const JobCityAndLocationEditModal = (props) => {
    const { modal, setModal } = props;
    const locRef = useRef();
    const { values, setFieldValue } = useFormikContext();
    const [locationList, setLocationList] = useState([]);

    useEffect(() => {
        if ((values.city && values.city.length === 0) || values.city[0]?.id === "") {
            setFieldValue(`location`, []);
        } else {
            if (values.city[0]?.id !== "") {
                MetadataService.getLocations(values.city[0]?.id).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        setLocationList(response.data);
                    }
                });
            }
        }
    }, [values.city, setFieldValue]);

    const handleSubmit = () => {
        setModal(false); // Close the modal
    };

    return (
        <Modal isOpen={modal} toggle={() => setModal(!modal)} className="nex-model-v2 modal-dialog-scrollable modal-dialog-centered">
            <ModalHeader toggle={() => setModal(!modal)}>Edit Apply mode</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col">
                        <div className="form-group mb-4">
                            <label className='bold'>City</label>
                            <Field
                                name="city"
                                id="city"
                                autocomplete="city"
                                type="text"
                                component={CityAutocompleteTypeaheadField}
                                placeholder="City"
                            />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group mb-4">
                            <label className='bold'>Location</label>
                            <Field
                                id="location"
                                name="location"
                                type="text"
                                component={LocationTypeaheadField}
                                value={values.location}
                                placeholder="Select Location"
                                spellCheck={false}
                                locations={locationList}
                                selectdLocation={(locationName) => setFieldValue("location", locationName)}
                                locationRef={locRef}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group nex-action-flex">
                <button className="btn nex-btn-primary ml-auto" onClick={handleSubmit}>Save</button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default JobCityAndLocationEditModal;
