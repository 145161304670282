import logo_white from "assets/images/logo-white-transparent.png";
import google_img from "assets/images/social-google-img.png";
import facebook_img from "assets/images/facebook-social-img.png";
import apple_img from "assets/images/apple-social-img.png";

import { useEffect } from "react";
import { Field, Formik } from "formik";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
});

const NewDoctorRegisterV2 = (props) => {
    const initValues = useMemo(() => {
        return {
          name: "",
        };
      }, []);
    const [isSubmitting, setSubmitting] = useState(false);
    const [initialValues, setInitialValues] = useState(initValues);
    const onSubmit = (values) => {
        console.log(values);
        if(!isEmpty(values.name)){
            setSubmitting(true);
            console.log(values);
        }
        
    };
    
    return (
        <div className="nex-bg-light-blue">
            <main className="nex-login-register--w">
                <div className="container sm d-flex">
                    <div className="nex-form-aside">
                        <div className="logo">
                            <a href="/"><img src={logo_white} alt="" /></a>
                        </div>
                        
                        <h2 className="nex-text-xxl">Hey, <br/>
                        Search with your <br/>
                        name</h2>
                    </div>
                    <div className="nex-form-fields--w">
                        <div className="nex-form-center-slot">
                        <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={onSubmit}
                        children={(props) => {
                            return (
                            <>
                                <RenderForm
                                {...props}
                                isSubmitting={isSubmitting}
                                />
                            </>
                            );
                        }}
                        />
                            
                        </div>
                        <div className="nex-form-bottom-slot">
                            <p className="nex-text-lg text-center">Are you a member? <a href="#" className="nex-link-dark">Login</a></p>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default  NewDoctorRegisterV2;

const RenderForm = (props) => {
    const {
        values,
        errors,
        isSubmitting,
        handleSubmit,
      } = props;
  
    return (
      <div>
        <form className="new-doctor-register-form" onSubmit={handleSubmit}>
            <div className="nex-form-container">
                                <div className="nex-social-login-btns-w">
                                    <a href="" className="btn"><img src={google_img} alt="" width={50} /></a>
                                    <a href="" className="btn"><img src={facebook_img} alt="" width={50} /></a>
                                    <a href="" className="btn"><img src={apple_img} alt="" width={50} /></a>
                                </div>
                                <div className="nex-or-txt--w">or</div>
                                <div className="form-group pt-3">
                                <Field
                            id="name"
                            name="name"
                            type="text"
                            label=""
                            component={FormikInputField}
                            placeholder="Tell us your name"
                            autoComplete="off"
                        />
                                </div>
                                <div className="form-group">
                                    <input type="submit" value="Continue" className="btn btn-primary w-100" />
                                </div>
            </div>

            
        </form>
      </div>
    );
  };