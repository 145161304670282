import addIcon from "assets/images/svgs/add_icon.svg";
import {faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "assets/styles/typeahead.css";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import { useFormik } from "formik";
import { findIndex } from "lodash";
import { useEffect, useRef, useState, useMemo } from "react";
import { withAlert } from "react-alert";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch } from "react-redux";
import { Button, Col, FormGroup, Row, Spinner } from "reactstrap";
import { setGlobalprofile } from "redux-helpers/ActionCreator";
import { MetadataService } from "utils/MetadataService";
import { PractitionerService } from "utils/PractitionerService";
import { setUser } from "utils/UserHelper";
import plusIcon from "assets/images/add-icon.png";
import * as Yup from "yup";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const validationSchema = Yup.object().shape({
  expert: Yup.string().required("This field is required"),
});

const ServicesForm = (props) => {
  const { toggle, setPractitioner, practitioner, isAdmin, alert } = props;
  const [servicesData, setServicesData] = useState([]);
  const [services, setServices] = useState(practitioner.services);
  const [suggestedServices, setSuggestedServices] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [loadMore,setLoadMore] = useState(10);
  const [isCompleted, setIsCompleted] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");
  const servicesRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        MetadataService.getServices().then((response) => {
          const { status, data } = response;
          if (status === 200 || status === 201) {
            setLoading(false);
            setServicesData(data);
            setSuggestedServices(data);
          }
        });
      } catch (e) {
        setErrorMessage(e.message);
        setLoading(false);
      }
    })();
  }, []);

  const formik = useFormik({
    initialValues: { services: services },
    validationSchema: isAdmin ? null : validationSchema,
  });

  const updateItems = async (value) => {
    setLoading(true);
    try {
      const payload = [
        {
          op: "replace",
          path: "/services",
          value: value,
        },
      ];
      PractitionerService.updatePractitionersDetails(
        payload,
        practitioner.guid
      ).then((response) => {
        const { status, validationErrors } = response;
        if (status === 200 || status === 201) {
          updateStoreSkils();
          showSuccessMessage("Services updated Successfully");
        } else {
          // showErrorMessage(response.message);
          setLoading(false);
          if (
            validationErrors &&
            validationErrors &&
            validationErrors.length > 0
          ) {
            const error = [];
            validationErrors.forEach((errors) => {
              error.push(errors.message);
            });
            showErrorMessage(error.join(","));
          } else {
            showErrorMessage("Error while updating services");
          }
        }
      });
    } catch (e) {
      console.log("PractitionerService.updatePractitionersDetails error", e);
      showErrorMessage("Server facing problem while updating services.");
      setLoading(false);
    }
  };
  
  // const getSuggestedSkills = () => {
  //   try {
  //     MetadataService.getSpecialitySkillSet(
  //       practitioner.primarySpeciality
  //     ).then((response) => {
  //       const { status, data } = response;
  //       if (status === 200 || status === 201) {
  //         setSuggestedSkills(data);
  //       }
  //     });
  //   } catch (e) {
  //     setErrorMessage(e.message);
  //     setLoading(false);
  //   }
  // };
  const handleLoadMore = () => {
    setLoadMore(loadMore + 550);
    if (loadMore >= servicesData.length) {
      setIsCompleted(true)
    } else {
      setIsCompleted(false)
    }
  }

  const handleServicesList = (service) => {
    setServices([...services, service]);
  };

  const servicesSuggestion = useMemo(() => {
    if (suggestedServices && suggestedServices.length > 0) {
      const uniqueSuggestedServices = suggestedServices.filter(
        (s) => findIndex(services, (o) => o === s) < 0
      );
      return uniqueSuggestedServices?.slice(0, loadMore)?.map((data, ind) => {
        return (
          <div
            key={`nex-sk-service-s-${ind}`}
            id={`nex-sk-service-suggest-${ind}`}
            className="nexogic-suggested-skill d-flex align-items-center"
            onClick={() => handleServicesList(data)}
          >
            <div className="card-text">{data}</div>
            <div className="add-icon ml-1">
              <img src={addIcon} alt="" />
            </div>
          </div>
        );
      });
    }
    return null;
  }, [services, suggestedServices,loadMore]);

  const updateStoreSkils = () => {
    PractitionerService.gePractitionersDetails(practitioner.guid)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setUser(response.data);
          setTimeout(() => {
            setPractitioner(response.data);
            dispatch(setGlobalprofile(response.data));
            toggle();
          }, 1000);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("PractitionerService.gePractitionersDetails error", error);
        showErrorMessage(error.message);
        setLoading(false);
      });
  };

  const onChangeHandler = (campaign) => {
    const preData = campaign.map((item) => {
      let clone = item;
      let custom = item?.customOption ? true : false;
      if (custom) {
        clone = item?.name;
      }
      return clone;
    });
    preData.length ? setServices(preData) : setServices([]);
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit} id="nex-ps-serviced-form">
        {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
        <Row form>
          <Col xs={12}>
            <FormGroup className="searchbox-input-chips mb-1" id="nex-ps-serviced-inp">
              <Typeahead
                ref={servicesRef}
                allowNew
                clearButton
                newSelectionPrefix="Add services: "
                selected={services}
                id="services"
                multiple
                options={servicesData}
                labelKey={"name"}
                placeholder="Add your services ex. Cardiology"
                onBlur={() => {
                  return servicesRef !== null
                    ? servicesRef.current.hideMenu()
                    : null;
                }}
                onChange={onChangeHandler}
              />
            </FormGroup>
            <p className="mb-2 card-text"><small>You can add upto 50 services</small></p>
            <div className="nexogic-suggested-skills d-flex flex-wrap mt-3">
              {servicesSuggestion}
            </div>
            <div className="nexogic-suggested-skill d-flex flex-wrap my-2 justify-content-center">
            {!isCompleted && (
              <Button
                id="nex-ps-serviced-more-btn"
                className="nexogic_primary_button_outline small mt-2"
                color="link"
                onClick={handleLoadMore}
              >
                <img src={plusIcon} alt=""/>  Load more
              </Button>
            )}
            </div>
          </Col>
        </Row>
        {/** <hr className="profile-info-modal-hr" />*/}
        <div className="justify-content-end  modal-footer">
          <Col xs="auto" className="action-group">
            {isLoading ? (
              <div className="loading-spiner">
                <Spinner />
              </div>
            ) : (
              <>
                
                <Button
                  id="nex-ps-serviced-save-btn"
                  className="nexogic_primary_button"
                  color="primary"
                  type="submit"
                  onClick={() => updateItems(services)}
                  disabled={services.length === 0 && !isAdmin}
                >
                  Save
                </Button>
                <Button
                  id="nex-ps-serviced-cancel-btn"
                  className="nexogic_primary_button_outline"
                  color="primary"
                  type="submit"
                  onClick={toggle}
                >
                  Cancel
                </Button>
              </>
            )}
          </Col>
        </div>
      </form>
    </>
  );
};

export default withAlert()(ServicesForm);
