import React from 'react'
import user_img from 'assets/images/profile-p.jpg'
import IconStar from '../Doctors/components/icons/IconStar'

export default function Reviews() {
  return (
    <>
        <h3 className="card-title big mb-3">Patient reviews</h3>
        <div className="card nexogic-patient-review-wrap">
            <div className="nexogic-patient-review">
                <div className="nexogic-review-info">
                    <div className="item">
                        <div className="card-text lead">Overall</div>
                        <div className="card-text"><IconStar /> 5.0</div>
                    </div>
                    <div className="item">
                        <div className="card-text lead">Wait time</div>
                        <div className="card-text"><IconStar /> 4.5</div>
                    </div>
                    <div className="item">
                        <div className="card-text lead">Bedside manner</div>
                        <div className="card-text"><IconStar /> 4.9</div>
                    </div>
                </div>
            </div>

            <div className="nexogic-patient-review-list">
                    <div className="card-head">
                        <div className="card-graphic">
                            <img src={user_img} alt="" className='card-img' />
                        </div>
                        <div className="info">
                            <h2 className="card-title">Daniel A. </h2>
                            <div className="card-text light">Feb 28, 2021</div>
                        </div>
                    <div className="nexogic-review-info">
                    <div className="item">
                        <div className="card-text lead">Overall</div>
                        <div className="card-text"><IconStar /> 5.0</div>
                    </div>
                    <div className="item">
                        <div className="card-text lead">Wait time</div>
                        <div className="card-text"><IconStar /> 4.5</div>
                    </div>
                    <div className="item">
                        <div className="card-text lead">Bedside manner</div>
                        <div className="card-text"><IconStar /> 4.9</div>
                    </div>
                </div>
                    </div>
                    <div className="card-body">
                        <p className='card-text desc-text'>Visited for Spinal Therapy: he explained every thing in very detailed manner. I was seriously very happy after seein him and will recommend him in future too. </p>
                        <p className="card-text"><a href="">See more</a></p>
                    </div>
            </div>

            <div className="nexogic-patient-review-list">
                    <div className="card-head">
                        <div className="card-graphic">
                            <img src={user_img} alt="" className='card-img' />
                        </div>
                        <div className="info">
                            <h2 className="card-title">Daniel A. </h2>
                            <div className="card-text light">Feb 28, 2021</div>
                        </div>
                    <div className="nexogic-review-info">
                    <div className="item">
                        <div className="card-text lead">Overall</div>
                        <div className="card-text"><IconStar /> 5.0</div>
                    </div>
                    <div className="item">
                        <div className="card-text lead">Wait time</div>
                        <div className="card-text"><IconStar /> 4.5</div>
                    </div>
                    <div className="item">
                        <div className="card-text lead">Bedside manner</div>
                        <div className="card-text"><IconStar /> 4.9</div>
                    </div>
                </div>
                    </div>
                    <div className="card-body">
                        <p className='card-text desc-text'>Visited for Spinal Therapy: he explained every thing in very detailed manner. I was seriously very happy after seein him and will recommend him in future too. </p>
                        <p className="card-text"><a href="">See more</a></p>
                    </div>
            </div>

            <div className="nexogic-patient-review-list">
                    <div className="card-head">
                        <div className="card-graphic">
                            <img src={user_img} alt="" className='card-img' />
                        </div>
                        <div className="info">
                            <h2 className="card-title">Daniel A. </h2>
                            <div className="card-text light">Feb 28, 2021</div>
                        </div>
                    <div className="nexogic-review-info">
                    <div className="item">
                        <div className="card-text lead">Overall</div>
                        <div className="card-text"><IconStar /> 5.0</div>
                    </div>
                    <div className="item">
                        <div className="card-text lead">Wait time</div>
                        <div className="card-text"><IconStar /> 4.5</div>
                    </div>
                    <div className="item">
                        <div className="card-text lead">Bedside manner</div>
                        <div className="card-text"><IconStar /> 4.9</div>
                    </div>
                </div>
                    </div>
                    <div className="card-body">
                        <p className='card-text desc-text'>Visited for Spinal Therapy: he explained every thing in very detailed manner. I was seriously very happy after seein him and will recommend him in future too. </p>
                        <p className="card-text"><a href="">See more</a></p>
                    </div>
            </div>

        </div>
    </>
  )
}
