import CollapseableItem from 'common/CollapseableItem'
import CollapseablePannel from 'common/CollapseablePannel'
import Integrations from 'pages/Settings/components/Integrations'
import SetNotifications from 'pages/Settings/components/SetNotifications'
import React, { useEffect } from 'react'
import ReactDOMServer from 'react-dom/server';
import { useState } from 'react';
import { withAlert } from 'react-alert';
import InstitutionPreference from '../InstitutionsDetails/components/InstitutionSettings/InstitutionPreference'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { InstitutionServices } from 'utils/InstitutionServices'
import { PractitionerService } from "utils/PractitionerService";
import { getLoggedInUserInstitutions, isAdmin } from 'utils/UserHelper'
import { ButtonDropdown, Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom'
import PreviewWindow from 'pages/UIDashboard/Components/CustomThemeDesign/PreviewWindow'
import ThemeOne from 'pages/UIDashboard/Components/CustomThemeDesign/ThemeOne'
import ThemeTwo from 'pages/UIDashboard/Components/CustomThemeDesign/ThemeTwo'
import ThemeThree from 'pages/UIDashboard/Components/CustomThemeDesign/ThemeThree'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import AdminUsers from './AdminUsers'
import ConfigurePage from './ConfigurePage'


const InstitutionsAdmin = (props) => {
    const { alert } = props;
    const location = useLocation();
    const currentUser = useSelector((state) => state.auth.currentUser);
    const { state } = location;
    const params = useParams();
    const [otherBranches, setOtherBranches] = useState([]);
    const [oBranchFlag, setOBranchFlag] = useState(false);
    const [practitioners, setPractitioners] = useState([]);
    const [institution, setInstitution] = useState({});
    const history = useHistory();
    const loggedInUserInstitutions = getLoggedInUserInstitutions();
    const [instituteThemeDetails, setInstituteThemeDetails] = useState(null);
    const [Loader, setLoader] = useState(false);
    const [modal, setModal] = useState(false);
    const [awards, setAwards] = useState([]);
    const currentUserId = currentUser.guid;
    // const toggle = () => {
    //     setModal(!modal);
    // };

    const getInstituteThemeDetails = async (instguid) => {
        await InstitutionServices.getDisplayPreferences(instguid)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setInstituteThemeDetails(data);
                }
                else {
                    setInstituteThemeDetails({});
                }
            })
    }

    const checkIfLoginUserIsAdminOfCurrentInstitution = () => {
        let url = window.location.pathname;
        const myArray = url.split("/");
        let profileId = myArray[myArray.length - 2];

        InstitutionServices.getInstitutionById(profileId)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setInstitution(data);
                    if (!(loggedInUserInstitutions && loggedInUserInstitutions.includes(data?.guid)) && !isAdmin()) {
                        history.push("/404");
                    }
                } else {
                    history.push("/404");
                }
            })
    };

    useEffect(() => {
        checkIfLoginUserIsAdminOfCurrentInstitution();
    }, [])

    useEffect(() => {
        if (institution?.guid) {
            getInstituteThemeDetails(institution?.guid);
        }
    }, [institution?.guid]);

    const [active_setting_pannel, setActive_setting_pannel] = useState('users');


    function active_pannel_handle(evt, val) {
        evt.preventDefault();
        setActive_setting_pannel(val);
    }

    const getAllOtherBranches = async () => {
        setOBranchFlag(true);
        const response = await InstitutionServices.instituteOtherLocation(
            institution?.profileId
        );
        if (response.status === 200) {
            const { data } = response;
            data.map((obranch, id) => {
                if (obranch.avatarId) {
                    const imageSet = `${process.env.REACT_APP_IMAGE_BASE_URL}/${obranch.guid}/${obranch.avatarId}`;
                    data[id].imageSource = imageSet;
                }
            })
            setOBranchFlag(true);
            let instOBranches = data.filter(obranch => obranch.profileId !== institution.profileId);
            setOtherBranches(instOBranches);
        } else {
            setOBranchFlag(false);
        }
    };

    const getlistOfawards = async () => {
        const response = await InstitutionServices.awardsInstitution(
            institution?.guid
        );
        if (response.status === 200) {
            setAwards(response.data);
        }
    };

    useEffect(() => {
        if (institution?.guid) {
            getlistOfawards();
        }
    }, [institution?.guid]);

    useEffect(() => {
        PractitionerService.getPractitionersAssociatedtoInstitutionWithPagination(
            institution?.guid
        ).then((response) => {
            const { status, data } = response;
            if (status === 200) {
                setPractitioners(data.contents);
            } else {
                console.log("Something went wrong");
            }
        });
    }, [institution]);

    useEffect(() => {
        if (institution?.profileId) {
            getAllOtherBranches();
        }
    }, [institution?.profileId])

    return (
        Loader ? (
            <div className="d-flex justify-content-center mt-3">
                <div className="loading-spiner">
                    <Spinner style={{ width: "2rem", height: "2rem" }} />
                </div>
            </div>
        ) : (
            <div className='nex-institution-admin-wrap'>
                <div className="container-fluid">
                    <div className="row nex-main-content-flex">
                        <div className="nex-insti-admin-left-sidebar">
                            <div className="nexogic-sidebar-stiky">
                                <ul className="nexogic-aside-menu v2 card">
                                    <li className={`nav-item ${active_setting_pannel === 'users' ? 'active' : ''}`}>
                                        <a href=" " onClick={(e) => active_pannel_handle(e, 'users')} className='nav-link'>
                                            <span className='icon'>
                                            <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8359 12.5C17.6769 12.5 19.1693 13.9924 19.1693 15.8333V17.5H17.5026M13.3359 9.06164C14.7736 8.69161 15.8359 7.38652 15.8359 5.83332C15.8359 4.28012 14.7736 2.97504 13.3359 2.60501M10.8359 5.83333C10.8359 7.67428 9.34355 9.16667 7.5026 9.16667C5.66165 9.16667 4.16927 7.67428 4.16927 5.83333C4.16927 3.99238 5.66165 2.5 7.5026 2.5C9.34355 2.5 10.8359 3.99238 10.8359 5.83333ZM4.16927 12.5H10.8359C12.6769 12.5 14.1693 13.9924 14.1693 15.8333V17.5H0.835938V15.8333C0.835938 13.9924 2.32832 12.5 4.16927 12.5Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
                                            </span>Users</a>
                                    </li>
                                    <li className={`nav-item  ${active_setting_pannel === 'theme' ? 'active' : ''}`}>
                                        <a href=" " onClick={(e) => active_pannel_handle(e, 'theme')} className='nav-link'>
                                            <span className='icon'>
                                            <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6693 5L6.66927 10V13.3333H10.0026L15.0026 8.33333M11.6693 5L14.1693 2.5L17.5026 5.83333L15.0026 8.33333M11.6693 5L15.0026 8.33333M8.33594 3.33333L3.33594 3.33333L3.33594 16.6667L16.6693 16.6667V11.6667"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
                                            </span>Configure</a>
                                    </li>
                                    <li className={`nav-item  ${active_setting_pannel === 'analytics' ? 'active' : ''}`}>
                                        <a href=" " onClick={(e) => active_pannel_handle(e, 'analytics')} className='nav-link'>
                                            <span className='icon'>
                                            <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 8C0.5 12.1421 3.85786 15.5 8 15.5C10.0711 15.5 11.9461 14.6605 13.3033 13.3033M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5M0.5 8H8M8 0.5C12.1421 0.5 15.5 3.85786 15.5 8C15.5 10.0711 14.6605 11.9461 13.3033 13.3033M8 0.5V8M13.3033 13.3033L8 8"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
                                            </span>Analytics</a>
                                    </li>
                                    <li className={`nav-item  ${active_setting_pannel === 'settings' ? 'active' : ''}`}>
                                        <a href=" " onClick={(e) => active_pannel_handle(e, 'settings')} className='nav-link'>
                                            <span className='icon'>
                                            <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.16667 0.5H8.83333C9.29357 0.5 9.66667 0.873096 9.66667 1.33333V1.80732C9.66667 2.16382 9.90593 2.47354 10.2352 2.61024C10.5646 2.74699 10.9481 2.69487 11.2002 2.44269L11.5355 2.10742C11.8609 1.78199 12.3886 1.78199 12.714 2.10742L13.8925 3.28594C14.218 3.61137 14.218 4.13901 13.8925 4.46445L13.5573 4.79967C13.3051 5.05186 13.253 5.43539 13.3897 5.76479C13.5265 6.09406 13.8362 6.33333 14.1927 6.33333L14.6667 6.33333C15.1269 6.33333 15.5 6.70643 15.5 7.16667V8.83333C15.5 9.29357 15.1269 9.66667 14.6667 9.66667H14.1927C13.8362 9.66667 13.5265 9.90594 13.3898 10.2352C13.253 10.5646 13.3051 10.9481 13.5573 11.2003L13.8925 11.5355C14.218 11.861 14.218 12.3886 13.8925 12.714L12.714 13.8925C12.3886 14.218 11.861 14.218 11.5355 13.8925L11.2003 13.5573C10.9481 13.3051 10.5646 13.253 10.2352 13.3898C9.90594 13.5265 9.66667 13.8362 9.66667 14.1927V14.6667C9.66667 15.1269 9.29357 15.5 8.83333 15.5H7.16667C6.70643 15.5 6.33333 15.1269 6.33333 14.6667V14.1927C6.33333 13.8362 6.09406 13.5265 5.76479 13.3897C5.43539 13.253 5.05186 13.3051 4.79966 13.5573L4.46443 13.8925C4.139 14.218 3.61136 14.218 3.28592 13.8925L2.10741 12.714C1.78197 12.3886 1.78197 11.861 2.10741 11.5355L2.44269 11.2002C2.69487 10.9481 2.74699 10.5646 2.61024 10.2352C2.47354 9.90593 2.16382 9.66667 1.80732 9.66667H1.33333C0.873096 9.66667 0.5 9.29357 0.5 8.83333V7.16667C0.5 6.70643 0.873096 6.33333 1.33333 6.33333L1.80731 6.33333C2.16382 6.33333 2.47354 6.09406 2.61024 5.7648C2.747 5.43542 2.69488 5.0519 2.44269 4.79972L2.10742 4.46445C1.78199 4.13901 1.78199 3.61137 2.10742 3.28594L3.28594 2.10743C3.61137 1.78199 4.13901 1.78199 4.46445 2.10743L4.79971 2.44269C5.0519 2.69488 5.43542 2.747 5.7648 2.61024C6.09406 2.47354 6.33333 2.16381 6.33333 1.8073V1.33333C6.33333 0.873096 6.70643 0.5 7.16667 0.5Z"
      stroke="currentColor"
    />
    <path
      d="M9.66667 8C9.66667 8.92047 8.92047 9.66667 8 9.66667C7.07953 9.66667 6.33333 8.92047 6.33333 8C6.33333 7.07953 7.07953 6.33333 8 6.33333C8.92047 6.33333 9.66667 7.07953 9.66667 8Z"
      stroke="currentColor"
    />
  </svg>
                                            </span>Settings</a>
                                    </li>
                                </ul>
                                <div>
                                    <a className='btn nex-btn-primary w-full'
                                            href={`${process.env.REACT_APP_WEBSITE_URL}/institutions/${institution?.profileId}`}
                                            target="_blank"
                                        >
                                            Visit Nexogic Website
                                    </a>
                                </div>
                                
                            </div>
                        </div>
                        <div className="nex-insti-admin-right-content">
                            {active_setting_pannel === 'users' &&
                                <AdminUsers institution={institution} />
                            }
                            {/* customized */}
                            {active_setting_pannel === 'theme' &&
                                <ConfigurePage institution={institution} instituteThemeDetails={instituteThemeDetails} otherBranches={otherBranches} practitioners={practitioners} awards={awards} currentUserId={currentUserId} />
                            }

                            {active_setting_pannel === 'analytics' &&
                                <CollapseablePannel title="Analytics">
                                    Coming Soon.
                                </CollapseablePannel>
                            }

                            {/* setting tabs */}
                            {active_setting_pannel === 'settings' &&
                                <CollapseablePannel title="Settings">

                                    {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                                        <CollapseableItem title="Integrations">
                                            <Integrations isTitle={false} />
                                        </CollapseableItem>
                                    }

                                    {institution &&
                                        <CollapseableItem title="Preferences">
                                            <InstitutionPreference isTitle={false} institution={institution} />
                                        </CollapseableItem>
                                    }
                                    {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                                        <CollapseableItem title="Set Notifications">
                                            <SetNotifications isTitle={false} />
                                        </CollapseableItem>
                                    }

                                </CollapseablePannel>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}
export default withAlert()(InstitutionsAdmin);
