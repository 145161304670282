import React from 'react'

export default function IconVarify() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#5CBA0D"
        d="M15.318 7.841l-.757-1.325.095-1.547a.32.32 0 00-.136-.282l-1.263-.884-.567-1.42a.32.32 0 00-.245-.197l-1.515-.252L9.793.892a.32.32 0 00-.308-.07l-1.484.441L6.516.821a.32.32 0 00-.307.07L5.072 1.935l-1.516.253a.32.32 0 00-.244.196l-.568 1.42-1.263.885a.32.32 0 00-.136.281l.095 1.548-.757 1.325a.32.32 0 000 .317l.757 1.325-.095 1.547a.32.32 0 00.136.282l1.263.884.568 1.42a.32.32 0 00.244.197l1.516.253 1.137 1.042a.32.32 0 00.307.07l1.485-.442 1.484.442a.317.317 0 00.308-.07l1.137-1.043 1.515-.253a.32.32 0 00.245-.196l.567-1.42 1.263-.885a.32.32 0 00.136-.281l-.095-1.547.757-1.326a.319.319 0 000-.317zM7.522 11.05L4.618 8.43l.429-.475 2.345 2.115L10.9 4.627l.538.346-3.916 6.077z"
      ></path>
    </svg>
  )
}
