import {UPDATE_PROFILE_PROGRESS, PROFILE_ADD_EVENT} from "./Actions"
let initialState={
  eventName: null,
  data: [
    {
      id: 0,
      name: "Add Experience",
      tip: 'Add your professional experience so that your colleagues can easily find you',
      event: "ADD_EXPERIENCE",
      filled: false
    },
    {
      id: 1,
      name: "Add Personal Info",
      tip: 'Add your personal information',
      event: "ADD_PERSONAL_INFO",
      filled: true
    },
    {
      id: 2,
      name: "Add About",
      tip: 'Add your profile summary',
      event: "ADD_ABOUT",
      filled: false
    },
    {
      id: 3,
      name: "Add Skill",
      tip: 'Add your skills',
      event: "ADD_SKILL",
      filled: false
    },
    {
      id: 4,
      name: "Add Affiliation",
      tip: 'Add affiliation',
      event: "ADD_Affiliation",
      filled: false
    },
    {
      id: 5,
      name: "Add Education",
      tip: 'Add your education',
      event: "ADD_EDUCATION",
      filled: false
    },
    {
      id: 6,
      name: "Add Certifications & Licensure",
      tip: 'Add your certifications and licensure you owned',
      event: "ADD_CERTIFICATIONS",
      filled: false
    },
    {
      id: 7,
      name: "Add Memberships",
      tip: 'Add your professional memberships',
      event: "ADD_MEMBERSHIPS",
      filled: false
    },
    {
      id: 8,
      name: "Add Publications & Presentations",
      tip: 'Add your publications and presentations',
      event: "ADD_PUB",
      filled: false
    },
    {
      id: 9,
      name: "Add Awards & Recognitions",
      tip: 'Add your awards, recognitions for more valued profile',
      event: "ADD_AWARDS",
      filled: false
    },
    {
      id: 10,
      name: "Add Clinical Trail",
      tip: 'Add your clinical trails',
      event: "ADD_CLINICAL",
      filled: false
    },
    {
      id: 11,
      name: "Add Committees",
      tip: 'Add your linked committees',
      event: "ADD_COMMITTEES",
      filled: false
    },
    {
      id: 12,
      name: "Add Social",
      tip: 'Add your social handles',
      event: "ADD_SOCIAL",
      filled: false
    },
    {
      id: 13,
      name: "Add Registration",
      tip: 'Add your registration number and contact details',
      event: "ADD_Registration",
      filled: false
    },
    {
      id: 14,
      name: "Add Service",
      tip: 'Add your services',
      event: "ADD_SERVICE",
      filled: false
    },
  ]
}
function ProfileCompleteReducer(state = initialState, action){
    switch(action.type){
      case UPDATE_PROFILE_PROGRESS:
        const newData = [...state.data]
        newData[action.index].filled = action.value
        return {
          ...state,
          data: newData
        };

      case PROFILE_ADD_EVENT:
        return {
          ...state,
          eventName: action.eventName
        }
     
      default:
        return state;
    }
  }
  export default ProfileCompleteReducer;