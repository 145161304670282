import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
  } from "react";  
import { Row, Col, Button,InputGroup } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import {emailNonNumericRegEx, onlyAlphabets,FORM_CONSTANTS} from "utils/Constants"
import { isEmpty } from "lodash";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikDatePicker from "components/forms/formikFields/FormikDatePicker";
import logo from "assets/images/logo-white.png";

import verifyMobileImg from "assets/images/otp-flow/verifyMobile.svg";
import FormikMobileField from "components/forms/formikFields/FormikMobileField";
// import './style.scss'

const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{5})(( x| ext)\d{1,5}){0,1}$/;

  const initValues = {
    
};

// const [initialValues, setInitialValues] = useState(initValues);

const validationSchema = Yup.object({
    firstName: Yup.string().when({
        is: (val) => val === undefined,
        then: Yup.string()
            .min(2)
            .max(30)
            .matches(onlyAlphabets, "Only Alphabets")
            .required("This field is required"),
        otherwise: Yup.string(),
    }),
    lastName: Yup.string().when({
        is: (val) => val === undefined,
        then: Yup.string()
            .min(2)
            .max(30)
            .matches(onlyAlphabets, "Only Alphabets")
            .required("This field is required"),
        otherwise: Yup.string(),
    }),
    email: Yup.string()
        .max(50)
        .matches(emailNonNumericRegEx, "Please Enter an valid Email")
        .required("This field is required"),
    password: Yup.string()
        .min(8)
        .max(32)
        .required("This field is required")
        .matches(
            /^(?=(.*[a-z]){3,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,}).{8,}$/,
            "Too weak. Try to add lower-case, upper-case, numbers, special characters, 8 or more characters"
        )
        .matches(
            /(?=(.*[!@#$%^&*()\-__+.]){1,})/,
            "Moderate. Try to add aleast 1 special characters or symbols"
        ),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
});

const RegisterForm = ({ handleSubmit, error, odetails, vmode }) => {
    const [isSubmitting, setSubmitting] = useState(false);
    // const initValues = useMemo(() => {
    //     return {
    //         firstName: "",
    //         lastName: "",
    //         phone:"",
    //         email: "",
    //         password: "",
    //         confirmPassword: "",
    //     };
    //   }, []);
    
    //   const [initialValues, setInitialValues] = useState(initValues);

      const initialValues ={
        firstName: "",
        lastName: "",
        // phone:odetails? odetails.phone : "",
        phone: vmode==="PHONE" ? odetails ? odetails.phone : "":"",
        email: vmode==="EMAIL" ? odetails ? odetails.email : "":"",
        password: "",
        confirmPassword: "",
  //   rememberMe: false,
    };
  return (
    <div className="nexogic-login-register-card card border-0 bg-transparent">
    <Row>
      <Col md={6} xs={12} className="nexogic-form-side-content text-center">
      <div className="nexogic-form-info">
        <p><img src={logo} alt="" className="nexogic-filter-white" /></p>
        <p className="text-white">Welcome to</p>
            <h1 className="text-white">Nexogic Portal</h1>
            {/* <h3>Patient Portal</h3> */}
            </div>
            <div className="nexogic-form-info">
          <img src={verifyMobileImg} className="img-fluid" alt="nexogic" />
        </div>
      </Col>
      <Col md={6} xs={12} className="nexogic-form-content card-body p-0">
      <div className="nexogic-form-container100">
      {/* <h3 className="nexogic-form-title mb-2">Create Account</h3> */}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              children={(props) => {
                return (
                  <>
                    <RenderForm
                      {...props}
                      isSubmitting={isSubmitting}
                    />
                  </>
                );
              }}
            />

            {/* <p className="text-muted text-center mt-2 mb-3 dth-ac">
              Already have an account? <Link to="/login">Sign in</Link>{" "}
            </p> */}
          </div>
      </Col>
    </Row>
    </div>
  );
};

export default RegisterForm;

const RenderForm = (props) => {
  const {
    values,
    errors,
    isSubmitting,
    handleSubmit,
  } = props;
  const [showPassword, onShowPassword] = useState(false);
  const toggleShowPassword = () => onShowPassword(!showPassword);
  const [showCPassword, onShowCPassword] = useState(false);
  const toggleCShowPassword = () => onShowCPassword(!showCPassword);

  const copyDisable = (fieldName) => {
    const passwordInput = document.getElementById(fieldName);
    passwordInput.onpaste = (e) => {
      e.preventDefault();
      return false;
    };
    passwordInput.oncut = (e) => {
      e.preventDefault();
      return false;
    };
    passwordInput.oncopy = (e) => {
      e.preventDefault();
      return false;
    };
  };

  useEffect(() => {
    copyDisable("password");
    copyDisable("confirmPassword");
  }, []);

  return (
    <div>
      <form className="personal-details-form" onSubmit={handleSubmit}>
        {/* {userProfileId === "" && ( */}
        <>
          <Row>
            <Col lg={6} className="w-40">
              <Field
                id="firstName"
                name="firstName"
                autoComplete="firstName"
                type="text"
                component={FormikInputField}
                label="First Name"
                placeholder="First Name"
              />
            </Col>
            <Col lg={6} className="w-40">
              <Field
                id="lastName"
                name="lastName"
                autoComplete="lastName"
                type="text"
                component={FormikInputField}
                label="Last Name"
                placeholder="Last Name"
              />
            </Col>
          </Row>
        </>
        {/* )} */}
        {/* <Row>
          <Col lg={6}>
            <Field
              id="dob"
              name="dob"
              type="text"
              label="Date of Birth"
              component={FormikDatePicker}
              inputprops={{
                dateFormat: "dd/MM/yyyy",
                showYearDropdown: true,
                showMonthDropdown: true,
                calendarClassName: "mycustomcal",
                scrollableYearDropdown: true,
                yearDropdownItemNumber: 10,
                autoComplete: "off",
              }}
              placeholder="DD/MM/YYYY"
            />
          </Col>
          <Col lg={6}>
            <Field
              id="gender"
              name="gender"
              type="text"
              label="Gender"
              component={FormikSelectField}
              inputprops={{
                name: "gender",
                options: FORM_CONSTANTS.GENDERS,
                keys: {
                  id: "value",
                  label: "label",
                },
              }}
            />
          </Col>
        </Row> */}
        <Row>
          <Col lg={6}>
            <div className="nexogic-phone-field-wrap">
              <Field
                id="phone"
                name="phone"
                autoComplete="phone"
                type="text"
                component={FormikMobileField}
                label="Mobile Number"
                placeholder="Enter number"
                value={props.initialValues.phone}
                className="w-100"
                disabled={props.initialValues.phone === "" ? false : true}
              />
            </div>
          </Col>
          {/* </Row>
        <Row> */}
          <Col lg={6}>
            <Field
              id="email"
              name="email"
              autoComplete="email"
              type="text"
              component={FormikInputField}
              label="Email Address"
                value={props.initialValues.phone}
                placeholder="Enter email"
                disabled={props.initialValues.email === "" ? false : true}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <div className="nexogic-password-field-wrap">
              <div className="password-eye">
                <Field
                  id="password"
                  name="password"
                  autoComplete="password"
                  type={showPassword ? "text " : "password"}
                  component={FormikInputField}
                  label="Create Password"
                  placeholder="Password"
                //   className="reg-password"
                />
                {!!errors.password === false && (
                  <FontAwesomeIcon
                    onClick={toggleShowPassword}
                    className="show-password"
                    color="#ccc"
                    icon={!showPassword ? faEyeSlash : faEye}
                  />
                )}
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="nexogic-password-field-wrap">
              <div className="password-eye">
                <Field
                  id="confirmPassword"
                  name="confirmPassword"
                  autoComplete="confirmPassword"
                  type={showCPassword ? "text " : "password"}
                  component={FormikInputField}
                  label="Confirm Password"
                  placeholder="Password"
                />
                {!!errors.confirmPassword === false && (
                  <FontAwesomeIcon
                    onClick={toggleCShowPassword}
                    className="show-password"
                    color="#ccc"
                    icon={!showCPassword ? faEyeSlash : faEye}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
<center>
        <Button
          className="mt-4 mb-4 d-block ml-auto nexogic_primary_button next-btn"
          type="submit"
          //   disabled={isSubmitting}
          //   onClick={() => addressDetails(address)}
        >
          Create Account
        </Button>
        </center>
      </form>
    </div>
  );
};
// import { Field, Formik } from "formik";
// import { isEmpty } from "lodash";
// // import "react-phone-input-2/lib/style.css";
// import { Button, Col, InputGroup, Row, Spinner,Label } from "reactstrap";
// import FormikInputField from "Pages/common/forms/formikFields/FormikInputField";
// // import FormikMobileField from "Pages/common/forms/formikFields/FormikMobileField";

// const RegisterForm = ({ isSubmitting, handleSubmit, error }) => {
//     return(
//   <div>
//     <form onSubmit={handleSubmit}>
//       <Row>
//       <Col xs={6} className="w-40">
//                   <Field
//                     id="firstName"
//                     name="firstName"
//                     autoComplete="firstName"
//                     type="text"
//                     component={FormikInputField}
//                     label="First Name"
//                     placeholder="Your first name"
//                     // disabled={!iseditable}
//                   />
//                 </Col>
//                 <Col md={6} className="w-40">
//                   <Field
//                     id="lastName"
//                     name="lastName"
//                     autoComplete="lastName"
//                     type="text"
//                     component={FormikInputField}
//                     label="Last Name"
//                     placeholder="Your last name"
//                     // disabled={!iseditable}
//                   />
//                 </Col>
//         {error ? <div className="otp-flow__error m-auto">{error}</div> : null}
//       </Row>

//       {/* <Row className="mb-4 mt-4 align-items-center">
//         <Col xs={12}>
//           {isSubmitting ? (
//             <div className="loading-spiner text-center">
//               <Spinner style={{ width: "2rem", height: "2rem" }} />
//             </div>
//           ) : (
//             <Button
//               type="submit"
//               className="nexogic_primary_button"
//               style={{ width: "100%" }}
//               color="primary"
//               size="md"
//             >
//               Receive OTP
//             </Button>
//           )}
//         </Col>
//       </Row> */}
//     </form>
//   </div>
// )};

// export default RegisterForm;
