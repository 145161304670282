import QuickPractitioner from 'components/QuickPractitioner/QuickPractitioner';
import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { Row, Col } from 'reactstrap'
import { encrypt } from 'utils/EncryptDecrypt';
import { getAge, getGenderDetails, capitalizeFirstLetter, createImageFromInitials, getRandomColor } from 'utils/Utils';

export default function PatientDetailsInfo(props) {
  const { patient, patientType } = props;

  return (
    <>
      <div className='nexogic-reffer-card need-margin nex-stack'>

        <div className='nex-avatar-info'>
          <QuickPractitioner
            isTitleLink={true}
            titleLink={`/patient-profile/${patient?.id}`}
            avatar={
              patient?.avatarId ?
                <img
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL} /${patient?.id}/${patient?.avatarId}`}
                  className="nex-avatar lg"
                  alt="#"
                />
                :
                <>
                  <img src={createImageFromInitials(50, `${(patient?.firstName + " " + patient?.lastName)}`, getRandomColor(encrypt(patient?.id).toString()))} className="nex-avatar lg" alt="#" />
                </>
            }
            name={`${patient?.firstName} ${patient?.lastName}`}
          >
            <div className="nex-text">{capitalizeFirstLetter(patientType.toLowerCase())}</div>
          </QuickPractitioner>
        </div>
        <div className="info">
          <div className="nex-text text-black">Genders : {getGenderDetails(patient?.gender)}</div>
          <div className="nex-text text-black">Type : {capitalizeFirstLetter(patientType)}</div>

        </div>
        <div className="info">
          {patient?.emailAddress &&
            <div className="nex-text text-black nexogic-word-break">Email : {patient?.emailAddress}</div>
          }
        </div>
      </div >
    </>
  )
}
