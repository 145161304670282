import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button } from 'reactstrap'

export default function PasswordDetails() {
  return (
    <div id='notification-password'>
        <div className="row">
            <div className="col-lg-5">
                <div className="password-eye">
                    <div className="flex-grow-1 form-group">
                        <label htmlFor="">Current password</label>
                        <input type="text" name="" id="np-current-pass" className='form-control' />
                        <FontAwesomeIcon
                                className="show-password"
                                color="#2C3E50"
                                icon={faEyeSlash}
                              />
                    </div>
                </div>
                <div className="password-eye">
                    <div className="flex-grow-1 form-group">
                        <label htmlFor="">New password</label>
                        <input type="text" name="" id="np-current-pass-new" className='form-control' />
                        <FontAwesomeIcon
                                className="show-password"
                                color="#2C3E50"
                                icon={faEyeSlash}
                              />
                    </div>
                </div>
                <div className="password-eye">
                    <div className="flex-grow-1 form-group">
                        <label htmlFor="">Confirm new password</label>
                        <input type="text" name="" id="np-current-pass-new-confirm" className='form-control' />
                        <FontAwesomeIcon
                                className="show-password"
                                color="#2C3E50"
                                icon={faEyeSlash}
                              />
                    </div>
                </div>
                <div className="flex-grow-1 form-group">
                <Button type="submit" className="change-pwd nexogic_primary_button mb-3" id="nex-np-cp-btn">
                            Change password
                          </Button>
                          </div>
            </div>
        </div>
    </div>
  )
}
