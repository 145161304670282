import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { withAlert } from "react-alert"
import SkillsForm from "./SkillsForm"
import SkillsFormBulkEdit from './SkillsFormBulkEdit';

const RenderSkillsAndExpertiseForm = (props) => {
  const { skills, isOpen, toggle, setPractitioner, practitioner, isAdmin } = props
  const [addInBulk, setAddInBulk] = useState(false);
  const handleClick = () => {
    setAddInBulk(!addInBulk);
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} id="profile-modal" className="skills-modal modal-dialog-scrollable modal-dialog-centered skills-expertise-modal" backdrop="static">
        <ModalHeader toggle={toggle}>
          <div className='modal-filter-header'>
          {`Skills & Expertise`}
          <span className='ml-auto'>
            <label className="mb-0 mr-2">Add in Bulk</label>
            <input name='addInBulk' id='addInBulk' type="checkbox" checked={addInBulk} onClick={() => handleClick()} />
          </span>
          </div>
        </ModalHeader>
        <ModalBody>
          {addInBulk ?
            <SkillsFormBulkEdit
              skills={skills}
              toggle={toggle}
              setPractitioner={setPractitioner}
              practitioner={practitioner}
              isAdmin={isAdmin}
            />
            :

            <SkillsForm
              skills={skills}
              toggle={toggle}
              setPractitioner={setPractitioner}
              practitioner={practitioner}
              isAdmin={isAdmin}
            />
          }
        </ModalBody>
      </Modal>
    </div>
  );
}


export default withAlert()(RenderSkillsAndExpertiseForm)



