import "assets/styles/typeahead.css";
import { ErrorMessage } from "formik";
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Label } from "reactstrap";

const SpecialityTypeaheadField = (props) => {
  const {
    disabled,
    placeholder,
    className,
    selectdItem,
    itemRef,
    data,
    allowNew,
  } = props;
  const { name, value } = props.field;
  const { setFieldValue, setFieldTouched, touched, errors } = props.form;

  const handleChange = (selected) => {
    if (selected.length > 0) {
      setFieldValue(name, selected, true);
    } else {
      setFieldValue(name, [], true);
    }
  };

  return (
    <>
      {props.label && (
        <Label htmlFor={name} className="d-block skills">
          {props.label}
        </Label>
      )}
      <Typeahead
        ref={itemRef}
        className={className}
        disabled={disabled}
        selected={value}
        id={name}
        options={data}
        labelKey={"value"}
        placeholder={placeholder}
        allowNew={allowNew}
        onChange={handleChange}
        onInputChange={(input) => selectdItem([input])}
        onBlur={(e) => {
          setFieldTouched(name, true);
          itemRef?.current && itemRef.current.hideMenu();
        }}
        isInvalid={Boolean(touched[name] && errors[name])}
      />
      <ErrorMessage
        name={name}
        render={(msg) => (
          <span className="text-danger skill-err f-12 d-inline-block  mt-1 line-height-error">
            {msg}
          </span>
        )}
      />
    </>
  );
};

SpecialityTypeaheadField.defaultProps = {
  allowNew: true,
};

export default SpecialityTypeaheadField;
