import React from 'react'
import homeIcon from "../../../../assets/images/svgs/home-icon.svg";
import dashboard_admin_icon from "../../../../assets/images/svgs/dashboard-icon.svg";
import contactsIcon from "../../../../assets/images/svgs/connections-icon.svg";
import settingsIcon from "../../../../assets/images/svgs/settings-icon.svg";
import jobsIcon from "../../../../assets/images/svgs/job-icon.svg";
import chatIcon from "../../../../assets/images/svgs/messages-icon.svg";
import appointmentIcon from "../../../../assets/images/svgs/calendar-icon.svg";
import referals from "../../../../assets/images/svgs/referals-icon.svg";
import doctor_icon from "../../../../assets/images/svgs/doctor-icon.svg";
import reviewIcon from "../../../../assets/images/svgs/reviews-icon.svg";
import ninedot from "../../../../assets/images/svgs/nine-dot-icon.svg";
import profile_icon from "../../../../assets/images/svgs/profile-icon-blue.svg";
import second_opinion from "../../../../assets/images/svgs/second-opinion.svg";
import nex_profile_icon from "../../../../assets/images/nex-profile-icon.png";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { isAdmin, isInstitutionAdmin, isPatient, isPractitioner } from 'utils/UserHelper';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { FAILTOSAVERECOED } from 'utils/Constants';
export default function HeaderMenu() {
    let location = useLocation();
    const isAdminUser = isAdmin();
    const isInstitutionAdminUser = isInstitutionAdmin();
    const isPractitionerUser = isPractitioner();
    const isPatientUser = isPatient();
    const currentUser = useSelector((state) => state.auth.currentUser);
    const isPractitionerEligibleForReferrals = useSelector(state => state.PreferenceFlags?.referalStatusFlag);
    function regHasString(text, chek_word) {
        const pattern = new RegExp(chek_word);
        return pattern.test(text);
    }
    const moreMenuRef = useRef(null);
    const [moreActive, setMoreActive] = useState(false);

    useOutsideAlerter(moreMenuRef);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setTimeout(() => {
                        setMoreActive(false);
                    }, 300)

                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    return (
        <>
            <div className='nexogic-header-menu'>
                <ul className="list-group">
                    {((isPractitionerUser && currentUser.status === "APPROVED") || isInstitutionAdminUser || isPatientUser) ?
                        <li className="list-group-item d-flex align-items-center hide-after-md" >
                            <Link id="nex-hm-t-home-link" to="/home" className={location.pathname === "/home" ? 'active' : ''}>
                                <span className="icon"><svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M21.5 9.84209V18.9614C21.5 21.56 19.4107 23.6667 16.8333 23.6667H5.16667C2.58934 23.6667 0.5 21.5601 0.5 18.9614V9.84209C0.5 8.42931 1.12959 7.09129 2.21484 6.19765L8.04817 1.39428C9.76602 -0.0202593 12.234 -0.0202622 13.9518 1.39428L19.7852 6.19765C20.8704 7.09129 21.5 8.42931 21.5 9.84209ZM8.66667 18.125C8.18342 18.125 7.79167 18.5168 7.79167 19C7.79167 19.4833 8.18342 19.875 8.66667 19.875H13.3333C13.8166 19.875 14.2083 19.4833 14.2083 19C14.2083 18.5168 13.8166 18.125 13.3333 18.125H8.66667Z" fill="#5EA9C6" />
                                </svg>
                                </span> Home</Link>
                        </li>
                        : isAdminUser ? "" :
                        <li className="list-group-item d-flex align-items-center hide-after-md" >
                            <Link id="text-warning" to="/home" className={location.pathname === "/home" ? 'active' : ''} style={{ pointerEvents: 'none' }}>
                                <span className="icon"><svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M21.5 9.84209V18.9614C21.5 21.56 19.4107 23.6667 16.8333 23.6667H5.16667C2.58934 23.6667 0.5 21.5601 0.5 18.9614V9.84209C0.5 8.42931 1.12959 7.09129 2.21484 6.19765L8.04817 1.39428C9.76602 -0.0202593 12.234 -0.0202622 13.9518 1.39428L19.7852 6.19765C20.8704 7.09129 21.5 8.42931 21.5 9.84209ZM8.66667 18.125C8.18342 18.125 7.79167 18.5168 7.79167 19C7.79167 19.4833 8.18342 19.875 8.66667 19.875H13.3333C13.8166 19.875 14.2083 19.4833 14.2083 19C14.2083 18.5168 13.8166 18.125 13.3333 18.125H8.66667Z" fill="#aeb1b1" />
                                </svg>
                                </span> Home</Link>
                        </li>
                    }
                    {((isPractitionerUser && currentUser.status === "APPROVED") || isInstitutionAdminUser) ?
                        <li className="list-group-item d-flex align-items-center hide-after-md">
                            <Link id="nex-hm-t-msg-link" to="/messages" className={location.pathname === "/messages" ? 'active' : ''}>
                                <div className="icon"><svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.8333 0.5H13.1666C18.9656 0.5 23.6666 5.20101 23.6666 11C23.6666 16.799 18.9656 21.5 13.1666 21.5H4.99992C2.42259 21.5 0.333252 19.4107 0.333252 16.8333V11C0.333252 5.20101 5.03426 0.5 10.8333 0.5ZM7.33325 14.2083C6.85 14.2083 6.45825 13.8166 6.45825 13.3333C6.45825 12.8501 6.85 12.4583 7.33325 12.4583H11.9999C12.4832 12.4583 12.8749 12.8501 12.8749 13.3333C12.8749 13.8166 12.4832 14.2083 11.9999 14.2083H7.33325ZM7.33325 9.54167C6.85 9.54167 6.45825 9.14992 6.45825 8.66667C6.45825 8.18342 6.85 7.79167 7.33325 7.79167H16.6666C17.1498 7.79167 17.5416 8.18342 17.5416 8.66667C17.5416 9.14992 17.1498 9.54167 16.6666 9.54167H7.33325Z" fill="#5EA9C6" />
                                </svg></div> Messages</Link>
                        </li>  
                        : isAdminUser || isPatientUser ? "" :                     
                        <li className="list-group-item d-flex align-items-center hide-after-md">
                            <Link id="nex-hm-t-msg-link" to="/messages" className={location.pathname === "/messages" ? 'active' : ''} style={{ pointerEvents: 'none' }}>
                                <div className="icon"><svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.8333 0.5H13.1666C18.9656 0.5 23.6666 5.20101 23.6666 11C23.6666 16.799 18.9656 21.5 13.1666 21.5H4.99992C2.42259 21.5 0.333252 19.4107 0.333252 16.8333V11C0.333252 5.20101 5.03426 0.5 10.8333 0.5ZM7.33325 14.2083C6.85 14.2083 6.45825 13.8166 6.45825 13.3333C6.45825 12.8501 6.85 12.4583 7.33325 12.4583H11.9999C12.4832 12.4583 12.8749 12.8501 12.8749 13.3333C12.8749 13.8166 12.4832 14.2083 11.9999 14.2083H7.33325ZM7.33325 9.54167C6.85 9.54167 6.45825 9.14992 6.45825 8.66667C6.45825 8.18342 6.85 7.79167 7.33325 7.79167H16.6666C17.1498 7.79167 17.5416 8.18342 17.5416 8.66667C17.5416 9.14992 17.1498 9.54167 16.6666 9.54167H7.33325Z" fill="#aeb1b1" />
                                </svg></div> Messages</Link>
                        </li>                        
                    }
                    {((isPractitionerUser && currentUser.status === "APPROVED") || isInstitutionAdminUser) ?
                        <li className="list-group-item d-flex align-items-center hide-after-md">
                            <Link id="nex-hm-t-connections-link" to={`${isPractitionerUser ? "/connections/all-connections" : "/connections/pages"}`} className={regHasString(location.pathname, '/connections') ? 'active' : ''}>
                                <span className="icon">
                                    <svg width="48" height="28" viewBox="0 0 48 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M28.6666 8.16667C28.6666 10.744 26.5772 12.8333 23.9999 12.8333C21.4226 12.8333 19.3333 10.744 19.3333 8.16667C19.3333 5.58934 21.4226 3.5 23.9999 3.5C26.5772 3.5 28.6666 5.58934 28.6666 8.16667Z" fill="#5EA9C6" />
                                        <path d="M32.1666 20.4167C32.1666 22.6718 28.5102 24.5 23.9999 24.5C19.4896 24.5 15.8333 22.6718 15.8333 20.4167C15.8333 18.1615 19.4896 16.3333 23.9999 16.3333C28.5102 16.3333 32.1666 18.1615 32.1666 20.4167Z" fill="#5EA9C6" />
                                        <path d="M27.9166 8.16667C27.9166 10.3298 26.163 12.0833 23.9999 12.0833V13.5833C26.9915 13.5833 29.4166 11.1582 29.4166 8.16667H27.9166ZM23.9999 12.0833C21.8368 12.0833 20.0833 10.3298 20.0833 8.16667H18.5833C18.5833 11.1582 21.0084 13.5833 23.9999 13.5833V12.0833ZM20.0833 8.16667C20.0833 6.00355 21.8368 4.25 23.9999 4.25V2.75C21.0084 2.75 18.5833 5.17512 18.5833 8.16667H20.0833ZM23.9999 4.25C26.163 4.25 27.9166 6.00355 27.9166 8.16667H29.4166C29.4166 5.17512 26.9915 2.75 23.9999 2.75V4.25ZM31.4166 20.4167C31.4166 21.1071 30.8374 21.9341 29.4392 22.6332C28.0859 23.3098 26.1639 23.75 23.9999 23.75V25.25C26.3463 25.25 28.5076 24.7761 30.11 23.9748C31.6676 23.196 32.9166 21.9814 32.9166 20.4167H31.4166ZM23.9999 23.75C21.8359 23.75 19.9139 23.3098 18.5606 22.6332C17.1625 21.9341 16.5833 21.1071 16.5833 20.4167H15.0833C15.0833 21.9814 16.3322 23.196 17.8898 23.9748C19.4923 24.7761 21.6536 25.25 23.9999 25.25V23.75ZM16.5833 20.4167C16.5833 19.7262 17.1625 18.8992 18.5606 18.2001C19.9139 17.5235 21.8359 17.0833 23.9999 17.0833V15.5833C21.6536 15.5833 19.4923 16.0573 17.8898 16.8585C16.3322 17.6373 15.0833 18.852 15.0833 20.4167H16.5833ZM23.9999 17.0833C26.1639 17.0833 28.0859 17.5235 29.4392 18.2001C30.8374 18.8992 31.4166 19.7262 31.4166 20.4167H32.9166C32.9166 18.852 31.6676 17.6373 30.11 16.8585C28.5076 16.0573 26.3463 15.5833 23.9999 15.5833V17.0833Z" fill="#5EA9C6" />
                                        <path d="M42 9.25C42 10.9069 40.6569 12.25 39 12.25C37.3431 12.25 36 10.9069 36 9.25C36 7.59315 37.3431 6.25 39 6.25C40.6569 6.25 42 7.59315 42 9.25Z" fill="#5EA9C6" />
                                        <path d="M44.25 17.125C44.25 18.5747 41.8995 19.75 39 19.75C36.1005 19.75 33.75 18.5747 33.75 17.125C33.75 15.6753 36.1005 14.5 39 14.5C41.8995 14.5 44.25 15.6753 44.25 17.125Z" fill="#5EA9C6" />
                                        <path d="M41.25 9.25C41.25 10.4926 40.2426 11.5 39 11.5V13C41.0711 13 42.75 11.3211 42.75 9.25H41.25ZM39 11.5C37.7574 11.5 36.75 10.4926 36.75 9.25H35.25C35.25 11.3211 36.9289 13 39 13V11.5ZM36.75 9.25C36.75 8.00736 37.7574 7 39 7V5.5C36.9289 5.5 35.25 7.17893 35.25 9.25H36.75ZM39 7C40.2426 7 41.25 8.00736 41.25 9.25H42.75C42.75 7.17893 41.0711 5.5 39 5.5V7ZM43.5 17.125C43.5 17.4128 43.2472 17.8752 42.3769 18.3103C41.5514 18.7231 40.3586 19 39 19V20.5C40.5409 20.5 41.9731 20.1893 43.0477 19.652C44.0775 19.1371 45 18.287 45 17.125H43.5ZM39 19C37.6414 19 36.4486 18.7231 35.6231 18.3103C34.7528 17.8752 34.5 17.4128 34.5 17.125H33C33 18.287 33.9225 19.1371 34.9523 19.652C36.0269 20.1893 37.4591 20.5 39 20.5V19ZM34.5 17.125C34.5 16.8372 34.7528 16.3748 35.6231 15.9397C36.4486 15.5269 37.6414 15.25 39 15.25V13.75C37.4591 13.75 36.0269 14.0607 34.9523 14.598C33.9225 15.1129 33 15.963 33 17.125H34.5ZM39 15.25C40.3586 15.25 41.5514 15.5269 42.3769 15.9397C43.2472 16.3748 43.5 16.8372 43.5 17.125H45C45 15.963 44.0775 15.1129 43.0477 14.598C41.9731 14.0607 40.5409 13.75 39 13.75V15.25Z" fill="#5EA9C6" />
                                        <path d="M12 9.25C12 10.9069 10.6569 12.25 9 12.25C7.34315 12.25 6 10.9069 6 9.25C6 7.59315 7.34315 6.25 9 6.25C10.6569 6.25 12 7.59315 12 9.25Z" fill="#5EA9C6" />
                                        <path d="M14.25 17.125C14.25 18.5747 11.8995 19.75 9 19.75C6.10051 19.75 3.75 18.5747 3.75 17.125C3.75 15.6753 6.10051 14.5 9 14.5C11.8995 14.5 14.25 15.6753 14.25 17.125Z" fill="#5EA9C6" />
                                        <path d="M11.25 9.25C11.25 10.4926 10.2426 11.5 9 11.5V13C11.0711 13 12.75 11.3211 12.75 9.25H11.25ZM9 11.5C7.75736 11.5 6.75 10.4926 6.75 9.25H5.25C5.25 11.3211 6.92893 13 9 13V11.5ZM6.75 9.25C6.75 8.00736 7.75736 7 9 7V5.5C6.92893 5.5 5.25 7.17893 5.25 9.25H6.75ZM9 7C10.2426 7 11.25 8.00736 11.25 9.25H12.75C12.75 7.17893 11.0711 5.5 9 5.5V7ZM13.5 17.125C13.5 17.4128 13.2472 17.8752 12.3769 18.3103C11.5514 18.7231 10.3586 19 9 19V20.5C10.5409 20.5 11.9731 20.1893 13.0477 19.652C14.0775 19.1371 15 18.287 15 17.125H13.5ZM9 19C7.64144 19 6.44857 18.7231 5.6231 18.3103C4.75276 17.8752 4.5 17.4128 4.5 17.125H3C3 18.287 3.92249 19.1371 4.95228 19.652C6.02693 20.1893 7.45907 20.5 9 20.5V19ZM4.5 17.125C4.5 16.8372 4.75276 16.3748 5.6231 15.9397C6.44857 15.5269 7.64144 15.25 9 15.25V13.75C7.45907 13.75 6.02693 14.0607 4.95228 14.598C3.92249 15.1129 3 15.963 3 17.125H4.5ZM9 15.25C10.3586 15.25 11.5514 15.5269 12.3769 15.9397C13.2472 16.3748 13.5 16.8372 13.5 17.125H15C15 15.963 14.0775 15.1129 13.0477 14.598C11.9731 14.0607 10.5409 13.75 9 13.75V15.25Z" fill="#5EA9C6" />
                                    </svg>
                                </span>
                                Network</Link>
                        </li>
                        : isAdminUser || isPatientUser ? "" :
                        <li className="list-group-item d-flex align-items-center hide-after-md">
                            <Link id="nex-hm-t-connections-link" to={`${isPractitionerUser ? "/connections/all-connections" : "/connections/pages"}`} className={regHasString(location.pathname, '/connections') ? 'active' : ''} style={{ pointerEvents: 'none' }}>
                                <span className="icon">
                                    <svg width="48" height="28" viewBox="0 0 48 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M28.6666 8.16667C28.6666 10.744 26.5772 12.8333 23.9999 12.8333C21.4226 12.8333 19.3333 10.744 19.3333 8.16667C19.3333 5.58934 21.4226 3.5 23.9999 3.5C26.5772 3.5 28.6666 5.58934 28.6666 8.16667Z" fill="#aeb1b1" />
                                        <path d="M32.1666 20.4167C32.1666 22.6718 28.5102 24.5 23.9999 24.5C19.4896 24.5 15.8333 22.6718 15.8333 20.4167C15.8333 18.1615 19.4896 16.3333 23.9999 16.3333C28.5102 16.3333 32.1666 18.1615 32.1666 20.4167Z" fill="#aeb1b1" />
                                        <path d="M27.9166 8.16667C27.9166 10.3298 26.163 12.0833 23.9999 12.0833V13.5833C26.9915 13.5833 29.4166 11.1582 29.4166 8.16667H27.9166ZM23.9999 12.0833C21.8368 12.0833 20.0833 10.3298 20.0833 8.16667H18.5833C18.5833 11.1582 21.0084 13.5833 23.9999 13.5833V12.0833ZM20.0833 8.16667C20.0833 6.00355 21.8368 4.25 23.9999 4.25V2.75C21.0084 2.75 18.5833 5.17512 18.5833 8.16667H20.0833ZM23.9999 4.25C26.163 4.25 27.9166 6.00355 27.9166 8.16667H29.4166C29.4166 5.17512 26.9915 2.75 23.9999 2.75V4.25ZM31.4166 20.4167C31.4166 21.1071 30.8374 21.9341 29.4392 22.6332C28.0859 23.3098 26.1639 23.75 23.9999 23.75V25.25C26.3463 25.25 28.5076 24.7761 30.11 23.9748C31.6676 23.196 32.9166 21.9814 32.9166 20.4167H31.4166ZM23.9999 23.75C21.8359 23.75 19.9139 23.3098 18.5606 22.6332C17.1625 21.9341 16.5833 21.1071 16.5833 20.4167H15.0833C15.0833 21.9814 16.3322 23.196 17.8898 23.9748C19.4923 24.7761 21.6536 25.25 23.9999 25.25V23.75ZM16.5833 20.4167C16.5833 19.7262 17.1625 18.8992 18.5606 18.2001C19.9139 17.5235 21.8359 17.0833 23.9999 17.0833V15.5833C21.6536 15.5833 19.4923 16.0573 17.8898 16.8585C16.3322 17.6373 15.0833 18.852 15.0833 20.4167H16.5833ZM23.9999 17.0833C26.1639 17.0833 28.0859 17.5235 29.4392 18.2001C30.8374 18.8992 31.4166 19.7262 31.4166 20.4167H32.9166C32.9166 18.852 31.6676 17.6373 30.11 16.8585C28.5076 16.0573 26.3463 15.5833 23.9999 15.5833V17.0833Z" fill="#aeb1b1" />
                                        <path d="M42 9.25C42 10.9069 40.6569 12.25 39 12.25C37.3431 12.25 36 10.9069 36 9.25C36 7.59315 37.3431 6.25 39 6.25C40.6569 6.25 42 7.59315 42 9.25Z" fill="#aeb1b1" />
                                        <path d="M44.25 17.125C44.25 18.5747 41.8995 19.75 39 19.75C36.1005 19.75 33.75 18.5747 33.75 17.125C33.75 15.6753 36.1005 14.5 39 14.5C41.8995 14.5 44.25 15.6753 44.25 17.125Z" fill="#aeb1b1" />
                                        <path d="M41.25 9.25C41.25 10.4926 40.2426 11.5 39 11.5V13C41.0711 13 42.75 11.3211 42.75 9.25H41.25ZM39 11.5C37.7574 11.5 36.75 10.4926 36.75 9.25H35.25C35.25 11.3211 36.9289 13 39 13V11.5ZM36.75 9.25C36.75 8.00736 37.7574 7 39 7V5.5C36.9289 5.5 35.25 7.17893 35.25 9.25H36.75ZM39 7C40.2426 7 41.25 8.00736 41.25 9.25H42.75C42.75 7.17893 41.0711 5.5 39 5.5V7ZM43.5 17.125C43.5 17.4128 43.2472 17.8752 42.3769 18.3103C41.5514 18.7231 40.3586 19 39 19V20.5C40.5409 20.5 41.9731 20.1893 43.0477 19.652C44.0775 19.1371 45 18.287 45 17.125H43.5ZM39 19C37.6414 19 36.4486 18.7231 35.6231 18.3103C34.7528 17.8752 34.5 17.4128 34.5 17.125H33C33 18.287 33.9225 19.1371 34.9523 19.652C36.0269 20.1893 37.4591 20.5 39 20.5V19ZM34.5 17.125C34.5 16.8372 34.7528 16.3748 35.6231 15.9397C36.4486 15.5269 37.6414 15.25 39 15.25V13.75C37.4591 13.75 36.0269 14.0607 34.9523 14.598C33.9225 15.1129 33 15.963 33 17.125H34.5ZM39 15.25C40.3586 15.25 41.5514 15.5269 42.3769 15.9397C43.2472 16.3748 43.5 16.8372 43.5 17.125H45C45 15.963 44.0775 15.1129 43.0477 14.598C41.9731 14.0607 40.5409 13.75 39 13.75V15.25Z" fill="#aeb1b1" />
                                        <path d="M12 9.25C12 10.9069 10.6569 12.25 9 12.25C7.34315 12.25 6 10.9069 6 9.25C6 7.59315 7.34315 6.25 9 6.25C10.6569 6.25 12 7.59315 12 9.25Z" fill="#aeb1b1" />
                                        <path d="M14.25 17.125C14.25 18.5747 11.8995 19.75 9 19.75C6.10051 19.75 3.75 18.5747 3.75 17.125C3.75 15.6753 6.10051 14.5 9 14.5C11.8995 14.5 14.25 15.6753 14.25 17.125Z" fill="#aeb1b1" />
                                        <path d="M11.25 9.25C11.25 10.4926 10.2426 11.5 9 11.5V13C11.0711 13 12.75 11.3211 12.75 9.25H11.25ZM9 11.5C7.75736 11.5 6.75 10.4926 6.75 9.25H5.25C5.25 11.3211 6.92893 13 9 13V11.5ZM6.75 9.25C6.75 8.00736 7.75736 7 9 7V5.5C6.92893 5.5 5.25 7.17893 5.25 9.25H6.75ZM9 7C10.2426 7 11.25 8.00736 11.25 9.25H12.75C12.75 7.17893 11.0711 5.5 9 5.5V7ZM13.5 17.125C13.5 17.4128 13.2472 17.8752 12.3769 18.3103C11.5514 18.7231 10.3586 19 9 19V20.5C10.5409 20.5 11.9731 20.1893 13.0477 19.652C14.0775 19.1371 15 18.287 15 17.125H13.5ZM9 19C7.64144 19 6.44857 18.7231 5.6231 18.3103C4.75276 17.8752 4.5 17.4128 4.5 17.125H3C3 18.287 3.92249 19.1371 4.95228 19.652C6.02693 20.1893 7.45907 20.5 9 20.5V19ZM4.5 17.125C4.5 16.8372 4.75276 16.3748 5.6231 15.9397C6.44857 15.5269 7.64144 15.25 9 15.25V13.75C7.45907 13.75 6.02693 14.0607 4.95228 14.598C3.92249 15.1129 3 15.963 3 17.125H4.5ZM9 15.25C10.3586 15.25 11.5514 15.5269 12.3769 15.9397C13.2472 16.3748 13.5 16.8372 13.5 17.125H15C15 15.963 14.0775 15.1129 13.0477 14.598C11.9731 14.0607 10.5409 13.75 9 13.75V15.25Z" fill="#aeb1b1" />
                                    </svg>
                                </span>
                                Network</Link>
                        </li>
                    }
                    {((isPractitionerUser && currentUser.status === "APPROVED") || isInstitutionAdminUser || isAdminUser) ?
                        <li className="list-group-item d-flex align-items-center hide-after-md">
                            <Link id="nex-hm-t-alljob-link" to="/jobsboard/all-jobs" className={regHasString(location.pathname, '/jobsboard') ? 'active' : ''} >
                                <span className="icon"><svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M11.4998 0.458374C9.0836 0.458374 7.12485 2.41713 7.12485 4.83337V6.00074C4.91411 6.03881 3.02828 7.62618 2.61943 9.80669L0.869435 19.14C0.330948 22.012 2.53419 24.6667 5.45617 24.6667H17.5435C20.4655 24.6667 22.6687 22.012 22.1303 19.14L20.3803 9.80669C19.9714 7.62618 18.0856 6.03881 15.8748 6.00074V4.83337C15.8748 2.41713 13.9161 0.458374 11.4998 0.458374ZM14.1248 6.00004V4.83337C14.1248 3.38363 12.9496 2.20837 11.4998 2.20837C10.0501 2.20837 8.87485 3.38363 8.87485 4.83337V6.00004H14.1248Z" fill="#5EA9C6" />
                                </svg></span>
                                Jobs</Link>
                        </li>
                        : isPatientUser ? "" :
                        <li className="list-group-item d-flex align-items-center hide-after-md">
                            <Link id="nex-hm-t-alljob-link" to="/jobsboard/all-jobs" className={regHasString(location.pathname, '/jobsboard') ? 'active' : ''} style={{ pointerEvents: 'none' }}>
                                <span className="icon"><svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M11.4998 0.458374C9.0836 0.458374 7.12485 2.41713 7.12485 4.83337V6.00074C4.91411 6.03881 3.02828 7.62618 2.61943 9.80669L0.869435 19.14C0.330948 22.012 2.53419 24.6667 5.45617 24.6667H17.5435C20.4655 24.6667 22.6687 22.012 22.1303 19.14L20.3803 9.80669C19.9714 7.62618 18.0856 6.03881 15.8748 6.00074V4.83337C15.8748 2.41713 13.9161 0.458374 11.4998 0.458374ZM14.1248 6.00004V4.83337C14.1248 3.38363 12.9496 2.20837 11.4998 2.20837C10.0501 2.20837 8.87485 3.38363 8.87485 4.83337V6.00004H14.1248Z" fill="#aeb1b1" />
                                </svg></span>
                                Jobs</Link>
                        </li>
                    }
                    {((isPractitionerUser && currentUser.status === "APPROVED") || isPatient()) ?
                        <li className="list-group-item d-flex align-items-center hide-after-md">
                            <Link id="nex-hm-t-so-link" to={isPatient() ? "/u/medicalcases/my-opinions" : "/u/medicalcases/received-opinions"} className={regHasString(location.pathname, '/medicalcases/lists') ? 'active' : ''} >
                                <span className="icon">
                                    <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.5 0L0.5 11.1176H4.1V21H20.9V11.1176H24.5L12.5 0ZM8.9 6.17647H13.7C14.3365 6.17647 14.947 6.43676 15.3971 6.90009C15.8471 7.36341 16.1 7.99182 16.1 8.64706V11.1176C16.1 11.7729 15.8471 12.4013 15.3971 12.8646C14.947 13.3279 14.3365 13.5882 13.7 13.5882H11.3V16.0588H16.1V18.5294H8.9V13.5882C8.9 12.933 9.15286 12.3046 9.60294 11.8413C10.053 11.3779 10.6635 11.1176 11.3 11.1176H13.7V8.64706H8.9V6.17647Z" fill="#3eabca" />
                                    </svg>

                                </span>
                                Second Opinion
                            </Link>
                        </li>
                        : isInstitutionAdminUser || isAdminUser ? "" :
                        <li className="list-group-item d-flex align-items-center hide-after-md">
                            <Link id="nex-hm-t-so-link" to={isPatient() ? "/u/medicalcases/my-opinions" : "/u/medicalcases/received-opinions"} className={regHasString(location.pathname, '/medicalcases/lists') ? 'active' : ''} style={{ pointerEvents: 'none' }}>
                                <span className="icon">
                                    <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.5 0L0.5 11.1176H4.1V21H20.9V11.1176H24.5L12.5 0ZM8.9 6.17647H13.7C14.3365 6.17647 14.947 6.43676 15.3971 6.90009C15.8471 7.36341 16.1 7.99182 16.1 8.64706V11.1176C16.1 11.7729 15.8471 12.4013 15.3971 12.8646C14.947 13.3279 14.3365 13.5882 13.7 13.5882H11.3V16.0588H16.1V18.5294H8.9V13.5882C8.9 12.933 9.15286 12.3046 9.60294 11.8413C10.053 11.3779 10.6635 11.1176 11.3 11.1176H13.7V8.64706H8.9V6.17647Z" fill="#aeb1b1" />
                                    </svg>

                                </span>
                                Second Opinion
                            </Link>
                        </li>
                    }
                    {isAdminUser
                        ?
                        <>
                            <li className="list-group-item d-flex align-items-center">
                                <Link id="nex-hm-profile-link" to="/profiles" className={location.pathname === "/profiles" ? 'active' : ''}><img src={nex_profile_icon} alt="Profiles Icon" className="icon" />Profiles</Link>
                            </li>
                        </>
                        :
                        ((isPractitionerUser && currentUser.status === "APPROVED") || isInstitutionAdminUser || isPatientUser) ?
                        <li className={`more-menu-btn-li list-group-item d-flex align-items-center position-relative nex-app-menu-list ${moreActive ? 'mactive' : ''}`} >
                            <a className='more-menu-btn' ref={moreMenuRef} onClick={(e) => { e.preventDefault(); setMoreActive(!moreActive); }}>
                                <span className="icon"><svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3.16659 0.333374C1.87792 0.333374 0.833252 1.37804 0.833252 2.66671V7.33337C0.833252 8.62204 1.87792 9.66671 3.16659 9.66671H7.83325C9.12192 9.66671 10.1666 8.62204 10.1666 7.33337V2.66671C10.1666 1.37804 9.12192 0.333374 7.83325 0.333374H3.16659ZM19.4999 9.66671C22.0772 9.66671 24.1666 7.57737 24.1666 5.00004C24.1666 2.42271 22.0772 0.333374 19.4999 0.333374C16.9226 0.333374 14.8333 2.42271 14.8333 5.00004C14.8333 7.57737 16.9226 9.66671 19.4999 9.66671ZM10.1666 19C10.1666 21.5774 8.07725 23.6667 5.49992 23.6667C2.92259 23.6667 0.833252 21.5774 0.833252 19C0.833252 16.4227 2.92259 14.3334 5.49992 14.3334C8.07725 14.3334 10.1666 16.4227 10.1666 19ZM17.1666 14.3334C15.8779 14.3334 14.8333 15.378 14.8333 16.6667V21.3334C14.8333 22.622 15.8779 23.6667 17.1666 23.6667H21.8333C23.1219 23.6667 24.1666 22.622 24.1666 21.3334V16.6667C24.1666 15.378 23.1219 14.3334 21.8333 14.3334H17.1666Z" fill="#5EA9C6" />
                                </svg></span>
                                <span className='txt'><span className='more-txt'>More</span>  </span><span className='small-down-arrow'></span></a>
                            <ul className="sub-menu list-group position-absolute" >
                                <li className="list-group-item d-flex align-items-center show-after-md">
                                    <Link id="nex-hm-home-link" to="/home" className={location.pathname === "/home" ? 'active' : ''}><img src={homeIcon} alt="Home Icon" className="icon" />Home</Link>
                                </li>
                                {(isPractitionerUser || isInstitutionAdminUser) &&
                                    <li className="list-group-item d-flex align-items-center show-after-md">
                                        <Link id="nex-hm-messages-link" to="/messages" className={location.pathname === "/messages" ? 'active' : ''}><img src={chatIcon} alt="Messages Icon" className="icon" />Messages</Link>
                                    </li>
                                }
                                {(isPractitionerUser || isInstitutionAdminUser) &&
                                    <li className="list-group-item d-flex align-items-center show-after-md">
                                        <Link id="nex-hm-network-link" to="/connections/all-connections" className={location.pathname === "/connections/all-connections" ? 'active' : ''}><img src={contactsIcon} alt="Connections Icon" className="icon" />Network</Link>
                                    </li>
                                }
                                {(isPractitionerUser || isAdminUser || isInstitutionAdminUser) &&
                                    <li className="list-group-item d-flex align-items-center show-after-md">
                                        <Link id="nex-hm-job-link" to="/jobsboard/all-jobs" className={location.pathname === "/jobsboard/all-jobs" ? 'active' : ''}><img src={jobsIcon} alt="Connections Icon" className="icon" />Jobs</Link>
                                    </li>
                                }
                                {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                                    <li className="list-group-item d-flex align-items-center">
                                        <Link id="nex-hm-dashboard-link" to="/dashboard" className={location.pathname === "/dashboard" ? 'active' : ''}><img src={dashboard_admin_icon} alt="Messages Icon" className="icon" />Dashboard</Link>
                                    </li>
                                }
                                {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (isPractitionerUser) &&
                                    <li className="list-group-item d-flex align-items-center">
                                        <Link id="nex-hm-appoint-link" to="/appointments" className={location.pathname === "/appointments" ? 'active' : ''}><img src={appointmentIcon} alt="Messages Icon" className="icon" />Appointments</Link>
                                    </li>
                                }

                                {((isPractitionerUser && isPractitionerEligibleForReferrals)) &&
                                    <li className="list-group-item d-flex align-items-center">
                                        <Link id="nex-hm-rreferrals-link" to="/referrals/received-referrals" className={location.pathname === "/referrals/received-referrals" ? 'active' : ''}><img src={referals} alt="Messages Icon" className="icon" />Referrals</Link>
                                    </li>
                                }

                                {!isPatientUser &&
                                    <li className="list-group-item d-flex align-items-center">
                                        <Link id="nex-hm-profile-link" to="/profiles" className={location.pathname === "/profiles" ? 'active' : ''}><img src={nex_profile_icon} alt="Profiles Icon" className="icon" />Profiles</Link>
                                    </li>
                                }

                                {isPatient() &&
                                    <li className="list-group-item d-flex align-items-center">
                                        <Link id="nex-hm-mreferrals-link" to="/my-referrals" className={location.pathname === "/my-referrals" ? 'active' : ''}><img src={referals} alt="Messages Icon" className="icon" />Referrals</Link>
                                    </li>
                                }

                                {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && !isInstitutionAdminUser &&
                                    <li className="list-group-item d-flex align-items-center">
                                        <Link id="nex-hm-review-link" to="/feedback" className={location.pathname === "/feedback" ? 'active' : ''}><img src={reviewIcon} alt="Messages Icon" className="icon" />Reviews</Link>
                                    </li>
                                }
                                {(isPractitioner() || isPatient()) &&
                                    <li className="list-group-item d-flex align-items-center show-after-md">
                                        <Link id="nex-hm-t-so-link" to={isPatient() ? "/u/medicalcases/my-opinions" : "/u/medicalcases/received-opinions"} className={regHasString(location.pathname, '/medicalcases/lists') ? 'active' : ''} >
                                            <img src={second_opinion} alt='Second Opinion icon' width="16" /> Second Opinion</Link>
                                    </li>
                                }
                            </ul>
                        </li>
                        :
                        <li className={`more-menu-btn-li list-group-item d-flex align-items-center position-relative nex-app-menu-list ${moreActive ? 'mactive' : ''}`} >
                            <a className='more-menu-btn' ref={moreMenuRef} onClick={(e) => { e.preventDefault(); setMoreActive(!moreActive); }} style={{ pointerEvents: 'none' }}>
                                <span className="icon"><svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3.16659 0.333374C1.87792 0.333374 0.833252 1.37804 0.833252 2.66671V7.33337C0.833252 8.62204 1.87792 9.66671 3.16659 9.66671H7.83325C9.12192 9.66671 10.1666 8.62204 10.1666 7.33337V2.66671C10.1666 1.37804 9.12192 0.333374 7.83325 0.333374H3.16659ZM19.4999 9.66671C22.0772 9.66671 24.1666 7.57737 24.1666 5.00004C24.1666 2.42271 22.0772 0.333374 19.4999 0.333374C16.9226 0.333374 14.8333 2.42271 14.8333 5.00004C14.8333 7.57737 16.9226 9.66671 19.4999 9.66671ZM10.1666 19C10.1666 21.5774 8.07725 23.6667 5.49992 23.6667C2.92259 23.6667 0.833252 21.5774 0.833252 19C0.833252 16.4227 2.92259 14.3334 5.49992 14.3334C8.07725 14.3334 10.1666 16.4227 10.1666 19ZM17.1666 14.3334C15.8779 14.3334 14.8333 15.378 14.8333 16.6667V21.3334C14.8333 22.622 15.8779 23.6667 17.1666 23.6667H21.8333C23.1219 23.6667 24.1666 22.622 24.1666 21.3334V16.6667C24.1666 15.378 23.1219 14.3334 21.8333 14.3334H17.1666Z" fill="#aeb1b1" />
                                </svg></span>
                                <span className='txt'><span className='more-txt'>More</span>  </span><span className='small-down-arrow'></span></a>
                        </li>
                    }
                </ul>
            </div>

            {/** */}
            <div className='nex-app-footer-menu'>
                <div className="item">
                    {((isPractitionerUser && currentUser.status === "APPROVED") || isInstitutionAdminUser || isPatientUser) &&

                        <Link id="nex-hm-t-home-link-2" to="/home" className={location.pathname === "/home" ? 'active' : ''}>
                            <span className="icon"><svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M21.5 9.84209V18.9614C21.5 21.56 19.4107 23.6667 16.8333 23.6667H5.16667C2.58934 23.6667 0.5 21.5601 0.5 18.9614V9.84209C0.5 8.42931 1.12959 7.09129 2.21484 6.19765L8.04817 1.39428C9.76602 -0.0202593 12.234 -0.0202622 13.9518 1.39428L19.7852 6.19765C20.8704 7.09129 21.5 8.42931 21.5 9.84209ZM8.66667 18.125C8.18342 18.125 7.79167 18.5168 7.79167 19C7.79167 19.4833 8.18342 19.875 8.66667 19.875H13.3333C13.8166 19.875 14.2083 19.4833 14.2083 19C14.2083 18.5168 13.8166 18.125 13.3333 18.125H8.66667Z" fill="#5EA9C6" />
                            </svg>
                            </span> Home</Link>

                    }
                </div>
                <div className="item">
                    {((isPractitionerUser && currentUser.status === "APPROVED") || isInstitutionAdminUser) &&

                        <Link id="nex-hm-t-job-link-2" to="/jobsboard/all-jobs" className={location.pathname === "/jobsboard/all-jobs" ? 'active' : ''}><span className="icon"><svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.4998 0.458374C9.0836 0.458374 7.12485 2.41713 7.12485 4.83337V6.00074C4.91411 6.03881 3.02828 7.62618 2.61943 9.80669L0.869435 19.14C0.330948 22.012 2.53419 24.6667 5.45617 24.6667H17.5435C20.4655 24.6667 22.6687 22.012 22.1303 19.14L20.3803 9.80669C19.9714 7.62618 18.0856 6.03881 15.8748 6.00074V4.83337C15.8748 2.41713 13.9161 0.458374 11.4998 0.458374ZM14.1248 6.00004V4.83337C14.1248 3.38363 12.9496 2.20837 11.4998 2.20837C10.0501 2.20837 8.87485 3.38363 8.87485 4.83337V6.00004H14.1248Z" fill="#5EA9C6" />
                        </svg></span> Jobs</Link>

                    }
                </div>
                <div className="item">
                    {(((isPractitionerUser && currentUser.status === "APPROVED") && isPractitionerEligibleForReferrals)) &&
                        <Link id="nex-hm-rreferrals-link-2" to="/referrals/received-referrals" className={location.pathname === "/referrals/received-referrals" ? 'active' : ''}><img src={referals} alt="Messages Icon" className="icon nex-app-icon-white" />Referrals</Link>

                    }
                </div>
                <div className="item">
                    {((isPractitionerUser && currentUser.status === "APPROVED") || isInstitutionAdminUser) &&

                        <Link id="nex-hm-network-link-2" to="/connections/all-connections" className={location.pathname === "/connections/all-connections" ? 'active' : ''}><img src={contactsIcon} alt="Connections Icon" className="icon" />Network</Link>

                    }
                </div>
            </div>
        </>
    )
}

