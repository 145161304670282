import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { RenderSchedules } from "components/RenderSchedules";
import { Field, FieldArray, Form, Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import HospitalTypeaheadFieldNew from "pages/Onboarding/components/FormComponents/HospitalTypeaheadFieldNew";
import NewHospitalSection from "pages/Onboarding/components/FormComponents/NewHospitalSection";
import { memo } from "react";
import { Fragment, useState } from "react";
import { withAlert } from "react-alert";
import { useSelector } from "react-redux";
import add_arrow from "assets/svgIcon/plus-add-icon.svg";


import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { FAILTOSAVERECOED } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import { getAddressName, getLocationName } from "utils/Utils";
import * as Yup from "yup";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const validationSchema = () =>
  Yup.object().shape({
    hospitalClinicFormData: Yup.array().of(
      Yup.object().shape({
        hospitalDetail: Yup.lazy((value) => {
          if (value && value.onSelectNewHospital === 0) {
            return Yup.object().test(
              "",
              "This field is required. You must select from options or add new",
              () => false
            );
          } else if (value && value.onSelectNewHospital === 1) {
            return Yup.object();
          } else if (value && value.onSelectNewHospital === 2) {
            return Yup.object();
          } else {
            return Yup.mixed().test(
              "",
              "This field is required. You must select from options or add new",
              (value) => false
              //(value) => value === {}
            );
          }
        }),
        category: Yup.string().when("hospitalDetail.onSelectNewHospital", {
          is: 2,
          then: Yup.string().required("This field is required"),
        }),
        city: Yup.array().when("hospitalDetail.onSelectNewHospital", {
          is: 2,
          then: Yup.array()
            .min(1, "This field is required")
            .required("This field is required"),
        }),
        state: Yup.array().when("hospitalDetail.onSelectNewHospital", {
          is: 2,
          then: Yup.array()
            .min(1, "This field is required")
            .required("This field is required"),
        }),
        country: Yup.array().when("hospitalDetail.onSelectNewHospital", {
          is: 2,
          then: Yup.array()
            .min(1, "This field is required")
            .required("This field is required"),
        }),
        location: Yup.array().when("hospitalDetail.onSelectNewHospital", {
          is: 2,
          then: Yup.array()
            .min(1, "This field is required")
            .required("This field is required"),
        }),
        mainItemName: Yup.string().when(
          "institutionDetail.onSelectNewMedicalCollege",
          {
            is: 2,
            then: Yup.string().required("This field is required"),
          }
        ),
        postalCode: Yup.string().when("hospitalDetail.onSelectNewHospital", {
          is: 2,
          then: Yup.string().required("This field is required"),
        }),
        addressLine1: Yup.string().when("hospitalDetail.onSelectNewHospital", {
          is: 2,
          then: Yup.string().required("This field is required"),
        }),
        websiteAddress: Yup.string().when(
          "hospitalDetail.onSelectNewHospital",
          {
            is: 2,
            then: Yup.string().when("websiteAddress", {
              is: (val) => val !== "",
              then: Yup.string().url("Please enter a valid URL"),
              otherwise: Yup.string(),
            }),
          }
        ),
        currency: Yup.string()
          .required("This field is required")
          .default("INR"),
        charge: Yup.string().required("This field is required"),
        duration: Yup.string().required("This field is required"),
        opdLocation: Yup.string(),
        schedulesHospital: Yup.array()
          .of(
            Yup.object()
              .shape({
                startTime: Yup.string().required("This field is required"),
                endTime: Yup.string()
                  .required("This field is required")
                  .test(
                    "",
                    "End time must be after Start time",
                    function (values) {
                      return this.parent.startTime < values;
                    }
                  ),
                mon: Yup.boolean(),
                tue: Yup.boolean(),
                wed: Yup.boolean(),
                thu: Yup.boolean(),
                fri: Yup.boolean(),
                sat: Yup.boolean(),
                sun: Yup.boolean(),
              })
              .test("myCustomTest", "Select at least on day", (obj) => {
                if (
                  obj.mon ||
                  obj.tue ||
                  obj.wed ||
                  obj.thu ||
                  obj.fri ||
                  obj.sat ||
                  obj.sun
                ) {
                  return true; // everything is fine
                }

                return new Yup.ValidationError(
                  "Please select at one day",
                  null,
                  "daySelected"
                );
              })
          )
          .min(1, "Atleast one work day is required"),
      })
    ),
  });

const initialValuesObj = {
  hospitalDetail: {
    hospitalName: "",
    guid: "",
    onSelectNewHospital: 0,
  },
  addressType: "BILLING_ADDRESS",
  addressLine1: "",
  addressLine2: "",
  city: [],
  country: [],
  location: [],
  state: [],
  postalCode: "",
  schedulesHospital: [],
  currency: "INR",
  charge: "",
  duration: "",
  websiteAddress: "",
  mainItemName: "",
  category: "",
  id: "",
  opdLocation: "",
};

const RenderForm = (props) => {
  const [nestedModal, setNestedModal] = useState(false);
  const { values, setFieldValue, index } = props;

  const onNewInstitute = () => {
    toggleNested();
  };

  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setFieldValue(
      `hospitalClinicFormData[${index}].hospitalDetail.onSelectNewHospital`,
      2
    );
    setFieldValue(
      `hospitalClinicFormData[${index}].hospitalDetail.hospitalName`,
      "Other"
    );
    setFieldValue(`hospitalClinicFormData[${index}].hospitalDetail.guid`, "");
  };

  return (
    <>
      <div className="card border-0">
        <div>
        <Row>
          <Col lg={12}>
            <Field
              name={`hospitalClinicFormData[${index}].hospitalDetail`}
              id={`hospitalClinicFormData[${index}].hospitalDetail`}
              type="text"
              autoComplete={`hospitalClinicFormData[${index}].hospitalDetail`}
              label="Hospital/Clinic Name"
              component={HospitalTypeaheadFieldNew}
              onNewInstituteReq={(index) => onNewInstitute(index)}
              placeholder="Enter hospital / clinic name"
              parentIndex={index}
            />
          </Col>
        </Row>  
        {values?.hospitalClinicFormData.length > 0 &&
          values?.hospitalClinicFormData[index]?.hospitalDetail
            ?.onSelectNewHospital === 2 && (
            <NewHospitalSection {...props} index={index} />
          )}
          <Row>
          <Col lg={12}>
            <Field
              name={`hospitalClinicFormData[${index}].opdLocation`}
              id={`hospitalClinicFormData[${index}].opdLocation`}
              autoComplete={`hospitalClinicFormData[${index}].opdLocation`}
              type="text"
              label="Clinic/OPD Location"
              component={FormikInputField}
              placeholder="Enter Clinic / OPD location"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Field
              id={`hospitalClinicFormData[${index}].currency`}
              name={`hospitalClinicFormData[${index}].currency`}
              autoComplete={`hospitalClinicFormData[${index}].currency`}
              type="text"
              label="Currency"
              component={FormikSelectField}
              inputprops={{
                name: `currency`,
                options: ["INR", "USD", "EUR"],
              }}
            />
          </Col>
          <Col lg={4}>
            <Field
              id={`hospitalClinicFormData[${index}].charge`}
              name={`hospitalClinicFormData[${index}].charge`}
              autoComplete={`hospitalClinicFormData[${index}].charge`}
              type="text"
              label="Charge"
              component={FormikInputField}
              placeholder="Enter Charge"
            />
          </Col>
          <Col lg={4}>
            <Field
              id={`hospitalClinicFormData[${index}].duration`}
              name={`hospitalClinicFormData[${index}].duration`}
              autoComplete={`hospitalClinicFormData[${index}].duration`}
              type="text"
              label="Duration"
              component={FormikSelectField}
              inputprops={{
                name: `duration`,
                options: [
                  "10 min",
                  "15 min",
                  "20 min",
                  "30 min",
                  "45 min",
                  "60 min",
                ],
                defaultOption: "Select",
              }}
            />
          </Col>
          <RenderSchedules {...props} index={index} />
        </Row>
        </div>
      </div>
    </>
  );
};

const RenderHospitalAffiliationsForm = (props) => {
  const [isLoading, setLoading] = useState(false);
  const { isOpen, toggle, facilitie, getData, formType, alert } = props;
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  const handleSubmit = async (values) => {
    setLoading(true);
    const userGUID = globalProfileInfo?.guid ?? "";
    const formData = values.hospitalClinicFormData.map((node) => {
      let newSchedules = [];
      node?.schedulesHospital.map((obj) => {
        const newValue = {
          dayOfWeek: "",
          startTime: moment(obj.startTime).format("HH:mm:ss"),
          endTime: moment(obj.endTime).format("HH:mm:ss"),
        };
        Object.keys(obj).forEach((key) => {
          if (key === "mon" && obj[key]) {
            newSchedules.push({ ...newValue, dayOfWeek: "MONDAY" });
          }
          if (key === "tue" && obj[key]) {
            newSchedules.push({ ...newValue, dayOfWeek: "TUESDAY" });
          }
          if (key === "wed" && obj[key]) {
            newSchedules.push({ ...newValue, dayOfWeek: "WEDNESDAY" });
          }
          if (key === "thu" && obj[key]) {
            newSchedules.push({ ...newValue, dayOfWeek: "THURSDAY" });
          }
          if (key === "fri" && obj[key]) {
            newSchedules.push({ ...newValue, dayOfWeek: "FRIDAY" });
          }
          if (key === "sat" && obj[key]) {
            newSchedules.push({ ...newValue, dayOfWeek: "SATURDAY" });
          }
          if (key === "sun" && obj[key]) {
            newSchedules.push({ ...newValue, dayOfWeek: "SUNDAY" });
          }
        });
        return obj;
      });
      return {
        charge: Number(node.charge),
        currency: node.currency,
        duration: Number(node.duration.replace(" min", "")),
        institutionDetail: {
          ...(!node.hospitalDetail.guid && {
            address: {
              addressLine1: node?.addressLine1 ?? "",
              addressLine2: node?.addressLine2 ?? "",
              addressType: node?.addressType ?? "BILLING_ADDRESS",
              city: getAddressName(node?.city),
              state: getAddressName(node?.state),
              country: getAddressName(node?.country),
              location: getLocationName(node?.location),
              postalCode: node?.postalCode ?? "",
            },
          }),
          ...(node.hospitalDetail.guid && {
            guid: node.hospitalDetail.guid,
          }),
          name: node.mainItemName,
          phone: node?.phone ?? "",
          websiteUrl: node?.websiteAddress ?? "",
          ...(node.category && {
            type: node.category,
          }),
        },
        opdLocation: node.opdLocation,
        schedules: newSchedules,
      };
    });
    if (formType !== "edit") {
      PractitionerService.registerHospitalAndClinicDetails(
        formData,
        userGUID,
        true
      )
        .then(async (response) => {
          const { status } = response;
          if (status === 200 || status === 201) {
            await getData();
            showSuccessMessage("Affiliations added Successfully");
            setLoading(false);
            toggle();
          } else {
            setLoading(false);
            let errmsg =
              response.data && !!response.data.message
                ? response.data.message
                : FAILTOSAVERECOED;
            showErrorMessage(errmsg);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(
            "PractitionerService.registerHospitalAndClinicDetails error",
            error
          );
        });
    } else {
      const singleItem = formData[0];
      PractitionerService.updateHospitalAndClinicDetails(
        singleItem,
        userGUID,
        values?.hospitalClinicFormData[0]?.id ?? "",
        true
      )
        .then(async (response) => {
          const { status } = response;
          if (status === 200 || status === 201) {
            await getData();
            showSuccessMessage("Affiliations updated Successfully");
            toggle();
          } else {
            setLoading(false);
            let errmsg =
              response.data && !!response.data.message
                ? response.data.message
                : FAILTOSAVERECOED;
            showErrorMessage(errmsg);
          }
        })
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(
            "PractitionerService.updateHospitalAndClinicDetails error",
            error
          );
        });
    }
  };

  const handleToggle = (e) => {
    e.preventDefault();
    toggle();
  };

  const showHideBtn = (val) =>
    _.reduce(
      val?.hospitalClinicFormData,
      function (accumulated, e) {
        return accumulated + e.id;
      },
      undefined
    );

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        toggle={handleToggle}
        id="profile-modal"
        scrollable={true}
        centered={true}
        backdrop="static"
        className="nex-apply-jobs-modal"
      >
        <ModalHeader toggle={handleToggle} className="nex-apply-jobs-modal-header">
          <span className="nex-text-xl">Hospital/Clinic Affiliations</span>
        </ModalHeader>
        <ModalBody className="bg-white">
          <Formik
            initialValues={facilitie}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            children={(props) => {
              return (
                <Form className="nexogic-hospital-form-card"> 
                  <div className="card-body">
                  <FieldArray
                    name="hospitalClinicFormData"
                    render={(arrayHelpers) => (
                      <>
                        {props.values.hospitalClinicFormData.map(
                          (item, index) => (
                            <Fragment key={index}>
                              <RenderForm {...props} index={index} />
                            </Fragment>
                          )
                        )}
                        <Row className="">
                          {!showHideBtn(arrayHelpers?.form?.values) && (
                            <Col lg={12} className="">
                              <Button
                                className="nexogic-trans-icon-btn"
                                onClick={() => {
                                  props.validateForm().then((res) => {
                                    if (!_.has(res, "hospitalClinicFormData")) {
                                      arrayHelpers.push({
                                        ...initialValuesObj,
                                      });
                                    } else {
                                      arrayHelpers.form.validateForm();
                                      arrayHelpers.form.setTouched({
                                        hospitalClinicFormData:
                                          arrayHelpers.form.values.hospitalClinicFormData.map(
                                            () => {
                                              return {
                                                hospitalDetail: true,
                                                mainItemName: true,
                                                category: true,
                                                addressLine1: true,
                                                addressLine2: true,
                                                country: true,
                                                state: true,
                                                city: true,
                                                postalCode: true,
                                                location: true,
                                                websiteAddress: true,
                                                currency: true,
                                                charge: true,
                                                duration: true,
                                                schedulesHospital: true,
                                              };
                                            }
                                          ),
                                      });
                                    }
                                  });
                                }}
                                
                                type="button"
                              >
                                <span className="nexogic-fill-icon-small">
                                  {/*<FontAwesomeIcon icon={faPlus} />*/}
                                  <img src={add_arrow} alt="" />
                                </span>
                                Add more Affiliations
                                
                              </Button>
                            </Col>
                          )}
                          <Col md={12}>
                            {props.values.hospitalClinicFormData.length > 1 && (
                              <Button
                                color="danger"
                                onClick={() =>
                                  arrayHelpers.remove(
                                    props.values.hospitalClinicFormData.length -
                                    1
                                  )
                                }
                                className="p-2 mt-2 mb-3 nex-ha-trash-btn-icon"
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </Button>
                            )}
                          </Col>
                        </Row>
                        
                        {isLoading ? (
                          <Row className="modal-save-main justify-content-center">
                            <div className="loading-spiner">
                              <Spinner />
                            </div>
                          </Row>
                        ) : (
                          <div className="justify-content-end  modal-footer ">
                          <div className="action-group">
                            
                              <>
                              <Button
                                id="nex-ham-popup-save-btn"
                                  className="nex-btn-primary"
                                  color="primary"
                                  type="submit"
                                >
                                  Save 
                                </Button>
                                <Button
                                  id="nex-ham-popup-cancel-btn"
                                  className="nex-btn-primary-outline"
                                  color="primary"
                                  type="submit"
                                  onClick={handleToggle}
                                >
                                  Cancel
                                </Button>
                                
                              </>
                            
                          </div>
                          </div>
                        )}
                      </>
                    )}
                  />
                  </div>

                </Form>
              );
            }}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default withAlert()(memo(RenderHospitalAffiliationsForm));
