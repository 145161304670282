import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faHospital } from "@fortawesome/free-solid-svg-icons";
import { faUserMd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import filterIcon from "assets/images/svgs/filter_icon.svg";
import hospital_icon from "assets/images/svgs/hospital-icon.svg";
import doctor_icon from "assets/images/svgs/doctor-icon.svg";
import AsyncGlobalSearchInput from "components/forms/formikFields/AsyncGlobalSearchInput";
import React, { useCallback, useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { setGlobalSearchType } from "redux-helpers/ActionCreator";
import {
  Button,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from "reactstrap";

const NewSearch = (props) => {  
  const dispatch = useDispatch();
  const globalSearchTypek = useSelector(
    (state) => state.GlobalSearchTypeReducer.globalSearchType
  );
  const [searchType, setSearchType] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState();
  const typeaheadRef = useRef(null);
  let iii=1;
  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleResponsiveModal = () => {
    if (props.responsiveToggle) {
      props.responsiveToggleFunc();
    }
  };
  const search_drops = useRef();
  const [search_drop_down, setSearch_drop_down] = useState(false);   

  function change_search_drops(val){
    search_drops.current.value = val;  
    search_drops.current.dispatchEvent(new Event('change', { bubbles: true}));
    setSearch_drop_down(false);
  }

  const handleDropDownChange = useCallback((event) => {
    dispatch(setGlobalSearchType(event.target.value));    
    typeaheadRef.current?.focus();
    typeaheadRef.current?.clear();
    // event.target.value=globalSearchTypek;
  },[dispatch]);

   

  


  return (
    <>
    <div className="nex-admin--header-search">
                    <div className="nex-header-search--dropdown">
                        <select onChange={handleDropDownChange}
                            value={globalSearchTypek}
                            ref={search_drops}
                            >
                                <option value="Practitioners"> Practitioners</option>
                                <option value="Institutions">Hospitals / Clinics</option>
                        </select>
                        <div className={`nexogic-custom-select ${search_drop_down ? 'on' : ''}`}>
                            <div className="selected-label small-down-arrow" onClick={()=>setSearch_drop_down(!search_drop_down)}>
                            {globalSearchTypek == "Practitioners" && <><span className="icon-w doctor-icon d-none"><img src={doctor_icon} alt="Practitioners Icon" /></span><span>Practitioners</span></>}
                            {globalSearchTypek == "Institutions" && <><span className="icon-w d-none"><img src={hospital_icon} alt="Institutions Icon" /></span><span>Hospitals</span></>}
                            </div>
                            <ul>
                                <li onClick={()=>change_search_drops('Practitioners')}><span className="icon-w doctor-icon d-none"><img src={doctor_icon} alt="Institutions Icon" /></span> <span>Practitioners</span></li>
                                <li onClick={()=>change_search_drops('Institutions')}><span className="icon-w d-none"><img src={hospital_icon} alt="Practitioners Icon" /></span> <span>Hospitals</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="nex-header-search--input-w">
                        <AsyncGlobalSearchInput
                            id="nexogic-global-search-input"
                            type="search"
                            placeholder="Search..."
                            className="border-0"
                            handleResponsiveModal={handleResponsiveModal}
                            searchType={globalSearchTypek}
                            typeaheadRef={typeaheadRef}
                        />
                    </div>
                    <div className="nex-header-search--input-button">
                        <Button color="link" id="nexogic_btn_search">
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18C14.866 18 18 14.866 18 11C18 7.13401 14.866 4 11 4ZM2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 15.9706 15.9706 20 11 20C6.02944 20 2 15.9706 2 11Z"
                                fill="white"
                                />
                                <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15.9413 15.9413C16.3319 15.5508 16.965 15.5508 17.3555 15.9413L21.7055 20.2913C22.0961 20.6819 22.0961 21.315 21.7055 21.7055C21.315 22.0961 20.6819 22.0961 20.2913 21.7055L15.9413 17.3555C15.5508 16.965 15.5508 16.3319 15.9413 15.9413Z"
                                fill="white"
                                />
                            </svg>
                        </Button>
                            
                    </div>
                </div>
    </>
  );
};

export default connect(null,null)(NewSearch);
