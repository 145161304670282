import React, { useState, useEffect } from 'react';

const CountDownTimer = ({ time, onComplete, digital }) => {
  const [minutes, setMinutes] = useState(Math.floor(time / 60));
  const [seconds, setSeconds] = useState(time % 60);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0 || minutes > 0) {
        if (seconds === 0) {
          setMinutes((prevMinutes) => prevMinutes - 1);
          setSeconds(59);
        } else {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }
      } else {
        clearInterval(interval);
        onComplete();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, minutes, onComplete]);

  const formatTime = (value) => (value < 10 ? `0${value}` : value);

  return (
    <h6 className="d-inline font-weight-normal">
      {!!digital ? (
        <span>{`${formatTime(minutes)}:${formatTime(seconds)}`}</span>
      ) : (
        <span>{`${minutes}:${formatTime(seconds)} left`}</span>
      )}
    </h6>
  );
};

export default CountDownTimer;
