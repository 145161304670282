import { Modal, ModalBody, ModalHeader } from "reactstrap";
import MedicalHistoryForm2 from "../MedicalHistoryForm2";

const MedicalHistoryForm2Modal = (props) => {
    const { modal, setModal } = props;

    return (
        <Modal isOpen={modal} toggle={() => setModal(!modal)} className="nex-model-v2 modal-dialog-scrollable modal-dialog-centered">
            <ModalHeader toggle={() => setModal(!modal)}>Edit Medical History</ModalHeader>
            <ModalBody>
                <MedicalHistoryForm2 />
            </ModalBody>
        </Modal>
    )
}

export default MedicalHistoryForm2Modal;