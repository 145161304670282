import AdsEight from 'pages/Ads/AdsEight'
import AdsEleven from 'pages/Ads/AdsEleven'
import AdsFive from 'pages/Ads/AdsFive'
import AdsFour from 'pages/Ads/AdsFour'
import AdsNine from 'pages/Ads/AdsNine'
import AdsOne from 'pages/Ads/AdsOne'
import AdsSeven from 'pages/Ads/AdsSeven'
import AdsSix from 'pages/Ads/AdsSix'
import AdsTen from 'pages/Ads/AdsTen'
import AdsThree from 'pages/Ads/AdsThree'
import AdsTwelve from 'pages/Ads/AdsTwelve'
import AdsTwo from 'pages/Ads/AdsTwo'
import DoctorSearchSidebar from 'pages/Search/DoctorSearchSidebar'
import DoctorSearchTop from 'pages/Search/DoctorSearchTop'
import React from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'

const AdsTemplates = () => {
    return (
        <div className='container-fluid microsite-side-main'>
            <main>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>Ads Templates</CardHeader>
                            <CardBody className='bg-dark'>
                                <Row>
                                    <Col lg={8} className='bg-transparent'>
                                        <div className='py-1'><DoctorSearchTop /></div>                                                                                        
                                        <div className='py-1'><AdsFive /></div>                                                                                        
                                        <div className='py-1'><AdsSix /></div>                                           
                                        <div className='py-1'><AdsNine /></div>
                                    </Col>
                                    <Col lg={4} className='bg-transparent'>
                                        <div className='my-2'><DoctorSearchSidebar /></div>
                                        <div className='my-2'><AdsOne /></div>
                                            <div className='my-2'><AdsThree /></div>
                                            <div className='my-2'><AdsTwo /></div>
                                            <div className='my-2'><AdsEight /></div>   
                                            <div className='my-2'><AdsTen /></div> 
                                            <div className='my-2'><AdsTwelve /></div>
                                            <div className='my-2'><AdsEleven /></div> 
                                            <div className='my-2'><AdsFour /></div>
                                            <div className='my-2'><AdsSeven /></div>                                       
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </main>
        </div>
    )
}

export default AdsTemplates