import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'

export default function Widgets() {
  return (
    <>
      Widgets
    </>
  )
}
