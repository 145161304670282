import React, { useState, useEffect, useRef } from "react"
import { Row, Col, FormGroup, Label, Button, Spinner, Input } from 'reactstrap'
import { Typeahead } from 'react-bootstrap-typeahead';
import "react-datepicker/dist/react-datepicker.css";
import { Field, ErrorMessage } from 'formik';
import API from "utils/API"
import FormikInputField from "components/forms/formikFields/FormikInputField"
import FormikDatePicker from "components/forms/formikFields/FormikDatePicker"
import { FORM_CONSTANTS, doctorQualifications } from "utils/Constants"
import { map } from "lodash"
import FormikSelectField from "components/forms/formikFields/FormikSelectField"

export const RenderInstituteFormFields = (props) => {
    const { setFieldValue, values, handleSubmit, isValid, dirty, toggle, isSubmitting, errors } = props

    return (
        <form onSubmit={handleSubmit}>
            <Row className="mb-5">
                <Col>
                    <Row form>
                        <Col lg={6}>
                            <Field
                                id="institutionName"
                                name="institutionName"
                                type="text"
                                component={FormikInputField}
                                label="Institute Name"
                                placeholder="Institute name"
                            />
                        </Col>
                        <Col lg={6}>
                            <Field
                                id="heading"
                                name="heading"
                                type="text"
                                component={FormikInputField}
                                label="Heading"
                                placeholder="Heading"
                            />
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col lg={6}>
                            <Field
                                id="city"
                                name="city"
                                type="text"
                                component={FormikInputField}
                                label="City"
                                placeholder="Enter city"
                            />
                        </Col>
                        <Col lg={6}>
                            <Field
                                id="State"
                                name="state"
                                type="text"
                                component={FormikInputField}
                                label="State"
                                placeholder="Enter State"
                            />
                        </Col>                          
                    </Row> */}
                    <Row form>
                        <Col lg={12}>
                            <Field
                                id="address"
                                name="address"
                                type="text"
                                component={FormikInputField}
                                label="address"
                                placeholder="Enter address"
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr className="profile-info-modal-hr" />
            <Row className="justify-content-center modal-save-main">
                <Col xs="auto">
                    {isSubmitting ?
                        <div className="loading-spiner"><Spinner style={{ width: '2rem', height: '2rem' }} /></div>
                        :
                        <Button id="rif_btn_submit" className="modal-save-btn" color="primary " type="submit" disabled={!dirty}>Save</Button>
                    }
                </Col>
            </Row>
        </form>
    )
}