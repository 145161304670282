import React, { useCallback, useRef, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Col, Row } from "reactstrap";
import { UserService } from "utils/UserService";
import RenderEducationFormik from "./Components/RenderEducationFormik";

const EducationalForm = ({ onNext, onBack, onSkip }) => {
  const [eduType, setEduType] = useState([
    {
      id: 0,
      type: "PRIMARY",
      formCount: 1,
    },
  ]);
  const addNewEdu = (newEdu) => {
    setEduType((prev) => {
      return [...prev, newEdu];
    });
  };
  const deleteEdu = (id) => {
    setEduType((prev) => {
      return prev.filter((obj, index) => {
        return index !== id;
      });
    });
  };

  const submitMyFormOne = useRef();

  const bindSubmitFormOne = useCallback((submitForm) => {
    submitMyFormOne.current = submitForm;
  }, []);

  const handleSubmitMyForm = () => {
    if (submitMyFormOne.current) {
      submitMyFormOne.current();
    }
  };

  const logout = () => {
    UserService.logout();
    window.location.reload();
  };

  return (
    <div className="nexogic-onboarding-form nexogic-form-content row">
      
      <div className="col">
      <Row className="cs-column-reverse">
        <Col md={9}>
          <h3 className="nexogic-form-title mb-1">Add Education</h3>
          <p className="nexogic-form-title-sub mb-2">Provide your education details</p>
        </Col>
        <Col md={'auto'} className="ml-auto">
          <button
            className="d-block ml-auto save-exit nexogic_primary_button_outline"
            // onClick={handleSubmitMyForm}
            onClick={logout}
          >
            Exit
          </button>
        </Col>
      </Row>
      
      
      <TransitionGroup>
        {eduType.map((et, i) => {
          const lastEduObj = eduType[eduType.length - 1];
          return (
            <CSSTransition key={et.id} timeout={500} classNames="item-trans">
              <RenderEducationFormik
                eduObj={et}
                onNext={onNext}
                skip={onSkip}
                onBack={onBack}
                lastEduObj={lastEduObj}
                onAddEdu={addNewEdu}
                onDelete={deleteEdu}
                bindSubmitForm={bindSubmitFormOne}
              />
            </CSSTransition>
          );
        })}
      </TransitionGroup>
      </div>
    </div>
  );
};

export default EducationalForm;
