import React, {useState, useEffect} from 'react'
import {Formik, Form, Field} from 'formik'
import {Link, useLocation, useHistory} from 'react-router-dom'
import {Button, Col, Container, InputGroup, Row} from 'reactstrap'
import { withAlert } from "react-alert";
import { useDispatch } from "react-redux";
import Logo from 'assets/images/logo-top-icon.png'
import verifyMobileImg from 'assets/images/otp-flow/verifyMobile.svg'
import logo from 'assets/images/logo-white.png'
import FormikInputField from 'components/forms/formikFields/FormikInputField'
import FormikSelectField from 'components/forms/formikFields/FormikSelectField'
import Header from 'common/Header'
import {titles, User_Types, onlyAlphabets, emailNonNumericRegEx} from 'utils/Constants'
import './style.scss'
import * as Yup from 'yup'
import {IdentityService} from 'utils/IdentityService'
import FormikMobileField from 'components/forms/formikFields/FormikMobileField'
import { toast } from 'react-toastify'
import { setOAuthTokens, setOAuthUser, setUser, updateAccessToken } from 'utils/UserHelper'
import { authSetUser } from 'redux-helpers/ActionCreator';
import { showErrorMessage } from 'common/AlertContainer';

const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{5})(( x| ext)\d{1,5}){0,1}$/
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2)
    .max(30)
    .matches(onlyAlphabets, 'Only Alphabets')
    .required('This field is required'),
  lastName: Yup.string()
    .min(2)
    .max(30)
    .matches(onlyAlphabets, 'Only Alphabets')
    .required('This field is required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Mobile number is not valid')
    .required('This field is required'),
  email: Yup.string()
    .max(50)
    .matches(emailNonNumericRegEx, 'Please Enter an valid Email')
    .required('This field is required'),
})

const UserEntry = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch();
  const {firstName, lastName, email, phoneNumber, token, refreshToken,utype,claim,profileId} = location.state || {}
  const initValues = {
    title: !utype ? titles[0].value : titles[1].value,
    firstName: firstName || '',
    lastName: lastName || '',
    phone: phoneNumber || '',
    email: email || '',
  }

  const [initialValues, setInitialValues] = useState(initValues)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    setErrorMessage(errorMessage)
    setTimeout(() => {
      setErrorMessage('')
    }, 5000)
  }, [errorMessage])

  // console.log("UserEntry Page----------------------------------",location.state);

  const handleSubmit = async (values, {setSubmitting}) => {
    const payload = {
      salutationCode: values.title,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email, 
      phoneNumber: values.phone,
      type: utype ? 'PATIENT' : 'PRACTITIONER',
      claim,
      claimProfileId:profileId
    }
    const payload_refrshToken = {
      refresh_token: refreshToken,
      grant_type:"refresh_token"
    }
    setSubmitting(true);
    try {
      // console.log("UserENtry==========================handleSubmit======payload======",payload);
      IdentityService.AddUserDetails(token, payload)
        .then(response => {
          if (response.status === 200 || response.status === 201) {
            return IdentityService.RefreshUserToken(payload_refrshToken).then(secondApiResponse => {
              // console.log("UserENtry==========================handleSubmit======response, secondApiResponse======",response, secondApiResponse);
              return { response, secondApiResponse }; 
            });
          } else if (response.status === 409) {
            showErrorMessage("User Already Exists..Please Sign In...")
            setErrorMessage(`User Already Exists`);
            throw new Error('User Already Exists');
          } else if (response.message) {
            setErrorMessage(response.message);
            throw new Error(response.message);
          } else {
            throw new Error('Unhandled API response status');
          }
        })
        .then(refreshResponse => {
          // console.log("UserENtry==========================handleSubmit===refreshResponse===",refreshResponse);
          if (refreshResponse.secondApiResponse.status === 200 || refreshResponse.secondApiResponse.status === 201) {
            const {response,secondApiResponse} = refreshResponse;
            updateAccessToken(secondApiResponse.data?.access_token);
            setOAuthTokens(secondApiResponse.data?.access_token, secondApiResponse.data?.refresh_token);            
            IdentityService.GetUserDetailsByGuid(response.data.id)
            .then(userdetailsResponse => {
              // console.log("UserENtry==========================handleSubmit===userdetailsResponse===",userdetailsResponse);
              if (userdetailsResponse.status === 200 || userdetailsResponse.status === 201) {
                const {data} = userdetailsResponse;
                setOAuthUser(data);
                setUser(data);
                const { types } = data; 
                // console.log("UserENtry==========================types===",types,data);
                if (types.includes("PATIENT")) {
                  // console.log("UserENtry==========================(types.includes(PATIENT))===",types,data);
                  // setOAuthTokens(secondApiResponse.data?.access_token, secondApiResponse.data?.refresh_token);
                  // setOAuthUser(data);
                  dispatch(authSetUser(data));
                  // setUser(data);
                  history.push(`/home`);
                  setSubmitting(false);
                }else{
                  // console.log("UserENtry==========================(types.includes(PRACTITIONER))===",types,data);
                  history.push(`/register/onboarding`, {email: data.emailAddress, guid: data.id, profiledetails:data,profileId})
                }           

                // history.push(`${data.types[0] === "PATIENT" ? '/register/user-account' :'/register/onboarding'}`, {email: data.emailAddress, guid: data.id, profiledetails:data,profileId})
                // history.push(`/register/onboarding`, {email: data.emailAddress, guid: data.id, profiledetails:data,profileId})
                setErrorMessage(''); 
              } else {
                setErrorMessage('Failed to Refresh Registration Info');
                throw new Error('Failed to refresh Registration Info');
              }
            })
          } else {
            setErrorMessage('Failed to Refresh Registration Info');
            throw new Error('Failed to refresh Registration Info');
          }
        })
        .catch(error => {
          console.error('API call error:', error);
          setErrorMessage(error.message || 'Something went wrong.....!');
        })
        .finally(() => {
          setSubmitting(false);
        });
    } catch (error) {
      console.error('Caught synchronous error in handleSubmit:', error);
      setSubmitting(false);
    }
  }

  const handleSubmit1 = (values, {setSubmitting}) => {
    const payload = {
      salutationCode: values.title,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email, // Include email in payload if necessary
      phoneNumber: values.phone,
      type: 'PRACTITIONER',
    }
    // console.log('HandleSubmit--------------------updated----------------------', token,"-----------------------------", payload, refreshToken)
    try {
      IdentityService.AddUserDetails(token, payload)
        .then((response) => {
          // console.log('IdentityService.AddUserDet(payload,token)------------------------', response)
          if (response.status === 200 || response.status === 201) {
            history(`/home`)
            // navigate(`/home`, {
            //   state: { data: { ...values,guid: userResponseGUID.id,profileId: userProfileId, } }
            // });
            setErrorMessage('')
          } else {
            // console.log("Inside else block..................................",response);
            if (response.status=== 409) {
                setErrorMessage(`User ALready Exists`);
            } else if (response.message) {
              setErrorMessage(response.message)
            }
          }
        })
        .catch((error) => {
          console.log('IdentityService.RegisterNewUser error', error)
          setErrorMessage(error.message)
        })
        .then(() => {
          setSubmitting(false)
        })
    } catch (error) {
      console.error('Caught error in handleSubmit:', error)
      setSubmitting(false)
    }
  }

  return (
    <section>
      <div className='nexogic-bg-light'>
        {/* <Header /> */}
        <main className='theme-body-bg nexogic-dashboard-card'>
          <Container className='nexogic-container-gap'>
            <div className='nexogic-login-register-card card border-0 bg-transparent'>
              <div className='row'>
                <Col lg={7} md={6} xs={12} className='nexogic-form-side-content text-center'>
                  <div className='nexogic-form-info '>
                    <p className='hide-on-app'>
                      <img src={logo} alt='' className='nexogic-filter-white' />
                    </p>
                    {/* <p className="card-text text-white mb-5 hide-on-app">Verify Your Details</p> */}
                    <p className='card-text text-white mb-5 hide-on-app'></p>
                    <div className='welcome-text text-white'>
                      <p className='text-white h2'>Welcome to Nexogic</p>
                      {/* <h3 className="h3 mb-4">Nexogic</h3> */}
                    </div>
                    <p>
                      <img src={verifyMobileImg} className='img-fluid' alt='nexogic' />
                    </p>
                  </div>
                </Col>

                <Col lg={5} md={6} xs={12} className='nexogic-form-content card-body p-1'>
                  <div className='nexogic-form-container'>
                    <h3 className='nexogic-form-title hide-on-app'>Verify Your Details</h3>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      // onSubmit={handleSubmit}
                      onSubmit={(values, {setSubmitting}) => {
                        handleSubmit(values, {setSubmitting})
                      }}>
                      {({isSubmitting}) => (
                        <Form className='personal-details-form'>
                          <div>
                            <Row>
                              <Col md={4}>
                                <Field
                                  id='title'
                                  name='title'
                                  type='text'
                                  label=''
                                  className='p55'
                                  component={FormikSelectField}
                                  inputprops={{
                                    name: 'title',
                                    options: titles,
                                    keys: {
                                      id: 'value',
                                      label: 'label',
                                    },
                                  }}
                                  // disabled={!iseditable}
                                  autoComplete='titles'
                                />
                              </Col>
                              <Col md={8}>
                                <Field
                                  id='firstName'
                                  name='firstName'
                                  autoComplete='firstName'
                                  type='text'
                                  component={FormikInputField}
                                  label=''
                                  placeholder='Your first name'
                                  // disabled={!iseditable}
                                />
                              </Col>
                            </Row>

                            <Field
                              id='lastName'
                              name='lastName'
                              autoComplete='lastName'
                              type='text'
                              component={FormikInputField}
                              label=''
                              placeholder='Your last name'
                              // disabled={!iseditable}
                            />
                            <Field
                              id='email'
                              name='email'
                              autoComplete='email'
                              type='text'
                              component={FormikInputField}
                              label=''
                              placeholder='Your Email'
                              disabled={email !== null ? true : false}
                            />
                            <div className="nexogic-phone-field-wrap">
                            <InputGroup className="mt-3" size="large">
                            <Field
                              id='mnf_phon'
                              name='phone'
                              autoComplete='phone'
                              type='text'
                              component={FormikMobileField}
                              label=''
                              placeholder='Your Phone Number'
                              value={phoneNumber}
                              disabled={phoneNumber === null ? false : true}
                            />
                            </InputGroup>
                            </div>
                          </div>
                          {errorMessage ? <div className="card-text text-danger m-auto col-lg-12">{errorMessage}</div> : null}
                          <Button
                            id='btn_sign_in'
                            color='primary'
                            size='lg'
                            block
                            className='nex-btn-primary'
                            type='submit'
                            disabled={isSubmitting}>
                            Submit
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Col>
              </div>
            </div>
          </Container>
        </main>
      </div>
    </section>
  )
}

export default UserEntry