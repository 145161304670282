import "assets/styles/typeahead.css";
import { ErrorMessage } from "formik";
import _ from "lodash";
import { useCallback, useRef, useState } from "react";
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { FormGroup, Label } from "reactstrap";
import { MetadataService } from "utils/MetadataService";

const CityAutocompleteTypeaheadField = (props) => {
  const cityAsyncInputRef = useRef(null);
  const { placeholder, type, onChange } = props;
  const { name, value } = props.field;
  const { setFieldValue } = props.form;
  const [optionData, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [innerText, setInnerText] = useState("");

  const handleSearch = async (query) => {
    setData([]);
    if (query.length < 3) return;
    setLoading(true);
    MetadataService.getCitiesWithoutStateId(query)
      .then((response) => {
        if (response.status === 200) {
          setData(
            [...response.data].map((node) => {
              const { cityId } = node;
              return {
                value: node?.description,
                id: cityId,
              };
            })
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
        if (props.alert) props.alert.error(err.message);
      });
  };

  const getdata = useCallback(_.debounce(handleSearch, 500), []);

  const handleChange = (selected) => {
    if (selected.length > 0) {
      const selectedCity = {
        id: selected[0].id.toString(),
        value: selected[0].value,
      };
      setFieldValue(`${name}`, [selectedCity], true);
      if (onChange) {
        onChange(selectedCity);
      }
    }
  };

  return (
    <FormGroup className="flex-grow-1">
      <Label htmlFor={name} className="d-block mb-2">
        {props.label}
      </Label>
      <AsyncTypeahead
        ref={cityAsyncInputRef}
        isLoading={loading}
        clearButton={innerText.length > 0}
        onSearch={getdata}
        id={"cityAsyncInput"}
        options={optionData}
        labelKey="value"
        filterBy={["value"]}
        selected={value}
        placeholder={placeholder}
        type={type}
        minLength={2}
        inputProps={{ className: "", autoComplete: name }}
        onInputChange={(text) => {
          setFieldValue(`${name}`, [{ id: "", value: text }]);
          getdata(text);
          setInnerText(text);
        }}
        onChange={handleChange}
        onBlur={() => {
          return cityAsyncInputRef !== null
            ? cityAsyncInputRef.current.hideMenu()
            : null;
        }}
        renderMenu={(results, menuProps) => {
          return (
            <Menu {...menuProps}>
              {results.map((result, index) => (
                <MenuItem key={index} option={result} position={index}>
                  {result?.value}
                </MenuItem>
              ))}
            </Menu>
          );
        }}
      />
      <ErrorMessage
        name={name}
        render={(msg) => (
          <span className="text-danger f-12 d-inline-block  mt-1 line-height-error">
            {msg}
          </span>
        )}
      />
    </FormGroup>
  );
};

export default CityAutocompleteTypeaheadField;
