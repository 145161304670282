import React from 'react';
import { useFormikContext } from 'formik';
import document_icon from "assets/images/document-icon.png";

const DocumentForm = () => {
    const { values, setFieldValue } = useFormikContext(); // Accessing Formik context

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileData = {
                name: file.name,
                size: file.size,
                file: file // Store the actual file to upload later
            };

            // Update the `docs` array in Formik's state
            setFieldValue('docs', [...values.docs, fileData]);
        }
    };

    const handleFileDelete = (index) => {
        // Remove the document from the `docs` array in Formik's state
        const updatedDocs = values.docs.filter((_, i) => i !== index);
        setFieldValue('docs', updatedDocs);
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="nex-text-xl big">Upload documents</h3>
                    </div>
                    <div className="col-lg-12">
                        <p className='nex-text text-black mb-2'>
                            Please upload any medical notes, results from diagnostic studies, etc.
                        </p>
                        <div className="nexogic-upload-card-v2 mt-3">
                            <div className="icon">
                                <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30.5 20V27.5C30.5 29.1569 29.1569 30.5 27.5 30.5H9.5C7.84315 30.5 6.5 29.1569 6.5 27.5L6.5 20M24.5 12.5L18.5 6.5M18.5 6.5L12.5 12.5M18.5 6.5L18.5 24.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <p className='card-text text-center'>
                                <label>
                                    Please upload a document for verification in ‘.pdf’,’ jpeg’, ‘png’ format <br />
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                    <a className="bold" href='#' onClick={() => document.querySelector('input[type="file"]').click()}>
                                        Select file
                                    </a> or drag and drop here. Max limit 50 MB
                                </label>
                            </p>
                        </div>
                    </div>
                    {values.docs && values.docs.length > 0 && (
                        <>
                            <hr />
                            <div className="col-lg-12 mt-2">
                                <h2 className='card-title big mb-3'>Uploaded Documents</h2>
                                {values.docs.map((doc, index) => (
                                    <div className="nexogic-upload-lists-card" key={index}>
                                        <div className="item">
                                            <div className="card-body">
                                                <div className="card-img">
                                                    <img src={document_icon} alt="" className="no-border" />
                                                </div>
                                                <div className="card-info">
                                                    <h3 className="card-title">{doc.name}</h3>
                                                    <p className='card-text text-muted small'>{doc.size}{" KB"}</p>
                                                </div>
                                                <div className="actions">
                                                    <div className="btns">
                                                        <span className='btn' onClick={() => handleFileDelete(index)}>
                                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="16.2266" cy="16" r="15.75" fill="#FFEAEF" />
                                                                <path d="M11.3984 13.7734L12.2586 20.1323C12.426 21.3702 12.5097 21.9892 12.9337 22.3597C13.3577 22.7302 13.9823 22.7302 15.2315 22.7302H18.4692C19.7184 22.7302 20.343 22.7302 20.767 22.3597C21.191 21.9892 21.2747 21.3702 21.4421 20.1323L22.3023 13.7734" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M14.5156 11.0475V11.0475C14.5156 10.2672 14.5156 9.87708 14.6572 9.57473C14.8061 9.25693 15.0616 9.00138 15.3794 8.85254C15.6818 8.71094 16.0719 8.71094 16.8522 8.71094V8.71094C17.6324 8.71094 18.0226 8.71094 18.3249 8.85254C18.6427 9.00138 18.8983 9.25693 19.0471 9.57473C19.1887 9.87708 19.1887 10.2672 19.1887 11.0475V11.0475" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M22.3023 11.0547L11.3984 11.0547" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M14.8906 14.1719L15.28 20.0132" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M18.7957 14.1719L18.4062 20.0132" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DocumentForm;
