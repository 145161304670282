import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import { useLocation } from "react-router-dom";

import "./jobs.scss";
// import JobsContent from "./JobsContent";
import JobSidebar from "./JobSidebar";
import ManageJobSideBar from "./ManageJobSideBar";
import JobsNavContent from "./JobsNavContent";
import jobsIcon from "../../assets/images/svgs/job-icon.svg";
import patiebt_sidebar from "../../assets/images/nex-patient.png";
import jobs_sidebar from "../../assets/images/nex-jobs-sidebar.png";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Jobs = (props) => {
  let location = useLocation();
  useEffect(() => {
    document.body.classList.add('body-nex-jobs-page');
    document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "Jobs";
    return () => {
      document.body.classList.remove('body-nex-jobs-page');
      document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "";
    }
  }, [])

  return (
    <>
      <section className="jobs-managements pb-4 nex-jobs-managements-v2">
        {/** 
        <div className="nexogic-heading-sec nexogic-card">
        <h2 className="nexogix-section-title"><img src={jobsIcon} className="icon" /> Jobs Board</h2>
        </div>
        */}
        <main>
          <Row className="container-jobs px-0">
            <Col className="nex-job-sidebar v2">
            {/** <JobSidebar /> */}
            <Card className="nexogic-widget-sidebar-card text-center">
                <div className="graphic">
                  <img src={jobs_sidebar} alt="" className="img-fluid" />
                </div>
                <div className="card-description">
                  <h2 className="card-title">Want to hire new Talents?</h2>
                  <p className="card-text">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed.</p>
                </div>
                <div className="action">
                  <Link to={"/new/create-jobs"} className="nex-btn-primary no-full-w">Post a Job</Link>
                </div>
              </Card>
              <Card className="nexogic-widget-sidebar-card text-center">
                <div className="graphic">
                  <img src={patiebt_sidebar} alt="" width={400} className="img-fluid" />
                </div>
                <div className="card-description">
                  <h2 className="card-title">Want to refer a Patient?</h2>
                  <p className="card-text">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed.</p>
                </div>
                <div className="action">
                  <Link to={"/referrals/refer-patient"} className="nex-btn-primary-outline no-full-w">Refer Your Patient</Link>
                </div>
              </Card>
              <ManageJobSideBar />
              {/** 
              <div className="nexogic-sidebar-stiky nexogic-custom-scroll">
              <Row>
                <Col>
                  <JobSidebar />
                </Col>
              </Row>
              <Row>
                <Col>
                  
                </Col>
              </Row>
              </div>
              */}

            </Col>
            <Col className="nex-app-no-pad nex-job-lists-content">
              <div className="">
                <JobsNavContent />
              </div>
            </Col>
          </Row>
        </main>
      </section>
    </>
  );
};

export default Jobs;
