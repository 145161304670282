import { ErrorMessage } from "formik";
import { FormGroup, Input, Label } from "reactstrap";

const FormikRadioField = ({
  field: { ...fields },
  form: { touched, errors, ...rest },
  ...props
}) => (
  <FormGroup className="m-0">
    <Label htmlFor={props.id} className={"label-color m-0"}>
      <Input
        {...props}
        {...fields}
        className=""
        invalid={Boolean(touched[fields.name] && errors[fields.name])}
      />
      {props.label}
    </Label>
    <ErrorMessage
      name={fields.name}
      render={(msg) => (
        <span className="text-danger d-inline-block  mt-1 line-height-error">
          {msg}
        </span>
      )}
    />
  </FormGroup>
);
export default FormikRadioField;
