import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Modal,ModalBody } from "reactstrap";
import Search from '../../Search';

const ResponsiveSearch = () => {
    const [toggle,setToggle] = useState(false);
    const handleToggle = () => {
        setToggle(!toggle);
    }
    return (
        <div>
            <FontAwesomeIcon icon={faSearch} className="text-dark fa-xl" onClick={handleToggle}/>
            <Modal toggle={handleToggle} isOpen={toggle} className="modal-dialog modal-fade m-0" style={{height:"100%"}}>
                <ModalBody>
                    <Search responsiveToggleFunc={handleToggle} responsiveToggle={toggle}/>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default ResponsiveSearch;