import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, CardBody, Card, Modal, ModalHeader, ModalBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import entryData from "pages/Referals/Components/Common/data/CommonData";
import BackIcon from "assets/images/backIcon.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import referals_icon from "assets/images/svgs/referals-icon.svg";
import { useState } from "react";
import ReferAssignModal from "./Components/Modal/ReferAssignModal";
import { useEffect } from "react";
import profile_sample from "assets/images/user-profile-sample.png";
import { ReferralService } from "utils/ReferralService";
import { FAILTOSAVERECOED, FAILTOGETRECOED, FAILED_TO_FETCH_DATA } from "utils/Constants";
import { createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor, getGenderDetails, capitalizeFirstLetter, getAge, getFullNameWithPrimarySpecialityAndSuperSpeciality, dateFormatter, dateFormatterNew, getFileIcon } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import { withAlert } from "react-alert";
import { MedialService } from "utils/MediaService";
import { useRef } from "react";
import CommonLoader from "common/CommonLoader";
import CandidateWidget from "common/CandidateWidget";
import PeopleKnowWidget from "./PeopleKnowWidget";
import "./style.css"
import { ReferralResponseService } from "utils/ReferralResponseService";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import CollapseablePannel from "common/CollapseablePannel";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import ReceivedReferralPreview from "../ReceivedReferals/Components/ReceivedReferralPreview";
import SentReferralPreview from "../SentReferals/Components/SentReferralPreview";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import PageTitle from "common/PageTitle/PageTitle";
import QuickInfo from "common/QuickInfo/QuickInfo";
import QuickPractitioner from "components/QuickPractitioner/QuickPractitioner";
import ReferWidget from "common/ReferWidget/ReferWidget";



const CompleteForm = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { type } = location.state || {};
    const [isLoading, setIsLoading] = useState(true);
    const [dataLoading, setDataLoading] = useState(false);
    const [pannel_one_on, setPannel_one_on] = useState(true);
    const [pannel_two_on, setPannel_two_on] = useState(true);
    const [pannel_three_on, setPannel_three_on] = useState(true);
    const [pannel_four_on, setPannel_four_on] = useState(true);
    const [reqAssign, setReqAssign] = useState(false);
    const [isAssignModal, setIsAssignModal] = useState(false);
    const [referral, setReferral] = useState({});
    const [docsInfo, setDocsInfo] = useState([]);
    const [isFileUploaing, setIsFileUploaing] = useState(false);
    const [actions, setActions] = useState([])
    const [dropDownItem, setDropDownItem] = useState();
    const [documentType, setDocumentType] = useState(null);
    const [documentData, setDocumentData] = useState(null);
    const [isResponseAvailable, setIsResponseAvailable] = useState(false);
    const [modal, setModal] = useState(false);
    const [assignmentHistory, setAssignmentHistory] = useState([]);
    const ls_app_val = localStorage.getItem('app_status') || null;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    let { id } = useParams();

    const fileInputRef = useRef(null);

    const toggle = () => {
        // setModal(!modal);
        setDropdownOpen((prevState) => !prevState);
    };

    // const toggless = () => setDropdownOpen((prevState) => !prevState);

    const toggleForm = () => setModal(!modal);
    const getReferralDoc = async (id) => {
        try {
            setDataLoading(true);
            const { data, headers } = await MedialService.getMediaFile(id);
            const createContentType = headers["content-type"] === "application/octet-stream"
                ? "application/pdf"
                : headers["content-type"];
            let generateURL = `data:${createContentType};base64,${Buffer.from(data).toString("base64")}`;
            setDocumentData(generateURL);
            let docArray = [data, createContentType];
            setDocumentType(docArray);

            if (ls_app_val === 'inapp') {
                setDataLoading(false);
                const link = document.createElement('a');
                link.href = generateURL;
                let ext = docArray[1].split('/').pop();
                link.download = 'reports.' + ext;
                link.target = '_blank';
                link.rel = 'noopener noreferrer';
                link.click();

                return;
            }

            if (createContentType === "application/pdf") {
                setDataLoading(false);
                onOpenBase64(generateURL);
            }
            else {
                setDataLoading(false);
                toggleForm();
            }
        } catch (error) {
            console.error("Error retrieving referral document:", error);
        }
    };

    const onOpenBase64 = (base64URL) => {
        // const onOpenBase64 = () => {
        try {
            var win = window.open();
            win.document.write(`
            <iframe class="nexogic-iframe" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" 
              src="${base64URL}" frameborder="0" allowfullscreen></iframe>
          `);
        } catch (error) {
            console.error("Error opening base64 URL:", error);
            // Handle error, show error message, etc.
        }
    };

    useEffect(() => {
        document.body.classList.add('refaral-details-page');
        return () => {
            document.body.classList.remove('refaral-details-page');
        }
    }, [])

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = documentData;
        let ext = documentType[1].split('/').pop();
        link.download = 'reports.' + ext;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
    };
    function setActionsForTheStatus(status, type) {
        if (type === "SENT") {
            if (status === "DRAFT")
                setActions(["Submit"])
            else if (status === "SUBMITTED")
                setActions(["Cancel"])
            else
                setActions([])
        } else {
            if (status === "SUBMITTED") {
                setActions(["In Review", "Accept", "Reject"])
            } else if (status === "IN_REVIEW") {
                setActions(["Accept", "Reject"])
            } else if (status === "ACCEPTED") {
                setActions(["In Progress", "Close"])
            } else if (status === "IN_PROGRESS") {
                setActions(["Close"])
            } else {
                setActions([])
            }
        }

    }

    const handleUpdateReferralStatus = (status) => {
        let act = "";
        switch (status) {
            case "Cancel":
                act = "CANCELED";
                break;
            case "Submit":
                act = "SUBMITTED";
                break;
            case "In Review":
                act = "IN_REVIEW";
                break;
            case "Accept":
                act = "ACCEPTED";
                break;
            case "Reject":
                act = "REJECTED";
                break;
            case "In Progress":
                act = "IN_PROGRESS";
                break;
            default:
                act = "CLOSED";
        }

        var payload = {
            "status": act
        }
        ReferralService.updateReferralStatus(id, type, payload)
            .then((response) => {
                const { status } = response;
                if (status === 200) {
                    showSuccessMessage(`Referral status updated Successfully.`);
                    getReferralById(id);
                    getReferralResponse(id);
                }
                else {
                    showErrorMessage(FAILTOSAVERECOED);
                }
            })
    }

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            // Perform any necessary validation or processing of the selected file here
            setIsFileUploaing(true);
            // Create a FormData object to send the file data
            const formData = new FormData();
            formData.append('files', selectedFile);

            // Submit the file to the API using your preferred method (e.g., fetch, axios)
            MedialService.uploadMedia("REFERRAL", id, formData)
                .then((response) => {
                    const { status } = response;
                    if (status === 200) {
                        showSuccessMessage("Document uploaded Successfully");
                        getDocsInfo();
                        setIsFileUploaing(false);
                    } else if (status === 409) {
                        props.alert.info("Can't upload the document with the same name again");
                        setIsFileUploaing(false);
                    }
                    else {
                        showErrorMessage(FAILTOSAVERECOED);
                        setIsFileUploaing(false);
                    }
                })
        }
    };

    const getDocsInfo = () => {
        MedialService.getMedias("REFERRAL", id)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setDocsInfo(data);
                } else {
                    console.log(FAILTOSAVERECOED);
                }
            })
    }

    const getReferralResponse = (id) => {
        ReferralResponseService.getReferralResponse(id, type)
            .then((response) => {
                const { status } = response;
                if (status === 200) {
                    setIsResponseAvailable(true);
                }
                else {
                    setIsResponseAvailable(false);
                }
            })
    }

    const getReferralById = (id) => {
        ReferralService.getReferralById(id, type)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setReferral(data);
                    setActionsForTheStatus(data?.status, type)
                    setIsLoading(false);
                } else if (response.status === 400 || response.status === 404) {
                    history.goBack();
                }
                return null;
            })
    }

    const getAssignmentHistory = (id) => {
        ReferralService.getAssignmentHistory(id)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    setAssignmentHistory(data);
                }
                else {
                    console.log(FAILED_TO_FETCH_DATA);
                }
            })
    }

    useEffect(() => {
        getReferralById(id);
        getReferralResponse(id);
        getDocsInfo();
        getAssignmentHistory(id);
    }, [id])


    const handleBack = () => {
        history.goBack()
    }
    const filteredArray = entryData.filter((data) => {
        return id === data.id
    });

    function openAssignModal(e) {
        e.preventDefault();
        setIsAssignModal(!isAssignModal);
    }

    function haldleAssign(data) {
        if (data === true) {
            setIsAssignModal(false);
            setReqAssign(data);
        }
    }

    function haldleUnassign(e) {
        e.preventDefault();
        setReqAssign(false);
    }

    useEffect(() => {
        document.body.classList.add("nexogic-refer-page");
        return () => document.body.classList.remove("nexogic-refer-page");
    }, []);


    function getStatusClass(inp) {
        let classes = '';
        switch (inp) {
            case "closed":
                classes += ' closed';
                break;
            case "submitted":
                classes += ' submitted';
                break;
            case "accept":
                classes += ' accepted';
                break;
            case "reject":
                classes += ' rejected';
                break;
            case "in_progress":
                classes += ' inprogress';
                break;
            case "draft":
                classes += ' draft';
                break;
            default:
                classes += '';
        }
        return classes;
    }

    return (
        <Row className="nex-container">
            <Col xs={12}>
                <PageTitle
                    title={<>Patient referral <span>{!isLoading && `(${referral?.patient?.firstName} ${referral?.patient?.lastName})`}</span></>}
                    titleClass="mb-2"
                    leftIcons={<Link to="" onClick={handleBack} className="btn pt-0"><img src={BackIcon} alt="back" /></Link>}
                >
                    {/*<span><Link to="" onClick={handleBack}><img src={BackIcon} alt="back" /></Link></span>
                        <h2 className="h2">Patient referral <span>{!isLoading && `(${referral?.patient?.firstName} ${referral?.patient?.lastName})`}</span></h2>
                        */}
                </PageTitle>
            </Col>
            <Col className="nex-lists-content nexogic-ref-details-page complete-form">
                {isLoading ?
                    <div className="loading-spiner text-center  mt-2">
                        <CommonLoader />
                    </div>
                    :
                    <>
                        <Card className="nexogic-form-text-display-w border-0 bg-transparent nexogic-referral-details">
                            <CardBody className="p-0  nexogic-referals-new">
                                <Row className="need-margin">
                                    <Col xl={12}>
                                        <div className="position-relative mb-3">
                                            <Card className="nexogic-card-list-custom nexogic-reffer-card nex-list-card-v2 bg-white">
                                                <div className="card-body">
                                                    <div className="row need-margin">

                                                        {/** patient */}
                                                        <div className="col-12 patient-cols">
                                                            <div className="">
                                                                <div className="patient-col-heading">
                                                                    <h3 className="nex-text-xl mb-3"> Patient Information</h3>
                                                                    <div className="status-col-w">
                                                                        {referral && ((type === "SENT")
                                                                            ?
                                                                            <div className="status-col position-top-big flex-row align-items-center">
                                                                                {referral?.status === 'DRAFT' && (
                                                                                    <Link id="nex-rccf-edit-btn" to={`/referrals/update/${referral?.id}`}>
                                                                                        <button type="button" id="nex-referral-edit-btn" title="Edit Referral Details" class="btn btn-link rounded border p-2 mr-2"><svg width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.3125 0.25L6.09375 1.46875L8.53125 3.90625L9.75 2.6875L7.3125 0.25ZM4.875 2.6875L0 7.5625V10H2.4375L7.3125 5.125L4.875 2.6875Z" fill="#46ABC9"></path></svg></button>
                                                                                        {/* <span className="status-pill cursor-pointer">
                                                                                            <img src={edit_blue_icon} alt="" width="22" className="mx-2" />
                                                                                        </span> */}
                                                                                    </Link>
                                                                                )}
                                                                                <div className="mx-3 col-status">
                                                                                    <span className={`rounded-pill w-100  ${getStatusClass(referral?.status?.toLowerCase())} `}>
                                                                                        {referral?.status
                                                                                            .toLowerCase()
                                                                                            .split("_")
                                                                                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                                                            .join(" ")
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                {(referral?.status === 'DRAFT' || referral?.status === 'SUBMITTED') ? (
                                                                                    <Dropdown
                                                                                        id="nex-rccf-status-btn"
                                                                                        isOpen={dropDownItem === referral.id}
                                                                                        toggle={(e) => dropDownItem === referral?.id ? setDropDownItem(null) : setDropDownItem(referral?.id)}
                                                                                    >
                                                                                        <DropdownToggle id="nex-rccf-status-btn1" tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen} className="common-icon p-0 border-0" style={{ cursor: 'pointer' }} color="primary">
                                                                                            <svg width="7" height="25" viewBox="0 0 5 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <circle cx="2.375" cy="1.875" r="1.875" fill="#46ABC9" />
                                                                                                <circle cx="2.375" cy="8.625" r="1.875" fill="#46ABC9" />
                                                                                                <circle cx="2.375" cy="15.375" r="1.875" fill="#46ABC9" />
                                                                                            </svg>
                                                                                        </DropdownToggle>
                                                                                        {(referral?.status === 'DRAFT' || referral?.status === 'SUBMITTED') && (
                                                                                            <DropdownMenu right className={`${getStatusClass(referral?.status?.toLowerCase())}`}>
                                                                                                {actions.map((action, index) => (
                                                                                                    <DropdownItem
                                                                                                        id={`nex-rccf-status-dynamic-${index}`}
                                                                                                        key={index}
                                                                                                        onClick={(e) => {
                                                                                                            handleUpdateReferralStatus(action);
                                                                                                        }}
                                                                                                    >
                                                                                                        {referral?.status != action && action}
                                                                                                    </DropdownItem>
                                                                                                ))}
                                                                                            </DropdownMenu>
                                                                                        )}
                                                                                    </Dropdown>
                                                                                ) : (
                                                                                    <span className={`status-pill  w-100 ${getStatusClass(referral?.status?.toLowerCase())}`}>
                                                                                        {referral?.status
                                                                                            .toLowerCase()
                                                                                            .split("_")
                                                                                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                                                            .join(" ")
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                            :
                                                                            <div className="status-col position-top-big">
                                                                                {/* <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                                                                    <DropdownToggle
                                                                                        tag="span"
                                                                                        data-toggle="dropdown"
                                                                                        aria-expanded={dropdownOpen}
                                                                                        className="p-0 border-0"
                                                                                        style={{ cursor: 'pointer' }}
                                                                                    >
                                                                                        <svg width="5" height="18" viewBox="0 0 5 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <circle cx="2.375" cy="1.875" r="1.875" fill="#46ABC9" />
                                                                                            <circle cx="2.375" cy="8.625" r="1.875" fill="#46ABC9" />
                                                                                            <circle cx="2.375" cy="15.375" r="1.875" fill="#46ABC9" />
                                                                                        </svg>
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem href="#/action-1">Option 1</DropdownItem>
                                                                                        <DropdownItem href="#/action-2">Option 2</DropdownItem>
                                                                                        <DropdownItem href="#/action-3">Option 3</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </Dropdown> */}

                                                                                {referral?.status !== 'CLOSED' ? (
                                                                                    <div className="d-flex">
                                                                                        <div className="mx-3 col-status">
                                                                                            <span className={`rounded-pill w-100  ${getStatusClass(referral?.status?.toLowerCase())} `}>
                                                                                                {referral?.status
                                                                                                    .toLowerCase()
                                                                                                    .split("_")
                                                                                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                                                                    .join(" ")
                                                                                                }
                                                                                            </span>
                                                                                        </div>
                                                                                        <Dropdown id="nex-rccf-status-close" isOpen={dropDownItem === referral.id} toggle={(e) =>
                                                                                            dropDownItem === referral?.id
                                                                                                ? setDropDownItem(null)
                                                                                                : setDropDownItem(referral?.id)
                                                                                        }>
                                                                                            <DropdownToggle
                                                                                                tag="span"
                                                                                                data-toggle="dropdown"
                                                                                                aria-expanded={dropdownOpen}
                                                                                                className="p-0 border-0"
                                                                                                style={{ cursor: 'pointer' }}
                                                                                            >
                                                                                                <svg width="7" height="25" viewBox="0 0 5 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <circle cx="2.375" cy="1.875" r="1.875" fill="#46ABC9" />
                                                                                                    <circle cx="2.375" cy="8.625" r="1.875" fill="#46ABC9" />
                                                                                                    <circle cx="2.375" cy="15.375" r="1.875" fill="#46ABC9" />
                                                                                                </svg>
                                                                                            </DropdownToggle>
                                                                                            {referral?.status !== 'CLOSED' && (
                                                                                                <DropdownMenu right className="dropdmenu">
                                                                                                    {actions.map((action, index) => (
                                                                                                        <DropdownItem
                                                                                                            id={`nex-rccf-status-close-dyna-${index}`}
                                                                                                            key={index}
                                                                                                            onClick={(e) => {
                                                                                                                handleUpdateReferralStatus(action);
                                                                                                            }}
                                                                                                        >
                                                                                                            {referral?.status !== action && action}
                                                                                                        </DropdownItem>
                                                                                                    ))}
                                                                                                </DropdownMenu>
                                                                                            )}
                                                                                            {/* <DropdownMenu>
                                                                                        <DropdownItem href="#/action-1">Option 1</DropdownItem>
                                                                                        <DropdownItem href="#/action-2">Option 2</DropdownItem>
                                                                                        <DropdownItem href="#/action-3">Option 3</DropdownItem>
                                                                                    </DropdownMenu> */}
                                                                                        </Dropdown>
                                                                                        {/* <Dropdown
                                                                                        id="nex-rccf-status-close-btn"
                                                                                        isOpen={dropDownItem == referral.id}
                                                                                        toggle={(e) =>
                                                                                            dropDownItem === referral?.id
                                                                                                ? setDropDownItem(null)
                                                                                                : setDropDownItem(referral?.id)
                                                                                        }
                                                                                    > */}

                                                                                        {/* <DropdownToggle id="nex-rccf-status-close-btn1" className={`status-pill  w-100 ${getStatusClass(referral?.status?.toLowerCase())} `} caret color="primary"> */}
                                                                                        {/* <DropdownToggle id="nex-rccf-status-close-btn1" className="common-icon" color="primary"> */}
                                                                                        {/* <DropdownToggle className="btn btn-link"> */}
                                                                                        {/* {referral?.status
                                                                                                .toLowerCase()
                                                                                                .split("_")
                                                                                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                                                                .join(" ")
                                                                                            } */}
                                                                                        {/* <svg width="5" height="18" viewBox="0 0 5 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <circle cx="2.375" cy="1.875" r="1.875" fill="#46ABC9" />
                                                                                                <circle cx="2.375" cy="8.625" r="1.875" fill="#46ABC9" />
                                                                                                <circle cx="2.375" cy="15.375" r="1.875" fill="#46ABC9" />
                                                                                            </svg>
                                                                                        </DropdownToggle> */}
                                                                                        {/* {referral?.status !== 'CLOSED' && (
                                                                                            <DropdownMenu right className={`${getStatusClass(referral?.status?.toLowerCase())}`}>
                                                                                                {actions.map((action, index) => (
                                                                                                    <DropdownItem
                                                                                                        id={`nex-rccf-status-close-dyna-${index}`}
                                                                                                        key={index}
                                                                                                        onClick={(e) => {
                                                                                                            handleUpdateReferralStatus(action);
                                                                                                        }}
                                                                                                    >
                                                                                                        {referral?.status != action && action}
                                                                                                    </DropdownItem>
                                                                                                ))}
                                                                                            </DropdownMenu>
                                                                                        )}
                                                                                    </Dropdown> */}
                                                                                    </div>
                                                                                ) : (''
                                                                                    // <span className={`status-pill w-100  ${getStatusClass(referral?.status?.toLowerCase())} `}>
                                                                                    //     {referral?.status
                                                                                    //         .toLowerCase()
                                                                                    //         .split("_")
                                                                                    //         .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                                                    //         .join(" ")
                                                                                    //     }
                                                                                    // </span>
                                                                                )}
                                                                            </div>
                                                                        )
                                                                        }

                                                                        {type === "RECEIVED" &&
                                                                            <>
                                                                                {isResponseAvailable ?
                                                                                    <Link id="nex-view-responsive-btn" className="btn nex-btn-primary py-2" to={`/referrals/sent-response/${referral?.id}`}>
                                                                                        {"View Response"}
                                                                                    </Link>
                                                                                    :
                                                                                    <>
                                                                                        {(referral?.status === 'ACCEPTED' || referral?.status === 'IN_PROGRESS') &&
                                                                                            <Link id="nex-view-responsive-btn" className="btn nex-btn-primary py-2" to={`/referrals/sent-response/${referral?.id}`}>
                                                                                                {"Provide Response"}
                                                                                            </Link>
                                                                                        }
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="nex-stack">
                                                                    <QuickPractitioner
                                                                        isTitleLink={true}
                                                                        titleLink={`/patient-profile/${referral?.patient?.id}`}
                                                                        avatar={
                                                                            referral?.patient?.avatarId ?
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_IMAGE_BASE_URL} /${referral?.patient?.id}/${referral?.patient?.avatarId}`}
                                                                                    className="nex-avatar lg"
                                                                                    alt="#"
                                                                                />
                                                                                :
                                                                                <>
                                                                                    <img
                                                                                        src={createImageFromInitials(
                                                                                            50,
                                                                                            `${(referral?.patient?.firstName + " " + referral?.patient?.lastName)}`,
                                                                                            getRandomColor(
                                                                                                encrypt(
                                                                                                    referral?.patient?.id
                                                                                                ).toString()
                                                                                            )
                                                                                        )}
                                                                                        className="nex-avatar lg"
                                                                                        alt="#"
                                                                                    />
                                                                                </>
                                                                        }
                                                                        name={`${referral?.patient?.firstName} ${referral?.patient?.lastName}`}
                                                                    >
                                                                        <div className="nex-text">{capitalizeFirstLetter(referral?.patientType.toLowerCase())}</div>
                                                                    </QuickPractitioner>
                                                                    <div className="info">
                                                                        <p className="nex-text text-black">Age : {referral?.patient?.dateOfBirth ? getAge(referral?.patient?.dateOfBirth) : 'N/A'}</p>
                                                                        <p className="nex-text text-black">Gender : {referral?.patient?.gender ? getGenderDetails(referral?.patient?.gender) : ''}</p>
                                                                    </div>
                                                                </div>
                                                                {/** old code */}
                                                                {/* <div className="col-md-6 d-none">
                                                                    <div className="head">
                                                                        {
                                                                            referral?.patient?.avatarId ?
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_IMAGE_BASE_URL} /${referral?.patient?.id}/${referral?.patient?.avatarId}`}
                                                                                    className="card-img"
                                                                                    alt="#"
                                                                                />
                                                                                :
                                                                                <>
                                                                                    <img
                                                                                        src={createImageFromInitials(
                                                                                            50,
                                                                                            `${(referral?.patient?.firstName + " " + referral?.patient?.lastName)}`,
                                                                                            getRandomColor(
                                                                                                encrypt(
                                                                                                    referral?.patient?.id
                                                                                                ).toString()
                                                                                            )
                                                                                        )}
                                                                                        className="card-img"
                                                                                        alt="#"
                                                                                    />
                                                                                </>
                                                                        }
                                                                        <div className="info">
                                                                            <p className="card-text"><b>Name  </b>
                                                                                <Link id="nex-view-prof-name" to={`/patient-profile/${referral?.patient?.id}`}>
                                                                                    <span>{referral?.patient?.firstName}{" "}{referral?.patient?.lastName}</span>
                                                                                </Link>
                                                                            </p>
                                                                            <p className="card-text"><b>Age  </b>{referral?.patient?.dateOfBirth ? getAge(referral?.patient?.dateOfBirth) : 'N/A'}</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 col-second d-none">
                                                                    <div className="head">
                                                                        {
                                                                            referral?.patient?.avatarId ?
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.patient?.id}/${referral?.patient?.avatarId}`}
                                                                                    className="card-img"
                                                                                    alt="#"
                                                                                />
                                                                                :
                                                                                <>
                                                                                    <img
                                                                                        src={createImageFromInitials(
                                                                                            50,
                                                                                            `${(referral?.patient?.firstName + " " + referral?.patient?.lastName)}`,
                                                                                            getRandomColor(
                                                                                                encrypt(
                                                                                                    referral?.patient?.id
                                                                                                ).toString()
                                                                                            )
                                                                                        )}
                                                                                        className="card-img"
                                                                                        alt="#"
                                                                                    />
                                                                                </>
                                                                        }
                                                                        <div className="info">
                                                                            <p className="card-text"><b>Gender  </b> {referral?.patient?.gender ? getGenderDetails(referral?.patient?.gender) : ''}</p>
                                                                            <p className="card-text"><b>{/*Patient}Type  </b> {capitalizeFirstLetter(referral?.patientType.toLowerCase())}</p>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                {/** old code End */}
                                                            </div>


                                                        </div>

                                                        {/** Receiving */}
                                                        <div className="col-12 reffering-to-col">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <h3 className="nex-text-xl mb-3"> Receiving Physician </h3>
                                                                    <div className="nex-stack">
                                                                        <QuickPractitioner
                                                                            isTitleLink={true}
                                                                            titleLink={`/profile/` + referral?.referredToPhysician?.profileId}
                                                                            titleLinkid="nex-view-prof-name2"
                                                                            avatar={
                                                                                referral?.referredToPhysician?.avatarId ?
                                                                                    <img
                                                                                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredToPhysician?.profileGuid}/${referral?.referredToPhysician?.avatarId}`}
                                                                                        className="nex-avatar lg"
                                                                                        alt="#"
                                                                                    />
                                                                                    :
                                                                                    <>
                                                                                        <img
                                                                                            src={createImageFromInitials(
                                                                                                50,
                                                                                                `${referral?.referredToPhysician?.name.replace("Dr. ", "")}`,
                                                                                                getRandomColor(
                                                                                                    encrypt(
                                                                                                        referral?.referredToPhysician?.profileGuid
                                                                                                    ).toString()
                                                                                                )
                                                                                            )}
                                                                                            className="nex-avatar lg"
                                                                                            alt="#"
                                                                                        />
                                                                                    </>
                                                                            }                                                                            
                                                                            name={(referral?.referredToPhysician && referral?.referredToPhysician?.name) ? referral?.referredToPhysician?.name : 'N/A'}
                                                                            speciality = {`${referral?.referredToPhysician?.primarySpeciality || ''}${referral?.referredToPhysician?.superSpeciality ? ` ( ${referral?.referredToPhysician?.superSpeciality} ) ` : ''}`}
                                                                            address={getFullAddressWithLocationCityAndState({ location: referral?.referredToPhysician?.location ?? "", city: referral?.referredToPhysician?.city ?? "", state: referral?.referredToPhysician?.state ?? "" })}
                                                                        ></QuickPractitioner>
                                                                    </div>
                                                                    {/** old code */}
                                                                    {/* <div className="head d-none">
                                                                        {
                                                                            referral?.referredToPhysician?.avatarId ?
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredToPhysician?.profileGuid}/${referral?.referredToPhysician?.avatarId}`}
                                                                                    className="card-img"
                                                                                    alt="#"
                                                                                />
                                                                                :
                                                                                <>
                                                                                    <img
                                                                                        src={createImageFromInitials(
                                                                                            50,
                                                                                            `${referral?.referredToPhysician?.name.replace("Dr. ", "")}`,
                                                                                            getRandomColor(
                                                                                                encrypt(
                                                                                                    referral?.referredToPhysician?.profileGuid
                                                                                                ).toString()
                                                                                            )
                                                                                        )}
                                                                                        className="card-img"
                                                                                        alt="#"
                                                                                    />
                                                                                </>
                                                                        }
                                                                        <div className="info">
                                                                            <p className="card-text"><b>Name  </b>
                                                                                {(referral?.referredToPhysician && referral?.referredToPhysician?.name) ?
                                                                                    <Link id="nex-view-prof-name2" to={`/profile/` + referral?.referredToPhysician?.profileId} >{referral?.referredToPhysician?.name}</Link>
                                                                                    :
                                                                                    <>N/A</>
                                                                                }
                                                                            </p>
                                                                            <p className="card-text">
                                                                                <b>Address  </b>
                                                                                {referral?.referredToPhysician ?
                                                                                    <>
                                                                                        {getFullAddressWithLocationCityAndState({ location: referral?.referredToPhysician?.location ?? "", city: referral?.referredToPhysician?.city ?? "", state: referral?.referredToPhysician?.state ?? "" })}
                                                                                    </>
                                                                                    :
                                                                                    <>N/A</>
                                                                                }
                                                                            </p>
                                                                            <p className="card-text"><b>Speciality  </b>
                                                                                {referral?.referredToPhysician ?
                                                                                    <>
                                                                                        {referral?.referredToPhysician?.primarySpeciality}{referral?.referredToPhysician?.superSpeciality ? ` ( ${referral?.referredToPhysician?.superSpeciality} ) ` : ''}
                                                                                    </>
                                                                                    :
                                                                                    <>N/A</>
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div> */}
                                                                    {/** old code end */}
                                                                </div>
                                                                <div className="col-md-6">
                                                                    {referral?.referredToInstitution && referral?.referredToInstitution?.guid &&
                                                                        <div className="reffering-physician-cols ">
                                                                            <h3 className=" nex-text-xl mb-3"> Receiving Hospital / Clinic </h3>
                                                                            <div className="nex-stack">
                                                                                <QuickPractitioner
                                                                                    isTitleLink={true}
                                                                                    titleLink={`/institution/` + referral?.referredToInstitution?.profileId}
                                                                                    titleLinkId="nex-view-prof-name3"
                                                                                    avatar={
                                                                                        referral?.referredToInstitution?.avatarId ?
                                                                                            <img
                                                                                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredToInstitution?.guid}/${referral?.referredToInstitution?.avatarId}`}
                                                                                                className="nex-avatar lg"
                                                                                                alt="#"
                                                                                            />
                                                                                            :
                                                                                            <>
                                                                                                <img
                                                                                                    src={createImageFromInitials(
                                                                                                        50,
                                                                                                        `${(referral?.referredToInstitution?.name)}`,
                                                                                                        getRandomColor(
                                                                                                            encrypt(
                                                                                                                referral?.referredToInstitution?.guid
                                                                                                            ).toString()
                                                                                                        )
                                                                                                    )}
                                                                                                    className="nex-avatar lg"
                                                                                                    alt="#"
                                                                                                />
                                                                                            </>
                                                                                    }
                                                                                    name={referral?.referredToInstitution?.name}
                                                                                    address={getFullAddressWithLocationCityAndState({ location: referral?.referredToInstitution?.address?.location ?? "", city: referral?.referredToInstitution?.address?.city ?? "", state: referral?.referredToInstitution?.address?.state ?? "" })}
                                                                                ></QuickPractitioner>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {!referral?.referredToInstitution && referral?.referredToInstitution?.guid &&
                                                                        <div className="reffering-physician-cols ">
                                                                            <h3 className=" nex-text-xl mb-3"> Receiving Hospital / Clinic </h3>
                                                                            <div className="head">
                                                                                <p className="nex-text">No Receiving Hospital / Clinic Found</p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>

                                                        {/** Referring */}
                                                        <div className="col-12 referring-cols">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <h3 className="nex-text-xl mb-3">Referring Physician</h3>
                                                                    <div className="nex-stack">
                                                                        <QuickPractitioner
                                                                            isTitleLink={true}
                                                                            titleLink={`/profile/` + referral?.referredByPhysician?.profileId}
                                                                            titleLinkId="nex-view-prof-name4"
                                                                            avatar={
                                                                                referral?.referredByPhysician?.avatarId ?
                                                                                    <img
                                                                                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredByPhysician?.profileGuid}/${referral?.referredByPhysician?.avatarId}`}
                                                                                        className="nex-avatar lg"
                                                                                        alt="#"
                                                                                    />
                                                                                    :
                                                                                    <>
                                                                                        <img
                                                                                            src={createImageFromInitials(
                                                                                                50,
                                                                                                `${referral?.referredByPhysician?.name.replace("Dr. ", "")}`,
                                                                                                getRandomColor(
                                                                                                    encrypt(
                                                                                                        referral?.referredByPhysician?.profileGuid
                                                                                                    ).toString()
                                                                                                )
                                                                                            )}
                                                                                            className="nex-avatar lg"
                                                                                            alt="#"
                                                                                        />
                                                                                    </>
                                                                            }
                                                                            name={referral?.referredByPhysician?.name}
                                                                            speciality={`${referral?.referredByPhysician?.primarySpeciality}${referral?.referredByPhysician?.superSpeciality ? ` ( ${referral?.referredByPhysician?.superSpeciality} ) ` : ''}`}
                                                                            address={getFullAddressWithLocationCityAndState({ location: referral?.referredByPhysician?.location ?? "", city: referral?.referredByPhysician?.city ?? "", state: referral?.referredByPhysician?.state ?? "" })}
                                                                        >
                                                                        </QuickPractitioner>
                                                                    </div>
                                                                    <div className="reffering-physician-cols mb-1 d-none">
                                                                        <h3 className=" card-title big">  Referring Physician</h3>
                                                                        <div className="head">
                                                                            {
                                                                                referral?.referredByPhysician?.avatarId ?
                                                                                    <img
                                                                                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredByPhysician?.profileGuid}/${referral?.referredByPhysician?.avatarId}`}
                                                                                        className="card-img"
                                                                                        alt="#"
                                                                                    />
                                                                                    :
                                                                                    <>
                                                                                        <img
                                                                                            src={createImageFromInitials(
                                                                                                50,
                                                                                                `${referral?.referredByPhysician?.name.replace("Dr. ", "")}`,
                                                                                                getRandomColor(
                                                                                                    encrypt(
                                                                                                        referral?.referredByPhysician?.profileGuid
                                                                                                    ).toString()
                                                                                                )
                                                                                            )}
                                                                                            className="card-img"
                                                                                            alt="#"
                                                                                        />
                                                                                    </>
                                                                            }
                                                                            <div className="info">
                                                                                <p className="card-text"><b>Name  </b>  <Link id="nex-view-prof-name4" to={`/profile/` + referral?.referredByPhysician?.profileId} >{referral?.referredByPhysician?.name}</Link></p>
                                                                                <p className="card-text">
                                                                                    <b>Address</b>
                                                                                    {getFullAddressWithLocationCityAndState({ location: referral?.referredByPhysician?.location ?? "", city: referral?.referredByPhysician?.city ?? "", state: referral?.referredByPhysician?.state ?? "" })}
                                                                                </p>
                                                                                <p className="card-text"><b>Speciality  </b> {referral?.referredByPhysician?.primarySpeciality}{referral?.referredByPhysician?.superSpeciality ? ` ( ${referral?.referredByPhysician?.superSpeciality} ) ` : ''}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    {referral?.referredByInstitution && referral?.referredByInstitution?.guid &&
                                                                        <div className="reffering-physician-colS mb-1">
                                                                            <h3 className="nex-text-xl mb-3"> Referring Hospital / Clinic</h3>
                                                                            <div className="nex-stack">
                                                                                <QuickPractitioner
                                                                                    isTitleLink={true}
                                                                                    titleLink={`/institution/` + referral?.referredByInstitution?.profileId}
                                                                                    avatar={
                                                                                        referral?.referredByInstitution?.avatarId ?
                                                                                            <img
                                                                                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredByInstitution?.guid}/${referral?.referredByInstitution?.avatarId}`}
                                                                                                className="nex-avatar lg"
                                                                                                alt="#"
                                                                                            />
                                                                                            :
                                                                                            <>
                                                                                                <img
                                                                                                    src={createImageFromInitials(
                                                                                                        50,
                                                                                                        `${(referral?.referredByInstitution?.name)}`,
                                                                                                        getRandomColor(
                                                                                                            encrypt(
                                                                                                                referral?.referredByInstitution?.guid
                                                                                                            ).toString()
                                                                                                        )
                                                                                                    )}
                                                                                                    className="nex-avatar lg"
                                                                                                    alt="#"
                                                                                                />
                                                                                            </>
                                                                                    }
                                                                                    name={referral?.referredByInstitution?.name}
                                                                                    address={getFullAddressWithLocationCityAndState({ location: referral?.referredByInstitution?.address?.location ?? "", city: referral?.referredByInstitution?.address?.city ?? "", state: referral?.referredByInstitution?.address?.state ?? "" })}
                                                                                ></QuickPractitioner>
                                                                            </div>

                                                                            <div className="head d-none">
                                                                                {
                                                                                    referral?.referredByInstitution?.avatarId ?
                                                                                        <img
                                                                                            src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredByInstitution?.guid}/${referral?.referredByInstitution?.avatarId}`}
                                                                                            className="card-img"
                                                                                            alt="#"
                                                                                        />
                                                                                        :
                                                                                        <>
                                                                                            <img
                                                                                                src={createImageFromInitials(
                                                                                                    50,
                                                                                                    `${(referral?.referredByInstitution?.name)}`,
                                                                                                    getRandomColor(
                                                                                                        encrypt(
                                                                                                            referral?.referredByInstitution?.guid
                                                                                                        ).toString()
                                                                                                    )
                                                                                                )}
                                                                                                className="card-img"
                                                                                                alt="#"
                                                                                            />
                                                                                        </>
                                                                                }
                                                                                <div className="info">
                                                                                    <p className="card-text"><b>Name  </b><Link id="nex-view-prof-name5" to={`/institution/` + referral?.referredByInstitution?.profileId} >  {referral?.referredByInstitution?.name}</Link></p>
                                                                                    <p className="card-text">
                                                                                        <b>Address  </b> {getFullAddressWithLocationCityAndState({ location: referral?.referredByInstitution?.address?.location ?? "", city: referral?.referredByInstitution?.address?.city ?? "", state: referral?.referredByInstitution?.address?.state ?? "" })}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {!referral?.referredByInstitution && referral?.referredByInstitution?.guid &&
                                                                        <div className="col-lg-12 reffering-physician-colS mb-1">
                                                                            <h3 className="nex-text-xl mb-3"> Referring Hospital / Clinic</h3>
                                                                            <div className="head">
                                                                                <p className="nex-text">No Referring Hospital / Clinic Found</p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*<div className="row need-margin">
                                                    <div className="col-lg-12 reffering-physician-col mb-1 mt-3">
                                                        <h3 className=" card-title big">  Referring Physician</h3>
                                                        <div className="head">
                                                            {
                                                                referral?.referredByPhysician?.avatarId ?
                                                                    <img
                                                                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredByPhysician?.profileGuid}/${referral?.referredByPhysician?.avatarId}`}
                                                                        className="card-img"
                                                                        alt="#"
                                                                    />
                                                                    :
                                                                    <>
                                                                        <img
                                                                            src={createImageFromInitials(
                                                                                50,
                                                                                `${referral?.referredByPhysician?.name.replace("Dr. ", "")}`,
                                                                                getRandomColor(
                                                                                    encrypt(
                                                                                        referral?.referredByPhysician?.profileGuid
                                                                                    ).toString()
                                                                                )
                                                                            )}
                                                                            className="card-img"
                                                                            alt="#"
                                                                        />
                                                                    </>
                                                            }
                                                            <div className="info">
                                                                <p className="card-text"><b>Name  </b>  <Link to={`/profile/` + referral?.referredByPhysician?.profileId} >{referral?.referredByPhysician?.name}</Link></p>
                                                                <p className="card-text">
                                                                    <b>Address</b>
                                                                    {getFullAddressWithLocationCityAndState({ location: referral?.referredByPhysician?.location ?? "", city: referral?.referredByPhysician?.city ?? "", state: referral?.referredByPhysician?.state ?? "" })}
                                                                </p>
                                                                <p className="card-text"><b>Speciality  </b> {referral?.referredByPhysician?.primarySpeciality}{" ("}{referral?.referredByPhysician?.superSpeciality}{")"}</p>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>*/}
                                                </div>
                                            </Card>
                                        </div>
                                        <div className="position-relative mb-3">
                                            <Card className="nexogic-card-list-custom nexogic-reffer-card nex-list-card-v2 bg-white">
                                                <div className="card-body">
                                                    <div className="row need-margin"></div>
                                                    <div className="position-relative mb-3">
                                                        <Card className={`nexogic-card-color-header other-det-bg ${pannel_one_on ? 'expanded' : ''}`}>
                                                            <div className="card-header py-3 other-det-bg">
                                                                <h2>Referral Reason</h2>
                                                                <button className="collapse-btn" type="button" onClick={() => setPannel_one_on(!pannel_one_on)}>
                                                                    <img src={pannel_arrow} alt="" />
                                                                </button>
                                                            </div>
                                                            <div className="collapse-div other-det-bg bottom-border-radius">
                                                                <CardBody className="other-det-bg pl-5 pr-3 pb-3">
                                                                    {/* <div className="mb-1 f-14 about-text">{parse(referral?.referralReason)}</div> */}
                                                                    <div>
                                                                        {referral?.referralReason ?
                                                                            (<div dangerouslySetInnerHTML={{ __html: referral?.referralReason }}></div>)
                                                                            : (<div className="nexogic-not-found-text">Not Available</div>)
                                                                        }
                                                                    </div>

                                                                </CardBody>
                                                            </div>
                                                        </Card>
                                                        <Card className={`nexogic-card-color-header other-det-bg ${pannel_two_on ? 'expanded' : ''}`}>
                                                            <div className="card-header py-3 other-det-bg">
                                                                <h2>Diagnosis</h2>
                                                                <button className="collapse-btn" type="button" onClick={() => setPannel_two_on(!pannel_two_on)}>
                                                                    <img src={pannel_arrow} alt="" />
                                                                </button>
                                                            </div>
                                                            <div className="collapse-div other-det-bg bottom-border-radius">
                                                                <CardBody className="other-det-bg pl-5 pr-3 pb-3">

                                                                    {/* <div className="mb-1 f-14 about-text">{parse(referral?.diagnosis)}</div> */}
                                                                    <div>
                                                                        {referral?.diagnosis ?
                                                                            (<div dangerouslySetInnerHTML={{ __html: referral?.diagnosis }}></div>)
                                                                            :
                                                                            <div className="nexogic-not-found-text">Not Available</div>
                                                                        }
                                                                    </div>
                                                                </CardBody>
                                                            </div>
                                                        </Card>
                                                        <Card className={`nexogic-card-color-header other-det-bg ${pannel_three_on ? 'expanded' : ''}`}>
                                                            <div className="card-header py-3 other-det-bg">
                                                                <h2>Treatment Medications</h2>
                                                                <button className="collapse-btn" type="button" onClick={() => setPannel_three_on(!pannel_three_on)}>
                                                                    <img src={pannel_arrow} alt="" />
                                                                </button>
                                                            </div>
                                                            <div className="collapse-div other-det-bg bottom-border-radius">
                                                                <CardBody className="other-det-bg pl-5 pr-3 pb-3">
                                                                    {/* <div className="mb-1 f-14 about-text">{parse(referral?.treatmentMedications)}</div> */}
                                                                    <div>
                                                                        {referral?.treatmentMedications ?
                                                                            (<div dangerouslySetInnerHTML={{ __html: referral?.treatmentMedications }}></div>)
                                                                            :
                                                                            <div className="nexogic-not-found-text">Not Available</div>
                                                                        }
                                                                    </div>

                                                                </CardBody>
                                                            </div>
                                                        </Card>
                                                        <Card className={`nexogic-card-color-header other-det-bg ${pannel_four_on ? 'expanded' : ''}`}>
                                                            <div className="card-header py-3 other-det-bg">
                                                                <h2>Additional Information</h2>
                                                                <button className="collapse-btn" type="button" onClick={() => setPannel_four_on(!pannel_four_on)}>
                                                                    <img src={pannel_arrow} alt="" />
                                                                </button>
                                                            </div>
                                                            <div className="collapse-div other-det-bg bottom-border-radius">
                                                                <CardBody className="other-det-bg pl-5 pr-3 pb-3">
                                                                    <div>
                                                                        {referral?.additionalInformation ?
                                                                            (<div dangerouslySetInnerHTML={{ __html: referral?.additionalInformation }}></div>)
                                                                            :
                                                                            <div className="nexogic-not-found-text">Not Available</div>
                                                                        }
                                                                    </div>
                                                                </CardBody>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </div>
                                            </Card>
                                            {/* </div> */}
                                            {/* <div className="position-relative mb-3"> */}
                                            <Card className="nexogic-card-list-custom nexogic-reffer-card nex-list-card-v2 bg-white">
                                                <div className="card-body">
                                                    <div className="row need-margin"></div>
                                                    {assignmentHistory && assignmentHistory.length > 0 &&
                                                        <CollapseablePannel title="Assignment History" bgcolor="other-det-bg">
                                                            <div className="nex-assign-history px-5">
                                                                <Card className="nex-assign-history-item other-det-bg" style={{ fontWeight: "800" }}>
                                                                    <CardBody className="other-det-bg bg-dark rounded p-2">
                                                                        <div className="card-text">{"From"}</div>
                                                                        <div className="card-text">{"To"}</div>
                                                                        <div className="card-text">{"Last Modified Date"}</div>
                                                                    </CardBody>
                                                                </Card>
                                                                {assignmentHistory.map((e, i) => (
                                                                    <Card className="nex-assign-history-item other-det-bg" key={i}>
                                                                        <CardBody className="other-det-bg pl-5 pr-3 pb-3">
                                                                            <div className="nex-text-lg bold">
                                                                                <a href={`/profile/${e?.currentAssignee?.profileId}`} target="_blank">
                                                                                    {getFullNameWithPrimarySpecialityAndSuperSpeciality(e?.previousAssignee)}
                                                                                </a>
                                                                            </div>
                                                                            <div className="nex-text-lg bold">
                                                                                <a href={`/profile/${e?.currentAssignee?.profileId}`} target="_blank">
                                                                                    {getFullNameWithPrimarySpecialityAndSuperSpeciality(e?.currentAssignee)}
                                                                                </a>
                                                                            </div>
                                                                            <div className="nex-text text-black">{dateFormatterNew(e?.updatedOn)}</div>
                                                                        </CardBody>
                                                                    </Card>
                                                                ))}
                                                            </div>
                                                        </CollapseablePannel>
                                                    }
                                                </div>
                                                {/* </div> */}
                                            </Card>
                                        </div>
                                        {/** Appointment Request */}

                                        {/* <div className="position-relative mb-3">
                                <Card className={`nexogic-card-color-header ${pannel_two_on ? 'expanded' : ''}`}>
                                    <div className="card-header">
                                        <h2>Appointment Request</h2>
                                        <button className="collapse-btn" type="button" onClick={() => setPannel_two_on(!pannel_two_on)}>
                                            <img src={pannel_arrow} alt="" />
                                        </button>
                                    </div>
                                    <div className="collapse-div">
                                        <CardBody>
                                            <AppointmentReq values={values} />
                                        </CardBody>
                                    </div>
                                </Card>
                            </div> */}

                                        {/** Comunication Section */}
                                        {/* <div className="position-relative mb-3">
                                <Card className={`nexogic-card-color-header nexogic-comunication-box ${pannel_three_on ? 'expanded' : ''}`}>
                                    <div className="card-header">
                                        <h2>Comunication</h2>
                                        <button className="collapse-btn" type="button" onClick={() => setPannel_three_on(!pannel_three_on)}>
                                            <img src={pannel_arrow} alt="" />
                                        </button>
                                    </div>
                                    <div className="collapse-div">
                                        <CardBody>
                                            <div className="nexogic-floated-input">
                                                <textarea type="text" name="ref_comment" id="ref_comment" className='form-control nexogic-form-control-gray small' placeholder="Write your comment"></textarea>
                                                <button type="button" name="ref_comment_submit" id="ref_comment_submit" className='btn btn-trans'> <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="23"
                                                    height="22"
                                                    fill="none"
                                                    viewBox="0 0 23 22"
                                                >
                                                    <path
                                                        stroke="#3EABCA"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M21.252 11.797l-15.538-.738m15.538.738L1.746 20.073l3.968-9.014m15.538.738L2.618 1.709l3.096 9.35"
                                                    ></path>
                                                </svg></button>
                                            </div>
                                            <div className="nexogic-custom-scroll">

                                                <div className="nexogic-comment-lists">
                                                    <ul>
                                                        <li>
                                                            <div className="nexogic-comment-item">
                                                                <div className="graphic">
                                                                    <img src="https://images-dev.nexogic.com/53efb6fd-1ceb-412a-b19d-b65032bde722/a8dd998a-8e20-4ac4-971f-bf91154aa6fc.png" alt="" className='card-img' />
                                                                </div>
                                                                <div className="info">
                                                                    <div className="comment-text">
                                                                        <h3 className="card-title">Steve Jobs <span className='text-muted'>2h ago</span></h3>
                                                                        <p className='card-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum voluptates, earum, dolore architecto odio dolor ipsa saepe possimus, aliquam omnis facere aut praesentium. Labore cupiditate, doloribus reprehenderit placeat nihil doloremque impedit totam est esse explicabo!</p>
                                                                    </div>
                                                                    <p className='card-text'><a href="" className="link">Reply</a></p>
                                                                </div>
                                                            </div>
                                                            <ul>
                                                                <li>
                                                                    <div className="nexogic-comment-item">
                                                                        <div className="graphic">
                                                                            <img src="https://images-dev.nexogic.com/53efb6fd-1ceb-412a-b19d-b65032bde722/a8dd998a-8e20-4ac4-971f-bf91154aa6fc.png" alt="" className='card-img' />
                                                                        </div>
                                                                        <div className="info">
                                                                            <div className="comment-text">
                                                                                <h3 className="card-title">Steve Jobs <span className='text-muted'>2h ago</span></h3>
                                                                                <p className='card-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum voluptates, earum, dolore architecto odio dolor ipsa saepe possimus, aliquam omnis facere aut praesentium. Labore cupiditate, doloribus reprehenderit placeat nihil doloremque impedit totam est esse explicabo!</p>
                                                                            </div>
                                                                            <p className='card-text'><a href="" className="link">Reply</a></p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <div className="nexogic-comment-item">
                                                                <div className="graphic">
                                                                    <img src="https://images-dev.nexogic.com/53efb6fd-1ceb-412a-b19d-b65032bde722/a8dd998a-8e20-4ac4-971f-bf91154aa6fc.png" alt="" className='card-img' />
                                                                </div>
                                                                <div className="info">
                                                                    <div className="comment-text">
                                                                        <h3 className="card-title">Steve Jobs <span className='text-muted'>2h ago</span></h3>
                                                                        <p className='card-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum voluptates, earum, dolore architecto odio dolor ipsa saepe possimus, aliquam omnis facere aut praesentium. Labore cupiditate, doloribus reprehenderit placeat nihil doloremque impedit totam est esse explicabo!</p>
                                                                    </div>
                                                                    <p className='card-text'><a href="" className="link">Reply</a></p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </div>
                                </Card>
                            </div> */}

                                        {/** Why Request Rejected*/}
                                        {/* {
                                (values?.status === "Rejected") &&
                                (
                                    <>
                                        <div className="position-relative mb-3">
                                            <Card className="card nexogic-card-color-header expanded">
                                                <div className="card-header">
                                                    <h2>Reason for Rejection</h2>
                                                </div>
                                                <div className="collapse-div">
                                                    <CardBody>
                                                        <RejectionReason values={values} />
                                                    </CardBody>
                                                </div>
                                            </Card>
                                        </div>
                                    </>
                                )
                            } */}

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card >
                        {/* assign modal */}
                        < ReferAssignModal open={isAssignModal} setToggle={setIsAssignModal} onAssign={haldleAssign} referral={referral}
                            getAssignmentHistory={getAssignmentHistory}
                            getReferralById={getReferralById}
                        />
                    </>
                }
                {
                    dataLoading ?
                        <div className="" style={{ zIndex: "111", position: "absolute", top: "0", left: "0", backgroundColor: "#edeff0b0", width: "100%", height: "100%" }}>
                            <div className="component-wrapper"><CommonLoader /></div>
                        </div>
                        :
                        <Modal isOpen={modal} toggle={toggleForm} backdrop="static" scrollable centered >
                            <ModalHeader toggle={toggleForm}><span style={{ color: "gray" }}>Document Viewer</span></ModalHeader>
                            <ModalBody>
                                <div className="modalbody">
                                    <img src={documentData} alt="document preview" />
                                </div>
                                <div className="downloadbtn">
                                    <FontAwesomeIcon color="#0087b9" size="xl" style={{ cursor: "pointer" }} icon={faDownload} onClick={handleDownload} />
                                </div>
                            </ModalBody>
                        </Modal>
                }
            </Col>
            <Col className="nex-sidebar v2">
                <div className="">
                    <div className="nexogic-widget-sidebar-card card border-0" style={{ display: 'none' }}>
                        <div className="card-body">
                            <div className="nexogic-h-btn-groups">
                                {reqAssign === false && (
                                    <a
                                        href=""
                                        className="btn d-block nexogic_primary_button"
                                        onClick={(e) => openAssignModal(e)}
                                    >
                                        Assign
                                    </a>
                                )}

                                {reqAssign === true && (
                                    <Link
                                        to="/referals/treatment/"
                                        className="btn d-block nexogic_primary_button"
                                    >
                                        Provide  Consultation
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>

                    {/** Selected assign patitioner or groups */}
                    {reqAssign === true && (
                        <div className="nexogic-widget-sidebar-card card border-0 nexogic-widget-assign">
                            <h3 className="card-title h2">Currently Assign</h3>
                            <div className="card-body">
                                <div className="nexogic-assign-pratitioner">
                                    <div className="nexogic-practitioner-search-lists">
                                        <div className="item selected">
                                            <div className="graphic">
                                                <img src={profile_sample} alt="" className="avatar" />
                                            </div>
                                            <div className="info">
                                                <h3 className="card-title">Dr. Anamica Mitra (MBBs)</h3>
                                                <p className="card-text light">Amri hospital</p>
                                            </div>
                                            <span className="active-check">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="9"
                                                    fill="none"
                                                    viewBox="0 0 21 14"
                                                >
                                                    <path
                                                        stroke="#fff"
                                                        strokeWidth="2"
                                                        d="M1 4.781L7.88 12 20 1"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="actions">

                                    <a
                                        href=""
                                        className="btn d-block nexogic_primary_button_red"
                                        onClick={(e) => haldleUnassign(e)}
                                    >
                                        Unassign
                                    </a>

                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {(!(type === "SENT") && referral?.status !== 'CLOSED') &&
                    <Button color="primary" onClick={() => setIsAssignModal(true)} className="nex-btn-primary w-100 mb-3">Assign Referral</Button>
                }

                {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                    <Card className="nexogic-widget-sidebar-card document-sidebar-widget nex-app-block">
                        <div className="pt-3 px-3 d-flex flex-wrap mb-2 pb-2">

                            {type === "SENT"
                                ?
                                <Button color="primary" className="nex-btn-primary w-100"><SentReferralPreview id={referral?.id} /></Button>
                                :
                                <ReceivedReferralPreview id={referral?.id} />
                            }

                        </div>
                    </Card>
                }

                <Card className="nexogic-widget-sidebar-card document-sidebar-widget nex-app-block">
                    <h2 className="nex-aside-title">
                        Referral Documents
                        <span className="icon ml-auto"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 13H14M8 17H16M13 3H5V21H19V9M13 3H14L19 8V9M13 3V7C13 8 14 9 15 9H19" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg></span>
                    </h2>
                    {/* <h2 className="nex-aside-title bg-light-yellow">
                        Referral Documents <div className="icon ml-auto"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 13H14M8 17H16M13 3H5V21H19V9M13 3H14L19 8V9M13 3V7C13 8 14 9 15 9H19" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg></div>
                        {(type === "SENT") && (referral?.status != 'CLOSED' && referral?.status != 'REJECTED') &&
                            <>
                                {isFileUploaing ?
                                    <div className="text-center mb-5" style={{ height: "10px" }} >
                                        <CommonLoader />
                                    </div>
                                    :
                                    <button
                                        className="btn nexogic-download-btn nexogic_primary_button"
                                        onClick={handleUploadClick}
                                    >
                                        <span className="icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="none"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    stroke="#fff"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M15 10.333v3.111A1.556 1.556 0 0113.444 15H2.556A1.556 1.556 0 011 13.444v-3.11m10.889-5.445L8 1m0 0L4.111 4.889M8 1v9.333"
                                                ></path>
                                            </svg>
                                        </span>
                                        Uploads
                                    </button>
                                }
                                <input
                                    name="uploadDocument"
                                    type="file"
                                    id="uploadDocument"
                                    accept="image/jpeg,image/jpg,image/png,application/pdf"
                                    title="Upload Document"
                                    className="nexogic-file-upload-input"
                                    style={{ display: 'none' }}
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                />
                            </>
                        }
                    </h2> */}
                    <CardBody className="pt-2">
                        {(docsInfo && docsInfo.length > 0) ?
                            <div className="nexogic-custom-scroll">
                                <div className="nexogic-document-lists">
                                    <ul>
                                        {docsInfo.map((doc, index) => (
                                            <li key={index} >
                                                <div className="nexogic-document-item" style={{ cursor: "pointer" }} onClick={() => getReferralDoc(doc?.id)}>
                                                    <img width={28} src={getFileIcon(doc?.type)} style={{ cursor: "pointer" }} />
                                                    <div className="card-text">{doc?.name}</div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>

                                </div>
                            </div>
                            :
                            <div className="nexogic-not-found-text">
                                <div className="icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20 13V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18L4 13M16 8L12 4M12 4L8 8M12 4L12 16" stroke="#46ABC9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                No Documents
                            </div>
                        }
                        {(type === "SENT") && (referral?.status !== 'CLOSED' && referral?.status != 'REJECTED') &&
                            <>
                                <div className="action">
                                    {isFileUploaing ?
                                        <div className="text-center mb-5" style={{ height: "10px" }} >
                                            <CommonLoader />
                                        </div>
                                        :
                                        <button
                                            className="btn nexogic-download-btn nex-btn-primary"
                                            onClick={handleUploadClick}
                                        >
                                            <span className="icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="none"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        stroke="#fff"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M15 10.333v3.111A1.556 1.556 0 0113.444 15H2.556A1.556 1.556 0 011 13.444v-3.11m10.889-5.445L8 1m0 0L4.111 4.889M8 1v9.333"
                                                    ></path>
                                                </svg>
                                            </span>
                                            Uploads
                                        </button>
                                    }
                                    <input
                                        name="uploadDocument"
                                        type="file"
                                        id="uploadDocument"
                                        accept="image/jpeg,image/jpg,image/png,application/pdf"
                                        title="Upload Document"
                                        className="nexogic-file-upload-input"
                                        style={{ display: 'none' }}
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                    />
                                </div>
                            </>
                        }
                    </CardBody>
                </Card>
                <ReferWidget />
                {referral && referral?.specialityRequested &&
                    <PeopleKnowWidget speciality={referral?.specialityRequested} />
                }
            </Col>
        </Row>
    )
}
export default withAlert()(CompleteForm);