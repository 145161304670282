import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import DocumentUploaForm from "./DocumentUploaForm"

export const DocumentModal = (props) => {
  const { isOpen, toggle } = props;
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-scrollable modal-dialog-centered" backdrop="static">
        <ModalHeader toggle={toggle}>Document</ModalHeader>
        <ModalBody>
          <DocumentUploaForm {...props} />
        </ModalBody>
      </Modal>
    </div>
  );
}




