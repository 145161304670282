import AddressTypeaheadField from "components/forms/formikFields/AddressTypeaheadField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import LocationTypeaheadField from "components/forms/formikFields/LocationTypeaheadField";
import { Field } from "formik";
import { memo, useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-toastify/dist/ReactToastify.css";
import { Col, FormFeedback, InputGroup, Label, Row } from "reactstrap";
import { AwardsTypesList } from "utils/Constants";
import { MetadataService } from "utils/MetadataService";

const NewInstituitionSection = (props) => {
  const { values, setFieldValue, errors, touched } = props;
  const [countryList, setCountyList] = useState([]);
  const [statesList, setSatesList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [displayType, setDisplayType] = useState("");
  const stateRef = useRef();
  const cityRef = useRef();
  const countryRef = useRef();
  const locRef = useRef();

  const getCountries = () => {
    setSatesList([]);
    setCityList([]);
    MetadataService.getCountry()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setCountyList(response.data);
        }
      })
      .catch((error) => {
        console.log("AwardForm.getCountries error", error);
      });
  };

  const getStates = (countryId) => {
    setCityList([]);
    MetadataService.getStates(countryId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setSatesList(response.data);
        }
      })
      .catch((error) => {
        console.log("AwardForm.getStates error", error);
      });
  };

  const getCities = (stateId) => {
    MetadataService.getCities(stateId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setCityList(response.data);
        }
      })
      .catch((error) => {
        console.log("AwardForm.getCities error", error);
      });
  };

  const getLocations = (cityId) => {
    MetadataService.getLocations(cityId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setLocationList(response.data);
        }
      })
      .catch((error) => {
        console.log("AwardForm.getCities error", error);
      });
  };

  useEffect(() => {
    if (values?.mainCategory === "FELLOWSHIP") {
      getCountries();
    }
    setDisplayType(values?.mainCategory ?? "");
  }, [values]);

  return (
    <>
      {displayType === "FELLOWSHIP" && (
        <>
          <Col sm={12}>
            <Row>
              <Col md={6}>
                <Label>Institution Name</Label>
                <Field
                  name={`mainItemName`}
                  id={`mainItemName`}
                  autocomplete={`mainItemName`}
                  type="text"
                  component={FormikInputField}
                  placeholder="Institution Name"
                />
              </Col>
              <Col md={6}>
                <Label>Category</Label>
                <Field
                  id={`category`}
                  name={`category`}
                  autocomplete={`category`}
                  component={FormikSelectField}
                  inputprops={{
                    options: AwardsTypesList,
                    defaultOption: "Choose",
                    keys: {
                      id: "id",
                      label: "label",
                    },
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <InputGroup size="medium" className="code-phone-ig">
                  <Label>Phone Number</Label>
                  <PhoneInput
                    country={"in"}
                    onlyCountries={["in"]}
                    inputClass="phonenumber-form-control"
                    containerClass="mb-2"
                    inputProps={{
                      name: `phone`,
                    }}
                    onChange={(value, country, e, formattedValue) =>
                      setFieldValue(`phone`, formattedValue)
                    }
                    inputStyle={{
                      width: "100%",
                    }}
                    autocomplete="phone"
                  />
                  {!!touched?.phone &&
                    !!errors?.phone &&
                    typeof errors.phone === "string" && (
                      <FormFeedback className="d-flex" invalid={!!errors.phone}>
                        {errors.phone}
                      </FormFeedback>
                    )}
                </InputGroup>
              </Col>

              <Col lg={6}>
                <Label>Website Address</Label>
                <Field
                  name={`websiteAddress`}
                  id="websiteAddress"
                  autocomplete="websiteAddress"
                  type="text"
                  component={FormikInputField}
                  placeholder="E.g https://www.google.com/"
                />
              </Col>
            </Row>
            <fieldset className="u-flsd-wpr mb-3">
              <legend>Mailing Address</legend>

              <Row>
                <Col lg={12}>
                  <Label>Street Address </Label>
                  <Field
                    name={`addressLine1`}
                    id="addressLine1"
                    autocomplete="addressLine1"
                    type="text"
                    component={FormikInputField}
                    placeholder="Street Address"
                  />
                </Col>
                <Col lg={12}>
                  <Label>Street Address2</Label>
                  <Field
                    name={`addressLine2`}
                    id="addressLine2"
                    autocomplete="addressLine2"
                    type="text"
                    component={FormikInputField}
                    placeholder="Street Address2"
                  />
                </Col>

                <Col lg={6}>
                  <div className="form-group">
                    <Label>Country</Label>
                    <Field
                      name={`country`}
                      id={`country`}
                      component={AddressTypeaheadField}
                      placeholder="Select country"
                      spellCheck={false}
                      inputList={countryList}
                      value={values.country}
                      getAddressById={(contryId) => {
                        getStates(contryId);
                        setFieldValue(`state`, []);
                        setFieldValue(`city`, []);
                        setFieldValue(`location`, []);
                      }}
                      locationRef={countryRef}
                    />
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="form-group">
                    <Label>State</Label>
                    <Field
                      name={`state`}
                      id={`state`}
                      component={AddressTypeaheadField}
                      placeholder="Select state"
                      spellCheck={false}
                      inputList={statesList}
                      value={values.state}
                      getAddressById={(stateId) => {
                        getCities(stateId);
                        setFieldValue(`city`, []);
                        setFieldValue(`location`, []);
                      }}
                      locationRef={stateRef}
                    />
                  </div>
                </Col>

                <Col lg={4}>
                  <div className="form-group">
                    <Label>City</Label>
                    <Field
                      name={`city`}
                      id={`city`}
                      component={AddressTypeaheadField}
                      placeholder="Select city"
                      spellCheck={false}
                      inputList={cityList}
                      value={values.city}
                      getAddressById={(cityId) => {
                        getLocations(cityId);
                      }}
                      locationRef={cityRef}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <Label>Zipcode </Label>
                  <Field
                    name={`postalCode`}
                    id={`postalCode`}
                    autocomplete={`postalCode`}
                    type="text"
                    component={FormikInputField}
                    placeholder="Zipcode"
                  />
                </Col>
                <Col lg={4}>
                  <Label>Location</Label>
                  <Field
                    id={`location`}
                    name={`location`}
                    type="text"
                    component={LocationTypeaheadField}
                    value={values.location}
                    placeholder="Select Location"
                    spellCheck={false}
                    locations={locationList}
                    selectdLocation={(input) =>
                      setFieldValue(`location`, input)
                    }
                    locationRef={locRef}
                  />
                </Col>
              </Row>
            </fieldset>
          </Col>
        </>
      )}
      {(displayType === "AWARD_AND_RECOGNITION" ||
        displayType === "HONORS") && (
        <>
          <Col md={12}>
            <Label>Institution Name</Label>
            <Field
              name={`mainItemName`}
              id={`mainItemName`}
              autocomplete={`mainItemName`}
              type="text"
              component={FormikInputField}
              placeholder="Institution Name"
            />
          </Col>
        </>
      )}
    </>
  );
};

export default memo(NewInstituitionSection);
