import jwtDecode from "jwt-decode";
import { logoutUser } from "redux-helpers/ActionCreator";
import { reset_feed_data } from "redux-helpers/FeedActionCreator";
import { Store } from "redux-helpers/Store";
import secureLocalStorage from 'react-secure-storage';
import API from "./API";
import API_New from "./API_New";
import { setToken, setUser } from "./UserHelper";

const currentUser = () =>
  JSON.parse(secureLocalStorage.getItem("nexogic.user.pract_detdesc"));
const token = () => secureLocalStorage.getItem("nexogic.token");

const verifyToken = () => {
  const authToken = secureLocalStorage.getItem("nexogic.token");
  try {
    jwtDecode(authToken);
  } catch (err) {
    logout();
    return false;
  }
  return authToken;
};

const login = (loginPayload) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API.post("login", loginPayload);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });  
};

const loginNew = (loginPayload) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.post(
        "practitioner-service/practitioners/login",
        loginPayload
      );
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};
const logout = () => {
  const { store } = Store;
  store.dispatch(logoutUser());
  store.dispatch(reset_feed_data());
  secureLocalStorage.removeItem("nexogic.token");
  secureLocalStorage.removeItem("nexogic.refreshToken");
  secureLocalStorage.removeItem("nexogic.user.pract_detdesc");
  secureLocalStorage.removeItem("nexogic.user.oauth");
  secureLocalStorage.clear();

};
const register = async (data) => {
  try {
    await API.post("/practitioners/registration", data);
  } catch (e) {}
};

const getToken = async ({
  type = "practitioner",
  socialPlatform = "google",
  email,
}) => {
  try {
    const response = await API.get("/token", {
      params: {
        type,
        socialPlatform,
        email,
      },
    });

    if (response.data && response.data.status === "SUCCESS") {
      const { data } = response.data;
      let decoded;
      try {
        decoded = jwtDecode(data.jwt);
        const {
          email: { emailId },
          name,
          id,
        } = decoded;
        setUser({ email: emailId, name, id });
        setToken(data.jwt);
      } catch (err) {
        return false;
      }
    }
    return response;
  } catch (e) {
    return e;
  }
};

const getCurrentUserProfile = async (id) => {
  try {
    const response = await API.get("/practitioners/" + id);
    if (response.data && response.data.status === "SUCCESS") {
      const { data } = response.data;
      if (data && data.practitioner) {
        if (currentUser() && id === currentUser().practitionerId) {
          setUser(data.practitioner);
        }
      }
    }
    return response;
  } catch (e) {}
};

const getReviews = async (id) => {
  try {
    const response = await API.get("/practitioners/" + id + "/reviews/");
    return response;
  } catch (e) {}
};

const getSignUrl = async (file) => {
  try {
    //const response = await API.get("/signed-url?" + "uploadPath=profile-pics/" + file.name + "&action=upload");
    const response = await API.get(
      `/signed-url?uploadPath=profile-pics/${file.name}&action=upload`
    );
    return response;
  } catch (e) {}
};

const getImageUrl = async (file) => {
  try {
    //const response = await API.get("/signed-url?" + "uploadPath=profile-pics/" + file.name + "&action=download");
    const response = await API.get(
      `/signed-url?uploadPath=profile-pics/${file.name}&action=download`
    );
    return response;
  } catch (e) {}
};

const createPassword = async (payload) => {
  try {
    const response = await API({
      baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
      url: "practitioner-service/create-password/login",
      method: "POST",
      responseType: "application/json",
      data: payload,
    });
    if (response) {
      const responseData = response.data;
      if (responseData && responseData.status === "SUCCESS") {
        const { data } = response.data;
        const { jwt, practitioner } = data;

        // temprary backend fix
        practitioner.id = !!practitioner.id
          ? practitioner.id
          : practitioner.practitionerId;

        if (jwt) {
          setToken(data.jwt);
        } else {
          console.warn("Token not found.");
        }
        if (practitioner) {
          setUser(practitioner);
        } else {
          console.warn("User details not found.");
        }
        return response;
      } else {
        return response;
      }
    } else {
      console.warn("Create password response is corrupt.");
      return response;
    }
  } catch (e) {
    return e;
  }
};

const sendOTPEmail = (email) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `communication/email/send-otp/`,
        method: "POST",
        responseType: "application/json",
        data: {
          email,
          entityType: "practitioner",
        },
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const verifyOTPEmail = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `communication/email/verify-otp/`,
        method: "POST",
        responseType: "application/json",
        data: payload,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const sendOTP = (phoneNumber) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.post(
        `communication/mobile/send-otp/${phoneNumber}`
      );
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const verifyOTP = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `communication/mobile/verify-otp/`,
        method: "POST",
        responseType: "application/json",
        data: payload,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const forgotPasswordRequest = (email) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.get(`practitioner-service/verify-email/${email}`);
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const convertGuidIntoUserId = (guid) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.get(
        `practitioner-service/practitioners/user/${guid}`
      );
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

export const UserService = {
  login,
  loginNew,
  logout,
  currentUser,
  token,
  register,
  getToken,
  verifyToken,
  getCurrentUserProfile,
  getReviews,
  getSignUrl,
  getImageUrl,
  createPassword,
  sendOTPEmail,
  verifyOTPEmail,
  sendOTP,
  verifyOTP,
  forgotPasswordRequest,
  convertGuidIntoUserId,
};
