import React from 'react'
import {useDropzone} from 'react-dropzone';

export default function DropAttachment(props) {
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        onDrop: (acceptedFiles)=>{
            props.onFileDrop(acceptedFiles[0]);
        }
    });

    return (
        <>
        <div {...getRootProps({className: 'dropzone nexogic-upload-card-v2'})}>
            <div className="icon"><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.1663 22.3335V7.39183L8.39967 12.1585L5.83301 9.50016L14.9997 0.333496L24.1663 9.50016L21.5997 12.1585L16.833 7.39183V22.3335H13.1663ZM0.333008 29.6668V20.5002H3.99967V26.0002H25.9997V20.5002H29.6663V29.6668H0.333008Z" fill="black" />
            </svg>
            </div>
            <input {...getInputProps()} />
            <p className='card-text'>Drag & Drop or <a href='' onClick={(e)=>e.preventDefault()}>Choose file</a> to upload</p>
            <p className='card-text'>PDF, PNG, JPG</p>
            <p className='card-text'>50mb file limit</p>
        </div>
        </>
    )
}
