import React from 'react'
import ReportDropsBtn from './ReportDropsBtn'
import pdf_icon from 'assets/images/svgs/pdf-icon.svg'
import sample_record_img from 'assets/images/record_sample_img.png'
import sample_download_img from 'assets/images/doctor-w-stetho.jpg'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useState } from 'react'
export default function MedicalPrescriptions() {
    const [previewModal, setPreviewModal] = useState(false);
    const [previewFileExt, setPreviewFileExt] = useState(null);
    const [previewFileUrl, setPreviewFileUrl] = useState(null);


    function preview_modal(url){
        const extension = url.split(".")[url.split(".").length - 1];
        console.log(extension);
        const isImage = new RegExp("(jpeg|jpg|gif|png)", 'i').test(extension);
        const isPdf = new RegExp("pdf", 'i').test(extension);
        console.log(isImage);
        console.log(isPdf);
        if (isImage) {
            setPreviewFileExt('image');
        }else if (isPdf) {
            setPreviewFileExt('pdf');
        }else{
            setPreviewFileExt(null);
        }

        setPreviewFileUrl(url);
        setPreviewModal(true);
    }

    function download(file_url){
        const extension = file_url.split(".")[file_url.split(".").length - 1];
        const link = document.createElement("a");
        link.setAttribute('href', file_url);
        link.setAttribute('download', "download."+extension);
        link.setAttribute('target', "_blank");
        link.style.display = 'none';
        document.body.appendChild(link);
        document.body.removeChild(link);
        link.click();
    }




  return (
    <>
    {/* visible modal */}
    <Modal isOpen={previewModal} toggle={()=>setPreviewModal(!previewModal)}>
        <ModalHeader toggle={()=>setPreviewModal(!previewModal)}>Preview </ModalHeader>
        <ModalBody>
            {previewFileExt === 'pdf' && 
                <object data={previewFileUrl} width="100%" height="500"> </object>
            }

            {previewFileExt === 'image' && 
                <img src={previewFileUrl} alt="" className="card-img" />
            }
        
        </ModalBody>
        
      </Modal>
    <div>
        <div className='nexogic-file-lists'>
                <div className="row item">
                
                <ReportDropsBtn download={()=>download('https://www.africau.edu/images/default/sample.pdf')} />
                    <div className="col-lg-6 file-name">
                        <div className="card-graphic">
                            <img src={pdf_icon} alt="" className="card-img" />
                        </div>
                        <p className='card-title' onClick={()=>preview_modal('https://www.africau.edu/images/default/sample.pdf')}>Blood report.pdf</p>
                    </div>
                    <div className="col-lg-3 file-date">
                        <p className="card-text">Feb 21, 2021</p>
                    </div>
                    <div className="col-lg-3 file-meta">
                        <p className="card-text">Dr. Anna Sotelo </p>
                    </div>
                </div>
                <div className="row item">
                <ReportDropsBtn download={()=>download(sample_download_img)} />
                    <div className="col-lg-6 file-name">
                        <div className="card-graphic">
                            <img src={sample_record_img} alt="" className="card-img img" />
                        </div>
                        <p className='card-title' onClick={()=>preview_modal(sample_download_img)}>Lorem ipsum dolor sit amet, consectetur sadsa d asdsa dsa d.pdf.jpg</p>
                    </div>
                    <div className="col-lg-3 file-date">
                        <p className="card-text">Feb 21, 2021</p>
                    </div>
                    <div className="col-lg-3 file-meta">
                        <p className="card-text">Dr. Anna Sotelo </p>
                    </div>
                </div>
                <div className="row item">
                    <ReportDropsBtn download={()=>download('https://www.africau.edu/images/default/sample.pdf')} />
                    <div className="col-lg-6 file-name">
                        <div className="card-graphic">
                            <img src={pdf_icon} alt="" className="card-img" />
                        </div>
                        <p className='card-title' onClick={()=>preview_modal('https://www.africau.edu/images/default/sample.pdf')}>Blood report.pdf</p>
                    </div>
                    <div className="col-lg-3 file-date">
                        <p className="card-text">Feb 21, 2021</p>
                    </div>
                    <div className="col-lg-3 file-meta">
                        <p className="card-text">Dr. Anna Sotelo </p>
                    </div>
                </div>
            </div>
    </div>
    </>
  )
}
