import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Field, Form, Formik } from "formik";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import { emailNonNumericRegEx } from "utils/Constants";
import * as Yup from "yup";
import { Button, InputGroup } from "reactstrap";
import logo_white from "assets/images/logo-white-transparent.png";
import arrow_left from "assets/images/arrowleft.png";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "firebaseconfig";
import { Link } from "react-router-dom";
// import { auth } from './firebase'; 

const validationSchema = Yup.object().shape({
    fpemail: Yup.string()
        .max(100)
        .matches(emailNonNumericRegEx, "Please enter a valid Email")
        .required("Please enter a valid Email"),
});

const ForgotPassword = () => {
    const [isSubmitting, setSubmitting] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const history = useHistory();
    const initValues = useMemo(() => {
        return {
            fpemail: "",
        };
    }, []);

    const [initialValues, setInitialValues] = useState(initValues);

    const handleSendResetEmail = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            const sentFlag = await sendPasswordResetEmail(auth, values.fpemail);
            setSuccessMsg(true);
            // history.push("/resent-link-message");
        } catch (error) {
            console.error("Error sending password reset email:", error);
            // Optionally, you can set an error state to display a message to the user
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div className="nex-bg-light-blue">
            <main className="nex-login-register--w">
                <div className="container sm d-flex" data-aos="fade-up">
                    <div className="nex-form-aside">
                        <div className="logo" data-aos="fade-up" data-aos-duration="800" data-aos-delay="100">
                            <a href="/"><img src={logo_white} alt="" /></a>
                        </div>
                        <h2 className="nex-text-xxl" data-aos="fade-up" data-aos-duration="800" data-aos-delay="150">Password Assistance</h2>
                    </div>
                    <div className="nex-form-fields--w">
                        <div className="nex-form-top-slot">
                            <div className="nex-form-top-actions nex-text-lg" data-aos="fade-down" data-aos-duration="800" data-aos-delay="200">
                                <img src={arrow_left} width="21" className="cursor-pointer" onClick={() => history.goBack()} />
                            </div>
                        </div>
                        {!successMsg ?
                        <>
                      
                        <div className="nex-form-container"> 
                        <div className="form-group-fields--w py-5">
                        <p className="nex-text-lg text-black text-center" data-aos="fade-down" data-aos-duration="800" data-aos-delay="250">Enter your email to recover your password. You will receive an email with instructions. If you are having problems recovering your password <a href="https://nexogic.com/contact-us/">contact</a> us</p>
                        </div>                           
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSendResetEmail}
                            >
                                {({ handleSubmit, isSubmitting, errors }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <div className="nexogic-phone-field-wrap">
                                            <InputGroup className="mt-3" size="large">
                                                <Field
                                                    id="fpemail"
                                                    name="fpemail"
                                                    type="email"
                                                    component={FormikInputField}
                                                    placeholder="Enter your registered Email "
                                                />
                                            </InputGroup>
                                        </div>
                                        <Button id="btn_reset" size="lg" block className="btn btn-primary-outline w-100" type="submit" disabled={isSubmitting}>Continue</Button>
                                        {/* <Button id="btn_login_in" size="lg" block className="btn btn-primary w-100" type="button" onClick={() => history.push("/login")} disabled={isSubmitting}>Login</Button> */}
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        </>
                        :
                        <div className="nex-form-center-slot">
                            <h2 className="nex-text-xxl text-center" data-aos="fade-up" data-aos-duration="800" data-aos-delay="150">Password Reset Email Sent</h2>
                            <p className="nex-text-lg text-center py-5" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
                                A password reset link has been sent to your email. Please check your inbox and follow the instructions to reset your password.
                            </p>
                            <Button size="lg" block className="btn btn-primary-outline w-100" onClick={() => history.push("/login")} data-aos="fade-up" data-aos-duration="800" data-aos-delay="250">Back to Login</Button>
                        </div>}
                        <div className="nex-form-bottom-slot" >
                            <p className="nex-text-lg text-center">
                                Not a member yet?{' '}
                                <Link to="/register" className="nex-link-dark"> Register </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default ForgotPassword;