import API_Factory from "./API_Factory";

const addReferral = (payload) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/referral-service/referrals`,
                method: "POST",
                responseType: "application/json",
                data: payload,
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const getReferrals = (type, sq = "", pageNo = 0, iids = "") => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/referral-service/referrals?iids=${iids}&cp=${pageNo}&so=DESC&referralsType=${type}&sq=${sq}`,
                method: "GET",
                responseType: "application/json",
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const getMyReferrals = (pageNo = 0) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/referral-service/my-referrals?cp=${pageNo}&so=DESC`,
                method: "GET",
                responseType: "application/json",
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const getReferralsCount = (type, iids) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/referral-service/referrals/count?referralsType=${type}&iids=${iids}`,
                method: "GET",
                responseType: "application/json",
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const getReferralById = (id, referralsType = "SENT") => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/referral-service/referrals/${id}?referralsType=${referralsType}`,
                method: "GET",
                responseType: "application/json",
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const getMyReferralById = (id) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/referral-service/my-referrals/${id}`,
                method: "GET",
                responseType: "application/json",
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};



const updateReferral = (id, payload, type = "SENT") => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/referral-service/referrals/${id}?referralsType=${type}`,
                method: "PUT",
                responseType: "application/json",
                data: payload,
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const updateReferralStatus = (id, referralsType, payload) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/referral-service/referrals/${id}/status?referralsType=${referralsType}`,
                method: "PUT",
                responseType: "application/json",
                data: payload,
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const getAssignmentHistory = (id) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/referral-service/referrals/${id}/assignment-history`,
                method: "GET",
                responseType: "application/json",
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

export const ReferralService = {
    addReferral,
    getReferrals,
    getMyReferrals,
    getReferralById,
    getMyReferralById,
    updateReferral,
    getReferralsCount,
    updateReferralStatus,
    getAssignmentHistory
};
