import React from 'react';
import Notifications from "../../Notifications";
const ResponsiveNotification = () => {
    return (
        <div className="header-icon notification-icon position-relative">
            <Notifications />
        </div>
    );
};

export default ResponsiveNotification;