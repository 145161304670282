import { Field, Formik } from "formik";
import { useMemo, useState, useEffect, useRef, Fragment } from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import * as Yup from "yup";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { getLocationName } from "utils/Utils";
import { InstitutionServices } from "utils/InstitutionServices";
import { MetadataService } from "utils/MetadataService";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import AddressTypeaheadField from "components/forms/formikFields/AddressTypeaheadField";
import { AllInstitutionTypesList, FAILTOSAVERECOED } from "utils/Constants";
import LocationTypeaheadField from "components/forms/formikFields/LocationTypeaheadField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import { withAlert } from "react-alert";

const validationSchema = Yup.object().shape({
    category: Yup.string().required("This field is required"),
    name: Yup.string().required("This field is required"),
    streetAddress1: Yup.string().required("This field is required"),
    country: Yup.array().required("This field is required"),
    state: Yup.array().required("This field is required"),
    city: Yup.array().required("This field is required"),
    zipcode: Yup.string().required("This field is required"),
    websiteAddress: Yup.string()
        .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Enter correct url!'
        )
        .required('This field is required')
});

const InstitutionForm = (props) => {
    const { alert, toggle } = props;
    const initValues = useMemo(() => {
        return {
            category: '',
            name: '',
            abbreviation: '',
            heading: '',
            streetAddress1: '',
            streetAddress2: '',
            country: '',
            state: '',
            city: '',
            zipcode: '',
            location: '',
            websiteAddress: ''

        };
    }, []);
    const [initialValues, setInitialValues] = useState(initValues);
    const onSubmit = (values) => {
        const payload = {
            type: values.category,
            name: values.name,
            abbreviation: values.abbreviation,
            heading: values.heading,
            address: {
                addressLine1: values.streetAddress1,
                addressLine2: values.streetAddress2,
                country: values.country[0]?.value,
                state: values.state[0]?.value,
                city: values.city[0]?.value,
                postalCode: values.zipcode,
                location: getLocationName(values.location)
            },
            websiteURL: values.websiteAddress
        }

        InstitutionServices.addInstitution(payload)
            .then((response) => {
                const { status } = response;
                if (status === 201) {
                    toggle();
                    showSuccessMessage("Institution created Successfully...");
                }
                else {
                    showErrorMessage(FAILTOSAVERECOED);
                }
            })
    }

    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={onSubmit}
                children={(props) => {
                    return (
                        <>
                            <RenderForm toggle={toggle} {...props} />
                        </>
                    );
                }}
            />
        </div>
    )
}

const RenderForm = (props) => {
    const [countryList, setCountyList] = useState([]);
    const [statesList, setSatesList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const stateRef = useRef();
    const cityRef = useRef();
    const countryRef = useRef();
    const locRef = useRef();
    const {
        values,
        handleSubmit,
        setFieldValue,
        isLoading,
        toggle
    } = props;
    const getCountries = () => {
        setSatesList([]);
        setCityList([]);
        MetadataService.getCountry()
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setCountyList(response.data);
                }
            })
            .catch((error) => {
                console.log("IdentityService.getCountries error", error);
            });
    };

    const getStates = (countryId) => {
        // console.log(countryId);
        setCityList([]);
        MetadataService.getStates(countryId)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setSatesList(response.data);
                }
            })
            .catch((error) => {
                console.log("IdentityService.getStates error", error);
            });
    };

    const getCities = (stateId) => {
        MetadataService.getCities(stateId)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setCityList(response.data);
                }
            })
            .catch((error) => {
                console.log("IdentityService.getCities error", error);
            });
    };

    const getLocations = (cityId) => {
        MetadataService.getLocations(cityId)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setLocationList(response.data);
                }
            })
            .catch((error) => {
                console.log("IdentityService.getCities error", error);
            });
    };

    useEffect(() => {
        getCountries();
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <Fragment>
                <fieldset className="u-flsd-wpr mb-3">
                    <legend>Instituation Information</legend>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className="form-group">
                                <Field
                                    id="category"
                                    name="category"
                                    label="Type"
                                    type="text"
                                    component={FormikSelectField}
                                    placeholder="Select Category"
                                    // autoComplete="off"
                                    // value={values.category}
                                    spellCheck={false}
                                    inputprops={{
                                        options: AllInstitutionTypesList,
                                        defaultOption: "Choose",
                                        keys: {
                                            id: "id",
                                            label: "label",
                                        },
                                    }}

                                // specialit={primarySpecialityList}
                                // disabled={!iseditable}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className="form-group">
                                <Field
                                    id="name"
                                    name="name"
                                    label="Name"
                                    // className="name"
                                    type="text"
                                    component={FormikInputField}
                                    placeholder="Enter Name"
                                    autoComplete="off"
                                    value={values.name}
                                    spellCheck={false}
                                // specialit={primarySpecialityList}
                                // disabled={!iseditable}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className="form-group">
                                <Field
                                    id="abbreviation"
                                    name="abbreviation"
                                    label="Abbreviation"
                                    // className="abbreviation"
                                    type="text"
                                    component={FormikInputField}
                                    placeholder="Enter Abbreviation"
                                    autoComplete="off"
                                    value={values.abbreviation}
                                    spellCheck={false}
                                // specialit={primarySpecialityList}
                                // disabled={!iseditable}
                                />
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className="form-group">
                                <Field
                                    id="heading"
                                    name="heading"
                                    label="Heading"
                                    // className="heading"
                                    type="text"
                                    component={FormikInputField}
                                    placeholder="Enter Heading"
                                    autoComplete="off"
                                    value={values.heading}
                                    spellCheck={false}
                                // specialit={primarySpecialityList}
                                // disabled={!iseditable}
                                />
                            </div>
                        </Col>
                    </Row>
                </fieldset>
                <fieldset className="u-flsd-wpr mb-3">
                    <legend>Mailing Address</legend>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className="form-group">
                                <Field
                                    id="streetAddress1"
                                    name="streetAddress1"
                                    label="Street Address 1"
                                    className="streetAddress1"
                                    type="text"
                                    component={FormikInputField}
                                    placeholder="Enter Street Address 1"
                                    autoComplete="off"
                                    value={values.streetAddress1}
                                    spellCheck={false}
                                // specialit={primarySpecialityList}
                                // disabled={!iseditable}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className="form-group">
                                <Field
                                    id="streetAddress2"
                                    name="streetAddress2"
                                    label="Street Address 2"
                                    className="streetAddress2"
                                    type="text"
                                    component={FormikInputField}
                                    placeholder="Enter Street Address 2"
                                    autoComplete="off"
                                    value={values.streetAddress2}
                                    spellCheck={false}
                                // specialit={primarySpecialityList}
                                // disabled={!iseditable}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} md={4} sm={12}>
                            <div className="form-group">
                                <Field
                                    id="country"
                                    name="country"
                                    label="Country"
                                    className="country"
                                    type="text"
                                    component={AddressTypeaheadField}
                                    inputList={countryList}
                                    placeholder="Select Country"
                                    autoComplete="off"
                                    value={values.country.value}
                                    spellCheck={false}
                                    getAddressById={(contryId) => {
                                        getStates(contryId);
                                        setFieldValue(`state`, []);
                                        setFieldValue(`city`, []);
                                        setFieldValue(`location`, []);
                                    }}
                                    locationRef={countryRef}
                                // specialit={primarySpecialityList}
                                // disabled={!iseditable}
                                />
                            </div>
                        </Col>

                        <Col lg={4} md={4} sm={12}>
                            <div className="form-group">
                                <Field
                                    id="state"
                                    name="state"
                                    label="State"
                                    className="state"
                                    type="text"
                                    component={AddressTypeaheadField}
                                    inputList={statesList}
                                    placeholder="Select State"
                                    autoComplete="off"
                                    value={values.state}
                                    spellCheck={false}
                                    getAddressById={(stateId) => {
                                        getCities(stateId);
                                        setFieldValue(`city`, []);
                                        setFieldValue(`location`, []);
                                    }}
                                    locationRef={stateRef}
                                // specialit={primarySpecialityList}
                                // disabled={!iseditable}
                                />
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={12}>
                            <div className="form-group">
                                <Field
                                    id="city"
                                    name="city"
                                    label="City"
                                    className="city"
                                    type="text"
                                    component={AddressTypeaheadField}
                                    inputList={cityList}
                                    placeholder="Select City"
                                    autoComplete="off"
                                    value={values.city}
                                    spellCheck={false}
                                    getAddressById={(cityId) => {
                                        getLocations(cityId);
                                    }}
                                    locationRef={cityRef}
                                // specialit={primarySpecialityList}
                                // disabled={!iseditable}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className="form-group">
                                <Field
                                    id="zipcode"
                                    name="zipcode"
                                    label="Zipcode"
                                    className="zipcode"
                                    type="text"
                                    component={FormikInputField}
                                    placeholder="Enter Zipcode"
                                    autoComplete="off"
                                    value={values.zipcode}
                                    spellCheck={false}
                                // specialit={primarySpecialityList}
                                // disabled={!iseditable}
                                />
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className="form-group">
                                <Field
                                    id="location"
                                    name="location"
                                    label="Location"
                                    className="location"
                                    type="text"
                                    component={LocationTypeaheadField}
                                    value={values?.location}
                                    locations={locationList}
                                    selectdLocation={(input) => setFieldValue(`location`, input)}
                                    locationRef={locRef}
                                    placeholder="Select Location"
                                    autoComplete="off"
                                    spellCheck={false}
                                // specialit={primarySpecialityList}
                                // disabled={!iseditable}
                                />
                            </div>
                        </Col>
                    </Row>
                </fieldset>
                <fieldset className="u-flsd-wpr mb-3">
                    <legend>Contact Information</legend>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className="form-group">
                                <Field
                                    id="websiteAddress"
                                    name="websiteAddress"
                                    label="Website Address"
                                    className="websiteAddress"
                                    type="text"
                                    component={FormikInputField}
                                    placeholder="Enter Website Address"
                                    autoComplete="off"
                                    value={values.websiteAddress}
                                    spellCheck={false}
                                // specialit={primarySpecialityList}
                                // disabled={!iseditable}
                                />
                            </div>
                        </Col>
                    </Row>
                </fieldset>
            </Fragment>
            <Row className="modal-save-main">
                <Col lg={12} md={12} sm={12} className="text-center">
                    {isLoading ? (
                        <div className="loading-spiner">
                            <Spinner style={{ width: "2rem", height: "2rem" }} />
                        </div>
                    ) : (
                        <>
                            <div>
                                <Button
                                    id="if_btn_cancel"
                                    onClick={toggle}
                                    className="modal-cancel-btn"
                                    color="primary"
                                    type="button"
                                    style={{ margin: "0px 10px" }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    id="if_btn_save"
                                    className="modal-save-btn"
                                    color="primary"
                                    type="submit"
                                    style={{ margin: "0px 10px" }}>
                                    Save
                                </Button>
                            </div>
                        </>
                    )}
                </Col>
            </Row>

        </form>
    );
}

export default withAlert()(InstitutionForm);