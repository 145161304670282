import React, { useState } from "react";
import {
  //   Button,
  Modal,
  ModalHeader,
  ModalBody,
  //   ModalFooter,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import DefaultPic from "../../../../../assets/images/defaultPic.png";
import { search_contact } from "../../../../../utils/message_connection";
import { useSelector } from "react-redux";
import "./index.css";

const ModalNew = ({
  showModal,
  forwardMessage,
  modaltitle,
  Uploaded_item,
  send_file,
  connections,
  forward_message_action,
  message_text,
  new_message_action,
  close_modal,
  forwardMessage_connection,
}) => {
  const [searchConnection, setsearchConnection] = useState([]);
  /*practioner id from redux store*/
  let practionerId = useSelector(
    (state) => state.auth.currentUser.practitionerId
  );
  /*onTypeHandler function*/
  const onTypeHandler = async (e) => {
    const search = e.target.value;
    search_contact(practionerId, search).then((res) => {
      if (search !== "" && res.data.status !== "FAILED") {
        setsearchConnection(res.data.data.suggestions);
      } else {
        setsearchConnection([]);
      }
    });
  };
  return (
    <div>
      <Modal
        isOpen={showModal}
        toggle={() => forwardMessage()}
        size={
          modaltitle === "Forward" || modaltitle === "New Message" ? "" : "md"
        }
        
      >
        <ModalHeader
          toggle={() => forwardMessage()}
          style={{ background: "white" }}
        >
          {modaltitle ? modaltitle : "Title"}
        </ModalHeader>

        {modaltitle === "Preview" ? (
          <ModalBody>
            <div>
              <InputGroup>
                <img
                  src={`${process.env.REACT_APP_API_ENDPOINT}getobject/${Uploaded_item}`}
                  alt=""
                  style={{
                    width: "100%",
                    height: "auto",
                    marginBottom: "10px",
                    borderRadius: "5px",
                  }}
                />
              </InputGroup>
            </div>
          </ModalBody>
        ) : modaltitle === "New Message" ? (
          <ModalBody>
            <div>
              <InputGroup>
                <Input id="mesg_username" placeholder="username" onChange={onTypeHandler} />
                <InputGroupAddon addonType="append">
                  {/* <InputGroupText>@example.com</InputGroupText> */}
                </InputGroupAddon>
              </InputGroup>
              <div className="mt-3 ml-1">
                <h6>Connections</h6>
              </div>

              <div
                className={"scroll_contacts"}
                style={{ height: "150px", padding: "0 10px" }}
              >
                {searchConnection.length > 0
                  ? searchConnection.map((item, index) => (
                      <div
                        className="d-flex align-items-center justify-content-between mb-3"
                        key={index}
                      >
                        <span>
                          <span>
                            <img
                              src={item.bannerUrl ? item.bannerUrl : DefaultPic}
                              alt=""
                              style={
                                item.bannerUrl !== null
                                  ? { width: "55px", borderRadius: "50%" }
                                  : { width: "52px", borderRadius: "50%" }
                              }
                            />
                          </span>
                          {item.firstName != null &&
                          item.firstName.length > 0 ? (
                            <span className="ml-4">{item.firstName}</span>
                          ) : null}
                          {item.middleName != null &&
                          item.middleName.length > 0 ? (
                            <span>{item.middleName}</span>
                          ) : null}{" "}
                          {item.lastName != null && item.lastName.length > 0 ? (
                            <span>{item.lastName}</span>
                          ) : null}
                        </span>

                        <div>
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => new_message_action(item)}
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    ))
                  : connections &&
                    connections.map((item, index) => (
                      <div
                        className="d-flex align-items-center justify-content-between mb-3"
                        key={index}
                      >
                        <span>
                          <span>
                            <img
                              src={item.bannerUrl ? item.bannerUrl : DefaultPic}
                              alt=""
                              style={
                                item.bannerUrl !== null
                                  ? { width: "55px", borderRadius: "50%" }
                                  : { width: "52px", borderRadius: "50%" }
                              }
                            />
                          </span>
                          {item.firstName != null &&
                          item.firstName.length > 0 ? (
                            <span className="ml-4">{item.firstName}</span>
                          ) : null}
                          {item.middleName != null &&
                          item.middleName.length > 0 ? (
                            <span>{item.middleName}</span>
                          ) : null}{" "}
                          {item.lastName != null && item.lastName.length > 0 ? (
                            <span>{item.lastName}</span>
                          ) : null}
                        </span>

                        <div>
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => new_message_action(item)}
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </ModalBody>
        ) : (
          <ModalBody>
            <div>
              <InputGroup>
                <Input id="mesg_username" placeholder="username" />
                <InputGroupAddon addonType="append"></InputGroupAddon>
              </InputGroup>
              <div className="mt-3 ml-1">
                <h6>Connections</h6>
              </div>
              <div>
                <div
                  className={"scroll_contacts"}
                  style={{ height: "300px", padding: "0 10px" }}
                >
                  {connections &&
                    connections.map((item, index) => (
                      <div
                        className="d-flex align-items-center justify-content-between mb-3"
                        key={index}
                      >
                        <span>
                          <span>
                            <img
                              src={
                                item.contacts[0].bannerUrl
                                  ? item.contacts[0].bannerUrl
                                  : DefaultPic
                              }
                              alt=""
                              style={
                                item.contacts[0].bannerUrl !== null
                                  ? { width: "55px", borderRadius: "50%" }
                                  : { width: "52px", borderRadius: "50%" }
                              }
                            />
                          </span>
                          {item.contacts[0].firstName ? (
                            <span className="ml-4">
                              {item.contacts[0].firstName}
                            </span>
                          ) : null}
                          {item.contacts[0].middleName ? (
                            <span>{item.contacts[0].middleName}</span>
                          ) : null}{" "}
                          {item.contacts[0].lastName ? (
                            <span>{item.contacts[0].lastName}</span>
                          ) : null}
                        </span>
                        <div>
                          <button
                            className="btn btn-outline-primary"
                            onClick={() =>
                              forward_message_action(
                                message_text,
                                item.channel.channelName
                              )
                            }
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </ModalBody>
        )}
      </Modal>
    </div>
  );
};
export default ModalNew;
