import React, { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const JobTypeEditModal = (props) => {
    const { modal, setModal } = props;
    const { values, setFieldValue } = useFormikContext();
    const [selectedOpportunity, setSelectedOpportunity] = useState(values?.opportunity);

    const handleRadioClick = (e) => {
        setSelectedOpportunity(e.target.value);
    };

    const handleSubmit = () => {
        setFieldValue('opportunity', selectedOpportunity);
        setModal(false);
    };


    return (
        <Modal isOpen={modal} toggle={() => setModal(!modal)} className="nex-model-v2 modal-dialog-scrollable modal-dialog-centered">
            <ModalHeader toggle={() => setModal(!modal)}>Edit Job Position</ModalHeader>
            <ModalBody>
                <div className="form-group gap-md">
                    <h2 className='nex-text-lg text-black'>Select the type of position you are hiring for</h2>
                    <div className="nex-inline-radio-group">
                        <div className="item">
                            <Field
                                type="radio"
                                name="opportunityType"
                                value="Fellowship"
                                checked={selectedOpportunity === "Fellowship"}
                                onClick={handleRadioClick}
                            /> Fellowship
                        </div>
                        <div className="item">
                            <Field
                                type="radio"
                                name="opportunityType"
                                value="Mentorship"
                                checked={selectedOpportunity === "Mentorship"}
                                onClick={handleRadioClick}
                            /> Mentorship
                        </div>
                        <div className="item">
                            <Field
                                type="radio"
                                name="opportunityType"
                                value="Job"
                                checked={selectedOpportunity === "Job"}
                                onClick={handleRadioClick}
                            /> Job
                        </div>
                        <div className="item">
                            <Field
                                type="radio"
                                name="opportunityType"
                                value="Volunteer"
                                checked={selectedOpportunity === "Volunteer"}
                                onClick={handleRadioClick}
                            /> Volunteer
                        </div>
                    </div>
                </div>
                <div className="form-group nex-action-flex">
                <button className="btn nex-btn-primary ml-auto" onClick={handleSubmit}>Save</button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default JobTypeEditModal;
