import React from 'react'
import { CardHeader } from 'reactstrap'
import collapse_arrow from './images/down-arrow-white-icon.svg';
import './css/style.scss'

export default function SectionHeadingOne() {
  return (
    <CardHeader className='main-header-v1'>
        {/** left side icon */}
        {/*<div className='header-left-icon'></div>*/}
        <h2 className='nexogic-section-label'>Section One Heading</h2>
        <div className='header-btns-group'>
            <button className='collapse-btn'><img src={collapse_arrow} alt=''/></button>
        </div>
    </CardHeader>
  )
}
