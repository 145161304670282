import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import SecondOpinionImg from "assets/images/second-opinion.png";

export default function AppointmentAdd() {
    return (
        <div className='row'>
            <div className="col-lg-9 nexogic-main-col">

                <Card className='nexogic-doctor-appoint-card'>
                    <CardBody>
                        <Row className='need-margin'>
                            <Col lg={12}>
                                <h3 className="nexogic-subsection-title">Add Appointment Request </h3>
                            </Col>
                            <Col xl={6} lg={6} className="">
                                <div className="form-group">
                                    <label htmlFor="">Select Appointment Date</label>
                                    <input type="text" name="" id="" className='form-control' placeholder='Appointment Date' />
                                </div>
                            </Col>
                            <Col xl={6} lg={6} className="">
                                <div className="form-group">
                                    <label htmlFor="">Select Appointment Time</label>
                                    <input type="text" name="" id="" className='form-control' placeholder='Select Time Slot' />
                                </div>
                            </Col>
                            <Col xl={6} lg={6} className="">
                                <div className="form-group">
                                    <label htmlFor="">Visit</label>
                                    <input type="text" name="" id="" className='form-control' placeholder='Follow up visit' />
                                </div>
                            </Col>
                            <Col xl={6} lg={6} className="">
                                <div className="form-group">
                                    <label htmlFor="">This appointment is for</label>
                                    <input type="text" name="" id="" className='form-control' placeholder='' />
                                </div>
                            </Col>
                            <Col xl={6} lg={6} className="">
                                <div className="form-group">
                                    <label htmlFor="">Purpose of visit</label>
                                    <input type="text" name="" id="" className='form-control' placeholder='' />
                                </div>
                            </Col>
                            <Col xl={6} lg={6} className="">
                                <div className="form-group">
                                    <label htmlFor="">Insurance</label>
                                    <input type="text" name="" id="" className='form-control' placeholder='' />
                                </div>
                            </Col>
                            <Col xl={6} lg={6} className="">
                                <div className="form-group">
                                    <label htmlFor="">Phone</label>
                                    <input type="text" name="" id="" className='form-control' placeholder='' />
                                </div>
                            </Col>
                            <Col xl={6} lg={6} className="">
                                <div className="form-group">
                                    <label htmlFor="">Email</label>
                                    <input type="text" name="" id="" className='form-control' placeholder='' />
                                </div>
                            </Col>
                            <Col xl={12} lg={12} className="">
                                <div className="form-group">

                                    <input type="submit" name="" id="" className='btn nexogic_primary_button' value='Submit' />
                                </div>
                            </Col>

                        </Row>

                    </CardBody>
                </Card>


            </div>
            <div className="col-lg-3">
                <div className="nexogic-widget-sidebar-card card border-0">
                    <h3 className="card-title h2">Second Opinion</h3>
                    <div className="card-body">
                        <ul className="nexogic-lists nexogic-custom-scroll">
                            <li>
                                <img src={SecondOpinionImg} alt="" className="img-fluid" />
                            </li>
                        </ul>
                    </div>
                </div>

            </div>


        </div>
    )
}
