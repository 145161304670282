import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useDropzone } from "react-dropzone";
import { Button, Card, CardImg } from "reactstrap";
import styled from "styled-components";

import AlertDialog from "common/AlertDialog";
import { PractitionerService } from "utils/PractitionerService";
import { decodeFileUrl } from "utils/Utils";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 10px;
  border-color: #e5f2f6;
  border-style: dashed;
  background-color: #ffffff;
  color: rgba(0, 18, 68, 0.5);
  outline: none;
  transition: border 0.24s ease-in-out;
  font-size: 14px;
  line-height: 21px;
`;

const UploadLink = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #3EABCA;
  cursor: pointer;
`;

const DragNDrop = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #66718f;
`;

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  background: "#FFFFFF",
  borderRadius: 10,
  border: "2px dashed #E5F2F6",
  padding: "16px",
};

const thumb = {
  border: "1px solid #eaeaea",
  marginRight: 8,
  width: 130,
  height: 120,
  boxSizing: "border-box",
  borderRadius: 8,
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  // width: 120,
  // height: 110,
};

const img = {
  display: "block",
  width: "100%",
  height: "100%",
};

const UploadDocument = (props) => {
  const { isAdmin, isLoggedinUser, documents, getDocuments } = props;
  const [files, setFiles] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isOpenAlert, setAlertModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const toggleAlert = () => {
    setLoading(false);
    setAlertModal(!isOpenAlert);
  };
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: "image/jpeg,image/jpg,image/png,application/pdf",
    onDrop: (acceptedFiles, fileRejections) => {
      const selectedFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles([...files, ...selectedFiles]);
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-invalid-type") {
            alert(`Only PDF & Images files are allowed.`);
          }
        });
      });
    },
  });

  const deleteInternalFile = (deletedFile) => {
    const deleteIndex = files.findIndex(
      (file) => file.preview === deletedFile.preview
    );
    setFiles([...files.slice(0, deleteIndex), ...files.slice(deleteIndex + 1)]);
  };

  const handleClickOnYes = async () => {
    setLoading(true);
    try {
      PractitionerService.deletePractitionerDocument(selectedItem.id).then(
        (response) => {
          const { status, message } = response.data;
          if (status === "SUCCESS") {
            setTimeout(() => {
              showSuccessMessage(message);
              getDocuments();
              setLoading(false);
              setSelectedItem({});
              toggleAlert();
            }, 1000);
          } else {
            showSuccessMessage(message || "Failed to delete");
            setLoading(false);
            setSelectedItem({});
            toggleAlert();
          }
        },
        (e) => {
          setLoading(false);
          console.log("error", e.message);
          let errmsg = e.message;
          if (e.response) {
            errmsg =
              e.response.data && !!e.response.data.message
                ? e.response.data.message
                : errmsg;
          }
          showErrorMessage(errmsg);
        }
      );
    } catch (e) {
      showErrorMessage(e.message);
      setLoading(false);
      toggleAlert();
    }
  };

  const deleteExternalFile = (deletedFile) => {
    // return false
    // Temp disable this feature untill delete document api available
    setSelectedItem(deletedFile);
    toggleAlert();
  };

  const thumbs = files.map((file, index) => (
    <Card
      key={`file-${index}`}
      className="mt-2 ml-2 hover-block overflow-hidden"
    >
      {file.type === "application/pdf" ? (
        <embed top className="doc-image" src={file.preview} height="110" />
      ) : (
        <CardImg
          top
          className="doc-image"
          src={file.preview}
          height="110"
          alt="Card image cap"
        ></CardImg>
      )}
      <div className="document-name d-flex align-items-center justify-content-center">
        <a
          href
          onClick={(e) => {
            e.preventDefault();
          }}
          className="text-truncate"
        >
          {file.name}
        </a>
      </div>
      {(isLoggedinUser || isAdmin) && (
        <Button
          color="link"
          className="visible-hover py-0 position-absolute bg-black"
          onClick={() => deleteInternalFile(file)}
        >
          <FontAwesomeIcon icon={faPlus} className="delete-icn" />
        </Button>
      )}
    </Card>
  ));

  const thumbsExternal = documents.map((item, index) => {
    const fileName = item.documentTitle ? item.documentTitle : "";
    return (
      <Card
        key={`extFile-${index}`}
        className="mt-2 ml-2 hover-block overflow-hidden"
      >
        {item.documentType === "application/pdf" ? (
          <embed
            top
            className="doc-image"
            src={decodeFileUrl(item.documentUrl)}
            height="110"
          />
        ) : (
          <CardImg
            top
            className="doc-image"
            src={decodeFileUrl(item.documentUrl)}
            height="110"
            alt="Card image cap"
          ></CardImg>
        )}
        <div className="document-name d-flex align-items-center justify-content-center">
          <a
            href={decodeFileUrl(item.documentUrl)}
            target="_blank"
            className="text-truncate"
          >
            {fileName}
          </a>
        </div>
        {(isLoggedinUser || isAdmin) && (
          <Button
            color="link"
            className="visible-hover py-0 position-absolute bg-black"
            onClick={() => deleteExternalFile(item)}
          >
            <FontAwesomeIcon icon={faPlus} className="delete-icn" />
          </Button>
        )}
      </Card>
    );
  });

  useEffect(() => {
    props.onDocumentsChange(files);
    return () => {
      // Make sure to revoke the data uris to avoid memory leaks
      // files.forEach(file => URL.revokeObjectURL(file.preview)); // revoke object after upload
    };
  }, [files]);

  return (
    <div className="profile-wrapper">
      <Container
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        <p>
          Please upload Govt. issued photo id and registration document for
          verification
        </p>
        <div className="d-flex align-items-center">
          <UploadLink className="mr-2" onClick={open}>
            Select file
          </UploadLink>
          <DragNDrop className="mr-2">or Drag & Drop here</DragNDrop>
        </div>
      </Container>
      {files.length || documents.length ? (
        <aside style={thumbsContainer}>
          {thumbsExternal}
          {thumbs}
        </aside>
      ) : (
        ""
      )}

      <AlertDialog
        toggle={toggleAlert}
        isOpen={isOpenAlert}
        isLoading={isLoading}
        handleClickOnYes={handleClickOnYes}
        yesLabel={"Yes"}
        noLabel={"No"}
      />
    </div>
  );
};

export default withAlert()(UploadDocument);
