import secureLocalStorage from "react-secure-storage";

export const setUser = async (userData) => {
  await secureLocalStorage.setItem(
    "nexogic.user.pract_detdesc",
    JSON.stringify(userData)
  );
};

export const getUser = () => {
  return JSON.parse(secureLocalStorage.getItem("nexogic.user.pract_detdesc"));
};

export const setToken = (token) => {
  secureLocalStorage.setItem("nexogic.token", token);
};

export const getToken = () => {
  return secureLocalStorage.getItem("nexogic.token");
};

export const getRefreshToken = () => {
  return secureLocalStorage.getItem("nexogic.refreshToken");
};

export const getOAuthUser = () => {
  return JSON.parse(secureLocalStorage.getItem("nexogic.user.oauth"));
};

export const isAdmin = () => {
  const user = getOAuthUser();
  // return user?.types?.[0]?.includes("ADMIN") ?? false;
  return user?.types?.some(type => type === "ADMIN") ?? false;
};

export const isInstitutionAdmin = () => {
  const user = getOAuthUser();
  // return user?.types?.[0]?.includes("INSTITUTION_ADMIN") ?? false;
  return user?.types?.some(type => type === "INSTITUTION_ADMIN") ?? false;
};

export const isPractitioner = () => {
  const user = getOAuthUser();
  return user?.types?.[0]?.includes("PRACTITIONER") ?? false;
};

export const isPatient = () => {
  const user = getOAuthUser();
  return user?.types?.[0]?.includes("PATIENT") ?? false;
};

export const setOAuthUser = (userOAuth) => {
  secureLocalStorage.setItem("nexogic.user.oauth", JSON.stringify(userOAuth));
};
export const setLoggedInUserInstitutions = (institutions) => {
  secureLocalStorage.setItem("nexogic.user.institutions", JSON.stringify(institutions));
};

export const getLoggedInUserInstitutions = () => {
  return JSON.parse(secureLocalStorage.getItem("nexogic.user.institutions"));
};

export const setOAuthTokens = (accessToken, refreshToken) => {
  secureLocalStorage.setItem("nexogic.token", accessToken);
  secureLocalStorage.setItem("nexogic.refreshToken", refreshToken);
};

export const updateAccessToken = (accessToken) => {
  secureLocalStorage.setItem("nexogic.token", accessToken);
};

export const resetAuthSession = () => {
  secureLocalStorage.removeItem("nexogic.token");
};