import React from 'react'
import './css/v1.scss'
import logo_img from 'assets/images/logo-white-img.png'

function AdsOne(props) {
  return (
    <div className='nex-ads-wrapper size-md mb-3 v1'>
    <div className="nex-ads-content">
        <div className="nex-ads-header">
            <a href="#"><img src={props.logo_img} alt="" /></a>
        </div>
        <div className="nex-ads-body add-svg-bg-1">
            <div className="nex-add-content">
                <h2 className='nex-ads-p-heading'>{props.title}</h2>
                <div className="nex-ads-c-w">
                <p>{props.short_desc}</p>
                <p>
                    <a href="" className='nex-add-btn'>Learn More</a>
                </p>
                <p>{props.website}</p>
                <p><b>Make Appointment</b><br/>
                    <a href={'tel:'+props.phone}>{props.phone}</a>
                </p>
                </div>
            </div>
            <div className="nex-add-aside">
                <div className="nex-aside-graphic">
                    <img src={props.bg} alt="" />
                    <div className="nex-offer-text">
                        <b>{props.discount}</b>
                        OFF
                    </div>
                </div>

                    <p className='text-right mt-5 px-3'><a href="" className='nex-ads-btn nex-ads-btn-secondary'>Our Services</a></p>
                    <ul className='nex-bullest right'>
                        {props.services.map((item, ind)=><li key={'ads-services-'+ind}><a href={item.url} target='_blank'>{item.title}</a></li>)}
                    </ul>
            </div>
        </div>
    </div>
</div>
  )
}

/* default props */
AdsOne.defaultProps = {
    bg:"https://picsum.photos/id/200/400/400",
    logo_img:logo_img,
    logo_url:"#",
    logo_target:"_blank",
    title:"Your Health Is Our Priority",
    short_desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo, magni.",
    website:"www.brandname.com",
    phone:"+0123456789",
    discount:"25%",
    services:[
        {title:"Emergency", url:"#"},
        {title:"Sleep Therapy", url:"#"},
        {title:"Blood Testing", url:"#"},
        {title:"Lab Test", url:"#"}
    ]
  }
  
  export default AdsOne;
