import logo_white from "assets/images/logo-white-transparent.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import ReactCodeInput from "react-verification-code-input";
import { useState } from "react";
import { Button } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { UserAuth } from "context/AuthContext";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { IdentityService } from "utils/IdentityService";

const OtpVerify = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { signUpWIthMobileOTP, verifyOTP } = UserAuth();
  const [verifyCode, setVerifyCode] = useState("");
  // const [isPatient, setIsPatient] = useState(false);
  const { utype, userProfileId, regtype, getUserDetails } = location?.state;
  // const [userProfileId, setUserProfileId] = useState("");

  // const handleSignUpWithMobileOtp = async (code) => {
  //   try {
  //     const signedInUser = await signUpWIthMobileOTP(code)
  //     if (signedInUser) {
  //       const { name, email, idToken, refreshToken, profiledetails, phoneNumber } = signedInUser;
  //       console.log("handleSignUpWithMobileOtp============signedInUser=============", signedInUser);
  //       if (!profiledetails) {
  //         try {
  //           history.push(`${(utype) ? '/u/create-account' : '/register/onboarding'}`, {
  //             firstName: name?.split(' ')[0],
  //             lastName: name?.split(' ')[1] || '',
  //             email: email,
  //             phoneNumber: phoneNumber,
  //             token: idToken,
  //             refreshToken: refreshToken,
  //             utype: utype,
  //             claim: userProfileId ? true : false,
  //             profileId: userProfileId ?? "",
  //           });
  //         } catch (apiError) {
  //           console.error('API call failed:', apiError);
  //           history.push('/login');
  //         }
  //       } else {
  //         console.log("Account ALready Exists..");
  //         history.push('/login')
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Mobile sign-in failed:', error);
  //     showErrorMessage("Invalid Otp, Authentication failed")
  //   }
  // }

  // const handleVerifyOtp = async (code) => {
  //   //setIsActive(true);
  //   try {
  //     const signedInUser = await verifyOTP(code)
  //     if (signedInUser?.accessToken) {
  //       try {
  //         IdentityService.UserAuthentication(signedInUser?.accessToken)
  //           .then((response) => {
  //             if (response.status === 200) {
  //               const { data } = response;
  //               getUserDetails(data, signedInUser);
  //             } else if (response.status === 404) {
  //               //   setOtpSent(false);
  //               //setIsActive(false);
  //               showErrorMessage("Please verify the information you entered and try again, or consider signing up for a new account.");
  //             }
  //           })
  //           .catch((error) => {
  //             console.log("Login Issue..", error);
  //           })
  //       } catch (apiError) {
  //         console.error('API call failed:', apiError);
  //         history.push('/login');
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Mobile sign-in failed:', error);
  //   }
  // };

  const handleOTPVerification = (e) => {
    e.preventDefault();
    // props.location.state.handleSignUpWithMobileOtp(verifyCode)
    // if (regtype === "LOGIN")
    //   handleVerifyOtp(verifyCode)
    // else
      // handleSignUpWithMobileOtp(verifyCode)
  };

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className="nex-form-fields--w">
      <div className="nex-form-center-slot">
        <div className="nex-form-container">
          <p className="nex-text-lg text-black mb-5" data-aos="fade-down" data-aos-duration="800" data-aos-delay="150">Verify your account by entering the 6 digit code we sent via text to your mobile number ******6485</p>
          <form onSubmit={handleOTPVerification}>
            <div className="form-group nex-otp-group" data-aos="fade-down" data-aos-duration="800" data-aos-delay="200">
              <ReactCodeInput
                id="vo_fields"
                //ref={otpRef}
                type="number"
                placeholder={[" ", " ", " ", " ", " ", " "]}
                fields={6}
                fieldWidth={42}
                fieldHeight={38}
                onChange={(val) => setVerifyCode(val)}
                className="verification-code-masked-input"
              />
            </div>
            {/* <p className="nex-text-lg text-right text-black mb-5" data-aos="fade-down" data-aos-duration="800" data-aos-delay="250">Your OTP expires in 
                                <CountDown time={60} digital onComplete={() => setCanResendOTP(true)} /></p> */}
            <div className="form-group" data-aos="fade-down" data-aos-duration="800" data-aos-delay="300">
              <Button
                id="nex-continue-form-next2"
                className="btn btn-primary lg w-100"
                type="submit"
              >
                Verify
              </Button>
            </div>
          </form>
          <p className="nex-text-lg pt-3 pb-2" data-aos="fade-down" data-aos-duration="800" data-aos-delay="350"><a href="" className="text-light-purple-dark">Entered a wrong number?</a></p>
          <p className="nex-text-lg" data-aos="fade-down" data-aos-duration="800" data-aos-delay="400"><a href="" className="text-light-purple-dark">Receive OTP via email instead</a></p>
        </div>
      </div>
      <div className="nex-form-bottom-slot">
        <p className="nex-text-lg text-left">If you are having problem receiving OTP <br />
          Please contact to  <a href="#" className="text-light-purple">Support</a></p>
      </div>
    </div>
  )
}

export default OtpVerify;