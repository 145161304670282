import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import InstitutionForm from "./InstitutionForm.js";

export const CreateInstitutionModal = (props) => {
  const { isOpen, toggle } = props;

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        id="connection-modal"
        scrollable
        centered
      >
        <ModalHeader toggle={toggle}>Create Institution</ModalHeader>
        <ModalBody>
          <InstitutionForm {...props} />
        </ModalBody>
      </Modal>
    </div>
  );
};
