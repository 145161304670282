import "assets/styles/typeahead.css";
import { ErrorMessage } from "formik";
import _ from "lodash";
import { useCallback, useRef, useState } from "react";
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { Button, FormGroup, Label } from "reactstrap";
import { InstitutionTypes } from "utils/Constants";
import { InstitutionServices } from "utils/InstitutionServices";
import { getFullName, makeFullNameWithAddress } from "utils/Utils";
import { toast } from "react-toastify";

const ParentInstitutesTypeaheadField = (props) => {
  const institutesRef = useRef(null);
  const { placeholder, type, onNewInstituteReq } = props;
  const { name, value } = props.field;
  const { setFieldValue } = props.form;
  const [institutesData, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async (query) => {
    setData([]);
    if (query.length < 3) return;
    setLoading(true);
    let instituteType = [
      InstitutionTypes.COLLEGE_OR_UNIVERSITY,
      InstitutionTypes.MEDICAL_ASSOCIATION,
      InstitutionTypes.MEDICAL_COLLEGE_OR_HOSPITAL,
      InstitutionTypes.HOSPITAL,
      InstitutionTypes.NURSING_HOMES,
      InstitutionTypes.INDIVIDUAL_CLINICS,
      InstitutionTypes.DIAGNOSTIC_CENTERS,
      InstitutionTypes.MEDICAL_LABORATORY_OR_DIALYSIS_CENTERS,
      InstitutionTypes.CLINICAL_RESEARCH_ORGANIZATIONS,
      InstitutionTypes.OTHER,
    ].join(",");
    InstitutionServices.getInstitutions(query, null, instituteType)
      .then((response) => {
        if (response.status === 200) {
          setData(
            [...response.data].map((node) => {
              return {
                ...node,
                institutionName: makeFullNameWithAddress(node),
              };
            })
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
        if (toast.alert) showErrorMessage(err.message);
      });
  };

  const getdata = useCallback(_.debounce(handleSearch, 500), []);

  const handleChange = (selected) => {
    if (selected.length > 0) {
      setFieldValue(
        `${name}`,
        {
          guid: selected[0].guid.toString(),
          institutionName: selected[0].institutionName,
        },
        true
      );
      setFieldValue(`mainItemName`, selected[0].institutionName);
      setFieldValue(`${name}.onSelectNewinstitution`, 1);
    }
  };

  return (
    <FormGroup className="flex-grow-1">
      <Label htmlFor={name} className="d-block mb-2">
        {props.label}
      </Label>
      <AsyncTypeahead
        ref={institutesRef}
        isLoading={loading}
        clearButton
        onSearch={getdata}
        id={"institutesAsyncInput"}
        options={institutesData}
        labelKey={"institutionName"}
        filterBy={["institutionName", "abbreviation"]}
        selected={value && value.institutionName ? [value] : [""]}
        placeholder={placeholder}
        type={type}
        minLength={2}
        inputProps={{ className: "" }}
        onInputChange={(text) => {
          setFieldValue(`${name}`, { institutionName: text });
          setFieldValue(`${name}.guid`, "");
          setFieldValue(`mainItemName`, "");
          setFieldValue(`${name}.onSelectNewinstitution`, 0);
          getdata(text);
        }}
        onChange={handleChange}
        onBlur={() => {
          return institutesRef !== null
            ? institutesRef.current.hideMenu()
            : null;
        }}
        renderMenu={(results, menuProps) => {
          return (
            <Menu {...menuProps}>
              {results.map((result, index) => (
                <MenuItem key={result.guid} option={result} position={index}>
                  {getFullName(result)}
                </MenuItem>
              ))}
              {!!onNewInstituteReq && (
                <Button
                  className="btn--outline-success bg-white border-0 ml-3 f-16 line-h-20"
                  onClick={() => {
                    onNewInstituteReq();
                    return institutesRef !== null
                      ? institutesRef.current.hideMenu()
                      : null;
                  }}
                  style={{ color: "#5CBA0D" }}
                >
                  {"Select other if haven't found"}
                </Button>
              )}
            </Menu>
          );
        }}
      />
      <ErrorMessage
        name={name}
        render={(msg) => (
          <span className="text-danger f-12 d-inline-block  mt-1 line-height-error">
            {msg}
          </span>
        )}
      />
    </FormGroup>
  );
};
export default ParentInstitutesTypeaheadField;
