import logo_white from "assets/images/logo-white-transparent.png";
import arrow_back from "assets/images/nex-arrow-back.svg";
import eye_pass from "assets/images/eye-pass.png";
import { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useState } from "react";
import { Field, Formik } from "formik";
import FormikCheckboxField from "components/forms/formikFields/FormikCheckboxField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikMobileField from "components/forms/formikFields/FormikMobileField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { emailNonNumericRegEx } from "utils/Constants";
import * as Yup from "yup";
import { useMemo } from "react";
const validationSchema = Yup.object().shape({
    
    email: Yup.string()
        .max(100)
        .matches(emailNonNumericRegEx, "Please Enter an valid Email")
        .required("Please Enter an valid Email"),
  });

const NewPasswordRecovery = (props) => {
    const [isSubmitting, setSubmitting] = useState(false);
    const initValues = useMemo(() => {
        return {
          email: "",
        };
      }, []);
    
      const [initialValues, setInitialValues] = useState(initValues);
      const onSubmit = (values) => {
        console.log(values);
      };
    useEffect(()=>{
        AOS.init();
    }, [])
    return (
        <div className="nex-bg-light-blue">
            <main className="nex-login-register--w">
                <div className="container sm d-flex" data-aos="fade-up">
                    <div className="nex-form-aside">
                        <div className="logo" data-aos="fade-up" data-aos-duration="800" data-aos-delay="100">
                            <a href="/"><img src={logo_white} alt="" /></a>
                        </div>
                        
                        <h2 className="nex-text-xxl" data-aos="fade-up" data-aos-duration="800" data-aos-delay="150">Password Assistance</h2>
                    </div>
                    <div className="nex-form-fields--w">
                        <div className="nex-form-top-slot">
                            <div className="nex-form-top-actions nex-text-lg" data-aos="fade-down" data-aos-duration="800" data-aos-delay="200">
                                {/*<a href="#" className="text-gray nex-back-btn"><img src={arrow_back} alt="" /></a>*/}
                                <a href="#" className="text-gray nex-cross-btn">&times;</a>
                            </div>
                        </div>
                        <div className="nex-form-center-slot">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            enableReinitialize={true}
                            onSubmit={onSubmit}
                            children={(props) => {
                                return (
                                <>
                                    <RenderForm
                                    {...props}
                                    isSubmitting={isSubmitting}
                                    />
                                </>
                                );
                            }}
                        />
                            {/*<div className="nex-form-container">
                                <div className="form-group-fields--w">
                                    <p className="nex-text-lg mb-3 text-black" data-aos="fade-down" data-aos-duration="800" data-aos-delay="250">Enter your email to recover your password. You will receive an email with instructions. If you are having problems recovering your password <a href="#">contact</a></p>
                                    <div className="form-group" data-aos="fade-down" data-aos-duration="800" data-aos-delay="300">
                                        <input type="email" name="" id="" className="form-control error" placeholder="Enter Email" />
                                        <p className="nex-error-text">Please enter a valid email addaress</p>
                                    </div>
                                
                                    <div className="form-group" data-aos="fade-down" data-aos-duration="800" data-aos-delay="350">
                                        <input type="reset" value="Continue" className="btn btn-primary-outline w-100" />
                                        <input type="submit" value="Register" className="btn btn-primary w-100" />
                                    </div>
                                </div>
        </div>*/}
                        </div>
                        
                    </div>
                </div>
            </main>
        </div>
    )
}

export default  NewPasswordRecovery;
const RenderForm = (props) => {
    const {
        values,
        errors,
        isSubmitting,
        handleSubmit,
        addressDetails,
        userProfileId,
      } = props;
      const [showPassword, onShowPassword] = useState(false);
      const toggleShowPassword = () => onShowPassword(!showPassword);
      return (
        <>
        <form className="personal-details-form" onSubmit={handleSubmit}>
        <div className="nex-form-container">
                                <div className="form-group-fields--w">
                                    <p className="nex-text-lg mb-3 text-black" data-aos="fade-down" data-aos-duration="800" data-aos-delay="250">Enter your email to recover your password. You will receive an email with instructions. If you are having problems recovering your password <a href="#">contact</a></p>
                                    <div data-aos="fade-down" data-aos-duration="800" data-aos-delay="300">
                                    <Field
                                        id="email"
                                        name="email"
                                        type="email"
                                        component={FormikInputField}
                                        // label="Email Address"
                                        placeholder="Enter Email" />
                                    </div>
                                
                                    <div className="form-group" data-aos="fade-down" data-aos-duration="800" data-aos-delay="350">
                                        <input type="reset" value="Continue" className="btn btn-primary-outline w-100" />
                                        <input type="submit" value="Register" className="btn btn-primary w-100" />
                                    </div>
                                </div>
                            </div>
        </form>
        </>
      )
}