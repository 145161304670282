import API_New from "../utils/API_New";

// fetch comments
export const fetch_Comment = (feed_id, pageNum, practitionerId) => {
  // console.log(pageNum, "from");
  return API_New.get(
    `practitioner-service/practitioners/feeds/${feed_id}/comments?pageNum=${pageNum}&pageSize=${10}&practitionerId=${practitionerId}`
  );
};

export const fetch_lat_Comment = (feed_id, practitionerId) => {

  return API_New.get(
    `practitioner-service/practitioners/feeds/${feed_id}/comments?pageNum=0&pageSize=1&practitionerId=${practitionerId}`
  );
};

//post comments
export const Post_Comment = (post_comment_data, practitionerId) => {
  return API_New.post(
    `practitioner-service/practitioners/comment`,
    post_comment_data
  );
};
