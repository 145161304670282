import React, { useState } from "react"
import { Card, CardBody, Col, Row, Button } from "reactstrap"
import { createImageFromInitials, getRandomColor } from "utils/Utils"
import { encrypt } from "utils/EncryptDecrypt"
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import CommonLoader from "common/CommonLoader"
import NoInvitations from "../../Groups/components/NoInvitations"
import { withAlert } from "react-alert"
import { ConnectionService } from "utils/ConnectionService"
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton"
import PagesFollowedTabularView from "components/tabularView/PagesFollowedTabularView"
import { useEffect } from "react"
import { ConnectionServices } from "utils/ConnectionServices"
import { useSelector } from "react-redux"
import PeopleKnowWidget from "common/PeopleKnowWidget"
import PageTitle from "common/PageTitle/PageTitle"
import ListTableSwitch from "common/ListTableSwitch/ListTableSwitch"
import QuickInfo from "common/QuickInfo/QuickInfo"

const PagesFollowed = (props) => {
    const currentUser = useSelector((state) => state.auth.currentUser);
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(0)
    const [isLoading, setIsLoading] = useState(true);
    const [pages, setPages] = useState(0);
    const [viewMode, setViewMode] = useState("card");

    const getFollowedPages = (pageNo) => {
        ConnectionServices.getPagesIFollow(currentUser.guid, "INSTITUTION", pageNo).then(
            (response) => {
                if (response.status === 200) {
                    const { data } = response;
                    if (data?.currentPage < (data?.totalPages - 1))
                        setHasMore(true);
                    else
                        setHasMore(false);
                    setCurrentPage(data?.currentPage);

                    if (pageNo === 0)
                        setPages(data.contents);
                    else
                        setPages([...pages, ...data.contents]);
                    setIsLoadingMore(false)

                } else {
                    setIsLoadingMore(false)
                    showErrorMessage("Something went wrong");
                }
                setIsLoadingMore(false)
                setIsLoading(false);
            }
        );
    };


    const handleUnFollow = (id) => {
        ConnectionService.UnFollowing(id)
            .then((response) => {
                if (response.status === 204) {
                    getFollowedPages(0);
                    showSuccessMessage("Page unfollowed successfully..");
                }
                else {
                    showErrorMessage("Something went wrong...");
                }
            })
    }

    const fetchData = () => {
        setIsLoadingMore(true);
        getFollowedPages(currentPage + 1);
    };

    useEffect(() => {
        getFollowedPages(0);
    }, [])


    const renderProfiles = () => {
        if (viewMode === 'card') {
            return (
                <>
                    {pages.map((e, ind) => (
                        <Card className="nex-list-card-v2 bg-white" key={'follow-page-' + ind}>
                            <CardBody>
                                <div className="nex-stack">
                                    <QuickInfo
                                        avatar={<img src={createImageFromInitials(50, e?.institutionProfileInfo?.name, getRandomColor(encrypt(e?.institutionProfileInfo?.guid).toString()))} className="nex-avatar lg" />}
                                        title={e.institutionProfileInfo?.name}
                                        titleLink={`/institution/${e.institutionProfileInfo?.profileId}`}

                                    >

                                    </QuickInfo>
                                    <div className="action ml-auto my-auto">
                                        <Button color="primary" className="nex-btn-primary" onClick={() => handleUnFollow(e.id)}>Unfollow</Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    ))}
                </>
            );
        } else { // For table view
            return (
                <PagesFollowedTabularView
                    theader={['Name', 'Type', 'Unfollow']} tdata={pages} viewmode={viewMode}
                    handleUnFollow={handleUnFollow}
                />
            );
        }
    };

    return (
        <div className="nex-container row">
            <div className="nex-app-no-pad nex-lists-content col">
                <PageTitle title="Pages Followed">
                    <ListTableSwitch viewMode={viewMode} cardViewAction={() => setViewMode("card")} tableViewAction={() => setViewMode("table")} />
                </PageTitle>
                <div className="nexogic-custom-scroll" style={{ '--mx-height': '650px' }}>
                    {
                        isLoading ?
                            <Col><div className="text-center"><CommonLoader /></div></Col>
                            :
                            (
                                (pages && pages.length > 0)
                                    ?
                                    <>{renderProfiles()}</>
                                    :
                                    <div className="nexogic-nodata-dotted"><div className="no-suggestion-text text-center"><NoInvitations content={"No Pages"} /></div></div>
                            )
                    }
                    {hasMore &&
                        <div className="text-center mt-3">
                            {isLoadingMore
                                ?
                                <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                                :
                                <LoadMoreButton loadMore={fetchData} />
                            }
                        </div>
                    }
                </div>
            </div>

            <div className="nex-sidebar v2 col">
                <PeopleKnowWidget speciality={"Acupuncturist"} />
            </div>

        </div>
    )
}

export default withAlert()(PagesFollowed)