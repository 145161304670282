import FormikInputField from 'components/forms/formikFields/FormikInputField';
import FormikTextAreaField from 'components/forms/formikFields/FormikTextAreaField';
import { Field, Form, Formik } from 'formik';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { withAlert } from 'react-alert';
import { MultiSelect } from "react-multi-select-component";
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { FAILED_TO_FETCH_DATA, FAILTOSAVERECOED } from 'utils/Constants';
import { OpinionService } from 'utils/OpinionService';
import * as Yup from 'yup';
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const validationSchema = Yup.object().shape({
    description: Yup.string().required('Description is required'),
    familyHistory: Yup.string(),
    pastHospitalization: Yup.string(),
    // Add validation for other fields as needed
});

const MedicalHistory = (props) => {
    const currentUser = useSelector((state) => state.auth.currentUser);
    const { opinionId } = props;
    // const opinionId = "c0c8dc74-f315-4200-af25-f47c8eaabc97";
    const [initialValues, setInitialValues] = useState({});

    const handleSubmit = (values, { setSubmitting }) => {

        const selectedSymptoms = symptoms_selected.map((item) => item.value).join(', ');
        const selectedAllergies = allergies_selected.map((item) => item.value).join(', ');
        const selectedSurgeries = surgeries_selected.map((item) => item.value).join(', ');

        // console.log("Form Values:", values);
        let payload = {
            "title": values?.inquiry,
            "description": values?.description,
            "symptoms": selectedSymptoms,
            "allergies": selectedAllergies,
            "surgeries": selectedSurgeries,
            "familyHistory": values?.familyHistory,
            "pastHospitalization": values?.pastHospitalization,
            "currentMedications": values?.currentMedications,
            "requestedBy": currentUser?.profileGuid,
            "requestedFor": values?.requestedFor,
            "speciality": values.speciality,
        }

        // console.log("Form payload:", payload);

        OpinionService.updateOpinion(opinionId, payload)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    showSuccessMessage("Medical History updated Successfully.")
                    props.handleStepSwitch(2);
                } else {
                    showErrorMessage(FAILTOSAVERECOED)
                }
            })

        setSubmitting(false);
    };

    const getOpinion = (opinionId) => {
        // console.log("opinionId", opinionId);
        OpinionService.getOpinion(opinionId)
            .then((response) => {
                const { status, data } = response;
                // console.log(data);
                setSymptoms_selected(data?.symptoms ? data.symptoms.split(', ').map((value) => ({ label: value, value })) : []);
                setAllergies_selected(data?.allergies ? data.allergies.split(', ').map((value) => ({ label: value, value })) : []);
                setSurgeries_selected(data?.surgeries ? data.surgeries.split(', ').map((value) => ({ label: value, value })) : []);

                if (status === 200) {
                    setInitialValues((prevState) => ({
                        ...prevState,
                        title: data?.title,
                        firstName: data?.firstName,
                        middleName: data?.middleName || "",
                        lastName: data?.lastName || "",

                        inquiry: data?.title || "",
                        speciality: data?.speciality || "",
                        institution: data?.institution || "",
                        practitioner: data?.practitioner || "",
                        requestedFor: data?.requestedFor || "",

                        description: data?.description || "",
                        familyHistory: data?.familyHistory || "",
                        pastHospitalization: data?.pastHospitalization || "",
                        currentMedications: data?.currentMedications || "",
                    }));
                } else {
                    console.log(FAILED_TO_FETCH_DATA);
                }
            })
    }

    useEffect(() => {
        getOpinion(opinionId);
    }, [opinionId])

    const symptoms_options = [
        { "label": "Depression", "value": "Depression" },
        { "label": "Kidney stones", "value": "Kidney stones" },
        { "label": "Abnormal heart rhythm", "value": "Abnormal heart rhythm" },
        { "label": "Diabetes", "value": "Diabetes" },
        { "label": "Memory problems", "value": "Memory problems" },
        { "label": "Anxiety", "value": "Anxiety" },
        { "label": "Drug or alcohol dependency", "value": "Drug or alcohol dependency" },
        { "label": "Poor hearing", "value": "Poor hearing" },
        { "label": "Other", "value": "Other" }
    ];
    const allergies_options = [
        { "label": "Depression", "value": "Depression" },
        { "label": "Kidney stones", "value": "Kidney stones" },
        { "label": "Abnormal heart rhythm", "value": "Abnormal heart rhythm" },
        { "label": "Diabetes", "value": "Diabetes" },
        { "label": "Memory problems", "value": "Memory problems" },
        { "label": "Anxiety", "value": "Anxiety" },
        { "label": "Drug or alcohol dependency", "value": "Drug or alcohol dependency" },
        { "label": "Poor hearing", "value": "Poor hearing" },
        { "label": "Arthritis", "value": "Arthritis" },
        { "label": "Fainting or dizzy spells", "value": "Fainting or dizzy spells" },
        { "label": "Poor vision", "value": "Poor vision" },
        { "label": "Asthma", "value": "Asthma" },
        { "label": "Gallbladder disease", "value": "Gallbladder disease" },
        { "label": "Psychiatric Illness", "value": "Psychiatric Illness" },
        { "label": "Bronchitis", "value": "Bronchitis" },
        { "label": "Headaches/Migraines", "value": "Headaches/Migraines" },
        { "label": "Stomach or GI disorders", "value": "Stomach or GI disorders" },
        { "label": "Cancer", "value": "Cancer" },
        { "label": "Heart disease", "value": "Heart disease" },
        { "label": "Stroke", "value": "Stroke" },
        { "label": "COPD or other lung disease", "value": "COPD or other lung disease" },
        { "label": "Hernia", "value": "Hernia" },
        { "label": "Thyroid disorders", "value": "Thyroid disorders" },
        { "label": "Kidney disease", "value": "Kidney disease" },
        { "label": "High blood pressure", "value": "High blood pressure" },
        { "label": "Congestive heart failure", "value": "Congestive heart failure" },
        { "label": "Other", "value": "Other" }
    ];
    const surgeries_options = [
        { "label": "Appendectomy (removal of the appendix)", "value": "Appendectomy" },
        { "label": "Cholecystectomy (removal of the gallbladder)", "value": "Cholecystectomy" },
        { "label": "Colectomy (removal of part of the colon)", "value": "Colectomy" },
        { "label": "Gastrectomy (removal of the stomach)", "value": "Gastrectomy" },
        { "label": "Hernia repair", "value": "Hernia repair" },
        { "label": "Lumpectomy (removal of a lump from the breast)", "value": "Lumpectomy" },
        { "label": "Mastectomy (removal of the breast)", "value": "Mastectomy" },
        { "label": "Oophorectomy (removal of the ovaries)", "value": "Oophorectomy" },
        { "label": "Thyroidectomy (removal of the thyroid gland)", "value": "Thyroidectomy" },
        { "label": "Tracheostomy (creation of an opening in the trachea to help with breathing)", "value": "Tracheostomy" }
    ];
    const [allergies_selected, setAllergies_selected] = useState([]);
    const [surgeries_selected, setSurgeries_selected] = useState([]);
    const [symptoms_selected, setSymptoms_selected] = useState([]);

    return (
        <div className='card-body pb-0'>
            <div className="nexogic-second-option-form-wrap">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    <Form>
                        <legend>Medical History</legend>
                        <Row>
                            <Col md={12}>
                                <Field
                                    title="description"
                                    label="Description"
                                    name="description"
                                    value={initialValues.description}
                                    id="description"
                                    type="text"
                                    component={FormikTextAreaField}
                                    placeholder="description"
                                    isTextArea={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="form-group">
                                    <label htmlFor="" className='form-label'>Symptoms</label>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <MultiSelect
                                                options={symptoms_options}
                                                value={symptoms_selected}
                                                onChange={setSymptoms_selected}
                                                labelledBy="Select"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <div className="form-group">
                                    <label htmlFor="" className='form-label'>Allergies</label>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <MultiSelect
                                                options={allergies_options}
                                                value={allergies_selected}
                                                onChange={setAllergies_selected}
                                                labelledBy="Select"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="form-group">
                                    <label htmlFor="" className='form-label'>Surgeries</label>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <MultiSelect
                                                options={surgeries_options}
                                                value={surgeries_selected}
                                                onChange={setSurgeries_selected}
                                                labelledBy="Select"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <Field
                                    title="familyHistory"
                                    label="Do you have a strong family history of any serious illnesses (cancer, heart disease etc)"
                                    name="familyHistory"
                                    value={initialValues.familyHistory}
                                    id="familyHistory"
                                    type="text"
                                    component={FormikTextAreaField}
                                    placeholder="familyHistory"
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <Field
                                    title="pastHospitalization"
                                    label="Any past hospitalizations"
                                    name="pastHospitalization"
                                    value={initialValues.pastHospitalization}
                                    id="pastHospitalization"
                                    type="text"
                                    component={FormikTextAreaField}
                                    placeholder="pastHospitalization"
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <Field
                                    title="currentMedications"
                                    label="Current Medications"
                                    name="currentMedications"
                                    value={initialValues.currentMedications}
                                    id="currentMedications"
                                    type="text"
                                    component={FormikTextAreaField}
                                    placeholder="currentMedications"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className="text-center mt-4">
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </Col>
                        </Row>
                    </Form>
                </Formik>
            </div>
        </div >
    )
}

export default withAlert()(MedicalHistory);