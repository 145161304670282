import React, { useEffect, useState, useCallback } from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withAlert } from "react-alert";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Container,Modal, ModalHeader, ModalBody } from "reactstrap";
import RegisterForm from "./component/RegisterForm";
import { IdentityService } from "utils/IdentityService";
import { authSetUser } from "redux-helpers/ActionCreator";
import { setOAuthTokens, setOAuthUser, setUser, getUser, updateAccessToken } from "utils/UserHelper";
import { cleanUpPhoneNumber } from "utils/Utils";
import Header from "common/Header";

const RegisterPatient = (props) => {
    const { alert } = props;
    const dispatch = useDispatch();
    const currentUser = getUser();
    const history = useHistory();
    const { state } = useLocation();
    const [guid, setGuid] = useState("");
    const [vmode, setVmode] = useState(null);
    const [values, setValues] = useState(null);
    const [showRegistrationSuccessModal, setShowRegistrationSuccessModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null);

    // const toggleRegSuccessModal = (e) => {
    //     setShowRegistrationSuccessModal(false)
    //     history.push('/login')
    // }

    useEffect(() => {
        if (state !== undefined) {
            setGuid(state.guid);
            getVerificationMode(state.guid);
        }
    }, [state]);

    const getVerificationMode = useCallback(
        (guid) => {
          IdentityService.GetVerificationMode(guid)
            .then((response) => {
              if (response.status === 200 || response.status === 201) {
                if (response.data.verificationMode === "PHONE") {
                    setVmode(() => response.data.verificationMode);
                }
                if (response.data.verificationMode === "EMAIL") {
                    setVmode(() => response.data.verificationMode);                 
                }
              }
            })
            .catch((error) => {
              console.log("IdentityService.GetVerificationMode error", error);
            });
        },
        [state, history]
      );
    

    const onSubmit = (values, { setSubmitting }) => {
        setValues(values);
        const payload = {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: cleanUpPhoneNumber(values.phone),
            emailAddress: values.email,
            password: values.password,
            confirmPassword: values.confirmPassword,
        }
        setSubmitting(true);
        IdentityService.AddPersonalDetails(payload, guid)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    // setShowRegistrationSuccessModal(true)
                    const {accessToken,refreshToken,guid} = response.data;

                    IdentityService.GetUserDetails(accessToken, guid).then((userRes) => {
                        if (userRes.status === 200) {
                          const { authorities } = userRes.data;  
                          if (authorities.includes("PATIENT")) {
                            setOAuthTokens(accessToken, refreshToken);
                            setOAuthUser(userRes.data);
                            dispatch(authSetUser(userRes.data));
                            setUser(userRes.data);
                            history.push(`/home`);
                            setSubmitting(false);
                          }
                        }
                    });
                } else if (
                    response.status === 404 &&
                    response.message.includes("No resource profile found with ID")
                ) {
                    props.alert.error(
                        "Session expired. Please validate OTP for registration."
                    );
                    history.push(`/register`);
                } else {
                    if (response.validationErrors) {
                        response.validationErrors.map((err) => {
                            props.alert.error(`${err.field}: ${err.message}`);
                        });
                    } else if (response.message) {
                        props.alert.error(response.message);
                    }
                }
                setSubmitting(false);
            })
            .catch((error) => {
                console.log("IdentityService.RegisterNewUser error", error);
                props.alert.error("Server facing problem. Please try after some time.");
                setSubmitting(false);
            })
            .then(() => {
                setSubmitting(false);
            });
    };

    return (
        <section id="claimProfile">
            <div className="nexogic-bg-light">
                <Header />
                <div className="theme-body-bg nexogic-dashboard-card">
                    <Container className="nexogic-container-gap">
                        <RegisterForm handleSubmit={onSubmit} error={errorMessage} odetails={state} vmode={vmode} />
                    </Container>
                </div>
            </div>
        </section>
    );
};

export default withAlert()(RegisterPatient)