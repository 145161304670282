import logo_img from "assets/images/dummy-icon.png";
import { createImageFromInitials, getRandomColor } from "utils/Utils";
const JobsdetailsBanner = ({ institutionDetails }) => {
  // console.log("institutionDetails", institutionDetails);
  return (
    <>
      <div className="banner-wrap nexogic-job-banner"
        style={
          institutionDetails?.bannerId
            ?
            { backgroundImage: `url(${process.env.REACT_APP_IMAGE_BASE_URL}/${institutionDetails?.guid}/${institutionDetails?.bannerId})`, backgroundSize: "cover" }
            :
            {}
        }
      >
        <div className="gray-shade"></div>
        {/** 
        <div className="comp-logo-wrP">
          {
            institutionDetails?.avatarId
              ? (
                <img
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${institutionDetails?.guid}/${institutionDetails?.avatarId}`}
                  alt="#"
                  className="img-fluid"
                />)
              : (
                <img
                  src={createImageFromInitials(
                    280,
                    institutionDetails?.name,
                    getRandomColor(null)
                  )}
                  alt="profile pic"
                  className="img-fluid"
                />)
          }
        </div>
        */}
        {institutionDetails?.bannerId ? "" : <h1 className="bannerTxt">{institutionDetails?.name}</h1>}
      </div>
    </>
  );
};

export default JobsdetailsBanner;
