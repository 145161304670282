import React from 'react'
import { Card, CardBody, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from "classnames";
import { useState } from 'react';

import NotificationDetails from './NotificationDetails';
import PasswordDetails from './PasswordDetails';

export default function Notifications() {
    const [activeTab, setActiveTab] = useState("Notification");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div className='nexogic-connections-container px-0 row'> 
      <div className="col-md-4 col-lg-3">
          <div className="nexogic-sidebar-stiky mb-3">
            <div className="tabs-border-radius card">
              <ul className='top-links nexogic-aside-menu'>
                <li className='nav-item active'><a href="" className='nav-link'>Profile</a></li>
                {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                  <>
                    <li className='nav-item'><a href="" className='nav-link'>Notifications & Password Settings</a></li>
                    <li className='nav-item'><a href="" className='nav-link'>Insurance</a></li>
                    <li className='nav-item'><a href="" className='nav-link'>Demographic Info</a></li>
                  </>
                }
              </ul>
            </div>
          </div>
        </div>
        <div className='col-md-8 col-lg-9'>
          <div className="border-0 bg-transparent">
            <div className="card-body p-0">
              
                <Nav tabs className="nexogic-tabs">
                            <NavItem>
                            <NavLink
                                className={classnames({
                                  active: activeTab === "Notification",
                                })}
                                onClick={() => {
                                  toggle("Notification");
                                }}
                              >
                                Notification
                              </NavLink>
                            </NavItem>
                            <NavItem>
                            <NavLink
                                className={classnames({
                                  active: activeTab === "Password",
                                })}
                                onClick={() => {
                                  toggle("Password");
                                }}
                              >
                                Password
                              </NavLink>
                            </NavItem>
                            
                        </Nav>
                        <Card>
                <CardBody>
                        {activeTab === "Notification" && (<>
                          <NotificationDetails />
                          </>
                        )}
                        {activeTab === "Password" && <PasswordDetails />}
                        
                        </CardBody>
                        </Card>
                
            </div>
          </div>
        </div>

      
    </div>
  )
}
