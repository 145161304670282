import React from 'react'
import ImgSec from './images/img1.jpg'
import './css/style.css'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'

const SectionTwo = () =>{
  return (
    <Card className="micro-section-card-v4">
        <CardHeader className='main-header'>Section Four Heading</CardHeader>
        <CardBody>
        <CardHeader className="card-header-sec">
            <Row className="row">
                <Col lg={12} >
                    <img src={ImgSec} alt="" width="100%" className="card-img-banner" />
                </Col>
                <Col lg={12} className="card-h-center">
                    <h2 className="card-title">Lorem ipsum dolor sit amet.</h2>
                </Col>
            </Row>
        </CardHeader>
        <Row className="row">
                <Col lg={6} className="card-content">
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Expedita rerum illo ducimus, quasi temporibus modi hic nobis dolore aperiam numquam ea quam adipisci debitis placeat vitae. Ea architecto exercitationem harum ratione voluptas repudiandae in minus at! Hic rerum dignissimos nulla minus eos. Eligendi, ad perferendis.</p>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Expedita rerum illo ducimus, quasi temporibus modi hic nobis dolore aperiam numquam ea quam adipisci debitis placeat vitae. Ea architecto exercitationem harum ratione voluptas repudiandae in minus at! Hic rerum dignissimos nulla minus eos. Eligendi, ad perferendis.</p>
                </Col>
                <Col lg={6} className="card-content">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse odit vero quis. Eaque mollitia dolore tempora ratione nesciunt error, rem non excepturi ad ullam corrupti natus id. Alias odit eos dignissimos nihil numquam ad consequuntur!</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse odit vero quis. Eaque mollitia dolore tempora ratione nesciunt error, rem non excepturi ad ullam corrupti natus id. Alias odit eos dignissimos nihil numquam ad consequuntur!</p>
                </Col>
        </Row>
        </CardBody>
    </Card>
  )
}
export default SectionTwo;