import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import FormikCheckboxField from "components/forms/formikFields/FormikCheckboxField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikInputMonthYearFormatField from "components/forms/formikFields/FormikInputMonthYearFormatField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import InstitutesTypeaheadFieldNew from "components/forms/formikFields/InstitutesTypeaheadFieldNew";
import { Field, Formik } from "formik";
import { useState } from "react";
import { withAlert } from "react-alert";
import { Button, Col, FormGroup, Label, Row, Spinner } from "reactstrap";
import { PositionTypesList } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import { getAddressName, getLocationName } from "utils/Utils";
import * as Yup from "yup";
import NewInstitutionProMembership from "./NewInstitutionProMembership";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

export const yearmonth = /^(0[1-9]|10|11|12)-\d{4}$/;

const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{5})(( x| ext)\d{1,5}){0,1}$/;

const validation = Yup.object().shape({
  institutionDetail: Yup.lazy((value) => {
    if (value && value.onSelectNewInstitutionCollege === 0) {
      return Yup.object().test(
        "",
        "This field is required. You must select from options or add new",
        () => false
      );
    } else if (value && value.onSelectNewInstitutionCollege === 1) {
      return Yup.object();
    } else if (value && value.onSelectNewInstitutionCollege === 2) {
      return Yup.object();
    } else {
      return Yup.mixed().test(
        "",
        "This field is required. You must select from options or add new",
        (value) => value === {}
      );
    }
  }),
  positionHeld: Yup.string().required("This field is required"),
  currentMember: Yup.boolean(),
  startYear: Yup.string()
    .matches(yearmonth, "Please enter valid format (MM-YYYY)")
    .required("This field is required"),
  endYear: Yup.string().when("currentMember", {
    is: false,
    then: Yup.string()
      .matches(yearmonth, "Please enter valid format (MM-YYYY)")
      .test({
        name: "startYear",
        message: "End Year should be greater than Start year",
        test: function (value) {
          if (this.parent.startYear && value) {
            const fullStart = this.parent.startYear.split("-");
            const fullEnd = value.split("-");
            if (fullStart.length > 0 && fullEnd.length > 0) {
              const [startYearM, startYearY] = fullStart;
              const [endYearM, endYearY] = fullEnd;
              return startYearY + startYearM < endYearY + endYearM;
            }
            return false;
          }
          return false;
        },
      })
      .required("This field is required"),
    otherwise: Yup.string(),
  }),
  membershipNumber: Yup.string(),
  city: Yup.array().when("institutionDetail.onSelectNewInstitutionCollege", {
    is: 2,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required"),
  }),
  state: Yup.array().when("institutionDetail.onSelectNewInstitutionCollege", {
    is: 2,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required"),
  }),
  country: Yup.array().when("institutionDetail.onSelectNewInstitutionCollege", {
    is: 2,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required"),
  }),
  location: Yup.array().when(
    "institutionDetail.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.array()
        .min(1, "This field is required")
        .required("This field is required"),
    }
  ),
  mainItemName: Yup.string().when(
    "institutionDetail.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().required("This field is required"),
    }
  ),
  phone: Yup.string().when("institutionDetail.onSelectNewInstitutionCollege", {
    is: 2,
    then: Yup.string().when("phone", {
      is: (val) => val !== "",
      then: Yup.string().matches(
        phoneRegExp,
        "Please enter valid Phone Number"
      ),
      otherwise: Yup.string(),
    }),
  }),
  postalCode: Yup.string().when(
    "institutionDetail.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().required("This field is required"),
    }
  ),
  addressLine1: Yup.string().when(
    "institutionDetail.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().required("This field is required"),
    }
  ),
  websiteAddress: Yup.string().when(
    "institutionDetail.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().when("websiteAddress", {
        is: (val) => val !== "",
        then: Yup.string().url("Please enter a valid URL"),
        otherwise: Yup.string(),
      }),
    }
  ),
  category: Yup.string().when(
    "institutionDetail.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().required("This field is required"),
    }
  ),
});

const RenderMembershipForm = (props) => {
  const { handleSubmit, isLoading, toggle, values } = props;

  const onNewInstitute = () => {
    toggleNested();
  };

  const toggleNested = () => {
    props.setFieldValue(`institutionDetail.onSelectNewInstitutionCollege`, 2);
    props.setFieldValue(`institutionDetail.institutionName`, "Other");
    props.setFieldValue(`institutionDetail.guid`, "");
    props.setFieldValue(`city`, []);
    props.setFieldValue(`state`, []);
    props.setFieldValue(`country`, []);
    props.setFieldValue(`location`, []);
    props.setFieldValue(`postalCode`, "");
    props.setFieldValue(`addressLine1`, "");
    props.setFieldValue(`category`, "");
  };

  return (
    <form id="promembershipForm" className="my-form" onSubmit={handleSubmit}>
      <div>
        <Row form>
          <Col md={12} xs={12} id="nex-ps-membership-institution-detail">
            <Field
              name="institutionDetail"
              id="institutionDetail"
              autocomplete="institutionDetail"
              type="text"
              label="Institution Name"
              component={InstitutesTypeaheadFieldNew}
              onNewInstituteReq={onNewInstitute}
              placeholder="Institution Name"
            />
          </Col>
          {/* <Col md={6} xs={12}>
            <Field
              name="memberShipName"
              id="memberShipName"
              autocomplete="memberShipName"
              label="Membership Name"
              type="text"
              component={FormikInputField}
              placeholder="Membership Name"
            />
          </Col> */}
        </Row>
        {props.values?.institutionDetail?.onSelectNewInstitutionCollege ===
          2 && <NewInstitutionProMembership {...props} />}
        <Row form>
          <Col md={6} xs={12}>
            <Field
              name="positionHeld"
              id="positionHeld"
              autocomplete="positionHeld"
              type="text"
              label="Member Type"
              component={FormikSelectField}
              onChange={(e) => {
                props.setFieldValue(`positionHeld`, e?.target?.value);
                props.setFieldValue(
                  `currentMember`,
                  e?.target?.value &&
                  e?.target?.value.toLowerCase() === "lifetime member"
                );
              }}
              inputprops={{
                name: "positionHeld",
                options: PositionTypesList,
                defaultOption: "Select",
              }}
            />
          </Col>
          <Col md={6} xs={12}>
            <Field
              name="membershipNumber"
              id="membershipNumber"
              label="Membership Number"
              type="text"
              autocomplete="membershipNumber"
              component={FormikInputField}
              placeholder="Your membership number "
            />
          </Col>
        </Row>
        <Row form>
          <Col md={4} xs={12}>
            <Field
              name="startYear"
              id="startYear"
              autoComplete="off"
              label="Member Since"
              type="text"
              component={FormikInputMonthYearFormatField}
              placeholder="MM-YYYY"
            />
          </Col>
          <Col md={4} xs={12}>
            <FormGroup>
              <Label>Expired On</Label>
              {props.values?.currentMember ? (
                <Field
                  name={`Presenttime`}
                  id={`Presenttime`}
                  type="text"
                  placeholder="Present time"
                  autocomplete="Presenttime"
                  value="Present time"
                  disabled
                  className="form-control"
                />
              ) : (
                <Field
                  name="endYear"
                  type="text"
                  id="endYear"
                  autoComplete="off"
                  component={FormikInputMonthYearFormatField}
                  placeholder="MM-YYYY"
                />
              )}
            </FormGroup>
            
          </Col>
          <Col md={4} xs={12} className="">
            <label className="d-xs-none">&nbsp;</label>
            <div className="nexogix-form-check-professional" >
          <Field 
              
              name="currentMember"
              type="checkbox"
              id="currentMember"
              autocomplete="currentMember"
              label="Does not Expire"
              component={FormikCheckboxField}
              placeholder="Does not Expire"
              disabled={
                values?.positionHeld &&
                values?.positionHeld.toLowerCase() === "lifetime member"
              }
            />
            </div>
          </Col>
        </Row>
        {/** <hr className="profile-info-modal-hr" />*/}
        <div form className="justify-content-end  modal-footer ">
          <Col xs="auto" className="action-group">
            {isLoading ? (
              <div className="loading-spiner">
                <Spinner />
              </div>
            ) : (
              <>
                
                <Button
                  id="pms_btn_submit"
                  className="nexogic_primary_button"
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  id="pms_btn_cancel"
                  className="nexogic_primary_button_outline"
                  color="primary"
                  onClick={toggle}
                >
                  Cancel
                </Button>
              </>
            )}
          </Col>
        </div>
      </div>
    </form>
  );
};

const ProMembershipForm = (props) => {
  const { selectedItem, practitionerId, getData, toggle, alert } = props;
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");

  const addItem = (value) => {
    setLoading(true);
    const payload = createPayloadMethod(value);
    const request = PractitionerService.addProMembership(
      practitionerId,
      payload
    );
    request.then(
      (response) => {
        const { status, validationErrors } = response;
        if (status === 200 || status === 201) {
          setTimeout(() => {
            showSuccessMessage("Membership added successfully");
            getData();
            setLoading(false);
            toggle();
          }, 1000);
        } else {
          setLoading(false);
          if (
            validationErrors &&
            validationErrors &&
            validationErrors.length > 0
          ) {
            const error = [];
            validationErrors.forEach((errors) => {
              error.push(errors.message);
            });
            showErrorMessage(error.join(","));
          } else {
            showErrorMessage("error while adding membership");
          }
        }
      },
      (e) => {
        setLoading(false);
        console.log("error", e.message);
        let errmsg = e.message;
        if (e.response) {
          errmsg =
            e.response.data && !!e.response.data.message
              ? e.response.data.message
              : errmsg;
        }
        showErrorMessage(errmsg);
      }
    );
  };
  const updateItem = (value) => {
    setLoading(true);
    const payload = createPayloadMethod(value);
    const request = PractitionerService.updateProMembership(
      practitionerId,
      value.guid,
      payload
    );
    request.then(
      (response) => {
        const { status, validationErrors } = response;
        if (status === 200 || status === 201) {
          setTimeout(() => {
            showSuccessMessage("Membership updated successfully");
            getData();
            setLoading(false);
            toggle();
          }, 1000);
        } else {
          setLoading(false);
          if (
            validationErrors &&
            validationErrors &&
            validationErrors.length > 0
          ) {
            const error = [];
            validationErrors.forEach((errors) => {
              error.push(errors.message);
            });
            showErrorMessage(error.join(","));
          } else {
            showErrorMessage("error while updating membership");
          }
        }
      },
      (e) => {
        setErrorMessage(e.message);
        setLoading(false);
      }
    );
  };

  const createPayloadMethod = (value) => {
    const {
      membershipNumber,
      startYear,
      endYear,
      currentMember,
      positionHeld,
    } = value;
    let payload = value;
    const createPayload = {
      doesNotExpire:
        typeof currentMember !== "undefined" ? currentMember : false,
      institutionDetail: {
        ...(!payload.institutionDetail.guid && {
          address: {
            addressLine1: payload?.addressLine1 ?? "",
            addressLine2: payload?.addressLine2 ?? "",
            addressType: payload?.addressType ?? "BILLING_ADDRESS",
            city: getAddressName(payload?.city),
            state: getAddressName(payload?.state),
            country: getAddressName(payload?.country),
            location: getLocationName(payload?.location),
            postalCode: payload?.postalCode ?? "",
          },
        }),
        ...(payload.institutionDetail.guid && {
          guid: payload.institutionDetail.guid,
        }),
        name: payload.mainItemName
          ? payload.mainItemName
          : payload.institutionDetail.name,
        phone: payload?.phone ?? "",
        websiteUrl: payload?.websiteAddress ?? "",
        ...(payload.category && {
          type: payload.category,
        }),
      },
      memberSince: startYear,
      membershipNumber: membershipNumber,
      name: positionHeld,
      positionHeld: positionHeld,
      ...((typeof currentMember !== "undefined" ? currentMember : false) ===
        false && {
        expiredOn: endYear,
      }),
    };
    return createPayload;
  };
  return (
    <>
      {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
      <Formik
        enableReinitialize={true}
        validationSchema={validation}
        initialValues={{ ...selectedItem }}
        onSubmit={(values) => {
          if (values.guid) {
            updateItem(values);
          } else {
            addItem(values);
          }
        }}
        children={(props) => {
          return (
            <>
              <RenderMembershipForm
                toggle={toggle}
                {...props}
                isLoading={isLoading}
              />
            </>
          );
        }}
      ></Formik>
    </>
  );
};
export default withAlert()(ProMembershipForm);
