import { showErrorMessage, showSuccessMessage } from 'common/AlertContainer';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { withAlert } from 'react-alert';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FAILTOSAVERECOED } from 'utils/Constants';
import { ReferralResponseService } from 'utils/ReferralResponseService';

export default withAlert()(function UpdateStatusModal(props) {
  const { toggle, modal, referralId, responseId, getReferralResponse, statusId, info } = props;

  const [statusValue, setStatusValue] = useState('');
  const [characterCount, setCharacterCount] = useState(0); // New state for character count
  const addPatientStatusInReferralResponse = (status) => {
    if (responseId) {
      let payload = {
        "info": statusValue
      }
      ReferralResponseService.addPatientStatus(referralId, responseId, payload)
        .then((response) => {
          const { status } = response;
          if (status === 201) {
            showSuccessMessage("Patient Status updated Successfully.")
            setStatusValue("");
            getReferralResponse(referralId);
          } else {
            showErrorMessage(FAILTOSAVERECOED)
          }
        })
    }
    else {
      let payload = {
        "patientStatusInfo": {
          "info": statusValue
        }
      }
      ReferralResponseService.addResponse(referralId, payload)
        .then((response) => {
          const { status } = response;
          if (status === 201) {
            showSuccessMessage("Patient Status updated Successfully.")
            getReferralResponse(referralId);
          } else {
            showErrorMessage(FAILTOSAVERECOED)
          }
        })
    }
  }

  const updatePatientStatusInReferralResponse = (status) => {
    let payload = {
      "info": statusValue
    }
    ReferralResponseService.updatePatientStatus(referralId, responseId, statusId, payload)
      .then((response) => {
        const { status } = response;
        if (status === 201) {
          showSuccessMessage("Patient Status updated Successfully.")
          getReferralResponse(referralId);
        } else {
          showErrorMessage(FAILTOSAVERECOED)
        }
      })

  }


  const handleStatusChange = (event) => {
    const value = event.target.value;
    // Replace newline characters with empty strings to get the accurate character count
    const sanitizedValue = value.replace(/(\r\n|\n|\r)/g, '');
    if (sanitizedValue?.length <= 255) {
      setStatusValue(value);
      setCharacterCount(sanitizedValue.length);
    }
  };

  const handleUpdate = () => {
    if (statusId) {
      updatePatientStatusInReferralResponse(statusValue);
    } else
      addPatientStatusInReferralResponse(statusValue);
    toggle(); // Close the modal after updating
  };

  useEffect(() => {
    if (statusId) {
      setStatusValue(info);
      setCharacterCount(info.length)
    }
  }, [statusId])


  return (
    <>
      <Modal isOpen={modal} toggle={toggle} className="modal-sm modal-dialog-centered">
        <ModalHeader toggle={toggle} className="nex-apply-jobs-modal-header"><span className="nex-text-xl">Update Status</span></ModalHeader>
        <ModalBody className='bg-white'>
          <div className="row">
            <div className="col px-2">
              <textarea
                type="text"
                name="info"
                id="info"
                className='form-control'
                placeholder={`${statusId ? 'Update Status' : 'Add Status'}`}
                value={statusValue}
                onChange={handleStatusChange}
                rows={4}
              ></textarea>
            </div>
          </div>
          <div className="row justify-content-end text-right">
            <div className='col'>
              {characterCount < 255 ?
                <small className="form-text text-muted font-weight-light">Remaining <span className='text-success'><b>{255 - characterCount}</b></span> characters</small>
                :
                <small className="form-text text-muted font-weight-light">Remaining <span className='text-danger'><b>{255 - characterCount}</b></span> character</small>
              }
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-end  modal-footer">
          <div className="action-group">
            <Button id="treatment-update-btn" type="submit" value="Update" className='modal-save-btn nex-btn-primary' onClick={handleUpdate} >Update</Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
});
