import { Modal, ModalBody, ModalHeader } from "reactstrap";
import MedicalHistoryForm3 from "../MedicalHistoryForm3";

const MedicalHistoryForm3Modal = (props) => {
    const { modal, setModal } = props;

    return (
        <Modal isOpen={modal} toggle={() => setModal(!modal)} className="nex-model-v2 modal-dialog-scrollable modal-dialog-centered">
            <ModalHeader toggle={() => setModal(!modal)}>Edit Medical History</ModalHeader>
            <ModalBody>
                <MedicalHistoryForm3 />
            </ModalBody>
        </Modal>
    )
}

export default MedicalHistoryForm3Modal;