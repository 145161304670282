import { Col, Row } from "reactstrap";
import attach_1 from "../../../../../assets/images/modal-attach-1.png";
import attach_2 from "../../../../../assets/images/modal-attach-2.png";
import attach_3 from "../../../../../assets/images/modal-attach-3.png";
const PostUploadDocument = () => {
  return (
    <>
      <div className="attach-elem">
        <img src={attach_1} alt="attach_1" />
        <img src={attach_2} alt="attach_1" />
        <img src={attach_3} alt="attach_1" />
      </div>
    </>
  );
};
export default PostUploadDocument;
