import { GET_GLOBAL_PROFILE_USER, SET_GLOBAL_PROFILE_USER } from "./Actions";

let initialState = {
  globalProfileInfo: null,
};

function GlobalProfileReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GLOBAL_PROFILE_USER:
      return {
        ...state,
        globalProfileInfo: action.payload,
      };
    case SET_GLOBAL_PROFILE_USER:
      return {
        ...state,
        globalProfileInfo: action.payload,
      };
    default:
      return state;
  }
}

export default GlobalProfileReducer;
