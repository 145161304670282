import React, { useState, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import FormikSelectField from 'components/forms/formikFields/FormikSelectField';
import { JOB_Work_Shift_TypesList } from 'utils/Constants';

const JobWorkShiftEditModal = (props) => {
    const { modal, setModal } = props;
    const { values, setFieldValue } = useFormikContext();
    const [shift, setShift] = useState(values.shift || '');

    useEffect(() => {
        if (values.shift) {
            setShift(values.shift);
        }
    }, [values.shift]);

    const handleChange = (e) => {
        setShift(e.target.value);
    };

    const handleSubmit = () => {
        setFieldValue("shift", shift, true);
        setModal(false); // Close the modal
    };

    return (
        <Modal isOpen={modal} toggle={() => setModal(!modal)} className="nex-model-v2 modal-dialog-scrollable modal-dialog-centered">
            <ModalHeader toggle={() => setModal(!modal)}>Edit Work Shift</ModalHeader>
            <ModalBody>
                <h2 className='nex-text-md'>Work Shift</h2>
                <div className="form-group gap-md">
                    <Field
                        id="shift"
                        name="shift"
                        component={FormikSelectField}
                        inputprops={{
                            name: `shift`,
                            options: JOB_Work_Shift_TypesList,
                            defaultOption: "Select",
                            keys: {
                                id: "value",
                                label: "label",
                            },
                        }}
                        onChange={handleChange}
                        value={shift}
                    />
                </div>
                <div className="form-group nex-action-flex">
                <button className="btn nex-btn-primary ml-auto" onClick={handleSubmit}>Save</button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default JobWorkShiftEditModal;
