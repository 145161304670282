import React from 'react'

export default function IconCall() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#001244"
        d="M12.487 15.039h-.015c-2.405-.079-5.222-2.41-7.164-4.352C3.365 8.744 1.033 5.925.958 3.53c-.027-.84 2.034-2.333 2.055-2.348.535-.373 1.129-.24 1.373.097.165.229 1.727 2.596 1.897 2.865.177.279.15.694-.07 1.11-.121.231-.523.939-.712 1.269.204.29.742 1.001 1.855 2.114C8.47 9.75 9.18 10.29 9.47 10.494a66.86 66.86 0 011.268-.713c.41-.217.823-.245 1.104-.072.288.176 2.649 1.746 2.867 1.898a.82.82 0 01.323.604c.023.26-.057.533-.223.772-.014.02-1.49 2.056-2.323 2.056z"
      ></path>
    </svg>
  )
}
