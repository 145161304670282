import API_New from "../utils/API_New";
import {
  AUTH_DISCARD_TOKEN,
  AUTH_SET_TOKEN,
  AUTH_SET_USER,
  GET_AFFILIATIONS,
  GET_USER_AFFILIATIONS,
  LOGOUT_USER,
  PROFILE_ADD_EVENT,
  SET_GLOBAL_PROFILE_USER,
  SET_GLOBAL_PROFILE_INSTITUTE,
  UPDATE_PROFILE_PROGRESS,
  SET_COONETIONS_INFO,
  SET_RECENT_EXPERIENCE,
  SET_RECENT_EDUCATION,
  SET_GLOBAL_SEARCHTYPE,
  SET_REFER_STATUS,
} from "./Actions";

export const authSetToken = (token) => {
  return {
    type: AUTH_SET_TOKEN,
    token,
  };
};

const authDiscardToken = () => {
  return {
    type: AUTH_DISCARD_TOKEN,
  };
};

export const authSetUser = (currentUser) => {
  return {
    type: AUTH_SET_USER,
    currentUser,
  };
};
export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};

export const updateProfileCompletness = (index, value) => {
  return {
    type: UPDATE_PROFILE_PROGRESS,
    index,
    value,
  };
};

export const profileAddEvent = (eventName) => {
  return {
    type: PROFILE_ADD_EVENT,
    eventName,
  };
};

export const setGlobalprofile = (data) => {
  return {
    type: SET_GLOBAL_PROFILE_USER,
    payload: data,
  };
};

export const setReferStatus = (data) => {
  return {
    type: SET_REFER_STATUS,
    payload: data,
  };
};
export const setGlobalInstituteProfile = (data) => {
  return {
    type: SET_GLOBAL_PROFILE_INSTITUTE,
    payload: data,
  };
};
export const setGlobalSearchType = (data) => {
  return {
    type: SET_GLOBAL_SEARCHTYPE,
    payload: data,
  };
};

export const setConnectionInfo = (data) => {
  return {
    type: SET_COONETIONS_INFO,
    payload: data,
  };
};

export const getAffiliations = (practitionerid, type) => {
  return (dispatch) => {
    API_New.get(
      `practitioner-service/practitioner/${practitionerid}/facility/schedule`
    ).then((response) => {
      const { data, status } = response.data;
      if (status === "SUCCESS") {
        if (data.facilitySchedules) {
          dispatch({
            type: GET_AFFILIATIONS,
            payload: data.facilitySchedules,
          });
          if (type === "headerAffiliations") {
            dispatch({
              type: GET_USER_AFFILIATIONS,
              payload: data.facilitySchedules,
            });
          }
        } else {
          dispatch({
            type: GET_AFFILIATIONS,
            payload: [],
          });
          if (type === "headerAffiliations") {
            dispatch({
              type: GET_USER_AFFILIATIONS,
              payload: data.facilitySchedules,
            });
          }
        }
      }
    });
  };
};

export const set_Recent_Experience_user = (data) => {
  return {
    type: SET_RECENT_EXPERIENCE,
    payload: data,
  };
};

export const set_Recent_Education_User = (data) => {
  return {
    type: SET_RECENT_EDUCATION,
    payload: data,
  };
};


const exportedObject = {
  authSetToken,
  authDiscardToken,
  authSetUser,
  logoutUser,
  updateProfileCompletness,
  getAffiliations,
  setGlobalprofile,
  setGlobalInstituteProfile,
  setConnectionInfo,
  set_Recent_Experience_user,
  set_Recent_Education_User,
  setGlobalSearchType,
};

export default exportedObject;
