import moment from "moment";
const CommonData = [
    {
        id: 1,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Pending",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 2,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Pending",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "International",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 3,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Pending",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 4,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 5,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 6,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 7,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "International",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 8,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "International",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 9,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 10,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "International",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 11,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 12,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "International",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 13,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 14,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 15,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "International",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 16,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 17,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 18,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 19,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "International",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 20,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 21,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 22,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 23,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "International",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 24,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 25,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 26,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 27,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 28,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 29,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 30,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 31,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 32,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 33,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 34,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "International",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 35,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "International",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 36,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "International",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 37,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 38,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "international",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 39,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 40,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 41,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "International",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 42,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 43,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "International",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 44,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 45,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 46,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 47,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "International",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 48,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    },
    {
        id: 49,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Rejected",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "International",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        },
        reasonForRejection: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute"
    },
    {
        id: 50,
        patientName: "Sophia Jones",
        doctorName: "Dr. Vinodha Reddy",
        hospitalName: "Massachusetts General Hospital",
        dateOfReferal: "Feb 21, 2021",
        status: "Accepted",
        clinicalQuestion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in",
        indicationOrDiagnosis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        specialityRequested: "Lorem ipsum dolor sit ",
        relatedRequest: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        essenstialInfo: {
            refferedDoctorName: "Nagaraj Palankar",
            refferedHospital: "Mayo Clinic",
            patientType: "Domestic",
        },
        refferingPhysicianInfo: {
            refferingPhysicianName: "Dr. Ted Russell",
            dateOfReferal: moment(new Date()).format("MM-DD-YYYY"),
            address: {
                practiceName: "Penn Hospital",
                OfficeAddress: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                NpiNumber: "1659349694"
            },
            contacts: {
                RefferingPhysicianEmail: "ted.russell@pennhospital.com",
                phone: "+44 1902 444141",
                fax: "480-301-4071"
            }
        },
        patientInfo: {
            general: {
                hospitalName: "_",
                insuranceProvider: "Molina HealthCare Inc.",
                primaryCarePhysician: "_"
            },
            personalInfo: {
                maidenName: "_",
                dateOfBirth: "11/11/1997",
                gender: "Female"
            },
            address: {
                address: "98, HAL Airport Road",
                city: "Philadelphia",
                state: "Pensylvania",
                zipCode: "19093",
                country: "United States"
            },
            contacts: {
                mobilePhone: "+1 302-246-1037",
                homePhone: "_",
                workPhone: "_",
                email: "sophiajones@gmail.com"
            },
            OtherInfo: {
                parentName: "_",
                spouseFName: "Landon"
            },
            Languages: {
                interpreterRequired: "Yes",
                Languages: "Chineese,German,Russian",
            }
        }
    }
]
export default CommonData;