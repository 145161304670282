import AlertDialog from "common/AlertDialog";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { UserStatusConstants } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import { DocumentModal } from "./components/DocumentModal";
import DocumentInfo from "./components/DocumentInfo"
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
const RenderDocuments = (props) => {
  const { documents, currentUser, practitioner, getDocumentData, isLoggedinUser } = props;
  const { guid } = practitioner;
  const [dataLoading, setDataLoading] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [clickLoading, setClickLoading] = useState(false);

  useEffect(() => {
    if (documents && documents.length > 0) {
      setLoading(false);
    }
  }, [documents.length, currentUser]);

  if (documents && documents.length === 0) {
    return (
      <div className="px-3 py-2">
        <Row className="align-items-center">
          <Col>{"No data found"}</Col>
        </Row>
      </div>
    );
  }


  if (documents && documents.length > 0 && isLoading === false) {
    return (
      <>
        {/* {clickLoading && (
          <div className="spinner-border text-primary" role="status"></div>
        )} */}
        {documents.map((item, index) => {
          const fileName = item.description ? item.description : item.fileName;
          const parts = (item?.fileName.split("."));
          const extension = parts[parts.length - 1];
          return (
            <DocumentInfo
              isLoggedinUser={isLoggedinUser}
              currentUser={currentUser}
              item={item} index={index} guid={guid} extension={extension} fileName={fileName} getDocumentData={getDocumentData} />
          );
        })}
      </>
    );
  } else {
    return <Col className=" text-primary">Loading..</Col>;
  }
};

const Documents = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { alert, isLoggedinUser, isAdmin } = props;
  const { guid } = props.practitioner;
  const [documents, setDocuments] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);
  const initialValue = {
    documentTitle: "",
    documentUrl: "",
    documentName: "",
    documentType: "",
  };
  const [isOpenAlert, setAlertModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const toggleAlert = () => {
    setLoading(false);
    setAlertModal(!isOpenAlert);
  };
  const getDocumentData = async () => {
    const { data, status } = await PractitionerService.getDocumentsProfile(
      guid
    );
    if (status === 200) {
      setDocuments(data);
    }
  };
  useEffect(() => {
    getDocumentData();
  }, [guid, documents.length]);

  const handleEditItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    toggle();
  };

  const handleRemoveItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    toggleAlert();
  };

  const handleClickOnYes = async () => {
    setLoading(true);
    try {
      const response = await PractitionerService.deleteDocumentsProfile(
        guid,
        selectedItem.id
      );
      const { data, status } = response;
      if (status === 204) {
        setTimeout(() => {
          showSuccessMessage("Document deleted successfully");
          getDocumentData();
          setLoading(false);
          toggleAlert();
        }, 1000);
      } else {
        showErrorMessage("Document not deleted");
        setLoading(false);
        toggleAlert();
      }
    } catch (e) {
      showErrorMessage("Document not deleted");
      setLoading(false);
      toggleAlert();
    }
  };

  if (
    documents &&
    documents.length === 0 &&
    !isAdmin &&
    currentUser.guid !== guid
  ) {
    return null;
  }
  return (
    <>
      <Card
        className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`}
        id="registration_documents"
      >
        <div className="card-header"><h2>Registration Documents</h2>
          {(isAdmin ||
            (isLoggedinUser &&
              (currentUser.status === UserStatusConstants.APPROVED || currentUser.status === UserStatusConstants.DOC_UPLOADED || currentUser.status === UserStatusConstants.PROFILE_CLAIMED))) && (
              <Button id="nex-rd-documents-add-btn" color="link" className="add-button ml-auto" onClick={() => handleEditItem(initialValue)}> <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="9.75" cy="9.75" r="8.4375" fill="white" stroke="#46ABC9" strokeWidth="1.125"/>
              <path d="M9.625 6L9.625 13.2539" stroke="#46ABC9" strokeWidth="1.125" strokeLinecap="round"/>
              <path d="M13.2656 9.63281L6.01172 9.63281" stroke="#46ABC9" strokeWidth="1.125" strokeLinecap="round"/>
              </svg> Add More
              </Button>
            )}
          <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
          <svg width={17}  height={10} viewBox="0 0 17 10" fill="none"  xmlns="http://www.w3.org/2000/svg" >
                        <path
                          d="M2.25 1.625L8.625 8L15 1.625"
                          stroke="black"
                          strokeWidth={3}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
            </svg>
          </button>
        </div>


        <div className={`collapse-div`}>
          <CardBody className="nex-reg-doc-card-body">
            {/* <Row> */}
            <ul className="nex-icon-list-within-card" id="nex-rd-documents-lists">
              <RenderDocuments
                getDocumentData={getDocumentData}
                documents={documents}
                handleEditItem={handleEditItem}
                handleRemoveItem={handleRemoveItem}
                isLoggedinUser={isLoggedinUser}
                currentUser={currentUser}
                isAdmin={isAdmin}
                {...props}
              />
            </ul>
            {/* </Row> */}

            <DocumentModal
              selectedItem={selectedItem}
              toggle={toggle}
              isOpen={isOpen}
              practitionerId={guid}
              getData={getDocumentData}
            />

            <AlertDialog
              toggle={toggleAlert}
              isOpen={isOpenAlert}
              isLoading={isLoading}
              handleClickOnYes={handleClickOnYes}
              yesLabel={"Yes"}
              noLabel={"No"}
            />
          </CardBody>
        </div>
      </Card>
    </>
  );
};

export default withAlert()(Documents);
