import { emailNonNumericRegEx } from "utils/Constants";
import * as Yup from "yup";

Yup.addMethod(Yup.array, "unique", function (field, message) {
  return this.test("unique", message, function (array) {
    const uniqueData = Array.from(
      new Set(array.map((row) => row[field]?.toLowerCase()))
    );
    const isUnique = array.length === uniqueData.length;
    if (isUnique) {
      return true;
    }
    const index = array.findIndex(
      (row, i) => row[field]?.toLowerCase() !== uniqueData[i]
    );
    if (array[index][field] === "") {
      return true;
    }
    return this.createError({
      path: `${this.path}.${index}.${field}`,
      message,
    });
  });
});

export const validationSchemaAdmin = Yup.object().shape({
  name: Yup.string().max(100).required("This field is required"),
  abbreviation: Yup.string(),
  addressLine1: Yup.string().max(100).required("This field is required"),
  websiteURL: Yup.string().matches(
    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
    "Enter a valid url."
  ),
  city: Yup.array()
    .min(1, "This field is required")
    .required("This field is required")
    .nullable(),
  state: Yup.array()
    .min(1, "This field is required")
    .required("This field is required")
    .nullable(),
  country: Yup.array()
    .min(1, "This field is required")
    .required("This field is required")
    .nullable(),
  location: Yup.array()
    .min(1, "This field is required")
    .required("This field is required")
    .nullable(),
  postalCode: Yup.string()
    .length(6, "Zip Code must be exactly 6 characters")
    .matches(/^[0-9]{6}/, "Zip Code must be exactly 6 characters")
    .required("This field is required"),
  phones: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string().required("This field is required"),
        value: Yup.string().required("This field is required"),
      })
    )
    .unique("value", "Please provide a unique Phones."),
  emails: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string().required("This field is required"),
        value: Yup.string()
          .matches(emailNonNumericRegEx, "Please Enter an valid Email")
          .required("This field is required"),
      })
    )
    .unique("value", "Please provide a unique Email."),
  // parent: Yup.string(),
});

export const validationSchema = Yup.object().shape({
  name: Yup.string().max(100).required("This field is required"),
  abbreviation: Yup.string().max(100),
  addressLine1: Yup.string().max(100).required("This field is required"),
  websiteURL: Yup.string().matches(
    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
    /*  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/, */
    "Enter a valid url."
  ),
  city: Yup.array()
    .min(1, "This field is required")
    .required("This field is required")
    .nullable(),
  state: Yup.array()
    .min(1, "This field is required")
    .required("This field is required")
    .nullable(),
  country: Yup.array()
    .min(1, "This field is required")
    .required("This field is required")
    .nullable(),
  location: Yup.array()
    .min(1, "This field is required")
    .required("This field is required")
    .nullable(),
  postalCode: Yup.string()
    .max(10)
    .required("This field is required")
    .nullable(),
  phones: Yup.array()
    .of(
      Yup.object().shape({
        primary: Yup.bool().default(false),
        type: Yup.string().required("This field is required"),
        value: Yup.string().required("This field is required"),
      })
    )
    .min(1, "This field is required")
    .required("This field is required")
    .unique("value", "Please provide a unique Phones."),
  emails: Yup.array()
    .of(
      Yup.object().shape({
        primary: Yup.bool().default(false),
        type: Yup.string().required("This field is required"),
        value: Yup.string()
          .matches(emailNonNumericRegEx, "Please Enter an valid Email")
          .required("This field is required"),
      })
    )
    .min(1, "This field is required")
    .required("This field is required")
    .unique("value", "Please provide a unique Email."),
  parent: Yup.string(),
});
