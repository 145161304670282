import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import ".././styles.scss";

const RenderReferalSuccessModal = (props) => {
  const {
    isOpen,
    toggle
  } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} id="profile-modal" className="modal-dialog-scrollable modal-dialog-centered">
      <ModalHeader className="f-16 line-h-16" style={{ fontWeight: "600px", color: "#2D4552" }} toggle={toggle}>Create a Referal</ModalHeader>
      <ModalBody className="referal-success d-flex flex-column justify-content-center align-items-center">

        <svg className="checkmark-svg m-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
          <path className="checkmark__check" fill="none" stroke="#2F8FCA" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>

        <p className="f-14 line-h-21 font-weight-bold mt-3" style={{ textAlign: 'center', color: "rgba(0, 18, 68, 0.5)" }}>Your Patient referral sent successfully. Thank You!</p>

      </ModalBody>
    </Modal>
  );
}
export default RenderReferalSuccessModal;