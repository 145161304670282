import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Field, useFormikContext } from 'formik';
import FormikInputField from 'components/forms/formikFields/FormikInputField';

const JobNoOfPositionsEditModal = (props) => {
    const { modal, setModal } = props;
    const { values, setFieldValue } = useFormikContext();
    const [inputNumber, setInputNumber] = useState(values?.noOfPosition);

    const handleChange = (e) => {
        console.log(e);
        setInputNumber(e.target.value);
    };

    const handleSubmit = () => {
        // setFieldValue('noOfPosition', inputNumber);
        setModal(false);
    };

    return (
        <Modal isOpen={modal} toggle={() => setModal(!modal)} className="nex-model-v2 modal-dialog-scrollable modal-dialog-centered">
            <ModalHeader toggle={() => setModal(!modal)}>Edit Number of Positions</ModalHeader>
            <ModalBody>
                <div className="form-group gap-md">
                    <h2 className='nex-text-lg text-black'>How many positions do you have?</h2>
                    <Field
                        id="noOfPosition"
                        name="noOfPosition"
                        autoComplete="noOfPosition"
                        type="number"
                        min="1"
                        onChange={handleChange}
                        component={FormikInputField}
                        placeholder="Number of Positions"
                    />
                </div>
                <div className="form-group nex-action-flex">
                <button className="btn nex-btn-primary ml-auto" onClick={handleSubmit}>Save</button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default JobNoOfPositionsEditModal;
