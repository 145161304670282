import moment from "moment";
import { useEffect, useState } from "react";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import plusIcon from "assets/images/plus-icon-lg.png";
import { Button } from "reactstrap";

const Timingslot = ({ title, formatedSchedules, unformatedSchedules, handleEditItem, editType, sList, isAdminUser }) => {
  const [showTimesSlot, setShowTimesSlot] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [today, setToday] = useState("");
  const [isOpenToday, setIsOpenToday] = useState(false);

  useEffect(() => {
    const dayNames = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const today = new Date();
    setToday(dayNames[today.getDay()]);

    if (
      formatedSchedules !== null &&
      Object.keys(formatedSchedules).length > 0
    ) {
      const formData = Object.keys(formatedSchedules).map((dayOfWeek) => {
        const timings = formatedSchedules[dayOfWeek];
        let dayName = dayOfWeek.substr(0, 3);
        let allday = {
          dayName,
          timings: timings.map((time) => {
            const { startTime, endTime } = time;
            const convertedStartTime = moment(startTime, "HHmmss").format(
              "hh:mm A"
            );
            const convertedEndTime = moment(endTime, "HHmmss").format(
              "hh:mm A"
            );
            if (
              moment(endTime, "HHmmss").isValid() &&
              moment(startTime, "HHmmss").isValid() &&
              endTime === "00:00:00" &&
              startTime === "00:00:00"
            ) {
              return "24 hours";
            }
            return moment(endTime, "HHmmss").isValid() &&
              moment(startTime, "HHmmss").isValid()
              ? `${convertedStartTime} - ${convertedEndTime}`
              : "Closed";
          }),
        };
        return allday;
      });
      setShowTimesSlot(formData);
    }
    checkIfOpenNow();
  }, [formatedSchedules]);

  function checkIfOpenNow() {
    const dayNames = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    const todayDate = new Date();
    const todayDay = (dayNames[todayDate.getDay()]);

    for (let schedule of unformatedSchedules) {
      if (schedule?.scheduleType === "HOSPITAL_HOURS" && schedule?.dayOfWeek === todayDay) {
        let todayDate1 = new Date();
        let str = todayDate1.toString().substring(16, 24);
        if (str >= schedule?.startTime && str <= schedule?.endTime) {
          setIsOpenToday(true);
          break;
        }
        else {
          setIsOpenToday(false);
        }
      }
    }
  }

  setInterval(checkIfOpenNow, 30 * 1000); // 30 * 1000 milsec


  return (
    <div className="timing-box">
      <div className="d-flex">
        {isAdminUser && (
          <div className="edit-action">
            <button
               id="ts-btn-del"
              // type="button"
              // className="visible-hover edit py-0 btn btn-link"
              color="link"
              className="add-button border-0"
              onClick={() => handleEditItem(formatedSchedules, editType)}
            >
              {(showTimesSlot.length > 0 && sList !== null) ?
              <svg width="40" height="40" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="13.5" cy="13.5" r="12.375" fill="white" stroke="#DBF4FB" stroke-width="0.75" />
                          <path d="M16.3125 8.25L15.0938 9.46875L17.5312 11.9062L18.75 10.6875L16.3125 8.25ZM13.875 10.6875L9 15.5625V18H11.4375L16.3125 13.125L13.875 10.6875Z" fill="#46ABC9" />
                        </svg>
                        :
              // <img src={(showTimesSlot.length > 0 && sList !== null) ? edit_blue_icon : plusIcon} alt="" />
              <img src={plusIcon} alt="" />}
            </button>
          </div>
        )}
      </div>
      {showTimesSlot.length > 0 && sList !== null ? (
        <div className="hospital-timings">
          <div className="schedule-timings">
            {showTimesSlot.map((item, index) => {
              return (
                <div key={index} className={`${(showAll || (today === item?.dayName)) ? 'on' : 'off'}`}>
                  <div className="date-time-slot" key={index}>
                    <div className="clock-icon">
                      <span className="date">
                        {(showAll || (today === item?.dayName)) && item?.dayName}
                      </span>
                    </div>

                    <div className="slot-seperator">
                      {item?.timings.map((node, index) => (
                        (showAll || (today === item?.dayName)) &&
                          <div
                            className={node === 'Closed' ? "nex-status-pill-close times closed" : "times"}
                            key={`${index}`}
                          >{`${node}`}

                          </div>
                      )
                      )}
                      {today === item?.dayName && isOpenToday && <span><small className="nex-status-pill-open">Open Now</small></span>}
                    </div>
                  </div>
                </div>
              );
            })}
            {showAll && <a className="readmore" onClick={() => setShowAll(!showAll)}> {showAll ? 'Show Less' : 'Show More'} </a>}
            {!showAll && <a className="readmore" onClick={() => setShowAll(!showAll)}> {showAll ? 'Show Less' : 'Show More'} </a>}
          </div>
        </div>
      ) : (
        isAdminUser &&
        <p className="card-text">
          <Button
            id="ts-btn-add-business-hours"
            className="readmore p-0"
            color="link"
            onClick={() => handleEditItem(formatedSchedules, editType)}
          > {"Add business hours"}</Button>
        </p>
      )}
    </div>
  );
};

Timingslot.defaultProps = {
  title: "OPD Hours",
  formatedSchedules: null,
};

export default Timingslot;
