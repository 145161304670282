import { Field, Formik } from "formik";
import { useMemo, useState, useEffect, Fragment } from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import * as Yup from "yup";
import { InstitutionServices } from "utils/InstitutionServices";
import { FAILTOSAVERECOED } from "utils/Constants";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import { withAlert } from "react-alert";
import { removeComma } from "utils/Utils";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
const validationSchema = Yup.object().shape({
    services: Yup.string().required("This field is required"),
});

const ServiceFormBulkEdit = (props) => {
    const { alert, toggle, institution, getInstitutionProfile } = props;
    const initValues = useMemo(() => {
        return {
            services: '',
        };
    }, []);

    const [initialValues, setInitialValues] = useState(initValues);
    const onSubmit = (values) => {
        let str = removeComma(values.services);
        str = str.split(",");
        str = str.map(string => string.trim());
        let payload = [
            {
                op: "add",
                path: "/services",
                value: str
            },
        ];

        InstitutionServices.patchInstitutionDetails(payload, institution.guid)
            .then((response) => {
                const { status } = response;
                if (status === 200) {
                    toggle();
                    getInstitutionProfile(institution.guid);
                    showSuccessMessage("Institution details updated successfully");
                }
                else {
                    showErrorMessage(FAILTOSAVERECOED);
                }
            })
    }

    useEffect(() => {
        let str = institution?.services.join(",  ");
        setInitialValues({ services: str });
    }, [institution.id])


    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={onSubmit}
                children={(props) => {
                    return (
                        <>
                            <RenderForm toggle={toggle} {...props} />
                        </>
                    );
                }}
            />
        </div>
    )
}

const RenderForm = (props) => {
    const {
        values,
        handleSubmit,
        setFieldValue,
        isLoading,
        toggle
    } = props;

    return (
        <form onSubmit={handleSubmit}>
            <Fragment>
                <fieldset className="u-flsd-wpr mb-3">
                    <legend>Enter values in comma seperated format</legend>
                    <div className="form-group">
                        <Field
                            id="services"
                            name="services"
                            type="textarea"
                            component={FormikInputField}
                            placeholder="value1, value2, value3,..."
                            autoComplete="off"
                            value={values.services}
                            spellCheck={false}
                            style={{ lineHeight: "1.5" }}
                            className="fixed"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    if ((e.target.value).charAt((e.target.value).length - 1) !== ",") {
                                        e.target.value = e.target.value + ',\n';
                                    }
                                }
                            }}
                        />
                    </div>
                </fieldset>
            </Fragment>
            <div className="justify-content-end  modal-footer">
                <div lg={12} md={12} sm={12} className="action-group col-auto">
                    {isLoading ? (
                        <div className="loading-spiner">
                            <Spinner style={{ width: "2rem", height: "2rem" }} />
                        </div>
                    ) : (
                        <>
                            <Button id="sfb_btn_submit" className="modal-save-btn nex-btn-primary" color="primary" type="submit">Save</Button>
                            <Button id="sfb_btn_cancel" className="modal-cancel-btn nex-btn-primary-outline" color="primary " type="button" onClick={toggle}>Cancel</Button>
                        </>
                    )}
                </div>
            </div>

        </form>
    );
}

export default withAlert()(ServiceFormBulkEdit);