import React, { useEffect, useState, useRef } from "react";
import { Label } from "reactstrap";
import { ErrorMessage } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";

import "assets/styles/typeahead.css";

const YearTypeaheadField = (props) => {
  const { disabled, inputProps, placeholder, className } = props;
  const { name, value } = props.field;
  const { setFieldValue, setFieldTouched, touched, errors } = props.form;
  const typeaRef = useRef();

  const handleChange = (selected) => {
    setFieldValue(name, selected[0], true);
  };

  return (
    <>
      {props.label && (
        <Label htmlFor={name} className="d-block skills">
          {props.label}
        </Label>
      )}
      <Typeahead
        ref={typeaRef}
        className={className}
        disabled={disabled}
        clearButton
        id={name}
        options={props.years}
        labelKey={"value"}
        placeholder={placeholder}
        selected={value ? [value && value.toString()] : []}
        onChange={handleChange}
        onBlur={() => {
          setFieldTouched(name, true);
          return typeaRef !== null ? typeaRef.current.hideMenu() : null;
        }}
        isInvalid={Boolean(touched[name] && errors[name])}
      />
      <ErrorMessage
        name={name}
        render={(msg) => (
          <span className="text-danger skill-err f-12 d-inline-block  mt-1 line-height-error">
            {msg}
          </span>
        )}
      />
    </>
  );
};
export default YearTypeaheadField;
