import React from "react";

const RejectionReason = ({ values }) => {
    return (
        <div className="nexogic-form-text-display">
            <div className="nexogic-text-box">
                <h6 className="nexogic-form-label">Reason for Rejection</h6>
                <p className="">{values.reasonForRejection}</p>
            </div>
        </div>
    )
}
export default RejectionReason;