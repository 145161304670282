import AlertDialog from "common/AlertDialog";
import CommonLoader from "common/CommonLoader";
import NoJobs from "pages/Jobs/NoJobs";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { withAlert } from "react-alert";
import { FAILTOSAVERECOED } from "utils/Constants";
import { TalentService } from "utils/TalentService";
import {
  Badge,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor } from "utils/Utils";
import moment from "moment";
import "./jobTracking.css";

import {
  faChevronDown,
  faCommentDots,
  faEllipsisV,
  faMapMarkerAlt,
  faShare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WhatsappShareButton } from "react-share";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import ShowStatus from "./ShowStatus";
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import graduation_cap from "assets/images/graduation-cap.png";
import suitcase_icon from "assets/images/suitcase.png";

const JobTracking = forwardRef((props, ref) => {
  const location = useLocation();
  const { jobId } = useParams();
  const { state } = location;
  const { alert } = props;
  const [dropDownItem, setDropDownItem] = useState();
  // const [dropDownItem, setDropDownItem] = useState();
  const actions = ["Shortlist", "Reject", "Hold"];
  const interview = ["Interview", "Hold"];
  const offered = ["Offer", "Hold", "Reject"];
  const cstatus = ["Accept", "Hold", "Reject"];
  const onhold = ["Shortlist", "Interview", "Offer", "Accept", "Hire"];
  const hired = ["Hire", "Hold"];
  const closed = ["Close"];

  const [list_of_data, setList_of_data] = useState(null);
  const [jobApplicationIdToReject, setJobApplicationIdToReject] =
    useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenAlert, setAlertModal] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const getData = async (pageNo, feedlist) => {
    const request = TalentService.getJobApplications(jobId, pageNo);
    request
      .then(async (response) => {
        let { data } = response;
        if (data?.currentPage < (data?.totalPages - 1))
          setHasMore(true);
        else
          setHasMore(false);
        setIsLoadingMore(false);
        if (pageNo === 0) {
          const allData = { ...data };
          const resolved = await Promise.all(
            allData.contents.map(async (item) => {
              const each = { ...item };
              return each;
            })
          );
          allData.contents = resolved;
          setList_of_data(allData);
          setIsLoading(false);
        } else {
          const resolved = await Promise.all(
            data.contents.map(async (item) => {
              const each = { ...item };
              return each;
            })
          );
          feedlist = feedlist.concat(resolved);
          setList_of_data({ ...data, contents: feedlist });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("Error while getting job list: ", error);
        setIsLoadingMore(false);
      });
  };

  const fetchData = () => {
    setIsLoadingMore(false);
    getData(list_of_data.currentPage + 1, list_of_data.contents);
  };

  const toggleAlert = () => {
    setAlertModal(!isOpenAlert);
  };

  useImperativeHandle(ref, () => ({
    getJobApplications() {
      getData(0, []);
    },
  }));

  const handleUpdateJobStatus = (jobId, status) => {
    let statusFlag = '';
    if (status === "Reject") {
      setJobApplicationIdToReject(jobId);
      toggleAlert();
      return;
    } else if (status === "Shortlist") {
      statusFlag = "SHORTLISTED"
    } else if (status === "Interview") {
      statusFlag = "INTERVIEW"
    } else if (status === "Offer") {
      statusFlag = "OFFERED"
    } else if (status === "Offer") {
      statusFlag = "OFFERED"
    } else if (status === "Accept") {
      statusFlag = "ACCEPTED"
    } else if (status === "Hire") {
      statusFlag = "HIRED"
    } else if (status === "Hold") {
      statusFlag = "ON_HOLD"
    } else {
      statusFlag = "CLOSED"
    }

    const payload = [
      {
        op: "replace",
        path: "/status",
        value: statusFlag,
      },
    ];
    TalentService.patchJobApplication(jobId, payload).then((response) => {
      const { status } = response;
      if (status === 200) {
        showSuccessMessage("Jobs Application has been updated Successfully...");
        getData(0, []);
      } else {
        showErrorMessage(FAILTOSAVERECOED);
      }
    });
  };

  const handleClickOnYes = () => {
    const payload = [
      {
        op: "replace",
        path: "/status",
        value: "REJECTED",
      },
    ];

    TalentService.patchJobApplication(jobApplicationIdToReject, payload).then(
      (response) => {
        const { status } = response;
        if (status === 200) {
          showSuccessMessage("Jobs Application has been rejected Successfully...");
          getData(0, []);
        } else {
          showErrorMessage(FAILTOSAVERECOED);
        }
      }
    );
    toggleAlert();
  };

  useEffect(() => {
    getData(0, []);
  }, []);

  if (isLoading) {
    return (
      <div className="loading-spiner text-center  mt-2">
        <CommonLoader style={{ width: "2rem", height: "2rem" }} />
      </div>
    );
  }

  const getFullSpeciality = (primarySpeciality, superSpeciality) => {
    let fullSpeciality = "";
    if (
      primarySpeciality !== null &&
      primarySpeciality != undefined &&
      primarySpeciality !== ""
    ) {
      fullSpeciality += primarySpeciality;
    }

    if (
      superSpeciality !== null &&
      superSpeciality != undefined &&
      superSpeciality !== ""
    ) {
      fullSpeciality += " | ";
      fullSpeciality += superSpeciality;
    }

    return fullSpeciality;
  };
  const RenderExpYear = ({ practiceStartYear }) => {
    let currentYear = new Date().getFullYear();
    if (isNaN(practiceStartYear) || currentYear - practiceStartYear < 1) {
      return "1 year experience";
    } else {
      return `${currentYear - practiceStartYear}+ years experience`;
    }
  };

  return (
    <>
      <div className="nex-job-applicant">
        <div className="mt-4 nex-text-xxl mb-3">
          Applicants for  &nbsp;
          <span>
            {state?.params?.jobTitle ? state?.params?.jobTitle : "Job"}
          </span>
        </div>
        <p className="nex-text-lg mb-3">Have a look, how many people have applied for the role</p>
        {list_of_data && list_of_data.contents.length > 0 ? (
          <div>
            <div>
              {list_of_data &&
                list_of_data.contents.map((item, idx) => (
                  <Card className="nex-job-list-card">
                   {/**  <div className="nex-del-btn">
                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 7.50781L2.08789 15.5504C2.31115 17.201 2.42279 18.0263 2.98808 18.5202C3.55338 19.0142 4.38619 19.0142 6.05179 19.0142H9.95602C11.6216 19.0142 12.4544 19.0142 13.0197 18.5202C13.585 18.0263 13.6967 17.201 13.9199 15.5504L15.0078 7.50781" stroke="#46ABC9" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 4.00167V4.00167C5 3.06822 5 2.60149 5.15274 2.23342C5.3557 1.74434 5.74434 1.3557 6.23342 1.15274C6.60149 1 7.06822 1 8.00167 1V1C8.93513 1 9.40185 1 9.76992 1.15274C10.259 1.3557 10.6476 1.74434 10.8506 2.23342C11.0033 2.60149 11.0033 3.06822 11.0033 4.00167V4.00167" stroke="#46ABC9" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.0078 4L1 4" stroke="#46ABC9" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.5 8.00781L6.00028 15.512" stroke="#46ABC9" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.5081 8.00781L10.0078 15.512" stroke="#46ABC9" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                    </div>*/}
                    <div className="card-head">
                        <div className="nex-job-quick-info">
                            <div className="nex-avatar lg">
                            <Link
                              to={`/profile/${item?.applicant?.profileId}`}
                            >
                              {item.applicant?.avatarId ? (
                                <img
                                  src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${item.applicant?.profileGuid}/${item.applicant?.avatarId}`}
                                  alt="#"
                                  className="card-img"
                                />
                              ) : (
                                <img
                                  src={createImageFromInitials(
                                    40,
                                    item.applicant?.name.replace('Dr. ', ''),
                                    getRandomColor(null)
                                  )}
                                  alt="profilePic"
                                  width={64}
                                  className="card-img"
                                />
                              )}
                            </Link>
                          </div>
                          <div className="info">
                        <h3 className="nex-text-lg bold">
                          <Link className="text-black"
                            to={`/profile/${item?.applicant?.profileId}`}
                          >
                            {item.applicant?.name}</Link>
                        </h3>
                        
                        <div className="nex-text-lg bold nex-text-blue mb-2">{getFullSpeciality(
                          item.applicant?.primarySpeciality,
                          item.applicant?.superSpeciality
                        )}</div>
                        


                      </div>
                        </div>
                      
                      
                      <div className="nexogic-job-keyval-lists">
                        <div className="nex-text-lg text-clamp"><img src={graduation_cap} alt="" /> {item.applicant?.qualification}</div>
                        <p className="nex-text-lg">{/*<b>Experience:</b>*/} <img src={suitcase_icon} alt="" /> <RenderExpYear
                          practiceStartYear={
                            item?.applicant?.practicingSince
                          }
                        /></p>
                        <div className="nex-text-lg text-black">
                          {getFullAddressWithLocationCityAndState({ location: item?.applicant?.location ?? "", city: item?.applicant?.city ?? "" }) !== "" &&
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              className="mr-1"
                            />}
                          {getFullAddressWithLocationCityAndState({ location: item?.applicant?.location ?? "", city: item?.applicant?.city ?? "" })}
                        </div>


                      </div>
                      <div className="nexogic-job-keyval-lists applied-on-col">
                        <p className={`nex-text-lg nex-status-pill-w 
                          ${item?.status === 'CLOSED' ? 'badge-dengar ' : ''}
                          ${item?.status === 'APPLIED' ? 'badge-green ' : ''} 
                          `}><ShowStatus status={item?.status} /></p>
                        <p className="nex-text-md text-black"><b>Applied On:</b> {`${moment(item?.createdOn).format(
                          "Do MMM, YYYY"
                        )}`}</p>
                      </div>
                      <div className="action job-apply-btns">
                        
                          <Link
                            className="nex-btn-primary d-inline-block"
                            to={{
                              pathname: `/application-details/${item?.id}`,
                              state: {
                                params: {
                                  jobTitle: state?.params?.jobTitle,
                                  jobProfileId:
                                    state?.params?.jobProfileId,
                                },
                              },
                            }}
                            color="primary"
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            View Details{/*View Detailed Application*/}
                          </Link>
                        
                        <div className="jobs-btns-action">
                          <div className="right-action-wrp">
                            {(item?.status === "APPLIED" || item?.status === "SHORTLISTED" || item?.status === "INTERVIEW" || item?.status === "OFFERED" || item?.status === "ACCEPTED" || item?.status === "HIRED" || item?.status === "ON_HOLD") && (
                              <Dropdown
                                isOpen={dropDownItem == item.id}
                                toggle={(e) =>
                                  dropDownItem === item?.id
                                    ? setDropDownItem(null)
                                    : setDropDownItem(item?.id)
                                }
                              >
                                <DropdownToggle
                                  // disabled={item.status !== "APPLIED"}
                                  color="primary"
                                  className="common-icon"
                                >
                                  <FontAwesomeIcon icon={faEllipsisV} />
                                </DropdownToggle>
                                <DropdownMenu right>
                                  {(() => {
                                    let actionsList;
                                    if (item?.status === "APPLIED") {
                                      actionsList = actions;
                                    } else if (item?.status === "SHORTLISTED") {
                                      actionsList = interview;
                                    } else if (item?.status === "INTERVIEW") {
                                      actionsList = offered;
                                    } else if (item?.status === "OFFERED") {
                                      actionsList = cstatus;
                                    } else if (item?.status === "ACCEPTED") {
                                      actionsList = hired;
                                    } else if (item?.status === "HIRED") {
                                      actionsList = closed;
                                    } else if (item?.status === "ON_HOLD") {
                                      actionsList = onhold;
                                    } else {
                                      actionsList = [];
                                    }
                                    {/* console.log("actionsList",actionsList) */ }
                                    return (
                                      actionsList.map((action, index) => (
                                        <DropdownItem
                                          key={index}
                                          onClick={(e) => {
                                            handleUpdateJobStatus(item?.id, action);
                                          }}
                                        >
                                          {item?.status != action && action}
                                        </DropdownItem>
                                      ))
                                    )
                                  })()}


                                  {/* {actionsList.map((action) => (
                                    <DropdownItem
                                      key={action}
                                      onClick={(e) => {
                                        handleUpdateJobStatus(item?.id, action);
                                      }}
                                    >
                                      {item?.status != action && action}
                                    </DropdownItem>
                                  ))} */}
                                </DropdownMenu>
                              </Dropdown>
                            )}
                            {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && <>
                              <Link to={"/messages"} className="common-icon">
                                <FontAwesomeIcon
                                  icon={faCommentDots}
                                  className="ja-bottom-bar-icon"
                                />
                              </Link>
                              <Link to={"/messages"} className="common-icon">
                                <FontAwesomeIcon
                                  icon={faShare}
                                  className="ja-bottom-bar-icon"
                                />
                              </Link>
                              <Link to={"/messages"} className="common-icon">
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="ja-bottom-bar-icon"
                                />
                              </Link>
                              <Link to={"/messages"} className="common-icon">
                                <WhatsappShareButton title="Job">
                                  <FontAwesomeIcon
                                    icon={faWhatsapp}
                                    className="ja-bottom-bar-icon"
                                  />
                                </WhatsappShareButton>
                              </Link>
                            </>}
                          </div>
                        </div>
                        
                      </div>
                    </div>

                    <CardBody className="d-none">
                      <Row>
                        <Col md={6} sm={12} xs={12}>
                          <Row>
                            <Col lg={2} md={3} sm={3} xs={3}>

                            </Col>
                            <Col
                              lg={10}
                              md={9}
                              sm={9}
                              xs={9}
                              className="ja-basic-details"
                            >
                              <Row>
                                <Col lg={12}>

                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <div className="ja-speciality">

                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>

                                </Col>
                              </Row>
                              {/* <Row>
                                <Col>
                                  <a href="tel:+919837012345">
                                    {" "}
                                    +919837012345[S]
                                  </a>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <a href="mailto:test@gmail.com">
                                    test@gmail.com[S]
                                  </a>
                                </Col>
                              </Row> */}
                            </Col>
                          </Row>
                        </Col>
                        <Col md={5} sm={12} xs={12}>
                          <Row className="ja-other-details">
                            <Col
                              className="ja-other-details-label"
                              lg={3}
                              md={4}
                              sm={4}
                              xs={12}
                            >

                            </Col>
                            <Col
                              className="ja-other-details-value"
                              lg={9}
                              md={8}
                              sm={8}
                              xs={12}
                            >

                            </Col>
                          </Row>
                          {/* <Row className="ja-other-details">
                            <Col
                              className="ja-other-details-label"
                              lg={3}
                              md={4}
                              sm={4}
                              xs={12}
                            >
                              Address:
                            </Col>
                            <Col
                              className="ja-other-details-value"
                              lg={9}
                              md={8}
                              sm={8}
                              xs={12}
                            >
                              {item.applicant?.location}
                              {", "}
                              {item.applicant?.city}
                              {", "}
                              {item.applicant?.state}
                              {", "}
                              {item.applicant?.country}
                            </Col>
                          </Row> */}

                          <Row className="ja-other-details">
                            <Col
                              className="ja-other-details-label"
                              lg={3}
                              md={4}
                              sm={4}
                              xs={12}
                            >
                              Applied On:
                            </Col>
                            <Col
                              className="ja-other-details-value"
                              lg={9}
                              md={8}
                              sm={8}
                              xs={12}
                            >{`${moment(item?.createdOn).format(
                              "Do MMM, YYYY"
                            )}`}</Col>
                          </Row>
                          <Row className="ja-other-details">
                            <Col
                              className="ja-other-details-label"
                              lg={3}
                              md={4}
                              sm={4}
                              xs={4}
                            >
                              Status:
                            </Col>
                            <Col
                              className="ja-other-details-value"
                              lg={9}
                              md={8}
                              sm={8}
                              xs={8}
                            >
                              <ShowStatus status={item?.status} />
                            </Col>
                            <Col md={12}>
                              <div className="ja-other-details">
                                <div className="ja-other-details-label">
                                  <Link
                                    className="d-block"
                                    to={{
                                      pathname: `/application-details/${item?.id}`,
                                      state: {
                                        params: {
                                          jobTitle: state?.params?.jobTitle,
                                          jobProfileId:
                                            state?.params?.jobProfileId,
                                        },
                                      },
                                    }}
                                    color="primary"
                                    onClick={() => window.scrollTo(0, 0)}
                                  >
                                    View Detailed Application
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>

                      </Row>
                    </CardBody>
                  </Card>
                ))}

              {hasMore &&
                <div className="text-center mt-3">
                  {isLoadingMore
                    ?
                    <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                    :
                    <LoadMoreButton loadMore={fetchData} />
                  }
                </div>
              }

              <AlertDialog
                bodyText={"Are you sure, you want to Reject this Candidate ?"}
                toggle={toggleAlert}
                isOpen={isOpenAlert}
                // isLoading={isLoading}
                handleClickOnYes={handleClickOnYes}
                yesLabel={"Yes"}
                noLabel={"No"}
              />
            </div>
          </div>
        ) : (
          <NoJobs content={"No Job Application"} />
        )}
      </div>
    </>
  );
});

export default withAlert()(JobTracking);
