import React, { useState, useEffect } from 'react'
import google_img from "assets/images/social-google-img.png";
import facebook_img from "assets/images/facebook-social-img.png";
import apple_img from "assets/images/apple-social-img.png";
import { Field, Form, Formik } from 'formik';
import { Button, InputGroup } from 'reactstrap';
import { emailNonNumericRegEx } from 'utils/Constants';
import FormikInputField from 'components/forms/formikFields/FormikInputField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import * as Yup from "yup";
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import CommonLoader from 'common/CommonLoader';

const validationSchemaEmailPassord = Yup.object({
    email: Yup.string()
        .max(100)
        .matches(emailNonNumericRegEx, "Please Enter an valid Email")
        .required("Please Enter an valid Email"),
    password: Yup.string()
        .required("Please Enter Password")
});

const SocialIconsForm = ({ handleGoogle, handleFacebook, handleApple, handleEmail, useOtp, setUseOtp, pageFlag,loading,setLoading }) => {
    const [showPassword, onShowPassword] = useState(false)
    const toggleShowPassword = () => onShowPassword(!showPassword);
    // const [isComponentMounted, setIsComponentMounted] = useState(true);
    const isComponentMounted = useRef(true);

    useEffect(() => {
        return () => {
            isComponentMounted.current = false;
        };
    }, []);

    const handleFormSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            await handleEmail(values, setSubmitting);
        } catch (error) {
            console.error(error);
        } 
        // finally {
        //     if (isComponentMounted.current) {
        //         setSubmitting(false);
        //     }
        // }
    };

    return (
        <>
        {/** <p className='nex-text-xl text-center text-dark'>Continue with</p>*/}
        <div className='d-flex flex-column'>
            <div className="nex-social-login-btns-w">
                <button className="btn" onClick={handleGoogle}><span className='icon'><img src={google_img} alt="" width='50' /></span><span className='label'>Continue with Google</span></button>
                <button className="btn" onClick={handleFacebook}><span className='icon'><img src={facebook_img} alt="" width='50' /></span><span className='label'>Continue with Facebook</span></button>
                <button className="btn nex-social-apple-btn" onClick={handleApple}><span className='icon'><img src={apple_img} alt="" width='50' /></span><span className='label'>Continue with Apple</span></button>
            </div>
            {pageFlag === "LOGIN" ?
                <>
                <div className="nex-or-txt--w">or</div>
                {/* <p className="form-or-text text-secondary font-weight-bold pb-0">Or</p> */}
                    <div>
                        <Formik
                            initialValues={{ email: '', password: '' }}
                            validationSchema={validationSchemaEmailPassord}
                            onSubmit={handleFormSubmit}
                        >
                            {({ handleSubmit, isSubmitting, errors }) => (
                                <Form onSubmit={handleSubmit}>
                                    <InputGroup className="mt-3" size="medium">
                                        <Field
                                            id="email"
                                            name="email"
                                            type="email"
                                            component={FormikInputField}
                                            // label="Email Address"
                                            placeholder="Email Address" />
                                    </InputGroup>
                                    <InputGroup className="" size="medium">
                                        <div className="nexogic-password-field-wrap password">
                                            <Field
                                                id="password"
                                                name="password"
                                                type={showPassword ? "text " : "password"}
                                                component={FormikInputField}
                                                placeholder="Password" />
                                            {!!errors.password === false &&
                                                <FontAwesomeIcon onClick={toggleShowPassword} className="show-password " color="#ccc" icon={!showPassword ? faEyeSlash : faEye} />
                                            }
                                        </div>
                                    </InputGroup>
                                    {loading ? (
                                        <div className="loading-spiner text-center">
                                            <CommonLoader />
                                        </div>
                                    ) : <Button id="nex-continue-form-next" className="btn btn-primary lg w-100" type="submit" disabled={isSubmitting}> Continue </Button>
                                    }
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className="form-group mt-4 nex-checkbox--w">
                        <Link to={'/reset-password'} className="nex-text-md nex-link-dark mb-3"><b>Forgot Password?</b></Link>
                        <div className="nex-checkbox--w mt-3">
                            <input
                                type="checkbox"
                                name="useOtp"
                                id="useOtp"
                                className="nex-check-input cursor-pointer"
                                checked={useOtp}
                                onChange={() => setUseOtp(!useOtp)}
                            />
                            <p className="nex-text-md">Login with your registered mobile number</p>
                        </div>
                    </div>
                </>
                : "" }
        </div>
        </>
    )
}

export default SocialIconsForm