import "assets/styles/typeahead.css";
import { ErrorMessage } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";
import { Label } from "reactstrap";

const LocationTypeaheadField = (props) => {
  const { disabled, placeholder, className, selectdLocation, locationRef, onChange } = props;
  const { name, value } = props.field;
  const { setFieldValue, setFieldTouched, touched, errors } = props.form;

  const handleChange = (selected) => {
    setFieldValue(name, selected, true);
    if (onChange) {
      onChange(selected); // Call the custom onChange handler passed from parent
    }
  };

  const setOnBlur = (name, flag) => {
    setFieldTouched(name, flag);
    locationRef.current.hideMenu();
  };
  return (
    <>
      {props.label && (
        <Label htmlFor={name} className="d-block skills">
          {props.label}
        </Label>
      )}
      <Typeahead
        ref={locationRef}
        className={className}
        disabled={disabled}
        selected={value}
        id={name}
        options={props.locations}
        labelKey={"value"}
        placeholder={placeholder}
        allowNew={true}
        onChange={handleChange}
        onBlur={(e) => setOnBlur(name, true)}
        isInvalid={Boolean(touched[name] && errors[name])}
        inputProps={{
          autoComplete: name,
        }}
      />
      <ErrorMessage
        name={name}
        render={(msg) => (
          <span className="text-danger skill-err f-12 d-inline-block  mt-1 line-height-error">
            {msg}
          </span>
        )}
      />
    </>
  );
};
export default LocationTypeaheadField;
