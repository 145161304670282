import React, { useState } from 'react';
import { useParams, useLocation, useHistory } from "react-router-dom";
import logo_white from "assets/images/logo-white-transparent.png";
import arrow_left from "assets/images/arrowback.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import ProfileCardSingleItem from "../PractitionerProfiles/ProfileCardSingleItem";
import SocialIconsForm from "../SocialIconsForm";
import { UserAuth } from "context/AuthContext";
import { Field, Form, Formik } from 'formik';
import { Button, InputGroup } from 'reactstrap';
import FormikMobileField from 'components/forms/formikFields/FormikMobileField';
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { useRef } from 'react';
import VerifyOtp from 'pages/LoginSocialProfile/VerifyOtp';
import Swal from 'sweetalert2';
import ReactDOMServer from 'react-dom/server';

const ProfileClaimNew = () => {
  const { profileId } = useParams();
  const history = useHistory();
  const { state } = useLocation();
  const { googleSignUp, facebookSignUp, appleSignUp, signUpWIthEmail, signInWithPhone, signUpWIthMobileOTP, user } = UserAuth();
  const [isPatient, setIsPatient] = useState(false);
  const [userProfileId, setUserProfileId] = useState("");
  // const [otpSent, setOtpSent] = useState(false);
  const [otpVerify, setOtpVerify] = useState("");
  const [verificationId, setVerificationId] = useState(null);
  const isComponentMounted = useRef(true);
  const pageFlag = "CLAIM";
  const [otpSent, setOtpSent] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");

  const handleGoogleSignUp = async () => {
    try {
      const signedInUser = await googleSignUp();
      const { name, email, idToken, refreshToken, profiledetails, phoneNumber, provider } = signedInUser;
      if (!profiledetails) {
        try {
          history.push(`${(isPatient) ? '/u/create-account' : '/register/onboarding'}`, {
            firstName: name?.split(' ')[0],
            lastName: name?.split(' ')[1] || '',
            email: email,
            phoneNumber: phoneNumber,
            token: idToken,
            refreshToken: refreshToken,
            utype: isPatient,
            claim: userProfileId ? true : false,
            profileId: userProfileId ?? "",
            provider: provider,
          });
        } catch (apiError) {
          console.error('API call failed:', apiError);
          showErrorMessage("Something went wrong..please try after sometime");
          history.push('/login');
        }
      } else {
        console.log("Account Already Exists..");
        showErrorMessage("Account Already Exists..Please Login");
        history.push('/login')
      }
    } catch (error) {
      console.error('Google sign-in failed:', error);
    }
  }

  const handleFacebookSignUp = async () => {
    try {
      const signedInUser = await facebookSignUp();
      const { name, email, idToken, refreshToken, profiledetails, phoneNumber, provider } = signedInUser;
      if (!profiledetails) {
        try {
          history.push(`${(isPatient) ? '/u/create-account' : '/register/onboarding'}`, {
            firstName: name?.split(' ')[0],
            lastName: name?.split(' ')[1] || '',
            email: email,
            phoneNumber: phoneNumber,
            token: idToken,
            refreshToken: refreshToken,
            utype: isPatient,
            claim: userProfileId ? true : false,
            profileId: userProfileId ?? "",
            provider: provider,
          });
        } catch (apiError) {
          console.error('API call failed:', apiError);
          showErrorMessage("Something went wrong..please try after sometime");
          history.push('/login');
        }
      } else {
        console.log("Account Already Exists..");
        showErrorMessage("Account Already Exists..Please Login");
        history.push('/login')
      }
      // }
    } catch (error) {
      if (error.code === 'auth/account-exists-with-different-credential') {
      const content = (
        <div className='d-flex flex-column'>
          <h5 className='text-warning'>An account already exists with the same email address but different sign-in credentials. Please sign in using the associated provider(Google/Apple).</h5>
        </div>
      );
      const { isConfirmed } = await Swal.fire({
        title: ' ',
        html: ReactDOMServer.renderToString(content),
        icon: 'warning',
        showCancelButton: false,
        // cancelButtonText: 'Cancel',
        confirmButtonText: 'Proceed',
      });
      if (isConfirmed) {
        history.push(`/login`);
      }
    }
      // console.log("Full error details:", JSON.stringify(error, null, 2));
      console.error('Facebook sign-in failed:', error);
    }
  }


  const handleAppleSignUp = async () => {
    try {
      const signedInUser = await appleSignUp();
      const { name, email, idToken, refreshToken, profiledetails, phoneNumber, provider } = signedInUser;
      if (!profiledetails) {
        try {
          history.push(`${(isPatient) ? '/u/create-account' : '/register/onboarding'}`, {
            firstName: name?.split(' ')[0],
            lastName: name?.split(' ')[1] || '',
            email: email,
            phoneNumber: phoneNumber,
            token: idToken,
            refreshToken: refreshToken,
            utype: isPatient,
            claim: userProfileId ? true : false,
            profileId: userProfileId ?? "",
            provider: provider,
          });
        } catch (apiError) {
          console.error('API call failed:', apiError);
          showErrorMessage("Something went wrong..please try after sometime");
          history.push('/login');
        }
      } else {
        console.log("Account ALready Exists..");
        showErrorMessage("Account Already Exists..Please Login");
        history.push('/login')
      }
    } catch (error) {
      console.error('Google sign-in failed:', error);
    }
  }

  const handleEmailPasswordSignUp = async (values, setSubmitting) => {
    try {
      const signedInUser = await signUpWIthEmail(values.email, values.password);
      const { name, email, idToken, refreshToken, profiledetails, phoneNumber, provider } = signedInUser;
      if (!profiledetails) {
        try {
          history.push(`${(isPatient) ? '/u/create-account' : '/register/onboarding'}`, {
            firstName: name?.split(' ')[0],
            lastName: name?.split(' ')[1] || '',
            email: email,
            phoneNumber: phoneNumber,
            token: idToken,
            refreshToken: refreshToken,
            utype: isPatient,
            claim: userProfileId ? true : false,
            profileId: userProfileId ?? "",
            provider: provider,
            // guid: state.props.profileGuid
          });
        } catch (apiError) {
          console.error('API call failed:', apiError);
          showErrorMessage("Something went wrong..please try after sometime");
          history.push('/login');
        }
      } else {
        console.log("Account ALready Exists..");
        showErrorMessage("Account Already Exists..Please Login");
        history.push('/login')
      }
    } catch (error) {
      console.error('Google sign-in failed:', error);
    } finally {
      if (isComponentMounted.current) {
        setSubmitting(false);
      }
    }
  }
  //Mobile 
  const handleSendOtp = async (phoneNumber, setSubmitting) => {
    await signInWithPhone(phoneNumber)
      .then((confirmationResult) => {
        showSuccessMessage("OTP sent successfully");
        setMobileNumber(phoneNumber);
        setOtpSent(true);
        // setOtpSent(true);
        // history.push('/register/verify-otp');
        // history.push({
        //   pathname: '/register/verify-otp',
        //   state: { userProfileId:userProfileId,utype: isPatient },
        // });
      })
      .catch((error) => {
        console.error('Error sending OTP:', error);
        showErrorMessage('Unable to send OTP, Please refresh the page and try again');
      })
      .finally(() => setSubmitting(false));
  };


  const handleSignUpWithMobileOtp = async (code) => {
    try {
      const signedInUser = await signUpWIthMobileOTP(code)
      if (signedInUser) {
        const { name, email, idToken, refreshToken, profiledetails, phoneNumber, provider } = signedInUser;
        if (!profiledetails) {
          try {
            history.push(`${(isPatient) ? '/u/create-account' : '/register/onboarding'}`, {
              firstName: name?.split(' ')[0],
              lastName: name?.split(' ')[1] || '',
              email: email,
              phoneNumber: phoneNumber,
              token: idToken,
              refreshToken: refreshToken,
              utype: isPatient,
              claim: userProfileId ? true : false,
              profileId: userProfileId ?? "",
              provider: provider,
            });
          } catch (apiError) {
            console.error('API call failed:', apiError);
            showErrorMessage("Something went wrong..please try after sometime");
            history.push('/login');
          }
        } else {
          console.log("Account ALready Exists..");
          showErrorMessage("Account Already Exists..Please Login");
          history.push('/login')
        }
      }
    } catch (error) {
      console.error('Mobile sign-in failed:', error);
      showErrorMessage("Invalid Otp, Authentication failed")
    }
  }

  useEffect(() => {
    if (profileId) {
      setUserProfileId(profileId);
    }
  }, [profileId]);

  useEffect(() => {
    AOS.init();
  }, [])

  useEffect(() => {
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  return (
    <div className="nex-bg-light-blue">
      <main className="nex-login-register--w">
        <div className="container sm d-flex" data-aos="fade-up">
          <div className="nex-form-aside nex-form-aside-v2" >
            <div className="logo" data-aos="fade-up" data-aos-duration="800" data-aos-delay="100">
              <a href="/"><img src={logo_white} alt="" /></a>
            </div>

            <h2 className="nex-text-xxl" data-aos="fade-up" data-aos-duration="800" data-aos-delay="150">Claim your profile</h2>
          </div>
          <div className="nex-form-fields--w nex-form-fields--w-v2">
            <div className="nex-form-top-slot nex-mob-hide" data-aos="fade-down" data-aos-duration="800" data-aos-delay="200">
              <span className='text-gray nex-link cursor-pointer' onClick={() => history.goBack()}><img src={arrow_left} width="21" className="icon" />Back</span>
            </div>
            <div className="nex-form-center-slot mt-0">
              <div className="nex-form-container pt-3">
                {!otpSent ? (
                  <>
                    <div className="nex-docor-profile-select-w">
                      <ProfileCardSingleItem profileId={profileId} viewMode={false} />
                    </div>
                    <div className='nex-social-login-btns--w' data-aos="fade-down" data-aos-duration="800" data-aos-delay="350">
                      <SocialIconsForm handleEmail={handleEmailPasswordSignUp} handleGoogle={handleGoogleSignUp} handleFacebook={handleFacebookSignUp} handleApple={handleAppleSignUp} />
                    </div>
                    <div className="nex-or-txt--w ">or</div>
                    <div className='nex-form--w'>
                      <Formik
                        initialValues={{ phoneNumber: '' }}
                        onSubmit={({ phoneNumber }, { setSubmitting }) => handleSendOtp(phoneNumber, setSubmitting)}
                      >
                        {({ handleSubmit, isSubmitting, errors }) => (
                          <Form onSubmit={handleSubmit}>
                            <div className="nexogic-phone-field-wrap">
                              <InputGroup className="mt-3" size="large">
                                <Field
                                  id="mnf_phon"
                                  name="phoneNumber"
                                  type="text"
                                  component={FormikMobileField}
                                  placeholder="Phone Number" />
                              </InputGroup>
                            </div>
                            <Button id="btn_sign_in" size="lg" block className="btn btn-primary w-100" type="submit" disabled={isSubmitting}>Continue</Button>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </>
                ) :
                  <VerifyOtp handleVerifyOtp={handleSignUpWithMobileOtp} mobileNumber={mobileNumber} setOtpSent={setOtpSent} />
                }
              </div>
            </div>
          </div>
        </div>
        <div id="recaptcha-container"></div>
      </main>
    </div>
  )
}

export default ProfileClaimNew;