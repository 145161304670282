import React,{useEffect} from 'react';
import '../css/style.css'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

const Section01List = (props) => {
  const {fieldDetails,themeDetails} = props;
  const {id, scontent, slabel} = fieldDetails;

  const sectionColors = (sThemeColor) => {
    // const frmDoc = document.documentElement.style.setProperty;
    document.documentElement.style.setProperty('--section-bg-color', sThemeColor[3]);
    document.documentElement.style.setProperty('--section-headerbg-color', sThemeColor[4]);
    document.documentElement.style.setProperty('--section-headerfont-color', sThemeColor[5]);
    document.documentElement.style.setProperty('--section-font-color', sThemeColor[2]);
    document.documentElement.style.setProperty('--section-heading-color', sThemeColor[1]);
}

useEffect(()=>{
  if(themeDetails.length >0){
    sectionColors(themeDetails)
  }
},[themeDetails])

  // console.log("Inside Section 01---------------",fieldDetails);
  return (
    <>
    <Card className='micro-section-card-vs1'>
        <CardHeader className='main-header section-header'>{fieldDetails.slabel}</CardHeader>
        <CardBody className='bg-color'>
        <Row className="row">
                {/* <Col lg={6} className="card-graphic"></Col> */}
                <Col lg={12} className="card-content">
                    <ul>
                    {fieldDetails.scontent.map((clist,index)=>{
                        return <li key={index} value={clist}>{clist}</li>
                    })}
                        </ul>                    
                    {/* {fieldDetails.scontent} */}
                    </Col>
            </Row>            
        </CardBody>
    </Card>
    </>       

  )
}

export default Section01List