import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import logo_white from "assets/images/logo-white-transparent.png";
import arrow_back from "assets/images/nex-arrow-back.svg";
import eye_pass from "assets/images/eye-pass.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Field, Formik } from "formik";
import FormikCheckboxField from "components/forms/formikFields/FormikCheckboxField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikMobileField from "components/forms/formikFields/FormikMobileField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import * as Yup from "yup";
import { useMemo } from "react";
import { useState } from "react";
import { Button } from "reactstrap";
const validationSchema = Yup.object().shape({
    
    rememberMe: Yup.boolean().oneOf(
      [true],
      "Accept Terms & Conditions is required "
    ),
    password: Yup.string()
      .min(8)
      .max(32)
      .required("This field is required")
      .matches(
        /^(?=(.*[a-z]){3,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,}).{8,}$/,
        "Too weak. Try to add lower-case, upper-case, numbers, special characters, 8 or more characters"
      )
      .matches(
        /(?=(.*[!@#$%^&*()\-__+.]){1,})/,
        "Moderate. Try to add aleast 1 special characters or symbols"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    claimsProfileId: Yup.string(),
  });

const NewPasswordDoctor = (props) => {
    const [isSubmitting, setSubmitting] = useState(false);
    const initValues = useMemo(() => {
        return {
          
          password: "",
          confirmPassword: "",
          rememberMe: false,
        };
      }, []);
    
      const [initialValues, setInitialValues] = useState(initValues);
      const onSubmit = (values) => {
        console.log(values);
        
      };
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <div className="nex-bg-light-blue">
            <main className="nex-login-register--w">
                <div className="container sm d-flex" data-aos="fade-up">
                    <div className="nex-form-aside">
                        <div className="logo" data-aos="fade-up" data-aos-duration="800" data-aos-delay="100">
                            <a href="/"><img src={logo_white} alt="" /></a>
                        </div>
                        
                        <h2 className="nex-text-xxl" data-aos="fade-up" data-aos-duration="800" data-aos-delay="150">Create your Password</h2>
                    </div>
                    <div className="nex-form-fields--w">
                        <div className="nex-form-top-slot" data-aos="fade-down" data-aos-duration="800" data-aos-delay="200">
                            <div className="nex-form-top-actions nex-text-lg d-none d-lg-flex">
                                <a href="#" className="text-gray nex-back-btn"><img src={arrow_back} alt="" /></a>
                                <a href="#" className="text-gray nex-cross-btn">&times;</a>
                            </div>
                        </div>
                        <div className="nex-form-center-slot">
                        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
          children={(props) => {
            return (
              <>
                <RenderForm
                  {...props}
                  isSubmitting={isSubmitting}
                />
              </>
            );
          }}
        />  {/**
                            <div className="nex-form-container">
                                <div className="form-group-fields--w">
                                    <p className="nex-text-lg mb-3 text-black" data-aos="fade-down" data-aos-duration="800" data-aos-delay="250">Create a password to continue with your login</p>
                                    <div className="form-group" data-aos="fade-down" data-aos-duration="800" data-aos-delay="300">
                                        <div className="form-group-password">
                                            <input type="password" name="" id="" className="form-control error" placeholder="Enter Password" />
                                            <i className="eye-icon"><img src={eye_pass} alt="" /></i>
                                        </div>
                                        <p className="nex-error-text">Password must be at least 8 characters</p>
                                    </div>
                                
                                <div className="form-group form-group-password" data-aos="fade-down" data-aos-duration="800" data-aos-delay="350">
                                    <input type="password" name="" id="" className="form-control" placeholder="Confirm Password" />
                                    <i className="eye-icon"><img src={eye_pass} alt="" /></i>
                                </div>
                                <div className="form-group" data-aos="fade-down" data-aos-duration="800" data-aos-delay="400">
                                    <div className="nex-checkbox--w flex">
                                        <input type="checkbox" name="" id="" className="nex-check-input" />
                                        <p>I have read and agree to the <a href="" className="text-light-purple">Terms and Conditions </a>and <a href="" className="text-light-purple">Privacy Policy</a></p>
                                    </div>
                                </div>
                                <div className="form-group" data-aos="fade-down" data-aos-duration="800" data-aos-delay="450">
                                    <input type="submit" value="Register" className="btn btn-primary w-100" />
                                </div>
                                </div>
                            </div>
*/}
                        </div>
                        
                    </div>
                </div>
            </main>
        </div>
    )
}

export default  NewPasswordDoctor;

const RenderForm = (props) => {
    const {
        values,
        errors,
        isSubmitting,
        handleSubmit,
        addressDetails,
        userProfileId,
      } = props;
      const [showPassword, onShowPassword] = useState(false);
      const toggleShowPassword = () => onShowPassword(!showPassword);
      return (
        <>
        <form className="personal-details-form" onSubmit={handleSubmit}>
        <div className="nex-form-container">
                                <div className="form-group-fields--w">
                                    <p className="nex-text-lg mb-3 text-black" data-aos="fade-down" data-aos-duration="800" data-aos-delay="250">Create a password to continue with your login</p>
                                    <div className="password-eye">
                  <Field
                    id="password"
                    name="password"
                    autoComplete="password"
                    type={showPassword ? "text " : "password"}
                    component={FormikInputField}
                    placeholder="Enter Password"
                  />
                  {!!errors.password === false && (
                    <FontAwesomeIcon
                      onClick={toggleShowPassword}
                      className="show-password"
                      color="#ccc"
                      icon={!showPassword ? faEyeSlash : faEye}
                    />
                  )}
                </div>
                                
                <div className="password-eye">
                  <Field
                    id="confirmPassword"
                    name="confirmPassword"
                    autoComplete="confirmPassword"
                    type={showPassword ? "text " : "password"}
                    component={FormikInputField}
                    label=""
                    placeholder="Confirm Password"
                  />
                  {!!errors.password === false && (
                    <FontAwesomeIcon
                      onClick={toggleShowPassword}
                      className="show-password"
                      color="#ccc"
                      icon={!showPassword ? faEyeSlash : faEye}
                    />
                  )}
                </div>
                                <div className="form-group" data-aos="fade-down" data-aos-duration="800" data-aos-delay="400">
                                    <div className="nex-checkbox--w flex">
                                    <Field
            id="rememberMe"
            name="rememberMe"
            type="checkbox"
            component={FormikCheckboxField}
          />
                                        <p>I have read and agree to the <a href="" className="text-light-purple">Terms and Conditions </a>and <a href="" className="text-light-purple">Privacy Policy</a></p>
                                    </div>
                                </div>
                                <div className="form-group" data-aos="fade-down" data-aos-duration="800" data-aos-delay="450">
                                <Button
          id="nex-pdf-form-next"
          className="btn btn-primary w-100"
          type="submit"
          disabled={isSubmitting}
          //onClick={() => addressDetails(address)}
        >
          Register
        </Button>
                                    
                                </div>
                                </div>
                            </div>
        </form>
        </>
      )
}