import React from 'react'
import doc_icon from 'assets/images/svgs/upload_icon.svg'
import { useRef } from 'react';

export default function ReportUpload(props) {
  const uploadFileInput = useRef(null);
  return (
    <div className='nexogic-upload-card'>
        <div className="graphic">
            <img src={props.image || doc_icon} alt="" />
        </div>
        <div className="desc">
            <p className='card-text light'>You don’t have any uploaded medical records</p>
            <p className='card-text light'><a className='link' onClick={(e)=>{e.preventDefault();uploadFileInput.current.click()}}>Select record</a> or Drag & Drop here</p>
            <input id='ru_file' name='ru_file' type="file" ref={uploadFileInput} className="d-none" />
        </div>
    </div>
  )
}
