import API_New from "./API_New";
import API_Factory from "./API_Factory";

export const Post_actions = (data) => {
  return API_New.post(`practitioner-service/practitioners/feeds/action`, data);
};

export const create_post = (data) => {
  return API_New.post(`practitioner-service/practitioners/feeds/create`, data);
};

export const upload_on_s3 = (data) => {
  return API_New.post(
    `practitioner-service/practitioners/860/media/upload/FEED`,
    data,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};
export const Suggested_Conn = () => {
  return API_New.get(
    `practitioner-service/practitioners/suggested-by-practitionerid/2`
  );
};

export const get_media_url_s3 = (id) => {
  return API_New.get(`practitioner-service/getobject/${id}`);
};

export const get_feed_by_id = async (url) => {
  return await API_New.get(url);
};

export const registerJob = (data) =>
  API_Factory({
    method: "POST",
    url: `${process.env.REACT_APP_API_TALENT_SERVICE}/jobs`,
    data,
    ignoreToken: false,
  });

export const applyJob = (job_id, data) =>
  API_Factory({
    method: "POST",
    url: `${process.env.REACT_APP_API_TALENT_SERVICE}/${job_id}/applications`,
    data,
    ignoreToken: false,
  });

export const listJob = (searchQuery = null) => {
  let strUrl = searchQuery
    ? Object.entries(searchQuery)
      .map(([key, val], index) => {
        if (index === 0) {
          return `?${key}=${val}`
        }
        return `${key}=${val}`
      })
      .join("&")
    : "";
  return API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_TALENT_SERVICE}/jobs${strUrl}`,
    data: searchQuery,
    ignoreToken: false,
  });
};

export const updateJob = (data, job_id) =>
  API_Factory({
    method: "PUT",
    url: `${process.env.REACT_APP_API_TALENT_SERVICE}/jobs/${job_id}`,
    data,
    ignoreToken: false,
  });

export const getJob = (job_id) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_TALENT_SERVICE}/jobs/${job_id}`,
    ignoreToken: false,
  });

export const getJobApplicantStatus = (job_id) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_TALENT_SERVICE}/my/applications/${job_id}`,
    ignoreToken: false,
  });

export const listMyJobs = (pageNo, status = "") => {
  return API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_TALENT_SERVICE}/my/jobs?cp=${pageNo}&sq=st==${status}`,
    ignoreToken: false,
  });
};

export const listAllMyJobs = (pageNo) => {
  return API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_TALENT_SERVICE}/my/jobs?cp=${pageNo}`,
    ignoreToken: false,
  });
};

export const deleteJob = (jobId) => {
  return API_Factory({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_TALENT_SERVICE}/jobs/${jobId}`,
    ignoreToken: false,
  });
};

export const patchJob = (job_id, data) =>
  API_Factory({
    method: "PATCH",
    url: `${process.env.REACT_APP_API_TALENT_SERVICE}/jobs/${job_id}`,
    data,
    ignoreToken: false,
    headers: {
      "Content-Type": "application/json-patch+json"
    }
  });

export const getJobApplications = (jobId, pageNo = 0) => {
  return API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_TALENT_SERVICE}/${jobId}/applications?cp=${pageNo}&nr=5`,
    ignoreToken: false,
  });
};

export const patchJobApplication = (job_id, data) =>
  API_Factory({
    method: "PATCH",
    url: `${process.env.REACT_APP_API_TALENT_SERVICE}/applications/${job_id}`,
    data,
    ignoreToken: false,
    headers: {
      "Content-Type": "application/json-patch+json"
    }
  });

export const getJobApplicationById = (jobApplicationId) => {
  return API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_TALENT_SERVICE}/applications/${jobApplicationId}`,
    ignoreToken: false,
  });
}

export const getMyAppliedJobs = (pageNo = 0) => {
  return API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_TALENT_SERVICE}/jobs/by-applicants?cp=${pageNo}&nr=10`,
    ignoreToken: false,
  });
};

export const TalentService = {
  registerJob,
  updateJob,
  listJob,
  getJob,
  getJobApplicantStatus,
  applyJob,
  listMyJobs,
  listAllMyJobs,
  deleteJob,
  patchJob,
  getJobApplications,
  patchJobApplication,
  getJobApplicationById,
  getMyAppliedJobs
};
