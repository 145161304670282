import React, { useEffect, useState, useRef } from "react"
import { Label } from 'reactstrap'
import { ErrorMessage } from 'formik';
import { Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import { isEmpty } from "lodash"

import 'assets/styles/typeahead.css'

const AssociatedPractitionersOpinionTypeaheadField = (props) => {
    const { disabled, inputProps, placeholder, className, getSelectedSpeciality, multiple, allowNew, activeFlag} = props
    const { name, value } = props.field
    const { setFieldValue, setFieldTouched, touched, errors } = props.form
    const [selectedSpeciality, setSelectedSpeciality] = useState("");
    const [allowMultiple, setSllowMultiple] = useState(false);
    const [allowNewFlag, setallowNewFlag] = useState(false);
    const typeaRef = useRef();

   
    useEffect(() => {
        if(multiple === true){
            setSllowMultiple(true)
        }
        if(allowNew === true){
            setallowNewFlag(true)
        }
        if(activeFlag === true){
            setSelectedSpeciality(""); 
            setFieldValue(`practitioner`,[]);
            typeaRef.current.hideMenu();
        }
    },[multiple,activeFlag])

    const loadmoreBtn ={
        backgroundColor:'#e9ecef',
        textAlign:'center',
        color:'#3498db',
        textDecoration:'none',
        }

    const handleChange = (selected) => {
        if(selected[0]?.value){
            setFieldValue(name, [selected[0]?.value], true)
        }else{
            setFieldValue(name, selected, true)
        }
        if(getSelectedSpeciality != undefined){
            if(selected !=undefined && selected.length > 0){
                setSelectedSpeciality(selected[0]);
            }else{
                setSelectedSpeciality("");
                getSelectedSpeciality("");
            }
        }
    }

    const setOnBlur = (name, flag) => {
        setFieldTouched(name, flag)
        if(getSelectedSpeciality != undefined){
            getSelectedSpeciality(selectedSpeciality);
        }
        typeaRef.current.hideMenu();
    }

    function getPractitionersDetails(result) {
        let name = result.name ?? '';
        let primarySpeciality = result.primarySpeciality ?? '';
        // var PractitionerDetails =result.name!==undefined ? (<div style={{width:'100%',backgroundColor:'#e9ecef',color:'blue',textAlign:'center'}}>`${result.name} , ${result.primarySpeciality}`</div>) : (<div style={{width:'100%',backgroundColor:'#e9ecef',color:'blue',textAlign:'center'}}>Load More</div>)
        var PractitionerDetails =result.name!==undefined ? (<div style={{width:'100%',borderBottom:'2px solid rgba(128, 128, 128, 0.5)',paddingBottom:'4px'}}>{result.name} | {result.qualification} <br />{result.location} | {result.city} | {result.state}</div>) : (<div style={{width:'100%',backgroundColor:'#e9ecef',color:'blue',textAlign:'center'}}>Load More</div>)
        return PractitionerDetails;
      }
      const customeLabelKey = (option) => `${option.name}`;

    return (
        <>
            {props.label && <Label htmlFor={name} className="d-block">{props.label}</Label>}
            <Typeahead
                ref={typeaRef}
                className={className}
                disabled={activeFlag}
                clearButton
                selected={activeFlag ? [] : value}
                id={name}
                options= {activeFlag ? [] : props.practitioners}
                labelKey={customeLabelKey}
                maxResults={10}
                placeholder={placeholder}
                multiple={allowMultiple}
                allowNew={allowNewFlag}
                onChange={handleChange}
                onBlur={(e) => setOnBlur(name, true)}
                renderMenu={(results, menuProps) => {
                    if (!results.length) {
                      return null;
                    }
                    return (
                      <Menu {...menuProps}>
                        {results.map((result, index) => (
                          (result.label !=='') ?
                          <MenuItem key={result.profileId} option={result} position={index} style={{textDecoration:'none'}}>
                            {getPractitionersDetails(result)}
                          </MenuItem>
                          :
                          <MenuItem key={result.profileId} option={result} position={index} style={loadmoreBtn}>
                            Load More
                          </MenuItem>
                        ))}
                      </Menu>
                    );
                  }}
                isInvalid={Boolean(touched[name] && errors[name])}
            />
            <ErrorMessage name={name} render={msg => <span className="text-danger skill-err f-12 d-inline-block  mt-1 line-height-error">{msg}</span>} />
        </>
    )
}
export default AssociatedPractitionersOpinionTypeaheadField;