import React, { useState, useMemo } from 'react'
import { Row, Col, CardBody, Container } from 'reactstrap'
import { withAlert } from "react-alert"
import * as Yup from 'yup';
import { Formik } from 'formik'; 
import { map } from 'lodash'

import { PractitionerService } from "utils/PractitionerService"
import { cleanEmptyNodes } from "utils/Utils"
import Loader from 'components/Loader'
import UserCard from './components/UserCard'
import Paginations from './components/Paginations'
import SearchCriteriaForm from './components/SearchCriteriaForm'


const searchValidationSchema = Yup.object().shape({
  name: Yup.string(),
  facility: Yup.lazy(value => {
    if(value && value.id){
        return Yup.object().shape({ id: Yup.string().required("This field is required. You must select from options") }) 
    } else { return Yup.mixed().test("", "You must select from options", (value) => value !== {}) } 
}),
  speciality: Yup.array(),
  // profileVerified: Yup.boolean(),
  // profileClaimed: Yup.boolean(),
  status: Yup.string(),
  city: Yup.string(),
  location: Yup.string()
});

const initialValues = {
  name: '',
  facility: '',
  speciality: [],
  status: 'All',
  // profileVerified: false,
  // profileClaimed: false,
  city: '',
  location: ''
}

const Search = () => {
  
  const [allPractitioners, setAllPractitioners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const currentPosts = useMemo(() => {
    if(allPractitioners.length === 0) return []
    return allPractitioners.slice(indexOfFirstPost, indexOfLastPost);
  })

  // Change page
  const paginate = pageNumber => {
             window.scrollTo(0, 0)
             setCurrentPage(pageNumber);
          }

  const getPractitioners = (searchData) => {
    setLoading(true)
    let payload = {...searchData}
    payload = cleanEmptyNodes(payload)

    if(!!payload.city) {
      if(payload.city.indexOf(',') != -1)
        payload.city = payload.city.substr(0, payload.city.indexOf(','))
      else
        payload.city = payload.city
    }
    if(!!payload.speciality && payload.speciality.length>0) {
      payload.primarySpeciality = payload.speciality[0].id
      delete payload.speciality
    }
    if(!!payload.facility && payload.facility.id) {
      payload.facility = payload.facility.id
    }
    if(payload.status === "") delete payload.status
    // payload.status = 'reviewed'
    const request = PractitionerService.adminSearchPractitioner(payload)
    request.then(response => {
      let { data } = response
      if(data.status === "SUCCESS"){
        let {data: {practitioner, practitionerCount}} = data
        if(practitionerCount>0) setAllPractitioners(practitioner)
        else setAllPractitioners([])
      } else setAllPractitioners([])
      setLoading(false)
    })
  }
  

  const onSubmit = (values) => {
    getPractitioners(values);
  }
  return (
    <Container>
    <Row className="admin-search pt-4">
      <Col className="card bg-white">
        <CardBody>
          <h4 className="mb-4 mt-2 text-center">Search profiles</h4> 
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={searchValidationSchema}
            children={props => {
              return <SearchCriteriaForm {...props} />
            }}
          />

          <div>
            {loading &&
              <Loader />
            }
            {allPractitioners.length>0 &&
              <>
                <UserCard practitioners={currentPosts} loading={loading}/>
                {
                allPractitioners && allPractitioners.length>=postsPerPage?
                  <Paginations postsPerPage={postsPerPage} totalPosts={allPractitioners.length} paginate={paginate} currentPage={currentPage} /> : null
                }
              </>
            }
          </div>
        </CardBody>
      </Col>
    </Row>
    </Container>
  )
}

export default withAlert()(Search)