import React from "react"
import { Row, Col, Button } from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import * as Yup from 'yup';
import { Formik } from 'formik';
import logo from "assets/images/logo-white.png";

import MobileNumberForm from "./components/MobileNumberForm";
import { countryCodes } from "utils/Constants"
import verifyMobileImg from 'assets/images/otp-flow/verifyMobile.svg'
import './style.scss'
import { useEffect } from "react";

const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{5})(( x| ext)\d{1,5}){0,1}$/;

const initialValues = {
  chkPatient: false,
  phone: ''
}

const validationSchema = Yup.object({
  phone: Yup.string().required("This field is required").test('len', 'Mobile number is not valid', (val) => {
    const splitVal = val?.split(' ');
    return (val && splitVal[1]?.length === 10)
  }),
})

const VerifyMobile = ({ handleSubmit, error }) => {
  useEffect(() => {
    document.body.classList.add('register-screen-page');
    document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "Register";
    return () => {
      document.body.classList.remove('register-screen-page');
      document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "";
    }
}, [])

  return (
    <div className="nexogic-login-register-card card border-0 bg-transparent">
    <Row>
      <Col md={6} lg={8} xs={12} className="nexogic-form-side-content text-center">
        <div className="nexogic-form-info">
        <p><img src={logo} alt="" className="nexogic-filter-white" /></p>
        <p className="card-text text-white mb-5">Sign in or create an account</p>
          <img src={verifyMobileImg} className="img-fluid" alt="nexogic" />
        </div>
      </Col>
      <Col md={6} lg={4} xs={12} className="nexogic-form-content card-body p-0">
        <div className="nexogic-form-container">
            <h3 className="nexogic-form-title mb-2">Verify your number</h3>
            <p className="nexogic-form-title-sub">Enter your phone number to verify. We'll send an SMS to verify your phone number</p>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              children={props => {
                return <MobileNumberForm {...props} error={error} />
              }}
            />

            <p className="card-text fw-semibold text-center mt-4" >Already have an account? <Link to="/login">Sign in</Link> </p>
        </div>
      </Col>
    </Row>
    </div>
  )
}

export default VerifyMobile