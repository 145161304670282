import { Formik } from "formik";
import jwtDecode from "jwt-decode";
import { isEmpty } from "lodash";
import { withAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { authSetUser } from "redux-helpers/ActionCreator";
import API from "utils/API";
import { encrypt } from "utils/EncryptDecrypt";
import { PractitionerService } from "utils/PractitionerService";
import { setToken, setUser } from "utils/UserHelper";
import * as Yup from "yup";
import Footer from "../../../../common/Footer";
import Header from "../../../../common/Header";
import VerificationNumberForm from "./components/VerificationNumberForm";

const validationSchema = Yup.object({
  varifyNumber: Yup.string().required("This field is required"),
});
const initialValues = {
  varifyNumber: "",
};

const OtpVarification = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { email } = props.location.state || "";

  const ResendOtp = async () => {
    const OtpPayload = { type: "practitioner", email };
    try {
      const response = await API.post("/otp/", OtpPayload);
      const { data } = response;
      if (data.status === "SUCCESS") {
        props.alert.success("OTP has been sent successfully in your email");
      } else {
        props.alert.error(data.status.message);
      }
    } catch (e) {
      props.alert.error(e.message);
    }
  };

  const onSubmit = async (values) => {
    try {
      const data = {
        email: email,
        otpCode: values.varifyNumber,
        userType: "practitioner",
      };
      const response = await API.put("/verify-otp", data);

      if (response.data) {
        if (response.data.status === "SUCCESS") {
          props.alert.success(response.data.message);
          getToken(data);
        } else {
          props.alert.error(response.data.message);
        }
      } else {
        props.alert.error("Server request failed. Try again");
      }
    } catch (e) {
      let errmsg = e.message;
      if (e.response) {
        errmsg =
          e.response.data && !!e.response.data.message
            ? e.response.data.message
            : errmsg;
      }
      props.alert.error(errmsg);
    }
  };

  const getToken = async (values) => {
    try {
      const payload = `?type=practitioner&email=${values.email}`;
      const response = await API.get("/token" + payload);
      if (response.data) {
        if (response.data.status === "SUCCESS") {
          const { data } = response.data;
          if (isEmpty(data)) {
            props.alert.error(response.data.message);
          } else {
            props.alert.success("Registered Successfully!!!");
            if (data.jwt) {
              const decode = jwtDecode(data.jwt);
              setToken(data.jwt);
              if (decode.id) {
                PractitionerService.getPractitionerById(decode.id).then(
                  (response) => {
                    if (response && response.data) {
                      const { data } = response.data;
                      if (data.practitioner) {
                        const { practitionerId } = data.practitioner;
                        dispatch(authSetUser(data.practitioner));
                        setUser(data.practitioner);
                        const currentUserId = encrypt(practitionerId);
                        history.push(`/profile/${currentUserId}`);
                      }
                    }
                  },
                  (err) => {
                    console.log("error getting profile", err);
                  }
                );
              }
            }
          }
        }
      } else {
        props.alert.error("Server request failed. Try again");
      }
    } catch (e) {
      props.alert.error("Error in get token.");
    }
  };

  return (
    <section id="otpVerification">
      <div className="bg-light">
        <Header />
        <div className="bg-white border-top py-5 content">
          <Container>
            <Row className="reg-process-bar mb-4">
              <Col>
                <h3> Create your Nexogic Account </h3>{" "}
              </Col>
              <Col className="active">
                <h3>
                  <strong> OTP Verification </strong>
                </h3>{" "}
              </Col>
            </Row>
            <Row className="pt-3 pb-5 justify-content-center">
              <Col md={6} sm={12}>
                <p className="mt-3 mb-4 pb-2 FFormi">
                  We have sent you an OTP on the registered email address. If
                  you are having problems receiving email,{" "}
                  <a
                    href={`${process.env.REACT_APP_WEBSITE_URL}contact-us/`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    contact
                  </a>
                </p>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  children={(props) => {
                    return (
                      <VerificationNumberForm
                        {...props}
                        ResendOtp={ResendOtp}
                      />
                    );
                  }}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default withAlert()(OtpVarification);
