import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import {useSelector} from "react-redux"
import SugesstionCard from './SugesstionCard';
import "./SuggModalCss.css"
const SuggestionModal = ({modal,toggle}) => {
  let sugesstedConn=useSelector(state=>state.feed.suggestedConn)
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} >
        <ModalHeader toggle={toggle}>Suggested Connections</ModalHeader>
        <ModalBody>
        {sugesstedConn &&
        sugesstedConn.map((item,index)=>{
            return <SugesstionCard 
            suggestion={item}
          />
          
        
        })
      }
        </ModalBody>
        
      </Modal>
    </div>
  );
}

export default SuggestionModal;