import React from 'react'
import user_img from 'assets/images/profile-p.jpg'

export default function UpcomingAppointment() {
  return (
    <div className='nexogic-widget-sidebar-card card nexogic-upcoming-appo'>
        <h2 className="card-title h2 mb-2">Upcoming appointment</h2>
        <div className="card-body">
            <div className="nexogic-list-card card border-0">
                <div className="card-head">
                    <div className="card-thumb">
                        <img src={user_img} alt="" className='card-img' />
                        <div className="small-pill"></div>
                    </div>
                    <div className="info">
                        <h3 className="card-title">Dr. Anna Sotelo </h3>
                        <p className="card-text">MBBS, MS - Orthopedic Surgeon (Orthopedist)</p>
                        <p className="card-text"><span className="icon"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.99987 0C3.58171 0 0 3.58182 0 7.99987C0 12.4179 3.58171 16 7.99987 16C12.418 16 16 12.4179 16 7.99987C16 3.58182 12.418 0 7.99987 0ZM11.4584 9.52144H8.0668C8.05533 9.52144 8.04467 9.51867 8.03331 9.51824C8.02195 9.51877 8.01133 9.52144 7.99981 9.52144C7.69426 9.52144 7.44652 9.2737 7.44652 8.96815V3.32C7.44652 3.01445 7.69426 2.76671 7.99981 2.76671C8.30537 2.76671 8.5531 3.01445 8.5531 3.32V8.41486H11.4582C11.7638 8.41486 12.0115 8.6626 12.0115 8.96815C12.0115 9.2737 11.764 9.52144 11.4584 9.52144Z" fill="#3eabca"/>
</svg>
</span> Today, 08:00 AM</p>
<p className='card-text'><span className='icon'><svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.6 0.307983C0.70375 0.307983 0 1.00873 0 1.90115V8.91106C0 9.80348 0.70375 10.5042 1.6 10.5042H10.24C11.1362 10.5042 11.84 9.80348 11.84 8.91106V1.90115C11.84 1.00873 11.1362 0.307983 10.24 0.307983H1.6ZM16 1.36345L12.48 3.23542V7.57679L16 9.44876V1.36345Z" fill="#3eabca"/>
</svg>
</span> Video appointment</p>
                        
                    </div>
                </div>
                <div className="card-body p-0">
                <p><a href="" className="btn nexogic_primary_button w-100 mx-0">Join <span className="icon"><svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.02984 0.240112C1.01425 0.240112 0.216797 1.03183 0.216797 2.04011V9.96012C0.216797 10.9684 1.01425 11.7601 2.02984 11.7601H11.8203C12.8359 11.7601 13.6333 10.9684 13.6333 9.96012V2.04011C13.6333 1.03183 12.8359 0.240112 11.8203 0.240112H2.02984ZM18.3472 1.43261L14.3585 3.54761V8.45262L18.3472 10.5676V1.43261Z" fill="white"/>
</svg>
</span></a></p>
                </div>
            </div>
        </div>
        

    </div>
  )
}
