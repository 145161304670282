import React from 'react'
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { useState } from 'react';

export default function ReportDropsBtn(props) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <div className='floated_three_dot'>
                        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction='end'>
                            <DropdownToggle><svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="2.25" cy="12.25" r="1.75" transform="rotate(90 2.25 12.25)" fill="#001244" fillOpacity="0.2" />
                                <circle cx="2.25" cy="7" r="1.75" transform="rotate(90 2.25 7)" fill="#001244" fillOpacity="0.2" />
                                <circle cx="2.25" cy="1.75" r="1.75" transform="rotate(90 2.25 1.75)" fill="#001244" fillOpacity="0.2" />
                            </svg></DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem >Share</DropdownItem>
                                <DropdownItem >Appointment history</DropdownItem>
                                <DropdownItem onClick={()=>props.download()}>Download</DropdownItem>
                                <DropdownItem className='text-danger'>Delete</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>


                    </div>
  )
}
