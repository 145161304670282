import React, { useState, useEffect } from "react";
import DefaultImg from "../../../../assets/images/defaultPic.png";
import { titleCase } from "../../../../utils/titleCase";
import { getTime } from "../../../../utils/Constants";
import { PubNubProvider, usePubNub } from "pubnub-react";
import "./messageContact.css";

const MessageContact = (props) => {
  const pubnub = usePubNub();
  const [resFetch, setresFetch] = useState("");

  useEffect(() => {
    get_history();
  }, []);

  useEffect(() => {
    get_history();
  }, [props.recent_message]);

  const get_history = async () => {
    let newarr = [];
    props.all_channel.map((i) => newarr.push(i.channel.channelName));

    let end_var = new Date();
    end_var = end_var.getTime() * 10000;
   
    pubnub.fetchMessages(
      {
        channels: newarr,
        start: null,
        end: null,
        count: 1,
      },
      (status, res) => {
        let a =
          res != null && res.hasOwnProperty("channels")
            ? res?.channels[props.channel_default.channelName]
            : null;

        setresFetch(a);
      }
    );
  };

  return (
    <div
      className={props.selected ? props.selected : null}
      onClick={() =>
        props.Click(
          props.contact.practitionerId,
          props.contact.profilePicUrl,
          props.contact.firstName,
          props.contact.middleName,
          props.contact.lastName,
          props.key_count,
          props.channel_default.channelName
        )
      }
      style={{ cursor: "pointer" }}
    >
      <div className="d-flex align-items-center message-open-container">
        <img
          className="Profile__pic"
          src={props.profilePicUrl ? props.profilePicUrl : DefaultImg}
          alt=""
        />
        {/* <img className="online_Status" src={OnlineIcon} alt="" /> */}
        <div className="mess-det">
          <div className="cont-name-time-container">
            <div className="cont-name">
              {props.contact ? (
                <div>
                  {(props.contact.firstName
                    ? titleCase(props.contact.firstName)
                    : "") +
                    " " +
                    (props.contact.middleName
                      ? titleCase(props.contact.middleName)
                      : "") +
                    " " +
                    (props.contact.lastName
                      ? titleCase(props.contact.lastName)
                      : "")}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="d-flex justify-content-end mess-dark">
              {resFetch && resFetch[0].timetoken
                ? getTime(resFetch[0].timetoken)
                : null}
            </div>
          </div>
          <p className="mess-dark">
            {resFetch && resFetch[0].message.content
              ? resFetch[0].message.content
              : resFetch && resFetch[0].message.file.name
              ? resFetch[0].message.file.name
              : "no new message"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MessageContact;
