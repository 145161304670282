import React, { useState } from "react";
import { Button, CardBody, FormGroup } from "reactstrap";
import ReactQuill from "react-quill";
import { withAlert } from "react-alert";
import { useFormikContext } from "formik";

const MedicalHistoryForm2 = ({ currentStep, setCurrentStep, isSelf, setIsSelf }) => {
    const { values, setFieldValue } = useFormikContext();

    return (
        <CardBody className={`nex-form-step active`}>
            <div className="nex-form-field-container">
                <div className="nex-form-group">
                    <div className="nex-form-group-c">
                        <label htmlFor="" className="nex-text-lg bold mb-3">Describe your Medical History in brief ? </label>
                        <FormGroup>
                            <ReactQuill
                                id="description"
                                theme="snow"
                                value={values.description}
                                name={"description"}
                                onChange={(content) => setFieldValue('description', content)}
                                placeholder="Enter Your Medical History in brief..."
                                bounds={".app"}
                            />
                        </FormGroup>

                        <label htmlFor="" className="nex-text-lg bold mb-3">Do you have a strong family medical history of any serious illnesses
                            (cancer, heart disease etc) ? </label>
                        <FormGroup>
                            <ReactQuill
                                id="familyHistory"
                                theme="snow"
                                placeholder="Enter Your Family Medical History in brief..."
                                value={values.familyHistory}
                                name={"familyHistory"}
                                onChange={(content) => setFieldValue('familyHistory', content)}
                                bounds={".app"}
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
        </CardBody>
    )
}

export default withAlert()(MedicalHistoryForm2);
