import React from 'react';
import { FormGroup, Input, Label } from "reactstrap";
import { ErrorMessage } from 'formik';
const FormikCheckboxField = (
    {
        field: { ...fields },
        form: { touched, errors, ...rest },
        ...props
    }) => (
        <>
        <FormGroup className="m-0 form-check">
            <Label htmlFor={props.id}>
                <Input {...props} {...fields}
                    className="" invalid={Boolean(touched[fields.name] && errors[fields.name])} />
                {props.label}
            </Label>
            
        </FormGroup>
        <ErrorMessage name={fields.name} render={msg => <span className="text-danger d-inline-block  mt-1 line-height-error">{msg}</span>} />
        </>
    );
export default FormikCheckboxField;