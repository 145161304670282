import React from 'react'
import classnames from 'classnames';
import { useState } from "react";
import { Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import no_appointment_img from 'assets/images/no-appointment-icon.svg'

export default function NoAppointments() {
  const [activeTab, setActiveTab] = useState("Prescriptions");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <>
      <div className=''>
        {/** page title */}
        <h2 className='card-title big mb-3'>Appointments</h2>
        <div className="card nexogic-medical-record-card border-0 bg-transparent">
          <Nav tabs className="nexogic-tabs">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "Prescriptions",
                })}
                onClick={() => {
                  toggle("Prescriptions");
                }}
              >
                Upcoming 
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "Reports" })}
                onClick={() => {
                  toggle("Reports");
                }}
              >
                Past
              </NavLink>
            </NavItem>
          </Nav>
          <Card>
            <CardBody className='px-0'>
              {activeTab === "Prescriptions" && 
                
                    
                            <div className='nexogic-upload-card'>
                                <div className="graphic">
                                    <img src={no_appointment_img} alt="" />
                                </div>
                                <div className="desc">
                                    <p className='card-text light'>You don’t have any appointment yet</p>
                                    <p className='card-text light'>
                                      <a href='' className='btn nexogic_primary_button_outline '>Book appointment</a>
                                    </p>
                                    
                                    
                                </div>
                            </div>
                        
                
              }
              {activeTab === "Reports" && <div>test</div>}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}
