import { Field, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import FormikInputField from 'components/forms/formikFields/FormikInputField';
import { useEffect } from 'react';

const JobSalaryEditModal = (props) => {
    const { modal, setModal } = props;
    const { values, setFieldValue } = useFormikContext();
    const [salaryLevel, setSalaryLevel] = useState(values?.salaryLevel);
    const [minSalary, setMinSalary] = useState(values?.minSalary);
    const [maxSalary, setMaxSalary] = useState(values?.maxSalary);
    const [showInputs, setShowInputs] = useState(false);

    const handleSubmit = () => {
        if (!showInputs) {
            setFieldValue('minSalary', minSalary);
            setFieldValue('maxSalary', maxSalary);
        }
        setModal(false);
    };

    const handleSalaryLevelChange = (event) => {
        const selectedLevel = event.target.value;
        setFieldValue('salaryLevel', selectedLevel);

        // Set default values based on selectedLevel
        if (selectedLevel === 'negotiable') {
            setShowInputs(false);
            setMinSalary(0);
            setMaxSalary(0);
        } else {
            setShowInputs(true);
        }
    };

    useEffect(() => {
        if (minSalary === 0 && maxSalary === 0) {
            setSalaryLevel("negotiable")
        } else {
            setSalaryLevel("custom")
            setShowInputs(true);
        }
    }, [minSalary, maxSalary])


    return (
        <Modal isOpen={modal} toggle={() => setModal(!modal)} className="nex-model-v2 modal-dialog-scrollable modal-dialog-centered">
            <ModalHeader toggle={() => setModal(!modal)}>Edit Job Salary</ModalHeader>
            <ModalBody>
                <div>
                    <h2 className="nex-text-xl-small mb-4">How much salary you are offering?</h2>
                    <div className="form-group nex-radio-content-group">
                        <div className="cus-radio-w">
                            <input
                                type="radio"
                                name="salaryLevel"
                                value="negotiable"
                                onChange={handleSalaryLevelChange}
                                checked={salaryLevel === 'negotiable'}
                            />
                        </div>
                        <div className="info">
                            <h3 className="card-title">Negotiable</h3>
                            <p>Salary can be negotiate at the time of hiring</p>
                        </div>
                    </div>
                    <div className="form-group nex-radio-content-group">
                        <div className="cus-radio-w">
                            <input
                                type="radio"
                                name="salaryLevel"
                                value=""
                                onChange={handleSalaryLevelChange}
                                checked={salaryLevel != "negotiable"}
                            />
                        </div>
                        <div className="info">
                            <h3 className="card-title">Add your own</h3>
                            <p>I would like to set the salary</p>
                        </div>
                    </div>
                    {showInputs && (
                        <div className="nex-flex nex-gap-30 nex-job-experience">
                            <div className="form-group nex-flex nex-gap-16">
                                <Field
                                    className="nex-max-w-100 nex-gap-16"
                                    id="minSalary"
                                    name="minSalary"
                                    autoComplete="minSalary"
                                    type="number"
                                    min="0"
                                    //label={"Min Salary"}
                                    component={FormikInputField}
                                    placeholder="Min"
                                />
                                <div className="label">INR</div>
                            </div>
                            <div className="form-group nex-flex nex-gap-16">
                                <Field
                                    className="nex-max-w-100"
                                    id="maxSalary"
                                    name="maxSalary"
                                    autoComplete="maxSalary"
                                    type="number"
                                    //label={"Max Salary"}
                                    min="0"
                                    component={FormikInputField}
                                    placeholder="Max"
                                />
                                <div className="label">INR</div>
                            </div>
                        </div>
                    )}
                </div>
                <button className="btn nex-btn-primary ml-auto" onClick={handleSubmit}>Save</button>
            </ModalBody>
        </Modal>
    );
};

export default JobSalaryEditModal;
