import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import AwardForm from "./AwardForm";
export const RenderAwardModal = (props) => {
  const { isOpen, toggle } = props;

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        id="profile-modal"
        className="modal-dialog-scrollable modal-dialog-centered"
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>
          Awards, Recognitions, Honors & Fellowship
        </ModalHeader>
        <ModalBody>
          <AwardForm {...props} />
        </ModalBody>
      </Modal>
    </div>
  );
};
