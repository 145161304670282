

import React from 'react'
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useState } from 'react';
import { useEffect } from 'react';

function HtmlStringToPdf(props) {

  const [isLoading, setIsLoading] = useState(false);
  const [isCustomWidth, setIsCustomWidth] = useState(false);

  useEffect(() => {
    if (Array.isArray(props.format)) {
      setIsCustomWidth(true);
    }
  }, [props.format])

  function pdf() {
    setIsLoading(true);
    let elm = document.querySelector(props.target);
    elm.classList.add('nex-pdf-gen-on');
    html2canvas(elm).then(function (canvas) {
      const pageWidth = 210;
      const height = canvas.height * pageWidth / canvas.width;
      //document.querySelectorAll('#pdf_canvas_preview')[0].appendChild(canvas);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF(props.orientation, props.unit, props.format);
      if (isCustomWidth) {
        pdf.addImage(imgData, 'JPEG', 0, 0, props.format[0], props.format[1], null, 'SLOW');
      } else {
        pdf.addImage(imgData, 'JPEG', 0, 0, pageWidth, height, null, 'SLOW');
      }

      //pdf.output('dataurlnewwindow');

      pdf.save(props.outputName);
      elm.classList.remove('nex-pdf-gen-on');
      setIsLoading(false);
    });
  }

  function pdf_from_jspdf() {
    setIsLoading(true);
    let elm = document.querySelector(props.target);
    elm.classList.add('nex-pdf-gen-on');
    const doc = new jsPDF({ orientation: props.orientation, unit: props.unit, format: props.format, });
    doc.html(elm, {
      callback: function (doc) {
        //doc.save("newpdf.pdf");
        //window.open(doc);
        if(props.openAs == 'browser'){
          setTimeout(() => {
            doc.output('dataurlnewwindow');
          }, 1000);
        }else if(props.openAs == 'download'){
          doc.save(props.outputName);
        }
        

      },
      x: 0,
      y: 0
    });
    setTimeout(() => {
      setIsLoading(false);
    }, 7000);
  }


  return (
    <>
      {isLoading &&
        <div className='nex-custom-loader'>
          <div className="spinner-border z-index-1" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      }
      <span className={`${props.elmClass} nex-pdf-download-btn`} onClick={() => pdf_from_jspdf()}>{props.children ? props.children : 'Download as Pdf' }</span></>
  )
}

HtmlStringToPdf.defaultProps = {
  target: "#custom-pdf-wrap",
  unit: 'pt',
  orientation: 'p',
  format: 'a4', // you can use format like [1100, 1500]
  outputName: "download.pdf",
  openAs: "browser",
  elmClass: 'btn nexogic_primary_button_outline'
}

export default HtmlStringToPdf;
