import Header from "common/Header";
import { isEmpty } from "lodash";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { authSetUser } from "redux-helpers/ActionCreator";
import { UserStatusConstants } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import { getUser, setUser } from "utils/UserHelper";
import MedicalRegistrationForm from "./components/MedicalRegistrationForm";
import DocumentsForm from "./components/MultipleFormCase/DocumentForm";
import EducationalForm from "./components/MultipleFormCase/EducationalForm";
import HospitalAffiliationsForm from "./components/MultipleFormCase/HospitalAffiliationForm";
import PersonalDetailsForm from "./components/PersonalDetailsForm";
import Stepper from "./components/Stepper";
//import "./style.scss";


// status: INPROGRESS, COMPLETED
const regSteps = [
  {
    key: "personal_details",
    name: "Personal Details",
    status: "INPROGRESS",
  },
  {
    key: "medical_registration",
    name: "Medical Registration",
    status: "",
  },
  /*{
    key: "education",
    name: "Education",
    status: "",
  },
  {
    key: "hospital_affiliation",
    name: "Hospital & Clinic Affiliation",
    status: "",
  },*/
  {
    key: "upload_documents",
    name: "Upload Documents",
    status: "",
  },
];

const claimSteps = [
  {
    key: "personal_details",
    name: "Create User",
    status: "INPROGRESS",
  },
  {
    key: "upload_documents",
    name: "Upload Documents",
    status: "",
  },
];

const OnboardingProcess = (props) => {
  const { alert } = props;
  const dispatch = useDispatch();
  const currentUser = getUser();
  const { state } = useLocation();
  const [steps, setSteps] = useState((state && state?.profileId) ? claimSteps : regSteps);
  const history = useHistory();
  const [affiliationDet, setAffiliationDet] = useState([]);

  const activeStep = useMemo(() => {
    return steps.length > 0 && steps.find((s) => s.status === "INPROGRESS");
  }, [steps]);

  let affilStepFlag;

  const onNext = useCallback((udata) => {
    const nextIndex = steps.findIndex((s) => s.key === activeStep.key) + 1;
    setSteps((preState) => {
      return preState.map((s, i) => {
        if (i === nextIndex - 1) {
          return { ...s, status: "COMPLETED" };
        }
        if (i === nextIndex) {
          return { ...s, status: "INPROGRESS" };
        }
        return { ...s };
      });
    });
  }, [steps, activeStep]);

  const onSkip = useCallback((skipsts) => {
    const nextIndex = steps.findIndex((s) => s.key === activeStep.key) + 1;
    setSteps((preState) => {
      return preState.map((s, i) => {
        if (i === nextIndex - 1) {
          return { ...s, status: "SKIPPED" };
        }
        if (i === nextIndex) {
          return { ...s, status: "INPROGRESS" };
        }
        return { ...s };
      });
    });
  }, [steps, activeStep]);

  const onNextStep = useCallback(
    (stepNo, userdet) => {
      const nextIndex =
        steps.findIndex((s) => s.key === activeStep.key) + stepNo;
      setSteps((preState) => {
        return preState.map((s, i) => {
          if (i === nextIndex) {
            return { ...s, status: "INPROGRESS" };
          } else if (i < nextIndex) {
            if (s.key === 'personal_details') {
              if (userdet?.firstName) {
                return { ...s, status: "COMPLETED" };
              } else {
                return { ...s, status: "SKIPPED" };
              }
            }
            if (s.key === 'medical_registration') {
              if (userdet?.registrations.length > 0) {
                return { ...s, status: "COMPLETED" };
              } else {
                return { ...s, status: "SKIPPED" };
              }
            }
            if (s.key === 'education') {
              if (userdet?.qualification) {
                return { ...s, status: "COMPLETED" };
              } else {
                return { ...s, status: "SKIPPED" };

              }
            }
            if(userdet?.status==="AFFILIATIONS_INFO_PROVIDED"){
              getAffilationDetails(userdet?.guid);
            }
            if (s.key === 'hospital_affiliation') {
              if (affilStepFlag.length > 0) {
                return { ...s, status: "COMPLETED" };
              } else {
                return { ...s, status: "SKIPPED" };
              }
            }
          }
          return { ...s };
        });
      });
    },
    [activeStep, steps]
  );

  const goToReristrationStep = useCallback(
    (completedRegStep, guid, userdet) => {
      if (completedRegStep === UserStatusConstants.BASIC_INFO_PROVIDED) {
        onNextStep(1, userdet);
      } else if (
        completedRegStep === UserStatusConstants.REGISTRATION_INFO_PROVIDED
      ) {
        onNextStep(2, userdet);
      } else if (
        completedRegStep === UserStatusConstants.EDUCATION_INFO_PROVIDED
      ) {
        onNextStep(3, userdet);
      } else if (
        completedRegStep === UserStatusConstants.AFFILIATIONS_INFO_PROVIDED
      ) {
        onNextStep(4, userdet);
      } else if (completedRegStep === UserStatusConstants.DOC_UPLOADED) {
        history.push(`/profile/${getUser().profileId}`);
      } else if (guid === undefined || guid == null) {
        onNextStep(0);
      } else {
        history.push(`/register`);
      }
    },
    [history, onNextStep]
  );

  const goToReristrationClaimStep = useCallback(
    (completedRegStep, guid) => {
      if (completedRegStep === UserStatusConstants.BASIC_INFO_PROVIDED) {
        onNextStep(2);
      } else if (
        completedRegStep === UserStatusConstants.REGISTRATION_INFO_PROVIDED
      ) {
        onNextStep(3);
      } else if (completedRegStep === UserStatusConstants.DOC_UPLOADED) {
        history.push(`/profile/${getUser().profileId}`);
      } else if (guid === undefined || guid == null) {
        onNextStep(1);
      } else {
        history.push(`/register`);
      }
    },
    [history, onNextStep]
  );

  const getAffilationDetails = useCallback(
    (guid) => {
      PractitionerService.getAffiliations(guid)
        .then((resp) => {
          if (resp.status === 200 || resp.status === 201) {
            let { data } = resp;
            if (data && data.length > 0) {
              setAffiliationDet(resp.data);
              affilStepFlag=resp.data;
            }
          }
        })
        .catch((error) => {
          console.log(
            "PractitionerService.getAffiliations error",
            error
          );
          alert.error(error.message);
        });
    }, [currentUser,goToReristrationStep,alert,state, affiliationDet.length]
  );

  const gePractitionersDetails = useCallback(
    (guid) => {
      PractitionerService.gePractitionersDetails(guid)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setUser(response.data);
            dispatch(authSetUser(response.data));
            getAffilationDetails(currentUser.guid);
            if (state && state?.profileId) {
              goToReristrationClaimStep(
                response.data.status,
                currentUser.guid
              );
            } else {
              goToReristrationStep(response.data.status, currentUser.guid, response.data,affiliationDet);
            }
          }
        })
        .catch((error) => {
          console.log(
            "PractitionerService.gePractitionersDetails error",
            error
          );
          alert.error(error.message);
        });
    },
    [
      currentUser,
      dispatch,
      goToReristrationClaimStep,
      goToReristrationStep,
      alert,
      state,
      affiliationDet
    ]
  );

  const onBack = () => {
    const prevIndex = steps.findIndex((s) => s.key === activeStep.key) - 1;
    setSteps(
      steps.map((s, i) => {
        if (i === prevIndex) return { ...s, status: "INPROGRESS" };
        if (s.status === "INPROGRESS") return { ...s, status: "" };
        return { ...s };
      })
    );
  };

  useEffect(() => {
    if (!isEmpty(currentUser) && !isEmpty(currentUser.guid)) {
      gePractitionersDetails(currentUser.guid);
    } else if (isEmpty(state)) {
      history.push(`/register`);
    }
  }, [state, history]);

  useEffect(() => {
    if (!isEmpty(currentUser) && !isEmpty(currentUser.guid)) {
      getAffilationDetails(currentUser.guid);
    }
  }, [affiliationDet.length]);

  useEffect(() => {
    document.body.classList.add('register-screen-page');
    document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "Register";
    return () => {
      document.body.classList.remove('register-screen-page');
      document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "";
    }
}, [])

  return (
    <section id="onboardingPage"> 
      <div className="nexogic-bg-light">
        <Header key={currentUser?.guid} />
        <div className="nexogic-main-content-wrap nexogic-py-md">
          <Container className="nexogic-container-gap">
            <Row>
              <Col>
                <div className="nexogic-onboarding-wrap">
                  
                    <div className="nexogic-register-step"> 
                      <Stepper steps={steps} onNextStep={onNextStep} userdet={currentUser} />
                    </div>
                    < >
                    
                      {activeStep.key === "personal_details" && (
                        <PersonalDetailsForm onNext={onNext} {...props} />
                      )}
                      {activeStep.key === "medical_registration" && (
                        <MedicalRegistrationForm
                          onNext={onNext}
                          onBack={onBack}
                          onSkip={onSkip}
                          {...props}
                        />
                      )}
                      {activeStep.key === "education" && (
                        <EducationalForm onNext={onNext} onBack={onBack} onSkip={onSkip} />
                      )}
                      {activeStep.key === "hospital_affiliation" && (
                        <HospitalAffiliationsForm
                          onNext={onNext}
                          onBack={onBack}
                          onSkip={onSkip}
                          setAffiliationDet={setAffiliationDet}
                        />
                      )}
                      
                      {activeStep.key === "upload_documents" && (
                        <DocumentsForm onBack={onBack} />
                      )}
                    </>
                  
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default memo(OnboardingProcess);
