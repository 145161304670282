import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import registration_profile from "assets/images/registration-profile.png";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";

//import registration_icon from "assets/images/svgs/registration_icon.svg";
import classnames from "classnames";
import AlertDialog from "common/AlertDialog";
import { withAlert } from "react-alert";
import {
  profileAddEvent,
  updateProfileCompletness
} from "redux-helpers/ActionCreator";
import { UserStatusConstants } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import RegistrationForm from "./components/RegistrationForm";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import registration_icon from "assets/svgIcon/registration_icon.svg";
import registration_person_icon from "assets/images/registration_person_icon.png";
import plusIcon from "assets/images/plus-icon-lg.png";
import editIcon from "assets/images/edit-blue-icon.png";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import del_red_icon from "assets/images/del-red-icon.png";
import { getEmptySectionShowHide } from "utils/Utils";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const initialValues = {
  registrationNumber: "",
  registrationCouncil: "",
  yearOfRegistration: "",
};

const RenderRegistrationDetails = (props) => {
  /*   const { registrationNumber, registrationCouncil, yearOfRegistration } = props.details */
  const {
    isAdmin,
    details,
    handleEditItem,
    handleRemoveItem,
    isLoggedinUser,
    status,
  } = props;

  if (details && details.length > 0) {
    return details.map((detail, index) => {
      const profileIdLink = detail?.institutionDetail?.profileId
        ? detail?.institutionDetail?.profileId
        : detail?.institutionDetail?.guid;
      return (
        <li
          className="nex-icon-list-item"
          key={`registration_${detail.id}`}
        >
          <div className="card-graphic">
            <img src={registration_profile} alt="" className="contain-bg" />
          </div>
          <div className="nex-info">
              <h3 className="card-title">{detail.registrationCouncil}</h3>
              <p className="card-text nex-text-light">{`Registration No: ${detail.registrationNumber}`}</p>
              <p className="card-text">{`Year: ${detail.yearOfRegistration}`}</p>
          
              <div className="nex-action">
            {(isAdmin) &&
                status === "APPROVED" &&
                index != 0 && (
                  <div className="delete-action">
                    <Button
                      color="link"
                      className="nex-rs-registration-edit-btn"
                      onClick={() => handleEditItem(detail)}
                    >
                      {/* <img src={grayPencilIcon} /> */}
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="15.9062" cy="16" r="15.375" fill="white" stroke="#DBF4FB" stroke-width="0.75"/>
<path d="M19.3612 9.53125L17.8557 11.0368L20.8667 14.0478L22.3722 12.5423L19.3612 9.53125ZM16.3502 12.5423L10.3281 18.5643V21.5754H13.3392L19.3612 15.5533L16.3502 12.5423Z" fill="#46ABC9"/>
</svg>
                    </Button>

                    <Button
                      color="link"
                      className="nex-rs-registration-del-btn"
                      onClick={() => handleRemoveItem(detail)}
                    >
                      {/* <img src={grayTrashIcon} /> */}
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="16.2266" cy="16" r="15.75" fill="#FFEAEF"/>
<path d="M11.3984 13.7734L12.2586 20.1323C12.426 21.3702 12.5097 21.9892 12.9337 22.3597C13.3577 22.7302 13.9823 22.7302 15.2315 22.7302H18.4692C19.7184 22.7302 20.343 22.7302 20.767 22.3597C21.191 21.9892 21.2747 21.3702 21.4421 20.1323L22.3023 13.7734" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.5156 11.0475V11.0475C14.5156 10.2672 14.5156 9.87708 14.6572 9.57473C14.8061 9.25693 15.0616 9.00138 15.3794 8.85254C15.6818 8.71094 16.0719 8.71094 16.8522 8.71094V8.71094C17.6324 8.71094 18.0226 8.71094 18.3249 8.85254C18.6427 9.00138 18.8983 9.25693 19.0471 9.57473C19.1887 9.87708 19.1887 10.2672 19.1887 11.0475V11.0475" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.3023 11.0547L11.3984 11.0547" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.8906 14.1719L15.28 20.0132" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.7957 14.1719L18.4062 20.0132" stroke="#FF9191" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                    </Button>
                  </div>
                )}
            </div>
          
          </div>


          {/** old code */}
          <div className="row g-0 d-none">

            <div className="col-auto">
              <img src={registration_icon} alt="" />
            </div>
            <div className="col">
              <h3 className="card-title">{detail.registrationCouncil}</h3>
              <p className="card-text"><small className="text-muted"><img src={registration_person_icon} alt="" /> {`Registration No: ${detail.registrationNumber}`}</small></p>
              <p className="card-text"><small className="text-muted">{`Year: ${detail.yearOfRegistration}`}</small></p>
            </div>

            <div className="flex-grow-1 d-none">
              <div className="d-flex">
                <div className="prize-icon badgeshape">
                  <img src={registration_icon} width="15" alt="#" />
                </div>
                <div className="ml-2">
                  {/*  <Link to={`/institution/${profileIdLink}`} className="mb-2 color-accent"> */}
                  <h6 className="section-item-title item-blue-title f-14 m-0">
                    {detail.registrationCouncil}
                  </h6>
                  {/*  </Link> */}
                  <div className="section-item-shortdes mt-2">{`Registration No: ${detail.registrationNumber}`}</div>
                  <div className="section-item-shortdes mt-2">{`Year: ${detail.yearOfRegistration}`}</div>
                </div>
              </div>
              {/*   <div className="d-flex flex-wrap">
                {props.documents.map((item, index) => {
                  const fileName = item.documentTitle ? item.documentTitle : ''
                  return (
                    <div className="position-relative document-card" key={`docs_${item.id}`}>
                      {
                        item.documentType === "application/pdf" ?
                          <embed src={decodeFileUrl(item.documentUrl)} className="img-fluid" />
                          :
                          <img src={decodeFileUrl(item.documentUrl)} className="img-fluid" />
                      }
                      <div className="document-name"><a href={decodeFileUrl(item.documentUrl)} target="_blank" className="text-truncate">{fileName}</a> </div>
                    </div>
                  )
                }
                )}
                {props.loadingDocuments &&
                  <p className="txt-mute">{'loading documents...'}</p>
                }
              </div> */}
            </div>
            <div className="ml-auto col-auto d-flex align-items-start">
              {/** (isLoggedinUser || isAdmin) */}
              {(isAdmin) &&
                status === "APPROVED" &&
                index != 0 && (
                  <div className="delete-action">
                    <Button
                      color="link"
                      className="nex-rs-registration-edit-btn"
                      onClick={() => handleEditItem(detail)}
                    >
                      {/* <img src={grayPencilIcon} /> */}
                      <img src={edit_blue_icon} alt="" />
                    </Button>

                    <Button
                      color="link"
                      className="nex-rs-registration-del-btn"
                      onClick={() => handleRemoveItem(detail)}
                    >
                      {/* <img src={grayTrashIcon} /> */}
                      <img src={del_red_icon} alt="" />
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </li>
      );
    });
  } else {
    return "";
  }
};

const Registration = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const { alert } = props;
  const eventName = useSelector((state) => state.profileComplete.eventName);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [registrationDetails, setRegistrationDetails] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [isOpenAlert, setAlertModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const toggle = () => setModal(!modal);
  const { practitioner, isAdmin, isLoggedinUser, setShowRegistrationOnTabScroll } = props;
  const { guid, status } = practitioner;

  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  useEffect(() => {
    getDocuments();
  }, [guid]);

  useEffect(() => {
    dispatch(updateProfileCompletness(13, !!registrationDetails.length));

    if (registrationDetails.length > 0 || isLoggedinUser || isAdmin) {
      setShowRegistrationOnTabScroll(true);
    }else{
      setShowRegistrationOnTabScroll(false);
    }
  }, [registrationDetails]);

  useEffect(() => {
    if (eventName === "ADD_Registration") {
      registrationModal(initialValues);
      dispatch(profileAddEvent(null));
    }
  }, [eventName]);

  const handleEditItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    registrationModal();
  };

  const toggleAlert = () => {
    setLoading(false);
    setAlertModal(!isOpenAlert);
  };

  const handleRemoveItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    toggleAlert();
  };

  const getDocuments = async () => {
    setLoadingDocuments(true);
    PractitionerService.getPractitionerRegistration(guid).then(
      (response) => {
        let { data, status } = response;
        if (status === 200) {
          //const { id, registrationNumber, registrationCouncil, yearOfRegistration } = data
          setRegistrationDetails(data);
          // console.log(registrationDetails, id, registrationNumber, registrationCouncil, yearOfRegistration);

          // Array.isArray(practitionerDocument) ? setDocuments(practitionerDocument) : setDocuments([])
        }
        setLoadingDocuments(false);
      },
      (err) => {
        console.log(err);
        setDocuments([]);
        setLoadingDocuments(false);
      }
    );
  };

  const handleClickOnYes = async () => {
    setLoading(true);
    try {
      // const response = await API.delete(Url + selectedItem.id);
      const response = PractitionerService.deletePractitionerRegistration(
        guid,
        selectedItem.id
      );
      response.then(
        (response) => {
          const { status } = response;
          if (status === 204) {
            setTimeout(() => {
              showSuccessMessage("Registration deleted successfully.");
              // setRegistrationDetails(data)
              getDocuments();
              setLoading(false);
              toggleAlert();
            }, 1000);
          } else {
            showErrorMessage("Cannot delete Registration.");
            setLoading(false);
            toggleAlert();
          }
        },
        (e) => {
          setLoading(false);
          console.log("error", e.message);
          let errmsg = e.message;
          if (e.response) {
            errmsg =
              e.response.data && !!e.response.data.message
                ? e.response.data.message
                : errmsg;
          }
          showErrorMessage(errmsg);
        }
      );
    } catch (e) {
      showErrorMessage(e.message);
      setLoading(false);
      toggleAlert();
    }
  };

  const registrationModal = () => {
    toggle();
  };

  const canAddRegDetails =
    isAdmin ||
    (isLoggedinUser && currentUser.status === UserStatusConstants.APPROVED);

  if (
    getEmptySectionShowHide(
      registrationDetails ?? [],
      isAdmin,
      currentUser,
      globalProfileInfo,
      guid
    )
  )
    return null;

  return (
    <Card className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="registrationSection">
      <div className="card-header"><h2>Registration</h2>

        {canAddRegDetails && (

          <Button
            id="nex-rs-registration-add-btn"
            color="link"
            className={`add-button ${registrationDetails.registrationNumber === "" ? '' : 'ml-auto'}`}
            onClick={() => handleEditItem(initialValues)}
          >
            {registrationDetails.registrationNumber === "" ?
              <img src={editIcon} alt="" />
              :
              <><svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="9.75" cy="9" r="8.4375" fill="white" stroke="#46ABC9" stroke-width="1.125"/>
<path d="M9.625 5.25L9.625 12.5039" stroke="#46ABC9" stroke-width="1.125" stroke-linecap="round"/>
<path d="M13.2656 8.88281L6.01172 8.88281" stroke="#46ABC9" stroke-width="1.125" stroke-linecap="round"/>
</svg> Add More</>
            }


          </Button>

        )}
        <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
        <svg width={17}  height={10} viewBox="0 0 17 10" fill="none"  xmlns="http://www.w3.org/2000/svg" >
                        <path
                          d="M2.25 1.625L8.625 8L15 1.625"
                          stroke="black"
                          strokeWidth={3}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
            </svg>
        </button>
      </div>
      <div className={`collapse-div`}>
        <CardBody className={
          registrationDetails && registrationDetails.length > 0
            ? ""
            : "align-items-center"
        }>

          {registrationDetails.registrationNumber !== ""
            ? ""
            : canAddRegDetails && (
              <Button
                id="nex-rs-registration-add-btn2"
                className="add-button"
                color="link"
                onClick={() => handleEditItem(initialValues)}
              >
                {"Add your registration"}
              </Button>
            )}
          {/** 
        <Row
          
        >
          <Col>
            <h3 className="section-title m-0 mb-3">{"Registration"}</h3>
            {registrationDetails.registrationNumber !== ""
              ? ""
              : canAddRegDetails && (
                  <div className="mt-3">
                    <Row className="align-items-center">
                      <Col className="add-infomation-part">
                        <Button
                          className="add-infomation p-0"
                          color="link"
                          onClick={() => handleEditItem(initialValues)}
                        >
                          {"Add your registration"}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )}
          </Col>

          {canAddRegDetails && (
            <Col xs="auto">
              <Button
                color="link"
                className="round-icon-btn rounded-circle"
                onClick={() => handleEditItem(initialValues)}
              >
                 {registrationDetails.registrationNumber === "" ?
                  <FontAwesomeIcon icon={faPlus} />
                  :
                  <img src={pencilIcon} />
                } 
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Col>
          )}
        </Row>
*/}

          <ul className="nex-icon-list-within-card" id="nex-rs-registration-lists">
            <RenderRegistrationDetails
              details={registrationDetails}
              handleEditItem={handleEditItem}
              handleRemoveItem={handleRemoveItem}
              status={status}
              documents={documents}
              loadingDocuments={loadingDocuments}
              isAdmin={isAdmin}
              isLoggedinUser={isLoggedinUser}
            />
          </ul>
        </CardBody>
      </div>
      <Modal
        isOpen={modal}
        id="profile-modal"
        toggle={registrationModal}
        className="modal-dialog-centered"
        backdrop="static"
      >
        <ModalHeader toggle={registrationModal}>Add Registration</ModalHeader>
        <ModalBody>
          <RegistrationForm
            {...props}
            details={registrationDetails}
            setDetails={getDocuments}
            toggle={toggle}
            //uuid={guid}
            selectedItem={selectedItem}
            documents={documents}
            loadingDocuments={loadingDocuments}
            getDocuments={getDocuments}
            isAdmin={isAdmin}
          />
        </ModalBody>
      </Modal>
      <AlertDialog
        toggle={toggleAlert}
        isOpen={isOpenAlert}
        isLoading={isLoading}
        handleClickOnYes={handleClickOnYes}
        yesLabel={"Yes"}
        noLabel={"No"}
      />
    </Card>
  );
};
export default withAlert()(Registration);
