import React from 'react';
import { Link } from "react-router-dom";
import {
    Button,
    ButtonDropdown,
    Card,
    CardImg,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import LinesEllipsis from 'react-lines-ellipsis';
import ConnectButton from 'pages/FeedHomePage/components/PractitionerProfiles/ConnectButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { getAge, getFullNameOfPerson, getGenderDetails } from 'utils/Utils';
import { useState } from 'react';

const GroupRecord = ({ profileDetails, index, group, toggleAlert, setGroupIdToAct, handleUnFollow }) => {
    const [dropdownOpen, seDropdownOpen] = useState(false);
    return (
        <tr key={`profile_${index}`}>
            <td className="border font-weight-bold">
                <div className=' d-flex'>
                    <h4 className="link-text text-truncate" style={{maxWidth: '350px'}}>
                        <Link className="group-link nex-gi-group-group-name" to={`/institution/${profileDetails?.institutionProfileInfo?.profileId}`} >
                            {profileDetails?.name}
                        </Link>
                    </h4>
                </div>
            </td>
            <td className="border font-weight-bold">
                <div className=' d-flex'>
                    <h4 className="link-text text-truncate" style={{maxWidth: '350px'}}>
                        {profileDetails?.type}
                    </h4>
                </div>
            </td>

            <td className="border">
                <div className="actions">
                    <Link to={`/institution/${profileDetails?.profileId}`}>
                        <Button color="primary" className="nex-btn-primary text-nowrap">Edit page</Button>
                    </Link>
                </div>
            </td>
        </tr >
    );
}

const PagesOwnedTabularView = (props) => {
    // const currentUser = useSelector((state) => state.auth.currentUser);
    const { theader, tdata, activeTab, handleEditItem, reportClick, setGroupIdToAct, toggleAlert, handleUnFollow } = props;
    const [dropdownOpen, seDropdownOpen] = useState(false);

    return (
        <div className="profiles-table">
            <table className="table border" >
                <thead>
                    <tr>
                        {theader.map((columns, index) => (
                            <th className="theader" key={index}>{columns}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tdata.map((profileDetails, index) => (
                        <GroupRecord profileDetails={profileDetails} index={index} group={profileDetails} handleEditItem={handleEditItem}
                            toggleAlert={toggleAlert}
                            setGroupIdToAct={setGroupIdToAct}
                            handleUnFollow={handleUnFollow}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default PagesOwnedTabularView