import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {
    updateAccessToken,
    resetAuthSession,
    getToken,
    getRefreshToken,
    setOAuthTokens,
} from "utils/UserHelper";

const service = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
    responseType: "application/json",
    contentType: "application/json",
});

service.interceptors.request.use(async (config) => {
    config.params = config.params || {};
    const token = getToken();
    try {
        const updatedConfig = config;
        if (!updatedConfig.ignoreToken) {
            if (token)
                updatedConfig.headers.common.Authorization = `Bearer ${token}`;
        }
        delete updatedConfig.ignoreToken;
        return updatedConfig;
    } catch (err) {
        return Promise.reject(err);
    }
},
(error) => {
    console.log(error);
    return Promise.reject(error);
});

const refreshAuthLogic = async (failedRequest) => {
    const refreshToken = getRefreshToken();
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_GOOGLE_SEC_TOKEN_URL}/v1/token?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
            { grant_type: "refresh_token", refresh_token: refreshToken }
        );
        const { access_token,refresh_token } = response.data;
        if (access_token) {
          updateAccessToken(access_token);
            setOAuthTokens(access_token,refresh_token);
            failedRequest.response.config.headers['Authorization'] = 'Bearer ' + access_token;
            return Promise.resolve();
        } else {
            resetAuthSession();
            return Promise.reject();
        }
    } catch (error) {
        resetAuthSession();
        return Promise.reject(error);
    }
};

createAuthRefreshInterceptor(service, refreshAuthLogic);

service.interceptors.response.use(undefined, async (err) => {
    const originalConfig = err.config;
    if (!!err?.response) {
        const { status, data } = err.response;
        const { error, validationErrors, message } = data;
        if (status >= 400 && status < 500) {
            if (validationErrors && validationErrors.length > 0) {
                return { message, status, validationErrors };
            } else {
                return { message, status };
            }
        } else if (status === 500) {
            console.log(error);
            return { status, message };
        }
    } else {
        return { err, status: 500 };
    }
});

export default service;