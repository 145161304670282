import CommonLoader from "common/CommonLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { withAlert } from "react-alert";
import { Button } from "reactstrap";
import { ConnectionServices } from "utils/ConnectionServices";
import { createImageFromInitials, getRandomColor } from "utils/Utils";
import NoConnection from "../../NoConnection";
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";
import { faAddressCard, faIdCard, faListAlt, faTable } from "@fortawesome/free-solid-svg-icons";
import GroupInvitationPendingTabularView from "components/tabularView/GroupInvitationPendingTabularView";
import PageTitle from "common/PageTitle/PageTitle";
import ListTableSwitch from "common/ListTableSwitch/ListTableSwitch";
import QuickInfo from "common/QuickInfo/QuickInfo";
import PeopleKnowWidget from "common/PeopleKnowWidget";

const GroupFollow = forwardRef((props, ref) => {
  const [list_of_data, setList_of_data] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [viewMode, setViewMode] = useState("card");

  const getData = async (pageNo, feedlist) => {
    const request = ConnectionServices.getGroupMembersipRequests("SENT", pageNo);
    request
      .then(async (response) => {
        let { data } = response;
        // console.log(data);
        if (data?.currentPage < (data?.totalPages - 1))
          setHasMore(true);
        else
          setHasMore(false);
        setIsLoadingMore(false);
        if (pageNo === 0) {
          const allData = { ...data };
          setList_of_data(allData);
          setIsLoading(false);
        } else {
          const resolved = await Promise.all(
            data.contents.map(async (item) => {
              const each = { ...item };
              return each;
            })
          );
          feedlist = feedlist.concat(resolved);
          setList_of_data({ ...data, contents: feedlist });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoadingMore(false);
        console.log("Error while getting groups list: ", error);
      });
  };

  const fetchData = () => {
    setIsLoadingMore(true);
    getData(list_of_data.currentPage + 1, list_of_data.contents);
  };

  useImperativeHandle(ref, () => ({
    getJobListData() {
      getData(0, []);
    },
  }));

  useEffect(() => {
    getData(0, []);
  }, []);

  if (isLoading) {
    return (
      <div className="loading-spiner text-center  mt-2">
        <CommonLoader style={{ width: "2rem", height: "2rem" }} />
      </div>
    );
  }

  const renderProfiles = () => {
    if (viewMode === 'card') {
      return (
        <>
          {list_of_data &&
            list_of_data.contents.map((e, i) => (
              <div className="nex-list-card-v2 bg-white card nex-networks-lists-pending">
                <div className="card-body">
                  <div className="nex-stack">
                    <QuickInfo avatar={<>
                      {e.groupAvatarId
                        ?
                        <img
                          className="nex-avatar lg"
                          src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${e.groupId}/${e.groupAvatarId}`}
                          alt="#"
                        />
                        :
                        <img
                          className="nex-avatar lg"
                          src={createImageFromInitials(64, e.groupName, getRandomColor(null))}
                          alt="profilePic"
                          width={64}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = createImageFromInitials(132, e.memberDetails.name, getRandomColor(null));
                          }}
                        />
                      }
                      </>}
                        title={e.groupName}
                        titleLink = {`groups/${e.groupId}`}
                      >
                        <p className="nex-text">{e.groupDescription}</p>
                        <p className="nex-text">{e.role === "MEMBER" ? "Member" : "Admin"}</p>
                    </QuickInfo>
                    <div className="action ml-auto my-auto">
                        <span disabled className={`nex-pill ${e.status.toLowerCase()} `}>{e.status}</span>
                    </div>
                    {/** 
                    <div className="col-auto col-img">
                      {e.groupAvatarId
                        ?
                        <img
                          className="card-img rounded card-img-md"
                          src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${e.groupId}/${e.groupAvatarId}`}
                          alt="#"
                        />
                        :
                        <img
                          className=" card-img rounded card-img-md"
                          src={createImageFromInitials(64, e.groupName, getRandomColor(null))}
                          alt="profilePic"
                          width={64}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = createImageFromInitials(132, e.memberDetails.name, getRandomColor(null));
                          }}
                        />
                      }
                    </div>
                    <div className="col col-content-right-btn">
                      <div className="nexogic-content-details">
                        <h3 className="card-title"><a className="practice-name" href={`groups/${e.groupId}`}>
                          {e.groupName}
                        </a></h3>

                        <div className="card-text"> <small className="text-muted">{e.groupType}</small></div>

                        <div className="card-text">
                          <small className="text-muted">{e.groupDescription}</small>
                        </div>
                        <div className="card-text"><small className="text-muted">{e.role === "MEMBER" ? "Member" : "Admin"} </small></div>
                      </div>
                      <div className="nexogic-action-part align-items-center"><Button disabled className="nexogic_primary_button_outline">{e.status}</Button></div>
                    </div>*/}
                  </div>
                </div>
              </div>
            ))
          }
        </>
      );
    } else { // For table view
      return (
        <GroupInvitationPendingTabularView
          theader={['Group Name', 'Group Type', 'Member Type', 'Status']} tdata={list_of_data.contents} viewmode={viewMode}
        />
      );
    }
  };

  return (
    <>
    <div className="nex-container row">
      <div className="nex-app-no-pad col">
      <PageTitle title="Pending Networks">
          <ListTableSwitch viewMode={viewMode} cardViewAction={() => setViewMode("card")}  tableViewAction={()=>setViewMode("table")} />
      </PageTitle>
      {/* <div className="view-toggle nexogic-view-toggle"> */}
        {/*<div className="btns">
          <FontAwesomeIcon title="Card View" color={viewMode === "card" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faAddressCard} onClick={() => setViewMode("card")} /> | <FontAwesomeIcon title="Table View" color={viewMode === "table" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faListAlt} onClick={() => setViewMode("table")} />
        </div>*/}
      {/* </div> */}
      {
        list_of_data && list_of_data.contents.length > 0 ?
          <div className="nexogic-connection-card nexogic-custom-scroll" id="nexogic-pending-card">
            {renderProfiles()}
          </div>
          :
          <div className="loading-spiner text-center mt-3 nex-app-need-pad ">
            <NoConnection content={'No Pending'} />
          </div>
      }
      {hasMore &&
        <div className="text-center mt-3">
          {isLoadingMore
            ?
            <CommonLoader style={{ width: "2rem", height: "2rem" }} />
            :
            <LoadMoreButton loadMore={fetchData} />
          }
        </div>
      }
      </div>
      {!props.nosidebar &&
      <div className="nex-sidebar v2 col">
        <PeopleKnowWidget speciality={"Acupuncturist"} />
      </div>
      }
    </div>
    </>
  )
});

GroupFollow.defaultProps = {
  toggleCGModal: () => { },
  isCGOpen: false,
};

export default withAlert()(GroupFollow);
