import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faRupeeSign, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommonLoader from "common/CommonLoader";
import JobVisitors from "common/JobVisitors";
import JobWidget from "common/JobWidget";
import ApplyJobs from "pages/FeedHomePage/components/ApplyJobs";
import { useDispatch, useSelector } from "react-redux";
import SEOWrapper from "pages/SEOWrapper";
import { memo, useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { Link, useParams, useHistory } from "react-router-dom";
import graduateSmallIcon from "../../assets/images/svgs/graduate-small-icon.svg";
import locationIcon from "../../assets/images/location-1.png";
import dateCalenderIcon from "../../assets/images/svgs/calender-green-icon.svg";
import emailSmallIcon from "../../assets/images/svgs/email-small-icon.svg";
import twitter_logo from "../../assets/images/twitter-fa-rep-icon.png";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  FAILTOSAVERECOED,
  JOB_PositionTypesList,
  JOB_Work_Shift_TypesList,
} from "utils/Constants";
import { TalentService } from "utils/TalentService";
import { getLoggedInUserInstitutions, isAdmin, isInstitutionAdmin, isPractitioner } from "utils/UserHelper";
import { createImageFromInitials, getRandomColor } from "utils/Utils";
import JobsdetailsBanner from "./JobsdetailsBanner";
import CandidateWidget from "common/CandidateWidget";
import PeopleKnowWidget from "common/PeopleKnowWidget";
import "./jobhomepage.css";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const JobHomePage = (props) => {
  const { alert } = props;
  const params = useParams();
  const { jobId } = params;
  const [jobInfo, setJobInfo] = useState(null);
  const [applicantStatus, setApplicantStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { primarySpeciality } = currentUser;
  const [isAdminOfCurrentJobInstitution, setIsAdminOfCurrentJobInstitution] = useState(false);
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const [skillmoreVal, setSkillmoreVal] = useState(5);

  const toggleForm = () => setModal(!modal);
  const loggedInUserInstitutions = getLoggedInUserInstitutions();

  const getApplicantStatus = (jobId) => {
    setLoading(true);
    TalentService.getJobApplicantStatus(jobId)
      .then((response) => {
        let { data } = response;
        if (response.status === 200 || response.status === 201) {
          setApplicantStatus(data?.status);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("Error while getting connection status: ", error);
      });

  };

  const getJob = (jobId) => {
    if (jobId) {
      (async () => {
        setLoading(true);
        TalentService.getJob(jobId)
          .then((response) => {
            setLoading(false);
            let { data } = response;
            if (response.status === 200 || response.status === 201) {
              if (isPractitioner())
                getApplicantStatus(data?.id);
              setJobInfo(data);
              checkIfCurrentUserAdminOfJobInstitution(data?.institutionId);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log("Error while getting connection status: ", error);
          });
      })();
    }
  };
  useEffect(() => {
    if (jobId)
      getJob(jobId);
  }, [jobId]);


  const showActionButton = (jobInfo) => {
    let possibleApplicationStatus = ["APPLIED", "SHORTLISTED", "INTERVIEW", "OFFERED", "ACCEPTED", "ON_HOLD", "HIRED", "REJECTED", "CLOSED"];
    if (isPractitioner()) {
      if (jobInfo?.creatorProfile === null) {
        if (jobInfo?.status === 'PUBLISHED') {
          return (
            <>
              {
                possibleApplicationStatus.includes(applicantStatus)
                  ?
                  <Button
                    color="primary"
                    className="nex-btn-primary"
                    disabled
                  >
                    {applicantStatus}
                  </Button>
                  :
                  <Button
                    color="primary"
                    className="nex-btn-primary"
                    onClick={() => {
                      // Check the apply mode
                      if (jobInfo?.applyMode === 'EMAIL') {
                        // Handle the email application logic
                        toggleForm();
                      } else {
                        // Redirect to the specified link for other modes
                        window.open(jobInfo?.linkToApply, "_blank");
                      }
                    }}
                  >
                    Apply For The Job
                  </Button>
              }
            </>
          );
        }
      }
      else {
        if (jobInfo?.creatorProfile?.profileGuid === currentUser?.guid || isAdminOfCurrentJobInstitution) {
          if (jobInfo?.status === "DRAFT" || jobInfo?.status === "SUBMITTED") {
            return (
              <>
                <Button
                  color="success"
                  className="btn nex-btn-primary nex-apply-btn"
                  onClick={() =>
                    updateJobStatus(jobInfo?.id, jobInfo?.status)
                  }
                >
                  {`${jobInfo?.status === "DRAFT"
                    ? "Submit"
                    : "Publish"
                    }`}{" "}
                  This Job
                </Button>
              </>
            )
          }
          return (
            <>
              <JobVisitors jobProfileId={jobInfo?.profileId} jobId={jobInfo?.id} jobTitle={jobInfo?.title} />
            </>
          )
        }
        else {
          return (
            <>
              {
                possibleApplicationStatus.includes(applicantStatus)
                  ?
                  <Button
                    color="primary"
                    className="nex-btn-primary"
                    disabled
                  >
                    {applicantStatus}
                  </Button>
                  :
                  <Button
                    color="primary"
                    className="nex-btn-primary"
                    onClick={() => {
                      // Check the apply mode
                      if (jobInfo?.applyMode === 'EMAIL') {
                        // Handle the email application logic
                        toggleForm();
                      } else {
                        // Redirect to the specified link for other modes
                        window.open(jobInfo?.linkToApply, "_blank");
                      }
                    }}
                  >
                    Apply For The Job
                  </Button>
              }
            </>
          );
        }
      }
    }

    if (isAdmin()) {
      if (jobInfo?.status === "DRAFT" || jobInfo?.status === "SUBMITTED") {
        return (
          <>
            <Button
              color="success"
              className="px-3 py-2 mb-2 btn-block"
              onClick={() =>
                updateJobStatus(jobInfo?.id, jobInfo?.status)
              }
            >
              {`${jobInfo?.status === "DRAFT"
                ? "Submit"
                : "Publish"
                }`}{" "}
              This Job
            </Button>
          </>
        )
      }
      else {
        return (
          <>
            <JobVisitors jobProfileId={jobInfo?.profileId} jobId={jobInfo?.id} jobTitle={jobInfo?.title} />
          </>
        )
      }
    }

    if (isInstitutionAdmin()) {
      if (isAdminOfCurrentJobInstitution) {
        if (jobInfo?.status === "DRAFT" || jobInfo?.status === "SUBMITTED") {
          return (
            <>
              <Button
                color="success"
                className="px-3 py-2 mb-2 btn-block"
                onClick={() =>
                  updateJobStatus(jobInfo?.id, jobInfo?.status)
                }
              >
                {`${jobInfo?.status === "DRAFT"
                  ? "Submit"
                  : "Publish"
                  }`}{" "}
                This Job
              </Button>
            </>
          )
        }
        else {
          return (<>
            <JobVisitors jobProfileId={jobInfo?.profileId} jobId={jobInfo?.id} jobTitle={jobInfo?.title} />
          </>)
        }
      }
    }

  }

  const showWidget = (jobInfo) => {
    if (isPractitioner()) {
      if (jobInfo?.creatorProfile === null) {
        if (jobInfo?.status === 'PUBLISHED') {
          return (
            <>
              <JobWidget
                widgetTitle="Similar Jobs"
                primarySpeciality={jobInfo.primarySpeciality}
                jobId={jobInfo.id}
              />
              {isPractitioner() && primarySpeciality &&
                <PeopleKnowWidget speciality={primarySpeciality} />
              }
            </>
          );
        }
        return (
          <JobWidget
            widgetTitle="Similar Jobs"
            primarySpeciality={jobInfo.primarySpeciality}
            jobId={jobInfo.id}
          />
        )
      }
      else {
        if (jobInfo?.creatorProfile?.profileGuid === currentUser?.guid || isAdminOfCurrentJobInstitution) {
          if (jobInfo?.status === "DRAFT" || jobInfo?.status === "SUBMITTED") {
            return (
              <>
                <CandidateWidget primarySpeciality={jobInfo?.primarySpeciality} jobCity={jobInfo?.city} />
              </>
            )
          }
          return (
            <>
              <CandidateWidget primarySpeciality={jobInfo?.primarySpeciality} jobCity={jobInfo?.city} />
            </>
          )
        }
        else {
          return (
            <>
              <JobWidget
                widgetTitle="Similar Jobs"
                primarySpeciality={jobInfo.primarySpeciality}
                jobId={jobInfo.id}
              />
            </>
          );
        }
      }
    }

    if (isAdmin()) {
      if (jobInfo?.status === "DRAFT" || jobInfo?.status === "SUBMITTED") {
        return (
          <>
            <CandidateWidget primarySpeciality={jobInfo?.primarySpeciality} jobCity={jobInfo?.city} />
          </>
        )
      }
      else {
        return (
          <>
            <CandidateWidget primarySpeciality={jobInfo?.primarySpeciality} jobCity={jobInfo?.city} />
          </>
        )
      }
    }

    if (isInstitutionAdmin()) {
      if (isAdminOfCurrentJobInstitution) {
        if (jobInfo?.status === "DRAFT" || jobInfo?.status === "SUBMITTED") {
          return (
            <>
              <CandidateWidget primarySpeciality={jobInfo?.primarySpeciality} jobCity={jobInfo?.city} />
            </>
          )
        }
        else {
          return (<>
            <CandidateWidget primarySpeciality={jobInfo?.primarySpeciality} jobCity={jobInfo?.city} />
          </>)
        }
      }
      return (
        <JobWidget
          widgetTitle="Similar Jobs"
          primarySpeciality={jobInfo.primarySpeciality}
          jobId={jobInfo.id}
        />
      );
    }

  }

  const updateJobStatus = (jobGuid, currentJobStatus) => {
    if (currentJobStatus === "DRAFT") {
      history.push(`/new/update-jobs/${jobInfo.profileId}`);
    } else {
      let act = "PUBLISHED";
      const payload = [
        {
          op: "replace",
          path: "/status",
          value: act,
        },
      ];
      TalentService.patchJob(jobGuid, payload).then((response) => {
        const { status } = response;
        if (status === 200) {
          showSuccessMessage("Job Updated Successfully...");
          getJob(jobId);
        } else {
          showErrorMessage(FAILTOSAVERECOED);
        }
      });
    }
  };

  const ShowExperience = ({ min, max }) => {
    if ((!min && !max) || min === '0' && max === '0')
      return (
        <p>Any</p>
      )
    else if (min && !max) {
      return (
        <p>{min || 0} Years</p>
      );
    }
    else if (!min && max) {
      return (
        <p>{max || 0} Years</p>
      );
    }
    else {
      return (
        <p>{min || 0} -{" "}
          {max || 0} Years</p>
      );
    }
  }

  const ShowSalary = ({ min, max }) => {
    if ((!min && !max) || min === '0' && max === '0')
      return (
        <p>Negotiable</p>
      )
    else if (min && !max) {
      return (
        <p>
          <span>
            <FontAwesomeIcon
              icon={faRupeeSign}
              className="ml-2 mr-1 text-gray f-13"
            />{min || 0}
          </span>
        </p>
      );
    }
    else if (!min && max) {
      return (
        <p>
          <span>
            <FontAwesomeIcon
              icon={faRupeeSign}
              className="ml-2 mr-1 text-gray f-13"
            />
            {max || 0}
          </span>
        </p>
      );
    }
    else {
      return (
        <p>
          <span>
            <FontAwesomeIcon
              icon={faRupeeSign}
              className="ml-2 mr-1 text-gray f-13"
            />
            {min || 0} -{" "}
            <FontAwesomeIcon
              icon={faRupeeSign}
              className="ml-2 mr-1 text-gray f-13"
            />
            {max || 0}
          </span>
        </p>
      );
    }
  }

  const checkIfCurrentUserAdminOfJobInstitution = (instId) => {
    if (loggedInUserInstitutions && loggedInUserInstitutions.includes(instId)) {
      setIsAdminOfCurrentJobInstitution(true);
    }
  }

  return (
    <div className="nex-jobs-details-w">

      {jobInfo && (
        <SEOWrapper title={jobInfo?.title ?? ""} content={jobInfo?.title ?? ""}>
          <Row className="container-jobs px-0">
            <Col className="nex-app-no-pad nex-job-lists-content" >
              <div className="nex-job-banner-sections">
                <JobsdetailsBanner
                  institutionDetails={jobInfo?.institutionDetails}
                />
                <Card className="nexogic-job-info-header bg-white ">
                  <div className="job-head-inner">
                    {
                      <Link className="job-info-image-link"
                        to={`/institution/${jobInfo?.institutionDetails?.profileId}`}
                      >
                        <div className="job-info-image">
                          {jobInfo?.institutionDetails &&
                            jobInfo?.institutionDetails?.avatarId ? (
                            <img
                              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${jobInfo.institutionId}/${jobInfo.institutionDetails.avatarId}`}
                              alt="#"
                              className="img-fluid"
                            />
                          ) : (
                            <img
                              src={createImageFromInitials(
                                280,
                                jobInfo.institutionDetails?.name,
                                getRandomColor(null)
                              )}
                              alt="profile pic"
                              className="img-fluid"
                            />
                          )}
                        </div>
                      </Link>
                    }
                    <div className="job-info-content">
                      <h1 className="nex-text-xxl nex-heading-xxl">{jobInfo?.title}
                        <Badge className="ml-3 mr-2 badge badge-pill green" color="primary" pill>
                          {jobInfo?.opportunity || ""}
                        </Badge>
                      </h1>
                      <p className="speciality nex-text-lg text-black">
                        {/*<img
                        src={graduateSmallIcon}
                        className="img-fluid mt-1 mr-1"
                        alt="#"
                      />*/}
                        <span className="icon"><svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4 11H10M7 8V14M3 4V6L1.21115 9.57771C1.07229 9.85542 1 10.1616 1 10.4721V16C1 17.1046 1.89543 18 3 18H11C12.1046 18 13 17.1046 13 16V10.4721C13 10.1616 12.9277 9.85542 12.7889 9.57771L11 6V4M3 4H11M3 4C2.44772 4 2 3.55228 2 3V2C2 1.44772 2.44772 1 3 1H11C11.5523 1 12 1.44772 12 2V3C12 3.55228 11.5523 4 11 4" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        </span>
                        {jobInfo?.primarySpeciality || ""}
                      </p>
                      {jobInfo?.institutionDetails?.name && (
                        <div>
                          <p className="nex-text-lg text-black">
                            <img
                              src={locationIcon}
                              className="img-fluid icon"
                              alt="#"
                            />
                            <span>{jobInfo?.location}, </span>
                            <span>{jobInfo?.city}</span>
                          </p>
                        </div>
                      )}
                      <p className="nex-text-lg text-black">
                        {/*<img
                        src={dateCalenderIcon}
                        className="img-fluid mt-1 mr-1"
                        alt="#"
                      />*/}

                        {JOB_PositionTypesList.filter(
                          (node) => node?.value === jobInfo?.position
                        ).length > 0
                          ? (<>
                            <span className="icon"><svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M17 9H1M17 9V17C17 18.1046 16.1046 19 15 19H3C1.89543 19 1 18.1046 1 17V5C1 3.89543 1.89543 3 3 3H15C16.1046 3 17 3.89543 17 5V9ZM12 1V5M6 1V5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            </span>
                            {JOB_PositionTypesList.filter(
                              (node) => node?.value === jobInfo?.position
                            )[0].label}
                          </>)
                          : ""}
                      </p>

                      {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
                        <div className="shl-wrp job-details-text">
                          {/* <Button color="primary" outline className="mr-2">
                          <FontAwesomeIcon icon={faThList} className="mr-2" />
                          Shortlist
                        </Button> */}
                          <Link to='' style={{ textDecoration: "none" }}>
                            <img
                              src={emailSmallIcon}
                              className="img-fluid mt-1 mr-1"
                              alt="#"
                            />
                            Email Job
                          </Link>
                          <div className="socialShare">
                            <Link to='' className="mr-3">
                              <FacebookShareButton
                                url={`https://devapp.nexogic.com/jobs/${jobInfo.profileId}`}
                                hashtag="#nexogic"
                              >
                                <FontAwesomeIcon icon={faFacebook} />
                              </FacebookShareButton>
                            </Link>
                            <Link to='' className="mr-3">
                              <TwitterShareButton
                                url={`https://devapp.nexogic.com/jobs/${jobInfo.profileId}`}
                                title={jobInfo.title}
                              >

                                <img src={twitter_logo} alt="" />
                              </TwitterShareButton>
                            </Link>
                            <Link to=''>
                              <LinkedinShareButton
                                url={`https://devapp.nexogic.com/jobs/${jobInfo.profileId}`}
                              >
                                <FontAwesomeIcon icon={faLinkedin} />
                              </LinkedinShareButton>
                            </Link>
                          </div>
                        </div>
                      )}

                      <div className="action">
                        {jobInfo &&
                          <>
                            {showActionButton(jobInfo)}
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </Card>
              </div>

              <Card className="nex-jobs-details-card open">
                <CardBody className="p-0">
                  <Row className="need-margin">
                    <Col lg={12}>
                      <h2 className="nex-text-xl nex-job-section-title">Job Details</h2>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <div className="j-list-item">
                          <label>Title : </label>
                          <p>{jobInfo?.title}</p>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div className="j-list-item">
                          <label>Role : </label>
                          <p>{jobInfo?.role || ""}</p>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div className="j-list-item">
                          <label>Primary Speciality : </label>
                          <p>{jobInfo?.primarySpeciality}</p>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div className="j-list-item">
                          <label>Required Qualifications : </label>
                          <p>{jobInfo?.qualifications || ""}</p>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <div className="j-list-item ">
                          <label>Work Shift : </label>
                          <p>
                            {JOB_Work_Shift_TypesList.filter(
                              (node) => node?.value === jobInfo?.shift
                            ).length > 0
                              ? JOB_Work_Shift_TypesList.filter(
                                (node) => node?.value === jobInfo?.shift
                              )[0].label
                              : ""}
                          </p>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div className="j-list-item ">
                          <label>Work Experience : </label>
                          <ShowExperience min={jobInfo?.minExperience} max={jobInfo?.maxExperience} />
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <div className="j-list-item  ">
                          <label>Salary : </label>
                          <ShowSalary min={jobInfo?.minSalary} max={jobInfo?.maxSalary} />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg={12}>
                      <FormGroup>
                        <label>Required Skills :</label>
                        <p className="">
                          {jobInfo?.skills.slice(0, skillmoreVal).map((node, index) => (
                            <Badge className=" mr-1" color="light" pill key={index}>
                              {node}
                            </Badge>
                          ))}
                          {skillmoreVal < 6 && jobInfo?.skills.length > 6 &&
                            <span className="readmore badge badge-light" onClick={() => setSkillmoreVal(jobInfo?.skills.length)}>Show More Skills...</span>
                          }
                        </p>
                      </FormGroup>
                    </Col>


                    <Col lg={12}>
                      <FormGroup>
                        <label>Job Description : </label>
                        <div
                          className="job-description"
                          dangerouslySetInnerHTML={{
                            __html: jobInfo?.description,
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              {(currentUser?.guid === jobInfo?.createdBy) || (isInstitutionAdmin && isAdminOfCurrentJobInstitution) && jobInfo?.screeningQuestions &&
                <>
                  <Card className="d-flex bg-white questions">
                    <CardBody>
                      <Row><h5>Asked Questions</h5></Row>
                      {jobInfo?.screeningQuestions.map((e) => (
                        <div className="question">
                          <Row >
                            <Col className="job-question-label" lg={3} md={3} sm={3} xs={12}>Question: </Col>
                            <Col className="job-question-text" lg={9} md={9} sm={9} xs={12}>{e?.question}</Col>
                          </Row>
                          <Row style={{ marginTop: "3px" }}>
                            <Col className="job-answer-type-label" lg={3} md={3} sm={3} xs={12}>Answer type: </Col>
                            <Col className="job-answer-type-text" lg={9} md={9} sm={9} xs={12}>{e?.renderType}</Col>
                          </Row>
                          {e?.renderType === 'SELECT' &&
                            <Row style={{ marginTop: "3px" }}>
                              <Col className="job-answer-options-label" lg={3} md={3} sm={3} xs={12}>Options: </Col>
                              <Col className="job-answer-options-text" lg={9} md={9} sm={9} xs={12}>{(e?.options).join(', ')}</Col>
                            </Row>
                          }
                        </div>
                      ))
                      }
                    </CardBody>
                  </Card>
                </>
              }
            </Col>
            <Col className="nex-job-sidebar v2 pt-0">
              {jobInfo &&
                <span>{showWidget(jobInfo)}</span>
              }
            </Col>
          </Row>
        </SEOWrapper>
      )
      }
      {
        loading && (
          <div
            className="loading-spiner text-center  mt-2"
            style={{
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CommonLoader />
          </div>
        )
      }

      <Modal
        className="nex-apply-jobs-modal"
        isOpen={modal}
        toggle={toggleForm}
        backdrop="static"
        scrollable
        centered
      >
        <ModalHeader toggle={toggleForm} className="nex-apply-jobs-modal-header">
          <div className="nex-flex">
            <div className="nex-avatar sm">
              <img src='https://images-dev.nexogic.com/78c57ab1-9bc0-4095-bb3b-54283acb88c7/030da8a0-f027-4bc8-a1a1-3c65ef0cafde.jpg' alt="" />
            </div>
            <div className="info">
              <span className="card-title-sub">Applying for </span>
              <span className="card-title">{jobInfo?.title} , {jobInfo?.institutionDetails?.name}</span>
            </div>
          </div>
          {/**  <span style={{ color: "gray" }}>Apply to</span> <span style={{ fontWeight: "400" }}>{jobInfo?.title} , {jobInfo?.institutionDetails?.name}</span>*/}</ModalHeader>
        <ModalBody>
          <ApplyJobs practitioner={currentUser} jobDet={jobInfo} getJob={getJob} {...props} toggle={toggle} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default withAlert()(memo(JobHomePage));
