import { useState, useEffect, useMemo } from "react";
import { ErrorMessage, Field } from "formik";
import { useSelector } from "react-redux";
import hospital_icon from 'assets/images/hospital_1.png';
import marker_icon from 'assets/images/svgs/marker-line-icon.svg';
import radio_tick from 'assets/images/radio-tick.png';
import { PractitionerService } from "utils/PractitionerService";
import { showErrorMessage } from "common/AlertContainer";
import { createImageFromInitials, getRandomColor, makeFullNameWithAddress, makeFullNameWithAddressWhenAddressIsNested } from "utils/Utils";
import { isInstitutionAdmin, isPractitioner } from "utils/UserHelper";
import { InstitutionServices } from "utils/InstitutionServices";
import { encrypt } from "utils/EncryptDecrypt";
import CommonLoader from "common/CommonLoader";

function JobCreateFormTwo(props) {
  const [hospitalList, setHospitalList] = useState([]);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const isPractitionerUser = useMemo(() => isPractitioner(), []);
  const isInstitutionAdminUser = useMemo(() => isInstitutionAdmin(), []);
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [loadingInstitutions, setLoadingInstitutions] = useState(true);

  const getHospitalList = () => {
    if (isPractitionerUser)
      PractitionerService.listExperiences(currentUser?.guid)
        .then((response) => {
          if (response.status === 200) {
            const seenInstitutions = new Set();
            const hospitalList = [...response.data]
              .map((node) => {
                return {
                  ...node,
                  institutionName: makeFullNameWithAddressWhenAddressIsNested(node?.institutionDetail),
                  name: node?.institutionDetail?.name,
                  avatarId: node?.institutionDetail?.avatarId,
                  institutionId: node?.institutionDetail?.guid,
                };
              })
              .filter((nodeItem) => nodeItem?.presentlyWorking)
              .filter((nodeItem) => {
                if (seenInstitutions.has(nodeItem.institutionId)) {
                  return false;
                } else {
                  seenInstitutions.add(nodeItem.institutionId);
                  return true;
                }
              });

            setHospitalList(hospitalList);
            setLoadingInstitutions(false);
          }
        })
        .catch((err) => {
          console.log(err.message);
          showErrorMessage(err.message);
        });


    if (isInstitutionAdminUser) {
      const insts = currentUser.groups;

      const promises = insts.map(instId => InstitutionServices.getInstitutionById(instId));

      Promise.all(promises)
        .then((responses) => {
          const hospitalList = responses
            .filter(response => response.status === 200)
            .map(response => {
              const node = response.data;
              return {
                ...node,
                institutionName: makeFullNameWithAddress(node),
                name: node?.name,
                avatarId: node?.avatarId,
                institutionId: node?.guid,
              };
            });
          setLoadingInstitutions(false);
          setHospitalList(hospitalList);
        })
        .catch((err) => {
          console.log(err.message);
          showErrorMessage(err.message);
        });
    }
  }

  useEffect(() => {
    getHospitalList();
  }, []);

  const handleInstitutionSelect = (institutionId) => {
    setSelectedInstitution(institutionId);
    // const payload = {
    //   "institution": institutionId
    // }
    // setData(prev => ({ ...prev, ...payload }));
  };

  return (
    <>
      <div className="nex-job-form-left-sec">
        <div className="nex-job-info">
          <img src={hospital_icon} alt="" className="graphic" />
          <div className="conts">
            <p><span className='light'>1/7</span> Job Post</p>
            <h2 className='title'>Let’s start with the hospital you want to go with...</h2>
            <p className='lead'>Choose the Hospital that you are creating a job for</p>
          </div>
        </div>
      </div>
      <div className="nex-job-form-right-sec">
        <h2 className='nex-text-xl'>Select the Hospital you would like to post the job</h2>
        <ErrorMessage name="institutionDetail" component="div" className="error-message"
          style={{ color: 'red', fontSize: '0.875rem', marginTop: '0.25rem' }}
        />
        {loadingInstitutions ?
          <div className="loading-spiner text-center m-5">
            <CommonLoader style={{ width: "2rem", height: "2rem" }} />
          </div>
          :
          <div className='nex-radio-hospital--w'>
            {hospitalList.map((e) => (
              <div
                key={e.institutionId}
                className={`nex-radio-hospital ${selectedInstitution === e.institutionId ? 'active' : ''}`}
                onClick={() => handleInstitutionSelect(e.institutionId)}
              >
                <div className="nex-custom-radio-v2">
                  <Field type="radio" name="institutionDetail" value={e.institutionId} className="stratch-link" title={e.institutionName} />
                  <div className="radio-icon">
                    <div className="highlited">
                      <img src={radio_tick} alt="" />
                    </div>
                  </div>
                </div>
                <div className="graphic">
                  {e.avatarId ? (
                    <img
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${e.institutionId}/${e.avatarId}`}
                      className="card-img"
                      alt="#"
                    />
                  ) : (
                    <img
                      src={createImageFromInitials(
                        50,
                        `${e.name}`,
                        getRandomColor(encrypt(e?.institutionId).toString())
                      )}
                      className="card-img"
                      alt="#"
                    />
                  )}
                </div>
                <div className="info">
                  <h3 className='nex-text-xl' >{e.institutionName}</h3>
                  <p className="nex-text text-black bold">
                    <span className='icon'>
                      <img src={marker_icon} alt="" />
                    </span>
                    {e.name}
                  </p>
                </div>
              </div>
            ))}
          </div>
        }
      </div>
    </>
  );
}

export default JobCreateFormTwo;
