import React from "react";
import { Col, Row, Card, CardImg, CardFooter, CardTitle, CardText, CardBody } from 'reactstrap'
import { Link } from 'react-router-dom'
import moment from "moment"

import { createImageFromInitials, getRandomColor } from 'utils/Utils'
import {  encrypt } from "utils/EncryptDecrypt"
import { getProfilePicUrl } from "utils/Utils"

const UserCard = (props) => {
  const { practitioners } = props

  return (
    <Row className='mb-4'>
      {practitioners.map(practitioner => {
        let practitionerId = encrypt(practitioner.practitionerId)
        const { firstName, lastName, title, profilePicUrl, primarySpeciality, cityState} = practitioner 
        let name = lastName? firstName + " " + lastName : firstName
      return (
        <Col sm={4} className="my-2" key={practitionerId}>
          <Card className="know-people-card search-list position-relative rounded-0 shadow-sm border-0 text-center h-100">
            <CardBody className="p-3 position-relative py-2 text-center">
              <CardImg 
                top
                src={profilePicUrl ? getProfilePicUrl(profilePicUrl) : createImageFromInitials(130, name, getRandomColor())}
                style={{ minHeight: "130px", width: "130px", height: "130px" }}
                className="mx-auto mb-1" alt="Card image cap"
              />
              
              <CardTitle className="font-weight-bold my-1 text-primary-dark" tag="h5">{title} {firstName} {lastName}</CardTitle>
              <CardText className="my-1 text-truncate-3" tag="p">{primarySpeciality ? primarySpeciality + ", " : null}
                <RenderExpYear practitioner={practitioner} /> {primarySpeciality}</CardText>
              <CardText className="text-muted mt-1 mb-0">{cityState} </CardText>
            </CardBody>
            <CardFooter className="bg-none border-primary-custom">
              <Link className="btn btn-link btn-block" to={`/profile/${practitionerId}`} >View Profile</Link>
            </CardFooter>
          </Card>
        </Col>
      )}
    )}
    </Row>
  )
}

const RenderExpYear = (props) => {
  let { practiceStartYear } = props.practitioner
  let currentYear = new Date().getFullYear()
  if( moment(practiceStartYear).format("YYYY")){
    practiceStartYear = parseInt(moment(practiceStartYear).format("YYYY"))
  }
  else{
    practiceStartYear = parseInt(practiceStartYear)
  }

  const expYears = currentYear - practiceStartYear
  // console.log(currentYear, practiceStartYear)
  if ( isNaN(expYears)) {
    return "1 year experience"
  } else if (expYears === 0){
    return "1 year experience"
  } else {
    return `${expYears}+ years experience`
  }
} 

export default UserCard