import React from 'react'
import './css/v1.scss'
import logo_img from 'assets/images/logo-top-icon.png'
import bg_img from 'assets/images/nex-ten-bg.png'
import ads_ten_left from 'assets/images/ads_ten_left.png'
import nex_ten_bot from 'assets/images/nex-ten-bot.png'
import nex_ten_bot_right from 'assets/images/nex-ten-bot-right.png'

function AdsTen(props) {
  return (
    <div className='nex-ads-wrapper v10' style={{backgroundImage: `url(${props.bg})`}}>
        
        <div className="nex-ads-content-w" style={{backgroundImage: `url(${props.bg_left_img})`}}>
            <div className="nex-ads-brand" >
                <img src={props.logo_img} alt="" />
            </div>
            <div className='nex-ads-content-left'>
                <h2 className="nex-ads-title">{props.title}</h2>
                <p>{props.short_desc}</p>
            </div>
            <div className='nex-ads-content-left-bot' style={{backgroundImage: `url(${nex_ten_bot})`}}>
                <h3 className="nex-ads-title-2">
                    Our Services
                </h3>
                <ul>
                {props.services.map((item, ind)=><li key={'ads-services-10-'+ind}><a href={item.url} target='_blank'>{item.title}</a></li>)}
                </ul>
            </div>
            
        </div>
        <div className='nex-ads-content-bot-right' style={{backgroundImage: `url(${props.bg_right_img})`}}>
                <div className="nex-ads-content-phone">
                    <div className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5499 5.5C15.5267 5.69057 16.4243 6.16826 17.128 6.87194C17.8317 7.57561 18.3094 8.47326 18.4999 9.45M14.5499 1.5C16.5792 1.72544 18.4715 2.63417 19.9162 4.07701C21.3608 5.51984 22.2719 7.41101 22.4999 9.44M21.4999 17.42V20.42C21.5011 20.6985 21.444 20.9742 21.3324 21.2293C21.2209 21.4845 21.0572 21.7136 20.852 21.9019C20.6468 22.0901 20.4045 22.2335 20.1407 22.3227C19.8769 22.4119 19.5973 22.4451 19.3199 22.42C16.2428 22.0856 13.2869 21.0341 10.6899 19.35C8.27376 17.8147 6.22527 15.7662 4.68993 13.35C2.99991 10.7412 1.94818 7.77099 1.61993 4.68C1.59494 4.40347 1.62781 4.12476 1.71643 3.86162C1.80506 3.59849 1.9475 3.35669 2.1347 3.15162C2.32189 2.94655 2.54974 2.78271 2.80372 2.67052C3.05771 2.55833 3.33227 2.50026 3.60993 2.5H6.60993C7.09524 2.49522 7.56572 2.66708 7.93369 2.98353C8.30166 3.29999 8.54201 3.73945 8.60993 4.22C8.73656 5.18007 8.97138 6.12273 9.30993 7.03C9.44448 7.38792 9.4736 7.77691 9.39384 8.15088C9.31408 8.52485 9.1288 8.86811 8.85993 9.14L7.58993 10.41C9.01349 12.9135 11.0864 14.9864 13.5899 16.41L14.8599 15.14C15.1318 14.8711 15.4751 14.6858 15.8491 14.6061C16.223 14.5263 16.612 14.5555 16.9699 14.69C17.8772 15.0286 18.8199 15.2634 19.7799 15.39C20.2657 15.4585 20.7093 15.7032 21.0265 16.0775C21.3436 16.4518 21.5121 16.9296 21.4999 17.42Z" stroke="#2BB8D2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

                    </div>
                    <div className="info">
                        Call to find out more
                        <strong>{props.phone}</strong>
                    </div>
                </div>
            </div>
    </div>
  )
}


/* default props */
AdsTen.defaultProps = {
    bg:bg_img,
    bg_left_img:ads_ten_left,
    bg_right_img:nex_ten_bot_right,
    logo_img:logo_img,
    logo_url:"#",
    logo_target:"_blank",
    title:"Lorem ipsum dolor sit amet.",
    short_desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    website:"www.nexogic.com",
    phone:"+0123456789",
    services:[
        {title:"Emergency", url:"#"},
        {title:"Sleep Therapy", url:"#"},
        {title:"Blood Testing", url:"#"},
        {title:"Lab Test", url:"#"}
    ]

  }
  
  export default AdsTen;