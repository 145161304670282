import {
  faAngleDown,
  faAngleUp,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormikDatePicker from "components/forms/formikFields/FormikDatePicker";
import { Field, FieldArray } from "formik";
import React, { useState } from "react";
import {
  Button,
  Col,
  Collapse,
  FormFeedback,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import "./style.scss";
import drop_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import add_icon from "assets/images/add-icon.png";
import del_icon from "assets/images/del-red-icon.png";

const defaultSchedules = {
  startTime: "",
  endTime: "",
  mon: false,
  tue: false,
  wed: false,
  thu: false,
  fri: false,
  sat: false,
  sun: false,
};

const RenderFieldArray = (props) => {
  const { arrayHelpers, index } = props;
  const { name } = arrayHelpers;
  const { schedulesHospital } =
    arrayHelpers.form.values.hospitalClinicFormData[index];
  if (schedulesHospital && schedulesHospital.length) {
    return schedulesHospital.map((schedule, indexKey) => {
      return (
        <div className="" key={indexKey}>
          <Row>
            <Col lg={5} className="pl-0 nexogic-date-fields">
              <Row>
                <Col>
                  <InputGroup>
                    <Field
                      id={`nex-rfa_startTime-${indexKey}`}
                      name={`${name}[${indexKey}].startTime`}
                      type="text"
                      placeholder="Start Time"
                      component={FormikDatePicker}
                      inputprops={{
                        dateFormat: "h:mm aa",
                        showTimeSelect: true,
                        showTimeSelectOnly: true,
                        calendarClassName: "mycustomcal",
                        autoComplete: "off",
                        showTwoColumnMonthYearPicker: true,
                        timeIntervals: 30,
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup>
                    <Field
                      id="rfa_endTime"
                      name={`${name}[${indexKey}].endTime`}
                      type="text"
                      placeholder="End Time"
                      component={FormikDatePicker}
                      inputprops={{
                        dateFormat: "h:mm aa",
                        showTimeSelect: true,
                        showTimeSelectOnly: true,
                        autoComplete: "off",
                        calendarClassName: "mycustomcal",
                        timeIntervals: 30,
                      }}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Col>
            <Col lg={6} className="nexogic-date-checkboxes-w">
              
              <div className="row nexogic-date-checkboxes">
                <div className="col">
                  <Field
                    className="nexogic-day-checkbox"
                    type="checkbox"
                    id={`${name}[${indexKey}].mon`}
                    name={`${name}[${indexKey}].mon`}
                  />
                  <Label htmlFor={`${name}[${indexKey}].mon`}></Label>
                  <Label>Mon</Label>
                </div>
                <div className="col">
                  <Field
                    className="nexogic-day-checkbox"
                    type="checkbox"
                    id={`${name}[${indexKey}].tue`}
                    name={`${name}[${indexKey}].tue`}
                  />
                  <Label htmlFor={`${name}[${indexKey}].tue`}></Label>
                  <Label>Tue</Label>
                </div>
                <div className="col">
                  <Field
                    className="nexogic-day-checkbox"
                    type="checkbox"
                    id={`${name}[${indexKey}].wed`}
                    name={`${name}[${indexKey}].wed`}
                  />
                  <Label htmlFor={`${name}[${indexKey}].wed`}></Label>
                  <Label>Wed</Label>
                </div>
                <div className="col">
                  <Field
                    className="nexogic-day-checkbox"
                    type="checkbox"
                    id={`${name}[${indexKey}].thu`}
                    name={`${name}[${indexKey}].thu`}
                  />
                  <Label htmlFor={`${name}[${indexKey}].thu`}></Label>
                  <Label>Thu</Label>
                </div>
                <div className="col">
                  <Field
                    className="nexogic-day-checkbox"
                    type="checkbox"
                    id={`${name}[${indexKey}].fri`}
                    name={`${name}[${indexKey}].fri`}
                  />
                  <Label htmlFor={`${name}[${indexKey}].fri`}></Label>
                  <Label>Fri</Label>
                </div>
                <div className="col">
                  <Field
                    className="nexogic-day-checkbox"
                    type="checkbox"
                    id={`${name}[${indexKey}].sat`}
                    name={`${name}[${indexKey}].sat`}
                  />
                  <Label htmlFor={`${name}[${indexKey}].sat`}></Label>
                  <Label>Sat</Label>
                </div>
                <div className="col">
                  <Field
                    className="nexogic-day-checkbox"
                    type="checkbox"
                    id={`${name}[${indexKey}].sun`}
                    name={`${name}[${indexKey}].sun`}
                  />
                  <Label htmlFor={`${name}[${indexKey}].sun`}></Label>
                  <Label>Sun</Label>
                </div>
              </div>
              {/** 
              <Row>
                <Col>
                  <div className="d-flex text-center">
                    <div className="flex-grow-1 mx-1">
                      <Label>Mon</Label>
                    </div>
                    <div className="flex-grow-1 mx-1">
                      <Label>Tue</Label>
                    </div>
                    <div className="flex-grow-1 mx-1">
                      <Label>Wed</Label>
                    </div>
                    <div className="flex-grow-1 mx-1">
                      <Label>Thu</Label>
                    </div>
                    <div className="flex-grow-1 mx-1">
                      <Label>Fri</Label>
                    </div>
                    <div className="flex-grow-1 mx-1">
                      <Label>Sat</Label>
                    </div>
                    <div className="flex-grow-1 mx-1">
                      <Label>Sun</Label>
                    </div>
                  </div>
                </Col>
                <Col xs="auto" className="px-1">
                  <Button color="link">
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="fa-fw text-white"
                    />
                  </Button>
                </Col>
              </Row>
              */}
            </Col>
            <Col lg={1} className="pr-0 d-flex justify-content-end ml-0 col-auto">
              <Button
                id="rfa_btn_del"
                color="link"
                className="cancel-btn"
                onClick={() => arrayHelpers.remove(indexKey)}
              >
                {/** <FontAwesomeIcon icon={faTrashAlt} className="text-danger" style={{ marginTop: '5px', fontSize: '15px' }} />*/}
                {/*<img src={del_icon} alt="" />*/}
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="11.8125" cy="12.3125" r="11.4375" fill="#FFEAEF" stroke="#FF8282" strokeWidth="0.75"/>
<path d="M8.20312 10.6484L8.84823 15.4176C8.97381 16.346 9.03661 16.8103 9.35459 17.0881C9.67257 17.366 10.141 17.366 11.0779 17.366H13.5062C14.4431 17.366 14.9116 17.366 15.2295 17.0881C15.5475 16.8103 15.6103 16.346 15.7359 15.4176L16.381 10.6484" stroke="#FF9191" strokeWidth="0.5625" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.5391 8.60397V8.60397C10.5391 8.01877 10.5391 7.72617 10.6453 7.49941C10.7569 7.26106 10.9486 7.0694 11.1869 6.95777C11.4137 6.85156 11.7063 6.85156 12.2915 6.85156V6.85156C12.8767 6.85156 13.1693 6.85156 13.396 6.95777C13.6344 7.0694 13.826 7.26106 13.9377 7.49941C14.0439 7.72617 14.0439 8.01877 14.0439 8.60397V8.60397" stroke="#FF9191" strokeWidth="0.5625" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.381 8.60937L8.20312 8.60938" stroke="#FF9191" strokeWidth="0.5625" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.8203 10.9453L11.1124 15.3263" stroke="#FF9191" strokeWidth="0.5625" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.7452 10.9453L13.4531 15.3263" stroke="#FF9191" strokeWidth="0.5625" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

              </Button>
            </Col>
          </Row>
          {indexKey === schedulesHospital.length - 1 && (
            <Row>
              <Col className="pl-0">
                <Button
                  id="rfa_btn_add"
                  color="primary" className="nex-btn-primary  nexogic-hospita-add-btn"
                  onClick={() =>
                    arrayHelpers.insert(indexKey + 1, defaultSchedules)
                  }
                >
                  {/*<img src={add_icon} alt="" />*/}{" Add"}
                </Button>
              </Col>
            </Row>
          )}
        </div>
      );
    });
  }
  return (
    <Row>
      <Col className="p-0">
        <Button
          id="rfa_btn_add"
          color="link" className="nex-btn-primary"
          onClick={() => arrayHelpers.push({ ...defaultSchedules })}
        >
          {/*<img src={add_icon} alt="" className="h-white" />*/}{" Add "}
        </Button>
      </Col>
    </Row>
  );
};

export const RenderSchedules = (props) => {
  const { index } = props;
  const { schedulesHospital } = props.values.hospitalClinicFormData[index];
  const [openOfficeTimings, setOfficeTimingsOpen] = useState(true);
  const toggleOfficeTimings = () => setOfficeTimingsOpen(!openOfficeTimings);

  return (
    <Col lg="12">
      <Row className="nexogic-form-inner-card card nexogic-hospital-inner-card">
        <Col
          className={`p-0 ${props.errors.schedulesHospital ? `border border-danger` : ``
            }`}
        >
          <h5 className="card-title">
            Work days and hours 
            <button type="button" className={`ml-auto btn nex-text d-none ${openOfficeTimings ? 'up' : ''}`} onClick={toggleOfficeTimings}>
              {/* <FontAwesomeIcon
                icon={openOfficeTimings ? faAngleUp : faAngleDown}
                
                className="fa-fw icon"
              />
              */}
              {/*<img src={drop_arrow} alt="" />*/}
              <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="9.25" cy="9" r="8.4375" fill="#ECF8FF" stroke="#46ABC9" strokeWidth="1.125"/>
<path d="M9.125 5.25L9.125 12.5039" stroke="#46ABC9" strokeWidth="1.125" strokeLinecap="round"/>
<path d="M12.75 8.875L5.49609 8.875" stroke="#46ABC9" strokeWidth="1.125" strokeLinecap="round"/>
</svg> Add More

            </button>

          </h5>
          {!!props?.touched?.hospitalClinicFormData &&
            !!props?.touched?.hospitalClinicFormData[index] &&
            !!props?.touched?.hospitalClinicFormData[index].schedulesHospital &&
            !!props?.errors?.hospitalClinicFormData &&
            !!props?.errors?.hospitalClinicFormData[index] &&
            !!props?.errors?.hospitalClinicFormData[index].schedulesHospital &&
            typeof props.errors.hospitalClinicFormData[index]
              .schedulesHospital === "string" && (
              <FormFeedback
                className="d-flex"
                invalid={
                  !!props.errors.hospitalClinicFormData[index].schedulesHospital
                }
              >
                {props.errors.hospitalClinicFormData[index].schedulesHospital}
              </FormFeedback>
            )}
        </Col>
        {/** 
        <Col xs={12} md={6} className="pl-0"> 
          {schedulesHospital.length > 0 && (
            <Row >
              <Col className="d-none d-md-block">
                <div className="d-flex text-center">
                  <div className="flex-grow-1 mx-1">
                    <Label>Mon</Label>
                  </div>
                  <div className="flex-grow-1 mx-1">
                    <Label>Tue</Label>
                  </div>
                  <div className="flex-grow-1 mx-1">
                    <Label>Wed</Label>
                  </div>
                  <div className="flex-grow-1 mx-1">
                    <Label>Thu</Label>
                  </div>
                  <div className="flex-grow-1 mx-1">
                    <Label>Fri</Label>
                  </div>
                  <div className="flex-grow-1 mx-1">
                    <Label>Sat</Label>
                  </div>
                  <div className="flex-grow-1 mx-1">
                    <Label>Sun</Label>
                  </div>
                </div>
              </Col>
              
              <Col xs="auto" className="px-1 d-none d-md-block">
                <Button color="link">
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="fa-fw text-white"
                  />
                </Button>
              </Col>
            </Row>
          )}
        </Col>
        */}
        <Collapse isOpen={openOfficeTimings}>
        <FieldArray
          name={`hospitalClinicFormData[${index}].schedulesHospital`}
          render={(arrayHelpers) => (
            <RenderFieldArray arrayHelpers={arrayHelpers} {...props} />
          )}
        />
      </Collapse>
      </Row>
      
    </Col>
  );
};
