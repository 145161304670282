import React from 'react'
import nodata from "assets/images/no-data.png";

export default function NoData(props) {
  return (
    <div className="nexogic-nodata-dotted">
        <div className="no-suggestion-text">
          <div className="view-on-header-card"><img src={nodata} alt="" /></div>
          {props.children}</div>
    </div>
  )
}
