import React from 'react'
import ChooseTheme from './Components/ChooseTheme'
import ThemeOne from './Components/ThemeOne'
import { Helmet } from 'react-helmet-async';

const Themes = () => {
  return (
    <>
     <Helmet>
        <title>Ui Dashboard Theme Page</title>
        <meta name="description" content="This is ui dashboard theme description"/>
        <link rel='canonical' href='/ui-dashboard/themes' />
      </Helmet>
    <h1>Theme One</h1>
    <ChooseTheme />
    <ThemeOne />
    </>
  )
}

export default Themes