import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import secondOpinionright from "assets/images/second-opinion.png";
import { useEffect } from "react";
import { useState } from "react";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import profile_sample from "assets/images/user-profile-sample.png";
import ReactQuill from "react-quill";
import { Link } from "react-router-dom";

export default function ConsultlationView() {
  const [pannel_one_on, setPannel_one_on] = useState(true);
  const [consultationValue, setConsultationValue] = useState("");

  useEffect(() => {
    document.body.classList.add("nexogic-so-page");
    return () => document.body.classList.remove("nexogic-so-page");
  }, []);
  return (
    <div className="nexogic-second-view-details">
      <Card className="nexogic-form-text-display-w border-0 bg-transparent">
        <CardBody className="">
          <Row className="need-margin">
            <Col xl={9}>
              <div className="nexogic-breadcumbs mb-3">
                  <Link to="/">Home</Link>
                  <Link to="/u/medicalcases/opinions">second opinion</Link>
                  <Link to="/u/medicalcases/details">details</Link>
                  <span >consultation</span>
              </div>
              <Card
                className={`nexogic-card-color-header ${
                  pannel_one_on ? "expanded" : ""
                }`}
              >
                <div className="card-header">
                  <h2>Patient Information</h2>
                  <button
                    className="collapse-btn"
                    type="button"
                    onClick={() => setPannel_one_on(!pannel_one_on)}
                  >
                    <img src={pannel_arrow} alt="" />
                  </button>
                </div>
                <div className="collapse-div ">
                  <CardBody>
                    <div className="mb-3 nexogic-consultation-text">
                      <p>
                        You can take medicine xxx for your knee pain. you can do
                        four type of leg exercise at evening which i attached.
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum.
                      </p>
                    </div>
                    <h3 className="card-title mb-3">Attachments</h3>
                    <div className="nexogic-upload-lists-card nexogic-consultation-attachments">
                      <div className="item">
                        <div className="card-body">
                          <div className="card-img">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="38"
                              height="46"
                              fill="none"
                              viewBox="0 0 38 46" className="icon"
                            >
                              <path
                                fill="#000"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M23.334 1.333H6a4.333 4.333 0 00-4.333 4.334v34.667A4.333 4.333 0 006 44.666h26a4.333 4.333 0 004.334-4.334v-26l-13-13zm8.666 39H6V5.668h15.167V16.5H32v23.834z"
                              ></path>
                            </svg>
                          </div>
                          <div className="card-info">
                            <h3 className="card-title">File name_12.cvs</h3>
                          </div>
                          <div className="actions center">
                            <div className="btns">
                              <span className="btn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="18"
                                  fill="none"
                                  viewBox="0 0 16 18"
                                >
                                  <path
                                    fill="#001244"
                                    d="M3 18c-.55 0-1.021-.196-1.413-.588A1.922 1.922 0 011 16V3H0V1h5V0h6v1h5v2h-1v13c0 .55-.196 1.021-.588 1.413A1.922 1.922 0 0113 18H3zM13 3H3v13h10V3zM5 14h2V5H5v9zm4 0h2V5H9v9z"
                                  ></path>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="nexogic-comment">
                      <h3 className="card-title  mb-3">
                        If You have any query then please comment below.
                      </h3>
                      <div className="nexogic-floated-input">
                        <textarea
                          type="text"
                          className="form-control nexogic-form-control-gray small"
                          placeholder="Write A Comment"
                        ></textarea>
                        <button type="button" name="" className="btn btn-trans">
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="22"
                            fill="none"
                            viewBox="0 0 23 22"
                          >
                            <path
                              stroke="#3EABCA"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M21.252 11.797l-15.538-.738m15.538.738L1.746 20.073l3.968-9.014m15.538.738L2.618 1.709l3.096 9.35"
                            ></path>
                          </svg>
                        </button>
                      </div>
                      <div className="nexogic-comment-lists">
                        <ul>
                          <li>
                            <div className="nexogic-comment-item">
                              <div className="graphic">
                                <img
                                  src="https://images-dev.nexogic.com/53efb6fd-1ceb-412a-b19d-b65032bde722/a8dd998a-8e20-4ac4-971f-bf91154aa6fc.png"
                                  alt=""
                                  className="card-img"
                                />
                              </div>
                              <div className="info">
                                <div className="comment-text">
                                  <h3 className="card-title">
                                    Steve Jobs{" "}
                                    <span className="text-muted">2h ago</span>
                                  </h3>
                                  <p className="card-text">
                                    Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Voluptatum voluptates,
                                    earum, dolore architecto odio dolor ipsa
                                    saepe possimus, aliquam omnis facere aut
                                    praesentium. Labore cupiditate, doloribus
                                    reprehenderit placeat nihil doloremque
                                    impedit totam est esse explicabo!
                                  </p>
                                </div>
                                <p className="card-text">
                                  <a href="" className="link">
                                    Reply
                                  </a>
                                </p>
                              </div>
                            </div>
                            <ul>
                              <li>
                                <div className="nexogic-comment-item">
                                  <div className="graphic">
                                    <img
                                      src="https://images-dev.nexogic.com/53efb6fd-1ceb-412a-b19d-b65032bde722/a8dd998a-8e20-4ac4-971f-bf91154aa6fc.png"
                                      alt=""
                                      className="card-img"
                                    />
                                  </div>
                                  <div className="info">
                                    <div className="comment-text">
                                      <h3 className="card-title">
                                        Steve Jobs{" "}
                                        <span className="text-muted">
                                          2h ago
                                        </span>
                                      </h3>
                                      <p className="card-text">
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. Voluptatum voluptates,
                                        earum, dolore architecto odio dolor ipsa
                                        saepe possimus, aliquam omnis facere aut
                                        praesentium. Labore cupiditate,
                                        doloribus reprehenderit placeat nihil
                                        doloremque impedit totam est esse
                                        explicabo!
                                      </p>
                                    </div>
                                    <p className="card-text">
                                      <a href="" className="link">
                                        Reply
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <div className="nexogic-comment-item">
                              <div className="graphic">
                                <img
                                  src="https://images-dev.nexogic.com/53efb6fd-1ceb-412a-b19d-b65032bde722/a8dd998a-8e20-4ac4-971f-bf91154aa6fc.png"
                                  alt=""
                                  className="card-img"
                                />
                              </div>
                              <div className="info">
                                <div className="comment-text">
                                  <h3 className="card-title">
                                    Steve Jobs{" "}
                                    <span className="text-muted">2h ago</span>
                                  </h3>
                                  <p className="card-text">
                                    Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Voluptatum voluptates,
                                    earum, dolore architecto odio dolor ipsa
                                    saepe possimus, aliquam omnis facere aut
                                    praesentium. Labore cupiditate, doloribus
                                    reprehenderit placeat nihil doloremque
                                    impedit totam est esse explicabo!
                                  </p>
                                </div>
                                <p className="card-text">
                                  <a href="" className="link">
                                    Reply
                                  </a>
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </div>
              </Card>
            </Col>
            <Col xl={3}>
            <div className="nexogic-widget-sidebar-card card border-0">
                <div className="card-body">
                  <div className="nexogic-h-btn-groups">
                   
                      <a
                        href=""
                        className="btn d-block nexogic_primary_button"
                        
                      >
                        Edit  Consultation
                      </a>                    
                  </div>
                </div>
              </div>
              <div className="nexogic-widget-sidebar-card card border-0">
                <h3 className="card-title h2">Patient Info</h3>
                <div className="card-body">
                  <div className="nexogic-quick-patient-info">
                    <div className="graphic">
                      <img src={profile_sample} alt="" className="avatar" />
                    </div>
                    <div className="Info">
                      <p className="card-text">
                        <b>Name</b> <span>Virat Palla</span>
                      </p>
                      <p className="card-text">
                        <b>Blood group </b> <span> AB+</span>
                      </p>
                      <p className="card-text">
                        <b>Specialty </b> <span> Surgery</span>
                      </p>
                      <p className="card-text">
                        <b>Weight </b> <span> 60kg</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nexogic-widget-sidebar-card card border-0 nexogic-sidebar-payment-card">
                <h3 className="card-title h2">Payment</h3>
                <div className="card-body">
                  <div className="info">
                    <p>
                      Second Opinion Allergy and Immunology Second Opinion -
                      Full Chart Review
                    </p>
                    <div className="price-sec">
                      <p>Total Payment : $100</p>
                      <p>Paid</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nexogic-widget-sidebar-card card border-0">
                <h3 className="card-title h2">Second Opinion</h3>
                <div className="card-body">
                  <ul className="nexogic-lists nexogic-custom-scroll">
                    <li>
                      <img
                        src={secondOpinionright}
                        alt=""
                        className="img-fluid"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
