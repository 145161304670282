import { SET_REFER_STATUS } from "./Actions";

let initialState = {
  referalStatusFlag: false,
  referUpdateFlag:false
};

function ProfilePreferencesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_REFER_STATUS:
      return {
        ...state,
        referalStatusFlag: action.payload,
        referUpdateFlag:true,
      };    
    default:
      return state;
  }
}

export default ProfilePreferencesReducer;
