import { Field, Formik, useFormikContext } from 'formik'
import React from 'react'
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap'
import ImageUpload from './ImageUpload';
import { useState } from 'react';
import landscape from "assets/images/landscape-icon.jpg";
import portrait from "assets/images/portrait-icon.jpg";
import { validateImageSize, validateImageType } from "utils/Utils";
import { InstitutionServices } from 'utils/InstitutionServices';
import * as Yup from "yup";
import { useEffect } from 'react';
import FormikInputField from 'components/forms/formikFields/FormikInputField';
import CommonLoader from 'common/CommonLoader';
import { MedialService } from 'utils/MediaService';
import { FAILTOSAVERECOED } from 'utils/Constants';

const validationSchema = Yup.object().shape({
  adsname: Yup.string()
    .required("This field is required"),
  // webLink: Yup.string()
  //   .required("This field is required")
  //   .url("Please enter a valid URL"),

})

const Ads = (props) => {
  const { eThemeDetails, institution, toggle, setModalIndex } = props
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [fileDetails, setFileDetails] = useState(null);
  const [adsDetails, setAdsDetails] = useState(null);
  const [advTypeIcon, setAdvTypeIcon] = useState('Portrait');
  const [isFileUploaing, setIsFileUploaing] = useState(false);
  const initialValue = { advtype: 'Portrait', advimgpath: '', webLink: '', adsname: '' };
  let ePageContent = eThemeDetails;

  // console.log("Ads-----------ePageContent,ePageContent?.draggables,ePageContent?.draggables?.length ?? 0-----------------------------",ePageContent,ePageContent?.draggables,ePageContent?.draggables?.length ?? 0);
  // const formik = useFormikContext();

  // const handleUpload = () => {
  //   // Perform upload logic and save to the database
  //   // You can send `selectedFile` to your server using an API call

  //   // Reset state after upload
  //   setSelectedFile(null);
  //   setPreviewImage(null);
  // };
  const handleCancel = () => {
    setSelectedFile(null);
    setPreviewImage(null);
    // setModal(true)
    toggle();
  }
  const advTypeImg = (rvalue) => {
    setAdvTypeIcon(rvalue);
  }

  const handleFileChange = (e, setFieldValue) => {
    const file = e.target.files[0];

    if (validateImageType(file)) {
      const maxSize = 1000 * 1000 * 5; //10MB
      const validSize = validateImageSize(file, maxSize);
      if (validSize === 1) {
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
        setFieldValue('advimgpath', file.name);
        setFileDetails(file);
      } else if (validSize === -1) {
        props.alert.error("Invalid File!");
      } else {
        props.alert.error("File must be less than 5MB");
      }
    } else {
      props.alert.error("Invalid File!");
    }
  };

  const getAdsDetails = async (instid) => {
    await MedialService.getMedias("MICRO_SITE", instid)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          setAdsDetails(() => data[data.length - 1].id);
          // return data[data.length - 1].id;
        } else {
          console.log(FAILTOSAVERECOED);
          return false
        }
      })
  }

  // useEffect(()=>{
  // let isMounted = true;
  // getAdsDetails("MICRO_SITE",institution?.guid)
  // return () => {
  //   isMounted = false;
  // };
  // },[institution?.guid])

  const onSubmit = async (values) => {
    if(ePageContent?.draggables?.length > 4){
      props.alert.error(" You have already uploaded maximum number of Ads.\n If you wish to upload more please contact Support ");
    }
    else{
    try {
      const formData = new FormData();
      formData.append('files', fileDetails);

      const response = await MedialService.uploadMedia("MICRO_SITE", institution?.guid, formData);
      let lastAdsId;
      if (response.status === 200) {
        const listOfAds = response.data;
        lastAdsId = listOfAds[listOfAds.length - 1].id;
      } else if (response.status === 409) {
        props.alert.error("The uploaded Ads banner already exists");
      }
      else {
        props.alert.error(FAILTOSAVERECOED);
      }

      if (ePageContent.hasOwnProperty('draggables') && Array.isArray(ePageContent.draggables)) {
        ePageContent.draggables.push({ imgid: lastAdsId, advtype: values.advtype, name: values.adsname, adspath: values.advimgpath, adslink: values.webLink });
      } else {
        ePageContent.draggables = [{ imgid: lastAdsId, advtype: values.advtype, name: values.adsname, adspath: values.advimgpath, adslink: values.webLink }];
      }
      const saveResponse = await InstitutionServices.updateDisplayPreferences(institution?.guid, ePageContent);

      if (saveResponse.status === 200) {
        props.alert.success("Preferences updated successfully");
        setModalIndex(Math.random())
        window.location.reload();
        toggle();
      }
      else {
        props.alert.error("Something went wrong, Please try again later");
      }
      return saveResponse.data;
    } catch (error) {
      console.error('Error:', error);
    }

    // if (fileDetails) {
    //   const formData = new FormData();
    //   formData.append('files', fileDetails);
    //   await MedialService.uploadMedia("MICRO_SITE", institution?.guid, formData)
    //     .then((response) => {
    //       const { status } = response;
    //       if (status === 200) {
    //         getAdsDetails(institution?.guid);
    //         console.log("adsDetails-------------------------------",adsDetails);
    //         if (ePageContent.hasOwnProperty('draggables') && Array.isArray(ePageContent.draggables)) {
    //           ePageContent.draggables.push({ id: adsDetails, advtype: values.advtype, name: values.adsname, adspath: values.advimgpath, adslink: values.webLink });
    //         } else {
    //           ePageContent.draggables = [{ id: adsDetails, advtype: values.advtype, name: values.adsname, adspath: values.advimgpath, adslink: values.webLink }];
    //         }
    //         console.log("ePageContent  adsDetails-------------------------------",adsDetails,ePageContent);
    //         InstitutionServices.updateDisplayPreferences(institution?.guid, ePageContent)
    //           .then(async (response) => {
    //             const { status, data } = response;
    //             if (status === 200) {
    //               console.log("Ads onSubmit==================2=======================", values, ePageContent, ePageContent.draggables?.length ?? 0);
    //               props.alert.success("Preferences updated successfully");
    //               setModalIndex(Math.random())
    //               toggle();
    //             }
    //             else {
    //               props.alert.error("Something went wrong, Please try again later");
    //             }
    //           })

    //       } else if (status === 409) {
    //         props.alert.error("The uploaded Ads banner already exists");
    //       }
    //       else {
    //         props.alert.error(FAILTOSAVERECOED);
    //       }
    //     })
    // }
    // setIsFileUploaing(true);
    // const epPageContent = {
    //   ...eThemeDetails,
    //   draggables:[{advtype:values.advtype, name:values.adsname, adspath:values.advimgpath, adslink:values.webLink}],
    // };

    // if (ePageContent.hasOwnProperty('draggables') && Array.isArray(ePageContent.draggables)) {
    //   ePageContent.draggables.push({ id: ePageContent.draggables?.length ?? 0, advtype: values.advtype, name: values.adsname, adspath: values.advimgpath, adslink: values.webLink });
    // } else {
    //   ePageContent.draggables = [{ id: ePageContent.draggables?.length ?? 0, advtype: values.advtype, name: values.adsname, adspath: values.advimgpath, adslink: values.webLink }];
    // }

    // console.log("Ads onSubmit===============1==========================", values, ePageContent);
    // await InstitutionServices.updateDisplayPreferences(institution?.guid, ePageContent)
    //   .then(async (response) => {
    //     const { status, data } = response;
    //     if (status === 200) {
    // console.log("Ads onSubmit==================2=======================", values, ePageContent, ePageContent.draggables?.length ?? 0);
    // if (fileDetails) {
    //   const formData = new FormData();
    //   formData.append('files', fileDetails);
    //   await MedialService.uploadMedia("MICRO_SITE", institution?.guid, formData)
    //     .then((response) => {
    //       const { status } = response;
    //       if (status === 200) {
    //         props.alert.success("Preferences updated successfully");
    //         setModalIndex(Math.random())
    //         toggle();
    //       } else if (status === 409) {
    //         props.alert.error("The uploaded Ads banner already exists");
    //       }
    //       else {
    //         props.alert.error(FAILTOSAVERECOED);
    //       }
    //     })
    // }


    // props.alert.success("Preferences updated successfully");
    // setModalIndex(Math.random())
    // toggle();
    //   }
    //   else {
    //     props.alert.error("Something went wrong, Please try again later");
    //   }
    // })
    // }, 3000);
    //   setPageContent((prePageContent => ({ ...prePageContent, draggables:[{id:10, advtype:values.advtype, name:values.adsname, adspath:values.advimgpath, adslink:values.webLink}] })),() => { 
    //   console.log("Ads onSubmit=========================================", values,pageContent,eThemeDetails);
    //   InstitutionServices.updateDisplayPreferences(institution?.guid, pageContent)
    //     .then((response) => {
    //       const { status, data } = response;
    //       if (status === 200) {
    //         props.alert.success("Preferences updated successfully");
    //       }
    //       else {
    //         props.alert.error("Something went wrong, Please try again later");
    //       }
    //     })
    //   toggle();
    // } 
  }  
  }
  return (
    <Formik
      initialValues={initialValue}
      validationSchema={validationSchema}
      onSubmit={(values) => { onSubmit(values) }}>
      {({ values, setFieldValue, handleSubmit }) => (
        <Form onSubmit={handleSubmit} className='nex-ads-model'>
          <Row className='px-2'>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label htmlFor="advtype" className='mb-3 mt-3 nex-text-xl small-md'>Advertisement Type : </Label>
                    <div className="">
                      <label className='d-block'>
                        <div className="d-flex flex-row align-items-baseline">
                          <Field type="radio" name="advtype" id="advtype-portrait" value="Portrait"
                            checked={values.advtype === 'Portrait'}
                            onChange={() => {
                              setFieldValue('advtype', 'Portrait');
                              advTypeImg('Portrait');
                            }}
                          />
                          <div className="d-flex flex-column pl-3 lh-lg">
                              <h3 className="nex-text bold text-black mb-0">Portrait</h3>
                              <p className='nex-text small'>Here the banner image will appear Horizontally.</p>
                          </div>
                        </div>
                      </label>
                      <label className='d-block'>
                        <div className="d-flex flex-row align-items-baseline">
                          <Field type="radio" name="advtype" id="advtype-ls" value="Landscape"
                            checked={values.advtype === 'Landscape'}
                            onChange={() => {
                              setFieldValue('advtype', 'Landscape');
                              advTypeImg('Landscape');
                            }}
                          />
                          <div className="d-flex flex-column pl-3 lh-lg">
                            <h3 className="nex-text bold text-black mb-0">Landscape</h3>
                            <p className='nex-text small'>Here the banner image will appear vertically</p>
                          </div>
                        </div>
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="adsname">Advertisement Name <small>( Max 20 Characters )</small></Label>
                    <Field
                      id="adsname"
                      name="adsname"
                      type="text"
                      component={FormikInputField}
                      placeholder='Enter Advertisement Name '
                      title='Enter an unique name for your ad max 20 chars'
                      maxLength={20}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label htmlFor="uploadadv" className='mb-3 mr-3'>Upload your Advertisement : </Label>
                    <div className='ads-image-upload'>
                      <div className="nexogic-custom-img-upload" id="nex-ids-ads-file-upload">
                        <input name="advImage" type="file" accept="image/*, png, jpg, jpeg" multiple hidden id="advImage" onChange={(e) => handleFileChange(e, setFieldValue)} />
                        <label className="image-upload text-center cursor-pointer" htmlFor="advImage">
                          <div className='icon'>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 10V9C7 6.23858 9.23858 4 12 4C14.7614 4 17 6.23858 17 9V10C19.2091 10 21 11.7909 21 14C21 15.4806 20.1956 16.8084 19 17.5M7 10C4.79086 10 3 11.7909 3 14C3 15.4806 3.8044 16.8084 5 17.5M7 10C7.43285 10 7.84965 10.0688 8.24006 10.1959M12 12V21M12 12L15 15M12 12L9 15" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                            {/*advTypeIcon === 'Portrait' ?
                              <img src={portrait} alt='portrait' height='30' />
                              : <img src={landscape} alt='portrait' height='30' />*/}
                          </div>
                          Upload image<br />
                          <p className='preview-info mx-3'>(.jpg, .png only image types with maximum size 5mb)</p>

                        </label>
                      </div>
                    </div>
                    {/* <ImageUpload setPreviewImage={setPreviewImage} setSelectedFile={setSelectedFile} advTypeIcon={advTypeIcon} /> */}
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              
              <Row>
                <Col md={12} className='ads-preview-container'>
                  {isFileUploaing ?
                    <div className="text-center mb-5" style={{ height: "10px" }} >
                      <CommonLoader />
                    </div>
                    :
                    previewImage ? (
                      <div>
                        <img src={previewImage} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                      </div>
                    )
                      : <div className='text-center py-5 bg-light'>
                        <h3>No Preview</h3>
                        <p className='preview-info mx-3'>For best fit please select image width min 1200px for Landscape and 350px for Portrait</p>
                        <p className='preview-info mx-3'>.jpg, .jpeg, .png etc types only</p>
                        <p className='preview-info mx-3'>Maximum image size upto 5mb</p>
                      </div>}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="webLink">Website Link</Label>
                <Field
                  id="webLink"
                  name="webLink"
                  type="text"
                  component={FormikInputField}
                  placeholder='Please Enter Website Link'
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="justify-content-center my-3">
                <div className="action-group">
                  <Button
                    type="submit"
                    id="nex-ids-ads-upload-btn"
                    className="nex-btn-primary"
                    disabled={selectedFile ? false : true}
                    color="secondary"
                  // onClick={handleSubmit}
                  >
                    Upload
                  </Button>
                  <Button
                    id="nex-ids-ads-cancel-btn"
                    className="nex-btn-primary-outline"
                    outline
                    color="primary"
                    onClick={() => handleCancel()}
                  // onClick={setModal(true)}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default Ads