import { Modal, ModalBody, ModalHeader } from "reactstrap";
import DocumentForm from "../DocumentForm";

const DocumentFormModal = (props) => {
    const { modal, setModal } = props;

    return (
        <Modal isOpen={modal} toggle={() => setModal(!modal)} className="nex-model-v2 modal-dialog-scrollable modal-dialog-centered">
            <ModalHeader toggle={() => setModal(!modal)}>Edit Document Form</ModalHeader>
            <ModalBody>
                <DocumentForm />
            </ModalBody>
        </Modal>
    )
}

export default DocumentFormModal;