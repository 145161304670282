import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ExperienceForm from "./ExperienceForm";

export const RenderExperienceModal = (props) => {
  const { isOpen, toggle } = props;

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        id="profile-modal"
        className="modal-dialog-scrollable modal-dialog-centered"
        backdrop="static"
      >
        <ModalHeader className="bg-white" toggle={toggle}>
          Experience
        </ModalHeader>
        <ModalBody>
          <ExperienceForm {...props} />
        </ModalBody>
      </Modal>
    </div>
  );
};
