import classnames from "classnames";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import SkiilsTypeaheadField from "components/forms/formikFields/SkiilsTypeaheadField";
import YearTypeaheadField from "components/forms/formikFields/YearTypeaheadField";
import { Field, Formik } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { withAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { authSetUser } from "redux-helpers/ActionCreator";
import { InstitutionTypes, TIME_CONSTANTS } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import { getUser, setUser } from "utils/UserHelper";
import { UserService } from "utils/UserService";
import * as Yup from "yup";
import InstitutesTypeahead from "./FormComponents/InstitutesTypeahead";
import NewInstitutionModal from "./FormComponents/NewInstituitionModal";

const validationSchema = Yup.object().shape({
  medicalInstituition: Yup.lazy((value) => {
    if (value && value.guid) {
      return Yup.object().shape({
        guid: Yup.string().required(
          "This field is required. You must select from options"
        ),
      });
    } else {
      return Yup.mixed().test(
        "",
        "This field is required. You must select from options", // or add new
        (value) => value === {}
      );
    }
  }),
  registrationNumber: Yup.string().min(3).required("This field is required"),
  registrationYear: Yup.string().required("This field is required").nullable(),
});

const years = TIME_CONSTANTS.YEARS();

const MedicalRegistrationForm = (props, { onNext, onBack, onSkip }) => {
  const { state } = useLocation();
  const history = useHistory();
  const formRef = useRef();
  const [input, setInput] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const currentUser = getUser();
  const dispatch = useDispatch();
  // dispatch(authSetUser(currentUser));
  const [userProfileId, setUserProfileId] = useState("");

  const initValues = useMemo(() => {
    return {
      medicalInstituition: "",
      registrationNumber: "",
      registrationYear: "",
    };
  }, []);

  const [initialValues, setInitialValues] = useState(initValues);

  useEffect(() => {
    if (Object.keys(currentUser).length > 0) {
      getMedicalRegistrationInfo(currentUser.guid);
      gePractitionersDetails(currentUser.guid);
      formRef.current.validateForm();
    }
  }, []);

  const getMedicalRegistrationInfo = (practitionerGuid) => {
    PractitionerService.getAllMedicalRegistrationDetails(practitionerGuid)
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data !== undefined
        ) {
          const { data } = response;
          if (data.length > 0) {
            setInitialValues({
              ...initValues,
              medicalInstituition: [],
              registrationNumber: data[0].registrationNumber,
              registrationYear: data[0].yearOfRegistration,
            });
            formRef.current.setFieldValue(
              "medicalInstituition",
              { guid: data[0].id, name: data[0].registrationCouncil },
              true
            );
          }
        }
      })
      .catch((error) => {
        console.log("PractitionerService.MedicalRegistrations error", error);
        props.alert.error(error.message);
      })
      .then(() => {});
  };

  const gePractitionersDetails = (practitionerGuid) => {
    PractitionerService.gePractitionersDetails(practitionerGuid)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(authSetUser(response.data));
          setUser(response.data);
        }
      })
      .catch((error) => {
        console.log("PractitionerService.gePractitionersDetails error", error);
        props.alert.error(error.message);
      });
  };

  const onSubmit = (formRef, exitFlag) => {
    const values = formRef.current.values;
    const payload = {
      registrationCouncil: values.medicalInstituition.name,
      registrationNumber: values.registrationNumber,
      yearOfRegistration: values.registrationYear,
    };
    setSubmitting(true);
    
    PractitionerService.MedicalRegistrations(
      payload,
      currentUser.guid,
      exitFlag
    )
      .then((response) => {
        setSubmitting(false);
        if (response.status === 200 || response.status === 201) {
          if (exitFlag) {   
            props.onNext();         
          } else {
            history.push(`/profile/${currentUser.profileId}`);
          }
        } else {
          if (response.validationErrors) {
            response.validationErrors.map((err) => {
              props.alert.error(`${err.field}: ${err.message}`);
            });
          } else if (response.message) {
            props.alert.error(response.message);
          }
        }
      })
      .catch((error) => {
        setSubmitting(false);
        console.log("PractitionerService.MedicalRegistrations error", error);
        props.alert.error("server facing problem while Medical Registrations");
      });
  };

  const saveExit = (exitFlag) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
      if (formRef.current.isValid) {
        onSubmit(formRef, exitFlag);
      }
    }
  };

  const skipExit = (exitFlag) => {
    // props.skipFlag('true');
    if(exitFlag==='skip'){
      formRef.current.handleSubmit();
        props.onSkip('SKIPPED');      
    }
  };

  const logout = () => {
    UserService.logout();
    // window.location.reload();
    window.location.replace('/login');

  };

  const getClaimsProfile = useCallback(
    (id) => {
      PractitionerService.getClaimsProfile(id)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            if (response?.data) {
              const { registrations } = response?.data;
              if (registrations && registrations.length > 0) {
                setInitialValues({
                  ...initValues,
                  medicalInstituition: [],
                  registrationNumber: registrations[0].registrationNumber,
                  registrationYear: registrations[0].yearOfRegistration,
                });
                formRef.current.setFieldValue(
                  "medicalInstituition",
                  {
                    guid: registrations[0].id,
                    name: registrations[0].registrationCouncil,
                  },
                  true
                );
              }
            }
          }
        })
        .catch((error) =>
          console.log("PractitionerService.getClaimsProfile error", error)
        );
    },
    [initValues]
  );

  useEffect(() => {
    if (state && state?.profileId) {
      getClaimsProfile(state?.profileId);
      setUserProfileId(state?.profileId);
    }
  }, [state, getClaimsProfile]);

  return (
    <Row className="nexogic-onboarding-form nexogic-form-content nex-medical-reg-sec">
      <Col>
        <Row className="cs-column-reverse">
          <Col md={'auto'} className="ml-auto nex-fieldset-field-floated-r">
            <Button
              className="btn nexogic_primary_button_outline  ml-auto save-exit"
              disabled={isSubmitting}
              onClick={logout}
            >
              Exit
            </Button>
          </Col>
        </Row>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values) => setInput(values)}
          innerRef={formRef}
          children={(props) => {
            return (
              <>
                <RenderForm
                  {...props}
                  isSubmitting={isSubmitting}
                  onBack={onBack}
                  next={saveExit}
                  skip={skipExit}
                  userProfileId={userProfileId}
                />
              </>
            );
          }}
        />
      </Col>
    </Row>
  );
};

const RenderForm = (props) => {
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
  const [iseditable, setisEditable] = useState(true);
  const { values, errors, isSubmitting, handleSubmit, userProfileId } = props;

  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };

  // useEffect(() => {
  //   if (userProfileId) {
  //     setisEditable(false);
  //   }
  // }, [userProfileId]);

  return (
    <form className="medical-registration-form nex-form-container" onSubmit={handleSubmit}>
      <div className="nex-form-section" data-aos="fade-up" data-aos-duration="1600" data-aos-delay="500">
          <div className="space-bottom-lg">
              <h2 className="nex-text-xxl text-center text-dark">Medical Registration</h2>
              <p className="text-center nex-text-xxl-sub">Provide your medical registration for authorization</p>
          </div>
          <Row className="mt-4">
        <Col lg={12}>
          <Field
            name="medicalInstituition"
            id="medicalInstituition"
            type="text"
            label="Medical Registration Council"
            component={InstitutesTypeahead}
            placeholder="Select"
            instituteType={InstitutionTypes.BOARD_OR_REGISTRATION_COUNCIL}
            disabled={!iseditable}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={8}>
          <Field
            name="registrationNumber"
            id="registrationNumber"
            label="Registration / NPI Number"
            type="text"
            component={FormikInputField}
            placeholder="Enter registration or NPI Number"
            autoComplete="off"
            disabled={!iseditable}
          />
        </Col>
        <Col lg={4}>
          <Field
            name="registrationYear"
            id="registrationYear"
            type="text"
            label="Registration Year"
            component={YearTypeaheadField}
            placeholder="YYYY"
            autoComplete="off"
            value={values.practicingSince}
            spellCheck={false}
            years={years}
            disabled={!iseditable}
          />
        </Col>
      </Row>
      <Row
        className={classnames({
          "mt-3": errors.registrationYear || errors.registrationNumber,
          "mt-4": !errors.registrationYear || !errors.registrationNumber,
        })}
      >
        <div className="col">
        
        </div>
      </Row>
      
      <NewInstitutionModal
        isOpen={nestedModal}
        toggle={toggleNested}
        closeAll={closeAll}
      />
      </div>
      <div className="actions">
          
          <div className="form-group">
            <Button color="primary"
              className="w-100 nex-max-sm mx-auto rf_btn_next"
              disabled={isSubmitting}
              type="submit"
              onClick={() =>  props.next(true)}
            >
              Next
            </Button>
          </div>
          <div className="form-group">
            <Button
              className=" btn-transparent w-100 nex-max-sm mx-auto rf_btn_skip"
              // disabled={isSubmitting}
              outline
              type="button"
              onClick={()=>props.skip('skip')}
            >
              Skip
            </Button>
          </div>
          

        </div>
      {/** 
      <fieldset className="u-flsd-wpr mb-3 nex-login-info-field-sec nex-fieldset-field-sec">
          <legend>Add Medical Registration</legend>
          
            <p className="nexogic-form-title-sub">
              Provide your Medical registration for authorization
            </p>
      <Row className="mt-4">
        <Col lg={12}>
          <Field
            name="medicalInstituition"
            id="medicalInstituition"
            type="text"
            label="Medical Registration Council"
            component={InstitutesTypeahead}
            placeholder="Select"
            instituteType={InstitutionTypes.BOARD_OR_REGISTRATION_COUNCIL}
            disabled={!iseditable}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={8}>
          <Field
            name="registrationNumber"
            id="registrationNumber"
            label="Registration / NPI Number"
            type="text"
            component={FormikInputField}
            placeholder="Enter registration or NPI Number"
            autoComplete="off"
            disabled={!iseditable}
          />
        </Col>
        <Col lg={4}>
          <Field
            name="registrationYear"
            id="registrationYear"
            type="text"
            label="Registration Year"
            component={YearTypeaheadField}
            placeholder="YYYY"
            autoComplete="off"
            value={values.practicingSince}
            spellCheck={false}
            years={years}
            disabled={!iseditable}
          />
        </Col>
      </Row>
      <Row
        className={classnames({
          "mt-3": errors.registrationYear || errors.registrationNumber,
          "mt-4": !errors.registrationYear || !errors.registrationNumber,
        })}
      >
        <div className="col">
        <div className="d-flex flex-wrap actions">
        <Button
            className="nexogic_primary_button_outline rf_btn_skip"
            // disabled={isSubmitting}
            outline
            type="button"
            onClick={()=>props.skip('skip')}
          >
            Skip
          </Button>
          <Button
            className="nexogic_primary_button rf_btn_next"
            disabled={isSubmitting}
            type="submit"
            onClick={() =>  props.next(true)}
          >
            Next
          </Button>

        </div>
        </div>
      </Row>
      <NewInstitutionModal
        isOpen={nestedModal}
        toggle={toggleNested}
        closeAll={closeAll}
      />
      </fieldset>*/}
    </form>
  );
};

export default withAlert()(MedicalRegistrationForm);
