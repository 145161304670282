import React from 'react'
import location_icon from "assets/images/location-1.png";
import { Link } from 'react-router-dom';

export default function QuickInfo(props) {
  return (
    <div className={`nex-quick-info ${props.className || ''}`}>
        {props.avatar && 
            <div className="graphic">
                {props.avatar}
            </div>
        }
        <div className="info my-auto">
                  {props.title && 
                    <h2 className="nex-text-lg stack-title bold mb-1">
                            {props.isTitleLink ? <Link to={props.titleLink}>{props.title}</Link> : <span>{props.title}</span>}
                    </h2>
                    }
                    {props.speciality &&
                        <p className="nex-text bold nex-text-blue text-truncate">{props.speciality}</p> 
                    }
                    {props.address &&
                      <p className="nex-text text-black text-truncate"><span className="icon"><img src={location_icon} alt="" /></span> {props.address}</p>
                    }    
                     
                    {props.children}
        </div>
    </div>
  )
}
