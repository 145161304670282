import React from 'react';
import {  Modal, ModalHeader, ModalBody } from 'reactstrap';
import CommitteeForm from "./CommitteeForm"
export const  RenderCommitteModal = (props) => {
  const {
    isOpen,
    toggle
  } = props;

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} id="profile-modal" className="modal-dialog-scrollable modal-dialog-centered" backdrop="static">
        <ModalHeader toggle={toggle}>Committees</ModalHeader>
        <ModalBody>
          <CommitteeForm {...props}/>
        </ModalBody>
      </Modal>
    </div>
  );
}

