import "assets/styles/typeahead.css";
import { ErrorMessage } from "formik";
import { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Label } from "reactstrap";

const AddressTypeaheadField = (props) => {
  const {
    disabled,
    placeholder,
    className,
    inputList,
    getAddressById,
    locationRef,
  } = props;
  const { name } = props.field;
  const { setFieldValue, setFieldTouched, touched, errors } = props.form;
  const [selectedLocation, setSelectedLocation] = useState("");

  const handleChange = (selected) => {
    setFieldValue(name, selected, true);
    if (selected !== undefined && selected.length === 1) {
      setSelectedLocation(selected[0].id);
    } else {
      setSelectedLocation("");
      getAddressById("");
    }
  };

  const setOnBlur = (name, flag) => {
    setFieldTouched(name, flag);
    getAddressById(selectedLocation);
    locationRef.current.hideMenu();
  };

  return (
    <>
      {props.label && (
        <Label htmlFor={name} className="d-block skills">
          {props.label}
        </Label>
      )}
      <Typeahead
        ref={locationRef}
        className={className}
        disabled={disabled}
        selected={props.value}
        id={name}
        options={inputList}
        labelKey={"value"}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={(e) => setOnBlur(name, true)}
        isInvalid={Boolean(touched[name] && errors[name])}
        inputProps={{
          autoComplete: name,
        }}
      />
      <ErrorMessage
        name={name}
        render={(msg) => (
          <span className="text-danger skill-err f-12 d-inline-block  mt-1 line-height-error">
            {msg}
          </span>
        )}
      />
    </>
  );
};
export default AddressTypeaheadField;
