import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, Formik } from "formik";
import { useState } from "react";
import { withAlert } from "react-alert";
import { Button, Col, Row, Spinner } from "reactstrap";
import * as Yup from "yup";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import { IdentityService } from "utils/IdentityService";

const initialValue = {
  currentPassword: "",
  password: "",
  confirmPassword: "",
};

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required("This field is required"),
  password: Yup.string()
    .required("This field is required")
    .matches(/^(?=.*[a-zA-Z])(?=.*[0-9]).{6,}$/, "Incorrect format"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password confirm is required"),
});

const General = (props) => {
  const [showPassword, onShowPassword] = useState(false);
  const [showNewPassword, onShowNewPassword] = useState(false);
  const [showConfirmPassword, onShowConfirmPassword] = useState(false);
  const toggleShowPassword = () => onShowPassword(!showPassword);
  const toggleShowNewPassword = () => onShowNewPassword(!showNewPassword);
  const toggleShowConfirmPassword = () =>
    onShowConfirmPassword(!showConfirmPassword);

  return (
    <>
    {props.isTitle && 
      <div className="nexogic-page-title">Change password</div>
    }
      <p className="caed-text">Create a new password that is at least 8 characters long.</p>
      <Row>
        <Col lg={12}>
          <div className="section-content">
            <Formik
              enableReinitialize={true}
              validationSchema={validationSchema}
              initialValues={initialValue}
              onSubmit={(values, { setSubmitting }) => {
                IdentityService.ResetPassword(values)
                  .then((response) => {
                    if (response.status === 200) {
                      props.alert.success("Password reset successfully");
                    } else {
                      if (response.validationErrors) {
                        response.validationErrors.map((err) => {
                          props.alert.error(`${err.field}: ${err.message}`);
                          return true;
                        });
                      } else if (response.message) {
                        props.alert.error(response.message);
                      }
                    }
                  })
                  .catch((error) => {
                    console.log("Reset password error", error);
                  })
                  .then(() => {
                    setSubmitting(false);
                  });
              }}
              children={({ handleSubmit, isSubmitting }) => {
                return (
                  <form onSubmit={handleSubmit} id="nex-setting-general-form">
                    
                      
                        <Row className="pt-3">
                          <Col lg={5}>
                            <div className="password-eye">
                              <Field
                                id="currentPassword"
                                name="currentPassword"
                                type={showPassword ? "text " : "password"}
                                label="Type your current password:"
                                component={FormikInputField}
                                placeholder="Enter your current password..."
                              />
                              <FontAwesomeIcon
                                onClick={toggleShowPassword}
                                className="show-password"
                                color="#2C3E50"
                                icon={!showPassword ? faEyeSlash : faEye}
                              />
                            </div>
                          </Col>
                          </Row>
                          <Row>
                          <Col lg={5}>
                            <div className="password-eye">
                              <Field
                                id="password"
                                name="password"
                                type={showNewPassword ? "text " : "password"}
                                label="Type your new password:"
                                component={FormikInputField}
                                placeholder="Enter new password"
                              />
                              <FontAwesomeIcon
                                onClick={toggleShowNewPassword}
                                className="show-password"
                                color="#2C3E50"
                                icon={!showNewPassword ? faEyeSlash : faEye}
                              />
                            </div>
                          </Col>
                          </Row>
                          <Row>
                          <Col lg={5}>
                            <div className="password-eye">
                              <Field
                                id="confirmPassword"
                                name="confirmPassword"
                                type={
                                  showConfirmPassword ? "text " : "password"
                                }
                                label="Retype your new password:"
                                component={FormikInputField}
                                placeholder="Confirm new password"
                              />
                              <FontAwesomeIcon
                                onClick={toggleShowConfirmPassword}
                                className="show-password"
                                color="#2C3E50"
                                icon={!showConfirmPassword ? faEyeSlash : faEye}
                              />
                            </div>
                          </Col>
                        </Row>
                     
                      
                        {isSubmitting ? (
                          <Button
                          id="nex-setting-general-submit"
                            type="submit"
                            disabled
                            className="change-pwd nexogic_primary_button"
                            style={{ minWidth: "145px" }}
                          >
                            <Spinner
                              style={{ width: "1rem", height: "1rem" }}
                            />
                          </Button>
                        ) : (
                          // <div className="loading-spiner text-center"><Spinner style={{ width: '2rem', height: '2rem' }} /></div>
                          <Button type="submit" className="change-pwd nexogic_primary_button mb-3" id="nex-setting-general-cp-btn">
                            Change password
                          </Button>
                        )}
                     
                  </form>
                );
              }}
            ></Formik>
          </div>
        </Col>
      </Row>
    </>
  );
};

General.defaultProps = {
  isTitle: true,
};

export default withAlert()(General);
