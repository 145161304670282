import React from 'react'
import secondOpinionImg from 'assets/images/second-opinion.png'
import ApoloImg from 'assets/images/Hospitals-applo.png';
import CareImg from 'assets/images/hospitals-care.png';
import UserSearchLists from './components/UserSearchLists';
import AsideOrthopedists from './components/AsideOrthopedists';
import DoctorProfileSec from './components/DoctorProfile';
import BookingForm from './components/BookingForm';
import AppointmentBookingForm from './components/BookingForm';
import user_img from 'assets/images/user-profile-sample.png'
import VisitForm from './components/VisitForm';
import IconVideo from './components/icons/IconVideo';
import IconPrev from './components/icons/IconPrev';
import IconNext from './components/icons/IconNext';
import IconVarify from './components/icons/IconVarify';
import appointment_icon from 'assets/images/svgs/appointment_icon.svg'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from 'react';


const Doctors = () => {
    const [searchDate, setSearchDate] = useState(new Date());
    return (
        <>
            <main id="nex-doctors-wrp" className=" nexogic-dashboard-card  nexogic-site-content-w pt-3 nexogic-my-doctor nexogic-theme-v2">
                <div className='row '>
                    <div className="col-lg-12">
                        <h2 className="nexogic-page-title mb-0">My doctors</h2>
                        {/** Search sec */}
                        <div className="mt-3 nexogic-banner-search card" id='nex-doctors-search-card'>
                            <div className="row">
                                <div className="col-lg-3"><input type="text" name="s_by_name" id="s_by_name" className='form-control search-icon-input' placeholder='Search By Name' /></div>
                                <div className="col-lg-2"><input type="text" name="s_by_location" id="s_by_location" className='form-control location-icon-input' placeholder='Location' /></div>
                                <div className="col-lg-2" id='s_by_date_w'> <DatePicker className='form-control calender-icon-input' id='s_by_date' selected={searchDate} onChange={(date) => setSearchDate(date)} />
                                </div>
                                <div className="col-lg-2"><input type="text" name="s_by_insurance" id="s_by_insurance" className='form-control Insurance-icon-input' placeholder='Insurance plan' /></div>
                                <div className="col-lg-2 appointments-icon-input-w">
                                    <span className="icon appointments-icon-input"></span>
                                    <select className='form-control' name='ppu_appointments' id='ppu_appointments'>
                                        <option>Appointments</option>
                                        <option>Appointments 2</option>
                                    </select></div>
                                <div className="col-lg-1">
                                    <button type='button' className='btn nexogic_primary_button w-100' id='ppu_appointment_search'>Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="row">
                    <div className="col-lg-8 nexogic-left-col">


                        <div className="nexogic-user-search-lists mt-4">
                            <div className="nexogic-user-search-list card">
                                <div className="nexogic-thumb-preview-w">
                                    <div className="nexogic-thumb-preview">
                                        <img src={user_img} alt="" />
                                        <span className="icon-upload-btn icon text-white">
                                            <IconVideo />
                                        </span>

                                    </div>
                                    {/* review */}
                                    <div className="card-text"><span className="icon"><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="13"
                                        fill="none"
                                        viewBox="0 0 14 13"
                                    >
                                        <path
                                            fill="#FF9D07"
                                            d="M7 10.513L11.12 13l-1.094-4.687 3.64-3.153-4.793-.407L7 .333l-1.874 4.42-4.793.407 3.64 3.153L2.88 13 7 10.513z"
                                        ></path>
                                    </svg></span> <span>5.0</span>
                                        <div><a className='link underline'>24 reviews</a></div>
                                    </div>
                                </div>
                                <div className="card-body">

                                    <div className="row">
                                        <div className="user-information col-lg-8">
                                            <h3 className="card-title"><a className='link'>Dr. Konstantin Maklackov</a> <span className='icon'><IconVarify /></span></h3>
                                            <p className="card-text md">MBBS, MS - Ortopaedics, Orthopedist, Joint replacement surgeon </p>
                                            <div className="nexogic-doctor-exp">
                                                <p className="card-text md">32 years experience overall</p>
                                                <p className="card-text md">12 years as a specialist</p>
                                            </div>
                                            <div className="card-text"><span className="icon"><svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14 7.54545C14 12.6364 7.5 17 7.5 17C7.5 17 1 12.6364 1 7.54545C1 5.80949 1.68482 4.14463 2.90381 2.91712C4.12279 1.68961 5.77609 1 7.5 1C9.22391 1 10.8772 1.68961 12.0962 2.91712C13.3152 4.14463 14 5.80949 14 7.54545Z" stroke="#3EABCA" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M7.5 9.72727C8.69662 9.72727 9.66667 8.75044 9.66667 7.54545C9.66667 6.34047 8.69662 5.36364 7.5 5.36364C6.30338 5.36364 5.33333 6.34047 5.33333 7.54545C5.33333 8.75044 6.30338 9.72727 7.5 9.72727Z" stroke="#3EABCA" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></span> California, CA, 18974</div>
                                            <div className="card-text nexogic-fee-text">Consultation fee : <b>₹700</b></div>




                                        </div>
                                        <div className="nexogic-user-action col-lg-4">
                                            <div className="nexogic-appoint-available ">
                                                <span className="icon">
                                                    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.1111 1V4.2M4.88889 1V4.2M1 7.4H15M2.55556 2.6H13.4444C14.3036 2.6 15 3.31634 15 4.2V15.4C15 16.2837 14.3036 17 13.4444 17H2.55556C1.69645 17 1 16.2837 1 15.4V4.2C1 3.31634 1.69645 2.6 2.55556 2.6Z" stroke="#4AA928" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round" />
                                                    </svg></span>

                                                Available Today
                                            </div>
                                            <a href="" className="btn nexogic_primary_button">Book appointment</a>
                                            <p className='card-text mb-0'>No Booking fee</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="nexogic-user-search-list card">
                                <div className="nexogic-thumb-preview-w">
                                    <div className="nexogic-thumb-preview">
                                        <img src={user_img} alt="" />
                                        <span className="icon-upload-btn icon text-white">
                                            <IconVideo />
                                        </span>

                                    </div>
                                    {/* review */}
                                    <div className="card-text"><span className="icon"><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="13"
                                        fill="none"
                                        viewBox="0 0 14 13"
                                    >
                                        <path
                                            fill="#FF9D07"
                                            d="M7 10.513L11.12 13l-1.094-4.687 3.64-3.153-4.793-.407L7 .333l-1.874 4.42-4.793.407 3.64 3.153L2.88 13 7 10.513z"
                                        ></path>
                                    </svg></span> <span>5.0</span>
                                        <div><a className='link underline'>24 reviews</a></div>
                                    </div>
                                </div>
                                <div className="card-body">

                                    <div className="row">
                                        <div className="user-information col-lg-8">
                                            <h3 className="card-title"><a className='link'>Dr. Konstantin Maklackov</a> <span className='icon'><IconVarify /></span></h3>
                                            <p className="card-text md">MBBS, MS - Ortopaedics, Orthopedist, Joint replacement surgeon </p>
                                            <div className="nexogic-doctor-exp">
                                                <p className="card-text md">32 years experience overall</p>
                                                <p className="card-text md">12 years as a specialist</p>
                                            </div>
                                            <div className="card-text"><span className="icon"><svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14 7.54545C14 12.6364 7.5 17 7.5 17C7.5 17 1 12.6364 1 7.54545C1 5.80949 1.68482 4.14463 2.90381 2.91712C4.12279 1.68961 5.77609 1 7.5 1C9.22391 1 10.8772 1.68961 12.0962 2.91712C13.3152 4.14463 14 5.80949 14 7.54545Z" stroke="#3EABCA" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M7.5 9.72727C8.69662 9.72727 9.66667 8.75044 9.66667 7.54545C9.66667 6.34047 8.69662 5.36364 7.5 5.36364C6.30338 5.36364 5.33333 6.34047 5.33333 7.54545C5.33333 8.75044 6.30338 9.72727 7.5 9.72727Z" stroke="#3EABCA" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></span> California, CA, 18974</div>
                                            <div className="card-text nexogic-fee-text">Consultation fee : <b>₹700</b></div>




                                        </div>
                                        <div className="nexogic-user-action col-lg-4">
                                            <div className="nexogic-appoint-available ">
                                                <span className="icon">
                                                    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.1111 1V4.2M4.88889 1V4.2M1 7.4H15M2.55556 2.6H13.4444C14.3036 2.6 15 3.31634 15 4.2V15.4C15 16.2837 14.3036 17 13.4444 17H2.55556C1.69645 17 1 16.2837 1 15.4V4.2C1 3.31634 1.69645 2.6 2.55556 2.6Z" stroke="#4AA928" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round" />
                                                    </svg></span>

                                                Available Today
                                            </div>
                                            <a href="" className="btn nexogic_primary_button">Book appointment</a>
                                            <p className='card-text mb-0'>No Booking fee</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="nexogic-user-search-list card">
                                <div className="nexogic-thumb-preview-w">
                                    <div className="nexogic-thumb-preview">
                                        <img src={user_img} alt="" />
                                        <span className="icon-upload-btn icon text-white">
                                            <IconVideo />
                                        </span>

                                    </div>
                                    {/* review */}
                                    <div className="card-text"><span className="icon"><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="13"
                                        fill="none"
                                        viewBox="0 0 14 13"
                                    >
                                        <path
                                            fill="#FF9D07"
                                            d="M7 10.513L11.12 13l-1.094-4.687 3.64-3.153-4.793-.407L7 .333l-1.874 4.42-4.793.407 3.64 3.153L2.88 13 7 10.513z"
                                        ></path>
                                    </svg></span> <span>5.0</span>
                                        <div><a className='link underline'>24 reviews</a></div>
                                    </div>
                                </div>
                                <div className="card-body">

                                    <div className="row">
                                        <div className="user-information col-lg-8">
                                            <h3 className="card-title"><a className='link'>Dr. Konstantin Maklackov</a> <span className='icon'><IconVarify /></span></h3>
                                            <p className="card-text md">MBBS, MS - Ortopaedics, Orthopedist, Joint replacement surgeon </p>
                                            <div className="nexogic-doctor-exp">
                                                <p className="card-text md">32 years experience overall</p>
                                                <p className="card-text md">12 years as a specialist</p>
                                            </div>
                                            <div className="card-text"><span className="icon"><svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14 7.54545C14 12.6364 7.5 17 7.5 17C7.5 17 1 12.6364 1 7.54545C1 5.80949 1.68482 4.14463 2.90381 2.91712C4.12279 1.68961 5.77609 1 7.5 1C9.22391 1 10.8772 1.68961 12.0962 2.91712C13.3152 4.14463 14 5.80949 14 7.54545Z" stroke="#3EABCA" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M7.5 9.72727C8.69662 9.72727 9.66667 8.75044 9.66667 7.54545C9.66667 6.34047 8.69662 5.36364 7.5 5.36364C6.30338 5.36364 5.33333 6.34047 5.33333 7.54545C5.33333 8.75044 6.30338 9.72727 7.5 9.72727Z" stroke="#3EABCA" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></span> California, CA, 18974</div>
                                            <div className="card-text nexogic-fee-text">Consultation fee : <b>₹700</b></div>




                                        </div>
                                        <div className="nexogic-user-action col-lg-4">
                                            <div className="nexogic-appoint-available ">
                                                <span className="icon">
                                                    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.1111 1V4.2M4.88889 1V4.2M1 7.4H15M2.55556 2.6H13.4444C14.3036 2.6 15 3.31634 15 4.2V15.4C15 16.2837 14.3036 17 13.4444 17H2.55556C1.69645 17 1 16.2837 1 15.4V4.2C1 3.31634 1.69645 2.6 2.55556 2.6Z" stroke="#4AA928" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round" />
                                                    </svg></span>

                                                Available Today
                                            </div>
                                            <a href="" className="btn nexogic_primary_button">Book appointment</a>
                                            <p className='card-text mb-0'>No Booking fee</p>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>



                    </div>

                    <div className="col-lg-4 nexogic-right-col pt-4">
                       
                        {/* ortho lists */}
                        <AsideOrthopedists />

                        <div className='card nexogic-see-all-doctor mt-4 pt-3'>
                            <div className="card-body text-center">
                                <div className="graphic text-center mb-2">
                                    <img src={appointment_icon} alt="" />
                                </div>
                                <h3 className="card-primary-title ">Make the new appointment with a doctor</h3>
                                <p className='card-text'>Semper aliquam purus volutpat fusce libero quam dictum. Nulla vitae blandit vestibulum, gravida rhoncus facilisis lorem.</p>
                                <p className="action mt-3 mb-3">
                                    <a href="" className="btn nexogic_primary_button_outline">See all doctors</a>
                                </p>
                            </div>

                        </div>





                    </div>


                </div>
            </main>
        </>

    )
}

export default Doctors