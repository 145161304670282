import React from 'react'
import { useState } from 'react';
import { Button, ButtonGroup, Card, CardBody, CardText } from 'reactstrap';

export default function CalenderNav(props) {

    const [date, setDate] = useState(new Date());
    const [view, setView] = useState('week');

    const handleNext = (num) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + num);
        setDate(newDate);
    };

    const handlePrev = (num) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() - num);
        setDate(newDate);
    };

    return (
        <Card className='nexogic-calender-week-navigate'>
            <CardBody>
                <div className='nexogic-day-week-pre'>
                <CardText>{props.title}</CardText>
                <ButtonGroup className='nexogic-day-navigate'>
                    <Button onClick={()=>handlePrev(props.intervalNum)}><svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 1L2 9.5L10.5 18" stroke="#2D4552" strokeOpacity="0.71" strokeWidth="2" />
                    </svg>
                    </Button>
                    <Button onClick={()=>handleNext(props.intervalNum)}><svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 18L9.5 9.5L1 0.999999" stroke="#2D4552" strokeOpacity="0.71" strokeWidth="2" />
                    </svg>
                    </Button>
                </ButtonGroup>
                <CardText>{date.toLocaleDateString()}</CardText>
                </div>
                <ButtonGroup className='nexogic-day-week-switcher'>
                    <Button active={view === 'day'} onClick={() => setView('day')}>Day</Button>
                    <Button active={view === 'week'} onClick={() => setView('week')}>Week</Button>
                </ButtonGroup>
            </CardBody>
        </Card>
    )
}
