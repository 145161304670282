import axios from "axios";
import API from "./API";
import API_Factory from "./API_Factory";
import secureLocalStorage from "react-secure-storage";
import API_New from "./API_New";

const changePractitionerStatusNew = async (endpoint, method) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_New({
        url: endpoint,
        method: method,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const createPractitioner = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: "practitioner-service/create/",
        method: "PUT",
        responseType: "application/json",
        data: payload,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const adminSearchPractitioner = (payload, limit = 100) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.get(
        `practitioner-service/admin/practitioners/profile/search/`,
        {
          params: { ...payload, maxResult: limit },
        }
      );
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getPractitionerById = (id) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}practitioner-service/practitioners/${id}`,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getPractitionerReview = (id) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.get(
        `practitioner-service/practitioner/review/by-practitonerId/${id}`
      );
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const updatePractitioner = (data, guid) => {
  return API_Factory({
    method: "PATCH",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}`,
    data,
    ignoreToken: false,
    headers: {
      "Content-Type": "application/json-patch+json",
    },
  });
};

const fileUpload = (file, sourceType, pid, fileType) => {
  const categoryType =
    fileType === "profile" ? "PROFILE_IMAGE" : "BANNER_IMAGE";
  const formData = new FormData();
  formData.append("file", file);
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `media-service/media/${pid}/image?t=PRACTITIONER&c=${categoryType}`,
        method: "POST",
        data: formData,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getPractitionersSkills = (id) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.get(
        `practitioner-service/practitioners/skills/get-primay-specialities`
      );
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getPractitionerRegistration = (practitionerId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}practitioner-service/practitioners/${practitionerId}/registrations`,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const deletePractitionerRegistration = (practitionerId, registration_id) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_BASE_URL}practitioner-service/practitioners/${practitionerId}/registrations/${registration_id}`,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const deletePractitionerDocument = (documentId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_New.delete(
        `practitioner-service/practitioners/documents/delete-by-documentid/${documentId}`
      );
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const addUpdateRegistrationDocs = (practitionerId, payload) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "POST",
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `/practitioner-service/practitioners/${practitionerId}/registrations`,
        data: payload,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const updateRegistrationDocs = (practitionerId, payload, registrationId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `/practitioner-service/practitioners/${practitionerId}/registrations/${registrationId}`,
        method: "PUT",
        responseType: "application/json",
        data: payload,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const addCalendar = async (authCode, calendarEmail, practitionerId) => {
  try {
    let response = API.put("add-calendar/", {
      authCode,
      calendarEmail,
      practitionerId,
    });
    return response;
  } catch (e) {
    return e;
  }
};

const deletePractitionerImage = async (practionerId, mediaId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_BASE_URL}media-service/media/${practionerId}/${mediaId}`,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const MedicalRegistrations = (data, guid, submitType) =>
  API_Factory({
    method: "POST",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/registrations?submit=${submitType}`,
    data,
    ignoreToken: false,
  });

const getAllMedicalRegistrationDetails = (guid) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/registrations`,
    ignoreToken: false,
  });

const gePractitionersDetails = (guid) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}`,
    ignoreToken: false,
  });

const updatePractitionersDetails = (data, guid) => {
  return API_Factory({
    method: "PATCH",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}`,
    data,
    ignoreToken: false,
    headers: {
      "Content-Type": "application/json-patch+json",
    },
  });
}

const getPractitionersPersonalDetails = (guid) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/personal-details`,
    headers: {
      "Content-Type": "application/json-patch+json",
    },
  });

const updatePractitionersPersonalDetails = (data, guid) =>
  API_Factory({
    method: "PUT",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/personal-details`,
    data,
  });

const registerEducationsDetails = (data, guid, submitType) =>
  API_Factory({
    method: "POST",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/educations?submit=${submitType}`,
    data,
    ignoreToken: false,
  });

const updateEducationsDetails = (data, guid, education_id) =>
  API_Factory({
    method: "PUT",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/educations/${education_id}`,
    data,
    ignoreToken: false,
  });

const deleteEducationsDetails = (guid, education_id, data = null) =>
  API_Factory({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/educations/${education_id}`,
    data,
    ignoreToken: false,
  });

const registerDocumentsDetails = (files, guid, options) => {
  const formData = new FormData();
  const documentDetailsSection = [];
  const documentsSection = [];
  files.forEach((file, index) => {
    documentDetailsSection.push({
      documentCategory: file.documentType,
      description: file.documentName,
      fileName: file.files[0].name,
    });
    documentsSection.push(file.files[0]);
    formData.append(`documents`, file.files[0]);
  });
  let modiData = JSON.stringify(documentDetailsSection);
  const blob = new Blob([modiData], {
    type: "application/json",
  });
  formData.append("documentDetails", blob);
  formData.append("submit", true);

  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `practitioner-service/practitioners/${guid}/documents`,
        method: "POST",
        data: formData,
        ignoreToken: false,
        onUploadProgress: options
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getEducation = (guid) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/educations`,
    ignoreToken: false,
  });

const registerHospitalAndClinicDetails = (data, guid, submitType) =>
  API_Factory({
    method: "POST",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/affiliations?submit=${submitType}`,
    data,
    ignoreToken: false,
  });

const updateHospitalAndClinicDetails = (
  data,
  guid,
  affiliation_id,
  submitType
) =>
  API_Factory({
    method: "PUT",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/affiliations/${affiliation_id}?submit=${submitType}`,
    data,
    ignoreToken: false,
  });

const getAffiliations = (guid) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/affiliations`,
    ignoreToken: false,
  });

const deleteAffiliations = (guid, affiliation_id) =>
  API_Factory({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/affiliations/${affiliation_id}`,
    ignoreToken: false,
  });

const getClinicalTrial = (guid) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `/practitioner-service/practitioners/${guid}/trails`,
        method: "GET",
        responseType: "application/json",
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const addClinicalTrial = (payload, guid) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `/practitioner-service/practitioners/${guid}/trails`,
        method: "POST",
        responseType: "application/json",
        data: payload,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const updateClinicalTrial = (payload, guid, trail_id) => {
  delete payload.guid;
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `/practitioner-service/practitioners/${guid}/trails/${trail_id}`,
        method: "PUT",
        responseType: "application/json",
        data: payload,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const deleteClinicalTrial = (guid, trialId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `/practitioner-service/practitioners/${guid}/trails/${trialId}`,
        method: "DELETE",
        responseType: "application/json",
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const addProMembership = (guid, payload) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `/practitioner-service/practitioners/${guid}/memberships`,
        method: "POST",
        data: payload,
        responseType: "application/json",
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const updateProMembership = (pId, mId, payload) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `/practitioner-service/practitioners/${pId}/memberships/${mId}`,
        method: "PUT",
        responseType: "application/json",
        data: payload,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getProMembership = (practitionerId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `/practitioner-service/practitioners/${practitionerId}/memberships`,
        method: "GET",
        responseType: "application/json",
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const deleteProMembership = (guid, membershipId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `practitioner-service/practitioners/${guid}/memberships/${membershipId}`,
        method: "DELETE",
        responseType: "application/json",
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getInstitutionsDetails = (guid) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `/institution-service/institutions/${guid}`,
        method: "GET",
        responseType: "application/json",
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const registerExperiences = (data, guid, submitType) =>
  API_Factory({
    method: "POST",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/experiences?submit=${submitType}`,
    data,
    ignoreToken: false,
  });

const listExperiences = (guid) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/experiences`,
    ignoreToken: false,
  });

const updateExperiences = (payload, guid, experienceId) => {
  delete payload.guid;
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `/practitioner-service/practitioners/${guid}/experiences/${experienceId}`,
        method: "PUT",
        responseType: "application/json",
        data: payload,
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const deleteExperiences = (guid, experiences_id) =>
  API_Factory({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/experiences/${experiences_id}`,
    ignoreToken: false,
  });

const getCertifications = (guid) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `/practitioner-service/practitioners/${guid}/certifications`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const addCertification = (practitionerId, payload) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `/practitioner-service/practitioners/${practitionerId}/certifications`,
        method: "POST",
        responseType: "application/json",
        data: payload,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const updateCertification = (guid, certification_id, payload) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `practitioner-service/practitioners/${guid}/certifications/${certification_id}`,
        method: "PUT",
        responseType: "application/json",
        data: payload,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const deleteCertification = (guid, certificationId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `practitioner-service/practitioners/${guid}/certifications/${certificationId}`,
        method: "DELETE",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const registerPublications = (data, guid, submitType) =>
  API_Factory({
    method: "POST",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/publications?submit=${submitType}`,
    data,
    ignoreToken: false,
  });

const updatePublications = (data, guid, publications_id, submitType) =>
  API_Factory({
    method: "PUT",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/publications/${publications_id}?submit=${submitType}`,
    data,
    ignoreToken: false,
  });

const getPublications = (guid) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/publications`,
    ignoreToken: false,
  });

const deletePublications = (guid, affiliation_id) =>
  API_Factory({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/publications/${affiliation_id}`,
    ignoreToken: false,
  });

const registerAwards = (data, guid, submitType) =>
  API_Factory({
    method: "POST",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/awards?submit=${submitType}`,
    data,
    ignoreToken: false,
  });

const updateAwards = (data, guid, publications_id, submitType) =>
  API_Factory({
    method: "PUT",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/awards/${publications_id}?submit=${submitType}`,
    data,
    ignoreToken: false,
  });

const getAwards = (guid) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/awards`,
    ignoreToken: false,
  });

const deleteAwards = (guid, affiliation_id) =>
  API_Factory({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/awards/${affiliation_id}`,
    ignoreToken: false,
  });

const getAllProfiles = (sq = "", cp = 0) =>
  API_Factory({
    method: "GET",
    url: `practitioner-service/claims?sq=${sq}&cp=${cp}&nr=10&sb=updatedOn&so=DESC`,
    ignoreToken: false,
  });


const getDocumentsProfile = (guid) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/documents`,
    ignoreToken: false,
  });

const deleteDocumentsProfile = (guid, documentId) =>
  API_Factory({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/documents/${documentId}`,
    ignoreToken: false,
  });

const displayPractionerDocument = (guid, documentId) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/documents/${documentId}`,
    ignoreToken: false,
    responseType: "arraybuffer",
  });

const globalSearchPractitioner = (payload) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/profiles/suggestions`,
    params: payload,
    ignoreToken: false,
  });

const new_fileUpload = (file, guid, fileType, options) => {
  const categoryType = fileType === "profile" ? "AVATAR" : "BANNER";
  const formData = new FormData();
  formData.append("image", file);
  formData.append("source", categoryType);
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/images`,
        method: "POST",
        data: formData,
        ignoreToken: false,
        onUploadProgress: options
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const new_deletePractitionerImage = async (guid, fileType) => {
  const categoryType = fileType === "profile" ? "AVATAR" : "BANNER";
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/images?source=${categoryType}`,
        method: "Delete",
        // params: {
        //   UUID: guid,
        //   source: categoryType,
        // },
        ignoreToken: false,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const deletePractitionerResume = (guid, resume_id) => {
  return API_Factory({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/resumes/${resume_id}`,
    ignoreToken: false,
  });
};

const getPractitionerProfiles = (sq, pageNo) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/profiles?sq=${sq}&cp=${pageNo}&nr=5`,
    ignoreToken: false,
  });

const getPractitionerProfileById = (id) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/profiles/${id}`,
    ignoreToken: false,
  });

const getResumesProfile = (guid) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/resumes`,
    ignoreToken: false,
  });

const downloadResumesProfile = (guid, resume_id) =>
  API_Factory({
    method: "GET",
    url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/resumes/${resume_id}`,
    ignoreToken: false,
    responseType: "arraybuffer",
  });

const uploadResumePractitioner = (guid, data) => {
  const formData = new FormData();
  formData.append("file", data);
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "POST",
        url: `${process.env.REACT_APP_API_PRACTITIONER_SERVICE}/practitioners/${guid}/resumes`,
        data: formData,
        ignoreToken: false
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
}

// const getPractitionersAssociatedtoInstitutionWithPagination = (institutionGuid, cp = 0) => {
//   return new Promise((resolve, reject) => {
//     try {
//       let response = API_Factory({
//         baseURL: process.env.REACT_APP_API_BASE_URL,
//         url: `practitioner-service/profiles?w_for=${institutionGuid}&cp=${cp}`,
//         method: "GET",
//         responseType: "application/json",
//       });
//       return resolve(response);
//     } catch (e) {
//       return reject(e);
//     }
//   });
// };

const getPractitionersAssociatedtoInstitution = (querySearch, cp = 0) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `practitioner-service/profiles?${querySearch}cp==${cp}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getPractitionersAssociatedtoInstitutionWithPagination = (institutionGuid, cp = 0) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `practitioner-service/profiles?w_for=${institutionGuid}&cp=${cp}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getPractitionersListToInstitutionSpeciality = (querySearch) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `practitioner-service/profiles/autocomplete?${querySearch}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getPreferences = (guid) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `practitioner-service/${guid}/preferences`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const updatePreferences = (guid, payload) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_LB_API_ENDPOINT,
        url: `practitioner-service/${guid}/preferences`,
        method: "PUT",
        responseType: "application/json",
        data: payload,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getPractitionersAssociatedtoInstitutionNew = (instId) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `practitioner-service/profiles/autocomplete?w_for=${instId}`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getPractitionersForOpinion = (sq, cp = 0) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `practitioner-service/claims?sq=${sq}&cp=${cp}&nr=10`,
        method: "GET",
        responseType: "application/json",
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

const getClaimsProfile = (id) => {
  return new Promise((resolve, reject) => {
    try {
      let response = API_Factory({
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}practitioner-service/claims/${id}`,
        ignoreToken: true,
      });
      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
};

// const AddPersonalDetails = (data, guid) => {
//   return new Promise((resolve, reject) => {
//     try {
//       let response = API_Factory({
//         method: "GET",
//         url: `${process.env.REACT_APP_API_BASE_URL}practitioner-service/claims/${id}`,
//         ignoreToken: false,
//       });
//       return resolve(response);
//     } catch (e) {
//       return reject(e);
//     }
//   });
// };
// API({
//   method: "PUT",
//   url: `${process.env.REACT_APP_API_IDENTITY_SERVICE}/register/${guid}/personal_details?registration_id=${guid}`,
//   data,
//   ignoreToken: true,
// });

export const PractitionerService = {
  getCertifications,
  addCertification,
  updateCertification,
  deleteCertification,
  changePractitionerStatusNew,
  createPractitioner,
  adminSearchPractitioner,
  getPractitionerById,
  getPractitionerReview,
  getPractitionersSkills,
  updatePractitioner,
  addClinicalTrial,
  deleteClinicalTrial,
  updateClinicalTrial,
  fileUpload,
  getPractitionerRegistration,
  deletePractitionerRegistration,
  deletePractitionerDocument,
  addUpdateRegistrationDocs,
  updateRegistrationDocs,
  addCalendar,
  deletePractitionerImage,
  getClinicalTrial,
  MedicalRegistrations,
  getAllMedicalRegistrationDetails,
  gePractitionersDetails,
  updatePractitionersDetails,
  registerEducationsDetails,
  updateEducationsDetails,
  deleteEducationsDetails,
  registerDocumentsDetails,
  registerHospitalAndClinicDetails,
  updateHospitalAndClinicDetails,
  getEducation,
  getAffiliations,
  deleteAffiliations,
  addProMembership,
  updateProMembership,
  getProMembership,
  deleteProMembership,
  getInstitutionsDetails,
  registerExperiences,
  listExperiences,
  updateExperiences,
  deleteExperiences,
  registerPublications,
  updatePublications,
  getPublications,
  deletePublications,
  registerAwards,
  updateAwards,
  getAwards,
  deleteAwards,
  getAllProfiles,
  getDocumentsProfile,
  deleteDocumentsProfile,
  displayPractionerDocument,
  globalSearchPractitioner,
  new_fileUpload,
  new_deletePractitionerImage,
  getPractitionersPersonalDetails,
  updatePractitionersPersonalDetails,
  getPractitionerProfiles,
  getPractitionerProfileById,
  getResumesProfile,
  deletePractitionerResume,
  uploadResumePractitioner,
  downloadResumesProfile,
  getPractitionersAssociatedtoInstitution,
  getPractitionersAssociatedtoInstitutionWithPagination,
  getPractitionersAssociatedtoInstitutionNew,
  getPractitionersListToInstitutionSpeciality,
  getPreferences,
  updatePreferences,
  getPractitionersForOpinion,
  getClaimsProfile,
  // AddPersonalDetails,
};
