import React from 'react'
import { useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import Section1 from 'pages/UIDashboard/Components/Sections/Section1';
import WidgetThree from 'pages/UIDashboard/Components/Widgets/WidgetThree';
import WidgetOne from 'pages/UIDashboard/Components/Widgets/WidgetOne';
import Section2 from 'pages/UIDashboard/Components/Sections/Section2';

import './style.scss'

const ThemeThree = () => {
  const [themeBgColor, setThemeBgColor] = useState('#00b7ac');
    const [sectionBG, setSectionBG] = useState('#f6f7fd');
    const [sectionFont, setSectionFont] = useState('');
    const [sectionHeaderBg, setSectionHeaderBg] = useState('#02827a');
    const [sectionHeaderFontColor, setSectionHeaderFontColor] = useState('#ffffff');
    const [sHeaderFontSize, setSHeaderFontSize] = useState('1rem');
    const [sHeaderFontWeight, setSHeaderFontWeight] = useState('bold');
    const [sHeader, setSHeader] = useState(''); //Header or none for No Header 
    const theme = {
        bg: {
            backgroundColor: `${themeBgColor}`,
        },
        sectionbg: {
            padding: "0",
            color: `${sectionFont ? sectionFont : ''}`,
            backgroundColor: `${sectionBG}`
        },
        theader: {
            backgroundColor: `${sectionHeaderBg}`,
            fontSize: `${sHeaderFontSize}`,
            color: `${sectionHeaderFontColor}`,
            fontWeight: `${sHeaderFontWeight}`
        },
    };
  return (
    <Card className="micro-site-wrap micro-site-theme-v3" style={theme.bg}>
            <CardHeader className="micro-header micro-bg-gray" style={{ backgroundImage: "url(https://picsum.photos/id/10/1200/300)" }}>
                <div className="micro-logo" style={{ backgroundImage: "url(https://picsum.photos/id/64/200/300)" }}>Logo</div>
            </CardHeader>
            <CardBody className="micro-header-content">
              <Row>
                <Col lg={12} >Header</Col>
              </Row>
            </CardBody>
            <CardBody className="micro-main-content-wrap">
                <Row>
                    <Col lg={12} className='pb-3'>
                    <WidgetThree />
                    <Section2 />

                    </Col>
                    <Col lg={12} className='pb-3'>
                        <WidgetOne />
                    </Col>
                    <Col lg={12} className='pb-3'>
                        <Section1 />
                    </Col>
                    {/** d-h bg-pink class should remove its for a min height and background color */}
                    <Col lg={12} className="d-h bg-pink pb-3"></Col>
                    <Col lg={12} className="d-h bg-blue pb-3"></Col>
                    <Col lg={12} className="d-h bg-green "></Col>
                </Row>

            </CardBody>
            <CardFooter className="micro-footer micro-bg-blue text-center text-white">Footer</CardFooter>
        </Card>
  )
}

export default ThemeThree