import React, { useState } from "react";
import { Row, Col, Button, Spinner, FormGroup, Label, Input } from "reactstrap";
import { withAlert } from "react-alert";
import { Field } from "formik";

import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import FormikDatePicker from "components/forms/formikFields/FormikDatePicker";
import NewInstitutionModal from "components/forms/NewInstitutionModal";

const CreateEventForm = (props) => {
  const {
    handleSubmit,
    setFieldValue,
    values,
    dirty,
    isLoading,
    practitionerId,
  } = props;
  const { apptFor, apptType } = values;
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
  const apptPurposes = [
    { label: "First Visit", value: "first_visit" },
    { label: "Procedure", value: "proc" },
    { label: "Consultation", value: "cons" },
  ];

  const handleChangeApptFor = (apptFor) => {
    setFieldValue("apptFor", apptFor, true);
  };

  const handleChangeApptType = (apptType) => {
    setFieldValue("apptType", apptType, true);
    console.log(apptType);
  };

  const onNewInstitute = () => {
    toggleNested();
  };

  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };
  // console.log(new Date(new Date().setHours(10).setMinutes(0)));

  return (
    <>
      <form className="nx-form event-form" onSubmit={handleSubmit}>
        <Row>
          <Col sm={12} md={6}>
            <Label className="d-block">Attendee Group</Label>
            <div className="mt-2 mb-2 ml-1">
              <FormGroup check inline>
                <Label check>
                  <Input
                    id="cef_apptFor_patient"
                    name="apptFor"
                    type="radio"
                    checked={apptFor === "patient"}
                    onChange={() => handleChangeApptFor("patient")}
                  />
                  <span className="ml-2">Patient</span>
                </Label>
              </FormGroup>
              <FormGroup check inline className="ml-4">
                <Label check>
                  <Input
                    id="cef_apptFor_colleague"
                    type="radio"
                    name="apptFor"
                    checked={apptFor === "colleague"}
                    onChange={() => handleChangeApptFor("colleague")}
                  />
                  <span className="ml-2">Colleague</span>
                </Label>
              </FormGroup>
            </div>
          </Col>
          <Col
            sm={12}
            md={6}
            className={apptFor === "colleague" ? "d-none" : ""}
          >
            <Label className="d-block">Appointment Type</Label>
            <div className="mt-2 ml-1">
              <FormGroup check inline>
                <Label check>
                  <Input
                    id="cef_apptType_video"
                    type="radio"
                    name="apptType"
                    checked={apptType === "video"}
                    onChange={() => handleChangeApptType("video")}
                  />
                  <span className="ml-2">Virtual</span>
                </Label>
              </FormGroup>
              <FormGroup check inline className="ml-4">
                <Label check>
                  <Input
                    id="cef_apptType_office"
                    type="radio"
                    name="apptType"
                    checked={apptType === "office"}
                    onChange={() => handleChangeApptType("office")}
                  />
                  <span className="ml-2">In Person</span>
                </Label>
              </FormGroup>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={apptFor === "colleague" ? 12 : 6}>
            <Field
              id="cef_patientName"
              name="patientName"
              type="text"
              label="First Name / Last Name"
              component={FormikInputField}
              placeholder="Your patient's first / last name"
              onNewInstituteReq={onNewInstitute}
            />
          </Col>
          <Col
            sm={12}
            md={6}
            className={apptFor === "colleague" ? "d-none" : ""}
          >
            <Field
              id="cef_apptPurpose"
              name="apptPurpose"
              type="text"
              label="Appointment Purpose"
              component={FormikSelectField}
              inputprops={{
                name: `apptPurpose`,
                options: apptPurposes,
                defaultOption: "Your Purpose",
                keys: {
                  id: "value",
                  label: "label",
                },
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Field
              id="cef_apptDate"
              name="apptDate"
              type="text"
              label="Date of Appointment"
              placeholder="MM/DD/YYYY"
              component={FormikDatePicker}
              inputprops={{
                dateFormat: "MM/dd/yyyy",
                // showYearDropdown: false,
                // showMonthDropdown: true,
                // scrollableYearDropdown: false,
                // yearDropdownItemNumber: 1,
                // maxDate: new Date(),
                autoComplete: "off",
                minDate: new Date(),
                maxDate: new Date().setDate(new Date().getDate() + 28),
              }}
            />
          </Col>
          <Col sm={12} md={6}>
            <Row>
              <Col sm={12} md={6}>
                <Field
                  id="cef_apptTimeFrom"
                  name="apptTimeFrom"
                  type="text"
                  label="Time of Appointment"
                  placeholder="Start Time"
                  component={FormikDatePicker}
                  inputprops={{
                    dateFormat: "HH:mm aa",
                    showTimeSelect: true,
                    showTimeSelectOnly: true,
                    calendarClassName: "mycustomcal",
                    autoComplete: "off",
                    showTwoColumnMonthYearPicker: true,
                    minDate: new Date(new Date().setHours(0)),
                    maxDate: new Date(new Date().setHours(24)),
                  }}
                />
              </Col>
              <Col sm={12} md={6}>
                <Field
                  id="cef_apptTimeTo"
                  name="apptTimeTo"
                  type="text"
                  label="."
                  placeholder="End Time"
                  component={FormikDatePicker}
                  inputprops={{
                    dateFormat: "HH:mm aa",
                    showTimeSelect: true,
                    showTimeSelectOnly: true,
                    calendarClassName: "mycustomcal",
                    autoComplete: "off",
                    showTwoColumnMonthYearPicker: true,
                  }}
                  /* minTime={setMinutes(now, 1)}
                  maxTime={setHours(setMinutes(now, 1), 2)} */
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={apptFor === "patient" ? "d-none" : ""}>
          <Col>
            <Field
              id="cef_description"
              name="description"
              type="textarea"
              label="Description (Optional)"
              component={FormikInputField}
              rows={4}
              placeholder="You may write something here"
            />
          </Col>
        </Row>
        <hr className="profile-info-modal-hr" />
        <Row className="modal-save-main justify-content-center mt-4 mb-2">
          <Col xs="auto">
            {isLoading ? (
              <div className="loading-spiner">
                <Spinner style={{ width: "2rem", height: "2rem" }} />
              </div>
            ) : (
              <Button
                id="cef_btn_submit"
                className="modal-save-btn"
                disabled={!dirty}
                color="primary"
                type="submit"
              >
                Save
              </Button>
            )}
          </Col>
        </Row>
        <NewInstitutionModal
          isOpen={nestedModal}
          toggle={toggleNested}
          closeAll={closeAll}
          practitionerId={practitionerId}
        />
      </form>
    </>
  );
};

export default withAlert()(CreateEventForm);
