import React from 'react'
import './css/v1.scss'
import logo_img from 'assets/images/logo-top-icon.png'
import bg_img from 'assets/images/ads_five_bg.jpg'

 function AdsFive(props) {
  return (
    <div className='nex-ads-wrapper-h v1' style={{backgroundImage: `url(${props.bg})`}}>
        <div className="nex-ads-brand">
            <a href={props.logo_url} target={props.logo_target}><img src={props.logo_img} alt="" /></a>
        </div>
        <div className="nex-ads-main-contain">
            <div className="nex-ads-content-wrap">
                <h2 className='nex-ads-headings' dangerouslySetInnerHTML={{__html: props.title}}></h2>
            </div>
        </div>
        <div className="nex-ads-footer">
            <div className="nex-ads-col" dangerouslySetInnerHTML={{__html: props.short_desc}}></div>
            <div className="nex-ads-col" dangerouslySetInnerHTML={{__html: props.tags}}></div>
        </div>
    </div>
  )
}

AdsFive.defaultProps = {
    bg:bg_img,
    logo_img:logo_img,
    logo_url:"#",
    logo_target:"_blank",
    title:"LEAKY Heart?<br/>Just Clip it",
    short_desc: "Lorem ipsum dolor sit amet.",
    tags: "#something",
}

export default AdsFive;