import React from 'react'
import secondOpinionImg from 'assets/images/second-opinion.png'
import VisitForm from '../Doctors/components/VisitForm'
import AsideOrthopedists from '../Doctors/components/AsideOrthopedists'
import user_img from 'assets/images/profile-p.jpg'
import IconFevarite from '../Doctors/components/icons/IconFevarite'
import IconVideo from '../Doctors/components/icons/IconVideo';
import IconVarify from '../Doctors/components/icons/IconVarify';

const Appointments = () => {
    return (
        <>
            <div className="nexogic-page-columns">

                <div className="row">
                    <div className="col-lg-8 left-col">

                        <div className="card nexogic-user-search-list position-relative">
                            <div className="floated-favarite-icon"><IconFevarite /></div>
                            <div className="nexogic-thumb-preview-w">
                                <div className="nexogic-thumb-preview">
                                    <img src={user_img} alt="" />
                                    <span className="icon-upload-btn icon text-white">
                                        <IconVideo />
                                    </span>

                                </div>
                                {/* review */}
                                <div className="card-text"><span className="icon"><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="13"
                                    fill="none"
                                    viewBox="0 0 14 13"
                                >
                                    <path
                                        fill="#FF9D07"
                                        d="M7 10.513L11.12 13l-1.094-4.687 3.64-3.153-4.793-.407L7 .333l-1.874 4.42-4.793.407 3.64 3.153L2.88 13 7 10.513z"
                                    ></path>
                                </svg></span> <span>5.0</span>
                                    <div><a className='link underline'>24 reviews</a></div>
                                </div>
                            </div>
                            <div className="card-body">

                                <div className="row">
                                    <div className="user-information col-lg-12">
                                        <h3 className="card-title"><a className='link'>Dr. Konstantin Maklackov</a> <span className='icon'><IconVarify /></span></h3>
                                        <p className="card-text md">MBBS, MS - Ortopaedics, Orthopedist, Joint replacement surgeon </p>
                                        <div className="nexogic-doctor-exp">
                                            <p className="card-text md">32 years experience overall</p>
                                            <p className="card-text md">12 years as a specialist</p>
                                        </div>
                                        <div className="card-text"><span className="icon"><svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14 7.54545C14 12.6364 7.5 17 7.5 17C7.5 17 1 12.6364 1 7.54545C1 5.80949 1.68482 4.14463 2.90381 2.91712C4.12279 1.68961 5.77609 1 7.5 1C9.22391 1 10.8772 1.68961 12.0962 2.91712C13.3152 4.14463 14 5.80949 14 7.54545Z" stroke="#3EABCA" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M7.5 9.72727C8.69662 9.72727 9.66667 8.75044 9.66667 7.54545C9.66667 6.34047 8.69662 5.36364 7.5 5.36364C6.30338 5.36364 5.33333 6.34047 5.33333 7.54545C5.33333 8.75044 6.30338 9.72727 7.5 9.72727Z" stroke="#3EABCA" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg></span> California, CA, 18974</div>
                                        <div className="card-text nexogic-fee-text">Consultation fee : <b>₹700</b></div>




                                    </div>
                                    

                                </div>
                            </div>
                        </div>
                        {/** appointment fields */}
                        <div className='card nexogic-card-color-header expanded'>
                            <div class="card-header"><h2>Please Confirm Your Appointment</h2></div>
                            <div className="card-body">
                                <div className="row need-margin">
                                    <div className="col-lg-6 form-group">
                                        <label htmlFor="" className="form-label">Appointment time</label>
                                        <input id='appoint_time' name='appoint_time' type="text" className='form-control' value='Wed, Apr 28, 2021 - 9:00 am' />
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <label htmlFor="" className="form-label">Choose  the type of appointment</label>
                                        <select className='form-control' id='appoint_video_sec' name='appoint_video_sec'>
                                            <option value="">Video appointment</option>
                                        </select>
                                    </div>
                                    
                                </div>
                                {/* visit form */}
                                <VisitForm />
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-lg-4 right-col">
                        {/* Top rated surgeons */}
                        <div className="nexogic-widget-sidebar-card card nexogic-top-surgeons mb-4">
                            <h2 className="card-title h2 ">Top rated surgeons</h2>
                            <div className="card-body">
                                <div className="nexogic-list-card card border-0">
                                    <div className="card-head">
                                        <div className="card-thumb">
                                            <img src={user_img} alt="" className='card-img' />
                                        </div>
                                        <div className="info">
                                            <h3 className="card-title">Dr. Anna Sotelo </h3>
                                            <p className="card-text">MBBS, MS - Orthopaedics, Orthopedist, Joint replacement surgeon</p>
                                            <p className="card-text light">32 years experience overall</p>
                                            <p className="card-text light">12 years as a specialist</p>
                                            <p className="card-text light"><i className='icon'><svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.99988 0.197754C3.17738 0.197754 0.879883 2.49525 0.879883 5.31775C0.879883 9.834 5.56113 15.2203 5.75988 15.4478C5.82113 15.5178 5.90738 15.5578 5.99988 15.5578C6.09863 15.5515 6.17863 15.5178 6.23988 15.4478C6.43863 15.2165 11.1199 9.73775 11.1199 5.31775C11.1199 2.49525 8.82239 0.197754 5.99988 0.197754ZM5.99988 3.71775C7.05989 3.71775 7.91989 4.57775 7.91989 5.63775C7.91989 6.69775 7.05989 7.55775 5.99988 7.55775C4.93988 7.55775 4.07988 6.69775 4.07988 5.63775C4.07988 4.57775 4.93988 3.71775 5.99988 3.71775Z" fill="#001244" fillOpacity="0.6" />
                                            </svg>
                                            </i> California, CA, 18974</p>
                                            <p className="card-text"><i className='icon'><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.99967 10.3911L11.1197 12.8777L10.0263 8.19106L13.6663 5.03773L8.87301 4.63106L6.99967 0.21106L5.12634 4.63106L0.333008 5.03773L3.97301 8.19106L2.87967 12.8777L6.99967 10.3911Z" fill="#FF9D07" />
                                            </svg>

                                            </i> 5.0 <a href='#'>24 reviews</a></p>


                                        </div>
                                    </div>
                                </div>
                                <div className="nexogic-list-card card border-0">
                                    <div className="card-head">
                                        <div className="card-thumb">
                                            <img src={user_img} alt="" className='card-img' />
                                        </div>
                                        <div className="info">
                                            <h3 className="card-title">Dr. Anna Sotelo </h3>
                                            <p className="card-text">MBBS, MS - Orthopaedics, Orthopedist, Joint replacement surgeon</p>
                                            <p className="card-text light">32 years experience overall</p>
                                            <p className="card-text light">12 years as a specialist</p>
                                            <p className="card-text light"><i className='icon'><svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.99988 0.197754C3.17738 0.197754 0.879883 2.49525 0.879883 5.31775C0.879883 9.834 5.56113 15.2203 5.75988 15.4478C5.82113 15.5178 5.90738 15.5578 5.99988 15.5578C6.09863 15.5515 6.17863 15.5178 6.23988 15.4478C6.43863 15.2165 11.1199 9.73775 11.1199 5.31775C11.1199 2.49525 8.82239 0.197754 5.99988 0.197754ZM5.99988 3.71775C7.05989 3.71775 7.91989 4.57775 7.91989 5.63775C7.91989 6.69775 7.05989 7.55775 5.99988 7.55775C4.93988 7.55775 4.07988 6.69775 4.07988 5.63775C4.07988 4.57775 4.93988 3.71775 5.99988 3.71775Z" fill="#001244" fillOpacity="0.6" />
                                            </svg>
                                            </i> California, CA, 18974</p>
                                            <p className="card-text"><i className='icon'><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.99967 10.3911L11.1197 12.8777L10.0263 8.19106L13.6663 5.03773L8.87301 4.63106L6.99967 0.21106L5.12634 4.63106L0.333008 5.03773L3.97301 8.19106L2.87967 12.8777L6.99967 10.3911Z" fill="#FF9D07" />
                                            </svg>

                                            </i> 5.0 <a href='#'>24 reviews</a></p>


                                        </div>
                                    </div>
                                </div>
                                <div className="nexogic-list-card card border-0">
                                    <div className="card-head">
                                        <div className="card-thumb">
                                            <img src={user_img} alt="" className='card-img' />
                                        </div>
                                        <div className="info">
                                            <h3 className="card-title">Dr. Anna Sotelo </h3>
                                            <p className="card-text">MBBS, MS - Orthopaedics, Orthopedist, Joint replacement surgeon</p>
                                            <p className="card-text light">32 years experience overall</p>
                                            <p className="card-text light">12 years as a specialist</p>
                                            <p className="card-text light"><i className='icon'><svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.99988 0.197754C3.17738 0.197754 0.879883 2.49525 0.879883 5.31775C0.879883 9.834 5.56113 15.2203 5.75988 15.4478C5.82113 15.5178 5.90738 15.5578 5.99988 15.5578C6.09863 15.5515 6.17863 15.5178 6.23988 15.4478C6.43863 15.2165 11.1199 9.73775 11.1199 5.31775C11.1199 2.49525 8.82239 0.197754 5.99988 0.197754ZM5.99988 3.71775C7.05989 3.71775 7.91989 4.57775 7.91989 5.63775C7.91989 6.69775 7.05989 7.55775 5.99988 7.55775C4.93988 7.55775 4.07988 6.69775 4.07988 5.63775C4.07988 4.57775 4.93988 3.71775 5.99988 3.71775Z" fill="#001244" fillOpacity="0.6" />
                                            </svg>
                                            </i> California, CA, 18974</p>
                                            <p className="card-text"><i className='icon'><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.99967 10.3911L11.1197 12.8777L10.0263 8.19106L13.6663 5.03773L8.87301 4.63106L6.99967 0.21106L5.12634 4.63106L0.333008 5.03773L3.97301 8.19106L2.87967 12.8777L6.99967 10.3911Z" fill="#FF9D07" />
                                            </svg>

                                            </i> 5.0 <a href='#'>24 reviews</a></p>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* ortho lists */}
                        <AsideOrthopedists />

                        {/* out top latest artical */}
                        <div className="nexogic-widget-sidebar-card card">
                            <h2 className="card-title h2">Our latest articles</h2>
                            <div className="card-body">
                                <div className='nexogic-custom-scroll'>
                                    <div className="nexogic-list-card card latest-artical border-0">
                                        <div className="card-head">
                                            <div className="card-thumb">
                                                <img src={user_img} alt="" className='card-img' />
                                            </div>
                                            <div className="info">
                                                <h3 className="card-title">Posuere nunc ac cras eget eget molestie non mattis pulvinar</h3>
                                                <p className='card-text light'><span className='icon'>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.31973 0C3.92598 0 3.59973 0.32625 3.59973 0.72V1.44H1.43973C1.02207 1.44 0.719727 1.74234 0.719727 2.16V4.68H17.2797V2.16C17.2797 1.74234 16.9774 1.44 16.5597 1.44H14.3997V0.72C14.3997 0.32625 14.0735 0 13.6797 0H12.9597C12.566 0 12.2397 0.32625 12.2397 0.72V1.44H5.75973V0.72C5.75973 0.32625 5.43348 0 5.03973 0H4.31973ZM4.31973 0.72H5.03973V2.88H4.31973V0.72ZM12.9597 0.72H13.6797V2.88H12.9597V0.72ZM0.719727 5.4V16.56C0.719727 16.9777 1.02207 17.28 1.43973 17.28H16.5597C16.9774 17.28 17.2797 16.9777 17.2797 16.56V5.4H0.719727ZM4.31973 7.56H6.11973V9.36H4.31973V7.56ZM6.83973 7.56H8.63973V9.36H6.83973V7.56ZM9.35973 7.56H11.1597V9.36H9.35973V7.56ZM11.8797 7.56H13.6797V9.36H11.8797V7.56ZM4.31973 10.08H6.11973V11.88H4.31973V10.08ZM6.83973 10.08H8.63973V11.88H6.83973V10.08ZM9.35973 10.08H11.1597V11.88H9.35973V10.08ZM11.8797 10.08H13.6797V11.88H11.8797V10.08ZM4.31973 12.6H6.11973V14.4H4.31973V12.6ZM6.83973 12.6H8.63973V14.4H6.83973V12.6ZM9.35973 12.6H11.1597V14.4H9.35973V12.6ZM11.8797 12.6H13.6797V14.4H11.8797V12.6Z" fill="#001244" fillOpacity="0.4" />
                                                    </svg>


                                                </span>Jan 20, 2021</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="nexogic-list-card card latest-artical border-0">
                                        <div className="card-head">
                                            <div className="card-thumb">
                                                <img src={user_img} alt="" className='card-img' />
                                            </div>
                                            <div className="info">
                                                <h3 className="card-title">Posuere nunc ac cras eget eget molestie non mattis pulvinar</h3>
                                                <p className='card-text light'><span className='icon'>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.31973 0C3.92598 0 3.59973 0.32625 3.59973 0.72V1.44H1.43973C1.02207 1.44 0.719727 1.74234 0.719727 2.16V4.68H17.2797V2.16C17.2797 1.74234 16.9774 1.44 16.5597 1.44H14.3997V0.72C14.3997 0.32625 14.0735 0 13.6797 0H12.9597C12.566 0 12.2397 0.32625 12.2397 0.72V1.44H5.75973V0.72C5.75973 0.32625 5.43348 0 5.03973 0H4.31973ZM4.31973 0.72H5.03973V2.88H4.31973V0.72ZM12.9597 0.72H13.6797V2.88H12.9597V0.72ZM0.719727 5.4V16.56C0.719727 16.9777 1.02207 17.28 1.43973 17.28H16.5597C16.9774 17.28 17.2797 16.9777 17.2797 16.56V5.4H0.719727ZM4.31973 7.56H6.11973V9.36H4.31973V7.56ZM6.83973 7.56H8.63973V9.36H6.83973V7.56ZM9.35973 7.56H11.1597V9.36H9.35973V7.56ZM11.8797 7.56H13.6797V9.36H11.8797V7.56ZM4.31973 10.08H6.11973V11.88H4.31973V10.08ZM6.83973 10.08H8.63973V11.88H6.83973V10.08ZM9.35973 10.08H11.1597V11.88H9.35973V10.08ZM11.8797 10.08H13.6797V11.88H11.8797V10.08ZM4.31973 12.6H6.11973V14.4H4.31973V12.6ZM6.83973 12.6H8.63973V14.4H6.83973V12.6ZM9.35973 12.6H11.1597V14.4H9.35973V12.6ZM11.8797 12.6H13.6797V14.4H11.8797V12.6Z" fill="#001244" fillOpacity="0.4" />
                                                    </svg>


                                                </span>Jan 20, 2021</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="nexogic-list-card card latest-artical border-0">
                                        <div className="card-head">
                                            <div className="card-thumb">
                                                <img src={user_img} alt="" className='card-img' />
                                            </div>
                                            <div className="info">
                                                <h3 className="card-title">Posuere nunc ac cras eget eget molestie non mattis pulvinar</h3>
                                                <p className='card-text light'><span className='icon'>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.31973 0C3.92598 0 3.59973 0.32625 3.59973 0.72V1.44H1.43973C1.02207 1.44 0.719727 1.74234 0.719727 2.16V4.68H17.2797V2.16C17.2797 1.74234 16.9774 1.44 16.5597 1.44H14.3997V0.72C14.3997 0.32625 14.0735 0 13.6797 0H12.9597C12.566 0 12.2397 0.32625 12.2397 0.72V1.44H5.75973V0.72C5.75973 0.32625 5.43348 0 5.03973 0H4.31973ZM4.31973 0.72H5.03973V2.88H4.31973V0.72ZM12.9597 0.72H13.6797V2.88H12.9597V0.72ZM0.719727 5.4V16.56C0.719727 16.9777 1.02207 17.28 1.43973 17.28H16.5597C16.9774 17.28 17.2797 16.9777 17.2797 16.56V5.4H0.719727ZM4.31973 7.56H6.11973V9.36H4.31973V7.56ZM6.83973 7.56H8.63973V9.36H6.83973V7.56ZM9.35973 7.56H11.1597V9.36H9.35973V7.56ZM11.8797 7.56H13.6797V9.36H11.8797V7.56ZM4.31973 10.08H6.11973V11.88H4.31973V10.08ZM6.83973 10.08H8.63973V11.88H6.83973V10.08ZM9.35973 10.08H11.1597V11.88H9.35973V10.08ZM11.8797 10.08H13.6797V11.88H11.8797V10.08ZM4.31973 12.6H6.11973V14.4H4.31973V12.6ZM6.83973 12.6H8.63973V14.4H6.83973V12.6ZM9.35973 12.6H11.1597V14.4H9.35973V12.6ZM11.8797 12.6H13.6797V14.4H11.8797V12.6Z" fill="#001244" fillOpacity="0.4" />
                                                    </svg>


                                                </span>Jan 20, 2021</p>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="mt-3 text-right">
                                    <button className="readmore btn btn-link" id='nex-appo-view-more-btn'>
                                        View More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/** 
        <div className="card ">
        <div className="row  card-body">
        <div className="col-md-7">
          <img src={secondOpinionImg} alt="Image" width="100%" />
        </div>
        <div className="col-md-5 nexogic-form-content">
          <div>
          <h3 className="nexogic-form-title mb-2 text-info">Appointments</h3>
          <h1 className="nexogic-form-title mb-2">UNDER CONSTRUCTION</h1>         
          </div>
        </div>
      </div>
      </div>
      */}
        </>
    )
}

export default Appointments