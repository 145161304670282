import logo_white from "assets/images/logo-white-transparent.png";
import google_img from "assets/images/social-google-img.png";
import facebook_img from "assets/images/facebook-social-img.png";
import apple_img from "assets/images/apple-social-img.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Field, Formik } from "formik";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import * as Yup from "yup";
const validationSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
});

const LoginScreen = (props) => {
  const initValues = useMemo(() => {
    return {
      name: "",
    };
  }, []);
  const [isSubmitting, setSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState(initValues);

  const onSubmit = (values) => {
    console.log(values);
    if (!isEmpty(values.name)) {
      setSubmitting(true);
      console.log(values);
    }

  };
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className="nex-bg-light-blue">
      <main className="nex-login-register--w" >
        <div className="container sm d-flex" data-aos="fade-up">
          <div className="nex-form-aside" >
            <div className="logo" data-aos="fade-down" data-aos-duration="800" data-aos-delay="800">
              <a href="/"><img src={logo_white} alt="" /></a>
            </div>

            <h2 className="nex-text-xxl" data-aos="fade-down" data-aos-duration="1200" data-aos-delay="800">Hey, <br />
              Search with your <br />
              name</h2>
          </div>
          <div className="nex-form-fields--w">
            <div className="nex-form-top-slot" data-aos="fade-up" data-aos-duration="800" data-aos-delay="800">
              <p className="nex-text-lg text-right">Are you a Patient? <a href="#">Register here</a></p>
            </div>
            <div className="nex-form-center-slot">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={onSubmit}
                children={(props) => {
                  return (
                    <>
                      <RenderForm
                        {...props}
                        isSubmitting={isSubmitting}
                      />
                    </>
                  );
                }}
              />

            </div>
            <div className="nex-form-bottom-slot">
              <p className="nex-text-lg text-center">Are you a member? <a href="#" className="nex-link-dark">Login</a></p>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default LoginScreen;

const RenderForm = (props) => {
  const {
    values,
    errors,
    isSubmitting,
    handleSubmit,
  } = props;

  return (
    <div>
      <form className="new-doctor-register-form" onSubmit={handleSubmit}>
        <div className="nex-form-container">
          <div className="nex-text-lg nex-social-login-btns-w" data-aos="fade-up" data-aos-duration="800" data-aos-delay="1000">
            <a href="" className="btn"><img src={google_img} alt="" width={60} /></a>
            <a href="" className="btn"><img src={facebook_img} alt="" width={60} /></a>
            <a href="" className="btn"><img src={apple_img} alt="" width={60} /></a>
          </div>
          <div className="nex-or-txt--w" data-aos="fade-up" data-aos-duration="800" data-aos-delay="1200">or</div>
          <div className="form-group-fields--w" data-aos="fade-up" data-aos-duration="800" data-aos-delay="1400">
            <div className="form-group pt-3">
              <Field
                id="name"
                name="name"
                type="text"
                label=""
                component={FormikInputField}
                placeholder="Tell us your name"
                autoComplete="off"
              />

            </div>

            <div className="form-group">
              <input type="submit" value="Continue" className="btn btn-primary w-100" />
            </div>
            <div className="form-group">
              <p className="nex-text-lg nex-link-dark mb-3"><b>Forgot Password?</b></p>
              <div className="nex-checkbox--w">
                <input type="checkbox" name="" id="" className="nex-check-input" />
                <p className="nex-text-md">Login with otp instead of password</p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};