// import Close_icon_right from "../../images/Home page/calender/Rectangle523.png";
import noProfile from "../../images/noProfile.jpg";
//import "./cardSugg.css";

const SugesstionCard = ({ suggestion }) => {
  console.log(545, suggestion);
  return (
    <div>
      <div className="card">
        <div>
          {/* <span >
            <img src={Close_icon_left} alt="" />
          </span> */}
          {/* <img src={Close_icon_right} alt="" /> */}
        </div>
        <div className="card-body text-center">
          <img
            src={
              suggestion.profilePicUrl ? suggestion.profilePicUrl : noProfile
            }
            alt=""
            className="mesaage_img_user_detail card-image mb-2"
          />
          <p className="doc_name mb-2">
            {suggestion.firstName + " " + suggestion.lastName}
          </p>
          <div className="doc-desp">
            <div className="mb-1">{suggestion.primarySpecialityArr[0]}</div>
            <div>{suggestion.city ? suggestion.city : "City"}</div>
          </div>

          <a
            href
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{ marginTop: "11px" }}
            className="btn btn-outline-primary"
          >
            Connect
          </a>
        </div>
      </div>
    </div>
  );
};

export default SugesstionCard;
