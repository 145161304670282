import React, { useState } from "react"
import { Row, Col, Button, Spinner, Label } from 'reactstrap'
import { Field, Formik } from 'formik';
import { withAlert } from "react-alert"
import FormikInputField from "components/forms/formikFields/FormikInputField"
import * as Yup from 'yup';
import classnames from 'classnames';

import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage"
import tickCircleIcon from 'assets/images/svgs/tick_circle_icon.svg';
import happyIcon from 'assets/images/svgs/happy_icon.svg';
import sadIcon from 'assets/images/svgs/sad_icon.svg';
import neutralIcon from 'assets/images/svgs/neutral_icon.svg';
import { phoneRegExp, emailNonNumericRegEx } from 'utils/Constants'

const validationSchema = Yup.object().shape({
    firstName: Yup.string().min(3).max(30).required("This field is required"),
    lastName: Yup.string().min(3).max(30).required("This field is required"),
    email: Yup.string().max(100).matches(emailNonNumericRegEx, 'Please Enter an valid Email').required("This field is required"),
    phoneno: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("This field is required"),
    experience: Yup.string().min(3).max(100).required("This field is required"),
    feedback: Yup.string().min(3).max(255),
});

const RenderForm = (props) => {
    const { values, toggle, isValid, dirty, handleSubmit, isLoading, isSubmittedSuccess } = props;
    const [ userExp, setUserExp ] = useState('');

    const handleExperience =(e, expVal) => {
        e.preventDefault();
        setUserExp(expVal);
    }

    return (
        <>
            {
                !isSubmittedSuccess ? 
                <form className="my-form" onSubmit={handleSubmit}>
                    <div>
                        <Row>
                            <Col md={6}>
                                <Field
                                    id="raf_firstName"
                                    name="firstName"
                                    type="text"
                                    label="First Name"
                                    placeholder="First Name"
                                    component={FormikInputField}
                                />
                            </Col>
                            <Col md={6}>
                                <Field
                                    id="raf_lastName"
                                    name="lastName"
                                    type="text"
                                    label="Last Name"
                                    placeholder="Last Name"
                                    component={FormikInputField}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Field
                                    id="raf_email"
                                    name="email"
                                    type="text"
                                    label="Email"
                                    placeholder="Email"
                                    component={FormikInputField}
                                />
                            </Col>
                            <Col md={6}>
                                <Field
                                    id="raf_phoneno"
                                    name="phoneno"
                                    type="text"
                                    label="Phone Number"
                                    placeholder="Phone Number"
                                    component={FormikInputField}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12}>
                                <Label>We love to hear from you! How is your Nexogic experience?</Label>
                                <div className="d-flex align-items-center mt-1">
                                    <Button id="raf_btn_happy" className={classnames({'btn exp-btn px-4 py-2 d-flex align-items-center justify-content-center': true, 'selected': userExp === 'happy' })} onClick={(e) => handleExperience(e, 'happy')}>
                                        <img src={happyIcon} />
                                        <span className="ml-3 black-color-accent f-16 line-h-20 font-weight-bold">Happy</span>
                                    </Button>
                                    <Button id="raf_btn_neutral" className={classnames({'btn exp-btn px-4 py-2 d-flex align-items-center justify-content-center ml-4': true, 'selected': userExp === 'neutral' })} onClick={(e) => handleExperience(e, 'neutral')}>
                                        <img src={neutralIcon} />
                                        <span className="ml-3 black-color-accent f-16 line-h-20 font-weight-bold">Neutral</span>
                                    </Button>
                                    <Button id="raf_btn_sad" className={classnames({'btn exp-btn px-4 py-2 d-flex align-items-center justify-content-center ml-4': true, 'selected': userExp === 'sad' })} onClick={(e) => handleExperience(e, 'sad')}>
                                        <img src={sadIcon} />
                                        <span className="ml-3 black-color-accent f-16 line-h-20 font-weight-bold">Sad</span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Field
                                    id="raf_feedback"
                                    name="feedback"
                                    type="textarea"
                                    label="Write your feedback"
                                    component={FormikInputField}
                                    rows={4}
                                    cols={5}
                                    placeholder="Write your feedback (optional)"
                                />
                            </Col>
                        </Row>
                        <hr className="profile-info-modal-hr" />
                        <Row className="modal-save-main justify-content-center mt-4 mb-2">
                            <Col xs="auto">
                                {isLoading ? 
                                    <div className="loading-spiner"><Spinner style={{ width: '2rem', height: '2rem' }} /></div>
                                    :
                                    <Button id="raf_btn_submit" className="modal-save-btn" disabled={!isValid || !dirty} color="primary" type="submit">Send feedback</Button>
                                }
                            </Col>
                        </Row>
                    </div>
                </form> :
                <div className="success-text position-relative text-center">
                    <img src={tickCircleIcon} />
                    <div className="mt-4 text-complimentary f-14 line-h-20 font-weight-500">Your your feedback sent successfully. Thank you!</div>
                </div>
            }
        </>    
    )
}

const RateAppForm = (props) => {
    let initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phoneno: '',
        experience: '',
        feedback: '',
    }

    const { selectedItem, practitionerId, getData, toggle, alert } = props
    const Url = "/practitioners/" + practitionerId + "/clinical-trials/"
    const [isLoading, setLoading] = useState(false)
    const [errorMsg, setErrorMessage] = useState("");
    const [isSubmittedSuccess, SetIsSubmittedSuccess] = useState(false);

    return (
        <>
            { errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} /> }
            <Formik
                enableReinitialize={true}
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(values) => {
                    if (values.id) {

                    } else {
                        SetIsSubmittedSuccess(true); //for now set true after submit
                    }
                }}

                children={(props) => {
                    return <RenderForm toggle={toggle} isSubmittedSuccess={isSubmittedSuccess} {...props} isLoading={isLoading} />
                }}
            >
            </Formik>
        </>
    )

}

export default withAlert()(RateAppForm);