import React, { useEffect, useState } from 'react'
import { withAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import { FAILTOSAVERECOED } from 'utils/Constants';
import { PatientService } from 'utils/PatientService';
import Select from 'react-select';
import CommonLoader from 'common/CommonLoader';
import { Spinner } from 'reactstrap';
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";


export default withAlert()(function Health(props) {
  const [isLoading, setIsLoading] = useState(true);

  const currentUser = useSelector((state) => state.auth.currentUser);
  let allergies = ["Pollen", "Dust mites", "Mold", "Pet dander", "Insect bites and stings", "Food allergies", "Latex", "Medications", "Chemicals", "Sunlight"]
  let chronicDiseases = ["Diabetes", "Heart disease", "Arthritis", "Asthma", "Chronic obstructive pulmonary disease (COPD)", "Cancer", "Alzheimer's disease", "Parkinson's disease", "Multiple sclerosis", "HIV/AIDS"]
  let injuries = ["Fracture", "Sprain", "Strain", "Concussion", "Burns", "Cuts and lacerations", "Whiplash", "Dislocation", "Traumatic brain injury", "Spinal cord injury"]
  let surgeries = ["Appendectomy", "Cataract surgery", "Gallbladder removal", "Hip replacement", "Hysterectomy", "Knee arthroscopy", "Laminectomy", "Mastectomy", "Prostatectomy", "Tonsillectomy"]

  const [hasAllergy, setHasAllergy] = useState(false);
  const [hasChronicDisease, setHasChronicDisease] = useState(false);
  const [hasInjury, setHasInjury] = useState(false);
  const [hasSurgery, setHasSurgery] = useState(false);

  const [selectedAllergies, setSelectedAllergies] = useState([]);
  const [selectedChronicDiseases, setSelectedChronicDiseases] = useState([]);
  const [selectedInjuries, setSelectedInjuries] = useState([]);
  const [selectedSurgeries, setSelectedSurgeries] = useState([]);
  const [medications, setMedications] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  allergies = allergies.map((str, index) => ({ value: str, label: str }));
  chronicDiseases = chronicDiseases.map((str, index) => ({ value: str, label: str }));
  injuries = injuries.map((str, index) => ({ value: str, label: str }));
  surgeries = surgeries.map((str, index) => ({ value: str, label: str }));

  const onSubmit = () => {
    setIsSubmitting(true);
    let payload = {
      "chronicDiseases": selectedChronicDiseases.map(function (item) {
        return item['label'];
      }),
      "injuries": selectedInjuries.map(function (item) {
        return item['label'];
      }),
      "allergies": selectedAllergies.map(function (item) {
        return item['label'];
      }),
      "medications": [
        {
          "name": medications,
          "description": medications
        }
      ]
    }

    updateHealth(currentUser?.profileId, payload);
  }

  const getHealth = (id) => {
    PatientService.getPatientHealth(id)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          if (data?.allergies.length > 0) {
            setHasAllergy(true);
            setSelectedAllergies(data?.allergies.map((str, index) => ({ value: str, label: str })))
          }

          if (data?.chronicDiseases.length > 0) {
            setHasChronicDisease(true);
            setSelectedChronicDiseases(data?.chronicDiseases.map((str, index) => ({ value: str, label: str })))
          }

          if (data?.injuries.length > 0) {
            setHasInjury(true);
            setSelectedInjuries(data?.injuries.map((str, index) => ({ value: str, label: str })))
          }

          if (data?.medications.length > 0) {
            setMedications(data?.medications[0].name);
          }
          setIsLoading(false);
        }
        else {
          showErrorMessage(FAILTOSAVERECOED);
          setIsLoading(false);
        }
      })
  }

  const updateHealth = (id, payload) => {
    PatientService.updatePatientHealth(id, payload)
      .then((response) => {
        const { status, data } = response;
        if (status) {
          showSuccessMessage("Health details updated successfully.");
          setIsSubmitting(false);
        }
        else {
          showErrorMessage(FAILTOSAVERECOED);
          setIsSubmitting(false);
        }
      })
  }

  useEffect(() => {
    getHealth(currentUser?.profileId);
  }, [currentUser?.profileId])


  return (
    <div className='card border-0'>
      {isLoading
        ?
        <div className="text-center mb-4">
          <CommonLoader />
        </div>
        :
        <div className="card-body p-0">
          <div className="row need-margin">
            <div className="col-lg-6">
              <div className="mb-3 form-group">
                <label for="" className="form-label">Do you have any Allergy ?</label>
                <select name="" id="" className="form-control" value={hasAllergy}
                  onChange={(e) => { e.target.value === "true" ? setHasAllergy(true) : setHasAllergy(false) }}
                >
                  <option key={false} value="false">No</option>
                  <option key={true} value="true">Yes</option>
                </select>

                {hasAllergy &&
                  <Select className='mt-3'
                    options={allergies}
                    isMulti
                    value={selectedAllergies}
                    placeholder={"Select Allergy(s)"}
                    onChange={(values) => {
                      setSelectedAllergies(values);
                    }}
                  />

                }
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3 form-group">

                <label for="" className="form-label">Do you have any Chronic\Disease ?</label>
                <select name="" id="" className="form-control" value={hasChronicDisease}
                  onChange={(e) => { e.target.value === "true" ? setHasChronicDisease(true) : setHasChronicDisease(false) }}
                >
                  <option key={false} value="false">No</option>
                  <option key={true} value="true">Yes</option>
                </select>

                {hasChronicDisease &&
                  <Select
                    className='mt-3'
                    options={chronicDiseases}
                    isMulti
                    value={selectedChronicDiseases}
                    placeholder={"Select Chronic Diseases(s)"}
                    onChange={(values) => {
                      setSelectedChronicDiseases(values);
                    }}
                  />
                }
              </div>
            </div>
          </div>

          <div className="row need-margin">
            <div className="col-lg-6">
              <div className="mb-3 form-group">
                <label for="" className="form-label">Do you have any Injury ?</label>
                <select name="" id="" className="form-control" value={hasInjury}
                  onChange={(e) => { e.target.value === "true" ? setHasInjury(true) : setHasInjury(false) }}
                >
                  <option key={false} value="false">No</option>
                  <option key={true} value="true">Yes</option>
                </select>
                {hasInjury &&
                  <Select
                    className='mt-3'
                    options={injuries}
                    isMulti
                    value={selectedInjuries}
                    placeholder={"Select Injury(s)"}
                    onChange={(values) => {
                      setSelectedInjuries(values);
                    }}
                  />
                }
              </div>
            </div>

            {/* <div className="col-lg-6">
              <div className="mb-3 form-group">
                <label for="" className="form-label">Do you have any Surgery ?[static]</label>
                <select name="" id="" className="form-control" value={hasSurgery}
                  onChange={(e) => { e.target.value === "true" ? setHasSurgery(true) : setHasSurgery(false) }}
                >
                  <option key={false} value="false">No</option>
                  <option key={true} value="true">Yes</option>
                </select>
                {hasSurgery &&
                  <Select
                    className='mt-3'
                    options={surgeries}
                    isMulti
                    value={selectedSurgeries}
                    placeholder={"Select Surgery(s)"}
                    onChange={(values) => {
                      setSelectedSurgeries(values);
                      console.log(values);
                    }}
                  />
                }
              </div>
            </div> */}

            {/* <div className="col-lg-12">
              <div className="mb-3 form-group">
                <label for="" className="form-label">Current Medication(s)</label>
                <textarea id="current_medication" name='current_medication' type="text" className="form-control"  placeholder='Current Medication'
                  value={medications}
                  onChange={(e) => {
                    console.log(e);
                    setMedications(e?.target?.value);
                  }}
                ></textarea>
              </div>
            </div> */}

          </div>

          <div className="row modal-footer mt-3 px-0 pb-0">
            {isSubmitting ? (
              <div className="loading-spiner">
                <Spinner />
              </div>
            ) : (

              <div className="col d-flex justify-content-end action-group px-0 m-0">
                {/* <button type='reset' className='nexogic_primary_button_outline'>Cancel</button> */}
                <button id='health-submit' type='submit' className='nexogic_primary_button' onClick={() => onSubmit()}>Save</button>
              </div>
            )
            }
          </div>

        </div>
      }
    </div >
  )
})
