import React, { useEffect, useMemo } from "react";
import { Nav, NavItem } from "reactstrap";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { ProfileStatusConstants, UserStatusConstants } from "utils/Constants";
import { UserService } from "utils/UserService";
import { getPath } from "utils/Utils";
import { isAdmin, isInstitutionAdmin, isPractitioner } from "utils/UserHelper";
import { isEmpty } from "lodash";
import homeIcon from "../../../assets/images/svgs/home-icon.svg";
import homeActiveIcon from "../../../assets/images/svgs/home-icon.svg";
import dashboardIcon from "../../../assets/images/svgs/dashboard-icon.svg";
import dashboardActiveIcon from "../../../assets/images/svgs/dashboard-icon.svg";
import appointmentIcon from "../../../assets/images/svgs/calendar-icon.svg";
import appointmentActiveIcon from "../../../assets/images/svgs/calendar-icon.svg";
import contactsIcon from "../../../assets/images/svgs/connections-icon.svg";
import contactsActiveIcon from "../../../assets/images/svgs/connections-icon.svg";
import messageIcon from "../../../assets/images/svgs/messages-icon.svg";
import messageActiveIcon from "../../../assets/images/svgs/messages-icon.svg";
import chatActiveIcon from "../../../assets/images/svgs/messages-icon.svg";
import chatIcon from "../../../assets/images/svgs/messages-icon.svg";
import reviewIcon from "../../../assets/images/svgs/reviews-icon.svg";
import reviewActiveIcon from "../../../assets/images/svgs/reviews-icon.svg";
import settingsIcon from "../../../assets/images/svgs/settings-icon.svg";
import settingsActiveIcon from "../../../assets/images/svgs/settings-icon.svg";
import jobsIcon from "../../../assets/images/svgs/job-icon.svg";
import jobsIconActive from "../../../assets/images/svgs/job-icon.svg";
import admin_home_icon from "../../../assets/images/svgs/home-icon.svg";
import dashboard_admin_icon from "../../../assets/images/svgs/dashboard-icon.svg";
import referals from "assets/images/svgs/referals-icon.svg";
import referalsActive from "assets/images/svgs/referals-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faSearch,
  faChevronLeft,
  faChevronRight,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";

const Sidebar = ({ toggleButton, isActive }) => {
  let location = useLocation();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const isAdminUser = useMemo(() => isAdmin());
  const isInstitutionAdminUser = useMemo(() => isInstitutionAdmin());
  const isPractitionerUser = isPractitioner();

  useEffect(() => {
    window.addEventListener("storage", handleLocalStorageChange);

    return () => {
      window.removeEventListener("storage", handleLocalStorageChange);
    };
  }, []);

  const handleLocalStorageChange = (e) => {
    if (
      e.key === "nexogic.token" &&
      e.oldValue &&
      !e.newValue &&
      !!currentUser?.guid
    ) {
      // Logout session from all other windows or tabs into same brower if exist
      UserService.logout();
      window.location.reload();
    }
  };

  return (
    <div className="nexogic-menu-container">
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="5" height="0">
  <defs>
    <filter id="gooey">
      <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"/>
      <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
      <feBlend in="SourceGraphic" in2="goo" operator="atop"/>
    </filter>
  </defs>
</svg>
      <div className="btn-wrap">
        
        <button className="nexogic-menu-toggle-btn" onClick={toggleButton}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
        </button>
        
        {/** 
        {isActive ? (
          <button className="btn toggle-btn" onClick={toggleButton}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        ) : (
          <button className="btn toggle-btn" onClick={toggleButton}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        )}
        */}
      </div>
      <div className="nav-list">
      <ul className="top-links">
            {((isPractitionerUser && currentUser.status === ProfileStatusConstants.APPROVED) || isInstitutionAdminUser) && (
              <NavItem
                title="Home"
                active={location.pathname === "/home" ? true : false}
                className=""
              >
                <Link className="nav-link" to="/home">
                <img src={admin_home_icon} alt="#" />
                  <span className="link-text">Home</span>
                </Link>
                <div className="left-border-line"></div>
              </NavItem>
            )}

            {!isAdminUser &&
              currentUser.status === ProfileStatusConstants.APPROVED &&
              process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
                <NavItem
                  title="Dashboard"
                  active={location.pathname === "/dashboard" ? true : false}
                  className=""
                >
                  <Link className="nav-link " to="/dashboard">
                  <img src={dashboard_admin_icon} alt="#" />
                    <span className="link-text">Dashboard</span>
                  </Link>
                  <div className="left-border-line"></div>
                </NavItem>
              )}

              {(currentUser.status === UserStatusConstants.APPROVED || isInstitutionAdminUser || isPractitionerUser/* || !isAdminUser ) || isInstitutionAdminUser || isPractitionerUser && ( */) && (
                <NavItem
                  title="Messages"
                  active={location.pathname === "/messages" ? true : false}
                  className=""
                >
                  <Link className="nav-link" to="/messages">
                    
                      {location.pathname === "/messages" ? (
                        <img src={chatActiveIcon} alt="#" />
                      ) : (
                        <img src={chatIcon} alt="#" />
                      )}
                      <span className="link-text">Messages</span>
                      {/* <div className="notification-count position-absolute rounded-circle p-1">2</div> */}
                    
                  </Link>
                  <div className="left-border-line"></div>
                </NavItem>
              )}

            {(currentUser.status === UserStatusConstants.APPROVED || isInstitutionAdminUser || isPractitionerUser)
              && (
                <NavItem
                  title="Connections"
                  active={
                    getPath(location.pathname, "/connections") ===
                      "/connections"
                      ? true
                      : false
                  }
                  className=""
                >
                  <Link className="nav-link " to="/connections">
                    {getPath(location.pathname, "/connections") ===
                      "/connections" ? (
                      <img src={contactsActiveIcon} alt="#" />
                    ) : (
                      <img src={contactsIcon} alt="#" />
                    )}
                    <span className="link-text">Connections</span>
                  </Link>
                  <div className="left-border-line"></div>
                </NavItem>
            )}

            {(currentUser.status === UserStatusConstants.APPROVED || isAdminUser || isInstitutionAdminUser) ? (
              <NavItem
                title="Jobs"
                active={
                  getPath(location.pathname, "/jobsboard") === "/jobsboard"
                    ? true
                    : false
                }
                className="w-100 text-center"
              >
                <Link className="nav-link " to="/jobsboard">
                  {getPath(location.pathname, "/jobsboard") ===
                    "/jobsboard" ? (
                    <img src={jobsIconActive} alt="#" />
                  ) : (
                    <img src={jobsIcon} alt="#" />
                  )}
                  <span className="link-name">Jobs</span>
                </Link>
                <div className="left-border-line"></div>
              </NavItem>
            ) : ''}

            {currentUser.status === UserStatusConstants.APPROVED &&
            !isAdminUser &&
            process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
              
              <ul className="bottom-icon pt-2 mb-4">
                <NavItem
                  title="Settings"
                  active={location.pathname === "/settings" ? true : false}
                  className="settings w-100 text-center"
                >
                  <Link className="nav-link " to="/settings">
                    {location.pathname === "/settings" ? (
                      <img src={settingsActiveIcon} alt="#" />
                    ) : (
                      <img src={settingsIcon} alt="#" />
                    )}
                    <span className="link-name">Settings</span>
                  </Link>
                  <div className="left-border-line"></div>
                </NavItem>
              </ul>
              
            )}

            {/*((isPractitionerUser && currentUser.status === ProfileStatusConstants.APPROVED) || isInstitutionAdminUser) && (
              <NavItem
                title="Home"
                active={location.pathname === "/home" ? true : false}
                className=""
              >
                <Link className="nav-link" to="/home">
                <img src={admin_home_icon} alt="#" />
                  <span className="link-text">Home</span>
                </Link>
                <div className="left-border-line"></div>
              </NavItem>
            )*/}
            

              {currentUser.status === UserStatusConstants.APPROVED &&
              !isAdminUser &&
              process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
                <NavItem
                  title="Appointments"
                  active={
                    getPath(location.pathname, "/appointments") ===
                      "/appointments"
                      ? true
                      : false
                  }
                  className=""
                >
                  <Link className="nav-link " to="/appointments">
                    {getPath(location.pathname, "/appointments") ===
                      "/appointments" ? (
                      <img src={appointmentActiveIcon} alt="#" />
                    ) : (
                      <img src={appointmentIcon} alt="#" />
                    )}
                    <span className="link-text">Appointments</span>
                  </Link>
                  <div className="left-border-line"></div>
                </NavItem>
              )}

              

            

            

            



            {currentUser.status === UserStatusConstants.APPROVED &&
              !isAdminUser &&
              process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
                <NavItem
                  title="Patient-referals"
                  active={
                    getPath(location.pathname, "/referals") === "/referals"
                      ? true
                      : false
                  }
                  className="w-100 text-center"
                >
                  <Link className="nav-link " to="/referals">
                    {getPath(location.pathname, "/referals") === "/referals" ? (
                      <img src={referalsActive} alt="referalActive" />
                    ) : (
                      <img src={referals} alt="referals" />
                    )}
                    {/* <span className="link-name">Patient Referals</span> */}
                    <span className="link-name">Referals</span>
                  </Link>
                  <div className="left-border-line"></div>
                </NavItem>
              )}

            {currentUser.status === UserStatusConstants.APPROVED &&
              !isAdminUser &&
              process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
                <NavItem
                  title="Reviews"
                  active={location.pathname === "/feedback" ? true : false}
                  className="w-100 text-center"
                >
                  <Link className="nav-link " to="/feedback">
                    {location.pathname === "/feedback" ? (
                      <img src={reviewActiveIcon} alt="#" />
                    ) : (
                      <img src={reviewIcon} alt="#" />
                    )}
                    <span className="link-name">Reviews</span>
                  </Link>
                  <div className="left-border-line"></div>
                </NavItem>
              )}
            {/* {isAdminUser &&
              <>
                <NavItem title="admin" active={location.pathname === '/admin' ? true : false} className="w-100 text-center">
                  <Link className="nav-link py-4" to="/admin">
                    {location.pathname === '/admin' ?
                      <FontAwesomeIcon icon={faUser} size="lg" color="#3EABCA" />
                      : <FontAwesomeIcon icon={faUser} size="lg" color="#A1ACC8" />
                    }
                  </Link>
                  <div className="left-border-line"></div>
                </NavItem>
                <NavItem title="admin search" active={location.pathname === '/admin/search' ? true : false} className="w-100 text-center">
                  <Link className="nav-link py-4" to="/admin/search">
                    {location.pathname === '/admin/search' ?
                      <FontAwesomeIcon icon={faSearch} size="lg" color="#3EABCA" />
                      : <FontAwesomeIcon icon={faSearch} size="lg" color="#A1ACC8" />
                    }
                  </Link>
                  <div className="left-border-line"></div>
                </NavItem>
              </>
            } */}




          </ul>
          

      </div>
    </div>
  );
};

export default Sidebar;
