import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString from "qs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { authSetUser } from "redux-helpers/ActionCreator";
import { encrypt } from "utils/EncryptDecrypt";
import { IdentityService } from "utils/IdentityService";
import { PractitionerService } from "utils/PractitionerService";
import { setOAuthTokens, setOAuthUser, setUser } from "utils/UserHelper";
import { UserService } from "utils/UserService";
import Header from "../../common/Header";
import "./style.scss";

const SocialOAuth = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    // extract query params
    const queryStringObj = queryString.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    // handle error case
    if (queryStringObj?.error) {
      const { error } = queryStringObj;
      setMessage({
        type: "error",
        value: error,
      });
    } else if (queryStringObj?.accessToken) {
      const { accessToken, refreshToken } = queryStringObj;

      setMessage({
        type: "success",
        value: "Getting user details...",
      });

      // Step1: get user details(guid, authorities)
      IdentityService.GetUserDetails(accessToken, currentUser.guid).then(
        (userRes) => {
          if (userRes.status === 200) {
            const { guid, authorities } = userRes.data;
            if (authorities.includes("ADMIN")) {
              setOAuthTokens(accessToken, refreshToken);
              setOAuthUser(userRes.data);
              dispatch(authSetUser(userRes.data));
              setUser(userRes.data);
              return;
            }

            // Step2: get userID from guid
            UserService.convertGuidIntoUserId(guid).then((res) => {
              if (res && res.data)
                // step3: get practitioner by ID
                PractitionerService.getPractitionerById(res.data).then(
                  (practResp) => {
                    if (practResp && practResp.data) {
                      const { data } = practResp.data;
                      if (data.practitioner) {
                        const { practitioner } = data;

                        Object.keys(practitioner).forEach(function (key) {
                          if (practitioner[key] === null) {
                            practitioner[key] = "";
                          }
                        });

                        setOAuthTokens(accessToken, refreshToken);
                        setOAuthUser(userRes.data);
                        dispatch(authSetUser(practitioner));
                        setUser(practitioner);

                        const { practitionerId } = practitioner;
                        let currentUserId = encrypt(practitionerId);
                        history.push(`/profile/${currentUserId}`);
                      }
                    }
                  }
                );
            });
          }
        }
      );

      // setMessage({
      //   type: "success",
      //   value: accessToken
      // })
    } else {
      setMessage({
        type: "error",
        value: "Something went wrong",
      });
    }
  }, [currentUser]);

  return (
    <section>
      <div className="bg-light">
        <Header />
        <div className="bg-light-blue border-top py-5">
          <Container className="container-dashboard socialoauth-page">
            <Row>
              <Col>
                <Button
                  className="btn-icon mb-3"
                  color={"link"}
                  onClick={() => history.replace("/login")}
                >
                  <FontAwesomeIcon icon={faChevronLeft} className="text-dark" />
                </Button>
              </Col>
            </Row>
            <Row className="pt-3 pb-5 justify-content-center">
              <Col lg={6} md={8} xs={10}>
                <div className="socialoauth-page__wrapper p-md-4 p-2">
                  <div>
                    <h4 className="socialoauth-page__wrapper__title text-center mb-5">
                      Nexogic
                    </h4>
                  </div>
                  <div className="text-center">
                    {message.type === "success" ? (
                      <p>{message.value}</p>
                    ) : (
                      <div className="d-flex justify-content-center flex-column">
                        <p>{message.value}</p>
                        <a href="/">Go Back</a>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default SocialOAuth;
