import React, { useEffect, useState, useRef } from "react"
import { Row, Col, FormGroup, Button, Spinner } from 'reactstrap'
import classnames from 'classnames';
import { withAlert } from "react-alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const ReportForm = (props) => {
    const [reportTags, setReportTags] = useState(['Spam', 'Offensive', 'Violence', 'False news', 'Other']);
    const [selectedTag, setSelectedTag] = useState([]);

    const handleReportTag = (tag) => {
        if(!checkTagSelected(tag)) {
            setSelectedTag([...selectedTag, tag]);
        }
    }

    const checkTagSelected = (tag) => {
        if(selectedTag.indexOf(tag) !== -1) {
            let newSelectedTag = selectedTag.filter(data => data != tag);
            setSelectedTag(newSelectedTag);
            return true;
        }
        return false;
    }

    const selectedClass = (tag) => {
        if(selectedTag.indexOf(tag) === -1) {
            return false;
        }
        return true;
    }

    return (
        <>
            <div className="f-16 line-h-16 black-color-accent mt-4 mb-3">Report this group</div>
            <div className="f-14 line-h-16 text-complimentary mb-4">Help us understand what's happening with this group. How would you describe it?</div>
            <div className="report-tags d-flex flex-wrap pt-2">
                {
                    reportTags.map((data, i) => {
                        return (
                            <div className="report-tag d-flex align-items-center px-2" style={selectedClass(data) ? {color: '#fff', backgroundColor: '#3EABCA'} : {color: 'rgba(0, 18, 68, 0.6)', backgroundColor: '#fff'} } key={i} onClick={() => handleReportTag(data)}>
                                <div className="f-12 line-h-12 report-name">{data}</div>
                                <div className="add-icon ml-3">
                                    <FontAwesomeIcon size="sm" icon={faPlus} />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <Row className="modal-save-main justify-content-center mt-4 mb-2">
                {/* <Col xs="auto">
                <Button color="link" className="cancel-btn" onClick={toggle}>Cancel</Button>
                </Col> */}
                <Col xs="auto">
                    <Button 
                        id="rf_btn_submit"
                        className="modal-save-btn"
                        color="primary" 
                        type="submit" 
                    >Submit</Button>
                </Col>
            </Row>
        </>
    )
}

export default withAlert()(ReportForm)