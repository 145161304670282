import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Card, CardBody, ButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import './dashboard.css';
import Workload from './components/Workload';
import AppointmentStats from './components/AppointmentStats';
import Connections from './components/Connections';
import MediaActivity from './components/MediaActivity';
import ReviewStats from './components/ReviewStats';

import patientsIcon from 'assets/images/patients_icon.png';
import rateStatIcon from 'assets/images/rate_stat_icon.png';
import CancellationIcon from 'assets/images/cancellation_icon.png';
import dropdownIcon from 'assets/images/dropdown_icon.png';
import dashboard_admin_icon from "assets/images/svgs/dashboard-icon.svg";

const Dashboard = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const toggle = () => {
        if (dropdownOpen) {
            setDropdownOpen(false)
        } else {
            setDropdownOpen(true)
        }
    }
    useEffect(() => {
        document.body.classList.add('body-dashboard-page');
        document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "Dashboard";
        return () => {
          document.body.classList.remove('body-dashboard-page');
          document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "";
        }
    }, [])

    return (
        <section className="dashboard-wrapper mb-4 pt-0">
            {/** 
            <div className="nexogic-heading-sec nexogic-card side-small">
                <div className="nexogix-section-title"><img className='icon' src={dashboard_admin_icon} /> Dashboard</div>
                <div className="dashboard-dropdown ml-auto d-flex align-items-center">
                    <div className="data-range pr-3">Data range</div>
                    <ButtonDropdown className="week-select-dropdown position-relative" isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle color="link" className="min-w-0 p-0">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="dropdown-selection">week</div>
                                <div className="ml-auto dropdown-icon pl-2">
                                    <img src={dropdownIcon} width="10" alt="#" />
                                </div>
                            </div>
                        </DropdownToggle>
                        <DropdownMenu right className="p-0">
                        </DropdownMenu>
                    </ButtonDropdown>
                </div>
            </div>
            */}
            <div className="page-content pt-4">
                <Row className="top-cards">
                    <Col md={12} lg={12}>
                        <Row>
                            <Col className="d-flex" md={3}>
                                <Card className="w-100 mb-sm-2">
                                    <CardBody>
                                        <div className="d-flex align-items-center">
                                            <div className="left-icon purple-icon-box d-flex align-items-center justify-content-center">
                                                <img src={patientsIcon} alt="#" />
                                            </div>
                                            <div className="flex-fill ml-3">
                                                <Row>
                                                    <Col xs={6}><div className="card-count">80</div></Col>
                                                    <Col xs={6} md={4} lg={5}>
                                                        <div className="percentage patient-percentage good-percentage p-1">+10%</div>
                                                    </Col>
                                                </Row>
                                                <div className="title mt-1">Patients</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="d-flex" md={3}>
                                <Card className="w-100 mb-sm-2">
                                    <CardBody>
                                        <div className="d-flex align-items-center">
                                            <div className="left-icon purple-icon-box d-flex align-items-center justify-content-center">
                                                <img src={rateStatIcon} alt="#" />
                                            </div>
                                            <div className="flex-fill ml-3">
                                                <Row>
                                                    <Col xs={6}><div className="card-count">29</div></Col>
                                                    <Col xs={6} md={4} lg={5}>
                                                        <div className="percentage appoint-percentage bad-percentage p-1">-3%</div>
                                                    </Col>
                                                </Row>
                                                <div className="title mt-1">Avg. weekly appoint.</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md={3} className="d-flex">
                                <Card className="w-100 mb-sm-2">
                                    <CardBody className="p-0">
                                        <div className="conversion-rate d-flex flex-fill align-items-center py-3 px-2">
                                            <div className="left-icon purple-icon-box d-flex align-items-center justify-content-center">
                                                <img src={rateStatIcon} alt="#" />
                                            </div>
                                            <div className="ml-2 flex-fill">
                                                <div className="count">30%</div>
                                                <div className="title mt-1">Conversion rate</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={3} className="d-flex">
                                <Card className="w-100 mb-sm-2">
                                    <CardBody className="p-0">

                                        <div className="d-flex align-items-center flex-fill py-3 px-2">
                                            <div className="left-icon cancellation-icon-box d-flex align-items-center justify-content-center">
                                                <img src={CancellationIcon} alt="#" />
                                            </div>
                                            <div className="ml-2 flex-fill">
                                                <div className="count">4%</div>
                                                <div className="title mt-1">Cancellation rate</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    {/* <Col md={12} lg={5} className="mt-3 mt-lg-0">
                        <Card>
                            <CardBody className="p-0">
                                <div className="d-flex align-items-center">
                                    <div className="conversion-rate d-flex flex-fill align-items-center py-3 px-2">
                                        <div className="left-icon purple-icon-box d-flex align-items-center justify-content-center">
                                            <img src={rateStatIcon} alt="#" />
                                        </div>
                                        <div className="ml-2 flex-fill">
                                            <div className="count">30%</div>
                                            <div className="title mt-1">Conversion rate</div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center flex-fill py-3 px-2">
                                        <div className="left-icon cancellation-icon-box d-flex align-items-center justify-content-center">
                                            <img src={CancellationIcon} alt="#" />
                                        </div>
                                        <div className="ml-2 flex-fill">
                                            <div className="count">4%</div>
                                            <div className="title mt-1">Cancellation rate</div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col> */}
                </Row>
                <Row className="mt-4">
                    <Col md={12} lg={7} className="">
                        <Workload />
                    </Col>
                    <Col md={12} lg={5}>
                        <AppointmentStats />
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={12} lg={7} className="">
                        <Row>
                            <Col md={6}>
                                <MediaActivity />
                            </Col>
                            <Col md={6}>
                                <Connections />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} lg={5}>
                        <ReviewStats />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Dashboard