import AddressTypeaheadField from "components/forms/formikFields/AddressTypeaheadField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import LocationTypeaheadField from "components/forms/formikFields/LocationTypeaheadField";
import { Field } from "formik";
import PropTypes from "prop-types";
import React, { memo, useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-toastify/dist/ReactToastify.css";
import { Col, FormFeedback, InputGroup, Label, Row } from "reactstrap";
import { AffiliationInstitutionTypesList } from "utils/Constants";
import { MetadataService } from "utils/MetadataService";

const NewHospitalSection = (props) => {
  const { index, values, setFieldValue, errors, touched } = props;
  const [countryList, setCountyList] = useState([]);
  const [statesList, setSatesList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const stateRef = useRef();
  const cityRef = useRef();
  const countryRef = useRef();
  const locRef = useRef();

  const getCountries = () => {
    setSatesList([]);
    setCityList([]);
    MetadataService.getCountry()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setCountyList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getCountries error", error);
      });
  };

  const getStates = (countryId) => {
    setCityList([]);
    MetadataService.getStates(countryId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setSatesList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getStates error", error);
      });
  };

  const getCities = (stateId) => {
    MetadataService.getCities(stateId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setCityList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getCities error", error);
      });
  };

  const getLocations = (cityId) => {
    MetadataService.getLocations(cityId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setLocationList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getCities error", error);
      });
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <>
      <Row>
        <Col md={4}>
          <Label>Category</Label>
          <Field
            id={`hospitalClinicFormData[${index}].category`}
            name={`hospitalClinicFormData[${index}].category`}
            autocomplete={`hospitalClinicFormData[${index}].category`}
            component={FormikSelectField}
            inputprops={{
              options: AffiliationInstitutionTypesList,
              defaultOption: "Choose",
              keys: {
                id: "id",
                label: "label",
              },
            }}
          />
        </Col>
        <Col md={8}>
          <Label>Hospital/Clinic Name</Label>
          <Field
            name={`hospitalClinicFormData[${index}].mainItemName`}
            id={`hospitalClinicFormData[${index}].mainItemName`}
            type="text"
            autocomplete={`hospitalClinicFormData[${index}].mainItemName`}
            component={FormikInputField}
            placeholder="Enter hospital/clinic name"
          />
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <InputGroup
            size="medium"
            className="code-phone-ig phone-input-container"
          >
            <Label>Phone Number</Label>
            <PhoneInput
              country={"in"}
              onlyCountries={["in"]}
              inputClass="phonenumber-form-control"
              containerClass="mb-2"
              inputProps={{
                name: `hospitalClinicFormData[${index}].phone`,
              }}
              autocomplete={`hospitalClinicFormData[${index}].phone`}
              onChange={(value, country, e, formattedValue) =>
                setFieldValue(
                  `hospitalClinicFormData[${index}].phone`,
                  formattedValue
                )
              }
              inputStyle={{
                width: "100%",
              }}
            />
            {!!touched?.hospitalClinicFormData &&
              !!touched?.hospitalClinicFormData[index] &&
              !!touched?.hospitalClinicFormData[index].phone &&
              !!errors?.hospitalClinicFormData &&
              !!errors?.hospitalClinicFormData[index] &&
              !!errors?.hospitalClinicFormData[index].phone &&
              typeof errors.hospitalClinicFormData[index].phone ===
                "string" && (
                <FormFeedback
                  className="d-flex"
                  invalid={!!errors.hospitalClinicFormData[index].phone}
                >
                  {errors.hospitalClinicFormData[index].phone}
                </FormFeedback>
              )}
          </InputGroup>
        </Col>
        <Col lg={6}>
          <Label>Website Address</Label>
          <Field
            name={`hospitalClinicFormData[${index}].websiteAddress`}
            id="websiteAddress"
            type="text"
            autocomplete={`hospitalClinicFormData[${index}].websiteAddress`}
            component={FormikInputField}
            placeholder="E.g: https://www.google.com"
          />
        </Col>
      </Row>
      <fieldset className=" mb-3">
        {/*<legend>Mailing Address</legend>*/}
        <Row>
          <Col lg={12}>
            <Label>Street Address</Label>
            <Field
              name={`hospitalClinicFormData[${index}].addressLine1`}
              id="addressLine1"
              type="text"
              autocomplete={`hospitalClinicFormData[${index}].addressLine1`}
              component={FormikInputField}
              placeholder="Street Address"
            />
          </Col>
          <Col lg={12}>
            <Label>Street Address2</Label>
            <Field
              name={`hospitalClinicFormData[${index}].addressLine2`}
              id="addressLine2"
              type="text"
              component={FormikInputField}
              placeholder="Street Address2"
              autocomplete={`hospitalClinicFormData[${index}].addressLine2`}
            />
          </Col>

          <Col lg={6}>
            <div className="form-group">
              <Label>Country</Label>
              <Field
                name={`hospitalClinicFormData[${index}].country`}
                id={`hospitalClinicFormData[${index}].country`}
                component={AddressTypeaheadField}
                placeholder="Select country"
                spellCheck={false}
                inputList={countryList}
                value={values[`hospitalClinicFormData`][index].country}
                getAddressById={(contryId) => {
                  getStates(contryId);
                  setFieldValue(`hospitalClinicFormData[${index}].state`, []);
                  setFieldValue(`hospitalClinicFormData[${index}].city`, []);
                  setFieldValue(
                    `hospitalClinicFormData[${index}].location`,
                    []
                  );
                }}
                locationRef={countryRef}
                autocomplete={`hospitalClinicFormData[${index}].country`}
              />
            </div>
          </Col>

          <Col lg={6}>
            <div className="form-group">
              <Label>State</Label>
              <Field
                name={`hospitalClinicFormData[${index}].state`}
                id={`hospitalClinicFormData[${index}].state`}
                component={AddressTypeaheadField}
                placeholder="Select state"
                spellCheck={false}
                inputList={statesList}
                value={values[`hospitalClinicFormData`][index].state}
                getAddressById={(stateId) => {
                  getCities(stateId);
                  setFieldValue(`hospitalClinicFormData[${index}].city`, []);
                  setFieldValue(
                    `hospitalClinicFormData[${index}].location`,
                    []
                  );
                }}
                locationRef={stateRef}
                autocomplete={`hospitalClinicFormData[${index}].state`}
              />
            </div>
          </Col>

          <Col lg={4}>
            <div className="form-group">
              <Label>City</Label>
              <Field
                name={`hospitalClinicFormData[${index}].city`}
                id={`hospitalClinicFormData[${index}].city`}
                component={AddressTypeaheadField}
                placeholder="Select city"
                spellCheck={false}
                inputList={cityList}
                value={values[`hospitalClinicFormData`][index].city}
                getAddressById={(cityId) => {
                  getLocations(cityId);
                }}
                locationRef={cityRef}
                autocomplete={`hospitalClinicFormData[${index}].city`}
              />
            </div>
          </Col>

          <Col lg={4}>
            <div className="form-group">
              <Label>Zipcode</Label>
              <Field
                name={`hospitalClinicFormData[${index}].postalCode`}
                id={`hospitalClinicFormData[${index}].postalCode`}
                type="text"
                component={FormikInputField}
                placeholder="Zipcode"
                autocomplete={`hospitalClinicFormData[${index}].postalCode`}
              />
            </div>
          </Col>
          <Col lg={4}>
            <div className="form-group">
              <Label>Location</Label>
              <Field
                id={`hospitalClinicFormData[${index}].location`}
                name={`hospitalClinicFormData[${index}].location`}
                type="text"
                component={LocationTypeaheadField}
                value={values[`hospitalClinicFormData`][index].location}
                placeholder="Select Location"
                spellCheck={false}
                locations={locationList}
                selectdLocation={(input) =>
                  setFieldValue(
                    `hospitalClinicFormData[${index}].location`,
                    input
                  )
                }
                autocomplete={`hospitalClinicFormData[${index}].location`}
                locationRef={locRef}
              />
            </div>
          </Col>
        </Row>
      </fieldset>
    </>
  );
};

NewHospitalSection.propTypes = {
  index: PropTypes.number.isRequired,
};

export default memo(NewHospitalSection);
