import React from 'react'
import AsideOrthopedists from '../Doctors/components/AsideOrthopedists'
import UserSearchLists from '../Doctors/components/UserSearchLists'
import ApoloImg from 'assets/images/Hospitals-applo.png';
import CareImg from 'assets/images/hospitals-care.png';
import IconStar from '../Doctors/components/icons/IconStar';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from 'react';

export default function UserSearchView() {
    const [searchDate, setSearchDate] = useState(new Date());
    return (
        <div className='nexogic-theme-v2'>
            {/** find doctors banner */}
            <div className="nexogic-top-banner mb-2 pt-0 pb-0 bg-transparent">
                <div className="container-fluid p-0">
                    {/** find doctors banner */}
                    <div className="mb-2">
                        
                        {/** Search sec */}
                        <div className="mb-3 mt-3 nexogic-banner-search card">
                            <div className="row">
                                <div className="col-lg-3"><input type="text" name="s_by_name" id="s_by_name" className='form-control search-icon-input' placeholder='Search By Name' /></div>
                                <div className="col-lg-2"><input type="text" name="s_by_location" id="s_by_location" className='form-control location-icon-input' placeholder='Location' /></div>
                                <div className="col-lg-2"> <DatePicker className='form-control calender-icon-input' selected={searchDate} onChange={(date) => setSearchDate(date)} />
                                </div>
                                <div className="col-lg-2"><input type="text" name="s_by_insurance" id="s_by_insurance" className='form-control Insurance-icon-input' placeholder='Insurance plan' /></div>
                                <div className="col-lg-2 appointments-icon-input-w">
                                    <span className="icon appointments-icon-input"></span>
                                    <select className='form-control' name='ppu_appointments' id='ppu_appointments'>
                                        <option>Appointments</option>
                                        <option>Appointments 2</option>
                                    </select></div>
                                <div className="col-lg-1">
                                    <button type='button' className='btn nexogic_primary_button w-100' id='ppu_appointment_search'>Search
                                    </button>
                                </div>
                            </div>
                        </div>



                    </div>

                </div>

            </div>

            <div className="row">
                <div className="col-lg-12">
                    <h2 className="nexogic-page-title">21 providers</h2>
                </div>
                <div className="col-lg-8 nexogic-left-col">
                    <UserSearchLists />
                </div>

                <div className="col-lg-4 nexogic-right-col">
                    {/** */}
                    <div className="nexogic-info-box mb-3">
                        <h2 className="title">Make an appointment with a cardiologist at the MedRite Hospital and get a <span>10% discount</span> for blood tests</h2>
                    </div>
                    {/* ortho lists */}
                    <AsideOrthopedists />

                </div>


            </div>
        </div>
    )
}

