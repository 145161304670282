import { useState, useRef } from "react";
import { useFormikContext } from "formik";
import { Button, Collapse } from "reactstrap";
import job_description from 'assets/images/job-icon-8.png';
import blue_trash from 'assets/images/svgs/blue-trash-icon.svg';

function JobCreateFormEight(props) {
  const locRef = useRef();
  const textInput = useRef();
  const { values, setFieldValue } = useFormikContext();
  const [address, setAddress] = useState({});
  const [locationList, setLocationList] = useState([]);
  const [openCustomQuestion, setOpenCustomQuestion] = useState(false);
  const [customQuestion, setCustomQuestion] = useState({ question: '', renderType: 'NUMBER', options: [] });

  // Default suggested questions
  const [suggestedQuestions, setSuggestedQuestions] = useState([
    {
      id: 1,
      question: "How many years of Experience do you have in OPD?",
      renderType: 'NUMBER',
      options: []
    },
    {
      id: 2,
      question: "Do you like to work shift basis?",
      renderType: 'SELECT',
      options: ["YES", "NO"]
    },
    {
      id: 3,
      question: "Are you available for ICU?",
      renderType: 'TEXT',
      options: []
    }
  ]);

  const addSelectedQuestion = (selectedQuestion) => {
    const newQuestion = { ...selectedQuestion };
    if (selectedQuestion.renderType === 'SELECT' && !selectedQuestion.options.length) {
      newQuestion.options = ["YES", "NO"];
    }
    const newQuestions = [...values.screeningQuestions, newQuestion];
    setFieldValue('screeningQuestions', newQuestions);
  }

  const removeQuestion = (indexToRemove) => {
    const newQuestions = values.screeningQuestions.filter((_, index) => index !== indexToRemove);
    setFieldValue('screeningQuestions', newQuestions);
  }

  const isQuestionSelected = (question) => {
    return values.screeningQuestions.some(selectedQuestion => selectedQuestion.question === question.question);
  }

  const handleCustomQuestionChange = (e) => {
    const { name, value } = e.target;
    setCustomQuestion({
      ...customQuestion,
      [name]: value
    });
  }

  const handleSaveCustomQuestion = () => {
    const newCustomQuestion = { ...customQuestion, id: Date.now() }; // Assign a unique id
    if (newCustomQuestion.renderType === 'SELECT') {
      newCustomQuestion.options = ["YES", "NO"];
    }
    addSelectedQuestion(newCustomQuestion);
    setOpenCustomQuestion(false);
    setCustomQuestion({ question: '', renderType: 'NUMBER', options: [] });
  }

  const handleCancelCustomQuestion = () => {
    setOpenCustomQuestion(false);
    setCustomQuestion({ question: '', renderType: 'NUMBER', options: [] });
  }

  const getFilteredSuggestedQuestions = () => {
    return suggestedQuestions.filter(question => !isQuestionSelected(question));
  }

  const filteredSuggestedQuestions = getFilteredSuggestedQuestions();

  return (
    <>
      <div className="nex-job-form-left-sec">
        <div className="nex-job-info">
          <img src={job_description} alt="" className="graphic" />
          <div className="conts">
            <p><span className='light'>7/7</span> Job Post</p>
            <h2 className='title'>Finally you are going to post a job</h2>
            <p className='lead'>By Submitting you can make your job post done</p>
          </div>
        </div>
      </div>
      
      <div className="nex-job-form-right-sec">
        <div className="nex-flex wrap custom">
          <h2 className='nex-text-xl-small'>Screening Question</h2>
          <Button className='btn nex-button-outline nex-own-question' onClick={() => setOpenCustomQuestion(!openCustomQuestion)}>+ Add your own Question</Button>
        </div>
        
        <div className="form-group mb-4">
          <Collapse isOpen={openCustomQuestion} className="mt-3">
            <div className="card nex-collapse-card v2">
              <div className="card-body">
                <div className="card-group">
                  <label>Type your own Question</label>
                  <div className="from-group form-inline-field">
                    <input
                      type="text"
                      name="question"
                      value={customQuestion.question}
                      onChange={handleCustomQuestionChange}
                      className='form-control'
                    />
                    <Button className='btn nex-btn-transparent' onClick={handleCancelCustomQuestion}><img src={blue_trash} alt="" /></Button>
                  </div>
                  <label htmlFor="">Answer Type</label>
                  <div className="from-group form-inline-field mb-0">
                    <select
                      name="renderType"
                      value={customQuestion.renderType}
                      onChange={handleCustomQuestionChange}
                      className='form-control nex-max-w-300'
                    >
                      <option value="NUMBER">Number</option>
                      <option value="SELECT">Yes/No</option>
                      <option value="TEXT">Text</option>
                    </select>
                    <Button className='btn nex-button-outline' onClick={handleSaveCustomQuestion}>Save</Button>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>

        {values.screeningQuestions.length > 0 &&
          <div className="nex-suggest-question-sec">
            <h2 className='nex-text-lg text-black'>Selected Question</h2>
            {values.screeningQuestions.map((e, index) => (
              <div className="card bg-white mb-4" key={index}>
                <div className="card-body ">
                  <h3 className="card-title">{e.question}</h3>
                  <div className="nex-text" htmlFor="">{"Answer Type"}{": "}<span className="nex-val">{e.renderType}</span></div>
                  <Button className="nex-btn-transparent" onClick={() => removeQuestion(index)}><img src={blue_trash} alt=""/></Button>
                </div>
              </div>
            ))}
          </div>
        }

        {filteredSuggestedQuestions.length > 0 && (
          <>
            <h2 className='nex-text-lg bold text-black'>Suggested Questions</h2>
            <div className="row">
              <div className="col-lg-12">
                {filteredSuggestedQuestions.map((e, index) => (
                  <Button
                    key={index}
                    color="primary"
                    className='nex-light-collapse-btn'
                    onClick={() => addSelectedQuestion(e)}
                  >
                    {e.question}
                  </Button>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default JobCreateFormEight;
