import moment from 'moment'
import React from 'react'
import { useState } from 'react';

export default function CalWeekAppoMobile(props) {
    const [selectedDate, setSelectedDate] = useState(moment());
    function isCurrentDate(item){
        return moment().format('MMM Do YY') === moment(item).format('MMM Do YY');
    }
    const get15MinuteSlots = (datetime, slotInterval=15, hours=13) => {
        const slots = [];
        for (let i = 0; i < hours * 60; i += slotInterval) {
          slots.push(moment(datetime).add(i, "minutes"));
        }
        return slots;
      };

      function check_datetime(date_a, date_b){
        return date_a === date_b;
      }

      function combine_date_slot(dtime, slot){
        return moment(dtime).format('DD MM YYYY')+", "+slot.format('HH:mm A');
      }

      const getCurrentDate = (startHour=9) => {
        const today = selectedDate;
        today.hour(startHour);
        today.minute(0);
        return today;
      };

      const calcInactive = (inactiveData, slotSate = '06 16 2023, 09:00 AM')=>{
        let out = [false, 0];
        inactiveData.forEach(item=>{
            let sdate = moment(item.startTime);
            let edate = moment(item.endTime);
            //let inactiveDef = sdate.diff(edate);
            if(moment(slotSate).isBetween(sdate, edate)){
                console.log(moment(slotSate).isBetween(sdate, edate), slotSate)
            }
             
            if(sdate.isSame(moment(slotSate)) || moment(slotSate).isBetween(sdate, edate) || edate.isSame(moment(slotSate))){
                //console.log(edate.diff(sdate, 'minutes')/15); 
                out = [true, edate.diff(sdate, 'minutes')/15];
                return;

            }
            
            
        });
        return out;
       // console.log(inactiveData);
    }  

  return (
    <div className='nexogic-mob-appointments'>
        <div className="nexogic-active-select-date">
            <div className="day-name">{selectedDate.format('dddd')}</div>
            <div className="day-number">{selectedDate.format('Do')}</div>
        </div>
        {get15MinuteSlots(getCurrentDate()).map((slot, ind)=>{
            return (
                <div className='nexogic-slot-mob-time' key={'mob-slot-time-'+ind}>
                    <div className='time'>{slot.format("hh:mm A")}</div>
                    {/* appointment  */}
                    {props.appointments.map((item, ind)=>{
                        return (
                            <React.Fragment key={'nrweek_'+ind}> 
                                {check_datetime(combine_date_slot(selectedDate, slot), moment(item.startTime).format('DD MM YYYY, HH:mm A')) && 
                                    <> 
                                        <div className={`nexogic-appoint-block ${item.status == 'accepted' ? 'active' : ''} 
                                        ${item.status == 'pending' ? 'pending' : ''} 
                                        ${item.status == 'reject' ? 'rejected' : ''}
                                        `} onClick={(e) => props.popupPos(e)}>
                                            <span className="name">{item.name}</span>
                                            <span className="time"> {moment(item.startTime).format('HH:mm A')} - {moment(item.endTime).format('HH:mm A')}</span>
                                            
                                        </div>

                                        
                                    </>
                                }
                            </React.Fragment>
                        )
                    })}
                    {calcInactive(props.inactiveTimes, selectedDate.format('MM DD YYYY')+", "+slot.format('HH:mm A'))[0] && 
                        <div className='inactive'></div>
                    }
                </div>
            )
        })}
    </div>
  )
}
