import { useState } from "react";
import { Button, Col, ListGroup, ListGroupItem, Row } from "reactstrap";

const SetNotifications = (props) => {
  const [conversationsNotf, setConversationNotf] = useState(false);
  const [profileNotf, setProfileNotf] = useState(false);
  const [networkNotf, setNetworkNotf] = useState(false);

  const enableConversationsNotf = () => {
    setConversationNotf(true);
  };
  const disableConversationsNotf = () => {
    setConversationNotf(false);
  };

  const enableprofileNotf = () => {
    setProfileNotf(true);
  };
  const disableprofileNotf = () => {
    setProfileNotf(false);
  };

  const enablenetworkNotf = () => {
    setNetworkNotf(true);
  };
  const disablenetworkNotf = () => {
    setNetworkNotf(false);
  };

  return (
    <>
      {props.isTitle &&
      <div className="nexogic-page-title">Notifications</div>
    }
      <Row>
        <Col lg={12}>
          <div className="section-content">
            <Row className="align-items-center">
              <Col>
                <span className="card-text text-muted">
                  Receive notification about contact requests, birthdays, events etc.
                </span>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col>
                <ListGroup className="nexogic-form-option-lists mt-0">
                  <ListGroupItem>
                    <h3 className="card-title">Conversations
                      <small className="card-text text-muted">Message, Post, Comments</small>
                    </h3>
                    <div className="action nexogic-swaches-btns">
                      <Button
                        className={
                          "calendar-status-btn enable-btn " +
                          (conversationsNotf ? "active" : "")
                        }
                        onClick={enableConversationsNotf}
                      >
                        Enable
                      </Button>
                      <Button
                        className={
                          "calendar-status-btn disable-btn " +
                          (!conversationsNotf ? "active" : "")
                        }
                        onClick={disableConversationsNotf}
                      >
                        Disable
                      </Button>
                    </div>
                  </ListGroupItem>
                  <ListGroupItem>
                    <h3 className="card-title">Profile
                      <small className="card-text text-muted"></small>
                    </h3>
                    <div className="action nexogic-swaches-btns">
                      <Button
                        className={
                          "calendar-status-btn enable-btn " +
                          (profileNotf ? "active" : "")
                        }
                        onClick={enableprofileNotf}
                      >
                        Enable
                      </Button>
                      <Button
                        className={
                          "calendar-status-btn disable-btn " +
                          (!profileNotf ? "active" : "")
                        }
                        onClick={disableprofileNotf}
                      >
                        Disable
                      </Button>
                    </div>
                  </ListGroupItem>
                  <ListGroupItem>
                    <h3 className="card-title">Network
                      <small className="card-text text-muted">Events, Birthdays, Invites</small>
                    </h3>
                    <div className="action nexogic-swaches-btns">
                      <Button
                        className={
                          "calendar-status-btn enable-btn " +
                          (networkNotf ? "active" : "")
                        }
                        onClick={enablenetworkNotf}
                      >
                        Enable
                      </Button>
                      <Button
                        className={
                          "calendar-status-btn disable-btn " +
                          (!networkNotf ? "active" : "")
                        }
                        onClick={disablenetworkNotf}
                      >
                        Disable
                      </Button>
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </Col>
            </Row>


{/* 
            <Row className="align-items-center">
              <Col>
                <span className="setting-card-content">
                  Receive notification about contact requests, birthdays, events
                  etc.
                </span>
              </Col>
            </Row>
            <Row className="align-items-center mt-4">
              <Col sm={8}>
                <div className="notification-title mb-2">Conversations</div>
                <div className="setting-card-content">
                  Message, Post, Comments
                </div>
              </Col>
              <Col
                sm={4}
                className="d-flex justify-content-lg-end justify-content-center enb-dsb-btn"
              >
                <Button
                  className={
                    "calendar-status-btn enable-btn " +
                    (conversationsNotf ? "active" : "")
                  }
                  onClick={enableConversationsNotf}
                >
                  Enable
                </Button>
                <Button
                  className={
                    "calendar-status-btn disable-btn " +
                    (!conversationsNotf ? "active" : "")
                  }
                  onClick={disableConversationsNotf}
                >
                  Disable
                </Button>
              </Col>
            </Row>
            <Row className="align-items-center mt-4">
              <Col sm={8}>
                <div className="notification-title mb-2">Profile</div>
              </Col>
              <Col
                sm={4}
                className="d-flex justify-content-lg-end justify-content-center enb-dsb-btn"
              >
                <Button
                  className={
                    "calendar-status-btn enable-btn " +
                    (profileNotf ? "active" : "")
                  }
                  onClick={enableprofileNotf}
                >
                  Enable
                </Button>
                <Button
                  className={
                    "calendar-status-btn disable-btn " +
                    (!profileNotf ? "active" : "")
                  }
                  onClick={disableprofileNotf}
                >
                  Disable
                </Button>
              </Col>
            </Row>
            <Row className="align-items-center mt-4">
              <Col sm={8}>
                <div className="notification-title mb-2">Network</div>
                <div className="setting-card-content">
                  Events, Birthdays, Invites
                </div>
              </Col>
              <Col
                sm={4}
                className="d-flex justify-content-lg-end justify-content-center enb-dsb-btn"
              >
                <Button
                  className={
                    "calendar-status-btn enable-btn " +
                    (networkNotf ? "active" : "")
                  }
                  onClick={enablenetworkNotf}
                >
                  Enable
                </Button>
                <Button
                  className={
                    "calendar-status-btn disable-btn " +
                    (!networkNotf ? "active" : "")
                  }
                  onClick={disablenetworkNotf}
                >
                  Disable
                </Button>
              </Col>
            </Row>
                */}
          </div>
        </Col>
      </Row>
    </>
  );
};

SetNotifications.defaultProps = {
  isTitle: true,
};

export default SetNotifications;
