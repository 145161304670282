import React, { useState, useEffect, Fragment } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse
} from "reactstrap";
import { useSelector, useDispatch, connect } from "react-redux"
import { getAffiliations } from "redux-helpers/ActionCreator";

import Downarrow from "assets/images/downward-arrow.png";
import dropdownIcon from "assets/images/dropdown_icon.png";
import hospitalBuilding from 'assets/images/hospital-building.png';
import Upload from "assets/images/upload.png";

const Hospital = (props) => {
  const dispatch = useDispatch()
  const { userAffiliations } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [affiliationsData, setAffiliationsData] = useState([]);
  const [selectedAffiliationIndex, setSelectedAffiliationIndex] = useState(0);
  const currentUser = useSelector(state => state.auth.currentUser);
  const [mobileToggle, setMobileToggle] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser.practitionerId) {
      dispatch(getAffiliations(currentUser.practitionerId, 'headerAffiliations'));
    }
  }, [currentUser])

  useEffect(() => {
    setAffiliationsData(userAffiliations)
  }, [userAffiliations]);

  const toggle = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const changeAffiliation = (i) => {
    setSelectedAffiliationIndex(i)
    toggle()
    if(mobileToggle){
      setMobileToggle(!mobileToggle);
    }
  }

  const handleMobileCollapse = () => {
    setMobileToggle(!mobileToggle);
  }

  return (
    (affiliationsData && affiliationsData.length) ?
      <>
        <ButtonDropdown
          className="hospital-menu position-relative d-none d-md-inline-flex"
          isOpen={dropdownOpen}
          toggle={toggle}
        >
          <DropdownToggle color="link" className="min-w-0 p-0">
            <div className="d-flex align-items-center">
              <div className="hospital-logo">
                <img src={hospitalBuilding} width="20" alt=""/>
              </div>
              <div className="hospital-name pl-2">{affiliationsData[selectedAffiliationIndex].institute ? affiliationsData[selectedAffiliationIndex].institute.institutionName : ''}</div>
              <div className="dropdown-icon pl-2">
                <img src={dropdownIcon} alt=""/>
              </div>
            </div>
          </DropdownToggle>
          <DropdownMenu right>
            {
              affiliationsData.length && affiliationsData.map((data, i) =>
                <Fragment key={i}>
                  <div className="dropdown-data d-flex align-items-center py-1 px-3 cursor-pointer" onClick={() => changeAffiliation(i)}>
                    <div className="hospital-logo">
                      <img src={hospitalBuilding} width="20" alt=""/>
                    </div>
                    <div className="hospital-name d-flex align-items-center flex-wrap pl-2">{data.institute ? data.institute.institutionName : ''}</div>
                  </div>
                  {i !== (affiliationsData.length - 1) && <DropdownItem divider />}
                </Fragment>
              )}
          </DropdownMenu>
        </ButtonDropdown>
        <div onClick={handleMobileCollapse} className="w-100 d-md-none d-flex justify-content-between align-items-center mt-3 mb-1">
          <div className="d-flex align-items-center">
            <div className="hospital-logo">
              <img src={hospitalBuilding} width="20" alt=""/>
            </div>
            <div className="hospital-name pl-2">{affiliationsData[selectedAffiliationIndex].institute ? affiliationsData[selectedAffiliationIndex].institute.institutionName : ''}</div>
          </div>
          <span>{mobileToggle ? <img src={Upload} alt="" width="24" /> : <img src={Downarrow} alt="" />}</span>
        </div>
        <Collapse isOpen={mobileToggle} className=" text-center mx-auto mt-1">
          {
            affiliationsData.length && affiliationsData.map((data, i) =>
              <Fragment key={i}>
                <div className="dropdown-data d-flex align-items-center py-1 px-3 mb-2 cursor-pointer" onClick={() => changeAffiliation(i)}>
                  <div className="hospital-logo">
                    <img src={hospitalBuilding} width="20" alt=""/>
                  </div>
                  <div className="hospital-name d-flex align-items-center flex-wrap pl-2">{data.institute ? data.institute.institutionName : ''}</div>
                </div>
                {/* {i !== (affiliationsData.length - 1) && <DropdownItem divider />} */}
              </Fragment>
            )}
        </Collapse>
      </> : ''
  );
}

const mapStateToProps = ({ ProfileData }) => ({
  ...ProfileData
})

export default connect(
  mapStateToProps,
  null
)(Hospital)
