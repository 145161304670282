// import Footer from 'common/Footer'
// import HeaderPage from 'common/HeaderPage'
import React from 'react'
import { useLocation, useHistory } from "react-router-dom";
import secondOpinionImg from '../../../assets/images/second-opi-circle-img.png'
import secondOpinionright from '../../../assets/images/second-opinion.png'
import "./style.scss";


const SecondOpinion = () => {
  const history = useHistory();
  return (
    <div className="nexogic-second-option container-fluid">
      <div className="row">
        <div className="col-lg-9 nexogic-second-option-left">
          <div className="card">
            <div className="row nexogic-form-header">
              <div className="col-lg-8">
                <h2 className="nexogic-form-title mb-2">Second Opinion Service</h2>
                <p className='nexogic-form-title-sub'>Medical consultation leader Providing online consultation for all areas of medicine </p>
              </div>
              <div className="col-lg-4 graphic">
                <img src={secondOpinionImg} alt="Image" width="100%" />
              </div>
            </div>
            <div className="row  card-body">
              <div className="col-md-12 nexogic-form-content">
                <div className='w-100'>
                  <div className="form-group">
                    <div className="form-check pl-0">
                      <label>Second Option For</label>
                    </div>
                    <div className="form-check form-check-inline ">
                      <input className="form-check-input" type="radio" name="so-form-type" id="selfRadio" value="self" checked />
                      <label className="form-check-label" htmlFor="selfRadio">Self</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="so-form-type" id="someoneRadio" value="someone" />
                      <label className="form-check-label" htmlFor="someoneRadio">Someone</label>
                    </div>
                  </div>

                  <div className="form-group">
                    <label for="exampleInputEmail1">Please Fill out the form to getting started </label>
                    <select className="form-control" id="so-specialty" name='so-specialty'>
                      <option>Select Specialty</option>
                      <option>Dentistry</option>
                      <option>Dermatology</option>
                      <option>Surgery</option>
                      <option>Pediatrics</option>
                      <option>Cardiology</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <textarea className="form-control big" id="so-inquery" name='so-inquery' rows="3" placeholder='Enter Your inquery' max="600"></textarea>
                    <div className="form-text text-left card-text"><small>* upto 600 charecters</small></div>
                  </div>
                  <div className="form-group">
                    <button type="submit" name='sq-submit' className="nexogic_primary_button btn" onClick={() => history.push(`/u/medicalcases/create-new-second-opinion`)}>Get A second Opinion</button>
                  </div>
                  <p className='form-text'><small>Your Information will be used only for medical purpose</small></p>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
        <div className="nexogic-widget-sidebar-card card border-0">
            <h3 className="card-title h2">Second Opinion</h3>
            <div className="card-body">
              <ul className="nexogic-lists nexogic-custom-scroll">
                <li>
                  <img src={secondOpinionright} alt="" className="img-fluid" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default SecondOpinion