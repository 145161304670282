import API_Factory from "./API_Factory";

const uploadMedia = (tenantType, tenantId, formData) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                method: "POST",
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/media-service/${tenantType}/${tenantId}/medias`,
                data: formData,
                ignoreToken: false
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
}

const getMedias = (tenantType, mediaId) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/media-service/${tenantType}/${mediaId}/medias`,
                method: "GET",
                responseType: "application/json",
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};

const getMediaFile = (guid) =>
    API_Factory({
        method: "GET",
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: `/media-service/medias/${guid}`,
        ignoreToken: false,
        responseType: "arraybuffer",
    });

const deleteMedia = (mediaId) => {
    return new Promise((resolve, reject) => {
        try {
            let response = API_Factory({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: `/media-service/medias/${mediaId}`,
                method: "DELETE",
                responseType: "application/json",
            });
            return resolve(response);
        } catch (e) {
            return reject(e);
        }
    });
};


export const MedialService = {
    uploadMedia,
    getMedias,
    getMediaFile,
    deleteMedia
};