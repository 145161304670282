import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { ErrorMessage } from "formik";

const FormikTextAreaField = ({
  field: { ...fields },
  form: { touched, errors, handleBlur, setFieldValue, ...rest },
  ...props
}) => (
  <FormGroup className="flex-grow-1">
    {props.label && (
      <Label htmlFor={props.id} className={"label-color"}>
        {props.label}
      </Label>
    )}
    <Input
      {...props}
      {...fields}
      type="textarea"
      onBlur={(e) => handleBlur(e)}
      invalid={Boolean(touched[fields.name] && errors[fields.name])}
    />
    <ErrorMessage
      name={fields.name}
      render={(msg) => (
        <span className="text-danger d-inline-block  mt-1 line-height-error">
          {msg}
        </span>
      )}
    />
  </FormGroup>
);

export default FormikTextAreaField;
