import React, { useState } from "react";
import RightArr from "../../images/rightarrow.png";
import SugesstionCard from "./SugesstionCard";
import "./SugestedConn.css";
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import SuggestionModal from "./SuggestionModal";
const SuggestedConn = () => {
  let sugesstedConn = useSelector(state => state.feed.suggestedConn)
  const [conModal, setConModal] = useState(false)
  const toggle = () => setConModal(!conModal)
  return (
    <div className="sugess_box">
      <div className="d-flex sug__header mb-4">
        <div className="heading">Suggested Connections</div>
        <div className="view_all d-flex align-items-center">
          <span className="view" onClick={toggle}>View all</span>
          <img src={RightArr} alt="" className="ml-3" />
        </div>
      </div>
      <div className="sug__body">
        {sugesstedConn &&
          sugesstedConn.map((item, index) => {
            if (index <= 2) {
              return <SugesstionCard
                suggestion={item}
              />
            }

          })
        }
        <SuggestionModal
          toggle={toggle}
          modal={conModal}
        />
      </div>
    </div>
  );
};

export default SuggestedConn;
