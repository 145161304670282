import {
    AUTH_SET_TOKEN,
    AUTH_DISCARD_TOKEN,
    AUTH_SET_USER,
    LOGOUT_USER
  }  from './Actions';
  let initialState = {
    currentUser: {}
  }
  function authReducer(state = initialState, action){
    switch(action.type){
      // saves the token into the state
      case AUTH_SET_TOKEN:
        return {
          ...state,
          token: action.token
        };
      // discards the current token (logout)
      case AUTH_DISCARD_TOKEN:
        return {};
      // saves the current user
      case AUTH_SET_USER:
        return {
          ...state,
          currentUser:action.currentUser
        };
        case LOGOUT_USER:
          return {
            ...state,
            currentUser:initialState.currentUser,
          };
      // as always, on default do nothing
      default:
        return state;
    }
  }
  
export default authReducer;