import "assets/styles/typeahead.css";
import React, { useRef, useState } from "react";
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import InfiniteScroll from "react-infinite-scroll-component";
import { withRouter } from "react-router-dom";
import { InstitutionServices } from "utils/InstitutionServices";
import { PractitionerService } from "utils/PractitionerService";

const AsyncGlobalSearchInput = (props) => {
  const { placeholder, type, history, searchType } = props;
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [userQuery, setUserQuery] = useState("");
  const currentPage = useRef(0);
  const ref = React.createRef();

  const getData = (query) => {
    if (searchType === 'Practitioners') {
      getPractitionersData(query);
    }
    else {
      getInstitutionsData(query);
    }
  }

  const getPractitionersData = async (query) => {
    if (currentPage.current === 0)
      setOptions([]);
    PractitionerService.globalSearchPractitioner({
      q: query,
      cp: currentPage.current,
    }).then((response) => {
      const { data } = response;
      currentPage.current = data.currentPage + 1;
      if (data.currentPage !== 0) {
        setHasMore(data.currentPage !== data.totalPages);
      }
      if (data && data.contents && data.contents.length > 0) {
        let practitioners = data?.contents;
        let results = practitioners.map((p) => {
          let {
            primarySpeciality,
            name,
            city,
            country,
            state,
            profileId,
            profileGuid,
            qualification,
          } = p;
          const location = `${!!city ? " " + city : ""}${!!state ? ", " + state : ""
            }${!!country ? ", " + country : ""}`;
          return {
            id: profileGuid,
            username: name,
            profilePicUrl: p.profilePicUrl,
            profileId,
            location,
            primarySpeciality,
            qualification,
          };
        });
        setOptions((prevState) => [...prevState, ...results]);
        setLoading(false);
      }
      setLoading(false);
    });
  };

  const getInstitutionsData = (query) => {
    if (currentPage.current === 0)
      setOptions([]);
    let q = `na=="*${query}*"`;
    InstitutionServices.searchInstitutions(q, currentPage.current).then((response) => {
      const { data } = response;
      currentPage.current = data.currentPage + 1;
      if (data.currentPage !== 0) {
        setHasMore(data.currentPage !== data.totalPages);
      }
      if (data && data.contents && data.contents.length > 0) {
        let Institutions = data.contents;
        let results = Institutions.map((p) => {
          let {
            name,
            city,
            country,
            state,
            guid,
            profileId,
          } = p;
          const location = `${!!city ? " " + city : ""}${!!state ? ", " + state : ""
            }${!!country ? ", " + country : ""}`;
          return {
            id: guid,
            username: name,
            profilePicUrl: p.profilePicUrl,
            profileId,
            location,
          };
        });
        setOptions((prevState) => [...prevState, ...results]);
        setLoading(false);
      }
      setLoading(false);
    });
  };

  const handleSearch = (query) => {
    if (query.length < 3) return false;
    setOptions([]);
    currentPage.current = 0;
    setLoading(true);
    setUserQuery(query);
    getData(query);
  };

  const fetchMoreData = () => {
    setTimeout(() => getData(userQuery), 500);
  };

  const handleChange = (selected) => {
    if (selected && selected[0]) {
      let result = selected[0];
      if (searchType === 'Practitioners') {
        history.push(`/profile/${result?.profileId}`);
      } else if (searchType === 'Institutions') {
        history.push(`/institution/${result?.profileId}`);
      }
      ref.current?.clear();
      setOptions([]);
      if (document.body.classList.contains("view_in_app")) {
        if (document.querySelectorAll('.header-search-switch')[0].classList.contains("on")) {
          document.querySelectorAll('.header-search-switch')[0].classList.remove('on');
          document.querySelectorAll('.header-search-wrap')[0].classList.remove('on');
        }
      }
    }
  };

  return (
    <>
      <AsyncTypeahead
        ref={ref}
        isLoading={loading}
        clearButton
        onSearch={handleSearch}
        id={"searchAsyncInput"}
        options={options}
        labelKey={"username"}
        placeholder={placeholder}
        type={type}
        minLength={3}
        maxResults={options.length}
        inputProps={{ className: "border-0" }}
        onChange={handleChange}
        useCache={false}
        delay={1000}
        filterBy={(option) => {
          return option;
        }}
        renderMenu={(results, menuProps) => {
          if (results && results.length) {
            return (
              <Menu {...menuProps} className="infi_menu_container" >
                <>
                  {/** loadingSearchContainer  */}
                  <div id="header_menu_scroll" style={{
                    height: 300,
                    overflow: 'auto',
                  }}>
                    <InfiniteScroll
                      dataLength={options.length}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      loader={<p className="loadingSearch">Loading...</p>}
                      className="loadingSearchContainer"

                      scrollableTarget="header_menu_scroll"
                    >
                      {results.map(
                        (result, index) =>
                          result?.username && (
                            <MenuItem
                              key={index}
                              option={result}
                              position={index}
                              className="px-3 text-wrap"
                            >
                              <div className="card-title fw-semibold text-truncate">
                                {result?.username}
                                <span>
                                  {result?.qualification
                                    ? ` , ${result?.qualification}`
                                    : ""}
                                </span>
                              </div>
                              <div className="card-text text-truncate">
                                {result?.primarySpeciality}
                              </div>
                              <div className="card-text text-truncate">
                                {result?.location}
                              </div>
                            </MenuItem>
                          )
                      )}
                    </InfiniteScroll>
                  </div>
                </>
              </Menu>
            );
          }
          return null;
        }}
      />
    </>
  );
};
export default withRouter(AsyncGlobalSearchInput);
