// Inside JobTitleEditModal component
import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const JobTitleEditModal = (props) => {
    const { modal, setModal } = props;
    const { values, setFieldValue } = useFormikContext();
    const [inputTitle, setInputTitle] = useState(values?.title);

    const handleChange = (e) => {
        setInputTitle(e.target.value);
    };

    const handleSubmit = () => {
        if (inputTitle != "")
            setFieldValue('title', inputTitle);
        setModal(false);
    };

    return (
        <Modal isOpen={modal} toggle={() => setModal(!modal)} className="nex-model-v2 modal-dialog-scrollable modal-dialog-centered">
            <ModalHeader toggle={() => setModal(!modal)}>Edit Job Title</ModalHeader>
            <ModalBody>
                <div className="form-group">
                    <label className='nex-text-lg'>Job Title</label>
                    <input
                        type="text"
                        className='form-control'
                        value={inputTitle}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group nex-action-flex">
                    <button className="btn nex-btn-primary ml-auto" onClick={handleSubmit}>Save</button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default JobTitleEditModal;
