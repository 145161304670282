import CommonLoader from "common/CommonLoader";
import AddressTypeaheadField from "components/forms/formikFields/AddressTypeaheadField";
import CommonMultipleSelectTypeaheadField from "components/forms/formikFields/CommonMultipleSelectTypeaheadField";
import FormikDatePicker from "components/forms/formikFields/FormikDatePicker";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import LocationTypeaheadField from "components/forms/formikFields/LocationTypeaheadField";
import { Field, FieldArray } from "formik";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Col, Row, Spinner } from "reactstrap";
import { TIME_CONSTANTS } from "utils/Constants";
import { MetadataService } from "utils/MetadataService";

const years = TIME_CONSTANTS.YEARS();

const titles = [
    {
        label: "Dr.",
        value: "Dr.",
    },
    {
        label: "Mr.",
        value: "Mr.",
    },
    {
        label: "Mrs.",
        value: "Mrs.",
    },
    {
        label: "Ms.",
        value: "Ms.",
    },
    {
        label: "Prof.",
        value: "Prof.",
    },
];

const genders = [
    {
        label: "Male",
        value: "M",
    },
    {
        label: "Female",
        value: "F",
    },
    {
        label: "Prefer not to answer",
        value: "U",
    },
];

const bloodGroups = [
    {
        label: "A+",
        value: "A+",
    },
    {
        label: "A-",
        value: "A-",
    },
    {
        label: "B+",
        value: "B+",
    },
    {
        label: "B-",
        value: "B-",
    },
    {
        label: "AB+",
        value: "AB+",
    },
    {
        label: "AB-",
        value: "AB-",
    },
    {
        label: "O+",
        value: "O+",
    },
    {
        label: "O-",
        value: "O-",
    },
    {
        label: "Unknown",
        value: "Unknown",
    },
];


export const RenderProfileFormFields = (props) => {
    const { setFieldValue, values, handleSubmit, dirty, toggle, isSubmitting, vmode, isLoading, setIsLoading } =
        props;
    const { primarySpeciality, practicingSince, gender, bloodGroup } = values;
    const [speciality, setSpeciality] = useState([]);
    const [superSpecialitySkills, setSuperSpecialitySkills] = useState([]);
    const [languagesList, setLanguagesList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [statesList, setSatesList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [address, setAddress] = useState({});
    const stateRef = useRef();
    const cityRef = useRef();
    const countryRef = useRef();
    const locRef = useRef();

    useEffect(() => {
        getPrimarySpeciality();
        getLanguages();
        if (primarySpeciality && primarySpeciality.length > 0) {
            getSpecialitySkillSet(primarySpeciality[0]);
        }
        setDefaultAddress();
    }, [primarySpeciality]);

    const getPrimarySpeciality = () => {
        MetadataService.getPrimarySpecialities()
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setSpeciality(response.data);
                }
            })
            .catch((error) => {
                console.log("IdentityService.getPrimarySpeciality error", error);
            });
    };

    const getSpecialitySkillSet = (selectedSpeciality) => {
        if (isEmpty(selectedSpeciality)) {
            setSuperSpecialitySkills([]);
            return;
        }
        MetadataService.getSpecialitySkillSet(selectedSpeciality)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setSuperSpecialitySkills(response.data);
                }
            })
            .catch((error) => {
                console.log("IdentityService.getSpecialitySkillSet error", error);
            });
    };

    const getLanguages = () => {
        MetadataService.getLanguages()
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setLanguagesList(response.data);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log("IdentityService.getLanguages error", error);
                setIsLoading(false);
            });
    };

    const setDefaultAddress = () => {
        new Promise(function (resolve, reject) {
            MetadataService.getCountry().then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setCountryList(response.data);
                    resolve(response.data);
                }
            });
        })
            .then(function (result) {
                return new Promise(function (resolve, reject) {
                    if (result !== undefined && result.length > 0) {
                        result.forEach((obj, index, array) => {
                            if (obj.value === values.country[0]) {
                                setFieldValue("country", [obj]);
                                MetadataService.getStates(obj.id).then((response) => {
                                    if (response.status === 200 || response.status === 201) {
                                        setSatesList(response.data);
                                        resolve(response.data);
                                    }
                                });
                            }
                        });
                    }
                });
            })
            .then(function (result) {
                return new Promise(function (resolve, reject) {
                    if (result !== undefined && result.length > 0) {
                        result.forEach((obj, index, array) => {
                            if (obj.value === values.state[0]) {
                                setFieldValue("state", [obj]);
                                MetadataService.getCities(obj.id).then((response) => {
                                    if (response.status === 200 || response.status === 201) {
                                        setCityList(response.data);
                                        resolve(response.data);
                                    }
                                });
                            }
                        });
                    }
                });
            })
            .then(function (result) {
                return new Promise(function (resolve, reject) {
                    if (result !== undefined && result.length > 0) {
                        result.forEach((obj, index, array) => {
                            if (obj.value === values.city[0]) {
                                setFieldValue("city", [obj]);
                                MetadataService.getLocations(obj.id).then((response) => {
                                    if (response.status === 200 || response.status === 201) {
                                        setLocationList(response.data);
                                        resolve(response.data);
                                    }
                                });
                            }
                        });
                    }
                });
            });
    };

    const getStates = (countryId) => {
        setSatesList([]);
        setCityList([]);
        setLocationList([]);
        getselectedAddress(countryList, countryId, "COUNTRY");
        if (countryId === "") {
            return false;
        }
        MetadataService.getStates(countryId)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setSatesList(response.data);
                }
            })
            .catch((error) => {
                console.log("IdentityService.getStates error", error);
            });
    };

    const getCities = (stateId) => {
        setCityList([]);
        setLocationList([]);
        getselectedAddress(statesList, stateId, "STATE");
        if (stateId === "") {
            return false;
        }
        MetadataService.getCities(stateId)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setCityList(response.data);
                }
            })
            .catch((error) => {
                console.log("IdentityService.getCities error", error);
            });
    };

    const getLocations = (cityId) => {
        setLocationList([]);
        getselectedAddress(cityList, cityId, "CITY");
        if (cityId === "") {
            return false;
        }
        MetadataService.getLocations(cityId)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setLocationList(response.data);
                }
            })
            .catch((error) => {
                console.log("IdentityService.getCities error", error);
            });
    };

    const getselectedAddress = (array, id, type) => {
        let filterValue = "";
        if (!isEmpty(values.country)) {
            array.map((obj) => {
                if (obj.id.toString() === id.toString()) {
                    filterValue = obj.value;
                }
                return true;
            });
        }
        if (type === "COUNTRY") {
            setAddress({
                ...address,
                country: filterValue,
                state: "",
                city: "",
                location: "",
            });
            values.state = [];
            values.city = [];
            values.location = [];
        } else if (type === "STATE") {
            setAddress({
                ...address,
                state: filterValue,
                city: "",
                location: "",
            });
            values.city = [];
            values.location = [];
        } else if (type === "CITY") {
            setAddress({
                ...address,
                city: filterValue,
                location: "",
            });
            values.location = [];
        }
    };

    const selectdLocation = (locationName) => {
        setAddress({
            ...address,
            location: locationName,
        });
    };

    return (
        <>
            {
                isLoading
                    ?
                    <div className="text-center mb-4">
                        <CommonLoader />
                    </div>
                    :

                    <form onSubmit={handleSubmit}>

                        <div className="row">
                            <div className="col-lg-12 profile-quick-info">
                                {/* <div className='nexogic-profile-thumb'>
                                    <img src="" alt="" className='card-img' />
                                    <span className="icon-upload-btn icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12"
                                            height="10"
                                            fill="none"
                                            viewBox="0 0 12 10"
                                        >
                                            <path
                                                fill="#fff"
                                                d="M7.272 1.111L8.37 2.222h2.43V8.89H1.2V2.222h2.43l1.098-1.11h2.544zM7.8 0H4.2L3.102 1.111H1.2c-.66 0-1.2.5-1.2 1.111V8.89C0 9.5.54 10 1.2 10h9.6c.66 0 1.2-.5 1.2-1.111V2.222c0-.61-.54-1.11-1.2-1.11H8.898L7.8 0zM6 3.889c.99 0 1.8.75 1.8 1.667 0 .916-.81 1.666-1.8 1.666s-1.8-.75-1.8-1.666c0-.917.81-1.667 1.8-1.667zm0-1.111c-1.656 0-3 1.244-3 2.778 0 1.533 1.344 2.777 3 2.777S9 7.09 9 5.556c0-1.534-1.344-2.778-3-2.778z"
                                            ></path>
                                        </svg>
                                    </span>
                                </div> */}
                                {/* <div className="info">
                                    <h3 className="card-title">{values?.firstName}{" "}{values?.lastName}</h3>
                                    <p className="card-text">{values?.emailAddress}</p>
                                    <p className="card-text">{values?.cellularPhoneNumber}</p>
                                </div> */}
                                {/* <div className="nexogic-floted-btn">
                                    <a href="" className="btn"><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="none"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            fill="#009FD7"
                                            d="M13.73.93c-.354 0-.684.134-.93.38l-.26.26h-.01a.322.322 0 00-.21-.03.32.32 0 00-.16.09L2.11 11.68a.144.144 0 00-.01.02.313.313 0 00-.06.09L.98 14.61a.318.318 0 00.075.335.318.318 0 00.335.075l2.82-1.06a.315.315 0 00.09-.06c.007-.005.014-.005.02-.01L14.37 3.84a.32.32 0 00.03-.41l.26-.26a1.315 1.315 0 000-1.86 1.303 1.303 0 00-.93-.38zM12.38 2.3l1.32 1.32-1.06 1.05-1.31-1.31 1.05-1.06zm-9.8 9.88l.3.3h.64v.64l.3.3-1.55.59-.28-.28.59-1.55z"
                                        ></path>
                                    </svg></a>
                                </div> */}
                            </div>
                        </div>

                        <Row className="mb-1 nexogic-profile-edit-form">
                            <Col>
                                <fieldset className=" mb-0">
                                    <Row >
                                        <Col lg={2}>
                                            <Field
                                                id="title"
                                                name="title"
                                                type="text"
                                                label="Title"
                                                component={FormikSelectField}
                                                value={values.title}
                                                inputprops={{
                                                    options: titles,
                                                    defaultOption: "Select",
                                                    keys: {
                                                        id: "value",
                                                        label: "label",
                                                    },
                                                }}
                                            />
                                        </Col>
                                        <Col lg={4}>
                                            <Field
                                                id="firstName"
                                                name="firstName"
                                                type="text"
                                                component={FormikInputField}
                                                label="First Name"
                                                placeholder="Your first name"
                                                autoComplete="firstName"
                                            />
                                        </Col>
                                        <Col lg={3}>
                                            <Field
                                                id="middleName"
                                                name="middleName"
                                                type="text"
                                                component={FormikInputField}
                                                label="Middle Name"
                                                placeholder="Your middle name"
                                                autoComplete="middleName"
                                            />
                                        </Col>
                                        <Col lg={3}>
                                            <Field
                                                id="lastName"
                                                name="lastName"
                                                type="text"
                                                component={FormikInputField}
                                                label="Last Name"
                                                placeholder="Your last name"
                                                autoComplete="lastName"
                                            />
                                        </Col>
                                        <Col lg={6}>
                                            <Field
                                                id="dateOfBirth"
                                                name="dateOfBirth"
                                                type="text"
                                                component={FormikDatePicker}
                                                inputprops={{
                                                    dateFormat: "yyyy-MM-dd",
                                                    showYearDropdown: true,
                                                    showMonthDropdown: true,
                                                    scrollableYearDropdown: true,
                                                    yearDropdownItemNumber: 10,
                                                    maxDate: new Date(),
                                                    autoComplete: "off",
                                                }}
                                                label="Date of Birth"
                                                placeholder="YYYY-MM-DD"
                                            />
                                        </Col>
                                        <Col lg={6}>
                                            <Field
                                                id="gender"
                                                name="gender"
                                                type="text"
                                                label="Gender"
                                                value={gender}
                                                component={FormikSelectField}
                                                inputprops={{
                                                    name: `duration`,
                                                    options: genders,
                                                    defaultOption: "Select",
                                                    keys: {
                                                        id: "value",
                                                        label: "label",
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col lg={4}>
                                            <div className="form-group">
                                                <Field
                                                    name="height"
                                                    id="height"
                                                    type="text"
                                                    label="Height(in CM)"
                                                    // selected={maritalStatus}
                                                    component={FormikInputField}
                                                    placeholder="Enter Height(in CM)"
                                                    value={values.height}
                                                    spellCheck={false}
                                                    autoComplete="height"
                                                />
                                            </div>
                                        </Col><Col lg={4}>
                                            <div className="form-group">
                                                <Field
                                                    name="weight"
                                                    id="weight"
                                                    type="text"
                                                    label="Weight(in KG)"
                                                    // selected={maritalStatus}
                                                    component={FormikInputField}
                                                    placeholder="Enter Weight(In KG)"
                                                    value={values.weight}
                                                    spellCheck={false}
                                                    autoComplete="weight"
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <Field
                                                id="bloodGroup"
                                                name="bloodGroup"
                                                type="text"
                                                label="Blood Group"
                                                value={bloodGroup}
                                                component={FormikSelectField}
                                                inputprops={{
                                                    name: `bloodGroup`,
                                                    options: bloodGroups,
                                                    defaultOption: "Select",
                                                    keys: {
                                                        id: "value",
                                                        label: "label",
                                                    },
                                                }}
                                            />
                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col lg={12}>
                                            <div className="form-group">
                                                <Field
                                                    id="languages"
                                                    name="languages"
                                                    label="Language Spoken"
                                                    className="language-suggest"
                                                    type="text"
                                                    component={CommonMultipleSelectTypeaheadField}
                                                    placeholder="Select Language"
                                                    spellCheck={false}
                                                    inputOptions={languagesList}
                                                    multiple={true}
                                                    autoComplete="languages"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </fieldset>
                                <fieldset className=" mb-3">
                                    {/** <legend>Mailing Address</legend>*/}
                                    <Row >
                                        <Col lg={12}>
                                            <Field
                                                id="streetAddress1"
                                                name="streetAddress1"
                                                type="text"
                                                component={FormikInputField}
                                                label="Street Address"
                                                placeholder="Current address"
                                                autoComplete="streetAddress1"
                                            />
                                        </Col>
                                        <Col lg={12}>
                                            <Field
                                                id="streetAddress2"
                                                name="streetAddress2"
                                                type="text"
                                                component={FormikInputField}
                                                label="Street Address 2"
                                                placeholder="Other Address"
                                                autoComplete="streetAddress2"
                                            />
                                        </Col>
                                        <Col lg={6}>
                                            <div className="form-group">
                                                <Field
                                                    id="country"
                                                    name="country"
                                                    type="text"
                                                    component={AddressTypeaheadField}
                                                    label="Country"
                                                    value={values.country}
                                                    selected={"test"}
                                                    placeholder="Select country"
                                                    spellCheck={false}
                                                    inputList={countryList}
                                                    getAddressById={(contryId) => getStates(contryId)}
                                                    locationRef={countryRef}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="form-group">
                                                <Field
                                                    id="state"
                                                    name="state"
                                                    type="text"
                                                    component={AddressTypeaheadField}
                                                    label="State"
                                                    value={values.state}
                                                    placeholder="Select state"
                                                    spellCheck={false}
                                                    inputList={statesList}
                                                    getAddressById={(stateId) => getCities(stateId)}
                                                    locationRef={stateRef}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="form-group">
                                                <Field
                                                    id="city"
                                                    name="city"
                                                    type="text"
                                                    component={AddressTypeaheadField}
                                                    label="City"
                                                    value={values.city}
                                                    placeholder="Select city"
                                                    spellCheck={false}
                                                    inputList={cityList}
                                                    getAddressById={(cityId) => getLocations(cityId)}
                                                    locationRef={cityRef}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <Field
                                                id="zipCode"
                                                name="zipCode"
                                                type="text"
                                                component={FormikInputField}
                                                label="Zip Code"
                                                placeholder="Enter code"
                                                autoComplete="zipCode"
                                            />
                                        </Col>
                                        {/* <Col lg={4}>
                                            <Field
                                                id="location"
                                                name="location"
                                                type="text"
                                                component={LocationTypeaheadField}
                                                label="Location"
                                                value={values.location}
                                                placeholder="Select Location"
                                                spellCheck={false}
                                                locations={locationList}
                                                selectdLocation={selectdLocation}
                                                locationRef={locRef}
                                            />
                                        </Col> */}
                                        <Col md={6}>
                                            <Field
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                type="text"
                                                component={FormikInputField}
                                                label="Phone Number"
                                                placeholder="Your phone number"
                                                autoComplete="phoneNumber"
                                                disabled={vmode === "PHONE" ? true : false}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Field
                                                id="email"
                                                name="email"
                                                type="text"
                                                component={FormikInputField}
                                                label="Email"
                                                placeholder="Your email"
                                                autoComplete="email"
                                                disabled={vmode === "EMAIL" ? true : false}
                                            />
                                        </Col>
                                    </Row>
                                </fieldset>

                            </Col>
                        </Row>

                        <div className=" modal-footer mx-0 px-0 pb-0">
                            <div className="form-row action-group d-flex flex-wrap justify-content-end w-100 m-0">
                                {isSubmitting ? (
                                    <div className="loading-spiner">
                                        <Spinner />
                                    </div>
                                ) : (
                                    <>
                                        <Button id="rpf_btn_submit" className="modal-save-btn nexogic_primary_button" color="primary " type="submit">
                                            Save
                                        </Button>
                                        {/* <Button id="rpf_btn_cancel"
                                            className="modal-cancel-btn nexogic_primary_button_outline "
                                            color="primary "
                                            type="button"
                                        // onClick={() => toggle()}
                                        >
                                            Cancel
                                        </Button> */}
                                    </>
                                )}
                            </div>
                        </div>
                    </form>
            }
        </>
    );
};
