import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap'
import { PatientService } from 'utils/PatientService';

export default function PatientHealth(props) {
  const { patient } = props;
  const [health, setHealth] = useState({});

  const getPatientHealth = (id) => {
    PatientService.getPatientHealth(id)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          // console.log("data", data);
          setHealth(data);
        } else {
          alert('Something went wrong!');
        }
      })
  }

  useEffect(() => {
    getPatientHealth(patient?.id);
  }, [patient])


  return (
    <div>
      {/*<h1 className='nexogic-page-title'>Health </h1>*/}
      <Card className='nex-list-card-v2'>
        <CardBody>
          <div className="nexogic-key-val-column">
            <Row>
              <Col lg={6} >
                <p className='nex-text bold text-black'>Do you have any Allergy ?</p>
                <p className='nex-text'>{(health && health?.allergies && health?.allergies.length > 0) ?
                  <>
                    {health?.allergies.map((allergy, index) => (
                      <div key={index}>
                        {allergy}
                        {`${(index + 1) < health?.allergies.length ? ', ' : ""}`}
                      </div>
                    ))}
                  </>
                  :
                  "No"
                }</p>
              </Col>
              <Col lg={6} >
                <p className='nex-text bold text-black'>Do you have any Chronic\Disease ?</p>
                <p className='nex-text'>
                {(health && health?.chronicDiseases && health?.chronicDiseases.length > 0) ?
                  <>
                    {health?.chronicDiseases.map((chronicDisease, index) => (
                      <div key={index}>
                        {chronicDisease}
                        {`${(index + 1) < health?.chronicDiseases.length ? ', ' : ""}`}
                      </div>
                    ))}
                  </>
                  :
                  "No"
                }
                </p>
              </Col>
              <Col lg={6} >
                <p className='nex-text bold text-black'>Do you have any Injury ?</p>
                <p className='nex-text'>
                {(health && health?.injuries && health?.injuries.length > 0) ?
                  <>
                    {health?.injuries.map((injury, index) => (
                      <div key={index}>
                        {injury}
                        {`${(index + 1) < health?.injuries.length ? ', ' : ""}`}
                      </div>
                    ))}
                  </>
                  :
                  "No"
                }
                </p>
              </Col>
              {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
              <Col lg={6} >
                <p className='nex-text bold text-black'>Current Medication(s)</p>
                <p className='nex-text'>
                {(health && health?.medications && health?.medications.length > 0) ?
                    <>
                      {health?.medications.map((medication, index) => (
                        <div key={index}>
                          {medication?.name}{"("}{medication?.description}{")"}
                          {`${(index + 1) < health?.medications.length ? ', ' : ""}`}
                        </div>
                      ))}
                    </>
                    :
                    "No"
                  }
                </p>
              </Col>
              }
            </Row>
            
          </div>
        </CardBody>
      </Card>
    </div>
  )
}
