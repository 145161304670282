import React, { useState, useEffect } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { MetadataService } from 'utils/MetadataService';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useRef } from 'react';

const JobQualificationEditModal = (props) => {
    const qualificationsRef = useRef(null);
    const [qualifications, setQualifications] = useState([]);
    const [qualificationInputValue, setQualificationInputValue] = useState([]);
    const { modal, setModal } = props;
    const { values, setFieldValue } = useFormikContext();
    const [degreeList, setDegreeList] = useState([]);
    const [selectedQualifications, setSelectedQualifications] = useState(values.qualifications || []);

    const onQualificationChangeHandler = (selected) => {
        const newQualifications = selected.map(item => item.customOption ? item.name : item);
        const updatedQualifications = [...qualifications, ...newQualifications.filter(qualification => !qualifications.includes(qualification))];
        setQualifications(updatedQualifications);
        setFieldValue('qualifications', updatedQualifications);
        setQualificationInputValue([]); // Clear the input value
    };

    const removeQualification = (qualificationToRemove) => {
        const updatedQualifications = qualifications.filter(qualification => qualification !== qualificationToRemove);
        setQualifications(updatedQualifications);
        setFieldValue('qualifications', updatedQualifications);
    };

    useEffect(() => {
        const getDegrees = () => {
            MetadataService.getDegree()
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        setDegreeList(response.data);
                    }
                })
                .catch((error) => {
                    console.error("MetadataService.getDegree error", error);
                });
        };

        getDegrees();
    }, []);

    const handleSubmit = () => {
        setFieldValue("qualifications", qualifications, true);
        setModal(false); // Close the modal
    };

    return (
        <Modal isOpen={modal} toggle={() => setModal(!modal)} className="nex-model-v2 modal-dialog-scrollable modal-dialog-centered">
            <ModalHeader toggle={() => setModal(!modal)}>Edit Qualifications</ModalHeader>
            <ModalBody>
                <h2 className='nex-text-md'>Multiple Qualification</h2>
                <div className="form-group gap-md">
                    <Typeahead
                        ref={qualificationsRef}
                        clearButton
                        newSelectionPrefix="Add Qualification: "
                        selected={qualificationInputValue}
                        id="qualifications"
                        multiple
                        options={degreeList}
                        labelKey={"name"}
                        placeholder="Add your qualifications"
                        onChange={onQualificationChangeHandler}
                    />
                </div>
                <>
                    {(qualifications && qualifications.length) ? <h2 className='nex-text-md'>Selected Qualifications</h2> : ""}
                    <div className="form-group gap-md">
                        <div className="nex-pill-group nex-selected-pill-group">
                            {(qualifications && qualifications.length > 0)
                                && qualifications.map((e, index) => (
                                    <span key={index} className='nex-pill nex-pill-primary'>{e}
                                        <span
                                            className="times"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => removeQualification(e)}
                                        >
                                            &times;
                                        </span>
                                    </span>
                                ))
                            }
                        </div>
                    </div>
                </>
                <div className="form-group nex-action-flex">
                <button className="btn nex-btn-primary ml-auto" onClick={handleSubmit}>Save</button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default JobQualificationEditModal;
