import React from 'react'
import { useState } from 'react';
import { Collapse } from 'reactstrap'

export default function Payment(props) {
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenBilling, setIsOpenBilling] = useState(false);
  const [isOpenConfi, setIsOpenConfi] = useState(false);
  return (
    <div className='nexogic-so-payment'>
      <div className="payment-info-box card">
          <div className="total-payment">
              <h3 className='card-title'>Total Payment</h3>
              <p className='payment-price'>$100</p>
          </div>
          <div className="payment-info">
            <p className='card-title'>Second Opinion</p>
            <p className='card-text'>Allergy and Immunology Second Opinion - Full Chart Review</p>
          </div>
      </div>
      <div className="nexogic-payment-step card">
        <div className="card-header">
          <h3 className="card-title big" onClick={()=>setIsOpen(!isOpen)}><span className='nexogic-num-bullet'>1</span> Select payment method <span className={`nexogic-arrow-down ${isOpen ? 'up':''}`}></span></h3>
          {isOpen && <p className='card-text text-muted'>Lorem Ipsum is simply dummy text of the printing</p>}
        </div>
        <Collapse isOpen={isOpen}>
        <div className="card-body">
          <div className="form-check">
            <input className="form-check-input" type="radio" name="sq-payment-type" id="sq-payment-type-dc" checked />
            <label className="form-check-label" for="sq-payment-type-dc">
              Debit/credit card
            </label>
          </div>
          <div className='col-lg-6'>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="" className='form-label'>Card number</label>
                  <input type="text" name="sq-card-number" id="sq-card-number" className='form-control' placeholder='XXXX - XXXX - XXXX - XXXX' />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="" className='form-label'>Name on card</label>
                  <input type="text" name="sq-card-name" id="sq-card-name" className='form-control' placeholder='E.g. John Doe' />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="" className='form-label'>Expiry date</label>
                  <input type="text" name="sq-card-expiry" id="sq-card-expiry" className='form-control' placeholder='MM/YY' />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="" className='form-label'>CVV</label>
                  <input type="text" name="sq-card-cvv" id="sq-card-cvv" className='form-control' placeholder='CVV' />
                </div>
              </div>
            </div>
              
          </div>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="sq-payment-type" id="sq-payment-type-upi"  />
            <label className="form-check-label" for="sq-payment-type-upi">
              UPI
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="sq-payment-type" id="sq-payment-type-pay"  />
            <label className="form-check-label" for="sq-payment-type-pay">
              <span className='icon'><svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="20"
                fill="none"
                viewBox="0 0 18 20"
              >
                <path
                  fill="#000"
                  d="M12.849 0h.142c.114 1.41-.424 2.464-1.078 3.228-.642.757-1.521 1.493-2.943 1.381-.095-1.39.444-2.366 1.098-3.127C10.674.772 11.785.14 12.849 0zM17.153 14.68v.04c-.4 1.21-.97 2.247-1.665 3.21-.635.874-1.413 2.05-2.803 2.05-1.2 0-1.998-.772-3.228-.793-1.302-.021-2.017.645-3.207.813h-.406c-.874-.127-1.58-.819-2.093-1.442-1.515-1.843-2.686-4.223-2.903-7.268v-.895c.092-2.18 1.151-3.952 2.559-4.811.743-.457 1.764-.846 2.902-.672.487.075.985.242 1.421.407.414.16.931.441 1.421.426.332-.01.663-.182.997-.304.98-.354 1.941-.76 3.207-.57 1.522.23 2.603.907 3.27 1.95-1.287.82-2.305 2.054-2.131 4.163.154 1.915 1.268 3.036 2.659 3.697z"
                ></path>
              </svg></span> Pay
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4"  />
            <label className="form-check-label" for="flexRadioDefault4">
              <span className='icon'><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.7898 15.8451C13.2289 17.1635 11.2039 18.0002 8.99961 18.0002C5.71953 18.0002 2.8402 16.1932 1.2793 13.5564L1.86366 10.8627L4.45391 10.3818C5.05508 12.3224 6.8691 13.7463 8.99961 13.7463C10.0332 13.7463 10.993 13.4193 11.784 12.8393L14.273 13.2189L14.7898 15.8451Z" fill="#59C36A" />
                <path d="M14.7902 15.8447L14.2734 13.2186L11.7844 12.8389C10.9934 13.4189 10.0336 13.7459 9 13.7459V17.9998C11.2043 17.9998 13.2293 17.1631 14.7902 15.8447Z" fill="#00A66C" />
                <path d="M4.2539 9.0001C4.2539 9.48522 4.32773 9.94928 4.4543 10.3817L1.27969 13.5563C0.488672 12.2274 0 10.6665 0 9.0001C0 7.33365 0.488672 5.77275 1.27969 4.44385L3.82757 4.88235L4.4543 7.61846C4.32773 8.05084 4.2539 8.5149 4.2539 9.0001Z" fill="#FFDA2D" />
                <path d="M18 9C18 11.7422 16.7309 14.1996 14.7902 15.8449L11.7844 12.8391C12.3961 12.3961 12.9129 11.8054 13.2504 11.1094H9C8.70465 11.1094 8.47266 10.8773 8.47266 10.582V7.41797C8.47266 7.12262 8.70465 6.89062 9 6.89062H17.325C17.5781 6.89062 17.7996 7.06989 17.8418 7.32301C17.9473 7.87148 18 8.44098 18 9Z" fill="#4086F4" />
                <path d="M13.2504 11.1094C12.9129 11.8054 12.3961 12.3961 11.7844 12.8391L14.7902 15.8449C16.7309 14.1996 18 11.7422 18 9C18 8.44098 17.9473 7.87148 17.8418 7.32301C17.7996 7.06989 17.5781 6.89062 17.325 6.89062H9V11.1094H13.2504Z" fill="#4175DF" />
                <path d="M14.9375 2.51363C14.948 2.66129 14.8848 2.79843 14.7898 2.90387L12.5328 5.15035C12.3536 5.34019 12.0582 5.36129 11.8473 5.20308C11.014 4.58085 10.0332 4.2539 8.99961 4.2539C6.86911 4.2539 5.05508 5.67769 4.45391 7.61835L1.2793 4.44374C2.8402 1.80703 5.71953 0 8.99961 0C11.0984 0 13.1445 0.773437 14.7477 2.1234C14.8637 2.21836 14.927 2.36598 14.9375 2.51363Z" fill="#FF641A" />
                <path d="M11.8477 5.20309C12.0586 5.36133 12.3539 5.3402 12.5332 5.15036L14.7902 2.90387C14.8852 2.79844 14.9484 2.66133 14.9379 2.51364C14.9273 2.36595 14.864 2.21836 14.748 2.1234C13.1449 0.773438 11.0988 0 9 0V4.25391C10.0336 4.25391 11.0144 4.58086 11.8477 5.20309Z" fill="#F03800" />
              </svg>
              </span>   Pay
            </label>
          </div>
          <p className='card-text'>Payment amount: <span className='highlight'>$100</span></p>
        </div>
        </Collapse>
      </div>


      <div className='nexogic-payment-step card'>
      <div className="card-header">
          <h3 className="card-title big" onClick={()=>setIsOpenBilling(!isOpenBilling)}><span className='nexogic-num-bullet'>2</span> Billing Address <span className='nexogic-arrow-down'></span></h3>
          {isOpenBilling && <p className='card-text text-muted'>Lorem Ipsum is simply dummy text of the printing</p>}
        </div>
        <Collapse isOpen={isOpenBilling}>
          <div className="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                      <label htmlFor="" className='form-label'>First Name</label>
                      <input type="text" name="so-billing-f-name" className='form-control' />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                      <label htmlFor="" className='form-label'>Last Name</label>
                      <input type="text" name="so-billing-l-name" className='form-control' />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                      <label htmlFor="" className='form-label'>Email</label>
                      <input type="text" name="so-billing-email" className='form-control' />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                      <label htmlFor="" className='form-label'>Phone Number</label>
                      <input type="text" name="so-billing-phone" className='form-control' />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                      <label htmlFor="" className='form-label'>Country</label>
                      <select name="so-billing-country" id="" className='form-control'>
                        <option value="">India</option>
                      </select>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                      <label htmlFor="" className='form-label'>Address</label>
                      <textarea type="text" name="so-billing-address" className='form-control'></textarea>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                      <label htmlFor="" className='form-label'>Pincode</label>
                      <input type="text" name="so-billing-Pincode" className='form-control' />
                  </div>
                </div>
              </div>

          </div>
        </Collapse>
      </div>


      <div className='nexogic-payment-step card'>
      <div className="card-header">
          <h3 className="card-title big" onClick={()=>setIsOpenConfi(!isOpenConfi)}><span className='nexogic-num-bullet'>3</span> Confirmation <span className='nexogic-arrow-down'></span></h3>
          {isOpenConfi && <p className='card-text text-muted '>Lorem Ipsum is simply dummy text of the printing</p>}
        </div>
        <Collapse isOpen={isOpenConfi}>
            <div className="nexogogic-checkout-table">
                    <div className="tr tbl-head">
                      <div className="td">Name</div>
                      <div className="td">Price</div>
                      <div className="td">Quantity</div>
                      <div className="td">Subtotal</div>
                    </div>
                    <div className="tr tbl-body">
                      <div className="td">Some item here</div>
                      <div className="td">INR 1000</div>
                      <div className="td">2 <span className='cross'>&times;</span></div>
                      <div className="td">INR 2000</div>
                    </div>
                    <div className="tr tbl-body">
                      <div className="td">Some item here 2</div>
                      <div className="td">INR 1000</div>
                      <div className="td">2 <span className='cross'>&times;</span></div>
                      <div className="td">INR 2000</div>
                    </div>
                    <div className="tr tbl-body">
                      <div className="td">Some item here 3</div>
                      <div className="td">INR 1000</div>
                      <div className="td">2 <span className='cross'>&times;</span></div>
                      <div className="td">INR 2000</div>
                    </div>
                    <div className="tr tbl-body tbl-subtotal">
                      <div className="td"></div>
                      <div className="td"></div>
                      <div className="td"><b>Subtotal :</b> </div>
                      <div className="td">INR 10000</div>
                    </div>
                    <div className="tr tbl-body tbl-total">
                      <div className="td"></div>
                      <div className="td"></div>
                      <div className="td"><b>Total</b> : </div>
                      <div className="td">INR 10000</div>
                    </div>
                    <div className="tr tbl-body tbl-total">
                      <div className="td"></div>
                      <div className="td"></div>
                      <div className="td"><b>Tax</b> : </div>
                      <div className="td">INR 0.00</div>
                    </div>
                    
            </div>
            <div className="nexogogic-checkout-other-info">
            <div className="row  ">
              <div className="col-lg-6">
                  <div className="card">
                  <h3 className='card-title mb-3'>Cradit Card Payment</h3>
                  <p className='card-text'>Name of card : Virat Kohili</p>
                  <p className='card-text'>card Number : 00000000000000</p>
                  </div>
              </div>
              <div className="col-lg-6">
                <div className="card">
                  <h3 className='card-title mb-3'>Billing Address</h3>
                  <p className='card-text'><b>51, 51,gundopanthstblr-53, Gundopanth Street</b></p>
                  <p className='card-text'>Bangalore, Karnataka, 560053</p>
                  <p className='card-text'>22914206</p>
                  </div>
              </div>
            </div>
            </div>
            <div className="col-lg-12">
          <div className="form-group nexogic-action-groups">
                         
            <input type="button" value="Submit" name='so-payment-submit' className='nexogic_primary_button' />         
          </div>
      </div>
        </Collapse>
      </div>

      <div className="row">
      <div className="col-lg-12">
          <div className="form-group nexogic-action-groups">
            <input type="button" value="Back" name='so-payment-back' className='nexogic_primary_button_outline' onClick={(e)=>{e.preventDefault(); props.backClick()}} />              
                   
          </div>
      </div>
      </div>

    </div>
  )
}

