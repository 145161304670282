import { faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommonLoader from "common/CommonLoader";
import CityAutocompleteTypeaheadField from "components/forms/formikFields/CityAutocompleteTypeaheadField";
import JobInstitutesTypeaheadField from "components/forms/formikFields/JobInstitutesTypeaheadField";
import LocationTypeaheadField from "components/forms/formikFields/LocationTypeaheadField";
import PrimarySpecialityTypeaheadField from "components/forms/formikFields/PrimarySpecialityTypeaheadField";
import { Field, Form } from "formik";
import RenderAboutForm from "pages/Profile/components/DoctorsDetails/components/About/components/RenderAboutForm";
import { memo, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import {
  JOB_PositionTypesList,
  JOB_Work_Shift_TypesList,
  Opportunity_Type,
} from "utils/Constants";
import { MetadataService } from "utils/MetadataService";
import CommonMultipleSelectTypeaheadField from "../../../../../components/forms/formikFields/CommonMultipleSelectTypeaheadField";
import FormikInputField from "../../../../../components/forms/formikFields/FormikInputField";
import FormikSelectField from "../../../../../components/forms/formikFields/FormikSelectField";

const CreateJobForm = (props) => {
  const {
    toggle,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    isLoading,
    touched,
  } = props;
  const { primarySpeciality, city, id } = values;
  const textInput = useRef();
  const locRef = useRef();
  const [locationList, setLocationList] = useState([]);
  const [primarySpecialityList, setPrimarySpecialityList] = useState([]);
  const [address, setAddress] = useState({});
  const [allskillsList, setallskillsList] = useState([]);
  const [degreeList, setdegreeList] = useState([]);

  const getprimarySpeciality = () => {
    MetadataService.getPrimarySpecialities()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setPrimarySpecialityList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getprimarySpeciality error", error);
      });
  };

  const getAllSkillSet = (primarySpecialityItem) => {
    MetadataService.getSpecialitySkillSet(primarySpecialityItem)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setallskillsList(response.data);
        }
      })
      .catch((error) => {
        console.log("MetadataService.getSpecialitySkillSet error", error);
      });
  };

  const handleChange = (html) => {
    setFieldValue("description", html, true);
  };

  const selectdLocation = (locationName) => {
    setAddress({
      ...address,
      location: locationName,
    });
  };

  const setDefaultAddress = (cityId) => {
    if (cityId) {
      setLocationList([]);
      new Promise(function (resolve, reject) {
        MetadataService.getCities(cityId).then((response) => {
          if (response.status === 200 || response.status === 201) {
            resolve(response.data);
          }
        });
      });
    }
  };

  useEffect(() => {
    MetadataService.getDegree()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setdegreeList(response.data);
        }
      })
      .catch((error) => {
        console.log("MetadataService.getDegree error", error);
      });
  }, []);

  useEffect(() => {
    if (primarySpeciality.length > 0 && primarySpeciality[0]) {
      getAllSkillSet(primarySpeciality[0]);
    } else {
      setFieldValue(`skills`, []);
    }
  }, [primarySpeciality, setFieldValue]);

  useEffect(() => {
    if ((city && city.length === 0) || city[0]?.id === "") {
      setFieldValue(`location`, []);
    } else {
      if (city[0]?.id !== "") {
        MetadataService.getLocations(city[0]?.id).then((response) => {
          if (response.status === 200 || response.status === 201) {
            setLocationList(response.data);
          }
        });
      }
    }
  }, [city, setFieldValue]);

  useEffect(() => {
    setDefaultAddress("");
    getprimarySpeciality();
  }, []);

  useEffect(() => {
    if (city && city.length > 0 && id) {
      const query = city[0];
      MetadataService.getCitiesWithoutStateId(query)
        .then((response) => {
          if (
            response.status === 200 &&
            response.data &&
            response.data.length > 0
          ) {
            response.data.forEach((obj) => {
              if (obj?.cityName.toLowerCase() === query.toLowerCase()) {
                setFieldValue("city", [
                  {
                    id: obj?.cityId,
                    value: obj?.cityName,
                  },
                ]);
              }
            });
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <div className="create-common">
        <Row>
          <Col md={12}>
            <div className="form-group">
              <Field
                name="institutionDetail"
                id="institutionDetail"
                autoComplete="institutionDetail"
                type="text"
                label="Company/Organization name"
                component={JobInstitutesTypeaheadField}
                placeholder="Company/Organization name"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="form-group">
              <Field
                id="cjf_opportunity"
                name="opportunity"
                type="text"
                label="Opportunity Type"
                component={FormikSelectField}
                inputprops={{
                  name: `opportunity`,
                  options: Opportunity_Type,
                  defaultOption: "Select",
                  keys: {
                    id: "value",
                    label: "label",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="form-group">
              <Field
                id="cjf_position"
                name="position"
                type="text"
                label="Position"
                component={FormikSelectField}
                inputprops={{
                  name: `position`,
                  options: JOB_PositionTypesList,
                  defaultOption: "Select",
                  keys: {
                    id: "value",
                    label: "label",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="form-group">
              <Field
                id="cjf_title"
                name="title"
                type="text"
                component={FormikInputField}
                placeholder="Job Title"
                label="Job Title"
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="form-group">
              <Field
                id="qualifications"
                name="qualifications"
                label="Qualification"
                className="language-suggest"
                type="text"
                component={CommonMultipleSelectTypeaheadField}
                placeholder="Select Qualification"
                spellCheck={false}
                inputOptions={degreeList}
                multiple={true}
                autoComplete="qualifications"
              />
            </div>
          </Col>
          <Col md={12}>
            <FormGroup>
              <Label for="Company">Description</Label>
              <ReactQuill
                id="cjf_description"
                onChange={handleChange}
                ref={textInput}
                value={values.description || ""}
                bounds={".app"}
                modules={RenderAboutForm.modules}
                formats={RenderAboutForm.formats}
              />
              {!!touched &&
                !!touched?.description &&
                !!errors &&
                !!errors?.description &&
                typeof errors.description === "string" && (
                  <FormFeedback
                    className="d-flex"
                    invalid={!!errors.description}
                  >
                    {errors.description}
                  </FormFeedback>
                )}
            </FormGroup>
          </Col>
          <Col md={12}>
            <div className="form-group" id="nex-cjf-primary-speciality">
              <Field
                id="primarySpeciality"
                name="primarySpeciality"
                autoComplete="primarySpeciality"
                label="Primary Speciality"
                className="primary-specialities"
                type="text"
                component={PrimarySpecialityTypeaheadField}
                placeholder="Select Speciality"
                inputprops={{
                  autoComplete: "off",
                }}
                value={values.primarySpeciality}
                spellCheck={false}
                specialit={primarySpecialityList}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="form-group" id="nex-cjf-skills">
              <Field
                id="skills"
                name="skills"
                label="Required Skills"
                type="text"
                component={CommonMultipleSelectTypeaheadField}
                placeholder="Select Required Skills"
                spellCheck={false}
                inputOptions={allskillsList}
                multiple={true}
                autoComplete="skills"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="form-group">
              <Field
                id="cjf_shift"
                name="shift"
                type="text"
                label="Work Shift"
                component={FormikSelectField}
                inputprops={{
                  name: `shift`,
                  options: JOB_Work_Shift_TypesList,
                  defaultOption: "Select",
                  keys: {
                    id: "value",
                    label: "label",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="form-group">
              <Field
                id="cjf_role"
                name="role"
                type="text"
                component={FormikInputField}
                placeholder="Role"
                label="Role"
                autoComplete="role"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="form-group" id="nex-cjf-city">
              <Field
                name="city"
                id="city"
                autocomplete="city"
                type="text"
                label="City"
                component={CityAutocompleteTypeaheadField}
                placeholder="City"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="form-group" id="nex-cjf-location">
              <Field
                id="location"
                name="location"
                type="text"
                component={LocationTypeaheadField}
                label="Location"
                value={values.location}
                placeholder="Select Location"
                spellCheck={false}
                locations={locationList}
                selectdLocation={selectdLocation}
                locationRef={locRef}
              />
            </div>
          </Col>
          <Col md={12}>
            <Field
              id="cjf_linkToApply"
              name="linkToApply"
              type="text"
              component={FormikInputField}
              placeholder="Link to apply"
              label="Link to apply"
            />
          </Col>
          <Col md={6}>
            <Field
              id="minExperience"
              name="minExperience"
              type="text"
              component={FormikInputField}
              placeholder="Min Experience"
              label="Min Experience ( Years)"
            />
          </Col>
          <Col md={6}>
            <Field
              id="maxExperience"
              name="maxExperience"
              type="text"
              component={FormikInputField}
              placeholder="Max Experience"
              label="Max Experience ( Years)"
            />
          </Col>
          <Col md={6}>
            <FormGroup>
              <label> Min Salary ( INR) </label>
              <InputGroup className="money-prefix" id="cjf_min_salary">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="bg-white text-muted">
                    <FontAwesomeIcon icon={faRupeeSign} />
                  </InputGroupText>
                </InputGroupAddon>
                <Field
                  id="minSalary"
                  name="minSalary"
                  type="text"
                  component={FormikInputField}
                  placeholder="Min Salary"
                  // label="Min Salary ( INR)"
                />
              </InputGroup>
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <label> Max Salary ( INR) </label>
              <InputGroup className="money-prefix" id="cjf_max_salary">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="bg-white text-muted">
                    <FontAwesomeIcon icon={faRupeeSign} />
                  </InputGroupText>
                </InputGroupAddon>
                <Field
                  id="maxSalary"
                  name="maxSalary"
                  type="text"
                  component={FormikInputField}
                  placeholder="Max Salary"
                  // label="Max Salary ( INR)"
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={12}>
            {isLoading ? (
              <div className="loading-spiner text-center">
                <CommonLoader />
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-center mt-2">
                <Button
                  id="cjf_cancel"
                  onClick={toggle}
                  type="button"
                  color="danger"
                  className="cancel-btn"
                >
                  Cancel
                </Button>
                <Button id="cjf_save" color="primary" className="post-btn ml-3" type="submit">
                  Save
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

CreateJobForm.defaultProps = {
  toggle: () => {},
};
export default memo(CreateJobForm);
