import React, { useState, useRef,useMemo } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import "./style.css";
import { useEffect } from 'react';

const ToolTip = ({ id, place, tooltipText, children ,targetRef}) => {
  const [isOpen, setOpen] = useState(false);
  const triggerRef = useRef(null);

  const { getTooltipProps,setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: 'hover',
    placement: 'top',
  });

  const handleToggle = () => {
    setOpen(!isOpen);
  };

  const tooltipRef = useRef('');

  useEffect(()=>{
    if(visible){
    setTimeout(()=>{
      tooltipRef.current?.classList?.add('on');
    }, 100)
  }
  else{
    tooltipRef.current?.classList?.remove('on');
  }
  // return ()=>{
  //   tooltipRef.current?.classList?.remove('on');
  // }
  }, [visible])

  return  useMemo(() => (
    <>
    <div className='nexogic-tooltip-w' ref={tooltipRef}>
     <span className="tooltip_icon" 
     id={id} 
    ref={(node) => {
      setTriggerRef(node);
      triggerRef.current = node;
    }}
    onClick={handleToggle} 
    >      
     </span>
      {isOpen && visible && (
        <div class="tooltip_box">
        <div
          {...getTooltipProps({
            ref: (node) => {
              targetRef.current = node;
              setTooltipRef(node);
            },
            // className: 'tooltip_box',
            className: 'msg-box tooltip_box--bottom',
          })}
        >
          {/* <div {...getArrowProps({ className: 'tooltip_arrow' })} /> */}
          {children}
        </div> 
        </div>       
      )}
      </div>
    </>
 ), [id, tooltipText, children, targetRef, isOpen, visible]);
};

export default ToolTip;