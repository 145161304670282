import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';

export default function ReferSearchPractitioner(props) {
    const { searchdata, selectedOption, setSelectedOption, referral } = props;
    const currentUser = useSelector((state) => state.auth.currentUser);

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    const [options, setOptions] = useState([]);

    useEffect(() => {
        const updatedOptions = searchdata.map((item) => ({
            value: item.profileGuid,
            label: item.name,
        }));
        setOptions(updatedOptions);
    }, [searchdata]);

    return (
        <div>
            <Select
                value={selectedOption}
                onChange={handleSelectChange}
                options={options}
                isSearchable={true}
                placeholder="Select a practitioner"
            />
        </div>
    );
}
