import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import './style.scss'

export default function WidgetTwo() {
  return (
    <Card className='micro-widget-v2'>
        <CardBody className='micro-widget-title-sec'>
          <Row>
            <Col lg={11} className="">
                <h3 className='micro-widget-title'>Jobs</h3>
            </Col>
          </Row>
        </CardBody>
        <CardBody>
            <Row className='micro-icon-lists'>
              <Col lg={'auto'} className='micro-widget-graphic'>
                <img src='https://picsum.photos/id/64/200/300' className="card-widget-img"></img>
              </Col>
              <Col className='micro-widget-content'>
                <h3 className='card-title'>Job Title</h3>
                <p className='card-text'>Required Skills</p>
                <p className='card-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero doloribus placeat architecto, cum distinctio repudiandae officia accusamus blanditiis dolore quae alias, mollitia fugit! Ea, recusandae!</p>
              </Col>
            </Row>
            {/* <Row className='micro-icon-lists'>
              <Col lg={'auto'} className='micro-widget-graphic'>
                <img src='https://picsum.photos/id/64/200/300' className="card-widget-img"></img>
              </Col>
              <Col className='micro-widget-content'>
                <h3 className='card-title'>Job Title</h3>
                <p className='card-text'>Required Skills</p>
                <p className='card-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero doloribus placeat architecto, cum distinctio repudiandae officia accusamus blanditiis dolore quae alias, mollitia fugit! Ea, recusandae!</p>
              </Col>
            </Row>
            <Row className='micro-icon-lists'>
              <Col lg={'auto'} className='micro-widget-graphic'>
                <img src='https://picsum.photos/id/64/200/300' className="card-widget-img"></img>
              </Col>
              <Col className='micro-widget-content'>
                <h3 className='card-title'>Job Title</h3>
                <p className='card-text'>Required Skills</p>
                <p className='card-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero doloribus placeat architecto, cum distinctio repudiandae officia accusamus blanditiis dolore quae alias, mollitia fugit! Ea, recusandae!</p>
              </Col>
            </Row>
            <Row className='micro-icon-lists'>
              <Col lg={'auto'} className='micro-widget-graphic'>
                <img src='https://picsum.photos/id/64/200/300' className="card-widget-img"></img>
              </Col>
              <Col className='micro-widget-content'>
                <h3 className='card-title'>Job Title</h3>
                <p className='card-text'>Required Skills</p>
                <p className='card-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero doloribus placeat architecto, cum distinctio repudiandae officia accusamus blanditiis dolore quae alias, mollitia fugit! Ea, recusandae!</p>
              </Col>
            </Row> */}         
        </CardBody>
    </Card>
  )
}
