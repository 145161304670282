import React from 'react'
import HeaderOne from './HeaderOne'
import HeaderTwo from './HeaderTwo'

const Headers = () => {
  return (
    <div>
      <HeaderOne />
      <HeaderTwo />
    </div>
  )
}

export default Headers