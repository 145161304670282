import React, { useEffect } from 'react'
import { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import ReferPractitionerSearchField from '../Practitioner/ReferPractitionerSearchField';
import { useSelector } from 'react-redux';
import { FAILTOSAVERECOED } from 'utils/Constants';
import { ReferralService } from 'utils/ReferralService';
import { isInstitutionAdmin } from 'utils/UserHelper';
import swal from "sweetalert";
import { withAlert } from 'react-alert';
import { PractitionerService } from 'utils/PractitionerService';
import { showErrorMessage, showSuccessMessage } from 'common/AlertContainer';

export default withAlert()(function ReferAssignModal(props) {
  const { referral, getReferralById, getAssignmentHistory, alert } = props;
  const [selectedOption, setSelectedOption] = useState(null);
  const [practitioners, setPractitioners] = useState([]);

  const getAllPractitioner = (guid) => {
    PractitionerService.getPractitionersAssociatedtoInstitutionWithPagination(guid)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          // Check if referral?.referredToPhysician?.profileGuid is present
          if (referral?.referredToPhysician?.profileGuid) {
            // Use filter to exclude records where profileGuid matches the condition
            const filteredPractitioners = data.contents.filter((practitioner) => practitioner.profileGuid !== referral.referredToPhysician.profileGuid);
            setPractitioners(filteredPractitioners);
          } else {
            // If referral?.referredToPhysician?.profileGuid is not present, set the data as is
            setPractitioners(data.contents);
          }
        } else {
          console.log("Something went wrong");
        }
      });
  }


  useEffect(() => {
    getAllPractitioner(referral?.referredToInstitution?.guid)
  }, [referral])


  const confirmAndUpdateAssignee = () => {
    swal("Are you sure you want to change the Assignee?", {
      buttons: ["cancel", "yes"],
    })
      .then((yes) => {
        if (yes)
          updateReferralAssignee();
      })
  }


  const updateReferralAssignee = () => {
    const payload = {
      "referredToPhysician": {
        "profileGuid": selectedOption?.value
      }
    }

    ReferralService.updateReferral(referral?.id, payload, 'RECEIVED')
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          showSuccessMessage(`Referral Assignee updated Successfully.`);
          getReferralById(referral?.id);
          getAssignmentHistory(referral?.id);
          setSelectedOption(null);
        }
        else {
          showErrorMessage(FAILTOSAVERECOED);
        }
      })
  }



  return (
    <Modal
      isOpen={props.open}
      toggle={() => props.setToggle(!props.open)}
    >
      <ModalHeader toggle={() => props.setToggle()}>
        Assign To
      </ModalHeader>

      {practitioners && practitioners.length > 0 ?
        <ModalBody>
          <ReferPractitionerSearchField label="Select Practitioner" referral={referral}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            practitioners={practitioners}
          />

          <div className="form-group">
            <a href="" className="btn nexogic_primary_button" onClick={(e) => {
              e.preventDefault();
              //  props.onAssign(true)
              confirmAndUpdateAssignee();
            }}>
              Assign
            </a>
          </div>

        </ModalBody>
        :
        <div className='nexogic-nodata-dotted m-4'>
          <div className='no-suggestion-text text-center'>There are no more practitioners available to assign a referral.</div>
        </div>
      }
    </Modal>
  )
}
);