import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { isEmpty } from "lodash";
import { UserStatusConstants } from "utils/Constants";

import "../../../Header";
import { useEffect } from "react";

const InCompleteNotify = ({ currentUser }) => {
  const history = useHistory();
  const onHandleContinue = () => {
    history.push(`/register/onboarding`);
  };


  useEffect(() => {
    if(document.querySelectorAll('.profile-incomplete-notification').length > 0){
      document.body.classList.add('has-profile-incomplete-notification');
    }
    return () => {
      if(document.querySelectorAll('.profile-incomplete-notification').length > 0){
        document.body.classList.remove('has-profile-incomplete-notification');
      }
    }
}, [])


  return (
    <>
      {!isEmpty(currentUser) &&
        !history.location.pathname.includes("/register/onboarding") &&
        (currentUser?.status === UserStatusConstants.BASIC_INFO_PROVIDED ||
          currentUser?.status ===
            UserStatusConstants.REGISTRATION_INFO_PROVIDED ||
          currentUser?.status === UserStatusConstants.EDUCATION_INFO_PROVIDED ||
          currentUser?.status ===
            UserStatusConstants.AFFILIATIONS_INFO_PROVIDED ||
          currentUser?.status === UserStatusConstants.READY_TO_CLAIM) && (
          <div className="profile-incomplete-notification">
            <div className="container-dashboard container">
              <div className="d-flex align-items-center px-2">
                <span>
                  {" "}
                  Your access is currently limited. Complete your registration
                  to unlock all Nexogic features
                </span>
                <Button
                  className="nexogic_primary_button_outline ml-auto"
                  onClick={onHandleContinue}
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>
        )}
      {!isEmpty(currentUser) &&
        !history.location.pathname.includes("/register/onboarding") &&
        (currentUser?.status === UserStatusConstants.DOC_UPLOADED ||
          currentUser?.status === UserStatusConstants.REJECTED ||
          currentUser?.status === UserStatusConstants.CANCELED ||
          currentUser?.status === UserStatusConstants.PROFILE_CLAIMED) && (
          <div className="profile-incomplete-notification">
            <div className="container-dashboard container">
              <div className="d-flex align-items-center px-2">
                <span>
                  {" "}
                  Your access is currently limited, because your profile is not
                  approved
                </span>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default InCompleteNotify;
