import React from 'react'
import { Link } from "react-router-dom";
import { Card } from 'reactstrap';
import { useEffect } from 'react';
import { useState } from 'react';
import { PatientService } from 'utils/PatientService';
import { FAILED_TO_FETCH_DATA } from 'utils/Constants';
import { createImageFromInitials, getAge, getGenderDetails, getRandomColor } from 'utils/Utils';
import OpinionDocument from '../MyOpinionsList/OpinionDocument';
import moment from 'moment';
import { encrypt } from 'utils/EncryptDecrypt';

const OpinionInfo = ({ opinion }) => {
    const [patientInfo, setPatientInfo] = useState({});

    const getPatientInfo = () => {

        if (opinion?.requestedBy === opinion?.requestedFor) {
            PatientService.getPatientById(opinion?.requestedBy)
                .then((response) => {
                    const { status, data } = response;
                    if (status === 200) {
                        setPatientInfo(data);
                        // console.log(data);
                    } else {
                        console.log(FAILED_TO_FETCH_DATA);
                    }
                })
        } else {
            PatientService.getRelatedPersonById(opinion?.requestedBy, opinion?.requestedFor)
                .then((response) => {
                    const { status, data } = response;
                    if (status === 200) {
                        setPatientInfo(data);
                        // console.log(data);
                    } else {
                        console.log(FAILED_TO_FETCH_DATA);
                    }
                })
        }
    }

    useEffect(() => {
        // console.log(opinion);
        getPatientInfo();
    }, [opinion?.id])


    return (
        <Card className="nex-list-card-v2 nexogic-so-list-card">
            <div className="card-body">
                <div className="nex-stack wrap nex-so-stack">
                    <div className="nex-avatar-info nex-flex lg">
                        <div className="graphic">
                            {patientInfo && patientInfo.avatarId ? (
                                <img
                                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${patientInfo?.profileGuid}/${patientInfo?.avatarId}`}
                                    className="nex-avatar lg"
                                    alt="#"
                                />
                            ) : (
                                <img
                                    src={createImageFromInitials(
                                        50,
                                        `${patientInfo?.firstName}${" "}${patientInfo?.lastName}`,
                                        getRandomColor(encrypt(patientInfo?.firstName?.profileGuid).toString())
                                    )}
                                    className="nex-avatar lg"
                                    alt="#"
                                />
                            )}
                        </div>
                        <div className="info w-full">
                            <h2 className='nex-text-xl stack-title text-truncate' title={`${patientInfo?.firstName} ${patientInfo?.lastName}`}>{patientInfo?.firstName}{" "}{patientInfo?.lastName}</h2>
                            <p className='nex-text-lg bold nex-text-blue text-truncate'>{opinion?.speciality ? opinion?.speciality : "N/A"}</p>
                            <p className='nex-text text-black'>Phone : 093 31 062133</p>
                        </div>
                    </div>
                    <div className="info md">
                        <p className='nex-text text-black'>Age : {getAge(patientInfo?.dateOfBirth)}</p>
                        <p className='nex-text text-black'>Gender : {getGenderDetails(patientInfo?.gender)}</p>
                        <p className={`nex-pill sm bold ${opinion?.status.toLowerCase()}`}>{opinion?.status}</p>
                    </div>
                    <div className="info md">
                        <p className='nex-text text-black'><span className='icon icon-g-r'><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 8.25H3M15 8.25V14.25C15 15.0784 14.3284 15.75 13.5 15.75H4.5C3.67157 15.75 3 15.0784 3 14.25V5.25C3 4.42157 3.67157 3.75 4.5 3.75H13.5C14.3284 3.75 15 4.42157 15 5.25V8.25ZM11.25 2.25V5.25M6.75 2.25V5.25" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        </span>Date : {moment(opinion?.createdOn).format("MMMM DD, YYYY")}</p>
                        <p className='nex-text text-black'><OpinionDocument id={'so_document_1'} opinionId={opinion.id} /></p>
                    </div>
                    <div className="action ml-auto my-auto">
                        <Link to={`/u/medicalcases/received-opinions/${opinion?.id}`} className="btn nex-btn-primary">More Details</Link>
                    </div>
                    {opinion.description &&
                    <div className="nex-description w-full">
                        <h3 className='nex-text-lg bold text-black'>Description</h3>

                        <div className='nex-description-content' dangerouslySetInnerHTML={{ __html: opinion.description }}></div>
                    </div>
                }
                </div>
                

                {/** old part start */}
                <div className="row need-margin d-none">
                    <div className="col-5 patient-info">
                        <h3 className="card-title big hide-on-app">Patient Info</h3>
                        <div className="head">
                            <div className="card-graphic"><img src="https://images-dev.nexogic.com/53efb6fd-1ceb-412a-b19d-b65032bde722/a8dd998a-8e20-4ac4-971f-bf91154aa6fc.png" alt="" className="card-img" /></div>
                            <div className="info">
                                <p className="card-text nex-so-title"><b className='hide-on-app'>Name  </b>  <a href="#"> {patientInfo?.firstName}{" "}{patientInfo?.lastName}</a></p>
                                <p className="card-text"><b className='hide-on-app'>Phone  </b> 093 31 062133</p>
                            </div>
                        </div>
                    </div>
                    <div className="col patient-info">
                        <h3 className=" card-title big hide-on-app">&nbsp;</h3>
                        <div className="head">
                            <div className="info inline-flex-on-app">
                                <p className="card-text"><b className='hide-on-app'>Age  </b> {getAge(patientInfo?.dateOfBirth)}</p>
                                <p className="card-text"><b className='hide-on-app'>Gender </b> {getGenderDetails(patientInfo?.gender)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col patient-info col-xl col-lg-12 hide-on-app">
                        <h3 className=" card-title big d-none d-xl-block">&nbsp;</h3>
                        <div className="head">
                            <div className="info">
                                <p className="card-text"><b>Specialty  </b>  {opinion?.speciality ? opinion?.speciality : "N/A"}</p>
                            </div>
                        </div>
                    </div>
                    <div className="status-col position-top"><span className="status-pill pending">{opinion?.status}</span></div>

                </div>
                <div className="row need-margin reason-sec d-none">
                    <div className="col-lg-12">
                        <hr />
                        <h3 className="card-title"> Description:</h3>
                        <div className="card-text">
                            <p dangerouslySetInnerHTML={{ __html: opinion.description }}></p>
                        </div>
                        {/* link class : nexogic_link */}
                        <div className="card-text d-flex"><div className="mr-auto"><Link to={`/u/medicalcases/received-opinions/${opinion?.id}`} className="nexogic_primary_button_outline ">More Details</Link></div></div>
                    </div>
                </div>

                <div className="nexogic-meta-description d-none">
                    <div className="row need-margin">
                        <div className="col"><p className="card-text"><span className="icon"><svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 8V17C1 19.2091 2.79086 21 5 21H15C17.2091 21 19 19.2091 19 17V8M1 8V6.5C1 4.29086 2.79086 2.5 5 2.5H15C17.2091 2.5 19 4.29086 19 6.5V8M1 8H19M14 1V4M6 1V4" stroke="#5EA9C6" strokeWidth="1.5" strokeLinecap="round"></path></svg></span>  <b>Date  </b>  22-03-2023</p></div>
                        {/* <div className="col"><SoComment id={'so_connemt_1'} /></div> */}
                        <div className="col-auto"><OpinionDocument id={'so_document_1'} opinionId={opinion.id} /></div>
                    </div>
                </div>
                {/** old part end */}
            </div>
        </Card>
    )
};

export default OpinionInfo;