import { withAlert } from "react-alert";
import radio_tick from "assets/images/nex-completed-tick.png";
import profile_image from 'assets/images/profile-image.png'
import { useFormikContext } from "formik";

const PersonalDetailsForm1 = ({ currentUser, currentStep, setCurrentStep, isSelf, setIsSelf }) => {
    const { setFieldValue } = useFormikContext();

    return (
        <>
            <div className="form-step">
                <div className="nex-form-group-c mt-10">
                    <h2 className='nex-text-xxl mb-5'>{`${false ? "Update" : "Create"} Second Opinion Request for`}</h2>
                    <div className="nex-radio-boxes">
                        <div className="nex-radio-box">
                            <input type="radio" name="radAvailTheme" id="customtheme" checked={isSelf === true}
                                onClick={() => {
                                    setIsSelf(true);
                                    setFieldValue("requestedFor", currentUser);
                                }}
                            />
                            <div className="radio-backgdrops"></div>
                            <span className='radio-check'>
                                <img src={radio_tick} alt="" />
                            </span>
                            <div className="graphic">
                                <img src={profile_image} width={68} alt="" />
                            </div>

                            <div className="info">
                                <div className="nex-text-xl small label">Yourself</div>
                                <div className="nex-text">You can take a second opinion for yourself only.</div>
                            </div>

                        </div>
                        <div className="nex-radio-box ">
                            <input type="radio" name="radAvailTheme" id="themedefault" checked={isSelf === false}
                                onClick={() => {
                                    setIsSelf(false);
                                }}

                            />
                            <div className="radio-backgdrops"></div>
                            <span className='radio-check'>
                                <img src={radio_tick} alt="" />
                            </span>
                            <div className="graphic">
                                <img src={profile_image} width={68} alt="" />
                            </div>

                            <div className="info">
                                <div className="nex-text-xl small label">Someone Else</div>
                                <div className="nex-text">You can take a second opinion for your family members too.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default withAlert()(PersonalDetailsForm1);