import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import { Field } from "formik";
import React from "react";
import { Button, Col, Input, Label, Row } from "reactstrap";

const renderType = [
  {
    label: "Number",
    value: "NUMBER",
  },
  {
    label: "Yes/No",
    value: "SELECT",
  },
  {
    label: "Text",
    value: "TEXT",
  }
];

export const CustomQuestions = (props) => {
  const { index, values,touched,errors,setErrors,arrayHelpers, setFieldValue,handleChange,addOwnQuestion,setCQuestions,setQFlag } = props;
  return (   
    <div className="screening-question">
    <div className="screen-qhead">
      <div className="q-txt">Type your own Question</div>
      <Button className="close-icon p-0" color="link" type="button"  
      onClick={()=>{
        if(values.cquestions !== undefined){
      values.cquestions.question=''
      values.cquestions.renderType=''
      values.cquestions.options=[]
    }
      setQFlag(false);
      setCQuestions(false);
      }}
      >           
        <svg name={`btnQadd${index}`} id={`btnQadd${index}`} xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="#047883" className="bi bi-x-lg" viewBox="0 0 16 16" >
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </Button>
    </div>
    <div className="screen-body">        
    {touched && errors?.cquestions && (
              <span style={{fontSize:"14px"}}><RenderErrorMessage
                errorMsg={errors.cquestions}
              /></span>
          )}
  <Row>
    <Col md={12} className="d-flex align-items-start">  
      <Field
        id={`cquestions.question`}
        name={`cquestions.question`}
        type="textarea"
        rows={2}
        cols={5}
        component={FormikInputField} 
        placeholder="Type your Question here..."
      />
      </Col>
      </Row>
      <Row>
      <Col md="3" className="d-flex align-items-start">
        <Label>Answer Type</Label>
      </Col>
      <Col md="5" className="d-flex align-items-start">
      <Field
        id={`cquestions.renderType`}
        name={`cquestions.renderType`}
        type="text"
        component={FormikSelectField} 
        onChange={event => {
          handleChange(event)
          if(event.target.value==="SELECT"){
            setFieldValue(`cquestions.options`,["Yes","No"]);
           }else{
            setFieldValue(`cquestions.options`,[]);
           }
          }}
        inputprops={{
          name: `cquestions.renderType`,
          defaultOption: "Select",
          options: renderType,
          keys: {
            id: "value",
            label: "label",
          },
        }}
      />
    </Col>
    <Col md={4} className="d-flex align-items-start text-align-center">
    <Button id="btn_btnAdd" name="btnAdd" onClick={(evt)=>{
      let queItem = {question:values?.cquestions?.question,renderType:values?.cquestions?.renderType,options:values?.cquestions?.options}
      
      if(queItem?.question==='' || queItem?.question===undefined){
        setErrors({'cquestions':'Please type your Question'});
        return false;
      }
      if(queItem?.renderType==='' || queItem?.renderType===undefined){
        setErrors({'cquestions':'Please select Answer Type'});
        return false;
      }
      addOwnQuestion(queItem,arrayHelpers,evt)
      values.cquestions.question=''
      values.cquestions.renderType=''
      values.cquestions.options=[]
      setCQuestions(false);
      }}>Add Question</Button>
    </Col>       
  </Row>
  </div>
  </div>
);
};
