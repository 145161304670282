import React from 'react'
import Slider from "react-slick";
import './style.scss';

export default function FullSlider() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
  return (
    <div className='micro-slider-v1 nex-full-page-slider'>
        <Slider {...settings}>
            <div className="slider-item">
                <img src="https://picsum.photos/id/202/600/200" alt="" className='slider-img' />
                <div className="caption">
                                  <h3 className="card-title">Lorem ipsum dolor sit amet.</h3>
                                  <p className='card-text'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. At voluptate modi tenetur, doloremque explicabo corporis.</p>
                                  <p><a href="" className='nexogic_primary_button btn btn-link'>Know More</a></p>
                              </div>
            </div>
            <div className="slider-item">
                <img src="https://picsum.photos/id/203/600/200" alt="" className='slider-img' />
                <div className="caption">
                                  <h3 className="card-title">Lorem ipsum dolor sit amet.</h3>
                                  <p className='card-text'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. At voluptate modi tenetur, doloremque explicabo corporis.</p>
                                  <p><a href="" className='nexogic_primary_button btn btn-link'>Know More</a></p>
                              </div>
            </div>
            <div className="slider-item">
                <img src="https://picsum.photos/id/204/600/200" alt="" className='slider-img' />
                <div className="caption">
                                  <h3 className="card-title">Lorem ipsum dolor sit amet.</h3>
                                  <p className='card-text'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. At voluptate modi tenetur, doloremque explicabo corporis.</p>
                                  <p><a href="" className='nexogic_primary_button btn btn-link'>Know More</a></p>
                              </div>
            </div>
        </Slider>
    </div>
  )
}
