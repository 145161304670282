import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PractitionerForm from "../PractitionerForm";

const PractitionerFormModal = (props) => {
    const { modal, setModal } = props;

    return (
        <Modal isOpen={modal} toggle={() => setModal(!modal)} className="nex-model-v2 modal-dialog-scrollable modal-dialog-centered">
            <ModalHeader toggle={() => setModal(!modal)}>Edit Medical History</ModalHeader>
            <ModalBody>
                <PractitionerForm />
            </ModalBody>
        </Modal>
    )
}

export default PractitionerFormModal;