import logo_blue from "assets/images/login-provider.png";
import google_img from "assets/images/social-google-img.png";
import facebook_img from "assets/images/facebook-social-img.png";
import upload_icon from "assets/images/upload_icon.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";

const NewRegisterStepThree = (props) => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <div className="nex-bg-light-blue">
            <main className="nex-login-register--w">
                <div className="container d-flex">
                    <div className="nex-form-fields--w nex-form-fields--w-v3">
                        <div className="nex-form-aside-v2" data-aos="fade-up">
                                <div className="logo text-center space-bottom-lg">
                                    <a href="/"><img src={logo_blue} alt="" width={110} /></a>
                                </div>
                            </div>
                        <div className="nex-form-center-slot mt-0 ">
                            
                            <ul className="nex-form-steps-lists" data-aos="fade-up" data-aos-duration="1200">
                                <li className="completed"><a href=""><div className="nex-indicator"></div> Personal Details</a></li>
                                <li className="completed "><a href=""><div className="nex-indicator"></div> Medical Registration</a></li>
                                <li className="current"><a href=""><div className="nex-indicator"></div> Upload Documents</a></li>
                            </ul>
                            <div className="nex-form-container">
                                <div className="nex-form-section" data-aos="fade-up" data-aos-duration="1400" data-aos-delay="300">
                                <div className="space-bottom-lg">
                                    <h2 className="nex-text-xxl text-center text-dark mb-2">Add Documents</h2>
                                    <p className="text-center text-gray">Provide your government issued ID and medical registration for verification of your identity</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label className="nex-text-lg" htmlFor="">Document Type</label>
                                            <select className="form-control">
                                                <option>Government issued photo ID</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label className="nex-text-lg" htmlFor="">Document Name</label>
                                            <input type="text" name="" id="" className="form-control" placeholder="Document Name" value={''} />
                                        </div>
                                    </div>
                                </div>
                                
                                

                                <div className="form-group">
                                    <div className="nex-upload--w">
                                        <div className="graphic"><img src={upload_icon} alt="" width={50} /></div>
                                        <p>Please upload a document for verification in ‘.pdf’,’ jpeg’, ‘png’ format</p>
                                        <p><a href="#">Select file</a> or drag and drop here.</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label className="nex-text-lg" htmlFor="">Document Type</label>
                                            <select className="form-control">
                                                <option>Government issued photo ID</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label className="nex-text-lg" htmlFor="">Document Name</label>
                                            <input type="text" name="" id="" className="form-control" placeholder="Document Name" value={''} />
                                        </div>
                                    </div>
                                </div>
                                
                                

                                <div className="form-group">
                                    <div className="nex-upload--w">
                                        <div className="graphic"><img src={upload_icon} alt="" width={50} /></div>
                                        <p>Please upload a document for verification in ‘.pdf’,’ jpeg’, ‘png’ format</p>
                                        <p><a href="#">Select file</a> or drag and drop here.</p>
                                    </div>
                                    <p className="nex-text-lg text-right"><a href="#">+ Add More Document</a></p>
                                </div>
                                </div>
                                
                                
                                <div className="form-group">
                                    <input type="submit" className="btn btn-primary w-100 nex-max-sm mx-auto mb-3" value="Submit" />
                                    <input type="submit" className="btn btn-transparent w-100 nex-max-sm mx-auto" value="Skip & Continue" />
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </main>
        </div>
    )
}

export default  NewRegisterStepThree;