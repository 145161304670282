import { useEffect, useState } from "react";
import { Card, CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import { MedialService } from "utils/MediaService";
import CommonLoader from "common/CommonLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useRef } from "react";
import { FAILED_TO_FETCH_DATA, FAILTOSAVERECOED } from "utils/Constants";
import { withAlert } from "react-alert";
import { isAdmin, isPractitioner } from "utils/UserHelper";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { toast } from "react-toastify";

const ResponseDocs = ({ opinionId, alert }) => {
    const [docs, setDocs] = useState([]);
    const [documentType, setDocumentType] = useState(null);
    const [documentData, setDocumentData] = useState(null);
    const [dataLoading, setDataLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const toggleForm = () => setModal(!modal);
    const [isFileUploaing, setIsFileUploaing] = useState(false);
    const fileInputRef = useRef(null);

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            // Perform any necessary validation or processing of the selected file here
            setIsFileUploaing(true);
            // Create a FormData object to send the file data
            const formData = new FormData();
            formData.append('files', selectedFile);

            // Submit the file to the API using your preferred method (e.g., fetch, axios)
            MedialService.uploadMedia("SECOND_OPINION_RESPONSE", opinionId, formData)
                .then((response) => {
                    const { status } = response;
                    if (status === 200) {
                        showSuccessMessage("Document uploaded Successfully");
                        getResponseDoc(opinionId);
                        setIsFileUploaing(false);
                    } else if (status === 409) {
                        toast.info("Can't upload the document with the same name again");
                        setIsFileUploaing(false);
                    }
                    else {
                        showErrorMessage(FAILTOSAVERECOED);
                        setIsFileUploaing(false);
                    }
                })
        }
    };

    const getResponseDoc = async (id) => {
        try {
            const { data, headers } = await MedialService.getMediaFile(id);
            const createContentType = headers["content-type"] === "application/octet-stream"
                ? "application/pdf"
                : headers["content-type"];
            let generateURL = `data:${createContentType};base64,${Buffer.from(data).toString("base64")}`;
            setDocumentData(generateURL);
            let docArray = [data, createContentType];
            setDocumentType(docArray);
            if (createContentType === "application/pdf") {
                onOpenBase64(generateURL);
            }
            else {
                toggleForm();
            }
        } catch (error) {
            console.error("Error retrieving response document:", error);
        }
    };

    const onOpenBase64 = (base64URL) => {
        // const onOpenBase64 = () => {
        try {
            var win = window.open();
            win.document.write(`
            <iframe class="nexogic-iframe" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" 
              src="${base64URL}" frameborder="0" allowfullscreen></iframe>
          `);
        } catch (error) {
            console.error("Error opening base64 URL:", error);
            // Handle error, show error message, etc.
        }
    };


    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = documentData;
        let ext = documentType[1].split('/').pop();
        link.download = 'reports.' + ext;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
    };

    const getAllDocs = (opinionId) => {
        MedialService.getMedias("SECOND_OPINION_RESPONSE", opinionId)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    // console.log(data);
                    setDocs(data);
                }
                else {
                    console.error(FAILED_TO_FETCH_DATA);
                }
            })
    }

    useEffect(() => {
        getAllDocs(opinionId);
    }, [opinionId])

    return (
        <>
            <h2 className="nex-aside-title">
                Documents
                <span className="icon ml-auto"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 13H14M8 17H16M13 3H5V21H19V9M13 3H14L19 8V9M13 3V7C13 8 14 9 15 9H19" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>
            </h2>
            <div className="collapse-div nexogic-so-documents">
                <CardBody>
                    {(docs && docs.length > 0) ?
                        <div className="nexogic-custom-scroll">
                            <div className="nexogic-document-lists">
                                <ul>
                                    {docs.map((doc, index) => (
                                        <li key={index} >
                                            <div className="nexogic-document-item" style={{ cursor: "pointer" }} onClick={() => getResponseDoc(doc?.id)}>
                                            <svg className='icon'
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="26"
                                                        height="32"
                                                        fill="none"
                                                        viewBox="0 0 26 32"
                                                    // onClick={() => getResponseDoc(doc?.id)}
                                                    >
                                                        <path
                                                            stroke="#3EABCA"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M16 1H4a3 3 0 00-3 3v24a3 3 0 003 3h18a3 3 0 003-3V10m-9-9l9 9m-9-9v9h9m-6 7.5H7m12 6H7m3-12H7"
                                                        >
                                                        </path>
                                                    </svg>
                                                <div className="card-text">{doc?.name}</div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                            </div>
                        </div>
                        :
                        <div className="nexogic-not-found-text">
                            <div className="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 13V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18L4 13M16 8L12 4M12 4L8 8M12 4L12 16" stroke="#46ABC9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></div>
                            No Documents</div>
                    }
                    <div className="action">
                    {(isPractitioner() || isAdmin()) &&
                    <>
                        {isFileUploaing ?
                            <div className="text-center mb-5" style={{ height: "10px" }} >
                                <CommonLoader />
                            </div>
                            :
                            <button
                                className="btn nexogic-download-btn nex-btn-primary"
                                onClick={handleUploadClick}
                            >
                                <span className="icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="none"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M15 10.333v3.111A1.556 1.556 0 0113.444 15H2.556A1.556 1.556 0 011 13.444v-3.11m10.889-5.445L8 1m0 0L4.111 4.889M8 1v9.333"
                                        ></path>
                                    </svg>
                                </span>
                                Uploads
                            </button>
                        }
                        <input
                            name="uploadDocument"
                            type="file"
                            id="uploadDocument"
                            accept="image/jpeg,image/jpg,image/png,application/pdf"
                            title="Upload Document"
                            className="nexogic-file-upload-input"
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        />
                    </>
                }
                    </div>
                </CardBody>
            </div>
            {
                dataLoading ?
                    <div className="" style={{ zIndex: "111", position: "absolute", top: "0", left: "0", backgroundColor: "#edeff0b0", width: "100%", height: "100%" }}>
                        <div className="component-wrapper"><CommonLoader /></div>
                    </div>
                    :
                    <Modal isOpen={modal} toggle={toggleForm} backdrop="static" scrollable centered >
                        <ModalHeader toggle={toggleForm}><span style={{ color: "gray" }}>Document Viewer</span></ModalHeader>
                        <ModalBody>
                            <div className="modalbody">
                                <img src={documentData} alt="document preview" />
                            </div>
                            <div className="downloadbtn">
                                <FontAwesomeIcon color="#0087b9" size="xl" style={{ cursor: "pointer" }} icon={faDownload} onClick={handleDownload} />
                            </div>
                        </ModalBody>
                    </Modal>
            }
        </>
    )
}

export default withAlert()(ResponseDocs)