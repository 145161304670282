import React from 'react'
import profile_sample from "assets/images/user-profile-sample.png";

export default function SearchPractitioner(props) {
    return (
        <div className="nexogic-practitioner-search-lists">
            {props.searchdata.map((item, ind)=>
                <div className="item" key={'search-practitioner-li-'+ind} onClick={()=>props.onSelect(item)}>
                    <div className="graphic">
                        <img src={profile_sample} alt="" className="avatar" />
                    </div>
                    <div className="info">
                        <h3 className="card-title">{item.name} {item.deg.length > 0 ? '('+item.deg+')' : ''}</h3>
                        <p className="card-text light">{item.hospital}</p>
                    </div>
                </div>
            )}
            
            
        </div>
    )
}
