import {
  faClock,
  faPlus,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import closeQuestion from "../../../../../assets/images/close-question.png";
import PostUploadDocument from "./PostUploadDocument";
const CreatePollForm = ({ toggle }) => {
  return (
    <Row>
      <Col md={12}>
        <FormGroup>
          <Label for="Title">Title</Label>
          <Input id="Title" name="text" type="text" placeholder="Title" />
        </FormGroup>
      </Col>
      <Col md={12}>
        <Input
          id="exampleText"
          name="text"
          type="textarea"
          placeholder="Start a discussion"
        />
      </Col>
      <Col md={12}>
        <Card className="createpoll-card mt-3">
          <CardBody className="pb-0">
            <div className="poll-header">
              <div className="left-part">
                <FontAwesomeIcon icon={faClock} />
                <Input
                  id="exampleSelect"
                  name="select"
                  type="select"
                  className="date-time-picker"
                >
                  <option>2 weeks</option>
                  <option>3 weeks</option>
                  <option>1 Month</option>
                </Input>
              </div>
              <div className="remove-poll">Remove poll</div>
            </div>
            <div className="question-plate">
              <div className="q-count-head">Question 1</div>
              <FormGroup>
                <Input
                  id="singleAnswer"
                  name="select"
                  type="select"
                  className="single-answer"
                >
                  <option>Single Answer</option>
                  <option>Test</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Input id="cpf-question" name="cpf-question" placeholder="Question" />
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <Input id="cpf-answer1" name="cpf-answer1" placeholder="Answer" />
                  <InputGroupText>
                    <img src={closeQuestion} alt="close Question" />
                  </InputGroupText>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <Input id="cpf-answer2" name="cpf-answer2" placeholder="Answer" />
                  <InputGroupText>
                    <img src={closeQuestion} alt="close Question" />
                  </InputGroupText>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <Input id="cpf-answer3" name="cpf-answer3" placeholder="Answer" />
                  <InputGroupText>
                    <img src={closeQuestion} alt="close Question" />
                  </InputGroupText>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <Input id="cpf-answer4" name="cpf-answer4"  placeholder="Answer" />
                  <InputGroupText>
                    <img src={closeQuestion} alt="close Question" />
                  </InputGroupText>
                </InputGroup>
              </FormGroup>
              <Button id="cpf-btn-add_answer"  className="add-more-ans">
                <FontAwesomeIcon icon={faPlusCircle} /> &nbsp; Add an answer
              </Button>
              <Button id="cpf-btn-add_question" outline color="info" className="add-more-question">
                <FontAwesomeIcon icon={faPlus} /> &nbsp; Add another question
              </Button>
              <PostUploadDocument />
              <div className="d-flex align-items-center justify-content-center">
                <Button id="cpf-btn-cancel"
                  onClick={toggle}
                  type="button"
                  color="danger"
                  className="cancel-btn"
                >
                  Cancel
                </Button>
                <Button id="cpf-btn-submit" color="primary" className="post-btn ml-3">
                  Save
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default CreatePollForm;
