import { decode } from "js-base64";
import jwtDecode from "jwt-decode";
import _ from "lodash";
import moment from "moment";
import { matchPath } from "react-router-dom";
import { FAILTOSAVERECOED, ProfileStatusConstants, UserStatusConstants } from "./Constants";

import wordFileIcon from "assets/images/svgs/word-file-icon.svg";
import pdfFileIcon from "assets/images/pdf-icon.png";
import imageFileIcon from "assets/images/image_icon.png";

export const plainPhoneNumber = (phn) => {
  return phn.replace("-", "").replace(" ", "");
};

export const cleanUpPhoneNumber = (phn) => {
  return phn.replace(/[^0-9+]/gi, "");
};

export const maskingPhoneNumber = (phoneNumber) => {
  const lastTenDigits = phoneNumber.slice(-10);
  const maskedDigits = lastTenDigits.slice(0, 6).replace(/./g, '*');
  const lastFourDigits = lastTenDigits.slice(-4);
  return maskedDigits + lastFourDigits;
}


export const cleanEmptyNodes = (obj) => {
  for (var propName in obj) {
    if (!!obj[propName] === false && typeof obj[propName] != "boolean") {
      delete obj[propName];
    }
  }
  return obj;
};

export const getRandomColor = (code) => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  if (code && code.length >= 6) {
    code = code.slice(0, 6).split("");
    for (var i = 0; i < 6; i++) {
      color += letters[code[i]];
    }
  } else {
    for (var j = 0; j < 6; j++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
  }
  return color;
};

const getInitials = (name) => {
  let initials;
  const nameSplit = name.split(" ");
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else return;

  return initials.toUpperCase();
};

export const createImageFromInitials = (size, name, color) => {
  if (name == null) return;
  name = getInitials(name);
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = canvas.height = size;

  context.fillStyle = "#ffffff";
  context.fillRect(0, 0, size, size);

  context.fillStyle = `${color}50`;
  context.fillRect(0, 0, size, size);

  context.fillStyle = color;
  context.textBaseline = "middle";
  context.textAlign = "center";
  context.font = `${size / 2}px Roboto`;
  context.fillText(name, size / 2, size / 2 + size * 0.06);

  return canvas.toDataURL();
};

export const validateImageType = (file) => {
  const validImageFileTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/bmp",
    "image/x-bmp",
  ];
  if (file.type) {
    let fileType = file.type;
    return validImageFileTypes.includes(fileType);
  } else {
    return false;
  }
};

export const validateImageSize = (file, maxSize) => {
  let res = -1;
  if (file.size > 0) {
    res = 0;
    const fileSize = file.size;
    if (fileSize <= maxSize) res = 1;
  }
  return res;
};

export const stripHtml = (html) => {
  // Create a new div element
  var temporalDivElement = document.createElement("div");
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html;
  // Retrieve the text property of the element (cross-browser support)
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
};

export const calculateExperienceYears = (expStartYr) => {
  let currentYear = new Date().getFullYear();
  if (moment(expStartYr).format("YYYY")) {
    expStartYr = parseInt(moment(expStartYr).format("YYYY"));
  } else {
    expStartYr = parseInt(expStartYr);
  }
  return currentYear - expStartYr;
};

export const calculateExperinceSpam = (startYr, startMon) => {
  const today = moment();
  const somedate = moment(`${startMon} ${startYr}`);
  const monthsDiff = today.diff(somedate, "months");
  if (monthsDiff > 0) {
    let year = Math.floor(monthsDiff / 12);
    let output = `Present • `;
    output += year > 1 ? `${year} years` : year === 1 ? `${year} year` : ``;
    output += monthsDiff % 12 > 0 ? ` ${monthsDiff % 12} month` : "";
    return output;
  }
};

export const getAgeFromDOB = (dob) => {
  if (!!dob === false) return 0;

  var a = moment();
  var b = moment(dob, "YYYY");
  var diff = a.diff(b, "years"); // calculates patient's age in years
  return diff;
};

export const getProfilePicUrl = (profilePicUrl) => {
  if (
    profilePicUrl != null &&
    !profilePicUrl.includes("https://") &&
    !profilePicUrl.includes("http://")
  ) {
    // profilePicUrl = process.env.REACT_APP_LB_API_ENDPOINT + "practitioner-service/getobject/" + profilePicUrl; // Old method(developed by Amar) to get image source
    try {
      let decoded = decode(profilePicUrl);
      profilePicUrl = decoded;
      // profilePicUrl = "https://s3.us-east-2.amazonaws.com/dev.nexogic.com/" + decoded;
    } catch (error) {
      console.log("error decodeding image clientID");
    }
  }
  return profilePicUrl;
};

export const getBannerUrl = (bannerUrl) => {
  if (
    bannerUrl != null &&
    !bannerUrl.includes("https://") &&
    !bannerUrl.includes("http://")
  ) {
    // bannerUrl = process.env.REACT_APP_LB_API_ENDPOINT + "practitioner-service/getobject/" + bannerUrl; // Old method(developed by Amar) to get image source
    try {
      let decoded = decode(bannerUrl);
      bannerUrl = decoded;
    } catch (error) {
      console.log("error decodeding image clientID");
    }
  }
  return bannerUrl;
};

export const getPath = (locPathName, matchPathName) => {
  const pathName = matchPath(locPathName, {
    path: matchPathName,
  });
  return !!pathName !== false ? pathName.path : "";
};

export const sortArrayByString = (arr, key) => {
  return arr.sort((a, b) => {
    let fa = a[key] && a[key].toLowerCase(),
      fb = b[key] && b[key].toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
};

export const sortByTime = (data) => {
  return data.sort((a, b) => {
    return (
      new Date("1970/01/01 " + a.startTime) -
      new Date("1970/01/01 " + b.startTime)
    );
  });
};

export const sortByDay = (data) => {
  const sorter = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  };
  return data.sort((a, b) => {
    let day1 = a.dayOfWeek.toLowerCase();
    let day2 = b.dayOfWeek.toLowerCase();
    return sorter[day1] - sorter[day2];
  });
};

// export const getGeoLocation = (address) => {
//   const { addressLine1, addressLine2, city, state } = address;
//   let country = address.country || "India";
//   let addressString = addressLine1 || "" + addressLine2 || "";
//   addressString += city ? `,${city}` : "";
//   addressString += state ? `,${state}` : "";

//   const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${addressString}&components=country:${country}&key=AIzaSyAYmMyjODE4s9BX37wAw7vResRh2ORv9CY`;

//   return new Promise((resolve, reject) => {
//     fetch(url)
//       .then((res) => res.json())
//       .then((response) => {
//         if (response.status === "OK") {
//           const result = response.results[0];
//           return resolve(result.geometry.location);
//         } else {
//           return reject(null);
//         }
//       });
//   });
// };

export const decodeFileUrl = (encodedUrl) => {
  let url = "";
  try {
    url = decode(encodedUrl);
  } catch (error) {
    console.log("error decodeding image clientID");
  }
  return url;
};

export const decodeJWT = (token) => {
  try {
    let decode = jwtDecode(token);
    return decode;
  } catch (error) {
    console.log("decodeJWT", error);
    return null;
  }
};

export const getLocationName = (value) => {
  try {
    if (value.length > 0) {
      if (value[0]?.customOption) {
        return value[0]?.value;
      }
      return value[0];
    }
    return "";
  } catch (error) {
    console.log("get Location Name", error);
    return "";
  }
};

export const getAddressName = (value) => {
  try {
    if (value.length > 0) {
      return value[0]?.value;
    }
    return "";
  } catch (error) {
    console.log("get Address Name", error);
    return "";
  }
};

export const getAddressID = (value) => {
  try {
    if (value.length > 0) {
      return value[0]?.id;
    }
    return "";
  } catch (error) {
    console.log("get Address ID", error);
    return "";
  }
};

export const getEmptySectionShowHide = (
  items,
  isAdmin,
  currentUser,
  globalProfileInfo,
  guid
) => {
  try {
    if (
      (items && items.length === 0 && !isAdmin && currentUser?.guid !== guid) ||
      // globalProfileInfo?.status === UserStatusConstants.DOC_UPLOADED ||
      globalProfileInfo?.status === UserStatusConstants.REJECTED ||
      globalProfileInfo?.status === UserStatusConstants.CANCELED
      // globalProfileInfo?.status === UserStatusConstants.PROFILE_CLAIMED
    ) {
      return true;
    }
    return false;
  } catch (error) {
    console.log("get Empty Section Show Hide", error);
    return "";
  }
};

export const getFullName = (node) => {
  let complateName = "";
  if (_.has(node, "name") && node?.name !== "") {
    complateName += node.name;
  }
  if (_.has(node, "abbreviation") && node?.abbreviation !== "") {
    complateName += " , " + node.abbreviation;
  }
  if (_.has(node, "location") && node?.location !== "") {
    if (node?.abbreviation && node?.abbreviation !== undefined) {
      complateName += node?.abbreviation !== "" ? " - " : " , ";
    } else {
      complateName += " - ";
    }
    complateName += node.location;
  }
  if (_.has(node, "city") && node?.city !== "") {
    if (
      node?.abbreviation &&
      node?.abbreviation !== undefined &&
      node?.location &&
      node?.location !== undefined
    ) {
      complateName += " - ";
    } else if (node?.abbreviation && node?.abbreviation !== undefined) {
      complateName += " - ";
    } else {
      complateName += node?.location === "" ? " - " : " , ";
    }
    complateName += node.city;
  }
  if (_.has(node, "state") && node?.state !== "") {
    complateName +=
      node.abbreviation === "" && node?.location === "" && node?.city === ""
        ? " - "
        : " , ";
    complateName += node.state;
  }
  return complateName.trim().slice(-1) === "-"
    ? complateName.trim().slice(0, -1)
    : complateName;
};

export const getFullAddress = (node) => {
  let val = "";
  if (node?.name) {
    val = getFullName({
      name: node?.name,
      abbreviation: node?.abbreviation ?? "",
      location: node?.location ?? "",
      city: node?.city ?? "",
      state: node?.state ?? "",
    });
  }
  return val;
};

export const calculateExperinceDiff = (startYr, startMon, presentText) => {
  try {
    const today = moment();
    const somedate = moment(`${startMon} ${startYr}`);
    const monthsDiff = today.diff(somedate, "months");
    if (monthsDiff > 0) {
      const sDate = `${startYr}/${startMon}/01`;
      const startText = moment(sDate).format("MMM YYYY");
      let year = Math.floor(monthsDiff / 12);
      let output = startText + " - " + presentText + ` • `;
      output += year > 1 ? `${year} years` : year === 1 ? `${year} year` : ``;
      output += monthsDiff % 12 > 0 ? ` ${monthsDiff % 12} month` : "";
      return output;
    }
  } catch (e) {
    console.log(e);
  }
};

export const formatBytes = (a, b = 2) => {
  if (!+a) return "0 Bytes";
  const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024));
  return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${["Bytes", "KB", "MB"][d]}`
}

export const dateFormat = (strDate) => {
  const varDate = moment(strDate).format("DD MMM YYYY H:mm a");
  return varDate;
};

export const renderDateRange = (
  startDate,
  endDate,
  status = false,
  presentText = "Present",
  showOnlyStartDate = false
) => {
  let val = "";
  if (startDate && startDate.indexOf("-") > -1) {
    const [fromMonth, fromYear] = startDate.split("-");
    if (status) {
      const somedateMonth = moment(fromMonth).format("MMMM");
      const getVal = calculateExperinceDiff(
        fromYear,
        somedateMonth,
        presentText
      );
      if (getVal) {
        val += getVal;
      }
    } else {
      if (endDate && endDate.indexOf("-") > -1) {
        const [toMonth, toYear] = endDate.split("-");
        const sDate = `${fromYear}/${fromMonth}/01`;
        const eDate = `${toYear}/${toMonth}/01`;
        const startText = moment(sDate).format("MMM YYYY");
        const endText = moment(eDate).format("MMM YYYY");
        if (showOnlyStartDate) {
          val = startText;
        } else {
          val = startText + " - " + endText;
        }
      } else {
        if (showOnlyStartDate) {
          const sDate = `${fromYear}/${fromMonth}/01`;
          const startText = moment(sDate).format("MMM YYYY");
          val = startText;
        } else {
          val = "";
        }
      }
      val += "";
    }
  }
  return val;
};

export const isOnlyApprovedAllowPathPractitioner = (status, mainPath) =>
  status ? mainPath : "#!";

export const makeFullNameWithAddress = (institution) => {
  let fullName = "";
  if (_.has(institution, "name") && institution?.name !== "") {
    fullName = institution.name;
  }

  if (_.has(institution, "abbreviation") && institution?.abbreviation !== "") {
    if (fullName !== "")
      fullName += ("-" + institution.abbreviation);
    else
      fullName = institution.abbreviation;
  }

  if (_.has(institution, "location") && institution?.location !== "") {
    if (fullName !== "")
      fullName += (", " + institution.location);
    else
      fullName = institution.location;
  }

  if (_.has(institution, "city") && institution?.city !== "") {
    if (fullName !== "")
      fullName += (", " + institution.city);
    else
      fullName = institution.city;
  }

  if (_.has(institution, "state") && institution?.state !== "") {
    if (fullName !== "")
      fullName += (", " + institution.state);
    else
      fullName = institution.state;
  }

  return fullName;
}

export const makeFullNameWithAddressWhenAddressIsNested = (institution) => {

  let fullName = "";
  if (_.has(institution, "name") && institution?.name !== "") {
    fullName = institution.name;
  }

  if (_.has(institution, "abbreviation") && institution?.abbreviation !== "") {
    if (fullName !== "")
      fullName += ("-" + institution.abbreviation);
    else
      fullName = institution.abbreviation;
  }

  if (_.has(institution, "address")) {
    let address = institution.address;

    if (_.has(address, "location") && address?.location !== "") {
      if (fullName !== "")
        fullName += (", " + address.location);
      else
        fullName = address.location;
    }

    if (_.has(address, "city") && address?.city !== "") {
      if (fullName !== "")
        fullName += (", " + address.city);
      else
        fullName = address.city;
    }


    if (_.has(address, "state") && address?.state !== "") {
      if (fullName !== "")
        fullName += (", " + address.state);
      else
        fullName = address.state;
    }

  }
  return fullName;
}

export const getFullAddressWithLocationCityAndState = (node) => {
  let val = "";
  if (_.has(node, "location") && node?.location !== "") {
    val += node.location;
  }
  if (_.has(node, "city") && node?.city !== "") {
    if (val != "") {
      val += ", " + node.city;
    } else {
      val = node.city;
    }
  }
  if (_.has(node, "state") && node?.state !== "") {
    if (val != "") {
      val += ", " + node.state;
    } else {
      val = node.state;
    }
  }
  return val;
};

export const dateDifferenceInYearsAndMonths = (startDate) => {
  const startMonth = startDate.split("-")[0];
  const startYear = startDate.split("-")[1];
  const date1 = new Date(`${startYear}` + ", " + `${startMonth}` + ", " + "1");
  const date2 = new Date();

  const diffInMilliseconds = date2.getTime() - date1.getTime();
  const totalMonths = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24 * 30));
  // const diffInYears = Math.floor(diffInMonths / 12);

  const diffInMonths = totalMonths % 12;
  const diffInYears = Math.floor(totalMonths / 12);

  if (diffInYears === 0 && diffInMonths === 0) {
    return "";
  }
  else if (diffInYears === 0 && diffInMonths !== 0) {
    return (diffInMonths + `${diffInMonths === 1 ? ' month' : ' months'}`);
  }
  else if (diffInYears !== 0 && diffInMonths === 0) {
    return (diffInYears + `${diffInYears === 1 ? ' year' : ' years'}`);
  }
  else {
    return (diffInYears + `${diffInYears === 1 ? ' year ' : ' years '}` + diffInMonths + `${diffInMonths === 1 ? ' month' : ' months'}`)
  }
}

export const dateDifferenceInYears = (startYear) => {
  const currentYear = new Date().getFullYear();
  if (currentYear - startYear === 1)
    return "1 year";
  else
    return (currentYear - startYear + " years");
}

export const convertToMMMYYYYFormat = (dateString) => {
  if (dateString && dateString.length === 7) {
    // Split the input string into month and year
    const parts = dateString.split("-");

    const month = parseInt(parts[0]);
    const year = parseInt(parts[1]);

    // Create a new date object using the year and month
    const date = new Date(year, month - 1);

    // Format the date object as a string in the desired format
    return date.toLocaleString("en-us", { month: "short", year: "numeric" });
  }
  return "";
};

export const removeComma = (str) => {
  str = str.trim()
  while (str.startsWith(',')) {
    str = str.substring(1);
  }
  while (str.endsWith(',')) {
    str = str.substring(0, str.length - 1);
  }
  return str;
}

export const getGenderDetails = (value) => {
  if (value === 'M') return 'Male'
  if (value === 'F') return 'Female'
  if (value === 'U') return 'Not Available'
}

export const capitalizeFirstLetter = (value) => {
  if (value === null) {
    return null;
  } else if (value === undefined) {
    return "";
  } else {
    value = value.toLowerCase();
    const firstLetter = value[0].toUpperCase();
    const restOfString = value.slice(1);
    return firstLetter + restOfString;
  }
};

//this functio return age in (y Years, m Months) format from date in (2023-05-14) format
export const getAge = (dateString) => {
  // Convert the date string to a Date object
  const birthDate = new Date(dateString);
  // Get the current date
  const currentDate = new Date();
  // Calculate the age in milliseconds
  let ageInMilliseconds = currentDate - birthDate;

  // Calculate the age in years, months, and days
  let ageDate = new Date(ageInMilliseconds);
  let years = ageDate.getUTCFullYear() - 1970; // Subtract 1970 to get the correct number of years
  let months = ageDate.getUTCMonth();
  let days = ageDate.getUTCDate() - 1; // Subtract 1 to get the correct number of days

  // Format the result based on the calculated age
  let result = "";

  if (years > 0) {
    result += years + (years === 1 ? " Year" : " Years");
  }

  if (months > 0) {
    if (result.length > 0) result += ", ";
    result += months + (months === 1 ? " Month" : " Months");
  }

  if (years === 0 && months === 0 && days > 0) {
    if (result.length > 0) result += ", ";
    result += days + (days === 1 ? " Day" : " Days");
  }

  if (result.length === 0) {
    result = "0 Days";
  }

  return result;
};


export const dateFormatter = (dateString) => {
  const serverDate = dateString;
  // const serverDateTime = new Date(serverDate);
  const dates = new Date(dateString);
  const timezoneOffsetMinutes = dates.getTimezoneOffset();
  const localDateTime = new Date(dates.getTime() - (timezoneOffsetMinutes * 60 * 1000));
  const formatDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  const localDateTimeStrings = formatDateTime(localDateTime);
  return localDateTimeStrings
};

export const dateFormatterNew = (dateString) => {
  const inputDate = new Date(dateFormatter(dateString));

  // Get the month abbreviation
  const monthAbbreviation = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(inputDate);

  // Get the day, year, and time components
  const day = inputDate.getDate();
  const year = inputDate.getFullYear();
  const hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();

  // Format the time with AM/PM
  const amPm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;

  // Create the final formatted date string
  const formattedDate = `${monthAbbreviation} ${day}, ${year} at ${formattedHours}:${minutes.toString().padStart(2, '0')} ${amPm}`;

  return formattedDate;
};



export const getFullNameWithPrimarySpecialityAndSuperSpeciality = (profile) => {
  if (!profile) {
    return '';
  }

  const { name, primarySpeciality, superSpeciality } = profile;

  if (!name) {
    return '';
  }

  let fullName = name;

  if (primarySpeciality) {
    fullName += `, ${primarySpeciality}`;

    if (superSpeciality) {
      fullName += `(${superSpeciality})`;
    }
  }

  return fullName;
}

export const getPosition = (positionType) => {
  if (positionType === "FULL_TIME")
    return "Full Time";
  else if (positionType === "PART_TIME")
    return "Part Time";
  else if (positionType === "FLEXIBLE_TIME")
    return "Flexible Time";
  else
    return "Consultant"
}

export const ShowExperience = ({ min, max }) => {
  if ((!min && !max) || min === '0' && max === '0')
    return (
      <span>Any Experience</span>
    )
  else if (min && !max) {
    return (
      <span>{min || 0} Years</span>
    );
  }
  else if (!min && max) {
    return (
      <span>{max || 0} Years</span>
    );
  }
  else {
    return (
      <span>{min || 0} -{" "}
        {max || 0} Years</span>
    );
  }
}

export const getMeaningFullErrorMessage = (errCode) => {
  switch (errCode) {
    case 'INVALID_ROLE':
      return "Invalid role: The selected role does not exist in the database.";

    case 'NO_ACTIVE_USER':
      return "No active user: The selected user is either not active or does not have a public profile.";

    case 'NOT_APPLICABLE_ROLE':
      return "Not applicable role: You are trying to assign a role that is not applicable to this user's profile.";

    case 'NOT_ENOUGH_PERMISSION':
      return "Not enough permission: You do not have the required permission to perform this action.";

    default:
      return FAILTOSAVERECOED;
  }
};

export const getFileIcon = (type) => {
  if (type === "application/pdf")
    return pdfFileIcon;
  else if (type === "image/jpeg" || type === "image/png")
    return imageFileIcon;
  else
    return wordFileIcon;
}

export const getFullNameOfPerson = (firstName, middleName, lastName) => {
  // Initialize an empty array to hold parts of the name
  const nameParts = [];

  // Check if firstName is not blank and add it to the array
  if (firstName) {
    nameParts.push(firstName);
  }

  // Check if middleName is not blank and add it to the array
  if (middleName) {
    nameParts.push(middleName);
  }

  // Check if lastName is not blank and add it to the array
  if (lastName) {
    nameParts.push(lastName);
  }

  // Join the name parts with a space and return the full name
  return nameParts.join(' ');
}

export const isEmptyOrNullOrObject = (value) => {
  if (value === null) {
    return true;
  }
  if (typeof value === 'object' && value.constructor === Object) {
    return Object.keys(value).length === 0;
  }
  return false;
}

export const getSalary = (minSal, maxSal) => {
  let salRange = `${minSal} LPA to ${maxSal} LPA`;
  return salRange;
}

export const getExperience = (min, max) => {
  if ((!min && !max) || min === '0' && max === '0')
    return (
      <span>Any Experience</span>
    )
  else if (min && !max) {
    return (
      <span>{min || 0} Years</span>
    );
  }
  else if (!min && max) {
    return (
      <span>{max || 0} Years</span>
    );
  }
  else {
    return (
      <span>{min || 0} -{" "}
        {max || 0} Years</span>
    );
  }
}

export const convertJobShiftToTitleCase = (str) => {
  return str
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}