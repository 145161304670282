import React, { useState } from "react"
import { Row, Col, Button, InputGroup} from 'reactstrap'
import { Field } from 'formik';
import { withAlert } from "react-alert"

import FormikInputField from "components/forms/formikFields/FormikInputField"
import SkiilsTypeaheadField from "components/forms/formikFields/SkiilsTypeaheadField"
import { useDispatch } from 'react-redux'

const CreatePractitionerProfile = (props) => {
  const { values, errors, touched, isValid, dirty, handleSubmit, isLoading } = props

  return (
    <div>
      <form className="signup-form" onSubmit={handleSubmit}>
        <Row>
          <Col md={6} xs={12}>
            <InputGroup size="medium">
              <Field
                id="firstName"
                name="firstName"
                type="text"
                label="First Name"
                component={FormikInputField}
                value={values.firstName} placeholder="First Name" />
            </InputGroup>
          </Col>
          <Col md={6} xs={12}>
            <InputGroup size="medium">
              <Field
                id="lastName"
                name="lastName"
                type="text"
                label="Last Name"
                component={FormikInputField}
                value={values.lastName} placeholder="Last Name" />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <InputGroup size="medium">
              <Field
                id="email"
                name="email"
                type="email"
                label="Email"
                component={FormikInputField}
                placeholder="Email" />
            </InputGroup>

          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} id="nex-cpp-cp_specialties">
            <Field
              id="cp_specialties"
              name="specialties"
              type="text"
              label="Your Primary Speciality"
              component={SkiilsTypeaheadField}           
            />
          </Col>
          <Col xs={12} md={6}>
            {/* <InputGroup size="medium"> */}
              <Field
                label="Location"
                id="location"
                name="location"
                type="text"
                component={FormikInputField}
                placeholder="Enter your city" />
            {/* </InputGroup> */}

          </Col>
        </Row>
        <Row className="my-4">
          <Col xs={12} className="d-flex m-auto justify-content-center">
            <Button id="cp_btn_submit" type="submit" disabled={ !dirty || isLoading} className="d-block btn-block nex-btn-primary" color="primary" size="md">Create Profile</Button>
          </Col>
        </Row>
      </form>
    </div>
  )
}

export default withAlert()(CreatePractitionerProfile);