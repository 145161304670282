import moment from "moment";

const DisplayCreatedTime = ({ createdOn }) => {
    let days=Math.floor(((new Date()) - (new Date(createdOn.slice(0, 10)))) / (1000 * 60 * 60 * 24)); 
    if (days === 0)
        return (
            "Today"
        );
    else if (days === 1) {
        return (
            "Yesterday"
        )
    }
    else {
        return (
            `${moment(createdOn).fromNow()}`
        )
    }
}

export default DisplayCreatedTime;