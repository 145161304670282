import React from 'react'
import { Row, Col, Button, InputGroup} from 'reactstrap'
import { Field } from 'formik';
import FormikInputField from "components/forms/formikFields/FormikInputField"

const ClaimSignupForm = (props) => {
  const { handleSubmit, isValid, dirty, isSubmitting } = props
  
  return(
    <form onSubmit={handleSubmit}>
      <div>
        <Row>
          <Col xs={12} className="text-left">
              <InputGroup>
                <Field
                  id="csf_email"
                  name="email"
                  type="email"
                  component={FormikInputField}
                  placeholder="Email address" />
              </InputGroup>
              <InputGroup>
                <Field
                  id="csf_password"
                  name="password"
                  type="password"
                  component={FormikInputField}
                  placeholder="Enter password" />
              </InputGroup>
              <InputGroup>
                <Field
                  id="csf_confirmPassword"
                  name="confirmPassword"
                  type="password"
                  component={FormikInputField}
                  placeholder="Enter Confirm Password" />
              </InputGroup>
            </Col>
          </Row>
          <Button id="csf_btn_submit" disabled={!isValid || isSubmitting} className="mt-3" color="primary" size="lg" block>Send</Button>
      </div>
    </form>
  )
}

export default ClaimSignupForm