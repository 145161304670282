import logo_blue from "assets/images/login-provider.png";
import google_img from "assets/images/social-google-img.png";
import facebook_img from "assets/images/facebook-social-img.png";
import apple_img from "assets/images/apple-social-img.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useState, useEffect } from "react";
import { Field, Formik } from "formik";
import { isEmpty } from "lodash";
import AddressTypeaheadField from "components/forms/formikFields/AddressTypeaheadField";
import FormikCheckboxField from "components/forms/formikFields/FormikCheckboxField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikMobileField from "components/forms/formikFields/FormikMobileField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import LocationTypeaheadField from "components/forms/formikFields/LocationTypeaheadField";
import PrimarySpecialityTypeaheadField from "components/forms/formikFields/PrimarySpecialityTypeaheadField";
import YearTypeaheadField from "components/forms/formikFields/YearTypeaheadField";
import { useMemo } from "react";
import * as Yup from "yup";
import {
    emailNonNumericRegEx,
    onlyAlphabets,
    TIME_CONSTANTS,
  } from "utils/Constants";
import { useRef } from "react";

import { IdentityService } from "utils/IdentityService";
import { MetadataService } from "utils/MetadataService";
import { PractitionerService } from "utils/PractitionerService";
import { withAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

const years = TIME_CONSTANTS.YEARS();

const NewRegisterStep = (props) => {
  const titles = [
    {
      label: "Dr.",
      value: "Dr.",
    },
    {
      label: "Mr.",
      value: "Mr.",
    },
    {
      label: "Mrs.",
      value: "Mrs.",
    },
    {
      label: "Ms.",
      value: "Ms.",
    },
    {
      label: "Prof.",
      value: "Prof.",
    },
  ];
    const [guid, setGuid] = useState("");
    const { state } = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedAddress, setSelectedAddress] = useState({});
    const [isSubmitting, setSubmitting] = useState(false);
    const [userProfileId, setUserProfileId] = useState("");
    const [countryList, setCountyList] = useState([]);
    const [iseditable, setisEditable] = useState(true);
  const [statesList, setSatesList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [primarySpecialityList, setPrimarySpecialityList] = useState([]);

  const stateRef = useRef();
  const cityRef = useRef();
  const countryRef = useRef();
  const locRef = useRef();
  const [address, setAddress] = useState({});
  
      const initValues = useMemo(() => {
        return {
          title: titles[0].value,
          firstName: "",
          lastName: "",
          primarySpeciality: "",
          practicingSince: "",
          streetAddress1: "",
          streetAddress2: "",
          country: "",
          state: "",
          city: "",
          zipCode: "",
          location: "",
          phone: "",
          email: "",
          password: "",
          confirmPassword: "",
          rememberMe: false,
          claimsProfileId: "",
        };
      }, []);
      const [initialValues, setInitialValues] = useState(initValues);
      const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{5})(( x| ext)\d{1,5}){0,1}$/;
const validationSchema = Yup.object().shape({
  title: Yup.string().required("This field is required"),
  firstName: Yup.string().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.string()
      .min(2)
      .max(30)
      .matches(onlyAlphabets, "Only Alphabets")
      .required("This field is required"),
    otherwise: Yup.string(),
  }),
  lastName: Yup.string().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.string()
      .min(2)
      .max(30)
      .matches(onlyAlphabets, "Only Alphabets")
      .required("This field is required"),
    otherwise: Yup.string(),
  }),
  primarySpeciality: Yup.array().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required")
      .nullable(),
    otherwise: Yup.array().nullable(),
  }),
  practicingSince: Yup.string().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.string().required("This field is required"),
    otherwise: Yup.string(),
  }),
  streetAddress1: Yup.string(),
  streetAddress2: Yup.string(),
  country: Yup.array().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required")
      .nullable(),
    otherwise: Yup.array().nullable(),
  }),
  state: Yup.array().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required")
      .nullable(),
    otherwise: Yup.array().nullable(),
  }),
  city: Yup.array().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required")
      .nullable(),
    otherwise: Yup.array().nullable(),
  }),
  location: Yup.array().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required")
      .nullable(),
    otherwise: Yup.array().nullable(),
  }),
  zipCode: Yup.string().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.string().max(10).required("This field is required").nullable(),
    otherwise: Yup.string(),
  }),
  rememberMe: Yup.boolean().oneOf(
    [true],
    "Accept Terms & Conditions is required "
  ),
  phone: Yup.string()
    .matches(phoneRegExp, "Mobile number is not valid")
    .required("This field is required"),
  email: Yup.string()
    .max(50)
    .matches(emailNonNumericRegEx, "Please Enter an valid Email")
    .required("This field is required"),
  password: Yup.string()
    .min(8)
    .max(32)
    .required("This field is required")
    .matches(
      /^(?=(.*[a-z]){3,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,}).{8,}$/,
      "Too weak. Try to add lower-case, upper-case, numbers, special characters, 8 or more characters"
    )
    .matches(
      /(?=(.*[!@#$%^&*()\-__+.]){1,})/,
      "Moderate. Try to add aleast 1 special characters or symbols"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  claimsProfileId: Yup.string(),
});

const onSubmit = (values) => {
    console.log(values)
  };
  


  

  const selectdLocation = (locationName) => {
    setAddress({
      ...address,
      location: locationName,
    });
  };

  const getAddressDetails = (address) => {
    setSelectedAddress(address);
  };

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <div className="nex-bg-light-blue">
            <main className="nex-login-register--w">
                <div className="container d-flex">
                    <div className="nex-form-fields--w nex-form-fields--w-v3">
                        <div className="nex-form-aside-v2" data-aos="fade-up">
                            <div className="logo text-center space-bottom-lg">
                                <a href="/"><img src={logo_blue} alt="" width={110} /></a>
                            </div>
                        </div>
                        <div className="nex-form-center-slot mt-0 ">

                            <ul className="nex-form-steps-lists" data-aos="fade-up" data-aos-duration="1200">
                                <li className="current"><a href=""><div className="nex-indicator"></div> Personal Details</a></li>
                                <li className=""><a href=""><div className="nex-indicator"></div> Medical Registration</a></li>
                                <li className=""><a href=""><div className="nex-indicator"></div> Upload Documents</a></li>
                            </ul>
                            <Formik 
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                enableReinitialize={true}
                                onSubmit={onSubmit}
                                children={(props) => {
                                    return (
                                      <>
                                         please enter render form here
                                      </>
                                    );
                                  }}
                            >
                            
                            </Formik>
                        </div>

                    </div>
                </div>
            </main>
        </div>
    )
}

export default NewRegisterStep;



const RenderForm = (props) => {
    const {
      values,
      errors,
      isSubmitting,
      handleSubmit,
      addressDetails,
      userProfileId,
    } = props;
  
    const [showPassword, onShowPassword] = useState(false);
    const toggleShowPassword = () => onShowPassword(!showPassword);
    const [countryList, setCountyList] = useState([]);
    const [statesList, setSatesList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [primarySpecialityList, setPrimarySpecialityList] = useState([]);
    const [iseditable, setisEditable] = useState(true);
    const stateRef = useRef();
    const cityRef = useRef();
    const countryRef = useRef();
    const locRef = useRef();
  
    useEffect(() => {
      getCountries();
      getprimarySpeciality();
    }, []);
  
    const getCountries = () => {
      setSatesList([]);
      setCityList([]);
      setLocationList([]);
      MetadataService.getCountry()
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setCountyList(response.data);
          }
        })
        .catch((error) => {
          console.log("IdentityService.getCountries error", error);
        });
    };
  
    const getStates = (countryId) => {
      setSatesList([]);
      setCityList([]);
      setLocationList([]);
      getselectedAddress(countryList, countryId, "COUNTRY");
      if (countryId == "") {
        return false;
      }
  
      MetadataService.getStates(countryId)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setSatesList(response.data);
          }
        })
        .catch((error) => {
          console.log("IdentityService.getStates error", error);
        });
    };
  
    const getCities = (stateId) => {
      setCityList([]);
      setLocationList([]);
      getselectedAddress(statesList, stateId, "STATE");
      if (stateId == "") {
        return false;
      }
      MetadataService.getCities(stateId)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setCityList(response.data);
          }
        })
        .catch((error) => {
          console.log("IdentityService.getCities error", error);
        });
    };
  
    const getLocations = (cityId) => {
      setLocationList([]);
      getselectedAddress(cityList, cityId, "CITY");
      if (cityId == "") {
        return false;
      }
      MetadataService.getLocations(cityId)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setLocationList(response.data);
          }
        })
        .catch((error) => {
          console.log("IdentityService.getCities error", error);
        });
    };
  
    const getprimarySpeciality = () => {
      MetadataService.getPrimarySpecialities()
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setPrimarySpecialityList(response.data);
          }
        })
        .catch((error) => {
          console.log("IdentityService.getprimarySpeciality error", error);
        });
    };
  
    const [address, setAddress] = useState({});
    const getselectedAddress = (array, id, type) => {
      let filterValue = "";
      if (!isEmpty(values.country)) {
        array.map((obj) => {
          if (obj.id == id) {
            filterValue = obj.value;
          }
        });
      }
      if (type == "COUNTRY") {
        setAddress({
          ...address,
          country: filterValue,
          state: "",
          city: "",
          location: "",
        });
        values.state = [];
        values.city = [];
        values.location = [];
      } else if (type == "STATE") {
        setAddress({
          ...address,
          state: filterValue,
          city: "",
          location: "",
        });
        values.city = [];
        values.location = [];
      } else if (type == "CITY") {
        setAddress({
          ...address,
          city: filterValue,
          location: "",
        });
        values.location = [];
      }
    };
  
    const selectdLocation = (locationName) => {
      setAddress({
        ...address,
        location: locationName,
      });
    };
  
    const copyDisable = (fieldName) => {
      const passwordInput = document.getElementById(fieldName);
      passwordInput.onpaste = (e) => {
        e.preventDefault();
        return false;
      };
      passwordInput.oncut = (e) => {
        e.preventDefault();
        return false;
      };
      passwordInput.oncopy = (e) => {
        e.preventDefault();
        return false;
      };
    };
  
    useEffect(() => {
      copyDisable("password");
      copyDisable("confirmPassword");
    }, []);
  
    useEffect(() => {
      if (userProfileId) {
        setisEditable(false);
      }
    }, [userProfileId]);
  
    return (
      <>
      <div className="nex-form-container" >
                                <div className="nex-form-section" data-aos="fade-up" data-aos-duration="1400" data-aos-delay="300">
                                    <div className="space-bottom-lg">
                                        <h2 className="nex-text-xxl text-center text-dark">Personal Information</h2>
                                        <p className="text-center nex-text-xxl-sub">Provide your personal information to start your registration</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                        
                                            <div className="form-group">
                                                {/*<label className="nex-text-lg" htmlFor="">First Name</label>*/}
                                                <div className="nex-input-prefix">
                                                    <Field
                                                        id="firstName"
                                                        name="firstName"
                                                        autoComplete="firstName"
                                                        type="text"
                                                        component={FormikInputField}
                                                        label="First Name"
                                                        placeholder=""
                                                        disabled={!iseditable}
                                                    />
                                                    <div className="nex-prefix-wrap">
                                                        <Field
                                                            id="title"
                                                            name="title"
                                                            type="text"
                                                            label=""
                                                            component={FormikSelectField}
                                                            inputprops={{
                                                            name: "title",
                                                            options: [
                                                              {
                                                                label: "Dr.",
                                                                value: "Dr.",
                                                              },
                                                              {
                                                                label: "Mr.",
                                                                value: "Mr.",
                                                              },
                                                              {
                                                                label: "Mrs.",
                                                                value: "Mrs.",
                                                              },
                                                              {
                                                                label: "Ms.",
                                                                value: "Ms.",
                                                              },
                                                              {
                                                                label: "Prof.",
                                                                value: "Prof.",
                                                              },
                                                            ],
                                                            keys: {
                                                                id: "value",
                                                                label: "label",
                                                            },
                                                            }}
                                                            disabled={!iseditable}
                                                            autoComplete="phone"
                                                            className="form-select"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                {/*<label className="nex-text-lg" htmlFor="">Last Name</label>*/}
                                                <Field
                                                    id="lastName"
                                                    name="lastName"
                                                    autoComplete="lastName"
                                                    type="text"
                                                    component={FormikInputField}
                                                    label="Last Name"
                                                    placeholder=""
                                                    disabled={!iseditable}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <Field
                                        id="email"
                                        name="email"
                                        autoComplete="email"
                                        type="text"
                                        component={FormikInputField}
                                        label="Email Address"
                                        placeholder=""
                                        disabled={props.initialValues.email === "" ? false : true}
                                    />
                                    <div className="form-group">
                                    <Field
                                        name="practicingSince"
                                        autoComplete="practicingSince"
                                        id="practicingSince"
                                        type="text"
                                        label="Practicing Since"
                                        component={YearTypeaheadField}
                                        placeholder="YYYY"
                                        inputprops={{
                                            autoComplete: "off",
                                        }}
                                        value={''}
                                        spellCheck={false}
                                        years={years}
                                        disabled={!iseditable}
                                        />
                                    </div>
                                    
                                    
                                </div>
                                <div className="nex-form-section address-form-sec" data-aos="fade-up" data-aos-duration="1600" data-aos-delay="500">
                                    <div className="mb-3 mt-3">
                                        <h2 className="nex-text-xxl text-center text-dark">Address</h2>
                                        <p className="nex-text-xxl-sub text-center">Provide your Address information</p>
                                    </div>
                                    <Field
                                    id="streetAddress1"
                                    name="streetAddress1"
                                    autoComplete="streetAddress1"
                                    type="text"
                                    component={FormikInputField}
                                    label="Address Line 1"
                                    placeholder=""
                                    inputprops={{
                                    autoComplete: "off",
                                    }}
                                    />
                                    <Field
                                    id="streetAddress2"
                                    name="streetAddress2"
                                    autoComplete="streetAddress1"
                                    type="text"
                                    component={FormikInputField}
                                    label="Address Line 2"
                                    placeholder=""
                                    inputprops={{
                                    autoComplete: "off",
                                    }}
                                    />
                                    
                                    <div className="row">
                                        <div className="col-lg-6">
                                            {/**  inputList={['test']} getAddressById={(contryId) => getStates(contryId)}
                                            locationRef={countryRef}*/}
                                            <Field
                                            id="country"
                                            name="country"
                                            autoComplete="country"
                                            type="text"
                                            component={AddressTypeaheadField}
                                            label="Country"
                                            value={values.country}
                                            placeholder="Select country"
                                            spellCheck={false}
                                            inputList={countryList}
                                            getAddressById={(contryId) => getStates(contryId)}
                                            locationRef={countryRef}
                                            inputprops={{
                                                autoComplete: "off",
                                            }}
                                            />
                                        
                                            <div className="form-group">
                                            <label className="nex-text-lg" htmlFor="">Country</label>
                                            <select className="form-control">
                                                <option>please select</option>
                                                <option selected>India</option>
                                            </select>
                                        </div>
                                        </div>
                                        <div className="col-lg-6">
                                        <div className="form-group">
                                        <label className="nex-text-lg" htmlFor="">State</label>
                                        <select className="form-control">
                                            <option>please select</option>
                                        </select>
                                    </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-lg-6">
                                        <div className="form-group">
                                        <label className="nex-text-lg" htmlFor="">City</label>
                                        <input type="text" name="" id="" className="form-control"   />
                                    </div>
                                        </div>
                                        <div className="col-lg-6">
                                        <div className="form-group">
                                        <label className="nex-text-lg" htmlFor="">Postal Code</label>
                                        <input type="text" name="" id="" className="form-control"   />
                                    </div>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                                <div className="nex-form-section">
                                    <div className="form-group">
                                        <div className="nex-checkbox--w">
                                            <input type="checkbox" name="" id="" className="nex-check-input" />
                                            <p className="nex-text-lg">I have read and agree to the <a href="" className="text-light-purple">Terms and Conditions </a>and <a href="" className="text-light-purple">Privacy Policy</a></p>
                                        </div>

                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="submit" className="btn btn-primary w-100 nex-max-sm mx-auto" value="Next" />
                                </div>

                            </div>
      </>
    );
  };