import React from 'react';
import { Link } from "react-router-dom";
import LinesEllipsis from 'react-lines-ellipsis';
import { getFullNameOfPerson, getGenderDetails } from 'utils/Utils';
import { PatientService } from 'utils/PatientService';
import { FAILED_TO_FETCH_DATA } from 'utils/Constants';
import { useState } from 'react';
import { useEffect } from 'react';

const DisplayPatientInfo = ({ opinion }) => {
    const [patientInfo, setPatientInfo] = useState({});

    const getPatientInfo = () => {
        if (opinion?.requestedBy === opinion?.requestedFor) {
            PatientService.getPatientById(opinion?.requestedBy)
                .then((response) => {
                    const { status, data } = response;
                    if (status === 200) {
                        setPatientInfo(data);
                    } else {
                        console.log(FAILED_TO_FETCH_DATA);
                    }
                })
        } else {
            PatientService.getRelatedPersonById(opinion?.requestedBy, opinion?.requestedFor)
                .then((response) => {
                    const { status, data } = response;
                    if (status === 200) {
                        setPatientInfo(data);
                    } else {
                        console.log(FAILED_TO_FETCH_DATA);
                    }
                })
        }
    }

    useEffect(() => {
        getPatientInfo();
    }, [opinion?.id])

    return (
        <>
            <td className="border font-weight-bold">
                <div className=' d-flex'>
                    <h4 className="link-text">
                        <Link to={`/patient-profile/${patientInfo?.id}`} style={{ color: "#2eabca" }} onClick={() => window.scrollTo(0, 0)}>
                            {getFullNameOfPerson(patientInfo?.firstName, patientInfo?.middleName, patientInfo?.lastName)}
                        </Link>
                    </h4>
                </div>
            </td>

            <td className="border font-weight-bold">
                <div className=' d-flex'>
                    <h4 className="link-text">
                        {getGenderDetails(patientInfo?.gender)}
                    </h4>
                </div>
            </td>
        </>
    )
}


const MySecondOpinionsTabularView = (props) => {
    const { theader, tdata } = props;

    return (
        <div className="profiles-table">
            <table className="table border" >
                <thead>
                    <tr>
                        {theader.map((columns, index) => (
                            <th className="theader" key={index}>{columns}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tdata.map((profileDetails, index) => (
                        <tr key={`profile_${index}`}>

                            <td className="border">
                                <Link
                                    to={`/u/medicalcases/my-opinions/${profileDetails?.id}`}
                                >
                                    {profileDetails?.caseId}
                                </Link>
                            </td>

                            <>
                                <DisplayPatientInfo opinion={profileDetails} />
                            </>

                            <td className="border">
                                {typeof profileDetails.speciality != "undefined" &&
                                    <LinesEllipsis
                                        className="card-text"
                                        text={profileDetails.speciality}
                                        maxLine="1"
                                        ellipsis="..."
                                        trimRight
                                        basedOn="letters"
                                    />
                                }
                            </td>

                            <td className="border">
                                <LinesEllipsis
                                    className="card-text"
                                    text={profileDetails.status}
                                    maxLine="1"
                                    ellipsis="..."
                                    trimRight
                                    basedOn="letters"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default MySecondOpinionsTabularView