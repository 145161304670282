
import { Field, Formik } from "formik";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import BackIcon from "assets/images/backIcon.png";
import AddPatientForm from "./Components/AddPatientForm";
import { Card, CardBody, Col, Row } from "reactstrap";
import SecondOpinionImg from "assets/images/second-opinion.png";
import formHeaderImg from "assets/images/add-patient.png";

const PatientForm = () => {
    const [errorMsg, setErrorMessage] = useState("");
    const history = useHistory();
    const handleBack = () => {
        history.goBack()
    }
    const initialValues = {
        firstName: "",
        lastName: "",
        gender: "",
        phoneNumber: "",
        emailAddress: ""
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-3 nex-app-patient-ref-title">
                <div className="nexogic-top-section-title ">
                    <span><Link to="/" onClick={handleBack}><img src={BackIcon} alt="back" /></Link></span>
                    <h2 className="h2 mb-3"><Link to="/" onClick={handleBack}>Patient Referral </Link></h2>
                </div>
            </div>
            <div className="nexogic-container-extragap nexogic-referals-new pt-5">
                <Row>
                    <Col lg={12} md={12} className="nex-app-no-pad">
                        <Card className="nexogic-form-header-top-gap nexogic-reffer-card border-0">
                            <div className="referral-header">
                                <div className="graphic header-img">
                                    <img src={formHeaderImg} alt="Image" width="100" />
                                </div>
                                <div className="text-center">
                                    <h2 className="referral-title text-black my-2">Add Patient</h2>
                                    <p className="nex-text-lg">Please fill out below form details to add Patient.</p>
                                </div>
                            </div>
                            <CardBody className="nexogic-small-container-gap">
                                {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
                                <AddPatientForm />
                            </CardBody>
                        </Card>
                    </Col>
                    {/* <Col lg={3} md={4} className="nex-app-no-pad">
                    <div className="nexogic-widget-sidebar-card card border-0 nex-app-block">
                        <h3 className="card-title h2">Second Opinion</h3>
                        <div className="card-body">
                            <ul className="nexogic-lists nexogic-custom-scroll">
                                <li>
                                    <img src={SecondOpinionImg} alt="" className="img-fluid" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </Col> */}
                </Row>
            </div>
        </>
    );
}

export default PatientForm;