import logo_white from "assets/images/logo-white-transparent.png";
import arrow_back from "assets/images/nex-arrow-back.svg";
import eye_pass from "assets/images/eye-pass.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Field, Formik } from "formik";
import FormikCheckboxField from "components/forms/formikFields/FormikCheckboxField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikMobileField from "components/forms/formikFields/FormikMobileField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import {
    emailNonNumericRegEx,
    onlyAlphabets,
    TIME_CONSTANTS,
  } from "utils/Constants";
import * as Yup from "yup";
import { useMemo } from "react";
import { useState } from "react";
import DOBFormatter from "components/DOBFormatter";
import { Button } from "reactstrap";

const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{5})(( x| ext)\d{1,5}){0,1}$/;
const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is required"),
    firstName: Yup.string().when("claimsProfileId", {
      is: (val) => val === undefined,
      then: Yup.string()
        .min(2)
        .max(30)
        .matches(onlyAlphabets, "Only Alphabets")
        .required("This field is required"),
      otherwise: Yup.string(),
    }),
    lastName: Yup.string().when("claimsProfileId", {
      is: (val) => val === undefined,
      then: Yup.string()
        .min(2)
        .max(30)
        .matches(onlyAlphabets, "Only Alphabets")
        .required("This field is required"),
      otherwise: Yup.string(),
    }),
    phone: Yup.string()
    .matches(phoneRegExp, "Mobile number is not valid")
    .required("This field is required"),
});

const PatientSignup = (props) => {
    const [isSubmitting, setSubmitting] = useState(false);
    const initValues = useMemo(() => {
        return {
            email: "",
            firstName: "",
            lastName: "",
            phone: "",
            password: "",
            confirmPassword: "",
        };
      }, []);
    
    const [initialValues, setInitialValues] = useState(initValues);
    const onSubmit = (values) => {
        console.log(values);
    };

    useEffect(()=>{
        AOS.init();
    }, [])
    return (
        <div className="nex-bg-light-blue">
            <main className="nex-login-register--w">
                <div className="container sm d-flex" data-aos="fade-up">
                    <div className="nex-form-aside">
                        <div className="logo" data-aos="fade-up" data-aos-duration="800" data-aos-delay="100">
                            <a href="/"><img src={logo_white} alt="" /></a>
                        </div>
                        
                        <h2 className="nex-text-xxl" data-aos="fade-up" data-aos-duration="800" data-aos-delay="150">Hey, <br/>
Create an account</h2>
                        <p className="nex-text-lg nex-text-light" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">Register your details to continue
with us</p>
                    </div>
                    <div className="nex-form-fields--w">
                        <div className="nex-form-top-slot nex-mob-hide" data-aos="fade-down" data-aos-duration="800" data-aos-delay="200">
                            <div className="nex-form-top-actions nex-text-lg">
                                <a href="#" className="text-gray nex-back-btn"><img src={arrow_back} alt="" /></a>
                                <a href="#" className="text-gray nex-cross-btn">&times;</a>
                            </div>
                        </div>
                        <div className="nex-form-center-slot">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                enableReinitialize={true}
                                onSubmit={onSubmit}
                                children={(props) => {
                                    return (
                                    <>
                                        <RenderForm
                                        {...props}
                                        isSubmitting={isSubmitting}
                                        />
                                    </>
                                    );
                                }}
                            />
                            {/** 
                            <div className="nex-form-container">
                                <div className="form-group-fields--w" data-aos="fade-down" data-aos-duration="800" data-aos-delay="250">
                                    <div className="form-group">
                                        <input type="text" name="" id="" className="form-control" placeholder="First Name" value={'Shayan'} />
                                        <p className="nex-error-text">Please enter a valid email addaress</p>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="" id="" className="form-control " placeholder="Last Name" value={'Sharma'} />
                                        <p className="nex-error-text">Please enter a valid email addaress</p>
                                    </div>
                                    <div className="form-group">
                                        <input type="date" name="" id="" className="form-control " placeholder="Date of Birth" value={''} />
                                        <p className="nex-error-text">Please enter a valid email addaress</p>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-group-flag">
                                            <input type="text" name="" id="" className="form-control " placeholder="Phone" value={''} />
                                        </div>
                                        <p className="nex-error-text">Please enter a valid email addaress</p>
                                    </div>

                                    <div className="form-group">
                                        <div className="form-group-flag">
                                            <input type="email" name="" id="" className="form-control " placeholder="Email Address" value={''} />
                                        </div>
                                        <p className="nex-error-text">Please enter a valid email addaress</p>
                                    </div>
                                    <div className="form-group pt-3">
                                <label className="nex-text-lg text-black mb-3" htmlFor=""><b>Gender</b></label>
                                <div className="nex-inline-radio-btn gender-radio flex-column">
                                    <div className="inp-radio">
                                        <input type="radio" name="gender" id="g1" className="nex-radio-check" checked/>
                                        <label for="g1" className="nex-text-lg">Male</label>
                                    </div>
                                    <div className="inp-radio">
                                        <input type="radio" name="gender" id="g2" className="nex-radio-check"/>
                                        <label for="g2" className="nex-text-lg">Female</label>
                                    </div>
                                    <div className="inp-radio">
                                        <input type="radio" name="gender" id="g3" className="nex-radio-check"/>
                                        <label for="g3" className="nex-text-lg">Prefer not to say </label>
                                    </div>
                                    
                                </div>
                            </div>
                                
                                    <div className="form-group">
                                        <input type="submit" value="Continue" className="btn btn-primary w-100" />
                                    </div>
                                </div>
                            </div>
                            */}
                        </div>
                        
                    </div>
                </div>
            </main>
        </div>
    )
}

export default  PatientSignup;

const RenderForm = (props) => {
    const {
        values,
        errors,
        isSubmitting,
        handleSubmit,
        addressDetails,
        userProfileId,
      } = props;
      const [showPassword, onShowPassword] = useState(false);
      const toggleShowPassword = () => onShowPassword(!showPassword);
      const [iseditable, setisEditable] = useState(true);
      return (
        <>
        <form className="personal-details-form" onSubmit={handleSubmit}>
        <div className="nex-form-container">
                                <div className="form-group-fields--w" data-aos="fade-down" data-aos-duration="800" data-aos-delay="250">
                                    
                                    <div className="form-group">
                                        <Field
                                            id="firstName"
                                            name="firstName"
                                            autoComplete="firstName"
                                            type="text"
                                            component={FormikInputField}
                                            label=""
                                            placeholder="First Name"
                                            disabled={!iseditable}
                                        />
                                        
                                        {/*<p className="nex-error-text">Please enter a valid email addaress</p>*/}
                                    </div>
                                    <div className="form-group">
                                        <Field
                                            id="lastName"
                                            name="lastName"
                                            autoComplete="lastName"
                                            type="text"
                                            component={FormikInputField}
                                            label=""
                                            placeholder="Last Name"
                                            disabled={!iseditable}
                                        />
                                        
                                        {/*<p className="nex-error-text">Please enter a valid email addaress</p>*/}
                                    </div>
                                    <div className="form-group nex-hide-label">
                                        <DOBFormatter />
                                        {/*<p className="nex-error-text">Please enter a valid email addaress</p>*/}
                                    </div>
                                    <div className="form-group">
                                        <div className="form-group-flag">
                                            <Field
                                                id="phone"
                                                name="phone"
                                                autoComplete="phone"
                                                type="text"
                                                component={FormikMobileField}
                                                label=""
                                                placeholder="Mobile Number"
                                                value={props.initialValues.phone}
                                                disabled={props.initialValues.phone === "" ? false : true}
                                            />
                                            
                                        </div>
                                        {/*<p className="nex-error-text">Please enter a valid email addaress</p>*/}
                                    </div>

                                    <div className="form-group">
                                        <div className="form-group-flag">
                                            <Field
                                                id="email"
                                                name="email"
                                                autoComplete="email"
                                                type="text"
                                                component={FormikInputField}
                                                label=""
                                                placeholder="Email Address (Optional)"
                                                disabled={props.initialValues.email === "" ? false : true}
                                            />
                                            
                                        </div>
                                        {/*<p className="nex-error-text">Please enter a valid email addaress</p>*/}
                                    </div>
                                    <div className="form-group pt-3">
                                <label className="nex-text-lg text-black mb-3" htmlFor=""><b>Gender</b></label>
                                <div className="nex-inline-radio-btn gender-radio flex-column">
                                    <div className="inp-radio">
                                        <input type="radio" name="gender" id="g1" className="nex-radio-check" checked/>
                                        <label htmlFor="g1" className="nex-text-lg">Male</label>
                                    </div>
                                    <div className="inp-radio">
                                        <input type="radio" name="gender" id="g2" className="nex-radio-check"/>
                                        <label htmlFor="g2" className="nex-text-lg">Female</label>
                                    </div>
                                    <div className="inp-radio">
                                        <input type="radio" name="gender" id="g3" className="nex-radio-check"/>
                                        <label htmlFor="g3" className="nex-text-lg">Prefer not to say </label>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="form-group">
                                        <div className="nex-checkbox--w">
                                            <input type="checkbox" name="" id="" className="nex-check-input" />
                                            <p className="nex-text-lg">I have read and agree to the <a href="" className="text-light-purple">Terms and Conditions </a>and <a href="" className="text-light-purple">Privacy Policy</a></p>
                                        </div>

                                    </div>
                                    <div className="form-group">
                                    <Button
          id="nex-form-next"
          className="btn btn-primary w-100"
          type="submit"
          disabled={isSubmitting}
          //onClick={() => addressDetails(address)}
        >
          Continue
        </Button>
                                    </div>
                                </div>
                            </div>
        </form>
        </>
      )
}