import { Button, Card, CardBody } from "reactstrap";
import marker_icon from "assets/images/marker_icon.png";
import { useEffect, useRef, useState } from "react";
import { createImageFromInitials, getRandomColor } from "utils/Utils";
import { Link } from "react-router-dom";
import NoJobs from "pages/Jobs/NoJobs";
import { encrypt } from "utils/EncryptDecrypt";
import { PractitionerService } from "utils/PractitionerService";
import NoConnection from "pages/Connections/components/NoConnection";

const CandidateWidget = (props) => {
  const { primarySpeciality = "", jobCity, alert } = props;
  const [candidates, setCandidates] = useState([]);
  const [hasMore, sethasMore] = useState(false);
  const currentPage = useRef(0);


  function makeQuery(status, primarySpeciality, city) {
    let query = "";
    if (status !== "") {
      query = `(st=="${status}")`;
    }

    if (primarySpeciality !== "") {
      if (query === "")
        query = `(ps=="${encodeURIComponent(primarySpeciality)}")`;
      else
        query += `;(ps=="${encodeURIComponent(primarySpeciality)}")`;
    }

    if (city !== "") {
      if (query === "")
        query = `(ci=="${encodeURIComponent(city)}")`;
      else
        query += `;(ci=="${encodeURIComponent(city)}")`;
    }

    return query;
  }

  const fetchData = () => {
    try {
      const pageNo = currentPage.current + 1;
      currentPage.current = pageNo;
      let sq = makeQuery("APPROVED",primarySpeciality, jobCity)

      PractitionerService.getPractitionerProfiles(sq, pageNo)
        .then((response) => {
          if (response.status === 200) {
            const { currentPage, totalPages, contents } = response.data;
            setCandidates(contents);
            sethasMore(totalPages - currentPage !== 1);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (e) {
      if (alert) alert.error(e.message);
    }
  };

  useEffect(() => {
    let sq = makeQuery("APPROVED", primarySpeciality, jobCity);
    PractitionerService.getPractitionerProfiles(sq, 0)
      .then((response) => {
        if (response.status === 200) {
          const { currentPage, totalPages, contents } = response.data;
          setCandidates(contents);
          sethasMore(totalPages - currentPage !== 1);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [alert]);

  return (
    <Card className="nexogic-widget-sidebar-card job-card bg-white">
      <h3 className="nex-aside-title">Recommended Candidates</h3>
      <CardBody>
      <div className="nexogic-custom-scroll" style={{ height: "500px", overflow: "auto" }}>
        <div className="card-group nexogic-card-group-list">
          {candidates.map((item, index) => (
            <div className="card" key={`job_list${index} `}>
              <div className="row no-gutters align-items-start">
              {
                <div className="col-auto icon">
                  <Link to={`/profile/${item?.profileId}`}>
                    {item && item.avatarId ? (
                      <img
                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${item?.profileGuid}/${item?.avatarId}`}
                        className="card-img"
                        alt="#"
                      />
                    ) : (
                      <img
                        src={createImageFromInitials(
                          50,
                          `${item?.name} `,
                          getRandomColor(encrypt(item?.profileGuid).toString())
                        )}
                        className="card-img"
                        alt="#"
                      />
                    )}
                  </Link>
                </div>
              }
              <div className="col">
                <h4 className="card-title">
                  {item?.name && (
                    <Link to={`/profile/${item?.profileId}`}>
                      {item?.name}
                      {", "}
                      {item?.qualification}
                    </Link>
                  )}
                </h4>
                <p className="card-text">
                  {item?.primarySpeciality && (
                    <small className="text-muted">
                      {item?.primarySpeciality}
                    </small>
                  )}
                </p>
                <p className="card-text">
                  <small className="text-muted"><img src={marker_icon} alt="#" /> {item?.location}{" "}
                  {item?.city}</small>
                </p>
              </div>
              </div>
            </div>
          ))}
          
          {candidates.length === 0 && <NoConnection content={"No Candidate"} />}
          
        </div>
      </div>
      
      {candidates.length > 0 && hasMore && (
            <div className="mt-3">
              <Button color="link" className="readmore btn-link" onClick={fetchData}>
                View More
              </Button>
            </div>
          )}
      </CardBody>
    </Card>
  );
};

export default CandidateWidget;
