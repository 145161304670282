import React from 'react'
import { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import profile_sample from "assets/images/user-profile-sample.png";
import AssignPractitioner from '../Practitioner/AssignPractitioner';
import SearchPractitioner from '../Practitioner/SearchPractitioner';
import PractitionerSearchField from '../Practitioner/PractitionerSearchField';

export default function AssignModal(props) {
    const [assignRadio, setAssignRadio] = useState("self");
    const [selectedPractitioner, setSelectedPractitioner] = useState(null);
    

  return (
    <Modal
            isOpen={props.open}
            toggle={()=>props.setToggle(!props.open)}
          >
            <ModalHeader toggle={()=>props.setToggle()}>
              Assign To
            </ModalHeader>
            <ModalBody>
              <div className="nexogic-assign-self">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="self_assign_so"
                    id="self_assign_so1"
                    value="self"
                    checked={assignRadio === "self"}
                    onChange={(e) => setAssignRadio(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="self_assign_so1">
                    Self
                  </label>
                </div>
                <div className="form-check ">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="self_assign_so"
                    id="self_assign_so2"
                    value="someone_else"
                    checked={assignRadio === "someone_else"}
                    onChange={(e) => setAssignRadio(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="self_assign_so2">
                    Someone Else
                  </label>
                </div>
              </div>
              {/* select practitioner or group */}
              {assignRadio === "someone_else" && (
                <PractitionerSearchField label="Please Select" onItemSelect={(item)=>setSelectedPractitioner(item)} />
              )}
              {/* Practitioner search lists */}
              
              
              {selectedPractitioner !== null && <AssignPractitioner assignData={selectedPractitioner} />}
                  
                 
                  
                
              
              <div className="form-group">
                <a href="" className="btn nexogic_primary_button" onClick={(e)=>{e.preventDefault(); props.onAssign(true)}}>
                  Assign
                </a>
              </div>
            </ModalBody>
          </Modal>
  )
}
