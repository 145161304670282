import React from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import LinesEllipsis from 'react-lines-ellipsis';
import ConnectButton from 'pages/FeedHomePage/components/PractitionerProfiles/ConnectButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { getAge, getFullNameOfPerson, getGenderDetails } from 'utils/Utils';


const MyPatientsTabularView = (props) => {
    // const currentUser = useSelector((state) => state.auth.currentUser);
    const { theader, tdata, viewmode } = props;

    // useEffect(() => {
    //     console.log(tdata);
    // }, [tdata])

    return (
        <div className="nex-tabular-table">
            <table className="table border" >
                <thead>
                    <tr>
                        {theader.map((columns, index) => (
                            <th className="theader" key={index}>{columns}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tdata.map((profileDetails, index) => (
                        <tr key={`profile_${index}`}>
                            <td className="border font-weight-bold">
                                <div className=' d-flex'>
                                    <h4 className="link-text">
                                        <Link to={`/patient-profile/${profileDetails?.id}`} style={{ color: "#2eabca" }} onClick={() => window.scrollTo(0, 0)}>
                                            {getFullNameOfPerson(profileDetails.firstName, profileDetails.middleName, profileDetails.lastName)}
                                        </Link>
                                    </h4>
                                </div>
                            </td>
                            <td className="border">
                                {typeof profileDetails.gender != "undefined" &&
                                    getGenderDetails(profileDetails.gender)
                                }
                            </td>
                            <td className="border">
                                {getAge(profileDetails.dateOfBirth)}
                            </td>

                            <td className="border">
                                {profileDetails.cellularPhoneNumber}
                            </td>

                            <td className="border">
                                {profileDetails.emailAddress}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default MyPatientsTabularView