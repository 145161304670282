import React from 'react'
import './css/v1.scss'
import nex_logo_img from 'assets/images/logo-white-img.png'
import logo from 'assets/images/logo-top-icon.png'

function AdsFour(props) {
  return (
    <div className='nex-ads-wrapper size-md v4'>
    <div className="nex-ads-brand">
        <a href={props.logo_url} target={props.logo_target}><img src={props.logo_img} alt="" /></a>
    </div>
    <div className="nex-ads-body">
        <div className="nex-ads-content">
            <p dangerouslySetInnerHTML={{__html: props.title}}></p>
            {props.links && 
                props.links.map((item, ind)=>(
                    <p className='link' key={'links_'+ind}><a>{item}</a></p>
                ))
            }
            
            
            <p className='p-number'>{props.phone}</p>
        </div>
        <div className="nex-ads-img-full">
            <img src="https://source.unsplash.com/UC9_itYaafM" alt="" />
            <div className="floated-txt">
                {props.short_desc}
            </div>
        </div>
        
    </div>
</div>
  )
}

AdsFour.defaultProps = {
    bg:"https://picsum.photos/id/211/800/450",
    logo_img:nex_logo_img,
    logo_url:"#",
    logo_target:"_blank",
    title:"Lorem ipsum dolor sit amet.",
    short_desc: "Best hospital in India",
    phone: "123456789",
    links: ["www.applolo.com", "www.applolo2.com"]
}

export default AdsFour;
