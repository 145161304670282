import React from 'react'
import { Card,CardBody, Col, Row } from 'reactstrap';

const RestrictedComponent = () => {
  return (
    <Card>
        <CardBody className='text-center py-5'>
            <h1>Referral Service is not active</h1>
            <Row className='pt-3'>
                <Col>Please enable <strong>Referrals</strong> from <em>Settings</em> &gt; <em>Preferences</em> &gt; Enable <em>Referrals</em></Col>
            </Row>
        </CardBody>
    </Card>
  )
}

export default RestrictedComponent