import React, {useState} from 'react'
import { Col, CardBody } from 'reactstrap'
import { useHistory} from "react-router-dom"
import { withAlert } from "react-alert"
import * as Yup from 'yup';
import { Formik } from 'formik';

import CreatePractitionerProfile from './components/CreatePractitionerProfile'
import { PractitionerService } from "utils/PractitionerService"
import { emailNonNumericRegEx, onlyAlphabets } from "utils/Constants"
import {  encrypt } from "utils/EncryptDecrypt"

const validationSchema = Yup.object({
  email: Yup.string().max(100).matches(emailNonNumericRegEx, 'Please Enter an valid Email'),
  firstName: Yup.string().min(2).max(30).matches(onlyAlphabets, "Only Alphabets").required("This field is required"),
  lastName: Yup.string().min(2).max(30).matches(onlyAlphabets, "Only Alphabets"),
  specialties: Yup.array().min(1, "This field is required"),
  location: Yup.string().min(3).max(50),
})

const initialValues = {
  email:'',
  firstName: '',
  lastName: '',
  specialties: [],
  location:''
}

const CreateProfile = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const  history  = useHistory()
  
  const registerUser = async (values) => {
    let customValues = { ...values }
    const specialties = customValues.specialties
    delete customValues.specialties
    
    setIsLoading(true)
    let payload = {
      practitioner: {
        ...customValues
      },
      specialties
    }
    PractitionerService.createPractitioner(payload)
    .then(response => {
      setIsLoading(false)
      const { status, data } = response.data
      if(status === "SUCCESS") {
        const { firstName, lastName, practitionerId } = data.practitioner
        props.alert.success("Profile successfully created!")
        const currentUserId = encrypt(practitionerId)
        history.push(`/profile/${currentUserId}`)
      } else {
        props.alert.error(<div style={{ textTransform: 'none' }}>{"Failed to create Profile"}</div>)
      }
    },
    e => {
      // console.log(e.message)
      setIsLoading(false)
      let errmsg = e.message
      if(e.response){
        errmsg = (e.response.data && !!e.response.data.message)? e.response.data.message : errmsg
        if(e.response.data && e.response.data.data && e.response.data.data.errors && e.response.data.data.errors.length > 0 ) {
          errmsg = e.response.data.data.errors[0]
        }
      }
      props.alert.error(errmsg)
    })
  }

  return (
    <Col className="card bg-white">
      <CardBody>
        <h4 className="mb-4 mt-4 text-center card-title">Create Practioner Account</h4> 
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={registerUser}
          children={props => {
            return <CreatePractitionerProfile {...props} isLoading={isLoading} />
          }}
        />
      </CardBody>  
    </Col>
  )
}

export default withAlert()(CreateProfile)