import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import ReactQuill from 'react-quill';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const JobDescriptionEditModal = (props) => {
    const { modal, setModal } = props;
    const { values, setFieldValue } = useFormikContext();
    const [description, setDescription] = useState(values.description);

    const handleSubmit = () => {
        setFieldValue("description", description);
        setModal(false); // Close the modal
    };

    return (
        <Modal isOpen={modal} toggle={() => setModal(!modal)} className="nex-model-v2 modal-dialog-scrollable modal-dialog-centered">
            <ModalHeader toggle={() => setModal(!modal)}>Edit Job Description</ModalHeader>
            <ModalBody>
                <h2 className='nex-text-lg text-black'>Job Descriptions</h2>
                <div className="form-group">
                    <ReactQuill
                        theme="snow"
                        value={description}
                        name={"description"}
                        onChange={(content) => setDescription(content)}
                    />
                </div>
                <div className="form-group nex-action-flex">
                <button className="btn nex-btn-primary ml-auto" onClick={handleSubmit}>Save</button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default JobDescriptionEditModal;
