import React from 'react'

export default function IconBooking() {
  return (
    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    fill="none"
                                    viewBox="0 0 18 18"
                                >
                                    <path
                                        fill="#009FD7"
                                        d="M4.32.854c-.394 0-.72.311-.72.687v.687H1.44c-.418 0-.72.289-.72.688V5.32h16.56V2.916c0-.4-.303-.688-.72-.688H14.4v-.687c0-.376-.327-.687-.72-.687h-.72c-.394 0-.72.311-.72.687v.687H5.76v-.687c0-.376-.327-.687-.72-.687h-.72zm0 .687h.72v2.062h-.72V1.54zm8.64 0h.72v2.062h-.72V1.54zM.72 6.008V16.66c0 .4.302.688.72.688h15.12c.417 0 .72-.289.72-.688V6.008H.72zm3.6 2.062h1.8v1.718h-1.8V8.07zm2.52 0h1.8v1.718h-1.8V8.07zm2.52 0h1.8v1.718h-1.8V8.07zm2.52 0h1.8v1.718h-1.8V8.07zm-7.56 2.405h1.8v1.718h-1.8v-1.718zm2.52 0h1.8v1.718h-1.8v-1.718zm2.52 0h1.8v1.718h-1.8v-1.718zm2.52 0h1.8v1.718h-1.8v-1.718zm-7.56 2.406h1.8v1.718h-1.8V12.88zm2.52 0h1.8v1.718h-1.8V12.88zm2.52 0h1.8v1.718h-1.8V12.88zm2.52 0h1.8v1.718h-1.8V12.88z"
                                    ></path>
                                </svg>
  )
}
