import React from "react"
import { Row, Col, Button } from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import * as Yup from 'yup';
import { Formik } from 'formik';

import EmailForm from "./components/EmailForm";
import verifyMobileImg from 'assets/images/otp-flow/verifyMobile.svg'
//import './style.scss'
import logo from "assets/images/logo-white.png";
import { useEffect } from "react";
const initialValues = {
  email: ''
}

const validationSchema = Yup.object({
  email: Yup.string().email('Email address is not valid').required("This field is required"),
})

const VerifyEmail = ({ handleSubmit, error }) => {

  useEffect(() => {
    document.body.classList.add('register-screen-page');
    document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "Verify Email";
    return () => {
      document.body.classList.remove('register-screen-page');
      document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "";
    }
}, [])

  return (
    <div className="nexogic-login-register-card card border-0 bg-transparent">
    <Row className="">
      <Col md={6} lg={7} xs={12} className="nexogic-form-side-content text-center">
        <div className="nexogic-form-info">
          <p><img src={logo} alt="" className="nexogic-filter-white" /></p>
          <p className="card-text text-white mb-5">Sign in or create an account</p>
          <img src={verifyMobileImg} className="img-fluid" alt="nexogic" />
        </div>
      </Col>
      <Col md={6} lg={5} xs={12} className="nexogic-form-content card-body p-0">
        <div className="nexogic-form-container">
          <div>
            <h4 className="nexogic-form-title">Verify your email</h4>
            <p className="nexogic-form-title-sub">Provide your email address to send you a code for verification</p>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              children={props => {
                return <EmailForm {...props} error={error} />
              }}
            />

            <p className="card-text text-center mt-4 fw-semibold">Already have an account? <Link to="/login">Sign in</Link> </p>
          </div>
        </div>
      </Col>
    </Row>
    </div>
  )
}

export default VerifyEmail