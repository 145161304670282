import { withAlert } from "react-alert";
import ApiCalendar from "react-google-calendar-api";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import googleCalendarIcon from "assets/images/svgs/google-calendar.svg";
import { authSetUser } from "redux-helpers/ActionCreator";
import { PractitionerService } from "utils/PractitionerService";

const Integrations = (props) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();

  const calendarLogin = async () => {
    if (!currentUser.calendarId) {
      const authInstance = ApiCalendar.gapi.auth2.getAuthInstance();
      const response = await authInstance.grantOfflineAccess({
        scope: "profile email",
      });
      if (response && response.code) {
        const calendarEmail = authInstance.currentUser
          .get()
          .getBasicProfile()
          .getEmail();
        const addCalendarResponse = await PractitionerService.addCalendar(
          response.code,
          calendarEmail,
          currentUser.practitionerId
        );
        if (addCalendarResponse.data.status === "SUCCESS") {
          dispatch(authSetUser({ ...currentUser, calendarId: calendarEmail }));
        } else {
          console.error("There is some error adding calendar.");
          props.alert.error(addCalendarResponse.data.message);
        }
      }
    }
  };

  return (
    <>
      {props.isTitle &&
      <div className="nexogic-page-title">Integrations</div>
}
      <Row>
        <Col lg={12}>
          <div className="section-content">

          <Row className="align-items-center">
              <Col>
                 <ListGroup className="nexogic-form-option-lists mt-0">
                      <ListGroupItem>
                      <h3 className="card-title"><img src={googleCalendarIcon} alt="" /> Google calendar
                          <small className="card-text text-muted">See your colleagues availability while scheduling meetings and
                appointments</small>
                      </h3>
                      <div className="action nexogic-swaches-btns">
                      <Button
                  className={
                    "calendar-status-btn enable-btn " +
                    (currentUser.calendarId ? "active" : "")
                  }
                  onClick={calendarLogin}
                >
                  Enable
                </Button>
                <Button
                  className={
                    "calendar-status-btn disable-btn " +
                    (!currentUser.calendarId ? "active" : "")
                  }
                >
                  Disable
                </Button>
                      </div>
                      </ListGroupItem>
                 </ListGroup>
              </Col>
            </Row>



{/* 
            <Row className="align-items-center">
              <Col lg={4} className="google-calender">
                <img src={googleCalendarIcon} alt="" />
                <span className="google-calenser-text ml-2">
                  Google calendar
                </span>
              </Col>
              <Col lg={4} className="setting-card-content line-height-19">
                See your colleagues availability while scheduling meetings and
                appointments
              </Col>
              <Col
                lg={4}
                className="d-flex justify-content-lg-end justify-content-center enb-dsb-btn"
              >
                <Button
                  className={
                    "calendar-status-btn enable-btn " +
                    (currentUser.calendarId ? "active" : "")
                  }
                  onClick={calendarLogin}
                >
                  Enable
                </Button>
                <Button
                  className={
                    "calendar-status-btn disable-btn " +
                    (!currentUser.calendarId ? "active" : "")
                  }
                >
                  Disable
                </Button>
              </Col>
            </Row>
            */}
          </div>
        </Col>
      </Row>
    </>
  );
};

Integrations.defaultProps = {
  isTitle: true,
};

export default withAlert()(Integrations);
