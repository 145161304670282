import React from 'react'

export default function VisionInsurance() {
  return (
    <div className='nexogic-form-w'>
      <div className="row">
        <div className="col-lg-6 form-group">
          <label>Choose Plan</label>
          <select name="" id="" className='form-control'>
            <option value="">None selected</option>
          </select>
        </div>
        <div className="col-lg-6 form-group">
          <label>Member ID (Optional) </label>
          <input id='member_id' name='member_id'  type="text" className='form-control' placeholder='Member ID (Optional)' />
        </div>
      </div>
    </div>
  )
}
