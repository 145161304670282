import React, { memo, useState, useEffect } from "react";
import { createImageFromInitials, getFullAddress, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import { PractitionerService } from "utils/PractitionerService";
import graduation_cap_icon from "assets/images/graduation-cap.png";
import suitcase_icon from "assets/images/suitcase.png";
import pin_small_icon from "assets/images/pin-small.png";

const ProfileCardSingleItem = ({ profileId, viewMode }) => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    PractitionerService.getClaimsProfile(profileId)
      .then((practResp) => {
        if (practResp && practResp.data) {
          setProfileData(practResp.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
        setError(error);
        setLoading(false);
      });
  }, [profileId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!profileData) {
    return <div>No profile data available</div>;
  }

  const {
    firstName,
    middleName,
    lastName,
    guid,
    qualification,
    primarySpeciality,
    superSpeciality,
    avatarId,
    practicingSince,
    location,
    city,
    state,
    profileStatus,
  } = profileData;

  const name = `${firstName} ${middleName ?? ''} ${lastName}`.trim();

  return (
    <div className="nex-docor-profile nex-image-list border">
      <div className="nexogic-graphic">
        <div className="graphic">
          {avatarId ? (
            <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${guid}/${avatarId}`} className="nexogic-avatar lg" alt="#" style={{ height: "120px", width: "120px" }} />
          ) : (
            <img src={createImageFromInitials(50, `${name.replace('Dr. ', '')}`, getRandomColor(encrypt(guid).toString()))} className="nexogic-avatar lg" alt="#" style={{ height: "120px", width: "120px" }} />
          )}
        </div>
      </div>
      <div className="nexogic-info">
        {name && <h4 className="card-title">{name}</h4>}
        {typeof primarySpeciality !== "undefined" && (
          <ul className="nex-inline-list">
            <li>{primarySpeciality}</li>
            {superSpeciality && superSpeciality !== "undefined" && <li> {superSpeciality}</li>}
          </ul>
        )}
        {qualification && <p><span className="nex-icon xs"><img src={graduation_cap_icon} alt="" /></span>{qualification}</p>}
        {typeof practicingSince !== "undefined" && (
          <p>
            <span className="nex-icon xs"><img src={suitcase_icon} alt="" /></span>
            {!isNaN(parseInt(practicingSince)) && parseInt(new Date().getFullYear()) - parseInt(practicingSince) > 0 ? (
              `${parseInt(new Date().getFullYear()) - parseInt(practicingSince)}+ years experience`
            ) : '0 year experience'}
          </p>
        )}
        {(location && city && state) && (
          <p className="nex-lead text-dark">
            <span className="nex-icon xs"><img src={pin_small_icon} alt="" /></span>
            {getFullAddress({
              name: " ",
              location: location ?? "",
              city: city ?? "",
              state: state ?? "",
            }).replace("-", "")}
          </p>
        )}
      </div>
    </div>
  );
};

export default memo(ProfileCardSingleItem);
