import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import edit_icon from 'assets/images/svgs/small-edit-btn.svg';
import pdf_icon from 'assets/images/pdf-icon.png';
import { createImageFromInitials, getRandomColor } from "utils/Utils";
import QuickInfo from 'common/QuickInfo/QuickInfo';
import { useFormikContext } from 'formik';
import MedicalHistoryForm1Modal from './modals/MedicalHistoryForm1Modal';
import MedicalHistoryForm2Modal from './modals/MedicalHistoryForm2Modal';
import MedicalHistoryForm3Modal from './modals/MedicalHistoryForm3Modal';
import DocumentFormModal from './modals/DocumentFormModal';
import PractitionerFormModal from './modals/PractitionerFormModal';

export default function CreatePreviewPage() {
    const { values } = useFormikContext();

    const [medicalHistoryForm1ModalOpen, setMedicalHistoryForm1ModalOpen] = useState(false);
    const [medicalHistoryForm2ModalOpen, setMedicalHistoryForm2ModalOpen] = useState(false);
    const [medicalHistoryForm3ModalOpen, setMedicalHistoryForm3ModalOpen] = useState(false);
    const [practitionerFormModalOpen, setPractitionerFormModalOpen] = useState(false);
    const [documentFormModalOpen, setDocumentFormModalOpen] = useState(false);

    // useEffect(() => {
    //     console.log(values);
    // }, [values])

    return (
        <div className="nex-preview-card v2 p-0">
            <h1 className='nex-text-xxl mb-3'>Second Opinion request details</h1>

            <div className='nex-job-preview-content'>
                <Row>
                    <Col lg={12}>
                        <div className="nex-item">
                            <div className="nex-info w-full">
                                <div className="card-title d-flex">Create Second Opinion request for
                                    {/* <span className="btn"><img src={edit_icon} alt="" /></span> */}
                                </div>
                                <div className="card-text">
                                    <QuickInfo avatar={<>
                                        <img
                                            className="nex-avatar lg"
                                            src={createImageFromInitials(64, `${values?.requestedFor?.firstName} ${values?.requestedFor?.lastName}`, getRandomColor(null))}
                                            alt="profilePic"
                                            width={64}
                                        />
                                    </>}
                                        title={`${values?.requestedFor?.firstName} ${values?.requestedFor?.lastName}`}
                                    >
                                        {/* <p className="nex-text">Yourself</p> */}
                                    </QuickInfo>
                                </div>
                            </div>

                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="nex-item">
                            <div className="nex-info w-full">
                                <div className="card-title">Describe your health issue in brief ?
                                    {/* <span className="btn"><img src={edit_icon} alt="" /></span> */}
                                </div>
                                <div className="card-text"><p>{values?.title}</p></div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="nex-item">
                            <div className="nex-info w-full">
                                <div className="card-title">Choose Doctor Speciality</div>
                                <div className="card-text">{values?.speciality && values?.speciality[0]}</div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="nex-item">
                            <div className="nex-info w-full">
                                <div className="card-title">Please specify your medical Symptoms, Allergies, Surgeries which are applicable?
                                    <span className="btn ml-2"><img src={edit_icon} onClick={() => setMedicalHistoryForm1ModalOpen(true)} /></span>
                                </div>

                                <div className="card-text">Symptoms: {values?.symptoms?.map((e, i) => <span>{`${i != 0 ? ", " : ""}`}{e}</span>)}</div>
                                <div className="card-text">Allergies: {values?.allergies?.map((e, i) => <span>{`${i != 0 ? ", " : ""}`}{e}</span>)}</div>
                                <div className="card-text">Surgeries: {values?.surgeries?.map((e, i) => <span>{`${i != 0 ? ", " : ""}`}{e}</span>)}</div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="nex-item">
                            <div className="nex-info w-full">
                                <div className="card-title">Describe your Medical History in brief ?
                                    <span className="btn"><img src={edit_icon} onClick={() => setMedicalHistoryForm2ModalOpen(true)} /></span>
                                </div>
                                <div className="card-text" dangerouslySetInnerHTML={{ __html: values?.description }}></div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="nex-item">
                            <div className="nex-info w-full">
                                <div className="card-title">Do you have a strong family medical history of any serious illnesses (cancer, heart disease etc) ?
                                    <span className="btn"><img src={edit_icon} onClick={() => setMedicalHistoryForm2ModalOpen(true)} /></span>
                                </div>
                                <div className="card-text" dangerouslySetInnerHTML={{ __html: values?.familyHistory }}></div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="nex-item">
                            <div className="nex-info w-full">
                                <div className="card-title">Enter Your past hospitalizations <span className="btn"><img src={edit_icon} onClick={() => setMedicalHistoryForm3ModalOpen(true)} /></span></div>
                                <div className="card-text" dangerouslySetInnerHTML={{ __html: values?.pastHospitalization }}></div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="nex-item">
                            <div className="nex-info w-full">
                                <div className="card-title">Enter Your Current Medications <span className="btn"><img src={edit_icon} onClick={() => setMedicalHistoryForm3ModalOpen(true)} /></span></div>
                                <div className="card-text" dangerouslySetInnerHTML={{ __html: values?.currentMedications }}></div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="nex-item">
                            <div className="nex-info w-full">
                                <div className="card-title">Your Selected Practitioner
                                    <span className="btn"><img src={edit_icon} onClick={() => setPractitionerFormModalOpen(true)} /></span>
                                </div>
                                {values?.practitionerAssignedTo?.name &&
                                    <div className='quickinfo-w-border'>
                                        <QuickInfo avatar={<>
                                            <img
                                                className="nex-avatar lg"
                                                src={createImageFromInitials(64, values?.practitionerAssignedTo?.name ? values?.practitionerAssignedTo?.name.replace('Dr. ', "") : "", getRandomColor(null))}
                                                alt="profilePic"
                                                width={64}
                                            />
                                        </>}
                                            title={values?.practitionerAssignedTo?.name}
                                        >
                                            {/* <p className="nex-text">Yourself</p> */}
                                        </QuickInfo>
                                    </div>
                                }
                            </div>
                        </div>
                    </Col>{!values?.id &&
                        <Col lg={6}>
                            <div className="nex-item">
                                <div className="nex-info w-full">
                                    <div className="card-title">Your uploaded document
                                        <span className="btn"><img src={edit_icon} onClick={() => setDocumentFormModalOpen(true)} /></span>
                                    </div>
                                    <div className="card-text">
                                        {values?.docs && values?.docs.map((e) => (
                                            <div className='nex-text m-2'><span className="icon mr-2"><img src={pdf_icon} width={30} alt="" /></span> {e?.name}</div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    }
                </Row>
                <MedicalHistoryForm1Modal
                    modal={medicalHistoryForm1ModalOpen}
                    setModal={setMedicalHistoryForm1ModalOpen}
                />
                <MedicalHistoryForm2Modal
                    modal={medicalHistoryForm2ModalOpen}
                    setModal={setMedicalHistoryForm2ModalOpen}
                />
                <MedicalHistoryForm3Modal
                    modal={medicalHistoryForm3ModalOpen}
                    setModal={setMedicalHistoryForm3ModalOpen}
                />
                <PractitionerFormModal
                    modal={practitionerFormModalOpen}
                    setModal={setPractitionerFormModalOpen}
                />
                <DocumentFormModal
                    modal={documentFormModalOpen}
                    setModal={setDocumentFormModalOpen}
                />
            </div>
        </div>
    )
}
