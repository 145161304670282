import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import './style.scss'

export default function WidgetThree() {
  return (
    <Card className='micro-widget-v3'>
      <CardBody>
          <Row>
            <Col lg={12} className="micro-widget-search">
                <input type="text" name="" id="" className='form-control' placeholder='search...' />
                <input type="text" name="" id="" className='form-control' placeholder='search...' />
                <button className='micro-search-btn'><FontAwesomeIcon icon={faSearch} /></button>
            </Col>
          </Row>
        </CardBody>
        <CardBody>
        <Row className='micro-icon-lists mx-0'>
              <Col lg={'auto'} className='micro-widget-graphic'>
                <img src='https://picsum.photos/id/64/200/300' className="card-widget-img"></img>
              </Col>
              <Col className='micro-widget-content'>
                <h3 className='card-title'>Doctor name <span className='sub-text'>Qalification</span></h3>
                <p className='card-text'>Spalities</p>
                <p className='card-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero doloribus placeat architecto, cum distinctio repudiandae officia accusamus blanditiis dolore quae alias, mollitia fugit! Ea, recusandae!</p>
              </Col>
            </Row>
            {/* <Row className='micro-icon-lists'>
              <Col lg={'auto'} className='micro-widget-graphic'>
                <img src='https://picsum.photos/id/64/200/300' className="card-widget-img"></img>
              </Col>
              <Col className='micro-widget-content'>
                <h3 className='card-title'>Doctor name <span className='sub-text'>Qalification</span></h3>
                <p className='card-text'>Spalities</p>
                <p className='card-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero doloribus placeat architecto, cum distinctio repudiandae officia accusamus blanditiis dolore quae alias, mollitia fugit! Ea, recusandae!</p>
              </Col>
            </Row>          */}
        </CardBody>
    </Card>
  )
}
