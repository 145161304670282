import React from 'react'
import { Row, Col, Button, InputGroup} from 'reactstrap'
import { Field } from 'formik';
import FormikInputField from "components/forms/formikFields/FormikInputField"

const OtpForm = (props) => {
  const { handleSubmit, isValid, dirty, isSubmitting } = props
  
  return(
    <form onSubmit={handleSubmit}>
      <div>
        <Row>
          <Col xs={12} className="text-left">
              <InputGroup>
                <Field
                  id="verifyNumber"
                  name="verifyNumber"
                  component={FormikInputField}
                  placeholder="Enter verification/otp code" />
              </InputGroup>
            </Col>
          </Row>
          <Button disabled={!isValid || isSubmitting} className="mt-3" color="primary" size="lg" block>Verify</Button>
      </div>
    </form>
  )
}

export default OtpForm