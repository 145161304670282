import moment from 'moment'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import CalWeekDaysMobile from './CalWeekDaysMobile';
import CalWeekAppoMobile from './CalWeekAppoMobile';

export default function CalenderWeekDays(props) {
    const [windowWidth, setWindowWidth] = useState(0);
    

    const [mobWeekDisplay, setMobWeekDisplay] = useState(false);
    const [desktopWeekDisplay, setDesktopWeekDisplay] = useState(false);
    const [weekDates, setWeekDates] = useState([]);

    /* set week */
    useEffect(() => {
        const startOfWeek = moment().startOf('isoWeek');
        const endOfWeek = moment().endOf('isoWeek');

        const days = [];
        console.log(startOfWeek, endOfWeek);
        for (let day = startOfWeek; day <= endOfWeek; day = day.add(1, 'days')) {
            days.push(day.format());
        }

        setWeekDates(days);

        return () => {
            // Cleanup function to clear data
            setWeekDates([]);
        };

    }, [])

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
        if (windowWidth < 993) {
          if(mobWeekDisplay == false){
            setMobWeekDisplay(true);
          }
        } else {
          if(desktopWeekDisplay == false){
            setDesktopWeekDisplay(true);
          }
        }
        console.log(window.innerWidth);
      };
    
      useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);

    const getCurrentDate = (startHour = 9) => {
        const today = moment();
        today.hour(startHour);
        today.minute(0);
        return today;
    };

    const get15MinuteSlots = (datetime, slotInterval = 15, hours = 13) => {
        const slots = [];
        for (let i = 0; i < hours * 60; i += slotInterval) {
            slots.push(moment(datetime).add(i, "minutes"));
        }
        return slots;
    };

    function isCurrentDate(item) {
        return moment().format('MMM Do YY') === moment(item).format('MMM Do YY');
    }

    const renderWeekTr = () => {

        return (
            <>
                {weekDates.map((item, ind) => (
                    <div className="tr-col" key={'tr_col_' + ind}>
                        {renderWeekTd(get15MinuteSlots(getCurrentDate()), item)}
                    </div>
                ))}
            </>
        )

    }

    const calcInactive = (inactiveData, slotSate = '06 16 2023, 09:00 AM') => {
        let out = [false, 0];
        inactiveData.forEach(item => {
            let sdate = moment(item.startTime);
            let edate = moment(item.endTime);
            //let inactiveDef = sdate.diff(edate);
            if (moment(slotSate).isBetween(sdate, edate)) {
                console.log(moment(slotSate).isBetween(sdate, edate), slotSate)
            }

            if (sdate.isSame(moment(slotSate)) || moment(slotSate).isBetween(sdate, edate) || edate.isSame(moment(slotSate))) {
                //console.log(edate.diff(sdate, 'minutes')/15); 
                out = [true, edate.diff(sdate, 'minutes') / 15];
                return;

            }


        });
        return out;
        // console.log(inactiveData);
    }


    const weekAppointClass = (dtime, slot, data) => {
        let out_class = '';
        let expand_num = 0;
        let get_date = moment(dtime).format('MM DD YYYY') + ", " + slot.format('HH:mm A');

        data.forEach(item => {
            let sdate = moment(item.startTime);
            let edate = moment(item.endTime);

            if (sdate.isSame(moment(get_date)) || moment(get_date).isBetween(sdate, edate) || edate.isSame(moment(get_date))) {
                out_class = 'has_appointment';
                if (moment(get_date).isBetween(sdate, edate) || edate.isSame(moment(get_date))) {
                    out_class += ' slot_need_off';
                }
                if (sdate.isSame(moment(get_date))) {
                    expand_num = edate.diff(sdate, 'minutes') / 15;
                } else {
                    expand_num = 0;
                }
                return;
            }


        });

        return [out_class, expand_num];
    }


    const renderWeekTd = (slots, dtime) => {
        return (
            <>
                {slots.map((slot) => (
                    <div className={`td 
                    ${calcInactive(props.calenderData.inactiveTimes, moment(dtime).format('MM DD YYYY') + ", " + slot.format('HH:mm A'))[0] ? 'inactive' : ''}
                    ${" " + weekAppointClass(dtime, slot, props.calenderData.appointments)[0]}
                `}
                        style={{ '--expand_num': weekAppointClass(dtime, slot, props.calenderData.appointments)[1] }}
                        key={'tr_' + slot.format()}>


                        {props.calenderData.appointments.map((item, ind) => (
                            <React.Fragment key={'rweek_' + ind}>  {check_datetime(moment(dtime).format('DD MM YYYY') + ", " + slot.format('HH:mm A'), moment(item.startTime).format('DD MM YYYY, HH:mm A')) ? (
                                <>
                                    <div className={`nexogic-appoint-block ${item.status == 'accepted' ? 'active' : ''} 
                            ${item.status == 'pending' ? 'pending' : ''} 
                            ${item.status == 'reject' ? 'rejected' : ''}
                            `} onClick={(e) => props.popupPos(e)} >
                                        <span className="name">{item.name}</span>
                                        <span className="time"> {moment(item.startTime).format('HH:mm A')} - {moment(item.endTime).format('HH:mm A')}</span>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            </React.Fragment>
                        ))}
                    </div>
                ))}
            </>
        )
    }


    function check_datetime(date_a, date_b) {
        return date_a === date_b;
    }

    return (
        <div className="nexogic-calender-week-table-w">
            {mobWeekDisplay && 
            <div className='nexogic-mob-week-w'>
                <CalWeekDaysMobile week={weekDates} />
                <CalWeekAppoMobile appointments={props.calenderData.appointments} inactiveTimes={props.calenderData.inactiveTimes} popupPos={props.popupPos} />
            </div>
            }
            
            {desktopWeekDisplay && 
            <div className='nexogic-calender-week-table'>
                <div className="tbl-head">
                    <div className="tr">
                        {weekDates.map((item, ind) => <div className={`th ${isCurrentDate(item) ? 'active' : ''}`} key={'tr_week_' + ind}>{moment(item).format('ddd')} <span className="date">{moment(item).format('DD')}</span></div>)}
                    </div>
                </div>

                <div className="tbl-body-week">
                    {renderWeekTr()}
                </div>

            </div>
            }
        </div>
    )
}
