import footer_logo from "assets/images/footer-logo.png";
function NewFooter() {
    return ( 
        <div className="nex-admin-footer">
            <div className="nex-admin-copyright">
              <p><img src={footer_logo} alt="" className="nex-footer-logo" /></p>
              <p className="text-text text-white">{(new Date().getFullYear())} &copy; Nexogic. All Rights Reserved.</p>
            </div>  
            <div className="footer-links">
                    <a className="links"
                  target="_blank"
                  href={`https://nexogic.com/privacy-policy`}
                >
                  Privacy Policy
                </a>
                <a
                  className="links"
                  target="_blank"
                  href={`https://nexogic.com/terms-and-conditions`}
                >
                  Terms and Conditions
                </a>
                     
            </div>
        </div>
     );
}

export default NewFooter;