import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import InstitutesTypeaheadFieldNew from "components/forms/formikFields/InstitutesTypeaheadFieldNew";
import { Field, Formik } from "formik";
import { omit } from "lodash";
import { useState } from "react";
import { withAlert } from "react-alert";
import { Button, Col, Row, Spinner } from "reactstrap";
import { PractitionerService } from "utils/PractitionerService";
import { getAddressName, getLocationName } from "utils/Utils";
import * as Yup from "yup";
import NewInstitutionClinicalTrails from "./NewInstitutionClinicalTrails";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{5})(( x| ext)\d{1,5}){0,1}$/;

export const yearmonth = /^(0[1-9]|10|11|12)-\d{4}$/;

const validationSchema = Yup.object().shape({
  title: Yup.string().max(100).required("This field is required"),
  url: Yup.string().matches(
    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
    "Enter a valid url."
  ),
  institutionDetail: Yup.lazy((value) => {
    if (value && value.onSelectNewInstitutionCollege === 0) {
      return Yup.object().test(
        "",
        "This field is required. You must select from options or add new",
        () => false
      );
    } else if (value && value.onSelectNewInstitutionCollege === 1) {
      return Yup.object();
    } else if (value && value.onSelectNewInstitutionCollege === 2) {
      return Yup.object();
    } else {
      return Yup.mixed().test(
        "",
        "This field is required. You must select from options or add new",
        (value) => value === {}
      );
    }
  }),
  issuedOn: Yup.string()
    .matches(yearmonth, "Please enter valid format (MM-YYYY)")
    .required("This field is required"),
  city: Yup.array().when("institutionDetail.onSelectNewInstitutionCollege", {
    is: 2,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required"),
  }),
  state: Yup.array().when("institutionDetail.onSelectNewInstitutionCollege", {
    is: 2,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required"),
  }),
  country: Yup.array().when("institutionDetail.onSelectNewInstitutionCollege", {
    is: 2,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required"),
  }),
  location: Yup.array().when(
    "institutionDetail.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.array()
        .min(1, "This field is required")
        .required("This field is required"),
    }
  ),
  mainItemName: Yup.string().when(
    "institutionDetail.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().required("This field is required"),
    }
  ),
  phone: Yup.string().when("institutionDetail.onSelectNewInstitutionCollege", {
    is: 2,
    then: Yup.string().when("phone", {
      is: (val) => val !== "",
      then: Yup.string().matches(
        phoneRegExp,
        "Please enter valid Phone Number"
      ),
      otherwise: Yup.string(),
    }),
  }),
  postalCode: Yup.string().when(
    "institutionDetail.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().required("This field is required"),
    }
  ),
  addressLine1: Yup.string().when(
    "institutionDetail.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().required("This field is required"),
    }
  ),
  websiteAddress: Yup.string().when(
    "institutionDetail.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().when("websiteAddress", {
        is: (val) => val !== "",
        then: Yup.string().url("Please enter a valid URL"),
        otherwise: Yup.string(),
      }),
    }
  ),
  category: Yup.string().when(
    "institutionDetail.onSelectNewInstitutionCollege",
    {
      is: 2,
      then: Yup.string().required("This field is required"),
    }
  ),
});

const RenderForm = (props) => {
  const { toggle, handleSubmit, isLoading } = props;

  const onNewInstitute = () => {
    toggleNested();
  };
  const toggleNested = () => {
    props.setFieldValue(`institutionDetail.onSelectNewInstitutionCollege`, 2);
    props.setFieldValue(`institutionDetail.institutionName`, "Other");
    props.setFieldValue(`institutionDetail.guid`, "");
    props.setFieldValue(`city`, []);
    props.setFieldValue(`state`, []);
    props.setFieldValue(`country`, []);
    props.setFieldValue(`location`, []);
    props.setFieldValue(`postalCode`, "");
    props.setFieldValue(`addressLine1`, "");
    props.setFieldValue(`category`, "");
  };

  return (
    <form className="my-form" onSubmit={handleSubmit} id="nex-ctf-modal-form">
      <div>
        <Row form>
          <Col xs={12}>
            <Field
              id="ctf_title"
              name="title"
              autocomplete="title"
              type="text"
              label="Title"
              component={FormikInputField}
              placeholder="Title"
            />
          </Col>
        </Row>
        <Row form>
          <Col xs={12}>
            <Field
              id="ctf_url"
              name="url"
              autocomplete="url"
              type="text"
              label="URL"
              placeholder="E.g: https://www.google.com"
              component={FormikInputField}
            />
          </Col>
        </Row>
        <Row form>
          <Col md={8} id="nex-cf_institution-detail-w">
            <Field
              name="institutionDetail"
              id="institutionDetail"
              autocomplete="institutionDetail"
              type="text"
              label="Issuing Organization"
              placeholder="Issuing Organization"
              component={InstitutesTypeaheadFieldNew}
              onNewInstituteReq={onNewInstitute}
              instituteType={
                "BOARD_OR_REGISTRATION_COUNCIL,CLINICAL_RESEARCH_ORGANIZATIONS,COLLEGE_OR_UNIVERSITY,MEDICAL_ASSOCIATION,MEDICAL_COLLEGE_OR_HOSPITAL,HOSPITAL,NURSING_HOMES,INDIVIDUAL_CLINICS,DIAGNOSTIC_CENTERS,MEDICAL_LABORATORY_OR_DIALYSIS_CENTERS,MEDICINE_STORES,OTHER"
              }
            />
          </Col>
          <Col md={4}>
            <Field
              name={`issuedOn`}
              id={`issuedOn`}
              autocomplete={`issuedOn`}
              type="text"
              label="Issued On"
              component={FormikInputField}
              placeholder="MM-YYYY"
            />
          </Col>
        </Row>
        {props.values?.institutionDetail?.onSelectNewInstitutionCollege ===
          2 && <NewInstitutionClinicalTrails {...props} />}
        {/*<hr className="profile-info-modal-hr mt-0" />*/}
        <div  className="justify-content-end  modal-footer">
          <Col xs="auto" className="action-group">
            {isLoading ? (
              <div className="loading-spiner">
                <Spinner />
              </div>
            ) : (
              <>
              <Button
                id="ctf_btn_submit"
                  className="nexogic_primary_button "
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  id="ctf_btn_cancel"
                  className="nexogic_primary_button_outline"
                  color="primary"
                  type="button"
                  onClick={toggle}
                >
                  Cancel
                </Button>
                
              </>
            )}
          </Col>
        </div>
      </div>
    </form>
  );
};

const ClinicalTrialsForm = (props) => {
  const { selectedItem, practitionerId, getData, toggle, alert } = props;
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");

  const addClinicalTrial = async (value) => {
    const payload = createPayloadMethod(value);
    setLoading(true);
    try {
      const request = PractitionerService.addClinicalTrial(
        payload,
        practitionerId
      );
      request.then(
        (response) => {
          const { status, validationErrors } = response;
          if (status === 200 || status === 201) {
            setTimeout(() => {
              showSuccessMessage("Clinical trial added successfully");
              getData();
              setLoading(false);
              toggle();
            }, 1000);
          } else {
            setLoading(false);
            if (
              validationErrors &&
              validationErrors &&
              validationErrors.length > 0
            ) {
              const error = [];
              validationErrors.forEach((errors) => {
                error.push(errors.message);
              });
              showErrorMessage(error.join(","));
            } else {
              showErrorMessage("Failed to save. Please try again");
            }
          }
          setLoading(false);
        },
        (e) => {
          setLoading(false);
          console.log("error", e.message);
          let errmsg = e.message;
          if (e.response) {
            errmsg =
              e.response.data && !!e.response.data.message
                ? e.response.data.message
                : errmsg;
          }
          showErrorMessage(errmsg);
        }
      );
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.message);
    }
  };

  const updateclinicalTrials = async (value) => {
    const payload = createPayloadMethod(value);
    setLoading(true);
    try {
      const request = PractitionerService.updateClinicalTrial(
        payload,
        practitionerId,
        value.guid
      );
      request.then(
        (response) => {
          const { data, validationErrors } = response;
          if (data?.guid) {
            setTimeout(() => {
              showSuccessMessage("Clinical trial updated successfully");
              getData();
              setLoading(false);
              toggle();
            }, 1000);
          } else {
            setLoading(false);
            if (
              validationErrors &&
              validationErrors &&
              validationErrors.length > 0
            ) {
              const error = [];
              validationErrors.forEach((errors) => {
                error.push(errors.message);
              });
              showErrorMessage(error.join(","));
            } else {
              showErrorMessage("Failed to save. Please try again");
            }
          }
        },
        (e) => {
          console.log(e.message);
          setLoading(false);
          let errmsg = e.message;
          if (e.response) {
            errmsg =
              e.response.data && !!e.response.data.message
                ? e.response.data.message
                : errmsg;
          }
          showErrorMessage(errmsg);
        }
      );
    } catch (e) {
      setErrorMessage(e.message);
      setLoading(false);
    }
  };

  const createPayloadMethod = (value) => {
    value = omit(value, ["issuingDate"]);
    const { issuedOn, url, title } = value;
    let payload = value;
    const createPayload = {
      issuedOn: issuedOn,
      institutionDetail: {
        ...(!payload.institutionDetail.guid && {
          address: {
            addressLine1: payload?.addressLine1 ?? "",
            addressLine2: payload?.addressLine2 ?? "",
            addressType: payload?.addressType ?? "BILLING_ADDRESS",
            city: getAddressName(payload?.city),
            state: getAddressName(payload?.state),
            country: getAddressName(payload?.country),
            location: getLocationName(payload?.location),
            postalCode: payload?.postalCode ?? "",
          },
        }),
        ...(payload.institutionDetail.guid && {
          guid: payload.institutionDetail.guid,
        }),
        name: payload.mainItemName
          ? payload.mainItemName
          : payload.institutionDetail.name,
        phone: payload?.phone ?? "",
        websiteUrl: payload?.websiteAddress ?? "",
        ...(payload.category && {
          type: payload.category,
        }),
      },
      url: url,
      title: title,
    };
    return createPayload;
  };

  return (
    <>
      {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={selectedItem}
        onSubmit={(values) => {
          if (values.guid) {
            updateclinicalTrials(values);
          } else {
            addClinicalTrial(values);
          }
        }}
        children={(props) => {
          return (
            <>
              <RenderForm toggle={toggle} {...props} isLoading={isLoading} />
            </>
          );
        }}
      ></Formik>
    </>
  );
};
export default withAlert()(ClinicalTrialsForm);
