import React, { useState, useEffect } from "react"
import { Row, Col, Button, InputGroup,FormGroup, Spinner,Label,Input } from 'reactstrap'
import { Field } from 'formik';
import { withAlert } from "react-alert"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import FormikInputField from "components/forms/formikFields/FormikInputField"
import FormikCheckboxField from "components/forms/formikFields/FormikCheckboxField"
import {
    User_Types
  } from "utils/Constants";
const LoginForm = (props) => {
    const { handleSubmit, isSubmitting, isValid, errors } = props
    const [showPassword, onShowPassword] = useState(false)
    const toggleShowPassword = () => onShowPassword(!showPassword)


    return (
        <form id="loginForm" onSubmit={handleSubmit}>            
            <InputGroup className="mt-3" size="medium">
                <Field
                    id="email"
                    name="email"
                    type="email"
                    component={FormikInputField}
                    label="Email Address"
                    placeholder="Email Address" />
            </InputGroup>
            <InputGroup className="" size="medium">
                <div className="nexogic-password-field-wrap">
                    <Field
                        id="password"
                        name="password"
                        type={showPassword ? "text " : "password"}
                        component={FormikInputField}
                        label="Password"
                        placeholder="Password" />
                    {!!errors.password === false &&
                        <FontAwesomeIcon onClick={toggleShowPassword} className="show-password " color="#ccc" icon={!showPassword ? faEyeSlash : faEye} />
                    }
                </div>
            </InputGroup>
            <Row className="mb-4 mt-1 nex-remember-me-tag ">
                <Col xs={6} className="text-left">
                    <InputGroup className="align-items-center ">
                        <Field
                            id="rememberMe"
                            name="rememberMe"
                            type="checkbox"
                            component={FormikCheckboxField}
                        />
                        <label htmlFor="rememberMe" className="form-check-label">Remember me</label>
                    </InputGroup>

                </Col>
                <Col xs={6} className="justify-content-end pl-0 d-flex align-items-center">
                    <Link to="forgot-password" className="card-text">Forgot your Password?</Link>
                </Col>
            </Row>
            {isSubmitting ?
                <div className="loading-spiner text-center mb-2"><Spinner /></div>
                :
                <Button id="btn_sign_in" color="primary" size="lg" block className="nex-btn-primary" type="submit" disabled={!isValid}>Sign In</Button>
            }
        </form>
    )
}

export default withAlert()(LoginForm)