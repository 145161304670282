import { UserAuth } from "context/AuthContext";
import { useState } from 'react';
import { Link } from "react-router-dom"; import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import logo_white from "assets/images/logo-white-transparent.png";
import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import SocialIconsForm from "./Components/SocialIconsForm";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import SocialIconsSearchProfile from "./Components/SocialIconsSearchProfile";

const ClaimSocialProfile = (props) => {
  const history = useHistory();
  const { claimProfileId } = useParams();
  const { googleSignUp,facebookSignUp, appleSignUp, signUpWIthEmail, signInWithPhone, signUpWIthMobileOTP, user } = UserAuth();
  const [isPatient, setIsPatient] = useState(false);
  const [userProfileId, setUserProfileId] = useState("");
  const [submittedData, setSubmittedData] = useState(null);
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required'),
  });

  const searchProfileByGoogle = async () => {
    try{
      const signedInUser = await googleSignUp();
      const { name, email, idToken, refreshToken, profiledetails, phoneNumber } = signedInUser;
      const pname = name.replace(/(Dr\.|Mr\.|Mrs\.|Ms\.|Prof\.)\s*/i, '').trim();
      const fname = pname?.split(' ')[0];
      const url = `/claim-search-profiles/${fname}`;
      history.push(url,{signedInUser,fname})
    }catch(error){
      console.error('Google sign-in failed:', error);
    }
    
  }
  const searchProfileByFacebook = async () => {
    try{
      const signedInUser = await facebookSignUp();
      const { name, email, idToken, refreshToken, profiledetails, phoneNumber } = signedInUser;
      const pname = name.replace(/(Dr\.|Mr\.|Mrs\.|Ms\.|Prof\.)\s*/i, '').trim();
      const fname = pname?.split(' ')[0];
      const url = `/claim-search-profiles/${fname}`;
      history.push(url,{signedInUser,fname})
    }catch(error){
      console.error('Facebook sign-in failed:', error);
    }
  }
  const searchProfileByApple = async () => {
    try{
      const signedInUser = await appleSignUp();
      const { name, email, idToken, refreshToken, profiledetails, phoneNumber } = signedInUser;
      const pname = name.replace(/(Dr\.|Mr\.|Mrs\.|Ms\.|Prof\.)\s*/i, '').trim();
      const fname = pname?.split(' ')[0];
      const url = `/claim-search-profiles/${fname}`;
      history.push(url,{signedInUser,fname})
    }catch(error){
      console.error('Apple sign-in failed:', error);
    }
  }

  const handleSubmit = (values, { setSubmitting }) => {
    if (values?.name) {
      const url = `/search-profiles/${values?.name}`;
      history.push(url)
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (claimProfileId) {
      setUserProfileId(claimProfileId);
    }
  }, [claimProfileId]);

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className="nex-bg-light-blue">
      <main className="nex-login-register--w">
        <div className="container sm d-flex">
          <div className="nex-form-aside">
            <div className="logo">
              <a href="/"><img src={logo_white} alt="" /></a>
            </div>
            <h2 className="nex-text-xxl">Hey, <br />
              Search with your <br />
              name</h2>
          </div>
          <div className="nex-form-fields--w">
            <div className="nex-form-top-slot">
              <p className="nex-text-lg text-right">Are you a Patient?{' '}
                <Link to={'/u/register'}>Register here</Link>
              </p>
            </div>
            <div className="nex-form-center-slot">
              <div className="nex-form-container">
                {/* <SocialIconsForm handleGoogle={handleGoogleSignUp} handleApple={handleAppleSignUp} handleEmail /> */}
                <SocialIconsSearchProfile handleGoogle={searchProfileByGoogle} handleFacebook={searchProfileByFacebook} handleApple={searchProfileByApple} handleEmail />
                <div className="nex-or-txt--w">or</div>
                <div className="pt-3">
                <Formik
                  initialValues={{ name: '' }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="form-group-fields--w">
                        <div className="form-group">
                          <Field type="text" name="name" className="form-control" placeholder="Tell us your name" />
                          <ErrorMessage name="name" component="div" className="error-message text-danger mt-1 fst-normal" />
                        </div>
                        <div className="form-group">
                          <button type="submit" disabled={isSubmitting} className="btn btn-primary w-100">
                            {isSubmitting ? 'Submitting...' : 'Continue'}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                </div>
              </div>
            </div>
            <div className="nex-form-bottom-slot">
              <p className="nex-text-lg text-center">Are you a member?{' '}
                <Link to="/login" className="nex-link-dark"> Login </Link>
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ClaimSocialProfile;