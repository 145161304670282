import React from 'react'
import { useState } from 'react';
import { Card, CardBody, Collapse } from 'reactstrap';
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";

export default function CollapseablePannel(props) {
  const [pannel_on, setPannel_on] = useState(true);
  return (
    <Card className={`nexogic-card-color-header ${props.bgcolor} ${pannel_on ? 'expanded' : ''} ${props.className ? props.className : ''}`} id={props.id} >
      {!props.noHeader &&
        <div className={`card-header py-3 ${props.bgcolor}`}><h2>{props.title}</h2>
          {props.headAction}
          <button id="agd_btn_desc" className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
            <img src={pannel_arrow} alt="" />
          </button>
        </div>
      }
      <Collapse isOpen={pannel_on} >
        <CardBody className={`${props.bgcolor}`}>
          {props.children}
        </CardBody>
      </Collapse>
    </Card>
  )
}
