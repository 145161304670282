import verifyMobileImg from "assets/images/otp-flow/verifyMobile.svg";
import logo from "assets/images/logo-white.png";
import Header from "common/Header";
import { Formik } from "formik";
import { withAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Col, Container } from "reactstrap";
import { authSetUser } from "redux-helpers/ActionCreator";
import { emailNonNumericRegEx } from "utils/Constants";
import { IdentityService } from "utils/IdentityService";
import { PractitionerService } from "utils/PractitionerService";
import { UserAuth } from 'context/AuthContext';
import {
  getUser,
  resetAuthSession,
  setLoggedInUserInstitutions,
  setOAuthTokens,
  setOAuthUser,
  setUser
} from "utils/UserHelper";
import * as Yup from "yup";
import LoginForm from "./components/LoginForm";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import SocialLoginForm from "./components/SocialLoginForm";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
// import "./style.scss";

const validationSchema = Yup.object({
  email: Yup.string()
    .max(100)
    .matches(emailNonNumericRegEx, "Please Enter an valid Email")
    .required("This field is required"),
  password: Yup.string()
    .required("This field is required")
});

const LoginSocial = (props) => {
  const [otpSent, setOtpSent] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [verificationId, setVerificationId] = useState(null);
  // const googleProvider = new GoogleAuthProvider();
  // const appleProvider = new AppleAuthProvider();
  const { googleSignIn, appleSignIn, signInWIthEmail, signInWithPhone, verifyOTP, user } = UserAuth();
  const dispatch = useDispatch();
  const rememberd = JSON.parse(secureLocalStorage.getItem("nexogic.rem_u_cred"));
  const history = useHistory();
  const initialValues = {
    email: rememberd ? rememberd.email : "",
    password: rememberd ? rememberd.password : "",
    rememberMe: rememberd ? true : false,
  };

  const jobsDetailstUrl = new URLSearchParams(window.location.search).get('jobdetails');
  
  //----------------------------------------------------New Methods------------------------------------------

  const getUserDetails = (userDetails, signedUser) => {
    const { accessToken, refreshToken } = signedUser?.stsTokenManager;
    const { types, profileId } = userDetails;


    if (types.includes("PATIENT")) {
      setOAuthTokens(accessToken, refreshToken);
      setOAuthUser(userDetails);
      setUser(userDetails);
      dispatch(authSetUser(userDetails));
      history.push(`/home`);
      // setSubmitting(false);
      return;
    }
    setOAuthTokens(accessToken, refreshToken); // remove
    // step2: get practitioner by ID
    PractitionerService.gePractitionersDetails(profileId)
      .then((practResp) => {
        if (practResp && practResp.data) {
          const data = practResp.data;
          setOAuthTokens(accessToken, refreshToken);
          setOAuthUser(userDetails);
          setUser(data);
          dispatch(authSetUser(data));
          if (jobsDetailstUrl !== null) {
            history.push(`/jobs/${jobsDetailstUrl}`)
          } else
            history.push(`/home`);
        }
        // setSubmitting(false);
      })
      .catch((error) => {
        console.log(
          "PractitionerService.gePractitionersDetails error",
          error
        );
        props.alert.error(error.message);
        resetAuthSession();
        // setSubmitting(false);
      });
  }

  const handleGoogleSignIn = async () => {
    try {
      const signedInUser = await googleSignIn();
      if (signedInUser?.accessToken) {
        try {
          IdentityService.UserAuthentication(signedInUser?.accessToken)
            .then((response) => {
              if (response.status === 200) {
                const { data } = response;
                getUserDetails(data, signedInUser);
                // history.push('/user-info-validate')
              } else {
                history.push('/register-social')
              }
            })
            .catch((error) => {
              console.log("Login Issue..", error);
            })
        } catch (apiError) {
          console.error('API call failed:', apiError);
          history.push('/login');
        }
      }
    } catch (error) {
      console.error('Google sign-in failed:', error);
    }
  };

  const handleAppleLogin = async () => {
    try {
      const signedInUser = await appleSignIn();
      if (signedInUser?.accessToken) {
        try {
          IdentityService.UserAuthentication(signedInUser?.accessToken)
            .then((response) => {
              if (response.status === 200) {
                const { data } = response;
                getUserDetails(data, signedInUser);
                // history.push('/user-info-validate')
              } else {
                history.push('/register', {
                  data: { user }
                })
              }
            })
            .catch((error) => {
              console.log("Login Issue..", error);
            })
        } catch (apiError) {
          console.error('API call failed:', apiError);
          history.push('/login');
        }
      }
    } catch (error) {
      console.error('Apple sign-in failed:', error);
    }
  };

  const handleEmailPasswordLogin = async (values, setSubmitting) => {
    const { email, password } = values;
    try {
      const signedInUseremail = await signInWIthEmail(email, password);
      if (signedInUseremail?.accessToken) {
        try {
          IdentityService.UserAuthentication(signedInUseremail?.accessToken)
            .then((response) => {
              if (response.status === 200) {
                const { data } = response;
                getUserDetails(data, signedInUseremail);
                // history.push('/user-info-validate')
              } else {
                history.push('/register', {
                  data: { user }
                })
              }
            })
            .catch((error) => {
              console.log("Login Issue..", error);
            })
        } catch (apiError) {
          console.error('API call failed:', apiError);
          history.push('/login');
        }
      }
    } catch (error) {
      console.error('Email/Password sign-in failed:', error);
    } finally {
      setSubmitting(false);
    }
  };

  
  const handleSendOtp = async (phoneNumber, setSubmitting) => {
    await signInWithPhone(phoneNumber)
      .then((confirmationResult) => {        
        showSuccessMessage("OTP sent successfully");
        setVerificationId(confirmationResult.verificationId);
        setOtpSent(true);
      })
      .catch((error) => {
        console.error('Error sending OTP:', error);
      })
      .finally(() => setSubmitting(false));
  };

  const handleVerifyOtp = async (code) => {
    setIsActive(true);
    try{
    const signedInUser = await verifyOTP(code)
    if (signedInUser?.accessToken) {
      try {
        IdentityService.UserAuthentication(signedInUser?.accessToken)
          .then((response) => {
            if (response.status === 200) {
              const { data } = response;
              getUserDetails(data, signedInUser);
              // history.push('/user-info-validate')
            } else if(response.status === 404){
              setOtpSent(false);
              setIsActive(false);
              // history.push('/register-social')
              showErrorMessage("Please verify the information you entered and try again, or consider signing up for a new account.");
            }
          })
          .catch((error) => {
            console.log("Login Issue..", error);
          })
      } catch (apiError) {
        console.error('API call failed:', apiError);
        history.push('/login');
      }
    }
  }catch (error) {
      console.error('Mobile sign-in failed:', error);
    }
  };

//   useEffect(() => {
//     document.body.classList.add('sign-up-page');
//     document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "Sign In";
//     return () => {
//       document.body.classList.remove('sign-up-page');
//       document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "";
//     }
// }, [])

  //----------------------------------------------------New Methods Ends------------------------------------------

  return (
    <section>
      <div className="nexogic-bg-light">
        <Header />
        <main className="theme-body-bg nexogic-dashboard-card">
          <Container className="nexogic-container-gap">
            <div className="nexogic-login-register-card card border-0 bg-transparent">
              <div className="row">
                <Col
                  lg={7}
                  md={6}
                  xs={12}
                  className="nexogic-form-side-content text-center"
                >
                  <div className="nexogic-form-info ">
                    <p className="hide-on-app"><img src={logo} alt="" className="nexogic-filter-white" /></p>
                    <p className="card-text text-white mb-5 hide-on-app">Login to Your Account</p>
                    <p>
                      <img
                        src={verifyMobileImg}
                        className="img-fluid"
                        alt="nexogic"
                      />
                    </p>
                  </div>
                </Col>
                <Col lg={5} md={6} xs={12} className="nexogic-form-content card-body p-0">
                  <div className="nexogic-form-container">
                    <SocialLoginForm {...props}
                      onEmailPasswordLogin={handleEmailPasswordLogin}
                      onGoogleLogin={handleGoogleSignIn}
                      onAppleLogin={handleAppleLogin}
                      onSendOtp={handleSendOtp}
                      onVerifyOtp={handleVerifyOtp}
                      otpSent={otpSent} 
                      isActive={isActive}/>
                    <p className="card-text text-center mt-4 fw-semibold">
                      Don't have an account?{" "}
                      <Link
                        to={`/register`}
                      >
                        Sign Up
                      </Link>{" "}
                    </p>
                  </div>
                </Col>
              </div>
            </div>
          </Container>
        </main>
      </div>
    </section>
  );
};
export default withAlert()(LoginSocial);
