import Header from "common/Header";
import VerifyOTP from "components/OTPFlow/VerifyOTP";
import React, { useEffect, useState, createRef } from "react";
import { withAlert } from "react-alert";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { IdentityService } from "utils/IdentityService";
import { cleanUpPhoneNumber } from "utils/Utils";

const ClaimVerifyOTP = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { type } = useParams();
  const [resendLoading, setResendLoading] = useState(false);
  const [canResendOTP, setCanResendOTP] = useState(false);
  const [error, setError] = useState(null);
  const [guid, setGuid] = useState("");
  const location = useLocation();
  const [userProfileId, setUserProfileId] = useState("");
  const otpRef = createRef();

  useEffect(() => {
    setGuid(state.data.guid);
    if (!["email", "phone"].includes(type)) return history.replace("/");
  }, [state]);

  useEffect(() => {
    setError(error);
    setTimeout(() => {
      setError(null);
    }, 5000);
  }, [error]);

  useEffect(() => {
    const { state } = location;
    const { data } = state;
    const { profileId } = data;
    if (profileId) {
      setUserProfileId(profileId);
    }
  }, [location]);

  const onSubmit = (e, verifyCode) => {
    e.preventDefault();
    setError(null);
    if (!!verifyCode === false || verifyCode.length !== 6) {
      setError("Please fill OTP code");
      return false;
    }
    const payload = {
      type: state.data.userType,
      otp: verifyCode,
    };
    IdentityService.VerifyRegistrationOTP(guid, type, payload)
      .then((response) => {
        if (response.status === 200) {
          history.push(`${state.data.userType === "PATIENT" ? '/register/user-account' :'/register/onboarding'}`, {///register/user-account
            email: response.data.emailAddress,
            phone: cleanUpPhoneNumber(response.data.phoneNumber),
            guid: response.data.id,
            profileId: userProfileId,
          });
        } else {
          if (response.validationErrors) {
            response.validationErrors.map(() =>
              setError("OTP can not be verified")
            );
          } else if (response.message) {
            setError(response.message);
          } else {
            setError("Something went wrong. Please try again");
          }
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const resendOTPVerification = (e) => {
    otpRef.current.__clearvalues__();
    setError(null);
    setResendLoading(true);
    const payload = {
      registrationGUID: guid,
    };
    IdentityService.ResendRegistrationOTP(guid, type, payload).then(
      (response) => {
        if (response.status === 200) {
          setCanResendOTP(false);
        } else {
          if (response.validationErrors) {
            response.validationErrors.map((err) =>
              setError(`${err.field} ${err.message}`)
            );
          } else if (response.message) {
            setError(response.message);
          } else {
            setError("Something went wrong. Please try again");
          }
        }
        setResendLoading(false);
      }
    );
  };

  // console.log("ClaimVerifyOTP----------------------------",state.data);
  return (
    <section id="claimProfile">
      <div className="nexogic-bg-light">
        <Header />
        <main className="theme-body-bg nexogic-dashboard-card">
          <Container className="nexogic-container-gap">
            <VerifyOTP
              handleSubmit={onSubmit}
              error={error}
              resendOTPVerification={resendOTPVerification}
              handleResendLoading={resendLoading}
              canResendOTP={canResendOTP}
              setCanResendOTP={setCanResendOTP}
              data={state.data}
              guid={guid}
              ref={otpRef}
            />
          </Container>
        </main>
      </div>
    </section>
  );
};

export default withAlert()(ClaimVerifyOTP);
