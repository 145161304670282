import React, { useState } from "react";
import RenderDocumentFormik from "./Components/RenderDocumentFormik";
import { withAlert } from "react-alert";
import { Col, Row } from "reactstrap";
const DocumentForm = (props, { onBack }) => {
  const [documentType, setDocumentType] = useState([
    {
      id: 0,
      type: "PRIMARY",
      formCount: 1,
    },
    // {
    //   id: 1,
    //   type: "PRIMARY",
    //   formCount: 2,
    // },
  ]);

  const addNewDocument = (newDocument) => {
    setDocumentType((prev) => {
      return [...prev, newDocument];
    });
  };
  const deleteDocument = (id) => {
    setDocumentType((prev) => {
      return prev.filter((obj, index) => {
        return index !== id;
      });
    });
  };

  return (
    <div className="nexogic-onboarding-form nexogic-form-content nex-app-document-upload nex-form-container">
        <div className="nex-form-section" data-aos="fade-up" data-aos-duration="1400" data-aos-delay="300">
            <div className="space-bottom-lg">
                <h2 className="nex-text-xxl text-center text-dark mb-2">Add Documents</h2>
                <p className="text-center text-gray">Provide your government issued ID and medical registration for verification of your identity</p>
            </div>
            <RenderDocumentFormik
              documentTypes={documentType}
              onBack={onBack}
              onAddDocument={addNewDocument}
              onDelete={deleteDocument}
              {...props}
            />
        </div>
    </div>
  );
};

export default withAlert()(DocumentForm);
