import QuickPractitioner from 'components/QuickPractitioner/QuickPractitioner';
import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { encrypt } from 'utils/EncryptDecrypt';
import { createImageFromInitials, getGenderDetails, getRandomColor } from 'utils/Utils';

export default function PatientPersonal(props) {
    const { patient } = props;

    return (
        <>
            {patient &&
                <div>
                    <div className='nex-list-card-v2 nexogic-so-list-card card'>
                        <div className="card-body">
                            <div className='nex-stack'>
                                <QuickPractitioner
                                    className="lg"
                                    name={`${patient?.title} ${patient?.firstName} ${patient?.lastName}`}
                                    titleClass='nex-text-xl'
                                    avatar={<img src={createImageFromInitials(100, patient.firstName + " " + patient.lastName, getRandomColor(encrypt(21).toString()))} className="nex-avatar lg" width="70" height="70" />}
                                >
                                    <p className='nex-text '>Email : {patient?.emailAddress ? patient?.emailAddress : "N/A"}</p>


                                </QuickPractitioner>

                                <div className="info md">
                                    <p className='nex-text '>Gender : {patient?.gender ? getGenderDetails(patient?.gender) : "N/A"}</p>
                                    <p className='nex-text '>Dob : {patient?.dateOfBirth ? patient?.dateOfBirth : "N/A"}</p>
                                </div>

                                <div className="info md">
                                    <p className='nex-text '>Phone : {patient?.phoneNumber ? patient?.phoneNumber : "N/A"}</p>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className='nex-list-card-v2 nexogic-so-list-card card'>
                        <div className="card-body">
                            <h3 className='nex-text-lg'>Basic Health Information</h3>
                            <div className='nex-stack'>
                                <div className="info lg">
                                    <p className='nex-text '>Height : {patient?.height ? patient?.height : "N/A"}</p>
                                    <p className='nex-text '>Weight : {patient?.weight ? patient?.weight : "N/A"}</p>
                                </div>
                                <div className="info lg">
                                    <p className='nex-text '>Blood Group : {patient?.bloodGroup ? patient?.bloodGroup : "N/A"}</p>
                                    <p className='nex-text '>Language Spoken : {(patient?.languages && patient?.languages.length > 0) ? patient?.languages : "N/A"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    {/** 
                    <Card className='mb-4'>
                        <CardBody>
                            <div className="row">
                                <div className="col-lg-6 nexogic-patient-quick-info">
                                    <div className="graphic">
                                        <div className="card-graphic"><img src={createImageFromInitials(100, patient.firstName + " " + patient.lastName, getRandomColor(encrypt(21).toString()))} className="rounded-circle" width="70" height="70" /></div>
                                    </div>
                                    <div className="info">
                                        <h2 className='card-title big'>{patient?.title} {" "}{patient?.firstName} {" "}{patient?.lastName} </h2>
                                        <p className='card-text'>Gender : {patient?.gender ? getGenderDetails(patient?.gender) : "N/A"}</p>
                                        <p className='card-text'>Dob : {patient?.dateOfBirth ? patient?.dateOfBirth : "N/A"}</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 nexogic-key-val-column">
                                    <ul className='full_list left-border'>
                                        <li><span>Phone Number</span>{patient?.phoneNumber ? patient?.phoneNumber : "N/A"}</li>
                                        <li><span>Email Address</span> {patient?.emailAddress ? patient?.emailAddress : "N/A"}</li>
                                    </ul>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    
                    <Card className='mb-4'>
                        <CardBody>
                            <div className="nexogic-key-val-column">
                                <h3 className='nexogic-subsection-title'>Basic Health Information</h3>
                                <ul>
                                    <li><span>Height</span>{patient?.height ? patient?.height : "N/A"}</li>
                                    <li><span>Weight</span>{patient?.weight ? patient?.weight : "N/A"}</li>
                                    <li><span>Blood Group</span>{patient?.bloodGroup ? patient?.bloodGroup : "N/A"}</li>
                                    <li><span>Language Spoken</span>{(patient?.languages && patient?.languages.length > 0) ? patient?.languages : "N/A"}</li>
                                </ul>
                            </div>
                        </CardBody>
                    </Card>
                    */}
                    {patient?.address &&
                    <>
                    <div className='nex-list-card-v2 nexogic-so-list-card card'>
                    <div className="card-body">
                        <h3 className='nex-text-lg'>Address</h3>
                        <div className='nex-stack'>
                            <div className="info lg">
                                <p className='nex-text '>Address Line 1 : {patient?.address?.addressLine1}</p>
                                <p className='nex-text '>Address Line 2 : {patient?.address?.addressLine2}</p>
                                <p className='nex-text '>City : {patient?.address?.city}</p>
                            </div>
                            <div className="info lg">
                                
                                <p className='nex-text '>State : {patient?.address?.state}</p>
                                <p className='nex-text '>Country : {patient?.address?.country}</p>
                                <p className='nex-text '>Zip Code : {patient?.address?.zipCode}</p>
                            </div>
                        </div>
                    </div>
                </div>
                        {/** <Card className='mb-4'>
                            <CardBody>
                                <div className="nexogic-key-val-column">
                                    <h3 className='nexogic-subsection-title'>Address</h3>
                                    <ul>
                                        <li><span>Address Line 1</span>{patient?.address?.addressLine1}</li>
                                        <li><span>Address Line 2</span>{patient?.address?.addressLine2}</li>
                                        <li><span>City</span> {patient?.address?.city}</li>
                                        <li><span>State</span> {patient?.address?.state}</li>
                                        <li><span>Country</span>{patient?.address?.country}</li>
                                        <li><span>Zip Code</span> {patient?.address?.zipCode}</li>
                                    </ul>
                                </div>
                            </CardBody>
                        </Card>*/}
                        </>
                    }
                </div>
            }
        </>
    )
}
