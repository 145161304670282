import CommonLoader from 'common/CommonLoader';
import React, { useEffect, useState } from 'react'
import { withAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { FAILTOSAVERECOED } from 'utils/Constants';
import { PatientService } from 'utils/PatientService';
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

export default withAlert()(function Lifestyle(props) {
  const [isLoading, setIsLoading] = useState(true);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const smokingHabits = ['Non-Smoker', 'Occasional Smoker', 'Daily Smoker', 'Heavy Smoker', 'Trying to Quit'];
  const drinkingHabits = ['Non-Drinker', 'Occasional Drinker', 'Social Drinker', 'Moderate Drinker', 'Heavy Drinker', 'Trying to Quit'];
  const physicalActivityLevel = ['Sedentary', 'Low Active', 'Moderately Active', 'Very Active', 'Super Active'];
  const foodPreferences = ['Vegetarian', 'Pescatarian', 'Lacto-Vegetarian', 'Ovo-Vegetarian', 'Non-Vegetarian', 'Flexitarian'];

  const [doesSmoke, setDoesSmoke] = useState(false);
  const [doesConsumeAlcohol, setDoesConsumeAlcohol] = useState(false);
  const [isPhysicallyActive, setIsPhysicallyActive] = useState(false);
  const [selectedSmokingHabit, setSelectedSmokingHabit] = useState("");
  const [selectedDrinkingHabit, setSelectedDrinkingHabit] = useState("");
  const [selectedPhysicalActivityLevel, setselectedPhysicalActivityLevel] = useState("");
  const [selectedFoodPreference, setSelectedFoodPreference] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);


  const handleSelectChange = (event, type) => {
    if (type === "SMOKING")
      setSelectedSmokingHabit(event.target.value);

    if (type === "DRINKING")
      setSelectedDrinkingHabit(event.target.value);

    if (type === "PHYSICAL_ACTIVITY")
      setselectedPhysicalActivityLevel(event.target.value);

    if (type === "FOOD_PREFERENCE")
      setSelectedFoodPreference(event.target.value);
  };

  const onSubmit = () => {
    setIsSubmitting(true);
    let payload = [
      {
        "code": "SMOKING",
        "answer": `${doesSmoke ? "true" : "false"}`,
        "otherAnswer": selectedSmokingHabit
      },
      {
        "code": "DRINKING",
        "answer": `${doesConsumeAlcohol ? "true" : "false"}`,
        "otherAnswer": selectedDrinkingHabit
      },
      {
        "code": "PHYSICAL_ACTIVITY",
        "answer": `${isPhysicallyActive ? "true" : "false"}`,
        "otherAnswer": selectedPhysicalActivityLevel
      },
      {
        "code": "FOOD_PREFERENCE",
        "answer": "true",
        "otherAnswer": selectedFoodPreference
      }
    ]

    updateLifestyle(currentUser?.profileId, payload);
  }

  const getLifestyle = (id) => {
    PatientService.getPatientLifestyle(id)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          data.map((e) => {
            if (e?.code === "SMOKING" && e?.answer) {
              setDoesSmoke(true);
              setSelectedSmokingHabit(e?.otherAnswer)
            }

            if (e?.code === "DRINKING" && e?.answer) {
              setDoesConsumeAlcohol(true);
              setSelectedDrinkingHabit(e?.otherAnswer)
            }

            if (e?.code === "PHYSICAL_ACTIVITY" && e?.answer) {
              setDoesConsumeAlcohol(true);
              setselectedPhysicalActivityLevel(e?.otherAnswer)
            }

            if (e?.code === "FOOD_PREFERENCE" && e?.answer)
              setSelectedFoodPreference(e?.otherAnswer)
          })
          setIsLoading(false);
        }
        else {
          setIsLoading(false);
          showErrorMessage(FAILTOSAVERECOED);
        }
      })
  }

  const updateLifestyle = (id, payload) => {
    PatientService.updatePatientLifestyle(id, payload)
      .then((response) => {
        const { status, data } = response;
        if (status) {
          showSuccessMessage("Lifestyle details updated successfully.");
          setIsSubmitting(false);
        }
        else {
          showErrorMessage(FAILTOSAVERECOED);
          setIsSubmitting(false);
        }
      })
  }

  useEffect(() => {
    getLifestyle(currentUser?.profileId);
  }, [currentUser?.profileId])


  return (
    <div className='card border-0'>
      {isLoading
        ?
        <div className="text-center mb-4">
          <CommonLoader />
        </div>
        :
        <div className="card-body p-0">
          <div className="row need-margin">
            <div className="col-lg-6">
              <div className="mb-3 form-group">

                <label for="" className="form-label">Do you Smoke ?</label>
                <select name="" id="" className="form-control" value={doesSmoke}
                  onChange={(e) => { e.target.value === "true" ? setDoesSmoke(true) : setDoesSmoke(false) }}
                >
                  <option key={false} value="false">No</option>
                  <option key={true} value="true">Yes</option>
                </select>

                {doesSmoke &&
                  <select name="" id="" className="form-control mt-3" value={selectedSmokingHabit} onChange={(e) => handleSelectChange(e, "SMOKING")}>
                    <option value="">Select Smoking Habits</option>
                    {smokingHabits.map((sHabit) => (
                      <option key={sHabit} value={sHabit}>{sHabit}</option>
                    ))}
                  </select>
                }
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3 form-group">

                <label for="" className="form-label">Do you consume Alcohol ?</label>
                <select name="" id="" className="form-control" value={doesConsumeAlcohol}
                  onChange={(e) => { e.target.value === "true" ? setDoesConsumeAlcohol(true) : setDoesConsumeAlcohol(false) }}
                >
                  <option key={false} value="false">No</option>
                  <option key={true} value="true">Yes</option>
                </select>

                {doesConsumeAlcohol &&
                  <select name="" id="" className="form-control mt-3" value={selectedDrinkingHabit} onChange={(e) => handleSelectChange(e, "DRINKING")}>
                    <option value="">Select Alcohol Consumption</option>
                    {drinkingHabits.map((dHabit) => (
                      <option key={dHabit} value={dHabit}>{dHabit}</option>
                    ))}
                  </select>
                }
              </div>
            </div>
          </div>

          <div className="row need-margin">
            <div className="col-lg-6">
              <div className="mb-3 form-group">
                <label for="" className="form-label">Are you Physically Active ?</label>
                <select name="" id="" className="form-control" value={isPhysicallyActive}
                  onChange={(e) => { e.target.value === "true" ? setIsPhysicallyActive(true) : setIsPhysicallyActive(false) }}
                >
                  <option key={false} value="false">No</option>
                  <option key={true} value="true">Yes</option>
                </select>
                {isPhysicallyActive &&
                  <select name="" id="" className="form-control mt-3" value={selectedPhysicalActivityLevel} onChange={(e) => handleSelectChange(e, "PHYSICAL_ACTIVITY")} >
                    <option value="">Select Activity Label</option>
                    {physicalActivityLevel.map((activity) => (
                      <option key={activity} value={activity}>{activity}</option>
                    ))}
                  </select>
                }
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3 form-group">
                <label for="" className="form-label">Food prefarence</label>
                <select name="" id="" className="form-control" value={selectedFoodPreference} onChange={(e) => handleSelectChange(e, "FOOD_PREFERENCE")}>
                  <option value="">Select Food Preference</option>
                  {foodPreferences.map((food) => (
                    <option key={food} value={food}>{food}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="row mt-3 modal-footer px-0 pb-0">
            <div className="col d-flex justify-content-end action-group px-0 m-0">
              {isSubmitting ? (
                <div className="loading-spiner">
                  <Spinner />
                </div>
              ) : (
                <button type='submit' className='nexogic_primary_button' onClick={() => onSubmit()}>Save</button>
              )
              }
            </div>
          </div>

        </div>
      }
    </div >
  )
})
