import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import SpecialityTypeaheadField from "components/forms/formikFields/SpecialityTypeaheadField";
import { Field, FieldArray, Form, Formik } from "formik";
import NewInstituitionSection from "pages/Onboarding/components/FormComponents/NewInstituitionSection";
import {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Button, Col, Row } from "reactstrap";
import { MetadataService } from "utils/MetadataService";
import { PractitionerService } from "utils/PractitionerService";
import { getUser } from "utils/UserHelper";
import { getAddressName, getLocationName } from "utils/Utils";
import * as Yup from "yup";
import InstitutesTypeaheadFieldNew from "../../../FormComponents/InstitutesTypeaheadFieldNew";

const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{5})(( x| ext)\d{1,5}){0,1}$/;

export const yearFormat = /^[12][0-9]{3}$/;

const validationSchema = () =>
  Yup.object().shape({
    educationFormData: Yup.array().of(
      Yup.object().shape({
        institutionDetail: Yup.lazy((value) => {
          if (value && value.onSelectNewMedicalCollege === 0) {
            return Yup.object().test(
              "",
              "This field is required. You must select from options or add new",
              () => false
            );
          } else if (value && value.onSelectNewMedicalCollege === 1) {
            return Yup.object();
          } else if (value && value.onSelectNewMedicalCollege === 2) {
            return Yup.object();
          } else {
            return Yup.mixed().test(
              "",
              "This field is required. You must select from options or add new",
              (value) => value === {}
            );
          }
        }),
        city: Yup.array().when("institutionDetail.onSelectNewMedicalCollege", {
          is: 2,
          then: Yup.array()
            .min(1, "This field is required")
            .required("This field is required"),
        }),
        state: Yup.array().when("institutionDetail.onSelectNewMedicalCollege", {
          is: 2,
          then: Yup.array()
            .min(1, "This field is required")
            .required("This field is required"),
        }),
        country: Yup.array().when(
          "institutionDetail.onSelectNewMedicalCollege",
          {
            is: 2,
            then: Yup.array()
              .min(1, "This field is required")
              .required("This field is required"),
          }
        ),
        location: Yup.array().when(
          "institutionDetail.onSelectNewMedicalCollege",
          {
            is: 2,
            then: Yup.array()
              .min(1, "This field is required")
              .required("This field is required"),
          }
        ),
        mainItemName: Yup.string().when(
          "institutionDetail.onSelectNewMedicalCollege",
          {
            is: 2,
            then: Yup.string().required("This field is required"),
          }
        ),
        phone: Yup.string().when(
          "institutionDetail.onSelectNewMedicalCollege",
          {
            is: 2,
            then: Yup.string().when("phone", {
              is: (val) => val !== "",
              then: Yup.string().matches(
                phoneRegExp,
                "Please enter valid Phone Number"
              ),
              otherwise: Yup.string(),
            }),
          }
        ),
        postalCode: Yup.string().when(
          "institutionDetail.onSelectNewMedicalCollege",
          {
            is: 2,
            then: Yup.string().required("This field is required"),
          }
        ),
        addressLine1: Yup.string().when(
          "institutionDetail.onSelectNewMedicalCollege",
          {
            is: 2,
            then: Yup.string().required("This field is required"),
          }
        ),
        websiteAddress: Yup.string().when(
          "institutionDetail.onSelectNewMedicalCollege",
          {
            is: 2,
            then: Yup.string().when("websiteAddress", {
              is: (val) => val !== "",
              then: Yup.string().url("Please enter a valid URL"),
              otherwise: Yup.string(),
            }),
          }
        ),
        degree: Yup.string().required("This field is required"),
        fieldOfStudy: Yup.array()
          .min(1, "This field is required")
          .required("This field is required"),
        startYear: Yup.string()
          .matches(yearFormat, "Please enter valid format (YYYY)")
          .required("This field is required"),
        endYear: Yup.string()
          .matches(yearFormat, "Please enter valid format (YYYY)")
          .required("This field is required")
          .test({
            name: "startYear",
            message: "End Year should be greater than Start year",
            test: function (value) {
              if (this.parent.startYear && value) {
                const fullStart = this.parent.startYear.split("-");
                const fullEnd = value.split("-");
                if (fullStart.length > 0 && fullEnd.length > 0) {
                  const [startYearM, startYearY] = fullStart;
                  const [endYearM, endYearY] = fullEnd;
                  return startYearY + startYearM < endYearY + endYearM;
                }
                return false;
              }
              return false;
            },
          }),
        category: Yup.string().when(
          "institutionDetail.onSelectNewMedicalCollege",
          {
            is: 2,
            then: Yup.string().required("This field is required"),
          }
        ),
      })
    ),
  });

const initialValuesObj = {
  institutionDetail: {
    institutionName: "",
    guid: "",
    onSelectNewMedicalCollege: 0,
  },
  addressLine2: "",
  city: [],
  country: [],
  location: [],
  postalCode: "",
  state: [],
  addressType: "BILLING_ADDRESS",
  degree: "",
  fieldOfStudy: [],
  startYear: "",
  endYear: "",
  additionalInfo: "",
  websiteAddress: "",
  addressLine1: "",
  mainItemName: "",
  category: "",
  phone: "",
};

const initialValues = {
  educationFormData: [{ ...initialValuesObj }],
};

const RenderEducationFormik = (props) => {
  const { eduObj, onNext, onBack, bindSubmitForm } = props;

  const skipExit = (exitFlag) => {
    if(exitFlag==='skip'){
      // formRef.current.handleSubmit();
        props.skip();      
    }
  };

  const getFieldOfStudyName = (value) => {
    try {
      if (value.length > 0) {
        if (value[0]?.customOption) {
          return value[0]?.value;
        }
        return value[0];
      }
      return "";
    } catch (error) {
      console.log("get Field Of StudyName", error);
      return "";
    }
  };

  const onSubmit = (values) => {
    const userGUID = getUser().guid;
    const formData = values.educationFormData.map((node) => {
      return {
        additionalInfo: node?.additionalInfo,
        courseTitle: node?.degree,
        guid: userGUID,
        institutionDetail: {
          ...(!node.institutionDetail.guid && {
            address: {
              addressLine1: node?.addressLine1 ?? "",
              addressLine2: node?.addressLine2 ?? "",
              addressType: node?.addressType ?? "BILLING_ADDRESS",
              city: getAddressName(node?.city),
              state: getAddressName(node?.state),
              country: getAddressName(node?.country),
              location: getLocationName(node?.location),
              postalCode: node?.postalCode ?? "",
            },
          }),
          ...(node.institutionDetail.guid && {
            guid: node.institutionDetail.guid,
          }),
          name: node.mainItemName,
          phone: node?.phone ?? "",
          websiteUrl: node?.websiteAddress ?? "",
          ...(node.category && {
            type: node.category,
          }),
        },
        passingYear: node.endYear,
        specialization: getFieldOfStudyName(node?.fieldOfStudy),
        startYear: node.startYear,
      };
    });
    PractitionerService.registerEducationsDetails(formData, userGUID, true)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          onNext();
        }
      })
      .catch((error) => {
        console.log(
          "PractitionerService.registerEducationsDetails error",
          error
        );
        props.alert.error(error.message);
      });
  };
  return (
    <Row className="render-education-form">
      <Col sm={12}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
          children={(props) => {
            bindSubmitForm(props.submitForm);
            return (
              <Form className="educational-form">
                {eduObj.type === "SECONDARY" && (
                  <div className="horizontal-line my-3"></div>
                )}
                <FieldArray
                  name="educationFormData"
                  render={(arrayHelpers) => (
                    <>
                      {props.values.educationFormData.map((item, index) => (
                        <Fragment key={index}>
                          {index !== 0 && (
                            <div className="horizontal-line my-3"></div>
                          )}
                          <RenderForm {...props} index={index} />
                        </Fragment>
                      ))}
                      <Row className="mt-4 add-more-education">
                        <Col lg={6} md={6} sm={12} xs={12}>
                          <Button
                            id="re_add_more_education"
                            onClick={() => {
                              props.validateForm().then(() => {
                                if (props.dirty && props.isValid) {
                                  arrayHelpers.push({ ...initialValuesObj });
                                } else {
                                  arrayHelpers.form.validateForm();
                                  arrayHelpers.form.setTouched({
                                    educationFormData:
                                      arrayHelpers.form.values.educationFormData.map(
                                        () => {
                                          return {
                                            institutionDetail: true,
                                            mainItemName: true,
                                            category: true,
                                            addressLine1: true,
                                            addressLine2: true,
                                            country: true,
                                            state: true,
                                            city: true,
                                            postalCode: true,
                                            location: true,
                                            websiteAddress: true,
                                            degree: true,
                                            fieldOfStudy: true,
                                            startYear: true,
                                            endYear: true,
                                            additionalInfo: true,
                                          };
                                        }
                                      ),
                                  });
                                }
                              });
                            }}
                            className="d-flex flex-row align-items-center nex-btn-primary-outline"
                            type="button"
                          >
                            + Add more Education
                            
                          </Button>
                        </Col>
                        {props.values.educationFormData.length > 1 && (
                          <Col lg={6} md={6} sm={12} xs={12} className="d-flex">
                            <div
                              onClick={() =>
                                arrayHelpers.remove(
                                  props.values.educationFormData.length - 1
                                )
                              }
                              className="w-100 d-flex flex-row justify-content-end align-items-center"
                            >
                              <p className="m-0 f-14 line-h-24 font-weight-bold">
                                Remove this education
                              </p>
                            </div>
                          </Col>
                        )}
                      </Row>
                      <Row className="mt-3">
                        {/* <Col lg={6} xs="6">
                          <Button
                            className="d-block back-btn ml-auto"
                            type="button"
                            onClick={onBack}
                          >
                            Back
                          </Button>
                        </Col> */}
                        <Col lg={12} xs="12">
                          <div className="d-flex flex-wrap actions">
                          <Button
                            className="nex-btn-primary-outline re_btn_skip"
                            type="button"
                            outline
                            onClick={()=>skipExit('skip')}
                          >
                            Skip
                          </Button>
                          <Button
                            className="nex-btn-primary re_btn_skip"
                            type="submit"
                          >
                            Next
                          </Button>
                          </div>
                        </Col>
                        
                      </Row>
                    </>
                  )}
                />
              </Form>
            );
          }}
        />
      </Col>
    </Row>
  );
};
const RenderForm = (props) => {
  const { index } = props;
  const [nestedModal, setNestedModal] = useState(false);
  const [degreeList, setdegreeList] = useState([]);
  const [field_Of_Stud_List, setField_Of_Stud_List] = useState([]);
  const { values, setFieldValue } = props;
  const location = useLocation();
  const specialityTypeaheadRef = useRef();

  const onNewInstitute = (index) => {
    toggleNested(index);
  };

  const toggleNested = (index) => {
    setNestedModal(!nestedModal);
    setFieldValue(
      `educationFormData[${index}].institutionDetail.onSelectNewMedicalCollege`,
      2
    );
    setFieldValue(
      `educationFormData[${index}].institutionDetail.institutionName`,
      "Other"
    );
  };

  useEffect(() => {
    MetadataService.getDegree()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setdegreeList(response.data);
        }
      })
      .catch((error) => {
        console.log("MetadataService.getDegree error", error);
      });
  }, [location]);

  useEffect(() => {
    if (values?.educationFormData[index]?.degree) {
      MetadataService.getCourses(values?.educationFormData[index]?.degree)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setField_Of_Stud_List([...response.data]);
          }
        })
        .catch((error) => {
          console.log("MetadataService.getDegree error", error);
        });
    }
  }, [values, index]);

  const handleChange = useCallback(
    (event) => {
      if (event.target.value) {
        MetadataService.getCourses(event.target.value)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              setField_Of_Stud_List([...response.data]);
            }
          })
          .catch((error) => {
            console.log("MetadataService.getDegree error", error);
          });
      }
      setFieldValue(`educationFormData[${index}].degree`, event.target.value);
      setFieldValue(`educationFormData[${index}].fieldOfStudy`, []);
    },
    [index, setFieldValue]
  );

  return (
    <>
      <Row className="mt-4">
        <Col lg={12}>
          <label className="d-block skills">Medical College/ University</label>
          <Field
            name={`educationFormData[${index}].institutionDetail`}
            id={`educationFormData[${index}].institutionDetail`}
            autoComplete={`educationFormData[${index}].institutionDetail`}
            type="text"
            component={InstitutesTypeaheadFieldNew}
            onNewInstituteReq={(index) => onNewInstitute(index)}
            placeholder="Choose your Medical's college / University name"
            parentIndex={index}
          />
        </Col>
      </Row>
      {values?.educationFormData[index]?.institutionDetail
        ?.onSelectNewMedicalCollege === 2 && (
        <NewInstituitionSection {...props} index={index} />
      )}
      <Row>
        <Col lg={4}>
          <label className="d-block skills">Degree</label>
          <Field
            id={`educationFormData[${index}].degree`}
            name={`educationFormData[${index}].degree`}
            autoComplete={`educationFormData[${index}].degree`}
            type="text"
            component={FormikSelectField}
            onChange={handleChange}
            inputprops={{
              name: `degree`,
              options: degreeList,
              defaultOption: "Choose",
            }}
          />
        </Col>
        <Col lg={8}>
          <div className="form-group">
            <label className="d-block skills">Field Of Study</label>
            <Field
              id={`educationFormData[${index}].fieldOfStudy`}
              name={`educationFormData[${index}].fieldOfStudy`}
              autoComplete={`educationFormData[${index}].fieldOfStudy`}
              type="text"
              component={SpecialityTypeaheadField}
              // autoComplete="off"
              value={values[`educationFormData`][index].fieldOfStudy}
              placeholder="Field Of Study"
              spellCheck={false}
              data={field_Of_Stud_List}
              allowNew={false}
              selectdItem={(input) =>
                setFieldValue(`educationFormData[${index}].fieldOfStudy`, input)
              }
              itemRef={specialityTypeaheadRef}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="form-group">
            <label className="d-block skills">Start Year</label>
            <Row>
              <Col xs={12}>
                <Field
                  name={`educationFormData[${index}].startYear`}
                  id={`educationFormData[${index}].startYear`}
                  autoComplete={`educationFormData[${index}].startYear`}
                  type="text"
                  component={FormikInputField}
                  placeholder="YYYY"
                  // autoComplete="off"
                  value={values[`educationFormData`][index].startYear}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={6}>
          <div className="form-group">
            <label className="d-block skills">End Year</label>
            <Row>
              <Col xs={12}>
                <Field
                  name={`educationFormData[${index}].endYear`}
                  id={`educationFormData[${index}].endYear`}
                  autoComplete={`educationFormData[${index}].endYear`}
                  type="text"
                  component={FormikInputField}
                  placeholder="YYYY"
                  // autoComplete="off"
                  value={values[`educationFormData`][index].endYear}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="form-group">
            <Field
              name={`educationFormData[${index}].additionalInfo`}
              autoComplete={`educationFormData[${index}].additionalInfo`}
              id="additionalInfo"
              type="textarea"
              label="Additional Information"
              rows={4}
              cols={5}
              component={FormikInputField}
              placeholder="Add some text here"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
export default RenderEducationFormik;
