import React, { useContext, useCallback, useState, useEffect, useRef } from 'react';
import Section001 from 'pages/UIDashboard/Components/Sections/Section001';
import Section002 from 'pages/UIDashboard/Components/Sections/Section002';
import Section01 from 'pages/UIDashboard/Components/Sections/Section01';
import Section1 from 'pages/UIDashboard/Components/Sections/Section1';
import Section3 from 'pages/UIDashboard/Components/Sections/Section3';
import SectionBlank from 'pages/UIDashboard/Components/Sections/SectionBlank';
import { CardContext } from 'pages/UIDashboard/ThemeDesign';
import { useDrop } from 'react-dnd';
import { Card, CardBody, CardFooter, CardHeader, Col, DropdownItem, DropdownMenu, Row } from 'reactstrap';
import itemType from '../../ThemeContents/utils';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
// import {faAlternateTrash} from '@fortawesome/free-solid-svg-icons';
import './style.scss';
import Section02 from 'pages/UIDashboard/Components/Sections/Section02';
import Section01List from 'pages/UIDashboard/Components/Sections/Section01/Section01List';
import ChooseColorTheme from './ChooseColorTheme';
import FullWidthSlider from './FullWidthSlider';
import Sections from 'pages/UIDashboard/Components/Sections';


const Droppable = ({ id, onDrop, setDropId, children }) => {
    const [status, setStatus] = useState({});
    const [{ isOver }, drop] = useDrop({
        accept: itemType.CARD,
        drop: (item, monitor, event) => {
            setDropId(monitor.targetId);
            onDrop(item, monitor, event)
        },
        // drop:handleDrop20,
        collect: monitor => ({
            isOver: monitor.isOver(),
        }),
    });

    // useEffect(() => {
    //     setDroppedIds1(()=>[status[0],status[1]])
    // }, [status])
    return (
        <div ref={drop} style={{ backgroundColor: isOver ? 'lightblue' : 'lightgray', width: "100%", minHeight: "80px" }}>
            {children}
        </div>
    );
}

const ThemeTwoTemplate= ({ themeDetails,setThemePColors, setDraggableItems, droppedItem }) => {
    const [themeColors, setThemeColors] = useState(themeDetails);
    const [droppedIds1, setDroppedIds1] = useState([]);
    const [droppedIds2, setDroppedIds2] = useState([]);
    const [droppedIds3, setDroppedIds3] = useState([]);
    const [droppedIds4, setDroppedIds4] = useState([]);
    const [droppedIds5, setDroppedIds5] = useState([]);
    const [droppedIds6, setDroppedIds6] = useState([]);
    const [droppedIds7, setDroppedIds7] = useState([]);
    const [dropID, setDropId] = useState('');

    // const col4Ref = useRef(null);


    const { markAsDragged } = useContext(CardContext);
    const [themeBgColor, setThemeBgColor] = useState(themeDetails[0]);
    const [sectionBG, setSectionBG] = useState(themeDetails[1]);
    const [sectionHeaderBg, setSectionHeaderBg] = useState(themeDetails[3]);
    const [sectionHeaderFontColor, setSectionHeaderFontColor] = useState(themeDetails[2]);
    const [sectionHeadingFontColor, setSectionHeadingFontColor] = useState(themeDetails[4]);
    const [sectionFontColor, setSectionFontColor] = useState(themeDetails[5]);
    const [sHeaderFontSize, setSHeaderFontSize] = useState('1rem');
    const [sHeaderFontWeight, setSHeaderFontWeight] = useState('bold');
    const [sHeader, setSHeader] = useState(''); //Header or none for No Header 
    const [cardDetails, setCardDetails] = useState([]);
    const [cardWidth, setCardWidth] = useState(0);
    const theme = {
        bg: {
            backgroundColor: `${themeBgColor}`,
        },
        bgBefore: {
            backgroundColor: `lightgray`,
        },
        sectionbg: {
            padding: "0",
            color: `${sectionFontColor}`,
            backgroundColor: `${sectionBG}`
        },
        theader: {
            backgroundColor: `${sectionHeaderBg}`,
            fontSize: `${sHeaderFontSize}`,
            color: `${sectionHeaderFontColor}`,
            fontWeight: `${sHeaderFontWeight}`
        },
    };
    // const ref = useRef(null);

    const sectionCardDetails = useCallback(
        (details) => {
            setCardDetails(details)
        }, [cardDetails]);
   
    const handleDrop1 = (menuItems, monitor) => {
        // console.log("-------/-handleDrop1-----------",menuItems);
        const dropAreaId = monitor.targetId;
        switch (dropAreaId) {
            case 'T7':
                    if (droppedIds1.length > 0) {
                        setDraggableItems(items => [...items, { id: droppedIds1[0], text: droppedIds1[1], content: droppedIds1[2] }]);
                        setDroppedIds1([menuItems.id, menuItems.text, menuItems.content,dropAreaId]);
                    } else {
                    setDroppedIds1([menuItems.id, menuItems.text, menuItems.content,dropAreaId]);
                    }
                    droppedItem(droppedIds1);
                break;
            case 'T8':
                if (droppedIds2.length > 0) {
                    setDraggableItems(items => [...items, { id: droppedIds2[0], text: droppedIds2[1], content: droppedIds2[2] }]);
                    setDroppedIds2([menuItems.id, menuItems.text, menuItems.content,dropAreaId]);
                } else {
                    setDroppedIds2([menuItems.id, menuItems.text, menuItems.content,dropAreaId]);

                }
                droppedItem(droppedIds2);
                break;
            case 'T9':
                if (droppedIds3.length > 0) {
                    setDraggableItems(items => [...items, { id: droppedIds3[0], text: droppedIds3[1], content: droppedIds3[2] }]);
                    // handleRemoveFromDroppedIds(droppedIds3);
                    setDroppedIds3([menuItems.id, menuItems.text, menuItems.content,dropAreaId]);
                } else {
                    setDroppedIds3([menuItems.id, menuItems.text, menuItems.content,dropAreaId]);
                }
                droppedItem(droppedIds3);
                break;
            case 'T10':
                if (droppedIds4.length > 0) {
                    setDraggableItems(items => [...items, { id: droppedIds4[0], text: droppedIds4[1], content: droppedIds4[2] }]);
                    setDroppedIds4([menuItems.id, menuItems.text, menuItems.content,dropAreaId]);
                } else {
                    setDroppedIds4([menuItems.id, menuItems.text, menuItems.content,dropAreaId]);
                }
                break;
            case 'T11':
                if (droppedIds5.length > 0) {
                    setDraggableItems(items => [...items, { id: droppedIds5[0], text: droppedIds5[1], content: droppedIds5[2] }]);
                    setDroppedIds5([menuItems.id, menuItems.text, menuItems.content,dropAreaId]);
                } else {
                    setDroppedIds5([menuItems.id, menuItems.text, menuItems.content,dropAreaId]);
                }
                break;
            case 'T12':
                if (droppedIds6.length > 0) {
                    setDraggableItems(items => [...items, { id: droppedIds6[0], text: droppedIds6[1], content: droppedIds6[2] }]);
                    setDroppedIds6([menuItems.id, menuItems.text, menuItems.content,dropAreaId]);
                } else {
                    setDroppedIds6([menuItems.id, menuItems.text, menuItems.content,dropAreaId]);
                }
                break;
            case 'T13':
                if (droppedIds7.length > 0) {
                    setDraggableItems(items => [...items, { id: droppedIds7[0], text: droppedIds7[1], content: droppedIds7[2] }]);
                    setDroppedIds7([menuItems.id, menuItems.text, menuItems.content,dropAreaId]);
                } else {
                    setDroppedIds7([menuItems.id, menuItems.text, menuItems.content,dropAreaId]);
                }
                break;
            default:
                console.log(`Unsupported item type: ${dropAreaId}`);
        }
    };

    const handleRemoveFromDroppedIds = (dId,menuItem) => {
        // console.log("========handleRemoveFromDroppedIds==============================",dId,menuItem);
        // const deleteId = menuItem[0];
        switch (menuItem[3]) {
            case 'T7':
                setDroppedIds1(() => []);
                setDraggableItems(items => ([...items, { id: menuItem[0], text: menuItem[1], content: menuItem[2] }].sort((a, b) => a.id - b.id)));
                break;
            case 'T8':
                setDroppedIds2(() => []);
                setDraggableItems(items => ([...items, { id: menuItem[0], text: menuItem[1], content: menuItem[2] }].sort((a, b) => a.id - b.id)));
                break;
            case 'T9':
                setDroppedIds3(() => []);
                setDraggableItems(items => ([...items, { id: menuItem[0], text: menuItem[1], content: menuItem[2] }].sort((a, b) => a.id - b.id)));
                break;
            case 'T10':
                setDroppedIds4(() => []);
                setDraggableItems(items => ([...items, { id: menuItem[0], text: menuItem[1], content: menuItem[2] }].sort((a, b) => a.id - b.id)));
                break;
            case 'T11':
                setDroppedIds5(() => []);
                setDraggableItems(items => ([...items, { id: menuItem[0], text: menuItem[1], content: menuItem[2] }].sort((a, b) => a.id - b.id)));
                break;
            case 'T12':
                setDroppedIds6([]);
                setDraggableItems(items => ([...items, { id: menuItem[0], text: menuItem[1], content: menuItem[2] }].sort((a, b) => a.id - b.id)));
                break;
            case 'T13':
                setDroppedIds7([]);
                setDraggableItems(items => ([...items, { id: menuItem[0], text: menuItem[1], content: menuItem[2] }].sort((a, b) => a.id - b.id)));
                break;
            default:
                console.log(`Unsupported item type: ${menuItem}`);
        }
        // setDroppedIds1([]);
        // setDraggableItems(items => ([...items, { id: menuItem[0], text: menuItem[1] }].sort((a, b) => a.id - b.id)));
        // console.log("remove--------------------------", menuItem[0], droppedIds1);
    };
    // const [{isOver},drop] = useDrop({
    //     accept:itemType.CARD,
    //     drop:(item,monitor) => {
    //         markAsDragged(item.sectionDetails.id)
    //         // console.log("ITEM,MONITOR----------",item,monitor,item.sectionDetails.id);
    //         sectionCardDetails(item.sectionDetails);
    //     },
    //     // drop:(item,monitor) => markAsDragged(item.id),
    //     collect: monitor => ({
    //         isOver: !!monitor.isOver()
    //     })
    // })
    // const [themeBgColor,setThemeBgColor] = useState('red');
    // const [themeBgColor,setThemeBgColor] = useState('red');

    // useEffect(()=>{
    //     if(themeBgColor){

    //     }
    // },[themeBgColor])
    // useEffect(()=>{
    //     if(cardDetails.length>0){
    //         setCardDetails();
    //     }

    // },cardDetails);
    // console.log("+++++++++++++++++++++++++++",cardDetails,cardDetails.length>0,cardDetails.length);
    // useEffect(() => {
    //     console.log('State updated:');
    //     if(droppedIds[1]!== undefined)
    //     handleDrop1(droppedIds);
    //   }, [droppedIds[1],droppedIds[2]]);
    const changeTheme = () => {

    }

    // useEffect(()=>{
    //     console.log("======",droppedIds1);
    //     if(droppedIds1[0])
    //     callSectionType()
    // },[droppedIds1[0],droppedIds2[0],droppedIds3[0]])
    useEffect(() => {
        setThemeBgColor(themeDetails[0]);
        setSectionBG(themeDetails[1]);
        setSectionHeaderBg(themeDetails[3]);
        setSectionHeaderFontColor(themeDetails[2]);
        setSectionHeadingFontColor(themeDetails[4]);
        setSectionFontColor(themeDetails[5]);
    }, [themeDetails,themeColors])

    // useEffect(() => {
    //     if (col4Ref.current) {
    //       const colWidth = col4Ref.current.getBoundingClientRect().width;
    //       setCardWidth(colWidth);
    //       console.log(colWidth); // outputs the width of the col in pixels
    //     }
    //   }, [cardWidth]);
    // console.log("TOT2-------------", cardWidth);
    // console.log("TOT2-------------", themeColors[1],themeColors[3],themeDetails[1],themeDetails[3]);
    return (
        // <Card className="micro-site-wrap micro-site-theme-v1 p-0  border-0" style={isOver ? theme.bgBefore :theme.bg}>
        <Card className="micro-site-wrap micro-site-theme-v1 p-0  border-0" style={{backgroundColor:themeDetails[0]}}>
            <div className='color-card-box'>
            <ChooseColorTheme  themeColors={themeDetails} setThemePColors={setThemePColors} setThemeBgColor={setThemeBgColor} setSectionBG={setSectionBG} setSectionHeaderBg={setSectionHeaderBg} setSectionHeaderFontColor={setSectionHeaderFontColor} setSectionHeadingFontColor={setSectionHeadingFontColor} setSectionFontColor={setSectionFontColor}/>
                {/* <DropdownMenu className='custom-dropdown-toggle'> */}
                    {/* {themeDropdowItem.map((item, ind) => { */}
                    {/* return ( */}
                    {/* <DropdownItem onClick={(e) => changeTheme}>
                        <div className='theme-color-items'>
                            {themeDetails.map((coloritem, index) => {
                                console.log("-------------color------------------------", coloritem);
                                return <span style={{ backgroundColor: coloritem, width: "50px" }} key={index}></span>
                            })}
                        </div>
                    </DropdownItem> */}
                    {/* ) */}
                    {/* })} */}
                {/* </DropdownMenu> */}
            </div>
            <CardHeader className="micro-header micro-bg-gray mb-0" style={{ backgroundImage: "url(https://picsum.photos/id/10/1200/300)" }}>
                <div className="micro-logo" style={{ backgroundImage: "url(https://picsum.photos/id/64/200/300)" }}>Logo</div>
            </CardHeader>
            <CardBody className="micro-main-content-wrap">
                <Row>
                    <Col lg={12} className="mb-3 p-0">
                        <SectionBlank themeDetails={themeDetails} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={8}>
                {/* <Row>
                    <Col lg={4} className="mb-3" style={{ minHeight: "80px", padding: "15px" }}>
                        <Droppable id="droppable-1" onDrop={(id, monitor) => handleDrop1(id, monitor)} setDropId={setDropId}>
                            {droppedIds1?.length > 0 ? <FontAwesomeIcon icon={faTrashAlt} className='btn-delete' onClick={() => handleRemoveFromDroppedIds(dropID,droppedIds1)}/> : ''}
                            {droppedIds1.length > 0 ? droppedIds1[2][0] === "list" ? <Section01List fieldDetails={{ slabel: droppedIds1[1], scontent: droppedIds1[2][1] }} themeDetails={themeColors} /> : droppedIds1[2][0] === "image" ? <FullWidthSlider fieldDetails={{ slabel: droppedIds1[1], scontent: droppedIds1[2][1] }} themeDetails={themeDetails}/> : <Sections fieldDetails={{ slabel: droppedIds1[1], scontent: droppedIds1[2][1] }} themeDetails={themeDetails} /> : ''}
                        </Droppable>
                    </Col>
                    <Col lg={8} id="div8" className="mb-3" style={{ minHeight: "80px", padding: "15px" }}>
                        <Droppable id="droppable-2" onDrop={(id, monitor) => handleDrop1(id, monitor)} setDropId={setDropId}>
                            {droppedIds2.length > 0 ? <FontAwesomeIcon icon={faTrashAlt} className='btn-delete' onClick={() => handleRemoveFromDroppedIds(dropID,droppedIds2)}/> : ''}                            
                            {droppedIds2.length > 0 ? droppedIds2[2][0] === "list" ? <Section01List fieldDetails={{ slabel: droppedIds2[1], scontent: droppedIds2[2][1] }} themeDetails={themeDetails} /> : droppedIds2[2][0] === "image" ? <FullWidthSlider fieldDetails={{ slabel: droppedIds2[1], scontent: droppedIds2[2][1] }} themeDetails={themeDetails}/> : <Sections fieldDetails={{ slabel: droppedIds2[1], scontent: droppedIds2[2][1] }} themeDetails={themeDetails} /> : ''}
                        </Droppable>
                    </Col>
                </Row> */}
                <Row>
                    <Col lg={12} id="div12" className=" mb-3" >
                    <Droppable id="droppable-1" onDrop={(id, monitor) => handleDrop1(id, monitor)} setDropId={setDropId}>
                            {droppedIds1?.length > 0 ? <FontAwesomeIcon icon={faTrashAlt} className='btn-delete fwidth' onClick={() => handleRemoveFromDroppedIds(dropID,droppedIds1)}/> : ''}
                            {droppedIds1.length > 0 ? droppedIds1[2][0] === "list" ? <Section01List fieldDetails={{ slabel: droppedIds1[1], scontent: droppedIds1[2][1] }} themeDetails={themeColors} /> : droppedIds1[2][0] === "image" ? <FullWidthSlider fieldDetails={{ slabel: droppedIds1[1], scontent: droppedIds1[2][1] }} themeDetails={themeDetails}/> : <Sections fieldDetails={{ slabel: droppedIds1[1], scontent: droppedIds1[2][1] }} themeDetails={themeDetails} /> : ''}
                        </Droppable>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} id="div12" className=" mb-3" >
                    <Droppable id="droppable-2" onDrop={(id, monitor) => handleDrop1(id, monitor)} setDropId={setDropId}>
                            {droppedIds2.length > 0 ? <FontAwesomeIcon icon={faTrashAlt} className='btn-delete fwidth' onClick={() => handleRemoveFromDroppedIds(dropID,droppedIds2)}/> : ''}                            
                            {droppedIds2.length > 0 ? droppedIds2[2][0] === "list" ? <Section01List fieldDetails={{ slabel: droppedIds2[1], scontent: droppedIds2[2][1] }} themeDetails={themeDetails} /> : droppedIds2[2][0] === "image" ? <FullWidthSlider fieldDetails={{ slabel: droppedIds2[1], scontent: droppedIds2[2][1] }} themeDetails={themeDetails}/> : <Sections fieldDetails={{ slabel: droppedIds2[1], scontent: droppedIds2[2][1] }} themeDetails={themeDetails} /> : ''}
                        </Droppable>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} id="div12" className=" mb-3" >
                        <Droppable id="droppable-3" onDrop={(id, monitor) => handleDrop1(id, monitor)} setDropId={setDropId}>
                            {droppedIds3.length > 0 ? <FontAwesomeIcon icon={faTrashAlt} className='btn-delete fwidth' onClick={() => handleRemoveFromDroppedIds(dropID,droppedIds3)}/> : ''}
                            {/* <p> {droppedIds3[1]}</p> */}
                            {droppedIds3.length > 0 ? droppedIds3[2][0] === "list" ? <Section01List fieldDetails={{ slabel: droppedIds3[1], scontent: droppedIds3[2][1] }} themeDetails={themeDetails} /> : droppedIds3[2][0] === "image" ? <FullWidthSlider fieldDetails={{ slabel: droppedIds3[1], scontent: droppedIds3[2][1] }} themeDetails={themeDetails}/>  : <Sections fieldDetails={{ slabel: droppedIds3[1], scontent: droppedIds3[2][1] }} themeDetails={themeDetails} /> : ''}
                        </Droppable>
                        {/* <div className='h-lg' style={theme.sectionbg}><Section1  themeDetails={themeDetails}/></div> */}
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className=" mb-3" >
                        <Droppable id="droppable-4" onDrop={(id, monitor) => handleDrop1(id, monitor)} setDropId={setDropId}>
                            {droppedIds4.length > 0 ? <FontAwesomeIcon icon={faTrashAlt} className='btn-delete fwidth' onClick={() => handleRemoveFromDroppedIds(dropID,droppedIds4)}/> : ''}
                            {droppedIds4.length > 0 ? droppedIds4[2][0] === "list" ? <Section01List fieldDetails={{ slabel: droppedIds4[1], scontent: droppedIds4[2][1] }} themeDetails={themeDetails} /> : <Sections fieldDetails={{ slabel: droppedIds4[1], scontent: droppedIds4[2][1] }} themeDetails={themeDetails} /> : ''}
                            {/* {droppedIds4.length > 0 ? <Section01List fieldDetails={{slabel:droppedIds4[1],scontent:droppedIds4[2][1]}} themeDetails={themeDetails}/> : ''} */}
                        </Droppable>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className=" mb-3" >
                        <Droppable id="droppable-5" onDrop={(id, monitor) => handleDrop1(id, monitor)} setDropId={setDropId}>
                            {droppedIds5.length > 0 ? <FontAwesomeIcon icon={faTrashAlt} className='btn-delete fwidth' onClick={() => handleRemoveFromDroppedIds(dropID,droppedIds5)}/> : ''}
                            {droppedIds5.length > 0 ? droppedIds5[2][0] === "list" ? <Section01List fieldDetails={{ slabel: droppedIds5[1], scontent: droppedIds5[2][1] }} themeDetails={themeDetails} /> : <Sections fieldDetails={{ slabel: droppedIds5[1], scontent: droppedIds5[2][1] }} themeDetails={themeDetails} /> : ''}
                            {/* <p> {droppedIds5[1]}</p> */}
                        </Droppable>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className=" mb-3" >
                        <Droppable id="droppable-6" onDrop={(id, monitor) => handleDrop1(id, monitor)} setDropId={setDropId}>
                            {droppedIds6.length > 0 ? <FontAwesomeIcon icon={faTrashAlt} className='btn-delete fwidth' onClick={() => handleRemoveFromDroppedIds(dropID,droppedIds6)}/> : ''}
                            {droppedIds6.length > 0 ? droppedIds6[2][0] === "list" ? <Section01List fieldDetails={{ slabel: droppedIds6[1], scontent: droppedIds6[2][1] }} themeDetails={themeDetails} /> : <Sections fieldDetails={{ slabel: droppedIds6[1], scontent: droppedIds6[2][1] }} themeDetails={themeDetails} /> : ''}
                            {/* <p> {droppedIds6[1]}</p> */}
                        </Droppable>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className=" mb-3" >
                        <Droppable id="droppable-7" onDrop={(id, monitor) => handleDrop1(id, monitor)} setDropId={setDropId}>
                            {droppedIds7.length > 0 ? <FontAwesomeIcon icon={faTrashAlt} className='btn-delete fwidth' onClick={() => handleRemoveFromDroppedIds(dropID,droppedIds7)}/> : ''}
                            {droppedIds7.length > 0 ? droppedIds7[2][0] === "list" ? <Section01List fieldDetails={{ slabel: droppedIds7[1], scontent: droppedIds7[2][1] }} themeDetails={themeDetails} /> : <Sections fieldDetails={{ slabel: droppedIds7[1], scontent: droppedIds7[2][1] }} themeDetails={themeDetails} /> : ''}
                        </Droppable>
                    </Col>
                </Row>
                </Col>
                    <Col lg={4}>
                    <Droppable id="droppable-1" onDrop={(id, monitor) => handleDrop1(id, monitor)} setDropId={setDropId}>
                            {droppedIds1?.length > 0 ? <FontAwesomeIcon icon={faTrashAlt} className='btn-delete' onClick={() => handleRemoveFromDroppedIds(dropID,droppedIds1)}/> : ''}
                            {droppedIds1.length > 0 ? droppedIds1[2][0] === "list" ? <Section01List fieldDetails={{ slabel: droppedIds1[1], scontent: droppedIds1[2][1] }} themeDetails={themeColors} /> : droppedIds1[2][0] === "image" ? <FullWidthSlider fieldDetails={{ slabel: droppedIds1[1], scontent: droppedIds1[2][1] }} themeDetails={themeDetails}/> : <Sections fieldDetails={{ slabel: droppedIds1[1], scontent: droppedIds1[2][1] }} themeDetails={themeDetails} /> : ''}
                        </Droppable>
                    </Col>
                </Row>
                {/** 
                <div className="micro-content-flex bg-warning" style={theme.sectionbg}>
                    <div className="col-12 col-lg-12 h-lg"></div>
                </div>
                */}
            </CardBody>
            {/* <CardFooter className="micro-footer micro-bg-blue text-center text-white">Footer</CardFooter> */}
        </Card>
    )
}

export default ThemeTwoTemplate