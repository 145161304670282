import moment from "moment";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import "../style.scss";

const SingleTimeSlot = (props) => {
  const { schedules } = props;
  const [showAll, setShowAll] = useState(false);

  function sortItByTiming(timePeriods) {
    if (timePeriods.length > 1) {
      timePeriods.sort((a, b) => {
        const timeA = new Date(`1970-01-01 ${a.startTime}`);
        const timeB = new Date(`1970-01-01 ${b.startTime}`);
        return timeA - timeB;
      });
      return timePeriods;
    }
    else {
      return timePeriods;
    }
  }

  function isCurrentTimeInRange(dayOfWeek, startTime, endTime) {
    const daysOfWeek = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];

    const now = new Date();
    const currentDayOfWeek = daysOfWeek[now.getDay()]; // Get current day of the week
    const currentTime = now.toTimeString().split(' ')[0]; // Get current time in HH:MM:SS format

    // Check if the current day matches the specified day
    if (currentDayOfWeek !== dayOfWeek) {
      return false;
    }

    // Check if the current time falls within the specified time range
    return currentTime >= startTime && currentTime <= endTime;
  }

  return (
    <>
      {/*<h3 className="card-title">
        <img className="icon" src={clock_icon} /> &nbsp;Consulting Hours  <a className="readmore" onClick={() => setShowAll(!showAll)}> {showAll ? 'Show Less' : 'Show More'} </a>
      </h3>*/}
      {showAll ?
        <div>
          <div className="item card-text">
            <small className="date">MON</small>
            <small className="times">
              {schedules?.MONDAY
                ?
                <>
                  {
                    sortItByTiming(schedules?.MONDAY).map((e, ind) => (
                      <>
                        <div className="time" key={ind}>
                          {moment(e?.startTime, "HHmmss").format(
                            "hh:mm A"
                          )}{" "}
                          -{" "}
                          {moment(e?.endTime, "HHmmss").format(
                            "hh:mm A"
                          )}
                        </div>
                      </>
                    )
                    )
                  }
                  {
                    (() => {
                      let isOpenNow = false;
                      for (const e of sortItByTiming(schedules?.MONDAY)) {
                        if (isCurrentTimeInRange("MONDAY", e?.startTime, e?.endTime)) {
                          isOpenNow = true;
                          break;
                        }
                      }
                      return isOpenNow ? <span className="nex-status-pill-open">Open Now</span> : <span></span>;
                    })()
                  }
                </>
                : <span className="nex-status-pill-close">Closed</span>
              }
            </small>
          </div>
          <div className="item card-text">
            <small className="date">TUE</small>
            <small className="times">
              {schedules?.TUESDAY
                ?
                <>
                  {
                    sortItByTiming(schedules?.TUESDAY).map((e, ind) => (
                      <>
                        <div className="time" key={ind}>
                          {moment(e?.startTime, "HHmmss").format(
                            "hh:mm A"
                          )}{" "}
                          -{" "}
                          {moment(e?.endTime, "HHmmss").format(
                            "hh:mm A"
                          )}
                        </div>
                      </>
                    )
                    )
                  }
                  {
                    (() => {
                      let isOpenNow = false;
                      for (const e of sortItByTiming(schedules?.TUESDAY)) {
                        if (isCurrentTimeInRange("TUESDAY", e?.startTime, e?.endTime)) {
                          isOpenNow = true;
                          break;
                        }
                      }
                      return isOpenNow ? <span className="nex-status-pill-open">Open Now</span> : <span></span>;
                    })()
                  }
                </>
                : <span className="nex-status-pill-close">Closed</span>
              }
            </small>
          </div>
          <div className="item card-text">
            <small className="date">WED</small>
            <small className="times">
              {schedules?.WEDNESDAY
                ?
                <>
                  {
                    sortItByTiming(schedules?.WEDNESDAY).map((e, ind) => (
                      <>
                        <div className="time" key={ind}>
                          {moment(e?.startTime, "HHmmss").format(
                            "hh:mm A"
                          )}{" "}
                          -{" "}
                          {moment(e?.endTime, "HHmmss").format(
                            "hh:mm A"
                          )}
                        </div>
                      </>
                    )
                    )
                  }
                  {
                    (() => {
                      let isOpenNow = false;
                      for (const e of sortItByTiming(schedules?.WEDNESDAY)) {
                        if (isCurrentTimeInRange("WEDNESDAY", e?.startTime, e?.endTime)) {
                          isOpenNow = true;
                          break;
                        }
                      }
                      return isOpenNow ? <span className="nex-status-pill-open">Open Now</span> : <span></span>;
                    })()
                  }
                </>
                : <span className="nex-status-pill-close">Closed</span>
              }
            </small>
          </div>
          <div className="item card-text">
            <small className="date">THU</small>
            <small className="times">
              {schedules?.THURSDAY
                ?
                <>
                  {
                    sortItByTiming(schedules?.THURSDAY).map((e, ind) => (
                      <>
                        <div className="time" key={ind}>
                          {moment(e?.startTime, "HHmmss").format(
                            "hh:mm A"
                          )}{" "}
                          -{" "}
                          {moment(e?.endTime, "HHmmss").format(
                            "hh:mm A"
                          )}
                        </div>
                      </>
                    )
                    )
                  }
                  {
                    (() => {
                      let isOpenNow = false;
                      for (const e of sortItByTiming(schedules?.THURSDAY)) {
                        if (isCurrentTimeInRange("THURSDAY", e?.startTime, e?.endTime)) {
                          isOpenNow = true;
                          break;
                        }
                      }
                      return isOpenNow ? <span className="nex-status-pill-open">Open Now</span> : <span></span>;
                    })()
                  }
                </>
                : <span className="nex-status-pill-close">Closed</span>
              }
            </small>
          </div>
          <div className="item card-text">
            <small className="date">FRI</small>
            <small className="times">
              {schedules?.FRIDAY
                ?
                <>
                  {
                    sortItByTiming(schedules?.FRIDAY).map((e, ind) => (
                      <>
                        <div className="time" key={ind}>
                          {moment(e?.startTime, "HHmmss").format(
                            "hh:mm A"
                          )}{" "}
                          -{" "}
                          {moment(e?.endTime, "HHmmss").format(
                            "hh:mm A"
                          )}
                        </div>
                      </>
                    )
                    )
                  }
                  {
                    (() => {
                      let isOpenNow = false;
                      for (const e of sortItByTiming(schedules?.FRIDAY)) {
                        if (isCurrentTimeInRange("FRIDAY", e?.startTime, e?.endTime)) {
                          isOpenNow = true;
                          break;
                        }
                      }
                      return isOpenNow ? <span className="nex-status-pill-open">Open Now</span> : <span></span>;
                    })()
                  }
                </>
                : <span className="nex-status-pill-close">Closed</span>
              }
            </small>
          </div>
          <div className="item card-text">
            <small className="date">SAT</small>
            <small className="times">
              {schedules?.SATURDAY
                ?
                <>
                  {
                    sortItByTiming(schedules?.SATURDAY).map((e, ind) => (
                      <>
                        <div className="time" key={ind}>
                          {moment(e?.startTime, "HHmmss").format(
                            "hh:mm A"
                          )}{" "}
                          -{" "}
                          {moment(e?.endTime, "HHmmss").format(
                            "hh:mm A"
                          )}
                        </div>
                      </>
                    )
                    )
                  }
                  {
                    (() => {
                      let isOpenNow = false;
                      for (const e of sortItByTiming(schedules?.SATURDAY)) {
                        if (isCurrentTimeInRange("SATURDAY", e?.startTime, e?.endTime)) {
                          isOpenNow = true;
                          break;
                        }
                      }
                      return isOpenNow ? <span className="nex-status-pill-open">Open Now</span> : <span></span>;
                    })()
                  }
                </>
                : <span className="nex-status-pill-close">Closed</span>
              }
            </small>
          </div>
          <div className="item card-text">
            <small className="date">SUN</small>
            <small className="times">
              {schedules?.SUNDAY
                ?
                <>
                  {
                    sortItByTiming(schedules?.SUNDAY).map((e, ind) => (
                      <>
                        <div className="time" key={ind}>
                          {moment(e?.startTime, "HHmmss").format(
                            "hh:mm A"
                          )}{" "}
                          -{" "}
                          {moment(e?.endTime, "HHmmss").format(
                            "hh:mm A"
                          )}
                        </div>
                      </>
                    )
                    )
                  }
                  {
                    (() => {
                      let isOpenNow = false;
                      for (const e of sortItByTiming(schedules?.SUNDAY)) {
                        if (isCurrentTimeInRange("SUNDAY", e?.startTime, e?.endTime)) {
                          isOpenNow = true;
                          break;
                        }
                      }
                      return isOpenNow ? <span className="nex-status-pill-open">Open Now</span> : <span></span>;
                    })()
                  }
                </>
                : <span className="nex-status-pill-close">Closed</span>
              }
            </small>
          </div>
        </div>
        :
        <div>
          <div>
            {(new Date().getDay() === 0) &&
              <div className="item card-text">
                <small className="date">SUN</small>
                <small className="times">
                  {schedules?.SUNDAY
                    ?
                    <>
                      {
                        sortItByTiming(schedules?.SUNDAY).map((e, ind) => (
                          <>
                            <div className="time" key={ind}>
                              {moment(e?.startTime, "HHmmss").format(
                                "hh:mm A"
                              )}{" "}
                              -{" "}
                              {moment(e?.endTime, "HHmmss").format(
                                "hh:mm A"
                              )}
                            </div>
                          </>
                        )
                        )
                      }
                      {
                        (() => {
                          let isOpenNow = false;
                          for (const e of sortItByTiming(schedules?.SUNDAY)) {
                            if (isCurrentTimeInRange("SUNDAY", e?.startTime, e?.endTime)) {
                              isOpenNow = true;
                              break;
                            }
                          }
                          return isOpenNow ? <span className="nex-status-pill-open">Open Now</span> : <span></span>;
                        })()
                      }
                    </>
                    : <span className="nex-status-pill-close">Closed</span>
                  }
                </small>
              </div>
            }
            {(new Date().getDay() === 1) &&
              <div className="item card-text">
                <small className="date">MON</small>
                <small className="times">
                  {schedules?.MONDAY
                    ?
                    <>
                      {
                        sortItByTiming(schedules?.MONDAY).map((e, ind) => (
                          <>
                            <div className="time" key={ind}>
                              {moment(e?.startTime, "HHmmss").format(
                                "hh:mm A"
                              )}{" "}
                              -{" "}
                              {moment(e?.endTime, "HHmmss").format(
                                "hh:mm A"
                              )}
                            </div>
                          </>
                        )
                        )
                      }
                      {
                        (() => {
                          let isOpenNow = false;
                          for (const e of sortItByTiming(schedules?.MONDAY)) {
                            if (isCurrentTimeInRange("MONDAY", e?.startTime, e?.endTime)) {
                              isOpenNow = true;
                              break;
                            }
                          }
                          return isOpenNow ? <span className="nex-status-pill-open">Open Now</span> : <span></span>;
                        })()
                      }
                    </>
                    : <span className="nex-status-pill-close">Closed</span>
                  }
                </small>
              </div>
            }
            {(new Date().getDay() === 2) &&
              <div className="item card-text">
                <small className="date">TUE</small>
                <small className="times">
                  {schedules?.TUESDAY
                    ?
                    <>
                      {
                        sortItByTiming(schedules?.TUESDAY).map((e, ind) => (
                          <>
                            <div className="time" key={ind}>
                              {moment(e?.startTime, "HHmmss").format(
                                "hh:mm A"
                              )}{" "}
                              -{" "}
                              {moment(e?.endTime, "HHmmss").format(
                                "hh:mm A"
                              )}
                            </div>
                          </>
                        )
                        )
                      }
                      {
                        (() => {
                          let isOpenNow = false;
                          for (const e of sortItByTiming(schedules?.TUESDAY)) {
                            if (isCurrentTimeInRange("TUESDAY", e?.startTime, e?.endTime)) {
                              isOpenNow = true;
                              break;
                            }
                          }
                          return isOpenNow ? <span className="nex-status-pill-open">Open Now</span> : <span></span>;
                        })()
                      }
                    </>
                    : <span className="nex-status-pill-close">Closed</span>
                  }
                </small>
              </div>
            }
            {(new Date().getDay() === 3) &&
              <div className="item card-text">
                <small className="date">WED</small>
                <small className="times">
                  {schedules?.WEDNESDAY
                    ?
                    <>
                      {
                        sortItByTiming(schedules?.WEDNESDAY).map((e, ind) => (
                          <>
                            <div className="time" key={ind}>
                              {moment(e?.startTime, "HHmmss").format(
                                "hh:mm A"
                              )}{" "}
                              -{" "}
                              {moment(e?.endTime, "HHmmss").format(
                                "hh:mm A"
                              )}
                            </div>
                          </>
                        )
                        )
                      }
                      {
                        (() => {
                          let isOpenNow = false;
                          for (const e of sortItByTiming(schedules?.WEDNESDAY)) {
                            if (isCurrentTimeInRange("WEDNESDAY", e?.startTime, e?.endTime)) {
                              isOpenNow = true;
                              break;
                            }
                          }
                          return isOpenNow ? <span className="nex-status-pill-open">Open Now</span> : <span></span>;
                        })()
                      }
                    </>
                    : <span className="nex-status-pill-close">Closed</span>
                  }
                </small>
              </div>
            }
            {(new Date().getDay() === 4) &&
              <div className="item card-text">
                <small className="date">THU</small>
                <small className="times">
                  {schedules?.THURSDAY
                    ?
                    <>
                      {
                        sortItByTiming(schedules?.THURSDAY).map((e, ind) => (
                          <>
                            <div className="time" key={ind}>
                              {moment(e?.startTime, "HHmmss").format(
                                "hh:mm A"
                              )}{" "}
                              -{" "}
                              {moment(e?.endTime, "HHmmss").format(
                                "hh:mm A"
                              )}
                            </div>
                          </>
                        )
                        )
                      }
                      {
                        (() => {
                          let isOpenNow = false;
                          for (const e of sortItByTiming(schedules?.THURSDAY)) {
                            if (isCurrentTimeInRange("THURSDAY", e?.startTime, e?.endTime)) {
                              isOpenNow = true;
                              break;
                            }
                          }
                          return isOpenNow ? <span className="nex-status-pill-open">Open Now</span> : <span></span>;
                        })()
                      }
                    </>
                    : <span className="nex-status-pill-close">Closed</span>
                  }
                </small>
              </div>
            }
            {(new Date().getDay() === 5) &&
              <div className="item card-text">
                <small className="date">FRI</small>
                <small className="times">
                  {schedules?.FRIDAY
                    ?
                    <>
                      {
                        sortItByTiming(schedules?.FRIDAY).map((e, ind) => (
                          <>
                            <div className="time" key={ind}>
                              {moment(e?.startTime, "HHmmss").format(
                                "hh:mm A"
                              )}{" "}
                              -{" "}
                              {moment(e?.endTime, "HHmmss").format(
                                "hh:mm A"
                              )}
                            </div>
                          </>
                        )
                        )
                      }
                      {
                        (() => {
                          let isOpenNow = false;
                          for (const e of sortItByTiming(schedules?.FRIDAY)) {
                            if (isCurrentTimeInRange("FRIDAY", e?.startTime, e?.endTime)) {
                              isOpenNow = true;
                              break;
                            }
                          }
                          return isOpenNow ? <span className="nex-status-pill-open">Open Now</span> : <span></span>;
                        })()
                      }
                    </>
                    : <span className="nex-status-pill-close">Closed</span>
                  }
                </small>
              </div>
            }
            {(new Date().getDay() === 6) &&
              <div className="item card-text">
                <small className="date">SAT</small>
                <small className="times">
                  {schedules?.SATURDAY
                    ?
                    <>
                      {
                        sortItByTiming(schedules?.SATURDAY).map((e, ind) => (
                          <>
                            <div className="time" key={ind}>
                              {moment(e?.startTime, "HHmmss").format(
                                "hh:mm A"
                              )}{" "}
                              -{" "}
                              {moment(e?.endTime, "HHmmss").format(
                                "hh:mm A"
                              )}
                            </div>
                          </>
                        )
                        )
                      }
                      {
                        (() => {
                          let isOpenNow = false;
                          for (const e of sortItByTiming(schedules?.SATURDAY)) {
                            if (isCurrentTimeInRange("SATURDAY", e?.startTime, e?.endTime)) {
                              isOpenNow = true;
                              break;
                            }
                          }
                          return isOpenNow ? <span className="nex-status-pill-open">Open Now</span> : <span></span>;
                        })()
                      }
                    </>
                    : <span className="nex-status-pill-close">Closed</span>
                  }
                </small>
              </div>
            }
          </div>
        </div>
      }
      <p><a className="readmore" onClick={() => setShowAll(!showAll)}> {showAll ? 'Show Less' : 'Show More'} </a></p>
    </>
  )
};

export default withAlert()(SingleTimeSlot);
