import { Link } from "react-router-dom";
import { Card, Nav, NavItem } from "reactstrap";
import PeopleKnowWidget from "common/PeopleKnowWidget";
import { useSelector } from "react-redux";
import { isPractitioner } from "utils/UserHelper";
import JobWidget from "common/JobWidget";

const ManageJobSideBar = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { primarySpeciality } = currentUser;
  return (
    <>
      <Card className="tabs-border-radius mt-2 mb-3">
        <Nav vertical>
          <NavItem as="header" className="p-3">
            <Link to="/jobsboard/post-a-job" className="nex-btn-primary-outline d-block align-items-center" id="nex-mjs-job-post-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22}
                height={22}
                fill="currentColor"
                className="bi bi-pencil-square mr-1"
                viewBox="0 0 22 22"
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fillRule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                />
              </svg>
              Post a free Job
            </Link>
          </NavItem>
        </Nav>
      </Card>
      <Card className="tabs-border-radius mt-2 mb-3">
        <Nav vertical>
          <NavItem as="header" className="p-3">
            <Link to="/new/create-jobs" className="nex-btn-primary-outline d-block align-items-center" id="nex-mjs-job-post-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22}
                height={22}
                fill="currentColor"
                className="bi bi-pencil-square mr-1"
                viewBox="0 0 22 22"
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fillRule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                />
              </svg>
              Post a free Job New
            </Link>
          </NavItem>
        </Nav>
      </Card>
      {isPractitioner() && primarySpeciality &&
        <>
          <PeopleKnowWidget speciality={primarySpeciality} />
          <JobWidget widgetTitle="Recommended Jobs" primarySpeciality={primarySpeciality} />
        </>
      }
    </>
  );
};

export default ManageJobSideBar;
