import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import './style.scss'

export default function HeaderTwo() {
  return (
    <Card className='micro-headers-sec-v2'>
        <CardBody>
            <Row>
                <Col lg={3} className="profile-image position-h-center">
                    <img src="https://picsum.photos/id/64/200/300" alt="" className='card-profile-image' />
                </Col>
                <Col lg={9}>
                    <img src="https://picsum.photos/id/10/1200/300" alt="" className='micro-headers-banner' />
                </Col>
                
            </Row>
        </CardBody>
    </Card>
  )
}

