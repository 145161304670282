import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { Formik } from "formik";
import * as Yup from "yup";
import { withAlert } from "react-alert"

import "react-datepicker/dist/react-datepicker.css";
import DropDownBox from "./Components/DropdownBox";
import Cards from "./Components/Cards"
import RescheduleFormFields from "./Components/RescheduleFormFields"
import { appointmentServices } from "utils/appointmentsServices";

const validationSchema = Yup.object({
  slotId: Yup.string().required("This field is required").nullable(),
  apptDate: Yup.date().required("This field is required")
});

const initialValues = {
  "apptDate": new Date(),
  "slotId": null,
  "reason": ""
};

/**
 * 
{
  "bookSlot": {
    "appointmentType": "string",
    "otherEmail": "string",
    "otherName": "string",
    "otherPhone": "string",
    "patientProfileId": 0,
    "platform": "string",
    "reason": "string",
    "requestedByUser": 0,
    "slotId": 0,
    "visitPurpose": "string",
    "visitType": "string"
  },
  "cancelSlot": {
    "appointmentReference": "string",
    "requestedByUser": 0,
    "statusReason": "string"
  }
}
 */


const RescheduleModal = ({ toggle, isOpen, apptData, getAppts, alert }) => {

  const onRescheduleSubmit = (values, { setSubmitting }) => {
    const payload = {
      "bookSlot": {
        "appointmentType": apptData.appointmentType,
        "patientProfileId": apptData.patientProfileId,
        "platform": apptData.platform,
        "reason": "",
        "requestedByUser": apptData.patientProfileId,
        "slotId": Number(values.slotId),
        "visitPurpose": apptData.visitPurpose,
        "visitType": apptData.visitType
      },
      "cancelSlot": {
        "appointmentReference": apptData.appointmentReference,
        "requestedByUser": apptData.patientProfileId,
        "statusReason": "REJECTED"
      }
    }

    appointmentServices.rescheduleAppointment(apptData.appointmentSlotId, payload)
    .then(response => {
      if(response.status === 200 && response.data) {
        alert.success("Appointment rescheduled")
        getAppts()
      }
    })
    .catch(error => {
      console.log("rescheduleAppointment error", error);
      alert.error(error.message)
    })
    .then(() => {
      toggle()
      setSubmitting(false)
    })
    
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} id="profile-modal" className="modal-dialog-scrollable modal-dialog-centered">

      <ModalHeader className="bg-white" toggle={toggle} style={{ color: " #2D4552" }}>Reschedule an Appointment</ModalHeader>
      <ModalBody>
        <Row className="mb-4">
          <Col md={4} className="d-flex flex-column  justify-content-center">
            <div className="text-left f-16 line-h-16 font-weight-bold my-2 px-5" style={{ color: "#2D4552", letterSpacing: "1.5px" }}> Patient Name</div>
            <div className="text-left f-14 line-h-14 text-capitalize my-2 px-5" style={{ letterSpacing: "1.5px" }}>{apptData.patientProfile.firstName} {apptData.patientProfile.lastName}</div>
          </Col>
          <Col md={4} className="d-flex flex-column  justify-content-center">
            <div className="text-left f-16 line-h-16 font-weight-bold my-2 px-5" style={{ color: "#2D4552", letterSpacing: "1.5px" }}>
              Instituition
            </div>
            <div className="text-left f-14 line-h-14 text-capitalize my-2 px-5" style={{ letterSpacing: "1.5px" }}>{apptData.instituteProfile.institutionName}</div>
          </Col>
          <Col md={4} className="d-flex flex-column  justify-content-center">
            <div className="text-left f-16 line-h-16 font-weight-bold my-2 px-4" style={{ color: "#2D4552", letterSpacing: "1.5px" }}>
              Appointment Type
            </div>
            <div className="text-left f-14 line-h-14 text-capitalize my-2 px-4" style={{ letterSpacing: "1.5px" }}>{apptData.appointmentType} <span className="text-lowercase text-danger">({apptData.appointmentStatus})</span></div>
          </Col>
        </Row>

        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onRescheduleSubmit}
          children={props => {
            return <RescheduleFormFields 
                      {...props} 
                      practitionerId={apptData.practitionerProfileId} 
                      facilityId={apptData.facilityId}
                    />
          }}
        />

      </ModalBody>
    </Modal>
  )
}
export default withAlert()(RescheduleModal);