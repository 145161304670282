import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useSelector } from "react-redux";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { Button, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { InstitutionServices } from "utils/InstitutionServices";

const InstitutionPreference = (props) => {
  const { institution } = props;
  const [realTimeChat, setRealTimeChat] = useState(false)
  const [videoChat, setVideoChat] = useState(false);
  const [secondOpinion, setSecondOpinion] = useState(false);

  const [isRealTimeChatLoading, setIsRealTimeChatLoading] = useState(true);
  const [isVideoChatLoading, setIsVideoChatLoading] = useState(true);
  const [isSecondOpinionLoading, setIsSecondOpinionLoading] = useState(true);

  const [isAvailableForReview, setIsAvailableForReview] = useState(true);
  const [isAvailableForReferral, setIsAvailableForReferral] = useState(true);

  let currentUser = useSelector((state) => state.auth.currentUser);

  const modifyRealTimeChat = (flag = false) => {
    if (realTimeChat != flag)
      updatePreferences(flag, videoChat, secondOpinion);
  };

  const modifyVideoChat = (flag = false) => {
    if (videoChat != flag)
      updatePreferences(realTimeChat, flag, secondOpinion);
  };

  const modifySecondOpinion = (flag = false) => {
    if (secondOpinion != flag)
      updatePreferences(realTimeChat, videoChat, flag);
  };

  const modifyReferral = (flag = false) => {
    if (isAvailableForReferral != flag)
      updatePreferences(realTimeChat, videoChat, secondOpinion, flag);
  };

  const getPreferences = (guid) => {
    InstitutionServices.getPreferences(guid)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          // console.log("data", data);
          setRealTimeChat(data?.availableForRealTimeChat);
          setVideoChat(data?.availableForVideoChat);
          setSecondOpinion(data?.availableForSecondOpinion);
          setIsAvailableForReferral(data?.openForReferral);

          setIsRealTimeChatLoading(false);
          setIsVideoChatLoading(false);
          setIsSecondOpinionLoading(false);
        }
        else {
          console.log("Something went wrong, Please try again later");
          setIsRealTimeChatLoading(false);
          setIsVideoChatLoading(false);
          setIsSecondOpinionLoading(false);
        }
      })
  }

  const updatePreferences = (realTimeChat1, videoChat1, secondOpinion1, isAvailableForReferral1) => {
    let payload = {
      "availableForSecondOpinion": secondOpinion1,
      "availableForVideoChat": videoChat1,
      "availableForRealTimeChat": realTimeChat1,
      "openForReferral": isAvailableForReferral1
    }

    InstitutionServices.updatePreferences(props?.institution?.guid, payload)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          setRealTimeChat(data?.availableForRealTimeChat);
          setVideoChat(data?.availableForVideoChat);
          setSecondOpinion(data?.availableForSecondOpinion);
          showSuccessMessage("Preferences updated successfully");
          setIsRealTimeChatLoading(false);
          setIsVideoChatLoading(false);
          setIsSecondOpinionLoading(false);
        }
        else {
          showErrorMessage("Something went wrong, Please try again later");
          setIsRealTimeChatLoading(false);
          setIsVideoChatLoading(false);
          setIsSecondOpinionLoading(false);
        }
      })
  }

  useEffect(() => {
    getPreferences(props?.institution?.guid)
  }, [institution])

  return (
    <>
      {props.isTitle &&
        <div className="nexogic-page-title">Preferences</div>
      }
      <Row id="nex-ipr-preferences-row">
        <Col lg={12}>
          <div className="section-content">
            <Row className="align-items-center">
              <Col>
                <span className="card-text text-muted">
                  Set institution preferences, it's available for these services or not
                </span>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <ListGroup className="nexogic-form-option-lists">
                  {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                    <ListGroupItem>
                      <h3 className="card-title">Second Opinion
                        <small className="card-text text-muted"></small>
                      </h3>
                      <div className="action nexogic-swaches-btns">
                        <Button
                          id="nex-ip-prefarence-so-s-enable-btn"
                          className={
                            "calendar-status-btn enable-btn " +
                            (secondOpinion ? "active" : "")
                          }
                          onClick={() => {
                            setIsSecondOpinionLoading(true);
                            modifySecondOpinion(true)
                          }
                          }
                          disabled={isSecondOpinionLoading}
                        >
                          Enable
                        </Button>
                        <Button
                          id="nex-ip-prefarence-so-s-diabled-btn"
                          className={
                            "calendar-status-btn disable-btn " +
                            (!secondOpinion ? "active" : "")
                          }
                          onClick={() => {
                            setIsSecondOpinionLoading(true);
                            modifySecondOpinion(false)
                          }
                          }
                          disabled={isSecondOpinionLoading}
                        >
                          Disable
                        </Button>
                      </div>
                    </ListGroupItem>
                  }
                  {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                    <ListGroupItem>
                      <h3 className="card-title">Review
                        <small className="card-text text-muted">Lorem Ipsum is simply dummy text of the printing.</small>
                      </h3>
                      <div className="action nexogic-swaches-btns">
                        <Button
                          id="nex-ip-prefarence-review-s-enable-btn"
                          className={
                            "calendar-status-btn enable-btn " +
                            (isAvailableForReview ? "active" : "")
                          }
                          onClick={() => {
                            setIsAvailableForReview(true);
                          }
                          }
                          disabled={isAvailableForReview}
                        >
                          Enable
                        </Button>
                        <Button
                          id="nex-ip-prefarence-review-s-disable-btn"
                          className={
                            "calendar-status-btn disable-btn " +
                            (!isAvailableForReview ? "active" : "")
                          }
                          onClick={() => {
                            setIsAvailableForReview(false);
                          }
                          }
                          disabled={!isAvailableForReview}
                        >
                          Disable
                        </Button>
                      </div>
                    </ListGroupItem>
                  }
                  <ListGroupItem>
                    <h3 className="card-title">Referrals
                      <small className="card-text text-muted">Enable your profile to receive referrals and refer your Patients</small>
                    </h3>
                    <div className="action nexogic-swaches-btns">
                      <Button
                        id="nex-ipr-switch-referrals-enable-btn"
                        className={
                          "calendar-status-btn enable-btn " +
                          (isAvailableForReferral ? "active" : "")
                        }
                        onClick={() => {
                          setIsAvailableForReferral(true);
                          modifyReferral(true)
                        }
                        }
                        disabled={isAvailableForReferral}
                      >
                        Enable
                      </Button>
                      <Button
                        id="nex-ipr-switch-referrals-disable-btn"
                        className={
                          "calendar-status-btn disable-btn " +
                          (!isAvailableForReferral ? "active" : "")
                        }
                        onClick={() => {
                          setIsAvailableForReferral(false);
                          modifyReferral(false)
                        }
                        }
                        disabled={!isAvailableForReferral}
                      >
                        Disable
                      </Button>
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

InstitutionPreference.defaultProps = {
  isTitle: true,
};

export default withAlert()(InstitutionPreference);
