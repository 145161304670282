import React from 'react'
import doc_img from 'assets/images/doctor-png-29750.png'
import logo from 'assets/images/logo-top-icon.png'
import ads_bg2 from 'assets/images/ads-bg2.jpg'
import './css/v1.scss'

function AdsTwo(props) {
  return (
    <div className='nex-ads-wrapper size-md v2 mb-3' style={{backgroundImage:`url(${props.bg})`}}>
    <div className="nex-ads-content position-relative">
        <div className="nex-ads-header abosolute-top-right"> 
            <a href="#"><img src={props.logo_img} alt="" /></a>
        </div>
        <div className="nex-ads-body ">
            <div className="nex-add-content">
                <div className="nex-add-content-top">
                    <h2 className='nex-ads-p-heading' dangerouslySetInnerHTML={{__html: props.title}}></h2>
                    <div className="nex-ads-c-w">
                        <p className='nex-ads-title-1'><strong>{props.doctor_name}</strong></p>
                        <p dangerouslySetInnerHTML={{__html: props.doctor_details}}></p>
                    </div>
                    <div className="nex-ads-ac-w">
                        <p className='nex-ads-title-2'>About <br/>Consultent</p>
                        <p>{props.consultent_details}</p>
                    </div>
                    <div className="float-blue-bg"></div>
                </div>
                <div className="nex-add-content-bot">
                    <div className="nex-ads-appoint-sec">
                        <div className="nex-ads-floated-text">For</div>
                        <p className='bold-text mb-0'>Appointment</p>
                        <p className='link'><a href={'tel:'+props.phone}>{props.phone}</a></p>
                        <div className="address">
                            <span className="icon"></span>
                            {/*<p>{props.address}</p>*/}
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="nex-add-aside">
                <div className="nex-add-aside-v2-graphic">
                    <img src={doc_img} alt="" />
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

/* default props */
AdsTwo.defaultProps = {
    bg:ads_bg2,
    logo_img:logo,
    logo_url:"#",
    logo_target:"_blank",
    title: "<span>Appoinment</span> the best doctor",
    short_desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum dolores est non ipsa laborum?",
    doctor_img:doc_img,
    button_text: "Appointment",
    button_link: "#",
    button_target: "_blank",
    doctor_name: "DR. DAVID SMITH ",
    doctor_details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo, magni.",
    phone:"+123 456 7890",
    website:"www.madecare.com",
    consultent_details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo, magni",
    address: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo, magni"
  }
  
  export default AdsTwo;