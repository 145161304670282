import React from 'react'
import Slider from "react-slick";
import './style.scss';

export default function RoundedSlider() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4,
                }
              },
              {
                breakpoint: 1000,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 520,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
        ]
      };
  return (
    <div className='micro-slider-v2'>
        <Slider {...settings}>
        <div className="slider-item">
                <div className="micro-slider-card">
                    <div className="graphic"><img src="https://picsum.photos/id/200/100/100" alt="" /></div>
                    <h3 className="card-title">Item 12</h3>
                </div>
            </div>
            <div className="slider-item">
                <div className="micro-slider-card">
                    <div className="graphic"><img src="https://picsum.photos/id/203/100/100" alt="" /></div>
                    <h3 className="card-title">Item 21</h3>
                </div>
            </div>
            <div className="slider-item">
                <div className="micro-slider-card">
                    <div className="graphic"><img src="https://picsum.photos/id/204/100/100" alt=""  /></div>
                    <h3 className="card-title">Item 31</h3>
                </div>
            </div>
            <div className="slider-item">
                <div className="micro-slider-card">
                    <div className="graphic"><img src="https://picsum.photos/id/208/100/100" alt="" /></div>
                    <h3 className="card-title">Item 41</h3>
                </div>
            </div>
            <div className="slider-item">
                <div className="micro-slider-card">
                    <div className="graphic"><img src="https://picsum.photos/id/206/100/100" alt="" /></div>
                    <h3 className="card-title">Item 51</h3>
                </div>
            </div>
            <div className="slider-item">
                <div className="micro-slider-card">
                    <div className="graphic"><img src="https://picsum.photos/id/210/100/100" alt="" /></div>
                    <h3 className="card-title">Item 61</h3>
                </div>
            </div>
            <div className="slider-item">
                <div className="micro-slider-card">
                    <div className="graphic"><img src="https://picsum.photos/id/209/100/100" alt="" /></div>
                    <h3 className="card-title">Item 71</h3>
                </div>
            </div> 
        </Slider>
    </div>
  )
}
