import React, { useState } from "react"
import { Label, FormGroup } from 'reactstrap'
import { ErrorMessage } from 'formik';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import '../../../assets/styles/typeahead.css'


const UserLocationAutocompleteField = (props) => {
    const { placeholder, disabled } = props
    const { name, value } = props.field
    const { setFieldValue, setFieldTouched, touched, errors } = props.form
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(false)

    const getData = async (searchValue) => {
        setLoading(true)
        const key = "AIzaSyAYmMyjODE4s9BX37wAw7vResRh2ORv9CY"
        const proxyurl = "https://cors-anywhere.herokuapp.com/";
        try {
            await fetch(`${proxyurl}https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${searchValue}&key=${key}&types=(regions)`)
            .then(res => res.json())
            .then(async (data) => {
                const locations = []
                if( data && data.predictions){
                      const { predictions } = data 
                      predictions.map((item, index)=>{
                        locations.push({ name: item.description, place_id:item.place_id, loc: item.structured_formatting.main_text})  
                        return item
                    })

                     await setLocations(locations)
                     await setLoading(false)
                }

            })
        }
        catch (e) {
            setLoading(false)
        }
    }

    const handleChange = async (selected) => {
        if(selected && selected.length){
            setFieldValue(name, selected[0].loc, true)
        }
    }

    const onInputChange = async (string, e) => {
         if(string===""){
            await setFieldValue(name, "", false)
         } else {
            await setFieldValue(name, string, true)
         }
    }

   return (
        <FormGroup className="flex-grow-1">
            <Label htmlFor={name} className="d-block">{props.label}</Label>
            <AsyncTypeahead
                isLoading={loading}
                //clearButton
                disabled={disabled}
                onSearch={getData}
                selected={value ? [{ name: value }] : []}
                id={name}
                options={locations}
                labelKey={"name"}
                placeholder={placeholder}
                onChange={handleChange}
                onInputChange={onInputChange}
                onBlur={(e) => setFieldTouched(name, true)}
                delay={1000}
                filterBy={(option, props) => {
                    return option
                }}
                renderMenu={(results, menuProps) => {
                    if (results && results.length) {
                     return (
                            <Menu {...menuProps}>
                                <>
                                    {
                                        results.map((result, index) => (
                                            <MenuItem option={result} key={`${result.place_id}_${index}`}position={index} className="no-wrap">
                                                {result.name}
                                            </MenuItem>
                                        ))

                                    }
                                </>
                            </Menu>
                        )
                    }

                    return null

                }}
                isInvalid={Boolean(touched[name] && errors[name])}
            />
            <ErrorMessage name={name} render={msg => <span className="text-danger f-10 d-inline-block  mt-1 line-height-error">{msg}</span>} />
        </FormGroup>
    )
}
export default UserLocationAutocompleteField