import { faCamera, faCode, faImage, faLink, faPaperclip, faTimes, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";


const PublishMenu = () => {
    const [showattach, setShowattach] = useState(false);


    const attachMenuClick = () => {
        setShowattach(!showattach);
    }
    return (
        <>

            <div className="write-preview">

                <FormGroup>
                    <Label for="heading">
                        Headline
                    </Label>
                    <Input
                        id="headline"
                        name="headline"
                        type="text"
                        placeholder="Heading"

                    />
                </FormGroup>
                <FormGroup>
                    <Input type="file" hidden id="upload-cover-img" />
                    <label className="cover-img" htmlFor="upload-cover-img">

                        <FontAwesomeIcon icon={faCamera} />
                        <h3>No cover image is selected</h3>
                        <p className="para-hints">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when </p>
                    </label>
                </FormGroup> <FormGroup>
                    <div className="attach-with-text">
                        <Button id="attachments_menu_btn" className="attachments" onClick={attachMenuClick} >
                            {
                                showattach ? <FontAwesomeIcon icon={faTimes} /> :
                                    <FontAwesomeIcon icon={faPaperclip} />
                            }

                        </Button>

                        <div className="textarea-cont">
                            {showattach &&
                                <div className="attach-elems">
                                    <div className="attach-item">
                                        <FontAwesomeIcon icon={faImage} className="mr-2" />
                                        Image
                                    </div>
                                    <div className="attach-item">
                                        <FontAwesomeIcon icon={faVideo} className="mr-2" />
                                        Video
                                    </div>
                                    <div className="attach-item">
                                        <FontAwesomeIcon icon={faLink} className="mr-2" />
                                        Link
                                    </div>
                                    <div className="attach-item">
                                        <FontAwesomeIcon icon={faCode} className="mr-2" />
                                        Embed code
                                    </div>
                                </div>
                            }
                            <Input
                                id="exampleText"
                                name="text"
                                type="textarea"
                                placeholder="Enter text image here"
                            />
                        </div>

                    </div>
                </FormGroup>
            </div>

        </>
    )



}

export default PublishMenu;