import logo_white from "assets/images/logo-white-transparent.png";
import google_img from "assets/images/social-google-img.png";
import facebook_img from "assets/images/facebook-social-img.png";
import apple_img from "assets/images/apple-social-img.png";
import doctor_profile_img from "assets/images/doctor-profile-img.png";
import graduation_cap_icon from "assets/images/graduation-cap.png";
import suitcase_icon from "assets/images/suitcase.png";
import pin_small_icon from "assets/images/pin-small.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Field, Formik } from "formik";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import * as Yup from "yup";
import { Button, Col, Row } from "reactstrap";
import FormikMobileField from "components/forms/formikFields/FormikMobileField";
const phoneRegExp =
    /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{5})(( x| ext)\d{1,5}){0,1}$/;
const validationSchema = Yup.object().shape({
    phone: Yup.string()
        .matches(phoneRegExp, "Mobile number is not valid")
        .required("This field is required"),
});

const NewProfileClaim = (props) => {
    const [isSubmitting, setSubmitting] = useState(false);

    const initValues = useMemo(() => {
        return {
            phone: '',
        };
    }, []);

    const [initialValues, setInitialValues] = useState(initValues);

    const onSubmit = (values) => {
        console.log(values);
        if (!isEmpty(values.name)) {
            setSubmitting(true);
            console.log(values);
        }

    };

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <div className="nex-bg-light-blue">
            <main className="nex-login-register--w">
                <div className="container sm d-flex" data-aos="fade-up">
                    <div className="nex-form-aside nex-form-aside-v2" >
                        <div className="logo" data-aos="fade-up" data-aos-duration="800" data-aos-delay="100">
                            <a href="/"><img src={logo_white} alt="" /></a>
                        </div>

                        <h2 className="nex-text-xxl" data-aos="fade-up" data-aos-duration="800" data-aos-delay="150">Claim your profile</h2>
                    </div>
                    <div className="nex-form-fields--w nex-form-fields--w-v2">
                        <div className="nex-form-top-slot nex-mob-hide" data-aos="fade-down" data-aos-duration="800" data-aos-delay="200">
                            <p className="nex-text-lg"><a href="" className="text-gray">Back</a></p>
                        </div>
                        <div className="nex-form-center-slot">
                            {/** selected profile */}
                            <p className="nex-text-lg text-black mb-3 nex-mob-text-center" data-aos="fade-down" data-aos-duration="800" data-aos-delay="250">Claiming the profile for</p>
                            <div className="nex-image-list nex-selected-profile" data-aos="fade-down" data-aos-duration="800" data-aos-delay="300">
                                <div className="nexogic-graphic">
                                    <img src={doctor_profile_img} alt="" className="nexogic-avatar lg" />
                                </div>
                                <div className="nexogic-info">
                                    <h3 className="nex-text-xl">Dr. Surya Prakash Rao</h3>
                                    <ul className="nex-inline-list">
                                        <li><a href="#">Cardiology</a></li>
                                        <li><a href="#">Radiology</a></li>
                                    </ul>
                                    <p><span className="nex-icon xs"><img src={graduation_cap_icon} alt="" /></span>BAMS</p>
                                    <p><span className="nex-icon xs"><img src={suitcase_icon} alt="" /></span>23+ Experience</p>
                                    <p className="nex-lead text-dark"><span className="nex-icon xs"><img src={pin_small_icon} alt="" /></span>Hyderabad, Telengana, India</p>
                                </div>
                            </div>
                            {/** selected profile ends */}
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                enableReinitialize={true}
                                onSubmit={onSubmit}
                                children={(props) => {
                                    return (
                                        <>
                                            <RenderForm
                                                {...props}
                                                isSubmitting={isSubmitting}
                                            />
                                        </>
                                    );
                                }}
                            />

                        </div>

                    </div>
                </div>
            </main>
        </div>
    )
}

export default NewProfileClaim;

const RenderForm = (props) => {
    const [iseditable, setisEditable] = useState(true);
    const {
        values,
        errors,
        isSubmitting,
        handleSubmit,
    } = props;

    return (
        <>
            <div className="nex-form-container">
                <div className="nex-social-login-btns-w" data-aos="fade-down" data-aos-duration="800" data-aos-delay="350">
                    <a href="" className="btn"><img src={google_img} alt="" width={50} /></a>
                    <a href="" className="btn"><img src={facebook_img} alt="" width={50} /></a>
                    <a href="" className="btn"><img src={apple_img} alt="" width={50} /></a>
                </div>
                <div className="nex-or-txt--w" data-aos="fade-down" data-aos-duration="800" data-aos-delay="400">or</div>
                <Field
                    id="phone"
                    name="phone"
                    autoComplete="phone"
                    type="text"
                    component={FormikMobileField}
                    label=""
                    placeholder="Please enter your mobile number"
                    value={props.initialValues.phone}
                    disabled={props.initialValues.phone === "" ? false : true}
                />
                <div className="form-group" data-aos="fade-down" data-aos-duration="800" data-aos-delay="500">
                    <Button
                        id="nex-continue-form-next"
                        className="btn btn-primary lg w-100"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Continue
                    </Button>

                </div>
            </div>
        </>
    )
}