import React, { useEffect, useState, useRef } from "react"
import { Label } from 'reactstrap'
import { ErrorMessage } from 'formik';
import { Typeahead } from 'react-bootstrap-typeahead';
import { isEmpty } from "lodash"

import 'assets/styles/typeahead.css'

const PrimarySpecialitiesReferralTypeheadField = (props) => {
    const { disabled, inputProps, placeholder, className, getSelectedSpeciality, multiple, allowNew, getPractitionersList } = props
    const { name, value } = props.field
    const { setFieldValue, setFieldTouched, touched, errors } = props.form
    const [selectedSpeciality, setSelectedSpeciality] = useState("");
    const [allowMultiple, setSllowMultiple] = useState(false);
    const [allowNewFlag, setallowNewFlag] = useState(false);
    // const [activeFlag, setActiveFlag] = useState(false);
    const typeaRef = useRef();
    useEffect(() => {
        if (multiple === true) {
            setSllowMultiple(true)
        }
        if (allowNew === true) {
            setallowNewFlag(true)
        }
    }, [multiple])

    const handleChange = (selected) => {
        if (selected[0] && props.form.values.referToInstitution.guid) {
            props.setShowAssociatedPractitioners(true);
            getPractitionersList(props.form.values.referToInstitution);
            setFieldValue(name, [selected[0]], true);
        } else {
            getPractitionersList();
            setFieldValue(name, selected, true);
            setFieldValue(`referToPractitioner`, []);
            typeaRef.current.hideMenu();
            props.setShowAssociatedPractitioners(false);
        }
        if (getSelectedSpeciality !== undefined) {
            if (selected !== undefined && selected.length > 0) {
                setSelectedSpeciality(selected[0]);
            } else {
                setSelectedSpeciality("");
                getSelectedSpeciality("");
            }
        }
    }
    const setOnBlur = (name, flag) => {
        setFieldTouched(name, flag)
        if (getSelectedSpeciality != undefined) {
            getSelectedSpeciality(selectedSpeciality);
        }
        typeaRef.current.hideMenu();
    }
    return (
        <>
            {props.label && <Label htmlFor={name} className="d-block skills">{props.label}</Label>}
            <Typeahead
                ref={typeaRef}
                className={className}
                disabled={disabled}
                clearButton
                selected={value}
                id={name}
                options={props.specialit}
                labelKey={"value"}
                placeholder={placeholder}
                multiple={allowMultiple}
                allowNew={allowNewFlag}
                onChange={handleChange}
                onBlur={(e) => setOnBlur(name, true)}
                isInvalid={Boolean(touched[name] && errors[name])}
            />
            <ErrorMessage name={name} render={msg => <span className="text-danger skill-err f-12 d-inline-block  mt-1 line-height-error">{msg}</span>} />
        </>
    )
}
export default PrimarySpecialitiesReferralTypeheadField
