import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import white_logo from "assets/images/logo-white.png";
import white_small_logo from "assets/images/white-nex-logo.png";
import search_icon from "assets/images/svgs/search_icon.svg";
import NewProfileMenu from "./components/ProfileMenu/NewProfileMenu";
import Close from "assets/images/close.png";
import { createBrowserHistory } from "history";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Col,
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import { isAdmin, isInstitutionAdmin, isPatient } from "utils/UserHelper";
import { UserService } from "utils/UserService";
import HeaderMenu from "./components/headerMenu/HeaderMenu";
import HeaderMenuPatient from "./components/headerMenuPatient/HeaderMenuPatient";
import Hospital from "./components/Hospital";
import InCompleteNotify from "./components/InCompleteNotify";
import Logo from "./components/Logo";
import ProfileMenu from "./components/ProfileMenu";
import ResponsiveNotification from "./components/ResponsiveFolders/ResponsiveNotification";
import ResponsiveProfileInfo from "./components/ResponsiveFolders/ResponsiveProfileInfo";
import ResponsiveSearch from "./components/ResponsiveFolders/ResponsiveSearch";
import "./header.css";
import FooterMobileMenu from "common/FooterMobileMenu";
import AppHeaderTop from "common/AppHeaderTop";
import { useEffect } from "react";
import { UserStatusConstants } from "utils/Constants";
import NewSearch from "./components/Search/NewSearch";

const NewHeader = () => {

  const [isActive, setIsActive] = useState(false);
  const handleToggle = () => {
    setIsActive(!isActive);
  }

  const history = createBrowserHistory();
  const pathname = history.location.pathname;
  const [toggler, setToggler] = useState(false);
  const [token, setToken] = useState(UserService.verifyToken());
  const isAdminUser = useMemo(() => isAdmin());
  const isPatientUser = useMemo(() => isPatient());
  const currentUser = useSelector((state) => state.auth.currentUser);
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );
  const toggle = () => setToggler(!toggler);

  const logout = () => {
    UserService.logout();
    window.location.reload();
  };

  const [isApp, setIsAPP] = useState(false);
  const [isSearchOn, setIsSearchOn] = useState(false);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('appreset') === '1') {
      localStorage.removeItem('app_status');
    }
    const ls_app_val = localStorage.getItem('app_status') || null;

    const inApp = queryParams.get('inapp');
    //const is_browser = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Win/i.test(navigator.userAgent);
    //const is_react_native = /ReactNativeWebView|WebView/i.test(navigator.userAgent);

    if (inApp === '1' || ls_app_val == 'inapp') {
      setIsAPP(true);
      localStorage.setItem('app_status', 'inapp');
      document.body.classList.add('view_in_app');
    }
  }, []);

  return (
    <>
      <div className="nex-admin--header">
        <div className="nex-admin--logo">
          <img src={white_logo} alt="" />
        </div>
        <NewSearch />

        <div className="nex-admin--header-utility-link">
          <div className="nex-header-small-logo">
            <img src={white_small_logo} alt="" />
          </div>
          <div className="nex-header-btns">
            <a className="btn btn-transparent nex-header-search-icon-btn" ><img src={search_icon} alt="" /></a>
            <a href="" className="btn btn-transparent"><svg
              width={26} height={26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.63525 3.30704C9.0574 1.88489 10.9863 1.08594 12.9975 1.08594C15.0087 1.08594 16.9376 1.88489 18.3597 3.30704C19.7819 4.7292 20.5808 6.65805 20.5808 8.66927C20.5808 12.3013 21.3576 14.5568 22.0735 15.8693C22.433 16.5282 22.783 16.9605 23.0261 17.2172C23.148 17.3458 23.2437 17.4311 23.3014 17.4791C23.3302 17.5032 23.3495 17.5179 23.3579 17.5241C23.3592 17.5251 23.3602 17.5258 23.361 17.5264C23.7493 17.7932 23.9212 18.2811 23.7843 18.7332C23.646 19.1901 23.2249 19.5026 22.7475 19.5026H3.24748C2.77009 19.5026 2.34897 19.1901 2.21063 18.7332C2.07376 18.2811 2.24562 17.7932 2.63398 17.5264C2.63474 17.5258 2.63577 17.5251 2.63707 17.5241C2.64541 17.5179 2.66476 17.5032 2.6936 17.4791C2.75125 17.4311 2.847 17.3458 2.96884 17.2172C3.21197 16.9605 3.56198 16.5282 3.92143 15.8693C4.63732 14.5568 5.41415 12.3013 5.41415 8.66927C5.41415 6.65805 6.2131 4.72919 7.63525 3.30704ZM2.64493 17.519C2.64501 17.5189 2.6451 17.5189 2.64518 17.5188C2.64518 17.5188 2.64517 17.5188 2.64517 17.5188L2.64493 17.519ZM5.57591 17.3359H20.419C20.3366 17.2009 20.2539 17.0579 20.1714 16.9068C19.2623 15.2401 18.4141 12.6206 18.4141 8.66927C18.4141 7.23268 17.8435 5.85493 16.8276 4.83911C15.8118 3.82329 14.4341 3.2526 12.9975 3.2526C11.5609 3.2526 10.1831 3.82329 9.16732 4.83911C8.1515 5.85493 7.58081 7.23268 7.58081 8.66927C7.58081 12.6206 6.73264 15.2401 5.82353 16.9068C5.74109 17.0579 5.65834 17.2009 5.57591 17.3359Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.579 21.8105C11.0965 21.5103 11.7594 21.6865 12.0597 22.204C12.1549 22.3682 12.2916 22.5044 12.456 22.5992C12.6205 22.6939 12.807 22.7437 12.9967 22.7437C13.1865 22.7437 13.373 22.6939 13.5374 22.5992C13.7019 22.5044 13.8386 22.3682 13.9338 22.204C14.234 21.6865 14.897 21.5103 15.4145 21.8105C15.932 22.1107 16.1082 22.7736 15.808 23.2912C15.5223 23.7837 15.1122 24.1925 14.6189 24.4766C14.1255 24.7608 13.5661 24.9104 12.9967 24.9104C12.4274 24.9104 11.868 24.7608 11.3746 24.4766C10.8812 24.1925 10.4712 23.7837 10.1855 23.2912C9.88528 22.7736 10.0615 22.1107 10.579 21.8105Z"
                fill="white"
              />
            </svg>
            </a>

          </div>
          <div className="nex-header-divider"></div>
          {token && currentUser ? (
            <div className="nex-header-profile">
              <NewProfileMenu key={token} currentUser={currentUser} globalProfileInfo={globalProfileInfo} />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <AppHeaderTop />
      {<InCompleteNotify currentUser={currentUser} />}
    </>
  );
};

export default NewHeader;